import { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { SearchPaginatedQuery } from '@bpm-web-app/api-client';
import { rebuildReactTooltip, useDebouncedCallback } from '@bpm-web-app/utils';
import { CustomIcon } from '../../custom-icon/custom-icon';
import styles from './track-list-filters.module.css';
import GenresFilter from '../../../genres-filter/genres-filter';
import { FiltersContext, FiltersSupremeTypes } from '../../../filters/filters.context';
import BpmFilter from '../../bpm-filter/bpm-filter';
import KeyFilter from '../../../key-filter/key-filter';
import ShowingFilter from '../../../showing-filter/showing-filter';
import VersionsFilter from '../../../versions-filter/versions-filter';
import { TrackListActiveFilters } from './track-list-active-filters';

interface TrackListFiltersProps {
    // eslint-disable-next-line react/no-unused-prop-types
    isCreate?: boolean;
    dynamicActiveTabColor?: boolean;
    showActiveFilters?: boolean;
    resultsCount?: number;
    defaultFilters?: Partial<SearchPaginatedQuery>;
    hideHideRemix?: boolean;
    onActionSelected?: () => void;
}
interface FilterTabProps {
    title: FiltersSupremeTypes;
    activeTabComponent?: ReactElement;
    isActiveTab?: boolean;
    disabled?: boolean;
    filterCount: number;
}

export function TrackListFilters({
    defaultFilters,
    resultsCount,
    dynamicActiveTabColor,
    showActiveFilters = false,
    hideHideRemix,
    onActionSelected
}: TrackListFiltersProps) {
    const {
        versions,
        hideRemixes,
        hideExclusives,
        hideExplicit,
        hidePrevDownloaded,
        bpm,
        setBpm,
        setHideRemixes,
        setHideExclusives,
        setHideExplicit,
        setHidePrevDownloaded,
        setVersions,
        key,
        setKey,
        genres,
        setGenres,
        activeFilters,
    } = useContext(FiltersContext);

    const [openIndex, setOpenIndex] = useState<number | undefined>(undefined);
    const [lastOpenIndex, setLastOpenIndex] = useState<number | undefined>(undefined);
    const [genresDisabled, setGenresDisabled] = useState<boolean>(false);
    const [versionsDisabled, setVersionsDisabled] = useState<boolean>(false);
    const [shouldDisableFurtherActions, setShouldDisableFurtherActions] = useState<boolean>(false);

    const isOpen = useMemo(() => openIndex !== undefined, [openIndex]);

    const handleSetOpenIndex = useCallback((index?: number) => {
        setLastOpenIndex(openIndex);
        setOpenIndex(index);
    }, [openIndex]);

    const disableFurtherActions = useDebouncedCallback(() => setShouldDisableFurtherActions(true), 500);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleAction = useCallback((actionProps: any, action: any) => {
        if (action && shouldDisableFurtherActions === false) {
            if (actionProps) action(actionProps);
            else action();
        }

        if (onActionSelected) {
            disableFurtherActions();
            onActionSelected();
        }
    }, [onActionSelected, shouldDisableFurtherActions, disableFurtherActions]);

    useEffect(() => {
        rebuildReactTooltip();
    }, [versionsDisabled, genresDisabled]);

    const handleCloseTabs = () => {
        handleSetOpenIndex(undefined);
    };

    const handleTabPressed = (index: number) => {
        if (index === openIndex) {
            handleCloseTabs();
        } else {
            handleSetOpenIndex(index);
        }
    };

    const filterSupremeTabs = useMemo<FilterTabProps[]>(
        () => [
            {
                title: 'Genre',
                isActiveTab: openIndex === 0,
                filterCount: activeFilters?.filter((item) => item.type === 'Genre').length || 0,
                disabled: genresDisabled,
                activeTabComponent: (
                    <GenresFilter
                        justTags
                        viewAll
                        rectangularCheckboxes
                        value={genres}
                        onChange={(newGenres) => handleAction(newGenres, setGenres)}
                        onDisable={setGenresDisabled}
                        defaultFilters={defaultFilters} />
                ),
            },
            {
                title: 'BPM',
                isActiveTab: openIndex === 1,
                filterCount: activeFilters?.filter((item) => item.type === 'BPM').length || 0,
                activeTabComponent: (
                    <div className={styles['track-list-filters__max-width']}>
                        <BpmFilter justSlider value={bpm} onChange={(newBpm) => handleAction(newBpm, setBpm)} />
                    </div>
                ),
            },
            {
                title: 'Key',
                isActiveTab: openIndex === 2,
                filterCount: activeFilters?.filter((item) => item.type === 'Key').length || 0,
                activeTabComponent: (
                    <div className={styles['track-list-filters__max-width']}><KeyFilter justFilter value={key} onChange={(newKey) => handleAction(newKey, setKey)} /></div>
                ),
            },
            {
                title: 'Showing',
                isActiveTab: openIndex === 3,
                filterCount: activeFilters?.filter((item) => item.type === 'Hide Exclusives' || item.type === 'Hide Explicit' || item.type === 'Hide Remixes').length || 0,
                activeTabComponent: (
                    <ShowingFilter
                        hideRemixes={hideRemixes}
                        onHideRemixes={(booleanValue) => handleAction(booleanValue, setHideRemixes)}
                        hideHideRemix={hideHideRemix}
                        hideExclusives={hideExclusives}
                        onHideExclusives={(booleanValue) => handleAction(booleanValue, setHideExclusives)}
                        hideExplicit={hideExplicit}
                        onHideExplicit={(booleanValue) => handleAction(booleanValue, setHideExplicit)}
                        hidePrevDownloaded={hidePrevDownloaded}
                        onHidePrevDownloaded={(booleanValue) => handleAction(booleanValue, setHidePrevDownloaded)}
                        justFilter
                        hasTwoColumns />
                ),
            },
            {
                title: 'Version',
                isActiveTab: openIndex === 4,
                filterCount: activeFilters?.filter((item) => item.type === 'Version').length || 0,
                disabled: versionsDisabled,
                activeTabComponent: (
                    <VersionsFilter
                        justTags
                        viewAll
                        rectangularCheckboxes
                        value={versions}
                        onChange={(newVersions) => handleAction(newVersions, setVersions)}
                        onDisable={setVersionsDisabled}
                        defaultFilters={defaultFilters} />
                ),
            }
        ],
        [
            bpm,
            handleAction,
            genres,
            hideExclusives,
            hideExplicit,
            hidePrevDownloaded,
            hideRemixes,
            key,
            openIndex,
            setBpm,
            setGenres,
            setHideExclusives,
            setHideExplicit,
            setHidePrevDownloaded,
            setHideRemixes,
            setKey,
            setVersions,
            versions,
            activeFilters,
            genresDisabled,
            versionsDisabled,
            defaultFilters,
            hideHideRemix
        ]
    );

    const renderFilterTabs = () => {
        return (
            <div className={styles['track-list-filters__tabs']}>
                {filterSupremeTabs.map((tab, index) => (
                    <button
                        type="button"
                        onClick={() => handleTabPressed(index)}
                        disabled={tab.disabled}
                        data-tip={tab.disabled ? 'Filter option disabled. Adjust other selections.' : ''}
                        className={classNames(styles['track-list-filters__tab'], {
                            [styles['track-list-filters__tab--active']]: tab.isActiveTab,
                            [styles['track-list-filters__tab--active--dynamic']]: tab.isActiveTab && dynamicActiveTabColor
                        })}
                        key={tab.title}>
                        <span className={styles['track-list-filters__tab--text']}>{tab.title}
                            <span className={styles['track-list-filters__tab--number']}>
                                {tab.filterCount > 0 ? `(${tab.filterCount})` : ''}
                            </span>
                        </span>
                    </button>
                ))}

            </div>
        );
    };

    const renderIcon = () => {
        return (
            <CustomIcon
                type={isOpen ? 'chevron-right-icon' : 'filters-icon'}
                className={isOpen ? styles['track-list-filters__chevron-icon'] : ''}
                onClick={() => isOpen ? handleCloseTabs() : setOpenIndex(lastOpenIndex || filterSupremeTabs.findIndex((i) => !i.disabled))}
                color={isOpen ? 'white' : activeFilters && activeFilters?.length > 0 ? 'red' : 'gray'}
                hasIconHover={!isOpen}
                hasBackgroundHover
                container="round"
                containerSize="regular"
                tooltip={!isOpen ? 'Open Filters' : 'Close Filters'}
            />
        );
    };

    const dropdownComponent = () => {
        return filterSupremeTabs.map((tab, index) => {
            return (
                <div key={tab.title} className={classNames(styles['track-list-filters__dropdown-container'], { [styles['track-list-filters__dropdown-container--open']]: index === openIndex })}>
                    {tab.activeTabComponent}
                </div>
            );
        });
    };

    return (
        <div className={styles['track-list-filters']}>
            <div className={styles['track-list-filters__container']}>
                {renderIcon()}
                {renderFilterTabs()}
            </div>
            {dropdownComponent()}
            {showActiveFilters ? <TrackListActiveFilters resultsCount={resultsCount} /> : null}
        </div>
    );
}
