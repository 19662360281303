import React, { useCallback, KeyboardEvent } from 'react';
import classNames from 'classnames';
import { KeyboardScale, KeyboardKeyType, keyTypes, scales } from '@bpm-web-app/utils';
import styles from './key-keyboard-input.module.css';

export interface KeyKeyboardInputProps {
    activeKeys: string[];
    onKeyChange: (value: string[], scale: KeyboardScale) => void;
    scale: KeyboardScale;
    onScaleChange: (value: KeyboardScale) => void;
    keyType: KeyboardKeyType;
    onKeyTypeChange: (value: KeyboardKeyType) => void;
    isSingleSelect: boolean;
}

const BIGGER_KEYS_KEYBOARD = ['C', 'D', 'E', 'F', 'G', 'A', 'B'];
const SMALL_FLAT_KEYS_KEYBOARD = ['Db', 'Eb', 'Gb', 'Ab', 'Bb'];
const SMALL_SHARP_KEYS_KEYBOARD = ['C#', 'D#', 'F#', 'G#', 'A#'];

export function KeyKeyboardInput({ onKeyChange, activeKeys, scale, onScaleChange, keyType, onKeyTypeChange, isSingleSelect }: KeyKeyboardInputProps) {
    const SMALL_KEYS_KEYBOARD = keyType === 'flats' ? SMALL_FLAT_KEYS_KEYBOARD : SMALL_SHARP_KEYS_KEYBOARD;

    const toggleKeyboardKey = useCallback(
        (key: string) => {
            if (isSingleSelect) {
                onKeyChange([key], scale);
                return;
            }

            let nextKeys = [...activeKeys].filter(Boolean);

            if (nextKeys.includes(key)) {
                nextKeys = nextKeys.filter((k) => k !== key);
            } else {
                nextKeys.push(key);
            }
            onKeyChange(nextKeys, scale);
        },
        [activeKeys, scale, onKeyChange, isSingleSelect]
    );

    const renderBigKey = useCallback(
        (keyName: string, index: number) => {
            const textProps = {
                className: classNames(styles['key-keyboard-input__key-text'], {
                    [styles['key-keyboard-input__key-text--active']]: activeKeys?.includes(keyName),
                }),
                key: `${keyName}-${index}-kb-text`,
                onClick: () => toggleKeyboardKey(keyName),
            };

            const keyProps = {
                className: classNames(styles['key-keyboard-input__key'], {
                    [styles['key-keyboard-input__key--active']]: activeKeys?.includes(keyName),
                }),
                key: `${keyName}-${index}-kb`,
                onClick: () => toggleKeyboardKey(keyName),
                onKeyUp: (event: KeyboardEvent) => {
                    if (event.key === 'Enter' || event.key === ' ' || event.key === 'Spacebar') {
                        event.preventDefault();
                        toggleKeyboardKey(keyName);
                    }
                },
                tabIndex: 0,
                'aria-label': keyName,
            };

            switch (index) {
                case 0:
                    return (
                        <React.Fragment key={keyProps.key}>
                            <rect y="0.5" width="39" height="128" stroke="#151515" {...keyProps} />
                            <text x="20" y="87" textAnchor="middle" {...textProps}>
                                {keyName}
                            </text>
                        </React.Fragment>
                    );

                case 1:
                    return (
                        <React.Fragment key={keyProps.key}>
                            <rect x="39" y="0.5" width="39" height="128" {...keyProps} stroke="#151515" />
                            <text x="58" y="87" textAnchor="middle" {...textProps}>
                                {keyName}
                            </text>
                        </React.Fragment>
                    );

                case 2:
                    return (
                        <React.Fragment key={keyProps.key}>
                            <rect x="78" y="0.5" width="39" height="128" {...keyProps} stroke="#151515" />
                            <text x="98" y="87" textAnchor="middle" {...textProps}>
                                {keyName}
                            </text>
                        </React.Fragment>
                    );

                case 3:
                    return (
                        <React.Fragment key={keyProps.key}>
                            <rect x="117" y="0.5" width="39" height="128" {...keyProps} stroke="#151515" />
                            <text x="136" y="87" textAnchor="middle" {...textProps}>
                                {keyName}
                            </text>
                        </React.Fragment>
                    );

                case 4:
                    return (
                        <React.Fragment key={keyProps.key}>
                            <rect x="156" y="0.5" width="39" height="128" {...keyProps} stroke="#151515" />
                            <text x="175" y="87" textAnchor="middle" {...textProps}>
                                {keyName}
                            </text>
                        </React.Fragment>
                    );

                case 5:
                    return (
                        <React.Fragment key={keyProps.key}>
                            <rect x="195" y="0.5" width="39" height="128" {...keyProps} stroke="#151515" />
                            <text x="216" y="87" textAnchor="middle" {...textProps}>
                                {keyName}
                            </text>
                        </React.Fragment>
                    );
                case 6:
                    return (
                        <React.Fragment key={keyProps.key}>
                            <rect x="234" y="0.5" width="39" height="128" {...keyProps} stroke="#151515" />
                            <text x="253" y="87" textAnchor="middle" {...textProps}>
                                {keyName}
                            </text>
                        </React.Fragment>
                    );
                default:
                    return null;
            }
        },
        [activeKeys, toggleKeyboardKey]
    );

    const renderSmallKey = useCallback(
        (keyName: string, index: number) => {
            const textProps = {
                className: classNames(styles['key-keyboard-input__small-key-text'], {
                    [styles['key-keyboard-input__small-key-text--active']]: activeKeys.includes(keyName),
                }),
                key: `${keyName}-${index}-small-kb-text`,
                onClick: () => toggleKeyboardKey(keyName),
            };

            const keyProps = {
                className: classNames(styles['key-keyboard-input__small-key'], {
                    [styles['key-keyboard-input__small-key--active']]: activeKeys.includes(keyName),
                }),
                key: `${keyName}-${index}-small-kb`,
                onClick: () => toggleKeyboardKey(keyName),
                onKeyUp: (event: KeyboardEvent) => {
                    if (event.key === 'Enter') {
                        event.preventDefault();
                        toggleKeyboardKey(keyName);
                    }
                },
                tabIndex: 0,
                'aria-label': keyName,
            };

            switch (keyName) {
                case 'Db':
                case 'C#':
                    return (
                        <React.Fragment key={keyProps.key}>
                            <rect x="26.2646" y="0.50293" width="24" height="64" {...keyProps} stroke="#151515" />
                            <text x="37" y="35" textAnchor="middle" {...textProps}>
                                {keyName}
                            </text>
                        </React.Fragment>
                    );

                case 'Eb':
                case 'D#':
                    return (
                        <React.Fragment key={keyProps.key}>
                            <rect x="65" y="0.50293" width="24" height="64" {...keyProps} stroke="#151515" />
                            <text x="77" y="35" textAnchor="middle" {...textProps}>
                                {keyName}
                            </text>
                        </React.Fragment>
                    );

                case 'Ab':
                case 'G#':
                    return (
                        <React.Fragment key={keyProps.key}>
                            <rect x="181" y="0.500977" width="24" height="64" {...keyProps} stroke="#151515" />
                            <text x="192" y="35" textAnchor="middle" {...textProps}>
                                {keyName}
                            </text>
                        </React.Fragment>
                    );

                case 'Bb':
                case 'A#':
                    return (
                        <React.Fragment key={keyProps.key}>
                            <rect x="220" y="0.500977" width="24" height="64" {...keyProps} stroke="#151515" />
                            <text x="232" y="35" textAnchor="middle" {...textProps}>
                                {keyName}
                            </text>
                        </React.Fragment>
                    );
                case 'Gb':
                case 'F#':
                    return (
                        <React.Fragment key={keyProps.key}>
                            <rect x="142" y="0.500977" width="24" height="64" {...keyProps} stroke="#151515" />
                            <text x="153" y="35" textAnchor="middle" {...textProps}>
                                {keyName}
                            </text>
                        </React.Fragment>
                    );

                default:
                    return null;
            }
        },
        [activeKeys, toggleKeyboardKey]
    );

    const handleKeyTypeChange = useCallback(
        (value: KeyboardKeyType) => {
            const oppositeSmallKeys = value === 'flats' ? SMALL_SHARP_KEYS_KEYBOARD : SMALL_FLAT_KEYS_KEYBOARD;

            const currentKeyboardSmallKeys = value === 'flats' ? SMALL_FLAT_KEYS_KEYBOARD : SMALL_SHARP_KEYS_KEYBOARD;

            const newKeys = activeKeys.map((key) => {
                const index = oppositeSmallKeys.indexOf(key);
                if (index >= 0) {
                    return currentKeyboardSmallKeys[index];
                }

                return key;
            });

            onKeyChange(newKeys, scale);
            onKeyTypeChange(value);
        },
        [activeKeys, onKeyChange, onKeyTypeChange, scale]
    );

    return (
        <div className={styles['key-keyboard-input']}>
            <div className={styles['key-keyboard-input__types']}>
                <div className={styles['key-keyboard-input__types-inner']}>
                    {keyTypes.map((type) => (
                        <button
                            className={classNames(styles['key-keyboard-input__type'], {
                                [styles['key-keyboard-input__type--active']]: keyType === type.value,
                            })}
                            key={type.value}
                            type="button"
                            onClick={() => handleKeyTypeChange(type.value)}
                            aria-label={`Change key type to ${type.label}`}
                        >
                            {type.label}
                        </button>
                    ))}
                </div>
                <div className={styles['key-keyboard-input__types-inner']}>
                    {scales.map((type) => (
                        <button
                            className={classNames(styles['key-keyboard-input__type'], {
                                [styles['key-keyboard-input__type--active']]: scale === type.value,
                            })}
                            key={type.value}
                            type="button"
                            onClick={() => onScaleChange(type.value)}
                            aria-label={`Change Scale to ${type.label}`}
                        >
                            {type.label}
                        </button>
                    ))}
                </div>
            </div>
            <svg className={styles['key-keyboard-input__svg']} viewBox="0 0 273 129">
                {BIGGER_KEYS_KEYBOARD.map((keyName, index) => renderBigKey(keyName, index))}
                {SMALL_KEYS_KEYBOARD.map((keyName, index) => renderSmallKey(keyName, index + BIGGER_KEYS_KEYBOARD.length))}
            </svg>
        </div>
    );
}
