import { ChangeEventHandler, ReactElement, useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import Dropdown from '@bpm-web-app/components/src/lib/dropdown/dropdown';
import { capitalizeFirstLetter } from '@bpm-web-app/utils';
import classNames from 'classnames';
import styles from './artist-application-inputfield.module.css';
import { ReactComponent as LinkIcon } from '../../../../../assets/icons/artist-platform/link.svg';
import { ApplicationErrorText } from '../artist-application-text/artist-application-text';

interface ArtistApplicationInputFieldProps {
    placeholder?: string;
    text?: string;
    setText?: (txt: string) => void;
    multiline?: boolean;
    inputProps?: FieldValues;
    isLink?: boolean;
    rightIcon?: ReactElement;
    dropdown?: string[];
    errorMessage?: string;
    displayErrorText?: boolean;
    onEnterPress?: () => void;
}

export function ArtistApplicationInputField({
    placeholder,
    text,
    setText,
    multiline = false,
    inputProps,
    isLink = false,
    rightIcon,
    dropdown,
    errorMessage,
    displayErrorText = true,
    onEnterPress,
}: ArtistApplicationInputFieldProps) {
    const DEFAULT_DROPDOWN_CATEGORY = {
        label: placeholder,
        value: '',
    };

    const [dropdownText, setDropdownText] = useState(dropdown && text !== undefined ? text : '');
    const [selectedOption, setSelectedOption] = useState('');

    const [hasErrors, setHasErrors] = useState(false);

    useEffect(() => {
        setHasErrors(!!errorMessage);
    }, [errorMessage]);

    return (
        <>
            <div
                className={classNames(styles['application-inputfield'], { [styles['application-inputfield--has-errors']]: hasErrors })}
                id={placeholder}
                key={placeholder}
                // eslint-disable-next-line @typescript-eslint/dot-notation
                aria-disabled={!!inputProps?.['disabled']}>
                {isLink ?
                    <LinkIcon className={styles['application-inputfield--left-icon']} height={24} width={24} />
                    : null
                }
                {dropdown ?
                    <div className={styles['application-inputfield--dropdown-container']}>
                        <Dropdown<string>
                            onClick={(option) => {
                                if (hasErrors) setHasErrors(false);
                                setText(option);
                                setDropdownText(option);
                                setSelectedOption(option);
                            }}
                            containerClassname={classNames(styles['application-inputfield--dropdown'], {
                                [styles['application-inputfield--dropdown--placeholder']]: dropdownText === DEFAULT_DROPDOWN_CATEGORY.value
                            })}
                            optionContainerClassname={styles['application-inputfield--dropdown-options']}
                            placeHolder={placeholder}
                            layoutType="text-input"
                            value={dropdownText}
                            inputValue={dropdownText}
                            setInputValue={(textChange) => {
                                setDropdownText(textChange);
                            }}
                            onInputBlur={() => {
                                setDropdownText(selectedOption);
                            }}
                            options={[
                                ...dropdown.map((p) => ({
                                    label: capitalizeFirstLetter(p),
                                    value: p
                                }))
                            ]}
                        />
                    </div>
                    : multiline ?
                        <textarea
                            placeholder={placeholder}
                            className={styles['application-inputfield--multiline']}
                            value={text}
                            {...inputProps}
                            onChange={(e) => {
                                if (e.target.value.length > 0 && hasErrors) setHasErrors(false);
                                if (e.target.value.length === 0 && errorMessage) setHasErrors(true);

                                if (inputProps && 'onChange' in inputProps) {
                                    (inputProps as { onChange: ChangeEventHandler<HTMLTextAreaElement> }).onChange(e);
                                }
                                if (setText) {
                                    setText(e.target.value);
                                }
                            }}
                        />
                        :
                        text !== undefined ?
                            <input
                                placeholder={placeholder}
                                className={styles['application-inputfield--text']}
                                value={text}
                                onChange={(e) => setText ? setText(e.target.value) : null}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter' && onEnterPress) {
                                        onEnterPress();
                                    }
                                }}
                            />
                            :
                            <input
                                placeholder={placeholder}
                                className={styles['application-inputfield--text']}
                                {...inputProps}
                                onChange={(e) => {
                                    if (e.target.value.length > 0 && hasErrors) setHasErrors(false);
                                    if (e.target.value.length === 0 && errorMessage) setHasErrors(true);

                                    if (inputProps && 'onChange' in inputProps) {
                                        (inputProps as { onChange: ChangeEventHandler<HTMLInputElement> }).onChange(e);
                                    }
                                    if (setText) {
                                        setText(e.target.value);
                                    }
                                }}
                            />

                }
                {rightIcon ?
                    <div className={styles['application-inputfield--right-icon']}>
                        {rightIcon}
                    </div>
                    : null
                }
            </div>
            {hasErrors && displayErrorText !== false ?
                <ApplicationErrorText text={errorMessage} />
                : null}
        </>
    );
}
