import { useLibraryTabs } from '@bpm-web-app/components';
import { PageNotFound } from '@bpm-web-app/components/src/lib/pages';
import { TrackListSupreme } from '@bpm-web-app/components/src/lib/shared/track-list/supreme/track-list-supreme';
import { useDownloadAlbumById, useMedia, useSimilarAlbum, useSimilarMedia } from '@bpm-web-app/swr-hooks';
import { useHubSwitch } from '@bpm-web-app/utils';
import { useMemo } from 'react';
import { TrackListPresetSupreme } from '@bpm-web-app/components/src/lib/shared/track-list/supreme/track-list-supreme-helpers';
import SimilarTracksIcon from '../../../../../assets/icons/find-similar.svg';
import { EmptyState } from '../empty-state/empty-state';
import { ListLoading } from '../list-loading/lists-loading';
import { ListWrapper } from '../list-wrapper/list-wrapper';

interface SimilarMediaProps {
    mediaId: number;
}

export const SimilarMedia = ({ mediaId }: SimilarMediaProps) => {
    const { isDownload } = useHubSwitch();
    const { libraryProperty } = useLibraryTabs();
    const { isLoading: isLoadingMedia, error: mediaError } = useMedia(mediaId, isDownload);
    const { isLoading: isLoadingAlbum, error: albumError } = useDownloadAlbumById(mediaId, isDownload);

    const { data: similarMedia, isLoading: isLoadingSimilarMedia, error: similarMediaError } = useSimilarMedia(mediaId, !isDownload);

    const { data: similarAlbums, isLoading: isLoadingSimilarAlbum, error: similarAlbumError, mutate: mutateSimilarAlbums } = useSimilarAlbum(mediaId, libraryProperty, isDownload);

    const error = useMemo(() => (isDownload ? albumError || similarAlbumError : mediaError || similarMediaError), [albumError, isDownload, mediaError, similarAlbumError, similarMediaError]);
    const isLoading = useMemo(
        () => (isDownload ? isLoadingAlbum || isLoadingSimilarAlbum : isLoadingMedia || isLoadingSimilarMedia),
        [isDownload, isLoadingAlbum, isLoadingMedia, isLoadingSimilarAlbum, isLoadingSimilarMedia]
    );

    const similarMediaDetail = isDownload ? similarAlbums?.data : similarMedia?.data;

    if (mediaId === undefined || error) return <PageNotFound />;

    if (isLoading) {
        return <ListLoading />;
    }

    if (similarMediaDetail?.length === 0) {
        return <EmptyState title="The track has no similar tracks." icon={<SimilarTracksIcon />} />;
    }

    return (
        <ListWrapper>
            <TrackListSupreme
                forMaxiPlayer
                list={similarMediaDetail}
                preset={isDownload ? TrackListPresetSupreme.DownloadMaxi : TrackListPresetSupreme.StreamMaxi}
                isSortable={false}
                isDraggable={false}
                onDownloadRevalidate={(downloadMedia) => {
                    if (downloadMedia) {
                        // eslint-disable-next-line no-param-reassign
                        downloadMedia.download_count += 1;
                        mutateSimilarAlbums(similarAlbums, { revalidate: false });
                    }
                }}
            />
        </ListWrapper>
    );
};
