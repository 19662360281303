/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * BPM Download
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.5
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from 'url';
import * as isomorphicFetch from 'isomorphic-fetch';
import { Configuration } from './configuration';

const BASE_PATH = 'https://api.develop.download.bpmsupreme.com/v1'.replace(/\/+$/, '');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ',',
    ssv: ' ',
    tsv: '\t',
    pipes: '|',
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = isomorphicFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: 'RequiredError'
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface Album
 */
export interface Album {
    /**
     * Unique Entity Id
     * @type {number}
     * @memberof Album
     */
    id: number;
    /**
     * Entity creation date
     * @type {string}
     * @memberof Album
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof Album
     */
    updated_at: string;
    /**
     * Combined artist string of the album. Please see artists for detailed information
     * @type {string}
     * @memberof Album
     */
    artist: string;
    /**
     * Title of the album
     * @type {string}
     * @memberof Album
     */
    title: string;
    /**
     * Subtitle of the album
     * @type {string}
     * @memberof Album
     */
    sub_title?: string;
    /**
     * Indicates if this Album is remixed
     * @type {boolean}
     * @memberof Album
     */
    is_remix: boolean;
    /**
     * If remix, referrs to the remixed album
     * @type {number}
     * @memberof Album
     */
    remix_of_id?: number;
    /**
     * Set to amount of remixes available. Null if unknown
     * @type {number}
     * @memberof Album
     */
    remixes_available?: number;
    /**
     * BPM of the album
     * @type {number}
     * @memberof Album
     */
    bpm?: number;
    /**
     * Key of the album
     * @type {string}
     * @memberof Album
     */
    key?: string;
    /**
     * Display Key of the track, set depending on request param 'keyType'
     * @type {string}
     * @memberof Album
     */
    display_key?: string;
    /**
     * Original release date
     * @type {string}
     * @memberof Album
     */
    release_date: string;
    /**
     * Highlight date
     * @type {string}
     * @memberof Album
     */
    highlighted_date?: string;
    /**
     * Cover (Artwork) URL of the album
     * @type {string}
     * @memberof Album
     */
    cover_url: string;
    /**
     * Cover Main Color
     * @type {string}
     * @memberof Album
     */
    cover_color?: string;
    /**
     * Full name of album
     * @type {string}
     * @memberof Album
     */
    name: string;
    /**
     * Tracks in this album
     * @type {Array<Media>}
     * @memberof Album
     */
    media: Array<Media>;
    /**
     * 
     * @type {Category}
     * @memberof Album
     */
    category: Category;
    /**
     * 
     * @type {Genre}
     * @memberof Album
     */
    genre: Genre;
    /**
     * Artists of this album
     * @type {Array<Artist>}
     * @memberof Album
     */
    artists: Array<Artist>;
    /**
     * 
     * @type {Artist}
     * @memberof Album
     */
    artist_remixer?: Artist;
    /**
     * Libraries this album is available in
     * @type {Array<Library>}
     * @memberof Album
     */
    libraries: Array<Library>;
    /**
     * Daily Trending status. Negative number if status went down, positive number if status went up
     * @type {number}
     * @memberof Album
     */
    trending_status_daily: number;
    /**
     * Weekly Trending status. Negative number if status went down, positive number if status went up
     * @type {number}
     * @memberof Album
     */
    trending_status_weekly: number;
    /**
     * Monthly Trending status. Negative number if status went down, positive number if status went up
     * @type {number}
     * @memberof Album
     */
    trending_status_monthly: number;
    /**
     * Set if the album is a exclusive album
     * @type {boolean}
     * @memberof Album
     */
    is_exclusive: boolean;
    /**
     * Internal version number of track
     * @type {number}
     * @memberof Album
     */
    bpm_api_version: number;
    /**
     * If current album is restricted based on current location
     * @type {boolean}
     * @memberof Album
     */
    is_restricted: boolean;
    /**
     * Set if this track is limited to only premium users
     * @type {boolean}
     * @memberof Album
     */
    is_premium_only?: boolean;
    /**
     * Set if this track is limited to only premium users and current user has access
     * @type {boolean}
     * @memberof Album
     */
    has_premium_only_access?: boolean;
    /**
     * Only set on collections!
     * @type {string}
     * @memberof Album
     */
    added_at?: string;
    /**
     * The invite id, set only for user playlists
     * @type {string}
     * @memberof Album
     */
    added_by_key?: string;
    /**
     * The badge to show on the album
     * @type {string}
     * @memberof Album
     */
    badge?: Album.BadgeEnum;
}

/**
 * @export
 * @namespace Album
 */
export namespace Album {
    /**
     * @export
     * @enum {string}
     */
    export enum BadgeEnum {
        Hot = <any> 'hot',
        Top = <any> 'top',
        Trending = <any> 'trending',
        Exclusive = <any> 'exclusive',
        Classic = <any> 'classic'
    }
}
/**
 * 
 * @export
 * @interface AlbumAlbumIdBody
 */
export interface AlbumAlbumIdBody {
    /**
     * The 1 based order of the track
     * @type {number}
     * @memberof AlbumAlbumIdBody
     */
    order?: number;
}
/**
 * 
 * @export
 * @interface AlbumIdReportBody
 */
export interface AlbumIdReportBody {
    /**
     * 
     * @type {string}
     * @memberof AlbumIdReportBody
     */
    message: string;
}
/**
 * 
 * @export
 * @interface AlbumWithAnalytics
 */
export interface AlbumWithAnalytics extends Album {
    /**
     * 
     * @type {AlbumWithAnalyticsAnalyticsInfo}
     * @memberof AlbumWithAnalytics
     */
    analytics_info: AlbumWithAnalyticsAnalyticsInfo;
}

/**
 * @export
 * @namespace AlbumWithAnalytics
 */
export namespace AlbumWithAnalytics {
}
/**
 * 
 * @export
 * @interface AlbumWithAnalyticsAnalyticsInfo
 */
export interface AlbumWithAnalyticsAnalyticsInfo {
    /**
     * 
     * @type {number}
     * @memberof AlbumWithAnalyticsAnalyticsInfo
     */
    previews?: number;
    /**
     * 
     * @type {number}
     * @memberof AlbumWithAnalyticsAnalyticsInfo
     */
    downloads?: number;
    /**
     * 
     * @type {number}
     * @memberof AlbumWithAnalyticsAnalyticsInfo
     */
    impressions?: number;
    /**
     * 
     * @type {number}
     * @memberof AlbumWithAnalyticsAnalyticsInfo
     */
    est_reach?: number;
}
/**
 * 
 * @export
 * @interface Artist
 */
export interface Artist {
    /**
     * Unique Entity Id
     * @type {number}
     * @memberof Artist
     */
    id: number;
    /**
     * Entity creation date
     * @type {string}
     * @memberof Artist
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof Artist
     */
    updated_at: string;
    /**
     * Artist name
     * @type {string}
     * @memberof Artist
     */
    name: string;
    /**
     * Artist bio
     * @type {string}
     * @memberof Artist
     */
    description?: string;
    /**
     * Artist slug
     * @type {string}
     * @memberof Artist
     */
    slug: string;
    /**
     * Artist social media links
     * @type {Array<string>}
     * @memberof Artist
     */
    social_media?: Array<string>;
    /**
     * Artist sequence number in display artist name. Only returned when assocaiated to media or album.
     * @type {number}
     * @memberof Artist
     */
    sequence_number?: number;
    /**
     * Artist banner URL
     * @type {string}
     * @memberof Artist
     */
    banner_url?: string;
    /**
     * Artist artwork URL
     * @type {string}
     * @memberof Artist
     */
    artwork_url?: string;
    /**
     * Artist artwork credit
     * @type {string}
     * @memberof Artist
     */
    artwork_credit?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ArtistAlbumsSortByQueryOptions {
    ReleaseDate = <any> 'release_date',
    Impressions = <any> 'impressions',
    Downloads = <any> 'downloads',
    Previews = <any> 'previews'
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ArtistApplicationDataStatus {
    NotAvailable = <any> 'not-available',
    Pending = <any> 'pending',
    Available = <any> 'available'
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ArtistApplicationStatus {
    Pending = <any> 'pending',
    Approved = <any> 'approved',
    Denied = <any> 'denied',
    NotSubmitted = <any> 'not-submitted'
}
/**
 * 
 * @export
 * @interface ArtistApplicationSubmit
 */
export interface ArtistApplicationSubmit {
    /**
     * 
     * @type {Array<string>}
     * @memberof ArtistApplicationSubmit
     */
    links?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArtistApplicationSubmit
     */
    social_links?: Array<string>;
    /**
     * Artist name
     * @type {string}
     * @memberof ArtistApplicationSubmit
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ArtistApplicationSubmit
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof ArtistApplicationSubmit
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof ArtistApplicationSubmit
     */
    full_name: string;
    /**
     * 
     * @type {string}
     * @memberof ArtistApplicationSubmit
     */
    bio?: string;
    /**
     * The artists profile photo
     * @type {Blob}
     * @memberof ArtistApplicationSubmit
     */
    artwork: Blob;
    /**
     * The artists press photo
     * @type {Blob}
     * @memberof ArtistApplicationSubmit
     */
    press?: Blob;
    /**
     * 
     * @type {ArtistTrackSubmit}
     * @memberof ArtistApplicationSubmit
     */
    track_submit: ArtistTrackSubmit;
}
/**
 * 
 * @export
 * @interface ArtistBanner
 */
export interface ArtistBanner {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof ArtistBanner
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof ArtistBanner
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof ArtistBanner
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof ArtistBanner
     */
    link: string;
    /**
     * 
     * @type {string}
     * @memberof ArtistBanner
     */
    link_text: string;
    /**
     * 
     * @type {string}
     * @memberof ArtistBanner
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ArtistBanner
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof ArtistBanner
     */
    position: number;
}
/**
 * 
 * @export
 * @interface ArtistProfileChangeRequest
 */
export interface ArtistProfileChangeRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ArtistProfileChangeRequest
     */
    social_media?: Array<string>;
    /**
     * Artist name
     * @type {string}
     * @memberof ArtistProfileChangeRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ArtistProfileChangeRequest
     */
    description?: string;
    /**
     * The artists profile photo
     * @type {Blob}
     * @memberof ArtistProfileChangeRequest
     */
    artwork?: Blob;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ArtistSortByQueryOptions {
    Impressions = <any> 'impressions',
    Downloads = <any> 'downloads',
    Previews = <any> 'previews'
}
/**
 * 
 * @export
 * @interface ArtistTrackSubmit
 */
export interface ArtistTrackSubmit {
    /**
     * Track name
     * @type {string}
     * @memberof ArtistTrackSubmit
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ArtistTrackSubmit
     */
    artist_name: string;
    /**
     * 
     * @type {string}
     * @memberof ArtistTrackSubmit
     */
    release_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ArtistTrackSubmit
     */
    genre: string;
    /**
     * 
     * @type {string}
     * @memberof ArtistTrackSubmit
     */
    isrc?: string;
    /**
     * 
     * @type {string}
     * @memberof ArtistTrackSubmit
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof ArtistTrackSubmit
     */
    produced_by?: string;
    /**
     * The track artwork
     * @type {Blob}
     * @memberof ArtistTrackSubmit
     */
    track_artwork: Blob;
    /**
     * 
     * @type {Array<ArtistTrackSubmitTracks>}
     * @memberof ArtistTrackSubmit
     */
    tracks: Array<ArtistTrackSubmitTracks>;
}
/**
 * 
 * @export
 * @interface ArtistTrackSubmitTracks
 */
export interface ArtistTrackSubmitTracks {
    /**
     * 
     * @type {string}
     * @memberof ArtistTrackSubmitTracks
     */
    track_path: string;
    /**
     * 
     * @type {string}
     * @memberof ArtistTrackSubmitTracks
     */
    version_name: string;
}
/**
 * 
 * @export
 * @interface ArtistWithAlbumCount
 */
export interface ArtistWithAlbumCount {
    /**
     * Unique Entity Id
     * @type {number}
     * @memberof ArtistWithAlbumCount
     */
    id: number;
    /**
     * Entity creation date
     * @type {string}
     * @memberof ArtistWithAlbumCount
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof ArtistWithAlbumCount
     */
    updated_at: string;
    /**
     * Artist name
     * @type {string}
     * @memberof ArtistWithAlbumCount
     */
    name: string;
    /**
     * Artist bio
     * @type {string}
     * @memberof ArtistWithAlbumCount
     */
    description?: string;
    /**
     * Artist slug
     * @type {string}
     * @memberof ArtistWithAlbumCount
     */
    slug: string;
    /**
     * Artist social media links
     * @type {Array<string>}
     * @memberof ArtistWithAlbumCount
     */
    social_media?: Array<string>;
    /**
     * Artist sequence number in display artist name
     * @type {number}
     * @memberof ArtistWithAlbumCount
     */
    sequence_number: number;
    /**
     * Artist banner URL
     * @type {string}
     * @memberof ArtistWithAlbumCount
     */
    banner_url?: string;
    /**
     * Artist artwork URL
     * @type {string}
     * @memberof ArtistWithAlbumCount
     */
    artwork_url?: string;
    /**
     * Amount of albums this artist has
     * @type {number}
     * @memberof ArtistWithAlbumCount
     */
    album_count: number;
}
/**
 * 
 * @export
 * @interface Banner
 */
export interface Banner {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof Banner
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof Banner
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof Banner
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    location: string;
    /**
     * 
     * @type {Array<BannerImage>}
     * @memberof Banner
     */
    images: Array<BannerImage>;
}
/**
 * 
 * @export
 * @interface BannerImage
 */
export interface BannerImage {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof BannerImage
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof BannerImage
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof BannerImage
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof BannerImage
     */
    image_url_large?: string;
    /**
     * 
     * @type {string}
     * @memberof BannerImage
     */
    image_url_small?: string;
    /**
     * 
     * @type {string}
     * @memberof BannerImage
     */
    video_url_large?: string;
    /**
     * 
     * @type {string}
     * @memberof BannerImage
     */
    video_url_small?: string;
    /**
     * 
     * @type {string}
     * @memberof BannerImage
     */
    link?: string;
    /**
     * 
     * @type {string}
     * @memberof BannerImage
     */
    background_color?: string;
    /**
     * 
     * @type {number}
     * @memberof BannerImage
     */
    position: number;
    /**
     * 
     * @type {string}
     * @memberof BannerImage
     */
    banner_id: string;
}
/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * Unique Entity Id
     * @type {number}
     * @memberof Category
     */
    id: number;
    /**
     * Entity creation date
     * @type {string}
     * @memberof Category
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof Category
     */
    updated_at: string;
    /**
     * Category name
     * @type {string}
     * @memberof Category
     */
    name: string;
    /**
     * Category slug
     * @type {string}
     * @memberof Category
     */
    slug: string;
    /**
     * The category icon, as svg
     * @type {string}
     * @memberof Category
     */
    icon_svg?: string;
    /**
     * Order of the Category. Ascending.
     * @type {number}
     * @memberof Category
     */
    order: number;
    /**
     * 
     * @type {Array<Genre>}
     * @memberof Category
     */
    genres?: Array<Genre>;
}
/**
 * 
 * @export
 * @interface CuratedSet
 */
export interface CuratedSet {
    /**
     * Unique Entity Id
     * @type {number}
     * @memberof CuratedSet
     */
    id: number;
    /**
     * Entity creation date
     * @type {string}
     * @memberof CuratedSet
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof CuratedSet
     */
    updated_at: string;
    /**
     * Title of the curated set
     * @type {string}
     * @memberof CuratedSet
     */
    title: string;
    /**
     * Slug of the curated set
     * @type {string}
     * @memberof CuratedSet
     */
    slug: string;
    /**
     * Curator of the curated set
     * @type {string}
     * @memberof CuratedSet
     */
    curator: string;
    /**
     * Order of the curated set within the Category. Ascending.
     * @type {number}
     * @memberof CuratedSet
     */
    order: number;
    /**
     * Cover (Artwork) URL of the curated set
     * @type {string}
     * @memberof CuratedSet
     */
    image_url?: string;
    /**
     * The category id
     * @type {number}
     * @memberof CuratedSet
     */
    category_id: number;
    /**
     * 
     * @type {CuratedSetCategory}
     * @memberof CuratedSet
     */
    category: CuratedSetCategory;
    /**
     * 
     * @type {Library}
     * @memberof CuratedSet
     */
    library: Library;
    /**
     * Artists of the curated set (first 6)
     * @type {Array<Artist>}
     * @memberof CuratedSet
     */
    artists?: Array<Artist>;
    /**
     * Artist string of the curated set (first 6). Please see artists for further information
     * @type {string}
     * @memberof CuratedSet
     */
    artist: string;
    /**
     * Optional youtube URL
     * @type {string}
     * @memberof CuratedSet
     */
    youtube_url?: string;
    /**
     * Optional description
     * @type {string}
     * @memberof CuratedSet
     */
    description?: string;
    /**
     * Weekly Trending status. Negative number if status went down, positive number if status went up
     * @type {number}
     * @memberof CuratedSet
     */
    trending_status_weekly: number;
}
/**
 * 
 * @export
 * @interface CuratedSetCategory
 */
export interface CuratedSetCategory {
    /**
     * Unique Entity Id
     * @type {number}
     * @memberof CuratedSetCategory
     */
    id: number;
    /**
     * Entity creation date
     * @type {string}
     * @memberof CuratedSetCategory
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof CuratedSetCategory
     */
    updated_at: string;
    /**
     * Curated Set Category name
     * @type {string}
     * @memberof CuratedSetCategory
     */
    name: string;
    /**
     * Curated Set Category slug
     * @type {string}
     * @memberof CuratedSetCategory
     */
    slug: string;
    /**
     * Order of the Curated Set Category. Ascending.
     * @type {number}
     * @memberof CuratedSetCategory
     */
    order: number;
    /**
     * The amount of curated sets
     * @type {number}
     * @memberof CuratedSetCategory
     */
    set_count: number;
}
/**
 * 
 * @export
 * @interface CuratedSetCategoryWithPlaylist
 */
export interface CuratedSetCategoryWithPlaylist extends CuratedSetCategory {
    /**
     * playlists
     * @type {Array<CuratedSet>}
     * @memberof CuratedSetCategoryWithPlaylist
     */
    sets?: Array<CuratedSet>;
}
/**
 * 
 * @export
 * @interface CuratedSetWithAlbum
 */
export interface CuratedSetWithAlbum extends CuratedSet {
    /**
     * Albums in this curated set
     * @type {Array<Album>}
     * @memberof CuratedSetWithAlbum
     */
    albums?: Array<Album>;
}
/**
 * 
 * @export
 * @interface DownloadCrateResponse
 */
export interface DownloadCrateResponse {
    /**
     * 
     * @type {string}
     * @memberof DownloadCrateResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadCrateResponse
     */
    url: string;
    /**
     * The endpoint will still generate a downloadable zip, even if errors occured. Those errors will be listed here.
     * @type {Array<DownloadCrateResponseErrors>}
     * @memberof DownloadCrateResponse
     */
    errors: Array<DownloadCrateResponseErrors>;
}
/**
 * 
 * @export
 * @interface DownloadCrateResponseErrors
 */
export interface DownloadCrateResponseErrors {
    /**
     * 
     * @type {number}
     * @memberof DownloadCrateResponseErrors
     */
    media_id: number;
    /**
     * 
     * @type {string}
     * @memberof DownloadCrateResponseErrors
     */
    media_name: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadCrateResponseErrors
     */
    type: DownloadCrateResponseErrors.TypeEnum;
}

/**
 * @export
 * @namespace DownloadCrateResponseErrors
 */
export namespace DownloadCrateResponseErrors {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        DownloadLimit = <any> 'download-limit',
        LibraryNotAllowed = <any> 'library-not-allowed',
        TerritoryNotAllowed = <any> 'territory-not-allowed',
        PremiumOnly = <any> 'premium-only'
    }
}
/**
 * 
 * @export
 * @interface DownloadLimitReachedError
 */
export interface DownloadLimitReachedError {
}
/**
 * 
 * @export
 * @interface FavoriteBatchBody
 */
export interface FavoriteBatchBody {
    /**
     * 
     * @type {Array<number>}
     * @memberof FavoriteBatchBody
     */
    album_ids: Array<number>;
}
/**
 * 
 * @export
 * @interface Genre
 */
export interface Genre {
    /**
     * Unique Entity Id
     * @type {number}
     * @memberof Genre
     */
    id: number;
    /**
     * Entity creation date
     * @type {string}
     * @memberof Genre
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof Genre
     */
    updated_at: string;
    /**
     * Genre name
     * @type {string}
     * @memberof Genre
     */
    name: string;
    /**
     * Genre slug
     * @type {string}
     * @memberof Genre
     */
    slug: string;
    /**
     * Genre color
     * @type {string}
     * @memberof Genre
     */
    color?: string;
    /**
     * For search endpoint, the number of available results
     * @type {number}
     * @memberof Genre
     */
    available_count?: number;
}
/**
 * 
 * @export
 * @interface IdBatchBody
 */
export interface IdBatchBody {
    /**
     * 
     * @type {Array<UserplaylistsidbatchAlbums>}
     * @memberof IdBatchBody
     */
    albums: Array<UserplaylistsidbatchAlbums>;
}
/**
 * 
 * @export
 * @interface IdBatchBody1
 */
export interface IdBatchBody1 {
    /**
     * 
     * @type {Array<number>}
     * @memberof IdBatchBody1
     */
    album_ids: Array<number>;
}
/**
 * 
 * @export
 * @interface IdImageBody
 */
export interface IdImageBody {
    /**
     * The Image file
     * @type {Blob}
     * @memberof IdImageBody
     */
    file?: Blob;
}
/**
 * 
 * @export
 * @interface IdInviteBody
 */
export interface IdInviteBody {
    /**
     * The users email
     * @type {string}
     * @memberof IdInviteBody
     */
    email: string;
    /**
     * 
     * @type {UserPlaylistCollaborationAccessLevel}
     * @memberof IdInviteBody
     */
    access_level: UserPlaylistCollaborationAccessLevel;
}
/**
 * 
 * @export
 * @interface IdPlayBody
 */
export interface IdPlayBody {
    /**
     * The duration in seconds, for example 20.45
     * @type {number}
     * @memberof IdPlayBody
     */
    duration: number;
    /**
     * The current location on site, like '/new-releases'
     * @type {string}
     * @memberof IdPlayBody
     */
    from_location: string;
    /**
     * The library selected at the time of stream. Not a filter!
     * @type {string}
     * @memberof IdPlayBody
     */
    from_library?: IdPlayBody.FromLibraryEnum;
}

/**
 * @export
 * @namespace IdPlayBody
 */
export namespace IdPlayBody {
    /**
     * @export
     * @enum {string}
     */
    export enum FromLibraryEnum {
        Music = <any> 'download:music',
        Artist = <any> 'download:artist',
        Latin = <any> 'download:latin'
    }
}
/**
 * 
 * @export
 * @interface IdUserplaylistsBody
 */
export interface IdUserplaylistsBody {
    /**
     * 
     * @type {string}
     * @memberof IdUserplaylistsBody
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof IdUserplaylistsBody
     */
    slug?: string;
    /**
     * 
     * @type {number}
     * @memberof IdUserplaylistsBody
     */
    order?: number;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {Array<Album>}
     * @memberof InlineResponse200
     */
    data: Array<Album>;
    /**
     * 
     * @type {Pagination}
     * @memberof InlineResponse200
     */
    pagination: Pagination;
}
/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {InlineResponse2001Data}
     * @memberof InlineResponse2001
     */
    data: InlineResponse2001Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20010
 */
export interface InlineResponse20010 {
    /**
     * 
     * @type {CuratedSetWithAlbum}
     * @memberof InlineResponse20010
     */
    data: CuratedSetWithAlbum;
}
/**
 * 
 * @export
 * @interface InlineResponse20011
 */
export interface InlineResponse20011 {
    /**
     * 
     * @type {Array<CuratedSet>}
     * @memberof InlineResponse20011
     */
    data: Array<CuratedSet>;
    /**
     * 
     * @type {Pagination}
     * @memberof InlineResponse20011
     */
    pagination: Pagination;
}
/**
 * 
 * @export
 * @interface InlineResponse20012
 */
export interface InlineResponse20012 {
    /**
     * 
     * @type {Array<CuratedSet>}
     * @memberof InlineResponse20012
     */
    data: Array<CuratedSet>;
}
/**
 * 
 * @export
 * @interface InlineResponse20013
 */
export interface InlineResponse20013 {
    /**
     * 
     * @type {PlaylistWithAlbum}
     * @memberof InlineResponse20013
     */
    data: PlaylistWithAlbum;
}
/**
 * 
 * @export
 * @interface InlineResponse20014
 */
export interface InlineResponse20014 {
    /**
     * 
     * @type {Array<Playlist>}
     * @memberof InlineResponse20014
     */
    data: Array<Playlist>;
    /**
     * 
     * @type {Pagination}
     * @memberof InlineResponse20014
     */
    pagination: Pagination;
}
/**
 * 
 * @export
 * @interface InlineResponse20015
 */
export interface InlineResponse20015 {
    /**
     * 
     * @type {Array<Playlist>}
     * @memberof InlineResponse20015
     */
    data: Array<Playlist>;
}
/**
 * 
 * @export
 * @interface InlineResponse20016
 */
export interface InlineResponse20016 {
    /**
     * 
     * @type {Array<PlaylistCategory>}
     * @memberof InlineResponse20016
     */
    data: Array<PlaylistCategory>;
}
/**
 * 
 * @export
 * @interface InlineResponse20017
 */
export interface InlineResponse20017 {
    /**
     * 
     * @type {Array<CuratedSetCategory>}
     * @memberof InlineResponse20017
     */
    data: Array<CuratedSetCategory>;
}
/**
 * 
 * @export
 * @interface InlineResponse20018
 */
export interface InlineResponse20018 {
    /**
     * 
     * @type {Array<UserPlaylistCategory>}
     * @memberof InlineResponse20018
     */
    data: Array<UserPlaylistCategory>;
}
/**
 * 
 * @export
 * @interface InlineResponse20019
 */
export interface InlineResponse20019 {
    /**
     * 
     * @type {any}
     * @memberof InlineResponse20019
     */
    data: any;
}
/**
 * 
 * @export
 * @interface InlineResponse2001Data
 */
export interface InlineResponse2001Data {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2001Data
     */
    total: number;
}
/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     * 
     * @type {Album}
     * @memberof InlineResponse2002
     */
    data: Album;
}
/**
 * 
 * @export
 * @interface InlineResponse20020
 */
export interface InlineResponse20020 {
    /**
     * 
     * @type {Array<Genre>}
     * @memberof InlineResponse20020
     */
    data: Array<Genre>;
}
/**
 * 
 * @export
 * @interface InlineResponse20021
 */
export interface InlineResponse20021 {
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineResponse20021
     */
    data: Array<string>;
}
/**
 * 
 * @export
 * @interface InlineResponse20022
 */
export interface InlineResponse20022 {
    /**
     * 
     * @type {Array<Version>}
     * @memberof InlineResponse20022
     */
    data: Array<Version>;
}
/**
 * 
 * @export
 * @interface InlineResponse20023
 */
export interface InlineResponse20023 {
    /**
     * 
     * @type {Array<MediaWithAlbum>}
     * @memberof InlineResponse20023
     */
    data: Array<MediaWithAlbum>;
}
/**
 * 
 * @export
 * @interface InlineResponse20024
 */
export interface InlineResponse20024 {
    /**
     * 
     * @type {InlineResponse20024Data}
     * @memberof InlineResponse20024
     */
    data: InlineResponse20024Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20024Data
 */
export interface InlineResponse20024Data {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20024Data
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse20025
 */
export interface InlineResponse20025 {
    /**
     * 
     * @type {Banner}
     * @memberof InlineResponse20025
     */
    data: Banner;
}
/**
 * 
 * @export
 * @interface InlineResponse20026
 */
export interface InlineResponse20026 {
    /**
     * 
     * @type {Artist}
     * @memberof InlineResponse20026
     */
    data: Artist;
}
/**
 * 
 * @export
 * @interface InlineResponse20027
 */
export interface InlineResponse20027 {
    /**
     * 
     * @type {Array<ArtistWithAlbumCount>}
     * @memberof InlineResponse20027
     */
    data: Array<ArtistWithAlbumCount>;
    /**
     * 
     * @type {Pagination}
     * @memberof InlineResponse20027
     */
    pagination: Pagination;
}
/**
 * 
 * @export
 * @interface InlineResponse20028
 */
export interface InlineResponse20028 {
    /**
     * 
     * @type {Array<ArtistWithAlbumCount>}
     * @memberof InlineResponse20028
     */
    data: Array<ArtistWithAlbumCount>;
}
/**
 * 
 * @export
 * @interface InlineResponse20029
 */
export interface InlineResponse20029 {
    /**
     * 
     * @type {InlineResponse20029Data}
     * @memberof InlineResponse20029
     */
    data: InlineResponse20029Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20029Data
 */
export interface InlineResponse20029Data {
    /**
     * 
     * @type {InlineResponse20029DataTotal}
     * @memberof InlineResponse20029Data
     */
    total: InlineResponse20029DataTotal;
    /**
     * 
     * @type {InlineResponse20029DataTotal}
     * @memberof InlineResponse20029Data
     */
    artist: InlineResponse20029DataTotal;
    /**
     * 
     * @type {InlineResponse20029DataTotal}
     * @memberof InlineResponse20029Data
     */
    playlist: InlineResponse20029DataTotal;
    /**
     * 
     * @type {InlineResponse20029DataTotal}
     * @memberof InlineResponse20029Data
     */
    curated_set: InlineResponse20029DataTotal;
    /**
     * 
     * @type {InlineResponse20029DataTotal}
     * @memberof InlineResponse20029Data
     */
    album: InlineResponse20029DataTotal;
}
/**
 * 
 * @export
 * @interface InlineResponse20029DataTotal
 */
export interface InlineResponse20029DataTotal {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20029DataTotal
     */
    downloadlatin: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20029DataTotal
     */
    downloadmusic: number;
}
/**
 * 
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
    /**
     * 
     * @type {InlineResponse2003Data}
     * @memberof InlineResponse2003
     */
    data: InlineResponse2003Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20030
 */
export interface InlineResponse20030 {
    /**
     * 
     * @type {InlineResponse20030Data}
     * @memberof InlineResponse20030
     */
    data: InlineResponse20030Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20030Data
 */
export interface InlineResponse20030Data {
    /**
     * 
     * @type {Array<Album>}
     * @memberof InlineResponse20030Data
     */
    albums: Array<Album>;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20030Data
     */
    album_total?: number;
    /**
     * 
     * @type {Array<ArtistWithAlbumCount>}
     * @memberof InlineResponse20030Data
     */
    artists: Array<ArtistWithAlbumCount>;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20030Data
     */
    artist_total?: number;
    /**
     * 
     * @type {Array<Playlist>}
     * @memberof InlineResponse20030Data
     */
    playlists: Array<Playlist>;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20030Data
     */
    playlist_total: number;
    /**
     * 
     * @type {Array<CuratedSet>}
     * @memberof InlineResponse20030Data
     */
    curated: Array<CuratedSet>;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20030Data
     */
    curated_total: number;
}
/**
 * 
 * @export
 * @interface InlineResponse20031
 */
export interface InlineResponse20031 {
    /**
     * 
     * @type {UserPlaylistWithAlbum}
     * @memberof InlineResponse20031
     */
    data: UserPlaylistWithAlbum;
}
/**
 * 
 * @export
 * @interface InlineResponse20032
 */
export interface InlineResponse20032 {
    /**
     * 
     * @type {Array<UserPlaylist>}
     * @memberof InlineResponse20032
     */
    data: Array<UserPlaylist>;
    /**
     * 
     * @type {Pagination}
     * @memberof InlineResponse20032
     */
    pagination: Pagination;
}
/**
 * 
 * @export
 * @interface InlineResponse20033
 */
export interface InlineResponse20033 {
    /**
     * 
     * @type {Array<UserPlaylistWithAlbum>}
     * @memberof InlineResponse20033
     */
    data: Array<UserPlaylistWithAlbum>;
    /**
     * 
     * @type {Pagination}
     * @memberof InlineResponse20033
     */
    pagination: Pagination;
}
/**
 * 
 * @export
 * @interface InlineResponse20034
 */
export interface InlineResponse20034 {
    /**
     * 
     * @type {InlineResponse20034Data}
     * @memberof InlineResponse20034
     */
    data: InlineResponse20034Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20034Data
 */
export interface InlineResponse20034Data {
    /**
     * The for you playlists available for the current user
     * @type {Array<UserPlaylist>}
     * @memberof InlineResponse20034Data
     */
    available: Array<UserPlaylist>;
    /**
     * The for you playlists not available yet to the current user
     * @type {Array<InlineResponse20034DataNotAvailable>}
     * @memberof InlineResponse20034Data
     */
    not_available: Array<InlineResponse20034DataNotAvailable>;
}
/**
 * 
 * @export
 * @interface InlineResponse20034DataNotAvailable
 */
export interface InlineResponse20034DataNotAvailable {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20034DataNotAvailable
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20034DataNotAvailable
     */
    image_url: string;
}
/**
 * 
 * @export
 * @interface InlineResponse20035
 */
export interface InlineResponse20035 {
    /**
     * 
     * @type {Array<UserPlaylistWithUser>}
     * @memberof InlineResponse20035
     */
    data: Array<UserPlaylistWithUser>;
}
/**
 * 
 * @export
 * @interface InlineResponse20036
 */
export interface InlineResponse20036 {
    /**
     * 
     * @type {Array<UserPlaylistCollaboration>}
     * @memberof InlineResponse20036
     */
    data: Array<UserPlaylistCollaboration>;
}
/**
 * 
 * @export
 * @interface InlineResponse20037
 */
export interface InlineResponse20037 {
    /**
     * 
     * @type {UserPlaylistCollaboration}
     * @memberof InlineResponse20037
     */
    data: UserPlaylistCollaboration;
}
/**
 * 
 * @export
 * @interface InlineResponse20038
 */
export interface InlineResponse20038 {
    /**
     * 
     * @type {InlineResponse20038Data}
     * @memberof InlineResponse20038
     */
    data: InlineResponse20038Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20038Data
 */
export interface InlineResponse20038Data {
    /**
     * 
     * @type {ArtistApplicationStatus}
     * @memberof InlineResponse20038Data
     */
    status: ArtistApplicationStatus;
}
/**
 * 
 * @export
 * @interface InlineResponse20039
 */
export interface InlineResponse20039 {
    /**
     * 
     * @type {Array<AlbumWithAnalytics>}
     * @memberof InlineResponse20039
     */
    data: Array<AlbumWithAnalytics>;
    /**
     * 
     * @type {Pagination}
     * @memberof InlineResponse20039
     */
    pagination: Pagination;
}
/**
 * 
 * @export
 * @interface InlineResponse2003Data
 */
export interface InlineResponse2003Data {
    /**
     * 
     * @type {Array<CuratedSet>}
     * @memberof InlineResponse2003Data
     */
    curated: Array<CuratedSet>;
    /**
     * 
     * @type {Array<Playlist>}
     * @memberof InlineResponse2003Data
     */
    playlists: Array<Playlist>;
}
/**
 * 
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
    /**
     * 
     * @type {Array<Album>}
     * @memberof InlineResponse2004
     */
    data: Array<Album>;
}
/**
 * 
 * @export
 * @interface InlineResponse20040
 */
export interface InlineResponse20040 {
    /**
     * 
     * @type {Array<InlineResponse20040Data>}
     * @memberof InlineResponse20040
     */
    data: Array<InlineResponse20040Data>;
}
/**
 * 
 * @export
 * @interface InlineResponse20040Data
 */
export interface InlineResponse20040Data {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20040Data
     */
    downloads: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20040Data
     */
    previews: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20040Data
     */
    percentage: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20040Data
     */
    impressions: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20040Data
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20040Data
     */
    city: string;
}
/**
 * 
 * @export
 * @interface InlineResponse20041
 */
export interface InlineResponse20041 {
    /**
     * 
     * @type {Array<InlineResponse20041Data>}
     * @memberof InlineResponse20041
     */
    data: Array<InlineResponse20041Data>;
}
/**
 * 
 * @export
 * @interface InlineResponse20041Data
 */
export interface InlineResponse20041Data {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20041Data
     */
    downloads: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20041Data
     */
    previews: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20041Data
     */
    impressions: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20041Data
     */
    percentage: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20041Data
     */
    country: string;
}
/**
 * 
 * @export
 * @interface InlineResponse20042
 */
export interface InlineResponse20042 {
    /**
     * 
     * @type {Array<InlineResponse20042Data>}
     * @memberof InlineResponse20042
     */
    data: Array<InlineResponse20042Data>;
}
/**
 * 
 * @export
 * @interface InlineResponse20042Data
 */
export interface InlineResponse20042Data {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20042Data
     */
    downloads: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20042Data
     */
    previews: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20042Data
     */
    impressions: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20042Data
     */
    est_reach: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20042Data
     */
    percentage: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20042Data
     */
    dj_type: string;
}
/**
 * 
 * @export
 * @interface InlineResponse20043
 */
export interface InlineResponse20043 {
    /**
     * 
     * @type {Array<InlineResponse20043Data>}
     * @memberof InlineResponse20043
     */
    data: Array<InlineResponse20043Data>;
}
/**
 * 
 * @export
 * @interface InlineResponse20043Data
 */
export interface InlineResponse20043Data {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20043Data
     */
    downloads: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20043Data
     */
    previews: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20043Data
     */
    impressions: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20043Data
     */
    est_reach: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20043Data
     */
    percentage: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20043Data
     */
    from: string;
}
/**
 * 
 * @export
 * @interface InlineResponse20044
 */
export interface InlineResponse20044 {
    /**
     * 
     * @type {Array<Artist>}
     * @memberof InlineResponse20044
     */
    data: Array<Artist>;
}
/**
 * 
 * @export
 * @interface InlineResponse20045
 */
export interface InlineResponse20045 {
    /**
     * 
     * @type {InlineResponse20045Data}
     * @memberof InlineResponse20045
     */
    data: InlineResponse20045Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20045Data
 */
export interface InlineResponse20045Data {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20045Data
     */
    previews_percentage: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20045Data
     */
    previews: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20045Data
     */
    downloads: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20045Data
     */
    downloads_percentage: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20045Data
     */
    est_reach: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20045Data
     */
    est_reach_percentage: number;
}
/**
 * 
 * @export
 * @interface InlineResponse20046
 */
export interface InlineResponse20046 {
    /**
     * 
     * @type {InlineResponse20046Data}
     * @memberof InlineResponse20046
     */
    data: InlineResponse20046Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20046Data
 */
export interface InlineResponse20046Data {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20046Data
     */
    previews_percentage: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20046Data
     */
    previews: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20046Data
     */
    downloads: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20046Data
     */
    downloads_percentage: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20046Data
     */
    est_reach: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20046Data
     */
    est_reach_percentage: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20046Data
     */
    impressions: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20046Data
     */
    impressions_percentage: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20046Data
     */
    link_clicks: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20046Data
     */
    link_clicks_percentage: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20046Data
     */
    views: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20046Data
     */
    views_percentage: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20046Data
     */
    followers: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20046Data
     */
    followers_percentage: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20046Data
     */
    playlists: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20046Data
     */
    playlists_percentage: number;
}
/**
 * 
 * @export
 * @interface InlineResponse20047
 */
export interface InlineResponse20047 {
    /**
     * 
     * @type {Array<InlineResponse20047Data>}
     * @memberof InlineResponse20047
     */
    data: Array<InlineResponse20047Data>;
}
/**
 * 
 * @export
 * @interface InlineResponse20047Data
 */
export interface InlineResponse20047Data {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20047Data
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20047Data
     */
    date: string;
}
/**
 * 
 * @export
 * @interface InlineResponse20048
 */
export interface InlineResponse20048 {
    /**
     * 
     * @type {any}
     * @memberof InlineResponse20048
     */
    data: any;
}
/**
 * 
 * @export
 * @interface InlineResponse20049
 */
export interface InlineResponse20049 {
    /**
     * 
     * @type {Array<ArtistBanner>}
     * @memberof InlineResponse20049
     */
    data: Array<ArtistBanner>;
}
/**
 * 
 * @export
 * @interface InlineResponse2005
 */
export interface InlineResponse2005 {
    /**
     * 
     * @type {MediaWithAlbum}
     * @memberof InlineResponse2005
     */
    data: MediaWithAlbum;
}
/**
 * 
 * @export
 * @interface InlineResponse20050
 */
export interface InlineResponse20050 {
    /**
     * 
     * @type {InlineResponse20050Data}
     * @memberof InlineResponse20050
     */
    data: InlineResponse20050Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20050Data
 */
export interface InlineResponse20050Data {
    /**
     * 
     * @type {ArtistApplicationStatus}
     * @memberof InlineResponse20050Data
     */
    application_status: ArtistApplicationStatus;
    /**
     * 
     * @type {ArtistApplicationDataStatus}
     * @memberof InlineResponse20050Data
     */
    data_status: ArtistApplicationDataStatus;
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse20050Data
     */
    has_albums: boolean;
}
/**
 * 
 * @export
 * @interface InlineResponse20051
 */
export interface InlineResponse20051 {
    /**
     * 
     * @type {InlineResponse20051Data}
     * @memberof InlineResponse20051
     */
    data: InlineResponse20051Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20051Data
 */
export interface InlineResponse20051Data {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20051Data
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20051Data
     */
    key: string;
}
/**
 * 
 * @export
 * @interface InlineResponse20052
 */
export interface InlineResponse20052 {
    /**
     * 
     * @type {Array<Section>}
     * @memberof InlineResponse20052
     */
    data: Array<Section>;
}
/**
 * 
 * @export
 * @interface InlineResponse20053
 */
export interface InlineResponse20053 {
    /**
     * 
     * @type {Section}
     * @memberof InlineResponse20053
     */
    data: Section;
}
/**
 * 
 * @export
 * @interface InlineResponse2006
 */
export interface InlineResponse2006 {
    /**
     * 
     * @type {InlineResponse2006Data}
     * @memberof InlineResponse2006
     */
    data?: InlineResponse2006Data;
}
/**
 * 
 * @export
 * @interface InlineResponse2006Data
 */
export interface InlineResponse2006Data {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2006Data
     */
    url: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2007
 */
export interface InlineResponse2007 {
    /**
     * 
     * @type {DownloadCrateResponse}
     * @memberof InlineResponse2007
     */
    data: DownloadCrateResponse;
}
/**
 * 
 * @export
 * @interface InlineResponse2008
 */
export interface InlineResponse2008 {
    /**
     * 
     * @type {Array<MediaWithDownloadedDate>}
     * @memberof InlineResponse2008
     */
    data: Array<MediaWithDownloadedDate>;
    /**
     * 
     * @type {Pagination}
     * @memberof InlineResponse2008
     */
    pagination: Pagination;
}
/**
 * 
 * @export
 * @interface InlineResponse2009
 */
export interface InlineResponse2009 {
    /**
     * 
     * @type {Array<Category>}
     * @memberof InlineResponse2009
     */
    data: Array<Category>;
}
/**
 * 
 * @export
 * @interface InlineResponse201
 */
export interface InlineResponse201 {
    /**
     * 
     * @type {UserPlaylistCategory}
     * @memberof InlineResponse201
     */
    data: UserPlaylistCategory;
}
/**
 * 
 * @export
 * @interface InlineResponse400
 */
export interface InlineResponse400 {
    /**
     * 
     * @type {InvalidParametersError}
     * @memberof InlineResponse400
     */
    error: InvalidParametersError;
}
/**
 * 
 * @export
 * @interface InlineResponse403
 */
export interface InlineResponse403 {
    /**
     * 
     * @type {ResourceAlreadyExists}
     * @memberof InlineResponse403
     */
    error: ResourceAlreadyExists;
}
/**
 * 
 * @export
 * @interface InlineResponse404
 */
export interface InlineResponse404 {
    /**
     * 
     * @type {ResourceNotFoundError}
     * @memberof InlineResponse404
     */
    error: ResourceNotFoundError;
}
/**
 * 
 * @export
 * @interface InternalServerError
 */
export interface InternalServerError {
}
/**
 * 
 * @export
 * @interface InvalidParametersError
 */
export interface InvalidParametersError extends Error {
    /**
     * 
     * @type {Array<string>}
     * @memberof InvalidParametersError
     */
    missing_keys: Array<string>;
    /**
     * object format: [invalid_key]: 'message'
     * @type {any}
     * @memberof InvalidParametersError
     */
    invalid_keys?: any;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum Library {
    Music = <any> 'download:music',
    Artist = <any> 'download:artist',
    Latin = <any> 'download:latin'
}
/**
 * 
 * @export
 * @interface Media
 */
export interface Media {
    /**
     * Unique Entity Id
     * @type {number}
     * @memberof Media
     */
    id: number;
    /**
     * Entity creation date
     * @type {string}
     * @memberof Media
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof Media
     */
    updated_at: string;
    /**
     * If track has explicit content
     * @type {boolean}
     * @memberof Media
     */
    explicit: boolean;
    /**
     * 
     * @type {Version}
     * @memberof Media
     */
    version: Version;
    /**
     * The Album ID
     * @type {number}
     * @memberof Media
     */
    album_id: number;
    /**
     * Internal version number of track
     * @type {number}
     * @memberof Media
     */
    bpm_api_version: number;
    /**
     * Size of the HQ 320kbs m4a file, in KB, if available
     * @type {number | string}
     * @memberof Media
     */
    size?: number | string;
    /**
     * Estimated duration of the track, in seconds, if available
     * @type {string}
     * @memberof Media
     */
    estimated_duration?: string;
    /**
     * Full name of track
     * @type {string}
     * @memberof Media
     */
    name: string;
    /**
     * 
     * @type {StreamInfo}
     * @memberof Media
     */
    stream_info: StreamInfo;
    /**
     * 
     * @type {WaveInfo}
     * @memberof Media
     */
    waves: WaveInfo;
    /**
     * If media is in current users online crate
     * @type {boolean}
     * @memberof Media
     */
    in_crate: boolean;
    /**
     * The current users download count
     * @type {number}
     * @memberof Media
     */
    download_count: number;
    /**
     * Only set on online crate endpoints!
     * @type {string}
     * @memberof Media
     */
    added_to_crate_at?: string;
}
/**
 * 
 * @export
 * @interface MediaDownloadmultiBody
 */
export interface MediaDownloadmultiBody {
    /**
     * The media ids
     * @type {Array<number>}
     * @memberof MediaDownloadmultiBody
     */
    media_ids: Array<number>;
}
/**
 * 
 * @export
 * @interface MediaWithAlbum
 */
export interface MediaWithAlbum extends Media {
    /**
     * 
     * @type {Album}
     * @memberof MediaWithAlbum
     */
    album?: Album;
}
/**
 * 
 * @export
 * @interface MediaWithDownloadedDate
 */
export interface MediaWithDownloadedDate extends MediaWithAlbum {
    /**
     * Last downloaded date
     * @type {string}
     * @memberof MediaWithDownloadedDate
     */
    last_downloaded?: string;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * Error Type
     * @type {string}
     * @memberof ModelError
     */
    type: string;
    /**
     * The HTTP Code
     * @type {number}
     * @memberof ModelError
     */
    code: number;
    /**
     * Message. Can be displayed to the user (not localized)
     * @type {string}
     * @memberof ModelError
     */
    message: string;
}
/**
 * 
 * @export
 * @interface NotAccessibleError
 */
export interface NotAccessibleError {
}
/**
 * 
 * @export
 * @interface OnlinecrateBatchBody
 */
export interface OnlinecrateBatchBody {
    /**
     * 
     * @type {Array<number>}
     * @memberof OnlinecrateBatchBody
     */
    media_ids: Array<number>;
}
/**
 * 
 * @export
 * @interface OnlinecrateBatchBody1
 */
export interface OnlinecrateBatchBody1 {
    /**
     * 
     * @type {Array<number>}
     * @memberof OnlinecrateBatchBody1
     */
    media_ids: Array<number>;
}
/**
 * 
 * @export
 * @interface Pagination
 */
export interface Pagination {
    /**
     * Total number of entities
     * @type {number}
     * @memberof Pagination
     */
    total: number;
    /**
     * Number of skipped entities
     * @type {number}
     * @memberof Pagination
     */
    skipped: number;
    /**
     * Max amount of entities to return
     * @type {number}
     * @memberof Pagination
     */
    limited: number;
    /**
     * Current page
     * @type {number}
     * @memberof Pagination
     */
    page: number;
}
/**
 * 
 * @export
 * @interface Playlist
 */
export interface Playlist {
    /**
     * Unique Entity Id
     * @type {number}
     * @memberof Playlist
     */
    id: number;
    /**
     * Entity creation date
     * @type {string}
     * @memberof Playlist
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof Playlist
     */
    updated_at: string;
    /**
     * Title of the playlist
     * @type {string}
     * @memberof Playlist
     */
    title: string;
    /**
     * Slug of the playlist
     * @type {string}
     * @memberof Playlist
     */
    slug: string;
    /**
     * Optional description
     * @type {string}
     * @memberof Playlist
     */
    description?: string;
    /**
     * Order of the playlist within the Category. Ascending.
     * @type {number}
     * @memberof Playlist
     */
    order: number;
    /**
     * Cover (Artwork) URL of the playlist
     * @type {string}
     * @memberof Playlist
     */
    image_url?: string;
    /**
     * The category id
     * @type {number}
     * @memberof Playlist
     */
    category_id: number;
    /**
     * 
     * @type {PlaylistCategory}
     * @memberof Playlist
     */
    category: PlaylistCategory;
    /**
     * 
     * @type {PlaylistGenre}
     * @memberof Playlist
     */
    genre?: PlaylistGenre;
    /**
     * 
     * @type {Library}
     * @memberof Playlist
     */
    library: Library;
    /**
     * Artists of the playlist (first 6)
     * @type {Array<Artist>}
     * @memberof Playlist
     */
    artists?: Array<Artist>;
    /**
     * Artist string of the playlist (first 6). Please see artists for further information
     * @type {string}
     * @memberof Playlist
     */
    artist: string;
    /**
     * Weekly Trending status. Negative number if status went down, positive number if status went up
     * @type {number}
     * @memberof Playlist
     */
    trending_status_weekly: number;
    /**
     * The badge for this playlist
     * @type {string}
     * @memberof Playlist
     */
    badge?: Playlist.BadgeEnum;
}

/**
 * @export
 * @namespace Playlist
 */
export namespace Playlist {
    /**
     * @export
     * @enum {string}
     */
    export enum BadgeEnum {
        New = <any> 'new',
        Trending = <any> 'trending'
    }
}
/**
 * 
 * @export
 * @interface PlaylistCategory
 */
export interface PlaylistCategory {
    /**
     * Unique Entity Id
     * @type {number}
     * @memberof PlaylistCategory
     */
    id: number;
    /**
     * Entity creation date
     * @type {string}
     * @memberof PlaylistCategory
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof PlaylistCategory
     */
    updated_at: string;
    /**
     * Playlist Category name
     * @type {string}
     * @memberof PlaylistCategory
     */
    name: string;
    /**
     * Playlist Category slug
     * @type {string}
     * @memberof PlaylistCategory
     */
    slug: string;
    /**
     * Order of the Category. Ascending.
     * @type {number}
     * @memberof PlaylistCategory
     */
    order: number;
    /**
     * The amount of playlists
     * @type {number}
     * @memberof PlaylistCategory
     */
    playlist_count: number;
}
/**
 * 
 * @export
 * @interface PlaylistCategoryWithPlaylist
 */
export interface PlaylistCategoryWithPlaylist extends PlaylistCategory {
    /**
     * playlists
     * @type {Array<Playlist>}
     * @memberof PlaylistCategoryWithPlaylist
     */
    playlists?: Array<Playlist>;
}
/**
 * 
 * @export
 * @interface PlaylistGenre
 */
export interface PlaylistGenre {
    /**
     * Unique Entity Id
     * @type {number}
     * @memberof PlaylistGenre
     */
    id: number;
    /**
     * Entity creation date
     * @type {string}
     * @memberof PlaylistGenre
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof PlaylistGenre
     */
    updated_at: string;
    /**
     * Playlist Genre name
     * @type {string}
     * @memberof PlaylistGenre
     */
    name: string;
    /**
     * Playlist Genre slug
     * @type {string}
     * @memberof PlaylistGenre
     */
    slug: string;
    /**
     * Order of the Genre. Ascending.
     * @type {number}
     * @memberof PlaylistGenre
     */
    order: number;
}
/**
 * 
 * @export
 * @interface PlaylistWithAlbum
 */
export interface PlaylistWithAlbum extends Playlist {
    /**
     * Albums in this playlist
     * @type {Array<Album>}
     * @memberof PlaylistWithAlbum
     */
    albums?: Array<Album>;
}

/**
 * @export
 * @namespace PlaylistWithAlbum
 */
export namespace PlaylistWithAlbum {
}
/**
 * 
 * @export
 * @interface ResourceAlreadyExists
 */
export interface ResourceAlreadyExists extends Error {
    /**
     * 
     * @type {Array<string>}
     * @memberof ResourceAlreadyExists
     */
    existing_keys?: Array<string>;
}
/**
 * 
 * @export
 * @interface ResourceNotFoundError
 */
export interface ResourceNotFoundError extends Error {
    /**
     * The resource type
     * @type {string}
     * @memberof ResourceNotFoundError
     */
    resource_type?: string;
}
/**
 * 
 * @export
 * @interface Section
 */
export interface Section {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof Section
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof Section
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof Section
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof Section
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Section
     */
    position: number;
    /**
     * 
     * @type {string}
     * @memberof Section
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof Section
     */
    type: Section.TypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Section
     */
    active: boolean;
    /**
     * 
     * @type {CuratedSetCategory}
     * @memberof Section
     */
    CuratedSetCategory?: CuratedSetCategory;
    /**
     * 
     * @type {PlaylistCategory}
     * @memberof Section
     */
    PlaylistCategory?: PlaylistCategory;
}

/**
 * @export
 * @namespace Section
 */
export namespace Section {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Virtual = <any> 'virtual',
        Section = <any> 'section'
    }
}
/**
 * 
 * @export
 * @interface SessionNotFoundError
 */
export interface SessionNotFoundError {
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SortByQueryOptions {
    DateAsc = <any> 'date_asc',
    DateDesc = <any> 'date_desc',
    ArtistAsc = <any> 'artist_asc',
    ArtistDesc = <any> 'artist_desc',
    TitleAsc = <any> 'title_asc',
    TitleDesc = <any> 'title_desc',
    BpmAsc = <any> 'bpm_asc',
    BpmDesc = <any> 'bpm_desc',
    Relevance = <any> 'relevance',
    Trending = <any> 'trending'
}
/**
 * 
 * @export
 * @interface StreamInfo
 */
export interface StreamInfo {
    /**
     * Contains information on how to stream this media file
     * @type {any}
     * @memberof StreamInfo
     */
    audioInfo: any;
}
/**
 * 
 * @export
 */
export type StringOrStringArray = string | Array<string>
/**
 * 
 * @export
 * @interface UserPlaylist
 */
export interface UserPlaylist {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof UserPlaylist
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof UserPlaylist
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof UserPlaylist
     */
    updated_at: string;
    /**
     * Title of the user playlist
     * @type {string}
     * @memberof UserPlaylist
     */
    title: string;
    /**
     * Artist string of the user playlist (first 6). Please see artists for further information
     * @type {string}
     * @memberof UserPlaylist
     */
    artist: string;
    /**
     * Artists of the user playlist (first 6)
     * @type {Array<Artist>}
     * @memberof UserPlaylist
     */
    artists?: Array<Artist>;
    /**
     * Slug of the user playlist
     * @type {string}
     * @memberof UserPlaylist
     */
    slug: string;
    /**
     * Order of the user playlist within the Category. Ascending.
     * @type {number}
     * @memberof UserPlaylist
     */
    order: number;
    /**
     * Cover (Artwork) main color
     * @type {string}
     * @memberof UserPlaylist
     */
    image_color?: string;
    /**
     * Cover (Artwork) URL of the user playlist
     * @type {string}
     * @memberof UserPlaylist
     */
    image_url?: string;
    /**
     * Custom Cover (Artwork) URL of the user playlist
     * @type {string}
     * @memberof UserPlaylist
     */
    custom_image_url?: string;
    /**
     * The amount of albums in this playlist
     * @type {number}
     * @memberof UserPlaylist
     */
    album_count: number;
    /**
     * The associated user id
     * @type {number}
     * @memberof UserPlaylist
     */
    user_id: number;
    /**
     * The link to invite users to thsi playlist. Only set for owner
     * @type {string}
     * @memberof UserPlaylist
     */
    invitation_link?: string;
    /**
     * 
     * @type {UserPlaylistCollaborationAccessLevel}
     * @memberof UserPlaylist
     */
    access_level?: UserPlaylistCollaborationAccessLevel;
    /**
     * The playlists collab status
     * @type {string}
     * @memberof UserPlaylist
     */
    collaboration_status?: UserPlaylist.CollaborationStatusEnum;
    /**
     * The playlists collab access (if downgraded)
     * @type {string}
     * @memberof UserPlaylist
     */
    access?: UserPlaylist.AccessEnum;
    /**
     * The associated category id
     * @type {number}
     * @memberof UserPlaylist
     */
    category_id?: number;
}

/**
 * @export
 * @namespace UserPlaylist
 */
export namespace UserPlaylist {
    /**
     * @export
     * @enum {string}
     */
    export enum CollaborationStatusEnum {
        Downgraded = <any> 'downgraded'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum AccessEnum {
        Downgraded = <any> 'downgraded',
        Expired = <any> 'expired'
    }
}
/**
 * 
 * @export
 * @interface UserPlaylistCategory
 */
export interface UserPlaylistCategory {
    /**
     * Unique Entity Id
     * @type {number}
     * @memberof UserPlaylistCategory
     */
    id: number;
    /**
     * Entity creation date
     * @type {string}
     * @memberof UserPlaylistCategory
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof UserPlaylistCategory
     */
    updated_at: string;
    /**
     * User Playlist Category name
     * @type {string}
     * @memberof UserPlaylistCategory
     */
    name: string;
    /**
     * User Playlist Category slug
     * @type {string}
     * @memberof UserPlaylistCategory
     */
    slug: string;
    /**
     * Order of the User Playlist Category. Ascending.
     * @type {number}
     * @memberof UserPlaylistCategory
     */
    order: number;
    /**
     * The amount of playlists
     * @type {number}
     * @memberof UserPlaylistCategory
     */
    playlist_count: number;
    /**
     * 
     * @type {Array<UserPlaylistCategoryPlaylists>}
     * @memberof UserPlaylistCategory
     */
    playlists?: Array<UserPlaylistCategoryPlaylists>;
}
/**
 * 
 * @export
 * @interface UserPlaylistCategoryPlaylists
 */
export interface UserPlaylistCategoryPlaylists {
    /**
     * 
     * @type {string}
     * @memberof UserPlaylistCategoryPlaylists
     */
    title: string;
}
/**
 * 
 * @export
 * @interface UserPlaylistCollaboration
 */
export interface UserPlaylistCollaboration {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof UserPlaylistCollaboration
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof UserPlaylistCollaboration
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof UserPlaylistCollaboration
     */
    updated_at: string;
    /**
     * Email of the invited user
     * @type {string}
     * @memberof UserPlaylistCollaboration
     */
    email: string;
    /**
     * The user playlist id
     * @type {string}
     * @memberof UserPlaylistCollaboration
     */
    user_playlist_id: string;
    /**
     * The users full name. Only populated when user accepted
     * @type {string}
     * @memberof UserPlaylistCollaboration
     */
    full_name?: string;
    /**
     * The users profile image. Only populated when user accepted
     * @type {string}
     * @memberof UserPlaylistCollaboration
     */
    profile_image_thumbnail_url?: string;
    /**
     * 
     * @type {UserPlaylistCollaborationStatus}
     * @memberof UserPlaylistCollaboration
     */
    status: UserPlaylistCollaborationStatus;
    /**
     * If its the current users connection
     * @type {boolean}
     * @memberof UserPlaylistCollaboration
     */
    is_own?: boolean;
    /**
     * 
     * @type {UserPlaylistCollaborationAccessLevel}
     * @memberof UserPlaylistCollaboration
     */
    access_level: UserPlaylistCollaborationAccessLevel;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum UserPlaylistCollaborationAccessLevel {
    View = <any> 'view',
    Edit = <any> 'edit',
    Manage = <any> 'manage'
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum UserPlaylistCollaborationStatus {
    Invited = <any> 'invited',
    Declined = <any> 'declined',
    Accepted = <any> 'accepted'
}
/**
 * 
 * @export
 * @interface UserPlaylistWithAlbum
 */
export interface UserPlaylistWithAlbum extends UserPlaylist {
    /**
     * Albums in this curated set
     * @type {Array<Album>}
     * @memberof UserPlaylistWithAlbum
     */
    albums?: Array<Album>;
    /**
     * 
     * @type {Array<UserPlaylistCollaboration>}
     * @memberof UserPlaylistWithAlbum
     */
    users?: Array<UserPlaylistCollaboration>;
    /**
     * 
     * @type {UserPlaylistWithAlbumOwner}
     * @memberof UserPlaylistWithAlbum
     */
    owner?: UserPlaylistWithAlbumOwner;
}

/**
 * @export
 * @namespace UserPlaylistWithAlbum
 */
export namespace UserPlaylistWithAlbum {
}
/**
 * 
 * @export
 * @interface UserPlaylistWithAlbumOwner
 */
export interface UserPlaylistWithAlbumOwner {
    /**
     * Email of the owner
     * @type {string}
     * @memberof UserPlaylistWithAlbumOwner
     */
    email: string;
    /**
     * The owners full name
     * @type {string}
     * @memberof UserPlaylistWithAlbumOwner
     */
    full_name: string;
    /**
     * The owners profile image
     * @type {string}
     * @memberof UserPlaylistWithAlbumOwner
     */
    profile_image_thumbnail_url: string;
}
/**
 * 
 * @export
 * @interface UserPlaylistWithUser
 */
export interface UserPlaylistWithUser extends UserPlaylist {
    /**
     * 
     * @type {Array<UserPlaylistCollaboration>}
     * @memberof UserPlaylistWithUser
     */
    users?: Array<UserPlaylistCollaboration>;
    /**
     * 
     * @type {UserPlaylistWithAlbumOwner}
     * @memberof UserPlaylistWithUser
     */
    owner?: UserPlaylistWithAlbumOwner;
}

/**
 * @export
 * @namespace UserPlaylistWithUser
 */
export namespace UserPlaylistWithUser {
}
/**
 * 
 * @export
 * @interface UserplaylistcategoriesBody
 */
export interface UserplaylistcategoriesBody {
    /**
     * 
     * @type {string}
     * @memberof UserplaylistcategoriesBody
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UserplaylistcategoriesBody
     */
    slug?: string;
    /**
     * 
     * @type {number}
     * @memberof UserplaylistcategoriesBody
     */
    order?: number;
}
/**
 * 
 * @export
 * @interface UserplaylistcategoriesIdBody
 */
export interface UserplaylistcategoriesIdBody {
    /**
     * 
     * @type {string}
     * @memberof UserplaylistcategoriesIdBody
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserplaylistcategoriesIdBody
     */
    slug?: string;
    /**
     * 
     * @type {number}
     * @memberof UserplaylistcategoriesIdBody
     */
    order?: number;
}
/**
 * 
 * @export
 * @interface UserplaylistinvitesIdBody
 */
export interface UserplaylistinvitesIdBody {
    /**
     * 
     * @type {UserPlaylistCollaborationAccessLevel}
     * @memberof UserplaylistinvitesIdBody
     */
    access_level: UserPlaylistCollaborationAccessLevel;
}
/**
 * 
 * @export
 * @interface UserplaylistsBody
 */
export interface UserplaylistsBody {
    /**
     * 
     * @type {string}
     * @memberof UserplaylistsBody
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof UserplaylistsBody
     */
    slug?: string;
    /**
     * 
     * @type {number}
     * @memberof UserplaylistsBody
     */
    order?: number;
}
/**
 * 
 * @export
 * @interface UserplaylistsIdBody
 */
export interface UserplaylistsIdBody {
    /**
     * 
     * @type {string}
     * @memberof UserplaylistsIdBody
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof UserplaylistsIdBody
     */
    slug?: string;
    /**
     * 
     * @type {number}
     * @memberof UserplaylistsIdBody
     */
    order?: number;
    /**
     * Change the User Playlist Category
     * @type {string}
     * @memberof UserplaylistsIdBody
     */
    category_id?: string;
}
/**
 * 
 * @export
 * @interface UserplaylistsidbatchAlbums
 */
export interface UserplaylistsidbatchAlbums {
    /**
     * The album Id
     * @type {number}
     * @memberof UserplaylistsidbatchAlbums
     */
    album_id: number;
    /**
     * 
     * @type {number}
     * @memberof UserplaylistsidbatchAlbums
     */
    order?: number;
}
/**
 * 
 * @export
 * @interface Version
 */
export interface Version {
    /**
     * Unique Entity Id
     * @type {number}
     * @memberof Version
     */
    id: number;
    /**
     * Entity creation date
     * @type {string}
     * @memberof Version
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof Version
     */
    updated_at: string;
    /**
     * Version name
     * @type {string}
     * @memberof Version
     */
    name: string;
    /**
     * The order of the version
     * @type {number}
     * @memberof Version
     */
    order: number;
    /**
     * For search endpoint, the number of available results
     * @type {number}
     * @memberof Version
     */
    available_count?: number;
}
/**
 * 
 * @export
 * @interface WaveInfo
 */
export interface WaveInfo {
    /**
     * 
     * @type {string}
     * @memberof WaveInfo
     */
    gray: string;
    /**
     * 
     * @type {string}
     * @memberof WaveInfo
     */
    colored: string;
    /**
     * 
     * @type {string}
     * @memberof WaveInfo
     */
    dat?: string;
    /**
     * 
     * @type {string}
     * @memberof WaveInfo
     */
    latino?: string;
}
/**
 * AlbumApi - fetch parameter creator
 * @export
 */
export const AlbumApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Favorite Album
         * @param {number} id The album Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteAlbum(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling favoriteAlbum.');
            }
            const localVarPath = `/albums/{id}/favorite`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Favorite multiple Albums
         * @param {FavoriteBatchBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteAlbumsBatch(body?: FavoriteBatchBody, options: any = {}): FetchArgs {
            const localVarPath = `/albums/favorite/batch`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'FavoriteBatchBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Album Featured In
         * @param {number} id The album Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlbumFeaturedIn(id: number, library?: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAlbumFeaturedIn.');
            }
            const localVarPath = `/albums/{id}/featured-in`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get 10 albums for every category
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlbumsForCategory(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, sort_by?: SortByQueryOptions, term?: string, date?: string, remixer?: StringOrStringArray, options: any = {}): FetchArgs {
            const localVarPath = `/albums/by-category`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (hide_remix !== undefined) {
                localVarQueryParameter['hide_remix'] = hide_remix;
            }

            if (hide_originals !== undefined) {
                localVarQueryParameter['hide_originals'] = hide_originals;
            }

            if (hide_exclusives !== undefined) {
                localVarQueryParameter['hide_exclusives'] = hide_exclusives;
            }

            if (hide_explicit !== undefined) {
                localVarQueryParameter['hide_explicit'] = hide_explicit;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (remixer !== undefined) {
                localVarQueryParameter['remixer'] = remixer;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get favorited Albums
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavAlbums(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/albums/favorited`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get all featured BPM Original Albums
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedBpmoriginalAlbums(limit?: number, skip?: number, keyType?: string, sort_by?: SortByQueryOptions, library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/albums/featured-bpm-originals`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get trending Albums by period
         * @param {string} period The period
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingAlbumPeriod(period: string, limit?: number, skip?: number, category?: StringOrStringArray, genre?: StringOrStringArray, library?: string, options: any = {}): FetchArgs {
            // verify required parameter 'period' is not null or undefined
            if (period === null || period === undefined) {
                throw new RequiredError('period','Required parameter period was null or undefined when calling getTrendingAlbumPeriod.');
            }
            const localVarPath = `/albums/{period}/trending`
                .replace(`{${'period'}}`, encodeURIComponent(String(period)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Remixes for Album
         * @param {number} id The album Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remixesAlbum(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling remixesAlbum.');
            }
            const localVarPath = `/albums/{id}/remixes`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Similar Albums for Album
         * @param {number} id The album Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        similarAlbum(id: number, library?: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling similarAlbum.');
            }
            const localVarPath = `/albums/{id}/similar`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Suggest Albums and Artist based on term
         * @param {string} term Search term
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestAlbumArtist(term: string, library?: string, keyType?: string, options: any = {}): FetchArgs {
            // verify required parameter 'term' is not null or undefined
            if (term === null || term === undefined) {
                throw new RequiredError('term','Required parameter term was null or undefined when calling suggestAlbumArtist.');
            }
            const localVarPath = `/suggest`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unfavorite Album
         * @param {number} id The album Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfavoriteAlbum(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling unfavoriteAlbum.');
            }
            const localVarPath = `/albums/{id}/favorite`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AlbumApi - functional programming interface
 * @export
 */
export const AlbumApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Favorite Album
         * @param {number} id The album Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteAlbum(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2004> {
            const localVarFetchArgs = AlbumApiFetchParamCreator(configuration).favoriteAlbum(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Favorite multiple Albums
         * @param {FavoriteBatchBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteAlbumsBatch(body?: FavoriteBatchBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2004> {
            const localVarFetchArgs = AlbumApiFetchParamCreator(configuration).favoriteAlbumsBatch(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Album Featured In
         * @param {number} id The album Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlbumFeaturedIn(id: number, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = AlbumApiFetchParamCreator(configuration).getAlbumFeaturedIn(id, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get 10 albums for every category
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlbumsForCategory(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, sort_by?: SortByQueryOptions, term?: string, date?: string, remixer?: StringOrStringArray, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2004> {
            const localVarFetchArgs = AlbumApiFetchParamCreator(configuration).getAlbumsForCategory(category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, sort_by, term, date, remixer, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get favorited Albums
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavAlbums(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2004> {
            const localVarFetchArgs = AlbumApiFetchParamCreator(configuration).getFavAlbums(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get all featured BPM Original Albums
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedBpmoriginalAlbums(limit?: number, skip?: number, keyType?: string, sort_by?: SortByQueryOptions, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse200> {
            const localVarFetchArgs = AlbumApiFetchParamCreator(configuration).getFeaturedBpmoriginalAlbums(limit, skip, keyType, sort_by, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get trending Albums by period
         * @param {string} period The period
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingAlbumPeriod(period: string, limit?: number, skip?: number, category?: StringOrStringArray, genre?: StringOrStringArray, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse200> {
            const localVarFetchArgs = AlbumApiFetchParamCreator(configuration).getTrendingAlbumPeriod(period, limit, skip, category, genre, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Remixes for Album
         * @param {number} id The album Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remixesAlbum(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2004> {
            const localVarFetchArgs = AlbumApiFetchParamCreator(configuration).remixesAlbum(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Similar Albums for Album
         * @param {number} id The album Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        similarAlbum(id: number, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2004> {
            const localVarFetchArgs = AlbumApiFetchParamCreator(configuration).similarAlbum(id, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Suggest Albums and Artist based on term
         * @param {string} term Search term
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestAlbumArtist(term: string, library?: string, keyType?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20030> {
            const localVarFetchArgs = AlbumApiFetchParamCreator(configuration).suggestAlbumArtist(term, library, keyType, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Unfavorite Album
         * @param {number} id The album Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfavoriteAlbum(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2004> {
            const localVarFetchArgs = AlbumApiFetchParamCreator(configuration).unfavoriteAlbum(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AlbumApi - factory interface
 * @export
 */
export const AlbumApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Favorite Album
         * @param {number} id The album Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteAlbum(id: number, options?: any) {
            return AlbumApiFp(configuration).favoriteAlbum(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Favorite multiple Albums
         * @param {FavoriteBatchBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteAlbumsBatch(body?: FavoriteBatchBody, options?: any) {
            return AlbumApiFp(configuration).favoriteAlbumsBatch(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Album Featured In
         * @param {number} id The album Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlbumFeaturedIn(id: number, library?: string, options?: any) {
            return AlbumApiFp(configuration).getAlbumFeaturedIn(id, library, options)(fetch, basePath);
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get 10 albums for every category
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlbumsForCategory(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, sort_by?: SortByQueryOptions, term?: string, date?: string, remixer?: StringOrStringArray, options?: any) {
            return AlbumApiFp(configuration).getAlbumsForCategory(category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, sort_by, term, date, remixer, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get favorited Albums
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavAlbums(library?: string, options?: any) {
            return AlbumApiFp(configuration).getFavAlbums(library, options)(fetch, basePath);
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get all featured BPM Original Albums
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedBpmoriginalAlbums(limit?: number, skip?: number, keyType?: string, sort_by?: SortByQueryOptions, library?: string, options?: any) {
            return AlbumApiFp(configuration).getFeaturedBpmoriginalAlbums(limit, skip, keyType, sort_by, library, options)(fetch, basePath);
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get trending Albums by period
         * @param {string} period The period
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingAlbumPeriod(period: string, limit?: number, skip?: number, category?: StringOrStringArray, genre?: StringOrStringArray, library?: string, options?: any) {
            return AlbumApiFp(configuration).getTrendingAlbumPeriod(period, limit, skip, category, genre, library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Remixes for Album
         * @param {number} id The album Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remixesAlbum(id: number, options?: any) {
            return AlbumApiFp(configuration).remixesAlbum(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Similar Albums for Album
         * @param {number} id The album Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        similarAlbum(id: number, library?: string, options?: any) {
            return AlbumApiFp(configuration).similarAlbum(id, library, options)(fetch, basePath);
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Suggest Albums and Artist based on term
         * @param {string} term Search term
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestAlbumArtist(term: string, library?: string, keyType?: string, options?: any) {
            return AlbumApiFp(configuration).suggestAlbumArtist(term, library, keyType, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Unfavorite Album
         * @param {number} id The album Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfavoriteAlbum(id: number, options?: any) {
            return AlbumApiFp(configuration).unfavoriteAlbum(id, options)(fetch, basePath);
        },
    };
};

/**
 * AlbumApi - object-oriented interface
 * @export
 * @class AlbumApi
 * @extends {BaseAPI}
 */
export class AlbumApi extends BaseAPI {
    /**
     * 
     * @summary Favorite Album
     * @param {number} id The album Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlbumApi
     */
    public favoriteAlbum(id: number, options?: any) {
        return AlbumApiFp(this.configuration).favoriteAlbum(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Favorite multiple Albums
     * @param {FavoriteBatchBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlbumApi
     */
    public favoriteAlbumsBatch(body?: FavoriteBatchBody, options?: any) {
        return AlbumApiFp(this.configuration).favoriteAlbumsBatch(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Album Featured In
     * @param {number} id The album Id
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlbumApi
     */
    public getAlbumFeaturedIn(id: number, library?: string, options?: any) {
        return AlbumApiFp(this.configuration).getAlbumFeaturedIn(id, library, options)(this.fetch, this.basePath);
    }

    /**
     * Default filters based on susbcription and region are applied automatic.
     * @summary Get 10 albums for every category
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
     * @param {string} [hide_remix] Set to 1 to hide remixes
     * @param {string} [hide_originals] Set to 1 to hide originals
     * @param {string} [hide_exclusives] Set to 1 to hide exclusives
     * @param {string} [hide_explicit] Set to 1 to hide explicit media
     * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
     * @param {StringOrStringArray} [version] version names(s) (can be multiple)
     * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {number} [bpm_min] 
     * @param {number} [bpm_max] Maxmium is 250
     * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
     * @param {string} [term] Search term
     * @param {string} [date] Date string (Example: 2021-09-10)
     * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlbumApi
     */
    public getAlbumsForCategory(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, sort_by?: SortByQueryOptions, term?: string, date?: string, remixer?: StringOrStringArray, options?: any) {
        return AlbumApiFp(this.configuration).getAlbumsForCategory(category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, sort_by, term, date, remixer, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get favorited Albums
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlbumApi
     */
    public getFavAlbums(library?: string, options?: any) {
        return AlbumApiFp(this.configuration).getFavAlbums(library, options)(this.fetch, this.basePath);
    }

    /**
     * Default filters based on susbcription and region are applied automatic.
     * @summary Get all featured BPM Original Albums
     * @param {number} [limit] Default is 50 for most endpoints
     * @param {number} [skip] 
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlbumApi
     */
    public getFeaturedBpmoriginalAlbums(limit?: number, skip?: number, keyType?: string, sort_by?: SortByQueryOptions, library?: string, options?: any) {
        return AlbumApiFp(this.configuration).getFeaturedBpmoriginalAlbums(limit, skip, keyType, sort_by, library, options)(this.fetch, this.basePath);
    }

    /**
     * Default filters based on susbcription and region are applied automatic.
     * @summary Get trending Albums by period
     * @param {string} period The period
     * @param {number} [limit] Default is 50 for most endpoints
     * @param {number} [skip] 
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlbumApi
     */
    public getTrendingAlbumPeriod(period: string, limit?: number, skip?: number, category?: StringOrStringArray, genre?: StringOrStringArray, library?: string, options?: any) {
        return AlbumApiFp(this.configuration).getTrendingAlbumPeriod(period, limit, skip, category, genre, library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Remixes for Album
     * @param {number} id The album Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlbumApi
     */
    public remixesAlbum(id: number, options?: any) {
        return AlbumApiFp(this.configuration).remixesAlbum(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Similar Albums for Album
     * @param {number} id The album Id
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlbumApi
     */
    public similarAlbum(id: number, library?: string, options?: any) {
        return AlbumApiFp(this.configuration).similarAlbum(id, library, options)(this.fetch, this.basePath);
    }

    /**
     * Default filters based on susbcription and region are applied automatic.
     * @summary Suggest Albums and Artist based on term
     * @param {string} term Search term
     * @param {string} [library] Default is all to the user available libraries
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlbumApi
     */
    public suggestAlbumArtist(term: string, library?: string, keyType?: string, options?: any) {
        return AlbumApiFp(this.configuration).suggestAlbumArtist(term, library, keyType, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Unfavorite Album
     * @param {number} id The album Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlbumApi
     */
    public unfavoriteAlbum(id: number, options?: any) {
        return AlbumApiFp(this.configuration).unfavoriteAlbum(id, options)(this.fetch, this.basePath);
    }

}
/**
 * ArtistApi - fetch parameter creator
 * @export
 */
export const ArtistApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Follow Artist
         * @param {number} id The artist Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followArtist(id: number, library?: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling followArtist.');
            }
            const localVarPath = `/artists/{id}/follow`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Artist Featured In
         * @param {number} id The artist Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistFeaturedIn(id: number, library?: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getArtistFeaturedIn.');
            }
            const localVarPath = `/artists/{id}/featured-in`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Artist By Id
         * @param {number} id The artist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistId(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getArtistId.');
            }
            const localVarPath = `/artists/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Artist By Slug
         * @param {string} slug The artist slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistSlug(slug: string, options: any = {}): FetchArgs {
            // verify required parameter 'slug' is not null or undefined
            if (slug === null || slug === undefined) {
                throw new RequiredError('slug','Required parameter slug was null or undefined when calling getArtistSlug.');
            }
            const localVarPath = `/artists/{slug}`
                .replace(`{${'slug'}}`, encodeURIComponent(String(slug)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get featured Artists
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedArtists(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/artists/featured`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get featured Editors
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedEditors(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/editors/featured`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get followed Artists
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowedArtists(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/artists/following`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search for Artists
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchArtist(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options: any = {}): FetchArgs {
            const localVarPath = `/artists`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (hide_remix !== undefined) {
                localVarQueryParameter['hide_remix'] = hide_remix;
            }

            if (hide_originals !== undefined) {
                localVarQueryParameter['hide_originals'] = hide_originals;
            }

            if (hide_exclusives !== undefined) {
                localVarQueryParameter['hide_exclusives'] = hide_exclusives;
            }

            if (hide_explicit !== undefined) {
                localVarQueryParameter['hide_explicit'] = hide_explicit;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (remixer !== undefined) {
                localVarQueryParameter['remixer'] = remixer;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Suggest Albums and Artist based on term
         * @param {string} term Search term
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestAlbumArtist(term: string, library?: string, keyType?: string, options: any = {}): FetchArgs {
            // verify required parameter 'term' is not null or undefined
            if (term === null || term === undefined) {
                throw new RequiredError('term','Required parameter term was null or undefined when calling suggestAlbumArtist.');
            }
            const localVarPath = `/suggest`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unfollow Artist
         * @param {number} id The artist Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfollowArtist(id: number, library?: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling unfollowArtist.');
            }
            const localVarPath = `/artists/{id}/follow`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArtistApi - functional programming interface
 * @export
 */
export const ArtistApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Follow Artist
         * @param {number} id The artist Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followArtist(id: number, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20028> {
            const localVarFetchArgs = ArtistApiFetchParamCreator(configuration).followArtist(id, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Artist Featured In
         * @param {number} id The artist Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistFeaturedIn(id: number, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = ArtistApiFetchParamCreator(configuration).getArtistFeaturedIn(id, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Artist By Id
         * @param {number} id The artist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistId(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20026> {
            const localVarFetchArgs = ArtistApiFetchParamCreator(configuration).getArtistId(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Artist By Slug
         * @param {string} slug The artist slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistSlug(slug: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20026> {
            const localVarFetchArgs = ArtistApiFetchParamCreator(configuration).getArtistSlug(slug, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get featured Artists
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedArtists(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20028> {
            const localVarFetchArgs = ArtistApiFetchParamCreator(configuration).getFeaturedArtists(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get featured Editors
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedEditors(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20028> {
            const localVarFetchArgs = ArtistApiFetchParamCreator(configuration).getFeaturedEditors(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get followed Artists
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowedArtists(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20028> {
            const localVarFetchArgs = ArtistApiFetchParamCreator(configuration).getFollowedArtists(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search for Artists
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchArtist(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20027> {
            const localVarFetchArgs = ArtistApiFetchParamCreator(configuration).searchArtist(limit, skip, category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, term, date, remixer, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Suggest Albums and Artist based on term
         * @param {string} term Search term
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestAlbumArtist(term: string, library?: string, keyType?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20030> {
            const localVarFetchArgs = ArtistApiFetchParamCreator(configuration).suggestAlbumArtist(term, library, keyType, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Unfollow Artist
         * @param {number} id The artist Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfollowArtist(id: number, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20028> {
            const localVarFetchArgs = ArtistApiFetchParamCreator(configuration).unfollowArtist(id, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ArtistApi - factory interface
 * @export
 */
export const ArtistApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Follow Artist
         * @param {number} id The artist Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followArtist(id: number, library?: string, options?: any) {
            return ArtistApiFp(configuration).followArtist(id, library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Artist Featured In
         * @param {number} id The artist Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistFeaturedIn(id: number, library?: string, options?: any) {
            return ArtistApiFp(configuration).getArtistFeaturedIn(id, library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Artist By Id
         * @param {number} id The artist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistId(id: number, options?: any) {
            return ArtistApiFp(configuration).getArtistId(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Artist By Slug
         * @param {string} slug The artist slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistSlug(slug: string, options?: any) {
            return ArtistApiFp(configuration).getArtistSlug(slug, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get featured Artists
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedArtists(library?: string, options?: any) {
            return ArtistApiFp(configuration).getFeaturedArtists(library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get featured Editors
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedEditors(library?: string, options?: any) {
            return ArtistApiFp(configuration).getFeaturedEditors(library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get followed Artists
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowedArtists(library?: string, options?: any) {
            return ArtistApiFp(configuration).getFollowedArtists(library, options)(fetch, basePath);
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search for Artists
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchArtist(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any) {
            return ArtistApiFp(configuration).searchArtist(limit, skip, category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, term, date, remixer, options)(fetch, basePath);
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Suggest Albums and Artist based on term
         * @param {string} term Search term
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestAlbumArtist(term: string, library?: string, keyType?: string, options?: any) {
            return ArtistApiFp(configuration).suggestAlbumArtist(term, library, keyType, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Unfollow Artist
         * @param {number} id The artist Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfollowArtist(id: number, library?: string, options?: any) {
            return ArtistApiFp(configuration).unfollowArtist(id, library, options)(fetch, basePath);
        },
    };
};

/**
 * ArtistApi - object-oriented interface
 * @export
 * @class ArtistApi
 * @extends {BaseAPI}
 */
export class ArtistApi extends BaseAPI {
    /**
     * 
     * @summary Follow Artist
     * @param {number} id The artist Id
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistApi
     */
    public followArtist(id: number, library?: string, options?: any) {
        return ArtistApiFp(this.configuration).followArtist(id, library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Artist Featured In
     * @param {number} id The artist Id
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistApi
     */
    public getArtistFeaturedIn(id: number, library?: string, options?: any) {
        return ArtistApiFp(this.configuration).getArtistFeaturedIn(id, library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Artist By Id
     * @param {number} id The artist Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistApi
     */
    public getArtistId(id: number, options?: any) {
        return ArtistApiFp(this.configuration).getArtistId(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Artist By Slug
     * @param {string} slug The artist slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistApi
     */
    public getArtistSlug(slug: string, options?: any) {
        return ArtistApiFp(this.configuration).getArtistSlug(slug, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get featured Artists
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistApi
     */
    public getFeaturedArtists(library?: string, options?: any) {
        return ArtistApiFp(this.configuration).getFeaturedArtists(library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get featured Editors
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistApi
     */
    public getFeaturedEditors(library?: string, options?: any) {
        return ArtistApiFp(this.configuration).getFeaturedEditors(library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get followed Artists
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistApi
     */
    public getFollowedArtists(library?: string, options?: any) {
        return ArtistApiFp(this.configuration).getFollowedArtists(library, options)(this.fetch, this.basePath);
    }

    /**
     * Note: sort_by is NOT supported on this endpoint
     * @summary Search for Artists
     * @param {number} [limit] Default is 50 for most endpoints
     * @param {number} [skip] 
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
     * @param {string} [hide_remix] Set to 1 to hide remixes
     * @param {string} [hide_originals] Set to 1 to hide originals
     * @param {string} [hide_exclusives] Set to 1 to hide exclusives
     * @param {string} [hide_explicit] Set to 1 to hide explicit media
     * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
     * @param {StringOrStringArray} [version] version names(s) (can be multiple)
     * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {number} [bpm_min] 
     * @param {number} [bpm_max] Maxmium is 250
     * @param {string} [term] Search term
     * @param {string} [date] Date string (Example: 2021-09-10)
     * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistApi
     */
    public searchArtist(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any) {
        return ArtistApiFp(this.configuration).searchArtist(limit, skip, category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, term, date, remixer, options)(this.fetch, this.basePath);
    }

    /**
     * Default filters based on susbcription and region are applied automatic.
     * @summary Suggest Albums and Artist based on term
     * @param {string} term Search term
     * @param {string} [library] Default is all to the user available libraries
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistApi
     */
    public suggestAlbumArtist(term: string, library?: string, keyType?: string, options?: any) {
        return ArtistApiFp(this.configuration).suggestAlbumArtist(term, library, keyType, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Unfollow Artist
     * @param {number} id The artist Id
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistApi
     */
    public unfollowArtist(id: number, library?: string, options?: any) {
        return ArtistApiFp(this.configuration).unfollowArtist(id, library, options)(this.fetch, this.basePath);
    }

}
/**
 * ArtistPortalApi - fetch parameter creator
 * @export
 */
export const ArtistPortalApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Albums for Artist Portal
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {boolean} [requestSignedUrls] 
         * @param {string} [analytics_period] The analytics period to return
         * @param {ArtistAlbumsSortByQueryOptions} [sort_by] Default is release_date
         * @param {string} [search] Search term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalAlbums(limit?: number, skip?: number, requestSignedUrls?: boolean, analytics_period?: string, sort_by?: ArtistAlbumsSortByQueryOptions, search?: string, options: any = {}): FetchArgs {
            const localVarPath = `/artist-portal/albums`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (requestSignedUrls !== undefined) {
                localVarQueryParameter['requestSignedUrls'] = requestSignedUrls;
            }

            if (analytics_period !== undefined) {
                localVarQueryParameter['analytics_period'] = analytics_period;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Artist Application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalApplication(options: any = {}): FetchArgs {
            const localVarPath = `/artist-portal/application`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Banner Artist Portal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalBanner(options: any = {}): FetchArgs {
            const localVarPath = `/artist-portal/banner`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Chart Data Artist Portal
         * @param {string} type 
         * @param {number} tz_offset The time zon offest in minutes, as it comes from new Date().getTimeZoneOffset()
         * @param {string} [analytics_period] The analytics period to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalChartData(type: string, tz_offset: number, analytics_period?: string, options: any = {}): FetchArgs {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getArtistPortalChartData.');
            }
            // verify required parameter 'tz_offset' is not null or undefined
            if (tz_offset === null || tz_offset === undefined) {
                throw new RequiredError('tz_offset','Required parameter tz_offset was null or undefined when calling getArtistPortalChartData.');
            }
            const localVarPath = `/artist-portal/chart-data`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (analytics_period !== undefined) {
                localVarQueryParameter['analytics_period'] = analytics_period;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (tz_offset !== undefined) {
                localVarQueryParameter['tz_offset'] = tz_offset;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Engagement Stats Artist Portal
         * @param {string} [analytics_period] The analytics period to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalEnagementStats(analytics_period?: string, options: any = {}): FetchArgs {
            const localVarPath = `/artist-portal/engagement-stats`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (analytics_period !== undefined) {
                localVarQueryParameter['analytics_period'] = analytics_period;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Other Artists Artist Portal
         * @param {string} [analytics_period] The analytics period to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalOtherArtists(analytics_period?: string, options: any = {}): FetchArgs {
            const localVarPath = `/artist-portal/other-artists`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (analytics_period !== undefined) {
                localVarQueryParameter['analytics_period'] = analytics_period;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Profile Status Artist Portal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalProfileStatus(options: any = {}): FetchArgs {
            const localVarPath = `/artist-portal/profile/status`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Top Cities Artist Portal
         * @param {string} [analytics_period] The analytics period to return
         * @param {ArtistSortByQueryOptions} [sort_by] Default is downloads
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalTopCities(analytics_period?: string, sort_by?: ArtistSortByQueryOptions, limit?: number, options: any = {}): FetchArgs {
            const localVarPath = `/artist-portal/top-locations/city`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (analytics_period !== undefined) {
                localVarQueryParameter['analytics_period'] = analytics_period;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Top Countries Artist Portal
         * @param {string} [analytics_period] The analytics period to return
         * @param {ArtistSortByQueryOptions} [sort_by] Default is downloads
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalTopCountries(analytics_period?: string, sort_by?: ArtistSortByQueryOptions, limit?: number, options: any = {}): FetchArgs {
            const localVarPath = `/artist-portal/top-locations/country`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (analytics_period !== undefined) {
                localVarQueryParameter['analytics_period'] = analytics_period;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Top DJ Types Artist Portal
         * @param {string} [analytics_period] The analytics period to return
         * @param {ArtistSortByQueryOptions} [sort_by] Default is downloads
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalTopDjTypes(analytics_period?: string, sort_by?: ArtistSortByQueryOptions, options: any = {}): FetchArgs {
            const localVarPath = `/artist-portal/top-dj-types`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (analytics_period !== undefined) {
                localVarQueryParameter['analytics_period'] = analytics_period;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Top Sources Artist Portal
         * @param {string} [analytics_period] The analytics period to return
         * @param {ArtistSortByQueryOptions} [sort_by] Default is downloads
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalTopSources(analytics_period?: string, sort_by?: ArtistSortByQueryOptions, options: any = {}): FetchArgs {
            const localVarPath = `/artist-portal/top-sources`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (analytics_period !== undefined) {
                localVarQueryParameter['analytics_period'] = analytics_period;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Track Upload URL Artist Portal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalTrackUploadUrl(options: any = {}): FetchArgs {
            const localVarPath = `/artist-portal/track/upload-link`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Weekly Stats Artist Portal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalWeeklyStats(options: any = {}): FetchArgs {
            const localVarPath = `/artist-portal/weekly-stats`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get My Artist
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManagingArtist(options: any = {}): FetchArgs {
            const localVarPath = `/artist-portal/profile/artist`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Report Album
         * @param {number} albumId The album Id
         * @param {AlbumIdReportBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportAlbum(albumId: number, body?: AlbumIdReportBody, options: any = {}): FetchArgs {
            // verify required parameter 'albumId' is not null or undefined
            if (albumId === null || albumId === undefined) {
                throw new RequiredError('albumId','Required parameter albumId was null or undefined when calling reportAlbum.');
            }
            const localVarPath = `/artist-portal/album/{albumId}/report`
                .replace(`{${'albumId'}}`, encodeURIComponent(String(albumId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'AlbumIdReportBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit Artist Application
         * @param {Array<string>} [links] 
         * @param {Array<string>} [social_links] 
         * @param {string} [name] 
         * @param {string} [city] 
         * @param {string} [country] 
         * @param {string} [full_name] 
         * @param {string} [bio] 
         * @param {Blob} [artwork] 
         * @param {Blob} [press] 
         * @param {ArtistTrackSubmit} [track_submit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitArtistPortalApplication(links?: Array<string>, social_links?: Array<string>, name?: string, city?: string, country?: string, full_name?: string, bio?: string, artwork?: Blob, press?: Blob, track_submit?: ArtistTrackSubmit, options: any = {}): FetchArgs {
            const localVarPath = `/artist-portal/application`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (links) {
                links.forEach((element) => {
                    localVarFormParams.append('links', element as any);
                })
            }

            if (social_links) {
                social_links.forEach((element) => {
                    localVarFormParams.append('social_links', element as any);
                })
            }

            if (name !== undefined) {
                localVarFormParams.set('name', name as any);
            }

            if (city !== undefined) {
                localVarFormParams.set('city', city as any);
            }

            if (country !== undefined) {
                localVarFormParams.set('country', country as any);
            }

            if (full_name !== undefined) {
                localVarFormParams.set('full_name', full_name as any);
            }

            if (bio !== undefined) {
                localVarFormParams.set('bio', bio as any);
            }

            if (artwork !== undefined) {
                localVarFormParams.set('artwork', artwork as any);
            }

            if (press !== undefined) {
                localVarFormParams.set('press', press as any);
            }

            if (track_submit !== undefined) {
                localVarFormParams.set('track_submit', track_submit as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit Artist Profile Change Request
         * @param {Array<string>} [social_media] 
         * @param {string} [name] 
         * @param {string} [description] 
         * @param {Blob} [artwork] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitArtistPortalProfileChangeRequest(social_media?: Array<string>, name?: string, description?: string, artwork?: Blob, options: any = {}): FetchArgs {
            const localVarPath = `/artist-portal/profile/change-request`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (social_media) {
                social_media.forEach((element) => {
                    localVarFormParams.append('social_media', element as any);
                })
            }

            if (name !== undefined) {
                localVarFormParams.set('name', name as any);
            }

            if (description !== undefined) {
                localVarFormParams.set('description', description as any);
            }

            if (artwork !== undefined) {
                localVarFormParams.set('artwork', artwork as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit Artist Tracks
         * @param {string} [title] 
         * @param {string} [artist_name] 
         * @param {string} [release_date] 
         * @param {string} [genre] 
         * @param {string} [isrc] 
         * @param {string} [label] 
         * @param {string} [produced_by] 
         * @param {Blob} [track_artwork] 
         * @param {Array<ArtistTrackSubmitTracks>} [tracks] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitArtistPortalTrack(title?: string, artist_name?: string, release_date?: string, genre?: string, isrc?: string, label?: string, produced_by?: string, track_artwork?: Blob, tracks?: Array<ArtistTrackSubmitTracks>, options: any = {}): FetchArgs {
            const localVarPath = `/artist-portal/track/submit`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (title !== undefined) {
                localVarFormParams.set('title', title as any);
            }

            if (artist_name !== undefined) {
                localVarFormParams.set('artist_name', artist_name as any);
            }

            if (release_date !== undefined) {
                localVarFormParams.set('release_date', release_date as any);
            }

            if (genre !== undefined) {
                localVarFormParams.set('genre', genre as any);
            }

            if (isrc !== undefined) {
                localVarFormParams.set('isrc', isrc as any);
            }

            if (label !== undefined) {
                localVarFormParams.set('label', label as any);
            }

            if (produced_by !== undefined) {
                localVarFormParams.set('produced_by', produced_by as any);
            }

            if (track_artwork !== undefined) {
                localVarFormParams.set('track_artwork', track_artwork as any);
            }

            if (tracks) {
                tracks.forEach((element) => {
                    localVarFormParams.append('tracks', element as any);
                })
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArtistPortalApi - functional programming interface
 * @export
 */
export const ArtistPortalApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Albums for Artist Portal
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {boolean} [requestSignedUrls] 
         * @param {string} [analytics_period] The analytics period to return
         * @param {ArtistAlbumsSortByQueryOptions} [sort_by] Default is release_date
         * @param {string} [search] Search term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalAlbums(limit?: number, skip?: number, requestSignedUrls?: boolean, analytics_period?: string, sort_by?: ArtistAlbumsSortByQueryOptions, search?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20039> {
            const localVarFetchArgs = ArtistPortalApiFetchParamCreator(configuration).getArtistPortalAlbums(limit, skip, requestSignedUrls, analytics_period, sort_by, search, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Artist Application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalApplication(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20038> {
            const localVarFetchArgs = ArtistPortalApiFetchParamCreator(configuration).getArtistPortalApplication(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Banner Artist Portal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalBanner(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20049> {
            const localVarFetchArgs = ArtistPortalApiFetchParamCreator(configuration).getArtistPortalBanner(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Chart Data Artist Portal
         * @param {string} type 
         * @param {number} tz_offset The time zon offest in minutes, as it comes from new Date().getTimeZoneOffset()
         * @param {string} [analytics_period] The analytics period to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalChartData(type: string, tz_offset: number, analytics_period?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20047> {
            const localVarFetchArgs = ArtistPortalApiFetchParamCreator(configuration).getArtistPortalChartData(type, tz_offset, analytics_period, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Engagement Stats Artist Portal
         * @param {string} [analytics_period] The analytics period to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalEnagementStats(analytics_period?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20046> {
            const localVarFetchArgs = ArtistPortalApiFetchParamCreator(configuration).getArtistPortalEnagementStats(analytics_period, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Other Artists Artist Portal
         * @param {string} [analytics_period] The analytics period to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalOtherArtists(analytics_period?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20044> {
            const localVarFetchArgs = ArtistPortalApiFetchParamCreator(configuration).getArtistPortalOtherArtists(analytics_period, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Profile Status Artist Portal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalProfileStatus(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20050> {
            const localVarFetchArgs = ArtistPortalApiFetchParamCreator(configuration).getArtistPortalProfileStatus(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Top Cities Artist Portal
         * @param {string} [analytics_period] The analytics period to return
         * @param {ArtistSortByQueryOptions} [sort_by] Default is downloads
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalTopCities(analytics_period?: string, sort_by?: ArtistSortByQueryOptions, limit?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20040> {
            const localVarFetchArgs = ArtistPortalApiFetchParamCreator(configuration).getArtistPortalTopCities(analytics_period, sort_by, limit, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Top Countries Artist Portal
         * @param {string} [analytics_period] The analytics period to return
         * @param {ArtistSortByQueryOptions} [sort_by] Default is downloads
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalTopCountries(analytics_period?: string, sort_by?: ArtistSortByQueryOptions, limit?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20041> {
            const localVarFetchArgs = ArtistPortalApiFetchParamCreator(configuration).getArtistPortalTopCountries(analytics_period, sort_by, limit, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Top DJ Types Artist Portal
         * @param {string} [analytics_period] The analytics period to return
         * @param {ArtistSortByQueryOptions} [sort_by] Default is downloads
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalTopDjTypes(analytics_period?: string, sort_by?: ArtistSortByQueryOptions, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20042> {
            const localVarFetchArgs = ArtistPortalApiFetchParamCreator(configuration).getArtistPortalTopDjTypes(analytics_period, sort_by, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Top Sources Artist Portal
         * @param {string} [analytics_period] The analytics period to return
         * @param {ArtistSortByQueryOptions} [sort_by] Default is downloads
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalTopSources(analytics_period?: string, sort_by?: ArtistSortByQueryOptions, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20043> {
            const localVarFetchArgs = ArtistPortalApiFetchParamCreator(configuration).getArtistPortalTopSources(analytics_period, sort_by, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Track Upload URL Artist Portal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalTrackUploadUrl(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20051> {
            const localVarFetchArgs = ArtistPortalApiFetchParamCreator(configuration).getArtistPortalTrackUploadUrl(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Weekly Stats Artist Portal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalWeeklyStats(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20045> {
            const localVarFetchArgs = ArtistPortalApiFetchParamCreator(configuration).getArtistPortalWeeklyStats(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get My Artist
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManagingArtist(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20026> {
            const localVarFetchArgs = ArtistPortalApiFetchParamCreator(configuration).getManagingArtist(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Report Album
         * @param {number} albumId The album Id
         * @param {AlbumIdReportBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportAlbum(albumId: number, body?: AlbumIdReportBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20048> {
            const localVarFetchArgs = ArtistPortalApiFetchParamCreator(configuration).reportAlbum(albumId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Submit Artist Application
         * @param {Array<string>} [links] 
         * @param {Array<string>} [social_links] 
         * @param {string} [name] 
         * @param {string} [city] 
         * @param {string} [country] 
         * @param {string} [full_name] 
         * @param {string} [bio] 
         * @param {Blob} [artwork] 
         * @param {Blob} [press] 
         * @param {ArtistTrackSubmit} [track_submit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitArtistPortalApplication(links?: Array<string>, social_links?: Array<string>, name?: string, city?: string, country?: string, full_name?: string, bio?: string, artwork?: Blob, press?: Blob, track_submit?: ArtistTrackSubmit, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20038> {
            const localVarFetchArgs = ArtistPortalApiFetchParamCreator(configuration).submitArtistPortalApplication(links, social_links, name, city, country, full_name, bio, artwork, press, track_submit, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Submit Artist Profile Change Request
         * @param {Array<string>} [social_media] 
         * @param {string} [name] 
         * @param {string} [description] 
         * @param {Blob} [artwork] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitArtistPortalProfileChangeRequest(social_media?: Array<string>, name?: string, description?: string, artwork?: Blob, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20048> {
            const localVarFetchArgs = ArtistPortalApiFetchParamCreator(configuration).submitArtistPortalProfileChangeRequest(social_media, name, description, artwork, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Submit Artist Tracks
         * @param {string} [title] 
         * @param {string} [artist_name] 
         * @param {string} [release_date] 
         * @param {string} [genre] 
         * @param {string} [isrc] 
         * @param {string} [label] 
         * @param {string} [produced_by] 
         * @param {Blob} [track_artwork] 
         * @param {Array<ArtistTrackSubmitTracks>} [tracks] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitArtistPortalTrack(title?: string, artist_name?: string, release_date?: string, genre?: string, isrc?: string, label?: string, produced_by?: string, track_artwork?: Blob, tracks?: Array<ArtistTrackSubmitTracks>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20048> {
            const localVarFetchArgs = ArtistPortalApiFetchParamCreator(configuration).submitArtistPortalTrack(title, artist_name, release_date, genre, isrc, label, produced_by, track_artwork, tracks, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ArtistPortalApi - factory interface
 * @export
 */
export const ArtistPortalApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get Albums for Artist Portal
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {boolean} [requestSignedUrls] 
         * @param {string} [analytics_period] The analytics period to return
         * @param {ArtistAlbumsSortByQueryOptions} [sort_by] Default is release_date
         * @param {string} [search] Search term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalAlbums(limit?: number, skip?: number, requestSignedUrls?: boolean, analytics_period?: string, sort_by?: ArtistAlbumsSortByQueryOptions, search?: string, options?: any) {
            return ArtistPortalApiFp(configuration).getArtistPortalAlbums(limit, skip, requestSignedUrls, analytics_period, sort_by, search, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Artist Application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalApplication(options?: any) {
            return ArtistPortalApiFp(configuration).getArtistPortalApplication(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Banner Artist Portal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalBanner(options?: any) {
            return ArtistPortalApiFp(configuration).getArtistPortalBanner(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Chart Data Artist Portal
         * @param {string} type 
         * @param {number} tz_offset The time zon offest in minutes, as it comes from new Date().getTimeZoneOffset()
         * @param {string} [analytics_period] The analytics period to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalChartData(type: string, tz_offset: number, analytics_period?: string, options?: any) {
            return ArtistPortalApiFp(configuration).getArtistPortalChartData(type, tz_offset, analytics_period, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Engagement Stats Artist Portal
         * @param {string} [analytics_period] The analytics period to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalEnagementStats(analytics_period?: string, options?: any) {
            return ArtistPortalApiFp(configuration).getArtistPortalEnagementStats(analytics_period, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Other Artists Artist Portal
         * @param {string} [analytics_period] The analytics period to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalOtherArtists(analytics_period?: string, options?: any) {
            return ArtistPortalApiFp(configuration).getArtistPortalOtherArtists(analytics_period, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Profile Status Artist Portal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalProfileStatus(options?: any) {
            return ArtistPortalApiFp(configuration).getArtistPortalProfileStatus(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Top Cities Artist Portal
         * @param {string} [analytics_period] The analytics period to return
         * @param {ArtistSortByQueryOptions} [sort_by] Default is downloads
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalTopCities(analytics_period?: string, sort_by?: ArtistSortByQueryOptions, limit?: number, options?: any) {
            return ArtistPortalApiFp(configuration).getArtistPortalTopCities(analytics_period, sort_by, limit, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Top Countries Artist Portal
         * @param {string} [analytics_period] The analytics period to return
         * @param {ArtistSortByQueryOptions} [sort_by] Default is downloads
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalTopCountries(analytics_period?: string, sort_by?: ArtistSortByQueryOptions, limit?: number, options?: any) {
            return ArtistPortalApiFp(configuration).getArtistPortalTopCountries(analytics_period, sort_by, limit, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Top DJ Types Artist Portal
         * @param {string} [analytics_period] The analytics period to return
         * @param {ArtistSortByQueryOptions} [sort_by] Default is downloads
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalTopDjTypes(analytics_period?: string, sort_by?: ArtistSortByQueryOptions, options?: any) {
            return ArtistPortalApiFp(configuration).getArtistPortalTopDjTypes(analytics_period, sort_by, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Top Sources Artist Portal
         * @param {string} [analytics_period] The analytics period to return
         * @param {ArtistSortByQueryOptions} [sort_by] Default is downloads
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalTopSources(analytics_period?: string, sort_by?: ArtistSortByQueryOptions, options?: any) {
            return ArtistPortalApiFp(configuration).getArtistPortalTopSources(analytics_period, sort_by, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Track Upload URL Artist Portal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalTrackUploadUrl(options?: any) {
            return ArtistPortalApiFp(configuration).getArtistPortalTrackUploadUrl(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Weekly Stats Artist Portal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistPortalWeeklyStats(options?: any) {
            return ArtistPortalApiFp(configuration).getArtistPortalWeeklyStats(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get My Artist
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManagingArtist(options?: any) {
            return ArtistPortalApiFp(configuration).getManagingArtist(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Report Album
         * @param {number} albumId The album Id
         * @param {AlbumIdReportBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportAlbum(albumId: number, body?: AlbumIdReportBody, options?: any) {
            return ArtistPortalApiFp(configuration).reportAlbum(albumId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Submit Artist Application
         * @param {Array<string>} [links] 
         * @param {Array<string>} [social_links] 
         * @param {string} [name] 
         * @param {string} [city] 
         * @param {string} [country] 
         * @param {string} [full_name] 
         * @param {string} [bio] 
         * @param {Blob} [artwork] 
         * @param {Blob} [press] 
         * @param {ArtistTrackSubmit} [track_submit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitArtistPortalApplication(links?: Array<string>, social_links?: Array<string>, name?: string, city?: string, country?: string, full_name?: string, bio?: string, artwork?: Blob, press?: Blob, track_submit?: ArtistTrackSubmit, options?: any) {
            return ArtistPortalApiFp(configuration).submitArtistPortalApplication(links, social_links, name, city, country, full_name, bio, artwork, press, track_submit, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Submit Artist Profile Change Request
         * @param {Array<string>} [social_media] 
         * @param {string} [name] 
         * @param {string} [description] 
         * @param {Blob} [artwork] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitArtistPortalProfileChangeRequest(social_media?: Array<string>, name?: string, description?: string, artwork?: Blob, options?: any) {
            return ArtistPortalApiFp(configuration).submitArtistPortalProfileChangeRequest(social_media, name, description, artwork, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Submit Artist Tracks
         * @param {string} [title] 
         * @param {string} [artist_name] 
         * @param {string} [release_date] 
         * @param {string} [genre] 
         * @param {string} [isrc] 
         * @param {string} [label] 
         * @param {string} [produced_by] 
         * @param {Blob} [track_artwork] 
         * @param {Array<ArtistTrackSubmitTracks>} [tracks] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitArtistPortalTrack(title?: string, artist_name?: string, release_date?: string, genre?: string, isrc?: string, label?: string, produced_by?: string, track_artwork?: Blob, tracks?: Array<ArtistTrackSubmitTracks>, options?: any) {
            return ArtistPortalApiFp(configuration).submitArtistPortalTrack(title, artist_name, release_date, genre, isrc, label, produced_by, track_artwork, tracks, options)(fetch, basePath);
        },
    };
};

/**
 * ArtistPortalApi - object-oriented interface
 * @export
 * @class ArtistPortalApi
 * @extends {BaseAPI}
 */
export class ArtistPortalApi extends BaseAPI {
    /**
     * 
     * @summary Get Albums for Artist Portal
     * @param {number} [limit] Default is 50 for most endpoints
     * @param {number} [skip] 
     * @param {boolean} [requestSignedUrls] 
     * @param {string} [analytics_period] The analytics period to return
     * @param {ArtistAlbumsSortByQueryOptions} [sort_by] Default is release_date
     * @param {string} [search] Search term
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistPortalApi
     */
    public getArtistPortalAlbums(limit?: number, skip?: number, requestSignedUrls?: boolean, analytics_period?: string, sort_by?: ArtistAlbumsSortByQueryOptions, search?: string, options?: any) {
        return ArtistPortalApiFp(this.configuration).getArtistPortalAlbums(limit, skip, requestSignedUrls, analytics_period, sort_by, search, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Artist Application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistPortalApi
     */
    public getArtistPortalApplication(options?: any) {
        return ArtistPortalApiFp(this.configuration).getArtistPortalApplication(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Banner Artist Portal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistPortalApi
     */
    public getArtistPortalBanner(options?: any) {
        return ArtistPortalApiFp(this.configuration).getArtistPortalBanner(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Chart Data Artist Portal
     * @param {string} type 
     * @param {number} tz_offset The time zon offest in minutes, as it comes from new Date().getTimeZoneOffset()
     * @param {string} [analytics_period] The analytics period to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistPortalApi
     */
    public getArtistPortalChartData(type: string, tz_offset: number, analytics_period?: string, options?: any) {
        return ArtistPortalApiFp(this.configuration).getArtistPortalChartData(type, tz_offset, analytics_period, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Engagement Stats Artist Portal
     * @param {string} [analytics_period] The analytics period to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistPortalApi
     */
    public getArtistPortalEnagementStats(analytics_period?: string, options?: any) {
        return ArtistPortalApiFp(this.configuration).getArtistPortalEnagementStats(analytics_period, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Other Artists Artist Portal
     * @param {string} [analytics_period] The analytics period to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistPortalApi
     */
    public getArtistPortalOtherArtists(analytics_period?: string, options?: any) {
        return ArtistPortalApiFp(this.configuration).getArtistPortalOtherArtists(analytics_period, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Profile Status Artist Portal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistPortalApi
     */
    public getArtistPortalProfileStatus(options?: any) {
        return ArtistPortalApiFp(this.configuration).getArtistPortalProfileStatus(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Top Cities Artist Portal
     * @param {string} [analytics_period] The analytics period to return
     * @param {ArtistSortByQueryOptions} [sort_by] Default is downloads
     * @param {number} [limit] Default is 50 for most endpoints
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistPortalApi
     */
    public getArtistPortalTopCities(analytics_period?: string, sort_by?: ArtistSortByQueryOptions, limit?: number, options?: any) {
        return ArtistPortalApiFp(this.configuration).getArtistPortalTopCities(analytics_period, sort_by, limit, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Top Countries Artist Portal
     * @param {string} [analytics_period] The analytics period to return
     * @param {ArtistSortByQueryOptions} [sort_by] Default is downloads
     * @param {number} [limit] Default is 50 for most endpoints
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistPortalApi
     */
    public getArtistPortalTopCountries(analytics_period?: string, sort_by?: ArtistSortByQueryOptions, limit?: number, options?: any) {
        return ArtistPortalApiFp(this.configuration).getArtistPortalTopCountries(analytics_period, sort_by, limit, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Top DJ Types Artist Portal
     * @param {string} [analytics_period] The analytics period to return
     * @param {ArtistSortByQueryOptions} [sort_by] Default is downloads
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistPortalApi
     */
    public getArtistPortalTopDjTypes(analytics_period?: string, sort_by?: ArtistSortByQueryOptions, options?: any) {
        return ArtistPortalApiFp(this.configuration).getArtistPortalTopDjTypes(analytics_period, sort_by, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Top Sources Artist Portal
     * @param {string} [analytics_period] The analytics period to return
     * @param {ArtistSortByQueryOptions} [sort_by] Default is downloads
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistPortalApi
     */
    public getArtistPortalTopSources(analytics_period?: string, sort_by?: ArtistSortByQueryOptions, options?: any) {
        return ArtistPortalApiFp(this.configuration).getArtistPortalTopSources(analytics_period, sort_by, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Track Upload URL Artist Portal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistPortalApi
     */
    public getArtistPortalTrackUploadUrl(options?: any) {
        return ArtistPortalApiFp(this.configuration).getArtistPortalTrackUploadUrl(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Weekly Stats Artist Portal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistPortalApi
     */
    public getArtistPortalWeeklyStats(options?: any) {
        return ArtistPortalApiFp(this.configuration).getArtistPortalWeeklyStats(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get My Artist
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistPortalApi
     */
    public getManagingArtist(options?: any) {
        return ArtistPortalApiFp(this.configuration).getManagingArtist(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Report Album
     * @param {number} albumId The album Id
     * @param {AlbumIdReportBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistPortalApi
     */
    public reportAlbum(albumId: number, body?: AlbumIdReportBody, options?: any) {
        return ArtistPortalApiFp(this.configuration).reportAlbum(albumId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Submit Artist Application
     * @param {Array<string>} [links] 
     * @param {Array<string>} [social_links] 
     * @param {string} [name] 
     * @param {string} [city] 
     * @param {string} [country] 
     * @param {string} [full_name] 
     * @param {string} [bio] 
     * @param {Blob} [artwork] 
     * @param {Blob} [press] 
     * @param {ArtistTrackSubmit} [track_submit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistPortalApi
     */
    public submitArtistPortalApplication(links?: Array<string>, social_links?: Array<string>, name?: string, city?: string, country?: string, full_name?: string, bio?: string, artwork?: Blob, press?: Blob, track_submit?: ArtistTrackSubmit, options?: any) {
        return ArtistPortalApiFp(this.configuration).submitArtistPortalApplication(links, social_links, name, city, country, full_name, bio, artwork, press, track_submit, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Submit Artist Profile Change Request
     * @param {Array<string>} [social_media] 
     * @param {string} [name] 
     * @param {string} [description] 
     * @param {Blob} [artwork] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistPortalApi
     */
    public submitArtistPortalProfileChangeRequest(social_media?: Array<string>, name?: string, description?: string, artwork?: Blob, options?: any) {
        return ArtistPortalApiFp(this.configuration).submitArtistPortalProfileChangeRequest(social_media, name, description, artwork, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Submit Artist Tracks
     * @param {string} [title] 
     * @param {string} [artist_name] 
     * @param {string} [release_date] 
     * @param {string} [genre] 
     * @param {string} [isrc] 
     * @param {string} [label] 
     * @param {string} [produced_by] 
     * @param {Blob} [track_artwork] 
     * @param {Array<ArtistTrackSubmitTracks>} [tracks] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistPortalApi
     */
    public submitArtistPortalTrack(title?: string, artist_name?: string, release_date?: string, genre?: string, isrc?: string, label?: string, produced_by?: string, track_artwork?: Blob, tracks?: Array<ArtistTrackSubmitTracks>, options?: any) {
        return ArtistPortalApiFp(this.configuration).submitArtistPortalTrack(title, artist_name, release_date, genre, isrc, label, produced_by, track_artwork, tracks, options)(this.fetch, this.basePath);
    }

}
/**
 * BannerApi - fetch parameter creator
 * @export
 */
export const BannerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Banners
         * @param {string} location The location to load the banners for, like &#x27;browse&#x27; or &#x27;trending&#x27;
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBanner(location: string, library?: string, options: any = {}): FetchArgs {
            // verify required parameter 'location' is not null or undefined
            if (location === null || location === undefined) {
                throw new RequiredError('location','Required parameter location was null or undefined when calling getBanner.');
            }
            const localVarPath = `/banner`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BannerApi - functional programming interface
 * @export
 */
export const BannerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Banners
         * @param {string} location The location to load the banners for, like &#x27;browse&#x27; or &#x27;trending&#x27;
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBanner(location: string, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20025> {
            const localVarFetchArgs = BannerApiFetchParamCreator(configuration).getBanner(location, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * BannerApi - factory interface
 * @export
 */
export const BannerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get Banners
         * @param {string} location The location to load the banners for, like &#x27;browse&#x27; or &#x27;trending&#x27;
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBanner(location: string, library?: string, options?: any) {
            return BannerApiFp(configuration).getBanner(location, library, options)(fetch, basePath);
        },
    };
};

/**
 * BannerApi - object-oriented interface
 * @export
 * @class BannerApi
 * @extends {BaseAPI}
 */
export class BannerApi extends BaseAPI {
    /**
     * 
     * @summary Get Banners
     * @param {string} location The location to load the banners for, like &#x27;browse&#x27; or &#x27;trending&#x27;
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannerApi
     */
    public getBanner(location: string, library?: string, options?: any) {
        return BannerApiFp(this.configuration).getBanner(location, library, options)(this.fetch, this.basePath);
    }

}
/**
 * CuratedSetApi - fetch parameter creator
 * @export
 */
export const CuratedSetApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Follow Curated Set
         * @param {number} id The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followCuratedSet(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling followCuratedSet.');
            }
            const localVarPath = `/curated-sets/{id}/follow`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Curated Set By Id
         * @param {number} id The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSet(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getCuratedSet.');
            }
            const localVarPath = `/curated-sets/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Curated Set Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetCategories(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/curated-set-categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Note: This endpoint does not have a default limit!
         * @summary Get All Curated Sets
         * @param {number} [limit] This endpoint does not have a default limit!
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSets(limit?: number, skip?: number, library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/curated-sets`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Curated Sets By Category Id
         * @param {number} id The curated set category Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetsByCatId(id: number, library?: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getCuratedSetsByCatId.');
            }
            const localVarPath = `/curated-set-categories/{id}/curated-sets`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Curated Sets By Category Slug
         * @param {string} slug The curated set category slug
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetsByCatSlug(slug: string, library?: string, options: any = {}): FetchArgs {
            // verify required parameter 'slug' is not null or undefined
            if (slug === null || slug === undefined) {
                throw new RequiredError('slug','Required parameter slug was null or undefined when calling getCuratedSetsByCatSlug.');
            }
            const localVarPath = `/curated-set-categories/{slug}/curated-sets`
                .replace(`{${'slug'}}`, encodeURIComponent(String(slug)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Curated Sets By Curator
         * @param {string} curator The curated set curator
         * @param {string} [exclude] The curated set slug to exclude
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetsByCurator(curator: string, exclude?: string, library?: string, options: any = {}): FetchArgs {
            // verify required parameter 'curator' is not null or undefined
            if (curator === null || curator === undefined) {
                throw new RequiredError('curator','Required parameter curator was null or undefined when calling getCuratedSetsByCurator.');
            }
            const localVarPath = `/curated-sets/curator/{curator}`
                .replace(`{${'curator'}}`, encodeURIComponent(String(curator)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (exclude !== undefined) {
                localVarQueryParameter['exclude'] = exclude;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Curated Set By Slug
         * @param {string} slug The curated set slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetsBySlug(slug: string, options: any = {}): FetchArgs {
            // verify required parameter 'slug' is not null or undefined
            if (slug === null || slug === undefined) {
                throw new RequiredError('slug','Required parameter slug was null or undefined when calling getCuratedSetsBySlug.');
            }
            const localVarPath = `/curated-sets/slug/{slug}`
                .replace(`{${'slug'}}`, encodeURIComponent(String(slug)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get followed Curated Sets
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowedCuratedSets(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/curated-sets/following`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all trending Curated Sets
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingCuratedSets(limit?: number, skip?: number, library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/curated-sets/trending`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search Curated Sets
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCuratedSets(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options: any = {}): FetchArgs {
            const localVarPath = `/curated-sets/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (hide_remix !== undefined) {
                localVarQueryParameter['hide_remix'] = hide_remix;
            }

            if (hide_originals !== undefined) {
                localVarQueryParameter['hide_originals'] = hide_originals;
            }

            if (hide_exclusives !== undefined) {
                localVarQueryParameter['hide_exclusives'] = hide_exclusives;
            }

            if (hide_explicit !== undefined) {
                localVarQueryParameter['hide_explicit'] = hide_explicit;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (remixer !== undefined) {
                localVarQueryParameter['remixer'] = remixer;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unfollow Curated Set
         * @param {number} id The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfollowCuratedSet(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling unfollowCuratedSet.');
            }
            const localVarPath = `/curated-sets/{id}/follow`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CuratedSetApi - functional programming interface
 * @export
 */
export const CuratedSetApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Follow Curated Set
         * @param {number} id The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followCuratedSet(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20012> {
            const localVarFetchArgs = CuratedSetApiFetchParamCreator(configuration).followCuratedSet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Curated Set By Id
         * @param {number} id The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSet(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20010> {
            const localVarFetchArgs = CuratedSetApiFetchParamCreator(configuration).getCuratedSet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get All Curated Set Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetCategories(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20017> {
            const localVarFetchArgs = CuratedSetApiFetchParamCreator(configuration).getCuratedSetCategories(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Note: This endpoint does not have a default limit!
         * @summary Get All Curated Sets
         * @param {number} [limit] This endpoint does not have a default limit!
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSets(limit?: number, skip?: number, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20011> {
            const localVarFetchArgs = CuratedSetApiFetchParamCreator(configuration).getCuratedSets(limit, skip, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Curated Sets By Category Id
         * @param {number} id The curated set category Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetsByCatId(id: number, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20012> {
            const localVarFetchArgs = CuratedSetApiFetchParamCreator(configuration).getCuratedSetsByCatId(id, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Curated Sets By Category Slug
         * @param {string} slug The curated set category slug
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetsByCatSlug(slug: string, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20012> {
            const localVarFetchArgs = CuratedSetApiFetchParamCreator(configuration).getCuratedSetsByCatSlug(slug, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Curated Sets By Curator
         * @param {string} curator The curated set curator
         * @param {string} [exclude] The curated set slug to exclude
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetsByCurator(curator: string, exclude?: string, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20012> {
            const localVarFetchArgs = CuratedSetApiFetchParamCreator(configuration).getCuratedSetsByCurator(curator, exclude, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Curated Set By Slug
         * @param {string} slug The curated set slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetsBySlug(slug: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20010> {
            const localVarFetchArgs = CuratedSetApiFetchParamCreator(configuration).getCuratedSetsBySlug(slug, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get followed Curated Sets
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowedCuratedSets(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20012> {
            const localVarFetchArgs = CuratedSetApiFetchParamCreator(configuration).getFollowedCuratedSets(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all trending Curated Sets
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingCuratedSets(limit?: number, skip?: number, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20011> {
            const localVarFetchArgs = CuratedSetApiFetchParamCreator(configuration).getTrendingCuratedSets(limit, skip, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search Curated Sets
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCuratedSets(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20011> {
            const localVarFetchArgs = CuratedSetApiFetchParamCreator(configuration).searchCuratedSets(limit, skip, category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, term, date, remixer, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Unfollow Curated Set
         * @param {number} id The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfollowCuratedSet(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20012> {
            const localVarFetchArgs = CuratedSetApiFetchParamCreator(configuration).unfollowCuratedSet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CuratedSetApi - factory interface
 * @export
 */
export const CuratedSetApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Follow Curated Set
         * @param {number} id The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followCuratedSet(id: number, options?: any) {
            return CuratedSetApiFp(configuration).followCuratedSet(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Curated Set By Id
         * @param {number} id The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSet(id: number, options?: any) {
            return CuratedSetApiFp(configuration).getCuratedSet(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get All Curated Set Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetCategories(library?: string, options?: any) {
            return CuratedSetApiFp(configuration).getCuratedSetCategories(library, options)(fetch, basePath);
        },
        /**
         * Note: This endpoint does not have a default limit!
         * @summary Get All Curated Sets
         * @param {number} [limit] This endpoint does not have a default limit!
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSets(limit?: number, skip?: number, library?: string, options?: any) {
            return CuratedSetApiFp(configuration).getCuratedSets(limit, skip, library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Curated Sets By Category Id
         * @param {number} id The curated set category Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetsByCatId(id: number, library?: string, options?: any) {
            return CuratedSetApiFp(configuration).getCuratedSetsByCatId(id, library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Curated Sets By Category Slug
         * @param {string} slug The curated set category slug
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetsByCatSlug(slug: string, library?: string, options?: any) {
            return CuratedSetApiFp(configuration).getCuratedSetsByCatSlug(slug, library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Curated Sets By Curator
         * @param {string} curator The curated set curator
         * @param {string} [exclude] The curated set slug to exclude
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetsByCurator(curator: string, exclude?: string, library?: string, options?: any) {
            return CuratedSetApiFp(configuration).getCuratedSetsByCurator(curator, exclude, library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Curated Set By Slug
         * @param {string} slug The curated set slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetsBySlug(slug: string, options?: any) {
            return CuratedSetApiFp(configuration).getCuratedSetsBySlug(slug, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get followed Curated Sets
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowedCuratedSets(library?: string, options?: any) {
            return CuratedSetApiFp(configuration).getFollowedCuratedSets(library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all trending Curated Sets
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingCuratedSets(limit?: number, skip?: number, library?: string, options?: any) {
            return CuratedSetApiFp(configuration).getTrendingCuratedSets(limit, skip, library, options)(fetch, basePath);
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search Curated Sets
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCuratedSets(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any) {
            return CuratedSetApiFp(configuration).searchCuratedSets(limit, skip, category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, term, date, remixer, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Unfollow Curated Set
         * @param {number} id The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfollowCuratedSet(id: number, options?: any) {
            return CuratedSetApiFp(configuration).unfollowCuratedSet(id, options)(fetch, basePath);
        },
    };
};

/**
 * CuratedSetApi - object-oriented interface
 * @export
 * @class CuratedSetApi
 * @extends {BaseAPI}
 */
export class CuratedSetApi extends BaseAPI {
    /**
     * 
     * @summary Follow Curated Set
     * @param {number} id The curated set Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedSetApi
     */
    public followCuratedSet(id: number, options?: any) {
        return CuratedSetApiFp(this.configuration).followCuratedSet(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Curated Set By Id
     * @param {number} id The curated set Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedSetApi
     */
    public getCuratedSet(id: number, options?: any) {
        return CuratedSetApiFp(this.configuration).getCuratedSet(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get All Curated Set Categories
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedSetApi
     */
    public getCuratedSetCategories(library?: string, options?: any) {
        return CuratedSetApiFp(this.configuration).getCuratedSetCategories(library, options)(this.fetch, this.basePath);
    }

    /**
     * Note: This endpoint does not have a default limit!
     * @summary Get All Curated Sets
     * @param {number} [limit] This endpoint does not have a default limit!
     * @param {number} [skip] 
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedSetApi
     */
    public getCuratedSets(limit?: number, skip?: number, library?: string, options?: any) {
        return CuratedSetApiFp(this.configuration).getCuratedSets(limit, skip, library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Curated Sets By Category Id
     * @param {number} id The curated set category Id
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedSetApi
     */
    public getCuratedSetsByCatId(id: number, library?: string, options?: any) {
        return CuratedSetApiFp(this.configuration).getCuratedSetsByCatId(id, library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Curated Sets By Category Slug
     * @param {string} slug The curated set category slug
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedSetApi
     */
    public getCuratedSetsByCatSlug(slug: string, library?: string, options?: any) {
        return CuratedSetApiFp(this.configuration).getCuratedSetsByCatSlug(slug, library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Curated Sets By Curator
     * @param {string} curator The curated set curator
     * @param {string} [exclude] The curated set slug to exclude
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedSetApi
     */
    public getCuratedSetsByCurator(curator: string, exclude?: string, library?: string, options?: any) {
        return CuratedSetApiFp(this.configuration).getCuratedSetsByCurator(curator, exclude, library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Curated Set By Slug
     * @param {string} slug The curated set slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedSetApi
     */
    public getCuratedSetsBySlug(slug: string, options?: any) {
        return CuratedSetApiFp(this.configuration).getCuratedSetsBySlug(slug, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get followed Curated Sets
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedSetApi
     */
    public getFollowedCuratedSets(library?: string, options?: any) {
        return CuratedSetApiFp(this.configuration).getFollowedCuratedSets(library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all trending Curated Sets
     * @param {number} [limit] Default is 50 for most endpoints
     * @param {number} [skip] 
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedSetApi
     */
    public getTrendingCuratedSets(limit?: number, skip?: number, library?: string, options?: any) {
        return CuratedSetApiFp(this.configuration).getTrendingCuratedSets(limit, skip, library, options)(this.fetch, this.basePath);
    }

    /**
     * Note: sort_by is NOT supported on this endpoint
     * @summary Search Curated Sets
     * @param {number} [limit] Default is 50 for most endpoints
     * @param {number} [skip] 
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
     * @param {string} [hide_remix] Set to 1 to hide remixes
     * @param {string} [hide_originals] Set to 1 to hide originals
     * @param {string} [hide_exclusives] Set to 1 to hide exclusives
     * @param {string} [hide_explicit] Set to 1 to hide explicit media
     * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
     * @param {StringOrStringArray} [version] version names(s) (can be multiple)
     * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {number} [bpm_min] 
     * @param {number} [bpm_max] Maxmium is 250
     * @param {string} [term] Search term
     * @param {string} [date] Date string (Example: 2021-09-10)
     * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedSetApi
     */
    public searchCuratedSets(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any) {
        return CuratedSetApiFp(this.configuration).searchCuratedSets(limit, skip, category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, term, date, remixer, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Unfollow Curated Set
     * @param {number} id The curated set Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedSetApi
     */
    public unfollowCuratedSet(id: number, options?: any) {
        return CuratedSetApiFp(this.configuration).unfollowCuratedSet(id, options)(this.fetch, this.basePath);
    }

}
/**
 * DownloadApi - fetch parameter creator
 * @export
 */
export const DownloadApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download multiple media files
         * @param {string} from_location The current location on site, like &#x27;/new-releases&#x27;
         * @param {string} from_library The library selected at the time of download. Not a filter!
         * @param {MediaDownloadmultiBody} [body] 
         * @param {string} [idempotent_token] Unique token. Prevents retry of same api calls (for longer operations).
         * @param {string} [crate] Set to &#x27;1&#x27; when download is happening from the crate page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMediaMulti(from_location: string, from_library: string, body?: MediaDownloadmultiBody, idempotent_token?: string, crate?: string, options: any = {}): FetchArgs {
            // verify required parameter 'from_location' is not null or undefined
            if (from_location === null || from_location === undefined) {
                throw new RequiredError('from_location','Required parameter from_location was null or undefined when calling downloadMediaMulti.');
            }
            // verify required parameter 'from_library' is not null or undefined
            if (from_library === null || from_library === undefined) {
                throw new RequiredError('from_library','Required parameter from_library was null or undefined when calling downloadMediaMulti.');
            }
            const localVarPath = `/media/download-multi`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (idempotent_token !== undefined) {
                localVarQueryParameter['idempotent_token'] = idempotent_token;
            }

            if (crate !== undefined) {
                localVarQueryParameter['crate'] = crate;
            }

            if (from_location !== undefined) {
                localVarQueryParameter['from_location'] = from_location;
            }

            if (from_library !== undefined) {
                localVarQueryParameter['from_library'] = from_library;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'MediaDownloadmultiBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Users download history
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {string} [term] Search term
         * @param {string} [category] The category slug
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadHistory(limit?: number, skip?: number, term?: string, category?: string, library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/download-history`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Users download history categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadHistoryCategories(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/download-history/categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get download URL for media
         * @param {number} id The media Id
         * @param {string} from_location The current location on site, like &#x27;/new-releases&#x27;
         * @param {string} from_library The library selected at the time of download. Not a filter!
         * @param {string} [crate] Set to &#x27;1&#x27; when download is happening from the crate page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadUrl(id: number, from_location: string, from_library: string, crate?: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDownloadUrl.');
            }
            // verify required parameter 'from_location' is not null or undefined
            if (from_location === null || from_location === undefined) {
                throw new RequiredError('from_location','Required parameter from_location was null or undefined when calling getDownloadUrl.');
            }
            // verify required parameter 'from_library' is not null or undefined
            if (from_library === null || from_library === undefined) {
                throw new RequiredError('from_library','Required parameter from_library was null or undefined when calling getDownloadUrl.');
            }
            const localVarPath = `/media/{id}/download`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (crate !== undefined) {
                localVarQueryParameter['crate'] = crate;
            }

            if (from_location !== undefined) {
                localVarQueryParameter['from_location'] = from_location;
            }

            if (from_library !== undefined) {
                localVarQueryParameter['from_library'] = from_library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DownloadApi - functional programming interface
 * @export
 */
export const DownloadApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download multiple media files
         * @param {string} from_location The current location on site, like &#x27;/new-releases&#x27;
         * @param {string} from_library The library selected at the time of download. Not a filter!
         * @param {MediaDownloadmultiBody} [body] 
         * @param {string} [idempotent_token] Unique token. Prevents retry of same api calls (for longer operations).
         * @param {string} [crate] Set to &#x27;1&#x27; when download is happening from the crate page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMediaMulti(from_location: string, from_library: string, body?: MediaDownloadmultiBody, idempotent_token?: string, crate?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2007> {
            const localVarFetchArgs = DownloadApiFetchParamCreator(configuration).downloadMediaMulti(from_location, from_library, body, idempotent_token, crate, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Users download history
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {string} [term] Search term
         * @param {string} [category] The category slug
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadHistory(limit?: number, skip?: number, term?: string, category?: string, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2008> {
            const localVarFetchArgs = DownloadApiFetchParamCreator(configuration).getDownloadHistory(limit, skip, term, category, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Users download history categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadHistoryCategories(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2009> {
            const localVarFetchArgs = DownloadApiFetchParamCreator(configuration).getDownloadHistoryCategories(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get download URL for media
         * @param {number} id The media Id
         * @param {string} from_location The current location on site, like &#x27;/new-releases&#x27;
         * @param {string} from_library The library selected at the time of download. Not a filter!
         * @param {string} [crate] Set to &#x27;1&#x27; when download is happening from the crate page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadUrl(id: number, from_location: string, from_library: string, crate?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2006> {
            const localVarFetchArgs = DownloadApiFetchParamCreator(configuration).getDownloadUrl(id, from_location, from_library, crate, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DownloadApi - factory interface
 * @export
 */
export const DownloadApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Download multiple media files
         * @param {string} from_location The current location on site, like &#x27;/new-releases&#x27;
         * @param {string} from_library The library selected at the time of download. Not a filter!
         * @param {MediaDownloadmultiBody} [body] 
         * @param {string} [idempotent_token] Unique token. Prevents retry of same api calls (for longer operations).
         * @param {string} [crate] Set to &#x27;1&#x27; when download is happening from the crate page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMediaMulti(from_location: string, from_library: string, body?: MediaDownloadmultiBody, idempotent_token?: string, crate?: string, options?: any) {
            return DownloadApiFp(configuration).downloadMediaMulti(from_location, from_library, body, idempotent_token, crate, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Users download history
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {string} [term] Search term
         * @param {string} [category] The category slug
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadHistory(limit?: number, skip?: number, term?: string, category?: string, library?: string, options?: any) {
            return DownloadApiFp(configuration).getDownloadHistory(limit, skip, term, category, library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Users download history categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadHistoryCategories(library?: string, options?: any) {
            return DownloadApiFp(configuration).getDownloadHistoryCategories(library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get download URL for media
         * @param {number} id The media Id
         * @param {string} from_location The current location on site, like &#x27;/new-releases&#x27;
         * @param {string} from_library The library selected at the time of download. Not a filter!
         * @param {string} [crate] Set to &#x27;1&#x27; when download is happening from the crate page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadUrl(id: number, from_location: string, from_library: string, crate?: string, options?: any) {
            return DownloadApiFp(configuration).getDownloadUrl(id, from_location, from_library, crate, options)(fetch, basePath);
        },
    };
};

/**
 * DownloadApi - object-oriented interface
 * @export
 * @class DownloadApi
 * @extends {BaseAPI}
 */
export class DownloadApi extends BaseAPI {
    /**
     * 
     * @summary Download multiple media files
     * @param {string} from_location The current location on site, like &#x27;/new-releases&#x27;
     * @param {string} from_library The library selected at the time of download. Not a filter!
     * @param {MediaDownloadmultiBody} [body] 
     * @param {string} [idempotent_token] Unique token. Prevents retry of same api calls (for longer operations).
     * @param {string} [crate] Set to &#x27;1&#x27; when download is happening from the crate page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadApi
     */
    public downloadMediaMulti(from_location: string, from_library: string, body?: MediaDownloadmultiBody, idempotent_token?: string, crate?: string, options?: any) {
        return DownloadApiFp(this.configuration).downloadMediaMulti(from_location, from_library, body, idempotent_token, crate, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Users download history
     * @param {number} [limit] Default is 50 for most endpoints
     * @param {number} [skip] 
     * @param {string} [term] Search term
     * @param {string} [category] The category slug
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadApi
     */
    public getDownloadHistory(limit?: number, skip?: number, term?: string, category?: string, library?: string, options?: any) {
        return DownloadApiFp(this.configuration).getDownloadHistory(limit, skip, term, category, library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Users download history categories
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadApi
     */
    public getDownloadHistoryCategories(library?: string, options?: any) {
        return DownloadApiFp(this.configuration).getDownloadHistoryCategories(library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get download URL for media
     * @param {number} id The media Id
     * @param {string} from_location The current location on site, like &#x27;/new-releases&#x27;
     * @param {string} from_library The library selected at the time of download. Not a filter!
     * @param {string} [crate] Set to &#x27;1&#x27; when download is happening from the crate page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadApi
     */
    public getDownloadUrl(id: number, from_location: string, from_library: string, crate?: string, options?: any) {
        return DownloadApiFp(this.configuration).getDownloadUrl(id, from_location, from_library, crate, options)(this.fetch, this.basePath);
    }

}
/**
 * FeaturedApi - fetch parameter creator
 * @export
 */
export const FeaturedApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all featured Albums
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlbumFeatured(limit?: number, skip?: number, library?: string, keyType?: string, sort_by?: SortByQueryOptions, options: any = {}): FetchArgs {
            const localVarPath = `/albums/featured`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Album Featured In
         * @param {number} id The album Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlbumFeaturedIn(id: number, library?: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAlbumFeaturedIn.');
            }
            const localVarPath = `/albums/{id}/featured-in`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Artist Featured In
         * @param {number} id The artist Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistFeaturedIn(id: number, library?: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getArtistFeaturedIn.');
            }
            const localVarPath = `/artists/{id}/featured-in`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get all featured BPM Original Albums
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedBpmoriginalAlbums(limit?: number, skip?: number, keyType?: string, sort_by?: SortByQueryOptions, library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/albums/featured-bpm-originals`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeaturedApi - functional programming interface
 * @export
 */
export const FeaturedApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all featured Albums
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlbumFeatured(limit?: number, skip?: number, library?: string, keyType?: string, sort_by?: SortByQueryOptions, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse200> {
            const localVarFetchArgs = FeaturedApiFetchParamCreator(configuration).getAlbumFeatured(limit, skip, library, keyType, sort_by, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Album Featured In
         * @param {number} id The album Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlbumFeaturedIn(id: number, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = FeaturedApiFetchParamCreator(configuration).getAlbumFeaturedIn(id, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Artist Featured In
         * @param {number} id The artist Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistFeaturedIn(id: number, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = FeaturedApiFetchParamCreator(configuration).getArtistFeaturedIn(id, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get all featured BPM Original Albums
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedBpmoriginalAlbums(limit?: number, skip?: number, keyType?: string, sort_by?: SortByQueryOptions, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse200> {
            const localVarFetchArgs = FeaturedApiFetchParamCreator(configuration).getFeaturedBpmoriginalAlbums(limit, skip, keyType, sort_by, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * FeaturedApi - factory interface
 * @export
 */
export const FeaturedApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get all featured Albums
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlbumFeatured(limit?: number, skip?: number, library?: string, keyType?: string, sort_by?: SortByQueryOptions, options?: any) {
            return FeaturedApiFp(configuration).getAlbumFeatured(limit, skip, library, keyType, sort_by, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Album Featured In
         * @param {number} id The album Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlbumFeaturedIn(id: number, library?: string, options?: any) {
            return FeaturedApiFp(configuration).getAlbumFeaturedIn(id, library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Artist Featured In
         * @param {number} id The artist Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistFeaturedIn(id: number, library?: string, options?: any) {
            return FeaturedApiFp(configuration).getArtistFeaturedIn(id, library, options)(fetch, basePath);
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get all featured BPM Original Albums
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedBpmoriginalAlbums(limit?: number, skip?: number, keyType?: string, sort_by?: SortByQueryOptions, library?: string, options?: any) {
            return FeaturedApiFp(configuration).getFeaturedBpmoriginalAlbums(limit, skip, keyType, sort_by, library, options)(fetch, basePath);
        },
    };
};

/**
 * FeaturedApi - object-oriented interface
 * @export
 * @class FeaturedApi
 * @extends {BaseAPI}
 */
export class FeaturedApi extends BaseAPI {
    /**
     * 
     * @summary Get all featured Albums
     * @param {number} [limit] Default is 50 for most endpoints
     * @param {number} [skip] 
     * @param {string} [library] Default is all to the user available libraries
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturedApi
     */
    public getAlbumFeatured(limit?: number, skip?: number, library?: string, keyType?: string, sort_by?: SortByQueryOptions, options?: any) {
        return FeaturedApiFp(this.configuration).getAlbumFeatured(limit, skip, library, keyType, sort_by, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Album Featured In
     * @param {number} id The album Id
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturedApi
     */
    public getAlbumFeaturedIn(id: number, library?: string, options?: any) {
        return FeaturedApiFp(this.configuration).getAlbumFeaturedIn(id, library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Artist Featured In
     * @param {number} id The artist Id
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturedApi
     */
    public getArtistFeaturedIn(id: number, library?: string, options?: any) {
        return FeaturedApiFp(this.configuration).getArtistFeaturedIn(id, library, options)(this.fetch, this.basePath);
    }

    /**
     * Default filters based on susbcription and region are applied automatic.
     * @summary Get all featured BPM Original Albums
     * @param {number} [limit] Default is 50 for most endpoints
     * @param {number} [skip] 
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturedApi
     */
    public getFeaturedBpmoriginalAlbums(limit?: number, skip?: number, keyType?: string, sort_by?: SortByQueryOptions, library?: string, options?: any) {
        return FeaturedApiFp(this.configuration).getFeaturedBpmoriginalAlbums(limit, skip, keyType, sort_by, library, options)(this.fetch, this.basePath);
    }

}
/**
 * MediaApi - fetch parameter creator
 * @export
 */
export const MediaApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Count Albums
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countAlbums(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options: any = {}): FetchArgs {
            const localVarPath = `/albums/count`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (hide_remix !== undefined) {
                localVarQueryParameter['hide_remix'] = hide_remix;
            }

            if (hide_originals !== undefined) {
                localVarQueryParameter['hide_originals'] = hide_originals;
            }

            if (hide_exclusives !== undefined) {
                localVarQueryParameter['hide_exclusives'] = hide_exclusives;
            }

            if (hide_explicit !== undefined) {
                localVarQueryParameter['hide_explicit'] = hide_explicit;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (remixer !== undefined) {
                localVarQueryParameter['remixer'] = remixer;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download multiple media files
         * @param {string} from_location The current location on site, like &#x27;/new-releases&#x27;
         * @param {string} from_library The library selected at the time of download. Not a filter!
         * @param {MediaDownloadmultiBody} [body] 
         * @param {string} [idempotent_token] Unique token. Prevents retry of same api calls (for longer operations).
         * @param {string} [crate] Set to &#x27;1&#x27; when download is happening from the crate page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMediaMulti(from_location: string, from_library: string, body?: MediaDownloadmultiBody, idempotent_token?: string, crate?: string, options: any = {}): FetchArgs {
            // verify required parameter 'from_location' is not null or undefined
            if (from_location === null || from_location === undefined) {
                throw new RequiredError('from_location','Required parameter from_location was null or undefined when calling downloadMediaMulti.');
            }
            // verify required parameter 'from_library' is not null or undefined
            if (from_library === null || from_library === undefined) {
                throw new RequiredError('from_library','Required parameter from_library was null or undefined when calling downloadMediaMulti.');
            }
            const localVarPath = `/media/download-multi`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (idempotent_token !== undefined) {
                localVarQueryParameter['idempotent_token'] = idempotent_token;
            }

            if (crate !== undefined) {
                localVarQueryParameter['crate'] = crate;
            }

            if (from_location !== undefined) {
                localVarQueryParameter['from_location'] = from_location;
            }

            if (from_library !== undefined) {
                localVarQueryParameter['from_library'] = from_library;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'MediaDownloadmultiBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Album By Id
         * @param {number} id The album Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlbum(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAlbum.');
            }
            const localVarPath = `/album/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all featured Albums
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlbumFeatured(limit?: number, skip?: number, library?: string, keyType?: string, sort_by?: SortByQueryOptions, options: any = {}): FetchArgs {
            const localVarPath = `/albums/featured`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Get all trending Albums
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlbumTrending(limit?: number, skip?: number, category?: StringOrStringArray, genre?: StringOrStringArray, library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/albums/trending`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [withGenres] If set to 1, will also return genres for each category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories(library?: string, withGenres?: string, options: any = {}): FetchArgs {
            const localVarPath = `/categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (withGenres !== undefined) {
                localVarQueryParameter['withGenres'] = withGenres;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Users download history
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {string} [term] Search term
         * @param {string} [category] The category slug
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadHistory(limit?: number, skip?: number, term?: string, category?: string, library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/download-history`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Users download history categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadHistoryCategories(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/download-history/categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get download URL for media
         * @param {number} id The media Id
         * @param {string} from_location The current location on site, like &#x27;/new-releases&#x27;
         * @param {string} from_library The library selected at the time of download. Not a filter!
         * @param {string} [crate] Set to &#x27;1&#x27; when download is happening from the crate page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadUrl(id: number, from_location: string, from_library: string, crate?: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDownloadUrl.');
            }
            // verify required parameter 'from_location' is not null or undefined
            if (from_location === null || from_location === undefined) {
                throw new RequiredError('from_location','Required parameter from_location was null or undefined when calling getDownloadUrl.');
            }
            // verify required parameter 'from_library' is not null or undefined
            if (from_library === null || from_library === undefined) {
                throw new RequiredError('from_library','Required parameter from_library was null or undefined when calling getDownloadUrl.');
            }
            const localVarPath = `/media/{id}/download`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (crate !== undefined) {
                localVarQueryParameter['crate'] = crate;
            }

            if (from_location !== undefined) {
                localVarQueryParameter['from_location'] = from_location;
            }

            if (from_library !== undefined) {
                localVarQueryParameter['from_library'] = from_library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Genres
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenres(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/genres`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKeys(options: any = {}): FetchArgs {
            const localVarPath = `/keys`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Media By Id
         * @param {number} id The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedia(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getMedia.');
            }
            const localVarPath = `/media/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Versions
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersions(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/versions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Report media
         * @param {number} id The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportMedia(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling reportMedia.');
            }
            const localVarPath = `/media/{id}/report`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Calling this endpoint is required after a media has been played!
         * @summary Report media play
         * @param {number} id The media Id
         * @param {IdPlayBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportMediaPlay(id: number, body?: IdPlayBody, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling reportMediaPlay.');
            }
            const localVarPath = `/media/{id}/play`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'IdPlayBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Search for Albums
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAlbum(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, sort_by?: SortByQueryOptions, term?: string, date?: string, remixer?: StringOrStringArray, options: any = {}): FetchArgs {
            const localVarPath = `/albums`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (hide_remix !== undefined) {
                localVarQueryParameter['hide_remix'] = hide_remix;
            }

            if (hide_originals !== undefined) {
                localVarQueryParameter['hide_originals'] = hide_originals;
            }

            if (hide_exclusives !== undefined) {
                localVarQueryParameter['hide_exclusives'] = hide_exclusives;
            }

            if (hide_explicit !== undefined) {
                localVarQueryParameter['hide_explicit'] = hide_explicit;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (remixer !== undefined) {
                localVarQueryParameter['remixer'] = remixer;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search Genres
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGenres(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options: any = {}): FetchArgs {
            const localVarPath = `/genres/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (hide_remix !== undefined) {
                localVarQueryParameter['hide_remix'] = hide_remix;
            }

            if (hide_originals !== undefined) {
                localVarQueryParameter['hide_originals'] = hide_originals;
            }

            if (hide_exclusives !== undefined) {
                localVarQueryParameter['hide_exclusives'] = hide_exclusives;
            }

            if (hide_explicit !== undefined) {
                localVarQueryParameter['hide_explicit'] = hide_explicit;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (remixer !== undefined) {
                localVarQueryParameter['remixer'] = remixer;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search Versions
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchVersions(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options: any = {}): FetchArgs {
            const localVarPath = `/versions/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (hide_remix !== undefined) {
                localVarQueryParameter['hide_remix'] = hide_remix;
            }

            if (hide_originals !== undefined) {
                localVarQueryParameter['hide_originals'] = hide_originals;
            }

            if (hide_exclusives !== undefined) {
                localVarQueryParameter['hide_exclusives'] = hide_exclusives;
            }

            if (hide_explicit !== undefined) {
                localVarQueryParameter['hide_explicit'] = hide_explicit;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (remixer !== undefined) {
                localVarQueryParameter['remixer'] = remixer;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MediaApi - functional programming interface
 * @export
 */
export const MediaApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Count Albums
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countAlbums(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2001> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).countAlbums(category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, term, date, remixer, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Download multiple media files
         * @param {string} from_location The current location on site, like &#x27;/new-releases&#x27;
         * @param {string} from_library The library selected at the time of download. Not a filter!
         * @param {MediaDownloadmultiBody} [body] 
         * @param {string} [idempotent_token] Unique token. Prevents retry of same api calls (for longer operations).
         * @param {string} [crate] Set to &#x27;1&#x27; when download is happening from the crate page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMediaMulti(from_location: string, from_library: string, body?: MediaDownloadmultiBody, idempotent_token?: string, crate?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2007> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).downloadMediaMulti(from_location, from_library, body, idempotent_token, crate, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Album By Id
         * @param {number} id The album Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlbum(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2002> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).getAlbum(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all featured Albums
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlbumFeatured(limit?: number, skip?: number, library?: string, keyType?: string, sort_by?: SortByQueryOptions, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse200> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).getAlbumFeatured(limit, skip, library, keyType, sort_by, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Get all trending Albums
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlbumTrending(limit?: number, skip?: number, category?: StringOrStringArray, genre?: StringOrStringArray, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse200> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).getAlbumTrending(limit, skip, category, genre, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get All Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [withGenres] If set to 1, will also return genres for each category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories(library?: string, withGenres?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2009> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).getCategories(library, withGenres, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Users download history
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {string} [term] Search term
         * @param {string} [category] The category slug
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadHistory(limit?: number, skip?: number, term?: string, category?: string, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2008> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).getDownloadHistory(limit, skip, term, category, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Users download history categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadHistoryCategories(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2009> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).getDownloadHistoryCategories(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get download URL for media
         * @param {number} id The media Id
         * @param {string} from_location The current location on site, like &#x27;/new-releases&#x27;
         * @param {string} from_library The library selected at the time of download. Not a filter!
         * @param {string} [crate] Set to &#x27;1&#x27; when download is happening from the crate page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadUrl(id: number, from_location: string, from_library: string, crate?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2006> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).getDownloadUrl(id, from_location, from_library, crate, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get All Genres
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenres(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20020> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).getGenres(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get All Keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKeys(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20021> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).getKeys(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Media By Id
         * @param {number} id The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedia(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).getMedia(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get All Versions
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersions(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20021> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).getVersions(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Report media
         * @param {number} id The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportMedia(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).reportMedia(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Calling this endpoint is required after a media has been played!
         * @summary Report media play
         * @param {number} id The media Id
         * @param {IdPlayBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportMediaPlay(id: number, body?: IdPlayBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).reportMediaPlay(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Search for Albums
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAlbum(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, sort_by?: SortByQueryOptions, term?: string, date?: string, remixer?: StringOrStringArray, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse200> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).searchAlbum(limit, skip, category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, sort_by, term, date, remixer, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Search Genres
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGenres(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20020> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).searchGenres(category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, bpm_min, bpm_max, term, date, remixer, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Search Versions
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchVersions(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20022> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).searchVersions(category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, bpm_min, bpm_max, term, date, remixer, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * MediaApi - factory interface
 * @export
 */
export const MediaApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Count Albums
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countAlbums(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any) {
            return MediaApiFp(configuration).countAlbums(category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, term, date, remixer, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Download multiple media files
         * @param {string} from_location The current location on site, like &#x27;/new-releases&#x27;
         * @param {string} from_library The library selected at the time of download. Not a filter!
         * @param {MediaDownloadmultiBody} [body] 
         * @param {string} [idempotent_token] Unique token. Prevents retry of same api calls (for longer operations).
         * @param {string} [crate] Set to &#x27;1&#x27; when download is happening from the crate page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMediaMulti(from_location: string, from_library: string, body?: MediaDownloadmultiBody, idempotent_token?: string, crate?: string, options?: any) {
            return MediaApiFp(configuration).downloadMediaMulti(from_location, from_library, body, idempotent_token, crate, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Album By Id
         * @param {number} id The album Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlbum(id: number, options?: any) {
            return MediaApiFp(configuration).getAlbum(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all featured Albums
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlbumFeatured(limit?: number, skip?: number, library?: string, keyType?: string, sort_by?: SortByQueryOptions, options?: any) {
            return MediaApiFp(configuration).getAlbumFeatured(limit, skip, library, keyType, sort_by, options)(fetch, basePath);
        },
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Get all trending Albums
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlbumTrending(limit?: number, skip?: number, category?: StringOrStringArray, genre?: StringOrStringArray, library?: string, options?: any) {
            return MediaApiFp(configuration).getAlbumTrending(limit, skip, category, genre, library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get All Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [withGenres] If set to 1, will also return genres for each category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories(library?: string, withGenres?: string, options?: any) {
            return MediaApiFp(configuration).getCategories(library, withGenres, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Users download history
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {string} [term] Search term
         * @param {string} [category] The category slug
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadHistory(limit?: number, skip?: number, term?: string, category?: string, library?: string, options?: any) {
            return MediaApiFp(configuration).getDownloadHistory(limit, skip, term, category, library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Users download history categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadHistoryCategories(library?: string, options?: any) {
            return MediaApiFp(configuration).getDownloadHistoryCategories(library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get download URL for media
         * @param {number} id The media Id
         * @param {string} from_location The current location on site, like &#x27;/new-releases&#x27;
         * @param {string} from_library The library selected at the time of download. Not a filter!
         * @param {string} [crate] Set to &#x27;1&#x27; when download is happening from the crate page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadUrl(id: number, from_location: string, from_library: string, crate?: string, options?: any) {
            return MediaApiFp(configuration).getDownloadUrl(id, from_location, from_library, crate, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get All Genres
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenres(library?: string, options?: any) {
            return MediaApiFp(configuration).getGenres(library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get All Keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKeys(options?: any) {
            return MediaApiFp(configuration).getKeys(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Media By Id
         * @param {number} id The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedia(id: number, options?: any) {
            return MediaApiFp(configuration).getMedia(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get All Versions
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersions(library?: string, options?: any) {
            return MediaApiFp(configuration).getVersions(library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Report media
         * @param {number} id The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportMedia(id: number, options?: any) {
            return MediaApiFp(configuration).reportMedia(id, options)(fetch, basePath);
        },
        /**
         * Calling this endpoint is required after a media has been played!
         * @summary Report media play
         * @param {number} id The media Id
         * @param {IdPlayBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportMediaPlay(id: number, body?: IdPlayBody, options?: any) {
            return MediaApiFp(configuration).reportMediaPlay(id, body, options)(fetch, basePath);
        },
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Search for Albums
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAlbum(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, sort_by?: SortByQueryOptions, term?: string, date?: string, remixer?: StringOrStringArray, options?: any) {
            return MediaApiFp(configuration).searchAlbum(limit, skip, category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, sort_by, term, date, remixer, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Search Genres
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGenres(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any) {
            return MediaApiFp(configuration).searchGenres(category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, bpm_min, bpm_max, term, date, remixer, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Search Versions
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchVersions(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any) {
            return MediaApiFp(configuration).searchVersions(category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, bpm_min, bpm_max, term, date, remixer, options)(fetch, basePath);
        },
    };
};

/**
 * MediaApi - object-oriented interface
 * @export
 * @class MediaApi
 * @extends {BaseAPI}
 */
export class MediaApi extends BaseAPI {
    /**
     * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
     * @summary Count Albums
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
     * @param {string} [hide_remix] Set to 1 to hide remixes
     * @param {string} [hide_originals] Set to 1 to hide originals
     * @param {string} [hide_exclusives] Set to 1 to hide exclusives
     * @param {string} [hide_explicit] Set to 1 to hide explicit media
     * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
     * @param {StringOrStringArray} [version] version names(s) (can be multiple)
     * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {number} [bpm_min] 
     * @param {number} [bpm_max] Maxmium is 250
     * @param {string} [term] Search term
     * @param {string} [date] Date string (Example: 2021-09-10)
     * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public countAlbums(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any) {
        return MediaApiFp(this.configuration).countAlbums(category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, term, date, remixer, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Download multiple media files
     * @param {string} from_location The current location on site, like &#x27;/new-releases&#x27;
     * @param {string} from_library The library selected at the time of download. Not a filter!
     * @param {MediaDownloadmultiBody} [body] 
     * @param {string} [idempotent_token] Unique token. Prevents retry of same api calls (for longer operations).
     * @param {string} [crate] Set to &#x27;1&#x27; when download is happening from the crate page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public downloadMediaMulti(from_location: string, from_library: string, body?: MediaDownloadmultiBody, idempotent_token?: string, crate?: string, options?: any) {
        return MediaApiFp(this.configuration).downloadMediaMulti(from_location, from_library, body, idempotent_token, crate, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Album By Id
     * @param {number} id The album Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getAlbum(id: number, options?: any) {
        return MediaApiFp(this.configuration).getAlbum(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all featured Albums
     * @param {number} [limit] Default is 50 for most endpoints
     * @param {number} [skip] 
     * @param {string} [library] Default is all to the user available libraries
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getAlbumFeatured(limit?: number, skip?: number, library?: string, keyType?: string, sort_by?: SortByQueryOptions, options?: any) {
        return MediaApiFp(this.configuration).getAlbumFeatured(limit, skip, library, keyType, sort_by, options)(this.fetch, this.basePath);
    }

    /**
     * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
     * @summary Get all trending Albums
     * @param {number} [limit] Default is 50 for most endpoints
     * @param {number} [skip] 
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getAlbumTrending(limit?: number, skip?: number, category?: StringOrStringArray, genre?: StringOrStringArray, library?: string, options?: any) {
        return MediaApiFp(this.configuration).getAlbumTrending(limit, skip, category, genre, library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get All Categories
     * @param {string} [library] Default is all to the user available libraries
     * @param {string} [withGenres] If set to 1, will also return genres for each category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getCategories(library?: string, withGenres?: string, options?: any) {
        return MediaApiFp(this.configuration).getCategories(library, withGenres, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Users download history
     * @param {number} [limit] Default is 50 for most endpoints
     * @param {number} [skip] 
     * @param {string} [term] Search term
     * @param {string} [category] The category slug
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getDownloadHistory(limit?: number, skip?: number, term?: string, category?: string, library?: string, options?: any) {
        return MediaApiFp(this.configuration).getDownloadHistory(limit, skip, term, category, library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Users download history categories
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getDownloadHistoryCategories(library?: string, options?: any) {
        return MediaApiFp(this.configuration).getDownloadHistoryCategories(library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get download URL for media
     * @param {number} id The media Id
     * @param {string} from_location The current location on site, like &#x27;/new-releases&#x27;
     * @param {string} from_library The library selected at the time of download. Not a filter!
     * @param {string} [crate] Set to &#x27;1&#x27; when download is happening from the crate page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getDownloadUrl(id: number, from_location: string, from_library: string, crate?: string, options?: any) {
        return MediaApiFp(this.configuration).getDownloadUrl(id, from_location, from_library, crate, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get All Genres
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getGenres(library?: string, options?: any) {
        return MediaApiFp(this.configuration).getGenres(library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get All Keys
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getKeys(options?: any) {
        return MediaApiFp(this.configuration).getKeys(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Media By Id
     * @param {number} id The media Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getMedia(id: number, options?: any) {
        return MediaApiFp(this.configuration).getMedia(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get All Versions
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getVersions(library?: string, options?: any) {
        return MediaApiFp(this.configuration).getVersions(library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Report media
     * @param {number} id The media Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public reportMedia(id: number, options?: any) {
        return MediaApiFp(this.configuration).reportMedia(id, options)(this.fetch, this.basePath);
    }

    /**
     * Calling this endpoint is required after a media has been played!
     * @summary Report media play
     * @param {number} id The media Id
     * @param {IdPlayBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public reportMediaPlay(id: number, body?: IdPlayBody, options?: any) {
        return MediaApiFp(this.configuration).reportMediaPlay(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
     * @summary Search for Albums
     * @param {number} [limit] Default is 50 for most endpoints
     * @param {number} [skip] 
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
     * @param {string} [hide_remix] Set to 1 to hide remixes
     * @param {string} [hide_originals] Set to 1 to hide originals
     * @param {string} [hide_exclusives] Set to 1 to hide exclusives
     * @param {string} [hide_explicit] Set to 1 to hide explicit media
     * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
     * @param {StringOrStringArray} [version] version names(s) (can be multiple)
     * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {number} [bpm_min] 
     * @param {number} [bpm_max] Maxmium is 250
     * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
     * @param {string} [term] Search term
     * @param {string} [date] Date string (Example: 2021-09-10)
     * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public searchAlbum(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, sort_by?: SortByQueryOptions, term?: string, date?: string, remixer?: StringOrStringArray, options?: any) {
        return MediaApiFp(this.configuration).searchAlbum(limit, skip, category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, sort_by, term, date, remixer, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Search Genres
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
     * @param {string} [hide_remix] Set to 1 to hide remixes
     * @param {string} [hide_originals] Set to 1 to hide originals
     * @param {string} [hide_exclusives] Set to 1 to hide exclusives
     * @param {string} [hide_explicit] Set to 1 to hide explicit media
     * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
     * @param {StringOrStringArray} [version] version names(s) (can be multiple)
     * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
     * @param {number} [bpm_min] 
     * @param {number} [bpm_max] Maxmium is 250
     * @param {string} [term] Search term
     * @param {string} [date] Date string (Example: 2021-09-10)
     * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public searchGenres(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any) {
        return MediaApiFp(this.configuration).searchGenres(category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, bpm_min, bpm_max, term, date, remixer, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Search Versions
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
     * @param {string} [hide_remix] Set to 1 to hide remixes
     * @param {string} [hide_originals] Set to 1 to hide originals
     * @param {string} [hide_exclusives] Set to 1 to hide exclusives
     * @param {string} [hide_explicit] Set to 1 to hide explicit media
     * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
     * @param {StringOrStringArray} [version] version names(s) (can be multiple)
     * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
     * @param {number} [bpm_min] 
     * @param {number} [bpm_max] Maxmium is 250
     * @param {string} [term] Search term
     * @param {string} [date] Date string (Example: 2021-09-10)
     * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public searchVersions(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any) {
        return MediaApiFp(this.configuration).searchVersions(category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, bpm_min, bpm_max, term, date, remixer, options)(this.fetch, this.basePath);
    }

}
/**
 * MetaApi - fetch parameter creator
 * @export
 */
export const MetaApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create User Playlist Category
         * @param {UserplaylistcategoriesBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserPlaylistCategory(body?: UserplaylistcategoriesBody, options: any = {}): FetchArgs {
            const localVarPath = `/user-playlist-categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'UserplaylistcategoriesBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete User Playlist Category
         * @param {string} id The user playlist category id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPlaylistCategory(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteUserPlaylistCategory.');
            }
            const localVarPath = `/user-playlist-categories/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Banners
         * @param {string} location The location to load the banners for, like &#x27;browse&#x27; or &#x27;trending&#x27;
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBanner(location: string, library?: string, options: any = {}): FetchArgs {
            // verify required parameter 'location' is not null or undefined
            if (location === null || location === undefined) {
                throw new RequiredError('location','Required parameter location was null or undefined when calling getBanner.');
            }
            const localVarPath = `/banner`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [withGenres] If set to 1, will also return genres for each category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories(library?: string, withGenres?: string, options: any = {}): FetchArgs {
            const localVarPath = `/categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (withGenres !== undefined) {
                localVarQueryParameter['withGenres'] = withGenres;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Curated Set Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetCategories(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/curated-set-categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Genres
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenres(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/genres`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKeys(options: any = {}): FetchArgs {
            const localVarPath = `/keys`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Playlist Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistCategories(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/playlist-categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All User Playlist Categories
         * @param {boolean} [include_view_only] Also return shared view-only playlists when set to true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistCategories(include_view_only?: boolean, options: any = {}): FetchArgs {
            const localVarPath = `/user-playlist-categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (include_view_only !== undefined) {
                localVarQueryParameter['include_view_only'] = include_view_only;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User Playlist Category
         * @param {string} id The user playlist category id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistCategory(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getUserPlaylistCategory.');
            }
            const localVarPath = `/user-playlist-categories/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Versions
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersions(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/versions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search Genres
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGenres(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options: any = {}): FetchArgs {
            const localVarPath = `/genres/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (hide_remix !== undefined) {
                localVarQueryParameter['hide_remix'] = hide_remix;
            }

            if (hide_originals !== undefined) {
                localVarQueryParameter['hide_originals'] = hide_originals;
            }

            if (hide_exclusives !== undefined) {
                localVarQueryParameter['hide_exclusives'] = hide_exclusives;
            }

            if (hide_explicit !== undefined) {
                localVarQueryParameter['hide_explicit'] = hide_explicit;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (remixer !== undefined) {
                localVarQueryParameter['remixer'] = remixer;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search Versions
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchVersions(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options: any = {}): FetchArgs {
            const localVarPath = `/versions/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (hide_remix !== undefined) {
                localVarQueryParameter['hide_remix'] = hide_remix;
            }

            if (hide_originals !== undefined) {
                localVarQueryParameter['hide_originals'] = hide_originals;
            }

            if (hide_exclusives !== undefined) {
                localVarQueryParameter['hide_exclusives'] = hide_exclusives;
            }

            if (hide_explicit !== undefined) {
                localVarQueryParameter['hide_explicit'] = hide_explicit;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (remixer !== undefined) {
                localVarQueryParameter['remixer'] = remixer;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update User Playlist Category
         * @param {string} id The user playlist category id
         * @param {UserplaylistcategoriesIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPlaylistCategory(id: string, body?: UserplaylistcategoriesIdBody, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateUserPlaylistCategory.');
            }
            const localVarPath = `/user-playlist-categories/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'UserplaylistcategoriesIdBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetaApi - functional programming interface
 * @export
 */
export const MetaApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create User Playlist Category
         * @param {UserplaylistcategoriesBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserPlaylistCategory(body?: UserplaylistcategoriesBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse201> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).createUserPlaylistCategory(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete User Playlist Category
         * @param {string} id The user playlist category id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPlaylistCategory(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20019> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).deleteUserPlaylistCategory(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Banners
         * @param {string} location The location to load the banners for, like &#x27;browse&#x27; or &#x27;trending&#x27;
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBanner(location: string, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20025> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).getBanner(location, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get All Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [withGenres] If set to 1, will also return genres for each category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories(library?: string, withGenres?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2009> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).getCategories(library, withGenres, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get All Curated Set Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetCategories(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20017> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).getCuratedSetCategories(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get All Genres
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenres(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20020> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).getGenres(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get All Keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKeys(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20021> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).getKeys(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get All Playlist Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistCategories(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20016> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).getPlaylistCategories(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get All User Playlist Categories
         * @param {boolean} [include_view_only] Also return shared view-only playlists when set to true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistCategories(include_view_only?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20018> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).getUserPlaylistCategories(include_view_only, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get User Playlist Category
         * @param {string} id The user playlist category id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistCategory(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse201> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).getUserPlaylistCategory(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get All Versions
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersions(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20021> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).getVersions(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Search Genres
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGenres(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20020> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).searchGenres(category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, bpm_min, bpm_max, term, date, remixer, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Search Versions
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchVersions(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20022> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).searchVersions(category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, bpm_min, bpm_max, term, date, remixer, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update User Playlist Category
         * @param {string} id The user playlist category id
         * @param {UserplaylistcategoriesIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPlaylistCategory(id: string, body?: UserplaylistcategoriesIdBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse201> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).updateUserPlaylistCategory(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * MetaApi - factory interface
 * @export
 */
export const MetaApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Create User Playlist Category
         * @param {UserplaylistcategoriesBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserPlaylistCategory(body?: UserplaylistcategoriesBody, options?: any) {
            return MetaApiFp(configuration).createUserPlaylistCategory(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete User Playlist Category
         * @param {string} id The user playlist category id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPlaylistCategory(id: string, options?: any) {
            return MetaApiFp(configuration).deleteUserPlaylistCategory(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Banners
         * @param {string} location The location to load the banners for, like &#x27;browse&#x27; or &#x27;trending&#x27;
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBanner(location: string, library?: string, options?: any) {
            return MetaApiFp(configuration).getBanner(location, library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get All Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [withGenres] If set to 1, will also return genres for each category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories(library?: string, withGenres?: string, options?: any) {
            return MetaApiFp(configuration).getCategories(library, withGenres, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get All Curated Set Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetCategories(library?: string, options?: any) {
            return MetaApiFp(configuration).getCuratedSetCategories(library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get All Genres
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenres(library?: string, options?: any) {
            return MetaApiFp(configuration).getGenres(library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get All Keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKeys(options?: any) {
            return MetaApiFp(configuration).getKeys(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get All Playlist Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistCategories(library?: string, options?: any) {
            return MetaApiFp(configuration).getPlaylistCategories(library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get All User Playlist Categories
         * @param {boolean} [include_view_only] Also return shared view-only playlists when set to true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistCategories(include_view_only?: boolean, options?: any) {
            return MetaApiFp(configuration).getUserPlaylistCategories(include_view_only, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get User Playlist Category
         * @param {string} id The user playlist category id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistCategory(id: string, options?: any) {
            return MetaApiFp(configuration).getUserPlaylistCategory(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get All Versions
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersions(library?: string, options?: any) {
            return MetaApiFp(configuration).getVersions(library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Search Genres
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGenres(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any) {
            return MetaApiFp(configuration).searchGenres(category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, bpm_min, bpm_max, term, date, remixer, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Search Versions
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchVersions(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any) {
            return MetaApiFp(configuration).searchVersions(category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, bpm_min, bpm_max, term, date, remixer, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update User Playlist Category
         * @param {string} id The user playlist category id
         * @param {UserplaylistcategoriesIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPlaylistCategory(id: string, body?: UserplaylistcategoriesIdBody, options?: any) {
            return MetaApiFp(configuration).updateUserPlaylistCategory(id, body, options)(fetch, basePath);
        },
    };
};

/**
 * MetaApi - object-oriented interface
 * @export
 * @class MetaApi
 * @extends {BaseAPI}
 */
export class MetaApi extends BaseAPI {
    /**
     * 
     * @summary Create User Playlist Category
     * @param {UserplaylistcategoriesBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public createUserPlaylistCategory(body?: UserplaylistcategoriesBody, options?: any) {
        return MetaApiFp(this.configuration).createUserPlaylistCategory(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete User Playlist Category
     * @param {string} id The user playlist category id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public deleteUserPlaylistCategory(id: string, options?: any) {
        return MetaApiFp(this.configuration).deleteUserPlaylistCategory(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Banners
     * @param {string} location The location to load the banners for, like &#x27;browse&#x27; or &#x27;trending&#x27;
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public getBanner(location: string, library?: string, options?: any) {
        return MetaApiFp(this.configuration).getBanner(location, library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get All Categories
     * @param {string} [library] Default is all to the user available libraries
     * @param {string} [withGenres] If set to 1, will also return genres for each category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public getCategories(library?: string, withGenres?: string, options?: any) {
        return MetaApiFp(this.configuration).getCategories(library, withGenres, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get All Curated Set Categories
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public getCuratedSetCategories(library?: string, options?: any) {
        return MetaApiFp(this.configuration).getCuratedSetCategories(library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get All Genres
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public getGenres(library?: string, options?: any) {
        return MetaApiFp(this.configuration).getGenres(library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get All Keys
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public getKeys(options?: any) {
        return MetaApiFp(this.configuration).getKeys(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get All Playlist Categories
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public getPlaylistCategories(library?: string, options?: any) {
        return MetaApiFp(this.configuration).getPlaylistCategories(library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get All User Playlist Categories
     * @param {boolean} [include_view_only] Also return shared view-only playlists when set to true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public getUserPlaylistCategories(include_view_only?: boolean, options?: any) {
        return MetaApiFp(this.configuration).getUserPlaylistCategories(include_view_only, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get User Playlist Category
     * @param {string} id The user playlist category id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public getUserPlaylistCategory(id: string, options?: any) {
        return MetaApiFp(this.configuration).getUserPlaylistCategory(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get All Versions
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public getVersions(library?: string, options?: any) {
        return MetaApiFp(this.configuration).getVersions(library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Search Genres
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
     * @param {string} [hide_remix] Set to 1 to hide remixes
     * @param {string} [hide_originals] Set to 1 to hide originals
     * @param {string} [hide_exclusives] Set to 1 to hide exclusives
     * @param {string} [hide_explicit] Set to 1 to hide explicit media
     * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
     * @param {StringOrStringArray} [version] version names(s) (can be multiple)
     * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
     * @param {number} [bpm_min] 
     * @param {number} [bpm_max] Maxmium is 250
     * @param {string} [term] Search term
     * @param {string} [date] Date string (Example: 2021-09-10)
     * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public searchGenres(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any) {
        return MetaApiFp(this.configuration).searchGenres(category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, bpm_min, bpm_max, term, date, remixer, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Search Versions
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
     * @param {string} [hide_remix] Set to 1 to hide remixes
     * @param {string} [hide_originals] Set to 1 to hide originals
     * @param {string} [hide_exclusives] Set to 1 to hide exclusives
     * @param {string} [hide_explicit] Set to 1 to hide explicit media
     * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
     * @param {StringOrStringArray} [version] version names(s) (can be multiple)
     * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
     * @param {number} [bpm_min] 
     * @param {number} [bpm_max] Maxmium is 250
     * @param {string} [term] Search term
     * @param {string} [date] Date string (Example: 2021-09-10)
     * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public searchVersions(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any) {
        return MetaApiFp(this.configuration).searchVersions(category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, bpm_min, bpm_max, term, date, remixer, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update User Playlist Category
     * @param {string} id The user playlist category id
     * @param {UserplaylistcategoriesIdBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public updateUserPlaylistCategory(id: string, body?: UserplaylistcategoriesIdBody, options?: any) {
        return MetaApiFp(this.configuration).updateUserPlaylistCategory(id, body, options)(this.fetch, this.basePath);
    }

}
/**
 * OnlineCrateApi - fetch parameter creator
 * @export
 */
export const OnlineCrateApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Media to Online Crate
         * @param {number} mediaId The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMediaToOnlineCrate(mediaId: number, options: any = {}): FetchArgs {
            // verify required parameter 'mediaId' is not null or undefined
            if (mediaId === null || mediaId === undefined) {
                throw new RequiredError('mediaId','Required parameter mediaId was null or undefined when calling addMediaToOnlineCrate.');
            }
            const localVarPath = `/online-crate/{mediaId}`
                .replace(`{${'mediaId'}}`, encodeURIComponent(String(mediaId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add multiple Media to Online Crate
         * @param {OnlinecrateBatchBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMultipleMediaToOnlineCrate(body?: OnlinecrateBatchBody, options: any = {}): FetchArgs {
            const localVarPath = `/online-crate/batch`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'OnlinecrateBatchBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Clear Online Crate
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearOnlineCrate(library?: string, category?: StringOrStringArray, options: any = {}): FetchArgs {
            const localVarPath = `/online-crate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * After successful execution, reload the crate!
         * @summary Generate Online Crate zip file
         * @param {string} id The zip id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOnlineCrateById(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling downloadOnlineCrateById.');
            }
            const localVarPath = `/online-crate/download/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * After successful execution, reload the crate! This will also send an email to the user with the zip download link. Operation can take a long time!
         * @summary Generate Online Crate zip file
         * @param {string} from_location The current location on site, like &#x27;/new-releases&#x27;
         * @param {string} from_library The library selected at the time of download.
         * @param {string} [idempotent_token] Unique token. Prevents retry of same api calls (for longer operations).
         * @param {string} [category] The category slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateOnlineCrateZip(from_location: string, from_library: string, idempotent_token?: string, category?: string, options: any = {}): FetchArgs {
            // verify required parameter 'from_location' is not null or undefined
            if (from_location === null || from_location === undefined) {
                throw new RequiredError('from_location','Required parameter from_location was null or undefined when calling generateOnlineCrateZip.');
            }
            // verify required parameter 'from_library' is not null or undefined
            if (from_library === null || from_library === undefined) {
                throw new RequiredError('from_library','Required parameter from_library was null or undefined when calling generateOnlineCrateZip.');
            }
            const localVarPath = `/online-crate/download`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (idempotent_token !== undefined) {
                localVarQueryParameter['idempotent_token'] = idempotent_token;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (from_location !== undefined) {
                localVarQueryParameter['from_location'] = from_location;
            }

            if (from_library !== undefined) {
                localVarQueryParameter['from_library'] = from_library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Online Crate
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnlineCrate(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/online-crate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Users online crate categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnlineCrateCategories(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/online-crate/categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove Media from Online Crate
         * @param {number} mediaId The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFromOnlineCrate(mediaId: number, options: any = {}): FetchArgs {
            // verify required parameter 'mediaId' is not null or undefined
            if (mediaId === null || mediaId === undefined) {
                throw new RequiredError('mediaId','Required parameter mediaId was null or undefined when calling removeFromOnlineCrate.');
            }
            const localVarPath = `/online-crate/{mediaId}`
                .replace(`{${'mediaId'}}`, encodeURIComponent(String(mediaId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove multiple Media from Online Crate
         * @param {OnlinecrateBatchBody1} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMultipleMediaFromOnlineCrate(body?: OnlinecrateBatchBody1, options: any = {}): FetchArgs {
            const localVarPath = `/online-crate/batch`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'OnlinecrateBatchBody1' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OnlineCrateApi - functional programming interface
 * @export
 */
export const OnlineCrateApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Media to Online Crate
         * @param {number} mediaId The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMediaToOnlineCrate(mediaId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20023> {
            const localVarFetchArgs = OnlineCrateApiFetchParamCreator(configuration).addMediaToOnlineCrate(mediaId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add multiple Media to Online Crate
         * @param {OnlinecrateBatchBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMultipleMediaToOnlineCrate(body?: OnlinecrateBatchBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20023> {
            const localVarFetchArgs = OnlineCrateApiFetchParamCreator(configuration).addMultipleMediaToOnlineCrate(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Clear Online Crate
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearOnlineCrate(library?: string, category?: StringOrStringArray, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20023> {
            const localVarFetchArgs = OnlineCrateApiFetchParamCreator(configuration).clearOnlineCrate(library, category, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * After successful execution, reload the crate!
         * @summary Generate Online Crate zip file
         * @param {string} id The zip id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOnlineCrateById(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20024> {
            const localVarFetchArgs = OnlineCrateApiFetchParamCreator(configuration).downloadOnlineCrateById(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * After successful execution, reload the crate! This will also send an email to the user with the zip download link. Operation can take a long time!
         * @summary Generate Online Crate zip file
         * @param {string} from_location The current location on site, like &#x27;/new-releases&#x27;
         * @param {string} from_library The library selected at the time of download.
         * @param {string} [idempotent_token] Unique token. Prevents retry of same api calls (for longer operations).
         * @param {string} [category] The category slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateOnlineCrateZip(from_location: string, from_library: string, idempotent_token?: string, category?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2007> {
            const localVarFetchArgs = OnlineCrateApiFetchParamCreator(configuration).generateOnlineCrateZip(from_location, from_library, idempotent_token, category, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Online Crate
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnlineCrate(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20023> {
            const localVarFetchArgs = OnlineCrateApiFetchParamCreator(configuration).getOnlineCrate(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Users online crate categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnlineCrateCategories(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2009> {
            const localVarFetchArgs = OnlineCrateApiFetchParamCreator(configuration).getOnlineCrateCategories(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Remove Media from Online Crate
         * @param {number} mediaId The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFromOnlineCrate(mediaId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20023> {
            const localVarFetchArgs = OnlineCrateApiFetchParamCreator(configuration).removeFromOnlineCrate(mediaId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Remove multiple Media from Online Crate
         * @param {OnlinecrateBatchBody1} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMultipleMediaFromOnlineCrate(body?: OnlinecrateBatchBody1, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20023> {
            const localVarFetchArgs = OnlineCrateApiFetchParamCreator(configuration).removeMultipleMediaFromOnlineCrate(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * OnlineCrateApi - factory interface
 * @export
 */
export const OnlineCrateApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Add Media to Online Crate
         * @param {number} mediaId The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMediaToOnlineCrate(mediaId: number, options?: any) {
            return OnlineCrateApiFp(configuration).addMediaToOnlineCrate(mediaId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add multiple Media to Online Crate
         * @param {OnlinecrateBatchBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMultipleMediaToOnlineCrate(body?: OnlinecrateBatchBody, options?: any) {
            return OnlineCrateApiFp(configuration).addMultipleMediaToOnlineCrate(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Clear Online Crate
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearOnlineCrate(library?: string, category?: StringOrStringArray, options?: any) {
            return OnlineCrateApiFp(configuration).clearOnlineCrate(library, category, options)(fetch, basePath);
        },
        /**
         * After successful execution, reload the crate!
         * @summary Generate Online Crate zip file
         * @param {string} id The zip id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOnlineCrateById(id: string, options?: any) {
            return OnlineCrateApiFp(configuration).downloadOnlineCrateById(id, options)(fetch, basePath);
        },
        /**
         * After successful execution, reload the crate! This will also send an email to the user with the zip download link. Operation can take a long time!
         * @summary Generate Online Crate zip file
         * @param {string} from_location The current location on site, like &#x27;/new-releases&#x27;
         * @param {string} from_library The library selected at the time of download.
         * @param {string} [idempotent_token] Unique token. Prevents retry of same api calls (for longer operations).
         * @param {string} [category] The category slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateOnlineCrateZip(from_location: string, from_library: string, idempotent_token?: string, category?: string, options?: any) {
            return OnlineCrateApiFp(configuration).generateOnlineCrateZip(from_location, from_library, idempotent_token, category, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Online Crate
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnlineCrate(library?: string, options?: any) {
            return OnlineCrateApiFp(configuration).getOnlineCrate(library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Users online crate categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnlineCrateCategories(library?: string, options?: any) {
            return OnlineCrateApiFp(configuration).getOnlineCrateCategories(library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Remove Media from Online Crate
         * @param {number} mediaId The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFromOnlineCrate(mediaId: number, options?: any) {
            return OnlineCrateApiFp(configuration).removeFromOnlineCrate(mediaId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Remove multiple Media from Online Crate
         * @param {OnlinecrateBatchBody1} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMultipleMediaFromOnlineCrate(body?: OnlinecrateBatchBody1, options?: any) {
            return OnlineCrateApiFp(configuration).removeMultipleMediaFromOnlineCrate(body, options)(fetch, basePath);
        },
    };
};

/**
 * OnlineCrateApi - object-oriented interface
 * @export
 * @class OnlineCrateApi
 * @extends {BaseAPI}
 */
export class OnlineCrateApi extends BaseAPI {
    /**
     * 
     * @summary Add Media to Online Crate
     * @param {number} mediaId The media Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineCrateApi
     */
    public addMediaToOnlineCrate(mediaId: number, options?: any) {
        return OnlineCrateApiFp(this.configuration).addMediaToOnlineCrate(mediaId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add multiple Media to Online Crate
     * @param {OnlinecrateBatchBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineCrateApi
     */
    public addMultipleMediaToOnlineCrate(body?: OnlinecrateBatchBody, options?: any) {
        return OnlineCrateApiFp(this.configuration).addMultipleMediaToOnlineCrate(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Clear Online Crate
     * @param {string} [library] Default is all to the user available libraries
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineCrateApi
     */
    public clearOnlineCrate(library?: string, category?: StringOrStringArray, options?: any) {
        return OnlineCrateApiFp(this.configuration).clearOnlineCrate(library, category, options)(this.fetch, this.basePath);
    }

    /**
     * After successful execution, reload the crate!
     * @summary Generate Online Crate zip file
     * @param {string} id The zip id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineCrateApi
     */
    public downloadOnlineCrateById(id: string, options?: any) {
        return OnlineCrateApiFp(this.configuration).downloadOnlineCrateById(id, options)(this.fetch, this.basePath);
    }

    /**
     * After successful execution, reload the crate! This will also send an email to the user with the zip download link. Operation can take a long time!
     * @summary Generate Online Crate zip file
     * @param {string} from_location The current location on site, like &#x27;/new-releases&#x27;
     * @param {string} from_library The library selected at the time of download.
     * @param {string} [idempotent_token] Unique token. Prevents retry of same api calls (for longer operations).
     * @param {string} [category] The category slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineCrateApi
     */
    public generateOnlineCrateZip(from_location: string, from_library: string, idempotent_token?: string, category?: string, options?: any) {
        return OnlineCrateApiFp(this.configuration).generateOnlineCrateZip(from_location, from_library, idempotent_token, category, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Online Crate
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineCrateApi
     */
    public getOnlineCrate(library?: string, options?: any) {
        return OnlineCrateApiFp(this.configuration).getOnlineCrate(library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Users online crate categories
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineCrateApi
     */
    public getOnlineCrateCategories(library?: string, options?: any) {
        return OnlineCrateApiFp(this.configuration).getOnlineCrateCategories(library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Remove Media from Online Crate
     * @param {number} mediaId The media Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineCrateApi
     */
    public removeFromOnlineCrate(mediaId: number, options?: any) {
        return OnlineCrateApiFp(this.configuration).removeFromOnlineCrate(mediaId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Remove multiple Media from Online Crate
     * @param {OnlinecrateBatchBody1} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineCrateApi
     */
    public removeMultipleMediaFromOnlineCrate(body?: OnlinecrateBatchBody1, options?: any) {
        return OnlineCrateApiFp(this.configuration).removeMultipleMediaFromOnlineCrate(body, options)(this.fetch, this.basePath);
    }

}
/**
 * PlaylistApi - fetch parameter creator
 * @export
 */
export const PlaylistApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Follow Playlist
         * @param {number} id The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followPlaylist(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling followPlaylist.');
            }
            const localVarPath = `/playlists/{id}/follow`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Featured Playlists
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedPlaylist(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/playlists/featured`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get followed Playlists
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowedPlaylists(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/playlists/following`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Playlist Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistCategories(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/playlist-categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Playlist By Id
         * @param {number} id The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSet(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPlaylistSet.');
            }
            const localVarPath = `/playlists/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Playlist By Slug
         * @param {string} slug The playlist slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSetBySlug(slug: string, options: any = {}): FetchArgs {
            // verify required parameter 'slug' is not null or undefined
            if (slug === null || slug === undefined) {
                throw new RequiredError('slug','Required parameter slug was null or undefined when calling getPlaylistSetBySlug.');
            }
            const localVarPath = `/playlists/slug/{slug}`
                .replace(`{${'slug'}}`, encodeURIComponent(String(slug)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Note: This endpoint does not have a default limit!
         * @summary Get All Playlists
         * @param {number} [limit] This endpoint does not have a default limit!
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSets(limit?: number, skip?: number, library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/playlists`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Playlists By Category Id
         * @param {number} id The playlist category Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSetsByCatId(id: number, library?: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPlaylistSetsByCatId.');
            }
            const localVarPath = `/playlist-categories/{id}/playlists`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Playlists By Category Slug
         * @param {string} slug The playlist category slug
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSetsByCatSlug(slug: string, library?: string, options: any = {}): FetchArgs {
            // verify required parameter 'slug' is not null or undefined
            if (slug === null || slug === undefined) {
                throw new RequiredError('slug','Required parameter slug was null or undefined when calling getPlaylistSetsByCatSlug.');
            }
            const localVarPath = `/playlist-categories/{slug}/playlists`
                .replace(`{${'slug'}}`, encodeURIComponent(String(slug)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all trending Playlists
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingPlaylists(limit?: number, skip?: number, library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/playlists/trending`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search Playlists
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPlaylistSets(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options: any = {}): FetchArgs {
            const localVarPath = `/playlists/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (hide_remix !== undefined) {
                localVarQueryParameter['hide_remix'] = hide_remix;
            }

            if (hide_originals !== undefined) {
                localVarQueryParameter['hide_originals'] = hide_originals;
            }

            if (hide_exclusives !== undefined) {
                localVarQueryParameter['hide_exclusives'] = hide_exclusives;
            }

            if (hide_explicit !== undefined) {
                localVarQueryParameter['hide_explicit'] = hide_explicit;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (remixer !== undefined) {
                localVarQueryParameter['remixer'] = remixer;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unfollow Playlist
         * @param {number} id The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfollowPlaylist(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling unfollowPlaylist.');
            }
            const localVarPath = `/playlists/{id}/follow`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlaylistApi - functional programming interface
 * @export
 */
export const PlaylistApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Follow Playlist
         * @param {number} id The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followPlaylist(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20015> {
            const localVarFetchArgs = PlaylistApiFetchParamCreator(configuration).followPlaylist(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Featured Playlists
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedPlaylist(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20015> {
            const localVarFetchArgs = PlaylistApiFetchParamCreator(configuration).getFeaturedPlaylist(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get followed Playlists
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowedPlaylists(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20015> {
            const localVarFetchArgs = PlaylistApiFetchParamCreator(configuration).getFollowedPlaylists(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get All Playlist Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistCategories(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20016> {
            const localVarFetchArgs = PlaylistApiFetchParamCreator(configuration).getPlaylistCategories(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Playlist By Id
         * @param {number} id The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSet(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20013> {
            const localVarFetchArgs = PlaylistApiFetchParamCreator(configuration).getPlaylistSet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Playlist By Slug
         * @param {string} slug The playlist slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSetBySlug(slug: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20013> {
            const localVarFetchArgs = PlaylistApiFetchParamCreator(configuration).getPlaylistSetBySlug(slug, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Note: This endpoint does not have a default limit!
         * @summary Get All Playlists
         * @param {number} [limit] This endpoint does not have a default limit!
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSets(limit?: number, skip?: number, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20014> {
            const localVarFetchArgs = PlaylistApiFetchParamCreator(configuration).getPlaylistSets(limit, skip, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Playlists By Category Id
         * @param {number} id The playlist category Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSetsByCatId(id: number, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20015> {
            const localVarFetchArgs = PlaylistApiFetchParamCreator(configuration).getPlaylistSetsByCatId(id, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Playlists By Category Slug
         * @param {string} slug The playlist category slug
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSetsByCatSlug(slug: string, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20015> {
            const localVarFetchArgs = PlaylistApiFetchParamCreator(configuration).getPlaylistSetsByCatSlug(slug, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all trending Playlists
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingPlaylists(limit?: number, skip?: number, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20014> {
            const localVarFetchArgs = PlaylistApiFetchParamCreator(configuration).getTrendingPlaylists(limit, skip, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search Playlists
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPlaylistSets(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20014> {
            const localVarFetchArgs = PlaylistApiFetchParamCreator(configuration).searchPlaylistSets(limit, skip, category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, term, date, remixer, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Unfollow Playlist
         * @param {number} id The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfollowPlaylist(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20015> {
            const localVarFetchArgs = PlaylistApiFetchParamCreator(configuration).unfollowPlaylist(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PlaylistApi - factory interface
 * @export
 */
export const PlaylistApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Follow Playlist
         * @param {number} id The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followPlaylist(id: number, options?: any) {
            return PlaylistApiFp(configuration).followPlaylist(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Featured Playlists
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedPlaylist(library?: string, options?: any) {
            return PlaylistApiFp(configuration).getFeaturedPlaylist(library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get followed Playlists
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowedPlaylists(library?: string, options?: any) {
            return PlaylistApiFp(configuration).getFollowedPlaylists(library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get All Playlist Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistCategories(library?: string, options?: any) {
            return PlaylistApiFp(configuration).getPlaylistCategories(library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Playlist By Id
         * @param {number} id The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSet(id: number, options?: any) {
            return PlaylistApiFp(configuration).getPlaylistSet(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Playlist By Slug
         * @param {string} slug The playlist slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSetBySlug(slug: string, options?: any) {
            return PlaylistApiFp(configuration).getPlaylistSetBySlug(slug, options)(fetch, basePath);
        },
        /**
         * Note: This endpoint does not have a default limit!
         * @summary Get All Playlists
         * @param {number} [limit] This endpoint does not have a default limit!
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSets(limit?: number, skip?: number, library?: string, options?: any) {
            return PlaylistApiFp(configuration).getPlaylistSets(limit, skip, library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Playlists By Category Id
         * @param {number} id The playlist category Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSetsByCatId(id: number, library?: string, options?: any) {
            return PlaylistApiFp(configuration).getPlaylistSetsByCatId(id, library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Playlists By Category Slug
         * @param {string} slug The playlist category slug
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSetsByCatSlug(slug: string, library?: string, options?: any) {
            return PlaylistApiFp(configuration).getPlaylistSetsByCatSlug(slug, library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all trending Playlists
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingPlaylists(limit?: number, skip?: number, library?: string, options?: any) {
            return PlaylistApiFp(configuration).getTrendingPlaylists(limit, skip, library, options)(fetch, basePath);
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search Playlists
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPlaylistSets(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any) {
            return PlaylistApiFp(configuration).searchPlaylistSets(limit, skip, category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, term, date, remixer, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Unfollow Playlist
         * @param {number} id The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfollowPlaylist(id: number, options?: any) {
            return PlaylistApiFp(configuration).unfollowPlaylist(id, options)(fetch, basePath);
        },
    };
};

/**
 * PlaylistApi - object-oriented interface
 * @export
 * @class PlaylistApi
 * @extends {BaseAPI}
 */
export class PlaylistApi extends BaseAPI {
    /**
     * 
     * @summary Follow Playlist
     * @param {number} id The playlist Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaylistApi
     */
    public followPlaylist(id: number, options?: any) {
        return PlaylistApiFp(this.configuration).followPlaylist(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Featured Playlists
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaylistApi
     */
    public getFeaturedPlaylist(library?: string, options?: any) {
        return PlaylistApiFp(this.configuration).getFeaturedPlaylist(library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get followed Playlists
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaylistApi
     */
    public getFollowedPlaylists(library?: string, options?: any) {
        return PlaylistApiFp(this.configuration).getFollowedPlaylists(library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get All Playlist Categories
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaylistApi
     */
    public getPlaylistCategories(library?: string, options?: any) {
        return PlaylistApiFp(this.configuration).getPlaylistCategories(library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Playlist By Id
     * @param {number} id The playlist Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaylistApi
     */
    public getPlaylistSet(id: number, options?: any) {
        return PlaylistApiFp(this.configuration).getPlaylistSet(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Playlist By Slug
     * @param {string} slug The playlist slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaylistApi
     */
    public getPlaylistSetBySlug(slug: string, options?: any) {
        return PlaylistApiFp(this.configuration).getPlaylistSetBySlug(slug, options)(this.fetch, this.basePath);
    }

    /**
     * Note: This endpoint does not have a default limit!
     * @summary Get All Playlists
     * @param {number} [limit] This endpoint does not have a default limit!
     * @param {number} [skip] 
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaylistApi
     */
    public getPlaylistSets(limit?: number, skip?: number, library?: string, options?: any) {
        return PlaylistApiFp(this.configuration).getPlaylistSets(limit, skip, library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Playlists By Category Id
     * @param {number} id The playlist category Id
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaylistApi
     */
    public getPlaylistSetsByCatId(id: number, library?: string, options?: any) {
        return PlaylistApiFp(this.configuration).getPlaylistSetsByCatId(id, library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Playlists By Category Slug
     * @param {string} slug The playlist category slug
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaylistApi
     */
    public getPlaylistSetsByCatSlug(slug: string, library?: string, options?: any) {
        return PlaylistApiFp(this.configuration).getPlaylistSetsByCatSlug(slug, library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all trending Playlists
     * @param {number} [limit] Default is 50 for most endpoints
     * @param {number} [skip] 
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaylistApi
     */
    public getTrendingPlaylists(limit?: number, skip?: number, library?: string, options?: any) {
        return PlaylistApiFp(this.configuration).getTrendingPlaylists(limit, skip, library, options)(this.fetch, this.basePath);
    }

    /**
     * Note: sort_by is NOT supported on this endpoint
     * @summary Search Playlists
     * @param {number} [limit] Default is 50 for most endpoints
     * @param {number} [skip] 
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
     * @param {string} [hide_remix] Set to 1 to hide remixes
     * @param {string} [hide_originals] Set to 1 to hide originals
     * @param {string} [hide_exclusives] Set to 1 to hide exclusives
     * @param {string} [hide_explicit] Set to 1 to hide explicit media
     * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
     * @param {StringOrStringArray} [version] version names(s) (can be multiple)
     * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {number} [bpm_min] 
     * @param {number} [bpm_max] Maxmium is 250
     * @param {string} [term] Search term
     * @param {string} [date] Date string (Example: 2021-09-10)
     * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaylistApi
     */
    public searchPlaylistSets(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any) {
        return PlaylistApiFp(this.configuration).searchPlaylistSets(limit, skip, category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, term, date, remixer, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Unfollow Playlist
     * @param {number} id The playlist Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaylistApi
     */
    public unfollowPlaylist(id: number, options?: any) {
        return PlaylistApiFp(this.configuration).unfollowPlaylist(id, options)(this.fetch, this.basePath);
    }

}
/**
 * ReportApi - fetch parameter creator
 * @export
 */
export const ReportApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Report media
         * @param {number} id The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportMedia(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling reportMedia.');
            }
            const localVarPath = `/media/{id}/report`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Calling this endpoint is required after a media has been played!
         * @summary Report media play
         * @param {number} id The media Id
         * @param {IdPlayBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportMediaPlay(id: number, body?: IdPlayBody, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling reportMediaPlay.');
            }
            const localVarPath = `/media/{id}/play`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'IdPlayBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Report media
         * @param {number} id The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportMedia(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = ReportApiFetchParamCreator(configuration).reportMedia(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Calling this endpoint is required after a media has been played!
         * @summary Report media play
         * @param {number} id The media Id
         * @param {IdPlayBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportMediaPlay(id: number, body?: IdPlayBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = ReportApiFetchParamCreator(configuration).reportMediaPlay(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Report media
         * @param {number} id The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportMedia(id: number, options?: any) {
            return ReportApiFp(configuration).reportMedia(id, options)(fetch, basePath);
        },
        /**
         * Calling this endpoint is required after a media has been played!
         * @summary Report media play
         * @param {number} id The media Id
         * @param {IdPlayBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportMediaPlay(id: number, body?: IdPlayBody, options?: any) {
            return ReportApiFp(configuration).reportMediaPlay(id, body, options)(fetch, basePath);
        },
    };
};

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
    /**
     * 
     * @summary Report media
     * @param {number} id The media Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportMedia(id: number, options?: any) {
        return ReportApiFp(this.configuration).reportMedia(id, options)(this.fetch, this.basePath);
    }

    /**
     * Calling this endpoint is required after a media has been played!
     * @summary Report media play
     * @param {number} id The media Id
     * @param {IdPlayBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportMediaPlay(id: number, body?: IdPlayBody, options?: any) {
        return ReportApiFp(this.configuration).reportMediaPlay(id, body, options)(this.fetch, this.basePath);
    }

}
/**
 * SearchApi - fetch parameter creator
 * @export
 */
export const SearchApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Count Albums
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countAlbums(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options: any = {}): FetchArgs {
            const localVarPath = `/albums/count`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (hide_remix !== undefined) {
                localVarQueryParameter['hide_remix'] = hide_remix;
            }

            if (hide_originals !== undefined) {
                localVarQueryParameter['hide_originals'] = hide_originals;
            }

            if (hide_exclusives !== undefined) {
                localVarQueryParameter['hide_exclusives'] = hide_exclusives;
            }

            if (hide_explicit !== undefined) {
                localVarQueryParameter['hide_explicit'] = hide_explicit;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (remixer !== undefined) {
                localVarQueryParameter['remixer'] = remixer;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get total counts per library
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counts(category?: StringOrStringArray, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options: any = {}): FetchArgs {
            const localVarPath = `/counts`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (hide_remix !== undefined) {
                localVarQueryParameter['hide_remix'] = hide_remix;
            }

            if (hide_originals !== undefined) {
                localVarQueryParameter['hide_originals'] = hide_originals;
            }

            if (hide_exclusives !== undefined) {
                localVarQueryParameter['hide_exclusives'] = hide_exclusives;
            }

            if (hide_explicit !== undefined) {
                localVarQueryParameter['hide_explicit'] = hide_explicit;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (remixer !== undefined) {
                localVarQueryParameter['remixer'] = remixer;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Search for Albums
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAlbum(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, sort_by?: SortByQueryOptions, term?: string, date?: string, remixer?: StringOrStringArray, options: any = {}): FetchArgs {
            const localVarPath = `/albums`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (hide_remix !== undefined) {
                localVarQueryParameter['hide_remix'] = hide_remix;
            }

            if (hide_originals !== undefined) {
                localVarQueryParameter['hide_originals'] = hide_originals;
            }

            if (hide_exclusives !== undefined) {
                localVarQueryParameter['hide_exclusives'] = hide_exclusives;
            }

            if (hide_explicit !== undefined) {
                localVarQueryParameter['hide_explicit'] = hide_explicit;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (remixer !== undefined) {
                localVarQueryParameter['remixer'] = remixer;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search for Artists
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchArtist(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options: any = {}): FetchArgs {
            const localVarPath = `/artists`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (hide_remix !== undefined) {
                localVarQueryParameter['hide_remix'] = hide_remix;
            }

            if (hide_originals !== undefined) {
                localVarQueryParameter['hide_originals'] = hide_originals;
            }

            if (hide_exclusives !== undefined) {
                localVarQueryParameter['hide_exclusives'] = hide_exclusives;
            }

            if (hide_explicit !== undefined) {
                localVarQueryParameter['hide_explicit'] = hide_explicit;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (remixer !== undefined) {
                localVarQueryParameter['remixer'] = remixer;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search Curated Sets
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCuratedSets(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options: any = {}): FetchArgs {
            const localVarPath = `/curated-sets/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (hide_remix !== undefined) {
                localVarQueryParameter['hide_remix'] = hide_remix;
            }

            if (hide_originals !== undefined) {
                localVarQueryParameter['hide_originals'] = hide_originals;
            }

            if (hide_exclusives !== undefined) {
                localVarQueryParameter['hide_exclusives'] = hide_exclusives;
            }

            if (hide_explicit !== undefined) {
                localVarQueryParameter['hide_explicit'] = hide_explicit;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (remixer !== undefined) {
                localVarQueryParameter['remixer'] = remixer;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search Playlists
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPlaylistSets(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options: any = {}): FetchArgs {
            const localVarPath = `/playlists/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (hide_remix !== undefined) {
                localVarQueryParameter['hide_remix'] = hide_remix;
            }

            if (hide_originals !== undefined) {
                localVarQueryParameter['hide_originals'] = hide_originals;
            }

            if (hide_exclusives !== undefined) {
                localVarQueryParameter['hide_exclusives'] = hide_exclusives;
            }

            if (hide_explicit !== undefined) {
                localVarQueryParameter['hide_explicit'] = hide_explicit;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (remixer !== undefined) {
                localVarQueryParameter['remixer'] = remixer;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Suggest Albums and Artist based on term
         * @param {string} term Search term
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestAlbumArtist(term: string, library?: string, keyType?: string, options: any = {}): FetchArgs {
            // verify required parameter 'term' is not null or undefined
            if (term === null || term === undefined) {
                throw new RequiredError('term','Required parameter term was null or undefined when calling suggestAlbumArtist.');
            }
            const localVarPath = `/suggest`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Count Albums
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countAlbums(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2001> {
            const localVarFetchArgs = SearchApiFetchParamCreator(configuration).countAlbums(category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, term, date, remixer, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get total counts per library
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counts(category?: StringOrStringArray, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20029> {
            const localVarFetchArgs = SearchApiFetchParamCreator(configuration).counts(category, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, term, date, remixer, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Search for Albums
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAlbum(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, sort_by?: SortByQueryOptions, term?: string, date?: string, remixer?: StringOrStringArray, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse200> {
            const localVarFetchArgs = SearchApiFetchParamCreator(configuration).searchAlbum(limit, skip, category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, sort_by, term, date, remixer, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search for Artists
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchArtist(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20027> {
            const localVarFetchArgs = SearchApiFetchParamCreator(configuration).searchArtist(limit, skip, category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, term, date, remixer, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search Curated Sets
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCuratedSets(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20011> {
            const localVarFetchArgs = SearchApiFetchParamCreator(configuration).searchCuratedSets(limit, skip, category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, term, date, remixer, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search Playlists
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPlaylistSets(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20014> {
            const localVarFetchArgs = SearchApiFetchParamCreator(configuration).searchPlaylistSets(limit, skip, category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, term, date, remixer, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Suggest Albums and Artist based on term
         * @param {string} term Search term
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestAlbumArtist(term: string, library?: string, keyType?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20030> {
            const localVarFetchArgs = SearchApiFetchParamCreator(configuration).suggestAlbumArtist(term, library, keyType, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Count Albums
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countAlbums(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any) {
            return SearchApiFp(configuration).countAlbums(category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, term, date, remixer, options)(fetch, basePath);
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get total counts per library
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counts(category?: StringOrStringArray, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any) {
            return SearchApiFp(configuration).counts(category, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, term, date, remixer, options)(fetch, basePath);
        },
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Search for Albums
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAlbum(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, sort_by?: SortByQueryOptions, term?: string, date?: string, remixer?: StringOrStringArray, options?: any) {
            return SearchApiFp(configuration).searchAlbum(limit, skip, category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, sort_by, term, date, remixer, options)(fetch, basePath);
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search for Artists
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchArtist(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any) {
            return SearchApiFp(configuration).searchArtist(limit, skip, category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, term, date, remixer, options)(fetch, basePath);
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search Curated Sets
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCuratedSets(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any) {
            return SearchApiFp(configuration).searchCuratedSets(limit, skip, category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, term, date, remixer, options)(fetch, basePath);
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search Playlists
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {string} [hide_remix] Set to 1 to hide remixes
         * @param {string} [hide_originals] Set to 1 to hide originals
         * @param {string} [hide_exclusives] Set to 1 to hide exclusives
         * @param {string} [hide_explicit] Set to 1 to hide explicit media
         * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPlaylistSets(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any) {
            return SearchApiFp(configuration).searchPlaylistSets(limit, skip, category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, term, date, remixer, options)(fetch, basePath);
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Suggest Albums and Artist based on term
         * @param {string} term Search term
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestAlbumArtist(term: string, library?: string, keyType?: string, options?: any) {
            return SearchApiFp(configuration).suggestAlbumArtist(term, library, keyType, options)(fetch, basePath);
        },
    };
};

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
    /**
     * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
     * @summary Count Albums
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
     * @param {string} [hide_remix] Set to 1 to hide remixes
     * @param {string} [hide_originals] Set to 1 to hide originals
     * @param {string} [hide_exclusives] Set to 1 to hide exclusives
     * @param {string} [hide_explicit] Set to 1 to hide explicit media
     * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
     * @param {StringOrStringArray} [version] version names(s) (can be multiple)
     * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {number} [bpm_min] 
     * @param {number} [bpm_max] Maxmium is 250
     * @param {string} [term] Search term
     * @param {string} [date] Date string (Example: 2021-09-10)
     * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public countAlbums(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any) {
        return SearchApiFp(this.configuration).countAlbums(category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, term, date, remixer, options)(this.fetch, this.basePath);
    }

    /**
     * Default filters based on susbcription and region are applied automatic.
     * @summary Get total counts per library
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
     * @param {string} [hide_remix] Set to 1 to hide remixes
     * @param {string} [hide_originals] Set to 1 to hide originals
     * @param {string} [hide_exclusives] Set to 1 to hide exclusives
     * @param {string} [hide_explicit] Set to 1 to hide explicit media
     * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
     * @param {StringOrStringArray} [version] version names(s) (can be multiple)
     * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {number} [bpm_min] 
     * @param {number} [bpm_max] Maxmium is 250
     * @param {string} [term] Search term
     * @param {string} [date] Date string (Example: 2021-09-10)
     * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public counts(category?: StringOrStringArray, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any) {
        return SearchApiFp(this.configuration).counts(category, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, term, date, remixer, options)(this.fetch, this.basePath);
    }

    /**
     * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
     * @summary Search for Albums
     * @param {number} [limit] Default is 50 for most endpoints
     * @param {number} [skip] 
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
     * @param {string} [hide_remix] Set to 1 to hide remixes
     * @param {string} [hide_originals] Set to 1 to hide originals
     * @param {string} [hide_exclusives] Set to 1 to hide exclusives
     * @param {string} [hide_explicit] Set to 1 to hide explicit media
     * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
     * @param {StringOrStringArray} [version] version names(s) (can be multiple)
     * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {number} [bpm_min] 
     * @param {number} [bpm_max] Maxmium is 250
     * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
     * @param {string} [term] Search term
     * @param {string} [date] Date string (Example: 2021-09-10)
     * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchAlbum(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, sort_by?: SortByQueryOptions, term?: string, date?: string, remixer?: StringOrStringArray, options?: any) {
        return SearchApiFp(this.configuration).searchAlbum(limit, skip, category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, sort_by, term, date, remixer, options)(this.fetch, this.basePath);
    }

    /**
     * Note: sort_by is NOT supported on this endpoint
     * @summary Search for Artists
     * @param {number} [limit] Default is 50 for most endpoints
     * @param {number} [skip] 
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
     * @param {string} [hide_remix] Set to 1 to hide remixes
     * @param {string} [hide_originals] Set to 1 to hide originals
     * @param {string} [hide_exclusives] Set to 1 to hide exclusives
     * @param {string} [hide_explicit] Set to 1 to hide explicit media
     * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
     * @param {StringOrStringArray} [version] version names(s) (can be multiple)
     * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {number} [bpm_min] 
     * @param {number} [bpm_max] Maxmium is 250
     * @param {string} [term] Search term
     * @param {string} [date] Date string (Example: 2021-09-10)
     * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchArtist(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any) {
        return SearchApiFp(this.configuration).searchArtist(limit, skip, category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, term, date, remixer, options)(this.fetch, this.basePath);
    }

    /**
     * Note: sort_by is NOT supported on this endpoint
     * @summary Search Curated Sets
     * @param {number} [limit] Default is 50 for most endpoints
     * @param {number} [skip] 
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
     * @param {string} [hide_remix] Set to 1 to hide remixes
     * @param {string} [hide_originals] Set to 1 to hide originals
     * @param {string} [hide_exclusives] Set to 1 to hide exclusives
     * @param {string} [hide_explicit] Set to 1 to hide explicit media
     * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
     * @param {StringOrStringArray} [version] version names(s) (can be multiple)
     * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {number} [bpm_min] 
     * @param {number} [bpm_max] Maxmium is 250
     * @param {string} [term] Search term
     * @param {string} [date] Date string (Example: 2021-09-10)
     * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchCuratedSets(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any) {
        return SearchApiFp(this.configuration).searchCuratedSets(limit, skip, category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, term, date, remixer, options)(this.fetch, this.basePath);
    }

    /**
     * Note: sort_by is NOT supported on this endpoint
     * @summary Search Playlists
     * @param {number} [limit] Default is 50 for most endpoints
     * @param {number} [skip] 
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
     * @param {string} [hide_remix] Set to 1 to hide remixes
     * @param {string} [hide_originals] Set to 1 to hide originals
     * @param {string} [hide_exclusives] Set to 1 to hide exclusives
     * @param {string} [hide_explicit] Set to 1 to hide explicit media
     * @param {string} [hide_prev_downloaded] Set to 1 to hide previous downloaded
     * @param {StringOrStringArray} [version] version names(s) (can be multiple)
     * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {number} [bpm_min] 
     * @param {number} [bpm_max] Maxmium is 250
     * @param {string} [term] Search term
     * @param {string} [date] Date string (Example: 2021-09-10)
     * @param {StringOrStringArray} [remixer] The remixer slug(s) (can be multiple)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchPlaylistSets(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, hide_remix?: string, hide_originals?: string, hide_exclusives?: string, hide_explicit?: string, hide_prev_downloaded?: string, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, remixer?: StringOrStringArray, options?: any) {
        return SearchApiFp(this.configuration).searchPlaylistSets(limit, skip, category, library, genre, artist, hide_remix, hide_originals, hide_exclusives, hide_explicit, hide_prev_downloaded, version, key, keyType, bpm_min, bpm_max, term, date, remixer, options)(this.fetch, this.basePath);
    }

    /**
     * Default filters based on susbcription and region are applied automatic.
     * @summary Suggest Albums and Artist based on term
     * @param {string} term Search term
     * @param {string} [library] Default is all to the user available libraries
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public suggestAlbumArtist(term: string, library?: string, keyType?: string, options?: any) {
        return SearchApiFp(this.configuration).suggestAlbumArtist(term, library, keyType, options)(this.fetch, this.basePath);
    }

}
/**
 * SectionApi - fetch parameter creator
 * @export
 */
export const SectionApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Section by Name
         * @param {string} library 
         * @param {string} location The location to load the section for, like &#x27;browse&#x27; or &#x27;trending&#x27;
         * @param {string} name The unique name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSectionByName(library: string, location: string, name: string, options: any = {}): FetchArgs {
            // verify required parameter 'library' is not null or undefined
            if (library === null || library === undefined) {
                throw new RequiredError('library','Required parameter library was null or undefined when calling getSectionByName.');
            }
            // verify required parameter 'location' is not null or undefined
            if (location === null || location === undefined) {
                throw new RequiredError('location','Required parameter location was null or undefined when calling getSectionByName.');
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling getSectionByName.');
            }
            const localVarPath = `/sections/{name}`
                .replace(`{${'name'}}`, encodeURIComponent(String(name)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Sections
         * @param {string} library 
         * @param {string} location The location to load the section for, like &#x27;browse&#x27; or &#x27;trending&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSections(library: string, location: string, options: any = {}): FetchArgs {
            // verify required parameter 'library' is not null or undefined
            if (library === null || library === undefined) {
                throw new RequiredError('library','Required parameter library was null or undefined when calling getSections.');
            }
            // verify required parameter 'location' is not null or undefined
            if (location === null || location === undefined) {
                throw new RequiredError('location','Required parameter location was null or undefined when calling getSections.');
            }
            const localVarPath = `/sections`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SectionApi - functional programming interface
 * @export
 */
export const SectionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Section by Name
         * @param {string} library 
         * @param {string} location The location to load the section for, like &#x27;browse&#x27; or &#x27;trending&#x27;
         * @param {string} name The unique name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSectionByName(library: string, location: string, name: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20053> {
            const localVarFetchArgs = SectionApiFetchParamCreator(configuration).getSectionByName(library, location, name, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Sections
         * @param {string} library 
         * @param {string} location The location to load the section for, like &#x27;browse&#x27; or &#x27;trending&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSections(library: string, location: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20052> {
            const localVarFetchArgs = SectionApiFetchParamCreator(configuration).getSections(library, location, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SectionApi - factory interface
 * @export
 */
export const SectionApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get Section by Name
         * @param {string} library 
         * @param {string} location The location to load the section for, like &#x27;browse&#x27; or &#x27;trending&#x27;
         * @param {string} name The unique name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSectionByName(library: string, location: string, name: string, options?: any) {
            return SectionApiFp(configuration).getSectionByName(library, location, name, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Sections
         * @param {string} library 
         * @param {string} location The location to load the section for, like &#x27;browse&#x27; or &#x27;trending&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSections(library: string, location: string, options?: any) {
            return SectionApiFp(configuration).getSections(library, location, options)(fetch, basePath);
        },
    };
};

/**
 * SectionApi - object-oriented interface
 * @export
 * @class SectionApi
 * @extends {BaseAPI}
 */
export class SectionApi extends BaseAPI {
    /**
     * 
     * @summary Get Section by Name
     * @param {string} library 
     * @param {string} location The location to load the section for, like &#x27;browse&#x27; or &#x27;trending&#x27;
     * @param {string} name The unique name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionApi
     */
    public getSectionByName(library: string, location: string, name: string, options?: any) {
        return SectionApiFp(this.configuration).getSectionByName(library, location, name, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Sections
     * @param {string} library 
     * @param {string} location The location to load the section for, like &#x27;browse&#x27; or &#x27;trending&#x27;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionApi
     */
    public getSections(library: string, location: string, options?: any) {
        return SectionApiFp(this.configuration).getSections(library, location, options)(this.fetch, this.basePath);
    }

}
/**
 * TrendingApi - fetch parameter creator
 * @export
 */
export const TrendingApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Get all trending Albums
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlbumTrending(limit?: number, skip?: number, category?: StringOrStringArray, genre?: StringOrStringArray, library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/albums/trending`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get trending Albums by period
         * @param {string} period The period
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingAlbumPeriod(period: string, limit?: number, skip?: number, category?: StringOrStringArray, genre?: StringOrStringArray, library?: string, options: any = {}): FetchArgs {
            // verify required parameter 'period' is not null or undefined
            if (period === null || period === undefined) {
                throw new RequiredError('period','Required parameter period was null or undefined when calling getTrendingAlbumPeriod.');
            }
            const localVarPath = `/albums/{period}/trending`
                .replace(`{${'period'}}`, encodeURIComponent(String(period)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all trending Playlists
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingPlaylists(limit?: number, skip?: number, library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/playlists/trending`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrendingApi - functional programming interface
 * @export
 */
export const TrendingApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Get all trending Albums
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlbumTrending(limit?: number, skip?: number, category?: StringOrStringArray, genre?: StringOrStringArray, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse200> {
            const localVarFetchArgs = TrendingApiFetchParamCreator(configuration).getAlbumTrending(limit, skip, category, genre, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get trending Albums by period
         * @param {string} period The period
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingAlbumPeriod(period: string, limit?: number, skip?: number, category?: StringOrStringArray, genre?: StringOrStringArray, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse200> {
            const localVarFetchArgs = TrendingApiFetchParamCreator(configuration).getTrendingAlbumPeriod(period, limit, skip, category, genre, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all trending Playlists
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingPlaylists(limit?: number, skip?: number, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20014> {
            const localVarFetchArgs = TrendingApiFetchParamCreator(configuration).getTrendingPlaylists(limit, skip, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TrendingApi - factory interface
 * @export
 */
export const TrendingApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Get all trending Albums
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlbumTrending(limit?: number, skip?: number, category?: StringOrStringArray, genre?: StringOrStringArray, library?: string, options?: any) {
            return TrendingApiFp(configuration).getAlbumTrending(limit, skip, category, genre, library, options)(fetch, basePath);
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get trending Albums by period
         * @param {string} period The period
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingAlbumPeriod(period: string, limit?: number, skip?: number, category?: StringOrStringArray, genre?: StringOrStringArray, library?: string, options?: any) {
            return TrendingApiFp(configuration).getTrendingAlbumPeriod(period, limit, skip, category, genre, library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all trending Playlists
         * @param {number} [limit] Default is 50 for most endpoints
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingPlaylists(limit?: number, skip?: number, library?: string, options?: any) {
            return TrendingApiFp(configuration).getTrendingPlaylists(limit, skip, library, options)(fetch, basePath);
        },
    };
};

/**
 * TrendingApi - object-oriented interface
 * @export
 * @class TrendingApi
 * @extends {BaseAPI}
 */
export class TrendingApi extends BaseAPI {
    /**
     * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
     * @summary Get all trending Albums
     * @param {number} [limit] Default is 50 for most endpoints
     * @param {number} [skip] 
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrendingApi
     */
    public getAlbumTrending(limit?: number, skip?: number, category?: StringOrStringArray, genre?: StringOrStringArray, library?: string, options?: any) {
        return TrendingApiFp(this.configuration).getAlbumTrending(limit, skip, category, genre, library, options)(this.fetch, this.basePath);
    }

    /**
     * Default filters based on susbcription and region are applied automatic.
     * @summary Get trending Albums by period
     * @param {string} period The period
     * @param {number} [limit] Default is 50 for most endpoints
     * @param {number} [skip] 
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrendingApi
     */
    public getTrendingAlbumPeriod(period: string, limit?: number, skip?: number, category?: StringOrStringArray, genre?: StringOrStringArray, library?: string, options?: any) {
        return TrendingApiFp(this.configuration).getTrendingAlbumPeriod(period, limit, skip, category, genre, library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all trending Playlists
     * @param {number} [limit] Default is 50 for most endpoints
     * @param {number} [skip] 
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrendingApi
     */
    public getTrendingPlaylists(limit?: number, skip?: number, library?: string, options?: any) {
        return TrendingApiFp(this.configuration).getTrendingPlaylists(limit, skip, library, options)(this.fetch, this.basePath);
    }

}
/**
 * UserPlaylistApi - fetch parameter creator
 * @export
 */
export const UserPlaylistApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Accept a invitation to a playlist
         * @param {string} id The invite Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptPlaylistInvite(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling acceptPlaylistInvite.');
            }
            const localVarPath = `/user-playlist-invites/{id}/accept`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add Album to User Playlist
         * @param {string} id The playlist Id
         * @param {number} albumId The album Id
         * @param {AlbumAlbumIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAlbumToUserPlaylist(id: string, albumId: number, body?: AlbumAlbumIdBody, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling addAlbumToUserPlaylist.');
            }
            // verify required parameter 'albumId' is not null or undefined
            if (albumId === null || albumId === undefined) {
                throw new RequiredError('albumId','Required parameter albumId was null or undefined when calling addAlbumToUserPlaylist.');
            }
            const localVarPath = `/user-playlists/{id}/album/{albumId}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'albumId'}}`, encodeURIComponent(String(albumId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'AlbumAlbumIdBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add Playlist to User Playlist
         * @param {string} id The playlist Id
         * @param {number} curatedSetId The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCuratedSetToUserPlaylist(id: string, curatedSetId: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling addCuratedSetToUserPlaylist.');
            }
            // verify required parameter 'curatedSetId' is not null or undefined
            if (curatedSetId === null || curatedSetId === undefined) {
                throw new RequiredError('curatedSetId','Required parameter curatedSetId was null or undefined when calling addCuratedSetToUserPlaylist.');
            }
            const localVarPath = `/user-playlists/{id}/curated-set/{curatedSetId}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'curatedSetId'}}`, encodeURIComponent(String(curatedSetId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add multiple Albums to User Playlist
         * @param {string} id The playlist Id
         * @param {IdBatchBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMultipleAlbumsToUserPlaylist(id: string, body?: IdBatchBody, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling addMultipleAlbumsToUserPlaylist.');
            }
            const localVarPath = `/user-playlists/{id}/batch`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'IdBatchBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add Playlist to User Playlist
         * @param {string} id The user playlist Id
         * @param {number} playlistId The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPlaylistToUserPlaylist(id: string, playlistId: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling addPlaylistToUserPlaylist.');
            }
            // verify required parameter 'playlistId' is not null or undefined
            if (playlistId === null || playlistId === undefined) {
                throw new RequiredError('playlistId','Required parameter playlistId was null or undefined when calling addPlaylistToUserPlaylist.');
            }
            const localVarPath = `/user-playlists/{id}/playlist/{playlistId}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'playlistId'}}`, encodeURIComponent(String(playlistId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Copy User Playlist
         * @param {string} id The playlist Id
         * @param {number} categoryId The user playlist category id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyPlaylistToOwn(id: string, categoryId: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling copyPlaylistToOwn.');
            }
            // verify required parameter 'categoryId' is not null or undefined
            if (categoryId === null || categoryId === undefined) {
                throw new RequiredError('categoryId','Required parameter categoryId was null or undefined when calling copyPlaylistToOwn.');
            }
            const localVarPath = `/user-playlists/{id}/copy/{categoryId}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'categoryId'}}`, encodeURIComponent(String(categoryId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create User Playlist
         * @param {number} id The playlist Id
         * @param {IdUserplaylistsBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserPlaylist(id: number, body?: IdUserplaylistsBody, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createUserPlaylist.');
            }
            const localVarPath = `/user-playlist-categories/{id}/user-playlists`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'IdUserplaylistsBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create User Playlist Category
         * @param {UserplaylistcategoriesBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserPlaylistCategory(body?: UserplaylistcategoriesBody, options: any = {}): FetchArgs {
            const localVarPath = `/user-playlist-categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'UserplaylistcategoriesBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create User Playlist
         * @param {UserplaylistsBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserPlaylistWithoutCategory(body?: UserplaylistsBody, options: any = {}): FetchArgs {
            const localVarPath = `/user-playlists`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'UserplaylistsBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Decline a invitation to a playlist
         * @param {string} id The invite Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declinePlaylistInvite(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling declinePlaylistInvite.');
            }
            const localVarPath = `/user-playlist-invites/{id}/decline`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Album from User Playlist
         * @param {string} id The playlist Id
         * @param {number} albumId The album Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlbumFromUserPlaylist(id: string, albumId: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteAlbumFromUserPlaylist.');
            }
            // verify required parameter 'albumId' is not null or undefined
            if (albumId === null || albumId === undefined) {
                throw new RequiredError('albumId','Required parameter albumId was null or undefined when calling deleteAlbumFromUserPlaylist.');
            }
            const localVarPath = `/user-playlists/{id}/album/{albumId}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'albumId'}}`, encodeURIComponent(String(albumId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete User Playlist
         * @param {string} id The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPlaylist(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteUserPlaylist.');
            }
            const localVarPath = `/user-playlists/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete User Playlist Category
         * @param {string} id The user playlist category id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPlaylistCategory(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteUserPlaylistCategory.');
            }
            const localVarPath = `/user-playlist-categories/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get For You User Playlists
         * @param {string} library 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForYouPlaylistSets(library: string, options: any = {}): FetchArgs {
            // verify required parameter 'library' is not null or undefined
            if (library === null || library === undefined) {
                throw new RequiredError('library','Required parameter library was null or undefined when calling getForYouPlaylistSets.');
            }
            const localVarPath = `/user-playlists/for-you`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns only accepted for collab users, for owner all invites
         * @summary Get all user invites for Playlist
         * @param {string} id The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitesForUserPlaylist(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getInvitesForUserPlaylist.');
            }
            const localVarPath = `/user-playlists/{id}/invites`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User Playlists By Category Id
         * @param {string} id The playlist category Id
         * @param {boolean} [include_view_only] Also return shared view-only playlists when set to true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSetsByCatId(id: string, include_view_only?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPlaylistSetsByCatId.');
            }
            const localVarPath = `/user-playlist-categories/{id}/user-playlists`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (include_view_only !== undefined) {
                localVarQueryParameter['include_view_only'] = include_view_only;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All User Playlist Categories
         * @param {boolean} [include_view_only] Also return shared view-only playlists when set to true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistCategories(include_view_only?: boolean, options: any = {}): FetchArgs {
            const localVarPath = `/user-playlist-categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (include_view_only !== undefined) {
                localVarQueryParameter['include_view_only'] = include_view_only;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User Playlist Category
         * @param {string} id The user playlist category id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistCategory(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getUserPlaylistCategory.');
            }
            const localVarPath = `/user-playlist-categories/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User Playlist By Id
         * @param {string} id The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistSet(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getUserPlaylistSet.');
            }
            const localVarPath = `/user-playlists/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User Playlist By Slug
         * @param {string} slug The playlist slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistSetBySlug(slug: string, options: any = {}): FetchArgs {
            // verify required parameter 'slug' is not null or undefined
            if (slug === null || slug === undefined) {
                throw new RequiredError('slug','Required parameter slug was null or undefined when calling getUserPlaylistSetBySlug.');
            }
            const localVarPath = `/user-playlists/slug/{slug}`
                .replace(`{${'slug'}}`, encodeURIComponent(String(slug)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All User Playlists
         * @param {number} [limit] This endpoint does not have a default limit!
         * @param {number} [skip] 
         * @param {boolean} [include_view_only] Also return shared view-only playlists when set to true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistSets(limit?: number, skip?: number, include_view_only?: boolean, options: any = {}): FetchArgs {
            const localVarPath = `/user-playlists`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (include_view_only !== undefined) {
                localVarQueryParameter['include_view_only'] = include_view_only;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All User Playlists with Albums
         * @param {number} [limit] This endpoint does not have a default limit!
         * @param {number} [skip] 
         * @param {boolean} [include_view_only] Also return shared view-only playlists when set to true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistsFull(limit?: number, skip?: number, include_view_only?: boolean, options: any = {}): FetchArgs {
            const localVarPath = `/user-playlists/full`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (include_view_only !== undefined) {
                localVarQueryParameter['include_view_only'] = include_view_only;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Invite user to Playlist
         * @param {string} id The playlist Id
         * @param {IdInviteBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUserToPlaylist(id: string, body?: IdInviteBody, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling inviteUserToPlaylist.');
            }
            const localVarPath = `/user-playlists/{id}/invite`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'IdInviteBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove multiple Albums from User Playlist
         * @param {number} id The playlist Id
         * @param {IdBatchBody1} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMultipleAlbumsFromUserPlaylist(id: number, body?: IdBatchBody1, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling removeMultipleAlbumsFromUserPlaylist.');
            }
            const localVarPath = `/user-playlists/{id}/batch`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'IdBatchBody1' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a user from a Playlist
         * @param {string} id The invite Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromPlaylist(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling removeUserFromPlaylist.');
            }
            const localVarPath = `/user-playlist-invites/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a user playlist invite
         * @param {string} id The invite Id
         * @param {UserplaylistinvitesIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserInvite(id: string, body?: UserplaylistinvitesIdBody, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateUserInvite.');
            }
            const localVarPath = `/user-playlist-invites/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'UserplaylistinvitesIdBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update User Playlist
         * @param {string} id The playlist Id
         * @param {UserplaylistsIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPlaylist(id: string, body?: UserplaylistsIdBody, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateUserPlaylist.');
            }
            const localVarPath = `/user-playlists/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'UserplaylistsIdBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update User Playlist Category
         * @param {string} id The user playlist category id
         * @param {UserplaylistcategoriesIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPlaylistCategory(id: string, body?: UserplaylistcategoriesIdBody, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateUserPlaylistCategory.');
            }
            const localVarPath = `/user-playlist-categories/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'UserplaylistcategoriesIdBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update User Playlist Image
         * @param {string} id The playlist Id
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPlaylistImage(id: string, file?: Blob, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateUserPlaylistImage.');
            }
            const localVarPath = `/user-playlists/{id}/image`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (file !== undefined) {
                localVarFormParams.set('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserPlaylistApi - functional programming interface
 * @export
 */
export const UserPlaylistApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Accept a invitation to a playlist
         * @param {string} id The invite Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptPlaylistInvite(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20037> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).acceptPlaylistInvite(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add Album to User Playlist
         * @param {string} id The playlist Id
         * @param {number} albumId The album Id
         * @param {AlbumAlbumIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAlbumToUserPlaylist(id: string, albumId: number, body?: AlbumAlbumIdBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20031> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).addAlbumToUserPlaylist(id, albumId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add Playlist to User Playlist
         * @param {string} id The playlist Id
         * @param {number} curatedSetId The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCuratedSetToUserPlaylist(id: string, curatedSetId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20031> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).addCuratedSetToUserPlaylist(id, curatedSetId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add multiple Albums to User Playlist
         * @param {string} id The playlist Id
         * @param {IdBatchBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMultipleAlbumsToUserPlaylist(id: string, body?: IdBatchBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20031> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).addMultipleAlbumsToUserPlaylist(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add Playlist to User Playlist
         * @param {string} id The user playlist Id
         * @param {number} playlistId The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPlaylistToUserPlaylist(id: string, playlistId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20031> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).addPlaylistToUserPlaylist(id, playlistId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Copy User Playlist
         * @param {string} id The playlist Id
         * @param {number} categoryId The user playlist category id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyPlaylistToOwn(id: string, categoryId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20031> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).copyPlaylistToOwn(id, categoryId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create User Playlist
         * @param {number} id The playlist Id
         * @param {IdUserplaylistsBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserPlaylist(id: number, body?: IdUserplaylistsBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20031> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).createUserPlaylist(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create User Playlist Category
         * @param {UserplaylistcategoriesBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserPlaylistCategory(body?: UserplaylistcategoriesBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse201> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).createUserPlaylistCategory(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create User Playlist
         * @param {UserplaylistsBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserPlaylistWithoutCategory(body?: UserplaylistsBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20031> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).createUserPlaylistWithoutCategory(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Decline a invitation to a playlist
         * @param {string} id The invite Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declinePlaylistInvite(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20037> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).declinePlaylistInvite(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete Album from User Playlist
         * @param {string} id The playlist Id
         * @param {number} albumId The album Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlbumFromUserPlaylist(id: string, albumId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20031> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).deleteAlbumFromUserPlaylist(id, albumId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete User Playlist
         * @param {string} id The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPlaylist(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20019> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).deleteUserPlaylist(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete User Playlist Category
         * @param {string} id The user playlist category id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPlaylistCategory(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20019> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).deleteUserPlaylistCategory(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get For You User Playlists
         * @param {string} library 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForYouPlaylistSets(library: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20034> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).getForYouPlaylistSets(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Returns only accepted for collab users, for owner all invites
         * @summary Get all user invites for Playlist
         * @param {string} id The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitesForUserPlaylist(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20036> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).getInvitesForUserPlaylist(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get User Playlists By Category Id
         * @param {string} id The playlist category Id
         * @param {boolean} [include_view_only] Also return shared view-only playlists when set to true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSetsByCatId(id: string, include_view_only?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20035> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).getPlaylistSetsByCatId(id, include_view_only, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get All User Playlist Categories
         * @param {boolean} [include_view_only] Also return shared view-only playlists when set to true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistCategories(include_view_only?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20018> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).getUserPlaylistCategories(include_view_only, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get User Playlist Category
         * @param {string} id The user playlist category id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistCategory(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse201> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).getUserPlaylistCategory(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get User Playlist By Id
         * @param {string} id The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistSet(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20031> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).getUserPlaylistSet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get User Playlist By Slug
         * @param {string} slug The playlist slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistSetBySlug(slug: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20031> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).getUserPlaylistSetBySlug(slug, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get All User Playlists
         * @param {number} [limit] This endpoint does not have a default limit!
         * @param {number} [skip] 
         * @param {boolean} [include_view_only] Also return shared view-only playlists when set to true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistSets(limit?: number, skip?: number, include_view_only?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20032> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).getUserPlaylistSets(limit, skip, include_view_only, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get All User Playlists with Albums
         * @param {number} [limit] This endpoint does not have a default limit!
         * @param {number} [skip] 
         * @param {boolean} [include_view_only] Also return shared view-only playlists when set to true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistsFull(limit?: number, skip?: number, include_view_only?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20033> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).getUserPlaylistsFull(limit, skip, include_view_only, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Invite user to Playlist
         * @param {string} id The playlist Id
         * @param {IdInviteBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUserToPlaylist(id: string, body?: IdInviteBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20036> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).inviteUserToPlaylist(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Remove multiple Albums from User Playlist
         * @param {number} id The playlist Id
         * @param {IdBatchBody1} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMultipleAlbumsFromUserPlaylist(id: number, body?: IdBatchBody1, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20031> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).removeMultipleAlbumsFromUserPlaylist(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Remove a user from a Playlist
         * @param {string} id The invite Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromPlaylist(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20036> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).removeUserFromPlaylist(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update a user playlist invite
         * @param {string} id The invite Id
         * @param {UserplaylistinvitesIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserInvite(id: string, body?: UserplaylistinvitesIdBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20037> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).updateUserInvite(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update User Playlist
         * @param {string} id The playlist Id
         * @param {UserplaylistsIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPlaylist(id: string, body?: UserplaylistsIdBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20031> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).updateUserPlaylist(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update User Playlist Category
         * @param {string} id The user playlist category id
         * @param {UserplaylistcategoriesIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPlaylistCategory(id: string, body?: UserplaylistcategoriesIdBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse201> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).updateUserPlaylistCategory(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update User Playlist Image
         * @param {string} id The playlist Id
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPlaylistImage(id: string, file?: Blob, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20031> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).updateUserPlaylistImage(id, file, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UserPlaylistApi - factory interface
 * @export
 */
export const UserPlaylistApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Accept a invitation to a playlist
         * @param {string} id The invite Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptPlaylistInvite(id: string, options?: any) {
            return UserPlaylistApiFp(configuration).acceptPlaylistInvite(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add Album to User Playlist
         * @param {string} id The playlist Id
         * @param {number} albumId The album Id
         * @param {AlbumAlbumIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAlbumToUserPlaylist(id: string, albumId: number, body?: AlbumAlbumIdBody, options?: any) {
            return UserPlaylistApiFp(configuration).addAlbumToUserPlaylist(id, albumId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add Playlist to User Playlist
         * @param {string} id The playlist Id
         * @param {number} curatedSetId The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCuratedSetToUserPlaylist(id: string, curatedSetId: number, options?: any) {
            return UserPlaylistApiFp(configuration).addCuratedSetToUserPlaylist(id, curatedSetId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add multiple Albums to User Playlist
         * @param {string} id The playlist Id
         * @param {IdBatchBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMultipleAlbumsToUserPlaylist(id: string, body?: IdBatchBody, options?: any) {
            return UserPlaylistApiFp(configuration).addMultipleAlbumsToUserPlaylist(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add Playlist to User Playlist
         * @param {string} id The user playlist Id
         * @param {number} playlistId The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPlaylistToUserPlaylist(id: string, playlistId: number, options?: any) {
            return UserPlaylistApiFp(configuration).addPlaylistToUserPlaylist(id, playlistId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Copy User Playlist
         * @param {string} id The playlist Id
         * @param {number} categoryId The user playlist category id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyPlaylistToOwn(id: string, categoryId: number, options?: any) {
            return UserPlaylistApiFp(configuration).copyPlaylistToOwn(id, categoryId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create User Playlist
         * @param {number} id The playlist Id
         * @param {IdUserplaylistsBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserPlaylist(id: number, body?: IdUserplaylistsBody, options?: any) {
            return UserPlaylistApiFp(configuration).createUserPlaylist(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create User Playlist Category
         * @param {UserplaylistcategoriesBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserPlaylistCategory(body?: UserplaylistcategoriesBody, options?: any) {
            return UserPlaylistApiFp(configuration).createUserPlaylistCategory(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create User Playlist
         * @param {UserplaylistsBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserPlaylistWithoutCategory(body?: UserplaylistsBody, options?: any) {
            return UserPlaylistApiFp(configuration).createUserPlaylistWithoutCategory(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Decline a invitation to a playlist
         * @param {string} id The invite Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declinePlaylistInvite(id: string, options?: any) {
            return UserPlaylistApiFp(configuration).declinePlaylistInvite(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete Album from User Playlist
         * @param {string} id The playlist Id
         * @param {number} albumId The album Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlbumFromUserPlaylist(id: string, albumId: number, options?: any) {
            return UserPlaylistApiFp(configuration).deleteAlbumFromUserPlaylist(id, albumId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete User Playlist
         * @param {string} id The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPlaylist(id: string, options?: any) {
            return UserPlaylistApiFp(configuration).deleteUserPlaylist(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete User Playlist Category
         * @param {string} id The user playlist category id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPlaylistCategory(id: string, options?: any) {
            return UserPlaylistApiFp(configuration).deleteUserPlaylistCategory(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get For You User Playlists
         * @param {string} library 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForYouPlaylistSets(library: string, options?: any) {
            return UserPlaylistApiFp(configuration).getForYouPlaylistSets(library, options)(fetch, basePath);
        },
        /**
         * Returns only accepted for collab users, for owner all invites
         * @summary Get all user invites for Playlist
         * @param {string} id The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitesForUserPlaylist(id: string, options?: any) {
            return UserPlaylistApiFp(configuration).getInvitesForUserPlaylist(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get User Playlists By Category Id
         * @param {string} id The playlist category Id
         * @param {boolean} [include_view_only] Also return shared view-only playlists when set to true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSetsByCatId(id: string, include_view_only?: boolean, options?: any) {
            return UserPlaylistApiFp(configuration).getPlaylistSetsByCatId(id, include_view_only, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get All User Playlist Categories
         * @param {boolean} [include_view_only] Also return shared view-only playlists when set to true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistCategories(include_view_only?: boolean, options?: any) {
            return UserPlaylistApiFp(configuration).getUserPlaylistCategories(include_view_only, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get User Playlist Category
         * @param {string} id The user playlist category id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistCategory(id: string, options?: any) {
            return UserPlaylistApiFp(configuration).getUserPlaylistCategory(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get User Playlist By Id
         * @param {string} id The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistSet(id: string, options?: any) {
            return UserPlaylistApiFp(configuration).getUserPlaylistSet(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get User Playlist By Slug
         * @param {string} slug The playlist slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistSetBySlug(slug: string, options?: any) {
            return UserPlaylistApiFp(configuration).getUserPlaylistSetBySlug(slug, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get All User Playlists
         * @param {number} [limit] This endpoint does not have a default limit!
         * @param {number} [skip] 
         * @param {boolean} [include_view_only] Also return shared view-only playlists when set to true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistSets(limit?: number, skip?: number, include_view_only?: boolean, options?: any) {
            return UserPlaylistApiFp(configuration).getUserPlaylistSets(limit, skip, include_view_only, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get All User Playlists with Albums
         * @param {number} [limit] This endpoint does not have a default limit!
         * @param {number} [skip] 
         * @param {boolean} [include_view_only] Also return shared view-only playlists when set to true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistsFull(limit?: number, skip?: number, include_view_only?: boolean, options?: any) {
            return UserPlaylistApiFp(configuration).getUserPlaylistsFull(limit, skip, include_view_only, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Invite user to Playlist
         * @param {string} id The playlist Id
         * @param {IdInviteBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUserToPlaylist(id: string, body?: IdInviteBody, options?: any) {
            return UserPlaylistApiFp(configuration).inviteUserToPlaylist(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Remove multiple Albums from User Playlist
         * @param {number} id The playlist Id
         * @param {IdBatchBody1} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMultipleAlbumsFromUserPlaylist(id: number, body?: IdBatchBody1, options?: any) {
            return UserPlaylistApiFp(configuration).removeMultipleAlbumsFromUserPlaylist(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Remove a user from a Playlist
         * @param {string} id The invite Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromPlaylist(id: string, options?: any) {
            return UserPlaylistApiFp(configuration).removeUserFromPlaylist(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update a user playlist invite
         * @param {string} id The invite Id
         * @param {UserplaylistinvitesIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserInvite(id: string, body?: UserplaylistinvitesIdBody, options?: any) {
            return UserPlaylistApiFp(configuration).updateUserInvite(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update User Playlist
         * @param {string} id The playlist Id
         * @param {UserplaylistsIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPlaylist(id: string, body?: UserplaylistsIdBody, options?: any) {
            return UserPlaylistApiFp(configuration).updateUserPlaylist(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update User Playlist Category
         * @param {string} id The user playlist category id
         * @param {UserplaylistcategoriesIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPlaylistCategory(id: string, body?: UserplaylistcategoriesIdBody, options?: any) {
            return UserPlaylistApiFp(configuration).updateUserPlaylistCategory(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update User Playlist Image
         * @param {string} id The playlist Id
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPlaylistImage(id: string, file?: Blob, options?: any) {
            return UserPlaylistApiFp(configuration).updateUserPlaylistImage(id, file, options)(fetch, basePath);
        },
    };
};

/**
 * UserPlaylistApi - object-oriented interface
 * @export
 * @class UserPlaylistApi
 * @extends {BaseAPI}
 */
export class UserPlaylistApi extends BaseAPI {
    /**
     * 
     * @summary Accept a invitation to a playlist
     * @param {string} id The invite Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public acceptPlaylistInvite(id: string, options?: any) {
        return UserPlaylistApiFp(this.configuration).acceptPlaylistInvite(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add Album to User Playlist
     * @param {string} id The playlist Id
     * @param {number} albumId The album Id
     * @param {AlbumAlbumIdBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public addAlbumToUserPlaylist(id: string, albumId: number, body?: AlbumAlbumIdBody, options?: any) {
        return UserPlaylistApiFp(this.configuration).addAlbumToUserPlaylist(id, albumId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add Playlist to User Playlist
     * @param {string} id The playlist Id
     * @param {number} curatedSetId The curated set Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public addCuratedSetToUserPlaylist(id: string, curatedSetId: number, options?: any) {
        return UserPlaylistApiFp(this.configuration).addCuratedSetToUserPlaylist(id, curatedSetId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add multiple Albums to User Playlist
     * @param {string} id The playlist Id
     * @param {IdBatchBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public addMultipleAlbumsToUserPlaylist(id: string, body?: IdBatchBody, options?: any) {
        return UserPlaylistApiFp(this.configuration).addMultipleAlbumsToUserPlaylist(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add Playlist to User Playlist
     * @param {string} id The user playlist Id
     * @param {number} playlistId The playlist Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public addPlaylistToUserPlaylist(id: string, playlistId: number, options?: any) {
        return UserPlaylistApiFp(this.configuration).addPlaylistToUserPlaylist(id, playlistId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Copy User Playlist
     * @param {string} id The playlist Id
     * @param {number} categoryId The user playlist category id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public copyPlaylistToOwn(id: string, categoryId: number, options?: any) {
        return UserPlaylistApiFp(this.configuration).copyPlaylistToOwn(id, categoryId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create User Playlist
     * @param {number} id The playlist Id
     * @param {IdUserplaylistsBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public createUserPlaylist(id: number, body?: IdUserplaylistsBody, options?: any) {
        return UserPlaylistApiFp(this.configuration).createUserPlaylist(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create User Playlist Category
     * @param {UserplaylistcategoriesBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public createUserPlaylistCategory(body?: UserplaylistcategoriesBody, options?: any) {
        return UserPlaylistApiFp(this.configuration).createUserPlaylistCategory(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create User Playlist
     * @param {UserplaylistsBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public createUserPlaylistWithoutCategory(body?: UserplaylistsBody, options?: any) {
        return UserPlaylistApiFp(this.configuration).createUserPlaylistWithoutCategory(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Decline a invitation to a playlist
     * @param {string} id The invite Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public declinePlaylistInvite(id: string, options?: any) {
        return UserPlaylistApiFp(this.configuration).declinePlaylistInvite(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete Album from User Playlist
     * @param {string} id The playlist Id
     * @param {number} albumId The album Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public deleteAlbumFromUserPlaylist(id: string, albumId: number, options?: any) {
        return UserPlaylistApiFp(this.configuration).deleteAlbumFromUserPlaylist(id, albumId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete User Playlist
     * @param {string} id The playlist Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public deleteUserPlaylist(id: string, options?: any) {
        return UserPlaylistApiFp(this.configuration).deleteUserPlaylist(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete User Playlist Category
     * @param {string} id The user playlist category id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public deleteUserPlaylistCategory(id: string, options?: any) {
        return UserPlaylistApiFp(this.configuration).deleteUserPlaylistCategory(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get For You User Playlists
     * @param {string} library 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public getForYouPlaylistSets(library: string, options?: any) {
        return UserPlaylistApiFp(this.configuration).getForYouPlaylistSets(library, options)(this.fetch, this.basePath);
    }

    /**
     * Returns only accepted for collab users, for owner all invites
     * @summary Get all user invites for Playlist
     * @param {string} id The playlist Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public getInvitesForUserPlaylist(id: string, options?: any) {
        return UserPlaylistApiFp(this.configuration).getInvitesForUserPlaylist(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get User Playlists By Category Id
     * @param {string} id The playlist category Id
     * @param {boolean} [include_view_only] Also return shared view-only playlists when set to true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public getPlaylistSetsByCatId(id: string, include_view_only?: boolean, options?: any) {
        return UserPlaylistApiFp(this.configuration).getPlaylistSetsByCatId(id, include_view_only, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get All User Playlist Categories
     * @param {boolean} [include_view_only] Also return shared view-only playlists when set to true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public getUserPlaylistCategories(include_view_only?: boolean, options?: any) {
        return UserPlaylistApiFp(this.configuration).getUserPlaylistCategories(include_view_only, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get User Playlist Category
     * @param {string} id The user playlist category id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public getUserPlaylistCategory(id: string, options?: any) {
        return UserPlaylistApiFp(this.configuration).getUserPlaylistCategory(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get User Playlist By Id
     * @param {string} id The playlist Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public getUserPlaylistSet(id: string, options?: any) {
        return UserPlaylistApiFp(this.configuration).getUserPlaylistSet(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get User Playlist By Slug
     * @param {string} slug The playlist slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public getUserPlaylistSetBySlug(slug: string, options?: any) {
        return UserPlaylistApiFp(this.configuration).getUserPlaylistSetBySlug(slug, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get All User Playlists
     * @param {number} [limit] This endpoint does not have a default limit!
     * @param {number} [skip] 
     * @param {boolean} [include_view_only] Also return shared view-only playlists when set to true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public getUserPlaylistSets(limit?: number, skip?: number, include_view_only?: boolean, options?: any) {
        return UserPlaylistApiFp(this.configuration).getUserPlaylistSets(limit, skip, include_view_only, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get All User Playlists with Albums
     * @param {number} [limit] This endpoint does not have a default limit!
     * @param {number} [skip] 
     * @param {boolean} [include_view_only] Also return shared view-only playlists when set to true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public getUserPlaylistsFull(limit?: number, skip?: number, include_view_only?: boolean, options?: any) {
        return UserPlaylistApiFp(this.configuration).getUserPlaylistsFull(limit, skip, include_view_only, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Invite user to Playlist
     * @param {string} id The playlist Id
     * @param {IdInviteBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public inviteUserToPlaylist(id: string, body?: IdInviteBody, options?: any) {
        return UserPlaylistApiFp(this.configuration).inviteUserToPlaylist(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Remove multiple Albums from User Playlist
     * @param {number} id The playlist Id
     * @param {IdBatchBody1} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public removeMultipleAlbumsFromUserPlaylist(id: number, body?: IdBatchBody1, options?: any) {
        return UserPlaylistApiFp(this.configuration).removeMultipleAlbumsFromUserPlaylist(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Remove a user from a Playlist
     * @param {string} id The invite Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public removeUserFromPlaylist(id: string, options?: any) {
        return UserPlaylistApiFp(this.configuration).removeUserFromPlaylist(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update a user playlist invite
     * @param {string} id The invite Id
     * @param {UserplaylistinvitesIdBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public updateUserInvite(id: string, body?: UserplaylistinvitesIdBody, options?: any) {
        return UserPlaylistApiFp(this.configuration).updateUserInvite(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update User Playlist
     * @param {string} id The playlist Id
     * @param {UserplaylistsIdBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public updateUserPlaylist(id: string, body?: UserplaylistsIdBody, options?: any) {
        return UserPlaylistApiFp(this.configuration).updateUserPlaylist(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update User Playlist Category
     * @param {string} id The user playlist category id
     * @param {UserplaylistcategoriesIdBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public updateUserPlaylistCategory(id: string, body?: UserplaylistcategoriesIdBody, options?: any) {
        return UserPlaylistApiFp(this.configuration).updateUserPlaylistCategory(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update User Playlist Image
     * @param {string} id The playlist Id
     * @param {Blob} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public updateUserPlaylistImage(id: string, file?: Blob, options?: any) {
        return UserPlaylistApiFp(this.configuration).updateUserPlaylistImage(id, file, options)(this.fetch, this.basePath);
    }

}
