import { AdminApi, SoundPackage, Sound, CreateCuratedSet } from '@bpm-web-app/create-api-sdk';
import { createAdminConfig } from '../../config';

export class Admin {
    static client = new AdminApi(createAdminConfig);

    static updateSoundPackage = (id: string, args: Partial<SoundPackage>) => Admin.client.adminUpdateSoundPackage(id, args as any);

    static updateSound = (id: string, args: Partial<Sound>) => Admin.client.adminUpdateSound(id, args as any);

    static uploadSound = (soundPackageId: string, file: File) => Admin.client.adminUploadSoundFile(soundPackageId, file);

    static addTagsToSound = (id: string, tagIds: string[]) => Admin.client.adminAddTagSound(id, { id: tagIds });

    static getTagCategories = () => Admin.client.adminGetTagCategories();

    static removeTagFromSound = (id: string, tagId: string) => Admin.client.adminRemoveTagSound(id, tagId);

    static getKeys = () => Admin.client.adminGetKeys();

    static getGenres = () => Admin.client.adminGetGenres();

    static getSubGenres = () => Admin.client.adminGetSubGenres();

    static getCuratedCategories = () => Admin.client.adminGetCuratedCategories();

    static uploadCuratedImage = (file: File) => Admin.client.adminUploadCuratedImage(file);

    static uploadCuratedDemo = (file: File) => Admin.client.adminUploadCuratedDemo(file);

    static createCuratedSet = (args: CreateCuratedSet) => Admin.client.adminCreateCuratedSet(args);
}
