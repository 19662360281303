import { CustomIcon } from '../../shared/custom-icon/custom-icon';
import styles from './header-platforms-button.module.css';

interface HeaderPlatformsButtonProps {
    onClick?: () => void;
    isOpen?: boolean;
}

export function HeaderPlatformsButton({ onClick, isOpen }: HeaderPlatformsButtonProps) {
    return (
        <CustomIcon
            type="switch-platforms-icon"
            isIconHoverActive={isOpen}
            hasIconHover
            hasBackgroundHover
            size="regular"
            container="round"
            containerSize="regular"
            tooltip="Platforms"
            hasActiveState
            onClick={onClick}
            className={styles['header-platforms-button']}
            tooltipBottomOffset={-4}
        />
    );
}
