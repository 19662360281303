import classNames from 'classnames';
import AddToPlaylistIcon from '../../../../assets/icons/supreme/add-to-playlist.svg';
import RemoveFromFavoritesIcon from '../../../../assets/icons/supreme/favorites-filled.svg';
import AddToFavoritesIcon from '../../../../assets/icons/supreme/favorites-outline.svg';
import ThreeDotsIcon from '../../../../assets/icons/supreme/three-dots.svg';
import styles from '../../supreme-maxi-player.module.css';

interface QuickActionsProps {
    isFavorite: boolean | undefined;
    openPlaylistsModal: (e: React.MouseEvent<HTMLButtonElement>) => void;
    openThreeDots: (e: React.MouseEvent<HTMLElement>) => void;
    likeOrUnlike: () => void;
}

export function QuickActions({ openPlaylistsModal, openThreeDots, isFavorite, likeOrUnlike }: QuickActionsProps) {
    return (
        <div className={classNames(styles['maxi-player__desktop-actions-quick-actions'])}>
            <button
                className={classNames(styles['maxi-player__player-ctrl'], 'three-dots-button')}
                type="button"
                onClick={openPlaylistsModal}
                aria-label="Add to Playlist"
                data-tip="Add to Playlist"
                data-tip-show
                data-delay-show="400"
            >
                <AddToPlaylistIcon />
            </button>
            <button
                className={classNames(styles['maxi-player__player-ctrl'], styles['maxi-player__player-ctrl--like'])}
                type="button"
                onClick={likeOrUnlike}
                aria-label={isFavorite ? 'Remove from Favorites' : 'Add to Favorites'}
                data-tip={isFavorite ? 'Remove from Favorites' : 'Add to Favorites'}
                data-tip-show
                data-delay-show="400"
            >
                {isFavorite ? <RemoveFromFavoritesIcon /> : <AddToFavoritesIcon />}
            </button>

            <button
                className={classNames(styles['maxi-player__player-ctrl'], styles['maxi-player__player-ctrl--show-more'], 'three-dots-button')}
                type="button"
                aria-label="More"
                data-tip="Show More"
                data-tip-show
                data-delay-show="400"
                onClick={openThreeDots}
            >
                <ThreeDotsIcon />
            </button>
        </div>
    );
}
