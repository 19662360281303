import { ContestApi, IdCommentBody, SubmissionIdBody } from '@bpm-web-app/create-api-sdk';
import { createConfig } from '../../config';

export class Contest {
    static client = new ContestApi(createConfig);

    static getContests = () => Contest.client.getContests();

    static getContestSubmissions = (id: string, limit?: number, skip?: number, submissionId?: string) =>
        Contest.client.getContestSubmissions(id, limit, skip, submissionId);

    static getContestComents = (id: string, limit?: number, skip?: number) => Contest.client.getContestComents(id, limit, skip);

    static postContestComent = (id: string, body: IdCommentBody) => Contest.client.postContestComent(id, body);

    static editContestComent = (id: string, body: IdCommentBody) => Contest.client.editContestComent(id, body);

    static contestReportDownloadStems = (id: string) => Contest.client.contestReportDownloadStems(id);

    static editContestSubmission = (id: string, body?: SubmissionIdBody) => Contest.client.editContestSubmission(id, body);

    static likeContestComment = (id: string) => Contest.client.likeContestComent(id);

    static likeContestSubmission = (id: string) => Contest.client.likeContestSubmission(id);

    static unlikeContestSubmission = (id: string) => Contest.client.unlikeContestSubmission(id);

    static contestReportStreamSubmission = (id: string) => Contest.client.contestReportStreamSubmission(id);
}
