import { CuratedSet } from '@bpm-web-app/download-api-sdk';
import { appendQueryParams } from '@bpm-web-app/utils';
import Card from '../card';
import CardGhostLoading from '../card-ghost-loading';

interface CuratedSetCardProps {
    curatedSet?: CuratedSet;
    curatedSets?: CuratedSet[];
    isGhost?: boolean;
    className?: string;
}

export function CuratedSetCard({ curatedSet, curatedSets, isGhost, className }: CuratedSetCardProps) {
    if (isGhost) {
        return (
            <CardGhostLoading contentType="curatedSet" />
        );
    }

    return curatedSet ? (
        <Card
            cardSize="small"
            id={curatedSet.id}
            title={curatedSet.title}
            descriptionProps={{ artist: curatedSet.artist, artists: curatedSet.artists }}
            imageUrl={appendQueryParams(curatedSet.image_url, { key: 'dw', value: 160 })}
            imageUrl2x={appendQueryParams(curatedSet.image_url, { key: 'dw', value: 320 })}
            isPlayable
            link={`/curated-set/${curatedSet.id}`}
            contentType="curatedSet"
            currentPlaylist={curatedSets}
            className={className}
        />
    ) : null;
}
