import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { Collapse } from 'react-collapse';
import Slider from '../ui/slider/slider';
import styles from './bpm-filter.module.css';
import ChevronUp from '../../../assets/icons/chevron-up.svg';

/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
export interface BpmFilterProps {
    value: [number, number];
    onChange: (value: [number, number]) => void;
    justSlider?: boolean;
}

export function BpmFilter({ value, onChange, justSlider }: BpmFilterProps) {
    const [isSectionExpanded, setIsSectionExpanded] = useState(true);
    const hasValueSet = !(value[0] === 0 && value[1] === 250);

    const [minValue, setMinValue] = useState(value[0]);
    const [maxValue, setMaxValue] = useState(value[1]);

    const propMinValue = useMemo(() => value[0], [value]);
    const propMaxValue = useMemo(() => value[1], [value]);

    useEffect(() => {
        setMinValue(propMinValue);
        setMaxValue(propMaxValue);
    }, [propMaxValue, propMinValue]);

    const filterComponent = () => (
        <>
            <div className={styles['bpm-filter__inputs']}>
                <input
                    className="generic__input"
                    placeholder="Min BPM"
                    onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value !== '' && re.test(e.target.value) && Number(e.target.value) < 250 && Number(e.target.value) > 0) {
                            setMinValue(Number(e.target.value));
                        } else if (e.target.value === '') {
                            setMinValue(0);
                        }
                    }}
                    value={minValue > 0 ? minValue : ''}
                    onBlur={() => {
                        if (value[0] !== minValue) {
                            onChange([minValue, value[1]]);
                        }
                        if (maxValue < minValue) {
                            setMaxValue(minValue);
                            onChange([minValue, minValue]);
                        }
                    }} />
                <div className={styles['bpm-filter__inputs-divider']}>-</div>
                <input
                    className="generic__input"
                    placeholder="Max BPM"
                    onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value !== '' && re.test(e.target.value) && Number(e.target.value) < 250 && Number(e.target.value) > 0) {
                            setMaxValue(Number(e.target.value));
                        } else if (e.target.value === '') {
                            setMaxValue(250);
                        }
                    }}
                    value={maxValue < 250 ? maxValue : ''}
                    onBlur={() => {
                        if (value[1] !== maxValue) {
                            onChange([value[0], maxValue]);
                        }
                        if (minValue > maxValue) {
                            setMinValue(maxValue);
                            onChange([maxValue, maxValue]);
                        }
                    }} />
            </div>
            <div className={styles['bpm-filter__slider']}>
                <Slider value={value} range={[0, 250]} onChange={onChange} />
            </div>
        </>
    );

    if (justSlider) {
        return filterComponent();
    }

    return (
        /* eslint-disable-next-line @typescript-eslint/dot-notation */
        <div className={classNames('filter', styles['bpm-filter'])}>
            <div
                role="button"
                onClick={() => setIsSectionExpanded(!isSectionExpanded)}
                tabIndex={0}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        setIsSectionExpanded(!isSectionExpanded);
                    }
                }}
                className="filter__header">
                <div className={classNames('filter__title', { 'filter__title-active': hasValueSet })}>BPM {hasValueSet ? '(1)' : ''}</div>
                {hasValueSet && (
                    <button type="button" className="filter__reset" onClick={() => onChange([0, 250])}>
                        Remove
                    </button>
                )}
                <button aria-label="Expand/Collapse BPM" type="button" onClick={() => setIsSectionExpanded(!isSectionExpanded)}>
                    <ChevronUp className={classNames('filter__chevron', { 'filter__chevron--expanded': isSectionExpanded })} />
                </button>
            </div>
            <Collapse isOpened={isSectionExpanded}>
                {filterComponent()}
            </Collapse>
        </div>
    );
}

export default BpmFilter;
