import { useGetArtistFeaturedIn } from '@bpm-web-app/swr-hooks/src/hooks/artist';
import { useUniqueArray } from '@bpm-web-app/utils';
import { Fragment, useState } from 'react';
import { CuratedSet } from '@bpm-web-app/download-api-sdk';
import GhostElement from '@bpm-web-app/components/src/lib/shared/ghost-element/ghost-element';
import { PlaylistCard } from '@bpm-web-app/components/src/lib/shared/card/playlist-card/playlist-card';
import { CuratedSetCard } from '@bpm-web-app/components/src/lib/shared/card/curated-set-card/curated-set-card';
import SeeMore from '@bpm-web-app/components/src/lib/shared/see-more-button/see-more-btn';
import styles from './featured-in.module.css';
import { EmptyState } from '../empty-state/empty-state';
import FeaturedInIcon from '../../../../../assets/icons/featured-in.svg';
import { ListWrapper } from '../list-wrapper/list-wrapper';

const FEATURED_IN_LINES_WIDTH = [90, 70];

export interface FeaturedInProps {
    artistId: number;
}

export function FeaturedIn({ artistId }: FeaturedInProps) {
    const [limit, setLimit] = useState(8);
    const { data: artistFeaturedInData, isLoading: isFeaturedInDataLoading } = useGetArtistFeaturedIn(artistId);

    const ghostLoadingItems = useUniqueArray(20);

    if (isFeaturedInDataLoading) {
        return (
            <ListWrapper>
                <div className="ghost-loading__loading-container">
                    <div className={styles['featured-in__loading']}>
                        {ghostLoadingItems.map((uuid) => (
                            <GhostElement isRoundImage forMaxiPlayer key={`featured-in-${uuid}`} itemClass={styles['featured-in__loading-item']} linesWidthArray={FEATURED_IN_LINES_WIDTH} />
                        ))}
                    </div>
                </div>
            </ListWrapper>
        );
    }

    if (artistFeaturedInData.playlists.length === 0 && artistFeaturedInData.curated.length === 0) {
        return <EmptyState title="The track was not featured yet." icon={<FeaturedInIcon />} />;
    }

    return (
        <ListWrapper>
            <div className={styles['featured-in']}>
                <div className={styles['featured-in__grid']}>
                    {artistFeaturedInData?.playlists.slice(0, limit).map((item: any) => (
                        <Fragment key={item.id}>
                            <PlaylistCard
                                key={item.id}
                                playlist={item}
                                playlists={artistFeaturedInData?.playlists}
                                className={styles['featured-in__playlist-card']}
                            />
                        </Fragment>
                    ))}
                    {artistFeaturedInData?.curated.slice(0, limit).map((item: any) => (
                        <Fragment key={item.id}>
                            <CuratedSetCard
                                key={item.id}
                                curatedSet={item as CuratedSet}
                                className={styles['featured-in__playlist-card']}
                            />
                        </Fragment>
                    ))}
                </div>
                {limit < Math.max(artistFeaturedInData?.curated.length ?? 0, artistFeaturedInData?.playlists.length ?? 0) && (
                    <div className="spacing--top">
                        <SeeMore expand={false} variant="text" onClick={() => setLimit(limit + 8)} />
                    </div>
                )}
            </div>
        </ListWrapper>
    );
}
