import { Fragment, useMemo, useState } from 'react';
import GhostElement from '@bpm-web-app/components/src/lib/shared/ghost-element/ghost-element';
import { useUniqueArray } from '@bpm-web-app/utils';
import classNames from 'classnames';
import SecondaryPageTitle from '../shared/secondary-page-title/secondary-page-title';
import styles from './categories-grid.module.css';
import ChevronRight from '../../assets/icons/chevron-right.svg';
import { AppLink } from '../shared/app-link/app-link';
import { GenreCard, GenreCardType } from '../shared/genre-card/genre-card';
import { SearchInput } from '../shared';
import SeeMore from '../shared/see-more-button/see-more-btn';

export interface GridElement {
    id: number | string;
    name: string;
    href: string;
    icon?: string;
}
export interface CategoriesGridProps {
    title?: string;
    mainHref?: string;
    elements?: Array<GridElement>;
    showMore?: boolean;
    isLoading?: boolean;
    genreVariant?: GenreCardType;
    gridVariant?: 'narrow' | 'wide' | 'medium';
    searchTerm?: string;
    setSearchTerm?: (term: string) => void;
    searchPlaceholder?: string;
    hasSearch?: boolean;
    ghostElementsCount?: number;
}

export function CategoriesGrid({ title, mainHref, elements, showMore = false, isLoading = false, genreVariant = 'default', gridVariant = 'narrow', searchTerm = '', setSearchTerm, hasSearch = false, searchPlaceholder = 'Search', ghostElementsCount = 16 }: CategoriesGridProps) {
    const [elementsToShow, setElementsToShow] = useState<number>(10);

    const ghostLoadingItems = useUniqueArray(ghostElementsCount);

    const modifiedElements = useMemo(() => {
        if (!elements) {
            return [];
        }

        if (!showMore || elements.length < elementsToShow) {
            return elements;
        }

        const newElements = [...elements];
        newElements.length = elementsToShow;
        return newElements;
    }, [showMore, elements, elementsToShow]);

    return (
        <article>
            {mainHref ? (
                <AppLink href={mainHref}>

                    <a className={styles['categories-grid__title']}>
                        <SecondaryPageTitle title={title || ''} noPadding />
                        <ChevronRight />
                    </a>
                </AppLink>
            ) : title ? (
                <div className={styles['categories-grid__header']}>
                    <div className={styles['categories-grid__title']}>
                        <SecondaryPageTitle title={title} noPadding />
                    </div>
                    {hasSearch ? <SearchInput
                        placeholder={searchPlaceholder}
                        value={searchTerm}
                        variant="small"
                        onChange={(e) => {
                            if (setSearchTerm) setSearchTerm(e.target.value);
                        }}
                        onClear={() => { if (setSearchTerm) { setSearchTerm(''); } }}
                    /> : null}
                </div>
            ) : null}

            <div className={classNames(styles['categories-grid__container'], styles[`categories-grid__container--${gridVariant}`])}>
                {isLoading
                    ? ghostLoadingItems.map((uuid) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Fragment key={`categories-grid-loading-${uuid}`}>
                            <GhostElement linesWidthArray={[100]} lineHeight={32} itemClass={styles['categories-grid__ghost-loading-element']} />
                        </Fragment>
                    ))
                    : modifiedElements.map(({ id, name, href, icon }) => (
                        <GenreCard key={id} id={id} name={name} href={href} variant={genreVariant} icon={icon} />
                    ))}
            </div>
            {!isLoading && showMore && elements && elements.length > elementsToShow ? (
                <div className="spacing--bottom">
                    <SeeMore expand={false} variant="text" onClick={() => setElementsToShow(elementsToShow + 10)} />
                </div>
            ) : null}
        </article>
    );
}

export default CategoriesGrid;
