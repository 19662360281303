import classNames from 'classnames';
import { AnchorHTMLAttributes, ButtonHTMLAttributes, ForwardedRef, forwardRef, HTMLAttributes } from 'react';

import IconLoader from '../icons/loader.svg';
import styles from './button.module.css';

type ButtonProps = {
    as?: 'button';
    type: Required<ButtonHTMLAttributes<HTMLButtonElement>['type']>
} & ButtonHTMLAttributes<HTMLButtonElement>;

type AnchorProps = { as: 'a' } & AnchorHTMLAttributes<HTMLAnchorElement>;
type SpanProps = { as: 'span' } & HTMLAttributes<HTMLSpanElement>;

type Props = ({
    variant?: 'primary' | 'secondary' | 'destructive' | 'inverted'
    isLoading?: boolean
} & (ButtonProps | AnchorProps | SpanProps));

export const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement | HTMLSpanElement, Props>(({ children, variant, className, isLoading, ...props }, ref) => {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const cls = classNames(styles['button'], {
        [styles['button--primary']]: variant === 'primary',
        [styles['button--secondary']]: variant === 'secondary',
        [styles['button--destructive']]: variant === 'destructive',
        [styles['button--inverted']]: variant === 'inverted'
    }, className);

    const Loader = isLoading && (
        <span className={styles['loading-indicator']}>
            <IconLoader />
        </span>
    );

    return props.as === 'a' ? (
        <a {...props} ref={ref as ForwardedRef<HTMLAnchorElement>} className={cls}>
            {children}
            {Loader}
        </a>
    ) : props.as === 'span' ? (
        <span {...props} ref={ref as ForwardedRef<HTMLSpanElement>} className={cls}>
            {children}
            {Loader}
        </span>
    ) : (
        // eslint-disable-next-line react/button-has-type
        <button {...props} ref={ref as ForwardedRef<HTMLButtonElement>} className={cls}>
            {children}
            {Loader}
        </button>
    );
});
