import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCurrentUserAddress, useGetMeSubscription, useGetPaymentInstrument } from '@bpm-web-app/swr-hooks';
import { DOWNLOAD_ONLY, formatDateToString, useApiErrorHandler, showToast, getSignupRedirectLinkAccountPlans, useUserSettings } from '@bpm-web-app/utils';
import { ChangeSubscriptionRequest, ChangeSubscriptionRequestStripe, PaymentInstrumentStripe, SubscriptionDetailsv4 } from '@bpm-web-app/supreme-api-sdk';
import { Subscription, User } from '@bpm-web-app/api-client';
import router from 'next/router';
import styles from './account-payments.module.css';
import Invoices from './invoices/invoices';
import CollapseIcon from '../../../assets/icons/minus.svg';
import ExpandIcon from '../../../assets/icons/plus.svg';
import AccountAddPayment, { PaymentData } from '../account-add-payment/account-add-payment';

export interface AccountPaymentsProps {
    isCreate: boolean;
}

export function AccountPayments({ isCreate }: AccountPaymentsProps) {
    const { data: paymentDownload, isLoading: isLoadingPaymentDownload, mutate: mutateUserPaymentDownload } = useGetPaymentInstrument('supreme', !isCreate);
    const { data: paymentStream, isLoading: isLoadingPaymentStream, mutate: mutateUserPaymentStream } = useGetPaymentInstrument('stream', !isCreate);
    const { data: paymentCreate, isLoading: isLoadingPaymentCreate, mutate: mutateUserPaymentCreate } = useGetPaymentInstrument('create', isCreate);

    const { data: userSubscriptionDownload, isLoading: isLoadingUserSubscriptionDownload } = useGetMeSubscription('supreme');
    const { data: userSubscriptionStream, isLoading: isLoadingUserSubscriptionStream } = useGetMeSubscription('stream');
    const { data: userSubscriptionCreate, isLoading: isLoadingUserSubscriptionCreate } = useGetMeSubscription('create');

    const { data: userAddress, isLoading: isLoadingUserAddress, mutate: mutateUserSubscriptionAddress } = useCurrentUserAddress();

    const [streamExpanded, setStreamExpanded] = useState(true);
    const [downloadExpanded, setDownloadExpanded] = useState(true);
    const [createExpanded, setCreateExpanded] = useState(true);

    const hasStream = useMemo(() => userSubscriptionStream?.data?.membership?.has_membership, [userSubscriptionStream?.data]);

    const hasCreate = useMemo(() => userSubscriptionCreate?.data?.membership?.has_membership, [userSubscriptionCreate?.data]);

    const hasDownload = useMemo(() => userSubscriptionDownload?.data?.membership?.has_membership, [userSubscriptionDownload?.data]);
    const errorHandler = useApiErrorHandler(isCreate);

    const { isAnonymous } = useUserSettings();

    useEffect(() => {
        if (isAnonymous) {
            router.replace(getSignupRedirectLinkAccountPlans());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAnonymous]);

    const handleErrorOnSubmit = async (error: any) => {
        errorHandler({ error });
        if (isCreate) {
            mutateUserPaymentCreate(null, { revalidate: true });
            return;
        }
        mutateUserPaymentDownload(null, { revalidate: true });
        mutateUserPaymentStream(null, { revalidate: true });
    };

    const getPackageId = useCallback(
        (type: 'stream' | 'download' | 'create') => {
            switch (type) {
                case 'stream':
                    return userSubscriptionStream?.data?.membership?.subscription?.package_id;
                case 'download':
                    return userSubscriptionDownload?.data?.membership?.subscription?.package_id;
                case 'create':
                    return userSubscriptionCreate?.data?.membership?.subscription?.package_id;
                default:
                    return userSubscriptionCreate?.data?.membership?.subscription?.package_id;
            }
        },
        [
            userSubscriptionCreate?.data?.membership?.subscription?.package_id,
            userSubscriptionDownload?.data?.membership?.subscription?.package_id,
            userSubscriptionStream?.data?.membership?.subscription?.package_id,
        ]
    );

    const handleSubmit = async (formData: PaymentData, type: 'stream' | 'download' | 'create') => {
        const body: ChangeSubscriptionRequest = {
            payment_data: null as any,
        };

        if (formData.payment?.stripe?.payment_method_id) {
            body.payment_data = {
                method: ChangeSubscriptionRequestStripe.MethodEnum.Stripe,
                payment_method_id: formData.payment.stripe.payment_method_id,
            };
        }

        try {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const response = await Subscription.changeSubscription(getPackageId(type)!, body);

            if ((response?.data as any)?.type === 'paypal') {
                router.push((response?.data as any)?.url);
            } else {
                mutateUserSubscriptionAddress();
                if (type === 'download') {
                    mutateUserPaymentDownload();
                } else if (type === 'stream') {
                    mutateUserPaymentStream();
                } else {
                    mutateUserPaymentCreate();
                }
            }
            showToast({ type: 'success', message: 'Payment method updated successfully.' });
        } catch (error: any) {
            await handleErrorOnSubmit(error);
        }
    };

    if (!isCreate && (isLoadingPaymentDownload || isLoadingPaymentStream || isLoadingUserSubscriptionDownload || isLoadingUserSubscriptionStream || isLoadingUserAddress)) {
        return null;
    }

    if (isCreate && (isLoadingPaymentCreate || isLoadingUserSubscriptionCreate || isLoadingUserAddress)) {
        return null;
    }

    const renderStream = () => {
        const stripePaymentInstrument = paymentStream?.data.method === PaymentInstrumentStripe.MethodEnum.Stripe ? paymentStream.data : undefined;
        const isCancelled = userSubscriptionStream?.data?.membership.subscription?.status === SubscriptionDetailsv4.StatusEnum.Cancelled;
        return (
            <>
                <div className={styles['account-payments__collapse-header']}>
                    <p className="heading--h2">Your Stream Subscription</p>
                    <button className={styles['account-payments__collapse-header--button']} type="button" aria-label="collapse/expand stream" onClick={() => setStreamExpanded(!streamExpanded)}>
                        {streamExpanded ? <CollapseIcon /> : <ExpandIcon />}
                    </button>
                </div>
                {streamExpanded && (
                    <div className={styles['account-payments__plans-container']}>
                        {hasStream && !isLoadingPaymentStream && (
                            <AccountAddPayment
                                billingAddress={userAddress?.data}
                                paymentData={stripePaymentInstrument}
                                billingText={isCancelled ? 'Subscription is cancelled.' : `You will be billed on ${formatDateToString(userSubscriptionStream?.data?.membership?.subscription?.expire_date, 'MM/DD/YYYY')} when your plan renews.`}
                                setPaymentData={(data) => {
                                    return handleSubmit({
                                        payment: {
                                            stripe: {
                                                payment_method_id: data.id
                                            }
                                        }
                                    }, 'stream');
                                }}
                                updateBillingAddress={async (address) => {
                                    await User.setMyCurrentAddress(address);
                                }}
                                isOpen={false}
                            />
                        )}
                        <Invoices type="stream" />
                    </div>
                )}
            </>
        );
    };

    const renderCreate = () => {
        const stripePaymentInstrument = paymentCreate?.data.method === PaymentInstrumentStripe.MethodEnum.Stripe ? paymentCreate.data : undefined;
        const isCancelled = userSubscriptionCreate?.data?.membership.subscription?.status === SubscriptionDetailsv4.StatusEnum.Cancelled;
        return (
            <>
                <div className={styles['account-payments__collapse-header']}>
                    <button className={styles['account-payments__collapse-header--button']} type="button" aria-label="collapse/expand create" onClick={() => setCreateExpanded(!createExpanded)}>
                        {createExpanded ? <CollapseIcon /> : <ExpandIcon />}
                    </button>
                </div>
                {createExpanded && (
                    <div className={styles['account-payments__plans-container']}>
                        {hasCreate && !isLoadingPaymentCreate && (
                            <AccountAddPayment
                                billingAddress={userAddress?.data}
                                isCreate
                                paymentData={stripePaymentInstrument}
                                billingText={isCancelled ? 'Subscription is cancelled.' : `You will be billed on ${formatDateToString(userSubscriptionCreate?.data?.membership?.subscription?.expire_date, 'MM/DD/YYYY')} when your plan renews.`}
                                setPaymentData={(data) => {
                                    return handleSubmit({
                                        payment: {
                                            stripe: {
                                                payment_method_id: data.id
                                            }
                                        }
                                    }, 'create');
                                }}
                                updateBillingAddress={async (address) => {
                                    await User.setMyCurrentAddress(address);
                                }}
                                isOpen={false}
                            />
                        )}
                        <Invoices type="create" />
                    </div>
                )}
            </>
        );
    };

    const renderDownload = () => {
        const stripePaymentInstrument = paymentDownload?.data.method === PaymentInstrumentStripe.MethodEnum.Stripe ? paymentDownload.data : undefined;
        const isCancelled = userSubscriptionDownload?.data?.membership.subscription?.status === SubscriptionDetailsv4.StatusEnum.Cancelled;
        return (
            <>
                <div className={styles['account-payments__collapse-header']}>
                    <p className="heading--h2">Your {DOWNLOAD_ONLY ? '' : 'Download'} Subscription</p>
                    <button className={styles['account-payments__collapse-header--button']} type="button" aria-label="collapse/expand download" onClick={() => setDownloadExpanded(!downloadExpanded)}>
                        {downloadExpanded ? <CollapseIcon /> : <ExpandIcon />}
                    </button>
                </div>
                {downloadExpanded && (
                    <div className={styles['account-payments__plans-container']}>
                        {hasDownload && !isLoadingPaymentDownload && (
                            <AccountAddPayment
                                billingAddress={userAddress?.data}
                                paymentData={stripePaymentInstrument}
                                billingText={isCancelled ? 'Subscription is cancelled.' : `You will be billed on ${formatDateToString(userSubscriptionDownload?.data?.membership?.subscription?.expire_date, 'MM/DD/YYYY')} when your plan renews.`}
                                setPaymentData={(data) => {
                                    return handleSubmit({
                                        payment: {
                                            stripe: {
                                                payment_method_id: data.id
                                            }
                                        }
                                    }, 'download');
                                }}
                                updateBillingAddress={async (address) => {
                                    await User.setMyCurrentAddress(address);
                                }}
                                isOpen={false}
                            />
                        )}
                        <Invoices type="supreme" />
                    </div>
                )}
            </>
        );
    };

    return (
        <div className={styles['account-payments']}>
            <h2 className={styles['account-payments__desktop-section-title']}>Payment</h2>
            <div className={styles['account-payments__content']}>
                {!isCreate && !DOWNLOAD_ONLY && hasStream && renderStream()}
                {!isCreate && renderDownload()}
                {isCreate && renderCreate()}
            </div>
        </div>
    );
}

export default AccountPayments;
