import { useHubSwitch } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { PlatformSwitchContent } from './platform-switch-content';
import styles from './platform-switch.module.css';
import { PlatformName } from './types';

export interface PlatformSwitchProps {
    platforms: { name: PlatformName }[]
}

export function PlatformSwitch({ platforms }: PlatformSwitchProps) {
    const { isCreate } = useHubSwitch();

    return (
        <div className={classNames(styles['platform-switch'], { [styles['platform-switch--create']]: isCreate })}>
            <PlatformSwitchContent platforms={platforms} />
        </div>
    );
}
