import CountUp from 'react-countup';
import { formatBigNumber, rebuildReactTooltip } from '@bpm-web-app/utils';
import { useEffect } from 'react';
import classNames from 'classnames';
import styles from './analytics-card.module.css';
import ToolTip from '../../../../assets/icons/artist-platform/tooltip.svg';
import { ReactComponent as Chart } from '../../../../assets/icons/artist-platform/chart.svg';

export interface AnalyticsPreviewCardProps {
    title: string;
    tooltip?: string; // if not given, won't show
    value: number;
    percent_changed: number; // (negative and positive)
    color?: string;
    variant?: 'small' | 'large'
}

export function AnalyticsCard({ title, tooltip, value, percent_changed, color, variant = 'small' }: AnalyticsPreviewCardProps) {
    useEffect(() => {
        rebuildReactTooltip();
    }, []);
    // Format the value (ex. 181900 becomes 181.9K)
    const formattedValue = formatBigNumber(value, '0', true);
    // Return the first letter (ex 181.9K returns the letter K)
    const firstLetter = (formattedValue.match(/[a-zA-Z]/) || []).pop();
    // Returns the numbered value without the letter (ex. 181.9K returns the number 181.9)
    const countUp = Number(formattedValue.split(firstLetter)[0]);
    const duration = Math.min(1, countUp * 0.1);

    return (
        <div style={color ? { borderTopColor: color } : {}} className={styles[`analytics-card--${variant}`]}>
            <div className={styles[`analytics-card--left-${variant}`]}>
                <div className={styles['analytics-card--title-section']}>
                    <div className={styles['analytics-card--title']}>{title}</div>
                    {
                        tooltip ?
                            <button aria-label={tooltip} type="button" data-tip={tooltip} className={styles['analytics-card--tooltip']}>
                                <ToolTip />
                            </button> : null
                    }
                </div>
                <div className={styles['analytics-card--right-values']}>
                    <div className={styles[`analytics-card--value-inline-${variant}`]}>
                        <CountUp className={styles['analytics-card--value']} duration={duration} start={0} end={countUp} decimals={countUp.toFixed(1).endsWith('.0') ? 0 : 1} />
                        {firstLetter !== undefined ? <div className={styles['analytics-card--value']}>{firstLetter.toLowerCase()}</div> : null}
                    </div>
                    <div className={styles[`analytics-card--growth-section-${variant}`]}>
                        <div className={styles['analytics-card--percentage-inline']}>
                            <div className={styles['analytics-card--percentage-row']}>
                                <div className={styles['analytics-card--chart']}>
                                    <Chart className={classNames(styles['analytics-card--chart'], { [styles['analytics-card--chart-green']]: percent_changed >= 0 })} />
                                </div>
                                <div
                                    className={classNames(styles['analytics-card--percentage'], {
                                        [styles['analytics-card--percentage--rising']]: percent_changed >= 0,
                                        [styles['analytics-card--percentage--descending']]: percent_changed < 0
                                    })}>
                                    &nbsp;{percent_changed > 0 ? '+' : ''}{`${percent_changed}%`}
                                </div>
                            </div>

                            <div className={styles[`analytics-card--percentage-text-${variant}`]}>
                                &nbsp;{' vs last week'}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
