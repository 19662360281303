/* eslint-disable @typescript-eslint/dot-notation */
import { Library, useHubSwitch, useLibraryTabs } from '@bpm-web-app/utils';
import { AppLink } from '..';
import { getPlatformEnvLink } from './common';
import { PlatformLogo } from './platform-logo';
import { PlatformName } from './types';
import styles from './platform-switch.module.css';

interface MenuPlatformSwitchProps {
    platforms: { name: PlatformName }[];
}

export function PlatformSwitchContent({ platforms }: MenuPlatformSwitchProps) {
    const { isArtistPlatform, isCreate } = useHubSwitch();
    const { library } = useLibraryTabs();

    return (
        <div className={styles['platform-switch__content']}>
            {platforms.map((platform) => (
                <a key={platform.name} className={styles['platform-switch__link']} href={getPlatformEnvLink(platform.name)}>
                    <span>Switch to {platform.name}</span>
                    <PlatformLogo platform={platform.name} />
                </a>
            ))}
            <div className={styles['platform-switch__divider']} />
            {!isArtistPlatform && (
                <AppLink href={isCreate ? 'https://classic.create.bpmsupreme.com' : library === Library.Main ? 'https://classic.bpmsupreme.com' : 'https://classic.latino.bpmsupreme.com'}>
                    <a className={styles['platform-switch__link']}>
                        Switch to Classic Mode
                    </a>
                </AppLink>
            )}
        </div>
    );
}
/* eslint-enable @typescript-eslint/dot-notation */
