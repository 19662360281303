import { GenericPaginatedQuery, Label } from '@bpm-web-app/api-client';
import { FAVORITES_STALE_INTERVAL } from '@bpm-web-app/utils';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';

export function useGetLabels(disabled = false, type?: string, synth?: string) {
    const { data, error } = useSWR(
        [
            'create-labels',
            {
                disabled,
                persist: true,
                type,
                synth
            },
        ],
        () => (disabled ? null : Label.getLabels(type, synth))
    );

    return {
        data: data?.data,
        isLoading: !error && !data,
        error,
    };
}

export function useTrendingGetLabels(disabled = false) {
    const { data, error } = useSWR(
        [
            'create-trending-labels',
            {
                disabled,
                persist: true,
            },
        ],
        () => (disabled ? null : Label.getTrendingLabels())
    );

    return {
        data: data?.data,
        isLoading: !error && !data,
        error,
    };
}

export function useLabelByName(name: string, disabled = false) {
    const { data, error } = useSWR(
        [
            'create-labels',
            name,
            {
                disabled,
                persist: true,
            },
        ],
        () => (disabled ? null : Label.getLabelByName(name))
    );

    return {
        data: data?.data,
        isLoading: !error && !data,
        error,
    };
}

export function useLabelTopPacks(name: string, args?: GenericPaginatedQuery, disabled = false) {
    const { data, error } = useSWR(
        [
            'create-label-top-packs',
            name,
            {
                disabled,
                persist: true,
                ...args,
            },
        ],
        () => (disabled ? null : Label.getLabelTopPacks(name, args))
    );

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

export function useLabelNewestPacks(name: string, args?: GenericPaginatedQuery, disabled = false) {
    const { data, error } = useSWR(
        [
            'create-label-new-packs',
            name,
            {
                disabled,
                persist: true,
                ...args,
            },
        ],
        () => (disabled ? null : Label.getLabelNewestPacks(name, args))
    );

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

export function useInfiniteLabelNewestPacks(label: string, args?: GenericPaginatedQuery) {
    const pageSize = args?.limit || 50;

    const { data, error, size, setSize, isValidating } = useSWRInfinite(
        (index) => [`create-newest-packs-by-label-${JSON.stringify({ ...args, label })}-${index}`, { persist: true }],
        (key) => {
            /* extract page index back from key name */
            const pageIndex = key.split('-').pop() || '0';
            return Label.getLabelNewestPacks(label, { ...args, skip: +pageIndex * pageSize });
        },
        { revalidateFirstPage: false }
    );

    const isLoadingInitialData = !data && !error;
    const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === 'undefined');
    const isEmpty = data?.[0]?.data.length === 0;
    const lastPageSize = data ? data[data.length - 1]?.data.length || 0 : 0;
    const isLastPage = isEmpty || lastPageSize < pageSize;

    return {
        data,
        isLoadingInitialData,
        isLoadingMore,
        isValidating,
        isLastPage,
        error,
        size,
        setSize,
    };
}

export function useInfiniteLabelTopPacks(label: string, args?: GenericPaginatedQuery) {
    const pageSize = args?.limit || 50;

    const { data, error, size, setSize, isValidating } = useSWRInfinite(
        (index) => [`create-top-packs-by-label-${JSON.stringify({ ...args, label })}-${index}`, { persist: true }],
        (key) => {
            /* extract page index back from key name */
            const pageIndex = key.split('-').pop() || '0';
            return Label.getLabelTopPacks(label, { ...args, skip: +pageIndex * pageSize });
        },
        { revalidateFirstPage: false }
    );

    const isLoadingInitialData = !data && !error;
    const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === 'undefined');
    const isEmpty = data?.[0]?.data.length === 0;
    const lastPageSize = data ? data[data.length - 1]?.data.length || 0 : 0;
    const isLastPage = isEmpty || lastPageSize < pageSize;

    return {
        data,
        isLoadingInitialData,
        isLoadingMore,
        isValidating,
        isLastPage,
        error,
        size,
        setSize,
    };
}

export function useGetFavoritedLabels(enabled = true) {
    const { data, error, mutate } = useSWR(
        [
            'create-labels-favorite',
            {
                enabled,
                persist: true,
            },
        ],
        () => (enabled ? Label.getFavoritedLabels() : null),
        {
            dedupingInterval: FAVORITES_STALE_INTERVAL,
            isPaused: () => !enabled,
        }
    );

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}

export function useFollowLabel() {
    return (id: Parameters<typeof Label.favoriteLabel>[0]) => Label.favoriteLabel(id);
}

export function useUnFollowLabel() {
    return (id: Parameters<typeof Label.unfavoriteLabel>[0]) => Label.unfavoriteLabel(id);
}
