import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useRef, useState } from 'react';
import classNames from 'classnames';
import VolumeMuteIcon from '../../assets/icons/supreme/volume-muted.svg';
import VolumeHalfIcon from '../../assets/icons/supreme/volume-half.svg';
import VolumeFullIcon from '../../assets/icons/supreme/volume-full.svg';
import { usePlayer } from '../player-context';
import styles from './volume-control.module.css';

const sliderStyles = {
    trackStyle: { backgroundColor: '#f73e00', borderRadius: 0 } as React.CSSProperties,
    handleStyle: {
        height: 14,
        width: 14,
        backgroundColor: '#e6efe9',
        border: '2px solid #e6efe9',
        boxShadow: 'none',
    },
    railStyle: {
        borderRadius: 0,
        backgroundColor: '#4a4a4a',
    },
};

export const VolumeControl = () => {
    const volumeSliderRef = useRef<HTMLDivElement>(null);
    const timeout = useRef<number | null>(null);

    const [visible, setVisible] = useState(false);
    const { volume, setVolume } = usePlayer();

    const handleOpenVolume = () => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
        setVisible(true);
    };

    const handleCloseMenu = () => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
        timeout.current = window.setTimeout(() => {
            setVisible(false);
        }, 100);
    };

    return (
        <div className={classNames(styles['volume-control'])} onMouseEnter={handleOpenVolume} onMouseLeave={handleCloseMenu}>
            <button
                className={classNames(styles['volume-control__volume'])}
                onFocus={handleOpenVolume}
                onBlur={handleCloseMenu}
                type="button"
                aria-label="Volume control"
                onClick={(e) => {
                    e.stopPropagation();
                    setVolume(!volume ? 100 : 0);
                }}
            >
                {volume === 0 ? <VolumeMuteIcon /> : volume > 50 ? <VolumeFullIcon /> : <VolumeHalfIcon />}
            </button>
            <div
                className={classNames(styles['volume-control__slider-container'], {
                    [styles['volume-control__slider-container--visible']]: visible,
                })}
                tabIndex={0}
                ref={volumeSliderRef}
                role="button"
                onClick={(e) => e.stopPropagation()}
                onKeyPress={() => {}}
            >
                <Slider
                    className={classNames(styles['volume-control__slider'], {
                        [styles['volume-control__slider--muted']]: volume === 0,
                    })}
                    min={0}
                    max={100}
                    step={1}
                    value={volume}
                    onChange={setVolume}
                    vertical
                    handleStyle={{ ...sliderStyles.handleStyle, backgroundColor: volume === 0 ? '#4a4a4a' : '#e6efe9', borderColor: volume === 0 ? '#4a4a4a' : '#e6efe9' }}
                    trackStyle={sliderStyles.trackStyle}
                    railStyle={sliderStyles.railStyle}
                />
            </div>
        </div>
    );
};
