import { ReactNode, createContext, useState, useEffect, useMemo } from 'react';
import debounce from 'lodash/debounce';

export interface ViewportContextProperties {
    width: number;
    height: number;
}

export const ViewportContext = createContext<ViewportContextProperties>({} as never);

export interface ViewProviderProps {
    children: ReactNode;
}

export function ViewportProvider({ children }: ViewProviderProps) {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const handleWindowResize = debounce(() => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }, 600);

    const result = useMemo(() => ({ width, height }), [width, height]);

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <ViewportContext.Provider value={result}>{children}</ViewportContext.Provider>;
}
