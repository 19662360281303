import { SyntheticEvent, useMemo } from 'react';
import classNames from 'classnames';
import styles from './checkboxes.module.css';
import { CustomIcon } from '../custom-icon/custom-icon';
import { FiltersSupremeTypes } from '../../filters/filters.context';
import { LoadingEllipsis } from '../loading-ellipsis/loading-ellipsis';

export interface CheckboxesProps {
    options: Array<{
        label: string;
        value: string;
        disabled?: boolean;
        type?: FiltersSupremeTypes;
        count?: number;
    }>;
    value: string[];
    onChange: (newValue: string[]) => void;
    hasRectangularCheckboxes?: boolean;
    onCloseCross?: (label: string, value: string, type?: FiltersSupremeTypes) => void;
    sortOptions?: boolean;
    hasMaxHeight?: 32 | 24;
    isLoading?: boolean;
}

export function Checkboxes({ options, value, onChange, hasRectangularCheckboxes, onCloseCross, sortOptions = true, hasMaxHeight, isLoading }: CheckboxesProps) {
    const handleChange = (e: SyntheticEvent<HTMLElement>, labelSelected: string, valueSelected: string, typeSelected?: FiltersSupremeTypes) => {
        if (onCloseCross) {
            onCloseCross(labelSelected, valueSelected, typeSelected);
        } else if ('checked' in e.target) {
            const newValue = new Set(value);
            const { checked, value: inputValue } = e.target as HTMLInputElement;
            newValue[checked ? 'add' : 'delete'](inputValue);
            onChange(Array.from(newValue));
        }
    };

    const sortedSelectedOptions = useMemo(() => {
        if (!sortOptions) return options;
        const selectedOptions = options.filter((option) => value.includes(option.value));
        const unselectedOptions = options.filter((option) => !value.includes(option.value));
        return [...selectedOptions, ...unselectedOptions];
    }, [options, value, sortOptions]);

    return (
        /* eslint-disable-next-line @typescript-eslint/dot-notation */
        <div className={classNames(styles['checkboxes'])}>
            {sortedSelectedOptions.map((option) => (
                <label key={option.value} className={styles['checkboxes__checkbox']}>
                    <input type="checkbox" value={option.value} checked={value.includes(option.value)} disabled={option.disabled} onChange={(e) => handleChange(e, option.label.toLowerCase(), option.value, option.type)} className={classNames(styles['checkboxes__checkbox-input'], { [styles['checkboxes__checkbox-rectangular-input']]: hasRectangularCheckboxes })} />
                    <div style={hasMaxHeight ? { maxHeight: hasMaxHeight } : {}} className={classNames(styles['checkboxes__checkbox-btn'], { [styles['checkboxes__checkbox-btn--rectangular']]: hasRectangularCheckboxes }, { [styles['checkboxes__checkbox-btn--cross']]: onCloseCross }, { [styles['checkboxes__checkbox-btn--count']]: option.count !== undefined })}>
                        {option.label}
                        {onCloseCross || option.count !== undefined ? (
                            <div className={styles['checkboxes__divider-container']} />
                        ) : null}
                        {isLoading ? <LoadingEllipsis /> : null}

                        {option.count !== undefined && !isLoading ? option.count : null}

                        {onCloseCross ? (
                            <CustomIcon type="close-icon" hasIconHover className={styles['checkboxes__cross']} tooltip="Remove Filter" onClick={(e) => handleChange(e, option.label.toLowerCase(), option.value, option.type)} />
                        ) : null}
                    </div>
                </label>
            ))}
        </div>
    );
}

export default Checkboxes;
