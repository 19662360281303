import { useFavoriteMedia as useFavoriteMediaSWRHook } from '@bpm-web-app/swr-hooks';
import { Media } from '@bpm-web-app/api-client';
import { useEffect, useState } from 'react';
import { useApiErrorHandler, showToast } from '@bpm-web-app/utils';

export const useFavoriteMedia = (mediaId: number, library?: string) => {
    const { data: favoriteMedia, isLoading, mutate: mutateFavoriteMedia } = useFavoriteMediaSWRHook(library);
    const errorHandler = useApiErrorHandler();
    const [isMediaFollowed, setIsMediaFollowed] = useState<boolean>();
    useEffect(() => {
        const isFav = favoriteMedia?.data.some(({ id }) => id === mediaId);
        setIsMediaFollowed(isFav);
    }, [favoriteMedia, mediaId]);

    const addToFavorites = async () => {
        setIsMediaFollowed(true);
        try {
            mutateFavoriteMedia(await Media.addMediaToFavorites(+mediaId));
            showToast({ type: 'success', message: 'Added to favorites.', buttonText: 'Go To Favorites', replaceRouteOnButtonClick: '/favorites' });
        } catch (error) {
            errorHandler({ error });
            setIsMediaFollowed(false);
        }
    };

    const removeFromFavorites = async () => {
        setIsMediaFollowed(false);
        try {
            mutateFavoriteMedia(await Media.removeMediaFromFavorites(+mediaId));
            showToast({ type: 'success', message: 'Removed from favorites.', buttonText: 'Go To Favorites', replaceRouteOnButtonClick: '/favorites' });
        } catch (error) {
            errorHandler({ error });
            setIsMediaFollowed(true);
        }
    };

    return {
        favoriteMedia,
        isLoading,
        addToFavorites,
        removeFromFavorites,
        isMediaFollowed,
    };
};

export default useFavoriteMedia;
