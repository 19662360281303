import styles from './loader.module.css';
import Icon from '../../../../assets/icons/exclusive-tag.svg';
/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
export interface LoaderProps {}

export function Loader(props: LoaderProps) {
    return (
        /* eslint-disable-next-line @typescript-eslint/dot-notation */
        <div className={styles['loader']}>
            <Icon />
        </div>
    );
}

export default Loader;
