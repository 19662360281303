import { useDownloadAlbumById } from '@bpm-web-app/swr-hooks';
import {
    Analytics,
    CreateCardCarouselItem,
    State as PlayerStateEnum,
    appendQueryParams,
    convertToPluralIfNeeded,
    downloadAlbumWithMediaToQueueItem,
    parseSeconds,
    useHubSwitch,
    useUserSettings,
    useViewport,
    usePlayerState,
} from '@bpm-web-app/utils';
import { useDrag } from '@use-gesture/react';
import classNames from 'classnames';
import 'rc-slider/assets/index.css';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import MaxiIcon from '../../assets/icons/supreme/chevron-up.svg';
import NextIcon from '../../assets/icons/supreme/player-next.svg';
import PauseIcon from '../../assets/icons/supreme/pause-large.svg';
import PlayIcon from '../../assets/icons/supreme/play-large.svg';
import PrevIcon from '../../assets/icons/supreme/player-prev.svg';
import { useArtistLinks } from '../artist-link/artist-link';
import { usePlayer } from '../player-context';
import { ThreeDotsButton } from '../shared/three-dots-button/three-dots-button';
import { ThreeDotsSheetContext } from '../shared/three-dots-sheet/three-dots-sheet.context';
import { MiniDownloadButton } from './mini-download-button/mini-download-button';
import { SeekSlider } from './seek-slider/seek-slider';
import styles from './supreme-mini-player.module.css';
import VersionsDropdownSupreme from '../versions-dropdown/versions-dropdown-supreme';
import { Wave } from './wave/wave';
import { TrackTitle } from './track-title/track-title';

export function SupremeMiniPlayer() {
    const { currentTrack, hasNextTrack, hasPrevTrack, nextTrack, prevTrack, setIsMaxiPlayer, replaceCurrentPlayingTrack, onPlay, onPause } = usePlayer();
    const playerState = usePlayerState();

    const { openThreeDotsModalSheet } = useContext(ThreeDotsSheetContext);
    const { isDownload } = useHubSwitch();
    const { data: downloadAlbumData } = useDownloadAlbumById(currentTrack?.album_id, isDownload);
    const { isMobile } = useViewport();
    const volumeSliderRef = useRef<HTMLDivElement>(null);

    const { isAnonymous, setSelectedMedia } = useUserSettings();

    const setCurrentMediaInContext = useCallback(() => {
        if (isAnonymous && downloadAlbumData?.data) {
            const currentCenterMedia = downloadAlbumData?.data;
            if (currentCenterMedia) setSelectedMedia(currentCenterMedia as unknown as CreateCardCarouselItem, [downloadAlbumData?.data] as unknown as CreateCardCarouselItem[]);
        }
    }, [isAnonymous, downloadAlbumData?.data, setSelectedMedia]);

    useEffect(() => {
        if (isAnonymous) {
            setCurrentMediaInContext();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAnonymous]);

    const dragEvents = useDrag(
        ({ movement: [, mY], velocity: [, vY], down, event }) => {
            if (mY > 0 || down) return;
            if (volumeSliderRef.current?.contains(event.target as Node)) {
                return;
            }
            const swipeUpVelocity = vY > 0.5;
            if (swipeUpVelocity) {
                setIsMaxiPlayer(true);
            }
        },
        { delay: true }
    );

    const miniPlayerClickableProps = useMemo(() => {
        if (!isMobile) return {};

        return {
            role: 'button',
            tabIndex: 0,
            onClick: (e: MouseEvent) => {
                e.stopPropagation();
                setIsMaxiPlayer(true);
            },
            'aria-label': 'Open Maxi Player',
        };
    }, [isMobile, setIsMaxiPlayer]);

    const generateArtistLinks = useArtistLinks();

    useEffect(() => {
        if (currentTrack?.album_id) {
            Analytics.trackImpression('Album', `${currentTrack.album_id}`);
        }
    }, [currentTrack]);

    if (!currentTrack) return null;

    const versionOptions = downloadAlbumData?.data.media.map((media, index) => ({
        versionName: media.version.name,
        versionId: media.id,
        versionIndex: index,
        versionDuration: parseSeconds(media.estimated_duration || 0),
    }));

    return (
        <div {...(dragEvents as any)()} {...miniPlayerClickableProps} className={styles['mini-player']}>
            <div
                className={styles['mini-player__container']}
                role="button"
                onKeyPress={() => { }}
                tabIndex={0}
                onClick={(e) => {
                    e.stopPropagation();
                    setIsMaxiPlayer(true);
                }}
            >
                <SeekSlider className={styles['mini-player__seek-slider']} />
                <div className={styles['mini-player__track-details']}>
                    <img
                        src={appendQueryParams(currentTrack.cover_url, { key: 'dw', value: 64 })}
                        srcSet={`${appendQueryParams(currentTrack.cover_url, { key: 'dw', value: 128 })} 2x`}
                        alt={currentTrack.title}
                        className={styles['mini-player__details-cover']}
                    />
                    <div className={classNames(styles['mini-player__details-title-wrapper'])}>
                        <TrackTitle currentTrack={currentTrack} setIsMaxiPlayer={setIsMaxiPlayer} />
                        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */}
                        <p onClick={(e) => e.stopPropagation()} className={styles['mini-player__details-artist']}>{generateArtistLinks(currentTrack.artist, currentTrack.artists)}</p>
                    </div>

                    {currentTrack.versions && currentTrack.versions - 1 > 0 && (
                        <div className={styles['mini-player__version-dropdown']}>
                            <VersionsDropdownSupreme<number>
                                currentVersion={currentTrack.version}
                                onClick={(version) => {
                                    replaceCurrentPlayingTrack(downloadAlbumWithMediaToQueueItem(downloadAlbumData.data, version));
                                }}
                                // 200 for the width of the context menu, 7 for padding, 246 for the height of the context menu
                                openThreeDotsMenu={(versionId, leftPos, topPos) => openThreeDotsModalSheet('mini-player-versions', versionId, leftPos + 207, topPos - 246, false, currentTrack?.album_id)}
                                placeholder={convertToPluralIfNeeded(currentTrack.versions, 'Version')}
                                options={versionOptions}
                                layoutType="mini"
                                openDirection="upward"
                            />
                        </div>
                    )}
                </div>

                <div className={styles['mini-player__controls']}>
                    <div className={styles['mini-player__controls-buttons']}>
                        <button
                            className={classNames(styles['mini-player__control'], styles['mini-player__control--prev'])}
                            type="button"
                            onClick={(e) => {
                                e.stopPropagation();
                                prevTrack();
                            }}
                            disabled={!hasPrevTrack}
                            aria-label="Previous Track"
                        >
                            <PrevIcon />
                        </button>
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                if (playerState === PlayerStateEnum.Playing) {
                                    // Should this be controlled at context level?
                                    // Should be the same for Maxi and mini
                                    onPause();
                                } else {
                                    onPlay();
                                }
                            }}
                            className={classNames(styles['mini-player__control'], {
                                [styles['mini-player__control--play']]: playerState !== PlayerStateEnum.Playing,
                                [styles['mini-player__control--pause']]: playerState === PlayerStateEnum.Playing,
                            })}
                            type="button"
                            aria-label={playerState === PlayerStateEnum.Playing ? 'Pause' : 'Play'}
                        >
                            {playerState === PlayerStateEnum.Playing ? <PauseIcon /> : <PlayIcon />}
                        </button>
                        <button
                            className={classNames(styles['mini-player__control'], styles['mini-player__control--next'])}
                            type="button"
                            onClick={(e) => {
                                e.stopPropagation();
                                nextTrack();
                            }}
                            disabled={!hasNextTrack}
                            aria-label="Next Track"
                        >
                            <NextIcon />
                        </button>
                    </div>

                    <Wave />
                </div>

                <div className={styles['mini-player__actions']}>
                    <MiniDownloadButton trackId={currentTrack.id} />
                    <div className={styles['mini-player__menu-wrapper']}>
                        <ThreeDotsButton
                            onClick={(e) => {
                                e.stopPropagation();
                                openThreeDotsModalSheet(
                                    'mini-player',
                                    currentTrack.id,
                                    e.currentTarget.getBoundingClientRect().left + 20,
                                    e.currentTarget.getBoundingClientRect().top + window.scrollY,
                                    false,
                                    currentTrack.album_id
                                );
                            }}
                            hasTooltip
                        />
                    </div>
                    <button
                        className={classNames(styles['mini-player__action'], styles['mini-player__action--maxi-player'])}
                        type="button"
                        aria-label="Open Maxi Player"
                        data-tip="Open Maxi Player"
                        data-tip-show
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsMaxiPlayer(true);
                        }}
                    >
                        <MaxiIcon />
                    </button>
                </div>
            </div>
        </div>
    );
}
