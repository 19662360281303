import { useGetSuggestedSoundPackages, useInfiniteSearchSound } from '@bpm-web-app/swr-hooks';
import { convertSoundPackageToCarouselProps, createAppRoutes, soundPackageToCreatePlayable, getMutatedSounds, useCreateFilterParams } from '@bpm-web-app/utils';
import { ComponentProps, useCallback, useMemo } from 'react';
import { BreakpointView } from '../../shared/ui';
import { CreateCardCarousel } from '../../create-card-carousel/create-card-carousel';
import { useCreatePlayer } from '../../../../../utils/src/lib/create-player.context';
import { HorizontalTabs } from '../../shared/ui/horizontal-tabs/horizontal-tabs';
import NoResultsBlock from '../../shared/ui/no-results-block/no-results-block';
import { PackItemList } from '../../pack-item-list/pack-item-list';
import PrimaryPageTitle from '../../shared/primary-page-title/primary-page-title';
import SecondaryPageTitle from '../../shared/secondary-page-title/secondary-page-title';
import TrackListCreate from '../../shared/track-list/create/track-list-create';
import { ClickableGenres } from '../../clickable-genres/clickable-genres';

import styles from './suggested-packs-sounds.module.css';

export const Suggestions = () => {
    const { playDemo } = useCreatePlayer();

    const query = useCreateFilterParams();

    const { data: suggestedSoundPacksData, isLoading: isLoadingPacks } = useGetSuggestedSoundPackages();

    const suggestedSoundPacks = useMemo(() => suggestedSoundPacksData?.data, [suggestedSoundPacksData]);

    const {
        data: suggestedSoundsData,
        isLoadingInitialData,
        isLoadingMore: isLoadingMoreSounds,
        setSize: setSoundsSize,
        isLastPage: isSoundsLastPage,
        mutate,
    } = useInfiniteSearchSound({
        ...query,
        suggested: true,
    });

    const mutateSound = useCallback(
        (id: string, progress: number) => {
            mutate(getMutatedSounds(suggestedSoundsData, id, progress));
        },
        [mutate, suggestedSoundsData]
    );

    const suggestedCarouselData = useMemo(() => suggestedSoundPacks?.map((data) => convertSoundPackageToCarouselProps(data, <ClickableGenres genres={data.Genre} />)), [suggestedSoundPacks]);

    const suggestedSounds = useMemo(() => suggestedSoundsData?.flatMap((data) => data.data), [suggestedSoundsData]);

    const totalSuggestedSounds = useMemo(() => suggestedSoundsData?.[0]?.pagination?.total || 0, [suggestedSoundsData]);

    const renderNoResults = useCallback((text: string) => <NoResultsBlock verticalMargins={16}>{text}</NoResultsBlock>, []);

    const handleLoadMoreSounds = useCallback(() => {
        if (!isLoadingMoreSounds && !isSoundsLastPage) {
            setSoundsSize((prevSize) => prevSize + 1);
        }
    }, [isSoundsLastPage, isLoadingMoreSounds, setSoundsSize]);

    const mobileTabs = useMemo<ComponentProps<typeof HorizontalTabs>['tabs']>(
        () => [
            {
                id: 'packs',
                title: 'Packs',
                count: suggestedSoundPacks?.length,
                activeTabComponent: <PackItemList data={suggestedSoundPacks} />,
            },
            {
                id: 'sounds',
                title: 'Sounds',
                count: totalSuggestedSounds,
                activeTabComponent: (
                    <div className={styles['suggested-packs-sounds__mobile-track-list']}>
                        <TrackListCreate
                            mutateSound={mutateSound}
                            list={suggestedSounds}
                            hideHeading
                            onLoadMore={handleLoadMoreSounds}
                            isLoadingMore={isLoadingMoreSounds}
                        />
                    </div>
                ),
            },
        ],
        [handleLoadMoreSounds, suggestedSoundPacks, isLoadingMoreSounds, suggestedSounds, mutateSound, totalSuggestedSounds]
    );

    return (
        <div className={styles['suggested-packs-sounds']}>
            <div className={styles['suggested-packs-sounds__header']}>
                <PrimaryPageTitle title="Suggested Packs & Sounds" />
            </div>
            <BreakpointView
                mobileChildren={
                    <div className={styles['suggested-packs-sounds__horizontal-tabs-container']}>
                        <HorizontalTabs tabs={mobileTabs} />
                    </div>
                }
                desktopChildren={
                    <>
                        <CreateCardCarousel
                            contentType="pack"
                            items={suggestedCarouselData}
                            cardSize="small"
                            carouselMorePath={undefined}
                            isLoading={isLoadingPacks}
                            carouselTitle="Packs"
                            carouselTitleCounter={`${suggestedSoundPacks?.length}`}
                            onCardPlay={(id) => playDemo(soundPackageToCreatePlayable(suggestedSoundPacks.find((item) => item.id === id)))}
                            noResultsText="No Suggested Packs Available"
                            itemBasePath={createAppRoutes.packs}
                        />
                        <SecondaryPageTitle title="Sounds" counter={totalSuggestedSounds} />

                        {suggestedSounds?.length && (
                            <div className={styles['suggested-packs-sounds__track-list']}>
                                <TrackListCreate
                                    mutateSound={mutateSound}
                                    list={suggestedSounds}
                                    onLoadMore={handleLoadMoreSounds}
                                    isLoadingMore={isLoadingMoreSounds} />
                            </div>
                        )}
                        {!isLoadingInitialData && !suggestedSounds?.length && renderNoResults('No Suggested Sounds Available')}
                    </>
                }
            />
        </div>
    );
};
