import { useEffect, useState } from 'react';

export enum State {
    None = 0,
    Error = 1,
    Paused = 2,
    Loading = 3,
    Buffering = 4,
    Playing = 5,
    Ended = 6,
}

export type StateChangeCallback = (state: State) => void;
export type LoadedSoundChangeCallback = (loadedSound: boolean) => void;
export type ProgressCallback = (progress: number) => void;
export type StartOffsetCallback = (progress: number) => void;
export type DurationCallback = (duration: number) => void;

export class PlayerState {
    private static localState: State = State.None;

    private static localLoadedSound = false;

    private static localProgress = 0;

    private static localDuration = 0;

    private static localStartTimeOffset = 0;

    static stateChangeCallbacks: StateChangeCallback[] = [];

    static loadedSoundChangeCallbacks: LoadedSoundChangeCallback[] = [];

    static progressCallbacks: ProgressCallback[] = [];

    static startOffsetCallbacks: StartOffsetCallback[] = [];

    static durationCallbacks: DurationCallback[] = [];

    static get START_TIME_OFFSET() {
        return PlayerState.localStartTimeOffset;
    }

    static set START_TIME_OFFSET(offset: number) {
        PlayerState.localStartTimeOffset = offset;
        this.startOffsetCallbacks.forEach((callback) => {
            callback(offset);
        });
    }

    static get STATE() {
        return PlayerState.localState;
    }

    static set STATE(state: State) {
        PlayerState.localState = state;
        this.stateChangeCallbacks.forEach((callback) => {
            callback(state);
        });
    }

    static get LOADED_SOUND() {
        return PlayerState.localLoadedSound;
    }

    static set LOADED_SOUND(loadedSound: boolean) {
        PlayerState.localLoadedSound = loadedSound;
        this.loadedSoundChangeCallbacks.forEach((callback) => {
            callback(loadedSound);
        });
    }

    static get PROGRESS() {
        return PlayerState.localProgress;
    }

    static set PROGRESS(progress: number) {
        PlayerState.localProgress = progress;
        this.progressCallbacks.forEach((callback) => {
            callback(progress);
        });
    }

    static get DURATION() {
        return PlayerState.localDuration;
    }

    static set DURATION(duration: number) {
        PlayerState.localDuration = duration;
        this.durationCallbacks.forEach((callback) => {
            callback(duration);
        });
    }

    static reset() {
        PlayerState.PROGRESS = 0;
        PlayerState.DURATION = 0;
        PlayerState.START_TIME_OFFSET = 0;
        PlayerState.STATE = State.None;
    }

    static onDuration(callback: DurationCallback) {
        PlayerState.durationCallbacks.push(callback);
    }

    static offDuration(callback: DurationCallback) {
        const index = PlayerState.durationCallbacks.indexOf(callback);
        if (index !== -1) PlayerState.durationCallbacks.splice(index, 1);
    }

    static onProgress(callback: ProgressCallback) {
        PlayerState.progressCallbacks.push(callback);
    }

    static offProgress(callback: ProgressCallback) {
        const index = PlayerState.progressCallbacks.indexOf(callback);
        if (index !== -1) PlayerState.progressCallbacks.splice(index, 1);
    }

    static onStartOffset(callback: StartOffsetCallback) {
        PlayerState.startOffsetCallbacks.push(callback);
    }

    static offStartOffset(callback: StartOffsetCallback) {
        const index = PlayerState.startOffsetCallbacks.indexOf(callback);
        if (index !== -1) PlayerState.startOffsetCallbacks.splice(index, 1);
    }

    static onPlayerStateChange(callback: StateChangeCallback) {
        PlayerState.stateChangeCallbacks.push(callback);
    }

    static offPlayerStateChange(callback: StateChangeCallback) {
        const index = PlayerState.stateChangeCallbacks.indexOf(callback);
        if (index !== -1) PlayerState.stateChangeCallbacks.splice(index, 1);
    }

    static onPlayerLoadedSoundChange(callback: LoadedSoundChangeCallback) {
        PlayerState.loadedSoundChangeCallbacks.push(callback);
    }

    static offPlayerLoadedSoundChange(callback: LoadedSoundChangeCallback) {
        const index = PlayerState.loadedSoundChangeCallbacks.indexOf(callback);
        if (index !== -1) PlayerState.loadedSoundChangeCallbacks.splice(index, 1);
    }
}

export function useLoadedSound() {
    const [isSoundLoaded, setIsSoundLoaded] = useState<boolean>(false);

    useEffect(() => {
        PlayerState.onPlayerLoadedSoundChange(setIsSoundLoaded);

        return () => {
            PlayerState.offPlayerLoadedSoundChange(setIsSoundLoaded);
        };
    }, []);

    return isSoundLoaded;
}

export function usePlayerState() {
    const [playerState, setPlayerState] = useState<State>(PlayerState.STATE);

    useEffect(() => {
        PlayerState.onPlayerStateChange(setPlayerState);

        return () => {
            PlayerState.offPlayerStateChange(setPlayerState);
        };
    }, []);

    return playerState;
}
