import { useRouter } from 'next/router';
import { useActionModal } from '@bpm-web-app/components/src/lib/shared/action-modal/action-modal.context';
import {
    dailyDownloadLimitReachedModalProps,
    libraryRequiredActionModalProps,
    membershipRequiredActionModalProps,
    notEnoughCreditsActionModalProps,
    upgradeRequiredActionModalProps,
    weeklyDownloadLimitReachedModalProps,
} from '@bpm-web-app/components/src/lib/shared/action-modal/action-modal';
import { useCallback, useContext } from 'react';
import { NotEnoughCreditsError } from '@bpm-web-app/create-api-sdk';
import { showToast } from './toast';
import { HistoryContext } from './useHistory';
import { getCurrentPlatformLink } from './getPlatformLink';
import { useUserSettings } from './userSettings.context';

interface ErrorHandlerProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: any;
    message?: string;
}

export const useApiErrorHandler = (isCreate?: boolean, isArtistPortal?: boolean) => {
    const { openModal, closeModal: closeActionModal } = useActionModal();
    const router = useRouter();
    const { history } = useContext(HistoryContext);
    const { setIsAnonymous } = useUserSettings();

    const getPreviousValidUrl = useCallback(() => {
        let nextUrl = '/';
        if (history.length && window.location) {
            /**
             * We want remove the routes that contain the current path
             * Sometimes the history can contain the router.pathname which are the
             * same location as the current path
             *
             * Also the history persists the base path but the router contains this
             * in a separate prop
             *
             * If it's coming from a redirect and there's no previous history it will default to home
             */
            const filteredHistory = history
                .map((path) => path?.split('?')[0].replace(router.basePath, '/').replace('//', '/'))
                .filter(Boolean)
                .filter((path) => {
                    const pathResource = path.split('/').filter(Boolean);
                    const routerResource = router.route.split('/').filter(Boolean);

                    if (path === router.asPath.split('?')[0]) return false;
                    if (path === router.route) return false;
                    if (path.includes('error')) return false;
                    if (pathResource[0] === routerResource[0]) return false;
                    return true;
                });

            if (filteredHistory.length) {
                nextUrl = filteredHistory[filteredHistory.length - 1];
            }
        }

        return nextUrl;
    }, [history, router.asPath, router.basePath, router.route]);

    return async ({ error, message }: ErrorHandlerProps) => {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        const bpmBasePath = process.env['NEXT_PUBLIC_POOL_URL'];
        if ((!('json' in error) || error?.bodyUsed) && isArtistPortal) {
            return null;
        }
        const errorData = error?.json && (await error.json());
        switch (errorData?.error?.type) {
            case 'InvalidSubscriptionError':
            case 'SubscriptionRequiredError':
                if (errorData.error.current_supscription > 0) {
                    openModal(
                        upgradeRequiredActionModalProps({
                            requiredSubscription: errorData.error.required_subscription,
                            onConfirm: () => {
                                router.replace(getCurrentPlatformLink('/account/plan'));
                                closeActionModal();
                            },
                            onClose: () => {
                                // Check if the current route has an id or slug in a detail page
                                const idKey = Object.keys(router.query).find((key) => key.toLowerCase().includes('id') || key.toLowerCase().includes('slug'));

                                if (idKey && (router.asPath.includes('curated-set') || router.asPath.includes('exclusive-playlist'))) {
                                    const nextUrl = getPreviousValidUrl();
                                    router.replace(nextUrl);
                                }
                                closeActionModal();
                            },
                        })
                    );
                } else {
                    openModal(
                        membershipRequiredActionModalProps({
                            isCreate,
                            onConfirm: () => {
                                router.replace(getCurrentPlatformLink('/account/plan'));
                                closeActionModal();
                            },
                            onClose: () => {
                                // Check if the current route has an id or slug in a detail page
                                const idKey = Object.keys(router.query).find((key) => key.toLowerCase().includes('id') || key.toLowerCase().includes('slug'));

                                if (idKey && (router.asPath.includes('curated-set') || router.asPath.includes('exclusive-playlist'))) {
                                    const nextUrl = getPreviousValidUrl();
                                    router.replace(nextUrl);
                                }
                                closeActionModal();
                            },
                        })
                    );
                }
                break;
            case 'NotSubscribedToLibraryError':
                openModal(
                    libraryRequiredActionModalProps({
                        onConfirm: () => {
                            router.replace(getCurrentPlatformLink('/account/plan'));
                            closeActionModal();
                        },
                        onClose: () => {
                            // Check if the current route has an id or slug in a detail page
                            const idKey = Object.keys(router.query).find((key) => key.toLowerCase().includes('id') || key.toLowerCase().includes('slug'));

                            if (idKey && (router.asPath.includes('curated-set') || router.asPath.includes('exclusive-playlist'))) {
                                const nextUrl = getPreviousValidUrl();
                                router.replace(nextUrl);
                            }
                            closeActionModal();
                        },
                        library: errorData?.error?.library_needed,
                    })
                );
                break;
            case 'NotEnoughCreditsError':
                openModal(
                    notEnoughCreditsActionModalProps({
                        onConfirm: () => {
                            router.replace(getCurrentPlatformLink('/account/plan'));
                            closeActionModal();
                        },
                        onClose: () => {
                            closeActionModal();
                        },
                        creditsRequired: (errorData?.error as NotEnoughCreditsError)?.credits_required,
                    })
                );
                break;
            case 'DownloadLimitReachedError':
                showToast({ type: 'error', message: 'Download Limit Reached.' });
                break;
            case 'WeeklyDownloadLimitReachedError':
                openModal(
                    weeklyDownloadLimitReachedModalProps({
                        onConfirm: () => {
                            window.open('https://support.bpmsupreme.com/hc/en-us', '__blank');
                            closeActionModal();
                        },
                        onClose: () => {
                            closeActionModal();
                        },
                    })
                );
                break;
            case 'DailyDownloadLimitReachedError':
                openModal(
                    dailyDownloadLimitReachedModalProps({
                        onConfirm: () => {
                            window.open('https://support.bpmsupreme.com/hc/en-us', '__blank');
                            closeActionModal();
                        },
                        onClose: () => {
                            closeActionModal();
                        },
                    })
                );
                break;
            case 'MissingAuthorizationError':
            case 'SessionNotFoundError':
                // eslint-disable-next-line max-len
                // NOTE: Since BPM is still redirecting to the current app we will force the redirect to bynd app
                // let path = window?.location.pathname.split('/').map(encodeURIComponent).join('/');
                // if (window?.location.search) {
                //   // eslint-disable-next-line no-unsafe-optional-chaining
                //   path += window?.location.search;
                // }
                // eslint-disable-next-line @typescript-eslint/dot-notation
                // const platform = process.env['NEXT_PUBLIC_PLATFORM'];
                // Router.replace(`${bpmBasePath}/login?platform=${platform}&pool_redirect=${path}`);
                if (isArtistPortal) router.replace(`${bpmBasePath}/login?redirect=${encodeURIComponent(window.location.href)}`);
                else setIsAnonymous(true);
                break;

            default:
                if (!errorData) return null;
                if (errorData.error?.message === 'No Ad available') return null;
                showToast({ type: 'error', message: errorData.error?.message ?? 'Unavailable. Please try again later.' });
                break;
        }
        return errorData;
    };
};
