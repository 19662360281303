import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useManagingArtist } from '@bpm-web-app/swr-hooks';
import { ApplicationHeader } from '../artist-application/artist-application-header/artist-application-header';
import { ApplicationSectionHeader } from '../artist-application/artist-application-text/artist-application-text';
import { ManageProfileForm, ProfileEditInputs } from './manage-profile-form/manage-profile-form';
import styles from './manage-profile.module.css';
import Checkmark from '../../../../assets/icons/artist-platform/checkmark.svg';
import PageSwitcher, { Page } from '../../../page-switcher/page-switcher';
import { BreakpointView } from '../../../shared';
import { ArtistDetailHeader } from '../../artist-detail/artist-detail-header/artist-detail-header';
import { ArtistDetailSections } from '../../artist-detail/artist-detail-sections/artist-detail-sections';
import { ArtistDetailInfo } from '../../artist-detail/artist-detail-info/artist-detail-info';

export function ManageProfile() {
    const { data, isLoading } = useManagingArtist();
    const artist = useMemo(() => data?.data, [data]);

    const [selectedPage, setSelectedPage] = useState<number>(0);
    const [profileData, setProfileData] = useState<ProfileEditInputs>(null);
    const [artistName, setArtistName] = useState<string>(artist?.name ? artist.name : '');
    const [profilePhoto, setProfilePhoto] = useState<File>();
    const [bioText, setBioText] = useState<string>(artist?.description ? artist.description : '');
    const [mediaLinks, setMediaLinks] = useState<string[]>(artist?.social_media ? artist.social_media : []);

    const profileFormRef: MutableRefObject<HTMLFormElement> = useRef();

    useEffect(() => {
        if (artist) {
            setProfileData({
                artistName: artist.name,
                bio: artist.description,
                social_links: artist.social_media
            });
            setArtistName(artist.name);
            setBioText(artist.description);
            setMediaLinks(artist.social_media);
        }
    }, [artist]);

    const artistInfoComponent = useMemo(() => {
        return <div className={styles['manage-profile--info']}><ArtistDetailInfo bio={bioText} links={mediaLinks} /></div>;
    }, [bioText, mediaLinks]);

    const musicComponent = useMemo(() => {
        return null;
    }, []);

    const pagesData = useCallback(() => [
        {
            text: 'Request Changes',
            leftIcon: <Checkmark />,
            enabled: true,
            completed: false,
            onSelected: (index: number) => {
                setSelectedPage(index);
            }
        },
        {
            text: 'Preview',
            leftIcon: <Checkmark />,
            color: 'var(--color-gin)',
            enabled: true,
            completed: false,
            form: 'profileEdit',
            buttonType: 'submit',
            onSelected: (index: number) => {
                setSelectedPage(index);
            }
        },
    ], []);

    const [pages, setPages] = useState<Page[]>(pagesData());

    const currentPage = useMemo(() => {
        switch (selectedPage) {
            case 0:
                return <ManageProfileForm
                    profileData={profileData}
                    setProfileData={setProfileData}
                    profileFormRef={profileFormRef}
                    setArtistName={setArtistName}
                    setArtistImage={setProfilePhoto}
                    setBio={setBioText}
                    setMediaLinks={setMediaLinks} />;
            case 1:
                return (
                    <>
                        <div className={styles['manage-profile--artist-header']}>
                            <ArtistDetailHeader
                                isPreview
                                artistName={artistName}
                                isArtistFollowed={false}
                                isPlaying={false}
                                imageFile={profilePhoto}
                                artistImageUrl={artist?.artwork_url} />
                        </div>
                        <div className={styles['manage-profile--artist-sections']}>
                            <ArtistDetailSections musicComponent={musicComponent} artistInfoComponent={artistInfoComponent} />
                        </div>
                    </>
                );
            default:
                return null;
        }
    }, [artist?.artwork_url, artistInfoComponent, artistName, musicComponent, profileData, profilePhoto, selectedPage]);

    const mobileLayout = useMemo(() => {
        return (
            <div>
                <PageSwitcher pages={pages} selected={selectedPage} />
                {currentPage}
            </div>
        );
    }, [currentPage, pages, selectedPage]);

    const wideLayout = useMemo(() => {
        return (
            <div>
                <div className={styles['manage-profile--divider']} />
                <div className={styles['manage-profile--horizontal-container']}>
                    <div className={styles['manage-profile--artist-header-container']}>
                        <ApplicationSectionHeader subtitle="Preview" />
                        <div className={styles['manage-profile--artist-header']}>

                            <ArtistDetailHeader
                                isPreview
                                artistName={artistName}
                                isArtistFollowed={false}
                                isPlaying={false}
                                imageFile={profilePhoto}
                                artistImageUrl={artist?.artwork_url}
                            />
                            {artistInfoComponent}
                        </div>
                    </div>
                    <div className={styles['manage-profile--form-container']}>
                        <ApplicationSectionHeader subtitle="Request Changes" />
                        <ManageProfileForm
                            profileData={profileData}
                            setProfileData={setProfileData}
                            profileFormRef={profileFormRef}
                            setArtistName={setArtistName}
                            setArtistImage={setProfilePhoto}
                            setBio={setBioText}
                            setMediaLinks={setMediaLinks} />;
                    </div>
                </div>
            </div>
        );
    }, [artist?.artwork_url, artistInfoComponent, artistName, profileData, profilePhoto]);

    useEffect(() => {
        setPages(pagesData());
    }, [pagesData]);

    if (isLoading || !profileData) {
        return null;
    }

    return (
        <div className={styles['manage-profile']}>
            <ApplicationHeader artistLogo title="Manage Your Artist Profile" subtitle="Request changes to your public artist profile on BPM Supreme." />
            <BreakpointView
                mobileChildren={mobileLayout}
                desktopChildren={wideLayout}
            />
        </div>
    );
}
