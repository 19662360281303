import { useCreateGenres } from '@bpm-web-app/swr-hooks';
import { createAppRoutes } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import CategoriesGrid from '../../categories-grid/categories-grid';
import { BreakpointView } from '../../shared';
import PrimaryPageTitle from '../../shared/primary-page-title/primary-page-title';
import { SearchInput } from '../../shared/search-input/search-input';
import styles from './genres.module.css';

export function GenresCreate() {
    const { genres, isLoading: isGenresLoading } = useCreateGenres();
    const [searchQuery, setSearchQuery] = useState<string>('');

    const allGenres = useMemo(
        () => {
            const genresArray: {
                id: string;
                name: string;
                href: string;
                icon?: string;
            }[] = [];
            genres?.forEach((genre) => {
                genresArray.push({
                    id: genre.id,
                    name: genre.name,
                    href: createAppRoutes.genresSlug(genre.name),
                    icon: genre.icon_svg,
                });
            });
            return genresArray;
        }, [genres]);

    const allSubgenre = useMemo(
        () => {
            let subgenre: {
                id: string;
                name: string;
                href: string;
                icon?: string;
            }[] = [];
            genres?.forEach((genre) => {
                genre.SubGenres.forEach(({ id, name, slug }) => {
                    subgenre.push({
                        id,
                        name,
                        href: slug ? createAppRoutes.genresSlugWithSubgenre(genre.name, name) : createAppRoutes.genresSlug(genre.name),
                    });
                });
            });

            if (searchQuery) {
                subgenre = subgenre?.filter((genre) => genre.name.toLowerCase().includes(searchQuery.toLowerCase()));
            }

            return subgenre;
        }, [genres, searchQuery]);

    return (
        // eslint-disable-next-line @typescript-eslint/dot-notation
        <section className={classNames(styles['genres'], 'spacing__window')}>
            <BreakpointView
                mobileChildren={
                    <>
                        <div className={styles['genres__header']}>
                            <div className={styles['genres__title']}>
                                <PrimaryPageTitle title={searchQuery ? 'Subgenres' : 'Genres'} noPadding />
                            </div>
                            <SearchInput
                                placeholder="Search Subgenres"
                                value={searchQuery}
                                variant="small"
                                onChange={(e) => {
                                    setSearchQuery(e.target.value);
                                }}
                                onClear={() => setSearchQuery('')}
                            />
                        </div>
                        {searchQuery
                            ? null : <CategoriesGrid elements={allGenres} isLoading={isGenresLoading} genreVariant="has-icon" />}

                    </>
                }
                desktopChildren={(
                    <>
                        <div className={styles['genres__header']}>
                            <div className={styles['genres__title']}>
                                <PrimaryPageTitle title="Genres" noPadding />
                            </div>
                        </div>
                        <CategoriesGrid elements={allGenres.slice(0, 3)} isLoading={isGenresLoading} genreVariant="has-icon" gridVariant="wide" ghostElementsCount={3} />
                        <CategoriesGrid elements={allGenres.slice(3)} isLoading={isGenresLoading} genreVariant="has-icon" gridVariant="narrow" />
                    </>
                )}
            />

            <BreakpointView
                mobileChildren={
                    <CategoriesGrid title={searchQuery ? '' : 'Subgenres'} elements={allSubgenre} isLoading={isGenresLoading} />

                }
                desktopChildren={
                    <CategoriesGrid title="Subgenres" elements={allSubgenre} isLoading={isGenresLoading} hasSearch searchPlaceholder="Search Subgenres" searchTerm={searchQuery} setSearchTerm={setSearchQuery} gridVariant="medium" />
                }
            />
        </section>
    );
}

export default GenresCreate;
