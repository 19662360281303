import React, { memo, useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import styles from './load-more.module.css';
import { LoadingSpinner } from '../loading-spinner/loading-spinner';

type LoadMoreProps = {
    onLoadMore: () => void;
    /** Default value is 0.8 of the screen height.
     * The fewer items per page you're loading, the higher the threshold needs to be
     * in order to trigger the onLoadMore.
     * TODO: potentially revisit this to make it more bulletproof */
    loadThreshold?: number;
    isLoadingMore?: boolean;
};
export const LoadMore = memo(({ onLoadMore, loadThreshold = 0.8, isLoadingMore = false }: LoadMoreProps) => {
    const { ref, inView } = useInView({ initialInView: false });

    if (loadThreshold && loadThreshold < 0 && loadThreshold > 1) {
        throw new Error('loadThreshold must be between 0 and 1');
    }
    useEffect(() => {
        if (isLoadingMore) {
            return;
        }
        if (inView) onLoadMore();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView, isLoadingMore]);

    const divStyle = useMemo<React.CSSProperties>(() => {
        const loadPosition = window.screen.height - window.screen.height * loadThreshold;

        return { bottom: `${loadPosition}px` };
    }, [loadThreshold]);

    return (
        <div className={styles['load-more__relative-wrapper']}>
            <LoadingSpinner isLoading={isLoadingMore} />
            <div id="loader" className={styles['load-more__trigger']} aria-hidden ref={ref} style={divStyle} />
        </div>
    );
});
