import { ReactComponent as BPMLogo } from '../../../../../assets/icons/bpm-artist-platform-logo-icon.svg';
import { ReactComponent as RedBPMLogo } from '../../../../../assets/icons/bpm-supreme-logo-icon.svg';
import { ReactComponent as BPMYellowLogo } from '../../../../../assets/icons/bpm-artist-platform-logo-yellow.svg';
import styles from './artist-application-header.module.css';

interface ApplicationHeaderProps {
    title: string;
    subtitle: string;
    redLogo?: boolean;
    artistLogo?: boolean;
    noPadding?: boolean;
}

export function ApplicationHeader({ title, subtitle, redLogo, noPadding = false, artistLogo }: ApplicationHeaderProps) {
    return (
        <div className={styles[noPadding ? '' : 'application-header']}>
            {/* Image */}
            {redLogo ? <RedBPMLogo className={styles['application-header--logo']} /> : artistLogo ? <BPMYellowLogo className={styles['application-header--logo']} /> : <BPMLogo className={styles['application-header--logo']} />}
            <div className={styles['application-header--title']}>
                {/* title */}
                {title}
            </div>
            <div className={styles['application-header--subtitle']}>
                {/* subtitle */}
                {subtitle}
            </div>
        </div>
    );
}
