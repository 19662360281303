import { SoundPackageApi } from '@bpm-web-app/create-api-sdk';
import { createConfig } from '../../config';
import { CreateSearchPaginatedQuery, GenericPaginatedQuery } from '../../typings';

export class SoundPackage {
    static client = new SoundPackageApi(createConfig);

    static getSoundPackages = (args: CreateSearchPaginatedQuery) =>
        SoundPackage.client.getSoundPackages(
            args.limit,
            args.skip,
            args.sort_by,
            args.search,
            args.bpm_max,
            args.bpm_min,
            args.key as string,
            args.keyType,
            args.type,
            args.tags,
            args.tag_groups,
            args.artists,
            args.subgenre,
            args.label,
            args.sound_package_id,
            args.curated_id,
            args.drive_id,
            args.genre as string,
            args.showHalfTime,
            args.suggested,
            args.hide_prev_played,
            args.hide_prev_downloaded,
            args.only_free,
            args.tag_categories,
            args.trending,
            args?.synth
        );

    static getNewestSoundPackages = (args?: CreateSearchPaginatedQuery) =>
        SoundPackage.client.getNewestSoundPackages(
            args?.limit,
            args?.skip,
            args?.search,
            args?.bpm_max,
            args?.bpm_min,
            args?.key as string,
            args?.keyType,
            args?.type,
            args?.tags,
            args?.tag_groups,
            args?.artists,
            args?.subgenre,
            args?.label,
            args?.sound_package_id,
            args?.curated_id,
            args?.drive_id,
            args?.genre as string,
            args?.showHalfTime,
            args?.suggested,
            args?.hide_prev_played,
            args?.hide_prev_downloaded,
            args?.only_free,
            args?.tag_categories,
            args?.trending,
            args?.synth
        );

    static getFeaturedSoundPackages = (args?: CreateSearchPaginatedQuery) =>
        SoundPackage.client.getFeaturedSoundPackages(
            args?.limit,
            args?.skip,
            args?.search,
            args?.bpm_max,
            args?.bpm_min,
            args?.key as string,
            args?.keyType,
            args?.type,
            args?.tags,
            args?.tag_groups,
            args?.artists,
            args?.subgenre,
            args?.label,
            args?.sound_package_id,
            args?.curated_id,
            args?.drive_id,
            args?.genre as string,
            args?.showHalfTime,
            args?.suggested,
            args?.hide_prev_played,
            args?.hide_prev_downloaded,
            args?.only_free,
            args?.tag_categories,
            args?.trending,
            args?.synth
        );

    static getSuggestedSoundPackages = () => SoundPackage.client.getSuggestedSoundPackages();

    static getTrendingSoundPackages = (args?: CreateSearchPaginatedQuery) =>
        SoundPackage.client.getTrendingSoundPackages(
            args?.limit,
            args?.skip,
            args?.search,
            args?.bpm_max,
            args?.bpm_min,
            args?.key as string,
            args?.keyType,
            args?.type,
            args?.tags,
            args?.tag_groups,
            args?.artists,
            args?.subgenre,
            args?.label,
            args?.sound_package_id,
            args?.curated_id,
            args?.drive_id,
            args?.genre as string,
            args?.showHalfTime,
            args?.suggested,
            args?.hide_prev_played,
            args?.hide_prev_downloaded, // ToDo: BPM-2775
            args?.only_free,
            args?.tag_categories,
            args?.trending,
            args?.synth
        );

    static getSoundPackagesByGenre = (args: { genre: string } & GenericPaginatedQuery) => SoundPackage.client.getSoundPackagesByGenre(args.genre, args?.limit, args?.skip);

    static getFreeSoundPackages = (args?: CreateSearchPaginatedQuery) =>
        SoundPackage.client.getFreeSoundPackages(
            args?.limit,
            args?.skip,
            args?.search,
            args?.bpm_max,
            args?.bpm_min,
            args?.key as string,
            args?.keyType,
            args?.type,
            args?.tags,
            args?.tag_groups,
            args?.artists,
            args?.subgenre,
            args?.label,
            args?.sound_package_id,
            args?.curated_id,
            args?.drive_id,
            args?.genre as string,
            args?.showHalfTime,
            args?.suggested,
            args?.hide_prev_played,
            // args?.hide_prev_downloaded // ToDo: BPM-2775
        );

    static getDownloadedSoundPackages = (args?: CreateSearchPaginatedQuery) =>
        SoundPackage.client.getDownloadedSoundPackages(
            args?.limit,
            args?.skip,
            args?.sort_by,
            args?.search,
            args?.bpm_max,
            args?.bpm_min,
            args?.key as string,
            args?.keyType,
            args?.type,
            args?.tags,
            args?.tag_groups,
            args?.artists,
            args?.subgenre,
            args?.label,
            args?.sound_package_id,
            args?.curated_id,
            args?.drive_id,
            args?.genre as string,
            args?.showHalfTime,
            args?.suggested,
            args?.hide_prev_played,
            args?.hide_prev_downloaded,
            args?.only_free,
            args?.tag_categories,
            args?.trending,
            args?.synth
        );

    static getNewestSoundPackagesByGenres = () => SoundPackage.client.getNewestSoundPackagesByGenres();

    static downloadSoundPackage = (id: string, location: string) => SoundPackage.client.downloadSoundPackage(id, location);

    static downloadSoundPackagePreview = (id: string) => SoundPackage.client.downloadSoundPackagePreview(id);

    static favoriteSoundPackage = (id: string) => SoundPackage.client.favoriteSoundPackage(id);

    static unfavoriteSoundPackage = (id: string) => SoundPackage.client.unfavoriteSoundPackage(id);

    static getLikedSoundPackages = (args?: CreateSearchPaginatedQuery) =>
        SoundPackage.client.getLikedSoundPackages(
            args?.limit,
            args?.skip,
            args?.search,
            args?.bpm_max,
            args?.bpm_min,
            args?.key as string,
            args?.keyType,
            args?.type,
            args?.tags,
            args?.tag_groups,
            args?.artists,
            args?.subgenre,
            args?.label,
            args?.sound_package_id,
            args?.curated_id,
            args?.drive_id,
            args?.genre as string,
            args?.showHalfTime,
            args?.suggested,
            args?.hide_prev_played,
            args?.hide_prev_downloaded,
            args?.only_free,
            args?.tag_categories,
            args?.trending,
            args?.synth
        );

    /** We can use ``SoundPackage["slug"]`` as id here */
    static getSoundPackage = (id: string) => SoundPackage.client.getSoundPackage(id);

    static getSimilarSoundPacks = (idOrSlug: string, args?: GenericPaginatedQuery) => SoundPackage.client.getSimilarSoundPackages(idOrSlug, args?.limit, args?.skip);
}
