/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { KeyboardScale, convertKeyboardToCamelot } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { AppLink } from '../../app-link/app-link';
import styles from '../supreme/track-list-supreme.module.css';

interface KeyColumnProps {
    displayKey?: string;
    actualKey?: string;
}

function parseKey(displayKey: string) {
    const [key, scale] = displayKey.split(' ');
    const mappedScale = scale === 'Min' ? 'minor' : 'major';
    return { scale: mappedScale as KeyboardScale, key };
}

function getActualKey(displayKey: string) {
    const { scale, key } = parseKey(displayKey);
    const converted = convertKeyboardToCamelot({ key, scale });
    return converted;
}

export function KeyColumn({ actualKey, displayKey }: KeyColumnProps) {
    if (!displayKey) {
        return <div className={classNames(styles['track-list__label-link'])}>-</div>;
    }

    const convertedKey = actualKey || getActualKey(displayKey);

    return (
        <div className={classNames(styles['track-list__label-link'])} onClick={(e) => e.stopPropagation()}>
            <AppLink href={`/releases?key=${convertedKey}`}>
                <a className="relative-link">{displayKey}</a>
            </AppLink>
        </div>
    );
}
/* eslint-enable jsx-a11y/click-events-have-key-events */
/* eslint-enable jsx-a11y/no-static-element-interactions */
