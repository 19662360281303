// eslint-disable-next-line @typescript-eslint/dot-notation
const supremeAPIUrl = process.env['NEXT_PUBLIC_SUPREME_API_BASE_PATH'];
// eslint-disable-next-line @typescript-eslint/dot-notation
const createAnalyticsUrl = process.env['NEXT_PUBLIC_CREATE_ANALYTICS_BASE_PATH'] || supremeAPIUrl;

type AnalyticsEvents = {
    click: Array<{ [key: string]: any }>;
    generic: Array<{ [key: string]: any }>;
    view: Array<{ [key: string]: any }>;
    session: Array<{ [key: string]: any }>;
    impression: Array<{ [key: string]: any }>;
};

type AnalyticsPlatform = 'supreme' | 'stream' | 'create';

export class Analytics {
    static previousReferrer = '';

    static userId = '';

    static platform: AnalyticsPlatform = 'supreme';

    static interval: NodeJS.Timeout;

    static lastImpressions: { [key: string]: Date } = {};

    static events: {
        supreme: AnalyticsEvents;
        stream: AnalyticsEvents;
        create: AnalyticsEvents;
    } = {
            supreme: {
                click: [],
                view: [],
                session: [],
                generic: [],
                impression: []
            },
            stream: {
                click: [],
                view: [],
                session: [],
                generic: [],
                impression: []
            },
            create: {
                click: [],
                view: [],
                session: [],
                generic: [],
                impression: []
            },
        };

    static initialize() {
        if (Analytics.interval) {
            clearInterval(Analytics.interval);
        }

        Analytics.interval = setInterval(() => {
            Analytics.send();
        }, 500);

        if (window) {
            window.onbeforeunload = () => {
                Analytics.send();
                Analytics.trackSession('end');
            };
        }
    }

    static send() {
        // eslint-disable-next-line max-len
        if (Object.keys(Analytics.events.supreme).find((key) => Analytics.events.supreme[key as keyof AnalyticsEvents].length > 0)) {
            if (typeof navigator.sendBeacon === 'function') {
                navigator.sendBeacon(
                    `${supremeAPIUrl}/analytics/track/supreme`,
                    JSON.stringify({
                        events: Analytics.events.supreme.click.concat(Analytics.events.supreme.view).concat(Analytics.events.supreme.session)
                            .concat(Analytics.events.supreme.generic).concat(Analytics.events.supreme.impression)
                    })
                );
                Analytics.events.supreme.session = [];
                Analytics.events.supreme.view = [];
                Analytics.events.supreme.generic = [];
                Analytics.events.supreme.click = [];
                Analytics.events.supreme.impression = [];
            }
        }
        // eslint-disable-next-line max-len
        if (Object.keys(Analytics.events.stream).find((key) => Analytics.events.stream[key as keyof AnalyticsEvents].length > 0)) {
            if (typeof navigator.sendBeacon === 'function') {
                navigator.sendBeacon(
                    `${supremeAPIUrl}/analytics/track/stream`,
                    JSON.stringify({
                        events: Analytics.events.stream.click.concat(Analytics.events.stream.view).concat(Analytics.events.stream.session)
                            .concat(Analytics.events.stream.generic).concat(Analytics.events.stream.impression)
                    })
                );
                Analytics.events.stream.session = [];
                Analytics.events.stream.view = [];
                Analytics.events.stream.generic = [];
                Analytics.events.stream.click = [];
                Analytics.events.stream.impression = [];
            }
        }
        // eslint-disable-next-line max-len
        if (Object.keys(Analytics.events.create).find((key) => Analytics.events.create[key as keyof AnalyticsEvents].length > 0)) {
            if (typeof navigator.sendBeacon === 'function') {
                navigator.sendBeacon(
                    `${createAnalyticsUrl}/analytics/track/create`,
                    JSON.stringify({
                        events: Analytics.events.create.click.concat(Analytics.events.create.view).concat(Analytics.events.create.session)
                            .concat(Analytics.events.create.generic).concat(Analytics.events.create.impression)
                    })
                );
                Analytics.events.create.session = [];
                Analytics.events.create.view = [];
                Analytics.events.create.generic = [];
                Analytics.events.create.click = [];
                Analytics.events.create.impression = [];
            }
        }
    }

    // eslint-disable-next-line max-len
    static trackClick(entity_name: string, entity_id: string, meta: any = {}) {
        Analytics.events[Analytics.platform].click.push({
            date: new Date(),
            type: 'click',
            entity_name,
            entity_id,
            from: `${window?.location.pathname}${window?.location.search}`,
            meta,
        });
    }

    static trackGeneric(event: string, meta: any = {}) {
        Analytics.events[Analytics.platform].generic.push({
            date: new Date(),
            type: 'generic',
            event,
            from: `${window?.location.pathname}${window?.location.search}`,
            meta,
        });

        if (window?.fbq) {
            window?.fbq('trackCustom', event);
        }
    }

    static trackView(entity_name?: string, entity_id?: string) {
        Analytics.events[Analytics.platform].view.push({
            date: new Date(),
            type: 'view',
            entity_name,
            entity_id,
            from: `${window?.location.pathname}${window?.location.search}`,
            referrer: document.referrer,
        });

        if (window?.fbq && !entity_name) {
            window?.fbq('track', 'PageView');
        }

        if (window?.ga && window?.ga.getAll && window?.ga.getAll().length >= 1 && !entity_name) {
            const tracker = window?.ga.getAll()[0];
            tracker.set('location', window?.location.href);
            tracker.set('referrer', Analytics.previousReferrer);
            tracker.set('userId', Analytics.userId);
            tracker.send('pageview');
        }

        if (window) {
            Analytics.previousReferrer = window?.location.href;
        }
    }

    static trackImpression(entity_name: string, entity_id: string) {
        if (Analytics.lastImpressions[`${entity_name}_${entity_id}`] >= new Date()) {
            return;
        }
        Analytics.lastImpressions[`${entity_name}_${entity_id}`] = new Date(Date.now() + 2000); // ignore same event for next 2 secs
        Analytics.events[Analytics.platform].impression.push({
            date: new Date(),
            type: 'impression',
            entity_name,
            entity_id,
            from: `${window?.location.pathname}${window?.location.search}`,
            referrer: document.referrer,
        });
    }

    static trackUserId(userId: string) {
        if (window?.ga && window?.ga.getAll && window?.ga.getAll().length >= 1) {
            const tracker = window?.ga.getAll()[0];
            tracker.set('userId', Analytics.userId);
            Analytics.userId = userId;
        }
    }

    /**
     * To be called when a platform is activated/deactivated
     * @param platform
     * @param session_type
     */
    static trackSession(session_type: 'end' | 'start') {
        Analytics.events[Analytics.platform].session.push({
            date: new Date(),
            type: 'session',
            session_type,
            from: `${window?.location.pathname}${window?.location.search}`,
            screen_width: window?.screen.width,
            screen_height: window?.screen.height,
            agent: navigator.userAgent,
        });
    }
}
