import {
    useCreateLike,
    useDownloadCuratedSet,
    useDownloadCuratedSetPreview,
    useDownloadMultiSounds,
    useDownloadMultiSoundsPreview,
    useGetCredits,
    useGetCuratedSet,
    useInfiniteSearchSound,
    useInfiniteSearchPresets
} from '@bpm-web-app/swr-hooks';
import {
    appendQueryParams,
    convertToPluralIfNeeded,
    DEFAULT_SOUNDS_LIMIT,
    fileDownload,
    isMobileNavigator,
    useApiErrorHandler,
    useDebounce,
    getMutatedSounds,
    useViewport,
    createAppRoutes,
    useHubSwitch,
    getMutatedPresets,
    showToast,
    useCreateFilterParams,
    Analytics,
    curatedPackToCreatePlayable,
    usePlayerState,
    State,
    rebuildReactTooltip
} from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Title, useCreatePlayer } from '@bpm-web-app/components';
import { CreateThreeDotsSheetContext, SearchInput, BreakpointView, GhostComponent } from '@bpm-web-app/components/shared';
import { MediaDetailBanner } from '../../shared/ui/media-detail-banner/media-detail-banner';
import styles from './curated-pack-detail.module.css';
import { SecondaryPageTitle } from '../../shared/secondary-page-title/secondary-page-title';
import { TrackListCreate } from '../../shared/track-list/create/track-list-create';
import { MissingImgPlaceholder } from '../../shared/ui';
import Filters from '../../filters/filters';
import { ActionModal } from '../../shared/action-modal/action-modal';
import TrackListCreatePresets from '../../shared/track-list/create-presets/track-list-create-presets';
import { DownloadButton } from '../../shared/download-button/download-button';
import { AddToFavoriteButton } from '../../shared/add-to-favorite-button/add-to-favorite-button';
import { PlayButton } from '../../shared/play-button/play-button';
import { ShareURL } from '../../shared/share-url/share-url';
import { ThreeDotsButton } from '../../shared/three-dots-button/three-dots-button';

export const CuratedPackDetail = () => {
    const router = useRouter();

    const { slug } = router.query;

    const [searchQuery, setSearchQuery] = useState<string>('');
    const debouncedSearchQuery = useDebounce(searchQuery, 300);

    const { data: curatedSet, isLoading: isLoadingCuratedPack } = useGetCuratedSet(slug as string);
    const downloadMultiSounds = useDownloadMultiSounds();
    const downloadMultiSoundsPreview = useDownloadMultiSoundsPreview();
    const downloadCuratedSet = useDownloadCuratedSet();
    const downloadCuratedSetPreview = useDownloadCuratedSetPreview();
    const [creditAmount, setCreditAmount] = useState(null);
    const [isDownloading, setIsDownloading] = useState(false);
    const [actionModalOpen, setActionModalOpen] = useState<boolean>(false);
    const [downloadSubtitle, setDownloadSubtitle] = useState<string>('this file');
    const { hub } = useHubSwitch();
    const { playDemo, togglePlayPause, currentTrack } = useCreatePlayer();
    const playerState = usePlayerState();

    const errorHandler = useApiErrorHandler();
    const { mutate: refreshCredits } = useGetCredits();

    const curatedPackData = curatedSet?.data.pack;
    const query = useCreateFilterParams({ curated_id: `${slug}`, search: debouncedSearchQuery });

    const { data: soundsData, isLoadingMore, isLoadingInitialData, setSize, isLastPage, mutate } = useInfiniteSearchSound(query);

    const presetQuery = useMemo(() => {
        return {
            ...query,
            limit: curatedPackData?.sound_count === 0 ? DEFAULT_SOUNDS_LIMIT : 3
        };
    }, [query, curatedPackData?.sound_count]);

    // eslint-disable-next-line max-len
    const { data: presetData, isLoadingInitialData: isLoadingInitialDataPresets, isLoadingMore: isLoadingMorePresets, setSize: setSizePreset, isLastPage: isLastPagePreset, mutate: mutatePresetFn } = useInfiniteSearchPresets(presetQuery);

    const isEverythingLoaded = useMemo(() => {
        if (!isLoadingInitialData &&
            !isLoadingInitialDataPresets &&
            !isLoadingCuratedPack
        ) return true;
        return false;
    }, [isLoadingCuratedPack, isLoadingInitialData, isLoadingInitialDataPresets]);

    const presetList = presetData?.flatMap(({ data }) => data);

    const mutateSound = useCallback(
        (id: string, progress: number) => {
            mutate(getMutatedSounds(soundsData, id, progress));
        },
        [mutate, soundsData]
    );

    const mutatePreset = useCallback(
        (id: string, progress: number) => {
            mutatePresetFn(getMutatedPresets(presetData, id, progress));
        },
        [mutatePresetFn, presetData]
    );

    const [selectedItemsIds, setSelectedItemsIds] = useState<string[]>([]);
    const handleSelectItem = useCallback((selectedSoundId: string) => {
        setSelectedItemsIds((prevState) => {
            if (prevState.includes(selectedSoundId)) {
                return prevState.filter((soundId) => selectedSoundId !== soundId);
            }
            return [...prevState, selectedSoundId];
        });
    }, []);

    const soundsList = soundsData?.flatMap(({ data }) => data);

    const handleToggleAll = useCallback(
        (all: boolean) => {
            setSelectedItemsIds(() => (all ? (soundsList || []).map((sound) => sound.id) : []));
        },
        [soundsList]
    );

    const handleToggleAllPreset = useCallback(
        (all: boolean) => {
            setSelectedItemsIds(() => (all ? (presetList || []).map((sound) => sound.id) : []));
        },
        [presetList]
    );

    const topTags = curatedSet?.data.topTags;

    const handleLoadMore = useCallback(() => {
        if (!isLoadingMore && !isLastPage) {
            setSize((prevSize) => prevSize + 1);
        }
    }, [isLastPage, isLoadingMore, setSize]);

    const handleLoadMorePresets = useCallback(() => {
        if (!isLoadingMorePresets && !isLastPagePreset) {
            setSizePreset((prevSize) => prevSize + 1);
        }
    }, [isLastPagePreset, isLoadingMorePresets, setSizePreset]);

    const { isLiked, likeDislike } = useCreateLike('curated');

    const handleLikePack = useCallback(() => {
        likeDislike(curatedPackData?.id);
        rebuildReactTooltip();
    }, [likeDislike, curatedPackData?.id]);

    useEffect(() => {
        rebuildReactTooltip();
    }, []);

    /* reset selected sounds on any query changes. */
    useEffect(() => {
        setSelectedItemsIds([]);
    }, [query]);

    const [isDescriptionCollapsed, setIsDescriptionCollapsed] = useState(true);
    const descriptionRef = useRef<HTMLDivElement>(null);
    const { isMobile } = useViewport();
    const descriptionLineHeight = useMemo(() => (isMobile ? 20 : 16), [isMobile]);

    const { openThreeDotsModalSheet } = useContext(CreateThreeDotsSheetContext);

    const resetDownloadModalStatus = () => {
        setCreditAmount(null);
        setIsDownloading(false);
    };

    const openThreeDots = useCallback(
        async (element) => {
            if (curatedPackData?.id) {
                const { top, left } = (element.target as HTMLButtonElement).getBoundingClientRect();
                openThreeDotsModalSheet({
                    newCreateActionType: 'curated-set',
                    shareUrl: createAppRoutes.curatedSet(curatedPackData.slug),
                    actionId: curatedPackData.id,
                    left,
                    top: top + window.scrollY,
                });
            }
        },
        [curatedPackData, openThreeDotsModalSheet]
    );

    const handleDownloadUrl = useCallback(
        (url: string) => {
            fileDownload(url);
            refreshCredits();
        },
        [refreshCredits]
    );

    const handleDownloadCuratedPack = useCallback(async () => {
        try {
            setIsDownloading(true);
            const response = await showToast({
                promise: downloadCuratedSet(curatedPackData?.id, router.asPath),
                message: 'Download in progress.',
                successText: 'Download successful.',
                preventErrorToast: true
            });
            if (response && response.data && response.data.url) handleDownloadUrl(response.data.url);
            if (curatedPackData?.id !== undefined) {
                Analytics.trackClick('download_curated', curatedPackData?.id, { location: 'curated-pack-detail' });
            }
            setSelectedItemsIds([]);
        } catch (err) {
            errorHandler({ error: err });
        } finally {
            resetDownloadModalStatus();
            setIsDownloading(false);
        }
    }, [curatedPackData, downloadCuratedSet, errorHandler, handleDownloadUrl, router]);

    const handleDownloadCuratedPackPreview = useCallback(async () => {
        try {
            const response = await downloadCuratedSetPreview(curatedPackData?.id);
            const creditsRequired = response?.data?.required?.total;

            setCreditAmount(creditsRequired);
            if (creditsRequired > 0) {
                setDownloadSubtitle('this sound package');
                setActionModalOpen(true);
            } else {
                handleDownloadCuratedPack();
            }
        } catch (err) {
            errorHandler({ error: err });
        }
    }, [curatedPackData?.id, downloadCuratedSetPreview, errorHandler, handleDownloadCuratedPack]);

    const handleDownloadSelectedItems = useCallback(async () => {
        try {
            setIsDownloading(true);
            const downloadMediaUrlData = await showToast({
                promise: downloadMultiSounds(selectedItemsIds, router.asPath),
                message: 'Download in progress.',
                successText: 'Download successful.',
                preventErrorToast: true
            });
            if (downloadMediaUrlData) {
                const payload = await downloadMediaUrlData?.json();
                if (payload?.data?.url) {
                    handleDownloadUrl(payload.data.url);
                    setSelectedItemsIds([]);
                }
            }
            Analytics.trackClick('download_curated_multi', '', { location: 'curated-pack-detail' });
        } catch (err) {
            errorHandler({ error: err });
        } finally {
            resetDownloadModalStatus();
            setIsDownloading(false);
        }
    }, [downloadMultiSounds, errorHandler, handleDownloadUrl, selectedItemsIds, router]);

    const handleDownloadSelectedItemsPreview = useCallback(async () => {
        try {
            const response = await downloadMultiSoundsPreview(selectedItemsIds, router.asPath);
            const creditsRequired = response?.data?.required?.total;

            setCreditAmount(creditsRequired);
            if (creditsRequired > 0) {
                if (selectedItemsIds?.length === 1) setDownloadSubtitle('this file');
                else if (selectedItemsIds?.length > 1) setDownloadSubtitle('these files');
                setActionModalOpen(true);
            } else {
                handleDownloadSelectedItems();
            }
        } catch (err) {
            errorHandler({ error: err });
        }
    }, [downloadMultiSoundsPreview, handleDownloadSelectedItems, errorHandler, selectedItemsIds, router]);

    const handlePreviewAndDownloadSelectedOrAll = useCallback(
        async (isPreview: boolean) => {
            if (!curatedPackData && !soundsList?.length) return;

            if (!selectedItemsIds?.length || selectedItemsIds.length === soundsData?.[0]?.pagination?.total) {
                if (isPreview) {
                    await handleDownloadCuratedPackPreview();
                } else {
                    await handleDownloadCuratedPack();
                }
            } else if (isPreview) {
                await handleDownloadSelectedItemsPreview();
            } else {
                await handleDownloadSelectedItems();
            }
        },
        [
            curatedPackData,
            soundsList?.length,
            selectedItemsIds?.length,
            soundsData,
            handleDownloadCuratedPackPreview,
            handleDownloadCuratedPack,
            handleDownloadSelectedItemsPreview,
            handleDownloadSelectedItems,
        ]
    );

    const handlePlayDemo = useCallback(
        () => {
            if (currentTrack?.id === curatedPackData?.id) togglePlayPause();
            else if (curatedPackData) playDemo(curatedPackToCreatePlayable(curatedPackData));
        },
        [curatedPackData, currentTrack?.id, playDemo, togglePlayPause]);

    return (
        <div className="spacing__window">
            <Title platform={hub} title={curatedPackData?.name || 'Loading Curated Pack...'} />
            <div className={styles['curated-pack-detail']}>
                {isEverythingLoaded ? (
                    <MediaDetailBanner align={isMobile ? 'end' : 'start'}>
                        {{
                            image: (
                                <div className={styles['curated-pack-detail__img-container']}>
                                    {curatedPackData?.artwork_url ? (
                                        <picture>
                                            <source
                                                srcSet={`${appendQueryParams(curatedPackData?.artwork_url, { key: 'dw', value: 296 })}, ${appendQueryParams(curatedPackData?.artwork_url, { key: 'dw', value: 592 })} 2x`}
                                                media="(min-width: 1024px)"
                                            />
                                            <img
                                                src={appendQueryParams(curatedPackData?.artwork_url, { key: 'dw', value: 136 })}
                                                srcSet={`${appendQueryParams(curatedPackData?.artwork_url, { key: 'dw', value: 272 })} 2x`}
                                                alt={curatedPackData.name}
                                                draggable={false}
                                            />
                                        </picture>
                                    ) : (
                                        <MissingImgPlaceholder />
                                    )}
                                </div>
                            ),
                            text: (
                                <>
                                    <div className={styles['curated-pack-detail__title-container']}>
                                        <div>
                                            <h2>{curatedPackData?.name}</h2>
                                            <h4 className={styles['curated-pack-detail__top-tags']}>
                                                {topTags?.map((tag) => (
                                                    <span key={tag.id}>{tag.name}</span>
                                                ))}
                                            </h4>
                                        </div>
                                        <Filters platform="create" showOnMobile />
                                    </div>
                                    <div className={styles['curated-pack-detail__info']}>
                                        <span>{`${convertToPluralIfNeeded(curatedPackData?.sound_count || 0, 'Sound')}`}</span>
                                        {curatedPackData?.preset_count > 0 && <span>{`${convertToPluralIfNeeded(curatedPackData?.preset_count ?? 0, 'Preset')}`}</span>}
                                    </div>
                                    <div
                                        className={classNames(styles['curated-pack-detail__description'], {
                                            [styles['curated-pack-detail__description--collapsed']]: isDescriptionCollapsed,
                                        })}
                                        ref={descriptionRef}
                                    >
                                        {curatedPackData?.description}
                                    </div>
                                    {(descriptionRef.current?.scrollHeight || 0) > 3 * descriptionLineHeight && (
                                        <button type="button" className="button button--plain-link" onClick={() => setIsDescriptionCollapsed(!isDescriptionCollapsed)}>
                                            {isDescriptionCollapsed ? 'Read More' : 'Read Less'}
                                        </button>
                                    )}
                                </>
                            ),
                            actions: (
                                <>
                                    {curatedPackData && curatedPackData.demo_file_url ? (
                                        <BreakpointView
                                            mobileChildren={undefined}
                                            desktopChildren={
                                                <PlayButton type="demo" onPress={handlePlayDemo} isPlaying={currentTrack?.id === curatedPackData?.id && playerState === State.Playing} />
                                            }
                                        />
                                    ) : null}

                                    {isMobileNavigator ? null : (
                                        <BreakpointView
                                            mobileChildren={undefined}
                                            desktopChildren={
                                                <DownloadButton isLoading={isDownloading} type={curatedPackData && curatedPackData.demo_file_url ? 'outline' : 'dynamic'} onPress={() => handlePreviewAndDownloadSelectedOrAll(true)} />
                                            }
                                        />
                                    )}
                                    <BreakpointView
                                        mobileChildren={undefined}
                                        desktopChildren={
                                            <AddToFavoriteButton isCreate squared={curatedPackData?.demo_file_url === undefined} isFavorite={isLiked(curatedPackData?.id || '')} onClick={handleLikePack} hasTooltip />
                                        }
                                    />

                                    <BreakpointView
                                        mobileChildren={undefined}
                                        desktopChildren={
                                            <ShareURL currentUrl />}
                                    />
                                    <div className={styles['curated-pack-detail__more-dots-btn']}>
                                        <ThreeDotsButton hasTooltip onClick={openThreeDots} />
                                    </div>
                                </>
                            ),
                        }}
                    </MediaDetailBanner>
                ) : (
                    <GhostComponent type="curated-pack-detail" />
                )}

                <BreakpointView
                    mobileChildren={
                        <div className={styles['curated-pack-detail__mobile-actions']}>
                            {curatedPackData && curatedPackData.demo_file_url ? (
                                <PlayButton
                                    fullWidth
                                    type="demo"
                                    onPress={handlePlayDemo}
                                    isPlaying={currentTrack?.id === curatedPackData?.id && playerState === State.Playing} />
                            ) : null}
                            <AddToFavoriteButton isCreate squared fullWidth isFavorite={isLiked(curatedPackData?.id || '')} onClick={handleLikePack} hasTooltip />
                        </div>
                    }
                    desktopChildren={null}
                />
                <div className={styles['curated-pack-detail__track-list-controls']}>
                    <div className={styles['curated-pack-detail__search-container']}>
                        <SearchInput
                            placeholder="Search Sounds"
                            value={searchQuery}
                            onChange={(e) => {
                                setSearchQuery(e.target.value);
                            }}
                            onClear={() => setSearchQuery('')}
                        />
                    </div>
                </div>
                {!isLoadingInitialDataPresets && curatedPackData?.preset_count > 0 && (
                    <>
                        <SecondaryPageTitle title="Synth Presets" counter={presetData?.[0]?.pagination?.total} noPadding />
                        <div className={styles['curated-pack-detail__track-list']}>
                            <TrackListCreatePresets
                                mutateSound={mutatePreset}
                                list={presetList}
                                onSelectItem={handleSelectItem}
                                selectedItemsIds={selectedItemsIds}
                                onSelectAll={handleToggleAllPreset}
                                onLoadMore={handleLoadMorePresets}
                                isLoadingMore={isLoadingMorePresets}
                                hasMore={!isLastPagePreset}
                                hidePackName
                                automatedLoadMore={curatedPackData?.sound_count === 0}
                                isLoading={!isEverythingLoaded}
                            />
                        </div>
                    </>
                )}
                <SecondaryPageTitle title="Sounds" counter={soundsData?.[0]?.pagination?.total} noPadding />
                <div className={styles['curated-pack-detail__track-list']}>
                    <TrackListCreate
                        list={soundsList}
                        onSelectItem={handleSelectItem}
                        selectedItemsIds={selectedItemsIds}
                        onSelectAll={handleToggleAll}
                        onLoadMore={handleLoadMore}
                        isLoadingMore={isLoadingMore}
                        hidePackName
                        mutateSound={mutateSound}
                        isLoading={!isEverythingLoaded}
                    />
                </div>
            </div>
            <ActionModal
                headerTitle="Heads Up"
                title="You are about to use a few credits."
                subtitle={`Do you want to download ${downloadSubtitle} for ${creditAmount} Credits?`}
                confirmButtonText="Continue"
                onConfirm={() => {
                    handlePreviewAndDownloadSelectedOrAll(false);
                    setActionModalOpen(false);
                }}
                onClose={() => {
                    resetDownloadModalStatus();
                    setActionModalOpen(false);
                }}
                actionLoading={isDownloading}
                variant="light"
                isOpen={actionModalOpen}
            />
        </div> // note Download can cost 0 credits
    );
};
