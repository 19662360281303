import useSWR from 'swr';
import { Media, Meta, SearchQuery } from '@bpm-web-app/api-client';
import { useHubSwitch } from '@bpm-web-app/utils';
import { Genre } from '@bpm-web-app/download-api-sdk';
import { useStickyResult } from './sticky-result';

export function useGenres(library?: string, disabled?: boolean) {
    const { isDownload } = useHubSwitch();

    const { data, error } = useSWR(
        [
            'genres',
            {
                persist: true,
                isDownload,
                library,
            },
        ],
        () => (disabled ? null : Media.getGenres(isDownload, library))
    );

    const actualData = useStickyResult(data?.data);

    return {
        genres: actualData,
        isLoading: !error && !data,
        error,
    };
}

export function useSearchGenres(args: Omit<SearchQuery, 'sort_by'>, disabled?: boolean) {
    const { isDownload } = useHubSwitch();

    const { data, error } = useSWR(
        [
            'search-genres',
            {
                persist: true,
                isDownload,
                args,
            },
        ],
        () => (disabled ? null : Media.searchGenres(isDownload, args))
    );

    const actualData = useStickyResult(data?.data);

    return {
        genres: actualData as Genre[],
        isLoading: !error && !data,
        error,
    };
}

export function useCreateGenres(disabled?: boolean) {
    const { data, error } = useSWR(
        [
            'genres-create',
            {
                persist: true,
            },
        ],
        () => (disabled ? null : Meta.getGenres())
    );

    return {
        genres: data?.data,
        isLoading: !error && !data,
        error,
    };
}
