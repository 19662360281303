import classNames from 'classnames';
import { ChangeEvent, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import styles from './dropdown.module.css';
import { CustomIcon } from '../shared/custom-icon/custom-icon';
import BPMIcons from '../shared/bpm-icons/bpm-icons';

export interface DropdownProps<Value> {
    value: Value;
    onClick?: (value: Value) => void;
    options?: Array<{
        value: Value;
        label: string | ReactNode
        disabled?: boolean
    }>;
    placeHolder?: string;
    layoutType?: 'input' | 'inline' | 'none' | 'text-input' | 'border-input' ;
    containerClassname?: string;
    optionContainerClassname?: string;
    optionClassname?: string;
    buttonClassname?: string;
    useNewMobileStyle?: boolean
    inputValue?: string;
    setInputValue?: (text: string) => void;
    onInputBlur?: () => void;
    inverted?: boolean
    isDefaultWidth?: boolean; // 192px
    labelAdditionalText?: string;
    disabled?: boolean
}

export function Dropdown<Value = string>({ value, onClick, options, placeHolder, layoutType = 'inline', containerClassname, useNewMobileStyle, buttonClassname, optionContainerClassname, optionClassname, inputValue, setInputValue, onInputBlur, inverted, isDefaultWidth, labelAdditionalText, disabled }: DropdownProps<Value>) {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownContainer = useRef<HTMLDivElement>(null);
    const [searchOptions, setSearchOptions] = useState(options);

    useEffect(() => {
        const handleDocClick = (e: MouseEvent) => {
            if (!dropdownContainer?.current || !dropdownContainer) return;
            if (isOpen && dropdownContainer.current !== e.target && !dropdownContainer.current.contains(e.target as Node)) {
                e.preventDefault();
                setIsOpen(false);
                setSearchOptions(options);
            }
        };

        document.addEventListener('mousedown', handleDocClick);
        return () => {
            document.removeEventListener('mousedown', handleDocClick);
        };
    }, [dropdownContainer, isOpen, options]);

    const handleClick = (option: Value) => {
        setIsOpen(false);
        if (onClick) onClick(option);
    };

    useEffect(() => {
        if (inputValue && options && setInputValue) {
            const filteredSearchOptions = options.filter((x) => String(x.label).toLowerCase().includes(inputValue.toLowerCase()));
            if (filteredSearchOptions.length === 0) {
                setIsOpen(false);
            }
            setSearchOptions(filteredSearchOptions);
        } else {
            setSearchOptions(options);
        }
    }, [options, inputValue, setInputValue]);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setIsOpen(true);
        if (setInputValue) {
            setInputValue(e.target.value);
        }
    };

    const listRef = useRef<HTMLUListElement | null>(null);

    const currentLabel = useMemo(() => {
        const findLabel = options?.find(({ value: v }) => v === value)?.label || placeHolder;
        if (labelAdditionalText) {
            return (
                <div className={styles['dropdown__labels-container']}>
                    <div>{findLabel}</div>
                    <div className={styles[`dropdown__button--${layoutType}__additional-label-text`]}>&nbsp;{labelAdditionalText}</div>
                </div>
            );
        }
        return findLabel;
    }, [labelAdditionalText, layoutType, options, placeHolder, value]);

    return (
        /* eslint-disable-next-line @typescript-eslint/dot-notation */
        <div className={classNames(styles['dropdown'], containerClassname, { [styles['dropdown__new-mobile-style']]: useNewMobileStyle }, { [styles['dropdown__flex']]: isDefaultWidth }, 'link-hover')} ref={dropdownContainer}>
            <button
                type="button"
                disabled={disabled}
                className={classNames(
                    styles['dropdown__button'],
                    styles[`dropdown__button--${layoutType}`],
                    { [styles[`dropdown__button--${layoutType}--open`]]: layoutType === 'border-input' && isOpen },
                    buttonClassname
                )}
                onClick={() => searchOptions.length > 0 ? setIsOpen(!isOpen) : null}>
                {layoutType === 'text-input' ? (
                    <input
                        placeholder={placeHolder}
                        className={styles['dropdown__text-input']}
                        value={inputValue}
                        onChange={handleInputChange}
                        onBlur={(e) => {
                            if (!listRef.current?.contains(e.relatedTarget as Node)) {
                                onInputBlur?.();
                            }
                        }}
                        onFocus={() => {
                            setSearchOptions(options);
                        }}
                    />
                ) :
                    currentLabel
                }
                {layoutType === 'border-input' ? <BPMIcons.ChevronRightIcon className={classNames(styles['dropdown__chevron'], { [styles['dropdown__chevron--open']]: isOpen })} /> : null}
            </button>
            <ul
                ref={listRef}
                className={classNames(styles['dropdown__options'], optionContainerClassname, {
                    [styles['dropdown__options--open']]: isOpen,
                    [styles['dropdown__options--inverted']]: inverted,
                    [styles['dropdown__options--inverted--open']]: isOpen && inverted,
                })}
            >
                {(inverted ? searchOptions : searchOptions)?.map((option) => (
                    <li key={option.value.toString()} className={classNames({ [styles['dropdown__option--active']]: option.value === value }, styles['dropdown__option'], optionClassname)}>
                        <button type="button" disabled={option.disabled} onClick={() => handleClick(option.value)} className={styles['dropdown__option-btn']}>
                            {option.label}
                        </button>
                        {option.value === value ? <CustomIcon size={20} type="checkmark" className={classNames({ [styles['dropdown__option--active-icon']]: option.value === value })} /> : null}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Dropdown;
