import classNames from 'classnames';
import { useUniqueArray } from '@bpm-web-app/utils';
import GhostElement from '../shared/ghost-element/ghost-element';
import SecondaryPageTitle from '../shared/secondary-page-title/secondary-page-title';
import ChevronRight from '../../assets/icons/chevron-right.svg';

import styles from './trending.module.css';
import { AppLink } from '../shared/app-link/app-link';

const TRENDING_LINES_WIDTH = [90, 70];

export function TrendingGhost({ noPadding }: { noPadding?: boolean }) {
    const ghostLoadingItems = useUniqueArray(10);
    return (
        <div className="ghost-loading__loading-container">
            <AppLink href="/trending">
                <a className={classNames(styles['trending__title'], 'heading--h6')}>
                    <SecondaryPageTitle title="Trending" noPadding={noPadding} />
                    <ChevronRight />
                </a>
            </AppLink>

            <div className={classNames(styles['trending__list'], 'spacing--top', { 'spacing__window--horizontal': !noPadding })}>
                {ghostLoadingItems.map((uuid) => (
                    <GhostElement isRow key={`trending-${uuid}`} itemClass={styles['trending__loading-item']} linesWidthArray={TRENDING_LINES_WIDTH} squareWidth={56} />
                ))}
            </div>
        </div>
    );
}
