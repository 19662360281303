import classNames from 'classnames';
import { useEffect } from 'react';
import { rebuildReactTooltip } from '@bpm-web-app/utils';
import styles from './primary-page-title.module.css';
import ToolTip from '../../../assets/icons/artist-platform/tooltip.svg';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PrimaryPageTitleProps {
    title: string;
    tooltip?: string;
    noPadding?: boolean;
}

export function PrimaryPageTitle({ title, noPadding = false, tooltip }: PrimaryPageTitleProps) {
    useEffect(() => {
        if (tooltip) { rebuildReactTooltip(); }
    }, [tooltip]);
    return (
        <h2 className={classNames(styles['primary-page-title'], { 'spacing__window--left': !noPadding }, { 'spacing__window--top': !noPadding })}>{title}
            {
                tooltip ?
                    <button aria-label={tooltip} type="button" data-tip={tooltip} className={styles['primary-page-title--tooltip']}>
                        <ToolTip />
                    </button> : null
            }
        </h2>
    );
}

export default PrimaryPageTitle;
