import { Album, Artist } from '@bpm-web-app/download-api-sdk';
import { useDownloadAlbumById } from '@bpm-web-app/swr-hooks';
import { Analytics, HLSPlayer, State as PlayerStateEnum, appendQueryParams, convertToPluralIfNeeded, downloadAlbumWithMediaToQueueItem, parseSeconds, useHubSwitch } from '@bpm-web-app/utils';
import classNames from 'classnames';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useContext, useEffect, useRef } from 'react';
import NextIcon from '../../assets/icons/player-next.svg';
import PauseIcon from '../../assets/icons/player-pause.svg';
import PlayIcon from '../../assets/icons/player-play.svg';
import PrevIcon from '../../assets/icons/player-prev.svg';
import VolumeMuteIcon from '../../assets/icons/player-volume-off.svg';
import VolumeIcon from '../../assets/icons/player-volume.svg';
import { useArtistLinks } from '../artist-link/artist-link';
import { usePlayer, usePlayerDuration } from '../player-context';
import { PlayerWave } from '../shared/player-wave/player-wave';
import { ThreeDotsSheetContext } from '../shared/three-dots-sheet/three-dots-sheet.context';
import VersionsDropdown from '../versions-dropdown/versions-dropdown';
import { MiniPlayerProps, sliderStyles } from './common';
import styles from './mini-player.module.css';

export function ArtistMiniPlayer({ playerState }: MiniPlayerProps) {
    const {
        currentTrack,
        hasNextTrack,
        hasPrevTrack,
        nextTrack,
        prevTrack,
        volume,
        setVolume,
        replaceCurrentPlayingTrack,
        onPlay,
        onPause,
        isPreviewTrack,
    } = usePlayer();

    const { elapsed } = usePlayerDuration();

    const { openThreeDotsModalSheet } = useContext(ThreeDotsSheetContext);
    const { isDownload } = useHubSwitch();
    const { data: downloadAlbumData } = useDownloadAlbumById(currentTrack?.album_id as number, isDownload);
    const volumeSliderRef = useRef<HTMLDivElement>(null);
    const generateArtistLinks = useArtistLinks();

    useEffect(() => {
        if (currentTrack?.album_id) {
            Analytics.trackImpression('Album', `${currentTrack.album_id}`);
        }
    }, [currentTrack]);

    return (
        <div className={styles['mini-player']}>
            <div
                className={styles['mini-player__container--artist']}
            >
                <div className={styles['mini-player__details']}>
                    <img
                        src={appendQueryParams(currentTrack?.cover_url, { key: 'dw', value: 64 })}
                        srcSet={`${appendQueryParams(currentTrack?.cover_url, { key: 'dw', value: 128 })} 2x`}
                        alt={currentTrack?.title}
                        className={styles['mini-player__details-cover']}
                    />
                    <div className={classNames(styles['mini-player__details-title-wrapper'], styles['mini-player__details-title-wrapper--has-right-fade-mobile'])}>
                        <div className={classNames(styles['mini-player__song-title-wrapper'])}>
                            <span className={classNames(styles['mini-player__details-title'])}>
                                {currentTrack?.title.replace(`(${currentTrack.version})`, '')}
                            </span>
                            {currentTrack?.versions && currentTrack.versions - 1 > 0 && (
                                <div className={styles['mini-player__version-dropdown']}>
                                    <VersionsDropdown<number>
                                        currentVersion={currentTrack.version}
                                        onClick={(version) => {
                                            replaceCurrentPlayingTrack(downloadAlbumWithMediaToQueueItem(downloadAlbumData?.data as Album, version));
                                        }}
                                        openThreeDotsMenu={(version, leftPos, topPos) => openThreeDotsModalSheet('mini-player-versions', version, leftPos + 220, topPos - 50, false, currentTrack?.album_id)}
                                        placeholder={convertToPluralIfNeeded(currentTrack.versions, 'Version')}
                                        options={downloadAlbumData?.data.media.map((media, index) => ({
                                            versionName: media.version.name,
                                            versionId: media.id,
                                            versionIndex: index,
                                            versionDuration: parseSeconds(media.estimated_duration || 0),
                                        }))}
                                        layoutType="mini"
                                        openDirection="upward"
                                    />
                                </div>
                            )}
                        </div>
                        <span className={styles['mini-player__details-artist']}>{generateArtistLinks(currentTrack?.artist as string, currentTrack?.artists as Artist[])}</span>
                    </div>
                </div>
                <div className={styles['mini-player__right-section']}>
                    <div className={styles['mini-player__controls--artist']}>
                        <button
                            className={classNames(styles['mini-player__control'], styles['mini-player__control--prev'])}
                            type="button"
                            onClick={(e) => {
                                e.stopPropagation();
                                prevTrack();
                            }}
                            disabled={!hasPrevTrack}
                            aria-label="Previous Track"
                        >
                            <PrevIcon />
                        </button>
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                if (playerState === PlayerStateEnum.Playing) {
                                    // Should this be controlled at context level?
                                    // Should be the same for Maxi and mini
                                    onPause();
                                } else {
                                    onPlay();
                                }
                            }}
                            className={classNames(styles['mini-player__control'], {
                                [styles['mini-player__control--play']]: playerState !== PlayerStateEnum.Playing,
                                [styles['mini-player__control--pause']]: playerState === PlayerStateEnum.Playing,
                            })}
                            type="button"
                            aria-label={playerState === PlayerStateEnum.Playing ? 'Pause' : 'Play'}
                        >
                            {playerState === PlayerStateEnum.Playing ? <PauseIcon /> : <PlayIcon />}
                        </button>
                        <button
                            className={classNames(styles['mini-player__control'], styles['mini-player__control--next-artist'])}
                            type="button"
                            onClick={(e) => {
                                e.stopPropagation();
                                nextTrack();
                            }}
                            disabled={!hasNextTrack}
                            aria-label="Next Track"
                        >
                            <NextIcon />
                        </button>
                    </div>
                    <div className={styles['mini-player__wave']}>
                        <div className={styles['mini-player__elapsed']}>
                            {parseSeconds(elapsed)}
                        </div>
                        {currentTrack && <PlayerWave variant="mini" />}
                        <div className={styles['mini-player__duration']}>{parseSeconds(isPreviewTrack ? currentTrack?.estimated_duration as string | number : HLSPlayer.getDuration())}</div>
                    </div>
                    <div className={styles['mini-player__actions']}>
                        <div className={styles['mini-player__volume-action']}>
                            <button
                                className={styles['mini-player__volume-action--volume']}
                                type="button"
                                aria-label="Repeat"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setVolume(!volume ? 100 : 0);
                                }}
                            >
                                {volume ? <VolumeIcon /> : <VolumeMuteIcon />}
                            </button>
                            <div
                                className={styles['mini-player__volume-action--slider-container']}
                                tabIndex={0}
                                ref={volumeSliderRef}
                                role="button"
                                onClick={(e) => e.stopPropagation()}
                                onKeyPress={() => { }}
                            >
                                <Slider
                                    className={styles['mini-player__volume-action--slider']}
                                    min={0}
                                    max={100}
                                    step={1}
                                    value={volume}
                                    onChange={setVolume}
                                    vertical
                                    trackStyle={sliderStyles.supremeTrackStyle}
                                    handleStyle={sliderStyles.handleStyle}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
