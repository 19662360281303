import classNames from 'classnames';

import { useContext, useState, useMemo, Fragment, useCallback, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { Analytics, getCurrentPlatformLink, rebuildReactTooltip, useApiErrorHandler, useHubSwitch, useUserSettings, useViewport } from '@bpm-web-app/utils';
import { useGetMeSubscription, useUserPlaylistSets } from '@bpm-web-app/swr-hooks';
import { Library, LibraryTabsContext } from 'libs/utils/src/lib/library-tabs.context';
import { Collapse } from 'react-collapse';
import styles from './nav.module.css';
import { NavContext } from './nav.context';

// Icons
import { ReactComponent as ChevronRight } from '../../assets/icons/chevron-right.svg';
import SupremeLogo from '../../assets/icons/supreme-icon.svg';

import NavMyPlaylistsCategories from '../nav-my-playlist-categories/nav-my-playlist-categories';
import PlaylistsForm, { PlaylistsFormProps } from '../playlists-form/playlists-form';
import OnboardingModalFeatures from '../onboarding-modals/onboarding-modal-features/onboarding-modal-features';
import { useUserPlaylistCategories } from '../shared/three-dots-sheet/useUserPlaylistCategories';
import { useUserPlaylists } from '../shared/three-dots-sheet/useUserPlaylists';
import { PlainNavItem } from './plain-nav-item';
import { NavFooter } from './nav-footer';
import { supremeMyLibraryNavItems, supremeNavItems } from './nav-menu-items';

import { useOnDragElement } from '../drag-and-drop/drag-and-drop.context';
import { AppLink, ContextMenuClickIgnoreClass, ContextMenuContext, ContextMenuItemProps, useActionModal } from '../shared';
import { CustomIcon } from '../shared/custom-icon/custom-icon';
import Droppable, { ALL_TYPES_NO_ARTIST, ALL_TYPES_NO_COLLECTION, ALL_TYPES_NO_USER_PLAYLIST } from '../droppable/droppable';
import SubscriptionPromotion from '../onboarding-modals/subscription-promotion/subscription-promotion';
import { NavHeader } from './nav-header/nav-header';
import { NavToggleButton } from './nav-toggle-button/nav-toggle-button';
import { LocalSearchSort } from '../nav-my-playlist-categories/utils';

export type LocalSortOptionProps = {
    value: LocalSearchSort;
    label: string;
};

export const LOCAL_SORT_OPTIONS: LocalSortOptionProps[] = [
    { value: 'most_recent', label: 'Most Recent' },
    { value: 'title_asc', label: 'Title (a-z)' },
    { value: 'title_desc', label: 'Title (z-a)' },
];

export function Nav() {
    const router = useRouter();
    const errorHandler = useApiErrorHandler(false);
    const { isDownload } = useHubSwitch();
    const { isNavOpen, isNavHidden, openNav, closeNav } = useContext(NavContext);
    const { openContextMenu, closeOptions, isOpen } = useContext(ContextMenuContext);
    const [showPlaylistFolders, setShowPlaylistFolders] = useState(false);

    const [categorySort, setCategorySort] = useState(undefined);

    const [categoryId, setCategoryId] = useState<number>(0);
    const [openForm, setOpenForm] = useState<null | PlaylistsFormProps>(null);
    const [isNavClicked, setNavClicked] = useState(false);
    const [openedFoldersOnDrag, setOpenedFoldersOnDrag] = useState<boolean>(false);
    const [openedNavOnDrag, setOpenedNavOnDrag] = useState<boolean>(false);

    const { isMobile } = useViewport();
    const { isDragging } = useOnDragElement();

    const contextMenuForm = () => <PlaylistsForm {...openForm} />;

    const contextMenuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        rebuildReactTooltip();
    }, []);

    useEffect(() => {
        Analytics.platform = isDownload ? 'supreme' : 'stream';
        Analytics.trackSession('start');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const handleDocClick = (e: MouseEvent) => {
            if (!contextMenuRef?.current || !closeOptions) return;
            if (contextMenuRef.current !== e.target && !contextMenuRef.current.contains(e.target as Node)) {
                e.preventDefault();
                closeOptions();
            }
        };

        document.addEventListener('click', handleDocClick);
        return () => {
            document.removeEventListener('click', handleDocClick);
        };
    }, [closeOptions]);

    useEffect(() => {
        if (!isNavOpen || isNavHidden) {
            setCategoryId(0);
            closeOptions();
            setShowPlaylistFolders(false);
        }
    }, [isNavOpen, isNavHidden, closeOptions]);

    const { createFolder, deleteFolder, editFolderName, userPlaylistCategories } = useUserPlaylistCategories();
    const { data: playlists } = useUserPlaylistSets(isDownload);
    const { createPlaylistOnFolder, createPlaylist } = useUserPlaylists(categoryId);

    const browseItems = useMemo(() => supremeNavItems(), []);
    const myLibraryItems = useMemo(() => supremeMyLibraryNavItems(), []);

    const addFolder = useCallback(() => {
        setOpenForm({
            type: 'CreateNewFolder',
            text: 'Create folders to organize your playlists by genre, event, or more.',
            formAction: createFolder,
            close: () => setOpenForm(null),
        });
    }, [createFolder]);

    const addPlaylist = useCallback((category: number | null) => {
        setOpenForm({
            type: 'CreateNewPlaylist',
            text: 'Create custom playlists to organize your music.',
            formAction: (name) => {
                if (!category) {
                    createPlaylist(name, true);
                } else {
                    createPlaylistOnFolder(category, name);
                }
            },
            close: () => setOpenForm(null),
        });
    }, [createPlaylist, createPlaylistOnFolder]);

    const updateFolderById = useCallback(
        (id: number, name: string) => {
            setOpenForm({
                type: 'EditMyFolder',
                text: `Enter a new name for ${name} below.`,
                formAction: (newName) => editFolderName(id, newName),
                close: () => setOpenForm(null),
            });
        },
        [editFolderName]
    );

    const createNewPlaylist = useCallback((category: number | null) => {
        addPlaylist(category);
        closeOptions();
    }, [addPlaylist, closeOptions]);

    const createNewFolder = useCallback(() => {
        addFolder();
        closeOptions();
    }, [addFolder, closeOptions]);

    const setSort = useCallback((sortOption) => {
        setCategorySort(sortOption);
    }, []);

    /* Close nav (if on mobile) + go back to the first level nav on route change. */
    useEffect(() => {
        const handleRouteChange = () => {
            if (isNavOpen && isMobile) closeNav();
        };

        router.events.on('routeChangeStart', handleRouteChange);

        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closeNav, isNavOpen, router.events]);

    useEffect(() => {
        if (isNavOpen && isMobile) {
            document.documentElement.classList.add('stop-scroll');
        } else {
            document.documentElement.classList.remove('stop-scroll');
        }
    }, [isMobile, isNavOpen]);

    const contextMenuOptions = useCallback((category: number | null) => {
        const menuOptions: ContextMenuItemProps[] = [
            {
                label: 'Create Playlist',
                leftIcon: <CustomIcon type="add-circle-icon" hasIconHover />,
                onClick: () => {
                    closeOptions();
                    Analytics.trackClick('nav_bar', 'create_new_playlist', { location: 'nav_my_playlist' });
                    createNewPlaylist(category);
                },
            },
            {
                label: 'Create Folder',
                leftIcon: <CustomIcon type="add-circle-icon" hasIconHover />,
                onClick: () => {
                    closeOptions();
                    Analytics.trackClick('nav_bar', 'create_new_folder', { location: 'nav_my_playlist' });
                    createNewFolder();
                },
            },
            {
                label: 'Sort By…',
                leftIcon: <CustomIcon type="sort-icon" hasIconHover />,
                items: LOCAL_SORT_OPTIONS.map((option) => {
                    return {
                        label: option.label,
                        onClick: () => {
                            closeOptions();
                            setSort(option.value);
                            setShowPlaylistFolders(true);
                        },
                    };
                }),
            },
            {
                leftIcon: <CustomIcon type="new-window-icon" hasIconHover />,
                label: 'Go to My Playlists',
                onClick: () => {
                    closeOptions();
                    router.push(getCurrentPlatformLink('/my-playlists'));
                },
            },
        ];
        return menuOptions;
    }, [closeOptions, createNewFolder, createNewPlaylist, router, setSort]);

    const handleOpenThreeDots = useCallback(
        (e: React.MouseEvent<HTMLElement>, category: number | null) => {
            const { left, top, width } = e.currentTarget.getBoundingClientRect();
            if (isOpen('my-playlists')) {
                closeOptions();
            } else {
                openContextMenu({ id: 'my-playlists', options: contextMenuOptions(category), left, top, renderLocationPosition: 'sidebar', align: 'right', buttonWidth: width });
            }
        },
        [closeOptions, contextMenuOptions, isOpen, openContextMenu]
    );

    const { openModal: openActionModal, closeModal: closeActionModal } = useActionModal();

    const handleDeleteFolderById = useCallback(
        async (id?: number) => {
            openActionModal({
                headerTitle: 'Delete Folder',
                title: 'Are you sure you want to delete this folder?',
                confirmButtonText: "I'm sure",
                cancelButtonText: 'Cancel',
                shouldCloseOnOverlayClick: true,
                onConfirm: async () => {
                    closeActionModal();
                    try {
                        await deleteFolder(id as number);
                        closeOptions();
                        // eslint-disable-next-line @typescript-eslint/dot-notation
                        if (router.query['categoryId'] === `${id}`) {
                            router.replace(getCurrentPlatformLink('/my-playlists'));
                        }
                        setCategoryId(0);
                    } catch (error) {
                        errorHandler({ error });
                    }
                },
                onClose: closeActionModal,
            });
        },
        [closeActionModal, deleteFolder, errorHandler, openActionModal, router, closeOptions]
    );

    const getMobileSubmenuHeader = ({ title, close }: { title: string; close: () => void }) => (
        <div className={styles['nav__submenu-header']}>
            <button
                type="button"
                className={classNames(styles['nav__submenu-btn'])}
                onClick={() => {
                    closeOptions();

                    close();
                }}
            >
                <i className={classNames(styles['nav__menu-item--icon'], 'icon', 'icon--chevron-left', 'icon--light')} />
                <span>{title}</span>
            </button>
            <div className={classNames(styles['nav__menu-item--three-dots'], { [styles['nav__menu-item--three-dots--open']]: showPlaylistFolders })}>
                <CustomIcon
                    type="three-dots"
                    hasIconHover
                    className={ContextMenuClickIgnoreClass}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleOpenThreeDots(e, null);
                    }}
                />
            </div>
        </div>
    );

    const handleTogglePlaylistFolders = useCallback(() => {
        if (userPlaylistCategories && userPlaylistCategories.data.length === 0) {
            router.push(getCurrentPlatformLink('/my-playlists'));
            return;
        }
        if (!isNavOpen) openNav();
        if (!showPlaylistFolders) {
            Analytics.trackClick('nav_bar', 'My Playlists', { location: 'my_playlists' });
        }
        setShowPlaylistFolders(!showPlaylistFolders);
    }, [isNavOpen, userPlaylistCategories, openNav, showPlaylistFolders, router]);

    useEffect(() => {
        if (isNavOpen && isMobile) closeNav();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile]);

    const handleShowPlaylistFolders = useCallback(() => {
        if (!isNavOpen) {
            openNav();
            setOpenedNavOnDrag(true);
        }
        setShowPlaylistFolders(true);
        setOpenedFoldersOnDrag(true);
    }, [isNavOpen, openNav]);

    useEffect(() => {
        if (isDragging && !showPlaylistFolders) {
            handleShowPlaylistFolders();
        } else if (!isDragging && openedFoldersOnDrag) {
            setShowPlaylistFolders(false);
            setOpenedFoldersOnDrag(false);

            if (openedNavOnDrag && isNavOpen) {
                closeNav();
                setOpenedNavOnDrag(false);
            }
        }
    }, [closeNav, handleShowPlaylistFolders, isDragging, isNavOpen, openNav, openedFoldersOnDrag, openedNavOnDrag, showPlaylistFolders]);

    const { data } = useGetMeSubscription('supreme');
    const userSubscriptionInfo = data?.data.membership.has_membership;
    const userSubscriptions = data?.data.membership.subscription?.membership_type;
    const { library } = useContext(LibraryTabsContext);
    const { isAnonymous } = useUserSettings();
    const isAccountCheckout = router.pathname.includes('/account/plan');

    const isItemDisabled = useCallback(
        (title: string) => {
            if (library === Library.Artist && title.toLowerCase() === 'curated sets') return true;
            return false;
        },
        [library]
    );

    return (
        <>
            <nav
                // eslint-disable-next-line @typescript-eslint/dot-notation
                className={classNames(styles['nav'], {
                    [styles['nav--open']]: isNavOpen,
                    [styles['nav--hidden']]: isNavHidden,
                })}
            >
                {data && data.data.membership.has_membership === false && data.data.membership.trial_applicable && !isAccountCheckout && <SubscriptionPromotion />}
                <div
                    role="button"
                    tabIndex={0}
                    onClick={() => setNavClicked(true)}
                    className={styles['nav__menu']}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            setNavClicked(true);
                        }
                    }}
                >
                    <NavHeader
                        title="BPM Supreme"
                        AfterTitle={!isMobile ?
                            <AppLink href="/account/plan">
                                <a>
                                    {userSubscriptionInfo === false ? 'Limited Preview' : userSubscriptions}
                                </a>
                            </AppLink> : null
                        }>
                        <SupremeLogo />
                    </NavHeader>

                    {openForm && contextMenuForm()}
                    {!isMobile && (
                        <div className={styles['nav__section']}>
                            <h4 className={classNames(styles['nav__section--title'], { [styles['nav__section--title--closed']]: !isNavOpen })}>Browse</h4>
                        </div>
                    )}
                    {isMobile && !showPlaylistFolders && (
                        <div className={styles['nav__section']}>
                            <h4 className={classNames(styles['nav__section--title'], { [styles['nav__section--title--closed']]: !isNavOpen })}>Browse</h4>
                        </div>
                    )}
                    {!isMobile &&
                        browseItems.map(({ title, icon, activeIcon, path }) => {
                            return (
                                <Fragment key={title}>
                                    <PlainNavItem title={title} icon={icon} activeIcon={activeIcon} path={path} isDragging={isDragging} disableDragDrop isDisabled={isItemDisabled(title)} />
                                </Fragment>
                            );
                        })}

                    {isMobile &&
                        !showPlaylistFolders &&
                        browseItems.map(({ title, icon, activeIcon, path }) => {
                            return (
                                <Fragment key={title}>
                                    <PlainNavItem
                                        title={title}
                                        icon={icon}
                                        activeIcon={activeIcon}
                                        path={path}
                                        disableDragDrop
                                        isDisabled={path === '/curated-sets' && library === Library.Artist} />
                                </Fragment>
                            );
                        })}

                    {!isMobile && (
                        <div className={styles['nav__section']}>
                            <h4 className={classNames(styles['nav__section--title'], { [styles['nav__section--title--closed']]: !isNavOpen })}>Your Library</h4>
                        </div>
                    )}
                    {isMobile && !showPlaylistFolders && (
                        <div className={styles['nav__section']}>
                            <h4 className={classNames(styles['nav__section--title'], { [styles['nav__section--title--closed']]: !isNavOpen })}>Your Library</h4>
                        </div>
                    )}
                    {showPlaylistFolders && isMobile && (
                        <>
                            {getMobileSubmenuHeader({
                                title: 'Your Playlists',
                                close: () => {
                                    setShowPlaylistFolders(false);
                                    closeOptions();
                                },
                            })}
                            <NavMyPlaylistsCategories
                                selectCategory={(id) => setCategoryId(id ?? 0)}
                                createNewPlaylist={createNewPlaylist}
                                sort={categorySort}
                                onUpdateCategory={updateFolderById}
                                onDeleteCategory={handleDeleteFolderById}
                            />
                        </>
                    )}
                    {!isMobile &&
                        myLibraryItems.map(({ title, icon, activeIcon, path, mainPath, droppableId }) => {
                            if (isAnonymous) {
                                return <PlainNavItem title={title} icon={icon} activeIcon={activeIcon} path={path} isDragging={isDragging} isDisabled />;
                            }
                            if (path) {
                                return (
                                    <Droppable
                                        key={title}
                                        accept={droppableId === 'my-crate' ? ALL_TYPES_NO_COLLECTION : ALL_TYPES_NO_USER_PLAYLIST}
                                        onDrop={(type, items) => {
                                            return { target: droppableId };
                                        }}
                                    >
                                        {(state) => (
                                            <PlainNavItem
                                                title={title}
                                                icon={icon}
                                                activeIcon={activeIcon}
                                                path={path}
                                                isDragging={isDragging}
                                                isDraggingOver={state.isOver}
                                                disableDragDrop={!state.canDrop}
                                                onClick={() => {
                                                    Analytics.trackClick('nav_bar', title, { location: 'nav_bar' });
                                                }}
                                            />
                                        )}
                                    </Droppable>
                                );
                            }
                            return (
                                <Droppable
                                    key={title}
                                    accept={ALL_TYPES_NO_ARTIST}
                                    onlyHover
                                >
                                    {(state) => (
                                        <>
                                            <Droppable
                                                shallow
                                                key="Your Playlists"
                                                accept={ALL_TYPES_NO_ARTIST}
                                                onDrop={(type, items) => {
                                                    return { target: 'my-playlists', id: '' };
                                                }}>
                                                {(dropState) => (
                                                    <button
                                                        type="button"
                                                        className={classNames(styles['nav__menu-item'], styles['nav__menu-item--btn'], {
                                                            [styles['nav__menu-item--is-dragging']]: isDragging,
                                                            [styles['nav__menu-item--is-dragging--over']]: dropState.isOver,
                                                            [styles['nav__menu-item--active']]: mainPath && router.pathname.endsWith(mainPath),
                                                        })}
                                                        onClick={handleTogglePlaylistFolders}
                                                        data-tip={!isNavOpen ? `${title}` : ''}
                                                    >
                                                        <i className={classNames(styles['nav__menu-item--icon'])}>{mainPath && router.pathname.endsWith(mainPath) ? activeIcon : icon}</i>
                                                        <span>Your Playlists</span>
                                                        {isNavOpen && (
                                                            <div className={classNames(styles['nav__menu-item--three-dots'], { [styles['nav__menu-item--three-dots--open']]: showPlaylistFolders })}>
                                                                <CustomIcon
                                                                    type="three-dots"
                                                                    hasIconHover
                                                                    className={ContextMenuClickIgnoreClass}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        handleOpenThreeDots(e, null);
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                        {playlists && playlists.data && playlists.data.length > 0 && (
                                                            <button
                                                                type="button"
                                                                className={`${classNames(styles['nav__submenu-btn'], styles['nav__submenu-btn--context-toggle'])}`}
                                                                aria-label="Open or Close List"
                                                                onClick={() => {
                                                                    setShowPlaylistFolders(!showPlaylistFolders);
                                                                }}
                                                            >
                                                                <ChevronRight className={classNames(styles['nav__menu-item--chevron'], { [styles['nav__menu-item--chevron--open']]: showPlaylistFolders })} />
                                                            </button>
                                                        )}
                                                    </button>
                                                )}

                                            </Droppable>

                                            <Collapse isOpened={showPlaylistFolders || state.isOver}>
                                                <div className={classNames(styles['nav__dropdown-container'], { [styles['nav__dropdown-container--open']]: showPlaylistFolders || state.isOver })}>
                                                    <NavMyPlaylistsCategories
                                                        selectCategory={(id) => setCategoryId(id ?? 0)}
                                                        createNewPlaylist={createNewPlaylist}
                                                        sort={categorySort}
                                                        isOver={state.isOver}
                                                        onUpdateCategory={updateFolderById}
                                                        onDeleteCategory={handleDeleteFolderById}
                                                    />
                                                </div>
                                            </Collapse>
                                        </>
                                    )}
                                </Droppable>
                            );
                        })}

                    {isMobile &&
                        !showPlaylistFolders &&
                        myLibraryItems.map(({ title, icon, activeIcon, path }) => {
                            if (isAnonymous) {
                                return <PlainNavItem title={title} icon={icon} activeIcon={activeIcon} path={path} isDragging={isDragging} isDisabled />;
                            }
                            if (path) {
                                return (
                                    <PlainNavItem
                                        title={title}
                                        icon={icon}
                                        activeIcon={activeIcon}
                                        path={path}
                                        isDragging={false}
                                        onClick={() => {
                                            Analytics.trackClick('nav_bar', title, { location: 'nav_bar' });
                                        }}
                                    />
                                );
                            }
                            if (!showPlaylistFolders) {
                                return (
                                    <button
                                        type="button"
                                        className={classNames(styles['nav__menu-item'], styles['nav__menu-item--btn'], {
                                            [styles['nav__menu-item--open']]: showPlaylistFolders,
                                            [styles['nav__menu-item--is-dragging']]: isDragging,
                                        })}
                                        onClick={handleTogglePlaylistFolders}
                                        data-tip={!isNavOpen ? `${title}` : ''}
                                    >
                                        <i className={classNames(styles['nav__menu-item--icon'])}>{icon}</i>
                                        <span>{title}</span>
                                        <ChevronRight className={classNames(styles['nav__menu-item--chevron'], styles['nav__menu-item--chevron--mobile'])} />
                                    </button>
                                );
                            }

                            return null;
                        })}
                </div>
                <NavFooter />
                {isNavClicked && <OnboardingModalFeatures />}
            </nav>

            {!isNavHidden && (
                <NavToggleButton
                    onCloseNav={() => {
                        setCategoryId(0);
                        setShowPlaylistFolders(false);
                        closeOptions();
                    }}
                />
            )}
        </>
    );
}
