import { useRef, useState } from 'react';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Swiper as SwiperConfig } from 'swiper';
import { useArtistBanner } from '@bpm-web-app/swr-hooks';
import styles from './artist-portal-banner.module.css';
import Arrow from '../../assets/icons/artist-platform/arrow-black.svg';
import Triangle from '../../assets/icons/artist-platform/triangle-gin.svg';
import { AppLink } from '../shared/app-link/app-link';
import ArtistPortalBannerGhostLoading from './artist-portal-banner-ghost-loading';

export function ArtistPortalBanner() {
    const [, setSwiper] = useState<SwiperConfig>();
    const prevButtonRef = useRef<HTMLButtonElement>(null);
    const nextButtonRef = useRef<HTMLButtonElement>(null);

    const { data, isLoading } = useArtistBanner();

    if (data?.data.length === 0) {
        return null;
    }

    if (isLoading) {
        return <ArtistPortalBannerGhostLoading />;
    }

    return (
        <Swiper
            className={classNames(styles['artist-portal-banner__swiper'])}
            modules={[Navigation]}
            spaceBetween={16}
            slidesPerView="auto"
            slidesPerGroupAuto
            threshold={8}
            navigation={{
                prevEl: prevButtonRef.current,
                nextEl: nextButtonRef.current,
            }}
            onSwiper={setSwiper}>
            {data?.data.map(({ link, link_text, title, message, id }) => {
                return (
                    <SwiperSlide key={id}>
                        <div className={classNames(styles['artist-portal-banner__container-outer'])}>
                            <div className={classNames(styles['artist-portal-banner__title'])}>
                                {title}
                            </div>
                            <div className={classNames(styles['artist-portal-banner__message'])}>
                                {message}
                            </div>
                            <div className={classNames(styles['artist-portal-banner__footer'])}>
                                <AppLink href={link}>
                                    <a>
                                        <div className={styles['artist-portal-banner__button']}>
                                            <div className={classNames(styles['artist-portal-banner__button-text'])}>
                                                {link_text}
                                            </div>
                                            <div className={styles['artist-portal-banner__button-triangle']}>
                                                <Triangle />
                                            </div>
                                        </div>
                                    </a>
                                </AppLink>
                            </div>
                        </div>
                    </SwiperSlide>
                );
            })}
            <div className={styles['artist-portal-banner__arrows']}>
                <button type="button" aria-label="Previous Page" ref={prevButtonRef} className={styles['artist-portal-banner__arrow-left']}>
                    <Arrow />
                </button>
                <button type="button" aria-label="Next Page" ref={nextButtonRef} className={styles['artist-portal-banner__arrow-right']}>
                    <Arrow />
                </button>
            </div>
        </Swiper>
    );
}

export default ArtistPortalBanner;
