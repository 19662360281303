import classNames from 'classnames';
import { CardProps } from './card';
import styles from './card.module.css';
import { CreateCardProps } from './card-create';
import GhostElement, { GhostElementProps } from '../ghost-element/ghost-element';
import { ActionType } from '../three-dots-sheet/three-dots-sheet.context';

export interface CardGhostLoadingProps {
    cardSize?: CardProps['cardSize'] | CreateCardProps['cardSize'];
    linesWidthArray?: GhostElementProps['linesWidthArray'];
    lineHeight?: GhostElementProps['lineHeight'];
    itemClass?: string;
    imageClass?: string;
    contentType?: ActionType;
}

export const getCardLineHeight = (contentType?: ActionType, lineHeight?: number) => {
    if (lineHeight) return lineHeight;
    switch (contentType) {
        case 'track':
        case 'download:album':
            return 18.33;
        case 'exclusive-playlist':
        case 'user-playlist':
            return 16;
        default:
            return 12;
    }
};

export const getCardLinesWidthArray = (contentType?: ActionType, linesWidthArray?: number[]) => {
    if (linesWidthArray) return linesWidthArray;
    switch (contentType) {
        case 'track':
        case 'download:album':
            return [90, 40, 60];
        case 'news':
            return [100, 80];
        case 'exclusive-playlist':
        case 'user-playlist':
            return [100, 80];
        case 'for-you-playlist':
            return [70];
        default:
            return [60, 90, 30];
    }
};

export const getCardSize = (contentType?: ActionType, cardSize?: CardProps['cardSize'] | CreateCardProps['cardSize']): CardProps['cardSize'] | CreateCardProps['cardSize'] => {
    if (cardSize) return cardSize;
    switch (contentType) {
        case 'news':
            return 'large';
        case 'track':
        case 'download:album':
        case 'exclusive-playlist':
        case 'user-playlist':
            return 'small';
        case 'for-you-playlist':
            return 'medium';
        default:
            return 'small';
    }
};

export function CardGhostLoading({ cardSize, linesWidthArray = [60, 90, 30], lineHeight, itemClass, imageClass, contentType }: CardGhostLoadingProps) {
    return (
        <div
            className={classNames(
                // eslint-disable-next-line @typescript-eslint/dot-notation
                styles['card'],
                styles[`card--${getCardSize(contentType, cardSize)}`],
                styles['card--ghost']
            )}
        >
            <div className={classNames(styles['card__img-container'], styles['card__img-container--ghost'], imageClass)}>
                <GhostElement itemClass={classNames(styles['card__ghost-img'], itemClass, 'shimmer')} lineHeight={0} isRow />
            </div>
            <div className={styles['card__ghost-text']}>
                <GhostElement linesWidthArray={getCardLinesWidthArray(contentType, linesWidthArray)} lineHeight={getCardLineHeight(contentType, lineHeight)} />
            </div>
        </div>
    );
}

export default CardGhostLoading;
