import { FC } from 'react';
import classNames from 'classnames';
import styles from './card-grid.module.css';

export interface CardGridProps {
    cardType: 'card-small' | 'card-medium' | 'card-large' | 'card-label' | 'artist-card';
}

export const CardGrid: FC<CardGridProps> = ({ children, cardType }) => <article className={classNames(styles['card-grid'], styles[`card-grid--${cardType}`])}>{children}</article>;

export default CardGrid;
