/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { appendQueryParams, convertToPluralIfNeeded, downloadAlbumWithMediaToQueueItem, getInitialsFromName, State, streamMediaWithAlbumToQueueItem, useHubSwitch, usePlayerState, useViewport } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { useCallback, useContext, useMemo, useState } from 'react';
import { Media } from '@bpm-web-app/api-client';
import { ArtistWithMediaCount, MediaWithAlbum } from '@bpm-web-app/stream-api-sdk';
import { Artist, ArtistWithAlbumCount, Album as DownloadAlbum } from '@bpm-web-app/download-api-sdk';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { AppLink } from '../app-link/app-link';
import PlayIcon from '../../../assets/icons/player-play-alt.svg';
import PauseIcon from '../../../assets/icons/player-pause-alt.svg';
import styles from './artist-card-image.module.css';
import { usePlayer } from '../../player-context';
import { LibraryTabsContext } from '../../../../../utils/src/lib/library-tabs.context';
import { DragDropItem, DragResult } from '../../droppable/droppable';
import useFollowArtist from '../three-dots-sheet/useFollowArtist';
import { CustomIcon } from '../custom-icon/custom-icon';

export interface ArtistCardFullImageProps {
    artist: Artist
    link?: string;
    isPlayable?: boolean
}

export function ArtistCardImage({ artist, isPlayable = true, link }: ArtistCardFullImageProps) {
    const playerState = usePlayerState();
    const { silenceWorkaround, setQueue, originalListDetails, onPause } = usePlayer();
    const { isDownload } = useHubSwitch();
    const { libraryProperty } = useContext(LibraryTabsContext);
    const { id, slug, name: title } = artist;
    const { followArtist, unfollowArtist, isArtistFollowed } = useFollowArtist(id);
    const [isHoveringCard, setIsHoveringCard] = useState(false);

    const handleFollowUnfollow = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();

        if (isArtistFollowed) unfollowArtist();
        else followArtist();
    };

    const subTitle = useMemo(() => {
        return isDownload
            ? convertToPluralIfNeeded((artist as ArtistWithAlbumCount).album_count, 'Track', 'Tracks')
            : convertToPluralIfNeeded((artist as ArtistWithMediaCount).media_count, 'Track', 'Tracks');
    }, [isDownload, artist]);

    const actualLink = link || `/artist/${artist.slug}`;

    const isPlaying = useMemo(() => {
        if (playerState !== State.Playing) {
            return false;
        }
        return originalListDetails?.identifier === slug && originalListDetails?.resource === 'artist';
    }, [playerState, originalListDetails, slug]);

    const handlePlay = useCallback(async () => {
        silenceWorkaround();
        const { data } = isDownload ? await Media.searchAlbum(true, {
            artist: slug,
            library: libraryProperty,
            limit: 20
        }) : await Media.searchMedia({
            artist: slug,
            library: libraryProperty,
            limit: 20
        });
        if (!data) return;
        const queueMedia = isDownload
            ? (data as DownloadAlbum[]).map((album) => downloadAlbumWithMediaToQueueItem(album))
            : (data as MediaWithAlbum[]).map((media) => streamMediaWithAlbumToQueueItem(media));

        setQueue(queueMedia, 0, { identifier: slug, resource: 'artist' });
    }, [isDownload, setQueue, silenceWorkaround, slug, libraryProperty]);

    const { isMobile } = useViewport();

    // eslint-disable-next-line no-empty-pattern
    const [{ }, dragRef, connectDragPreview] = useDrag<DragDropItem, any, { opacity: number }>({
        item: { id, title },
        canDrag() {
            return !isMobile;
        },
        end: (draggedItem, monitor) => {
            if (monitor.didDrop()) {
                // eslint-disable-next-line @typescript-eslint/no-shadow
                const { target } = monitor.getDropResult() as DragResult;
                if (target === 'favorites') {
                    followArtist();
                }
            }
        },
        type: 'Artist'
    });

    connectDragPreview(getEmptyImage(), {
        // IE fallback: specify that we'd rather screenshot the node
        // when it already knows it's being dragged so we can hide it with CSS.
        captureDraggingState: true,
    });

    const imageUrl = appendQueryParams(artist.artwork_url, { key: 'dw', value: 160 });
    const imageUrl2x = appendQueryParams(artist.artwork_url, { key: 'dw', value: 160 * 2 });

    return (
        <AppLink href={actualLink}>
            <div
                ref={dragRef}
                className={classNames(styles['artist-card-image'])}
                onMouseEnter={() => setIsHoveringCard(true)}
                onMouseLeave={() => setIsHoveringCard(false)}>
                <a>
                    <div className={styles['artist-card-image__img-container']}>
                        <figure className={styles['artist-card-image__image']}>
                            {imageUrl && imageUrl2x && (
                                <img src={imageUrl} draggable={false} alt={title} srcSet={imageUrl2x ? `${imageUrl2x} 2x` : undefined} />
                            )}
                            {(!imageUrl || !imageUrl2x) && <div className={styles['artist-card-image__name-initials']}>{getInitialsFromName(title)}</div>}
                        </figure>

                        <div className={classNames(styles['artist-card-image__card-overlay'], {
                            [styles['artist-card-image__card-overlay--disabled']]: !isPlayable
                        })}>
                            {isPlayable &&
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        if (isPlaying) {
                                            onPause();
                                        } else {
                                            handlePlay();
                                        }
                                    }}
                                    type="button"
                                    aria-label={isPlaying ? 'Pause' : 'Play'}>
                                    <div className={styles['artist-card-image-play']}>
                                        {isPlaying ? <PauseIcon /> : <PlayIcon />}
                                    </div>
                                </button>
                            }
                        </div>

                    </div>
                </a>
                <div className={styles['artist-card-image__text-container']}>
                    <div className={styles['artist-card-image__title-container']}>
                        <AppLink href={actualLink}>
                            <a
                                data-for="artist-tooltip"
                                data-tip={`${artist.name}|${artist.slug}|${artist.id}|${artist.artwork_url}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                role="link"
                                tabIndex={-1}
                                className={styles['artist-card-image__title']}>{title}
                            </a>
                        </AppLink>
                        <CustomIcon className={classNames(styles['artist-card-image__follow-button'], { [styles['artist-card-image__follow-button--visible']]: isHoveringCard })} hasBackgroundBorderHover hasActiveState type={isArtistFollowed ? 'checkmark' : 'plus-icon'} onClick={(e) => handleFollowUnfollow(e)} hasBackgroundHover color="white" tooltip={isArtistFollowed ? 'Unfollow Artist' : 'Follow Artist'} tooltipBottomOffset={7} container="round" containerSize={32} />
                    </div>
                    <div className={styles['artist-card-image__subtitle']}>{subTitle}</div>
                </div>
            </div>
        </AppLink>
    );
}

export default ArtistCardImage;
/* eslint-enable jsx-a11y/click-events-have-key-events */
/* eslint-enable jsx-a11y/anchor-is-valid */
