import { useCallback, useMemo, useState, useEffect } from 'react';
import classNames from 'classnames';
import { ALTERNATE_DATE_FORMAT, formatDateToString, HLSPlayer, PlayerState, useLoadedSound, usePosition } from '@bpm-web-app/utils';
import { ContestSubmissionSongUrl, ContestSubmissionSongWave } from '@bpm-web-app/create-api-sdk';
import { useRouter } from 'next/router';
import { useContestReportStreamSubmission } from '@bpm-web-app/swr-hooks';
import styles from './submission.module.css';
import { useSession } from '../shared/session-provider/session-provider';
import SmallPlay from '../../assets/icons/create/small-play.svg';
import SmallHeart from '../../assets/icons/create/small-heart.svg';
import PauseIcon from '../../assets/icons/player-pause.svg';
import SubmissionHeart from '../../assets/icons/create/submission-heart.svg';
import SubmissionShare from '../../assets/icons/create/submission-share.svg';
import EditIcon from '../../assets/icons/pencil.svg';

import useContest from '../shared/three-dots-sheet/useContest';
import SocialShare from '../shared/social-share/social-share';
import { useCreatePlayer } from '../../../../utils/src/lib/create-player.context';
import { CreateWave } from '../create-wave/create-wave';

export interface SubmissionProps {
    contestId?: string;
    id: string;
    user: {
        id: number;
        username: string;
        full_name: string;
        profile_image_thumbnail_url?: string;
    };
    created_at: string;
    description: string;
    streamCount: number;
    likeCount: number;
    title: string;
    liked: boolean;
    songUrl: ContestSubmissionSongUrl;
    songWave: ContestSubmissionSongWave;
    mutate: () => void;
}

export function Submission({ contestId, id, user, created_at, description, streamCount, likeCount, title, liked, songUrl, songWave, mutate }: SubmissionProps) {
    const { user: loggedUser } = useSession();

    const { editContestSubmission, likeContestSubmission, unlikeContestSubmission } = useContest(contestId, false);

    const router = useRouter();
    const { submissionId } = router.query;
    const { top, ref: submissionRef } = usePosition();

    const contestReport = useContestReportStreamSubmission();

    useEffect(() => {
        if (submissionId && id === submissionId) {
            window.scrollTo({
                top: top - 50,
                behavior: 'smooth',
            });
        }
    }, [submissionId, top, id]);

    const [elapsed, setElapsed] = useState(0);
    const [duration, setDuration] = useState(0);

    const [isEditing, setIsEditing] = useState(false);

    const canEdit = useMemo(() => user?.id === loggedUser?.id, [loggedUser?.id, user?.id]);

    const [editedDescription, setEditedDescription] = useState(description);
    const [editedTitle, setEditedTitle] = useState(title);

    const isSoundLoaded = useLoadedSound();

    const { closePlayer, setCurrentSoundId, currentSoundId } = useCreatePlayer();

    const setElapsedTime = useCallback(
        (time: number) => {
            if (currentSoundId === id && time > 0) {
                setElapsed(time);
            }
        },
        [currentSoundId, id]
    );

    useEffect(() => {
        PlayerState.onProgress(setElapsedTime);

        return () => {
            PlayerState.offProgress(setElapsedTime);
        };
    }, [setElapsedTime]);

    const playSound = useCallback(
        async (progress?: number) => {
            contestReport(id);
            mutate();

            if (duration === 0) {
                setDuration(HLSPlayer.getDuration());
            }

            const finalProgress = progress || elapsed;

            if (finalProgress > 0) {
                HLSPlayer.goTo(finalProgress);
            }

            await HLSPlayer.play();
        },
        [contestReport, duration, elapsed, id, mutate]
    );

    const loadSound = useCallback(async () => {
        if (songUrl.hls) {
            const lastLoadedSoundUrl = HLSPlayer.getLastLoadedSound();
            if (lastLoadedSoundUrl === songUrl.hls) {
                playSound();
                return;
            }
            await HLSPlayer.load(songUrl.hls);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (currentSoundId === id && isSoundLoaded) {
            playSound();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSoundLoaded]);

    useEffect(() => {
        if (currentSoundId === id) {
            loadSound();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSoundId, id]);

    const handlePlay = useCallback(async () => {
        closePlayer();
        setCurrentSoundId(id);
    }, [closePlayer, id, setCurrentSoundId]);

    const handleSeek = useCallback(
        async (progress: number) => {
            try {
                closePlayer();

                if (currentSoundId !== id) {
                    handlePlay();
                } else {
                    playSound(progress);
                }
            } catch {
                // Placeholder
            }
        },
        [closePlayer, currentSoundId, handlePlay, id, playSound]
    );

    const handleStop = useCallback(() => {
        closePlayer();
        HLSPlayer.pause();
        setCurrentSoundId(undefined);
    }, [closePlayer, setCurrentSoundId]);

    const updateSubmission = useCallback(() => {
        setIsEditing(false);
        editContestSubmission(id, editedTitle, editedDescription);
    }, [editContestSubmission, editedDescription, editedTitle, id]);

    const cancelEditing = useCallback(() => {
        setIsEditing(false);
        setEditedDescription(description);
        setEditedTitle(title);
    }, [description, title]);

    const handleLike = useCallback(() => {
        if (liked) {
            unlikeContestSubmission(id);
            return;
        }

        likeContestSubmission(id);
    }, [id, likeContestSubmission, liked, unlikeContestSubmission]);

    return (
        // eslint-disable-next-line @typescript-eslint/dot-notation
        <div ref={submissionRef} id={id} className={styles['submission']}>
            <div className={styles['submission__container']}>
                <div>
                    <div className={styles['submission__author']}>
                        <div className={styles['submission__author-container']}>
                            <div className={styles['submission__author-name']}>{user?.full_name}</div>

                            {!isEditing && canEdit && (
                                <button type="button" onClick={() => setIsEditing(true)} className={styles['submission__edit']}>
                                    <EditIcon />
                                    Edit
                                </button>
                            )}
                        </div>
                        <div className={styles['submission__author-row']}>
                            <button className={styles['submission__author-row']} type="button" aria-label="play/pause submission" onClick={currentSoundId === id ? handleStop : handlePlay}>
                                <SmallPlay />
                                <span>{streamCount}</span>
                            </button>

                            <button className={styles['submission__author-row']} type="button" aria-label="like submission" onClick={handleLike}>
                                <SmallHeart />
                                <span>{likeCount}</span>
                            </button>
                        </div>
                    </div>
                    <div className={styles['submission__author-date']}>{formatDateToString(created_at, ALTERNATE_DATE_FORMAT)}</div>
                </div>

                <div className={styles['submission__content']}>
                    {isEditing ? (
                        <form className={styles['submission__content-form']}>
                            <textarea className={styles['submission__content-input']} onChange={(e) => setEditedDescription(e.target.value)} value={editedDescription} />
                            <button
                                className={classNames(styles['submission__content-submit'], styles['submission__content-submit--primary'])}
                                aria-label="update submission"
                                type="button"
                                onClick={updateSubmission}
                            >
                                Save
                            </button>
                            <button
                                className={classNames(styles['submission__content-submit'], styles['submission__content-submit--secondary'])}
                                aria-label="cancel update submission"
                                onClick={cancelEditing}
                                type="button"
                            >
                                Cancel
                            </button>
                        </form>
                    ) : (
                        description.length && <div className={styles['submission__content-description']}>{description}</div>
                    )}
                </div>
            </div>
            <div className={styles['submission__song-container']}>
                {isEditing ? (
                    <textarea
                        className={classNames(styles['submission__content-input'], styles['submission__content-input--title'])}
                        onChange={(e) => setEditedTitle(e.target.value)}
                        value={editedTitle}
                    />
                ) : (
                    <div className={styles['submission__title-container']}>
                        <button type="button" aria-label="play/pause submission" onClick={currentSoundId === id ? handleStop : handlePlay}>
                            {currentSoundId === id ? <PauseIcon /> : <SmallPlay />}
                        </button>
                        <div className={styles['submission__wave-container']}>
                            <CreateWave wave={songWave} handleSeek={handleSeek} elapsed={elapsed} duration={`${duration}`} isPaused={currentSoundId !== id} />
                        </div>

                        <span className={styles['submission__title-container']}>{title}</span>
                    </div>
                )}

                <div className={styles['submission__actions']}>
                    <button
                        type="button"
                        onClick={handleLike}
                        className={classNames(styles['submission__actions__like'], {
                            [styles['submission__actions__like--liked']]: liked,
                        })}
                    >
                        <SubmissionHeart />
                    </button>
                    <SocialShare
                        platforms={['facebook', 'twitter', 'linkedin', 'link', 'email']}
                        url={`${window.location.host}${window.location.pathname}?submissionId=${id}`}
                        icon={<SubmissionShare />}
                    />
                </div>
            </div>
        </div>
    );
}

export default Submission;
