import { MediaWithDownloadedDate } from '@bpm-web-app/download-api-sdk';
import { appendQueryParams, formatDateToString, isDownloadLimitReached } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { Sound } from '@bpm-web-app/create-api-sdk';
import styles from './list.module.css';
import DownloadIcon from '../../../../assets/icons/download.svg';
import ExclusiveTagIcon from '../../../../assets/icons/exclusive-tag.svg';

interface ListItemProps {
    media: MediaWithDownloadedDate
    downloadMedia: () => void
    isDownloadInProgress: boolean
}

export function ListItemSupreme({ media, downloadMedia, isDownloadInProgress }: ListItemProps) {
    const isMediaAvailable = !isDownloadLimitReached(media.download_count);
    return (
        <div
            key={media.id}
            className={classNames(styles['table__list-item'], {
                [styles['table__list-item--inactive']]: isMediaAvailable,
            })}
        >
            <div className={classNames(styles['table__title-column'])}>
                {media.album?.cover_url && (
                    <img
                        src={appendQueryParams(media.album?.cover_url, { key: 'dw', value: 64 })}
                        srcSet={`${appendQueryParams(media.album?.cover_url, { key: 'dw', value: 112 })} 2x`}
                        alt={media.name}
                        className={styles['table__title-column-cover']}
                    />
                )}
                <div className={styles['table__title-column-text']}>
                    <div className={styles['table__title-column-title']}>
                        <span>{media.name}</span>
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore */}
                        {media.album?.is_exclusive && <ExclusiveTagIcon className={styles['table__title-column-exclusive']} />}
                    </div>
                    <div className={styles['table__title-column-subtitle']}>
                        <span>{media.album?.artist}</span>
                        <span className={styles['table__title-column-download-info-mobile']}>{media.version.name} / {formatDateToString(media.last_downloaded, 'MM/DD/YYYY')}</span>
                    </div>
                </div>
            </div>
            <div className={styles['table__list-column--version']}>{media.version.name}</div>
            <div className={styles['table__list-column--download-date']}>{formatDateToString(media.last_downloaded, 'MM/DD/YYYY')}</div>
            <div className={styles['table__list-column--actions']}>
                <button
                    type="button"
                    className={styles['table__download-btn']}
                    aria-label={`Download ${media.name}`}
                    disabled={isDownloadInProgress || !isMediaAvailable}
                    onClick={downloadMedia}
                >
                    {media.download_count} / 3
                    <DownloadIcon />
                </button>
            </div>
        </div>
    );
}

interface ListItemCreateProps extends Omit<ListItemProps, 'media'> {
    media: Sound
}
export function ListItemCreate({ media, downloadMedia, isDownloadInProgress }: ListItemCreateProps) {
    return (
        <div key={media.id} className={classNames(styles['table__list-item'], styles['table__list-item--create'])}>
            <div className={classNames(styles['table__title-column'])}>
                {media.artwork_url && (
                    <img
                        src={appendQueryParams(media.artwork_url, { key: 'dw', value: 64 })}
                        srcSet={`${appendQueryParams(media.artwork_url, { key: 'dw', value: 112 })} 2x`}
                        alt={media.name}
                        className={styles['table__title-column-cover']}
                    />
                )}
                <div className={styles['table__title-column-text']}>
                    <div className={styles['table__title-column-title']}>
                        <span>{media.name}</span>
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore */}
                        {media.album?.is_exclusive && <ExclusiveTagIcon className={styles['table__title-column-exclusive']} />}
                    </div>
                    <div className={styles['table__title-column-subtitle']}>
                        <span>{media.artist}</span>
                        <span className={styles['table__title-column-download-info-mobile']}>{formatDateToString(media.last_downloaded, 'MM/DD/YYYY')}</span>
                    </div>
                </div>
            </div>
            <span className={styles['table__list-column--download-date']}>{formatDateToString(media.last_downloaded, 'MM/DD/YYYY')}</span>
            <div className={styles['table__list-column--actions']}>
                <button type="button" className={styles['table__download-btn']} aria-label={`Download ${media.name}`} disabled={isDownloadInProgress} onClick={downloadMedia}>
                    <DownloadIcon />
                </button>
            </div>
        </div>
    );
}
