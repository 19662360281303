import { ReactNode, useState } from 'react';
import { Collapse } from 'react-collapse';
import classNames from 'classnames';
import styles from './expansion-panel.module.css';
import PlusIcon from '../../../../assets/icons/plus.svg';
import MinusIcon from '../../../../assets/icons/minus.svg';

interface ExpansionPanelProps {
    label: ReactNode;
    content: ReactNode;
    // eslint-disable-next-line react/require-default-props
    isOpenByDefault?: boolean;
}
export function ExpansionPanel({ label, content, isOpenByDefault }: ExpansionPanelProps) {
    const [isOpen, setIsOpen] = useState(isOpenByDefault || false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div
            className={classNames(styles['expansion-panel'], {
                [styles['expansion-panel--open']]: isOpen,
            })}
        >
            <div
                className={styles['expansion-panel__trigger']}
                role="button"
                aria-expanded={isOpen}
                tabIndex={0}
                aria-label="Toggle expansion panel"
                onClick={() => {
                    toggle();
                }}
                onKeyUp={(event) => {
                    if (event.key === ' ' || event.key === 'Enter') toggle();
                }}
            >
                {label}
                {isOpen ? <MinusIcon /> : <PlusIcon />}
            </div>
            <Collapse isOpened={isOpen}>
                <div className={styles['expansion-panel__content']}>{content}</div>
            </Collapse>
        </div>
    );
}
