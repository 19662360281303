import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { getAbsolutePosition, useOnClickOutside, useViewport } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { ContextMenuContext } from './context-menu.context';
import { ContextMenuItem } from './context-menu-item';
import styles from './context-menu.module.css';

type ContainerStyle = {
    top?: number;
    left?: number;
};

export const ContextMenuClickIgnoreClass = 'context-menu-ignore-on-click';

export function ContextMenu() {
    const { options, closeOptions, leftPosition, topPosition, move, alignment, renderLocation, actionButtonWidth } = useContext(ContextMenuContext);
    const { isMobile } = useViewport();
    const threeDotsIconClassName = 'js-three-dots-icon';
    const [containerWidth, setContainerWidth] = useState(0);

    const handleCloseSheet = useCallback(() => {
        closeOptions();
    }, [closeOptions]);

    const { ref } = useOnClickOutside<HTMLDivElement>(false, (e) => {
        const eventTarget = e.target;
        if (eventTarget instanceof Element) {
            const parentClass = `.${styles['context-menu__container']}`;
            /* if clicked target with 3 dots */
            if (eventTarget.closest(parentClass)) {
                return;
            }

            if (eventTarget.closest(`.${ContextMenuClickIgnoreClass}`)) {
                return;
            }

            /* if clicked on a direct parent of the svg icon */
            const eventTargetChildren = Array.from(eventTarget.children);
            if (eventTargetChildren.some((childElement) => childElement.classList.contains(threeDotsIconClassName))) {
                return;
            }
        }

        handleCloseSheet();
    });

    const containerStyle = useMemo(() => {
        const styleTemp: ContainerStyle = {};
        if (isMobile) {
            return styleTemp;
        }

        if (leftPosition !== null) {
            styleTemp.left = leftPosition;

            if (alignment === 'right' && containerWidth) {
                styleTemp.left += containerWidth + (actionButtonWidth || 0);
            }
        }
        if (topPosition !== null) {
            styleTemp.top = topPosition;
        }
        return styleTemp;
    }, [isMobile, leftPosition, topPosition, alignment, containerWidth, actionButtonWidth]);

    useEffect(() => {
        if (isMobile && options.length > 0) {
            document.documentElement.classList.add('stop-scroll');
        } else {
            document.documentElement.classList.remove('stop-scroll');
        }
        return () => {
            document.documentElement.classList.remove('stop-scroll');
        };
    }, [isMobile, options.length]);

    useEffect(() => {
        if (isMobile || renderLocation === 'notification') {
            return;
        }
        if (ref.current && ref.current.children.length > 0) {
            const { width, height } = ref.current.children[0].getBoundingClientRect();
            // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
            const _left = leftPosition;

            const { x, y } = getAbsolutePosition(ref.current);
            let deltaX = 0;
            let deltaY = 0;
            if ((x - width) < 0) {
                deltaX = (width - _left);
            }
            if ((y + height) > window.innerHeight) {
                deltaY = window.innerHeight - (y + height);
            }
            if (Math.abs(deltaX) > 10 || Math.abs(deltaY) > 10) {
                move(_left + deltaX, topPosition + deltaY);
            }
        }
    }, [ref, leftPosition, topPosition, options, isMobile, move, alignment, renderLocation]);

    useEffect(() => {
        const onScroll = () => {
            handleCloseSheet();
        };
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, [handleCloseSheet]);

    return (
        <div
            ref={ref}
            style={containerStyle}
            className={classNames(styles['context-menu__container'], styles['context-menu__container--active'], {
                [styles['context-menu__container--hide']]: options.length === 0,

            })}>
            <div
                ref={(e) => {
                    setContainerWidth(e?.getBoundingClientRect().width || 0);
                }}
                className={classNames(styles['context-menu__list'], {
                    [styles['context-menu__list--hide']]: options.length === 0,
                })}>
                <ul>
                    {options ? options.map((option, index) =>
                        <Fragment key={option.label || 'divider'}>
                            <ContextMenuItem {...option} />
                        </Fragment>
                    )
                        : null
                    }
                </ul>
                {isMobile ? (
                    <button
                        type="button"
                        className={classNames(styles['context-menu__container--cancel-btn'], {
                            [styles['context-menu__container--cancel-btn--bottom-bar-visible']]: renderLocation === 'notification',
                        })}
                        onClick={() => {
                            handleCloseSheet();
                        }}
                    >
                        Cancel
                    </button>
                ) : null}
            </div>

        </div>
    );
}
