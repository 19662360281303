import { useGetArtistBySlug, useGetArtistFeaturedIn } from '@bpm-web-app/swr-hooks';
import { useRouter } from 'next/router';
import { appendQueryParams, useHubSwitch, useViewport } from '@bpm-web-app/utils';
import { Fragment, useContext } from 'react';
import { LibraryTabsContext } from '@bpm-web-app/components';
import { Playlist } from '@bpm-web-app/download-api-sdk';
import styles from './artist-featured-in.module.css';
import CardGrid from '../../shared/card-grid/card-grid';
import SecondaryPageTitle from '../../shared/secondary-page-title/secondary-page-title';
import ListGrid from '../../shared/ui/list-grid/list-grid';
import ListGridItem from '../../shared/list-grid-item/list-grid-item';
import Title from '../../title/title';
import { PlaylistCard } from '../../shared/card/playlist-card/playlist-card';

export function ArtistFeaturedIn() {
    const router = useRouter();
    const { artistSlug } = router.query;

    const { data: artist, isLoading: isArtistLoading } = useGetArtistBySlug(artistSlug ? artistSlug.toString() : '');

    const { libraryProperty } = useContext(LibraryTabsContext);
    const { data: artistFeaturedIn, isLoading: isArtistFeaturedInLoading } = useGetArtistFeaturedIn(artist?.id, libraryProperty);

    const { isMobile } = useViewport();
    const { hub } = useHubSwitch();

    if (!artist && !isArtistLoading) return null;

    // TODO: Do you have a loading indicator/component?
    if (isArtistFeaturedInLoading || isArtistLoading || !artist || !artistFeaturedIn) return null;

    return (
        <>
            <Title platform={hub} title={artist?.name} />
            <article className={styles['artist-featured-in']}>
                {isMobile && (
                    <>
                        <div className={styles['artist-featured-in__title']}>
                            <SecondaryPageTitle title={`${artist.name} Featured In`} counter={`${artistFeaturedIn.playlists.length}`} />
                        </div>
                        <ListGrid>
                            {artistFeaturedIn.playlists.map((playlist: any) => (
                                <Fragment key={playlist.id}>
                                    <ListGridItem
                                        id={playlist.id}
                                        title={playlist.title}
                                        subtitle={playlist.artist}
                                        imageUrl={appendQueryParams(playlist.image_url, { key: 'dw', value: 56 })}
                                        imageUrl2x={appendQueryParams(playlist.image_url, { key: 'dw', value: 112 })}
                                        link={`/exclusive-playlist/${playlist.id}`}
                                        contentType="artist"
                                    />
                                </Fragment>
                            ))}
                        </ListGrid>
                    </>
                )}
                {!isMobile && (
                    <div className="spacing__window">
                        <div className={styles['artist-featured-in__title']}>
                            <SecondaryPageTitle title={`${artist.name} Featured In`} noPadding counter={`${artistFeaturedIn.playlists.length}`} />
                        </div>
                        <CardGrid cardType="card-small">
                            {artistFeaturedIn.playlists.map((playlist: Playlist, index: number) => (
                                <PlaylistCard
                                    key={playlist.id}
                                    playlist={playlist as Playlist}
                                    playlists={artistFeaturedIn.playlists as Playlist[]}
                                />
                            ))}
                        </CardGrid>
                    </div>
                )}
            </article>
        </>
    );
}

export default ArtistFeaturedIn;
