import { useGetPaymentHistory } from '@bpm-web-app/swr-hooks';
import { Subscription } from '@bpm-web-app/api-client';
import { formatDateToString, useApiErrorHandler } from '@bpm-web-app/utils';
import styles from './invoices.module.css';
import DownloadIcon from '../../../../assets/icons/download-small.svg';

interface InvoiceProps {
    type: 'stream' | 'supreme' | 'create';
}

export function Invoices({ type }: InvoiceProps) {
    const { data: paymentHistory, isLoading: isLoadingPaymentHistory } = useGetPaymentHistory(type);
    const errorHandler = useApiErrorHandler();
    const handleDownload = async (id: number) => {
        try {
            const response = await Subscription.downloadInvoice(id);
            const file = await response.blob();
            window.open(URL.createObjectURL(file), '_blank');
        } catch (error) {
            errorHandler({ error });
        }
    };

    if (isLoadingPaymentHistory) return null;

    return (
        <>
            <div className={styles['invoices__header']}>
                <h3 className={styles['invoices__content-title']}>Invoices</h3>
            </div>
            {paymentHistory?.data.length > 0 && (
                <table className={styles['invoices__table']}>
                    <thead>
                        <tr>
                            <th>ORDER ID</th>
                            <th>PAYMENT DATE</th>
                            <th className={styles['invoices__heading--desktop-only']}>PLAN</th>
                            <th className={styles['invoices__heading--desktop-only']}>PAYMENT VIA</th>
                            <th className={styles['invoices__heading--desktop-only']}>AMOUNT</th>
                            <th>INVOICE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paymentHistory.data.map((payment) => (
                            <tr key={payment.id}>
                                <td><span>{`#${payment.id}`}</span></td>
                                <td><span>{formatDateToString(payment.created_at, 'MM/DD/YYYY')}</span></td>
                                <td className={styles['invoices__heading--desktop-only']}><span>{payment.plan_name}</span></td>
                                <td className={styles['invoices__heading--desktop-only']}><span>{payment.method}</span></td>
                                <td className={styles['invoices__heading--desktop-only']}><span>{`$${payment.paid_amount}`}</span></td>
                                <td>
                                    <button aria-label="Download invoice" onClick={() => handleDownload(payment.id)} type="button">
                                        <DownloadIcon />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </>
    );
}

export default Invoices;
