import { getCurrentPlatformLink, rebuildReactTooltip, useHubSwitch } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { memo, useContext, useEffect, useState } from 'react';
import { AppLink } from '../shared/app-link/app-link';

import styles from './nav.module.css';
import { NavContext } from './nav.context';

type Props = {
    title: string;
    icon: JSX.Element;
    activeIcon: JSX.Element;
    path?: string;
    action?: () => void;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    isDragging?: boolean;
    isDraggingOver?: boolean;
    disableDragDrop?: boolean;
    isDisabled?: boolean;
};

export const PlainNavItem = memo(({ title, icon, activeIcon, path, action, isDragging = false, isDraggingOver = false, disableDragDrop = false, onClick, isDisabled }: Props) => {
    const router = useRouter();
    const { isCreate, isArtistPlatform } = useHubSwitch();
    const [currentHash, setCurrentHash] = useState(window.location.hash);
    useEffect(() => {
        const listener = () => {
            setCurrentHash(window.location.hash);
        };
        if (isArtistPlatform) {
            document.addEventListener('artist-nav-update', listener);
            setCurrentHash(window.location.hash);
        }
        return () => {
            document.removeEventListener('artist-nav-update', listener);
        };
    }, [isArtistPlatform, setCurrentHash]);

    const isSelected = isArtistPlatform ? (`${router.pathname}${currentHash}`) === path || (`${router.pathname}`) === path : router.pathname.slice(isCreate ? 1 : 3) === path?.slice(1);
    const { isNavOpen } = useContext(NavContext);

    useEffect(() => {
        rebuildReactTooltip();
    }, []);

    if (isDisabled) {
        return (
            <button type="button" className={classNames(styles['nav__menu-item'], styles['nav__menu-item--btn'])} disabled>
                <i className={classNames(styles['nav__menu-item--icon'])}>{icon}</i>
                <span>{title}</span>
            </button>
        );
    }

    if (path) {
        return (
            <AppLink key={title} href={{ pathname: `${getCurrentPlatformLink(path)}` }} overrideAppLinkTransform>
                <button
                    disabled={isDragging && disableDragDrop}
                    className={classNames(styles['nav__menu-item'], styles['nav__menu-item--link'], {
                        [styles['nav__menu-item--active']]: isSelected,
                        [styles['nav__menu-item--is-dragging']]: isDragging,
                        [styles['nav__menu-item--is-dragging--over']]: isDraggingOver,
                    })}
                    onClick={onClick}
                    type="button"
                    data-tip={(!isNavOpen) ? `${title}` : ''}
                >
                    <i
                        className={classNames(styles['nav__menu-item--icon'])}
                    >{isSelected && activeIcon ? activeIcon : icon}
                    </i>
                    <span>{title}</span>
                </button>
            </AppLink>
        );
    }

    if (action) {
        return (
            <button type="button" className={classNames(styles['nav__menu-item'], styles['nav__menu-item--btn'])} onClick={action} disabled={isDragging && disableDragDrop}>
                <i className={classNames(styles['nav__menu-item--icon'])}>{icon}</i>
                <span>{title}</span>
            </button>
        );
    }

    return null;
});
