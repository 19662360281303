import useSWR from 'swr';
import { Notifications } from '@bpm-web-app/api-client';

export function useNotifications(params: Parameters<typeof Notifications.getNotifications>[0]) {
    const { data, error, mutate } = useSWR(
        [
            'supreme-get-notifications',
            {
                persist: true,
                ...params,
            },
        ],
        () => Notifications.getNotifications(params),
        {
            refreshInterval: 1000 * 60 * 1, // 1 min
        }
    );

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}

/** Marks notifications as read */
export function useReadNotifications(params: Parameters<typeof Notifications.readNotifications>[0]) {
    return () => Notifications.readNotifications(params);
}
