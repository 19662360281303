import styles from './search-buttons.module.css';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';

interface SearchButtonsProps {
    handleCancel: () => void;
}

export function SearchButtons({ handleCancel }: SearchButtonsProps) {
    return (
        <div className={styles['search-buttons']}>
            <button type="submit" className={styles['search-buttons__search-container']}>
                <SearchIcon className={styles['search-buttons__icon']} />
                <div className={styles['search-buttons__search']}>
                    Search
                </div>
            </button>
        </div>
    );
}
