import classNames from 'classnames';
import { RefObject, useEffect, useState } from 'react';
import ChevronDownIcon from '../../../../assets/icons/chevron-up.svg';
import styles from './minimize-button.module.css';

interface MinimizeButtonProps {
    closeMaxiPlayer: () => void;
    playerRef: RefObject<HTMLDivElement | null>
}

export function MinimizeButton({ playerRef, closeMaxiPlayer }: MinimizeButtonProps) {
    const [isFixed, setIsFixed] = useState(false);

    useEffect(() => {
        let ref: HTMLDivElement;

        const handleScroll = (e: Event) => {
            if ((e.target as HTMLDivElement).scrollTop >= 72) {
                setIsFixed(true);
            } else {
                setIsFixed(false);
            }
        };

        if (playerRef.current) {
            ref = playerRef.current;
            playerRef.current.addEventListener('scroll', handleScroll, { passive: true });
        }

        return () => {
            ref?.removeEventListener('scroll', handleScroll);
        };
    }, [playerRef]);

    return (
        <div className={styles['minimize-button__container']}>
            <button
                aria-label="Switch to mini player"
                onClick={closeMaxiPlayer}
                className={classNames(styles['minimize-button__button'], {
                    [styles['minimize-button__button--scrolled']]: isFixed,
                })}
                type="button"
            >
                <span className={classNames(styles['minimize-button__button-label'])}>Minimize</span>
                <span className={classNames(styles['minimize-button__button-icon'])}>
                    <ChevronDownIcon />
                </span>
            </button>
        </div>
    );
}
