import { ReactElement } from 'react';
import classNames from 'classnames';
import styles from './page-switcher.module.css';

export interface Page {
    text: string
    leftIcon?: ReactElement
    color?: string
    enabled?: boolean
    completed?: boolean
    onClick?: () => void
    onSelected: (index: number) => void;
}

export interface PageSwitcherProps {
    pages?: Page[]
    selected?: number;
    variant?: 'artist' | 'dynamic';
}

export function PageSwitcher({ pages, selected, variant = 'artist' }: PageSwitcherProps) {
    return (
        <div role="tablist" className={styles[`switcher-tabs__${variant}`]}>
            {pages && pages.map(({ text, leftIcon, color = `var(${variant === 'artist' ? '--color-gin' : '--color-main-dynamic'})`, enabled, onSelected, completed }, index) => {
                const selectedStyles = {
                    color,
                    borderColor: color,
                    stroke: color
                };

                const completedStyles = {
                    stroke: 'var(--color-fruit-salad)',
                    color: 'var(--color-fruit-salad)',
                    opacity: 1,
                };

                const computeStyles = (type?: 'icon' | 'tab') => {
                    if ((completed) && (type === 'tab')) {
                        if (selected === index) return { ...completedStyles, marginLeft: '1.5rem', borderColor: 'var(--color-fruit-salad)' };
                        return { ...completedStyles, marginLeft: '1.5rem' };
                    }
                    if ((completed) && (type === 'icon')) {
                        if (selected === index) return { ...completedStyles, borderColor: 'var(--color-fruit-salad)' };
                        return completedStyles;
                    }
                    if (selected === index) {
                        return selectedStyles;
                    }
                    return null;
                };

                return (
                    <button
                        key={text}
                        className={classNames(styles[`switcher-tabs__${variant}__tab`], {
                            [styles[`switcher-tabs__${variant}__tab--active`]]: selected === index,
                        })}
                        type="button"
                        role="tab"
                        onClick={() => {
                            if (enabled) {
                                onSelected(index);
                            }
                        }}
                        aria-selected={selected === index}
                        style={computeStyles('tab')}
                        disabled={!enabled}
                    >
                        {completed &&
                            <div
                                className={styles['switcher-tabs__left-icon']}
                                style={computeStyles('icon')}
                            >
                                {leftIcon}
                            </div>
                        }

                        {text}
                    </button>
                );
            })}
        </div>
    );
}

export default PageSwitcher;
