import { UserPlaylist } from '@bpm-web-app/api-client';
import { useGetUserPlaylistCategories, useUserPlaylistSets } from '@bpm-web-app/swr-hooks';
import { useApiErrorHandler, useHubSwitch, showToast } from '@bpm-web-app/utils';

export const useUserPlaylistCategories = (include_view_only?: boolean) => {
    const { isDownload, isCreate } = useHubSwitch();
    const { data: userPlaylistCategories, isLoading, mutate: mutateUserPlaylistCategories, } = useGetUserPlaylistCategories(isDownload, include_view_only, isCreate);
    const { data: userPlaylistSets, isLoading: isLoadingPlaylistSets, } = useUserPlaylistSets(isDownload, include_view_only, isCreate);
    const errorHandler = useApiErrorHandler();

    const createFolder = async (name: string) => {
        try {
            const response = await UserPlaylist.createUserPlaylistCategory(isDownload, name, 0);
            await mutateUserPlaylistCategories();
            showToast({ type: 'success', message: 'Folder created successfully.', buttonText: 'Go To Folder', replaceRouteOnButtonClick: `/my-playlists/${response.data.id}` });
            return response;
        } catch (error) {
            errorHandler({ error, message: 'Failed to create folder' });
            return null;
        }
    };

    const deleteFolder = async (categoryId: number) => {
        try {
            await UserPlaylist.deleteUserPlaylistCategory(isDownload, `${categoryId}`);
            mutateUserPlaylistCategories();
            showToast({ type: 'success', message: 'Folder deleted successfully.' });
        } catch (error) {
            errorHandler({ error });
        }
    };

    const editFolderName = async (categoryId: number, name: string) => {
        try {
            await UserPlaylist.updateUserPlaylistCategory(isDownload, categoryId as number, name, 0);
            mutateUserPlaylistCategories();
            showToast({ type: 'success', message: 'Folder edited.' });
        } catch (error) {
            errorHandler({ error });
        }
    };

    return {
        userPlaylistCategories,
        isLoading,
        userPlaylistSets,
        isLoadingPlaylistSets,
        createFolder,
        deleteFolder,
        editFolderName
    };
};
