import { SectionApi } from '@bpm-web-app/create-api-sdk';
import { createConfig } from '../../config';

export class Section {
    static client = new SectionApi(createConfig);

    static getSections = (location: string) => Section.client.getSections(location);

    static getSectionByName = (location: string, name: string) => Section.client.getSectionByName(location, name);
}
