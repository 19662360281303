import { SoundPackage, Label, CuratedSet } from '@bpm-web-app/create-api-sdk';
import { ReactNode } from 'react';
import dayjs from 'dayjs';
import { convertTopGenresToString } from './strings';

/** We might be able to reuse this with different Types besides sound packages */
export const convertSoundPackageToCarouselProps = (
    pack: Pick<SoundPackage, 'id' | 'name' | 'artwork_url' | 'slug' | 'demo_file_url' | 'demo_file_url_2' | 'approved' | 'created_at' | 'badge'> & { Genre?: SoundPackage['Genre'] },
    description: ReactNode = null
) => ({
    id: pack.id,
    title: pack.name,
    coverUrl: pack.artwork_url,
    description: description || '',
    genre: pack?.Genre || null,
    slug: pack.slug,
    approved: pack.approved && dayjs(pack.created_at).isBefore(new Date()),
    /** We assume that if there's no demo it's not playable */
    isPlayable: !!pack.demo_file_url,
    hasExtraDemo: !!pack.demo_file_url_2,
    badge: pack.badge
});

export const convertSoundPackageToSeeMoreLayoutProps = (pack: SoundPackage, description: ReactNode = null) => ({
    id: pack.id,
    image_url: pack.artwork_url,
    description: description || pack.Genre.name || '',
    title: pack.name,
    slug: pack.slug,
    demo_file_url: pack.demo_file_url,
    demo_wave: pack.demo_wave,
    demo_file_url_2: pack.demo_file_url_2,
    demo_wave_2: pack.demo_wave_2,
    /** We assume that if there's no demo it's not playable */
    isPlayable: !!pack.demo_file_url,
    hasExtraDemo: !!pack.demo_file_url_2,
    badge: pack.badge
});

export const convertLabelToCarouselProps = (label: Label, showDescription = false) => ({
    id: label.id,
    title: label.name,
    coverUrl: label.logo_url,
    description: showDescription && convertTopGenresToString(label?.top_genres),
    slug: label.name,
});

export const convertCuratedToCarouselProps = (curated: CuratedSet, showDescription = false) => ({
    id: curated.id,
    title: curated.name,
    coverUrl: curated.artwork_url,
    description: showDescription && curated?.description,
    slug: curated.slug,
});

export const convertCuratedToSeeMoreLayoutProps = (curated: CuratedSet, showDescription = false) => ({
    id: curated.id,
    title: curated.name,
    description: showDescription ? curated.description || '' : '',
    image_url: curated.artwork_url,
    slug: curated.slug,
});

export const convertLabelToSeeMoreProps = (label: Label, showDescription = false) => ({
    id: label.id,
    title: label.name,
    image_url: label.logo_url,
    description: showDescription && convertTopGenresToString(label?.top_genres),
    slug: label.name,
});
