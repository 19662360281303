import { AudioFileType } from '@bpm-web-app/create-api-sdk';
import { LOOP_TAG_VALUE, ONE_SHOT_TAG_VALUE } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { useCallback, useContext, useMemo, useState } from 'react';
import { Collapse } from 'react-collapse';
import ChevronUp from '../../assets/icons/chevron-up.svg';
import { FiltersContext } from '../filters/filters.context';
import Checkboxes from '../shared/checkboxes/checkboxes';
import styles from './file-type-filter.module.css';

const SYNTH_PRESETS_VALUE = 'synth';
const MIDI_VALUE = 'midi';

const options = [
    { label: 'One-Shot', value: ONE_SHOT_TAG_VALUE },
    { label: 'Loop', value: LOOP_TAG_VALUE },
    { label: 'MIDI', value: MIDI_VALUE },
    { label: 'Synth Preset', value: SYNTH_PRESETS_VALUE },
];

export function FileTypeFilter() {
    const { tags, setTags, fileType, setFileType } = useContext(FiltersContext);

    const [isSectionExpanded, setIsSectionExpanded] = useState(true);

    const tagsWithoutLoopOneShot = useMemo(() => tags.filter((tag) => tag !== LOOP_TAG_VALUE && tag !== ONE_SHOT_TAG_VALUE), [tags]);

    const handleTagSelection = useCallback(
        (selectedOptions: string[]) => {
            let currentOptions = selectedOptions;
            if (currentOptions.includes(MIDI_VALUE) && fileType !== AudioFileType.Midi) {
                setFileType(AudioFileType.Midi);
                currentOptions = [];
            } else if (currentOptions.includes(SYNTH_PRESETS_VALUE) && fileType !== AudioFileType.Preset) {
                setFileType(AudioFileType.Preset);
                currentOptions = [];
            } else {
                setFileType(undefined);
            }
            const nextTag = currentOptions.length ? currentOptions[currentOptions.length - 1] : undefined;
            setTags(nextTag ? [...tagsWithoutLoopOneShot, nextTag] : [...tagsWithoutLoopOneShot]);
        },
        [setTags, tagsWithoutLoopOneShot, setFileType, fileType]
    );

    const handleResetLoopOneShotTags = useCallback(() => {
        setTags(tagsWithoutLoopOneShot);
        setFileType(undefined);
    }, [setTags, setFileType, tagsWithoutLoopOneShot]);

    const handleSectionToggle = useCallback(() => setIsSectionExpanded((prevState) => !prevState), []);

    const selection = useMemo(() => {
        const op = tags.filter((tag) => tag === LOOP_TAG_VALUE || tag === ONE_SHOT_TAG_VALUE);
        if (fileType === AudioFileType.Mid || fileType === AudioFileType.Midi) {
            op.push(MIDI_VALUE);
        } else if (fileType === AudioFileType.Preset) {
            op.push(SYNTH_PRESETS_VALUE);
        }
        return op;
    }, [tags, fileType]);

    const filterActiveCount = selection.length;

    return (
        <div className={classNames('filter', styles['file-type-filter'], { [styles['file-type-filter--expanded']]: isSectionExpanded })}>
            <div
                role="button"
                onClick={handleSectionToggle}
                tabIndex={0}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleSectionToggle();
                    }
                }}
                className="filter__header">
                <div className={classNames('filter__title', { 'filter__title-active': filterActiveCount > 0 })}>File Type {filterActiveCount > 0 ? `(${filterActiveCount})` : ''}</div>
                {filterActiveCount > 0 && (
                    <button aria-label="Reset File Type" type="button" className="filter__reset" onClick={handleResetLoopOneShotTags}>
                        Remove
                    </button>
                )}
                <button aria-label="Expand/Collapse File Type" type="button" onClick={handleSectionToggle}>
                    <ChevronUp className="filter__chevron" />
                </button>
            </div>
            <Collapse isOpened={isSectionExpanded}>
                <Checkboxes options={options} value={selection} onChange={handleTagSelection} />
            </Collapse>
        </div>
    );
}

export default FileTypeFilter;
