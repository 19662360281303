import Lottie from 'lottie-react';
import { Library, LibraryTabsContext } from '@bpm-web-app/components';
import { useContext } from 'react';
import classNames from 'classnames';
import PlayingAnimation from '../../../assets/icons/animated-playing.json';
import styles from './now-playing-animation.module.css';

function NowPlayingAnimation() {
    const { library } = useContext(LibraryTabsContext);

    return (
        <div className={styles['now-playing-animation']}>
            <Lottie className={classNames(styles['now-playing-animation'], { [styles['now-playing-animation--artist']]: library === Library.Artist })} animationData={PlayingAnimation} loop />
        </div>
    );
}

export default NowPlayingAnimation;
