import { ReactElement } from 'react';
import { MissingImgPlaceholder } from '../../ui';
import styles from './card-image.module.css';

interface CardImageProps {
    imageComponent?: ReactElement;
    imageUrl?: string;
    imageUrl2x?: string;
    setIsCardImageLoaded?: () => void;
    title?: string;
    isSmallImg?: boolean;
    gridImagesUrls?: string[];
}

export function CardImage({ imageComponent, imageUrl, imageUrl2x, setIsCardImageLoaded, title, isSmallImg, gridImagesUrls }: CardImageProps) {
    if (gridImagesUrls && gridImagesUrls.length === 4) {
        return (
            <div className={styles['card-image__image-grid']}>
                {gridImagesUrls.map((url, index) => (
                    <div className={styles['card-image__image-item']} key={`${title}-grid-image-${index + 1}`}>
                        <img src={url} alt={`${title} ${index + 1}`} />
                    </div>
                ))}
            </div>
        );
    }
    return (
        <>
            {imageComponent === undefined ?
                (
                    imageUrl ? (
                        <img className={styles['card-image__image']} src={imageUrl} draggable={false} alt={title} srcSet={imageUrl2x ? `${imageUrl2x} 2x` : undefined} onLoad={setIsCardImageLoaded} />
                    ) : <MissingImgPlaceholder isSmallImg={isSmallImg} />
                ) : null}
            {imageComponent || null}
        </>
    );
}
