import classNames from 'classnames';
import { SoundPackageDemoFileUrl } from '@bpm-web-app/create-api-sdk';
import { ReactNode, useMemo, useContext, Fragment } from 'react';
import { getInitialsFromName } from '@bpm-web-app/utils';
import { MediaWithAlbum } from '@bpm-web-app/stream-api-sdk';
import { Album as DownloadAlbum } from '@bpm-web-app/download-api-sdk';
import styles from './list-grid-item.module.css';
import ThreeDotsIcon from '../../../assets/icons/more-dots-vert.svg';
import { AppLink } from '../app-link/app-link';
import { CreateActionType, CreateThreeDotsSheetContext } from '../three-dots-sheet/create-three-dots-sheet.context';
import { showActionsForContentType } from '../card/card-create';
import { CreateCardContentTypeWithoutActions } from '../card/card-image-overlay/card-image-overlay';

export interface CreateListGridItemProps {
    id: number | string;
    link?: string;
    imageUrl: string;
    imageUrl2x: string;
    title: string;
    subtitle: ReactNode;
    song?: MediaWithAlbum | DownloadAlbum;
    songsList?: MediaWithAlbum[];
    isPlayable?: boolean;
    onPlayItem?: () => void;
    onMoreOptions?: (id: string | number) => void;
    contentType: CreateActionType | CreateCardContentTypeWithoutActions;
    secondPlayableDemo?: SoundPackageDemoFileUrl;
    shareURL?: string;
}

// eslint-disable-next-line max-len
export function CreateListGridItem({ id, imageUrl, imageUrl2x, title, subtitle, link, song, contentType, isPlayable = false, onPlayItem, onMoreOptions, shareURL, secondPlayableDemo }: CreateListGridItemProps) {
    const { openThreeDotsModalSheet } = useContext(CreateThreeDotsSheetContext);

    const ItemBody = useMemo(
        () => (
            <a className={classNames(styles['list-grid-item'])}>
                <div className={styles['list-grid-item__img-wrapper']}>
                    {imageUrl && <img className={styles['list-grid-item__img']} src={imageUrl} draggable={false} alt={title} srcSet={imageUrl2x ? `${imageUrl2x} 2x` : undefined} />}
                    {!imageUrl && <div className={styles['list-grid-item__name-initials']}>{getInitialsFromName(title)}</div>}
                </div>
                <div className={styles['list-grid-item__details']}>
                    <div className={styles['list-grid-item__title']}>{title}</div>
                    <div className={styles['list-grid-item__subtitle']}>{subtitle}</div>
                </div>
                {(contentType || onMoreOptions) && showActionsForContentType(contentType) && (
                    <div className={styles['list-grid-item__menu']}>
                        <button
                            aria-label="More Options"
                            type="button"
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                if (onMoreOptions) return onMoreOptions(id);
                                return openThreeDotsModalSheet({
                                    newCreateActionType: contentType as CreateActionType,
                                    actionId: id,
                                    left: event.currentTarget.offsetLeft,
                                    top: event.currentTarget.offsetTop,
                                    showExtraPlayDemoOption: !!secondPlayableDemo,
                                    shareUrl: shareURL,
                                });
                            }}
                        >
                            <ThreeDotsIcon />
                        </button>
                    </div>
                )}
            </a>
        ),
        [imageUrl, title, imageUrl2x, subtitle, contentType, onMoreOptions, id, openThreeDotsModalSheet, secondPlayableDemo, shareURL]
    );

    if (isPlayable) {
        return (
            <div
                key={id}
                aria-label={song ? `Play ${song.name}` : ''}
                role="button"
                tabIndex={0}
                onClick={onPlayItem}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') onPlayItem();
                }}
            >
                {ItemBody}
            </div>
        );
    }

    return link ? (
        <AppLink key={id} href={link}>
            {ItemBody}
        </AppLink>
    ) : (
        <Fragment key={id}>{ItemBody}</Fragment>
    );
}

export default CreateListGridItem;
