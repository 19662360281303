import { OfflineCrateApi as StreamOfflineCrateApi, OfflinecrateBatchBody } from '@bpm-web-app/stream-api-sdk';
import { OnlineCrateApi as DownloadOnlineCrateApi, OnlinecrateBatchBody } from '@bpm-web-app/download-api-sdk';
import { downloadConfig, downloadDirectConfig, streamConfig } from '../config';

export class Crate {
    static offlineClient = new StreamOfflineCrateApi(streamConfig);

    static onlineClient = new DownloadOnlineCrateApi(downloadConfig);

    static getOfflineCrate = (library?: string) => Crate.offlineClient.getOfflineCrate(library);

    static getOfflineCrateCategories = (library: string) => Crate.offlineClient.getOfflineCrateCategories(library);

    static getOnlineCrate = (library?: string) => Crate.onlineClient.getOnlineCrate(library);

    static getOnlineCrateCategories = (library: string) => Crate.onlineClient.getOnlineCrateCategories(library);

    static addMediaToOnlineCrate = (mediaId: number) => Crate.onlineClient.addMediaToOnlineCrate(mediaId);

    static addMultipleMediaToOnlineCrate = (body: OnlinecrateBatchBody) => Crate.onlineClient.addMultipleMediaToOnlineCrate(body);

    static addMediaToOfflineCrate = (mediaId: number) => Crate.offlineClient.addMediaToOfflineCrate(mediaId);

    static addMultipleMediaToOfflineCrate = (body: OfflinecrateBatchBody) => Crate.offlineClient.addMultipleMediaToOfflineCrate(body);

    static removeMultipleMediaFromOfflineCrate = (ids: number[]) => Crate.offlineClient.removeMultipleMediaFromOfflineCrate({ media_ids: ids });

    static removeFromOfflineCrate = (id: number) => Crate.offlineClient.removeFromOfflineCrate(id);

    static removeMultipleMediaFromOnlineCrate = (ids: number[]) => Crate.onlineClient.removeMultipleMediaFromOnlineCrate({ media_ids: ids });

    static removeFromOnlineCrate = (id: number) => Crate.onlineClient.removeFromOnlineCrate(id);

    static generateOnlineCrateZip = (location: string, library: string, token: string, categorySlug: string) =>
        new DownloadOnlineCrateApi(downloadDirectConfig).generateOnlineCrateZip(location, library, token, categorySlug);
}
