import { LabelApi } from '@bpm-web-app/create-api-sdk';
import { createConfig } from '../../config';
import { CreateSearchPaginatedQuery } from '../../typings';

export class Label {
    static client = new LabelApi(createConfig);

    static getLabels = (type?: string, synth?: string) => Label.client.getLabels(type, synth);

    static getTrendingLabels = () => Label.client.getTrendingLabels();

    static searchLabels = (name: string) => Label.client.searchLabels(name);

    static getLabelByName = (name: string) => Label.client.getLabelByName(name);

    static getLabelNewestPacks = (name: string, args?: CreateSearchPaginatedQuery) =>
        Label.client.getLabelNewestPacks(
            name,
            args?.limit,
            args?.skip,
            args?.search,
            args?.bpm_max,
            args?.bpm_min,
            args?.key as string,
            args?.keyType,
            args?.type,
            args?.tags,
            args?.tag_groups,
            args?.artists,
            args?.subgenre,
            args?.label,
            args?.sound_package_id,
            args?.curated_id,
            args?.drive_id,
            args?.genre as string,
            args?.showHalfTime,
            args?.suggested,
            args?.hide_prev_played,
            args?.hide_prev_downloaded,
            args?.only_free,
            args?.tag_categories,
            args?.trending,
            args?.synth
        );

    static getLabelTopPacks = (name: string, args?: CreateSearchPaginatedQuery) =>
        Label.client.getLabelTopPacks(
            name,
            args?.limit,
            args?.skip,
            args?.search,
            args?.bpm_max,
            args?.bpm_min,
            args?.key as string,
            args?.keyType,
            args?.type,
            args?.tags,
            args?.tag_groups,
            args?.artists,
            args?.subgenre,
            args?.label,
            args?.sound_package_id,
            args?.curated_id,
            args?.drive_id,
            args?.genre as string,
            args?.showHalfTime,
            args?.suggested,
            args?.hide_prev_played,
            args?.hide_prev_downloaded,
            args?.only_free,
            args?.tag_categories,
            args?.trending,
            args?.synth
        );

    static favoriteLabel = (id: string) => Label.client.favoriteLabel(id);

    static unfavoriteLabel = (id: string) => Label.client.unfavoriteLabel(id);

    static getFavoritedLabels = () => Label.client.getFavoritedLabels();
}
