import { PlaylistApi as StreamPlaylistApi } from '@bpm-web-app/stream-api-sdk';
import { PlaylistApi as DownloadPlaylistApi } from '@bpm-web-app/download-api-sdk';
import { downloadConfig, streamConfig } from '../config';
import { SearchPlaylistsSetsQuery } from '../typings';

export class Playlist {
    static streamClient = new StreamPlaylistApi(streamConfig);

    static downloadClient = new DownloadPlaylistApi(downloadConfig);

    static getClient(isDownload: boolean) {
        return isDownload ? this.downloadClient : this.streamClient;
    }

    static getPlaylists = (
        isDownload: boolean,
        args: {
            limit: number;
            skip: number;
            library?: string;
        }
    ) => Playlist.getClient(isDownload).getPlaylistSets(args.limit, args.skip, args.library);

    static getFeaturedPlaylists = (
        isDownload: boolean,
        args: {
            library?: string;
        }
    ) => Playlist.getClient(isDownload).getFeaturedPlaylist(args.library);

    static getPlaylistByCategoryId = (isDownload: boolean, categoryId: number, library?: string) =>
        Playlist.getClient(isDownload).getPlaylistSetsByCatId(categoryId, library);

    static getPlaylistDetail = (isDownload: boolean, playlistId: number) => Playlist.getClient(isDownload).getPlaylistSet(playlistId);

    static getPlaylistCategories = (isDownload: boolean, library: string) => Playlist.getClient(isDownload).getPlaylistCategories(library);

    static searchPlaylistSets = (isDownload: boolean, query: SearchPlaylistsSetsQuery) => {
        if (isDownload) {
            return Playlist.downloadClient.searchPlaylistSets(
                query.limit,
                query.skip,
                query.category,
                query.library,
                query.genre,
                query.artist,
                query.hide_remix ? '1' : '0',
                query.hide_originals ? '1' : '0',
                query.hide_exclusives ? '1' : '0',
                query.hide_explicit ? '1' : '0',
                query.hide_prev_downloaded ? '1' : '0',
                query.version as string,
                query.key,
                query.keyType,
                query.bpm_min,
                query.bpm_max,
                query.term,
                query.date
            );
        }
        return Playlist.streamClient.searchPlaylistSets(
            query.limit,
            query.skip,
            query.category,
            query.library,
            query.genre,
            query.artist,
            query.version as string,
            query.key,
            query.keyType,
            query.bpm_min,
            query.bpm_max,
            query.term,
            query.date
        );
    };

    static getFollowedPlaylists = (isDownload: boolean, library?: string) => Playlist.getClient(isDownload).getFollowedPlaylists(library);

    static addPlaylistToFollowed = (isDownload: boolean, id: number) => Playlist.getClient(isDownload).followPlaylist(id);

    static removePlaylistFromFollowed = (isDownload: boolean, id: number) => Playlist.getClient(isDownload).unfollowPlaylist(id);
}
