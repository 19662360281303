import classNames from 'classnames';
import styles from './breakpoint-view.module.css';

type Props = {
    desktopChildren: React.ReactNode;
    mobileChildren: React.ReactNode;
    containerStyle?: any;
};
/**
 * Component to hide/show a component based in mobile/desktop breakpoint
 */
export function BreakpointView({ mobileChildren, desktopChildren, containerStyle }: Props) {
    return (
        <div className={classNames(styles['breakpoint-view'], containerStyle)}>
            <div className={styles['breakpoint-view__mobile']}>{mobileChildren}</div>
            <div className={styles['breakpoint-view__desktop']}>{desktopChildren}</div>
        </div>
    );
}

export default BreakpointView;
