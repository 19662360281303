import { useCreateDrive, useGetDrives, useGetMeSubscription } from '@bpm-web-app/swr-hooks';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Analytics, useApiErrorHandler, useViewport, showToast, useUserSettings, rebuildReactTooltip } from '@bpm-web-app/utils';
import { Collapse } from 'react-collapse';
import { SubscriptionDetailsv4 } from '@bpm-web-app/supreme-api-sdk';
import { NavContext } from './nav.context';
import styles from './nav.module.css';

// Icons
import { ReactComponent as ChevronRight } from '../../assets/icons/chevron-right.svg';
import { NavMyDriveFolders } from '../nav-my-drive-folders/nav-my-drive-folders';
import PlaylistsForm from '../playlists-form/playlists-form';
import { LOCAL_SORT_OPTIONS } from './nav';
import { PlainNavItem } from './plain-nav-item';
import { NavFooter } from './nav-footer';
import { createMyLibraryNavItems, createNavItems } from './nav-menu-items';
import { useOnDragElement } from '../drag-and-drop/drag-and-drop.context';
import { AppLink, ContextMenuClickIgnoreClass, ContextMenuContext, ContextMenuItemProps } from '../shared';
import CreateLogo from '../../assets/icons/create/create-logo-icon.svg';
import { CustomIcon } from '../shared/custom-icon/custom-icon';
import Droppable from '../droppable/droppable';
import SubscriptionPromotion from '../onboarding-modals/subscription-promotion/subscription-promotion';
import { NavHeader } from './nav-header/nav-header';
import { NavToggleButton } from './nav-toggle-button/nav-toggle-button';

export function NavCreate() {
    const router = useRouter();
    const { mutate, data: driveData } = useGetDrives(true, true);

    const { isNavOpen, isNavHidden, openNav, closeNav } = useContext(NavContext);
    const { openContextMenu: handleOpenContextMenu, closeOptions, isOpen } = useContext(ContextMenuContext);
    const { isDragging, type: draggingType } = useOnDragElement();

    const [openContextMenu, setOpenContextMenu] = useState<undefined | { top: number; left: number }>(undefined);
    const [openSortContextMenu, setOpenSortContextMenu] = useState<undefined | { top: number; left: number }>(undefined);
    const [showDriveFolders, setShowDriveFolders] = useState(false);
    const [categorySort, setFolderSort] = useState(undefined);
    const [showCreateForm, setShowCreateForm] = useState<boolean>(false);
    const [openedDrivesOnDrag, setOpenedDrivesOnDrag] = useState<boolean>(false);
    const [openedNavOnDrag, setOpenedNavOnDrag] = useState<boolean>(false);
    const errorHandler = useApiErrorHandler(true);
    const contextMenuRef = useRef<HTMLDivElement>(null);
    const { isMobile } = useViewport();
    const { isAnonymous } = useUserSettings();

    useEffect(() => {
        rebuildReactTooltip();
    }, []);

    useEffect(() => {
        Analytics.platform = 'create';
        Analytics.trackSession('start');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const handleDocClick = (e: MouseEvent) => {
            if (!contextMenuRef?.current || !openContextMenu) return;
            if (contextMenuRef.current !== e.target && !contextMenuRef.current.contains(e.target as Node)) {
                e.preventDefault();
                setOpenContextMenu(undefined);
            }
        };

        document.addEventListener('click', handleDocClick);
        return () => {
            document.removeEventListener('click', handleDocClick);
        };
    }, [openContextMenu]);

    const contextSortMenuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleDocClick = (e: MouseEvent) => {
            if (!contextSortMenuRef?.current || !openSortContextMenu) return;
            if (contextSortMenuRef.current !== e.target && !contextSortMenuRef.current.contains(e.target as Node)) {
                e.preventDefault();
                setOpenSortContextMenu(undefined);
            }
        };

        document.addEventListener('click', handleDocClick);
        return () => {
            document.removeEventListener('click', handleDocClick);
        };
    }, [openSortContextMenu]);

    const createDriveFolder = useCreateDrive();

    const navItems = useMemo(() => createNavItems(), []);
    const myLibraryNavItems = useMemo(() => createMyLibraryNavItems(), []);

    const handleCreateDrive = useCallback(
        async (name: string) => {
            try {
                const response = await createDriveFolder({ name });
                mutate();
                showToast({ type: 'success', message: 'Drive folder created successfully.', buttonText: 'Go To Drive', replaceRouteOnButtonClick: `/my-drive/${response.data.id}` });
            } catch (error) {
                errorHandler({ error, message: 'Failed to create new drive folder' });
            }
        },
        [createDriveFolder, errorHandler, mutate]
    );

    const CreateDriveForm = useMemo(
        () => (
            <PlaylistsForm type="CreateNewDrive" text="Create a personal collection of sounds to help organize your projects." formAction={handleCreateDrive} close={() => setShowCreateForm(false)} />
        ),
        [handleCreateDrive]
    );

    const resetNav = useCallback(() => {
        setShowDriveFolders(false);
        setOpenContextMenu(undefined);
        setOpenSortContextMenu(undefined);
    }, []);

    const createNewFolder = useCallback(() => {
        setShowCreateForm(true);
        setOpenContextMenu(undefined);
        setOpenSortContextMenu(undefined);
    }, []);

    const setSort = useCallback((sortOption) => {
        setFolderSort(sortOption);
    }, []);

    const contextMenuOptions = useCallback(() => {
        const menuOptions: ContextMenuItemProps[] = [
            {
                leftIcon: <CustomIcon type="new-window-icon" hasIconHover />,
                label: 'Go to My Drives',
                onClick: () => {
                    closeOptions();
                    router.push('/my-drive');
                },
            },
            {
                label: 'Create Drive',
                leftIcon: <CustomIcon type="plus-icon" hasIconHover />,
                onClick: () => {
                    closeOptions();
                    Analytics.trackClick('nav_bar', 'create_new_folder', { location: 'nav_my_playlist' });
                    createNewFolder();
                },
            },
            {
                label: 'Sort By…',
                leftIcon: <CustomIcon type="sort-icon" hasIconHover />,
                items: LOCAL_SORT_OPTIONS.map((option) => {
                    return {
                        label: option.label,
                        onClick: () => {
                            closeOptions();
                            setSort(option.value);
                            setShowDriveFolders(true);
                        },
                    };
                }),
            },
        ];
        return menuOptions;
    }, [closeOptions, createNewFolder, router, setSort]);

    const handleOpenThreeDots = (e: React.MouseEvent<HTMLElement>) => {
        const { left, top, width } = e.currentTarget.getBoundingClientRect();
        if (isOpen('my-drives')) {
            closeOptions();
        } else {
            handleOpenContextMenu({ id: 'my-drives', options: contextMenuOptions(), left, top, renderLocationPosition: 'sidebar', align: 'right', buttonWidth: width });
        }
    };

    /* Close nav (if on mobile) + go back to the first level nav on route change. */
    useEffect(() => {
        const handleRouteChange = () => {
            if (isNavOpen && isMobile) closeNav();
        };

        router.events.on('routeChangeStart', handleRouteChange);

        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closeNav, isNavOpen, router.events]);

    useEffect(() => {
        if (isNavOpen && isMobile) {
            document.documentElement.classList.add('stop-scroll');
        } else {
            document.documentElement.classList.remove('stop-scroll');
        }
    }, [isMobile, isNavOpen]);

    const getSortContextMenu = useCallback(
        () =>
            openSortContextMenu === undefined ? null : (
                <div
                    className={classNames(styles['nav__sort-context-menu'], styles['nav__create__sort-context-menu'])}
                    style={isMobile ? {} : { left: openSortContextMenu.left, top: openSortContextMenu.top }}
                >
                    <div ref={contextSortMenuRef}>
                        {LOCAL_SORT_OPTIONS.map((sortOption) => (
                            <button
                                onClick={() => {
                                    setSort(sortOption.value);
                                    setOpenSortContextMenu(undefined);
                                }}
                                key={sortOption.value}
                                type="button"
                                className={styles['nav__context-menu-item']}
                            >
                                {sortOption.label}
                            </button>
                        ))}
                    </div>
                </div>
            ),
        [setSort, openSortContextMenu, isMobile]
    );

    const handleDriveHeaderBack = useCallback(() => {
        if (openContextMenu) {
            setOpenContextMenu(undefined);
            return;
        }

        if (openSortContextMenu) {
            setOpenSortContextMenu(undefined);
            return;
        }

        resetNav();
    }, [openContextMenu, openSortContextMenu, resetNav]);

    const handleShowDriveFolders = useCallback(() => {
        if (driveData && driveData.length === 0) {
            router.push('/my-drive');
            return;
        }
        if (!isNavOpen) openNav();
        setShowDriveFolders(true);
    }, [isNavOpen, driveData, router, openNav]);

    useEffect(() => {
        if (isDragging && !showDriveFolders) {
            if (!isNavOpen) {
                openNav();
                setOpenedNavOnDrag(true);
            }
            if (draggingType === 'Sound') {
                setShowDriveFolders(true);
                setOpenedDrivesOnDrag(true);
            }
        } else if (!isDragging) {
            if (openedDrivesOnDrag) {
                setShowDriveFolders(false);
                setOpenedDrivesOnDrag(false);
            }

            if (openedNavOnDrag && isNavOpen) {
                closeNav();
                setOpenedNavOnDrag(false);
            }
        }
    }, [closeNav, draggingType, isDragging, isNavOpen, openNav, openedDrivesOnDrag, openedNavOnDrag, showDriveFolders]);

    useEffect(() => {
        if (isNavOpen && isMobile) closeNav();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile]);

    const { data } = useGetMeSubscription('create');
    const userSubscriptionInfo = data?.data.membership.has_membership;
    const userSubscriptions = useMemo(() => {
        switch (data?.data.membership.subscription?.membership_type) {
            case (SubscriptionDetailsv4.MembershipTypeEnum.PremiumPlus):
                return 'Premium Plus';
            default:
                return data?.data.membership.subscription?.membership_type;
        }
    }, [data]);
    const isAccountCheckout = router.pathname.includes('/account/plan');

    return (
        <>
            <nav
                data-context-open={openSortContextMenu !== undefined || openContextMenu}
                // eslint-disable-next-line @typescript-eslint/dot-notation
                className={classNames(styles['nav'], styles['nav__create'], {
                    [styles['nav--open']]: isNavOpen,
                    [styles['nav--hidden']]: isNavHidden,
                })}
            >
                {data && data.data.membership.has_membership === false && data.data.membership.trial_applicable && !isAccountCheckout && <SubscriptionPromotion />}
                <div role="button" tabIndex={0} className={styles['nav__menu']}>
                    <NavHeader
                        title="BPM Create"
                        AfterTitle={!isMobile ?
                            <AppLink href="/account/plan">
                                <a>
                                    {userSubscriptionInfo === false ? 'Limited Preview' : userSubscriptions}
                                </a>
                            </AppLink> : null
                        }
                        hasSwitcher={false}>
                        <CreateLogo />
                    </NavHeader>

                    {showCreateForm && CreateDriveForm}

                    {getSortContextMenu()}

                    {!isMobile && (
                        <div className={styles['nav__section']}>
                            <h4 className={classNames(styles['nav__section--title'], { [styles['nav__section--title--closed']]: !isNavOpen })}>Browse</h4>
                        </div>
                    )}
                    {isMobile && !showDriveFolders && (
                        <div className={styles['nav__section']}>
                            <h4 className={classNames(styles['nav__section--title'], { [styles['nav__section--title--closed']]: !isNavOpen })}>Browse</h4>
                        </div>
                    )}
                    {!isMobile &&
                        navItems.map(({ title, icon, activeIcon, path }) => {
                            return <PlainNavItem key={title} title={title} icon={icon} activeIcon={activeIcon} path={path} isDragging={isDragging} disableDragDrop />;
                        })}
                    {isMobile &&
                        !showDriveFolders &&
                        navItems.map(({ title, icon, activeIcon, path }) => {
                            return <PlainNavItem key={title} title={title} icon={icon} activeIcon={activeIcon} path={path} />;
                        })}
                    {!isMobile && (
                        <div className={styles['nav__section']}>
                            <h4 className={classNames(styles['nav__section--title'], { [styles['nav__section--title--closed']]: !isNavOpen })}>Your Library</h4>
                        </div>
                    )}
                    {isMobile && !showDriveFolders && (
                        <div className={styles['nav__section']}>
                            <h4 className={classNames(styles['nav__section--title'], { [styles['nav__section--title--closed']]: !isNavOpen })}>Your Library</h4>
                        </div>
                    )}

                    {showDriveFolders && isMobile && (
                        <>
                            <div className={styles['nav__submenu-header']}>
                                <button type="button" className={classNames(styles['nav__submenu-btn'])} aria-label="Close" onClick={handleDriveHeaderBack}>
                                    <i className={classNames(styles['nav__menu-item--icon'], 'icon', 'icon--chevron-left', 'icon--light')} />
                                    <span>My Drives</span>
                                </button>
                                <div className={classNames(styles['nav__menu-item--three-dots'], styles['nav__menu-item--three-dots--open'])}>
                                    <CustomIcon
                                        type="three-dots"
                                        className={ContextMenuClickIgnoreClass}
                                        hasIconHover
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleOpenThreeDots(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <NavMyDriveFolders sort={categorySort} />
                        </>
                    )}
                    {!isMobile &&
                        myLibraryNavItems.map(({ title, icon, activeIcon, path, mainPath, droppableId }) => {
                            if (isAnonymous) {
                                return <PlainNavItem title={title} icon={icon} activeIcon={activeIcon} path={path} isDragging={isDragging} isDisabled />;
                            }
                            if (droppableId === 'favorites') {
                                return (
                                    <Droppable
                                        key={title}
                                        accept={['Sound', 'Label', 'SoundPack', 'CuratedSet']}
                                        onDrop={(type, items) => {
                                            return { target: droppableId };
                                        }}
                                    >
                                        {(state) => (
                                            <PlainNavItem
                                                key={title}
                                                title={title}
                                                icon={icon}
                                                activeIcon={activeIcon}
                                                path={path}
                                                isDragging={isDragging}
                                                isDraggingOver={state.isOver}
                                                onClick={() => {
                                                    Analytics.trackClick('nav_bar', title, { location: 'nav_bar' });
                                                }}
                                            />
                                        )}
                                    </Droppable>
                                );
                            }

                            return (
                                <Droppable
                                    key={title}
                                    accept="Sound"
                                    onlyHover
                                    onDrop={(type, items) => {
                                        return { target: droppableId };
                                    }}
                                >
                                    {(state) => (
                                        <>
                                            <button
                                                key={title}
                                                type="button"
                                                className={classNames(styles['nav__menu-item'], styles['nav__menu-item--btn'], {
                                                    [styles['nav__menu-item--is-dragging']]: isDragging,
                                                    [styles['nav__menu-item--active']]: router.pathname === mainPath,
                                                })}
                                                onClick={() => {
                                                    if (showDriveFolders) {
                                                        handleDriveHeaderBack();
                                                    } else {
                                                        handleShowDriveFolders();
                                                        Analytics.trackClick('nav_bar', title, { location: 'my_drive_folders' });
                                                    }
                                                }}
                                                data-tip={!isNavOpen ? `${title}` : ''}
                                            >
                                                <i className={classNames(styles['nav__menu-item--icon'])}>{router.pathname === mainPath ? activeIcon : icon}</i>
                                                <span>{title}</span>
                                                {isNavOpen && (
                                                    <div className={classNames(styles['nav__menu-item--three-dots'], { [styles['nav__menu-item--three-dots--open']]: showDriveFolders })}>
                                                        <CustomIcon
                                                            type="three-dots"
                                                            hasIconHover
                                                            className={ContextMenuClickIgnoreClass}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleOpenThreeDots(e);
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                                {driveData && driveData.length > 0 && (
                                                    <div
                                                        tabIndex={0}
                                                        role="button"
                                                        onKeyDown={() => { }}
                                                        className={`${classNames(styles['nav__submenu-btn'], styles['nav__submenu-btn--context-toggle'])}`}
                                                        aria-label="Open or Close List"
                                                        onClick={() => {
                                                            setShowDriveFolders(!showDriveFolders);
                                                        }}
                                                    >
                                                        <ChevronRight className={classNames(styles['nav__menu-item--chevron'], { [styles['nav__menu-item--chevron--open']]: showDriveFolders })} />
                                                    </div>
                                                )}
                                            </button>

                                            <Collapse isOpened={showDriveFolders || state.isOver}>
                                                <div className={classNames(styles['nav__dropdown-container'], { [styles['nav__dropdown-container--open']]: showDriveFolders || state.isOver })}>
                                                    <NavMyDriveFolders sort={categorySort} />
                                                </div>
                                            </Collapse>
                                        </>
                                    )}
                                </Droppable>
                            );
                        })}
                    {isMobile &&
                        !showDriveFolders &&
                        myLibraryNavItems.map(({ title, icon, activeIcon, path, mainPath }) => {
                            if (isAnonymous) {
                                return <PlainNavItem title={title} icon={icon} activeIcon={activeIcon} path={path} isDragging={isDragging} isDisabled />;
                            }
                            if (path) {
                                return (
                                    <PlainNavItem
                                        key={title}
                                        title={title}
                                        icon={icon}
                                        activeIcon={activeIcon}
                                        path={path}
                                        onClick={() => {
                                            Analytics.trackClick('nav_bar', title, { location: 'nav_bar' });
                                        }}
                                    />
                                );
                            }

                            if (!showDriveFolders) {
                                return (
                                    <button
                                        key={title}
                                        type="button"
                                        className={classNames(styles['nav__menu-item'], styles['nav__menu-item--btn'], {
                                            [styles['nav__menu-item--open']]: showDriveFolders,
                                            [styles['nav__menu-item--is-dragging']]: isDragging,
                                        })}
                                        onClick={() => {
                                            handleShowDriveFolders();
                                            Analytics.trackClick('nav_bar', title, { location: 'my_drive_folders' });
                                        }}
                                        data-tip={!isNavOpen ? `${title}` : ''}
                                    >
                                        <i className={classNames(styles['nav__menu-item--icon'])}>{router.pathname === mainPath ? activeIcon : icon}</i>
                                        <span>{title}</span>
                                        <ChevronRight />
                                    </button>
                                );
                            }

                            return null;
                        })}
                </div>
                <NavFooter />
            </nav>

            {!isNavHidden && (
                <NavToggleButton
                    onCloseNav={() => {
                        closeOptions();
                    }}
                />
            )}
        </>
    );
}
