import {
    UserPlaylistApi as DownloadUserPlaylistApi,
    IdBatchBody as DownloadIdBatchBody,
    IdInviteBody,
    UserplaylistinvitesIdBody,
    IdBatchBody1 as DownloadBatchBody,
    UserplaylistsIdBody,
} from '@bpm-web-app/download-api-sdk';
import { IdBatchBody, UserPlaylistApi as StreamUserPlaylistApi, IdBatchBody1 as StreamBatchBody } from '@bpm-web-app/stream-api-sdk';
import { streamConfig, downloadConfig } from '../config';

export class UserPlaylist {
    static streamClient = new StreamUserPlaylistApi(streamConfig);

    static downloadClient = new DownloadUserPlaylistApi(downloadConfig);

    static getClient(isDownload: boolean) {
        return isDownload ? this.downloadClient : this.streamClient;
    }

    static copyUserPlaylist = (playlistId: string, categoryId: number) => UserPlaylist.downloadClient.copyPlaylistToOwn(playlistId, categoryId);

    static getUserPlaylistDetail = (isDownload: boolean, playlistId: string) => UserPlaylist.getClient(isDownload).getUserPlaylistSet(playlistId);

    static getUserPlaylistSets = (isDownload: boolean, include_view_only?: boolean) => UserPlaylist.getClient(isDownload).getUserPlaylistSets(undefined, undefined, include_view_only);

    static getUserPlaylistsFull = (isDownload: boolean, include_view_only?: boolean) => {
        if (isDownload) {
            return (UserPlaylist.getClient(isDownload) as DownloadUserPlaylistApi).getUserPlaylistsFull(undefined, undefined, include_view_only);
        }

        return UserPlaylist.getClient(isDownload).getUserPlaylistSets(undefined, undefined, include_view_only);
    };

    static getUserPlaylistCategories = (isDownload: boolean, include_view_only?: boolean) => UserPlaylist.getClient(isDownload).getUserPlaylistCategories(include_view_only);

    static getUserPlaylistCategory = (id: number | string, isDownload: boolean) => UserPlaylist.getClient(isDownload).getUserPlaylistCategory(`${id}`);

    // eslint-disable-next-line max-len
    static getPlaylistSetsByCatId = (isDownload: boolean, categoryId: number | string, include_view_only?: boolean) =>
        UserPlaylist.getClient(isDownload).getPlaylistSetsByCatId(categoryId as never, include_view_only);

    static createUserPlaylistCategory = (isDownload: boolean, name: string, order: number) => UserPlaylist.getClient(isDownload).createUserPlaylistCategory({ name, order });

    static deleteUserPlaylistCategory = (isDownload: boolean, categoryId: string) => UserPlaylist.getClient(isDownload).deleteUserPlaylistCategory(categoryId);

    static deleteUserPlaylist = (isDownload: boolean, id: string) => UserPlaylist.getClient(isDownload).deleteUserPlaylist(id);

    static createUserPlaylist = (isDownload: boolean, categoryId: number, title: string, order: number) => UserPlaylist.getClient(isDownload).createUserPlaylist(categoryId, { title, order });

    static createUserPlaylistWithoutCategory = (title: string, order: number) => (UserPlaylist.getClient(true) as DownloadUserPlaylistApi).createUserPlaylistWithoutCategory({ title, order });

    static addMediaToUserPlaylist = (id: string, mediaId: number, order?: number) => UserPlaylist.streamClient.addMediaToUserPlaylist(id, mediaId, { order });

    static addAlbumToUserPlaylist = (id: string, albumId: number, order?: number) => UserPlaylist.downloadClient.addAlbumToUserPlaylist(id, albumId, { order });

    static addMultipleMediaToUserPlaylist = (id: string, body: IdBatchBody) => UserPlaylist.streamClient.addMultipleMediaToUserPlaylist(id, body);

    static addMultipleAlbumToUserPlaylist = (id: string, body: DownloadIdBatchBody) => UserPlaylist.downloadClient.addMultipleAlbumsToUserPlaylist(id, body);

    static removeMultipleAlbumsFromUserPlaylist = (id: string, body: DownloadBatchBody) =>
        UserPlaylist.downloadClient.removeMultipleAlbumsFromUserPlaylist(id as unknown as number, body as DownloadBatchBody);

    static removeMultipleMediaFromUserPlaylist = (id: string, body: StreamBatchBody) => UserPlaylist.streamClient.removeMultipleMediaFromUserPlaylist(id as unknown as number, body as StreamBatchBody);

    static updateUserPlaylist = (isDownload: boolean, playlistId: string, body: UserplaylistsIdBody) => UserPlaylist.getClient(isDownload).updateUserPlaylist(playlistId, body);

    static updateUserPlaylistCategory = (isDownload: boolean, categoryId: number, name: string, order: number) =>
        UserPlaylist.getClient(isDownload).updateUserPlaylistCategory(String(categoryId), { name, order });

    static getUserForYouPlaylist = (isDownload: boolean, library: string) =>
        isDownload ? UserPlaylist.downloadClient.getForYouPlaylistSets(library) : UserPlaylist.getClient(isDownload).getForYouPlaylistSets(library);

    static removeTrackFromPlaylist = (isDownload: boolean, playlistId: string, mediaOrAlbumId: number) => {
        if (isDownload) {
            return UserPlaylist.downloadClient.deleteAlbumFromUserPlaylist(playlistId, mediaOrAlbumId);
        }
        return UserPlaylist.streamClient.deleteMediaFromUserPlaylist(playlistId, mediaOrAlbumId);
    };

    static updateMyPlaylistPicture = (playlistId: string, file: Blob) => UserPlaylist.downloadClient.updateUserPlaylistImage(playlistId, file);

    static getUserPlaylistInvites = (playlistId: string) => UserPlaylist.downloadClient.getInvitesForUserPlaylist(playlistId);

    static inviteUserToPlaylist = (playlistId: string, invitee: IdInviteBody) => UserPlaylist.downloadClient.inviteUserToPlaylist(playlistId, invitee);

    static editUserInviteToPlaylist = (inviteId: string, invitee: UserplaylistinvitesIdBody) => UserPlaylist.downloadClient.updateUserInvite(inviteId, invitee);

    static removeUserFromPlaylist = (inviteId: string) => UserPlaylist.downloadClient.removeUserFromPlaylist(inviteId);

    static acceptPlaylistInvite = (inviteId: string) => UserPlaylist.downloadClient.acceptPlaylistInvite(inviteId);

    static declinePlaylistInvite = (inviteId: string) => UserPlaylist.downloadClient.declinePlaylistInvite(inviteId);
}
