import { SearchPaginatedQuery } from '@bpm-web-app/api-client';
import { Album, Album as DownloadAlbum, Media as DownloadMedia, MediaWithAlbum as DownloadMediaWithAlbum, UserPlaylistCollaboration, UserPlaylistWithAlbumOwner } from '@bpm-web-app/download-api-sdk';
import { MediaWithAlbum, Album as StreamAlbum } from '@bpm-web-app/stream-api-sdk';
import { QueueItem, rebuildReactTooltip, useUserSettings, useViewport } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import SortOptionsSheet, { APISortingKeys, LocalSortingKeys, SortOption } from '../../../sort-options-sheet/sort-options-sheet';
import { TrackListItemSelectableProps } from '../track-list-item';
import styles from './track-list-supreme.module.css';

import { DragDropItem } from '../../../droppable/droppable';
import { TrackListGhostLoading } from '../ghost-loading/track-list-ghost-loading';
import { TrackListFilters } from '../track-list-filters/track-list-filters';
import { useAccordions } from './hooks/use-accordions';
import { TrackListItemSupreme } from './track-list-item-supreme';
import { TrackListHeader } from './track-list-supreme-header';
import { TrackListPresetSupreme } from './track-list-supreme-helpers';
import { TrackListLoadingSupreme } from '../ghost-loading/track-list-loading-supreme';

interface TrackListSupremeBaseProps {
    list?: Array<MediaWithAlbum> | Array<DownloadAlbum> | Array<DownloadMediaWithAlbum> | Array<QueueItem>;
    preset: TrackListPresetSupreme;
    album?: StreamAlbum;
    isUserPlaylist?: boolean;
    isSortable?: boolean;
    onSort?: (type: LocalSortingKeys | APISortingKeys) => void;
    selectedSortType?: LocalSortingKeys | APISortingKeys;
    sortOptions?: SortOption<LocalSortingKeys | APISortingKeys | any>[];
    onDownloadRevalidate?: (downloadMedia?: DownloadMedia) => void;
    defaultExpanded?: number;
    trendingStatusToShow?: 'trending_status_weekly' | 'trending_status_daily' | 'trending_status_monthly';
    onFilterApplied?: (filter: { bpm?: number; key?: string; genre?: string }) => void;
    collaborators?: UserPlaylistCollaboration[];
    owner?: UserPlaylistWithAlbumOwner;
    onMove?: (mediaId: number, prevIndex: number, targetIndex: number) => void;
    hasEditAccess?: boolean;
    hasFilters?: boolean;
    showActiveFilters?: boolean;
    defaultFilters?: Partial<SearchPaginatedQuery>;
    isUpdatingList?: boolean;
    isLoading?: boolean;
    resultsCount?: number;
    onFiltersSelected?: () => void;
    hasColumnSorting?: boolean;
    forMaxiPlayer?: boolean;
    /**
     * Used for pagination
     */
    indexOffset?: number;
}

export interface TrackListDraggableProps extends TrackListSupremeBaseProps {
    isSortable?: boolean;
    isDraggable?: boolean;
}

export interface TrackListNonSelectableProps extends TrackListSupremeBaseProps {
    onSelectItem?: never;
    selectedItemsIds?: never;
    onSelectAll?: never;
}

export interface TrackListSelectableProps extends TrackListSupremeBaseProps {
    /** Sets the TrackList with the selectable variant */
    onSelectItem: (mediaId: number | string, index: number) => void;
    selectedItemsIds: string[] | number[];
    onSelectAll: (checked: boolean) => void;
}

/* This type definition is not 100% spot on - the issues with draggable/non-draggable props
 * are not being correctly flagged (a different error gets shown - unrelated to missing/redundant
 * props for a Draggable feature).
 * TODO: figure this out */
export type TrackListSupremeProps = (TrackListNonSelectableProps | Partial<TrackListSelectableProps>) & TrackListDraggableProps;

/* We need to support both albums and a list of tracks.
 * Albums are a bit different as they contain some properties like 'genre'
 * or 'is_exclusive' on the root level (instead of each media having its own field).
 * TODO: potentially make this more consistent */
function isAlbum(album: StreamAlbum | undefined): album is StreamAlbum {
    return album !== undefined;
}

export function TrackListSupreme({
    list,
    preset,
    album,
    isSortable,
    onSelectItem,
    selectedItemsIds,
    onSelectAll,
    isUserPlaylist = false,
    isDraggable,
    onSort,
    onDownloadRevalidate,
    selectedSortType,
    sortOptions,
    defaultExpanded,
    onFilterApplied,
    trendingStatusToShow,
    collaborators,
    owner,
    onMove,
    hasEditAccess,
    hasFilters,
    isUpdatingList,
    isLoading,
    showActiveFilters,
    resultsCount,
    defaultFilters,
    onFiltersSelected,
    forMaxiPlayer,
    indexOffset = 0
}: TrackListSupremeProps) {
    const streamAlbum = album as StreamAlbum;
    const streamTracksList = isAlbum(streamAlbum) ? streamAlbum.media : (list as MediaWithAlbum[]);
    const downloadTracksList = list as DownloadAlbum[];
    const queueTracksList = list as QueueItem[];
    const offlineCrateTracksList = list as DownloadMediaWithAlbum[];
    const [sortVisible, setSortVisible] = useState(false);
    const [sortPosition, setSortPosition] = useState({ left: 0, top: 0 });
    const { setSelectedMedia, setShowSignUpModal } = useUserSettings();

    const { isMobile } = useViewport();

    let tracksList: DownloadAlbum[] | MediaWithAlbum[] | QueueItem[] | DownloadMediaWithAlbum[];
    switch (preset) {
        case TrackListPresetSupreme.Download:
        case TrackListPresetSupreme.DownloadMaxi:
            tracksList = downloadTracksList;
            break;
        case TrackListPresetSupreme.Stream:
        case TrackListPresetSupreme.StreamMaxi:
            tracksList = streamTracksList;
            break;
        case TrackListPresetSupreme.Queue:
            tracksList = queueTracksList;
            break;
        case TrackListPresetSupreme.OnlineCrate:
            tracksList = offlineCrateTracksList;
            break;
        default:
            tracksList = streamTracksList;
            break;
    }

    const setCurrentMediaInContext = useCallback(
        (id: string, showSignUpModal?: boolean) => {
            if (tracksList && tracksList.length > 0) {
                const currentCenterMedia = (tracksList as DownloadAlbum[]).find((item) => `${item.id}` === id);
                if (currentCenterMedia) setSelectedMedia(currentCenterMedia as DownloadAlbum, tracksList as DownloadAlbum[]);

                if (showSignUpModal) {
                    setShowSignUpModal({ type: 'track' });
                }
            }
        },
        [setSelectedMedia, setShowSignUpModal, tracksList]
    );

    useEffect(() => {
        rebuildReactTooltip();
        if ((tracksList as DownloadAlbum[]) && (tracksList as DownloadAlbum[])[0] && (tracksList as DownloadAlbum[])[0].id) setCurrentMediaInContext(`${(tracksList as DownloadAlbum[])[0].id}`, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { openAccordions, onAccordionClick } = useAccordions();

    const selectableProps = useCallback(
        (mediaId: number | string, trackIndex: number) => {
            if (onSelectItem) {
                return {
                    onSelectItem: () => (onSelectItem ? onSelectItem(mediaId, trackIndex) : undefined),
                    isSelected: selectedItemsIds?.map(String).includes(String(mediaId)),
                } as Pick<TrackListItemSelectableProps, 'onSelectItem' | 'isSelected'>;
            }
            return {};
        },
        [onSelectItem, selectedItemsIds]
    );

    const [{ isOver }, dropRef] = useDrop<DragDropItem, any, { isOver: boolean }>({
        accept: preset === TrackListPresetSupreme.Queue ? 'Queue' : 'UserPlaylist-Album',
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
        canDrop: () => {
            return !!isDraggable;
        },
        drop: (data: any) => {
            onMove?.(data.id, data.prevIndex, 0);
        },
    });

    return (
        <div className={classNames(styles['track-list'])}>
            {hasFilters && (
                <TrackListFilters dynamicActiveTabColor showActiveFilters={showActiveFilters} resultsCount={resultsCount} defaultFilters={defaultFilters} onActionSelected={onFiltersSelected} />
            )}
            {isSortable && (
                <SortOptionsSheet
                    leftPosition={sortPosition.left}
                    topPosition={sortPosition.top}
                    selectedAction={selectedSortType}
                    onAction={(actionType) => {
                        setSortVisible(false);
                        onSort?.(actionType);
                    }}
                    visible={sortVisible}
                    setVisible={setSortVisible}
                    options={sortOptions || undefined}
                />
            )}
            <TrackListHeader
                ref={dropRef}
                isSortable={isSortable}
                isOver={isOver}
                preset={preset}
                selectedItemsIds={selectedItemsIds}
                list={tracksList}
                onSelectAll={onSelectAll}
                onSelectItem={onSelectItem}
                setSortPosition={setSortPosition}
                setSortVisible={setSortVisible}
                selectedSortType={selectedSortType}
                sortOptions={sortOptions}
            />
            {isLoading || isUpdatingList ? <TrackListLoadingSupreme showHeader={false} preset={preset} amount={10} /> : null}

            {!isLoading && tracksList?.length > 0
                ? (tracksList?.map((media, trackIndex) => {
                    const collaborator = collaborators?.find((current) => current.id === (media as DownloadAlbum).added_by_key);

                    return (
                        <TrackListItemSupreme
                            indexOffset={indexOffset}
                            hasPremiumOnlyAccess={(media as Album).has_premium_only_access}
                            isPremiumOnly={(media as Album).is_premium_only}
                            setCurrentMediaInContext={setCurrentMediaInContext}
                            key={media.id}
                            media={media}
                            collaborator={collaborator}
                            owner={owner}
                            isSharedPlaylist={collaborators && collaborators.length > 0}
                            hasEditAccess={isUserPlaylist || (hasEditAccess && collaborator?.is_own)}
                            addedAt={(media as DownloadAlbum).added_at}
                            preset={preset}
                            streamTracksList={streamTracksList}
                            downloadTracksList={downloadTracksList}
                            trackIndex={trackIndex}
                            streamAlbum={preset === TrackListPresetSupreme.Download ? undefined : album}
                            isUserPlaylist={isUserPlaylist}
                            onDownloadRevalidate={onDownloadRevalidate}
                            defaultExpanded={defaultExpanded}
                            onFilterApplied={onFilterApplied}
                            trendingStatusToShow={trendingStatusToShow}
                            isDraggable={isDraggable}
                            onMove={(prevIndex, mediaId) => {
                                let newIndex = trackIndex;
                                if (trackIndex < prevIndex) {
                                    newIndex += 1;
                                }
                                  onMove?.(mediaId, prevIndex, newIndex);
                            }}
                            {...selectableProps(preset === TrackListPresetSupreme.Queue ? (media as QueueItem).uuid : media.id, trackIndex)}
                            forMaxiPlayer={forMaxiPlayer}
                            openAccordions={openAccordions}
                            onAccordionClick={onAccordionClick}
                        />
                    );
                }))
                : null}
        </div>
    );
}
