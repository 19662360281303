// eslint-disable-next-line max-classes-per-file
import { CuratedApi, CuratedSetApi } from '@bpm-web-app/create-api-sdk';
import { createConfig } from '../../config';
import { GenericPaginatedQuery, CreateSearchPaginatedQuery } from '../../typings';

export class CuratedSet {
    static client = new CuratedSetApi(createConfig);

    static getCuratedSet = (idOrSlug: string) => CuratedSet.client.getCuratedSet(idOrSlug);

    static getNewestCuratedSets = (args?: CreateSearchPaginatedQuery) =>
        CuratedSet.client.getNewestCuratedSets(
            args?.limit,
            args?.skip,
            args?.search,
            args?.bpm_max,
            args?.bpm_min,
            args?.key as string,
            args?.keyType,
            args?.type,
            args?.tags,
            args?.tag_groups,
            args?.artists,
            args?.subgenre,
            args?.label,
            args?.sound_package_id,
            args?.drive_id,
            args?.genre as string,
            args?.showHalfTime,
            args?.suggested,
            args?.hide_prev_played,
            args?.hide_prev_downloaded,
            args?.only_free,
            args?.tag_categories,
            args?.trending,
            args?.synth
        );

    static getFreeCuratedSets = (args?: CreateSearchPaginatedQuery) =>
        CuratedSet.client.getFreeCuratedSets(
            args?.limit,
            args?.skip,
            args?.search,
            args?.bpm_max,
            args?.bpm_min,
            args?.key as string,
            args?.keyType,
            args?.type,
            args?.tags,
            args?.tag_groups,
            args?.artists,
            args?.subgenre,
            args?.label,
            args?.sound_package_id,
            args?.drive_id,
            args?.genre as string,
            args?.showHalfTime,
            args?.suggested,
            args?.hide_prev_played
        );

    static getCuratedSetsByCategory = (args?: GenericPaginatedQuery) => CuratedSet.client.getCuratedSetsByCategory(args?.limit, args?.skip);

    static followCuratedSet = (id: string) => CuratedSet.client.followCuratedSet(id);

    static unfollowCuratedSet = (id: string) => CuratedSet.client.unfollowCuratedSet(id);

    static getFavoritedCuratedSets = (args?: CreateSearchPaginatedQuery) =>
        CuratedSet.client.getFavoritedCuratedSets(
            args?.limit,
            args?.skip,
            args?.search,
            args?.bpm_max,
            args?.bpm_min,
            args?.key as string,
            args?.keyType,
            args?.type,
            args?.tags,
            args?.tag_groups,
            args?.artists,
            args?.subgenre,
            args?.label,
            args?.sound_package_id,
            args?.drive_id,
            args?.genre as string,
            args?.showHalfTime,
            args?.suggested,
            args?.hide_prev_played,
            args?.hide_prev_downloaded,
            args?.only_free,
            args?.tag_categories,
            args?.trending,
            args?.synth
        );

    static searchCuratedSets = (args?: CreateSearchPaginatedQuery) =>
        CuratedSet.client.searchCuratedSets(
            args?.limit,
            args?.skip,
            args?.search,
            args?.bpm_max,
            args?.bpm_min,
            args?.key as string,
            args?.keyType,
            args?.type,
            args?.tags,
            args?.tag_groups,
            args?.artists,
            args?.subgenre,
            args?.label,
            args?.sound_package_id,
            args?.drive_id,
            args?.genre as string,
            args?.showHalfTime,
            args?.suggested,
            args?.hide_prev_played,
            args?.hide_prev_downloaded,
            args?.only_free,
            args?.tag_categories,
            args?.trending,
            args?.synth
        );

    static downloadCuratedSet = (id: string, location: string) => CuratedSet.client.downloadCuratedSet(id, location);
}

export class Curated {
    static client = new CuratedApi(createConfig);

    static downloadCuratedSetPreview = (id: string) => Curated.client.downloadCuratedSetPreview(id);
}
