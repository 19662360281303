interface QueryParam {
    key: string;
    value: string | number;
}

export const appendQueryParams = (url?: string, ...queryParams: QueryParam[]) => {
    if (!url) return '';

    const urlObj = new URL(url);

    queryParams.forEach((queryParam) => {
        urlObj.searchParams.append(queryParam.key, queryParam.value?.toString());
    });

    return urlObj.href;
};

export default function objectToGetParams(object: { [key: string]: string | number | undefined | null }) {
    const params = Object.entries(object)
        .filter(([, value]) => value !== undefined && value !== null)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);

    return params.length > 0 ? `?${params.join('&')}` : '';
}
