import { TrendingPeriod } from '@bpm-web-app/api-client';
import { Genre } from '@bpm-web-app/create-api-sdk';
import pluralize from 'pluralize';

/**
 * Returns the count and the term
 * @param count the number of something
 * @param singular term
 * @returns `1 track` or `2 tracks`
 */
export const convertToPluralIfNeeded = (count: number, singular: string, plural?: string, flip = false): string => {
    if (count === 1) {
        return flip ? `${singular} (${count})` : `${count} ${singular}`;
    }
    return flip ? `${plural || `${pluralize(singular)}`} (${(count || 0).toLocaleString()})` : `${(count || 0).toLocaleString()} ${plural || `${pluralize(singular)}`}`;
};

export const convertTopGenresToString = (genres: Genre[] | undefined): string => {
    if (!genres) {
        return '';
    }

    return genres
        .slice(0, 3)
        .map((tag) => tag.name)
        .join(' / ');
};

export function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function convertPeriodIntoTimeframes(period: TrendingPeriod) {
    switch (period) {
        case 'daily':
            return 'Past 24 Hours';
        case 'weekly':
            return 'Past 7 Days';
        case 'monthly':
            return 'Past 30 Days';
        default:
            return '';
    }
}

export function convertToTitleCase(string: string) {
    return string
        .toLowerCase()
        .split(' ')
        .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}
