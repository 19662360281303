/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import { AlbumWithAnalytics, ArtistAlbumsSortByQueryOptions, ArtistSortByQueryOptions, Album as DownloadAlbum } from '@bpm-web-app/download-api-sdk';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Collapse } from 'react-collapse';
import {
    formatBigNumber,
    formatDateToString,
    generateArtistLinks,
    showToast,
    useApiErrorHandler,
    downloadAlbumWithMediaToQueueItem,
    usePageDetails,
    State,
    usePlayerState,
    Analytics,
    rebuildReactTooltip,
} from '@bpm-web-app/utils';
import { ArtistPortal } from '@bpm-web-app/api-client';
import styles from './artist-portal-list.module.css';
import ChevronRight from '../../assets/icons/chevron-right.svg';
import SupremeActionLogo from '../../assets/icons/artist-platform/supreme-action-logo.svg';
import LinkIcon from '../../assets/icons/artist-platform/link.svg';
import ReportTrack from '../../assets/icons/report-track.svg';
import { ArtistPortalListPreset, columnDefinitions, ColumnType } from './artist-portal-list-helpers';
import TitleColumn from '../shared/track-list/columns/title-column';
import { AppLink } from '../shared/app-link/app-link';
import { ActionModal } from '../shared/action-modal/action-modal';
import { ArtistApplicationInputField } from '../pages/artist-portal/artist-application/artist-application-inputfield/artist-application-inputfield';
import { usePlayer } from '../player-context';
import { BreakpointView, ContextMenuClickIgnoreClass, ContextMenuContext, ContextMenuItemProps } from '../shared';
import { CustomIcon } from '../shared/custom-icon/custom-icon';

interface ArtistPortalAlbumListItemGenericProps {
    trackIndex: number;
    preset: ArtistPortalListPreset;
    album: AlbumWithAnalytics;
    sortType?: ArtistSortByQueryOptions | ArtistAlbumsSortByQueryOptions;
}

export type ArtistPortalAlbumListItemProps = ArtistPortalAlbumListItemGenericProps;

// eslint-disable-next-line @typescript-eslint/dot-notation
const SUPREME_URL = process.env['NEXT_PUBLIC_SUPREME_PLATFORM_URL'];

export function ArtistPortalAlbumListItem({
    preset,
    trackIndex,
    album,
    sortType
}: ArtistPortalAlbumListItemProps) {
    const [openDownloadAccordions, setOpenDownloadAccordions] = useState<number[]>([]);
    const [showReportTrack, setShowReportTrack] = useState<boolean>(false);
    const [reportTrackInput, setReportTrackInput] = useState<string>('');
    const [reportTrackInputError, setReportTrackInputError] = useState<string>(undefined);
    const { resource } = usePageDetails();
    const { setQueue, currentTrack, togglePlayPause, originalListDetails } = usePlayer();
    const playerState = usePlayerState();
    const isCurrentlyPlaying = useCallback((mediaID: number) => currentTrack?.id === mediaID && playerState === State.Playing, [currentTrack?.id, playerState]);
    const isCurrentlyPlayingAlbum = useCallback((albumID: number) => `${originalListDetails?.identifier}` === `${albumID}` && playerState === State.Playing, [originalListDetails?.identifier, playerState]);
    const { openContextMenu, closeOptions, isOpen } = useContext(ContextMenuContext);

    const handlePlaySongs = useCallback((index: number) => {
        if (album.media[index].id === currentTrack?.id) {
            togglePlayPause();
        } else {
            setQueue([downloadAlbumWithMediaToQueueItem(album as DownloadAlbum, index)], 0, { identifier: `${album.id}`, resource });
        }
    }, [album, currentTrack?.id, resource, setQueue, togglePlayPause]);

    const errorHandler = useApiErrorHandler();

    const onAccordionClick = useCallback(() => {
        const downloadAlbumId = album.id;
        let newOpenDownloadAccordions = [...openDownloadAccordions, downloadAlbumId];

        if (openDownloadAccordions.includes(downloadAlbumId)) {
            newOpenDownloadAccordions = newOpenDownloadAccordions.filter((id) => id !== downloadAlbumId);
        }
        setOpenDownloadAccordions(newOpenDownloadAccordions);
    }, [album, openDownloadAccordions]);

    const isDownloadAccordionOpen = useMemo(() => openDownloadAccordions.includes(album.id), [album.id, openDownloadAccordions]);

    const albumUrl = useMemo(() => `${SUPREME_URL}d/album/${album.id}?library=artist`, [album]);

    const copyUrl = useCallback(() => {
        Analytics.trackClick('share_album', album.id.toString(), { location: 'artist_portal_album_list_item' });
        navigator.clipboard?.writeText(albumUrl);
        showToast({ type: 'success', message: 'Copied to clipboard.' });
    }, [album.id, albumUrl]);

    const handleReportAlbum = useCallback(async () => {
        if (reportTrackInput === '') {
            setReportTrackInputError('Please enter a description.');
        } else {
            try {
                await ArtistPortal.reportAlbum(album.id, reportTrackInput);
                Analytics.trackClick('report_album', album.id.toString(), { location: 'artist_portal_album_list_item' });
                showToast({ type: 'success', title: 'Reported Album successfully' });
            } catch (error) {
                errorHandler({ error });
            }
            setShowReportTrack(false);
        }
    }, [album.id, errorHandler, reportTrackInput]);

    useEffect(() => {
        rebuildReactTooltip();
    }, []);

    const reportTrackInputComponent = useCallback(() => {
        return <ArtistApplicationInputField
            multiline
            placeholder="Describe issue here"
            setText={(text) => {
                setReportTrackInput(text);
                setReportTrackInputError(undefined);
            }}
            errorMessage={reportTrackInputError} />;
    }, [setReportTrackInput, setReportTrackInputError, reportTrackInputError]);

    const contextMenuOptions: ContextMenuItemProps[] = [
        {
            label: 'Open Album on BPM Supreme',
            leftIcon: <SupremeActionLogo />,
            onClick: () => {
                closeOptions();
                window.open(albumUrl, '_blank');
            }
        },
        {
            label: 'Copy Link to Album',
            leftIcon: <LinkIcon />,
            onClick: () => {
                closeOptions();
                copyUrl();
            }

        },
        {
            type: 'divider',
        },
        {
            leftIcon: <ReportTrack />,
            label: 'Report Album',
            onClick: () => {
                closeOptions();
                setShowReportTrack(true);
            },
        }
    ];

    const handleOpenThreeDots = (e: React.MouseEvent<HTMLElement>) => {
        const { left, top, width } = e.currentTarget.getBoundingClientRect();
        if (isOpen(album.id)) {
            closeOptions();
        } else {
            openContextMenu({ id: album.id, options: contextMenuOptions, left, top, renderLocationPosition: 'app', align: 'left', buttonWidth: width });
        }
    };

    const actionButtons = useMemo(() => {
        return (
            <div className={styles['artist-portal-list__action-btn-container']}>
                <button
                    aria-label="Open Album on BPM Supreme"
                    type="button"
                    data-tip="Open Album on BPM Supreme"
                    className={styles['artist-portal-list__action-btn-desktop']}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        // eslint-disable-next-line @typescript-eslint/dot-notation
                        window.open(albumUrl, '_blank');
                    }}
                >
                    <SupremeActionLogo />
                </button>
                <button
                    aria-label="Copy Link to Album"
                    data-tip="Copy Link to Album"
                    type="button"
                    className={styles['artist-portal-list__action-btn-desktop']}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        copyUrl();
                    }}
                >
                    <LinkIcon />
                </button>
                <button
                    aria-label="Report Album"
                    data-tip="Report Album"
                    type="button"
                    className={styles['artist-portal-list__action-btn-desktop']}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowReportTrack(true);
                    }}
                >
                    <ReportTrack />
                </button>
            </div>
        );
    }, []);

    return (
        <div>
            <ActionModal
                headerTitle="Report track"
                onClose={() => {
                    setShowReportTrack(false);
                }}
                subtitle="A member of our quality control team will review your report and respond with next steps within 72 hours."
                onConfirm={handleReportAlbum}
                confirmButtonText="Submit Report"
                title="Please describe in detail what the issue with this track is."
                renderInput={reportTrackInputComponent}
                isOpen={showReportTrack}
                variant="dark"
            />
            <div
                className={classNames(styles['artist-portal-list__list-item'], styles[`artist-portal-list__list-item--${preset}`])}
            >
                {columnDefinitions[preset]?.map(({ type }, index) => {
                    let isCurrentSortType = false;
                    let column;
                    switch (type) {
                        case ColumnType.Count:
                            column = trackIndex + 1;
                            break;
                        case ColumnType.TitleWithoutArtist:
                            column = (
                                <TitleColumn
                                    coverUrl={album.cover_url}
                                    isPlayable={false}
                                    title={album.title}
                                    isExclusive={false}
                                    hasThreeLinesOnMobile={false}
                                />
                            );
                            break;
                        case ColumnType.Title:
                            column = (
                                <TitleColumn
                                    coverUrl={album.cover_url}
                                    title={album.title}
                                    subtitle={generateArtistLinks(
                                        album.artist,
                                        album.artists,
                                        // eslint-disable-next-line react/no-unstable-nested-components
                                        (a) => {
                                            return (
                                                <AppLink href={`${SUPREME_URL}d/artist/${a.slug}`} key={a.id}>
                                                    <a
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                        }}
                                                        className="underline-link"
                                                    >
                                                        {a.name}
                                                    </a>
                                                </AppLink>
                                            );
                                        },
                                        // eslint-disable-next-line react/no-unstable-nested-components
                                        (sep, i) => (
                                            <span key={i}>{sep}</span>
                                        )
                                    )}
                                    isExclusive={album.is_exclusive}
                                    hasThreeLinesOnMobile={false}
                                    isPlayable
                                    isPlaying={isCurrentlyPlayingAlbum(album.id)}
                                    playClick={() => handlePlaySongs(0)}
                                />
                            );

                            break;
                        case ColumnType.Downloads:
                            column = formatBigNumber(album.analytics_info.downloads);
                            if (sortType === ArtistSortByQueryOptions.Downloads || sortType === ArtistAlbumsSortByQueryOptions.Downloads) isCurrentSortType = true;
                            break;
                        case ColumnType.Previews:
                            column = formatBigNumber(album.analytics_info.previews);
                            if (sortType === ArtistSortByQueryOptions.Previews || sortType === ArtistAlbumsSortByQueryOptions.Previews) isCurrentSortType = true;
                            break;
                        case ColumnType.EstReach:
                            column = formatBigNumber(album.analytics_info.est_reach);
                            break;
                        case ColumnType.Impressions:
                            column = formatBigNumber(album.analytics_info.impressions);
                            if (sortType === ArtistSortByQueryOptions.Impressions || sortType === ArtistAlbumsSortByQueryOptions.Impressions) isCurrentSortType = true;
                            break;
                        case ColumnType.Date:
                            column = formatDateToString(album.release_date);
                            if (sortType === ArtistAlbumsSortByQueryOptions.ReleaseDate) isCurrentSortType = true;
                            break;
                        case ColumnType.Accordion:
                            column = (
                                <button
                                    className={classNames(styles['artist-portal-list__download-accordion-trigger'], {
                                        [styles['artist-portal-list__download-accordion-trigger--open']]: isDownloadAccordionOpen,
                                    })}
                                    type="button"
                                    aria-label={`${album.name} - versions`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onAccordionClick();
                                    }}
                                >
                                    <ChevronRight />
                                </button>
                            );
                            break;
                        case ColumnType.Actions:
                            if (sortType) {
                                column = (
                                    <BreakpointView
                                        desktopChildren={actionButtons}
                                        mobileChildren={
                                            <CustomIcon
                                                type="three-dots"
                                                className={ContextMenuClickIgnoreClass}
                                                hasIconHover
                                                onClick={handleOpenThreeDots} />

                                        } />
                                );
                            } else {
                                column = (actionButtons);
                            }
                            break;
                        case ColumnType.Empty:
                        default:
                            column = '';
                            break;
                    }
                    return (
                        <div
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            className={classNames(styles['artist-portal-list__column'], styles[`artist-portal-list__column--${type}`], { [styles[`artist-portal-list__column--${type}--visible-type`]]: isCurrentSortType }, styles[`artist-portal-list__column--${type}--${preset}`])}
                        >
                            {column}
                        </div>
                    );
                })}
            </div>
            {/* Download accordion content */}
            <Collapse isOpened={openDownloadAccordions.includes(album.id)}>
                {album.media.map((track, index) => {
                    return (
                        <div
                            key={track.id}
                            className={classNames(styles['artist-portal-list__list-item--download'], styles['artist-portal-list__download-accordion'], styles['artist-portal-list__list-item--download-accordion'])}
                        >
                            <span />
                            <div className={classNames(styles['artist-portal-list__column'], styles['artist-portal-list__column--title'], styles['artist-portal-list__track-container'])}>
                                <TitleColumn
                                    title={track.version.name}
                                    isPlayable
                                    isPlaying={isCurrentlyPlaying(track.id)}
                                    hasPlainPlayButton
                                    isExclusive={false}
                                    hasThreeLinesOnMobile={false}
                                    playClick={() => handlePlaySongs(index)}
                                />

                            </div>
                        </div>
                    );
                })}
            </Collapse>
        </div>
    );
}

/* eslint-enable jsx-a11y/no-static-element-interactions */
/* eslint-enable jsx-a11y/click-events-have-key-events */
/* eslint-enable jsx-a11y/anchor-is-valid */
