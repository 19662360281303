import Router, { useRouter } from 'next/router';
import { ReactNode, createContext, useState, useMemo, useContext, useCallback, useRef, useEffect } from 'react';
import { KeyboardKeyType, KeyboardScale, useDebouncedCallback, useDeepCompareEffect, useHubSwitch } from '@bpm-web-app/utils';
import { AudioFileType } from '@bpm-web-app/create-api-sdk';
import { ParsedUrlQuery } from 'querystring';
import { NavContext } from '../nav/nav.context';

export type FilterInputType = 'wheel' | 'keyboard';

export type FiltersSupremeTypes = 'Genre' | 'BPM' | 'Key' | 'Showing' | 'Version' | 'Hide Explicit' | 'Hide Remixes' | 'Hide Exclusives';

export interface FilterActiveItem {
    value: string;
    type: FiltersSupremeTypes;
}

export interface FiltersContextProperties {
    isFiltersOpen: boolean;
    openFilters: () => void;
    closeFilters: () => void;
    resetFilters: () => void;
    updateFiltersFromQuery: (query: ParsedUrlQuery) => void;
    versions: string[];
    hideRemixes: boolean;
    hideExplicit: boolean;
    hideExclusives: boolean;
    hidePrevDownloaded: boolean;
    hidePrevPlayed: boolean;
    bpm: [number, number];
    setBpm: (bpm: [number, number]) => void;
    setHideRemixes: (hide: boolean) => void;
    setHideExplicit: (hide: boolean) => void;
    setHideExclusives: (hide: boolean) => void;
    setHidePrevDownloaded: (hide: boolean) => void;
    setHidePrevPlayed: (hide: boolean) => void;
    setVersions: (versions: string[]) => void;
    key: string[];
    setKey: (key: string[]) => void;
    genres: string[];
    setGenres: (genres: string[]) => void;
    subGenres: string[];
    setSubGenres: (subGenres: string[]) => void;
    tags: string[];
    setTags: (tags: string[]) => void;
    tagGroups: string[];
    setTagGroups: (tags: string[]) => void;
    inputType: FilterInputType;
    setInputType: (inputType: FilterInputType) => void;
    scale: KeyboardScale;
    setScale: (inputType: KeyboardScale) => void;
    keyType: KeyboardKeyType;
    setKeyType: (inputType: KeyboardKeyType) => void;
    fileType?: AudioFileType
    setFileType: (type?: AudioFileType) => void;
    synth?: string
    setSynth: (synth?: string) => void;
    activeFilters?: FilterActiveItem[];
    setActiveFilters?: (filters: FilterActiveItem[]) => void;
    removeActiveFilterByValue: (value: string, label: string, type?: FiltersSupremeTypes) => void;
}

const DEFAULT_INPUT_TYPE_CREATE: FiltersContextProperties['inputType'] = 'keyboard';
const DEFAULT_INPUT_TYPE_SUPREME: FiltersContextProperties['inputType'] = 'wheel';
const DEFAULT_SCALE: FiltersContextProperties['scale'] = 'major';
const DEFAULT_KEYBOARD_KEY_TYPE: FiltersContextProperties['keyType'] = 'sharps';
const DEFAULT_BPM_VALUES: FiltersContextProperties['bpm'] = [0, 250];

export const FiltersContext = createContext<FiltersContextProperties>({
    isFiltersOpen: false,
    openFilters: () => { },
    closeFilters: () => { },
    resetFilters: () => { },
    updateFiltersFromQuery: () => { },
    versions: [],
    hideRemixes: false,
    hideExclusives: false,
    hideExplicit: false,
    hidePrevDownloaded: false,
    hidePrevPlayed: false,
    bpm: [0, 250],
    setBpm: () => { },
    setHideRemixes: () => { },
    setHideExclusives: () => { },
    setHideExplicit: () => { },
    setHidePrevDownloaded: () => { },
    setVersions: () => { },
    setHidePrevPlayed: () => { },
    key: [],
    setKey: () => { },
    genres: [],
    setGenres: () => { },
    subGenres: [],
    setSubGenres: () => { },
    tags: [],
    setTags: () => { },
    tagGroups: [],
    setTagGroups: () => { },
    inputType: DEFAULT_INPUT_TYPE_SUPREME,
    setInputType: () => { },
    scale: DEFAULT_SCALE,
    setScale: () => { },
    keyType: DEFAULT_KEYBOARD_KEY_TYPE,
    setKeyType: () => { },
    setFileType: () => { },
    setSynth: () => { },
    activeFilters: [],
    setActiveFilters: () => { },
    removeActiveFilterByValue: () => { },
});

export interface FiltersProviderProps {
    children: ReactNode;
    initialState?: {
        isFiltersOpen: boolean;
        versions: string[];
        hideRemixes: boolean;
        bpm: [number, number];
        key: string[];
        genres: string[];
        tags: string[];
        activeFilters: FilterActiveItem[]
    };
}

const stringToArray = (param: string | string[]) => (Array.isArray(param) ? param : [param]);

export function FiltersProvider({ children, initialState }: FiltersProviderProps) {
    const { isCreate } = useHubSwitch();
    const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(initialState?.isFiltersOpen || false);
    const [activeFilters, setActiveFilters] = useState<FilterActiveItem[]>([]);
    const [versions, setVersions] = useState<string[]>([]);
    const [hideRemixes, setHideRemixes] = useState<boolean>(false);
    const [hideExclusives, setHideExclusives] = useState<boolean>(false);
    const [hideExplicit, setHideExplicit] = useState<boolean>(false);
    const [hidePrevDownloaded, setHidePrevDownloaded] = useState<boolean>(false);
    const [hidePrevPlayed, setHidePrevPlayed] = useState<boolean>(false);
    const [bpm, setBpm] = useState<[number, number]>(DEFAULT_BPM_VALUES);
    const [key, setKey] = useState<string[]>([]);
    const [genres, setGenres] = useState<string[]>([]);
    const [subGenres, setSubGenres] = useState<string[]>([]);
    const [tags, setTags] = useState<string[]>([]);
    const [tagGroups, setTagGroups] = useState<string[]>([]);
    const [inputType, setInputType] = useState<FilterInputType>(isCreate ? DEFAULT_INPUT_TYPE_CREATE : DEFAULT_INPUT_TYPE_SUPREME);
    const [scale, setScale] = useState<KeyboardScale>(DEFAULT_SCALE);
    const [keyType, setKeyType] = useState<KeyboardKeyType>(DEFAULT_KEYBOARD_KEY_TYPE);
    const [fileType, setFileType] = useState<AudioFileType>();
    const [synth, setSynth] = useState<string>();
    const firstUpdate = useRef(true);
    const router = useRouter();

    const updateSearchParams = useCallback((query: any) => {
        Router.replace({
            pathname: Router.pathname,
            query,
        }, undefined, { scroll: false });
    }, []);

    const updateSearchParamsDebounced = useDebouncedCallback(updateSearchParams, 500);

    const loadFiltersFromQuery = useCallback((query: ParsedUrlQuery) => {
        let initialVersions: string[] = [];
        let initialHideRemixes = false;
        let initialHideExclusives = false;
        let initialHideExplicit = false;
        let initialHidePrevDownloaded = false;
        let initialHidePrevPlayed = false;
        const initialBpm: [number, number] = [0, 250];
        let initialKeys: string[] = [];
        let initialGenres: string[] = [];
        let initialSubGenres: string[] = [];
        let initialTags: string[] = [];
        let initialTagGroups: string[] = [];
        let initialSynth: string | undefined;
        const initialActiveFilters: FilterActiveItem[] = [];

        const {
            versions: queryVersions,
            hideRemixes: queryHideRemixes,
            hideExclusives: queryHideExclusives,
            hideExplicit: queryHideExplicit,
            hidePrevDownloaded: queryHidePrevDownloaded,
            hidePrevPlayed: queryHidePrevPlayed,
            bpm: queryBpm,
            key: queryKeys,
            genre: queryGenres,
            subgenre: querySubGenres,
            tags: queryTags,
            tagGroups: queryTagGroups,
            synth: querySynth
        } = query;

        if (queryVersions) {
            const versionsArray = stringToArray(queryVersions);
            initialVersions = versionsArray;
            versionsArray.forEach((item) => {
                initialActiveFilters.push({ value: item, type: 'Version' });
            });
        }

        if (queryHideRemixes) {
            const shouldHideRemixes = JSON.parse(queryHideRemixes as string);

            initialHideRemixes = shouldHideRemixes;
            if (shouldHideRemixes) initialActiveFilters.push({ value: 'Hide Remixes', type: 'Hide Remixes' });
        }

        if (queryHideExclusives) {
            const shouldHideExclusives = JSON.parse(queryHideExclusives as string);

            initialHideExclusives = shouldHideExclusives;
            if (shouldHideExclusives) initialActiveFilters.push({ value: 'Hide Exclusives', type: 'Hide Exclusives' });
        }

        if (queryHideExplicit) {
            const shouldHideExplicit = JSON.parse(queryHideExplicit as string);

            initialHideExplicit = shouldHideExplicit;
            if (shouldHideExplicit) initialActiveFilters.push({ value: 'Hide Explicit', type: 'Hide Explicit' });
        }

        if (queryHidePrevDownloaded) {
            initialHidePrevDownloaded = JSON.parse(queryHidePrevDownloaded as string);
        }

        if (queryHidePrevPlayed) {
            initialHidePrevPlayed = JSON.parse(queryHidePrevPlayed as string);
        }

        if (queryBpm) {
            initialBpm[0] = Number(queryBpm[0]);
            initialBpm[1] = Number(queryBpm[1]);
            initialActiveFilters.push({ value: `${Number(queryBpm[0])}-${Number(queryBpm[1])} BPM`, type: 'BPM' });
        }

        if (queryKeys) {
            const keysArray = stringToArray(queryKeys);

            initialKeys = stringToArray(queryKeys);

            keysArray.forEach((item) => {
                initialActiveFilters.push({ value: item, type: 'Key' });
            });
        }

        if (queryGenres) {
            const genresArray = stringToArray(queryGenres);

            initialGenres = stringToArray(queryGenres);

            genresArray.forEach((item) => {
                initialActiveFilters.push({ value: item, type: 'Genre' });
            });
        }

        if (queryTags) {
            initialTags = stringToArray(queryTags);
        }

        if (querySubGenres) {
            initialSubGenres = stringToArray(querySubGenres);
        }

        if (queryTagGroups) {
            initialTagGroups = stringToArray(queryTagGroups);
        }

        if (querySynth) {
            initialSynth = querySynth as string;
        }
        setVersions(initialVersions);
        setHideRemixes(initialHideRemixes);
        setHideExclusives(initialHideExclusives);
        setHideExplicit(initialHideExplicit);
        setHidePrevDownloaded(initialHidePrevDownloaded);
        setHidePrevPlayed(initialHidePrevPlayed);
        setBpm(initialBpm);
        setKey(initialKeys);
        setGenres(initialGenres);
        setSubGenres(initialSubGenres);
        setTags(initialTags);
        setTagGroups(initialTagGroups);
        setSynth(initialSynth);
        setActiveFilters(initialActiveFilters);
    }, []);

    useEffect(() => {
        loadFiltersFromQuery(router.query);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.pathname]);

    useDeepCompareEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        /* TODO: potentially add typing for a query with filter params */
        const query = {
            ...router.query,
            versions,
            key,
            genre: genres,
            keyType,
            tags,
            subgenre: subGenres,
            tagGroups,
        } as any;

        if (keyType !== DEFAULT_KEYBOARD_KEY_TYPE) {
            query.keyType = keyType;
        } else {
            delete query.keyType;
        }

        if (!isCreate) {
            if (hideRemixes) {
                query.hideRemixes = '1';
            } else {
                delete query.hideRemixes;
            }
            if (hideExclusives) {
                query.hideExclusives = '1';
            } else {
                delete query.hideExclusives;
            }
            if (hideExplicit) {
                query.hideExplicit = '1';
            } else {
                delete query.hideExplicit;
            }
            if (hidePrevDownloaded) {
                query.hidePrevDownloaded = '1';
            } else {
                delete query.hidePrevDownloaded;
            }
        } else {
            if (hidePrevPlayed) {
                query.hidePrevPlayed = '1';
            } else {
                delete query.hidePrevPlayed;
            }
            if (hidePrevDownloaded) {
                query.hidePrevDownloaded = '1';
            } else {
                delete query.hidePrevDownloaded;
            }
            if (fileType) {
                query.fileType = fileType;
            } else {
                delete query.fileType;
            }
            if (synth) {
                query.synth = synth;
            } else {
                delete query.synth;
            }
        }

        if (bpm[0] === 0 && bpm[1] === 250) {
            delete query.bpm;
        } else {
            query.bpm = bpm;
        }

        updateSearchParamsDebounced(query);
        // eslint-disable-next-line max-len
    }, [bpm, genres, hideRemixes, hideExclusives, fileType, synth, hideExplicit, hidePrevDownloaded, hidePrevPlayed, isCreate, key, keyType, subGenres, tagGroups, tags, versions, updateSearchParamsDebounced]);

    const { isNavOpen, closeNav } = useContext(NavContext);

    const handleResetFilters = useCallback(() => {
        setHideRemixes(false);
        setHideExplicit(false);
        setHideExclusives(false);
        setHidePrevDownloaded(false);
        setHidePrevPlayed(false);
        setVersions([]);
        setBpm(DEFAULT_BPM_VALUES);
        setKey([]);
        setGenres([]);
        setSubGenres([]);
        setTags([]);
        setTagGroups([]);
        setInputType(isCreate ? DEFAULT_INPUT_TYPE_CREATE : DEFAULT_INPUT_TYPE_SUPREME);
        setScale(DEFAULT_SCALE);
        setKeyType(DEFAULT_KEYBOARD_KEY_TYPE);
        setFileType(undefined);
        setSynth(undefined);
        setActiveFilters([]);
    }, [isCreate]);

    const value = useMemo<FiltersContextProperties>(
        () => ({
            isFiltersOpen,
            openFilters: () => {
                if (isNavOpen) closeNav();
                setIsFiltersOpen(true);
            },
            closeFilters: () => {
                setIsFiltersOpen(false);
            },
            resetFilters: handleResetFilters,
            updateFiltersFromQuery: loadFiltersFromQuery,
            versions,
            hideRemixes,
            hideExclusives,
            hideExplicit,
            hidePrevDownloaded,
            hidePrevPlayed,
            bpm,
            setBpm: (newBPM) => {
                setBpm(newBPM);

                const newActiveFilterArray = activeFilters.filter((item) => item.type !== 'BPM');
                if (Number(newBPM[0]) === 0 && Number(newBPM[1]) === 250) setActiveFilters(newActiveFilterArray);
                else setActiveFilters([...newActiveFilterArray, { value: `${Number(newBPM[0])}-${Number(newBPM[1])} BPM`, type: 'BPM' }]);
            },
            setHideRemixes: (shouldHideRemixes) => {
                setHideRemixes(shouldHideRemixes);

                const newActiveFilterArray = activeFilters.filter((item) => item.type !== 'Hide Remixes');
                if (shouldHideRemixes) setActiveFilters([...newActiveFilterArray, { value: 'Hide Remixes', type: 'Hide Remixes' }]);
                else setActiveFilters(newActiveFilterArray);
            },
            setHideExplicit: (shouldHideExplicit) => {
                setHideExplicit(shouldHideExplicit);

                const newActiveFilterArray = activeFilters.filter((item) => item.type !== 'Hide Explicit');
                if (shouldHideExplicit) setActiveFilters([...newActiveFilterArray, { value: 'Hide Explicit', type: 'Hide Explicit' }]);
                else setActiveFilters(newActiveFilterArray);
            },
            setHidePrevDownloaded,
            setHidePrevPlayed,
            setHideExclusives: (shouldHideExclusives) => {
                setHideExclusives(shouldHideExclusives);

                const newActiveFilterArray = activeFilters.filter((item) => item.type !== 'Hide Exclusives');
                if (shouldHideExclusives) setActiveFilters([...newActiveFilterArray, { value: 'Hide Exclusives', type: 'Hide Exclusives' }]);
                else setActiveFilters(newActiveFilterArray);
            },
            setVersions: (newVersions) => {
                setVersions(newVersions);

                const newActiveFilterArray = activeFilters.filter((item) => item.type !== 'Version');
                newVersions.forEach((item) => {
                    newActiveFilterArray.push({ value: item, type: 'Version' });
                });
                setActiveFilters(newActiveFilterArray);
            },
            key,
            setKey: (newKeys) => {
                setKey(newKeys);

                const newActiveFilterArray = activeFilters.filter((item) => item.type !== 'Key');
                newKeys.forEach((item) => {
                    newActiveFilterArray.push({ value: item, type: 'Key' });
                });
                setActiveFilters(newActiveFilterArray);
            },
            genres,
            setGenres: (newGenres) => {
                setGenres(newGenres);

                const newActiveFilterArray = activeFilters.filter((item) => item.type !== 'Genre');
                newGenres.forEach((item) => {
                    newActiveFilterArray.push({ value: item, type: 'Genre' });
                });
                setActiveFilters(newActiveFilterArray);
            },
            removeActiveFilterByValue: (labelToRemove: string, valueToRemove: string, itemType?: FiltersSupremeTypes) => {
                const currentType = itemType as FiltersSupremeTypes;
                if (currentType) {
                    switch (currentType) {
                        case 'BPM': {
                            const newActiveFilterArray = activeFilters.filter((item) => item.type !== itemType);
                            setActiveFilters(newActiveFilterArray);
                            setBpm(DEFAULT_BPM_VALUES);
                            break;
                        }
                        case 'Hide Remixes': {
                            const newActiveFilterArray = activeFilters.filter((item) => item.type !== itemType);
                            setActiveFilters(newActiveFilterArray);
                            setHideRemixes(false);
                            break;
                        }
                        case 'Hide Exclusives': {
                            const newActiveFilterArray = activeFilters.filter((item) => item.type !== itemType);
                            setActiveFilters(newActiveFilterArray);
                            setHideExclusives(false);
                            break;
                        }
                        case 'Hide Explicit': {
                            const newActiveFilterArray = activeFilters.filter((item) => item.type !== itemType);
                            setActiveFilters(newActiveFilterArray);
                            setHideExplicit(false);
                            break;
                        }
                        case 'Genre': {
                            const newActiveFilterArray = activeFilters.filter((item) => item.value !== valueToRemove.toLowerCase());
                            setActiveFilters(newActiveFilterArray);

                            const newFilterArray = genres.filter((item) => item !== valueToRemove);
                            setGenres(newFilterArray);
                            break;
                        }
                        case 'Version': {
                            const newActiveFilterArray = activeFilters.filter((item) => item.value !== valueToRemove);
                            setActiveFilters(newActiveFilterArray);
                            const newFilterArray = versions.filter((item) => item !== valueToRemove);
                            setVersions(newFilterArray);
                            break;
                        }
                        case 'Key': {
                            const newActiveFilterArray = activeFilters.filter((item) => item.value !== valueToRemove);
                            setActiveFilters(newActiveFilterArray);
                            const newFilterArray = key.filter((item) => item !== valueToRemove);
                            setKey(newFilterArray);
                            break;
                        }
                        default:
                            break;
                    }
                }
            },
            subGenres,
            setSubGenres,
            tags,
            setTags,
            tagGroups,
            setTagGroups,
            inputType,
            setInputType,
            scale,
            setScale,
            keyType,
            setKeyType,
            fileType,
            setFileType,
            synth,
            setSynth,
            activeFilters,
            setActiveFilters
        }),
        // eslint-disable-next-line max-len
        [isFiltersOpen, handleResetFilters, loadFiltersFromQuery, versions, hideRemixes, hideExclusives, hideExplicit, hidePrevDownloaded, hidePrevPlayed, bpm, key, genres, subGenres, tags, tagGroups, inputType, scale, keyType, fileType, synth, activeFilters, isNavOpen, closeNav]
    );

    return <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>;
}
