import { GenericPaginatedQuery, SoundPackage } from '@bpm-web-app/api-client';
import { SoundPackage as SoundPackageModel } from '@bpm-web-app/create-api-sdk';

import { FAVORITES_STALE_INTERVAL } from '@bpm-web-app/utils';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';

export function useGetNewestSoundPackages(args?: Parameters<typeof SoundPackage.getNewestSoundPackages>[0]) {
    const { data, error } = useSWR(
        [
            'newest-sound-packages',
            {
                ...args,
                persist: true,
            },
        ],
        () => SoundPackage.getNewestSoundPackages(args)
    );

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

export function useGetFeaturedSoundPackages(args?: Parameters<typeof SoundPackage.getFeaturedSoundPackages>[0]) {
    const { data, error } = useSWR(
        [
            'featured-sound-packages',
            {
                ...args,
                persist: true,
            },
        ],
        () => SoundPackage.getFeaturedSoundPackages(args)
    );

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

export function useGetSuggestedSoundPackages() {
    const { data, error } = useSWR(
        [
            'suggested-sound-packages',
            {
                persist: true,
            },
        ],
        () => SoundPackage.getSuggestedSoundPackages()
    );

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

export function useGetSoundPackagesByGenre(args: Parameters<typeof SoundPackage.getSoundPackagesByGenre>[0]) {
    const { data, error } = useSWR(
        [
            'sound-packages-by-genre',
            {
                ...args,
                persist: true,
            },
        ],
        () => SoundPackage.getSoundPackagesByGenre(args)
    );

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

export function useGetFreeSoundPackages(args?: Parameters<typeof SoundPackage.getFreeSoundPackages>[0]) {
    const { data, error } = useSWR(
        [
            'free-sound-packages',
            {
                ...args,
                persist: true,
            },
        ],
        () => SoundPackage.getFreeSoundPackages(args)
    );

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

export function useInfiniteFreeSoundPackages(query: GenericPaginatedQuery) {
    const pageSize = query?.limit || 50;

    const { data, error, size, setSize, isValidating } = useSWRInfinite(
        (index) => [`create-free-sound-packs-${JSON.stringify(query)}-${index}`, { persist: true }],
        (key) => {
            /* extract page index back from key name */
            const pageIndex = key.split('-').pop() || '0';
            return SoundPackage.getFreeSoundPackages({
                ...query,
                skip: +pageIndex * pageSize,
            });
        },
        { revalidateFirstPage: false }
    );

    const isLoadingInitialData = !data && !error;
    const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === 'undefined');
    const isEmpty = data?.[0]?.data.length === 0;
    const lastPageSize = data ? data[data.length - 1]?.data.length || 0 : 0;
    const isLastPage = isEmpty || lastPageSize < pageSize;

    return {
        data,
        isLoadingInitialData,
        isLoadingMore,
        isValidating,
        isLastPage,
        error,
        size,
        setSize,
    };
}

export function useGetDownloadedSoundPackages(args: Parameters<typeof SoundPackage.getDownloadedSoundPackages>[0], isEnabled: boolean) {
    const { data, error } = useSWR(
        [
            'get-downloaded-sound-packages',
            {
                ...args,
                persist: true,
                isEnabled,
            },
        ],
        () => (isEnabled ? SoundPackage.getDownloadedSoundPackages(args) : null)
    );

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

export function useGetNewestSoundPackagesByGenres() {
    const { data, error } = useSWR(
        [
            'newest-sound-packages-by-genres',
            {
                persist: true,
            },
        ],
        () => SoundPackage.getNewestSoundPackagesByGenres()
    );

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

export function useGetFavoritedSoundPackages(args?: Parameters<typeof SoundPackage.getLikedSoundPackages>[0], enabled = true) {
    const { data, error, mutate } = useSWR(
        [
            'sound-packages-favorite',
            {
                ...args,
                enabled,
                persist: true,
            },
        ],
        () => (enabled ? SoundPackage.getLikedSoundPackages(args) : null),
        {
            dedupingInterval: FAVORITES_STALE_INTERVAL,
        }
    );

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}

export function useFollowSoundPackage() {
    return (id: Parameters<typeof SoundPackage.favoriteSoundPackage>[0]) => SoundPackage.favoriteSoundPackage(id);
}

export function useUnFollowSoundPackage() {
    return (id: Parameters<typeof SoundPackage.unfavoriteSoundPackage>[0]) => SoundPackage.unfavoriteSoundPackage(id);
}

export function useDownloadSoundPackage() {
    return (id: Parameters<typeof SoundPackage.downloadSoundPackage>[0], location: string) => SoundPackage.downloadSoundPackage(id, location);
}

export function useDownloadSoundPackagePreview() {
    return (id: Parameters<typeof SoundPackage.downloadSoundPackage>[0]) => SoundPackage.downloadSoundPackagePreview(id);
}

/** We can use ``SoundPackage["slug"]`` as id here */
export function useGetSoundPackage(id: Parameters<typeof SoundPackage.getSoundPackage>[0], disabled?: boolean) {
    const { data, error, mutate } = useSWR(
        [
            'sound-package',
            disabled,
            {
                id,
                persist: true,
            },
        ],
        () => (disabled ? null : SoundPackage.getSoundPackage(id))
    );

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}

export function useInfiniteSimilarSoundPackages(idOrSlug: string, query?: GenericPaginatedQuery) {
    const pageSize = query?.limit || 50;

    const {
        data: responsesArray,
        error,
        size,
        setSize,
        isValidating,
    } = useSWRInfinite(
        (index) => [`create-similar-sound-packs-${JSON.stringify(query)}-${idOrSlug}-${index}`, { persist: true }],
        (key) => {
            const pageIndex = key.split('-').pop() || '0';
            return SoundPackage.getSimilarSoundPacks(idOrSlug, {
                ...query,
                skip: +pageIndex * pageSize,
            });
        },
        { revalidateFirstPage: false }
    );

    const isLoadingInitialData = !responsesArray && !error;
    const isLoadingMore = isLoadingInitialData || (size > 0 && responsesArray && typeof responsesArray[size - 1] === 'undefined');
    /* TODO: fix typing when refactoring this to match create api v1.1 */
    const isEmpty = responsesArray?.[0]?.data?.packs.length === 0;
    const lastPageSize = responsesArray ? responsesArray[responsesArray.length - 1]?.data?.packs.length || 0 : 0;
    const isLastPage = isEmpty || lastPageSize < pageSize;

    return {
        data: responsesArray,
        isLoadingInitialData,
        isLoadingMore,
        isValidating,
        isLastPage,
        error,
        size,
        setSize,
    };
}
