import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';

// eslint-disable-next-line @typescript-eslint/dot-notation
export const StripeAPIKey = (isCreate: boolean) => (isCreate ? process.env['NEXT_PUBLIC_STRIPE_CREATE_PUBLISH_KEY'] ?? '' : process.env['NEXT_PUBLIC_STRIPE_SUPREME_PUBLISH_KEY'] ?? '');

export const StripeCreatePromise = loadStripe(StripeAPIKey(true));
export const StripeSupremePromise = loadStripe(StripeAPIKey(false));

const commonVariables = {
    colorBackground: '#212121',
    colorText: '#B3B3B3',
    borderRadius: '0px',
    colorIconTab: '#E6EFE9',
    colorIconTabHover: '#E6EFE9',
    spacingGridColumn: '16px',
    spacingGridRow: '16px',
    colorDanger: '#EB5757',
    colorDangerText: '#EB5757',
    spacingTab: '4px',
};
const commonRules = {
    '.Tab': {
        boxShadow: 'none',
        border: '1px solid var(--colorBackground)',
    },
    '.Tab--selected:focus': {
        boxShadow: 'none',
    },
    '.Tab:focus': {
        border: '1px solid var(--colorPrimary)',
        boxShadow: 'none',
    },
    '.Tab:hover': {
        backgroundColor: '#2A2A2A',
    },
    '.TabIcon': {
        color: '#E6EFE9',
    },
    '.TabLabel': {
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '20px',
        color: '#E6EFE9',
    },
    '.TabLabel--selected': {
        color: 'var(--colorPrimary)',
    },
    '.Label--floating': {
        color: '#919191',
        fontSize: '12px',
        lineHeight: '16px',
        opacity: '1',
    },
    '.Label--resting': {
        fontSize: '16px',
        lineHeight: '24px',
        color: '#919191',
    },
    '.Input': {
        color: '#E6EFE9',
        fontSize: '16px',
        lineHeight: '24px',
        padding: '16px',
        boxShadow: 'none',
        border: '1px solid #212121',
    },
    '.Input::placeholder': {
        color: '#919191',
    },
    '.Input:focus': {
        boxShadow: 'none',
        borderColor: '#E6EFE9',
    },
    '.Input:hover': {
        backgroundColor: '#2A2A2A',
    },
    '.TermsText': {
        color: '#919191',
        fontSize: '12px',
        lineHeight: '16px',
    },
    '.Error': {
        fontSize: '12px',
        lineHeight: '16px',
    },
};

export const StripeOptionsCreate: StripeElementsOptions = {
    fonts: [
        {
            family: 'Gerstner Programm FSL',
            src: 'url(https://app.bpmsupreme.com/_next/static/media/GerstnerProgrammFSLWeb-Bold.fd81b57e.woff2)',
            weight: '700',
        },
        {
            family: 'Gerstner Programm FSL',
            src: 'url(https://app.bpmsupreme.com/_next/static/media/GerstnerProgrammFSLWeb-Medium.1cf69ddc.woff2)',
            weight: '500',
        },
        {
            family: 'Gerstner Programm FSL',
            src: 'url(https://app.bpmsupreme.com/_next/static/media/GerstnerProgrammFSLWeb-Regular.1cf69ddc.woff2)',
            weight: '400',
        },
        {
            family: 'Gerstner Programm FSL',
            src: 'url(https://app.bpmsupreme.com/_next/static/media/GerstnerProgrammFSLWeb-Light.597ff2a0.woff2)',
            weight: '300',
        },
    ],
    appearance: {
        theme: 'flat',
        labels: 'floating',
        variables: {
            colorPrimary: '#1af085',
            colorIconTabSelected: '#1af085',
            ...commonVariables,
        },
        rules: {
            '.Tab--selected': {
                boxShadow: 'none',
                border: '1px solid var(--colorPrimary)',
                backgroundColor: '#1af0850A',
                color: 'var(--colorPrimary)',
            },
            '.Tab--selected:hover': {
                backgroundColor: '#1af0850A',
            },
            ...commonRules,
        },
    },
};

export const StripeOptionsSupreme: StripeElementsOptions = {
    fonts: [
        {
            family: 'Gerstner Programm FSL',
            src: 'url(https://app.bpmsupreme.com/_next/static/media/GerstnerProgrammFSLWeb-Bold.fd81b57e.woff2)',
            weight: '700',
        },
        {
            family: 'Gerstner Programm FSL',
            src: 'url(https://app.bpmsupreme.com/_next/static/media/GerstnerProgrammFSLWeb-Medium.1cf69ddc.woff2)',
            weight: '500',
        },
        {
            family: 'Gerstner Programm FSL',
            src: 'url(https://app.bpmsupreme.com/_next/static/media/GerstnerProgrammFSLWeb-Regular.1cf69ddc.woff2)',
            weight: '400',
        },
        {
            family: 'Gerstner Programm FSL',
            src: 'url(https://app.bpmsupreme.com/_next/static/media/GerstnerProgrammFSLWeb-Light.597ff2a0.woff2)',
            weight: '300',
        },
    ],
    appearance: {
        labels: 'floating',
        theme: 'flat',
        variables: {
            colorPrimary: '#F73E00',
            colorIconTabSelected: '#F73E00',
            ...commonVariables,
        },
        rules: {
            '.Tab--selected': {
                boxShadow: 'none',
                border: '1px solid var(--colorPrimary)',
                backgroundColor: '#F73E000A',
                color: 'var(--colorPrimary)',
            },
            '.Tab--selected:hover': {
                backgroundColor: '#F73E000A',
            },
            ...commonRules,
        },
    },
};
