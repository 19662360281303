import classNames from 'classnames';
import { ReactElement, useCallback, useState } from 'react';
import { AlbumWithAnalytics, ArtistAlbumsSortByQueryOptions, ArtistSortByQueryOptions } from '@bpm-web-app/download-api-sdk';
import styles from './artist-portal-list.module.css';
import { ArtistPortalListPreset, columnDefinitions, ColumnType } from './artist-portal-list-helpers';
import { ArtistPortalAlbumListItem } from './artist-portal-album-list-item';
import { ArtistPortalListItem } from './artist-portal-list-item';
import { SortButton } from '../shared/sort-button/sort-button';
import SortOptionsSheet, { SortOption } from '../sort-options-sheet/sort-options-sheet';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ArtistPortalListBaseProps {
    sortType?: ArtistSortByQueryOptions | ArtistAlbumsSortByQueryOptions
    setSortType?: (type: ArtistSortByQueryOptions | ArtistAlbumsSortByQueryOptions) => void;
    sortOptions?: SortOption<ArtistSortByQueryOptions | ArtistAlbumsSortByQueryOptions>[];
}

export interface SourcesAttributes {
    icon: string | ReactElement
    source: string
    percentage: number
    previews: number
    downloads: number
    est_reach: number
    impressions: number
}

export interface LocationAttributes {
    icon: string
    source: string
    percentage: number
    previews: number
    downloads: number
}

export interface DJTypesAttributes {
    source: string
    percentage: number
    previews: number
    downloads: number
    est_reach: number
    impressions: number
}

interface ArtistPortalListAlbumProps {
    list?: Array<AlbumWithAnalytics>
    preset: ArtistPortalListPreset.AlbumAnalytics | ArtistPortalListPreset.AlbumAnalyticsSmall
}

interface ArtistPortalListLocationProps {
    list?: Array<LocationAttributes>
    preset: ArtistPortalListPreset.Location
}

interface ArtistPortalListSourcesProps {
    list?: Array<SourcesAttributes>
    preset: ArtistPortalListPreset.Sources
}

interface ArtistPortalListDJTypesProps {
    list?: Array<DJTypesAttributes>
    preset: ArtistPortalListPreset.DJType
}

export type ArtistPortalListProps = ArtistPortalListBaseProps & (ArtistPortalListAlbumProps | ArtistPortalListLocationProps | ArtistPortalListSourcesProps | ArtistPortalListDJTypesProps);

const artistSortOption: SortOption<ArtistSortByQueryOptions>[] = [
    { actionType: ArtistSortByQueryOptions.Downloads, label: 'Downloads' },
    { actionType: ArtistSortByQueryOptions.Previews, label: 'Previews' },
    { actionType: ArtistSortByQueryOptions.Impressions, label: 'Impressions' },
];

export function ArtistPortalList({
    list,
    preset,
    sortType,
    setSortType,
    sortOptions = artistSortOption
}: ArtistPortalListProps) {
    const [sortVisible, setSortVisible] = useState(false);
    const [sortPosition, setSortPosition] = useState({ left: 0, top: 0 });

    const getColumnHeading = useCallback(
        (type, heading) => {
            switch (type) {
                case ColumnType.Actions:
                    return <SortButton
                        setSortPosition={setSortPosition}
                        setSortVisible={setSortVisible} />;
                default:
                    return heading;
            }
        }, []);

    const shouldDisplayType = useCallback((type) => {
        if (type === ColumnType.Impressions && sortType === ArtistSortByQueryOptions.Impressions) {
            return true;
        } if (type === ColumnType.Downloads && sortType === ArtistSortByQueryOptions.Downloads) {
            return true;
        } if (type === ColumnType.Previews && sortType === ArtistSortByQueryOptions.Previews) {
            return true;
        } if (type === ColumnType.Date && sortType === ArtistAlbumsSortByQueryOptions.ReleaseDate) {
            return true;
        }
        return false;
    }, [sortType]);

    return (
        <div className={classNames(styles['artist-portal-list'])}>
            <SortOptionsSheet
                leftPosition={sortPosition.left}
                topPosition={sortPosition.top}
                selectedAction={sortType}
                onAction={(actionType) => {
                    setSortVisible(false);
                    setSortType(actionType);
                }}
                visible={sortVisible}
                setVisible={setSortVisible}
                options={sortOptions}
            />
            <div
                className={classNames(styles['artist-portal-list__list-heading'], styles[`artist-portal-list__list-heading--${preset}`])}
            >
                {columnDefinitions[preset]?.map(({ heading, type, class: className }, index) =>
                    <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        className={classNames(className || '', styles['artist-portal-list__heading-column'], styles[`artist-portal-list__heading-column--${type}`], { [styles[`artist-portal-list__heading-column--${type}--visible-type`]]: shouldDisplayType(type) })}
                    >
                        {getColumnHeading(type, heading)}
                    </div>
                )}
            </div>

            <div className={styles['artist-portal-list__list']}>
                {[ArtistPortalListPreset.AlbumAnalytics, ArtistPortalListPreset.AlbumAnalyticsSmall].includes(preset) && (list as Array<AlbumWithAnalytics>)?.map((album, index) => (
                    <ArtistPortalAlbumListItem sortType={sortType} key={album.id} album={album} preset={preset} trackIndex={index} />
                ))}
                {preset === ArtistPortalListPreset.Location && list?.map((location, index) => (
                    <ArtistPortalListItem
                        key={location.source}
                        row={[index, location.icon, decodeURIComponent(location.source), location.percentage, location.previews, location.downloads]}
                        preset={preset}
                        trackIndex={index}
                        sortType={sortType} />
                ))}
                {preset === ArtistPortalListPreset.Sources && list?.map((source, index) => (
                    <ArtistPortalListItem
                        key={source.source}
                        row={[index, source.icon, decodeURIComponent(source.source), source.percentage, source.previews, source.downloads, source.est_reach, source.impressions]}
                        preset={preset}
                        trackIndex={index}
                        sortType={sortType} />
                ))}
                {preset === ArtistPortalListPreset.DJType && list?.map((type, index) => (
                    <ArtistPortalListItem
                        key={type.source}
                        row={[index, decodeURIComponent(type.source), type.percentage, type.previews, type.downloads, type.est_reach, type.impressions]}
                        preset={preset}
                        trackIndex={index}
                        sortType={sortType} />
                ))}
            </div>

        </div>
    );
}

export default ArtistPortalList;
