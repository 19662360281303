import { Media } from '@bpm-web-app/download-api-sdk';
import { isDownloadLimitReached } from '@bpm-web-app/utils';
import classNames from 'classnames';
import DownloadIcon from '../../../assets/icons/download.svg';
import styles from './version-pill.module.css';

export function getVersionPillLabel(version: string) {
    return version
        .split(' ')
        .slice(0, 3)
        .map((c) => c.charAt(0).toUpperCase());
}

interface VersionPillProps {
    media: Media;
    onClick?: (media: Media) => void;
}

export function VersionPill({ media, onClick }: VersionPillProps) {
    return (
        <button
            type="button"
            key={media.id}
            className={classNames(styles['version-pill'], { [styles['version-pill__downloaded']]: media.download_count })}
            disabled={isDownloadLimitReached(media.download_count)}
            data-tip={media.version?.name}
            onClick={(e) => {
                    e.stopPropagation();
                    onClick?.(media);
            }}>
            <span className={styles['version-pill__text']}>
                {getVersionPillLabel(media.version?.name)}
            </span>
            <span className={styles['version-pill__download']}>
                <DownloadIcon />
            </span>
        </button>
    );
}
