import { Device as DeviceHandler } from 'libs/api-client/src/lib/handlers/device';
import { Device as BPMDevice, SubscriptionDetailsv4 } from '@bpm-web-app/supreme-api-sdk';
import { useGetAuthorizedDevices, useGetMeSubscriptionUnCached } from '@bpm-web-app/swr-hooks';
import { useState, useMemo, useCallback, useContext } from 'react';
import { DeviceManager, useApiErrorHandler, showToast, formatDateToString } from '@bpm-web-app/utils';
import classNames from 'classnames';
import styles from './devices.module.css';
import { useSession } from '../../../shared/session-provider/session-provider';
import { CustomIcon } from '../../../shared/custom-icon/custom-icon';
import { ActionModal } from '../../../shared/action-modal/action-modal';
import { ArtistApplicationInputField } from '../../../pages/artist-portal/artist-application/artist-application-inputfield/artist-application-inputfield';
import { BreakpointView, ContextMenuContext, NoResultsBlock, SecondaryPageTitle } from '../../../shared';
import { ApplicationSectionSmall } from '../../../pages/artist-portal/artist-application/artist-application-text/artist-application-text';

export function Devices({ platform }: { platform: 'supreme' | 'create' }) {
    const { data: subscriptionData } = useGetMeSubscriptionUnCached(platform);

    const hasStandardMembership = useMemo(() => subscriptionData?.data.membership.subscription?.membership_type === SubscriptionDetailsv4.MembershipTypeEnum.Standard,
        [subscriptionData?.data.membership]);
    const hasMembership = useMemo(() => subscriptionData?.data.membership.has_membership, [subscriptionData?.data.membership.has_membership]);

    const errorHandler = useApiErrorHandler();
    const { logout } = useSession();
    const [editingDevice, setEditingDevice] = useState<BPMDevice>();
    const [isEditingDeviceName, setIsEditingDeviceName] = useState<boolean>();
    const [newDeviceName, setNewDeviceName] = useState<string>();
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState<boolean>(false);

    const { openContextMenu, closeOptions } = useContext(ContextMenuContext);

    const { data, mutate, isLoading } = useGetAuthorizedDevices(platform);

    const allowedDevices = useMemo(() => data?.data, [data]);

    const handleRemovePressed = useCallback(async (device: BPMDevice) => {
        setEditingDevice(device);
        setShowRemoveConfirmation(true);
    }, []);

    const handlerDelete = useCallback(async (device: BPMDevice) => {
        try {
            await DeviceHandler.deleteDeviceById(device.id, platform);

            if (device.is_current_device) {
                logout();
                return;
            }
            mutate();
            showToast({ type: 'success', message: 'Deleted successfully.' });
        } catch (error) {
            errorHandler({ error });
        }
    }, [mutate, logout, errorHandler, platform]);

    const updateDeviceName = (newName: string, device: BPMDevice) => {
        if (device !== undefined && device.device_uuid) {
            try {
                DeviceHandler.updateDeviceName(device.device_uuid, newName);
                showToast({ type: 'success', title: 'Device Renamed Successfully' });
                mutate();
            } catch (error) {
                errorHandler({ error });
            }
        }
    };

    const contextMenuOptions = useCallback((device: BPMDevice) => {
        return ([
            {
                label: 'Edit Device Name',
                leftIcon: <CustomIcon type="edit-pencil-icon" hasIconHover />,
                onClick: () => {
                    closeOptions();
                    setIsEditingDeviceName(true);
                }
            },
            {
                label: 'Remove Device',
                leftIcon: <CustomIcon type="delete-filled-icon" hasIconHover />,
                onClick: () => {
                    if (device) {
                        handleRemovePressed(device);
                    }
                    closeOptions();
                }
            },
        ]);
    }, [closeOptions, handleRemovePressed]);

    const handleOpenThreeDots = useCallback((e: React.MouseEvent<HTMLElement>, device: BPMDevice) => {
        const { left, top, width } = e.currentTarget.getBoundingClientRect();
        if (device) {
            setEditingDevice(device);
            openContextMenu({ id: device.id, options: contextMenuOptions(device), left, top, renderLocationPosition: 'app', align: 'left', buttonWidth: width });
        }
    }, [contextMenuOptions, openContextMenu]);

    const deviceTable = useCallback((devicesList: BPMDevice[] | undefined, title: string, allowedCount: number) => {
        return (
            <>
                <div className="spacing--top" />
                <div className="spacing--top" />
                <div className={styles['devices__title-container']}>{`${title}`}{allowedCount ? <div className={styles['devices__title-counter']}>{` (${devicesList?.length}/${allowedCount})`}</div> : null}</div>
                <table className={styles['devices__table']}>
                    <thead>
                        <tr>
                            <th>DEVICES</th>
                            <th>DATE ADDED</th>
                            <th> </th>
                        </tr>
                    </thead>
                    <tbody>
                        {devicesList === undefined || devicesList.length === 0 ? (
                            <NoResultsBlock>{`No ${title} Registered`}</NoResultsBlock>
                        ) : (
                            devicesList?.map((device: BPMDevice) => (
                                <tr key={device.id} className={classNames(styles['devices__table-row'])}>
                                    <td>
                                        <span>{device.device_data_device_name ?? 'Unknown'}</span>
                                        <div className={classNames(styles['devices__table-row-edit'], 'spacing--left-half')}>
                                            <CustomIcon
                                                type="edit-pencil-icon"
                                                hasIconHover
                                                tooltip="Edit Device Name"
                                                onClick={() => {
                                                    setEditingDevice(device);
                                                    setIsEditingDeviceName(true);
                                                }} />
                                        </div>
                                    </td>
                                    <td><span>{formatDateToString(device.created_at)}</span></td>
                                    <td>
                                        <BreakpointView
                                            mobileChildren={
                                                <CustomIcon
                                                    type="three-dots"
                                                    color="white"
                                                    onClick={(e) => {
                                                        handleOpenThreeDots(e, device);
                                                    }} />
                                            }
                                            desktopChildren={
                                                <button onClick={() => handleRemovePressed(device)} type="button">
                                                    <div className={styles['devices__remove-text']}>Remove Device</div>
                                                </button>
                                            } />
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </>
        );
    }, [handleOpenThreeDots, handleRemovePressed]);

    if (isLoading) {
        return null;
    }

    return (
        <>
            <ActionModal
                headerTitle="Confirm Device Removal"
                title={editingDevice?.is_current_device ? 'Remove Current Device?' : 'Remove Device?'}
                subtitle={editingDevice?.is_current_device ? 'Are you sure you want to remove this device from your account? You will be logged out.' : 'Are you sure you want to remove this device from your account?'}
                confirmButtonText={editingDevice?.is_current_device ? 'Remove and Log Out' : 'Remove'}
                cancelButtonText="Cancel"
                onConfirm={() => {
                    if (editingDevice) handlerDelete(editingDevice);
                    setShowRemoveConfirmation(false);
                }}
                onClose={() => {
                    setShowRemoveConfirmation(false);
                }}
                variant="light"
                isOpen={showRemoveConfirmation}
            />
            <ActionModal
                headerTitle="Device Management"
                confirmButtonText="Save"
                cancelButtonText="Cancel"
                onConfirm={() => {
                    if (editingDevice && newDeviceName) {
                        updateDeviceName(newDeviceName, editingDevice);
                    }
                    setEditingDevice(undefined);
                    setNewDeviceName(undefined);
                    setIsEditingDeviceName(false);
                }}
                onClose={() => {
                    setIsEditingDeviceName(false);
                    setEditingDevice(undefined);
                    setNewDeviceName(undefined);
                }}
                variant="dark"
                isOpen={isEditingDeviceName}
            >
                <SecondaryPageTitle title="Edit Device Name" noPadding />
                <ApplicationSectionSmall text="Enter the new device name below." />
                <ArtistApplicationInputField placeholder={editingDevice !== undefined ? editingDevice.device_data_device_name : undefined} text={newDeviceName} setText={setNewDeviceName} />
            </ActionModal>
            {hasMembership && (
                <>
                    <div className={styles['devices__header']}>
                        <h3 className={styles['devices__content-title']}>Device Management</h3>
                    </div>
                    {hasMembership && !hasStandardMembership ? (
                        <div className={styles['devices__header-text']}>BPM Supreme allows premium users to seamlessly access the platform from two desktops and a designated mobile device. If you wish to access from a new device, simply log into BPM Supreme on the new device and follow the on-screen instructions.</div>

                    ) : hasStandardMembership ? (
                        <div className={styles['devices__header-text']}>BPM Supreme allows standard users to seamlessly access the platform from their primary desktop and a designated mobile device. If you wish to access from a new device, simply log into BPM Supreme on the new device and follow the on-screen instructions.</div>

                    ) : null}
                    {deviceTable(allowedDevices?.web || [], 'Main Device(s)', allowedDevices?.web_limit || 0)}
                    <div className="spacing--top" />
                    {deviceTable(allowedDevices?.app || [], 'Mobile Device', allowedDevices?.app_limit || 0)}
                </>
            )}
        </>
    );
}

export default Devices;
