import { DeviceManager, firebaseCloudMessaging } from '@bpm-web-app/utils';
import { getMessaging, onMessage } from 'firebase/messaging';

import { useCallback, useEffect } from 'react';

export function AppNotifications(): null {
    const getMessage = useCallback(() => {
        const messaging = getMessaging();

        onMessage(messaging, (payload) => {
            navigator.serviceWorker
                .getRegistrations()
                .then((r) =>
                    r?.[0]?.showNotification(payload?.notification?.title || 'BPM', {
                        body: payload?.notification?.body || '',
                        icon: '/favicon.png',
                    })
                )
                .catch(() => null);
        });
    }, []);

    useEffect(() => {
        async function setToken() {
            try {
                const token = await firebaseCloudMessaging.init();
                if (token) {
                    await DeviceManager.setDevicePushToken(token);
                    getMessage();
                }
            } catch (error) {
                // Placeholder
            }
        }
        setToken();
    }, [getMessage]);

    return null;
}
