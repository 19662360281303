import classNames from 'classnames';
import styles from './artist-card-image.module.css';

export function ArtistCardImageGhost() {
    return (
        <div className={classNames(styles['artist-card-image'])}>
            <div className={classNames(styles['artist-card-image__img-container'], styles['artist-card-image__img-container-ghost'])}>
                <div className={classNames(styles['artist-card-image__image'], styles['artist-card-image__image-ghost'], 'shimmer')} />
            </div>
            <div className={styles['artist-card-image__text-container']}>
                <div className={classNames(styles['artist-card-image__title'], styles['artist-card-image__title-ghost'], 'shimmer')} />
                <div className={classNames(styles['artist-card-image__subtitle'], styles['artist-card-image__subtitle-ghost'], 'shimmer')} />
            </div>
        </div>
    );
}
