import React, { MouseEvent, useCallback } from 'react';
import { useDrag } from '@use-gesture/react';

import styles from './create-preset-wave.module.css';

interface CreatePresetWaveProps {
    hasPreview: boolean
    handleSeek: (to: number) => void;
    elapsed: number;
    duration: string;
    isPaused: boolean;
}

export function CreatePresetWave({ handleSeek, elapsed, duration, hasPreview }: CreatePresetWaveProps) {
    const elapsedPercentage = (elapsed / +duration) * 100;

    const onClick = useCallback(
        (e: MouseEvent) => {
            const { width, left } = e.currentTarget.getBoundingClientRect();
            const offset = e.clientX - left;
            let percentage = (offset / width) * 100;
            if (percentage < 0) percentage = 0;
            if (percentage > 100) percentage = 100;
            handleSeek((+duration / 100) * percentage);
        },
        [duration, handleSeek]
    );

    /* TODO: implement drag events */
    const dragEvents = useDrag(({ event, down }) => {
        if (!down) return;
        onClick({
            clientX: (event as any).clientX,
            currentTarget: event.currentTarget,
        } as React.MouseEvent);
    });

    if (!hasPreview) {
        return (
            <div className={styles['create-preset-wave__no-preview']}>
                <div className={styles['create-preset-wave__no-preview--wave']} />
                <div className={styles['create-preset-wave__no-preview--text']}>
                    No Preview Available
                </div>
                <div className={styles['create-preset-wave__no-preview--wave']} />
            </div>);
    }

    return (
        <div
            {...(dragEvents as any)()}
            role="progressbar"
            aria-valuenow={elapsedPercentage}
            tabIndex={0}
            aria-valuemin={0}
            aria-live="assertive"
            aria-valuemax={100}
            className={styles['create-preset-wave']}
            onClick={onClick}
            onKeyDown={() => { }}
        >
            <div className={styles['create-preset-wave__wave']} draggable={false}>
                <div className={styles['create-preset-wave__elapsed-wrapper']} style={{ width: `${elapsedPercentage}%` }} />
            </div>

        </div>
    );
}
