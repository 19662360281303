import { useCallback } from 'react';
import CheckIcon from '../../assets/icons/check.svg';
import styles from './sort-options-sheet.module.css';

interface MoreOptionsSheetItemProps<T> {
    action: (type: T) => void;
    actionType: T;
    label: string;
    isSelected: boolean;
}

function SortOptionsSheetItem<T>(props: MoreOptionsSheetItemProps<T>) {
    const { action, label, actionType, isSelected = true } = props;

    const handleClick = useCallback(
        (e) => {
            e.preventDefault();
            action(actionType);
        },
        [action, actionType]
    );

    return (
        <li className={styles['sort-options-menu-item']}>
            <button type="button" className={styles['sort-options-menu-item__btn']} onClick={handleClick}>
                <span>{label}</span>
            </button>
            {isSelected && <CheckIcon />}
        </li>
    );
}

export default SortOptionsSheetItem;
