import { Sound } from '@bpm-web-app/create-api-sdk';
import { MediaWithDownloadedDate } from '@bpm-web-app/download-api-sdk';
import { useMemo } from 'react';
import { formatDateToString } from '@bpm-web-app/utils';
import Dayjs from 'dayjs';
import dayJsIsToday from 'dayjs/plugin/isToday';
import dayJsIsYesterday from 'dayjs/plugin/isYesterday';

Dayjs.extend(dayJsIsToday);
Dayjs.extend(dayJsIsYesterday);

export const isToday = (date: string) => {
    return Dayjs(date).isToday();
};

export const isYesterday = (date: string) => {
    return Dayjs(date).isToday();
};

type DataType = Sound | MediaWithDownloadedDate;

const getKey = (media: DataType) => {
    const date = media.last_downloaded;
    if (!date) {
        return 'Unknown';
    }

    if (isToday(date)) {
        return 'Today';
    }
    if (isYesterday(date)) {
        return 'Yesterday';
    }
    return formatDateToString(date, 'MM/DD/YYYY');
};

export function useGroupByDownloadDate(data: DataType[]) {
    return useMemo(() => {
        if (!data) {
            return undefined;
        }

        // group by download date
        const grouped: { [date: string]: DataType[] } = data.reduce((acc: { [date: string]: DataType[] }, media: DataType) => {
            const key = getKey(media);

            if (acc[key]) {
                acc[key].push(media);
            } else {
                acc[key] = [media];
            }
            return acc;
        }, {});

        return grouped;
    }, [data]);
}
