import { InputHTMLAttributes } from 'react';
import styles from './checkbox.module.css';
import CheckboxMark from '../../../../assets/icons/checkbox_mark.svg';

type CheckboxProps = InputHTMLAttributes<HTMLInputElement>;
export function Checkbox({ ...props }: CheckboxProps) {
    return (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <label
            /* eslint-disable-next-line @typescript-eslint/dot-notation */
            className={styles['checkbox']}
            tabIndex={-1}
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e) => e.stopPropagation()}
        >
            <input type="checkbox" {...props} />
            <span className={styles['checkbox__checkmark']} aria-hidden>
                <CheckboxMark />
            </span>
        </label>
    );
}
