import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useContext, useEffect, useMemo } from 'react';
import { Analytics, useViewport } from '@bpm-web-app/utils';
import { NavContext } from './nav.context';
import styles from './nav.module.css';

// Icons
import { PlainNavItem } from './plain-nav-item';
import { NavFooter } from './nav-footer';
import { artistPortalNavItems } from './nav-menu-items';
import ArtistLogo from '../../assets/icons/artist-platform/yellow-icon.svg';
import { NavHeader } from './nav-header/nav-header';
import { NavToggleButton } from './nav-toggle-button/nav-toggle-button';

export function NavArtistPortal() {
    const router = useRouter();

    const { isNavOpen, isNavHidden, closeNav } = useContext(NavContext);

    const { isMobile } = useViewport();

    const navItems = useMemo(() => artistPortalNavItems(), []);

    /* Close nav (if on mobile) + go back to the first level nav on route change. */
    useEffect(() => {
        const handleRouteChange = () => {
            if (isNavOpen && isMobile) closeNav();
        };

        router.events.on('routeChangeStart', handleRouteChange);

        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closeNav, isNavOpen, router.events]);

    useEffect(() => {
        if (isNavOpen && isMobile) {
            document.documentElement.classList.add('stop-scroll');
        } else {
            document.documentElement.classList.remove('stop-scroll');
        }
    }, [isMobile, isNavOpen]);

    const onClickNavItem = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (isNavOpen && isMobile) closeNav();
    };

    useEffect(() => {
        if (isNavOpen && isMobile) closeNav();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile]);

    return (
        <>
            <nav
                // eslint-disable-next-line @typescript-eslint/dot-notation
                className={classNames(
                    // eslint-disable-next-line @typescript-eslint/dot-notation
                    styles['nav'],
                    styles['nav__ap'],
                    {
                        [styles['nav--open']]: isNavOpen,
                        [styles['nav--hidden']]: isNavHidden,
                    },
                    styles['nav--fullscreen-mobile']
                )}
            >
                <div role="button" tabIndex={0} className={styles['nav__menu']}>
                    <NavHeader title="BPM For Artist" hasSwitcher={false}>
                        <ArtistLogo />
                    </NavHeader>
                    <div className={styles['nav__section-items--divider']} />
                    {navItems.map(({ title, icon, activeIcon, path }) => {
                        return (
                            <PlainNavItem
                                key={title}
                                title={title}
                                icon={icon}
                                activeIcon={activeIcon}
                                path={path}
                                onClick={(e) => {
                                    Analytics.trackClick('nav_bar', title, { location: 'nav_bar' });
                                    if (path !== '/manage-profile') {
                                        onClickNavItem(e);
                                    }
                                }}
                            />
                        );
                    })}
                </div>
                <NavFooter />
            </nav>

            {!isNavHidden && <NavToggleButton />}
        </>
    );
}
