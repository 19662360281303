import useSWR from 'swr';
import { Artist } from '@bpm-web-app/api-client';
import { useHubSwitch, useUserSettings } from '@bpm-web-app/utils';

export function useGetArtistBySlug(slug: string) {
    const { data, error } = useSWR(
        [
            `stream-get-artists-by-slug-${slug}`,
            {
                persist: true,
                slug,
            },
        ],
        () => (slug === undefined ? null : Artist.getArtistBySlug(slug))
    );

    return {
        data: data?.data,
        isLoading: !error && !data,
        error,
    };
}

export function useGetArtistFeaturedIn(id?: number, library?: string) {
    const { isDownload } = useHubSwitch();
    // Using SWR's conditional fetching feature
    if (id) {
        const { data, error } = useSWR<Awaited<ReturnType<typeof Artist['searchArtistFeaturedIn']>>>(
            [`get-artists-featured-in-${id}`, {
                persist: true,
                id,
                isDownload,
                library
            }],
            () => Artist.searchArtistFeaturedIn(isDownload, id, library)
        );
        return {
            data: data?.data,
            isLoading: !error && !data,
            error,
        };
    }
    const { data, error } = useSWR(null, null);
    return {
        data: data?.data,
        isLoading: !error && !data,
        error,
    };
}

export function useFollowedArtists(library?: string) {
    const { isDownload } = useHubSwitch();
    const { isAnonymous } = useUserSettings();

    const { data, error, mutate } = useSWR<Awaited<ReturnType<typeof Artist['getFollowedArtists']>>>(
        ['get-followed-artists', {
            persist: true,
            isDownload,
            library
        }],
        () => Artist.getFollowedArtists(isDownload, library),
        { isPaused: () => isAnonymous }
    );

    return {
        data,
        mutate,
        isLoading: !error && !data,
        error,
    };
}

export function useGetFeaturedArtists(library: string) {
    const { data, error, mutate } = useSWR<Awaited<ReturnType<typeof Artist['getFeaturedArtists']>>>(
        ['get-featured-artists', {
            persist: true,
            // isDownload,
            library,
        }],
        () => Artist.getFeaturedArtists(library)
    );

    return {
        data,
        mutate,
        isLoading: !error && !data,
        error,
    };
}

export function useGetFeaturedEditors(library: string) {
    const { data, error, mutate } = useSWR<Awaited<ReturnType<typeof Artist['getFeaturedEditors']>>>(
        ['get-featured-editors', {
            persist: true,
            // isDownload,
            library,
        }],
        () => Artist.getFeaturedEditors(library)
    );

    return {
        data,
        mutate,
        isLoading: !error && !data,
        error,
    };
}
