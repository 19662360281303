/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Analytics, appendQueryParams, generateArtistLinks, useViewport } from '@bpm-web-app/utils';
import { Artist, Genre } from '@bpm-web-app/stream-api-sdk';
import { Genre as CreateGenre } from '@bpm-web-app/create-api-sdk';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';
import classNames from 'classnames';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useCreateLike } from '@bpm-web-app/swr-hooks';
import { AppLink } from '../shared';
import styles from './trending.module.css';
import { useCreateGenreLinks, useGenreLinks } from '../generate-link/generate-link';
import PlayIcon from '../../assets/icons/player-play-alt.svg';
import PauseIcon from '../../assets/icons/player-pause-alt.svg';
import NowPlayingAnimation from '../shared/now-playing-animation/now-playing-animation';
import { DragDropItem, DragResult } from '../droppable/droppable';
import useFavoriteAlbums from '../shared/three-dots-sheet/useFavoriteAlbums';
import { useCrates } from '../shared/three-dots-sheet/useCrates';
import { useUserPlaylistsDetails } from '../shared/three-dots-sheet/useUserPlaylistsDetails';

export type ITrendingItem = {
    trending_status: number;
    cover_url: string;
    id: string | number;
    title: string;
    artist: string;
    artists?: Artist[];
    slug?: string;
    genre?: Genre | CreateGenre;
    tracking_id: number | string;
    media_id?: number;
};

export interface TrendingItemProps {
    calculateBarWidthPercentage: (trendingStatus: number) => number
    index: number
    media: ITrendingItem
    platform: 'create' | 'supreme';
    isPlaying?: boolean;
}

export function TrendingItem({ calculateBarWidthPercentage, index, media, platform, isPlaying }: TrendingItemProps) {
    const generateCreateGenreLinks = useCreateGenreLinks();
    const generateSupremeGenreLinks = useGenreLinks();

    const { addAlbumToFavorites } = useFavoriteAlbums(media.id as number);
    const { likeDislike } = useCreateLike('soundPackage');
    const { addMediaToOnlineCrate } = useCrates();
    const { addAlbumToPlaylist } = useUserPlaylistsDetails();

    const { ref: viewRef, inView } = useInView({
        triggerOnce: true,
        threshold: 1
    });

    useEffect(() => {
        if (inView) {
            Analytics.trackImpression(platform === 'create' ? 'SoundPack' : 'Album', `${media.tracking_id}`);
        }
    }, [inView, media, platform]);
    const { isMobile } = useViewport();
    // eslint-disable-next-line no-empty-pattern
    const [{ }, dragRef, connectDragPreview] = useDrag<DragDropItem, any, { opacity: number }>({
        item: media,
        canDrag() {
            return !isMobile;
        },
        end: (draggedItem, monitor) => {
            if (monitor.didDrop()) {
                const { target, id } = monitor.getDropResult() as DragResult;
                if (target === 'favorites') {
                    if (platform === 'create') {
                        likeDislike(media.id as string, true);
                    } else {
                        addAlbumToFavorites();
                    }
                } else if (target === 'my-crate') {
                    if (platform === 'supreme') {
                        addMediaToOnlineCrate(media.media_id as number);
                    }
                } else if (target === 'playlist') {
                    addAlbumToPlaylist(id as string, { albums: [{ album_id: media.id as number }] });
                }
            }
        },
        type: platform === 'create' ? 'SoundPack' : 'Album'
    });

    connectDragPreview(getEmptyImage(), {
        // IE fallback: specify that we'd rather screenshot the node
        // when it already knows it's being dragged so we can hide it with CSS.
        captureDraggingState: true,
    });

    return (
        <div
            ref={(ref) => {
                viewRef(ref);
                dragRef(ref);
            }}
            className={styles['trending__list-item-wrapper']}
            style={{ width: `${calculateBarWidthPercentage(media.trending_status)}%` }}>
            <div className={styles['trending__cover']}>
                {isPlaying ?
                    <NowPlayingAnimation /> :
                    <img
                        src={appendQueryParams(media.cover_url, { key: 'dw', value: 56 })}
                        srcSet={`${appendQueryParams(media.cover_url, { key: 'dw', value: 112 })} 2x`}
                        alt={media.title}
                        className={styles['stream-header__cover-img']}
                    />}
                {platform === 'supreme' && <div className={styles['trending__cover-play-overlay']}>{isPlaying ? <PauseIcon /> : <PlayIcon />}</div>}
            </div>
            <div className={styles['trending__details']}>
                <div className={classNames(styles['trending__details-album'], { [styles['trending__details-album--active']]: isPlaying })}>{media.title}</div>
                <div className={classNames(styles['trending__details-media'], { [styles['trending__details-media--active']]: isPlaying })}>
                    {generateArtistLinks(
                        media.artist,
                        media.artists!,
                        (a) => {
                            return (
                                <AppLink href={`/artist/${a.slug}`} key={a.id}>
                                    <button
                                        data-for="artist-tooltip"
                                        data-tip={`${a.name}|${a.slug}|${a.id}|${a.artwork_url}`}
                                        type="button"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                        className={classNames('underline-link', styles['trending__underline-link'], { [styles['trending__underline-link--active']]: isPlaying })}
                                    >
                                        {a.name}
                                    </button>
                                </AppLink>
                            );
                        },
                        (sep, i) => (
                            <span key={i}>{sep}</span>
                        )
                    )}
                </div>
                <div className={styles['trending__details-media']}>{platform === 'create' ? generateCreateGenreLinks((media.genre as CreateGenre)) : generateSupremeGenreLinks((media.genre as Genre))}</div>
            </div>
            <div className={styles['trending__rank']}>
                <span>{`${index + 1}`.padStart(2, '0')}</span>
                <i />
            </div>
        </div>
    );
}
/* eslint-enable jsx-a11y/anchor-is-valid */
/* eslint-enable jsx-a11y/click-events-have-key-events */
/* eslint-enable jsx-a11y/no-static-element-interactions */
