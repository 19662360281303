import classNames from 'classnames';
import { useMemo } from 'react';
import styles from './sort-button.module.css';
import { ReactComponent as SortIcon } from '../../../assets/icons/sort.svg';
import { LocalSortingKeys, APISortingKeys, SortOption } from '../../sort-options-sheet/sort-options-sheet';

interface SortButtonProps {
    componentClass?: string;
    setSortPosition: (position: { left: number, top: number }) => void;
    setSortVisible: (isVisible: boolean) => void;
    selectedSortType?: LocalSortingKeys | APISortingKeys;
    sortOptions?: SortOption<LocalSortingKeys | APISortingKeys | any>[];

}

export function SortButton({ componentClass, setSortPosition, setSortVisible, selectedSortType, sortOptions }: SortButtonProps) {
    const selectedSortOption = useMemo(() => {
        if (sortOptions && selectedSortType) {
            const selectedOption = sortOptions.find((option) => option.actionType === selectedSortType);
            if (selectedOption?.label !== 'Recent') { // Recent is the default option
                return selectedOption?.label;
            }
        }
        return undefined;
    }, [selectedSortType, sortOptions]);

    return (
        <button
            type="button"
            className={classNames(styles['sort-button'], { [styles['sort-button--active']]: selectedSortOption }, { [componentClass]: componentClass })}
            aria-label="Sort"
            onClick={(e) => {
                setSortPosition({ left: e.currentTarget.offsetLeft, top: e.currentTarget.offsetTop });
                setSortVisible(true);
            }}
        >
            {selectedSortOption ? <span>{selectedSortOption}</span> : null}
            <SortIcon className={classNames(styles['sort-button__icon'], selectedSortOption ? styles['sort-button--active'] : '')} />
        </button>
    );
}
