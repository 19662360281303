import classNames from 'classnames';
import React, { FC } from 'react';
import styles from './secondary-page-title.module.css';

export interface SecondaryPageTitleProps {
    title: string;
    counter?: string | number;
    noPadding?: boolean;
}

export const SecondaryPageTitle: FC<SecondaryPageTitleProps> = ({ title, counter, noPadding = false }) => (
    <h3 className={classNames(styles['secondary-page-title'], { 'spacing__window--left': !noPadding })}>
        {title}
        {counter !== undefined && counter !== '' && (
            <span className={styles['secondary-page-title__counter']}>
                &nbsp;
                {`(${counter})`}
            </span>
        )}
    </h3>
);

export default React.memo(SecondaryPageTitle);
