import { useMemo, useCallback, useEffect, useState, useContext } from 'react';
import classNames from 'classnames';
import {
    fileDownload,
    getAbsolutePosition,
    isDownloadLimitReached,
    useApiErrorHandler,
    useOnClickOutside,
    ViewportContext,
} from '@bpm-web-app/utils';
import { useDownloadMedia } from '@bpm-web-app/swr-hooks';
import { Media } from 'libs/download-api-sdk/src/api';
import styles from './three-dots-sheet.module.css';
import { LibraryTabsContext } from '../../../../../utils/src/lib/library-tabs.context';

type ContainerStyle = {
    top?: number;
    left?: number;
};

const threeDotsIconClassName = 'js-three-dots-icon';

export function ThreeDotsSheetVersions(props: { leftPosition: number, topPosition: number, media: Media[], onHide: () => void, move: (x: number, y: number) => void }) {
    const { leftPosition, topPosition, media, onHide, move } = props;
    const { width: viewPortWidth } = useContext(ViewportContext);
    const isMobile = viewPortWidth <= 767;

    const handleCloseSheet = useCallback(() => {
        onHide();
    }, [onHide]);

    const errorHandler = useApiErrorHandler();

    const [showComponent] = useState(false);
    const { libraryProperty } = useContext(LibraryTabsContext);
    const { downloadSingleMedia } = useDownloadMedia(libraryProperty);

    const { ref } = useOnClickOutside(false, (e) => {
        const eventTarget = e.target;
        if (eventTarget instanceof Element) {
            const parentClass = `.${styles['three-dots-menu__container']}`;
            /* if clicked target with 3 dots */
            if (eventTarget.closest(parentClass)) {
                return;
            }

            /* if clicked on a direct parent of the svg icon */
            const eventTargetChildren = Array.from(eventTarget.children);
            if (eventTargetChildren.some((childElement) => childElement.classList.contains(threeDotsIconClassName))) {
                return;
            }
        }

        handleCloseSheet();
    });

    useEffect(
        () => () => {
            if (!showComponent) {
                handleCloseSheet();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [showComponent]
    );

    const containerStyle = useMemo(() => {
        const styleTemp: ContainerStyle = {};
        if (leftPosition !== null) {
            styleTemp.left = leftPosition;
        }
        if (topPosition !== null) {
            styleTemp.top = topPosition;
        }
        return styleTemp;
    }, [leftPosition, topPosition]);

    const handleDownloadVersion = useCallback(async (m: Media) => {
        handleCloseSheet();
        try {
            const { data: downloadMediaUrlData } = await downloadSingleMedia(m.id);

            if (downloadMediaUrlData?.url) {
                fileDownload(downloadMediaUrlData.url);
            }
        } catch (error) {
            errorHandler({ error });
        }
    }, [downloadSingleMedia, errorHandler, handleCloseSheet]);

    useEffect(() => {
        if (isMobile) {
            return;
        }
        if (ref.current && ref.current.children.length > 0) {
            const { width, height } = ref.current.children[0].getBoundingClientRect();
            const { x, y } = getAbsolutePosition(ref.current);
            let deltaX = 0;
            let deltaY = 0;
            if ((x - width) < 0) {
                deltaX = (width - leftPosition);
            }
            if ((y + height) > window.innerHeight) {
                deltaY = window.innerHeight - (y + height);
            }
            if (Math.abs(deltaX) > 10 || Math.abs(deltaY) > 10) {
                move(leftPosition + deltaX, topPosition + deltaY);
            }
        }
    }, [ref, leftPosition, topPosition, media, move, isMobile]);

    return (
        <div ref={ref} style={containerStyle} className={styles['three-dots-menu__container']}>
            <div className={classNames(styles['three-dots-menu__list'], styles['three-dots-menu__list--open'])}>
                <ul>
                    {media?.map((version) => (
                        <li key={version.id} className={styles['three-dots-menu__list-item']}>
                            <button
                                disabled={isDownloadLimitReached(version.download_count)}
                                type="button"
                                className={styles['three-dots-menu__list-item__button']}
                                onClick={() => {
                                    handleDownloadVersion(version);
                                }}
                            >
                                <span>{version.version.name}</span>
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default ThreeDotsSheetVersions;
