import classNames from 'classnames';
import { FC, useMemo } from 'react';
import styles from './no-results-block.module.css';

export interface NoResultsBlockProps {
    verticalMargins?: number;
    className?: string;
    hasPadding?: boolean;
    hasMargin?: boolean;
}
export const NoResultsBlock: FC<NoResultsBlockProps> = ({ children, verticalMargins, className, hasPadding = false, hasMargin = false }) => {
    const verticalMarginsStyle = useMemo(
        () =>
            verticalMargins ? {
                marginTop: `${verticalMargins}px`,
                marginBottom: `${verticalMargins}px`,
            } : {},
        [verticalMargins]
    );

    return (
        <div className={classNames(styles['no-results-block'], className, hasPadding ? 'spacing__window' : '', { [styles['no-results-block--with-margin']]: hasMargin })} style={verticalMarginsStyle}>
            {children}
        </div>
    );
};

export default NoResultsBlock;
