import { useHubSwitch, useViewport } from '@bpm-web-app/utils';
import { useDrag } from '@use-gesture/react';
import classNames from 'classnames';
import { clamp } from 'lodash';
import { MouseEvent, useCallback, useState } from 'react';
import FeaturedInIcon from '../../../../assets/icons/featured-in.svg';
import SimilarTracksIcon from '../../../../assets/icons/find-similar.svg';
import QueueIcon from '../../../../assets/icons/queue.svg';
import RemixesAndEditsIcon from '../../../../assets/icons/remixes.svg';
import { usePlayer } from '../../../player-context';
import { FeaturedIn } from './featured-in/featured-in';
import { ListsButton } from './lists-button/lists-button';
import styles from './lists.module.css';
import PlayerQueue from './player-queue/player-queue';
import { Remixes } from './remixes/remixes';
import { SimilarMedia } from './similar-media/similar-media';

export const LISTS = {
    queue: 'queue',
    similarTracks: 'similarTracks',
    featuredIn: 'featuredIn',
    remixesAndEdits: 'remixesAndEdits',
};

interface ListHeaderProps {
    setShownList: (list: string) => void;
    shownList: string;
}

export function ListsHeader({ setShownList, shownList }: ListHeaderProps) {
    const { isSmallMobile: isMobile } = useViewport();

    const [scroll, setScroll] = useState(0);
    const dragEvents = useDrag(({ down, offset }) => {
        if (!down || !isMobile) return;
        // don't scroll if we're at the end
        if (scroll > 300 && offset[0] < 0) return;
        setScroll((current) => {
            return clamp(current + offset[0] * 3, -300, 0);
        });
    }, { axis: 'x', threshold: 10 });

    const onClick = useCallback((e: MouseEvent<HTMLButtonElement>, list: string) => {
        if (isMobile) {
            const offset = e.currentTarget?.offsetLeft;
            // don't scroll first & last items
            if (offset > 200 && offset < 300) {
                setScroll((current) => {
                    const direction = Math.abs(current) < offset ? -1 : 1;
                    // scroll backward
                    if (direction === 1) {
                        return current + offset;
                    }
                    // scroll forwards
                    return -offset;
                });
            }
        }

        setShownList(list);
    }, [isMobile, setScroll, setShownList]);

    return (
        <div className={classNames(styles['lists__header'])}>
            <div
                {...dragEvents()}
                className={classNames(styles['lists__header-scroll-container'])}
                style={{
                    '--scroll-x': `${scroll}px`
                } as React.CSSProperties}>
                <ListsButton onClick={(e) => onClick(e, LISTS.queue)} isActive={shownList === LISTS.queue}>
                    <QueueIcon />
                    Play Queue
                </ListsButton>
                <ListsButton onClick={(e) => onClick(e, LISTS.similarTracks)} isActive={shownList === LISTS.similarTracks}>
                    <SimilarTracksIcon />
                    Similar Tracks
                </ListsButton>
                <ListsButton onClick={(e) => onClick(e, LISTS.featuredIn)} isActive={shownList === LISTS.featuredIn}>
                    <FeaturedInIcon />
                    Featured In
                </ListsButton>
                <ListsButton onClick={(e) => onClick(e, LISTS.remixesAndEdits)} isActive={shownList === LISTS.remixesAndEdits}>
                    <RemixesAndEditsIcon />
                    Remixes & Edits
                </ListsButton>
            </div>
        </div>
    );
}

export function Lists() {
    const [shownList, setShownList] = useState(LISTS.queue);

    const { currentTrack } = usePlayer();

    const { isDownload } = useHubSwitch();

    const mediaId = isDownload ? currentTrack?.album_id : currentTrack?.id;

    if (!currentTrack || !mediaId) return null;

    return (
        // eslint-disable-next-line @typescript-eslint/dot-notation
        <div className={classNames(styles['lists'], {})}>
            <ListsHeader setShownList={setShownList} shownList={shownList} />
            {shownList === LISTS.queue && <PlayerQueue />}
            {shownList === LISTS.similarTracks && <SimilarMedia mediaId={mediaId} />}
            {shownList === LISTS.featuredIn && <FeaturedIn artistId={currentTrack.artists?.[0]?.id} />}
            {shownList === LISTS.remixesAndEdits && <Remixes mediaId={mediaId} />}
        </div>
    );
}
