import { Album, MediaWithAlbum, Playlist } from '@bpm-web-app/stream-api-sdk';
import { Artist, Album as DownloadAlbum, Media as DownloadMedia } from '@bpm-web-app/download-api-sdk';
import { downloadAlbumWithMediaToQueueItem, getCurrentPlatformLink, State, streamMediaWithAlbumToQueueItem, streamMediaWithoutAlbumToQueueItem, useHubSwitch, usePageDetails, usePlayerState } from '@bpm-web-app/utils';
import { useCallback, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { AppLink, BreakpointView, CardCarousel, SecondaryPageTitle, TrackList, TrackListPreset } from '../../../shared';
import styles from './artist-detail-music.module.css';
import { APISortingKeys, LocalSortingKeys, SortOption } from '../../../sort-options-sheet/sort-options-sheet';
import { usePlayer } from '../../../player-context';
import { RowComponent } from '../../../row-component/row-component';
import { PlaylistCard } from '../../../shared/card/playlist-card/playlist-card';
import { AlbumCard } from '../../../shared/card/album-card/album-card';
import SeeMore from '../../../shared/see-more-button/see-more-btn';
import { TrackListSupreme } from '../../../shared/track-list/supreme/track-list-supreme';
import { TrackListPresetSupreme } from '../../../shared/track-list/supreme/track-list-supreme-helpers';

enum ArtistDetailMobileView {
    Albums = 'albums',
    FeaturedIn = 'featuredIn',
    ArtistInfo = 'artistInfo',
    All = 'all',
    Originals = 'originals',
    Remixes = 'remixes',
    RemixedBy = 'remixed_by'
}

enum ArtistDetailTrackView {
    All = 'all',
    Originals = 'originals',
    Remixes = 'remixes',
    RemixedBy = 'remixed_by'
}

interface ArtistDetailMusicProps {
    // Songs
    songsListTotalCount: number;
    songsList: MediaWithAlbum[] | DownloadAlbum[];
    originalSongsList: MediaWithAlbum[] | DownloadAlbum[];
    remixSongsList: MediaWithAlbum[] | DownloadAlbum[];
    originalSongsListTotalCount: number;
    remixSongsListTotalCount: number;
    remixedBySongsList: MediaWithAlbum[] | DownloadAlbum[];
    remixedBySongsListTotalCount: number;
    onSort: (nextSort: APISortingKeys | LocalSortingKeys) => void;
    sortOptions: SortOption<APISortingKeys>[];
    selectedSortType: APISortingKeys;
    onDownloadRevalidate: (downloadMedia?: DownloadMedia) => void;
    // Albums
    albumsList: Album[];
    // Featured In
    featuredInList: Playlist[];
    // Artwork Credit
    artworkCredit?: string;
    artistSlug: string;
    currentArtist?: Artist;
}

export function ArtistDetailMusic({
    remixedBySongsList,
    remixedBySongsListTotalCount,
    songsListTotalCount,
    songsList,
    originalSongsList,
    originalSongsListTotalCount,
    remixSongsList,
    remixSongsListTotalCount,
    onSort,
    sortOptions,
    selectedSortType,
    onDownloadRevalidate,
    albumsList,
    featuredInList,
    artworkCredit,
    artistSlug,
    currentArtist,
}: ArtistDetailMusicProps) {
    const { isDownload } = useHubSwitch();
    const router = useRouter();

    const [activeTrackTab, setActiveTrackTab] = useState(ArtistDetailTrackView.All);
    const { setQueue, currentTrack } = usePlayer();
    const { resource } = usePageDetails();
    const playerState = usePlayerState();

    const isPlaying = useCallback((id: number) => {
        if (playerState !== State.Playing) {
            return false;
        }
        return currentTrack?.id === id;
    }, [playerState, currentTrack]);

    const trackTabs = useMemo(
        () => [
            {
                id: ArtistDetailTrackView.All,
                label: 'All',
                disabled: songsListTotalCount === 0,
                counter: songsListTotalCount,
            },
            {
                id: ArtistDetailTrackView.Originals,
                label: 'Originals',
                disabled: originalSongsListTotalCount === 0,
                counter: originalSongsListTotalCount,
            },
            {
                id: ArtistDetailTrackView.Remixes,
                label: 'Remixes',
                disabled: remixSongsListTotalCount === 0,
                counter: remixSongsListTotalCount,
            },
            {
                id: ArtistDetailTrackView.RemixedBy,
                label: 'Remixed By',
                disabled: remixedBySongsListTotalCount === 0,
                counter: remixedBySongsListTotalCount,
            }
        ],
        [originalSongsListTotalCount, remixSongsListTotalCount, songsListTotalCount, remixedBySongsListTotalCount]
    );

    const handlePlaySong = useCallback(
        (indexToPlay: number) => {
            const queueItems = isDownload
                ? (songsList as DownloadAlbum[]).map((album) => downloadAlbumWithMediaToQueueItem(album))
                : (songsList as MediaWithAlbum[]).map((song) => streamMediaWithAlbumToQueueItem(song));

            setQueue(queueItems, indexToPlay || 0, { identifier: artistSlug, resource });
        },
        [artistSlug, isDownload, resource, setQueue, songsList]
    );

    const handlePlayAlbum = useCallback(
        (albumToPlay: Album) => {
            if (isDownload) return;

            const queueItems = albumToPlay.media.map((media) =>
                streamMediaWithoutAlbumToQueueItem(media, {
                    cover_url: albumToPlay.cover_url,
                    genre: albumToPlay.genre,
                    isExclusive: albumToPlay.is_exclusive,
                })
            );
            setQueue(queueItems);
        },
        [isDownload, setQueue]
    );

    const handlePlaylistClick = useCallback(
        (playlistId: number) => {
            router.push(getCurrentPlatformLink(`/exclusive-playlist/${playlistId}`));
        },
        [router]
    );

    const getAlbumRow = useCallback(
        (album: Album | DownloadAlbum, index?: number) => (
            <RowComponent
                type="album"
                id={album.id}
                onClick={() => (isDownload ? handlePlaySong(index ?? 0) : handlePlayAlbum(album as Album))}
                isPlaying={isPlaying(album.media[0].id)}
                cover_url={album.cover_url}
                title={album.title}
                albumName={album.name}
                artist={album.artist}
                artists={album.artists}
                mediaCount={album.media.length}
                genre={album.genre.name}
            />
        ),
        [handlePlayAlbum, handlePlaySong, isDownload, isPlaying]
    );

    const getPlaylistRow = useCallback(
        (playlist: Playlist) => (
            <RowComponent
                type="playlist"
                id={playlist.id}
                onClick={() => handlePlaylistClick(playlist.id)}
                cover_url={playlist.image_url}
                title={playlist.title}
                artist={playlist.artist}
                artists={playlist.artists}
            />
        ),
        [handlePlaylistClick]
    );

    const mobileView = useMemo(() => {
        return (
            <article>
                <div className={styles['detail-mobile--list-container']}>
                    {songsList && (
                        <>
                            <button
                                key={ArtistDetailMobileView.All}
                                type="button"
                                className={classNames(styles['detail-mobile--tab'])}
                            >
                                <AppLink href={`/artist/${artistSlug}/albums?category=${activeTrackTab}`}>
                                    <a className={styles['detail-mobile--tab']}>
                                        All
                                        <i className="icon icon--chevron-right icon--red" />
                                    </a>
                                </AppLink>
                            </button>
                            <TrackListSupreme
                                preset={isDownload ? TrackListPresetSupreme.Download : TrackListPresetSupreme.Stream}
                                list={songsList}
                                isSortable
                                onSort={onSort}
                                sortOptions={sortOptions}
                                selectedSortType={selectedSortType}
                                onDownloadRevalidate={onDownloadRevalidate}
                            />
                        </>
                    )}
                    {originalSongsList && (
                        <>
                            <button
                                key={ArtistDetailMobileView.Originals}
                                type="button"
                                className={classNames(styles['detail-mobile--tab'])}
                            >
                                <AppLink href={`/artist/${artistSlug}/albums?category=${activeTrackTab}`}>
                                    <a className={styles['detail-mobile--tab']}>
                                        Originals
                                        <i className="icon icon--chevron-right icon--red" />
                                    </a>
                                </AppLink>
                            </button>
                            <TrackListSupreme
                                preset={isDownload ? TrackListPresetSupreme.Download : TrackListPresetSupreme.Stream}
                                list={originalSongsList}
                                isSortable
                                onSort={onSort}
                                sortOptions={sortOptions}
                                selectedSortType={selectedSortType}
                                onDownloadRevalidate={onDownloadRevalidate}
                            />
                        </>
                    )}
                    {remixSongsList && (
                        <>
                            <button
                                key={ArtistDetailMobileView.Remixes}
                                type="button"
                                className={classNames(styles['detail-mobile--tab'])}
                            >
                                <AppLink href={`/artist/${artistSlug}/albums?category=${activeTrackTab}`}>
                                    <a className={styles['detail-mobile--tab']}>
                                        Remixes
                                        <i className="icon icon--chevron-right icon--red" />
                                    </a>
                                </AppLink>
                            </button>
                            <TrackListSupreme
                                preset={isDownload ? TrackListPresetSupreme.Download : TrackListPresetSupreme.Stream}
                                list={remixSongsList}
                                isSortable
                                onSort={onSort}
                                sortOptions={sortOptions}
                                selectedSortType={selectedSortType}
                                onDownloadRevalidate={onDownloadRevalidate}
                            />
                        </>
                    )}
                    {remixedBySongsList && (
                        <>
                            <button
                                key={ArtistDetailMobileView.RemixedBy}
                                type="button"
                                className={classNames(styles['detail-mobile--tab'])}
                            >
                                <AppLink href={`/artist/${artistSlug}/albums?category=${activeTrackTab}`}>
                                    <a className={styles['detail-mobile--tab']}>
                                        Remixed By
                                        <i className="icon icon--chevron-right icon--red" />
                                    </a>
                                </AppLink>
                            </button>
                            <TrackListSupreme
                                preset={isDownload ? TrackListPresetSupreme.Download : TrackListPresetSupreme.Stream}
                                list={remixedBySongsList}
                                isSortable
                                onSort={onSort}
                                sortOptions={sortOptions}
                                selectedSortType={selectedSortType}
                                onDownloadRevalidate={onDownloadRevalidate}
                            />
                        </>
                    )}
                    {albumsList && (
                        <>
                            <button
                                key={ArtistDetailMobileView.Albums}
                                type="button"
                                className={classNames(styles['detail-mobile--tab'])}
                            >
                                <AppLink href={`/artist/${artistSlug}/albums?category=${activeTrackTab}`}>
                                    <a className={styles['detail-mobile--tab']}>
                                        Albums
                                        <i className="icon icon--chevron-right icon--red" />
                                    </a>
                                </AppLink>
                            </button>
                            {albumsList.map((album) => getAlbumRow(album))}
                        </>
                    )}
                    {featuredInList.length !== 0 ? (
                        <>
                            <button
                                key={ArtistDetailMobileView.Albums}
                                type="button"
                                className={classNames(styles['detail-mobile--tab'])}
                            >
                                <AppLink href={`/artist/${artistSlug}/albums?category=${activeTrackTab}`}>
                                    <a className={styles['detail-mobile--tab']}>
                                        Featured in
                                        <i className="icon icon--chevron-right icon--red" />
                                    </a>
                                </AppLink>
                            </button>
                            {featuredInList && featuredInList.map((playlist) => getPlaylistRow(playlist))}
                        </>
                    ) : null
                    }
                </div>
                {
                    artworkCredit ?
                        <div>
                            <h3 className={styles['detail-mobile--art-credit']}>Image Credit &copy; {artworkCredit}</h3>
                        </div> : null
                }
            </article>
        );
    }, [activeTrackTab,
        albumsList,
        artistSlug,
        artworkCredit,
        featuredInList,
        getAlbumRow,
        getPlaylistRow,
        isDownload,
        onDownloadRevalidate,
        onSort,
        originalSongsList,
        remixSongsList,
        remixedBySongsList,
        selectedSortType,
        songsList,
        sortOptions]);

    const activeSongList = useMemo(() => {
        switch (activeTrackTab) {
            case ArtistDetailTrackView.Originals:
                return { count: originalSongsListTotalCount, list: originalSongsList };
            case ArtistDetailTrackView.Remixes:
                return { count: remixSongsListTotalCount, list: remixSongsList };
            case ArtistDetailTrackView.RemixedBy:
                return { count: remixedBySongsListTotalCount, list: remixedBySongsList };

            case ArtistDetailTrackView.All:
            default:
                return { count: songsListTotalCount, list: songsList };
        }
    }, [activeTrackTab, originalSongsList, originalSongsListTotalCount, remixSongsList, remixSongsListTotalCount, remixedBySongsList, remixedBySongsListTotalCount, songsList, songsListTotalCount]);

    const desktopView = useMemo(() => {
        return (
            <article>
                <div className="spacing__window--horizontal">
                    <div role="tablist" className={classNames(styles['artist-detail-music--track-tabs'])}>
                        <div className={styles['artist-detail-music--desktop--article-heading']}>
                            <SecondaryPageTitle title="Songs" noPadding />
                        </div>
                        {
                            trackTabs.map((tab) => (
                                <button
                                    key={tab.id}
                                    className={classNames(styles['artist-detail-music--track-tabs__tab'], {
                                        [styles['artist-detail-music--track-tabs__tab--active']]: activeTrackTab === tab.id,
                                        [styles['artist-detail-music--track-tabs__tab--disabled']]: tab.disabled,
                                    })}
                                    type="button"
                                    role="tab"
                                    onClick={() => !tab.disabled && setActiveTrackTab(tab.id)}
                                >
                                    {tab.label}
                                    <span>{` (${tab.counter})`}</span>
                                </button>
                            ))
                        }
                        <div className={classNames(styles['tracklist-view'])}>
                            {
                                activeSongList.count > 0 && (
                                    <TrackListSupreme
                                        preset={isDownload ? TrackListPresetSupreme.Download : TrackListPresetSupreme.Stream}
                                        list={activeSongList.list}
                                        isSortable
                                        onSort={onSort}
                                        sortOptions={sortOptions}
                                        selectedSortType={selectedSortType}
                                        onDownloadRevalidate={onDownloadRevalidate}
                                    />
                                )
                            }
                        </div>
                        {activeSongList.count > activeSongList.list.length && (
                            <AppLink href={`/artist/${artistSlug}/albums?category=${activeTrackTab}`}>
                                <SeeMore expand={false} variant="text" />
                            </AppLink>
                        )}
                    </div>
                </div>
                {!isDownload && (
                    <article>
                        <CardCarousel title={`Albums Featuring ${currentArtist?.name}`} titleCounter={albumsList.length.toString()} seeMorePath={`/artist/${artistSlug}/albums`}>
                            {albumsList.map((album) => (
                                <AlbumCard
                                    key={album.id}
                                    isDownload={isDownload}
                                    album={album as unknown as DownloadAlbum}
                                    albumsList={albumsList as unknown as DownloadAlbum[]}
                                />
                            ))}
                        </CardCarousel>
                    </article>
                )}
                <article>
                    {featuredInList !== undefined && featuredInList.length !== 0 ?
                        <CardCarousel title={`Playlists Featuring ${currentArtist?.name}`} titleCounter={featuredInList?.length.toString()} seeMorePath={`/artist/${artistSlug}/featured-in`}>
                            {featuredInList.slice(0, 20).map((playlist) => (
                                <PlaylistCard
                                    key={playlist.id}
                                    playlist={playlist}
                                    playlists={featuredInList}
                                />
                            ))}
                        </CardCarousel> : null
                    }
                </article>
                {
                    artworkCredit ?
                        <article className="spacing--horizontal">
                            <div>
                                <h3 className={styles['artist-detail-music--art-credit']}>Image Credit &copy; {artworkCredit}</h3>
                            </div>
                        </article>
                        : null
                }
            </article>
        );
    }, [trackTabs,
        activeSongList,
        isDownload,
        onSort,
        sortOptions,
        selectedSortType,
        onDownloadRevalidate,
        artistSlug,
        activeTrackTab,
        currentArtist?.name,
        albumsList,
        featuredInList,
        artworkCredit]);

    return (
        <BreakpointView
            mobileChildren={mobileView}
            desktopChildren={desktopView}
        />
    );
}
