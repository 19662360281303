import * as React from 'react';
import { Artist } from '@bpm-web-app/stream-api-sdk';

// eslint-disable-next-line max-len
export function generateArtistLinks(artist: string, artists: Artist[], generateLink: (artist: Artist) => React.ReactElement, generateSeparator: (sep: string, index: number) => React.ReactElement, options: { sort: boolean } = { sort: true }) {
    if (options.sort) {
        artists?.sort((a1, a2) => (a1.sequence_number || 0) - (a2.sequence_number || 0));
    }
    let artistIterator = (artist || '').normalize('NFD').replace(/\p{Diacritic}/gu, '');
    const linkElements: React.ReactElement[] = [];
    artists?.forEach((a, i) => {
        const currentArtist = a.name.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLowerCase();
        const foundIndex = artistIterator.toLowerCase().indexOf(currentArtist);
        if (foundIndex !== -1) {
            const { length } = currentArtist;
            const separator = artistIterator.slice(0, foundIndex);
            artistIterator = artistIterator.slice(separator.length + length);
            if (separator) {
                linkElements.push(generateSeparator(separator, i));
            }
            linkElements.push(generateLink(a));
        }
    });
    return linkElements;
}
