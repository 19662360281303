import { Fragment, MouseEvent, useCallback, useContext, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useViewport } from '@bpm-web-app/utils';
import styles from './context-menu.module.css';
// eslint-disable-next-line import/no-cycle
import { ContextMenuContext } from './context-menu.context';
import { ContextMenuItemProps } from './types';

export function ContextMenuItem({
    type = 'item',
    label,
    leftIcon,
    rightIcon,
    onClick,
    items
}: ContextMenuItemProps) {
    const [showSubMenuAtPosition, setShowSubMenuAtPosition] = useState<number | undefined>(undefined);
    const { updateOptions } = useContext(ContextMenuContext);
    const { isMobile } = useViewport();

    const onButtonClick = useCallback((e: MouseEvent) => {
        if (items) {
            if (isMobile) {
                updateOptions(items);
            } else {
                setShowSubMenuAtPosition(1);
            }
        } else if (onClick) onClick(e);
    }, [items, onClick, isMobile, updateOptions]);

    const subMenu = useMemo(() => {
        if (showSubMenuAtPosition !== undefined && items) {
            return (
                <div className={classNames(styles['context-menu__container'], styles['context-menu__sub-item'])}>
                    <ul>
                        {items.map((option, index) =>
                            <Fragment key={option.label || 'divider'}>
                                <ContextMenuItem {...option} />
                            </Fragment>
                        )}
                    </ul>
                </div>
            );
        }
        return null;
    }, [showSubMenuAtPosition, items]);

    if (type === 'divider') {
        return (
            <div className={styles['context-menu-item__divider']} />
        );
    }

    return (
        <button type="button" onClick={onButtonClick} className={styles['context-menu-item']}>
            <li className={styles['context-menu-item']}>
                {leftIcon ? <div className={styles['context-menu-item__icon-container']}>{leftIcon}</div> : <div className={styles['context-menu-item__icon-container--placeholder']} />}
                {label ? <span className={classNames(styles['context-menu-item__label'], { [styles['context-menu-item__label--delete']]: type === 'delete-item' })}>{label}</span> : null}
                {rightIcon || null}
                {subMenu}
            </li>
        </button>
    );
}
