import { HLSPlayer, PLAYER_PREVIEW_SECONDS, drawWaveForm, getDrawData } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { noop } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';
import { usePlayer, usePlayerDuration } from '../../../player-context';
import { PlayerWaveVariant } from '../types';
import { PlayerWaveCanvasConfig } from './config';
import styles from './player-wave-canvas.module.css';
import { useResizableDetail } from './useResizableDetail';
import { useWaveData } from './useWaveData';

interface PlayerWaveCanvasProps {
    /**
     * URL to the .dat file containing the wave data
     */
    datUrl: string;

    /**
     *
     * Variant is used to determine the height of the wave (since we need to set static heights for the canvas)
     *
     */
    variant: PlayerWaveVariant;

}

export function PlayerWaveCanvas({ datUrl, variant }: PlayerWaveCanvasProps) {
    const { containerRef, width: containerWidth, detailDivFactor } = useResizableDetail(PlayerWaveCanvasConfig[variant].barWidth);

    const { currentTrack, isPreviewTrack, currentDuration } = usePlayer();
    const { elapsed, startOffset } = usePlayerDuration();
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const waveData = useWaveData(datUrl);

    const currentTrackDuration = useMemo(() => Number(currentTrack?.estimated_duration), [currentTrack?.estimated_duration]);
    const duration: number = isPreviewTrack ? currentDuration - startOffset || PLAYER_PREVIEW_SECONDS : HLSPlayer.getDuration();

    const lockedRanges = useMemo(() => {
        const ranges: [number, number][] = [];
        if (!isPreviewTrack) {
            return [];
        }
        if (startOffset) {
            const fistHalfPercentage = (startOffset / currentTrackDuration) * 100;
            const secondHalfPercentage = ((startOffset + duration) / currentTrackDuration) * 100;
            ranges.push([0, fistHalfPercentage]);
            ranges.push([secondHalfPercentage, 100]);
        } else {
            const lockedPercentage = ((currentTrackDuration - duration) / currentTrackDuration) * 100;
            if (lockedPercentage) {
                ranges.push([lockedPercentage, 100]);
            }
        }
        return ranges;
    }, [currentTrackDuration, duration, startOffset, isPreviewTrack]);

    const canvasSize = useMemo(() => {
        return {
            width: containerWidth * (window.devicePixelRatio || 1),
            height: PlayerWaveCanvasConfig[variant].height * (window.devicePixelRatio || 1),
        };
    }, [variant, containerWidth]);

    const elapsedPercentage = isPreviewTrack ? (elapsed / currentTrackDuration) * 100 : (elapsed / duration) * 100;

    const { options, drawData } = useMemo(() => {
        if (!waveData) return {};
        return {
            options: { ...PlayerWaveCanvasConfig[variant], detailDivFactor },
            drawData: getDrawData(waveData)
        };
    }, [variant, waveData, detailDivFactor]);

    useEffect(() => {
        const canvas = canvasRef.current;
        let animationFrameId: number;
        if (canvas && options && drawData) {
            animationFrameId = requestAnimationFrame(() =>
                drawWaveForm({
                    canvas,
                    waveform: drawData,
                    lockedRanges,
                    progressPercentage: elapsedPercentage,
                    options,
                })
            );
        }

        return () => {
            cancelAnimationFrame(animationFrameId);
        };
    }, [waveData, elapsedPercentage, lockedRanges, options, drawData, containerWidth]);

    return (
        <div
            ref={containerRef}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            role="progressbar"
            aria-valuenow={elapsedPercentage}
            tabIndex={0}
            aria-valuemin={0}
            aria-live="assertive"
            aria-valuemax={100}
            className={classNames(styles['player-wave-canvas'])}
            onKeyDown={noop}
        >
            <canvas className={classNames(styles['player-wave-canvas__canvas'])} ref={canvasRef} draggable={false} {...canvasSize} />
        </div>
    );
}
