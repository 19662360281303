import { useRouter } from 'next/router';
import { Album, MediaWithAlbum } from '@bpm-web-app/stream-api-sdk';
import { Album as DownloadAlbum, SortByQueryOptions } from '@bpm-web-app/download-api-sdk';
import { useGetArtistBySlug, useInfiniteSearchMedia, useInfiniteSearchAlbum, useGetArtistFeaturedIn } from '@bpm-web-app/swr-hooks';
import { downloadAlbumWithMediaToQueueItem, State, streamMediaWithAlbumToQueueItem, useHubSwitch, usePageDetails, usePlayerState, useViewport } from '@bpm-web-app/utils';
import { useMemo, useCallback, useContext, useState } from 'react';
import { SearchPaginatedQuery, SearchQuery } from '@bpm-web-app/api-client';
import classNames from 'classnames';
import { LibraryTabsContext } from '../../../../../utils/src/lib/library-tabs.context';
import Title from '../../title/title';
import { ArtistDetailHeader } from './artist-detail-header/artist-detail-header';
import { ArtistDetailInfo } from './artist-detail-info/artist-detail-info';
import { usePlayer } from '../../player-context';
import useFollowArtist from '../../shared/three-dots-sheet/useFollowArtist';
import { ArtistDetailMusic } from './artist-detail-music/artist-detail-music';
import { APISortingKeys, apiSortOptionsWithRelevance, useApiSort } from '../../sort-options-sheet/sort-options-sheet';
import { GhostComponent } from '../../shared';
import styles from './artist-detail.module.css';
import MusicNotesIcon from '../../../assets/icons/music-notes-icon.svg';
import MusicNotesIconActive from '../../../assets/icons/music-notes-active.svg';
import AvatarIcon from '../../../assets/icons/artist-avatar-icon.svg';
import AvatarIconActive from '../../../assets/icons/artist-avatar-active.svg';
import Toolbar from '../../toolbar/toolbar';

const SONGS_LIMIT = 10;
const ALBUMS_LIMIT = 20;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ArtistDetailProps { }

enum ArtistDetailHeaderTabs {
    Music = 'Music',
    ArtistInfo = 'Artist Info',
}

export function ArtistDetail(props: ArtistDetailProps) {
    const router = useRouter();
    const { artistSlug } = router.query;
    const { isDownload, hub } = useHubSwitch();
    const { data: artist, isLoading: isArtistLoading } = useGetArtistBySlug(artistSlug?.toString() || '');
    const [activeTab, setActiveTab] = useState(ArtistDetailHeaderTabs.Music);
    const { isMobile } = useViewport();

    const { libraryProperty } = useContext(LibraryTabsContext);

    const { sortBy } = router.query;
    const apiSortFunction = useApiSort();

    const query = useMemo<SearchQuery & SearchPaginatedQuery>(() => {
        const queryParams: SearchQuery & SearchPaginatedQuery = {
            artist: artistSlug?.toString(),
            library: libraryProperty,
            limit: SONGS_LIMIT,
        };

        if (sortBy) {
            queryParams.sort_by = sortBy.toString() as unknown as SortByQueryOptions;
        }

        return queryParams;
    }, [artistSlug, libraryProperty, sortBy]);

    // MEDIA SECTION
    const {
        data: streamSongs,
        isLoadingInitialData: isLoadingSongsInitialData,
        isLoadingMore: isLoadingMoreSongs,
        setSize: setSongsSize,
        size: songsSize,
    } = useInfiniteSearchMedia(query, !isDownload);

    const songs = useMemo(() => (!isDownload ? streamSongs?.flatMap(({ data: paginatedResponse }) => paginatedResponse) : []), [isDownload, streamSongs]);
    const songsPagination = streamSongs?.[0]?.pagination;

    const loadMoreMedia = useCallback(() => {
        if (isLoadingMoreSongs) return;
        setSongsSize(songsSize + 1);
    }, [isLoadingMoreSongs, songsSize, setSongsSize]);

    // ALBUMS SECTION
    const albumsQuery = useMemo(
        () => ({
            ...query,
            limit: isDownload ? SONGS_LIMIT : ALBUMS_LIMIT,
            sort_by: isDownload ? query.sort_by : (undefined as unknown as SortByQueryOptions),
        }),
        [isDownload, query]
    );
    const {
        data: albumsData,
        isLoadingInitialData: isLoadingAlbumsInitialData,
        isLoadingMore: isLoadingAlbumsMore,
        setSize: setAlbumsSize,
        size: albumsSize,
        mutate: mutateAlbums,
    } = useInfiniteSearchAlbum(albumsQuery);

    // Originals
    const originalsQuery = useMemo(
        () => ({
            ...query,
            limit: isDownload ? SONGS_LIMIT : ALBUMS_LIMIT,
            sort_by: isDownload ? query.sort_by : (undefined as unknown as SortByQueryOptions),
            hide_remix: true
        }),
        [isDownload, query]
    );

    const {
        data: originalsData,
        isLoadingInitialData: isLoadingOriginalAlbumsInitialData,
        isLoadingMore: isLoadingOriginalAlbumMore,
        setSize: setOriginalAlbumsSize,
        size: originalAlbumsSize,
        mutate: mutateOriginalAlbums,
    } = useInfiniteSearchAlbum(originalsQuery);

    // Remixes
    const remixQuery = useMemo(
        () => ({
            ...query,
            limit: isDownload ? SONGS_LIMIT : ALBUMS_LIMIT,
            sort_by: isDownload ? query.sort_by : (undefined as unknown as SortByQueryOptions),
            hide_originals: true,
        }),
        [isDownload, query]
    );
    const {
        data: remixData,
        isLoadingInitialData: isLoadingRemixAlbumsInitialData,
        isLoadingMore: isLoadingRemixAlbumMore,
        setSize: setRemixAlbumsSize,
        size: remixAlbumsSize,
        mutate: mutateRemixAlbums,
    } = useInfiniteSearchAlbum(remixQuery);

    const remixedByQuery = useMemo(
        () => ({
            ...query,
            artist: undefined,
            remixer: query.artist,
            limit: isDownload ? SONGS_LIMIT : ALBUMS_LIMIT,
            sort_by: isDownload ? query.sort_by : (undefined as unknown as SortByQueryOptions),
        }),
        [isDownload, query]
    );
    const {
        data: remixByData,
        isLoadingInitialData: isLoadingRemixedByAlbumsInitialData,
        isLoadingMore: isLoadingRemixedByAlbumMore,
        setSize: setRemixedByAlbumsSize,
        size: remixedByAlbumsSize,
        mutate: mutateRemixedByAlbums,
    } = useInfiniteSearchAlbum(remixedByQuery);

    /* TODO: potentially refactor this so it uses Array.flatMap */
    const albums = useMemo(() => albumsData?.map(({ data: paginatedResponse }) => paginatedResponse), [albumsData]);

    const flattenAlbums = useMemo<Album[] | DownloadAlbum[]>(() => (albums ? [].concat(...albums) : []), [albums]);

    const originals = useMemo(() => originalsData?.map(({ data: paginatedResponse }) => paginatedResponse), [originalsData]);
    const flattenOriginals = useMemo<Album[] | DownloadAlbum[]>(() => (originals ? [].concat(...originals) : []), [originals]);

    const remixes = useMemo(() => remixData?.map(({ data: paginatedResponse }) => paginatedResponse), [remixData]);
    const flattenRemixes = useMemo<Album[] | DownloadAlbum[]>(() => (remixes ? [].concat(...remixes) : []), [remixes]);

    const remixedBy = useMemo(() => remixByData?.map(({ data: paginatedResponse }) => paginatedResponse), [remixByData]);
    const flattenRemixedBy = useMemo<Album[] | DownloadAlbum[]>(() => (remixedBy ? [].concat(...remixedBy) : []), [remixedBy]);

    const albumsPagination = albumsData?.[0]?.pagination;
    const originalsPagination = originalsData?.[0]?.pagination;
    const remixesPagination = remixData?.[0]?.pagination;
    const remixeedByPagination = remixByData?.[0]?.pagination;

    const loadMoreAlbums = useCallback(() => {
        if (isLoadingAlbumsMore || isLoadingOriginalAlbumMore || isLoadingRemixAlbumMore || isLoadingRemixedByAlbumMore) return;
        setAlbumsSize(albumsSize + 1);
        setOriginalAlbumsSize(originalAlbumsSize + 1);
        setRemixAlbumsSize(remixAlbumsSize + 1);
        setRemixedByAlbumsSize(remixedByAlbumsSize + 1);
    }, [isLoadingAlbumsMore, isLoadingOriginalAlbumMore, isLoadingRemixAlbumMore, isLoadingRemixedByAlbumMore,
        setAlbumsSize, albumsSize, setOriginalAlbumsSize, originalAlbumsSize,
        setRemixAlbumsSize, remixAlbumsSize, remixedByAlbumsSize, setRemixedByAlbumsSize]
    );

    const songList = useMemo(() => (isDownload ? (flattenAlbums as DownloadAlbum[]) : (songs as MediaWithAlbum[])), [flattenAlbums, songs, isDownload]);
    const originalList = useMemo(() => (isDownload ? (flattenOriginals as DownloadAlbum[]) : (songs as MediaWithAlbum[])), [flattenOriginals, songs, isDownload]);
    const remixList = useMemo(() => (isDownload ? (flattenRemixes as DownloadAlbum[]) : (songs as MediaWithAlbum[])), [flattenRemixes, songs, isDownload]);
    const remixedByList = useMemo(() => (isDownload ? (flattenRemixedBy as DownloadAlbum[]) : (songs as MediaWithAlbum[])), [flattenRemixedBy, songs, isDownload]);

    const songListCount = useMemo(() => (isDownload ? albumsPagination?.total || 0 : songsPagination?.total || 0), [albumsPagination?.total, isDownload, songsPagination?.total]);
    const originalListCount = useMemo(() => (isDownload ? originalsPagination?.total || 0 : songsPagination?.total || 0), [originalsPagination?.total, isDownload, songsPagination?.total]);
    const remixListCount = useMemo(() => (isDownload ? remixesPagination?.total || 0 : songsPagination?.total || 0), [remixesPagination?.total, isDownload, songsPagination?.total]);
    const remixedByListCount = useMemo(() => (isDownload ? remixeedByPagination?.total || 0 : songsPagination?.total || 0), [remixeedByPagination?.total, isDownload, songsPagination?.total]);

    const handleLoadMoreSongs = useCallback(() => (isDownload ? loadMoreAlbums() : loadMoreMedia()), [isDownload, loadMoreAlbums, loadMoreMedia]);

    const { data: artistFeaturedInData, isLoading: isFeaturedInDataLoading } = useGetArtistFeaturedIn(artist ? artist.id : undefined, libraryProperty);
    const { originalListDetails, setQueue, togglePlayPause } = usePlayer();
    const playerState = usePlayerState();
    const { resource } = usePageDetails();

    const { followArtist, unfollowArtist, isArtistFollowed } = useFollowArtist(artist ? artist.id : undefined);

    const handleFollow = useCallback(() => (isArtistFollowed ? unfollowArtist() : followArtist()), [unfollowArtist, followArtist, isArtistFollowed]);

    const isCurrentListInPlayer = useMemo(() => originalListDetails?.identifier === artistSlug && originalListDetails?.resource === resource, [originalListDetails, artistSlug, resource]);

    const handlePlaySongs = useCallback(() => {
        if (isCurrentListInPlayer) {
            togglePlayPause();
            return;
        }

        const queueItems = isDownload
            ? (songList as DownloadAlbum[]).map((album) => downloadAlbumWithMediaToQueueItem(album))
            : (songList as MediaWithAlbum[]).map((song) => streamMediaWithAlbumToQueueItem(song));

        setQueue(queueItems, 0, { identifier: artistSlug?.toString(), resource });
    }, [artistSlug, isCurrentListInPlayer, isDownload, resource, setQueue, songList, togglePlayPause]);

    const hasArtistInfo = !((artist?.description === null || artist?.description === '') && (artist?.social_media === null || artist?.social_media?.length === 0));

    const tabs = useMemo(() => [
        {
            id: ArtistDetailHeaderTabs.Music,
            label: 'Music',
            icon: <MusicNotesIcon />,
            activeIcon: <MusicNotesIconActive />
        },
        {
            id: ArtistDetailHeaderTabs.ArtistInfo,
            label: 'Artist Info',
            icon: <AvatarIcon />,
            activeIcon: <AvatarIconActive />
        }
    ], []);

    const ghostLoading = () => {
        return (
            <>
                <Title platform={hub} title="Loading Artist..." />
                <GhostComponent type="artist-detail" />
                <GhostComponent type="track-list" title={isMobile ? undefined : 'Songs'} elementsCount={8} hasTrackListTabs />
            </>
        );
    };

    if (!artistSlug
        || (!artist && !isArtistLoading)
        || isArtistLoading
        || isFeaturedInDataLoading
        || isLoadingAlbumsInitialData
        || isLoadingOriginalAlbumsInitialData
        || isLoadingRemixAlbumsInitialData
        || isLoadingRemixedByAlbumsInitialData
        || (!isDownload && isLoadingSongsInitialData)) return ghostLoading();

    const artistInfoComponent = () => {
        if (!hasArtistInfo) {
            return null;
        }
        return <ArtistDetailInfo bio={artist?.description as string} links={artist && artist.social_media ? artist.social_media : []} />;
    };

    const musicComponent = () => {
        return <ArtistDetailMusic
            songsListTotalCount={songListCount}
            songsList={songList}
            originalSongsList={originalList}
            originalSongsListTotalCount={originalListCount || 0}
            remixSongsList={remixList}
            remixSongsListTotalCount={remixListCount || 0}
            remixedBySongsList={remixedByList}
            remixedBySongsListTotalCount={remixedByListCount || 0}
            sortOptions={apiSortOptionsWithRelevance}
            selectedSortType={(sortBy || 'trending') as APISortingKeys}
            onSort={apiSortFunction}
            onDownloadRevalidate={(downloadMedia) => {
                if (downloadMedia) {
                    // eslint-disable-next-line no-param-reassign
                    downloadMedia.download_count += 1;
                    mutateAlbums(albumsData, false);
                    mutateOriginalAlbums(originalsData, false);
                    mutateRemixAlbums(remixData, false);
                    mutateRemixedByAlbums(remixByData, false);
                }
            }}
            albumsList={flattenAlbums as Album[]}
            featuredInList={artistFeaturedInData?.playlists}
            artworkCredit={artist?.artwork_credit}
            artistSlug={artist?.slug || ''}
            currentArtist={artist}
        />;
    };

    const artistButtonsComponents = () => {
        if (!hasArtistInfo) {
            return null;
        }
        return (
            <div className={styles['artist-detail--button-container']}>
                {
                    !isMobile ? tabs.map((tab) => (
                        <button
                            key={tab.id}
                            type="button"
                            className={classNames(styles['artist-detail--button-container--item'], {
                                [styles['artist-detail--button-container--item--active']]: tab.id === activeTab,
                            })}
                            role="tab"
                            onClick={() => setActiveTab(tab.id)}
                        >
                            <i className={classNames(styles['artist-detail--button-container--item--icon'])}>
                                {tab.id === activeTab ? tab.activeIcon : tab.icon}
                            </i>
                            {tab.label}
                        </button>
                    )) : (
                        <Toolbar>
                            <div className={styles['detail-mobile__tab-container']}>
                                {
                                    tabs.map((tab) => (
                                        <button
                                            key={tab.id}
                                            type="button"
                                            className={classNames(styles['detail-mobile--tab'], {
                                                [styles['detail-mobile--tab--active']]: tab.id === activeTab,
                                            })}
                                            role="tab"
                                            onClick={() => setActiveTab(tab.id)}
                                        >
                                            {tab.label}
                                        </button>
                                    ), [])
                                }
                            </div>
                        </Toolbar>
                    )
                }
            </div>
        );
    };

    return (
        <>
            <Title platform={hub} title={artist?.name} />
            <div className={styles['artist-detail']}>
                <ArtistDetailHeader
                    artistName={artist.name}
                    artistImageUrl={artist.artwork_url}
                    isArtistFollowed={isArtistFollowed}
                    isPlaying={isCurrentListInPlayer && playerState === State.Playing}
                    handlePlaySongs={handlePlaySongs}
                    handleFollow={handleFollow}
                />
            </div>
            <div className={styles['artist-detail']}>
                {artistButtonsComponents()}
            </div>
            {activeTab === ArtistDetailHeaderTabs.ArtistInfo ? artistInfoComponent() : musicComponent()}
        </>
    );
}

export default ArtistDetail;
