import { useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import styles from './text-switch.module.css';

export interface TextSwitchOption<T> {
    value: T;
    label: string;
}

export interface TextSwitchProps<T> {
    options: [TextSwitchOption<T>, TextSwitchOption<T>];
    value: T;
    onChange: (value: T) => void;
}

export function TextSwitch<T = string>({ options, value, onChange }: TextSwitchProps<T>) {
    const optionRefs = useRef<{ [key: string]: HTMLButtonElement }>({});
    const backgroundRef = useRef<HTMLDivElement>(null);

    const resizeBg = useCallback(() => {
        const el = optionRefs.current[value?.toString()];
        if (!el) return;
        const { width, left } = el.getBoundingClientRect();
        const { left: parentLeft } = el.parentElement.getBoundingClientRect();
        backgroundRef.current.style.width = `${width}px`;
        backgroundRef.current.style.transform = `translateX(${left - parentLeft}px)`;
    }, [value]);

    useEffect(() => {
        resizeBg();
        setTimeout(() => {
            resizeBg();
        }, 300);
        window.addEventListener('resize', resizeBg);
        return () => {
            window.removeEventListener('resize', resizeBg);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <div className={styles['text-switch']}>
            {options.map((option) => (
                <button
                    key={option.label}
                    type="button"
                    ref={(el) => {
                        optionRefs.current[option.value?.toString()] = el;
                    }}
                    aria-label={`Toggle ${option.label}`}
                    onClick={() => {
                        onChange(option.value);
                    }}
                    className={classNames(styles['text-switch__label'], styles['text-switch__label--download'], {
                        [styles['text-switch__label--active']]: value === option.value,
                    })}
                >
                    {option.label}
                </button>
            ))}
            <div className={styles['text-switch__background']} ref={backgroundRef} />
        </div>
    );
}

export default TextSwitch;
