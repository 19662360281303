import { useMediaQuery } from './useMediaQuery';

export const useViewport = () => {
    const isMobile = useMediaQuery(['(max-width: 1023px)'], [true], false);
    const isSmallMobile = useMediaQuery(['(max-width: 768px)'], [true], false);

    return {
        // --from-tablet-landscape breakpoint
        isMobile,
        isSmallMobile,
    };
};

export default useViewport;
