import { toast, ToastContainerProps, Icons } from 'react-toastify';
import { ToastCloseButton } from '@bpm-web-app/components/src/lib/bpm-toast/toast-close-button';
import BPMIcons from '@bpm-web-app/components/src/lib/shared/bpm-icons/bpm-icons';
import { LoadingIcon } from '@bpm-web-app/components/src/lib/shared/loading-icon/loading-icon';

type ToastType = 'error' | 'alert' | 'success' | 'info';

type ToastMessageBank =
    'Please enter the full activation code' |
    'The entered activation code is not correct' |
    'Please use custom order when changing order of tracks' |
    'File upload successful.' |
    'Switched to stream platform.' |
    'An error has occurred. Please try again.' |
    'Please answer all required questions.' |
    'Photo upload successful.' |
    'Password saved successfully.' |
    'Details saved successfully.' |
    'Deleted successfully.' |
    'Payment method updated successfully.' |
    'Please email supreme@bpmmusic.io for more information.' |
    'Please add payment method.' |
    'An error has occurred with paypal. Please try again.' |
    'Settings saved.' |
    'No changes detected.' |
    'Drive created successfully.' |
    'Copied to clipboard.' |
    'Drive folder created successfully.' |
    'Selected items downloaded successfully and removed from the crate.' |
    'Selected items were deleted successfully.' |
    'Download in progress.' |
    'Added to queue.' |
    'Please select one or more tracks.' |
    'Removed from queue.' |
    'Added to favorites.' |
    'Renamed successfully.' |
    'Download successful.' |
    'Reported successfully.' |
    'Added to crate.' |
    'Added to offline crate.' |
    'Removed from favorites.' |
    'Artist followed.' |
    'Artist unfollowed.' |
    'Curated Set followed.' |
    'Curated Set unfollowed.' |
    'Playlist followed.' |
    'Playlist unfollowed.' |
    'Folder created successfully.' |
    'Folder deleted successfully.' |
    'Playlist created.' |
    'Playlist deleted.' |
    'Playlist edited.' |
    'Folder edited.' |
    'Removed from playlist.' |
    'Added to playlist.' |
    'Added to playlist.' |
    'Removed from crate.' |
    'Credits required. Purchase credits now.' |
    'File upload required.' |
    'Remix submission limit reached.' |
    'Unavailable. Please try again later.' |
    'Playlist not found or no access.' |
    'Drive not found or no access.' |
    'This promo code is invalid. Please try again.' |
    'Parental control has been activated.' |
    'Parental control has been deactivated.' |
    'Download Limit Reached.';

type ToastButtonCTA =
    'Go To Crate' |
    'Go To Playlist' |
    'Go To Favorites' |
    'Go To Drive' |
    'Go To Folder' |
    'Undo' |
    'Contact' |
    'Open Queue' |
    'Add Credits' |
    'View Plans';

interface ShowToastProps<T> {
    type?: ToastType;
    title?: string;
    message?: ToastMessageBank;
    buttonText?: ToastButtonCTA;
    onButtonClick?: () => void;
    successText?: ToastMessageBank;
    errorText?: ToastMessageBank;
    preventErrorToast?: boolean;
    noProgress?: boolean
    autoClose?: number | false | undefined;
    routeOnButtonClick?: string
    replaceRouteOnButtonClick?: string;
    promise?: Promise<T>;
}

export function showToast<T = undefined>({ type, title = 'Success', noProgress, message, buttonText, onButtonClick, routeOnButtonClick, replaceRouteOnButtonClick, promise, successText, errorText, autoClose = 3000, preventErrorToast = false }: ShowToastProps<T>): Promise<T> {
    const closeButton = <ToastCloseButton title={buttonText} onClick={onButtonClick} routeOnClick={routeOnButtonClick} replaceRouteOnClick={replaceRouteOnButtonClick} />;
    const toastOptions: ToastContainerProps = {
        closeButton,
        autoClose: buttonText ? 5000 : autoClose,
    };

    const mainText = message || title;

    if (promise) {
        return toast.promise(
            promise,
            {
                pending: noProgress ? undefined : {
                    ...toastOptions,
                    icon: <LoadingIcon />,
                    render() {
                        return mainText;
                    },
                },
                success: {
                    ...toastOptions,
                    icon: Icons.success,
                    render() {
                        return successText || `Success ${mainText.toLowerCase()}`;
                    },
                },
                error: preventErrorToast ? undefined : {
                    ...toastOptions,
                    icon: Icons.error,
                    render() {
                        return errorText || `Error ${mainText.toLowerCase()}`;
                    }
                }
            }
        );
    }
    switch (type) {
        case 'alert':
            toast(mainText, {
                ...toastOptions,
                type: 'warning',
                icon: <BPMIcons.WarningIcon height={28} width={28} />
            });
            return Promise.resolve() as unknown as Promise<T>;
        case 'error':
            toast(mainText, {
                ...toastOptions,
                type: 'error',
            });
            return Promise.resolve() as unknown as Promise<T>;
        case 'info':
            toast(mainText, {
                ...toastOptions,
                type: 'info',
            });
            return Promise.resolve() as unknown as Promise<T>;
        case 'success':
            toast(mainText, {
                ...toastOptions,
                type: 'success',
                icon: <BPMIcons.CheckmarkIcon height={28} width={28} />
            });
            return Promise.resolve() as unknown as Promise<T>;
        default:
            return Promise.resolve() as unknown as Promise<T>;
    }
}
