import { useCallback, useMemo } from 'react';
import { UserPlaylistCategory } from '@bpm-web-app/download-api-sdk';
import { useUserPlaylistCategories } from '../shared/three-dots-sheet/useUserPlaylistCategories';
import Folder from '../../assets/icons/folder.svg';
import styles from './my-playlist-categories.module.css';
import { CustomIcon } from '../shared/custom-icon/custom-icon';

export interface MyPlaylistsCategoriesProps {
    selectCategory: (id: number) => void;
    showIcon?: boolean;
}

export function MyPlaylistsCategories({ selectCategory, showIcon = false }: MyPlaylistsCategoriesProps) {
    const { userPlaylistCategories, isLoading } = useUserPlaylistCategories();
    const folderIcon = useMemo(() => <Folder />, []);
    const sharedFolderIcon = useMemo(() => <CustomIcon hasIconHover type="shared-folder" size="regular" />, []);

    const renderFolderIcon = useCallback((item: UserPlaylistCategory) => {
        if (item.slug === 'shared') return sharedFolderIcon;
        return folderIcon;
    }, [folderIcon, sharedFolderIcon]);

    if (!isLoading && userPlaylistCategories?.data) {
        return (
            <>
                {userPlaylistCategories.data.map((item) => (
                    <li key={item.id} className={styles['my-playlists-category-item']}>
                        <button
                            type="button"
                            className={styles['my-playlists-category-item__button']}
                            onClick={(e) => {
                                e.preventDefault();
                                selectCategory(item.id);
                            }}
                        >
                            {showIcon ? renderFolderIcon(item) : null}
                            <span>{item.name}</span>
                        </button>
                    </li>
                ))}
            </>
        );
    }

    return null;
}

export default MyPlaylistsCategories;
