import classNames from 'classnames';
import { useCreateGenres, useTrending } from '@bpm-web-app/swr-hooks';
import { useState, Fragment, useMemo } from 'react';
import {
    DEFAULT_DROPDOWN_CATEGORY,
    useViewport,
    useCreateFilterParams,
} from '@bpm-web-app/utils';
import { SortByQueryOptions, SoundPackage } from '@bpm-web-app/create-api-sdk';
import { useRouter } from 'next/router';
import styles from './trending-packs.module.css';
import Dropdown from '../../dropdown/dropdown';
import CardGrid from '../../shared/card-grid/card-grid';
import Filters from '../../filters/filters';
import { PageNotFound } from '../page-not-found/page-not-found';
import { CardCarouselGhostLoading } from '../../shared/card-carousel/card-carousel-ghost-loading';
import { EmptyState } from '../../shared';
import { PackCardCreate } from '../../shared/card/pack-card-create/pack-card-create';

export function TrendingPacks() {
    const router = useRouter();

    const [activeGenre, setActiveGenre] = useState<string>(DEFAULT_DROPDOWN_CATEGORY.value);
    const { genres: genresData } = useCreateGenres();
    const { isMobile } = useViewport();
    const { sortBy } = router.query;

    const overrides = useMemo(() => {
        if (sortBy) {
            return { sort_by: `${sortBy}` as unknown as SortByQueryOptions, limit: 50 };
        }
        return { limit: 50 };
    }, [sortBy]);
    const query = useCreateFilterParams(overrides);

    const { data: trending, isLoading, error } = useTrending('create', { ...query, genre: activeGenre });

    if (!isLoading && (error || !trending)) return <PageNotFound />;

    return (
        <div className={classNames(styles['trending-pack'], 'spacing__window')}>
            <div className={styles['trending-pack__intro']}>
                <h1 className={styles['trending-pack__title']}>Trending</h1>
                <Filters hideCreateGenres showOnMobile platform="create" />
            </div>
            <div className={styles['trending-pack__sub-intro']}>
                <h2 className={styles['trending-pack__subtitle']}>Packs</h2>
                <div className={styles['trending-pack__period-dropdown']}>
                    {genresData?.length && (
                        <Dropdown<string>
                            onClick={setActiveGenre}
                            value={activeGenre}
                            options={[
                                DEFAULT_DROPDOWN_CATEGORY,
                                ...(genresData?.map((genre) => ({
                                    label: genre.name,
                                    value: genre.name,
                                })) || []),
                            ]}
                        />
                    )}
                </div>
            </div>

            {trending?.data.length === 0 && !isLoading ? (
                <EmptyState title={`No ${activeGenre} Packs Found`} subtitle="" noPadding icon="search-outline-icon" variant="white" />
            ) : null}

            {!isLoading ? (
                <div className={styles['trending-pack__grid-container']}>
                    <CardGrid cardType="card-small">
                        {trending?.data.map((item, index) => (
                            <Fragment key={item.id}>
                                <PackCardCreate item={item as SoundPackage} items={trending?.data as SoundPackage[]} showTrendingTags index={index} />
                            </Fragment>
                        ))}
                    </CardGrid>
                </div>
            ) : (
                <CardCarouselGhostLoading elementsCount={12} cardSize="small" grid />
            )}
        </div>
    );
}

export default TrendingPacks;
