import { MediaApi } from '@bpm-web-app/download-api-sdk';
import { downloadConfig } from '../../config';
import { SimplePaginatedSearchQuery } from '../../typings/simple-paginated-search-query';
import { SearchPaginatedQuery } from '../../typings/search-query';

export class Media {
    static client = new MediaApi(downloadConfig);

    static getUserDownloadHistory = (query: SimplePaginatedSearchQuery) =>
        Media.client.getDownloadHistory(query.limit, query.skip, query.term, query.category, query.library);

    static getDownloadUrlForMedia = (mediaId: number, from_location: string, from_library: string, isFromCrate?: boolean) =>
        Media.client.getDownloadUrl(mediaId, from_location, from_library, isFromCrate ? '1' : undefined);

    static downloadMultipleMedia = (from_location: string, from_library: string, mediaIds: number[], idempotent_token: string, fromCrate = false) =>
        Media.client.downloadMediaMulti(from_location, from_library, { media_ids: mediaIds }, idempotent_token, fromCrate ? '1' : '');

    static getAlbumById = (id: number) => Media.client.getAlbum(id);

    static searchAlbum = (args: SearchPaginatedQuery) =>
        Media.client.searchAlbum(
            args.limit,
            args.skip,
            args.category,
            args.library,
            args.genre as string,
            args.artist,
            args.hide_remix ? '1' : '0',
            args.hide_originals ? '1' : '0',
            args.hide_exclusives ? '1' : '0',
            args.hide_explicit ? '1' : '0',
            args.hide_prev_downloaded ? '1' : '0',
            args.version as string,
            args.key as string,
            args.keyType,
            args.bpm_min,
            args.bpm_max,
            args.sort_by,
            args.term,
            args.date
        );
}
