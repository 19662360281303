import { ReactElement, ReactNode, useCallback } from 'react';
import { Artist, Category, Genre } from '@bpm-web-app/download-api-sdk';
import classNames from 'classnames';
import styles from './card-description.module.css';
import { useArtistLinks } from '../../../artist-link/artist-link';
import { useBpmLink, useKeyLinks, useGenreLinks, useCuratedSetCategoryLinks, usePlaylistCategoryLinks } from '../../../generate-link/generate-link';

export interface SupremeCardDescriptionProps {
    artists?: Artist[],
    artist?: string,
    bpm?: number,
    actualKey?: string,
    key?: string,
    genre?: Genre
    curatedSetCategory?: Category;
    playlistCategory?: Category;
}

interface CardDescriptionProps {
    isDescriptionSingleLine?: boolean,
    description?: ReactNode;
    supremeCardDescriptionProps?: SupremeCardDescriptionProps;
}

export function CardDescription({
    isDescriptionSingleLine = false,
    description,
    supremeCardDescriptionProps,
}: CardDescriptionProps): ReactElement | null {
    const generateArtistLinks = useArtistLinks();
    const generateBPMLinks = useBpmLink();
    const generateKeyLinks = useKeyLinks();
    const generateGenreLinks = useGenreLinks();
    const generatePlaylistCategoryLinks = usePlaylistCategoryLinks();
    const generateCuratedSetCategoryLinks = useCuratedSetCategoryLinks();

    const getDescriptionMarkup = useCallback(
        ({ artists, artist, bpm, actualKey, key, genre, playlistCategory, curatedSetCategory }: SupremeCardDescriptionProps) => (
            <div className={styles['card-description']}>
                {artist && artists ? <div className={styles['card-description__artist']}>{generateArtistLinks(artist, artists)}</div> : null}
                {bpm || actualKey || key || genre || curatedSetCategory || playlistCategory ? (
                    <div className={styles['card-description__card-details']}>
                        {bpm ? <span>{generateBPMLinks(bpm)}</span> : null}
                        {actualKey || key ? <span>{generateKeyLinks(actualKey, key)}</span> : null}
                        {genre ? <span>{generateGenreLinks(genre)}</span> : null}
                        {curatedSetCategory ? <span>{generateCuratedSetCategoryLinks(curatedSetCategory)}</span> : null}
                        {playlistCategory ? <span>{generatePlaylistCategoryLinks(playlistCategory)}</span> : null}
                    </div>
                ) : null}
            </div>
        ),
        [generateArtistLinks, generateBPMLinks, generateCuratedSetCategoryLinks, generateGenreLinks, generateKeyLinks, generatePlaylistCategoryLinks]
    );

    if (supremeCardDescriptionProps) {
        return getDescriptionMarkup(supremeCardDescriptionProps);
    }

    if (description === undefined) {
        return null;
    }

    return (isDescriptionSingleLine ? <div className={classNames(styles['card-description__single-line-description'], styles['card-description'])}>{description}</div> : (description as ReactElement));
}
