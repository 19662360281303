import { PropsWithChildren, createContext, useContext, useMemo, useState } from 'react';
import { CreatePlaylistForm } from './playlists-form-create-playlist';
import { useUserPlaylistCategories } from '../shared/three-dots-sheet/useUserPlaylistCategories';
import { useUserPlaylists } from '../shared/three-dots-sheet/useUserPlaylists';
import { useUserPlaylistsDetails } from '../shared/three-dots-sheet/useUserPlaylistsDetails';

type CreatePlaylistProps = {
    category?: string;
    albums: { album_id: number }[];
};

type CreatePlaylistFormContextValue = {
    openCreatePlaylistForm: (defaultValues?: CreatePlaylistProps) => void;
};

const CreatePlaylistFormContext = createContext<CreatePlaylistFormContextValue | undefined>(undefined);

export function CreatePlaylistFormProvider({ children }: PropsWithChildren<any>) {
    const [isOpen, setIsOpen] = useState(false);
    const [props, setProps] = useState<CreatePlaylistProps | undefined>(undefined);
    const { createPlaylist } = useUserPlaylists(props?.category, false);
    const { addAlbumToPlaylist } = useUserPlaylistsDetails();

    const { userPlaylistCategories } = useUserPlaylistCategories();

    const openCreatePlaylistForm = (values?: CreatePlaylistProps) => {
        setProps(values);
        setIsOpen(true);
    };

    const onSubmit = async (data: { name: string, category: string }) => {
        if (!props) return;
        const res = await createPlaylist(data.name, data.category === undefined);
        if (!res) return;
        await addAlbumToPlaylist(res.data.id, { albums: props.albums });
    };

    const onClose = () => {
        setProps(undefined);
        setIsOpen(false);
    };

    const value = useMemo(() => {
        return {
            openCreatePlaylistForm,
        };
    }, []);

    return (
        <CreatePlaylistFormContext.Provider value={value}>
            {isOpen ? (
                <CreatePlaylistForm defaultValues={{ category: props?.category }} categories={userPlaylistCategories?.data} onClose={onClose} onSubmit={onSubmit} />
            ) : null}
            {children}
        </CreatePlaylistFormContext.Provider>
    );
}

export function useCreatePlaylistForm() {
    const value = useContext(CreatePlaylistFormContext);
    if (!value) {
        throw new Error('useCreatePlaylistForm must be used within a CreatePlaylistFormProvider');
    }

    return value;
}
