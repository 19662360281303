import { isServerSide, useApiErrorHandler } from '@bpm-web-app/utils';
import { ReactNode } from 'react';
import { SWRConfig } from 'swr';

// eslint-disable-next-line @typescript-eslint/dot-notation
export const LOCAL_CACHE_KEY = `app-cache-${process.env['NODE_ENV']}`;

export function cacheProvider() {
    let map = new Map();
    try {
        map = new Map(JSON.parse(localStorage.getItem(LOCAL_CACHE_KEY) || '[]'));
        // eslint-disable-next-line no-empty
    } catch (error) {

    }
    window.addEventListener('beforeunload', () => {
        const mapToStore = new Map(map);
        map.forEach((entry, key) => {
            const persist = (key as string).includes('persist:true');
            if (persist) {
                mapToStore.set(key, entry);
            }
        });
        const appCache = JSON.stringify(Array.from(mapToStore.entries()));
        try {
            localStorage.setItem(LOCAL_CACHE_KEY, appCache);
            // eslint-disable-next-line no-empty
        } catch (error) {
        }
    });

    return map;
}

export interface SWRConfigProviderProps {
    children: ReactNode;
    isCreate?: boolean;
    isArtistPortal?: boolean
}

export const SWRConfigProvider = ({ children, isCreate, isArtistPortal }: SWRConfigProviderProps) => {
    const errorHandler = useApiErrorHandler(isCreate, isArtistPortal);
    return (
        <SWRConfig
            value={{
                revalidateOnFocus: false,
                shouldRetryOnError: false,
                refreshWhenHidden: true,
                provider: isServerSide() ? () => new Map() : cacheProvider,
                onError: (error) => {
                    errorHandler({ error });
                },
            }}
        >
            {children}
        </SWRConfig>
    );
};
