import { ReactNode } from 'react';

export enum TrackListPresetSupreme {
    Stream = 'stream',
    Queue = 'queue',
    Download = 'download',
    DownloadMaxi = 'download-maxi',
    StreamMaxi = 'stream-maxi',
    StreamTrending = 'stream-trending',
    DownloadTrending = 'download-trending',
    // Create = 'create',
    Album = 'album',
    OnlineCrate = 'online-crate',
    OfflineCrate = 'offline-crate',
}

export enum ColumnTypeSupreme {
    Title = 'title',
    Bpm = 'bpm',
    Key = 'key',
    Time = 'time',
    Date = 'date',
    Genre = 'genre',
    Versions = 'versions',
    Count = 'count',
    TrendingCount = 'trending-count',
    StreamActions = 'stream-actions',
    // DownloadActions = 'download-actions',
    Accordion = 'accordion',
    EditModeCheckbox = 'edit-mode-checkbox',
    DownloadablePill = 'downloadable-pill',
    // Empty = 'empty',
    OnlineCrateActions = 'online-crate-actions',
    OfflineCrateActions = 'offline-crate-actions',
    QuickActions = 'quick-actions',
}

interface ColumnDefinitionSupreme {
    heading: ReactNode;
    type: ColumnTypeSupreme;
    class?: string;
}

const commonColumns = (firstColumnType: ColumnTypeSupreme) => [
    { heading: '', type: firstColumnType },
    { heading: 'Title', type: ColumnTypeSupreme.Title },
    { heading: 'BPM', type: ColumnTypeSupreme.Bpm },
    { heading: 'Key', type: ColumnTypeSupreme.Key },
];

export const columnDefinitionsSupreme: { [key in TrackListPresetSupreme]?: ColumnDefinitionSupreme[] } = {
    [TrackListPresetSupreme.Stream]: [
        ...commonColumns(ColumnTypeSupreme.Count),
        { heading: 'Length', type: ColumnTypeSupreme.Time },
        { heading: 'Genre', type: ColumnTypeSupreme.Genre },
        {
            heading: 'placeholder',
            type: ColumnTypeSupreme.StreamActions,
        },
    ],
    [TrackListPresetSupreme.Download]: [
        ...commonColumns(ColumnTypeSupreme.Accordion),
        { heading: 'Genre', type: ColumnTypeSupreme.Genre },
        { heading: 'Download', type: ColumnTypeSupreme.Versions },
        { heading: 'Added', type: ColumnTypeSupreme.Date },
        {
            heading: 'placeholder',
            type: ColumnTypeSupreme.QuickActions,
        },
    ],
    [TrackListPresetSupreme.Album]: [
        ...commonColumns(ColumnTypeSupreme.Count),
        { heading: 'Length', type: ColumnTypeSupreme.Time },
        {
            heading: 'placeholder',
            type: ColumnTypeSupreme.StreamActions,
        },
    ],
    [TrackListPresetSupreme.OnlineCrate]: [
        /* The placeholder will be replaced by the "Select All" checkbox.
         * See the logic in TrackList for more info. */
        ...commonColumns(ColumnTypeSupreme.EditModeCheckbox),
        { heading: 'Genre', type: ColumnTypeSupreme.Genre },
        { heading: 'Versions', type: ColumnTypeSupreme.DownloadablePill },
        { heading: 'Added', type: ColumnTypeSupreme.Date },
        {
            heading: 'placeholder',
            type: ColumnTypeSupreme.OnlineCrateActions,
        },
    ],
    [TrackListPresetSupreme.OfflineCrate]: [
        /* The placeholder will be replaced by the "Select All" checkbox.
         * See the logic in TrackList for more info. */
        ...commonColumns(ColumnTypeSupreme.EditModeCheckbox),
        { heading: 'Genre', type: ColumnTypeSupreme.Genre },
        { heading: 'Added', type: ColumnTypeSupreme.Date },
        {
            heading: 'placeholder',
            type: ColumnTypeSupreme.OfflineCrateActions,
        },
    ],
    [TrackListPresetSupreme.Queue]: [
        /* If the queue is in edit mode, the hash will be replaced by a "select all" checkbox.
         * See the logic in TrackList for more info. */
        ...commonColumns(ColumnTypeSupreme.EditModeCheckbox),
        { heading: 'Genre', type: ColumnTypeSupreme.Genre },
        { heading: 'Download', type: ColumnTypeSupreme.Versions },
        {
            heading: 'placeholder',
            type: ColumnTypeSupreme.QuickActions,
        },
    ],
    [TrackListPresetSupreme.DownloadMaxi]: [
        /* If the queue is in edit mode, the hash will be replaced by a "select all" checkbox.
         * See the logic in TrackList for more info. */
        ...commonColumns(ColumnTypeSupreme.Accordion),
        { heading: 'Genre', type: ColumnTypeSupreme.Genre },
        { heading: 'Download', type: ColumnTypeSupreme.Versions },
        {
            heading: 'placeholder',
            type: ColumnTypeSupreme.QuickActions,
        },
    ],
    [TrackListPresetSupreme.DownloadTrending]: [
        { heading: '', type: ColumnTypeSupreme.TrendingCount },
        { heading: 'Title', type: ColumnTypeSupreme.Title },
        { heading: 'BPM', type: ColumnTypeSupreme.Bpm },
        { heading: 'Key', type: ColumnTypeSupreme.Key },
        { heading: 'Genre', type: ColumnTypeSupreme.Genre },
        { heading: 'Download', type: ColumnTypeSupreme.Versions },
        { heading: 'Added', type: ColumnTypeSupreme.Date },
        {
            heading: 'placeholder',
            type: ColumnTypeSupreme.QuickActions,
        },
    ]
    // rest to be implemented
};
