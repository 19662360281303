import { State as PlayerStateEnum } from '@bpm-web-app/utils';

export const sliderStyles = {
    supremeTrackStyle: { backgroundColor: '#f73e00' } as React.CSSProperties,
    createTrackStyle: { backgroundColor: '#1af085' } as React.CSSProperties,
    handleStyle: {
        borderColor: ' #919191',
        height: 14,
        width: 14,
        backgroundColor: 'white',
    } as React.CSSProperties,
};

export interface MiniPlayerProps {
    playerState: PlayerStateEnum;
}
