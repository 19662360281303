import React, { MouseEvent, useCallback } from 'react';
import { useDrag } from '@use-gesture/react';
import { SoundWave } from '@bpm-web-app/create-api-sdk';

import styles from './create-wave.module.css';

interface CreateWaveProps {
    wave: SoundWave;
    handleSeek: (to: number) => void;
    elapsed: number;
    duration: string;
    isPaused: boolean;
}

export function CreateWave({ wave, handleSeek, elapsed, duration, isPaused }: CreateWaveProps) {
    const elapsedPercentage = (elapsed / +duration) * 100;

    const onClick = useCallback(
        (e: MouseEvent) => {
            const { width, left } = e.currentTarget.getBoundingClientRect();
            const offset = e.clientX - left;
            let percentage = (offset / width) * 100;
            if (percentage < 0) percentage = 0;
            if (percentage > 100) percentage = 100;
            handleSeek((+duration / 100) * percentage);
        },
        [duration, handleSeek]
    );

    if (!wave?.gray || !wave?.green || !wave?.light) return null;

    return (
        <div
            role="progressbar"
            aria-valuenow={elapsedPercentage}
            tabIndex={0}
            aria-valuemin={0}
            aria-live="assertive"
            aria-valuemax={100}
            className={styles['create-wave']}
            onClick={onClick}
            onKeyDown={() => { }}
        >
            <img className={styles['create-wave__wave']} src={wave.gray} alt="" draggable={false} />
            <div className={styles['create-wave__elapsed-wrapper']} style={{ width: `${elapsedPercentage}%` }}>
                <div
                    className={styles['create-wave__wave--elapsed']}
                    style={{
                        width: `calc(100% / ${elapsedPercentage / 100})`,
                        backgroundImage: `url(${!isPaused && elapsed > 0 ? wave.green : wave.light})`,
                    }}
                />
            </div>
        </div>
    );
}
