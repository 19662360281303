import useSWR, { useSWRConfig } from 'swr';
import { UserPlaylist } from '@bpm-web-app/api-client';
import { showToast, useApiErrorHandler } from '@bpm-web-app/utils';

export function userPlaylistDetailSWRKey(isDownload: boolean, playlistId: number | string) {
    return isDownload ? `download-user-playlist-detail-${playlistId}` : `stream-user-playlist-detail-${playlistId}`;
}

export type UserPlaylistResponse = Awaited<ReturnType<typeof UserPlaylist['getUserPlaylistDetail']>>;

export function useUserPlaylistDetail(isDownload: boolean, playlistId: string, disabled?: boolean) {
    const errorHandler = useApiErrorHandler(false);
    const { data, error, mutate } = useSWR<UserPlaylistResponse>([userPlaylistDetailSWRKey(isDownload, playlistId), { persist: true }],
        () => UserPlaylist.getUserPlaylistDetail(isDownload, playlistId),
        {
            isPaused: () => !!disabled,
            onError(err) {
                if (err.status === 404 || err.status === 403) {
                    showToast({ type: 'error', message: 'Playlist not found or no access.' });
                } else { errorHandler({ error: err }); }
            },
        });

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}

type UserPlaylistsResponse = Awaited<ReturnType<typeof UserPlaylist['getPlaylistSetsByCatId']>>;

export const getUserPlaylistsByCategoryIdKey = (isDownload: boolean, categoryId: number | string, include_view_only?: boolean) => [`get-user-playlists-${categoryId}-${isDownload}`, { isDownload, include_view_only, persist: true }];

export function useUserPlaylistSetsByCatId(isDownload: boolean, categoryId: number | string, include_view_only?: boolean) {
    const { mutate } = useSWRConfig();
    const { data, error } = useSWR<UserPlaylistsResponse>(getUserPlaylistsByCategoryIdKey(isDownload, categoryId, include_view_only),
        () => UserPlaylist.getPlaylistSetsByCatId(isDownload, categoryId, include_view_only),
        {
            isPaused: () => !categoryId,
        }
    );

    return {
        data,
        mutate: () => {
            mutate(getUserPlaylistsByCategoryIdKey(isDownload, categoryId, true), (d: any) => d, { revalidate: true });
            mutate(getUserPlaylistsByCategoryIdKey(isDownload, categoryId, false), (d: any) => d, { revalidate: true });
            mutate(getUserPlaylistsByCategoryIdKey(isDownload, categoryId, undefined), (d: any) => d, { revalidate: true });
        },
        isLoading: !error && !data && Boolean(categoryId),
        error,
    };
}

type UserPlaylistSetsInvitesResponse = Awaited<ReturnType<typeof UserPlaylist['getUserPlaylistInvites']>>;

export function useUserPlaylistInvites(playlistId: string) {
    const { data, error, mutate } = useSWR<UserPlaylistSetsInvitesResponse>(
        [
            'get-user-playlists-invites',
            {
                playlistId,
                persist: true
            }
        ],
        () => UserPlaylist.getUserPlaylistInvites(playlistId),
        {
            onError(err, key, config) {

            },
        }
    );

    return {
        data,
        mutate,
        isLoading: !error && !data,
        error,
    };
}
