import { UserPlaylist } from '@bpm-web-app/api-client';
import { UserPlaylistCollaborationAccessLevel, UserPlaylistWithAlbum, UserPlaylistWithUser } from '@bpm-web-app/download-api-sdk';
import { Analytics, getCurrentPlatformLink, useHubSwitch, useViewport } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import PlaylistIcon from '../../assets/icons/playlist-item-dynamic.svg';
import Droppable, { ALL_TYPES_NO_ARTIST, DragDropItem, DragResult } from '../droppable/droppable';
import categoryStyles from '../nav-my-playlist-categories/nav-my-playlist-categories.module.css';
import { useActionModal } from '../shared';
import { useUserPlaylists } from '../shared/three-dots-sheet/useUserPlaylists';
import { useUserPlaylistsDetails } from '../shared/three-dots-sheet/useUserPlaylistsDetails';
import styles from './nav-my-playlist-list.module.css';
import IconShared from './shared-icon.svg';

export interface NavMyPlaylistListItemProps {
    isDragging?: boolean;
    containsActiveInList: boolean;
    item: UserPlaylistWithUser;
    indented?: boolean;
}

export function NavMyPlaylistListItem({ item, indented = true, containsActiveInList, isDragging }: NavMyPlaylistListItemProps) {
    const router = useRouter();
    const { isMobile } = useViewport();
    const { openModal: openActionModal, closeModal: closeActionModal } = useActionModal();
    const { addAlbumToPlaylist } = useUserPlaylistsDetails();
    const { editPlaylistCategory, removePlaylistFromCategory } = useUserPlaylists(item.category_id ?? '', true);
    const { isDownload } = useHubSwitch();

    const handleClick = (path: string) => {
        router.push(path);
    };

    // eslint-disable-next-line @typescript-eslint/dot-notation
    const isActive = useCallback((id: string) => router.query['playlistId'] && router.query['playlistId'] === id, [router]);

    const getUserPlaylists = useCallback(async (id) => {
        const { data } = await UserPlaylist.getUserPlaylistDetail(isDownload, `${id}`);
        return data;
    }, [isDownload]);

    // eslint-disable-next-line no-empty-pattern
    const [{ }, dragRef, connectDragPreview] = useDrag({
        item: { type: 'playlist', id: item.id, title: item.title, album_count: item.album_count },
        canDrag: () => {
            if (item?.users?.length > 1) {
                return false;
            }
            return !isMobile;
        },
        end: async (draggedItem, monitor) => {
            if (monitor.didDrop()) {
                const { target, id } = monitor.getDropResult() as DragResult;

                const dropResult = monitor.getDropResult() as DragDropItem;
                if (dropResult && target === 'playlist') {
                    // fetch current playlist contents
                    const list: UserPlaylistWithAlbum = await getUserPlaylists(item.id) as any;

                    if (dropResult.id === item.id) {
                        return;
                    }

                    openActionModal({
                        title: 'Add to Playlist?',
                        subtitle: `You are about to add ${item.album_count ? ` ${item.album_count}` : ''}tracks from the playlist "${item.title}" to "${dropResult?.title}" playlist, do you want to proceed?`,
                        confirmButtonText: 'Add Tracks to Playlist',
                        cancelButtonText: 'Cancel',
                        shouldCloseOnOverlayClick: true,
                        variant: 'dark',
                        onConfirm: async () => {
                            closeActionModal();
                            if (list && list.albums) {
                                await addAlbumToPlaylist(id as string, { albums: list.albums.map((a) => ({ album_id: a.id })) });
                            }
                        },
                        onClose: closeActionModal,
                    });
                } else if (dropResult && target === 'playlist-category') {
                    if (dropResult.id === item.category_id) {
                        return;
                    }
                    openActionModal({
                        title: 'Move Playlist?',
                        subtitle: `You are about to move this playlist "${item.title}" into "${dropResult?.title}" folder, do you want to proceed?`,
                        confirmButtonText: 'Move Playlist',
                        cancelButtonText: 'Cancel',
                        shouldCloseOnOverlayClick: true,
                        variant: 'dark',
                        onConfirm: async () => {
                            closeActionModal();
                            await editPlaylistCategory(item.id, String(dropResult.id));
                        },
                        onClose: closeActionModal,
                    });
                } else if (dropResult && target === 'my-playlists') {
                    if (item.category_id === null) {
                        return;
                    }
                    openActionModal({
                        title: 'Move Playlist?',
                        subtitle: "You're about to remove this playlist from a folder, do you want to proceed?",
                        confirmButtonText: 'Remove from Folder',
                        cancelButtonText: 'Cancel',
                        shouldCloseOnOverlayClick: true,
                        variant: 'dark',
                        onConfirm: async () => {
                            closeActionModal();
                            await removePlaylistFromCategory(item.id);
                        },
                        onClose: closeActionModal,
                    });
                }
            }
        },
        type: 'UserPlaylist-Album'
    });

    connectDragPreview(getEmptyImage(), { captureDraggingState: true });

    return (
        <div ref={dragRef}>
            <Droppable
                accept={ALL_TYPES_NO_ARTIST}
                onDrop={(type) => {
                    return { target: 'playlist', id: item.id, title: item.title, };
                }}
                disabled={item.access_level === UserPlaylistCollaborationAccessLevel.View}
                shallow
            >
                {(state) => {
                    const classes = indented
                    ? classNames(styles['nav-my-playlist-list-item'], {
                        [styles['nav-my-playlist-list-item--active']]: isActive(item.id),
                        [styles['nav-my-playlist-list-item--indented']]: indented,
                        [styles['nav-my-playlist-list-item--is-dragging']]: isDragging,
                        [styles['nav-my-playlist-list-item--is-dragging--over']]: state.isOver,
                    })
                    : classNames(styles['nav-my-playlist-list-item'], styles['nav-my-playlist-list-item--link'], styles['nav-my-playlist-list-item--no-overflow'], {
                        [styles['nav-my-playlist-list-item--is-dragging']]: isDragging,
                        [styles['nav-my-playlist-list-item--is-dragging--over']]: state.isOver,
                        [styles['nav-my-playlist-list-item--active']]: isActive(item.id),
                        [styles['nav-my-playlist-list-item--active-hover']]: containsActiveInList,
                    });

                    return (
                        <button
                            type="button"
                            disabled={isDragging && item.access_level === UserPlaylistCollaborationAccessLevel.View}
                            aria-label="Go to playlist"
                            onClick={() => {
                            handleClick(getCurrentPlatformLink(`/my-playlist/${item.id}`));
                            Analytics.trackClick('my_playlist', item.id, { location: 'nav_my_playlist' });
                            }}
                            className={classes}
                        >
                            <i className={classNames(categoryStyles['nav-my-playlists-category-item__button'])}>
                                <PlaylistIcon />
                            </i>
                            <span>{item.title}</span>

                            {item.users && item.users.length > 0 ? <IconShared className={styles['nav-my-playlist-list-item__icon']} /> : null}
                        </button>
                    );
                }}
            </Droppable>
        </div>
    );
}

export default NavMyPlaylistListItem;
