import { DriveApi, DriveIdBody, DriveinvitesIdBody, DrivesBody, IdInviteBody } from '@bpm-web-app/create-api-sdk';
import { createConfig } from '../../config';

export class Drive {
    static client = new DriveApi(createConfig);

    static getDrives = (include_view_only?: boolean) => Drive.client.getDrives(include_view_only);

    static getDrive = (driveId: string) => Drive.client.getDrive(driveId);

    static createDrive = (data: DrivesBody) => Drive.client.createDrives(data);

    static deleteDrive = (driveId: string) => Drive.client.deleteDrive(driveId);

    static addSoundToDrive = (driveId: string, soundId: string) => Drive.client.addSoundToDrive(driveId, soundId);

    static removeSoundFromDrive = (driveId: string, soundId: string) => Drive.client.removeSoundFromDrive(driveId, soundId);

    static updateDriveName = (driveId: string, body: DriveIdBody) => Drive.client.updateDrive(driveId, body);

    static getSharedDrives = (include_view_only: boolean) => Drive.client.getSharedDrives(include_view_only);

    static getInvitesToDrive = (driveId: string) => Drive.client.getInvitesForDrive(driveId);

    static inviteToDrive = (driveId: string, body: IdInviteBody) => Drive.client.inviteUserToDrive(driveId, body);

    static editInviteToDrive = (driveId: string, body: DriveinvitesIdBody) => Drive.client.updateUserDriveInvite(driveId, body);

    static removeUserFromDrive = (driveId: string) => Drive.client.removeUserFromDrive(driveId);

    static acceptInviteToDrive = (inviteId: string) => Drive.client.acceptDriveInvite(inviteId);

    static declineInviteToDrive = (inviteId: string) => Drive.client.declineDriveInvite(inviteId);

    static copyDrive = (driveId: string) => Drive.client.copyDriveToOwn(driveId);

    static downloadDrive = (driveId: string, location: string) => Drive.client.downloadDrive(driveId, location);

    static downloadDrivePreview = (driveId: string, location: string) => Drive.client.downloadDrivePreview(driveId, location);
}
