import { Album } from '@bpm-web-app/api-client';
import { getFavoriteAlbums } from '@bpm-web-app/swr-hooks';
import { useApiErrorHandler, useHubSwitch, showToast, Analytics } from '@bpm-web-app/utils';
import { useContext, useEffect, useState } from 'react';
import { LibraryTabsContext } from '../../../../../utils/src/lib/library-tabs.context';

export const useFavoriteAlbums = (albumId: number) => {
    const { libraryProperty } = useContext(LibraryTabsContext);
    const { data: favAlbums, isLoading, mutate: mutateFavAlbums } = getFavoriteAlbums(libraryProperty);
    const errorHandler = useApiErrorHandler();
    const { isDownload } = useHubSwitch();
    const [isAlbumFavorite, setIsAlbumFavorite] = useState<boolean>();

    useEffect(() => {
        const isFav = favAlbums?.data.some(({ id }) => id === albumId);

        setIsAlbumFavorite(isFav);
    }, [favAlbums, albumId]);

    const addToFavorites = async () => {
        setIsAlbumFavorite(true);
        try {
            mutateFavAlbums(await Album.addAlbumToFavorites(isDownload, albumId));
            Analytics.trackClick('add_to_favorites_album', albumId.toString());
            showToast({ type: 'success', message: 'Added to favorites.', buttonText: 'Go To Favorites', replaceRouteOnButtonClick: '/favorites' });
        } catch (error) {
            errorHandler({ error });
            setIsAlbumFavorite(false);
        }
    };

    const removeFromFavorites = async () => {
        setIsAlbumFavorite(false);
        try {
            mutateFavAlbums(await Album.removeAlbumFromFavorites(isDownload, albumId));
            showToast({ type: 'success', message: 'Removed from favorites.', buttonText: 'Go To Favorites', replaceRouteOnButtonClick: '/favorites' });
        } catch (error) {
            errorHandler({ error });
            setIsAlbumFavorite(true);
        }
    };

    return {
        favoriteAlbums: favAlbums,
        isLoading,
        addAlbumToFavorites: addToFavorites,
        removeAlbumFromFavorites: removeFromFavorites,
        isAlbumFavorite,
        mutate: mutateFavAlbums,
    };
};

export default useFavoriteAlbums;
