import { ArtistAlbumsSortByQueryOptions, ArtistPortalApi, ArtistSortByQueryOptions } from '@bpm-web-app/download-api-sdk';
import { downloadConfig } from '../config';

export class ArtistPortal {
    static downloadClient = new ArtistPortalApi(downloadConfig);

    static getAlbums = (period: 'day' | 'week' | 'month', limit: number, skip: number, sortBy?: ArtistAlbumsSortByQueryOptions, search?: string) => this.downloadClient.getArtistPortalAlbums(limit, skip, true, period, sortBy, search);

    static getTopCities = (period: 'day' | 'week' | 'month', sortBy?: ArtistSortByQueryOptions, limit?: number) => this.downloadClient.getArtistPortalTopCities(period, sortBy, limit);

    static getTopCountries = (period: 'day' | 'week' | 'month', sortBy?: ArtistSortByQueryOptions, limit?: number) => this.downloadClient.getArtistPortalTopCountries(period, sortBy, limit);

    static getTopSources = (period: 'day' | 'week' | 'month', sortBy?: ArtistSortByQueryOptions) => this.downloadClient.getArtistPortalTopSources(period, sortBy);

    static getTopDJTypes = (period: 'day' | 'week' | 'month', sortBy?: ArtistSortByQueryOptions) => this.downloadClient.getArtistPortalTopDjTypes(period, sortBy);

    static getChartData = (period: 'day' | 'week' | 'month', type: 'download' | 'preview' | 'impression' | 'est_reach') => this.downloadClient.getArtistPortalChartData(type, new Date().getTimezoneOffset(), period);

    static getEnagementStats = (period: 'day' | 'week' | 'month') => this.downloadClient.getArtistPortalEnagementStats(period);

    static getOtherArtists = (period: 'day' | 'week' | 'month') => this.downloadClient.getArtistPortalOtherArtists(period);

    static getWeeklyStats = () => this.downloadClient.getArtistPortalWeeklyStats();

    static getBanner = () => this.downloadClient.getArtistPortalBanner();

    static getProfileStatus = () => this.downloadClient.getArtistPortalProfileStatus();

    static uploadAudioFile = (file: File) => {
        return this.downloadClient.getArtistPortalTrackUploadUrl().then(async ({ data }) => {
            const headers = new Headers({ 'Content-Type': 'audio/wav' });
            await fetch(data.url, {
                method: 'PUT',
                headers,
                body: file
            });
            return data.key;
        });
    };

    static getManagingArtist = () => this.downloadClient.getManagingArtist();

    static reportAlbum = (id: number, message: string) => this.downloadClient.reportAlbum(id, { message });
}
