import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { getPlatformLinkUsingRouter, useHubSwitch } from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import { ActionModal } from '../../shared/action-modal/action-modal';

export function SubscriptionPromotion() {
    const [show, setShow] = useState(false);
    const router = useRouter();

    const { isDownload, isCreate } = useHubSwitch();

    useEffect(() => {
        const lastShownSubscriptionPromotion = localStorage.getItem('SubscriptionPromotion:LastShown');
        if (!lastShownSubscriptionPromotion) {
            setShow(true);
        } else {
            const lastShown = dayjs(lastShownSubscriptionPromotion);
            if (dayjs().diff(lastShown, 'days') >= 7) {
                setShow(true);
            }
        }
    }, []);

    useEffect(() => {
        if (show) {
            localStorage.setItem('SubscriptionPromotion:LastShown', new Date().toJSON());
        }
    }, [show]);

    if (!isCreate && !isDownload) {
        return null;
    }

    return <ActionModal
        headerTitle="Start Subscription"
        title={!isCreate ? 'Welcome to BPM Supreme!' : 'Welcome to BPM Create!'}
        subtitle={!isCreate ? 'You can subscribe now to start downloading our library of DJ-ready music, exclusive remixes, playlists, and more. Or you can preview the platform for free.' : 'You can subscribe now to start downloading our library of one-shots, loops, MIDI, synth presets and more. Or you can preview the platform for free.'}
        confirmButtonText="Select Plan"
        cancelButtonText="Preview for Free"
        variant="light"
        onConfirm={() => {
            router.push(getPlatformLinkUsingRouter('/account/plan'));
            setShow(false);
        }}
        onClose={() => {
            setShow(false);
        }}
        isOpen={show} />;
}

export default SubscriptionPromotion;
