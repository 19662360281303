import { FC, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';

import { UserPlaylistWithAlbum } from '@bpm-web-app/download-api-sdk';
import { UserPlaylist as StreamUserPlaylist } from '@bpm-web-app/stream-api-sdk';
import { getCurrentPlatformLink, showToast } from '@bpm-web-app/utils';
import { useUserPlaylistsFull } from '../shared/three-dots-sheet/useUserPlaylistsFull';
import PlaylistIcon from './icon-playlist.svg';
import styles from './my-playlist-checkboxes.module.css';
import { Checkbox } from './checkbox/checkbox';
import { Search } from './search/search';
import { MyPlaylistHightLight } from './my-playlist-highlight';
import { MyPlaylistCreateButton } from './my-playlist-create-button';
import { MyPlaylistsLoading } from './my-playlist-loading';

export interface MyPlaylistsCategoriesProps {
    mediaIds: string[];
    isSubmenu?: boolean;
}

export const MyPlaylistsCheckboxes: FC<MyPlaylistsCategoriesProps> = ({ mediaIds, isSubmenu }: MyPlaylistsCategoriesProps) => {
    const { playlists, addItemsToUserPlaylist, removeItemsFromUserPlaylist } = useUserPlaylistsFull(false);
    const { push } = useRouter();
    const [search, setSearch] = useState('');
    const ref = useRef<HTMLUListElement>(null);

    const filterByTitle = useCallback((item: UserPlaylistWithAlbum) => item.title.toLowerCase().includes(search.toLowerCase()), [search]);

    const filteredResults = (playlists?.data as UserPlaylistWithAlbum[])?.filter(filterByTitle);

    const handleCheckboxChange = async (playlist: UserPlaylistWithAlbum | StreamUserPlaylist, isChecked: boolean) => {
        const ctaOptions: Parameters<typeof showToast>[0] = {
            type: 'success',
            buttonText: 'Go To Playlist',
            onButtonClick: () => push(getCurrentPlatformLink(`/my-playlist/${playlist.id}`)),
        };

        if (isChecked) {
            await addItemsToUserPlaylist(playlist.id, mediaIds).then(
                () => showToast({ title: `Added to ${playlist.title} playlist.`, ...ctaOptions }),
                () => showToast({ type: 'error', title: `Could not be added to ${playlist.title} playlist.` })
            );
        } else {
            await removeItemsFromUserPlaylist(playlist.id, mediaIds).then(
                () => showToast({ title: `Removed from ${playlist.title} playlist.`, ...ctaOptions }),
                () => showToast({ type: 'error', title: `Could not be removed to ${playlist.title} playlist.` })
            );
        }
    };

    useEffect(() => {
        if (ref.current) {
            const boundingRect = ref.current.getBoundingClientRect();
            const maxHeight = window.innerHeight - boundingRect.top - 20;

            if (boundingRect.height > maxHeight) {
                ref.current.setAttribute('style', `height: ${window.innerHeight - boundingRect.top - 30}px;`);
            }
        }
    }, [playlists?.data]);

    return (
        <ul
            className={classNames(styles['my-playlists'], {
                [styles['my-playlists--submenu']]: isSubmenu,
            })}
            key={playlists?.data ? 'loaded' : 'loading'}
            ref={ref}
        >
            <li className={styles['my-playlists-item']}>
                <Search placeholder="Search" disabled={!playlists?.data} value={search} onClear={() => setSearch('')} onChange={(e) => setSearch(e.target.value)} />
            </li>

            {!(playlists?.data && mediaIds.length) ? (
                <MyPlaylistsLoading />
            ) : filteredResults.length > 0 ? (
                <li className={styles['my-playlists__list']}>
                    <ul>
                        {filteredResults.map((item: (typeof playlists.data)[number], i) => (
                            <li key={`${item.id}-${item.updated_at}`} className={styles['my-playlists-item']}>
                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                <label className={styles['my-playlists-item__wrapper']}>
                                    <PlaylistIcon className={styles['my-playlists-item__icon']} />
                                    <span className={styles['my-playlists-item__label']}>
                                        <MyPlaylistHightLight text={item.title} query={search} />
                                    </span>
                                    <Checkbox
                                        value={item.id}
                                        defaultChecked={mediaIds.every((id) => (item as UserPlaylistWithAlbum).albums?.find((n) => n.id === Number(id)))}
                                        onChange={(evt) => handleCheckboxChange(item, evt.target.checked)}
                                        className={styles['my-playlists-item__checkbox']}
                                    />
                                </label>
                            </li>
                        ))}
                    </ul>
                </li>
            ) : (
                <li className={classNames(styles['my-playlists-item'], styles['my-playlists-item--no-results'])}>No Results</li>
            )}
            <MyPlaylistCreateButton onCreated={() => null} />
        </ul>
    );
};
