import { Period } from '@bpm-web-app/swr-hooks';
import { convertToPluralIfNeeded } from '@bpm-web-app/utils';
import { Chart as ChartJS, registerables, TooltipModel, TooltipItem } from 'chart.js';
import { useEffect, useRef, useState } from 'react';
import { Chart as ReactChart } from 'react-chartjs-2';
import styles from './chart.module.css';

ChartJS.register(...registerables);

interface DataSource {
    argument: string | string[]
    value: number
}
interface ChartProps {
    barColor?: string;
    seriesName: string;
    dataSource?: DataSource[];
    period: Period;
}

const loadingData = [
    {
        argument: 'Sun',
        value: 50
    },
    {
        argument: 'Mon',
        value: 100
    },
    {
        argument: 'Tues',
        value: 90
    },
    {
        argument: 'Wed',
        value: 80
    },
    {
        argument: 'Thurs',
        value: 60
    },
    {
        argument: 'Fri',
        value: 200
    },
    {
        argument: 'Sat',
        value: 150
    },
];

export function Chart({ barColor, seriesName, dataSource, period = 'week' }: ChartProps) {
    const [args, setArgs] = useState<(string | string[])[]>([]);
    const [values, setValues] = useState<number[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const chart = useRef<ChartJS>(null);

    const grabArguments = (data: DataSource[]) => {
        const mapped = data.map((item) => {
            return (
                item.argument
            );
        });
        return mapped;
    };

    const grabValues = (data: DataSource[]) => {
        const vals = data.map((item) => {
            return (
                item.value
            );
        });
        return vals;
    };

    useEffect(() => {
        if (!dataSource) {
            setArgs(grabArguments(loadingData));
            setValues(grabValues(loadingData));
            setLoading(true);
        } else {
            setArgs(grabArguments(dataSource));
            setValues(grabValues(dataSource));
            setLoading(false);
        }
    }, [dataSource]);

    enum Colors {
        gin = '#e6efe9',
        cod_gray_gray_400 = '#2a2a2a',
        cod_gray_gray_500 = '#212121',
    }

    return (
        <div className={styles['chart__wrapper']}>
            {
                dataSource && dataSource.length === 0 ?
                    <div className={styles['chart__no-data']}>No data ready yet </div> :
                    <ReactChart
                        ref={chart}
                        type="bar"
                        className={styles['chart__canvas']}
                        options={{
                            maintainAspectRatio: false,
                            hover: {
                                mode: !dataSource ? undefined : 'nearest'
                            },
                            scales: {
                                x: {
                                    grid: {
                                        display: false
                                    },
                                    ticks: {
                                        autoSkip: period === 'month',
                                        maxTicksLimit: 4.5,
                                        maxRotation: 0,
                                        minRotation: 0,
                                        display: !loading,
                                        font: {
                                            family: 'Gerstner Programm FSL',
                                            weight: '500',
                                            size: 10
                                        }
                                    }
                                },
                                y: {
                                    grid: {
                                        display: false
                                    },
                                    ticks: {
                                        display: !loading,
                                        font: {
                                            family: 'Gerstner Programm FSL',
                                            weight: '500',
                                            size: 10
                                        }
                                    },
                                }
                            },
                            plugins: {
                                tooltip: {
                                    xAlign: 'center',
                                    yAlign: 'bottom',
                                    displayColors: false,
                                    backgroundColor: Colors.cod_gray_gray_400,
                                    bodyFont: {
                                        family: 'Gerstner Programm FSL',
                                        weight: '500',
                                        size: 14
                                    },
                                    padding: { top: 8, right: 16, bottom: 8, left: 16 },
                                    enabled: !!dataSource || false,
                                    callbacks: {
                                        title: () => { return ''; },
                                        footer: () => { return ''; },
                                        label(this: TooltipModel<'bar'>, tooltipItem: TooltipItem<'bar'>): string | void | string[] {
                                            if (period === 'month') return `${convertToPluralIfNeeded(Number(tooltipItem.raw), seriesName)}  on ${tooltipItem.label.replace(',', ' ')}`.split('  ');
                                            return `${convertToPluralIfNeeded(Number(tooltipItem.raw), seriesName)}`;
                                        },
                                    }
                                },
                                legend: {
                                    display: false,
                                },
                            }
                        }}
                        data={{
                            labels: args,
                            datasets: [{
                                data: values,
                                borderWidth: 0,
                                backgroundColor: Colors.cod_gray_gray_500,
                                hoverBackgroundColor: barColor || Colors.gin
                            }]
                        }}
                    />
            }
        </div>
    );
}
