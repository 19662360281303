import classNames from 'classnames';
import { ReactNode } from 'react';
import styles from './accordion-count-column.module.css';
import ChevronRight from '../../../../assets/icons/chevron-right.svg';
import { TrackCountColumn } from './track-count-column';
import { TrendingCountColumn } from './trending-count-column';

export function AccordionColumnBase({
    children
}: { children: ReactNode }) {
    return (
        <div className={classNames(styles['accordion-count-column'])}>
            {children}
        </div>
    );
}

interface AccordionButtonProps {
    isAccordionOpen: boolean;
    ariaLabel: string;
    openAccordion: () => void;
}

export function AccordionButton({ isAccordionOpen, ariaLabel, openAccordion }: AccordionButtonProps) {
    return (
        <button
            className={classNames(styles['accordion-count-column__accordion-button'], {
                [styles['accordion-count-column__accordion-button--open']]: isAccordionOpen,
            })}
            type="button"
            aria-label={ariaLabel}
            onClick={(e) => {
                e.stopPropagation();
                openAccordion();
            }}
        >
            <ChevronRight />
        </button>
    );
}

interface AccordionTrackCountColumnProps extends AccordionButtonProps {
    trackCount: number;
}
/**
 * Displays accordion button on mobile and counter on desktop (accordion on hover)
 */
export function AccordionTrackCountColumn({
    trackCount,
    isAccordionOpen,
    ariaLabel,
    openAccordion,
}: AccordionTrackCountColumnProps) {
    return (
        <AccordionColumnBase>
            <div className={
                classNames(styles['accordion-count-column__track-count'],
                    { [styles['accordion-count-column__track-count--hidden']]: isAccordionOpen })}>
                <TrackCountColumn trackCount={trackCount} />
            </div>
            <div className={classNames(styles['accordion-count-column__count-button-container'], {
                [styles['accordion-count-column__count-button-container--shown']]: isAccordionOpen,
            })}>
                <AccordionButton
                    isAccordionOpen={isAccordionOpen}
                    ariaLabel={ariaLabel}
                    openAccordion={openAccordion}
                />
            </div>
        </AccordionColumnBase>
    );
}

interface AccordionTrendingColumnProps extends AccordionButtonProps {
    trackCount: number;
    direction: 'rising' | 'dropping';
}

export function AccordionTrendingColumn({
    trackCount,
    direction,
    isAccordionOpen,
    openAccordion,
    ariaLabel
}: AccordionTrendingColumnProps) {
    return (
        <AccordionColumnBase>
            <AccordionButton ariaLabel={ariaLabel} isAccordionOpen={isAccordionOpen} openAccordion={openAccordion} />
            <TrendingCountColumn direction={direction} trackCount={trackCount} />
        </AccordionColumnBase>
    );
}
