import classNames from 'classnames';
import styles from './missing-img-placeholder.module.css';
import BpmLogo from '../../../../assets/icons/bpm-logo.svg';

/* This component will cover and fill the entire parent element. When using, make sure that
 * the relevant parent component has position: relative. */
interface MissingImgPlaceholderProps {
    className?: string;
    isSmallImg?: boolean;
}
export const MissingImgPlaceholder = ({ className, isSmallImg }: MissingImgPlaceholderProps) => (
    <div
        className={classNames(styles['missing-img-placeholder'], className)}
    >
        <BpmLogo />
    </div>
);
