import { Crate, DownloadMedia } from '@bpm-web-app/api-client';
import { fileDownload, generateUuid } from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';

export function useDownloadMedia(library: string, isFromCrate = false) {
    const [isRequestInProgress, setIsRequestInProgress] = useState(false);
    const router = useRouter();

    const downloadSingleMedia = useCallback(
        async (mediaId: string | number) => DownloadMedia.getDownloadUrlForMedia(+mediaId, router.asPath, library, isFromCrate),
        [isFromCrate, library, router.asPath]
    );

    const downloadMultipleMedia = useCallback(
        async (mediaIds: number[]) => DownloadMedia.downloadMultipleMedia(router.asPath, library, mediaIds, generateUuid(), isFromCrate),
        [isFromCrate, library, router.asPath]
    );

    const handleOpenDownloadUrl = useCallback((url?: string) => {
        if (url) {
            fileDownload(url);
        }
    }, []);

    const downloadAllMediaFromCrate = useCallback(async (categorySlug: string) => Crate.generateOnlineCrateZip(router.asPath, library, generateUuid(), categorySlug), [library, router.asPath]);

    return {
        setIsRequestInProgress,
        isRequestInProgress,
        downloadMultipleMedia,
        downloadSingleMedia,
        handleOpenDownloadUrl,
        downloadAllMediaFromCrate,
    };
}
