import { useMemo } from 'react';
import ModalSlider from '../modal-slider/modal-slider';
import BPMS_1 from '../../../assets/onboarding/3.1/BPMS_Modal_LibrarySwitcher.png';
import BPMS_2 from '../../../assets/onboarding/3.1/BPMS_Modal_Player.png';

export function BPMSupremeQ31Update() {
    const data = useMemo(
        () => [
            {
                image: BPMS_1,
                title: 'Updates to switching libraries',
                text: 'We’ve moved the music library switch to the top right of the navigation to give you more space for browsing music. You’ll still be able to seamlessly switch between Open Format, Latino, and White Label libraries just like before!'
            },
            {
                image: BPMS_2,
                title: 'New full screen player',
                text: 'We’ve redesigned the player experience to make it even easier to download tracks, as well as improving track waveforms and queue functionality. Give it a try!'
            }
        ],
        []
    );

    return <ModalSlider title="Platform Updates" type="BPMSupremeQ31Update" sliderItems={data} />;
}

export default BPMSupremeQ31Update;
