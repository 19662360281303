/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unstable-nested-components */
import { useCrates } from '@bpm-web-app/components/src/lib/shared/three-dots-sheet/useCrates';
import { Artist, Album as DownloadAlbum, Media as DownloadMedia, MediaWithAlbum as DownloadMediaWithAlbum, UserPlaylistCollaboration, UserPlaylistWithAlbumOwner } from '@bpm-web-app/download-api-sdk';
import { MediaWithAlbum, Album as StreamAlbum } from '@bpm-web-app/stream-api-sdk';
import { useDownloadMedia } from '@bpm-web-app/swr-hooks';
import {
    Analytics,
    QueueItem,
    State,
    downloadAlbumWithMediaToQueueItem,
    downloadMediaWithAlbumToQueueItem,
    formatDateToString,
    generateArtistLinks,
    parseSeconds,
    rebuildReactTooltip,
    showToast,
    streamMediaWithAlbumToQueueItem,
    streamMediaWithoutAlbumToQueueItem,
    timeAgo,
    useApiErrorHandler,
    useHubSwitch,
    useLibraryTabs,
    usePageDetails,
    usePlayerState,
    useUserSettings,
    useViewport,
} from '@bpm-web-app/utils';
import classNames from 'classnames';
import { HTMLAttributes, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useInView } from 'react-intersection-observer';
import { DragDropItem, DragResult } from '../../../droppable/droppable';
import { usePlayer } from '../../../player-context';
import DownloadIcon from '../../../../assets/icons/download.svg';
import { AppLink } from '../../app-link/app-link';
import { ActionType, ThreeDotsSheetContext } from '../../three-dots-sheet/three-dots-sheet.context';
import useFavoriteAlbums from '../../three-dots-sheet/useFavoriteAlbums';
import { useUserPlaylistsDetails } from '../../three-dots-sheet/useUserPlaylistsDetails';
import { Checkbox } from '../../ui/checkbox/checkbox';
import { AccordionTrackCountColumn, AccordionTrendingColumn } from '../columns-supreme/accordion-count-column';
import { GenreColumn } from '../columns-supreme/genre-column';
import { QuickActionsColumn } from '../columns-supreme/quick-actions-column';
import { TitleColumnSupreme } from '../columns-supreme/title-column-supreme';
import { DownloadAccordion } from './download-accordion';
import { ColumnTypeSupreme, TrackListPresetSupreme, columnDefinitionsSupreme } from './track-list-supreme-helpers';
import styles from './track-list-supreme.module.css';
import { BpmColumn } from '../columns-supreme/bpm-column';
import { getVersionPillLabel } from '../../version-pill/version-pill';
import pillStyles from '../../version-pill/version-pill.module.css';
import { ThreeDotsButton } from '../../three-dots-button/three-dots-button';
import { KeyColumn } from '../columns-supreme/key-column';
import { VersionsColumnSupreme } from '../columns-supreme/versions-column-supreme';

interface TrackListItemGenericProps {
    preset: TrackListPresetSupreme;
    streamTracksList: MediaWithAlbum[];
    downloadTracksList: DownloadAlbum[];
    trackIndex: number;
    onMove?: (prevIndex: number, mediaId: number) => void;
    listId?: number;
    // eslint-disable-next-line react/require-default-props
    isUserPlaylist?: boolean;
    hasEditAccess?: boolean;
    streamAlbum?: StreamAlbum;
    onDownloadRevalidate?: (downloadMedia?: DownloadMedia) => void;
    defaultExpanded?: number;
    onFilterApplied?: (filter: { bpm?: number; key?: string; genre?: string }) => void;
    trendingStatusToShow?: 'trending_status_weekly' | 'trending_status_daily' | 'trending_status_monthly';
    collaborator?: UserPlaylistCollaboration;
    owner?: UserPlaylistWithAlbumOwner;
    isSharedPlaylist?: boolean;
    addedAt?: string;
    isDraggable?: boolean;
    setCurrentMediaInContext: (id: string, showSignUpModal?: boolean) => void;
    isPremiumOnly?: boolean;
    hasPremiumOnlyAccess?: boolean;
    forMaxiPlayer?: boolean;
    onAccordionClick: (albumId: number) => void;
    openAccordions: number[];
    indexOffset?: number;
}

interface TrackListItemNonSelectableProps extends TrackListItemGenericProps {
    media: MediaWithAlbum | DownloadMediaWithAlbum | DownloadAlbum | QueueItem;
    onSelectItem?: never;
    isSelected?: never;
}

export interface TrackListItemSelectableProps extends TrackListItemGenericProps {
    media: MediaWithAlbum | DownloadAlbum | QueueItem;
    onSelectItem: () => void;
    isSelected: boolean;
}

export type TrackListItemSupremeProps = TrackListItemNonSelectableProps | TrackListItemSelectableProps;

export function TrackListItemSupreme({
    preset,
    media,
    streamTracksList,
    downloadTracksList,
    trackIndex,
    streamAlbum,
    onSelectItem,
    isSelected,
    isUserPlaylist = false,
    hasEditAccess,
    onDownloadRevalidate,
    defaultExpanded,
    onFilterApplied,
    trendingStatusToShow = 'trending_status_weekly',
    collaborator,
    owner,
    isSharedPlaylist,
    addedAt,
    isDraggable,
    onMove,
    setCurrentMediaInContext,
    isPremiumOnly,
    hasPremiumOnlyAccess,
    forMaxiPlayer,
    onAccordionClick,
    openAccordions,
    indexOffset = 0
}: TrackListItemSupremeProps) {
    const { openThreeDotsModalSheet, secondaryActionTypeId } = useContext(ThreeDotsSheetContext);
    // Replace setQueue
    const { identifier, resource } = usePageDetails();

    const mediaWithAlbum = media as MediaWithAlbum;
    const downloadAlbum = media as DownloadAlbum;
    const onlineCrateMedia = media as DownloadMediaWithAlbum;
    const queueItem = media as QueueItem;
    const errorHandler = useApiErrorHandler();

    const { isDownload } = useHubSwitch();
    const { isAnonymous } = useUserSettings();

    const { libraryProperty } = useLibraryTabs();

    const isDownloadPreset = [TrackListPresetSupreme.Download, TrackListPresetSupreme.DownloadMaxi, TrackListPresetSupreme.DownloadTrending].includes(preset);

    const getActionType = useCallback((isPresetDownload: boolean) => {
        let actionType: ActionType = isPresetDownload ? 'download:album' : 'track';

        if (preset === TrackListPresetSupreme.Queue) {
            actionType = 'queue-track';
        } else if (isUserPlaylist || hasEditAccess) {
            actionType = 'user-playlist-track';
        }

        return actionType;
    }, [hasEditAccess, isUserPlaylist, preset]);

    const getAuxiliaryId = useCallback(() => {
        let auxiliaryId = secondaryActionTypeId || undefined;

        if (preset === TrackListPresetSupreme.Queue) {
            auxiliaryId = (media as Partial<QueueItem>)?.uuid;
        }

        return auxiliaryId;
    }, [media, preset, secondaryActionTypeId]);

    // TODO: (by Elliot Briant) We need to implement the fill in icon when adding to Crate or Queue method for Stream as well.
    const { isMediaInTheQueue, setIsMaxiPlayer } = usePlayer();

    useEffect(() => {
        if (defaultExpanded) {
            onAccordionClick(defaultExpanded);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultExpanded]);

    const mainItemRef = useRef<HTMLDivElement>(null);

    const { ref: viewRef, inView } = useInView({
        triggerOnce: true,
        threshold: 1,
    });

    useEffect(() => {
        viewRef(mainItemRef.current);
    }, [viewRef, mainItemRef]);

    useEffect(() => {
        if (inView) {
            let albumId: number | undefined;
            if ([
                TrackListPresetSupreme.Stream,
                TrackListPresetSupreme.StreamMaxi,
                TrackListPresetSupreme.StreamTrending,
                TrackListPresetSupreme.Album,
                TrackListPresetSupreme.OfflineCrate].includes(preset)) {
                albumId = streamAlbum?.id;
            } else if ([TrackListPresetSupreme.Download, TrackListPresetSupreme.DownloadMaxi, TrackListPresetSupreme.DownloadTrending, TrackListPresetSupreme.OnlineCrate].includes(preset)) {
                albumId = downloadAlbum.id;
            }
            if (preset === TrackListPresetSupreme.Queue) {
                albumId = queueItem.album_id;
            }
            Analytics.trackImpression('Album', `${albumId}`);
        }
    }, [downloadAlbum, inView, media, preset, queueItem, streamAlbum]);

    const { setQueue, skipToTrackInQueue, addToQueue, removeFromQueue, isMaxiPlayer, currentTrack, togglePlayPause } = usePlayer();

    const playerState = usePlayerState();

    const { addMediaToOnlineCrate } = useCrates();

    const { downloadSingleMedia, handleOpenDownloadUrl, setIsRequestInProgress } = useDownloadMedia(
        libraryProperty,
        preset === TrackListPresetSupreme.OnlineCrate || preset === TrackListPresetSupreme.OfflineCrate
    );

    const handlePlayStreamList = useCallback(() => {
        if (streamAlbum) {
            const mediaToAdd = streamAlbum.media.map((albumMedia) =>
                streamMediaWithoutAlbumToQueueItem(albumMedia, {
                    cover_url: streamAlbum.cover_url,
                    genre: streamAlbum.genre,
                    isExclusive: streamAlbum.is_exclusive,
                })
            );
            setQueue(mediaToAdd, trackIndex, { identifier, resource });
        } else {
            setQueue(
                streamTracksList.map((track) => streamMediaWithAlbumToQueueItem(track)),
                trackIndex || 0,
                { identifier, resource }
            );
        }
    }, [identifier, resource, setQueue, streamAlbum, streamTracksList, trackIndex]);

    const onDownloadAccordionClick = useCallback(() => {
        const downloadAlbumId = +downloadAlbum.id;
        onAccordionClick(downloadAlbumId);
    }, [downloadAlbum.id, onAccordionClick]);

    const handleSingleMediaDownload = useCallback(
        async (downloadMedia: DownloadMedia) => {
            if (isAnonymous || (isPremiumOnly && !hasPremiumOnlyAccess)) {
                if (setCurrentMediaInContext) setCurrentMediaInContext(`${media.id}`, !hasPremiumOnlyAccess);
                return;
            }
            try {
                setIsRequestInProgress(true);
                const url = (await downloadSingleMedia(downloadMedia.id)).data?.url || '';
                Analytics.trackClick('one_click_download', downloadMedia.id.toString(), { location: '1-click' });

                handleOpenDownloadUrl(url);
                if (onDownloadRevalidate) onDownloadRevalidate(downloadMedia);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                errorHandler({ error, message: 'Error downloading files. Please try again.' });
            } finally {
                setIsRequestInProgress(false);
            }
        },
        [downloadSingleMedia, errorHandler, handleOpenDownloadUrl, hasPremiumOnlyAccess, isAnonymous, isPremiumOnly, media.id, onDownloadRevalidate, setCurrentMediaInContext, setIsRequestInProgress]
    );

    const addDownloadTrackToQueue = useCallback(
        (downloadMedia, album) => {
            if (!downloadMedia || !album) return;

            const newQueueItem = downloadMediaWithAlbumToQueueItem(
                {
                    ...downloadMedia,
                    album,
                },
                album.media.length
            );

            addToQueue([newQueueItem]);
            showToast({ type: 'success', message: 'Added to queue.', buttonText: 'Open Queue', onButtonClick: () => setIsMaxiPlayer(true) });
            rebuildReactTooltip();
        },
        [addToQueue, setIsMaxiPlayer]
    );

    const removeTrackFromQueue = useCallback(
        async (mediaId) => {
            removeFromQueue(mediaId);
            showToast({ type: 'success', message: 'Removed from queue.' });
            rebuildReactTooltip();
        },
        [removeFromQueue]
    );

    const addDownloadTrackToCrate = useCallback(
        async (mediaId: number) => {
            if (isAnonymous || (isPremiumOnly && !hasPremiumOnlyAccess)) {
                if (setCurrentMediaInContext) setCurrentMediaInContext(`${media.id}`, !hasPremiumOnlyAccess);
                return;
            }
            await addMediaToOnlineCrate(mediaId);
            rebuildReactTooltip();
        },
        [addMediaToOnlineCrate, hasPremiumOnlyAccess, isAnonymous, isPremiumOnly, media.id, setCurrentMediaInContext]
    );

    const downloadAlbumMediaIds = useMemo<number[]>(() => downloadAlbum?.media?.map(({ id }) => id) || [], [downloadAlbum?.media]);

    const isCurrentTrackPartOfDownloadAlbum = useMemo(() => isDownload && downloadAlbumMediaIds.includes(currentTrack?.id), [currentTrack?.id, downloadAlbumMediaIds, isDownload]);

    const isCurrentPlayingTrack = useMemo(() => currentTrack?.id === media.id, [currentTrack?.id, media.id]);

    const handleMainItemClick = useCallback(() => {
        /* TODO: improve and extend the click event handler */
        if (isCurrentTrackPartOfDownloadAlbum || isCurrentPlayingTrack) {
            togglePlayPause();
            return;
        }

        if ([TrackListPresetSupreme.Stream, TrackListPresetSupreme.StreamTrending, TrackListPresetSupreme.Album, TrackListPresetSupreme.OfflineCrate].includes(preset)) {
            handlePlayStreamList();
        }

        if ([TrackListPresetSupreme.Download, TrackListPresetSupreme.DownloadTrending, TrackListPresetSupreme.DownloadMaxi].includes(preset)) {
            const currentAlbumIndex = downloadTracksList?.findIndex((album) => album.id === downloadAlbum.id);

            const newQueue = downloadTracksList.map((album) => downloadAlbumWithMediaToQueueItem(album));

            setQueue(newQueue, currentAlbumIndex, { identifier, resource });
        } else if (preset === TrackListPresetSupreme.OnlineCrate) {
            const currentAlbumIndex = downloadTracksList?.findIndex((album) => album.id === downloadAlbum.id);

            const newQueue = downloadTracksList.map((album) => downloadMediaWithAlbumToQueueItem(album as unknown as DownloadMediaWithAlbum, 1));

            setQueue(newQueue, currentAlbumIndex, { identifier, resource });
        }
        if (preset === TrackListPresetSupreme.Queue) skipToTrackInQueue(queueItem.uuid);
    }, [
        downloadAlbum,
        downloadTracksList,
        handlePlayStreamList,
        identifier,
        isCurrentPlayingTrack,
        isCurrentTrackPartOfDownloadAlbum,
        preset,
        queueItem.uuid,
        resource,
        setQueue,
        skipToTrackInQueue,
        togglePlayPause,
    ]);

    const isMainItemClickable = useMemo(
        () =>
            [
                TrackListPresetSupreme.Stream,
                TrackListPresetSupreme.Download,
                TrackListPresetSupreme.DownloadMaxi,
                TrackListPresetSupreme.StreamMaxi,
                TrackListPresetSupreme.Queue,
                TrackListPresetSupreme.StreamTrending,
                TrackListPresetSupreme.Album,
                TrackListPresetSupreme.OfflineCrate,
                TrackListPresetSupreme.OnlineCrate,
                TrackListPresetSupreme.DownloadTrending,
            ].includes(preset),
        [preset]
    );

    const mainItemProps = useMemo<HTMLAttributes<HTMLDivElement>>(() => {
        if (isMainItemClickable) {
            return {
                role: 'button',
                ref: mainItemRef,
                tabIndex: 0,
                onClick: handleMainItemClick,
                onKeyDown: (e) => {
                    const hasKeyPressedOnMainItem = e.target === mainItemRef.current;
                    if (e.key === 'Enter' && hasKeyPressedOnMainItem) {
                        handleMainItemClick();
                    }
                },
                'aria-label': `Play ${mediaWithAlbum.title}`,
            };
        }
        return {};
    }, [handleMainItemClick, isMainItemClickable, mediaWithAlbum.title]);

    const isDownloadAccordionOpen = useMemo(() => openAccordions.includes(+downloadAlbum.id), [downloadAlbum.id, openAccordions]);

    // TODO: extract these two to TitleColumnSupreme
    const artistLink = useCallback((a: Artist) => {
        return (
            <AppLink href={`/artist/${a.slug}`} key={a.id}>
                <a
                    data-for="artist-tooltip"
                    data-tip={`${a.name}|${a.slug}|${a.id}|${a.artwork_url}`}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    className="underline-link"
                >
                    {a.name}
                </a>
            </AppLink>
        );
    }, []);

    const separator = useCallback((sep: string, i: number) => {
        return <span key={i}>{sep}</span>;
    }, []);

    const subtitle = useMemo(() => {
        if ([TrackListPresetSupreme.Download, TrackListPresetSupreme.DownloadMaxi, TrackListPresetSupreme.Queue].includes(preset)) {
            return generateArtistLinks(downloadAlbum.artist, downloadAlbum.artists, artistLink, separator);
        }
        if (preset === TrackListPresetSupreme.OnlineCrate) {
            return generateArtistLinks(
                onlineCrateMedia.album?.artist || '',
                onlineCrateMedia.album?.artists || [],
                artistLink,
                separator
            );
        }
        return generateArtistLinks(mediaWithAlbum.artist, mediaWithAlbum.artists, artistLink, separator);
    }, [preset, mediaWithAlbum.artist, mediaWithAlbum.artists, artistLink, separator, downloadAlbum.artist, downloadAlbum.artists, onlineCrateMedia.album?.artist, onlineCrateMedia.album?.artists]);

    const playClick = useMemo(() => {
        return (track: DownloadMedia, index: number) => {
            return () => {
                if (currentTrack?.id === track.id) {
                    togglePlayPause();
                    return;
                }

                let albumToPlayIndex = 0;
                const queueItems = (downloadTracksList as DownloadAlbum[]).map((incomingAlbum, albumIndex) => {
                    if (incomingAlbum.id === track.album_id) {
                        albumToPlayIndex = albumIndex;
                        return downloadAlbumWithMediaToQueueItem(incomingAlbum, index);
                    }
                    return downloadAlbumWithMediaToQueueItem(incomingAlbum);
                });
                setQueue(queueItems, albumToPlayIndex, { identifier, resource });
            };
        };
    }, [currentTrack?.id, downloadTracksList, identifier, resource, setQueue, togglePlayPause]);

    const { addMediaToPlaylist, addAlbumToPlaylist } = useUserPlaylistsDetails();

    const { addAlbumToFavorites, removeAlbumFromFavorites, isAlbumFavorite } = useFavoriteAlbums(mediaWithAlbum.album_id || media.id);

    const { isMobile } = useViewport();

    const [draggingMediaId, setDraggingMediaId] = useState<number | undefined>();

    // eslint-disable-next-line no-empty-pattern
    const [{ }, dragRef, connectDragPreview] = useDrag<DragDropItem, any, { opacity: number }>({
        item: {
            id: isUserPlaylist ? media.id : mediaWithAlbum.album_id || media.id,
            title: downloadAlbum.title || downloadAlbum.name,
            prevIndex: trackIndex,
        } as any,
        canDrag: () => {
            return !isMobile;
        },
        end: (draggedItem, monitor) => {
            if (monitor.didDrop()) {
                const { target, id } = monitor.getDropResult() as DragResult;
                if (target === 'favorites') {
                    addAlbumToFavorites();
                } else if (target === 'my-crate') {
                    switch (preset) {
                        case TrackListPresetSupreme.Queue:
                            if (isDownload) {
                                addDownloadTrackToCrate(queueItem.id);
                            } else {
                                addMediaToOnlineCrate(queueItem.id);
                            }
                            break;
                        case TrackListPresetSupreme.StreamTrending:
                        case TrackListPresetSupreme.Stream:
                        case TrackListPresetSupreme.OfflineCrate:
                        case TrackListPresetSupreme.Album:
                            if (isDownload) {
                                addDownloadTrackToCrate(mediaWithAlbum.id);
                            } else {
                                addMediaToOnlineCrate(mediaWithAlbum.id);
                            }
                            break;
                        case TrackListPresetSupreme.DownloadTrending:
                        case TrackListPresetSupreme.Download:
                            if (isDownload) {
                                if (draggingMediaId !== undefined) {
                                    addDownloadTrackToCrate(draggingMediaId);
                                } else {
                                    addDownloadTrackToCrate(downloadAlbum.media[0].id);
                                }
                            }
                            break;
                        default:
                            break;
                    }
                } else if (target === 'playlist') {
                    switch (preset) {
                        case TrackListPresetSupreme.Queue:
                            if (isDownload) {
                                addAlbumToPlaylist(id as string, { albums: [{ album_id: queueItem.album_id as number }] });
                            } else {
                                addMediaToPlaylist(id as string, { media: [{ media_id: queueItem.id as number }] });
                            }
                            break;
                        case TrackListPresetSupreme.StreamTrending:
                        case TrackListPresetSupreme.Stream:
                        case TrackListPresetSupreme.OfflineCrate:
                        case TrackListPresetSupreme.Album:
                            if (isDownload) {
                                addAlbumToPlaylist(id as string, { albums: [{ album_id: mediaWithAlbum.album_id as number }] });
                            } else {
                                addMediaToPlaylist(id as string, { media: [{ media_id: mediaWithAlbum.id as number }] });
                            }
                            break;
                        case TrackListPresetSupreme.DownloadTrending:
                        case TrackListPresetSupreme.Download:
                            if (isDownload) {
                                addAlbumToPlaylist(id as string, { albums: [{ album_id: downloadAlbum.id as number }] });
                            }
                            break;
                        default:
                            break;
                    }
                }
                setDraggingMediaId(undefined);
            }
        },
        type: isDraggable ? (preset === TrackListPresetSupreme.Queue ? 'Queue' : 'UserPlaylist-Album') : 'Album',
    });

    const [{ isOver }, dropRef] = useDrop<DragDropItem, any, { isOver: boolean }>({
        accept: preset === TrackListPresetSupreme.Queue ? 'Queue' : 'UserPlaylist-Album',
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
        canDrop: () => {
            return !!isDraggable;
        },
        drop: (data: any) => {
            onMove?.(data.prevIndex, data.id);
        },
    });

    connectDragPreview(getEmptyImage(), {
        // IE fallback: specify that we'd rather screenshot the node
        // when it already knows it's being dragged so we can hide it with CSS.
        captureDraggingState: true,
    });

    const columnTypeDefitions = useMemo(() => columnDefinitionsSupreme[preset] || [], [preset]);

    const dateColumn = useMemo(() => {
        let column;
        let dateToDisplay = mediaWithAlbum.created_at;
        if (mediaWithAlbum.created_at < mediaWithAlbum.release_date) {
            dateToDisplay = mediaWithAlbum.release_date;
        }
        if (mediaWithAlbum.highlighted_date) {
            dateToDisplay = mediaWithAlbum.highlighted_date;
        }
        if ((preset === TrackListPresetSupreme.OnlineCrate || preset === TrackListPresetSupreme.OfflineCrate) && mediaWithAlbum.added_to_crate_at) {
            dateToDisplay = mediaWithAlbum.added_to_crate_at;
        }
        if (preset === TrackListPresetSupreme.Download && downloadAlbum.added_at) {
            dateToDisplay = downloadAlbum.added_at;
            column = timeAgo(dateToDisplay);
            if (Number.isNaN(+column.slice(0, 1))) {
                column = <span className={styles['track-list__date-timeago']}>{column}</span>;
            }
        } else {
            column = formatDateToString(dateToDisplay);
        }
        return column;
    }, [mediaWithAlbum.created_at, mediaWithAlbum.release_date, mediaWithAlbum.highlighted_date, mediaWithAlbum.added_to_crate_at, preset, downloadAlbum.added_at]);

    const likeOrUnlike = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();

        if (isAnonymous || (isPremiumOnly && !hasPremiumOnlyAccess)) {
            if (setCurrentMediaInContext) setCurrentMediaInContext(`${media.id}`, !hasPremiumOnlyAccess);
            return;
        }

        if (isAlbumFavorite) {
            removeAlbumFromFavorites();
        } else {
            addAlbumToFavorites();
        }
    }, [addAlbumToFavorites, hasPremiumOnlyAccess, isAlbumFavorite, isAnonymous, isPremiumOnly, media.id, removeAlbumFromFavorites, setCurrentMediaInContext]);

    const openThreeDots = useCallback((e, playlistsOnly = false) => {
        e.stopPropagation();
        e.preventDefault();

        if (isAnonymous || (isPremiumOnly && !hasPremiumOnlyAccess)) {
            if (setCurrentMediaInContext) setCurrentMediaInContext(`${media.id}`, !hasPremiumOnlyAccess);
            return;
        }

        const { top: topPos, left: leftPos } = (e.target as HTMLButtonElement).getBoundingClientRect();
        openThreeDotsModalSheet(getActionType(isDownloadPreset), media?.id, leftPos, topPos + window.scrollY, playlistsOnly, getAuxiliaryId());
    }, [getActionType, getAuxiliaryId, hasPremiumOnlyAccess, isAnonymous, isPremiumOnly, media.id, openThreeDotsModalSheet, setCurrentMediaInContext, isDownloadPreset]);

    const openPlaylistsModal = useCallback((e) => {
        openThreeDots(e, true);
    }, [openThreeDots]);

    return (
        <div ref={(ref) => {
            dragRef(ref);
            dropRef(ref);
        }}>
            <div
                className={classNames('track-list-item', styles['track-list__list-item'], [styles[`track-list__list-item--${preset}`]], {
                    [styles['track-list__list-item--clickable']]: isMainItemClickable,
                    [styles['track-list__list-item--download-accordion-open']]: isDownloadAccordionOpen,
                    [styles['track-list__list-item--download-accordion-open--maxi-player']]: isDownloadAccordionOpen && isMaxiPlayer,
                    [styles['track-list__list-item--is-album-restricted']]: downloadAlbum?.is_restricted || mediaWithAlbum?.album?.is_restricted,
                    [styles['track-list__list-item--dragging-over']]: isOver,
                    [styles['track-list__list-item--maxi-player']]: forMaxiPlayer,
                    [styles['track-list__list-item--crate']]: TrackListPresetSupreme.OnlineCrate === preset || TrackListPresetSupreme.OfflineCrate === preset,
                })}
                {...mainItemProps}
            >
                {columnTypeDefitions.map(({ type }, index) => {
                    let column;

                    switch (type) {
                        case ColumnTypeSupreme.Title:

                            if (preset === TrackListPresetSupreme.OnlineCrate) {
                                column = (
                                    <TitleColumnSupreme
                                        hasPremiumOnlyAccess={hasPremiumOnlyAccess}
                                        isPremiumOnly={isPremiumOnly}
                                        coverUrl={onlineCrateMedia.album?.cover_url}
                                        title={onlineCrateMedia.name}
                                        subtitle={subtitle}
                                        isExclusive={!!onlineCrateMedia.album?.is_exclusive}
                                        isPlaying={currentTrack?.id === onlineCrateMedia.id && playerState === State.Playing}
                                    />
                                );
                            } else if (isDownloadPreset || preset === TrackListPresetSupreme.Queue) {
                                column = (
                                    <TitleColumnSupreme
                                        hasPremiumOnlyAccess={hasPremiumOnlyAccess}
                                        isPremiumOnly={isPremiumOnly}
                                        coverUrl={downloadAlbum.cover_url}
                                        title={downloadAlbum.title}
                                        linkedArtist={downloadAlbum.artist_remixer}
                                        subtitle={subtitle}
                                        isExclusive={downloadAlbum.is_exclusive}
                                        isPlaying={downloadAlbumMediaIds.includes(Number(currentTrack?.id)) && playerState === State.Playing}
                                    />
                                );
                            } else {
                                column = (
                                    <TitleColumnSupreme
                                        hasPremiumOnlyAccess={hasPremiumOnlyAccess}
                                        isPremiumOnly={isPremiumOnly}
                                        coverUrl={streamAlbum ? streamAlbum.cover_url : mediaWithAlbum.album?.cover_url}
                                        title={mediaWithAlbum.title}
                                        subtitle={subtitle}
                                        isPlaying={isCurrentPlayingTrack}
                                        isExclusive={!!(streamAlbum?.is_exclusive || mediaWithAlbum.album?.is_exclusive)}
                                    />
                                );
                            }
                            break;
                        case ColumnTypeSupreme.Bpm:
                            if (onFilterApplied) {
                                column = (
                                    <button
                                        className={classNames(styles['track-list__label'], styles['track-list__label--clickable'])}
                                        type="button"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onFilterApplied({ bpm: onlineCrateMedia?.album?.bpm || mediaWithAlbum?.bpm });
                                        }}
                                    >
                                        {preset === TrackListPresetSupreme.OnlineCrate ? onlineCrateMedia.album?.bpm : mediaWithAlbum.bpm}
                                    </button>
                                );
                            } else {
                                column = <BpmColumn bpm={(preset === TrackListPresetSupreme.OnlineCrate ? onlineCrateMedia.album?.bpm : mediaWithAlbum.bpm)} />;
                            }
                            break;
                        case ColumnTypeSupreme.Key:
                            if (onFilterApplied) {
                                column = (
                                    <button
                                        className={classNames(styles['track-list__label'], styles['track-list__label--clickable'])}
                                        type="button"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onFilterApplied({ key: onlineCrateMedia?.album?.key || mediaWithAlbum?.key });
                                        }}
                                    >
                                        {preset === TrackListPresetSupreme.OnlineCrate ? onlineCrateMedia.album?.display_key : mediaWithAlbum.display_key}
                                    </button>
                                );
                            } else if (preset === TrackListPresetSupreme.Queue) {
                                column = <KeyColumn displayKey={queueItem.display_key} actualKey={queueItem.actual_key} />;
                            } else {
                                column = <KeyColumn
                                    displayKey={(preset === TrackListPresetSupreme.OnlineCrate ? onlineCrateMedia.album?.key : mediaWithAlbum.display_key)}
                                />;
                            }
                            break;
                        case ColumnTypeSupreme.Genre:
                            if (preset === TrackListPresetSupreme.Queue) {
                                column = <GenreColumn genre={queueItem.genre} />;
                            } else {
                                column = isDownloadPreset ? <GenreColumn genre={downloadAlbum.genre} /> : <GenreColumn genre={mediaWithAlbum.album?.genre} />;
                            }
                            break;
                        case ColumnTypeSupreme.Time:
                            column = isDownloadPreset ? parseSeconds(downloadAlbum.media[0].estimated_duration || 0) : parseSeconds(mediaWithAlbum.estimated_duration || 0);
                            break;
                        case ColumnTypeSupreme.Date:
                            column = dateColumn;

                            break;
                        case ColumnTypeSupreme.Versions:
                            column = (
                                <div className={classNames(styles['track-list__versions-content'])}>
                                    <VersionsColumnSupreme
                                        hasPremiumOnlyAccess={hasPremiumOnlyAccess}
                                        isPremiumOnly={isPremiumOnly}
                                        versionsList={downloadAlbum.media}
                                        onMoreVersionsClick={onDownloadAccordionClick}
                                        isAccordionOpen={isDownloadAccordionOpen}
                                        onVersionClick={(m) => handleSingleMediaDownload(m)}
                                        setCurrentMediaInContext={() => (setCurrentMediaInContext ? setCurrentMediaInContext(`${media.id}`, !hasPremiumOnlyAccess) : null)}
                                    />
                                </div>
                            );
                            break;
                        case ColumnTypeSupreme.Count:
                        case ColumnTypeSupreme.TrendingCount:
                            column = <AccordionTrendingColumn
                                isAccordionOpen={isDownloadAccordionOpen}
                                openAccordion={onDownloadAccordionClick}
                                trackCount={trackIndex + indexOffset + 1}
                                ariaLabel={`${downloadAlbum.name} - versions`}
                                direction={
                                    (preset === TrackListPresetSupreme.DownloadTrending && downloadAlbum[trendingStatusToShow] > 0) ||
                                        (preset === TrackListPresetSupreme.StreamTrending && mediaWithAlbum[trendingStatusToShow] > 0) ? 'rising' : 'dropping'
                                } />;
                            break;
                        case ColumnTypeSupreme.Accordion:
                            column = (
                                <AccordionTrackCountColumn
                                    isAccordionOpen={isDownloadAccordionOpen}
                                    openAccordion={onDownloadAccordionClick}
                                    trackCount={trackIndex + indexOffset + 1}
                                    ariaLabel={`${downloadAlbum.name} - versions`}
                                />
                            );
                            break;
                        case ColumnTypeSupreme.EditModeCheckbox:
                            column = onSelectItem ? (
                                <div className={styles['track-list__column--checkbox']}>
                                    <Checkbox checked={isSelected} onChange={onSelectItem} />
                                </div>
                            ) : (
                                <AccordionTrackCountColumn
                                    isAccordionOpen={isDownloadAccordionOpen}
                                    openAccordion={onDownloadAccordionClick}
                                    trackCount={trackIndex + indexOffset + 1}
                                    ariaLabel={`${downloadAlbum.name} - versions`}
                                />
                            );
                            break;

                        case ColumnTypeSupreme.OnlineCrateActions:
                            column = (
                                <ThreeDotsButton
                                    avoidCheckingIfAnonymous
                                    onClick={(e) => {
                                        if (isAnonymous || (isPremiumOnly && !hasPremiumOnlyAccess)) {
                                            if (setCurrentMediaInContext) setCurrentMediaInContext(`${media.id}`, !hasPremiumOnlyAccess);
                                            return;
                                        }
                                        e.preventDefault();
                                        e.stopPropagation();
                                        openThreeDotsModalSheet(
                                            'online-crate',
                                            media.id,
                                            isMaxiPlayer ? e.currentTarget.getBoundingClientRect().left : e.currentTarget.offsetLeft,
                                            isMaxiPlayer ? e.currentTarget.getBoundingClientRect().top + window.scrollY : e.currentTarget.offsetTop,
                                            false
                                        );
                                    }} />
                            );
                            break;

                        case ColumnTypeSupreme.QuickActions:
                            column = (
                                <QuickActionsColumn
                                    isAlbumFavorite={isAlbumFavorite}
                                    media={media as QueueItem}
                                    openPlaylists={openPlaylistsModal}
                                    openThreeDots={openThreeDots}
                                    likeOrUnlike={likeOrUnlike}
                                    isVisible={isDownloadAccordionOpen}
                                />
                            );
                            break;
                        case ColumnTypeSupreme.DownloadablePill:
                            column = (
                                <button
                                    type="button"
                                    className={pillStyles['version-pill']}
                                    aria-label={`Download ${onlineCrateMedia.name}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleSingleMediaDownload(onlineCrateMedia);
                                    }}
                                >
                                    <span className={pillStyles['version-pill__text']}>
                                        {getVersionPillLabel(onlineCrateMedia.version?.name)}
                                    </span>
                                    <span className={pillStyles['version-pill__download']}>
                                        <DownloadIcon />
                                    </span>
                                </button>
                            );
                            break;

                        default:
                            column = '';
                            break;
                    }
                    return (
                        <div
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            className={classNames(styles['track-list__column'], styles[`track-list__column--${type}`])}
                        >
                            {column}
                        </div>
                    );
                })}
            </div>
            {/* Download accordion content */}
            {[TrackListPresetSupreme.Download, TrackListPresetSupreme.DownloadMaxi, TrackListPresetSupreme.Queue, TrackListPresetSupreme.DownloadTrending].includes(preset) && (
                <DownloadAccordion
                    media={media}
                    preset={preset}
                    forMaxiPlayer={forMaxiPlayer}
                    hasPremiumOnlyAccess={hasPremiumOnlyAccess}
                    isPremiumOnly={isPremiumOnly}
                    isOpen={isDownloadAccordionOpen}
                    isMobile={isMobile}
                    currentTrack={currentTrack}
                    isAnonymous={isAnonymous}
                    playerState={playerState}
                    downloadAlbum={downloadAlbum}
                    openDownloadAccordions={openAccordions}
                    isMediaInTheQueue={isMediaInTheQueue}
                    handleSingleMediaDownload={handleSingleMediaDownload}
                    addDownloadTrackToQueue={addDownloadTrackToQueue}
                    removeTrackFromQueue={removeTrackFromQueue}
                    playClick={playClick}
                    onAccordionClick={onDownloadAccordionClick}
                    openThreeDotsModalSheet={openThreeDotsModalSheet}
                    setDraggingMediaId={setDraggingMediaId}
                    setCurrentMediaInContext={setCurrentMediaInContext}
                    wideOffset={preset === TrackListPresetSupreme.DownloadTrending || preset === TrackListPresetSupreme.StreamTrending}
                />
            )}
        </div>
    );
}
/* eslint-enable jsx-a11y/anchor-is-valid */
/* eslint-enable jsx-a11y/click-events-have-key-events */
/* eslint-enable jsx-a11y/no-static-element-interactions */
/* eslint-enable react/no-unstable-nested-components */
