import { GiveawayApi, IdCommentBody, CommentsIdBody, EnterExistingBody } from '@bpm-web-app/supreme-api-sdk';
import { supremeConfig } from '../config';

export class Giveaway {
    static client = new GiveawayApi(supremeConfig);

    static getGiveaway = (slug: string) =>
        Giveaway.client.getGiveaways(undefined).then(async (data) => {
            const giveaway = data?.data?.current?.find((giveawayObj) => giveawayObj.slug === slug);
            if (giveaway) {
                return giveaway;
            }
            return data?.data?.past?.find((giveawayObj) => giveawayObj.slug === slug);
        });

    static getGiveawayEntry = (id: string) => Giveaway.client.getGiveawayEntry(id);

    static getAllGiveaways = () => Giveaway.client.getGiveaways(undefined);

    static postGiveawayComent = (id: string, body: IdCommentBody) => Giveaway.client.postGiveawayComent(id, body);

    static editGiveawayComent = (id: string, body: CommentsIdBody) => Giveaway.client.editGiveawayComent(id, body);

    static likeGiveawayComent = (id: string) => Giveaway.client.likeGiveawayComent(id);

    static unlikeGiveawayComent = (id: string) => Giveaway.client.unlikeGiveawayComent(id);

    static enterGiveaway = (id: string, body: EnterExistingBody) => Giveaway.client.enterGiveaway(id, body);

    static getGiveawayComents = (id: string, limit: number, skip: number) => Giveaway.client.getGiveawayComents(id, limit, skip);
}
