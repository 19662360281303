import { FC, useCallback, useState } from 'react';
import ReactModal from 'react-modal';
import classNames from 'classnames';

import { PlaylistCategory } from '@bpm-web-app/download-api-sdk';
import styles from './playlists-form.module.css';
import CloseIcon from '../../assets/icons/close.svg';

export interface PlaylistsFormProps {
    id?: string;
    categories?: PlaylistCategory[]
    onSubmit: (data: { name: string, category: string }) => void;
    onClose: (status: 'submitted' | 'closed') => void;
    defaultValues?: { name?: string, category?: string };
}

export const CreatePlaylistForm: FC<PlaylistsFormProps> = ({ id, categories, onSubmit, onClose, defaultValues }) => {
    const [name, setName] = useState(defaultValues?.name || '');
    const [category, setCategory] = useState(defaultValues?.category || '');

    const handleSubmit = useCallback(() => {
        onSubmit({ name, category });
        onClose('submitted');
    }, [onClose, onSubmit, id, name, category]);

    return (
        <ReactModal isOpen className={classNames('react-modal__content', styles['playlists-form__modal-content'], styles['playlists-form__modal-content--small'])} overlayClassName="react-modal__overlay" onRequestClose={() => onClose('closed')}>
            <div className={styles['playlists-form__modal-form']}>
                <div className={styles['playlists-form__modal-header']}>
                    <h2 className={styles['playlists-form__modal-title']}>Create New Playlist</h2>
                    <button type="button" className={styles['playlists-form__modal-close-btn']} onClick={() => onClose('closed')}>
                        <i className={styles['playlists-form__modal-close-icon']}>
                            <CloseIcon />
                        </i>
                    </button>
                </div>
                <input
                    type="text"
                    name="name"
                    aria-label="Playlist Name"
                    placeholder="Playlist Name"
                    className={classNames(styles['playlists-form__modal-input-text'], styles['playlists-form__modal-input-text--long'])}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            handleSubmit();
                        }
                    }}
                />
                <select
                    name="folder"
                    aria-label="Select Folder (optional)"
                    placeholder="Select Folder (optional)"
                    className={classNames(styles['playlists-form__modal-input-text'], styles['playlists-form__modal-input-text--long'])}
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            handleSubmit();
                        }
                    }}
                >
                    <option value="">Select Folder (optional)</option>
                    {categories?.map((cat) => (
                        <option key={cat.id} value={cat.id}>{cat.name}</option>
                    ))}
                </select>

                <button type="submit" className={styles['playlists-form__modal-btn']} onClick={handleSubmit}>
                    Create New Playlist
                </button>

                <button type="button" className={styles['playlists-form__modal-btn']} onClick={() => onClose('closed')}>
                    Cancel
                </button>
            </div>
        </ReactModal>
    );
};
