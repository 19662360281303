import { useCallback, useContext, useMemo, useState } from 'react';
import { useCreateGenres } from '@bpm-web-app/swr-hooks';
import classNames from 'classnames';
import { SubGenre } from '@bpm-web-app/create-api-sdk';
import { unique } from '@bpm-web-app/utils';
import { Collapse } from 'react-collapse';
import Checkboxes from '../shared/checkboxes/checkboxes';
import styles from './genres-filter.module.css';
import { SearchInput } from '../shared/search-input/search-input';
import ChevronUp from '../../assets/icons/chevron-up.svg';
import { FiltersContext } from '../filters/filters.context';

export function CreateGenresFilter() {
    const { genres: selectedGenres, setGenres, subGenres, setSubGenres } = useContext(FiltersContext);

    const { genres: genresData } = useCreateGenres();

    const mainGenresList = useMemo(
        () =>
            genresData?.map((genre) => ({
                label: genre.name,
                value: genre.name,
            })) || [],
        [genresData]
    );

    const [searchQuery, setSearchQuery] = useState<string>('');
    const [isGenresExpanded, setIsGenresExpanded] = useState(true);

    const isSearching = useMemo(() => searchQuery.trim() !== '', [searchQuery]);

    const filteredGenres = useMemo(() => mainGenresList.filter((genre) => !isSearching || genre.label.toLowerCase().includes(searchQuery.toLowerCase())), [mainGenresList, searchQuery, isSearching]);

    const handleSelectMainGenre = useCallback(
        (genre: string[]) => {
            const nextGenre = genre.length ? [genre[genre.length - 1]] : [];
            setGenres(nextGenre);

            if (nextGenre.length) {
                setSubGenres([]);
            }
        },
        [setGenres, setSubGenres]
    );

    const allSubGenresForGenre = useMemo(() => {
        if (!selectedGenres || !genresData?.length) return [];
        return genresData?.find((genre) => genre.name === selectedGenres[0])?.SubGenres || [];
    }, [genresData, selectedGenres]);

    const filteredSubGenres = useMemo(() => {
        if (!allSubGenresForGenre.length) return [];
        return allSubGenresForGenre.filter((subGenre) => subGenre.name.toLowerCase().includes(searchQuery.toLowerCase())).map((subgenre) => ({ label: subgenre.name, value: subgenre.name }));
    }, [allSubGenresForGenre, searchQuery]);

    const allFilteredSubGenres = useMemo(() => {
        if (!genresData?.length) return [];
        const uniqueSubGenres = unique(genresData.reduce((genres, genre) => {
            genres.push(...genre.SubGenres);
            return genres;
        }, [] as SubGenre[]), 'id');
        return uniqueSubGenres.filter((subGenre) => subGenre.name.toLowerCase().includes(searchQuery.toLowerCase())).map((subgenre) => ({ label: subgenre.name, value: subgenre.name }));
    }, [genresData, searchQuery]);

    const resetFilter = useCallback(() => {
        setSearchQuery('');
        setGenres([]);
        setSubGenres([]);
    }, [setGenres, setSubGenres]);

    const filterActiveCount = (subGenres.length + selectedGenres.length);

    return (
        <div className={classNames('filter', styles['genres-filter'], { [styles['genres-filter__expanded']]: isGenresExpanded })}>
            <div
                role="button"
                onClick={() => setIsGenresExpanded(!isGenresExpanded)}
                tabIndex={0}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        setIsGenresExpanded(!isGenresExpanded);
                    }
                }}
                className="filter__header">
                <div className={classNames('filter__title', { 'filter__title-active': filterActiveCount > 0 })}>Genres {filterActiveCount > 0 ? `(${filterActiveCount})` : ''}</div>
                {(filterActiveCount > 0) && (
                    <button type="button" className="filter__reset" onClick={resetFilter}>
                        Remove
                    </button>
                )}
                <button aria-label="Expand/Collapse Genres" type="button" onClick={() => setIsGenresExpanded(!isGenresExpanded)}>
                    <ChevronUp
                        className={classNames('filter__chevron', {
                            'filter__chevron--expanded': isGenresExpanded,
                        })}
                    />
                </button>
            </div>
            <Collapse isOpened={isGenresExpanded}>
                <div className={styles['genres-filter__search']}>
                    <SearchInput
                        placeholder="Search Genres & Sub Genres"
                        value={searchQuery}
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                        }}
                        onClear={() => setSearchQuery('')}
                    />
                </div>
                <div className={classNames(styles['genres-filter__checkboxes'], { [styles['genres-filter__checkboxes-searching']]: isSearching })}>
                    <Checkboxes options={filteredGenres} value={selectedGenres} onChange={handleSelectMainGenre} />
                    {isSearching && <Checkboxes options={allFilteredSubGenres} value={subGenres} onChange={setGenres} />}
                </div>
                {!allSubGenresForGenre.length ? null : (
                    <div className={styles['genres-filter__checkboxes']}>
                        <div className={classNames('filter__title', styles['genres-filter__subgenre-title'])}>{`${selectedGenres}: Sub Genres`}</div>
                        <Checkboxes
                            // eslint-disable-next-line max-len
                            options={filteredSubGenres}
                            value={subGenres}
                            onChange={setSubGenres}
                        />
                    </div>
                )}
                {selectedGenres.length ? (
                    <div className={styles['genres-filter__checkboxes']}>
                        <div className={classNames('filter__title', styles['genres-filter__subgenre-title'])}>Selected Genre</div>
                        <Checkboxes options={selectedGenres.map((tag) => ({ label: tag, value: tag }))} value={selectedGenres} onChange={setGenres} />
                    </div>
                ) : null}
                {subGenres.length ? (
                    <div className={styles['genres-filter__checkboxes']}>
                        <div className={classNames('filter__title', styles['genres-filter__subgenre-title'])}>Selected Sub Genres</div>
                        <Checkboxes options={subGenres.map((subGenre) => ({ label: subGenre, value: subGenre }))} value={subGenres} onChange={setSubGenres} />
                    </div>
                ) : null}
            </Collapse>
        </div>
    );
}

export default CreateGenresFilter;
