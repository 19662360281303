import { capitalizeFirstLetter, convertKeyToSelectedKeyType } from '@bpm-web-app/utils';
import { useContext } from 'react';
import { useGenres } from '@bpm-web-app/swr-hooks';
import Checkboxes, { CheckboxesProps } from '../../checkboxes/checkboxes';
import { FiltersContext } from '../../../filters/filters.context';

export function TrackListActiveFilters({ resultsCount }: { resultsCount?: number }) {
    const {
        activeFilters,
        removeActiveFilterByValue,
        keyType
    } = useContext(FiltersContext);

    const { genres: genresData } = useGenres();

    const activeFiltersValues = () => {
        return activeFilters?.map((activeFilter) => {
            switch (activeFilter.type) {
                case 'Key': {
                    const keyLabel = convertKeyToSelectedKeyType(activeFilter.value, keyType, false);
                    return ({ label: keyLabel, value: activeFilter.value, type: activeFilter.type });
                }
                case 'Genre':
                    if (genresData) {
                        const currentGenre = genresData.find((item) => item.slug === activeFilter.value);
                        return ({ label: currentGenre?.name, value: activeFilter.value, type: activeFilter.type });
                    }
                    return ({ label: capitalizeFirstLetter(activeFilter.value), value: activeFilter.value, type: activeFilter.type });

                default:
                    return ({ label: capitalizeFirstLetter(activeFilter.value), value: activeFilter.value, type: activeFilter.type });
            }
        });
    };

    if (activeFilters && activeFilters.length > 0) {
        return (
            <div className="spacing--top">
                <Checkboxes
                    hasRectangularCheckboxes
                    options={activeFiltersValues() as CheckboxesProps['options']}
                    value={[]}
                    onChange={() => null}
                    onCloseCross={removeActiveFilterByValue}
                    hasMaxHeight={32}
                />
            </div>
        );
    }
    return null;
}
