import { Sound } from '@bpm-web-app/create-api-sdk';
import { MediaWithAlbum, MediaWithDownloadedDate } from '@bpm-web-app/download-api-sdk';
import { Sound as SoundAPI } from '@bpm-web-app/api-client';
import classNames from 'classnames';
import { appendQueryParams, fileDownload, formatDateToString, isDownloadLimitReached, useApiErrorHandler } from '@bpm-web-app/utils';
import { useContext } from 'react';
import { Pagination } from '@bpm-web-app/stream-api-sdk';
import { useDownloadMedia, useGetCredits } from '@bpm-web-app/swr-hooks';
import { useRouter } from 'next/router';
import ChevronLeft from '../../../../assets/icons/chevron-left.svg';
import ChevronRight from '../../../../assets/icons/chevron-right.svg';
import Download from '../../../../assets/icons/download.svg';
import ExclusiveTag from '../../../../assets/icons/exclusive-tag.svg';
import { Checkbox } from '../../../shared/ui/checkbox/checkbox';
import { LibraryTabsContext } from '../../../../../../utils/src/lib/library-tabs.context';
import styles from './table.module.css';

interface TableProps {
    isCreate: boolean;
    mediaList: Sound[] | MediaWithDownloadedDate[];
    pagination: Pagination;
    mutateSupreme: (item: { data: MediaWithDownloadedDate[]; pagination: Pagination }) => void;
    checkedMediaIds: (string | number)[];
    setCheckedMediaIds: React.Dispatch<React.SetStateAction<(string | number)[]>>;
    mainState: {
        activePage: number;
        searchQuery: string;
    };
    onPageChange: (nextPage: number) => void;
    pageSize: number;
    dataSupreme: { data: MediaWithDownloadedDate[]; pagination: Pagination };
}

export function Table({ isCreate, mediaList, pagination, mutateSupreme, checkedMediaIds, setCheckedMediaIds, mainState, onPageChange, pageSize, dataSupreme }: TableProps) {
    const { libraryProperty } = useContext(LibraryTabsContext);
    const errorHandler = useApiErrorHandler();
    const isMediaAvailable = (media: MediaWithAlbum) => !isDownloadLimitReached(media.download_count);
    const { mutate: updateCredits } = useGetCredits(!isCreate);

    const router = useRouter();

    const { downloadSingleMedia: downloadSingleMediaHook, downloadMultipleMedia, isRequestInProgress, setIsRequestInProgress } = useDownloadMedia(libraryProperty, false);

    const onCheckboxChange = (mediaId: string | number) => {
        let newArray = [...checkedMediaIds, mediaId];
        if (checkedMediaIds.includes(mediaId)) {
            newArray = newArray.filter((id) => id !== mediaId);
        }
        setCheckedMediaIds(newArray);
    };

    const downloadSingleMedia = async (media: MediaWithAlbum | Sound) => {
        try {
            setIsRequestInProgress(true);

            if (isCreate) {
                const { data: downloadMediaUrlData } = await SoundAPI.downloadSound(media.id as string, router.asPath);
                if (downloadMediaUrlData?.url) {
                    fileDownload(downloadMediaUrlData.url);
                    updateCredits();
                }
            } else {
                const { data: downloadMediaUrlData } = await downloadSingleMediaHook(+media.id);

                if (downloadMediaUrlData?.url) {
                    fileDownload(downloadMediaUrlData.url);
                    const mutatedData = dataSupreme.data.map((downloadMedia) =>
                        downloadMedia.id === media.id ? { ...downloadMedia, download_count: downloadMedia.download_count + 1 } : downloadMedia
                    );
                    mutateSupreme({ ...dataSupreme, data: mutatedData });
                }
            }
        } catch (error) {
            errorHandler({ error });
        } finally {
            setIsRequestInProgress(false);
        }
    };

    const downloadSelectedMedia = async () => {
        try {
            setIsRequestInProgress(true);
            if (isCreate) {
                const downloadMediaUrlData = await SoundAPI.downloadMultiSounds(checkedMediaIds as string[], router.asPath);
                if (downloadMediaUrlData) {
                    const payload = await downloadMediaUrlData?.json();
                    if (payload?.data?.url) {
                        fileDownload(payload.data.url);
                        updateCredits();
                    }
                }
            } else {
                const { data: downloadMediaUrlData } = await downloadMultipleMedia(checkedMediaIds as number[]); // note on supreme IDS are only number

                if (downloadMediaUrlData?.url) {
                    fileDownload(downloadMediaUrlData.url);
                    const mutatedData = dataSupreme.data.map((downloadMedia) =>
                        checkedMediaIds.includes(downloadMedia.id) ? { ...downloadMedia, download_count: downloadMedia.download_count + 1 } : downloadMedia
                    );
                    mutateSupreme({ ...dataSupreme, data: mutatedData });
                }
                setCheckedMediaIds([]);
            }
        } catch (error) {
            errorHandler({ error, message: 'Error getting download url for media' });
        } finally {
            setIsRequestInProgress(false);
        }
    };

    const handleSupremeLines = (media: MediaWithDownloadedDate) => (
        <div
            key={media.id}
            className={classNames(styles['table__list-item'], {
                [styles['table__list-item--inactive']]: !isMediaAvailable(media),
            })}
        >
            <Checkbox disabled={!isMediaAvailable(media)} onChange={() => onCheckboxChange(+media.id)} checked={checkedMediaIds.includes(+media.id)} />
            <div className={classNames(styles['table__title-column'])}>
                {media.album?.cover_url && (
                    <img
                        src={appendQueryParams(media.album?.cover_url, { key: 'dw', value: 56 })}
                        srcSet={`${appendQueryParams(media.album?.cover_url, { key: 'dw', value: 112 })} 2x`}
                        alt={media.name}
                        className={styles['table__title-column-cover']}
                    />
                )}
                <div className={styles['table__title-column-text']}>
                    <div className={styles['table__title-column-title']}>
                        <span>{media.name}</span>
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore */}
                        {media.album?.is_exclusive && <ExclusiveTag className={styles['table__title-column-exclusive']} />}
                    </div>
                    <div className={styles['table__title-column-subtitle']}>
                        <span>{media.album?.artist}</span>
                        <span className={styles['table__not-available--mobile']}>{isMediaAvailable(media) ? formatDateToString(media.last_downloaded, 'MM/DD/YYYY') : '3/3 Downloaded'}</span>
                    </div>
                </div>
            </div>
            <span className={styles['table__list-column--download-date']}>{isMediaAvailable(media) ? formatDateToString(media.last_downloaded, 'MM/DD/YYYY') : ''}</span>
            <div className={styles['table__list-column--actions']}>
                {isMediaAvailable(media) ? (
                    <button
                        type="button"
                        className={styles['table__single-download-btn']}
                        aria-label={`Download ${media.name}`}
                        disabled={isRequestInProgress}
                        onClick={() => downloadSingleMedia(media)}
                    >
                        <Download />
                    </button>
                ) : (
                    <span className={styles['table__not-available--desktop']}>3/3 Downloaded</span>
                )}
            </div>
        </div>
    );

    const handleCreateLines = (media: Sound) => (
        <div key={media.id} className={styles['table__list-item']}>
            <Checkbox onChange={() => onCheckboxChange(media.id)} checked={checkedMediaIds.includes(media.id)} />
            <div className={classNames(styles['table__title-column'])}>
                {media.artwork_url && (
                    <img
                        src={appendQueryParams(media.artwork_url, { key: 'dw', value: 56 })}
                        srcSet={`${appendQueryParams(media.artwork_url, { key: 'dw', value: 112 })} 2x`}
                        alt={media.name}
                        className={styles['table__title-column-cover']}
                    />
                )}
                <div className={styles['table__title-column-text']}>
                    <div className={styles['table__title-column-title']}>
                        <span>{media.name}</span>
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore */}
                        {media.album?.is_exclusive && <ExclusiveTag className={styles['table__title-column-exclusive']} />}
                    </div>
                    <div className={styles['table__title-column-subtitle']}>
                        <span>{media.artist}</span>
                        <span className={styles['table__not-available--mobile']}>{formatDateToString(media.last_downloaded, 'MM/DD/YYYY')}</span>
                    </div>
                </div>
            </div>
            <span className={styles['table__list-column--download-date']}>{formatDateToString(media.last_downloaded, 'MM/DD/YYYY')}</span>
            <div className={styles['table__list-column--actions']}>
                <button type="button" className={styles['table__single-download-btn']} aria-label={`Download ${media.name}`} disabled={isRequestInProgress} onClick={() => downloadSingleMedia(media)}>
                    <Download />
                </button>
            </div>
        </div>
    );

    return mediaList?.length ? (
        <>
            <div className={classNames(styles['table__list'])}>
                <div
                    className={classNames(styles['table__list-heading'], {
                        [styles['table__list-heading--sortable']]: true,
                    })}
                >
                    <div className={classNames(styles['table__list-heading-column'], styles['table__list-heading-column--count'])} />
                    <div className={classNames(styles['table__list-heading-column'], styles['table__list-heading-column--title'])}>Title</div>
                    <div className={classNames(styles['table__list-heading-column'], styles['table__list-heading-column--download-date'])}>Download Date</div>
                    <div className={classNames(styles['table__list-heading-column'], styles['table__list-heading-column--sort'])} />
                </div>
                <div className={styles['table__list']}>{mediaList?.map((media) => (isCreate ? handleCreateLines(media as Sound) : handleSupremeLines(media as MediaWithDownloadedDate)))}</div>
            </div>
            <div className={styles['table__controls']}>
                <button
                    className={classNames('button button--solid', styles['table__cta'])}
                    type="button"
                    disabled={checkedMediaIds.length === 0 || isRequestInProgress}
                    onClick={() => downloadSelectedMedia()}
                >
                    Download Selected
                </button>
                {pagination && (
                    <div className={styles['table__pagination']}>
                        <span>{`${mainState.activePage + 1} of ${Math.ceil(pagination.total / pagination.limited)}`}</span>
                        <button
                            type="button"
                            aria-label="Previous Page"
                            className={classNames(styles['table__arrow-btn'], styles['table__prev-btn'])}
                            disabled={mainState.activePage === 0}
                            onClick={() => onPageChange(mainState.activePage - 1)}
                        >
                            <ChevronLeft />
                        </button>
                        <button
                            type="button"
                            aria-label="Next Page"
                            className={classNames(styles['table__arrow-btn'], styles['table__next-btn'])}
                            disabled={pagination.total <= (mainState.activePage + 1) * pageSize}
                            onClick={() => onPageChange(mainState.activePage + 1)}
                        >
                            <ChevronRight />
                        </button>
                    </div>
                )}
            </div>
        </>
    ) : (
        <h4 className={styles['table__empty']}>No downloads found.</h4>
    );
}

export default Table;
