export type KeyboardScale = 'major' | 'minor';
export type KeyboardKeyType = 'flats' | 'sharps';

export const keyTypes: { label: string; value: KeyboardKeyType }[] = [
    {
        label: 'Flats',
        value: 'flats',
    },
    {
        label: 'Sharps',
        value: 'sharps',
    },
];

export const scales: { label: string; value: KeyboardScale }[] = [
    {
        label: 'Major',
        value: 'major',
    },
    {
        label: 'Minor',
        value: 'minor',
    },
];

export type Key =
    | '1A' // A Flat Minor
    | '2A' // E Flat Minor
    | '3A' // B Flat Minor
    | '4A' // F Minor
    | '5A' // C Minor
    | '6A' // G Minor
    | '7A' // D Minor
    | '8A' // A Minor
    | '9A' // E Minor
    | '10A' // B Minor
    | '11A' // F Sharp Minor
    | '12A' // D Flat Minor
    | '1B' // B Major
    | '2B' // F Sharp Major
    | '3B' // D Flat Major
    | '4B' // A Flat Major
    | '5B' // E Flat Major
    | '6B' // B Flat Major
    | '7B' // F Major
    | '8B' // C Major
    | '9B' // G Major
    | '10B' // D Major
    | '11B' // A Major
    | '12B'; // E Major

const KeyMapping: { [key: string]: string[] } = {
    '1A': ['Ab Min', 'G# Min'],
    '2A': ['Eb Min', 'D# Min'],
    '3A': ['Bb Min', 'A# Min'],
    '4A': ['F Min'],
    '5A': ['C Min'],
    '6A': ['G Min'],
    '7A': ['D Min'],
    '8A': ['A Min'],
    '9A': ['E Min'],
    '10A': ['B Min'],
    '11A': ['Gb Min', 'F# Min'],
    '12A': ['Db Min', 'C# Min'],
    '1B': ['B'],
    '2B': ['Gb', 'F#'],
    '3B': ['Db', 'C#'],
    '4B': ['Ab', 'G#'],
    '5B': ['Eb', 'D#'],
    '6B': ['Bb', 'A#'],
    '7B': ['F'],
    '8B': ['C'],
    '9B': ['G'],
    '10B': ['D'],
    '11B': ['A'],
    '12B': ['E'],
};

export function convertKeyToSelectedKeyType(key: string, keyType: KeyboardKeyType, shortSyntax = false) {
    const keys = KeyMapping[key];

    if (keyType === 'sharps' && keys.length > 1) {
        if (shortSyntax) {
            return keys[1].replace(' Min', 'm');
        }
        return keys[1];
    }
    if (shortSyntax) {
        return keys[0].replace(' Min', 'm');
    }
    return keys[0];
}

export function convertKeyboardToCamelot({ key, scale }: { key: string; scale?: KeyboardScale }) {
    if (!key) return null;
    const [keyWithoutType, keyType] = key;

    let type;
    if (keyType === '#' || keyType === '♯') {
        type = 'sharp';
    } else if (keyType === 'b') {
        type = 'flat';
    }

    /** Major keys */
    if ((keyWithoutType === 'A' && scale === 'minor' && type === 'flat') || (keyWithoutType === 'G' && scale === 'minor' && type === 'sharp')) {
        return '1A';
    }

    if ((keyWithoutType === 'E' && scale === 'minor' && type === 'flat') || (keyWithoutType === 'D' && scale === 'minor' && type === 'sharp')) {
        return '2A';
    }

    if ((keyWithoutType === 'B' && scale === 'minor' && type === 'flat') || (keyWithoutType === 'A' && scale === 'minor' && type === 'sharp')) {
        return '3A';
    }

    if (keyWithoutType === 'F' && scale === 'minor' && !type) {
        return '4A';
    }

    if (keyWithoutType === 'C' && scale === 'minor' && !type) {
        return '5A';
    }

    if (keyWithoutType === 'G' && scale === 'minor' && !type) {
        return '6A';
    }

    if (keyWithoutType === 'D' && scale === 'minor' && !type) {
        return '7A';
    }

    if (keyWithoutType === 'A' && scale === 'minor' && !type) {
        return '8A';
    }

    if (keyWithoutType === 'E' && scale === 'minor' && !type) {
        return '9A';
    }

    if (keyWithoutType === 'B' && scale === 'minor' && !type) {
        return '10A';
    }

    if ((keyWithoutType === 'G' && scale === 'minor' && type === 'flat') || (keyWithoutType === 'F' && scale === 'minor' && type === 'sharp')) {
        return '11A';
    }

    if ((keyWithoutType === 'D' && scale === 'minor' && type === 'flat') || (keyWithoutType === 'C' && scale === 'minor' && type === 'sharp')) {
        return '12A';
    }

    /** Major keys */
    if (keyWithoutType === 'B' && scale === 'major' && !type) {
        return '1B';
    }

    if ((keyWithoutType === 'F' && scale === 'major' && type === 'sharp') || (keyWithoutType === 'G' && scale === 'major' && type === 'flat')) {
        return '2B';
    }

    if ((keyWithoutType === 'D' && scale === 'major' && type === 'flat') || (keyWithoutType === 'C' && scale === 'major' && type === 'sharp')) {
        return '3B';
    }

    if ((keyWithoutType === 'A' && scale === 'major' && type === 'flat') || (keyWithoutType === 'G' && scale === 'major' && type === 'sharp')) {
        return '4B';
    }

    if ((keyWithoutType === 'E' && scale === 'major' && type === 'flat') || (keyWithoutType === 'D' && scale === 'major' && type === 'sharp')) {
        return '5B';
    }

    if ((keyWithoutType === 'B' && scale === 'major' && type === 'flat') || (keyWithoutType === 'A' && scale === 'major' && type === 'sharp')) {
        return '6B';
    }

    if (keyWithoutType === 'F' && scale === 'major' && !type) {
        return '7B';
    }

    if (keyWithoutType === 'C' && scale === 'major' && !type) {
        return '8B';
    }

    if (keyWithoutType === 'G' && scale === 'major' && !type) {
        return '9B';
    }

    if (keyWithoutType === 'D' && scale === 'major' && !type) {
        return '10B';
    }

    if (keyWithoutType === 'A' && scale === 'major' && !type) {
        return '11B';
    }

    if (keyWithoutType === 'E' && scale === 'major' && !type) {
        return '12B';
    }

    return null;
}
