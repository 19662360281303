import { Media } from '@bpm-web-app/api-client';

import { useHubSwitch } from '@bpm-web-app/utils';
import { useCallback } from 'react';
import useSWR from 'swr';

type GetMediaResponse = Awaited<ReturnType<typeof Media['getMedia']>>;

export function useMedia(id: number, disabled?: boolean) {
    const { isDownload } = useHubSwitch();

    const { data, error } = useSWR<GetMediaResponse>(['get-media', id, { persist: true, isDownload }], () => Media.getMedia(isDownload, id), {
        isPaused: () => !!disabled,
    });

    return {
        media: data,
        isLoading: !error && !data,
        error,
    };
}

export function useSimilarMedia(id: number, enabled = true) {
    const { data, error } = useSWR(['stream-get-similar-media', { persist: true, id }], () => (enabled ? Media.getSimilarMedia(id) : null));

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

export function useFavoriteMedia(library?: string) {
    const { isDownload } = useHubSwitch();

    const { data, error, mutate } = useSWR(
        [
            'get-favorite-media',
            {
                persist: true,
                isDownload,
                library,
            },
        ],
        () => (isDownload ? null : Media.getFavoriteMedia(library))
    );

    return {
        data,
        mutate,
        isLoading: isDownload ? false : !error && !data,
        error,
    };
}

/** Informs the BE that a track as been played */
export function useReportMediaPlay() {
    const { isDownload } = useHubSwitch();

    return useCallback((id: number, params: Parameters<typeof Media.reportMediaPlay>[2]) => Media.reportMediaPlay(isDownload, id, params), [isDownload]);
}
