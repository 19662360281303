import { Notification } from '@bpm-web-app/supreme-api-sdk';
import { useCurrentUser, useNotifications } from '@bpm-web-app/swr-hooks';
import { getCurrentPlatformLink, getLoginRedirectLink, getPlatformLinkFromHub, getSignupRedirectLink, useHubSwitch, useOnClickOutside, useUserSettings, useViewport } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { OnboardingModalNotifications } from '../onboarding-modals/onboarding-modal-notifications/onboarding-modal-notifications';

// Icons
import BPMLogo from '../../assets/icons/bpm-logo.svg';
import CloseIcon from '../../assets/icons/close.svg';
import HomeIcon from '../../assets/icons/home.svg';
import PlaylistIcon from '../../assets/icons/my-playlists.svg';
import NavIcon from '../../assets/icons/nav-icon.svg';
import SearchIcon from '../../assets/icons/search-icon-nav.svg';
import SupremeLogo from '../../assets/icons/supreme-icon.svg';

import { FiltersContext } from '../filters/filters.context';
import { NavContext } from '../nav/nav.context';
import { modalOpenBodyClassName } from '../onboarding-modals/modal-slider/modal-slider';
import { usePlayer } from '../player-context';
import PlaylistsForm, { PlaylistsFormProps } from '../playlists-form/playlists-form';
import { AppLink } from '../shared/app-link/app-link';
import { PlatformSwitch } from '../shared/platform-switch/platform-switch';
import { useSession } from '../shared/session-provider/session-provider';
import { SquaredButton } from '../shared/squared-button/squared-button';
import { UserNotifications } from '../user-notifications/user-notifications';
import { HeaderAccount } from './header-account/header-account';
import { HeaderFullscreenButton } from './header-fullscreen-button/header-fullscreen-button';
import { HeaderNotificationButton } from './header-notification-button/header-notification-button';
import { HeaderPlatformsButton } from './header-platforms-button/header-platforms-button';
import { HeaderProfileButton } from './header-profile-button/header-profile-button';
import { LibrarySwitch } from './library-switch/library-switch';
import { SearchForm } from './search-form/search-form';
import styles from './supreme-header.module.css';
import { SupremeProfileMenu } from './supreme-profile-menu/supreme-profile-menu';

/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
export interface SupremeHeaderProps { }

export function SupremeHeader(props: SupremeHeaderProps) {
    const { userMemberships } = useSession();
    const { hub } = useHubSwitch();

    const isWebview = !!window.isRNWebView;

    const { closeFilters } = useContext(FiltersContext);
    const { setIsMaxiPlayer } = usePlayer();
    const router = useRouter();
    const { data: userData } = useCurrentUser();
    const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
    const { isNavOpen, openNav, closeNav, isSearchVisible, hideSearchBar, showSearchBar } = useContext(NavContext);
    const { isDownload } = useHubSwitch();

    const { data: notifications } = useNotifications(isDownload ? [Notification.PlatformEnum.Download] : [Notification.PlatformEnum.Stream]);
    const [showNotifications, setShowNotifications] = useState(false);
    const [showPlatformSwitch, setShowPlatformSwitch] = useState(false);
    const { isMobile: isMobileViewPort } = useViewport();
    const { ref: notificationElRef } = useOnClickOutside<HTMLDivElement>(false, () =>
        isMobileViewPort || document.body.classList.contains(modalOpenBodyClassName) ? null : setShowNotifications(false)
    );

    const desktopMenuOpenerRef = useRef<HTMLDivElement>(null);
    const { ref: profileMenuRef } = useOnClickOutside<HTMLDivElement>(false, (e: MouseEvent) => {
        if (!desktopMenuOpenerRef.current?.contains(e.target as Node)) {
            setIsProfileMenuOpen(false);
        }
    });

    const desktopSwitchPlatformOpenerRef = useRef<HTMLDivElement>(null);
    const { ref: switchPlatformMenuRef } = useOnClickOutside<HTMLDivElement>(false, (e: MouseEvent) => {
        if (!desktopSwitchPlatformOpenerRef.current?.contains(e.target as Node)) {
            setShowPlatformSwitch(false);
        }
    });

    const hasNotifications = useMemo(() => notifications?.data?.some((notification) => !notification.read), [notifications?.data]);

    const closeIcon = useMemo(() => <CloseIcon />, []);
    const navIcon = useMemo(() => <NavIcon />, []);
    const isAccount = useMemo(() => router.pathname.includes('/account/'), [router.pathname]);
    const isAccountCheckout = useMemo(() => router.pathname.includes('/account/plan/change'), [router.pathname]);
    const [openForm, setOpenForm] = useState<null | PlaylistsFormProps>(null);
    const { isAnonymous } = useUserSettings();

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const contextMenuForm = () => <PlaylistsForm {...openForm!} />;

    useEffect(() => {
        setIsProfileMenuOpen(false);
        setShowNotifications(false);
    }, [router]);

    const handleMenuToggle = () => {
        closeFilters();
        setIsMaxiPlayer(false);
        if (isMobileViewPort) {
            closeNav();
        }
        setIsProfileMenuOpen(!isProfileMenuOpen);
    };

    return (
        <div className={styles['supreme-header']}>
            {openForm && contextMenuForm()}
            <header className={classNames(styles['supreme-header__container'], { [styles['supreme-header__container--nav-opened']]: isNavOpen })}>
                <button
                    type="button"
                    aria-label="Open Nav"
                    onClick={() => {
                        if (isNavOpen) {
                            setShowNotifications(false);
                            closeNav();
                        } else {
                            openNav();
                        }
                    }}
                    className={styles['supreme-header__mobile-nav-btn']}
                >
                    {isNavOpen ? closeIcon : navIcon}
                </button>
                {isMobileViewPort ? (
                    <div className={styles['supreme-header__logo']}>
                        <AppLink href="/">
                            <a aria-label="Homepage" className={styles['supreme-header__logo-img']}>
                                <BPMLogo />
                            </a>
                        </AppLink>
                    </div>
                ) : null}

                {isAccount
                    ? <HeaderAccount title="BPM Supreme" IconComponent={SupremeLogo} />
                    : (
                        <div className={styles['supreme-header__center']}>
                            {!isMobileViewPort && <SearchForm isMobile={false} setOpenForm={setOpenForm} />}
                        </div>)}

                <div className={classNames(styles['supreme-header__right'], { [styles['supreme-header__right--with-action']]: !userMemberships.download?.has_membership })}>
                    {!isAccount && (
                        <>
                            <LibrarySwitch />
                            <HeaderFullscreenButton />
                            <div>
                                <div ref={desktopSwitchPlatformOpenerRef}>
                                    <HeaderPlatformsButton onClick={() => setShowPlatformSwitch(!showPlatformSwitch)} isOpen={showPlatformSwitch} />
                                </div>
                                <div ref={switchPlatformMenuRef}>
                                    {showPlatformSwitch ? (
                                        <PlatformSwitch
                                            platforms={[{ name: 'BPM Create' }, { name: 'BPM Artist' }]}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </>
                    )}
                    {isAnonymous ? (
                        <div className={styles['supreme-header__anonymous-buttons']}>
                            <button type="button" onClick={() => router.replace(getLoginRedirectLink())}>
                                <div>Log In</div>
                            </button>
                            {!isAccount && (
                                <SquaredButton
                                    type="dynamic"
                                    label="Start Downloading"
                                    // eslint-disable-next-line @typescript-eslint/dot-notation
                                    onPress={() => router.push(getPlatformLinkFromHub(hub, `/account/plan?library=${router.query['library']?.toString() || 'music'}`))}
                                />
                            )}
                        </div>
                    ) : (
                        <>
                            {!isAccount && (
                                <div ref={notificationElRef}>
                                    <HeaderNotificationButton
                                        onClick={() => setShowNotifications((prevState) => !prevState)}
                                        hasNotifications={hasNotifications}
                                        isShowingNotifications={showNotifications}
                                    />
                                    <UserNotifications
                                        platform={isDownload ? Notification.PlatformEnum.Download : Notification.PlatformEnum.Stream}
                                        isVisible={showNotifications && !isMobileViewPort}
                                        onClose={() => setShowNotifications(false)}
                                    />
                                </div>
                            )}
                            <div ref={desktopMenuOpenerRef}>
                                <HeaderProfileButton
                                    isOpen={isProfileMenuOpen}
                                    isHidden={isAnonymous}
                                    imageAlt={userData?.data?.user?.full_name}
                                    imageUrl={userData?.data?.user?.profile_image_thumbnail_url}
                                    onClick={handleMenuToggle}
                                />
                            </div>
                            {!isAnonymous && !userMemberships.download?.has_membership && !isAccountCheckout && (
                                <div className={styles['supreme-header__subscribe']}>
                                    <SquaredButton
                                        type="dynamic"
                                        label="Subscribe"
                                        // eslint-disable-next-line @typescript-eslint/dot-notation
                                        onPress={() => router.push(getCurrentPlatformLink(`/account/plan?library=${router.query['library']?.toString() || 'music'}`))}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </div>
                {isAnonymous ? (
                    <button type="button" aria-label="Open Nav" className={styles['supreme-header__mobile-profile-btn']} onClick={() => router.replace(getSignupRedirectLink())}>
                        Sign Up
                    </button>
                ) : (
                    <div className={styles['supreme-header__mobile-right']}>
                        {!isAnonymous && !userMemberships.download?.has_membership && (
                            <div className={styles['supreme-header__subscribe-mobile']}>
                                <button
                                    type="button"
                                    aria-label="Subscribe"
                                    className={styles['supreme-header__mobile-subscribe-btn']}
                                    // eslint-disable-next-line @typescript-eslint/dot-notation
                                    onClick={() => router.push(getCurrentPlatformLink(`/account/plan?library=${router.query['library']?.toString() || 'music'}`))}
                                >
                                    Subscribe
                                </button>
                            </div>
                        )}

                        <HeaderProfileButton
                            imageAlt={userData?.data?.user?.full_name}
                            imageUrl={userData?.data?.user?.profile_image_thumbnail_url}
                            onClick={handleMenuToggle}
                            isMobile
                            isOpen={isProfileMenuOpen}
                            hasNotifications={hasNotifications}
                        />
                    </div>
                )}
            </header>
            <div className={classNames(styles['supreme-header__mobile'], { [styles['supreme-header__RNmobile']]: isWebview })}>
                <button
                    type="button"
                    aria-label="Home"
                    className={styles['supreme-header__mobile-btn']}
                    onClick={() => {
                        hideSearchBar();
                        router.push(getCurrentPlatformLink('/'));
                    }}
                >
                    <HomeIcon />
                </button>
                <button type="button" aria-label="Search" className={styles['supreme-header__mobile-btn']} onClick={() => (isSearchVisible ? hideSearchBar() : showSearchBar())}>
                    <SearchIcon />
                </button>
                <AppLink href="/my-playlists">
                    <a aria-label="Playlists" className={styles['supreme-header__mobile-btn']}>
                        <PlaylistIcon />
                    </a>
                </AppLink>
            </div>
            <SupremeProfileMenu
                isOpen={isProfileMenuOpen}
                ref={profileMenuRef}
                platforms={[{ name: 'BPM Create' }, { name: 'BPM Artist' }]}
                setIsOpen={setIsProfileMenuOpen}
                userData={userData}
            />

            {isSearchVisible && (
                <div className={styles['supreme-header__mobile-search']}>
                    <div className={styles['supreme-header__mobile-search-form']}>
                        <SearchForm isMobile={false} setOpenForm={setOpenForm} />
                    </div>
                </div>
            )}
            {showNotifications && <OnboardingModalNotifications />}
        </div>
    );
}

export default SupremeHeader;
