import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import ReactModal from 'react-modal';
import CloseIcon from '../../../../assets/icons/close.svg';
import styles from './modal.module.css';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    wide?: boolean;
}

export function Modal({ isOpen, onClose, children, wide }: PropsWithChildren<ModalProps>) {
    return (
        // eslint-disable-next-line @typescript-eslint/dot-notation
        <ReactModal isOpen={isOpen} shouldCloseOnOverlayClick onRequestClose={onClose} className={classNames('react-modal__content', styles['modal'], { [styles['modal--wide']]: wide })} overlayClassName="react-modal__overlay">
            <button type="button" className={styles['modal__close-button']} onClick={onClose}>
                <CloseIcon />
            </button>
            {children}
        </ReactModal>
    );
}

export function ModalLabel({ children }: PropsWithChildren<any>) {
    return (
        <p className={styles['modal__label']}>
            {children}
        </p>
    );
}
