import { PageNotFound } from '@bpm-web-app/components/src/lib/pages';
import { TrackListSupreme } from '@bpm-web-app/components/src/lib/shared/track-list/supreme/track-list-supreme';
import { TrackListPresetSupreme } from '@bpm-web-app/components/src/lib/shared/track-list/supreme/track-list-supreme-helpers';
import { InlineResponse2002 } from '@bpm-web-app/download-api-sdk';
import { useDownloadAlbumById, useRemixesAlbum } from '@bpm-web-app/swr-hooks';
import { useHubSwitch } from '@bpm-web-app/utils';
import { useMemo } from 'react';
import RemixesIcon from '../../../../../assets/icons/remixes.svg';
import { EmptyState } from '../empty-state/empty-state';
import { ListLoading } from '../list-loading/lists-loading';
import { ListWrapper } from '../list-wrapper/list-wrapper';

export interface RemixesMediaDetailProps {
    mediaId: number;
}

const getRemixesId = (album: InlineResponse2002 | undefined) => {
    if (!album) return null;

    const isRemix = album.data.remix_of_id !== null;

    if (isRemix) {
        return album.data.remix_of_id;
    }

    return album.data.id;
};

export function Remixes({ mediaId }: RemixesMediaDetailProps) {
    const { isDownload } = useHubSwitch();
    const { isLoading: isLoadingAlbum, error: albumError, data: album } = useDownloadAlbumById(mediaId, isDownload);
    const remixesId = getRemixesId(album);
    const { data: remixesAlbum, isLoading: isLoadingRemixesAlbum, error: remixesAlbumError, mutate: mutateRemixesAlbums } = useRemixesAlbum(remixesId ?? mediaId, isDownload);

    const remixesWithoutCurrent = useMemo(() => {
        return remixesAlbum?.data?.filter((remix) => remix.id !== mediaId);
    }, [remixesAlbum, mediaId]);

    const isLoading = useMemo(
        () => (isDownload ? isLoadingAlbum || isLoadingRemixesAlbum : false),
        [isDownload, isLoadingAlbum, isLoadingRemixesAlbum]
    );

    const error = useMemo(() => (isDownload ? albumError || remixesAlbumError : false), [albumError, isDownload, remixesAlbumError]);

    if (mediaId === undefined || error) return <PageNotFound />;

    if (isLoading) {
        return (
            <ListLoading />
        );
    }

    if (!remixesWithoutCurrent?.length) {
        return (
            <EmptyState title="The track has no remixes or edits." icon={<RemixesIcon />} />
        );
    }

    return (
        <ListWrapper>
            <TrackListSupreme
                forMaxiPlayer
                list={remixesWithoutCurrent}
                preset={isDownload ? TrackListPresetSupreme.DownloadMaxi : TrackListPresetSupreme.StreamMaxi}
                isSortable={false}
                isDraggable={false}
                onDownloadRevalidate={(downloadMedia) => {
                    if (downloadMedia) {
                    // eslint-disable-next-line no-param-reassign
                        downloadMedia.download_count += 1;
                        mutateRemixesAlbums(remixesAlbum, { revalidate: false });
                    }
                }}
            />
        </ListWrapper>
    );
}
