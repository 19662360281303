import classNames from 'classnames';
import { useCallback } from 'react';

import { Media } from '@bpm-web-app/download-api-sdk';
import { VersionPill } from '../../version-pill/version-pill';
import pillStyles from '../../version-pill/version-pill.module.css';
import styles from './versions-column-supreme.module.css';

interface VersionsColumnProps {
    versionsList?: Media[];
    onMoreVersionsClick?: () => void;
    onVersionClick?: (version: Media) => void;
    isAccordionOpen: boolean;
    isPremiumOnly?: boolean;
    hasPremiumOnlyAccess?: boolean;
    setCurrentMediaInContext?: () => void;
}

const limitTablet = 4;
const limitDesktop = 4;
const limitLargeDesktop = 8;

export function ShowMoreButton({ onMoreVersionsClick, isAccordionOpen }: { onMoreVersionsClick?: () => void; isAccordionOpen: boolean }) {
    return (
        <button
            type="button"
            className={classNames(pillStyles['version-pill'])}
            data-tip={isAccordionOpen ? 'Close' : 'View All'}
            onClick={(e) => {
                e.stopPropagation();
                onMoreVersionsClick?.();
            }}
        >
            <span className={pillStyles['version-pill__text']}>…</span>
            <span className={pillStyles['version-pill__download']}>…</span>
        </button>
    );
}

export function VersionsColumnSupreme({ versionsList, onMoreVersionsClick, onVersionClick, isAccordionOpen, isPremiumOnly, hasPremiumOnlyAccess, setCurrentMediaInContext }: VersionsColumnProps) {
    const slicePills = useCallback((limit: number) => versionsList?.slice(0, versionsList.length > limit ? limit - 1 : limit), [versionsList]);

    if (!versionsList) {
        return null;
    }

    if (isPremiumOnly && !hasPremiumOnlyAccess) {
        return (
            <div className={classNames('hide-filters-open', styles['versions-column'])}>
                <button
                    type="button"
                    className={classNames(styles['versions-column__upgrade-to-unlock'])}
                    onClick={(e) => {
                        e.stopPropagation();
                        if (setCurrentMediaInContext) setCurrentMediaInContext();
                    }}
                >
                    UPGRADE TO UNLOCK
                </button>
            </div>
        );
    }

    return (
        <div className={classNames('hide-filters-open', styles['versions-column'])}>
            <div className={classNames(styles['versions-column'], styles['versions-column__default'])}>
                {slicePills(limitTablet)?.map((media) => (
                    <VersionPill media={media} key={media.id} onClick={() => onVersionClick?.(media)} />
                ))}

                {versionsList.length > limitTablet && <ShowMoreButton isAccordionOpen={isAccordionOpen} onMoreVersionsClick={onMoreVersionsClick} />}
            </div>

            <div className={classNames(styles['versions-column'], styles['versions-column__from-desktop'])}>
                {slicePills(limitDesktop)?.map((media) => (
                    <VersionPill media={media} key={media.id} onClick={() => onVersionClick?.(media)} />
                ))}
                {versionsList.length > limitDesktop && <ShowMoreButton isAccordionOpen={isAccordionOpen} onMoreVersionsClick={onMoreVersionsClick} />}
            </div>

            <div className={classNames(styles['versions-column'], styles['versions-column__from-large-desktop'])}>
                {slicePills(limitLargeDesktop)?.map((media) => (
                    <VersionPill media={media} key={media.id} onClick={() => onVersionClick?.(media)} />
                ))}

                {versionsList.length > limitLargeDesktop && <ShowMoreButton isAccordionOpen={isAccordionOpen} onMoreVersionsClick={onMoreVersionsClick} />}
            </div>
        </div>
    );
}
