import { FC, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { rebuildReactTooltip } from '@bpm-web-app/utils';
import styles from './three-dots-sheet.module.css';
import Add from '../../../assets/icons/add-to-playlist-lg.svg';
import AddToFavorites from '../../../assets/icons/favorite-heart.svg';
import AddToQueue from '../../../assets/icons/add-to-queue.svg';
import FindRemixes from '../../../assets/icons/find-remixes.svg';
import RemoveFromQueue from '../../../assets/icons/remove-track-from-queue-dynamic.svg';
import ReportTrack from '../../../assets/icons/report-track.svg';
import Edit from '../../../assets/icons/pencil.svg';
import Follow from '../../../assets/icons/follow.svg';
import Unfollow from '../../../assets/icons/follow-active.svg';
import Download from '../../../assets/icons/download.svg';
import Midi from '../../../assets/icons/create/midi.svg';
import GoToPack from '../../../assets/icons/create/go-to.svg';
import Play from '../../../assets/icons/player-play.svg';
import AddToDrive from '../../../assets/icons/create/add-to-drive.svg';
import Back from '../../../assets/icons/chevron-left.svg';
import PlaylistItem from '../../../assets/icons/playlist-item.svg';

import { BreakpointView } from '../ui';
import { CustomIcon } from '../custom-icon/custom-icon';

const ICONS = [
    'addToPlaylist',
    'favorite',
    'unFavorite',
    'addToQueue',
    'addToCrate',
    'removeFromCrate',
    'findSimilar',
    'findRemixes',
    'playCard',
    'reportTrack',
    'removeFromQueue',
    'featuredIn',
    'editPlaylist',
    'editDriveName',
    'deletePlaylist',
    'follow',
    'unFollow',
    'followPlaylist',
    'unFollowPlaylist',
    'deleteTrack',
    'deleteDrive',
    'addToDrive',
    'download',
    'midi',
    'goTo',
    'playDemo',
    'share',
    'back',
    'playlistItem',
    'folder',
    'sharedFolder',
] as const;

export type IconType = typeof ICONS[number];

interface ThreeDotsMenuItemProps {
    action: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    legend: string;
    icon?: IconType;
    highlight?: boolean;
    extraLabel?: string;
    disabled?: boolean;
    disabledDataTip?: string;
    keyText?: string;
}

const ThreeDotsMenuItem: FC<ThreeDotsMenuItemProps> = ({ action, legend, icon, highlight = false, extraLabel, disabled = false, disabledDataTip, keyText }) => {
    const [isHovering, setIsHovering] = useState(false);
    const getIcon = useCallback(() => {
        switch (icon) {
            case 'addToPlaylist':
                return <Add />;

            case 'addToDrive':
                return <AddToDrive />;

            case 'download':
                return <Download />;

            case 'midi':
                return <Midi />;

            case 'goTo':
                return <GoToPack />;

            case 'playDemo':
                return <Play />;

            case 'favorite':
            case 'unFavorite':
                return <AddToFavorites />;

            case 'addToQueue':
                return <AddToQueue />;

            case 'addToCrate':
                return <CustomIcon isIconHoverActive={isHovering} hasIconHover={!disabled} type="add-to-crate-icon" size="regular" color={disabled ? 'boulder' : 'gray'} />;

            case 'removeFromCrate':
                return <CustomIcon isIconHoverActive={isHovering} hasIconHover={!disabled} type="remove-from-crate-icon" size="regular" color="current" />;

            case 'findSimilar':
                return <CustomIcon isIconHoverActive={isHovering} hasIconHover={!disabled} type="find-similar-icon" size="regular" color={disabled ? 'boulder' : 'gray'} />;

            case 'findRemixes':
                return <FindRemixes />;

            case 'featuredIn':
                return <CustomIcon isIconHoverActive={isHovering} hasIconHover={!disabled} type="featured-in-icon" size="regular" color={disabled ? 'boulder' : 'gray'} />;

            case 'reportTrack':
                return <ReportTrack />;

            case 'removeFromQueue':
                return <RemoveFromQueue />;

            case 'editPlaylist':
            case 'editDriveName':
                return <Edit />;

            case 'deletePlaylist':
            case 'deleteTrack':
            case 'deleteDrive':
                return <CustomIcon isIconHoverActive={isHovering} hasIconHover={!disabled} type="delete-filled-icon" size="regular" color="delete" />;
            case 'follow':
                return <Follow />;
            case 'unFollow':
                return <Unfollow />;
            case 'followPlaylist':
                return <CustomIcon isIconHoverActive={isHovering} hasIconHover={!disabled} type="plus-icon" size="regular" color={disabled ? 'boulder' : 'gray'} />;
            case 'unFollowPlaylist':
                return <CustomIcon isIconHoverActive={isHovering} hasIconHover={!disabled} type="checkmark" size="regular" color={disabled ? 'boulder' : 'gray'} />;
            case 'share':
                return <CustomIcon isIconHoverActive={isHovering} hasIconHover={!disabled} type="share-url-icon" size="regular" color={disabled ? 'boulder' : 'gray'} />;
            case 'back':
                return <Back />;
            case 'playlistItem':
                return <PlaylistItem />;
            case 'folder':
                return <CustomIcon isIconHoverActive={isHovering} hasIconHover={!disabled} type="folder-icon" size="regular" color={disabled ? 'boulder' : 'gray'} />;
            case 'sharedFolder':
                return <CustomIcon isIconHoverActive={isHovering} hasIconHover={!disabled} type="shared-folder" size="regular" color={disabled ? 'boulder' : 'gray'} />;
            default:
                return null;
        }
    }, [disabled, icon, isHovering]);

    useEffect(() => {
        rebuildReactTooltip();
    }, []);

    const isDownloadStyleType = useCallback(() => {
        switch (keyText) {
            case 'download-credits':
            case 'download-preset-credits':
            case 'download-midi-credits':
                return true;

            default:
                return false;
        }
    }, [keyText]);

    return (
        <li
            className={classNames(styles['three-dots-menu__list-item'], { [styles['three-dots-menu__list-item--downloadable']]: isDownloadStyleType() })}
            data-tip={disabled ? disabledDataTip : undefined}
            data-place="left"
        >
            <button
                type="button"
                className={classNames(styles['three-dots-menu__btn'], { [styles['three-dots-menu__btn--downloadable']]: isDownloadStyleType() }, styles[`three-dots-menu__btn--icon-${icon}`], { [styles['three-dots-menu__btn--active']]: highlight }, { [styles['three-dots-menu__btn--disable']]: disabled }, { [styles[`three-dots-menu__btn--icon-${icon}--disable`]]: disabled })}
                onClick={(e) => {
                    e.preventDefault();
                    if (!disabled) action(e);
                }}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
            >
                {getIcon()}
                <span className={classNames({ [styles['three-dots-menu__red']]: icon?.includes('delete') }, { [styles['three-dots-menu__red--hover']]: icon?.includes('delete') && isHovering })}>{legend}</span>
                {isDownloadStyleType() ? <div className={classNames(styles['three-dots-menu__divider'], { [styles['three-dots-menu__divider-preset']]: keyText === 'download-preset-credits' })}>|</div> : null}
                {extraLabel && <span className={styles['three-dots-menu__btn-extra-label']}>{extraLabel}</span>}
            </button>
            {(disabled && disabledDataTip !== undefined) ? <BreakpointView desktopChildren={null} mobileChildren={<div className={styles['three-dots-menu__btn--disable-text']}>{disabledDataTip}</div>} /> : null}
        </li>
    );
};

export default ThreeDotsMenuItem;
