import { Album, ArtistAlbumsSortByQueryOptions, ArtistSortByQueryOptions, SortByQueryOptions } from '@bpm-web-app/download-api-sdk';
import { Media } from '@bpm-web-app/stream-api-sdk';
import { sortArray } from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import { Fragment, useMemo, useState } from 'react';
import MoreOptionsSheet from '../more-options-sheet/more-options-sheet';
import { LocalSearchSort } from '../nav-my-playlist-categories/utils';
import SortOptionsSheetItem from './sort-options-sheet-item';

export type LocalSortingKeys = 'date' | 'title' | 'default';
export type APISortingKeys = 'trending' | SortByQueryOptions;

export type SortOption<T extends LocalSortingKeys | APISortingKeys | ArtistSortByQueryOptions | ArtistAlbumsSortByQueryOptions | LocalSearchSort> = {
    actionType: T;
    label: string;
};

type Props<T extends LocalSortingKeys | APISortingKeys | ArtistSortByQueryOptions | ArtistAlbumsSortByQueryOptions | LocalSearchSort> = {
    leftPosition: number;
    topPosition: number;
    onAction: (type: T) => void;
    visible: boolean;
    setVisible: (visible: boolean) => void;
    /* eslint-disable react/require-default-props */
    selectedAction?: T;
    options?: SortOption<T>[];
    /* eslint-enable react/require-default-props */
};

const localSortOptions: SortOption<LocalSortingKeys>[] = [
    { actionType: 'default', label: 'Default Order' },
    { actionType: 'date', label: 'Most recent' },
    { actionType: 'title', label: 'Title' },
];

/**
 *  NOTE: Default api sort is `trending desc`
 *  https://api-docs.bpmsupreme.com/stream.html#tag/Search/operation/search_media
 */
export const apiSortOptions: SortOption<SortByQueryOptions>[] = [
    { actionType: SortByQueryOptions.Trending, label: 'Trending' },
    { actionType: SortByQueryOptions.DateDesc, label: 'Most Recent' },
    { actionType: SortByQueryOptions.TitleAsc, label: 'Title' },
];

/**
 *  NOTE: New Releases sorting options
 *  https://api-docs.bpmsupreme.com/stream.html#tag/Search/operation/search_media
 */
export const allApiSortOptions: SortOption<SortByQueryOptions>[] = [
    { actionType: SortByQueryOptions.TitleAsc, label: 'Title (a-z)' },
    { actionType: SortByQueryOptions.TitleDesc, label: 'Title (z-a)' },
    { actionType: SortByQueryOptions.BpmAsc, label: 'BPM (min-max)' },
    { actionType: SortByQueryOptions.BpmDesc, label: 'BPM (max-min)' },
    { actionType: SortByQueryOptions.ArtistAsc, label: 'Artist (a-z)' },
    { actionType: SortByQueryOptions.ArtistDesc, label: 'Artist (z-a)' },
    { actionType: SortByQueryOptions.DateDesc, label: 'Recent' },
    { actionType: SortByQueryOptions.DateAsc, label: 'Oldest' },
];

/**
 *  NOTE: All API sorting options
 *  https://api-docs.bpmsupreme.com/stream.html#tag/Search/operation/search_media
 */
export const allApiSortOptionsWithRelevance: SortOption<SortByQueryOptions>[] = [
    ...allApiSortOptions,
    { actionType: SortByQueryOptions.Relevance, label: 'Relevance' },
];

/** NOTE: ATM Relevance is only available for search endpoint */
export const apiSortOptionsWithRelevance: SortOption<APISortingKeys>[] = [...apiSortOptions, { actionType: SortByQueryOptions.Relevance, label: 'Relevance' }];

export function SortOptionsSheet<T extends LocalSortingKeys | APISortingKeys | ArtistSortByQueryOptions | ArtistAlbumsSortByQueryOptions | LocalSearchSort>({
    leftPosition,
    topPosition,
    selectedAction,
    options,
    onAction,
    visible,
    setVisible
}: Props<T>) {
    const optionsToRender = useMemo<SortOption<T>[]>(() => options || (localSortOptions as unknown as SortOption<T>[]), [options]);

    return (
        <MoreOptionsSheet leftPosition={leftPosition} topPosition={topPosition} setIsVisible={setVisible} isVisible={visible}>
            {optionsToRender.map((option) => (
                <Fragment key={option.actionType}>
                    <SortOptionsSheetItem<T> label={option.label} action={onAction} actionType={option.actionType} isSelected={selectedAction === option.actionType} />
                </Fragment>
            ))}
        </MoreOptionsSheet>
    );
}

export default SortOptionsSheet;

/**
 *
 * @param array Array to sort
 * @param sortingKey `LocalSortingKeys`
 * @returns New Array of same type but sorted
 */
export function sortMediaLocally(
    // NOTE: Adding any here since in some cases the types might overlap giving a false positive
    array: Media[] | Album[] | any[],
    sortingKey: LocalSortingKeys
) {
    switch (sortingKey) {
        case 'title': {
            return sortArray(array, 'name');
        }
        case 'date': {
            const arrayWithNumberDate = array.map<{ date: number }>((media: any) => ({ ...media, date: new Date(media.release_date).getTime() }));
            return sortArray(arrayWithNumberDate, 'date', 'DESC');
        }
        default: {
            return array;
        }
    }
}

export function useLocalSort(initialValue: LocalSortingKeys = 'default') {
    return useState<LocalSortingKeys>(initialValue);
}

/**
 * Helper function for API sorting
 * @returns function to handle sorting key
 */
export function useApiSort() {
    const router = useRouter();

    return (nextSort: APISortingKeys) => {
        if (nextSort === 'trending') {
            // NOTE: Trending is the default sort
            const nextQuery: any = { ...router.query };
            if (nextQuery.sortBy) {
                delete nextQuery.sortBy;
            }
            router.push({ query: nextQuery });
        } else {
            router.push({ query: { ...router.query, sortBy: nextSort } });
        }
    };
}
