import { Category, Genre } from '@bpm-web-app/stream-api-sdk';
import { useCallback } from 'react';
import { Genre as CreateGenre } from '@bpm-web-app/create-api-sdk';
import { AppLink } from '../shared/app-link/app-link';

export function useBpmLink() {
    const link = useCallback((bpm: number) => {
        return (
            <AppLink href={`/releases?bpm=${bpm}&bpm=${bpm}`}>
                <a className="relative-link">{bpm}</a>
            </AppLink>
        );
    }, []);
    return link;
}

export function useKeyLinks() {
    const link = useCallback((actualKey?: string, displayKey?: string) => {
        if (!displayKey) {
            return null;
        }
        return (
            <AppLink href={`/releases?key=${actualKey || displayKey}`}>
                <a className="relative-link">{displayKey}</a>
            </AppLink>
        );
    }, []);
    return link;
}

export function useGenreLinks() {
    const link = useCallback((genre?: Genre) => {
        if (!genre) {
            return null;
        }
        return (
            <AppLink href={`/genres?genre=${genre.slug}`}>
                <a className="relative-link">{genre.name}</a>
            </AppLink>
        );
    }, []);

    return link;
}

export function useCreateGenreLinks() {
    const link = useCallback((genre?: CreateGenre) => {
        if (!genre) {
            return null;
        }
        return (
            <AppLink href={`/genres/${genre.name}`}>
                <a className="underline-link">{genre.name}</a>
            </AppLink>
        );
    }, []);

    return link;
}

export function useCategoryLinks() {
    const link = useCallback((category?: Category) => {
        if (!category) {
            return null;
        }
        return (
            <AppLink href={`/genres?category=${category.slug}`} isFilterLink>
                <a className="relative-link">{category.name}</a>
            </AppLink>
        );
    }, []);
    return link;
}

export function usePlaylistCategoryLinks() {
    const link = useCallback((category?: Category) => {
        if (!category) {
            return null;
        }
        return (
            <AppLink href={`/exclusive-playlists/category/${category.id}`}>
                <div className="relative-link">{category.name}</div>
            </AppLink>
        );
    }, []);
    return link;
}

export function useCuratedSetCategoryLinks() {
    const link = useCallback((category?: Category) => {
        if (!category) {
            return null;
        }
        return (
            <AppLink href={`/curated-sets/category/${category.id} `}>
                <div className="relative-link">{category.name}</div>
            </AppLink>
        );
    }, []);
    return link;
}
