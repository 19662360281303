import { State as PlayerStateEnum } from '@bpm-web-app/utils';
import classNames from 'classnames';
import PauseIcon from '../../../../assets/icons/supreme/pause-large.svg';
import PlayIcon from '../../../../assets/icons/supreme/play-large.svg';
import NextIcon from '../../../../assets/icons/supreme/player-next.svg';
import PrevIcon from '../../../../assets/icons/supreme/player-prev.svg';
import styles from '../../supreme-maxi-player.module.css';

interface PlayerPrimaryControlsProps {
    hasPrevTrack: boolean;
    hasNextTrack: boolean;
    playerState: PlayerStateEnum;
    onPlay: () => void;
    onPause: () => void;
    nextTrack: () => void;
    prevTrack: () => void;
}

export function PlayerPrimaryControls({ hasPrevTrack, hasNextTrack, playerState, onPlay, onPause, nextTrack, prevTrack }: PlayerPrimaryControlsProps) {
    return (
        <div className={classNames(styles['maxi-player__desktop-left-controls'])}>
            <button
                className={classNames(styles['maxi-player__player-ctrl'], styles['maxi-player__player-ctrl--prev'])}
                type="button"
                onClick={prevTrack}
                disabled={!hasPrevTrack}
                aria-label="Previous Track"
            >
                <PrevIcon />
            </button>
            <button
                onClick={() => {
                    if (playerState === PlayerStateEnum.Playing) {
                        onPause();
                    } else {
                        onPlay();
                    }
                }}
                className={classNames(styles['maxi-player__player-ctrl'], {
                    [styles['maxi-player__player-ctrl--play']]: playerState !== PlayerStateEnum.Playing,
                    [styles['maxi-player__player-ctrl--pause']]: playerState === PlayerStateEnum.Playing,
                })}
                type="button"
                aria-label={playerState === PlayerStateEnum.Playing ? 'Pause' : 'Play'}
            >
                {playerState === PlayerStateEnum.Playing ? <PauseIcon /> : <PlayIcon />}
            </button>
            <button
                className={classNames(styles['maxi-player__player-ctrl'], styles['maxi-player__player-ctrl--next'])}
                type="button"
                onClick={nextTrack}
                disabled={!hasNextTrack}
                aria-label="Next Track"
            >
                <NextIcon />
            </button>
        </div>
    );
}
