import { Notification, NotificationTypeIdentifier } from '@bpm-web-app/supreme-api-sdk';
import { memo, MouseEvent } from 'react';
import {
    LeadingActions,
    SwipeableList,
    SwipeableListItem,
    SwipeAction,
    TrailingActions,
    Type as ListType,
} from 'react-swipeable-list';
import styles from './user-notifications.module.css';
import UserNotificationsItem from './user-notifications-item';
import 'react-swipeable-list/dist/styles.css';
import BPMIcons from '../shared/bpm-icons/bpm-icons';

interface NotificationSectionProps {
    title: string;
    notifications: Notification[];
    onDeleteNotification: (id: string) => void;
    platform: Notification.PlatformEnum;
    isPlaying: (notification: Notification) => boolean;
    handlePlay: (notification: Notification) => void;
    togglePlayPause: () => void;
    onClose: () => void;
    isMobile?: boolean;
}

export function NotificationSection({ title, notifications, onDeleteNotification, platform, isPlaying, handlePlay, togglePlayPause, isMobile, onClose }: NotificationSectionProps) {
    const handleDeleteOnSwipe = (e: MouseEvent, id: string) => {
        e.preventDefault();
        e.stopPropagation();
        onDeleteNotification(id);
    };

    const handlePlayOnSwipe = (e: MouseEvent, item: Notification) => {
        e.preventDefault();
        e.stopPropagation();
        if (isPlaying(item)) {
            togglePlayPause();
        } else {
            handlePlay(item);
        }
    };

    const leadingActions = (item: Notification) => (
        <LeadingActions>
            <SwipeAction
                onClick={() => null}
            >
                <button
                    type="button"
                    onClick={(e) => handlePlayOnSwipe(e, item)}
                    className={styles['user-notifications-section__play-item']}>
                    {isPlaying(item) ? <BPMIcons.PauseIcon /> : <BPMIcons.PlayIcon />}
                </button>
            </SwipeAction>
        </LeadingActions>
    );

    const trailingActions = (item: Notification) => (
        <TrailingActions>
            <SwipeAction
                onClick={() => null}
            >
                <button
                    type="button"
                    onClick={(e) => handleDeleteOnSwipe(e, item.id)}
                    className={styles['user-notifications-section__delete-item']}>
                    {item.type_identifier === NotificationTypeIdentifier.CrateDownloadReady ? <div className={styles['user-notifications-section__delete-icon']}><BPMIcons.DeleteIcon /></div> : <div className={styles['user-notifications-section__close-icon']}><BPMIcons.CloseIcon /></div>}
                </button>
            </SwipeAction>
        </TrailingActions>
    );

    if (notifications.length === 0) return null;

    return (
        <>
            <h4 className={styles['user-notifications-section']}>{title}</h4>
            {notifications.map((item) => (
                isMobile === true ?
                    (
                        <div key={item.id}>
                            <SwipeableList
                                fullSwipe={false}
                                type={ListType.IOS}
                            >
                                <SwipeableListItem
                                    key={item.id}
                                    fullSwipe={false}
                                    leadingActions={isMobile ? leadingActions(item) : null}
                                    trailingActions={isMobile ? trailingActions(item) : null}

                                >
                                    <UserNotificationsItem
                                        key={item.id}
                                        notification={item}
                                        onDeleteNotification={onDeleteNotification}
                                        platform={platform}
                                        isPlaying={isPlaying(item)}
                                        handlePlay={() => handlePlay(item)}
                                        togglePlayPause={togglePlayPause}
                                        onClose={onClose}
                                    />
                                </SwipeableListItem>
                            </SwipeableList>
                        </div>
                    ) : (
                        <UserNotificationsItem
                            key={item.id}
                            notification={item}
                            onDeleteNotification={onDeleteNotification}
                            platform={platform}
                            isPlaying={isPlaying(item)}
                            handlePlay={() => handlePlay(item)}
                            togglePlayPause={togglePlayPause}
                            onClose={onClose}
                        />
                    )
            ))
            }
        </>
    );
}
export default memo(NotificationSection);
