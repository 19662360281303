import { ReactNode, createContext, useState, useMemo, useCallback, useEffect } from 'react';
import { RenderLocationType } from '../three-dots-sheet/three-dots-sheet.context';
import { ContextMenuItemProps } from './types';

type ContextMenuAlignment = 'left' | 'right';

export type ContextMenuArgs = {
    id: number | string
    options: ContextMenuItemProps[];
    left: number;
    top: number;
    align?: ContextMenuAlignment;
    renderLocationPosition?: RenderLocationType;
    buttonWidth?: number;
};

export interface ContextMenuContextProperties {
    options: ContextMenuItemProps[];
    isOpen: (id: string | number) => boolean;
    updateOptions: (options: ContextMenuItemProps[]) => void
    closeOptions: () => void;
    openContextMenu: (args: ContextMenuArgs) => void;
    leftPosition: number;
    topPosition: number;
    move: (x: number, y: number) => void;
    alignment: ContextMenuAlignment;
    renderLocation?: RenderLocationType;
    actionButtonWidth?: number;
}

export const ContextMenuContext = createContext<ContextMenuContextProperties>({
    options: [],
    isOpen: () => false,
    closeOptions: () => null,
    updateOptions: () => null,
    openContextMenu: () => null,
    leftPosition: 0,
    topPosition: 0,
    move: () => null,
    alignment: 'left',
    renderLocation: 'app',
    actionButtonWidth: 0,
});

export interface ContextMenuProviderProps {
    children: ReactNode;
    initialState?: {
        options?: ContextMenuItemProps[];
        leftPosition?: number;
        topPosition?: number;
        renderLocation?: RenderLocationType;
        actionButtonWidth?: number;
    };
}

export function ContextMenuProvider({ children, initialState }: ContextMenuProviderProps) {
    const [leftPosition, setLeftPosition] = useState<number>(initialState?.leftPosition || 0);
    const [id, setId] = useState<string | number>('');
    const [topPosition, setTopPosition] = useState<number>(initialState?.topPosition || 0);
    const [optionsToShow, setOptionsToShow] = useState<ContextMenuItemProps[]>(initialState?.options || []);
    const [renderLocation, setRenderLocation] = useState<ContextMenuContextProperties['renderLocation']>('app');
    const [alignment, setAlignment] = useState<ContextMenuContextProperties['alignment']>('left');
    const [actionButtonWidth, setActionButtonWidth] = useState<ContextMenuContextProperties['actionButtonWidth']>(0);

    const move = useCallback((x: number, y: number) => {
        setLeftPosition(x);
        setTopPosition(y);
    }, [setLeftPosition, setTopPosition]);

    const close = useCallback(() => {
        setOptionsToShow([]);
    }, []);

    const isOpen = useCallback((idToCheck: string | number) => {
        return id === idToCheck && optionsToShow.length > 0;
    }, [optionsToShow, id]);

    const updateOptions = useCallback((options: ContextMenuItemProps[]) => {
        setOptionsToShow(options);
    }, []);

    const value = useMemo(
        () => ({
            options: optionsToShow,
            closeOptions: close,
            isOpen,
            updateOptions,
            openContextMenu: ({
                id: idToSet,
                options,
                left,
                top,
                renderLocationPosition,
                align,
                buttonWidth,
            }: ContextMenuArgs) => {
                if (renderLocationPosition) {
                    setRenderLocation(renderLocationPosition);
                } else {
                    setRenderLocation('app');
                }

                setId(idToSet);

                if (align) {
                    setAlignment(align);
                } else {
                    setAlignment('left');
                }

                if (buttonWidth) {
                    setActionButtonWidth(buttonWidth);
                } else {
                    setActionButtonWidth(0);
                }

                setOptionsToShow(options);
                setTopPosition(top);
                setLeftPosition(left);
            },
            leftPosition,
            topPosition,
            move,
            alignment,
            renderLocation,
            actionButtonWidth,
        }),
        [optionsToShow, leftPosition, topPosition, move, alignment, renderLocation, actionButtonWidth, updateOptions, close, isOpen]
    );
    return <ContextMenuContext.Provider value={value}>{children}</ContextMenuContext.Provider>;
}
