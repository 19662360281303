import { createAppRoutes } from '@bpm-web-app/utils';
import Link from 'next/link';
import { useMemo } from 'react';
import classNames from 'classnames';
import styles from './track-list-create.module.css';

interface ArtworkImageProps {
    artworkURL: string;
    hintText?: string;
    packId?: string;
    isLastItem?: boolean;
}

function TrackListCreateArtwork({ artworkURL, hintText, packId, isLastItem }: ArtworkImageProps) {
    const renderedArtwork = useMemo(() => {
        return (
            <div className={styles['track-list-create__artwork-container']}>
                <img src={artworkURL} alt={hintText || ''} />
                <div className={classNames(styles['track-list-create__artwork-container--hover-container'], {
                    [styles['track-list-create__artwork-container--hover-container__last-item']]: isLastItem
                })}>
                    <div className={styles['track-list-create__artwork-container--artwork-title']}>{hintText}</div>
                </div>
            </div>
        );
    }, [artworkURL, hintText, isLastItem]);

    if (!packId) {
        return renderedArtwork;
    }
    return (
        <Link href={createAppRoutes.packsSlug(packId)}>
            {renderedArtwork}
        </Link>
    );
}

export default TrackListCreateArtwork;
