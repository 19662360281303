import { ChangeEventHandler, RefCallback, useMemo, useRef, useState, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { FieldValues } from 'react-hook-form';
import styles from './file-uploader.module.css';
import { ReactComponent as PlusIcon } from '../../../assets/icons/add.svg';
import { ReactComponent as CheckmarkIcon } from '../../../assets/icons/check.svg';
import { ApplicationErrorText } from '../../pages/artist-portal/artist-application/artist-application-text/artist-application-text';

interface FileUploaderProps {
    text?: string;
    hint?: string;
    hints?: string[];
    filename?: string;
    setValue?: (file: FileList) => void;
    accept?: 'wav' | 'image' | 'file' | 'video' | 'audio'
    inputProps?: FieldValues;
    errorMessage?: string;
    setPreviewFile?: (file: File) => void;
}

export function FileUploader({ text = 'Choose File', hint, hints, filename, setValue = () => null, accept, inputProps = {}, errorMessage, setPreviewFile }: FileUploaderProps) {
    const fileRef = useRef([]);
    const [uploadedFileName, setUploadedFileName] = useState(filename !== undefined ? filename : '');
    const [hasErrors, setHasErrors] = useState(false);
    const [dragActive, setDragActive] = useState(false);

    useEffect(() => {
        setHasErrors(!!errorMessage);
    }, [errorMessage]);

    // handle drag events
    const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
        e.stopPropagation();
    };

    const confirmAcceptedFileType = (file: FileList) => {
        switch (accept) {
            case 'image':
                return file[0].type.toLowerCase().includes('image/');
            case 'video':
                return file[0].type.toLowerCase().includes('video/');
            case 'wav':
                return file[0].type.toLowerCase().endsWith('wav');
            case 'audio':
                return file[0].type.toLowerCase().endsWith('wav') || file[0].type.toLowerCase().endsWith('mp3');
            case 'file':
                return true;
            default:
                return false;
        }
    };

    const acceptedFileTypes = () => {
        switch (accept) {
            case 'image':
                return 'Accepted file types .jpg / .jpeg / .png';
            case 'video':
                return 'Accepted file types: .mov / .mp4';
            case 'wav':
                return 'Accepted file types: .wav';
            case 'audio':
                return 'Accepted file types: .wav / .mp3';
            case 'file':
                return '';
            default:
                return '';
        }
    };

    // triggers when file is dropped
    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            // Check file type
            if (confirmAcceptedFileType(e.dataTransfer.files)) {
                setUploadedFileName(e.dataTransfer.files[0].name);

                if (e.dataTransfer.files.length > 0 && hasErrors) setHasErrors(false);
                if (e.dataTransfer.files.length === 0 && errorMessage) setHasErrors(true);
                setValue(e.dataTransfer.files);
                if (setPreviewFile) setPreviewFile(e.dataTransfer.files[0]);
            } else {
                setHasErrors(true);
            }
        }
    };

    const handleClick = useCallback(() => {
        if (fileRef.current && fileRef.current[0]) fileRef.current[0].click();
    }, [fileRef]);

    const allowedFileTypes = useMemo(() => {
        switch (accept) {
            case 'wav':
                return 'audio/wav';
            case 'audio':
                return 'audio/*';
            case 'image':
                return 'image/*';
            case 'file':
                return '*';
            case 'video':
                return 'video/*';
            default:
                return '*';
        }
    }, [accept]);

    const hintTextView = (hintText: string, indent = false) => {
        return (
            <div key={hintText} className={classNames({ [styles['file-uploader--hint-text-indent']]: indent }, { [styles['file-uploader--hint-text']]: !indent })}>
                {hintText}
            </div>
        );
    };

    const hintsComponent = useMemo(() => {
        if (hints && hints.length > 0) {
            return (
                <div>
                    {hint ? hintTextView(hint) : null}
                    {hints.map((hintText) => hintTextView(`• ${hintText}`, hint !== undefined))}
                </div>
            );
        } if (hint) {
            return hintTextView(hint);
        }

        if (accept === 'wav') {
            return hintTextView('Only .WAV files are accepted to ensure highest audio quality.');
        }
        return null;
    }, [accept, hint, hints]);

    return (
        <div className={styles['file-uploader']} onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}>
            <button type="button" aria-label="upload" className={classNames(styles['file-uploader--container'], { [styles['file-uploader--container--has-error']]: hasErrors, [styles['file-uploader--active-drop']]: dragActive })} onClick={handleClick}>
                <input
                    type="file"
                    multiple={false}
                    hidden
                    accept={allowedFileTypes}
                    {...inputProps}
                    ref={(element) => {
                        fileRef.current[0] = element;

                        if (inputProps && 'ref' in inputProps) {
                            (inputProps as { ref: RefCallback<HTMLInputElement> }).ref(element);
                        }
                    }}
                    onChange={(e) => {
                        setUploadedFileName(e.target.value.split('\\').slice(-1)[0]);

                        if (e.target.value.length > 0 && hasErrors) setHasErrors(false);
                        if (e.target.value.length === 0 && errorMessage) setHasErrors(true);

                        if (inputProps && 'onChange' in inputProps) {
                            (inputProps as { onChange: ChangeEventHandler<HTMLInputElement> }).onChange(e);
                        }
                        if (setPreviewFile) setPreviewFile(e.target.files[0]);

                        if (setValue && e.target.files) setValue(e.target.files);
                        e.target.value = '';
                    }}

                />
                <div
                    className={styles['file-uploader--uploader-button']}
                >
                    {uploadedFileName === '' ?
                        null
                        :
                        <CheckmarkIcon className={styles['file-uploader--uploader-button-icon-check']} height={24} width={24} />
                    }
                    {uploadedFileName === '' ?
                        <PlusIcon className={styles['file-uploader--uploader-button-icon']} height={24} width={24} />
                        :
                        null
                    }

                    {uploadedFileName === '' ?
                        <div
                            className={styles['file-uploader--uploader-button-text']}
                        >
                            {text}
                        </div> :
                        <div className={classNames(styles['file-uploader--selected-file-text'])}>
                            {`Selected file: ${uploadedFileName}`}
                        </div>
                    }

                </div>
            </button>
            {hintsComponent}
            {hasErrors ?
                <ApplicationErrorText text={errorMessage ? `${errorMessage} ${acceptedFileTypes()}` : `Incorrect file type, please try again. ${acceptedFileTypes()}`} />
                : null}
        </div>

    );
}
