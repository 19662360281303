import { ReactNode } from 'react';

export enum ArtistPortalListPreset {
    AlbumAnalytics = 'album-analytics',
    AlbumAnalyticsSmall = 'album-analytics-small',
    Location = 'location',
    Sources = 'sources',
    DJType = 'dj-type'

}

export enum ColumnType {
    Title = 'title',
    TitleWithoutArtist = 'title-without-artist',
    Flag = 'flag',
    Source = 'source',
    Previews = 'previews',
    Downloads = 'downloads',
    EstReach = 'estreach',
    Impressions = 'impressions',
    Date = 'date',
    Percentage = 'percentage',
    Actions = 'actions',
    Accordion = 'accordion',
    Empty = 'empty',
    Count = 'count',
    Icon = 'icon',
}

interface ColumnDefinition {
    heading: ReactNode;
    type: ColumnType;
    class?: string
}

export const columnDefinitions: { [key in ArtistPortalListPreset]?: ColumnDefinition[] } = {
    [ArtistPortalListPreset.AlbumAnalytics]: [
        { heading: '', type: ColumnType.Accordion },
        { heading: 'Title', type: ColumnType.Title },
        { heading: 'Previews', type: ColumnType.Previews },
        { heading: 'Downloads', type: ColumnType.Downloads },
        { heading: 'Est. Reach', type: ColumnType.EstReach },
        { heading: 'Impressions', type: ColumnType.Impressions },
        { heading: 'Released', type: ColumnType.Date },
        { heading: 'placeholder', type: ColumnType.Actions }
    ],
    [ArtistPortalListPreset.AlbumAnalyticsSmall]: [
        { heading: '', type: ColumnType.TitleWithoutArtist },
        { heading: 'Downloads', type: ColumnType.Downloads },
    ],
    [ArtistPortalListPreset.Location]: [
        { heading: '', type: ColumnType.Count },
        { heading: 'Source', type: ColumnType.Flag },
        { heading: '', type: ColumnType.Source },
        { heading: 'Percentage', type: ColumnType.Percentage },
        { heading: 'Previews', type: ColumnType.Previews },
        { heading: 'Downloads', type: ColumnType.Downloads },
        { heading: '', type: ColumnType.Actions }
    ],
    [ArtistPortalListPreset.Sources]: [
        { heading: '', type: ColumnType.Count },
        { heading: 'Source', type: ColumnType.Icon },
        { heading: '', type: ColumnType.Source },
        { heading: 'Percentage', type: ColumnType.Percentage },
        { heading: 'Previews', type: ColumnType.Previews },
        { heading: 'Downloads', type: ColumnType.Downloads },
        { heading: 'Est. Reach', type: ColumnType.EstReach },
        { heading: 'Impressions', type: ColumnType.Impressions },
        { heading: '', type: ColumnType.Actions }
    ],
    [ArtistPortalListPreset.DJType]: [
        { heading: '', type: ColumnType.Count },
        { heading: 'Source', type: ColumnType.Source },
        { heading: 'Percentage', type: ColumnType.Percentage },
        { heading: 'Previews', type: ColumnType.Previews },
        { heading: 'Downloads', type: ColumnType.Downloads },
        { heading: 'Est. Reach', type: ColumnType.EstReach },
        { heading: 'Impressions', type: ColumnType.Impressions },
        { heading: '', type: ColumnType.Actions }
    ]
};
