import { Fragment } from 'react';
import { UserPlaylist, UserPlaylistCollaborationAccessLevel } from '@bpm-web-app/download-api-sdk';
import { useUserPlaylists } from '../shared/three-dots-sheet/useUserPlaylists';
import ThreeDotsMenuItem from '../shared/three-dots-sheet/three-dots-menu-item';

export interface MyPlaylistListProps {
    categoryId: number | string;
    addToPlaylist: (id: string) => void;
    showIcon?: boolean;
}

export function MyPlaylistList({ categoryId, addToPlaylist, showIcon = false }: MyPlaylistListProps) {
    const { userPlaylists, isLoading } = useUserPlaylists(categoryId, true);

    if (!isLoading && userPlaylists?.data) {
        return (
            <>
                {userPlaylists.data.map((item) => (
                    <Fragment key={item.id}>
                        <ThreeDotsMenuItem
                            action={(e) => {
                                e.preventDefault();
                                addToPlaylist(item.id);
                            }}
                            legend={item.title}
                            icon={showIcon ? 'playlistItem' : undefined}
                            disabled={((item as UserPlaylist).access_level === UserPlaylistCollaborationAccessLevel.View)}
                            disabledDataTip="Unavailable"
                        />
                    </Fragment>
                ))}
            </>
        );
    }

    return null;
}

export default MyPlaylistList;
