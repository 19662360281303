import { Album as DownloadAlbum } from '@bpm-web-app/download-api-sdk';
import { useTrending } from '@bpm-web-app/swr-hooks';
import {
    DEFAULT_BROWSE_TRENDING_LIMIT,
    DEFAULT_PERIOD,
    useHubSwitch,
} from '@bpm-web-app/utils';
import { useContext, useEffect, useMemo } from 'react';
import { LibraryTabsContext } from '../../../../utils/src/lib/library-tabs.context';
import { CardCarousel, GhostComponent } from '../shared';
import { AlbumCard } from '../shared/card/album-card/album-card';

interface TrendingBrowseSupremeProps {
    isDataLoaded?: boolean;
    setIsDataLoaded?: (loaded: boolean) => void;
}

export function TrendingBrowseSupreme({ isDataLoaded, setIsDataLoaded }: TrendingBrowseSupremeProps) {
    const { isDownload } = useHubSwitch();

    const { libraryProperty } = useContext(LibraryTabsContext);

    const { data, isLoading } = useTrending(isDownload ? 'download' : 'stream', {
        period: DEFAULT_PERIOD,
        limit: DEFAULT_BROWSE_TRENDING_LIMIT,
        library: libraryProperty,
    });

    const trending = useMemo(
        () => (data?.data || []) as DownloadAlbum[],
        [data?.data]
    );

    useEffect(() => {
        if (setIsDataLoaded) {
            if (isLoading === false) {
                setIsDataLoaded(true);
            } else {
                setIsDataLoaded(false);
            }
        }
    }, [isLoading, setIsDataLoaded]);

    const shouldDisplayGhostLoading = useMemo(() => isDataLoaded === false || isLoading, [isDataLoaded, isLoading]);

    return (
        <div>
            {shouldDisplayGhostLoading ? (
                <GhostComponent type="cards" elementsCount={10} title="Top 10" contentType={isDownload ? 'download:album' : 'track'} />
            ) : trending && trending?.length > 0 ? (
                <CardCarousel title="Top 10" seeMorePath="/trending">
                    {trending.map((album) => (
                        <AlbumCard
                            key={album.id}
                            isDownload={isDownload}
                            album={album}
                            albumsList={trending}
                        />
                    ))}
                </CardCarousel>
            ) : null}
        </div>
    );
}

export default TrendingBrowseSupreme;
