import { useSearchPlaylistSets } from '@bpm-web-app/swr-hooks';
import { useRouter } from 'next/router';
import { appendQueryParams, convertToTitleCase, useSupremeFilterParams, useUniqueArray, useViewport } from '@bpm-web-app/utils';
import { Fragment, useCallback, useEffect, useMemo } from 'react';

import classNames from 'classnames';
import { NoResultsBlock } from '../../shared/ui/no-results-block/no-results-block';
import styles from './search-exclusive-playlists.module.css';
import CardCarousel from '../../shared/card-carousel/card-carousel';
import ListGrid from '../../shared/ui/list-grid/list-grid';
import ListGridItem from '../../shared/list-grid-item/list-grid-item';
import SecondaryPageTitle from '../../shared/secondary-page-title/secondary-page-title';
import CardGrid from '../../shared/card-grid/card-grid';
import GhostElement from '../../shared/ghost-element/ghost-element';
import { CardCarouselGhostLoading } from '../../shared/card-carousel/card-carousel-ghost-loading';
import { CardGhostLoading } from '../../shared/card/card-ghost-loading';
import { PlaylistCard } from '../../shared/card/playlist-card/playlist-card';

const PLAYLISTS_LINES_WIDTH = [90, 70];

export interface SearchExclusivePlaylistsProps {
    indexPage?: boolean;
    onCount: (count: number) => void;
    hasNoResults?: boolean;
    isLoadingData?: boolean;
    setIsLoadingData?: (isLoading: boolean) => void;
}

export function SearchExclusivePlaylists({ indexPage, onCount, hasNoResults, isLoadingData, setIsLoadingData }: SearchExclusivePlaylistsProps) {
    const router = useRouter();
    const query = useSupremeFilterParams(false);
    const { searchTerm } = router.query;

    const { data, isLoading } = useSearchPlaylistSets(query);
    const { isMobile } = useViewport();

    const playlists = data?.data;
    const playlistSize = playlists?.length as number;

    const seeMorePath = useMemo(
        // eslint-disable-next-line @typescript-eslint/dot-notation
        () => `/search?searchTerm=${router.query['searchTerm']}&type=exclusive-playlists`,
        [router.query]
    );
    const pageTitle = searchTerm && searchTerm !== undefined && String(searchTerm) ? `Playlists Related To ‘${convertToTitleCase(String(searchTerm))}’` : 'Playlists';

    const getIntroSection = useCallback(
        () => (
            <div className={styles['search-exclusive-playlists__intro-section']}>
                <div className={indexPage ? '' : 'spacing__window--top'} />
                <SecondaryPageTitle title={pageTitle} counter={`${playlistSize || 0}`} />
                <div className="spacing--top" />
                {playlistSize === 0 && <NoResultsBlock hasPadding>No Playlists Available</NoResultsBlock>}
            </div>
        ),
        [indexPage, pageTitle, playlistSize]
    );

    const ghostLoadingItems = useUniqueArray(10);

    useEffect(() => {
        onCount(playlistSize || 0);
    }, [onCount, playlistSize]);

    useEffect(() => {
        if (setIsLoadingData) setIsLoadingData(isLoading);
    }, [isLoading, setIsLoadingData]);

    if (hasNoResults && !isLoadingData) {
        return null;
    }

    return (
        <div className={classNames(styles['search-exclusive-playlists'])}>
            {(playlistSize === 0 || (indexPage && !isMobile && playlists?.length)) && getIntroSection()}
            {indexPage && isMobile && (
                <div className="spacing__window--horizontal">
                    <ListGrid>
                        {isLoading
                            ? ghostLoadingItems.map((uuid) => (
                                <GhostElement
                                    isRow
                                    key={`search-exclusive-playlists-${uuid}`}
                                    linesWidthArray={PLAYLISTS_LINES_WIDTH}
                                    squareWidth={56}
                                    itemClass={styles['search-exclusive-playlists__mobile-loading-item']}
                                />
                            ))
                            : playlists?.map((playlist) => (
                                <Fragment key={playlist.id}>
                                    <ListGridItem
                                        id={playlist.id}
                                        title={playlist.title}
                                        subtitle={playlist.artist}
                                        imageUrl={appendQueryParams(playlist.image_url, { key: 'dw', value: 160 })}
                                        imageUrl2x={appendQueryParams(playlist.image_url, { key: 'dw', value: 320 })}
                                        link={`/exclusive-playlist/${playlist.id}`}
                                        contentType="exclusive-playlist"
                                    />
                                </Fragment>
                            ))}
                    </ListGrid>
                </div>
            )}
            {indexPage && !isMobile && (
                <div className="spacing__window--horizontal">
                    <CardGrid cardType="card-small">
                        {isLoadingData || isLoading ? ghostLoadingItems.map((uuid) => <CardGhostLoading key={`card-ghost-${uuid}`} cardSize="small" linesWidthArray={PLAYLISTS_LINES_WIDTH} lineHeight={10} />) : null}
                        {!isLoadingData &&
                            playlists?.map((playlist) => (
                                <PlaylistCard
                                    key={playlist.id}
                                    playlist={playlist}
                                    playlists={playlists}
                                />
                            ))}
                    </CardGrid>
                </div>
            )}
            {!indexPage && (
                <>
                    {isLoadingData && <CardCarouselGhostLoading elementsCount={10} cardSize="small" title={pageTitle} linesWidthArray={PLAYLISTS_LINES_WIDTH} lineHeight={10} />}
                    {!isLoadingData && playlists?.length && (
                        <CardCarousel title={pageTitle} titleCounter={data?.data?.length.toString()} seeMorePath={seeMorePath}>
                            {data?.data &&
                                data.data.map((playlist) => (
                                    <PlaylistCard
                                        key={playlist.id}
                                        playlist={playlist}
                                        playlists={data.data}
                                    />
                                ))}
                        </CardCarousel>
                    )}
                </>
            )}
        </div>
    );
}

export default SearchExclusivePlaylists;
