import { useContext } from 'react';
import { SortByQueryOptions } from '@bpm-web-app/stream-api-sdk';
import { useHubSwitch, useUniqueArray } from '@bpm-web-app/utils';
import { useFeaturedBpmOriginalMedia } from '@bpm-web-app/swr-hooks';

import CardGrid from '../../shared/card-grid/card-grid';
import { LibraryTabsContext } from '../../../../../utils/src/lib/library-tabs.context';
import styles from './featured-bpm-exclusives-all.module.css';
import SeeMoreMobile from '../../see-more-mobile/see-more-mobile';
import { CardGhostLoading } from '../../shared/card/card-ghost-loading';
import SeeMoreSupremeLayout from '../../see-more-layout/see-more-supreme-layout';
import { AlbumCard } from '../../shared/card/album-card/album-card';

export function FeaturedBpmExclusivesAll() {
    const { libraryProperty } = useContext(LibraryTabsContext);
    const { isDownload } = useHubSwitch();

    const { data, isLoading } = useFeaturedBpmOriginalMedia({
        sort_by: SortByQueryOptions.DateDesc,
        library: libraryProperty,
    });

    const searchFeaturedBpmOriginal = data?.data;

    const ghostLoadingItems = useUniqueArray(20);

    return (
        <div className={styles['featured-bpm-exclusives-all']}>
            <SeeMoreSupremeLayout
                pageTitle="Exclusives"
                mobileChildren={<SeeMoreMobile contentType={isDownload ? 'download:album' : 'track'} isDownload={isDownload} data={searchFeaturedBpmOriginal || []} isLoading={isLoading} />}
                desktopChildren={
                    <CardGrid cardType="card-small">
                        {isLoading && ghostLoadingItems.map((uuid) => <CardGhostLoading key={`card-ghost-${uuid}`} cardSize="small" />)}
                        {!isLoading &&
                            searchFeaturedBpmOriginal?.map((media) => (
                                <AlbumCard
                                    key={media.id}
                                    album={media}
                                    albumsList={searchFeaturedBpmOriginal}
                                    isDownload={isDownload} />

                            ))}
                    </CardGrid>
                }
            />
        </div>
    );
}

export default FeaturedBpmExclusivesAll;
