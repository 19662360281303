const CACHE_NAME = 'audioCache';
export class PlayerCache {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    static ENABLED = true;// process.env['NEXT_PUBLIC_RELEASE_STAGE'] !== 'local';

    private static cache: Cache | undefined;

    private static cachePromise: Promise<Cache> | undefined;

    private static bufferCache: { [key: string]: string } = {};

    private static listToBuffer: { [key: string]: string[] } = {};

    static async loadUrls(listId: string, urls: string[]) {
        if (!PlayerCache.ENABLED || !window || !window.caches) {
            return;
        }
        if (!PlayerCache.cachePromise) {
            PlayerCache.cachePromise = window.caches.open(CACHE_NAME).then((cache) => {
                PlayerCache.cache = cache;
                return cache;
            });
        }
        await PlayerCache.cachePromise;

        if (!PlayerCache.listToBuffer[listId]) {
            PlayerCache.listToBuffer[listId] = [];
        }
        PlayerCache.listToBuffer[listId].push(...urls);
        await PlayerCache.cache?.addAll(urls).then(() => {
            urls.forEach((url) => {
                if (PlayerCache.bufferCache[url]) {
                    return;
                }
                // eslint-disable-next-line promise/no-nesting
                PlayerCache.getObjectUrlFor(url)?.then((localUrl) => {
                    if (localUrl) { PlayerCache.bufferCache[url] = localUrl; }
                    return null;
                });
            });
            return null;
        }).catch((err) => {
            // eslint-disable-next-line no-console
            console.error(err);
        });
    }

    static redirectMediaUrl(url?: string) {
        if (url && 'serviceWorkerAvailable' in window) {
            // eslint-disable-next-line no-restricted-globals
            return `${location.protocol}//${location.host}/media-stream/${encodeURIComponent(url)}`;
        }
        return url;
    }

    static resetPreloadUrls(listId: string) {
        const urls = PlayerCache.listToBuffer[listId] || [];
        urls.forEach((url) => {
            if (PlayerCache.bufferCache[url]) {
                URL.revokeObjectURL(PlayerCache.bufferCache[url]);
                delete PlayerCache.bufferCache[url];
            }
        });
        delete PlayerCache.listToBuffer[listId];
    }

    static async getObjectUrlFor(url?: string, fallback: string | undefined = url) {
        if (url && PlayerCache.bufferCache[url]) {
            return PlayerCache.bufferCache[url];
        }
        if (url && PlayerCache.cache) {
            return PlayerCache.cache.match(url).then(async (res) => {
                if (!res) {
                    return fallback;
                }
                const result = await res.arrayBuffer();
                const mimeType = 'audio/mpeg';
                const toReturn = URL.createObjectURL(new Blob([result], { type: mimeType }));
                return toReturn;
            }).catch(() => {
                return fallback;
            });
        }
        return fallback;
    }
}
