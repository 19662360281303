import { useState } from 'react';
import classNames from 'classnames';
import { Collapse } from 'react-collapse';
import Switch from '../shared/ui/switch/switch';
import styles from './showing-filter.module.css';
import ChevronUp from '../../assets/icons/chevron-up.svg';

export interface ShowingFilterProps {
    hidePrevPlayed: boolean;
    onHidePrevPlayed: (value: boolean) => void;
    hidePrevDownloaded: boolean;
    onHidePrevDownloaded: (value: boolean) => void;
}

export function ShowingFilterCreate({ hidePrevPlayed, onHidePrevPlayed, onHidePrevDownloaded, hidePrevDownloaded }: ShowingFilterProps) {
    const [isSectionExpanded, setIsSectionExpanded] = useState(true);
    const filterActiveCount = [hidePrevPlayed].filter((i) => i).length;

    return (
        /* eslint-disable-next-line @typescript-eslint/dot-notation */
        <div className={classNames('filter', styles['showing-filter'])}>
            <div
                role="button"
                onClick={() => setIsSectionExpanded(!isSectionExpanded)}
                tabIndex={0}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        setIsSectionExpanded(!isSectionExpanded);
                    }
                }}
                className="filter__header">
                <div className={classNames('filter__title', { 'filter__title-active': filterActiveCount > 0 })}>Showing {filterActiveCount > 0 ? `(${filterActiveCount})` : ''}</div>
                {filterActiveCount > 0 && (
                    <button
                        type="button"
                        className={styles['filter__reset']}
                        onClick={() => {
                            onHidePrevPlayed(false);
                        }}>
                        Remove
                    </button>
                )}
                <button aria-label="Expand/Collapse Versions" type="button" onClick={() => setIsSectionExpanded(!isSectionExpanded)}>
                    <ChevronUp className={classNames('filter__chevron', { 'filter__chevron--expanded': isSectionExpanded })} />
                </button>
            </div>
            <Collapse isOpened={isSectionExpanded}>
                <div className={styles['showing-filter__switch']}>
                    <Switch onChange={onHidePrevPlayed} enabled={hidePrevPlayed} label="Hide previously played" />
                </div>
                <div className={styles['showing-filter__switch']}>
                    <Switch onChange={onHidePrevDownloaded} enabled={hidePrevDownloaded} label="Hide previously downloaded" />
                </div>
            </Collapse>
        </div>
    );
}

export default ShowingFilterCreate;
