import { PlatformName } from './types';

const linkDict = {
    links: {
        local: {
            create: 'https://local.bpmsupreme.com:4201/',
            supreme: 'https://local.bpmsupreme.com:4101/d',
            artist: 'https://local.bpmsupreme.com:4301'
        },
        dev: {
            create: 'https://develop.bpmcreate.com/',
            supreme: 'https://develop.bpmsupreme.com/',
            artist: 'https://develop.artist.bpmmusic.io/'
        },
        qa: {
            create: 'https://qa.bpmcreate.com/',
            supreme: 'https://qa.bpmsupreme.com/',
            artist: 'https://qa.artist.bpmmusic.io/'
        },
        strv: {
            create: 'https://strv.bpmcreate.com/',
            supreme: 'https://strv.bpmsupreme.com/',
            artist: 'https://strv.artist.bpmmusic.io/'
        },
        prod: {
            create: 'https://app.bpmcreate.com/',
            supreme: 'https://app.bpmsupreme.com/',
            artist: 'https://artist.bpmmusic.io/'
        }
    }
};
export function getPlatformEnvLink(platform: PlatformName) {
    // eslint-disable-next-line no-restricted-globals
    const envData = location.host.split('.');
    const env = envData[0];

    switch (env) {
        case 'qa':
            return platform === 'BPM Create' ? linkDict.links.qa.create
                    : platform === 'BPM Supreme' ? linkDict.links.qa.supreme
                        : linkDict.links.qa.artist;
        case 'local':
            return platform === 'BPM Create' ? linkDict.links.local.create
                    : platform === 'BPM Supreme' ? linkDict.links.local.supreme
                        : linkDict.links.local.artist;
        case 'dev':
            return platform === 'BPM Create' ? linkDict.links.dev.create
                    : platform === 'BPM Supreme' ? linkDict.links.dev.supreme
                        : linkDict.links.dev.artist;
        case 'strv':
            return platform === 'BPM Create' ? linkDict.links.strv.create
                    : platform === 'BPM Supreme' ? linkDict.links.strv.supreme
                        : linkDict.links.strv.artist;
        default:
            return platform === 'BPM Create' ? linkDict.links.prod.create
                    : platform === 'BPM Supreme' ? linkDict.links.prod.supreme
                        : linkDict.links.prod.artist;
    }
}
