import { useUniqueArray } from '@bpm-web-app/utils';
import classNames from 'classnames';
import GhostElement from '../../ghost-element/ghost-element';
import SecondaryPageTitle from '../../secondary-page-title/secondary-page-title';
import styles from './track-list-ghost-loading.module.css';
import { BreakpointView } from '../../ui';

const SONGS_LINES_WIDTH = [100, 100];
const MOBILE_SONGS_LINES_WIDTH = [90, 60, 30];

interface TrackListGhostLoadingProps {
    title?: string;
    titleGhostLoading?: boolean;
    amount: number;
    isMobile?: boolean;
    isCreate?: boolean;
    onlyListItem?: boolean;
    hasShimmer?: boolean;
}

export function TrackListGhostLoading({ title, amount, titleGhostLoading, isMobile, isCreate, onlyListItem, hasShimmer = true }: TrackListGhostLoadingProps) {
    const ghostLoadingItems = useUniqueArray(amount);

    const trackListGhostItem = (key: string) => (
        <BreakpointView
            key={key}
            desktopChildren={
                <div key={key} className={classNames(styles['track-list-ghost-loading__loading__item-container'], isCreate ? styles['track-list-ghost-loading__loading__item-container--create'] : '')}>
                    <div className={styles['track-list-ghost-loading__loading__item-container--accordion']} />

                    <div className={styles['track-list-ghost-loading__loading__item-container--left']}>
                        <GhostElement hasShimmer={hasShimmer} isRow key={`track-list-ghost-loading-${key}`} itemClass={styles['track-list-ghost-loading__loading-item']} linesWidthArray={isMobile ? MOBILE_SONGS_LINES_WIDTH : SONGS_LINES_WIDTH} squareWidth={isMobile ? 56 : 40} lineHeight={isMobile ? 14 : 18} />
                    </div>

                    <div className={classNames(styles['track-list-ghost-loading__loading__item-container--info'], hasShimmer ? 'shimmer' : '')} />
                    <div className={classNames(styles['track-list-ghost-loading__loading__item-container--versions'], hasShimmer ? 'shimmer' : '')} />
                    <div className={styles['track-list-ghost-loading__loading__item-container--actions']} />
                </div>
            }
            mobileChildren={
                <div key={key} className={styles['track-list-ghost-loading__loading__item-container--left']}>
                    <GhostElement hasShimmer={hasShimmer} isRow key={`track-list-ghost-loading-${title}`} itemClass={styles['track-list-ghost-loading__loading-item']} linesWidthArray={isMobile ? MOBILE_SONGS_LINES_WIDTH : SONGS_LINES_WIDTH} squareWidth={isMobile ? 56 : 40} lineHeight={isMobile ? 14 : 18} />
                </div>
            } />
    );

    if (onlyListItem) {
        return trackListGhostItem(title || '');
    }

    return (
        <div className={styles['track-list-ghost-loading__container']}>
            {titleGhostLoading ? <GhostElement hasShimmer={hasShimmer} key="track-list-ghost-loading-title" itemClass={styles['track-list-ghost-loading__loading-title']} /> : null}
            {!titleGhostLoading && title && <SecondaryPageTitle title={title} />}
            <div className={styles['track-list-ghost-loading__loading']}>
                {ghostLoadingItems.map((uuid) => (
                    trackListGhostItem(uuid)
                ))}
            </div>
        </div>
    );
}
