import { CreatePlayerItem, HLSPlayer, State, usePlayerState, showToast } from '@bpm-web-app/utils';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

export type CreateSortBy = 'date_asc' | 'date_desc' | 'title_asc' | 'title_desc' | 'bpm_asc' | 'bpm_desc' | 'relevance';

interface CreatePlayerContextData {
    /** Closes the player */
    closePlayer: () => void;
    /** Current playing track */
    currentTrack: CreatePlayerItem;
    elapsed: number;
    isOpen: boolean;
    currentSoundId: string | undefined;
    currentSelectedSoundId: string | undefined;
    currentSelectedSoundType: 'sound' | 'preset' | undefined;
    trackListSortBy: CreateSortBy | undefined;
    setTrackListSortBy: (sortBy: CreateSortBy) => void;
    setCurrentSoundId: (id?: string) => void;
    setCurrentSelectedSoundId: (id?: string, type?: 'sound' | 'preset') => void;
    onPause: () => void;
    onPlay: () => void;
    onSeek: (progress: number) => void;
    onTrackEnd: () => void;
    playDemo: (item: CreatePlayerItem) => void;
    setElapsed: (elapsed: number) => void;
    setVolume: (volume: number) => void;
    togglePlayPause: () => void;
    volume: number;
}

const CreatePlayerContext = createContext<CreatePlayerContextData>({} as CreatePlayerContextData);

interface CreatePlayerProviderProps {
    children: ReactNode;
}

export function CreatePlayerProvider({ children }: CreatePlayerProviderProps) {
    const [isOpen, setIsOpen] = useState(false);
    const [volume, setVolume] = useState(100);
    const [elapsed, setElapsed] = useState(0);
    const [currentSoundId, setCurrentSoundId] = useState(undefined);
    const [currentSelectedSoundId, setCurrentSelectedSoundId] = useState<string | undefined>(undefined);
    const [currentSelectedSoundType, setCurrentSelectedSoundType] = useState<'sound' | 'preset' | undefined>(undefined);
    const [trackListSortBy, setTrackListSortBy] = useState(undefined);
    const [currentTrack, setCurrentTrack] = useState<CreatePlayerContextData['currentTrack'] | null>(null);

    useEffect(() => {
        HLSPlayer.setErrorHandler(() => {
            showToast({ type: 'error', message: 'Unavailable. Please try again later.' });
        });
    }, []);

    const playerState = usePlayerState();

    const handlePlay = useCallback(() => {
        HLSPlayer.play();
    }, []);

    const handleTogglePlayPause = useCallback(() => {
        if (playerState === State.Playing) {
            HLSPlayer.pause();
        } else if (playerState === State.Paused) {
            handlePlay();
        }
    }, [handlePlay, playerState]);

    const handleSeek = useCallback(async (progress: number) => {
        try {
            HLSPlayer.goTo(progress);
            await HLSPlayer.play();
            setElapsed(progress);
        } catch {
            // Placeholder
        }
    }, []);

    const handleTrackEnded = useCallback(() => {
        // NOTE: Nothing to implement Implement some kind of fn on track end
    }, []);

    const handlePlayDemo = useCallback(
        async (item: CreatePlayerItem) => {
            try {
                if (playerState === State.Playing) {
                    HLSPlayer.stop();
                }
                await HLSPlayer.load(item.demo_file_url.hls);

                if (item.skipSilence > 0) {
                    HLSPlayer.goTo(item.skipSilence);
                }

                await HLSPlayer.play();
                setCurrentSoundId(undefined);
                setCurrentTrack(item);
                setIsOpen(true);
            } catch (error) {
                console.error(error);
            }
        },
        [playerState]
    );

    const handleClosePlayer = useCallback(() => {
        setIsOpen(false);
        setCurrentTrack(null);
    }, []);

    const value = useMemo(
        (): CreatePlayerContextData => ({
            isOpen,
            onTrackEnd: handleTrackEnded,
            volume,
            elapsed,
            currentTrack,
            currentSoundId,
            trackListSortBy,
            setCurrentSelectedSoundId: (id, type) => {
                if (id) {
                    setCurrentSelectedSoundId(id);
                    setCurrentSelectedSoundType(type);
                } else {
                    setCurrentSelectedSoundId(undefined);
                    setCurrentSelectedSoundType(undefined);
                }
            },
            currentSelectedSoundId,
            currentSelectedSoundType,
            setCurrentSoundId: (id?: string) => setCurrentSoundId(id),
            setTrackListSortBy: (sortBy: CreateSortBy) => setTrackListSortBy(sortBy),
            closePlayer: handleClosePlayer,
            setVolume: (newVolume: number) => setVolume(newVolume),
            setElapsed: (time: number) => setElapsed(time),
            playDemo: handlePlayDemo,
            onSeek: handleSeek,
            onPlay: handlePlay,
            onPause: HLSPlayer.pause,
            togglePlayPause: handleTogglePlayPause
        }),
        [
            currentTrack,
            trackListSortBy,
            setTrackListSortBy,
            elapsed,
            handlePlay,
            handlePlayDemo,
            handleSeek,
            handleTogglePlayPause,
            handleTrackEnded,
            handleClosePlayer,
            setCurrentSelectedSoundId,
            setCurrentSelectedSoundType,
            setCurrentSoundId,
            currentSelectedSoundId,
            currentSelectedSoundType,
            currentSoundId,
            isOpen,
            volume
        ]
    );

    useEffect(() => {
        document.body.classList[isOpen ? 'add' : 'remove']('player-is-open');
        return () => {
            document.body.classList.remove('player-is-open');
        };
    }, [isOpen]);

    return <CreatePlayerContext.Provider value={value}>{children}</CreatePlayerContext.Provider>;
}

export const useCreatePlayer = () => {
    const context: CreatePlayerContextData = useContext(CreatePlayerContext);

    if (!Object.keys(context).length) {
        throw new Error('usePlayer must be used inside PlayerContext');
    }

    return context;
};
