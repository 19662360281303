import { useContext, useMemo, useState, useCallback, useRef } from 'react';
import { useTags } from '@bpm-web-app/swr-hooks';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { SortByQueryOptions } from '@bpm-web-app/create-api-sdk';
import { createAppRoutes, LOOP_TAG_VALUE, ONE_SHOT_TAG_VALUE } from '@bpm-web-app/utils';
import { Collapse } from 'react-collapse';
import Checkboxes, { CheckboxesProps } from '../shared/checkboxes/checkboxes';
import styles from './tags-filter.module.css';
import { SearchInput } from '../shared/search-input/search-input';
import ChevronUp from '../../assets/icons/chevron-up.svg';
import { FiltersContext } from '../filters/filters.context';

export type AudioFileType = 'midi' | 'fxp' | 'wav' | 'mid' | 'mp3';

export interface SoundQueryAttributes {
    bpm_max?: string | number;
    bpm_min?: string | number;
    curated_id?: string;
    key?: string;
    only_free?: true;
    search?: string;
    sort_by?: SortByQueryOptions;
    suggested?: true;
    tag_groups?: string[];
    tags?: string[];
    type?: AudioFileType;
}

export interface SoundPackageQueryAttributes extends SoundQueryAttributes {
    artist?: string[];
    genre?: string;
    label?: string[] | string;
    subgenre?: string[];
    sound_package_id?: string;
    trending?: boolean;
}
/** This will return the path eg: packs/[slug] that will match the router.pathname */
const appendSlugToRoute = (path: string) => `${path}[slug]`;

export function TagsFilter() {
    const { tags, setTags, tagGroups, setTagGroups } = useContext(FiltersContext);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [isTagsExpanded, setIsTagsExpanded] = useState(false);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const router = useRouter();

    const { slug, searchTerm } = router.query;

    const tagsQuery = useMemo(() => {
        // use search=<searchTerm>
        const isSearchPage = router.pathname === createAppRoutes.search;
        // use curated_id
        const isCuratedPackPage = router.pathname === appendSlugToRoute(createAppRoutes.curatedSet(''));
        // use sound_package_id
        const isPackDetailPage = router.pathname === appendSlugToRoute(createAppRoutes.packsSlug(''));
        // use type=midi
        const isMidiPage = router.pathname === createAppRoutes.midi;
        // use type=synth
        const isPresetPage = router.pathname === createAppRoutes.presets;
        // use only_free=true
        const isFreeSoundsPage = router.pathname === createAppRoutes.freeSounds;
        // use trending=true
        const isTrendingPage = router.pathname === createAppRoutes.trending;
        // use label=<label_id>
        const isLabelDetailPage = router.pathname === appendSlugToRoute(createAppRoutes.labelDetail(''));

        const queryParams = {} as Required<Parameters<typeof useTags>>[0];
        if (isPackDetailPage) queryParams.sound_package_id = slug as string;
        if (isCuratedPackPage) queryParams.curated_id = slug as string;
        if (isSearchPage) queryParams.search = searchTerm as string;
        if (isFreeSoundsPage) queryParams.only_free = true;
        if (isMidiPage) queryParams.type = 'midi';
        if (isPresetPage) queryParams.type = 'preset';
        if (isTrendingPage) queryParams.trending = true;
        if (isLabelDetailPage) queryParams.label = slug as string;

        return queryParams;
    }, [router.pathname, searchTerm, slug]);

    const { tags: tagsData } = useTags({ ...tagsQuery });

    const isSearching = useMemo(() => searchQuery.trim() !== '', [searchQuery]);

    const applyFilter = useCallback((name: string) => {
        return name !== ONE_SHOT_TAG_VALUE && name !== LOOP_TAG_VALUE && (!isSearching || name.toLowerCase().includes(searchQuery.toLowerCase()));
    }, [searchQuery, isSearching]);

    const filteredTagsFlattened = useMemo(() => {
        return tagsData?.filter((tag) => applyFilter(tag.name) && tag.name !== ONE_SHOT_TAG_VALUE && tag.name !== LOOP_TAG_VALUE).map((tag) => ({ label: tag.name, value: tag.name })) || [];
    }, [applyFilter, tagsData]);

    const filteredTagGroupsFlattened = useMemo(() => {
        const unqiueGroups = tagsData?.reduce((groups, tag) => {
            if (tag.TagGroup) {
                if (!groups.includes(tag.TagGroup.name)) {
                    groups.push(tag.TagGroup.name);
                }
            }
            return groups;
        }, [] as string[]);
        return unqiueGroups?.filter(applyFilter).map((tag) => ({ label: tag, value: tag })) || [];
    }, [applyFilter, tagsData]);

    const selectedTags = useMemo<CheckboxesProps['options']>(() => tags.filter((tag) => tag !== LOOP_TAG_VALUE && tag !== ONE_SHOT_TAG_VALUE).map((tag) => ({ label: tag, value: tag })), [tags]);
    const filterActiveCount = selectedTags.length + tagGroups.length;
    const hasMore = (filteredTagGroupsFlattened.length + filteredTagsFlattened.length) > 10;

    const handleSectionToggle = useCallback(() => {
        if (!isTagsExpanded) {
            setIsExpanded(false);
        }
        setIsTagsExpanded(!isTagsExpanded);
    }, [isTagsExpanded]);

    return (
        <div className={classNames('filter', styles['tags-filter'], { [styles['tags-filter__expanded']]: isTagsExpanded })}>
            <div
                role="button"
                onClick={handleSectionToggle}
                tabIndex={0}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleSectionToggle();
                    }
                }}
                className="filter__header">
                <div className={classNames('filter__title', { 'filter__title-active': filterActiveCount > 0 })}>Tags {filterActiveCount > 0 ? `(${filterActiveCount})` : ''}</div>
                {filterActiveCount > 0 && (
                    <button
                        type="button"
                        className="filter__reset"
                        onClick={() => {
                            setSearchQuery('');
                            setTags(tags.filter((tag) => tag === ONE_SHOT_TAG_VALUE || tag === LOOP_TAG_VALUE));
                            setTagGroups([]);
                        }}
                    >
                        Remove
                    </button>
                )}
                <button
                    aria-label="Expand/Collapse Instruments"
                    type="button"
                    onClick={handleSectionToggle}>
                    <ChevronUp className={classNames('filter__chevron', { 'filter__chevron--expanded': isTagsExpanded })} />
                </button>
            </div>
            <Collapse isOpened={isTagsExpanded}>
                <div className={styles['tags-filter__search']}>
                    <SearchInput
                        placeholder="Search Tags"
                        value={searchQuery}
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                        }}
                        onClear={() => setSearchQuery('')}
                    />
                </div>
                <div className={styles['tags-filter__search-results']}>
                    <Checkboxes options={filteredTagGroupsFlattened.slice(0, isExpanded ? undefined : 10)} value={tagGroups} onChange={setTagGroups} />
                    <Checkboxes options={filteredTagsFlattened.slice(0, isExpanded ? undefined : Math.max((10 - filteredTagGroupsFlattened.length), 0))} value={tags} onChange={setTags} />
                </div>
                {hasMore && (
                    <button type="button" onClick={() => setIsExpanded(!isExpanded)} className="filter__view-more">
                        {isExpanded ? 'View Less' : 'View More'}
                    </button>
                )}
                {tagGroups.length ? (
                    <div className={styles['tags-filter__checkboxes']}>
                        <div className={classNames('filter__title', styles['tags-filter__tags-title'])}>Selected Tag Groups</div>
                        <Checkboxes options={tagGroups.map((tag) => ({ label: tag, value: tag }))} value={tagGroups} onChange={setTagGroups} />
                    </div>
                ) : null}
                {selectedTags.length ? (
                    <div className={styles['tags-filter__checkboxes']}>
                        <div className={classNames('filter__title', styles['tags-filter__tags-title'])}>Selected Tags</div>
                        <Checkboxes options={selectedTags} value={tags} onChange={setTags} />
                    </div>
                ) : null}
            </Collapse>
        </div>
    );
}

export default TagsFilter;
