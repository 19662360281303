import { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useUserPlaylists } from '../shared/three-dots-sheet/useUserPlaylists';
import styles from './maxi-player-playlist.module.css';
import MyPlaylistsCategories from '../my-playlist-categories/my-playlist-categories';
import MyPlaylistList from '../my-playlist-list/my-playlist-list';
import { useUserPlaylistCategories } from '../shared/three-dots-sheet/useUserPlaylistCategories';
import PlaylistsForm, { PlaylistsFormProps } from '../playlists-form/playlists-form';
import Add from '../../assets/icons/add.svg';
import ChevronLeft from '../../assets/icons/chevron-left.svg';

export interface MaxiPlayerPlaylistProps {
    addToPlaylist: (id: string) => void;
    closePlaylistOverlay: () => void;
}
// ToDo(ae): need to finally implement when launching stream
export function MaxiPlayerPlaylist({ addToPlaylist, closePlaylistOverlay }: MaxiPlayerPlaylistProps) {
    const [categoryId, setCategoryId] = useState<number | string | null>(null);
    const [openForm, setOpenForm] = useState<null | PlaylistsFormProps>(null);

    const { createFolder } = useUserPlaylistCategories();

    const { createPlaylist } = useUserPlaylists(categoryId || 0);

    const addIcon = useMemo(() => <Add />, []);
    const backIcon = useMemo(() => <ChevronLeft />, []);

    const addFolder = useCallback(() => {
        setOpenForm({
            type: 'CreateNewFolder',
            text: 'Create folders to organize your playlists by genre, event, or more.',
            formAction: createFolder,
            close: () => setOpenForm(null),
        });
    }, [createFolder]);

    const addPlaylist = useCallback(() => {
        setOpenForm({
            type: 'CreateNewPlaylist',
            text: 'Create custom playlists to organize your music.',
            formAction: createPlaylist,
            close: () => setOpenForm(null),
        });
    }, [createPlaylist]);

    return (
        <>
            {openForm ? <PlaylistsForm {...openForm} /> : null}
            {categoryId === null ? (
                <ul>
                    <li className={classNames(styles['maxi-player-playlist__item'], styles['maxi-player-playlist__item__title'])}>
                        <button
                            className={styles['maxi-player-playlist__item-button']}
                            type="button"
                            aria-label="Back"
                            onClick={(e) => {
                                e.preventDefault();
                                closePlaylistOverlay();
                            }}
                        >
                            {backIcon}
                            <span>Back</span>
                        </button>
                        <button
                            type="button"
                            className={styles['maxi-player-playlist__item-button']}
                            onClick={(e) => {
                                e.preventDefault();
                                addFolder();
                            }}
                        >
                            {addIcon}
                            <span>Create folder</span>
                        </button>
                    </li>
                    <MyPlaylistsCategories selectCategory={(id) => setCategoryId(id)} showIcon />
                </ul>
            ) : (
                <ul>
                    <li className={classNames(styles['maxi-player-playlist__item'], styles['maxi-player-playlist__item__title'])}>
                        <button
                            className={styles['maxi-player-playlist__item-button']}
                            type="button"
                            aria-label="Back"
                            onClick={(e) => {
                                e.preventDefault();
                                setCategoryId(null);
                            }}
                        >
                            {backIcon}
                            <span>Back</span>
                        </button>
                    </li>
                    <li className={classNames(styles['maxi-player-playlist__item'], styles['maxi-player-playlist__item__title'])}>
                        <button
                            type="button"
                            className={styles['maxi-player-playlist__item-button']}
                            onClick={(e) => {
                                e.preventDefault();
                                addPlaylist();
                            }}
                        >
                            {addIcon}
                            <span>Create Playlist</span>
                        </button>
                    </li>
                    <MyPlaylistList categoryId={categoryId} addToPlaylist={addToPlaylist} showIcon />
                </ul>
            )}
        </>
    );
}

export default MaxiPlayerPlaylist;
