import { useCallback, useState } from 'react';

interface ElementPosition {
    left: number | undefined;
    top: number | undefined;
}

export const usePosition = () => {
    const [position, setPosition] = useState<ElementPosition>({
        left: undefined,
        top: undefined,
    });

    const ref = useCallback((node) => {
        if (node !== null) {
            const { top, left } = node.getBoundingClientRect();
            setPosition({
                top,
                left,
            });
        }
    }, []);

    return { ...position, ref };
};
