/* eslint-disable jsx-a11y/anchor-is-valid */
import { Album as DownloadAlbum, InlineResponse2002 as DownloadResponse } from '@bpm-web-app/download-api-sdk';
import { Album as StreamAlbum, InlineResponse2002 as StreamResponse } from '@bpm-web-app/stream-api-sdk';
import { useAlbumById, useDownloadAlbumById } from '@bpm-web-app/swr-hooks';
import {
    QueueItem,
    State,
    appendQueryParams,
    convertToPluralIfNeeded,
    downloadAlbumWithMediaToQueueItem,
    formatDateToString,
    rebuildReactTooltip,
    streamMediaWithoutAlbumToQueueItem,
    useHideSwitch,
    useHubSwitch,
    usePageDetails,
    usePlayerState,
    usePosition,
} from '@bpm-web-app/utils';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useHideLibraryTabs } from '../../../../../utils/src/lib/library-tabs.context';
import PauseIcon from '../../../assets/icons/player-pause-lg.svg';
import PlayArrow from '../../../assets/icons/player-play-alt.svg';
import { useArtistLinks } from '../../artist-link/artist-link';
import { usePlayer } from '../../player-context';
import { AppLink, GhostComponent } from '../../shared';
import { AddToFavoriteButton } from '../../shared/add-to-favorite-button/add-to-favorite-button';
import { AddToPlaylistButton } from '../../shared/add-to-playlist-button/add-to-playlist-button';
import { ThreeDotsButton } from '../../shared/three-dots-button/three-dots-button';
import { ThreeDotsSheetContext } from '../../shared/three-dots-sheet/three-dots-sheet.context';
import useFavoriteAlbums from '../../shared/three-dots-sheet/useFavoriteAlbums';
import { TrackListLoadingSupreme } from '../../shared/track-list/ghost-loading/track-list-loading-supreme';
import { TrackListSupreme } from '../../shared/track-list/supreme/track-list-supreme';
import { TrackListPresetSupreme } from '../../shared/track-list/supreme/track-list-supreme-helpers';
import { MediaDetailBanner } from '../../shared/ui/media-detail-banner/media-detail-banner';
import { LocalSortingKeys, sortMediaLocally, useLocalSort } from '../../sort-options-sheet/sort-options-sheet';
import Title from '../../title/title';
import { PageNotFound } from '../page-not-found/page-not-found';
import styles from './album-detail.module.css';

export function AlbumDetail() {
    const router = useRouter();
    const { openThreeDotsModalSheet } = useContext(ThreeDotsSheetContext);
    const { left, top, ref } = usePosition();
    const { albumId } = router.query;
    const [sortType, setSortType] = useLocalSort();
    const { originalListDetails, setQueue, togglePlayPause } = usePlayer();
    const playerState = usePlayerState();
    const { resource } = usePageDetails();
    const { isDownload, hub } = useHubSwitch();

    const isCurrentListInPlayer = useMemo(() => originalListDetails?.identifier === albumId && originalListDetails?.resource === resource, [originalListDetails, albumId, resource]);

    useEffect(() => {
        rebuildReactTooltip();
    }, []);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { data, isLoading, error, mutate } = isDownload ? useDownloadAlbumById(+albumId) : useAlbumById(+albumId);
    const album = data?.data;

    useHideSwitch();
    useHideLibraryTabs();
    const generateArtistLinks = useArtistLinks();

    const openAddToPlaylist = useCallback(
        async (element: EventTarget) => {
            const { top: topPosition, left: leftPosition } = (element as HTMLButtonElement).getBoundingClientRect();
            openThreeDotsModalSheet((!isDownload) ? 'stream:album' : 'download:album', +albumId, leftPosition, topPosition, true);
            rebuildReactTooltip();
        },
        [openThreeDotsModalSheet, isDownload, albumId]
    );

    const { addAlbumToFavorites, removeAlbumFromFavorites, isAlbumFavorite } = useFavoriteAlbums(+albumId);

    const handleFavorite = useCallback(() => {
        if (isAlbumFavorite) {
            removeAlbumFromFavorites();
            rebuildReactTooltip();
        } else {
            addAlbumToFavorites();
            rebuildReactTooltip();
        }
    }, [addAlbumToFavorites, isAlbumFavorite, removeAlbumFromFavorites]);

    const handlePlay = useCallback(() => {
        if (isCurrentListInPlayer) {
            togglePlayPause();
            return;
        }

        const queueItems = isDownload
            ? [downloadAlbumWithMediaToQueueItem(album as DownloadAlbum)]
            : (album as StreamAlbum).media.map((media) =>
                streamMediaWithoutAlbumToQueueItem(media, {
                    cover_url: album.cover_url,
                    genre: album.genre,
                    isExclusive: album.is_exclusive,
                })
            );

        setQueue(queueItems as QueueItem[], 0, { identifier: albumId as string, resource });
    }, [isCurrentListInPlayer, isDownload, album, setQueue, albumId, resource, togglePlayPause]);

    const sortedAlbum = useMemo<typeof album>(() => {
        if (album?.media?.length) {
            const media = sortMediaLocally(album.media, sortType) as typeof album['media'];
            return { ...album, media } as DownloadAlbum | StreamAlbum;
        }

        return album;
    }, [album, sortType]);

    const ghostLoading = () => {
        return (
            <>
                <Title platform={hub} title="Loading Artist..." />
                <GhostComponent type="album-detail" />
                <TrackListLoadingSupreme amount={10} preset={TrackListPresetSupreme.Album} />
            </>
        );
    };

    const renderTitle = useCallback(() => {
        if (album && (album as DownloadAlbum).artist_remixer) {
            const startIndex = album.title.indexOf((album as DownloadAlbum).artist_remixer!.name);
            if (startIndex > -1) {
                const endIndex = startIndex + (album as DownloadAlbum).artist_remixer!.name.length;

                // Extract parts of the title
                const beforeLink = album.title.substring(0, startIndex);
                const linkText = album.title.substring(startIndex, endIndex);
                const afterLink = album.title.substring(endIndex);
                const artistUrl = `/artist/${(album as DownloadAlbum).artist_remixer!.slug}`;

                return (
                    <h2>
                        <span>{beforeLink}</span>
                        <AppLink href={artistUrl}>
                            <a className={classNames('underline-link')} onClick={(e) => e.stopPropagation()} onKeyDown={() => { }} role="link" tabIndex={-5}>{linkText}</a>
                        </AppLink>
                        <span>{afterLink}</span>
                    </h2>
                );
            }
        }

        return (
            <h2>{album?.title}</h2>
        );
    }, [album]);

    if (Number.isNaN(+albumId)) return <PageNotFound />;

    /* TODO: implement (ghost?) loading */
    if (isLoading) return ghostLoading();

    /* TODO: potentially replace this with a custom 404 page if/when built */
    if (!isLoading && error) return null;

    if (album === null) {
        return null;
    }

    return (
        <div className="spacing__window">
            <Title platform={hub} title={album?.name} />
            <MediaDetailBanner>
                {{
                    image: (
                        <picture className={styles['album-detail__image']}>
                            <source
                                srcSet={`${appendQueryParams(album.cover_url, { key: 'dw', value: 296 })}, ${appendQueryParams(album.cover_url, { key: 'dw', value: 592 })} 2x`}
                                media="(min-width: 1024px)"
                            />
                            <img
                                src={appendQueryParams(album.cover_url, { key: 'dw', value: 136 })}
                                srcSet={`${appendQueryParams(album.cover_url, { key: 'dw', value: 272 })} 2x`}
                                alt={album.title}
                                draggable={false}
                            />
                        </picture>
                    ),
                    text: (
                        <>
                            <div className={styles['album-detail__title-container']}>
                                <h2>{renderTitle()}</h2>
                                <button ref={ref} type="button">
                                    <ThreeDotsButton hasTooltip onClick={() => openThreeDotsModalSheet(isDownload ? 'download:album' : 'stream:album', album.id, left, top, false)} />
                                </button>
                            </div>
                            <div className={styles['album-detail__info']}>
                                <span>{convertToPluralIfNeeded(album.media?.length, 'Track')}</span>
                                {album.genre && <span>{album.genre.name}</span>}
                                <span>{`Updated ${formatDateToString(album.updated_at)}`}</span>
                            </div>
                            <p className={styles['album-detail__description']}>
                                {generateArtistLinks(
                                    album.artist,
                                    album.artists
                                )}
                            </p>
                        </>
                    ),
                    actions: (
                        <>
                            <button type="button" className={styles['album-detail__play-btn']} aria-label="Play/Pause" onClick={handlePlay}>
                                {isCurrentListInPlayer && playerState === State.Playing ? <PauseIcon /> : <PlayArrow />}
                            </button>
                            <AddToFavoriteButton onClick={handleFavorite} hasTooltip isFavorite={isAlbumFavorite} />
                            <AddToPlaylistButton onClick={(event) => openAddToPlaylist(event.target)} hasTooltip />
                        </>
                    ),
                }}
            </MediaDetailBanner>
            {isDownload && album?.media && <TrackListSupreme
                preset={TrackListPresetSupreme.Download}
                defaultExpanded={album.id}
                list={[album as DownloadAlbum]}
                onDownloadRevalidate={(downloadMedia) => {
                    if (downloadMedia) {
                        // eslint-disable-next-line no-param-reassign
                        downloadMedia.download_count += 1;
                        const mutatedData = { data: album as DownloadAlbum } as DownloadResponse;
                        mutate(mutatedData as any, false);
                    }
                }} />}
            {!isDownload && album?.media && (
                <TrackListSupreme
                    preset={TrackListPresetSupreme.Album}
                    album={sortedAlbum as StreamAlbum}
                    isSortable
                    onSort={(sort) => setSortType(sort as LocalSortingKeys)}
                    selectedSortType={sortType}
                    onDownloadRevalidate={(downloadMedia) => {
                        if (downloadMedia) {
                            // eslint-disable-next-line no-param-reassign
                            downloadMedia.download_count += 1;
                            const mutatedData = { data: album as StreamAlbum } as StreamResponse;
                            mutate(mutatedData as any, false);
                        }
                    }} />
            )}
        </div>
    );
}

export default AlbumDetail;
/* eslint-enable jsx-a11y/anchor-is-valid */
