import classNames from 'classnames';
import styles from './search-input.module.css';
import SearchIcon from '../../../../assets/icons/supreme/search.svg';
import SearchIconActive from '../../../../assets/icons/supreme/search-filled.svg';
import ClearIcon from '../../../../assets/icons/supreme/close.svg';

interface SearchInputProps {
    placeholder: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;

}

export function SearchInput({ placeholder, value, onChange }: SearchInputProps) {
    const onClear = () => {
        onChange({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>);
    };

    return (
        <div className={styles['search-input']}>
            <div className={styles['search-input__search-icon']}>
                {value.length > 0 ? <SearchIconActive /> : <SearchIcon />}
            </div>
            <input
                type="text"
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                className={styles['search-input__input']}
            />
            <button
                type="button"
                className={classNames(styles['search-input__clear-button'], {
                    [styles['search-input__clear-button--hidden']]: value.length === 0
                })}
                onClick={onClear}>
                <ClearIcon />
            </button>
        </div>
    );
}
