import { ReactComponent as HomeOutlineIcon } from '../../../assets/icons/home-active.svg';
import { ReactComponent as HomeFillIcon } from '../../../assets/icons/home.svg';

import { ReactComponent as YourTracksOutlineIcon } from '../../../assets/icons/artist-platform/navigation/YourTracks-Outline.svg';
import { ReactComponent as YourTracksFillIcon } from '../../../assets/icons/artist-platform/navigation/YourTracks-Fill.svg';

import { ReactComponent as EngagementOutlineIcon } from '../../../assets/icons/artist-platform/navigation/Engagement-Outline.svg';
import { ReactComponent as EngagementFillIcon } from '../../../assets/icons/artist-platform/navigation/Engagement-Fill.svg';

import { ReactComponent as SimilarArtistsOutlineIcon } from '../../../assets/icons/artist-platform/navigation/SimilarArtists-Outline.svg';
import { ReactComponent as SimilarArtistsFillIcon } from '../../../assets/icons/artist-platform/navigation/SimilarArtists-Fill.svg';

import { ReactComponent as SourcesOutlineIcon } from '../../../assets/icons/artist-platform/navigation/Sources-Outline.svg';
import { ReactComponent as SourcesFillIcon } from '../../../assets/icons/artist-platform/navigation/Sources-Fill.svg';

import { ReactComponent as LocationsOutlineIcon } from '../../../assets/icons/artist-platform/navigation/Locations-Outline.svg';
import { ReactComponent as LocationsFillIcon } from '../../../assets/icons/artist-platform/navigation/Locations-Fill.svg';

import { ReactComponent as DJTypeOutlineIcon } from '../../../assets/icons/artist-platform/navigation/DJType-Outline.svg';
import { ReactComponent as DJTypeFillIcon } from '../../../assets/icons/artist-platform/navigation/DJType-Fill.svg';

import { ReactComponent as EditProfileOutlineIcon } from '../../../assets/icons/artist-platform/navigation/EditProfile-Outline.svg';
import { ReactComponent as EditProfileFillIcon } from '../../../assets/icons/artist-platform/navigation/EditProfile-Fill.svg';

const ArtistPortalIcons = {
    HomeFillIcon,
    HomeOutlineIcon,
    YourTracksFillIcon,
    YourTracksOutlineIcon,
    EngagementFillIcon,
    EngagementOutlineIcon,
    SimilarArtistsFillIcon,
    SimilarArtistsOutlineIcon,
    SourcesFillIcon,
    SourcesOutlineIcon,
    LocationsFillIcon,
    LocationsOutlineIcon,
    DJTypeFillIcon,
    DJTypeOutlineIcon,
    EditProfileFillIcon,
    EditProfileOutlineIcon
};

export default ArtistPortalIcons;
