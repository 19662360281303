import { useMemo } from 'react';
import { useDrop } from 'react-dnd';

export type Accepting = 'Album' | 'Media' | 'CuratedSet' | 'ExclusivePlaylist' | 'UserPlaylist-Album' | 'Artist' | 'None' | 'Sound' | 'Label' | 'SoundPack' | 'Queue';
export const ALL_TYPES: Accepting[] = ['Album', 'Media', 'CuratedSet', 'ExclusivePlaylist', 'Artist', 'UserPlaylist-Album', 'Queue'];
export const ALL_TYPES_NO_USER_PLAYLIST_NO_ARTIST: Accepting[] = ['Album', 'Media', 'CuratedSet', 'ExclusivePlaylist', 'Queue'];
export const ALL_TYPES_NO_USER_PLAYLIST: Accepting[] = ['Album', 'Media', 'Artist', 'CuratedSet', 'ExclusivePlaylist', 'Queue'];
export const ALL_TYPES_NO_COLLECTION: Accepting[] = ['Album', 'Media', 'Queue'];
export const ALL_TYPES_NO_ARTIST: Accepting[] = ['Album', 'Media', 'CuratedSet', 'ExclusivePlaylist', 'UserPlaylist-Album', 'Queue'];
export const USER_PLAYLISTS: Accepting[] = ['UserPlaylist-Album', 'ExclusivePlaylist'];

export type DragDropItem = { title?: string, id: string | number };
export type DragResult = { target: string, id?: string | number };

export interface DraggableItemProps {
    accept: Accepting | Accepting[]
    disabled?: boolean
    onlyHover?: boolean
    onDrop?: (type: Accepting, data: DragDropItem | DragDropItem[]) => any
    children(state: { isOver: boolean, canDrop: boolean }): React.ReactElement<HTMLElement>;
    shallow?: boolean;
}

export function Droppable({ children, onDrop, accept, onlyHover, disabled, shallow }: DraggableItemProps) {
    const [{ isOver, canDrop }, dropRef] = useDrop<DragDropItem | DragDropItem[], unknown, { isOver: boolean, canDrop: boolean }>({
        accept,
        collect: (monitor) => ({
            isOver: monitor.isOver({ shallow }),
            canDrop: monitor.canDrop(),
        }),
        canDrop: () => {
            if (disabled || onlyHover) {
                return false;
            }
            return true;
        },
        drop: (data, monitor) => {
            if (!shallow && monitor.didDrop()) {
                return undefined;
            }
            return onDrop?.(monitor.getItemType() as any, data);
        }
    });
    const state = useMemo(() => {
        return {
            isOver: isOver && !disabled,
            canDrop
        };
    }, [isOver, disabled, canDrop]);

    return (
        <div ref={dropRef}>
            {children(state)}
        </div>
    );
}

export function useDroppable({ onDrop, accept, onlyHover, disabled, shallow }: Omit<DraggableItemProps, 'children'>) {
    const [{ isOver, canDrop }, dropRef] = useDrop<DragDropItem | DragDropItem[], unknown, { isOver: boolean, canDrop: boolean }>({
        accept,
        collect: (monitor) => ({
            isOver: monitor.isOver({ shallow }),
            canDrop: monitor.canDrop(),
        }),
        canDrop: () => {
            if (disabled || onlyHover) {
                return false;
            }
            return true;
        },
        drop: (data, monitor) => {
            if (!shallow && monitor.didDrop()) {
                return undefined;
            }
            return onDrop?.(monitor.getItemType() as any, data);
        }
    }, [accept, disabled, shallow, onlyHover, onDrop]);

    return {
        isOver: isOver && !disabled,
        canDrop,
        dropRef
    };
}

export default Droppable;
