import { DownloadAlbum, DownloadMedia, SearchPaginatedQuery, SearchQuery, SimplePaginatedSearchQuery } from '@bpm-web-app/api-client';
import { Library } from '@bpm-web-app/download-api-sdk';
import useSWR from 'swr';

export function useUserDownloadHistory(query: SimplePaginatedSearchQuery, isEnabled = true) {
    const pageSize = query.limit || 10;
    const { data, error, mutate } = useSWR(['download-get-download-history', { ...query, isEnabled, persist: true }], () => (isEnabled
        ? DownloadMedia.getUserDownloadHistory({
            ...query,
            limit: pageSize,
        }) : null)
    );

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}

export function useDownloadAlbumById(albumId: number, isEnabled = true) {
    const { data, error, mutate } = useSWR<Awaited<ReturnType<typeof DownloadMedia['getAlbumById']>>>([`download-get-album-by-id-${albumId}`, { persist: true, isEnabled }], () => DownloadMedia.getAlbumById(albumId), {
        isPaused: () => !isEnabled || !albumId,
    });

    return {
        data,
        mutate,
        isLoading: !error && !data && Boolean(albumId),
        error,
    };
}

export function useDownloadSearchAlbum(query: SearchPaginatedQuery) {
    const { data, error } = useSWR(
        [
            'download-search-album',
            {
                persist: false,
                ...query,
            },
        ],
        () => DownloadMedia.searchAlbum(query)
    );

    return {
        data: data?.data,
        isLoading: !error && !data,
        error,
    };
}

export type DownloadCategoryAlbums = Awaited<ReturnType<typeof DownloadAlbum['getAlbumsForCategory']>>;

export function useAlbumForCategory(query: SearchQuery, isEnabled = true) {
    const { data, error, mutate } = useSWR<DownloadCategoryAlbums>(
        [
            'download-album-category',
            {
                persist: true,
                isEnabled,
                ...query,
            },
        ],
        () => DownloadAlbum.getAlbumsForCategory(query),
        {
            isPaused: () => !isEnabled,
        }
    );

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}

export function useSimilarAlbum(albumId: number, library: string, isEnabled = true) {
    const { data, error, mutate } = useSWR(['download-get-similar-albums', { persist: true, albumId, isEnabled, library }], () => (isEnabled ? DownloadAlbum.getSimilarAlbum(albumId, library) : null));

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}

export function useRemixesAlbum(albumId: number, isEnabled = true) {
    const { data, error, mutate } = useSWR(['download-get-similar-albums', { persist: true, albumId, isEnabled }], () => (isEnabled ? DownloadAlbum.getRemixesAlbum(albumId) : null));

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}
