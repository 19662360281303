import { getFollowedPlaylists } from '@bpm-web-app/swr-hooks';
import { useApiErrorHandler, useHubSwitch, showToast, Analytics } from '@bpm-web-app/utils';
import { Playlist } from 'libs/api-client/src/lib/handlers/playlist';
import { useEffect, useState } from 'react';

export const useFollowPlaylists = (playlistId: number, library?: string) => {
    const { isDownload } = useHubSwitch();
    const errorHandler = useApiErrorHandler();
    const { data: followedPlaylists, isLoading, mutate: mutateFollowedPlaylists } = getFollowedPlaylists(library);

    const [isPlaylistFollowed, setIsPlaylistFollowed] = useState<boolean>();

    useEffect(() => {
        const isFav = followedPlaylists?.data.some(({ id }) => `${id}` === `${playlistId}`);
        setIsPlaylistFollowed(isFav);
    }, [followedPlaylists, playlistId]);

    const addToFavorites = async () => {
        setIsPlaylistFollowed(true);
        try {
            mutateFollowedPlaylists(await Playlist.addPlaylistToFollowed(isDownload, +playlistId));
            Analytics.trackClick('add_playlist_to_followed', playlistId.toString());
            showToast({ type: 'success', message: 'Playlist followed.' });
        } catch (error) {
            errorHandler({ error });
            setIsPlaylistFollowed(false);
        }
    };

    const removeFromFavorites = async () => {
        setIsPlaylistFollowed(false);
        try {
            mutateFollowedPlaylists(await Playlist.removePlaylistFromFollowed(isDownload, +playlistId));
            showToast({ type: 'success', message: 'Playlist unfollowed.' });
        } catch (error) {
            errorHandler({ error });
            setIsPlaylistFollowed(true);
        }
    };

    return {
        followedPlaylists,
        isLoading,
        addPlaylistToFavorites: addToFavorites,
        removePlaylistFromFavorites: removeFromFavorites,
        isPlaylistFollowed,
    };
};
