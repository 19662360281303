import { appendQueryParams, getInitialsFromName, useViewport } from '@bpm-web-app/utils';
import { useEffect, useState } from 'react';
import styles from './artist-detail-header.module.css';
import { FollowButton } from '../../../shared/follow-button/follow-button';
import { ShareURL } from '../../../shared/share-url/share-url';
import { PlayButton } from '../../../shared/play-button/play-button';
import { BreakpointView } from '../../../shared';

interface ArtistDetailHeaderProps {
    artistImageUrl?: string;
    imageFile?: File;
    artistName: string;
    isArtistFollowed: boolean;
    isPlaying: boolean;
    handlePlaySongs?: () => void;
    handleFollow?: () => void;
    isPreview?: boolean;
}

export function ArtistDetailHeader({ artistImageUrl, artistName, isArtistFollowed, isPlaying, handlePlaySongs, handleFollow, imageFile, isPreview = false }: ArtistDetailHeaderProps) {
    const [imageFileObject, setImageFileObject] = useState(undefined);
    const { isMobile } = useViewport();

    const buttonsComponents = () => {
        return (
            <>
                <PlayButton type={isPreview ? 'play-red' : 'play'} isPlaying={isPlaying} onPress={handlePlaySongs} removeHover={isPreview} fullWidth={isMobile} />
                <FollowButton isFollowed={isArtistFollowed} handleFollow={handleFollow} removeHover={isPreview} type="artist" fullWidth={isMobile} signUpModalType="track" />
                {!isPreview ? (
                    <BreakpointView desktopChildren={<ShareURL currentUrl />} mobileChildren={undefined} />
                ) : null}
            </>
        );
    };

    useEffect(() => {
        setImageFileObject(imageFile ? URL.createObjectURL(imageFile) : undefined);
        return () => {
            // Destroy image object on unmount
            URL.revokeObjectURL(imageFileObject);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageFile]);

    return (
        <>
            <div className={styles['artist-detail-header--banner']}>
                <div className={styles['artist-detail-header--image-container']}>
                    <figure className={styles['artist-detail-header--image']}>
                        {
                            imageFile ? <img
                                src={imageFileObject}
                                alt={artistName}
                                draggable={false}
                            />
                                : !artistImageUrl ? (
                                    <div className={styles['artist-detail-header--image-initials']}>{getInitialsFromName(artistName)}</div>
                                ) : (
                                    <img
                                        src={appendQueryParams(artistImageUrl, { key: 'dw', value: 192 })}
                                        srcSet={`${appendQueryParams(artistImageUrl, { key: 'dw', value: 384 })} 2x`}
                                        alt={artistName}
                                        draggable={false}
                                    />
                                )
                        }
                    </figure>
                </div>
                <div className={styles['artist-detail-header--infos']}>
                    <div className={styles['artist-detail-header--name-container']}>
                        <h1 className={styles['artist-detail-header--title']}>{artistName}</h1>
                    </div>
                    <div className={styles['artist-detail-header--actions']}>
                        {buttonsComponents()}
                    </div>
                </div>
            </div>
            <div className={styles['artist-detail-header--actions-mobile']}>
                {buttonsComponents()}
            </div>
        </>
    );
}
