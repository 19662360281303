/* eslint-disable react/no-danger */
import { appendQueryParams, contestSongToCreatePlayable, createAppRoutes, HLSPlayer, State, useHubSwitch, usePlayerState } from '@bpm-web-app/utils';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useContests } from '@bpm-web-app/swr-hooks';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './contest-detail.module.css';
import SocialShare from '../../shared/social-share/social-share';
import GenericCarousel from '../../shared/ui/generic-carousel/generic-carousel';
import PrizeCard from '../../prize-card/prize-card';
import Comments from '../../comments/comments';
import SmallPlay from '../../../assets/icons/create/small-play.svg';
import Submissions from '../../submissions/submissions';
import useContest from '../../shared/three-dots-sheet/useContest';
import ShareIcon from '../../../assets/icons/share-icon.svg';
import { useCreatePlayer } from '../../../../../utils/src/lib/create-player.context';
import { AppLink } from '../../shared/app-link/app-link';
import CardCreate from '../../shared/card/card-create';
import PauseIcon from '../../../assets/icons/player-pause.svg';
import { PageNotFound } from '../page-not-found/page-not-found';
import Title from '../../title/title';

const MAX_DESCRIPTION_CHARACTERS = 590;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ContestDetailProps { }

export function ContestDetail(props: ContestDetailProps) {
    const router = useRouter();
    const { slug } = router.query;
    const { contests, isLoading, error } = useContests();
    const [showTotalDescription, setShowTotalDescription] = useState(false);
    const playerState = usePlayerState();
    const { hub } = useHubSwitch();

    const { playDemo, currentTrack, togglePlayPause } = useCreatePlayer();

    const { isOpen } = useCreatePlayer();

    const detailedContest = useMemo(() => {
        const contest = contests?.current?.find((contestObj) => contestObj.slug === slug);
        if (contest) {
            return contest;
        }
        return contests?.past?.find((contestObj) => contestObj.slug === slug);
    }, [contests, slug]);

    const isExpired = useMemo(() => dayjs(detailedContest?.submission_deadline).isBefore(new Date()), [detailedContest]);

    const isCurrentContestPlaying = useMemo(() => currentTrack?.name === detailedContest?.title, [currentTrack?.name, detailedContest?.title]);

    const { contestReportDownloadStems } = useContest(detailedContest?.id, true);

    const downloadStem = useCallback(() => {
        if (detailedContest?.zip_url && !isExpired) {
            contestReportDownloadStems();
            window.location.assign(detailedContest.zip_url);
        }
    }, [contestReportDownloadStems, detailedContest?.zip_url, isExpired]);

    const play = useCallback(() => {
        if (detailedContest?.song_url) {
            if (!isCurrentContestPlaying) {
                playDemo(contestSongToCreatePlayable(detailedContest));
            } else {
                togglePlayPause();
            }
        }
    }, [detailedContest, isCurrentContestPlaying, playDemo, togglePlayPause]);

    const getDescriptionMarkup = (text: string) => <div className={styles['contest-detail__date']}>{text}</div>;

    useEffect(
        () => () => {
            if (!isOpen) HLSPlayer.destroy();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    /* TODO: implement (ghost?) loading */
    if (isLoading) return <div />;

    /* TODO: potentially replace this with a custom 404 page if/when built */
    if (!isLoading && (error || !detailedContest)) return <PageNotFound />;

    return (
        <>
            <Title platform={hub} title={detailedContest?.title} />
            <div className={styles['contest-detail']}>
                {detailedContest.banner_image_url && (
                    <div className={styles['contest-detail__img-wrapper']}>
                        <picture className={styles['contest-detail__img']}>
                            <source srcSet={detailedContest.banner_image_url} media="(min-width: 1920px)" />
                            <source
                                srcSet={`${appendQueryParams(detailedContest.banner_image_url, { key: 'dw', value: 3840 })} 2x, ${appendQueryParams(detailedContest.banner_image_url, { key: 'dw', value: 1920 })} 1x`}
                                media="(min-width: 1440px)"
                            />
                            <source
                                srcSet={`${appendQueryParams(detailedContest.banner_image_url, { key: 'dw', value: 2880 })} 2x, ${appendQueryParams(detailedContest.banner_image_url, { key: 'dw', value: 1440 })} 1x`}
                                media="(min-width: 1024px)"
                            />
                            <source
                                srcSet={`${appendQueryParams(detailedContest.banner_image_url, { key: 'dw', value: 2048 })} 2x, ${appendQueryParams(detailedContest.banner_image_url, { key: 'dw', value: 1024 })} 1x`}
                                media="(min-width: 768px)"
                            />
                            <img
                                src={appendQueryParams(detailedContest.banner_image_url, { key: 'dw', value: 1024 })}
                                srcSet={`${appendQueryParams(detailedContest.banner_image_url, { key: 'dw', value: 2048 })} 2x`}
                                alt={detailedContest?.title || ''}
                            />
                        </picture>
                    </div>
                )}
                <div className={styles['contest-detail__intro']}>
                    <div className={styles['contest-detail__summary']}>
                        <h1 className={styles['contest-detail__title']}>{detailedContest?.title || ''}</h1>
                        <ul className={styles['contest-detail__details']}>
                            <li className={styles['contest-detail__detail']}>
                                Submission Start Date
                                <strong>{dayjs(detailedContest.start_date).format('MMMM D, YYYY, hh:mma')}</strong>
                            </li>
                            <li className={styles['contest-detail__detail']}>
                                Submission Deadline
                                <strong>{dayjs(detailedContest.submission_deadline).format('MMMM D, YYYY, hh:mma')}</strong>
                            </li>
                            <li className={styles['contest-detail__detail']}>
                                Winner Announced
                                <strong>{dayjs(detailedContest.announcement).format('MMMM D, YYYY, hh:mma')}</strong>
                            </li>
                        </ul>
                        <div className={styles['contest-detail__summary-ctas']}>
                            {isExpired ?
                                <div className={classNames(styles['contest-detail__summary-cta'], styles['contest-detail__summary-cta--primary'], styles['contest-detail__summary-cta--text'], styles['contest-detail__summary-cta--disabled'])}>
                                    Submit Your Remix
                                </div>
                                : (
                                    <AppLink href={`/contest/${slug}/submit-remix`}>
                                        <a className={classNames(styles['contest-detail__summary-cta'], styles['contest-detail__summary-cta--primary'], styles['contest-detail__summary-cta--text'])}>
                                            Submit Your Remix
                                        </a>
                                    </AppLink>
                                )
                            }

                            <button
                                type="button"
                                disabled={isExpired}
                                className={classNames(styles['contest-detail__summary-cta'], styles['contest-detail__summary-cta--secondary'], styles['contest-detail__summary-cta--text'])}
                                onClick={downloadStem}
                            >
                                Download Stems
                            </button>

                            <button
                                type="button"
                                className={classNames(
                                    styles['contest-detail__summary-cta'],
                                    styles['contest-detail__summary-cta--tertiary'],
                                    styles['contest-detail__summary-cta--text'],
                                    styles['contest-detail__summary-cta--svg']
                                )}
                                onClick={play}
                            >
                                {isCurrentContestPlaying && playerState === State.Playing ? <PauseIcon /> : <SmallPlay />}
                                Play
                            </button>
                        </div>
                    </div>
                    <div className={styles['contest-detail__content']}>
                        <div
                            className={styles['contest-detail__description']}
                            dangerouslySetInnerHTML={{
                                __html:
                                    detailedContest.description.length > MAX_DESCRIPTION_CHARACTERS && !showTotalDescription
                                        ? detailedContest.description.slice(0, MAX_DESCRIPTION_CHARACTERS)
                                        : detailedContest.description,
                            }}
                        />
                        {detailedContest.description.length > MAX_DESCRIPTION_CHARACTERS && !showTotalDescription && (
                            <button type="button" onClick={() => setShowTotalDescription(true)} className={styles['contest-detail__link']}>
                                See Rules
                            </button>
                        )}
                        <div className={styles['contest-detail__share']}>
                            <SocialShare platforms={['facebook', 'twitter', 'linkedin', 'link', 'email']} url={window?.location?.href} title="Share" icon={<ShareIcon />} />
                        </div>
                    </div>
                </div>

                <GenericCarousel title="Prizes" cssMode noPadding>
                    {detailedContest.ContestPrizes.map((prize) => (
                        <Fragment key={prize.id}>
                            <PrizeCard id={prize.id} image={prize.image_url} title={prize.title} description={prize.description} link={prize.link} />
                        </Fragment>
                    ))}
                </GenericCarousel>

                <Submissions contestId={detailedContest.id} />
                <Comments contestId={detailedContest.id} />

                {contests?.current.length > 0 &&
                    <GenericCarousel title="Current Contests" noPadding>
                        {contests?.current.map((item) => (
                            <CardCreate
                                contentType="contest"
                                cardSize="large"
                                key={item.id}
                                id={`${item.id}`}
                                title={item.title}
                                imageUrl={appendQueryParams(item.artwork_url, { key: 'dw', value: 160 })}
                                imageUrl2x={appendQueryParams(item.artwork_url, { key: 'dw', value: 320 })}
                                link={createAppRoutes.contestDetail(item.slug)}
                                description={getDescriptionMarkup(`Deadline ${dayjs(item.submission_deadline).tz('America/Los_Angeles').format('MMMM DD,YYYY hh:mm')} PST`)}
                            />
                        ))}
                    </GenericCarousel>
                }
                <GenericCarousel title="Past Contests" noPadding>
                    {contests?.past.map((item) => (
                        <CardCreate
                            contentType="contest"
                            cardSize="large"
                            key={item.id}
                            id={`${item.id}`}
                            title={item.title}
                            description={getDescriptionMarkup(`Expired ${dayjs(item.submission_deadline).tz('America/Los_Angeles').format('MMMM DD,YYYY')}`)}
                            imageUrl={appendQueryParams(item.artwork_url, { key: 'dw', value: 160 })}
                            imageUrl2x={appendQueryParams(item.artwork_url, { key: 'dw', value: 320 })}
                            link={createAppRoutes.contestDetail(item.slug)}
                        />
                    ))}
                </GenericCarousel>
            </div>
        </>
    );
}

export default ContestDetail;
/* eslint-enable react/no-danger */
