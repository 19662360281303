import { FC, ReactNode } from 'react';
import styles from './list-grid.module.css';

export interface ListGridProps {
    children: ReactNode[] | ReactNode;
}

export const ListGrid: FC<ListGridProps> = ({ children }) => <div className={styles['list-grid']}>{children}</div>;

export default ListGrid;
