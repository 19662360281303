import { useCallback, useState } from 'react';

export function useAccordions() {
    const [openAccordions, setOpenAccordions] = useState<number[]>([]);

    const onAccordionClick = useCallback(
        (id: number) => {
            if (openAccordions.includes(id)) {
                setOpenAccordions([]);
            } else {
                setOpenAccordions([id]);
            }
        },
        [openAccordions]
    );

    return { openAccordions, onAccordionClick };
}
