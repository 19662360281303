import { appendQueryParams, formatDateToString } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { UserPlaylistCollaboration, UserPlaylistWithAlbumOwner } from '@bpm-web-app/download-api-sdk';
import { useMemo } from 'react';
import { UserDriveCollaboration } from '@bpm-web-app/create-api-sdk';
import { CustomIcon } from '../custom-icon/custom-icon';
import styles from './collaboration.module.css';

export type CollaborationUserImageSize = 'small' | 'regular' | 'large';

export interface CollaborationUserImageProps {
    imageUrl?: string;
    size?: CollaborationUserImageSize;
    collaborator?: UserPlaylistCollaboration;
    driveCollaborator?: UserDriveCollaboration;
    owner?: UserPlaylistWithAlbumOwner;
    addedAt?: string;
}

export function CollaborationUserImage({ imageUrl, size = 'regular', collaborator, driveCollaborator, owner, addedAt }: CollaborationUserImageProps) {
    const renderCollaborator = useMemo(() => {
        return driveCollaborator !== undefined ? (
            <div className={styles['collaboration-user-image__container--hover-container']}>
                <div className={styles['collaboration-modal__row-title']}>{driveCollaborator.full_name}</div>
                {addedAt ? (
                    <div className={styles['collaboration-modal__row-subtitle']}>
                        {formatDateToString(addedAt, 'MM/DD/YY')}
                    </div>
                ) : driveCollaborator.created_at ? (
                    <div className={styles['collaboration-modal__row-subtitle']}>{formatDateToString(driveCollaborator.created_at, 'MM/DD/YY')}</div>
                )
                    : null}
                <div className={styles['collaboration-user-image__container--hover-container--arrow']} />
            </div>
        ) : collaborator !== undefined ? (
            <div className={styles['collaboration-user-image__container--hover-container']}>
                <div className={styles['collaboration-modal__row-title']}>{collaborator.full_name}</div>
                {addedAt ? (
                    <div className={styles['collaboration-modal__row-subtitle']}>
                        {formatDateToString(addedAt, 'MM/DD/YY')}
                    </div>
                ) : collaborator.created_at ? (
                    <div className={styles['collaboration-modal__row-subtitle']}>{formatDateToString(collaborator.created_at, 'MM/DD/YY')}</div>
                )
                    : null}
                <div className={styles['collaboration-user-image__container--hover-container--arrow']} />
            </div>
        ) : owner ? (
            <div className={styles['collaboration-user-image__container--hover-container']}>
                <div className={styles['collaboration-modal__row-title']}>{owner.full_name}</div>
                {addedAt ? <div className={styles['collaboration-modal__row-subtitle']}>{formatDateToString(addedAt, 'MM/DD/YY')}</div> : null}
                <div className={styles['collaboration-user-image__container--hover-container--arrow']} />
            </div>
        ) : null;
    }, [addedAt, collaborator, driveCollaborator, owner]);

    return imageUrl ? (
        <div
            className={classNames(
                styles['collaboration-user-image__container'],
                styles[`collaboration-user-image__size--${size}`],
            )}
        >
            <img
                src={appendQueryParams(imageUrl, { key: 'dw', value: 56 })}
                srcSet={`${appendQueryParams(imageUrl, { key: 'dw', value: 112 })} 2x`}
                alt="Collaborator"
            />
            {renderCollaborator}
        </div>
    ) : (
        <div className={classNames(
            styles['collaboration-user-image__container'],
            styles[`collaboration-user-image__size--${size}`],
        )}
        >
            <CustomIcon type="profile-icon" hasIconHover size={size} />
            {renderCollaborator}
        </div>
    );
}
