import { MediaWithAlbum } from '@bpm-web-app/download-api-sdk';
import { Artist, Media } from '@bpm-web-app/stream-api-sdk';
import { useDownloadMedia, useOfflineCrate, useOnlineCrate, useRemoveMultipleFromCrate } from '@bpm-web-app/swr-hooks';
import { convertToPluralIfNeeded, formatDateToString, getCurrentPlatformLink, useApiErrorHandler, useHideSwitch, useHubSwitch, unique, showToast, rebuildReactTooltip } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import SyncCompleteIcon from '../../../assets/icons/crate-sync-complete-lg.svg';
import ControlsCheck from '../../../assets/icons/controls-check.svg';
import DeleteIcon from '../../../assets/icons/delete-lg.svg';
import { ActionModal } from '../../shared/action-modal/action-modal';
import { LibraryTabsContext } from '../../../../../utils/src/lib/library-tabs.context';
import { MediaDetailBanner } from '../../shared/ui/media-detail-banner/media-detail-banner';
import { TrackListPreset, TrackListSelectableProps } from '../../shared/track-list/track-list';
import { CRATE_ALL_ITEM } from '../../crate-list/crate-list';
import styles from './crate-detail.module.css';
import { LocalSortingKeys, sortMediaLocally, useLocalSort } from '../../sort-options-sheet/sort-options-sheet';
import { useArtistLinks } from '../../artist-link/artist-link';
import Title from '../../title/title';
import { AppLink, BreakpointView, GhostComponent } from '../../shared';
import { DownloadButton } from '../../shared/download-button/download-button';
import { CardImage } from '../../shared/card/card-image/card-image';
import { TrackListSupreme } from '../../shared/track-list/supreme/track-list-supreme';
import { TrackListPresetSupreme } from '../../shared/track-list/supreme/track-list-supreme-helpers';
import { TrackListLoadingSupreme } from '../../shared/track-list/ghost-loading/track-list-loading-supreme';

type HeaderDetails = {
    title: string;
    shortTitle: string;
    updatedAt: string;
    numberOfTracks: number;
    coverUrl: string;
    coverColor: string;
    artistsByName: string;
    artists: Artist[];
};

export function CrateDetail() {
    const router = useRouter();
    const { categorySlug } = router.query;

    const [selectedItemsIds, setSelectedItemsIds] = useState<Media['id'][]>([]);
    const [showDeleteAllModal, setShowDeleteAllModal] = useState(false);
    const [showInProgressModal, setShowInProgressModal] = useState(false);
    const { libraryProperty } = useContext(LibraryTabsContext);
    useHideSwitch();
    const [sortType, setSortType] = useLocalSort();

    const { downloadMultipleMedia, downloadSingleMedia, isRequestInProgress, handleOpenDownloadUrl, setIsRequestInProgress, downloadAllMediaFromCrate } = useDownloadMedia(libraryProperty, true);

    const { isDownload, hub } = useHubSwitch();
    const { data: offlineData, mutate: mutateOfflineCrate, isLoading: isOfflineCrateLoading } = useOfflineCrate(libraryProperty, isDownload);
    const { data: onlineData, mutate: mutateOnlineCrate, isLoading: isOnlineCrateLoading } = useOnlineCrate(libraryProperty, !isDownload);
    const removeMultiple = useRemoveMultipleFromCrate(hub);
    const errorHandler = useApiErrorHandler();

    useEffect(() => {
        rebuildReactTooltip();
    }, []);

    const isLoading = useMemo(() => {
        return isDownload ? isOnlineCrateLoading : isOfflineCrateLoading;
    }, [isDownload, isOfflineCrateLoading, isOnlineCrateLoading]);

    const tracks = useMemo(() => {
        let tracksData = (isDownload ? onlineData?.data || [] : offlineData?.data || []) as MediaWithAlbum[];

        tracksData = sortMediaLocally(tracksData, sortType);

        if (categorySlug === CRATE_ALL_ITEM.slug) {
            return tracksData;
        }

        return tracksData.filter((track) => track?.album.category.slug === categorySlug) || [];
    }, [categorySlug, isDownload, offlineData?.data, onlineData?.data, sortType]);

    const headerDetails = useMemo<HeaderDetails | null>(() => {
        if (!tracks.length) return null;

        const artists: Artist[] = unique(tracks?.map((track) => track.album?.artists?.[0]).filter(Boolean), 'id').slice(0, 5);
        const newestTrack = tracks.slice().sort((t1, t2) => new Date(t2.added_to_crate_at).getTime() - new Date(t1.added_to_crate_at).getTime())[0];

        return {
            title: categorySlug === CRATE_ALL_ITEM.slug ? CRATE_ALL_ITEM.name : tracks[0].album.category.name,
            shortTitle: '',
            updatedAt: `Updated ${formatDateToString(newestTrack.added_to_crate_at)}` || '',
            numberOfTracks: tracks?.length || 0,
            coverUrl: newestTrack.album?.cover_url || '',
            coverColor: newestTrack.album?.cover_color || '#000000',
            artistsByName: artists.map((a) => a.name).join(', '),
            artists,
        } as HeaderDetails;
    }, [categorySlug, tracks]);

    const handlePostDownloadActions = useCallback(
        async (idsToDownload: number[]) => {
            const all = idsToDownload.length === tracks.length;

            await mutateOnlineCrate({
                data: (tracks as MediaWithAlbum[])?.filter((track) => !idsToDownload.includes(track.id)),
            });

            if (all) {
                showToast({ type: 'success', title: `All items of ${headerDetails?.title || 'this crate'} were downloaded successfully.` });
            } else {
                showToast({ type: 'success', message: 'Selected items downloaded successfully and removed from the crate.' });
            }
        },
        [headerDetails?.title, mutateOnlineCrate, tracks]
    );

    const handleDownload = useCallback(async () => {
        try {
            if (isDownload && tracks.length) {
                setIsRequestInProgress(true);

                let idsToDownload: number[] = [];
                let url = '';

                if (!selectedItemsIds.length) {
                    idsToDownload = tracks.map((track) => track.id);
                } else {
                    idsToDownload = selectedItemsIds;
                }

                if (idsToDownload.length === 1) {
                    url = (await downloadSingleMedia(idsToDownload[0])).data?.url || '';
                } else if (idsToDownload.length < tracks.length) {
                    setShowInProgressModal(true);
                    url = (await downloadMultipleMedia(idsToDownload)).data?.url || '';
                } else if (idsToDownload.length === tracks.length) {
                    setShowInProgressModal(true);
                    const slug: string = categorySlug === CRATE_ALL_ITEM.slug ? '' : (categorySlug as string);
                    url = (await downloadAllMediaFromCrate(slug)).data?.url || '';
                }
                await handlePostDownloadActions(idsToDownload);
                setShowInProgressModal(false);
                handleOpenDownloadUrl(url);
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            errorHandler({ error });
        } finally {
            setIsRequestInProgress(false);
        }
    }, [
        categorySlug,
        downloadAllMediaFromCrate,
        downloadMultipleMedia,
        downloadSingleMedia,
        errorHandler,
        handleOpenDownloadUrl,
        handlePostDownloadActions,
        isDownload,
        selectedItemsIds,
        setIsRequestInProgress,
        tracks,
        setShowInProgressModal,
    ]);

    const onDeleteAllPostAction = useCallback(() => {
        showToast({ type: 'success', title: `${headerDetails?.title} cleared.` });

        router.replace(getCurrentPlatformLink('/crate'));
    }, [headerDetails?.title, router]);

    const handleDelete = useCallback(async () => {
        try {
            if (!selectedItemsIds.length) {
                await removeMultiple(tracks.map((track) => track.id));
                onDeleteAllPostAction();
                rebuildReactTooltip();
            }

            if (selectedItemsIds.length) {
                await removeMultiple(selectedItemsIds);
                if (selectedItemsIds.length === tracks.length) {
                    onDeleteAllPostAction();
                    rebuildReactTooltip();
                } else {
                    showToast({ type: 'success', message: 'Selected items were deleted successfully.' });

                    setSelectedItemsIds([]);
                    rebuildReactTooltip();
                    if (isDownload) {
                        mutateOnlineCrate({ data: onlineData.data.filter((track) => !selectedItemsIds.includes(track.id)) }, { revalidate: true });
                    } else {
                        mutateOfflineCrate({ data: offlineData.data.filter((track) => !selectedItemsIds.includes(track.id)) }, { revalidate: true });
                    }
                }
            }
        } catch (error) {
            errorHandler({ error, message: 'Failed to delete. Please try again.' });
        }
    }, [isDownload, mutateOfflineCrate, mutateOnlineCrate, offlineData?.data, onDeleteAllPostAction, onlineData?.data, removeMultiple, selectedItemsIds, tracks, errorHandler]);

    const handleSelectItem = useCallback((id: Media['id']) => {
        setSelectedItemsIds((prevState) => {
            if (prevState.includes(id)) {
                return prevState.filter((selectedId) => selectedId !== id);
            }
            return [...prevState, id];
        });
    }, []);

    const handleToggleAll = useCallback(
        (all: boolean) => {
            if (tracks.length) {
                setSelectedItemsIds(() => (all ? [...tracks.map((track) => track.id)] : []));
            }
        },
        [tracks]
    );

    const handleTrashPress = useCallback(() => {
        if (!selectedItemsIds.length || selectedItemsIds.length === tracks.length) {
            return setShowDeleteAllModal(true);
        }

        return handleDelete();
    }, [handleDelete, selectedItemsIds.length, tracks.length]);

    const selectableProps = useCallback(
        () =>
            ({
                onSelectItem: handleSelectItem,
                selectedItemsIds,
                onSelectAll: handleToggleAll,
            } as Pick<TrackListSelectableProps, 'onSelectItem' | 'selectedItemsIds' | 'onSelectAll'>),
        [handleSelectItem, handleToggleAll, selectedItemsIds]
    );

    const generateArtistLinks = useArtistLinks();

    const getFourUniqueImages = () => {
        if (tracks === undefined) return undefined;

        const uniqueUrls: string[] = [];
        tracks.filter((item) => {
            if (item.album?.cover_url && !uniqueUrls.includes(item.album?.cover_url)) {
                uniqueUrls.push(item.album?.cover_url);
                return true;
            }
            return false;
        });
        return uniqueUrls.slice(0, 4);
    };

    if (!tracks?.length) {
        if (isLoading) {
            return (
                <>
                    <Title platform={hub} title="Loading Artist..." />
                    <GhostComponent type="my-drive-detail" />
                    <TrackListLoadingSupreme amount={20} preset={TrackListPresetSupreme.OfflineCrate} />
                </>
            );
        }
        return (
            <h4 className={styles['crate-detail__empty']}>
                <div>No Tracks Available</div>
                {`${categorySlug}` !== CRATE_ALL_ITEM.slug && (
                    <div>
                        <AppLink href="/crate">
                            <a className={styles['crate-detail__empty--back']}>Back To My Crate</a>
                        </AppLink>
                    </div>
                )}
            </h4>
        );
    }

    return (
        <>
            <Title platform={hub} title="My Crate" />
            <ActionModal
                headerTitle="Clear all?"
                confirmButtonText="I'm sure"
                title={`Are you sure you want to clear your ${headerDetails?.title || ''} Crate?`}
                subtitle="This action is permanent and cannot be undone."
                onConfirm={() => {
                    setShowDeleteAllModal(false);
                    handleDelete();
                }}
                onClose={() => setShowDeleteAllModal(false)}
                isOpen={showDeleteAllModal}
            />
            <ActionModal
                contentClassname={styles['crate-detail__progress-popup']}
                headerTitle={
                    <div className={styles['crate-detail__progress-popup-title']}>
                        <ControlsCheck />
                        Your crate download is being prepared.
                    </div>
                }
                confirmButtonText="Ok"
                variant="dark"
                subtitle={
                    <>
                        <p>Depending on the number of tracks and your connection speed, this process may take a while.</p>
                        <br />
                        <p>You will receive an email and notification once your crate is ready to be downloaded.</p>
                    </>
                }
                hideCancel
                onConfirm={() => {
                    setShowInProgressModal(false);
                }}
                onClose={() => setShowInProgressModal(false)}
                isOpen={showInProgressModal}
            />
            <div className={classNames(styles['crate-detail'], 'spacing__window')}>
                {headerDetails && tracks && (
                    <MediaDetailBanner>
                        {{
                            image: <CardImage gridImagesUrls={getFourUniqueImages()} imageUrl={headerDetails.coverUrl} />,
                            text: (
                                <>
                                    <div className={styles['crate-detail__title-container']}>
                                        <h2>{headerDetails.title}</h2>
                                    </div>
                                    <div className={styles['crate-detail__info']}>
                                        <span>{`${convertToPluralIfNeeded(tracks.length, 'Track')}`}</span>
                                        <span>{headerDetails.updatedAt}</span>
                                    </div>
                                    <p className={styles['crate-detail__description']}>{generateArtistLinks(headerDetails.artistsByName, headerDetails.artists, { sort: false })}</p>
                                </>
                            ),
                            actions: (
                                <>
                                    {isDownload ? (
                                        <BreakpointView desktopChildren={<DownloadButton type="dynamic" isLoading={isRequestInProgress} onPress={handleDownload} />} mobileChildren={undefined} />
                                    ) : (
                                        <button className={styles['crate-detail__sync-btn']} type="button" aria-label="Sync" disabled>
                                            <SyncCompleteIcon />
                                        </button>
                                    )}
                                    <button
                                        className={styles['crate-detail__delete-btn']}
                                        type="button"
                                        aria-label="Delete"
                                        data-tip="Delete Crate"
                                        onClick={handleTrashPress}
                                        onKeyDown={(e) => (e.key === 'enter' ? handleTrashPress : undefined)}
                                    >
                                        <DeleteIcon />
                                    </button>
                                </>
                            ),
                        }}
                    </MediaDetailBanner>
                )}

                {tracks && (
                    <TrackListSupreme
                        list={tracks}
                        isSortable
                        onSort={(type) => setSortType(type as LocalSortingKeys)}
                        selectedSortType={sortType}
                        {...selectableProps()}
                        {...(isDownload
                            ? {
                                preset: TrackListPresetSupreme.OnlineCrate,
                                onDownloadRevalidate: (media) => {
                                      handlePostDownloadActions([media.id]);
                                },
                            }
                            : {
                                preset: TrackListPresetSupreme.OfflineCrate,
                            })}
                    />
                )}
            </div>
        </>
    );
}

export default CrateDetail;
