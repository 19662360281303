import { useFollowedArtists } from '@bpm-web-app/swr-hooks';
import { Artist } from '@bpm-web-app/api-client';
import { useEffect, useState } from 'react';
import { useApiErrorHandler, showToast, Analytics } from '@bpm-web-app/utils';

export const useFollowArtist = (artistId: number) => {
    const { data: followedArtists, isLoading, mutate: mutateFollowedArtists } = useFollowedArtists();
    const errorHandler = useApiErrorHandler();

    const [isArtistFollowed, setIsArtistFollowed] = useState<boolean>();
    useEffect(() => {
        const isFav = followedArtists?.data.some(({ id }) => id === artistId);
        setIsArtistFollowed(isFav);
    }, [followedArtists, artistId]);

    const addToFavorites = async () => {
        setIsArtistFollowed(true);
        try {
            mutateFollowedArtists(await Artist.followArtist(+artistId));
            Analytics.trackClick('add_artist_to_followed', artistId.toString());
            showToast({ type: 'success', message: 'Artist followed.' });
        } catch (error) {
            setIsArtistFollowed(false);
            errorHandler({ error });
        }
    };

    const removeFromFavorites = async () => {
        setIsArtistFollowed(false);
        try {
            mutateFollowedArtists(await Artist.unfollowArtist(+artistId));
            showToast({ type: 'success', message: 'Artist unfollowed.' });
        } catch (error) {
            setIsArtistFollowed(true);
            errorHandler({ error });
        }
    };

    return {
        followedArtists,
        isLoading,
        followArtist: addToFavorites,
        unfollowArtist: removeFromFavorites,
        isArtistFollowed,
    };
};

export default useFollowArtist;
