import { FC, useContext, useEffect } from 'react';
import classNames from 'classnames';

import CloseNav from '../../../assets/icons/close-nav.svg';
import { NavContext } from '../nav.context';
import styles from './nav-toggle-button.module.css';

interface Props {
    onCloseNav?: () => void;
}
export const NavToggleButton: FC<Props> = ({ onCloseNav }) => {
    const { isNavOpen, openNav, closeNav, isNavHidden } = useContext(NavContext);

    useEffect(() => {
        document.body.classList[!isNavOpen ? 'add' : 'remove']('nav-closed');
        document.body.classList[isNavHidden ? 'add' : 'remove']('nav-hidden');
    }, [isNavOpen, isNavHidden]);

    return (
        <button
            type="button"
            onClick={() => {
                if (isNavOpen) {
                    closeNav();

                    if (typeof onCloseNav === 'function') {
                        onCloseNav();
                    }
                } else {
                    openNav();
                }
            }}
            className={classNames(styles['nav-toggle'], { [styles['nav-toggle--closed']]: !isNavOpen })}
        >
            <div className={classNames(styles['nav-toggle__wrapper'])}>
                <CloseNav />
            </div>
        </button>
    );
};
