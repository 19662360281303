import { FC } from 'react';
import classNames from 'classnames';

import PlaylistIcon from './icon-playlist.svg';
import styles from './my-playlist-checkboxes.module.css';

export const MyPlaylistsLoading: FC = () => (
    <li className={styles['my-playlists__list']}>
        {[...Array(10)].map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={`placeholder-${index}`} className={styles['my-playlists-item']}>
                <span className={classNames(styles['my-playlists-item__wrapper'], styles['my-playlists-item__wrapper--disabled'])}>
                    <PlaylistIcon className={styles['my-playlists-item__icon']} />
                    <span className={styles['my-playlists-item__label']}>
                        <span className={classNames(styles['loading-wrapper'], styles['loading-wrapper--block'])}>Loading playlist name…</span>
                    </span>
                </span>
            </li>
        ))}
    </li>
);
