export const getInitialsFromName = (title: string): string => {
    if (!title) return '';

    const titleInitialsArray = title.split(' ').map((c) => c.charAt(0).toUpperCase());

    const numWords = titleInitialsArray.length;

    if (numWords > 3) {
        return `${titleInitialsArray[0]}${titleInitialsArray[1]}${titleInitialsArray[titleInitialsArray.length - 1]}`;
    }
    if (numWords === 1) {
        return `${title.charAt(0)}${title.charAt(1)}`.toUpperCase();
    }
    return titleInitialsArray.map((word) => word[0]).join('');

    return '? ?';
};
