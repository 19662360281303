import React, { forwardRef, useCallback, useRef, useState } from 'react';
import { Pagination } from '@bpm-web-app/download-api-sdk';
import classNames from 'classnames';
import styles from './paging.module.css';
import ChevronLeft from '../../../assets/icons/chevron-left.svg';
import ChevronRight from '../../../assets/icons/chevron-right.svg';
import Dropdown from '../../dropdown/dropdown';

interface PagingComponentProps {
    pagination: Pagination;
    onPageChange: (pageNumber: number) => void;
    onLimitChange: (limit: number) => void;
    inverted?: boolean;
    hasPerPageDropdown?: boolean;
}

const PER_PAGE_OPTIONS = [{ label: '25', value: 25 }, { label: '50', value: 50 }, { label: '100', value: 100 }];

const Paging = forwardRef<HTMLDivElement, PagingComponentProps>(({ pagination, onPageChange, onLimitChange, inverted, hasPerPageDropdown }, ref) => {
    const totalPagesCount = Math.ceil(pagination.total / pagination.limited);
    const arrayOfPages = Array.from(Array(totalPagesCount).keys()).map((val) => ({ value: val + 1, label: `${val + 1}` }));

    return (
        <div className={styles['paging__container']} ref={ref}>
            <div className={styles['paging__container--left']}>
                <button
                    type="button"
                    aria-label="Previous Page"
                    className={classNames(styles['paging__arrow-btn'], styles['paging__prev-btn'])}
                    disabled={pagination.page <= 1}
                    onClick={() => onPageChange(pagination.page - 1)}
                >
                    <ChevronLeft />
                </button>
                <Dropdown
                    layoutType="border-input"
                    labelAdditionalText={`of ${totalPagesCount}`}
                    value={pagination.page}
                    containerClassname={styles['paging__dropdown']}
                    optionClassname={styles['paging__dropdown-option']}
                    optionContainerClassname={styles['paging__dropdown--container']}
                    buttonClassname={styles['paging__dropdown--button']}
                    inverted={inverted}
                    options={arrayOfPages}
                    onClick={onPageChange} />
                <button
                    type="button"
                    aria-label="Next Page"
                    className={classNames(styles['paging__arrow-btn'], styles['paging__next-btn'])}
                    disabled={pagination.total <= (pagination.page * pagination.limited)}
                    onClick={() => onPageChange(pagination.page + 1)}
                >
                    <ChevronRight />
                </button>
            </div>
            {hasPerPageDropdown ? (
                <div className={styles['paging__container--right']}>
                    <Dropdown
                        layoutType="border-input"
                        labelAdditionalText="Per Page"
                        value={pagination.limited}
                        containerClassname={classNames(styles['paging__dropdown'], styles['paging__dropdown--limit'])}
                        optionClassname={styles['paging__dropdown-option']}
                        optionContainerClassname={styles['paging__dropdown--container']}
                        buttonClassname={styles['paging__dropdown--button']}
                        inverted={inverted}
                        options={PER_PAGE_OPTIONS}
                        onClick={onLimitChange} />
                </div>
            ) : null}
        </div>
    );
});

// eslint-disable-next-line max-len
export function usePagination(hasPerPageDropdownBottom = true): { limit: number, page: number, bottomComponent?: JSX.Element, setPagination: (pagination?: Pagination) => void } {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(50);
    const [intPagination, setIntPagination] = useState<Pagination | undefined>();

    const setPagination = useCallback((pagination?: Pagination) => {
        if (pagination) {
            if (pagination.total < pagination.skipped) {
                setPage(1);
            }
            setIntPagination(pagination);
        }
    }, []);

    if (intPagination) {
        const bottom = <Paging
            inverted
            pagination={intPagination}
            onLimitChange={setLimit}
            onPageChange={(p) => {
                setPage(p);
                window.scrollTo({
                    top: 0,
                    left: 0
                });
            }}
            hasPerPageDropdown={!!hasPerPageDropdownBottom} />;

        return { limit, page, bottomComponent: bottom, setPagination };
    }

    return { limit, page, setPagination };
}

export default Paging;
