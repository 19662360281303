import 'rc-slider/assets/index.css';
import RCSlider from 'rc-slider';
import styles from './slider.module.css';

const { createSliderWithTooltip } = RCSlider;
const Range = createSliderWithTooltip(RCSlider.Range);

/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
export interface SliderProps {
    range: [number, number];
    value: [number, number];
    onChange: (range: [number, number]) => void;
}

export function Slider({ range, value = [0, 100], onChange }: SliderProps) {
    return (
        /* eslint-disable-next-line @typescript-eslint/dot-notation */
        <div className={styles['slider']}>
            <Range
                min={range[0]}
                max={range[1]}
                onChange={(newValue) => {
                    onChange(newValue as [number, number]);
                }}
                value={value}
                allowCross={false}
                defaultValue={value}
                tipFormatter={(tooltipValue) => tooltipValue}
            />
            <div className={styles['slider__ranges']}>
                <div className={styles['slider__range']}>Min {value[0]} </div>
                <div className={styles['slider__range']}>{value[1]} Max</div>
            </div>
        </div>
    );
}

export default Slider;
