import { Crate } from '@bpm-web-app/api-client';
import { OnlinecrateBatchBody } from '@bpm-web-app/download-api-sdk';
import { OfflinecrateBatchBody } from '@bpm-web-app/stream-api-sdk';
import { offlineCrateSWRKey, onlineCrateSWRKey, useOnlineCrate } from '@bpm-web-app/swr-hooks';
import { Analytics, useApiErrorHandler, useHubSwitch, showToast } from '@bpm-web-app/utils';
import { useMemo } from 'react';
import { useSWRConfig } from 'swr';

export const useCrates = () => {
    const { mutate } = useSWRConfig();
    const errorHandler = useApiErrorHandler();
    const { isDownload, isCreate } = useHubSwitch();
    const { data: onlineCrateData } = useOnlineCrate(undefined, !isDownload || isCreate);
    const crateData = useMemo(() => onlineCrateData?.data.map((item) => item.id) || [], [onlineCrateData]);

    const isMediaInCrate = (mediaId: number): boolean => {
        if (crateData.includes(mediaId)) {
            return true;
        }
        return false;
    };

    const mutateOnlineCrate = () => {
        mutate(onlineCrateSWRKey(undefined));
        mutate(onlineCrateSWRKey('music'));
        mutate(onlineCrateSWRKey('latin'));
        mutate(onlineCrateSWRKey('artist'));
    };

    const mutateOfflineCrate = () => {
        mutate(offlineCrateSWRKey(undefined));
        mutate(offlineCrateSWRKey('music'));
        mutate(offlineCrateSWRKey('latin'));
        mutate(offlineCrateSWRKey('artist'));
    };

    const addMediaToOnlineCrate = async (mediaId: number) => {
        try {
            await Crate.addMediaToOnlineCrate(mediaId);
            showToast({ type: 'success', message: 'Added to crate.', buttonText: 'Go To Crate', replaceRouteOnButtonClick: '/crate' });
            mutateOnlineCrate();
            Analytics.trackClick('add_to_crate_media', `${mediaId}`);
        } catch (error) {
            errorHandler({ error });
        }
    };

    const addMultipleMediaToOnlineCrate = async (body: OnlinecrateBatchBody) => {
        try {
            await Crate.addMultipleMediaToOnlineCrate(body);
            showToast({ type: 'success', title: `${body?.media_ids?.length ?? ''} tracks added to crate.`, buttonText: 'Go To Crate', replaceRouteOnButtonClick: '/crate' });
            mutateOnlineCrate();
            body.media_ids.forEach((id) => Analytics.trackClick('add_to_crate_media', `${id}`));
        } catch (error) {
            errorHandler({ error });
        }
    };

    const addMediaToOfflineCrate = async (mediaId: number) => {
        try {
            await Crate.addMediaToOfflineCrate(mediaId);
            showToast({ type: 'success', message: 'Added to crate.', buttonText: 'Go To Crate', replaceRouteOnButtonClick: '/crate' });
            mutateOfflineCrate();
            Analytics.trackClick('add_to_crate_media', `${mediaId}`);
        } catch (error) {
            errorHandler({ error });
        }
    };

    const addMultipleMediaToOfflineCrate = async (body: OfflinecrateBatchBody) => {
        try {
            await Crate.addMultipleMediaToOfflineCrate(body);
            showToast({ type: 'success', title: `${body?.media_ids?.length ?? ''} tracks added to crate`, buttonText: 'Go To Crate', replaceRouteOnButtonClick: '/crate' });
            mutateOfflineCrate();
            body.media_ids.forEach((id) => Analytics.trackClick('add_to_crate_media', `${id}`));
        } catch (error) {
            errorHandler({ error });
        }
    };

    const removeFromOfflineCrate = async (id: number) => {
        try {
            await Crate.removeFromOfflineCrate(id);
            mutateOfflineCrate();
            showToast({ type: 'success', message: 'Removed from crate.', buttonText: 'Go To Crate', replaceRouteOnButtonClick: '/crate' });
            Analytics.trackClick('remove_from_crate_media', `${id}`);
        } catch (error) {
            errorHandler({ error, message: 'Failed to remove track from crate' });
        }
    };

    const removeFromOnlineCrate = async (id: number) => {
        try {
            await Crate.removeFromOnlineCrate(id);
            mutateOnlineCrate();
            showToast({ type: 'success', message: 'Removed from crate.', buttonText: 'Go To Crate', replaceRouteOnButtonClick: '/crate' });
            Analytics.trackClick('remove_from_crate_media', `${id}`);
        } catch (error) {
            errorHandler({ error, message: 'Failed to remove track from crate' });
        }
    };

    const batchRemoveFromOfflineCrate = async (ids: number[]) => {
        try {
            await Crate.removeMultipleMediaFromOfflineCrate(ids);
            mutateOfflineCrate();
            ids.forEach((id) => Analytics.trackClick('remove_from_crate_media', `${id}`));
            showToast({ type: 'success', message: 'Removed from crate.' });
        } catch (error) {
            errorHandler({ error, message: 'Failed to remove tracks from crate' });
        }
    };

    const batchRemoveFromOnlineCrate = async (ids: number[]) => {
        try {
            await Crate.removeMultipleMediaFromOnlineCrate(ids);
            mutateOnlineCrate();
            ids.forEach((id) => Analytics.trackClick('remove_from_crate_media', `${id}`));
            showToast({ type: 'success', message: 'Removed from crate.' });
        } catch (error) {
            errorHandler({ error, message: 'Failed to remove tracks from crate' });
        }
    };

    return {
        isMediaInCrate,
        addMediaToOnlineCrate,
        addMultipleMediaToOnlineCrate,
        addMediaToOfflineCrate,
        addMultipleMediaToOfflineCrate,
        removeFromOfflineCrate,
        removeFromOnlineCrate,
        batchRemoveFromOfflineCrate,
        batchRemoveFromOnlineCrate,
    };
};
