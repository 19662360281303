import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDownloadMedia } from '@bpm-web-app/swr-hooks';
import { State, fileDownload, useApiErrorHandler, usePlayerState, useUserSettings } from '@bpm-web-app/utils';
import PlayIcon from '../../assets/icons/player-play.svg';
import PauseIcon from '../../assets/icons/player-pause.svg';
import DownloadIcon from '../../assets/icons/download.svg';
import { useLibraryTabs } from '../../../../utils/src/lib/library-tabs.context';

import styles from './versions-dropdown.module.css';
import { ThreeDotsButton } from '../shared/three-dots-button/three-dots-button';
import { usePlayer } from '../player-context';

export interface VersionDropdownProps<Value> {
    value?: Value;
    onClick?: (value: Value) => void;
    openThreeDotsMenu?: (value: number, left: number, top: number) => void;
    options?: Array<{
        versionIndex: Value;
        versionName: string;
        versionDuration: string;
        versionId: number;
    }>;
    flipOptionsContainer?: boolean;
    layoutType?: 'maxi' | 'mini';
    placeholder?: string;
    // TODO: refactor this to be dynamic taking into account surrouding element
    openDirection?: 'upward' | 'downward';
    currentVersion?: string;
}

export function VersionsDropdown<Value = string>({ value, onClick, openThreeDotsMenu, options, flipOptionsContainer = false, placeholder, layoutType = 'maxi', openDirection = 'downward', currentVersion }: VersionDropdownProps<Value>) {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownContainer = useRef<HTMLDivElement>(null);
    const { libraryProperty } = useLibraryTabs();
    const errorHandler = useApiErrorHandler();
    const playerState = usePlayerState();
    const { togglePlayPause } = usePlayer();

    const { downloadSingleMedia } = useDownloadMedia(libraryProperty, false);

    const { isAnonymous, setShowSignUpModal } = useUserSettings();

    useEffect(() => {
        const handleDocClick = (e: MouseEvent) => {
            if (!dropdownContainer?.current || !dropdownContainer) return;
            if (isOpen && dropdownContainer.current !== e.target && !dropdownContainer.current.contains(e.target as Node)) {
                e.preventDefault();
                setIsOpen(false);
            }
        };

        document.addEventListener('click', handleDocClick);
        return () => {
            document.removeEventListener('click', handleDocClick);
        };
    }, [dropdownContainer, isOpen]);

    const handleClick = (option: {
        versionIndex: Value;
        versionName: string;
        versionDuration: string;
        versionId: number;
    }) => {
        if (playerState === State.Playing && currentVersion === option.versionName) togglePlayPause();
        else if (onClick) onClick(option.versionIndex);
    };

    const handleMenuOpen = (versionId: number, element: EventTarget) => {
        const { top: topPos, left: leftPos } = (element as HTMLButtonElement).getBoundingClientRect();
        openThreeDotsMenu?.(versionId, leftPos, topPos + window.scrollY - 100);
    };

    const handleDownloadVersion = useCallback(
        async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) => {
            e.stopPropagation();
            if (isAnonymous) {
                setShowSignUpModal({ type: 'track' });
            }
            try {
                const { data: downloadMediaUrlData } = await downloadSingleMedia(id);

                if (downloadMediaUrlData?.url) {
                    fileDownload(downloadMediaUrlData.url);
                }
            } catch (error) {
                errorHandler({ error });
            }
        },
        [downloadSingleMedia, errorHandler, isAnonymous, setShowSignUpModal]
    );

    return (
        /* eslint-disable-next-line @typescript-eslint/dot-notation */
        <div className={styles['dropdown']} ref={dropdownContainer}>
            <button
                type="button"
                className={classNames(styles['dropdown__button'], styles[`dropdown__button--${layoutType}`])}
                onClick={(e) => {
                    e.stopPropagation();
                    setIsOpen(!isOpen);
                }}
            >
                {currentVersion}
            </button>
            <ul
                className={classNames(styles['dropdown__options'], styles[`dropdown__options--open-${openDirection}`], {
                    [styles['dropdown__options--open']]: isOpen
                }, styles[`dropdown__options${flipOptionsContainer ? '--left-side' : '--right-side'}`])}
            >
                {options?.map((option) => (
                    <li key={option.versionIndex.toString()} className={styles['dropdown__option']}>
                        <button
                            type="button"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleClick(option);
                            }}
                            className={styles['dropdown__option-btn']}
                        >
                            {currentVersion === option.versionName && playerState === State.Playing ? <PauseIcon /> : <PlayIcon />}
                            <span className={styles['dropdown__option-btn-label']}>{option.versionName}</span>
                            <span className={styles['dropdown__option-duration']}>{option.versionDuration}</span>
                        </button>

                        <button className={styles['dropdown__option-menu']} aria-label={`Download ${option.versionName}`} type="button" onClick={(e) => handleDownloadVersion(e, option.versionId)}>
                            <DownloadIcon />
                        </button>
                        <ThreeDotsButton onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleMenuOpen(option.versionId, e.target);
                        }} />
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default VersionsDropdown;
