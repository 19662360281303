import React, { ReactElement, useRef, useState } from 'react';
import classNames from 'classnames';
import styles from './generic-carousel.module.css';
import ChevronLeft from '../../../../assets/icons/chevron-left.svg';
import ChevronRight from '../../../../assets/icons/chevron-right.svg';

export interface GenericCarouselProps {
    children: ReactElement[];
    title: string;
    cssMode?: boolean;
    noPadding?: boolean;
}

export function GenericCarousel({ children, title, cssMode, noPadding = false }: GenericCarouselProps) {
    const cardListRef = useRef() as React.MutableRefObject<HTMLInputElement>;

    const [isAtTheStart, setIsAtTheStart] = useState(true);
    const [isAtTheEnd, setIsAtTheEnd] = useState(false);

    const onScroll = () => {
        if (cardListRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = cardListRef.current;

            if (scrollLeft + clientWidth === scrollWidth) {
                setIsAtTheEnd(true);
                setIsAtTheStart(false);
            } else if (scrollLeft <= 1) {
                setIsAtTheEnd(false);
                setIsAtTheStart(true);
            } else {
                setIsAtTheEnd(false);
                setIsAtTheStart(false);
            }
        }
    };

    function handleScroll(left = false) {
        if (left) cardListRef.current.scrollBy({ left: 700, behavior: 'smooth' });
        else cardListRef.current.scrollBy({ left: -700, behavior: 'smooth' });
    }

    return (
        <div className={styles['generic-carousel']}>
            <div className={styles['generic-carousel__title__container']}>
                <h2 className={styles['generic-carousel__title']}>{title}</h2>
                <button type="button" aria-label="Previous Page" className={classNames(styles['generic-carousel__arrow-btn'], styles['generic-carousel__prev-btn'], { [styles['card-carousel__arrow-btn__change-color']]: isAtTheStart, [styles['card-carousel__arrow-btn__default']]: !isAtTheStart })} onClick={() => handleScroll()}>
                    <ChevronLeft />
                </button>
                <button type="button" aria-label="Next Page" className={classNames(styles['generic-carousel__arrow-btn'], styles['generic-carousel__next-btn'], { [styles['card-carousel__arrow-btn__change-color']]: isAtTheEnd, [styles['card-carousel__arrow-btn__default']]: !isAtTheEnd })} onClick={() => handleScroll(true)}>
                    <ChevronRight />
                </button>
            </div>
            <div className={classNames(styles['generic-carousel__card-list'], noPadding ? '' : 'spacing--left-first-child')} ref={cardListRef} onScroll={onScroll}>
                {React.Children.map(children, (child) => (
                    <div key={child.props.id}>{child}</div>
                ))}
            </div>
        </div>
    );
}

export default GenericCarousel;
