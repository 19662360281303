import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { showToast, QueueItem, useHubSwitch } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { TrackList, TrackListPreset, TrackListSelectableProps } from '../shared/track-list/track-list';
import styles from './player-queue.module.css';
import { usePlayer } from '../player-context';
import PencilIcon from '../../assets/icons/pencil.svg';
import ClearIcon from '../../assets/icons/clear.svg';
import AddToPlaylist from '../../assets/icons/add-to-playlist.svg';
import FavoriteHeart from '../../assets/icons/favorite-heart.svg';
import Delete from '../../assets/icons/delete.svg';
import { useFavoriteMultipleMedia } from '../shared/three-dots-sheet/useFavoriteMultipleMedia';
import { LibraryTabsContext } from '../../../../utils/src/lib/library-tabs.context';
import { ThreeDotsSheetContext } from '../shared/three-dots-sheet/three-dots-sheet.context';

/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
export interface PlayerQueueProps { }

export function PlayerQueue(props: PlayerQueueProps) {
    const { currentQueue, removeFromQueue, currentTrackIndex, repeat, currentTrack, moveTrackPositionInQueue } = usePlayer();
    const [selectedItemsIds, setSelectedItemsIds] = useState<QueueItem['uuid'][]>([]);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const { isDownload } = useHubSwitch();
    const { libraryProperty } = useContext(LibraryTabsContext);
    const { openThreeDotsModalSheet } = useContext(ThreeDotsSheetContext);

    const { addMultipleToFavorites } = useFavoriteMultipleMedia(libraryProperty);

    const handleSelectItem = useCallback((uuid: QueueItem['uuid']) => {
        setSelectedItemsIds((prevState) => {
            if (prevState.includes(uuid)) {
                return prevState.filter((selectedUuid) => selectedUuid !== uuid);
            }
            return [...prevState, uuid];
        });
    }, []);

    const upNextQueue = useMemo(() => {
        if (currentTrackIndex === currentQueue.length - 1 && repeat === 'all') {
            return currentQueue.filter((media) => media.uuid !== currentTrack.uuid);
        }

        return currentQueue.filter((media) => media.uuid !== currentTrack.uuid).splice(currentTrackIndex);
    }, [currentTrackIndex, currentQueue, repeat, currentTrack]);

    const handleToggleAll = useCallback(
        (all: boolean) => {
            if (upNextQueue.length) {
                setSelectedItemsIds(() => (all ? [...upNextQueue.map((track) => track.uuid)] : []));
            }
        },
        [upNextQueue]
    );

    const toggleEditMode = useCallback(() => {
        setIsEditMode(!isEditMode);
    }, [isEditMode]);

    const selectableProps = useMemo(() => {
        if (isEditMode) {
            return {
                onSelectItem: handleSelectItem,
                selectedItemsIds,
                onSelectAll: handleToggleAll,
            } as Pick<TrackListSelectableProps, 'onSelectItem' | 'selectedItemsIds' | 'onSelectAll'>;
        }
        return {};
    }, [handleSelectItem, handleToggleAll, isEditMode, selectedItemsIds]);

    const handleRemoveFromQueue = useCallback(() => {
        if (!selectedItemsIds.length) {
            showToast({ type: 'error', message: 'Please select one or more tracks.' });
            return;
        }

        selectedItemsIds.forEach((uuid) => removeFromQueue(uuid));
        showToast({ type: 'success', message: 'Removed from queue.' });
        setSelectedItemsIds([]);
    }, [removeFromQueue, selectedItemsIds]);

    const openAddToPlaylist = useCallback(
        async (element: EventTarget) => {
            if (!selectedItemsIds.length) {
                showToast({ type: 'error', message: 'Please select one or more tracks.' });
                return;
            }

            const { top: topPosition, left: leftPosition } = (element as HTMLButtonElement).getBoundingClientRect();
            const selectedMediaIds = selectedItemsIds
                .map((uuid) => currentQueue.find((item) => item.uuid === uuid))
                .filter((item) => item !== undefined)
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                .map((item) => item!.id);
            /* Infinity actionTypeId is just to indicate that it doesn't matter and won't be used,
             * because adding to a playlist mode is not directly a 3-dot menu */
            openThreeDotsModalSheet('user-playlist', Infinity, leftPosition, topPosition, true, null, selectedMediaIds);
        },
        [currentQueue, openThreeDotsModalSheet, selectedItemsIds]
    );

    const handleFavoriteMultipleMedia = useCallback(async () => {
        if (!selectedItemsIds.length) {
            showToast({ type: 'error', message: 'Please select one or more tracks.' });
            return;
        }

        const selectedMediaIds = selectedItemsIds
            .map((uuid) => upNextQueue.find((item) => item.uuid === uuid))
            .filter((item) => item !== undefined)
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .map((item) => (isDownload ? item!.album_id : item!.id))
            .filter((id) => id !== undefined);
        await addMultipleToFavorites(selectedMediaIds as number[]);
        showToast({ type: 'success', message: 'Added to favorites.', buttonText: 'Go To Favorites', replaceRouteOnButtonClick: '/favorites' });
        setSelectedItemsIds([]);
    }, [addMultipleToFavorites, upNextQueue, isDownload, selectedItemsIds]);

    useEffect(() => {
        const visibleTrackUuids = upNextQueue.map((item) => item.uuid);
        setSelectedItemsIds((prevState) => prevState.filter((uuid) => visibleTrackUuids.includes(uuid)));
    }, [upNextQueue]);

    return (
        <div className={styles['player-queue']}>
            <div className={styles['player-queue__controls-container']}>
                <h3 className={styles['player-queue__title']}>Up Next</h3>
                <button
                    type="button"
                    onClick={() => toggleEditMode()}
                    aria-label="Edit queue"
                    className={classNames(styles['player-queue__edit-btn'], {
                        [styles['player-queue__edit-mode-open']]: isEditMode
                    })}>
                    {isEditMode ? <ClearIcon /> : <PencilIcon />}
                </button>
                {isEditMode && (
                    <div className={styles['player-queue__edit-mode-actions']}>
                        {!isDownload && (
                            <button type="button" onClick={(event) => openAddToPlaylist(event.target)} aria-label="Add to playlist">
                                <AddToPlaylist />
                            </button>
                        )}
                        <button type="button" onClick={handleFavoriteMultipleMedia} aria-label="Add to favorites">
                            <FavoriteHeart />
                        </button>
                        <button type="button" onClick={handleRemoveFromQueue} aria-label="Delete">
                            <Delete />
                        </button>
                    </div>
                )}
            </div>
            <div className={styles['player-queue__list']}>
                <TrackList
                    preset={TrackListPreset.Queue}
                    list={upNextQueue}
                    isDraggable
                    {...selectableProps}
                    onMove={(id, prevIndex, index) => {
                        moveTrackPositionInQueue(prevIndex, index);
                    }} />
            </div>
        </div>
    );
}

export default PlayerQueue;
