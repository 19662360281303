import { useSearchAlbum, useSearchMedia } from '@bpm-web-app/swr-hooks';
import { CreateCardCarouselItem, useHubSwitch, useSupremeFilterParams, useUserSettings, useViewport } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { AppLink } from '../../shared/app-link/app-link';
import { usePagination } from '../../shared/paging/paging';
import { SecondaryPageTitle } from '../../shared/secondary-page-title/secondary-page-title';
import SeeMore from '../../shared/see-more-button/see-more-btn';
import { TrackListSupreme } from '../../shared/track-list/supreme/track-list-supreme';
import { TrackListPresetSupreme } from '../../shared/track-list/supreme/track-list-supreme-helpers';
import { TrackListFilters } from '../../shared/track-list/track-list-filters/track-list-filters';
import { NoResultsBlock } from '../../shared/ui/no-results-block/no-results-block';
import { APISortingKeys, apiSortOptionsWithRelevance, useApiSort } from '../../sort-options-sheet/sort-options-sheet';
import styles from './search-songs.module.css';

export interface SearchSongsProps {
    indexPage?: boolean;
    onCount: (count: number) => void;
    hasNoResults?: boolean;
    isLoadingData?: boolean;
    setIsLoadingData?: (isLoading: boolean) => void;
}

export function SearchSongs({ indexPage, onCount, hasNoResults, isLoadingData, setIsLoadingData }: SearchSongsProps) {
    const router = useRouter();
    const { isDownload } = useHubSwitch();
    const { isMobile } = useViewport();
    const apiSortHandler = useApiSort();
    const { sortBy } = router.query;

    const { limit, page, bottomComponent, setPagination } = usePagination();
    const query = useSupremeFilterParams(true, { limit: indexPage ? limit : 10, skip: (page - 1) * limit });
    const seeMoreLimit = indexPage ? 25 : 10;

    const { data, mutate, isLoading } = isDownload ? useSearchAlbum(query) : useSearchMedia(query);

    const { isAnonymous, setSelectedMedia } = useUserSettings();

    const setCurrentMediaInContext = useCallback(() => {
        if (isAnonymous && data?.data) {
            const currentCenterMedia = data.data[0];
            if (currentCenterMedia) setSelectedMedia(currentCenterMedia as unknown as CreateCardCarouselItem, data as unknown as CreateCardCarouselItem[]);
        }
    }, [isAnonymous, data, setSelectedMedia]);

    useEffect(() => {
        if (isAnonymous) {
            setCurrentMediaInContext();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAnonymous]);

    useEffect(() => {
        setPagination(data?.pagination);
        onCount(data?.pagination.total || 0);
    }, [data, onCount, setPagination]);

    useEffect(() => {
        if (setIsLoadingData) setIsLoadingData(isLoading);
    }, [isLoading, setIsLoadingData]);

    const songs = data?.data;
    const flatMediaList = songs ? [].concat(...(songs as any)) : [];

    return (
        /* eslint-disable-next-line @typescript-eslint/dot-notation */
        <div className={styles['search-songs']}>
            {(!indexPage || !isMobile || (indexPage && isMobile && flatMediaList?.length === 0)) && (
                <div className={styles['search-songs__intro-section']}>
                    <SecondaryPageTitle title="Songs" counter={data?.pagination?.total?.toString()} />
                </div>
            )}
            {!isMobile && indexPage ? <div className={classNames('spacing__window--horizontal', 'spacing--top')}><TrackListFilters dynamicActiveTabColor showActiveFilters /></div> : null}
            <div className={classNames(styles['search-songs__track-list-container'], 'spacing__window--horizontal', 'spacing--top')}>
                <TrackListSupreme
                    resultsCount={data?.pagination.total}
                    isLoading={isLoadingData}
                    isUpdatingList={isLoading}
                    hasFilters={!indexPage}
                    showActiveFilters={!indexPage}
                    preset={isDownload ? TrackListPresetSupreme.Download : TrackListPresetSupreme.Stream}
                    list={flatMediaList}
                    isSortable
                    hasColumnSorting
                    sortOptions={apiSortOptionsWithRelevance}
                    onSort={(nextSort) => apiSortHandler(nextSort as APISortingKeys)}
                    selectedSortType={(sortBy as APISortingKeys) || 'trending'}
                    indexOffset={(page - 1) * limit}
                    onDownloadRevalidate={(downloadMedia) => {
                        if (downloadMedia) {
                            // eslint-disable-next-line no-param-reassign
                            downloadMedia.download_count += 1;
                            /* for some reason, mutate of useSWRInfinite handles opts differently
                            * than a normal useSWR. See a reported bug for more info:
                            * https://github.com/vercel/swr/issues/1899 */
                            mutate(data as any, false);
                        }
                    }}
                    onFilterApplied={(filters) => {
                        if (filters.bpm) {
                            router.replace({
                                pathname: router.pathname,
                                query: {
                                    ...router.query,
                                    bpm: [filters.bpm, filters.bpm]
                                },
                            });
                        }
                        if (filters.genre) {
                            router.replace({
                                pathname: router.pathname,
                                query: {
                                    ...router.query,
                                    genre: [filters.genre]
                                },
                            });
                        }
                        if (filters.key) {
                            router.replace({
                                pathname: router.pathname,
                                query: {
                                    ...router.query,
                                    key: [filters.key]
                                },
                            });
                        }
                    }}
                />
                {data && data.pagination && data.pagination?.total > seeMoreLimit ?
                    (
                        !indexPage ? (
                            <AppLink
                                href={{
                                    pathname: router.pathname,
                                    query: {
                                        ...router.query,
                                        type: 'songs',
                                    },
                                }}
                                overrideAppLinkTransform
                            >
                                <div className={styles['search-songs__see-more']}><SeeMore expand={false} variant="text" /></div>
                            </AppLink>
                        ) : (
                            bottomComponent
                        )
                    ) : null
                }
            </div>
            {flatMediaList?.length === 0 && isLoading === false && <NoResultsBlock hasMargin>No Songs Available</NoResultsBlock>}
            <div className="spacing--bottom" />
        </div>
    );
}

export default SearchSongs;
