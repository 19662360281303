import { useEffect, useState } from 'react';
import FullscreenExit from '../../../assets/icons/fullscreen-exit.svg';
import Fullscreen from '../../../assets/icons/fullscreen.svg';
import { CustomIcon } from '../../shared/custom-icon/custom-icon';
import styles from './header-fullscreen-button.module.css';

/* istanbul ignore next */
const toggleFullscreen = () => {
    if (!(document.fullscreenEnabled || document.webkitFullscreenEnabled)) return;

    if (document.fullscreenElement || document.webkitFullscreenElement) {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        }
    } else {
        const docEl = document.documentElement;
        if (docEl.requestFullscreen) {
            docEl.requestFullscreen();
        } else if (docEl.webkitRequestFullscreen) {
            docEl.webkitRequestFullscreen();
        }
    }
};

export const HeaderFullscreenButton = () => {
    const [isFullscreen, setIsFullscreen] = useState(false);

    useEffect(() => {
        const handler = () => {
            setIsFullscreen(!isFullscreen);
        };

        window.addEventListener('fullscreenchange', handler);
        window.addEventListener('mozfullscreenchange', handler);
        window.addEventListener('webkitfullscreenchange', handler);
        window.addEventListener('msfullscreenchange', handler);

        return () => {
            window.removeEventListener('fullscreenchange', handler);
            window.removeEventListener('mozfullscreenchange', handler);
            window.removeEventListener('webkitfullscreenchange', handler);
            window.removeEventListener('msfullscreenchange', handler);
        };
    }, [isFullscreen]);

    return (
        <CustomIcon
            tooltip={isFullscreen ? 'Exit Full Screen' : 'Full Screen'}
            hasIconHover
            hasBackgroundHover
            size="regular"
            container="round"
            containerSize="regular"
            className={styles['header-fullscreen-button']}
            tooltipBottomOffset={-4}
            onClick={toggleFullscreen}
        >
            {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
        </CustomIcon>
    );
};
