import { ReactNode, createContext, useState, useMemo } from 'react';
import { useViewport } from '@bpm-web-app/utils';

export interface NavContextProperties {
    isNavOpen: boolean;
    isNavHidden: boolean;
    isSearchVisible: boolean;
    openNav: () => void;
    closeNav: () => void;
    /* on some pages the sidenav is not visible at all.
     * showNav and hideNav can be used to toggle that */
    showNav: () => void;
    hideNav: () => void;
    showSearchBar: () => void;
    hideSearchBar: () => void;
}

export const NavContext = createContext<NavContextProperties>({
    isNavOpen: false,
    isNavHidden: false,
    isSearchVisible: false,
    openNav: /* istanbul ignore next */ () => { },
    closeNav: /* istanbul ignore next */ () => { },
    showNav: /* istanbul ignore next */ () => { },
    hideNav: /* istanbul ignore next */ () => { },
    showSearchBar: /* istanbul ignore next */ () => { },
    hideSearchBar: /* istanbul ignore next */ () => { },
});

export interface NavProviderProps {
    children: ReactNode;
    initialState?: {
        isNavOpen: boolean;
        isNavHidden: boolean;
        isSearchVisible: boolean;
    };
}

export function NavProvider({ children, initialState }: NavProviderProps) {
    const { isMobile } = useViewport();

    /* by default, on desktop, the sidenav should be open. */
    const [isNavOpen, setIsNavOpen] = useState<boolean>(initialState?.isNavOpen || !isMobile);
    const [isNavHidden, setIsNavHidden] = useState<boolean>(initialState?.isNavHidden || false);
    const [isSearchVisible, setIsSearchVisible] = useState<boolean>(initialState?.isSearchVisible || false);

    const value = useMemo(
        () => ({
            isNavOpen,
            isNavHidden,
            isSearchVisible,
            openNav: () => {
                setIsNavOpen(true);
            },
            closeNav: () => {
                setIsNavOpen(false);
            },
            showNav: () => {
                setIsNavHidden(false);
                document.body.classList.remove('nav-hidden');
            },
            hideNav: () => {
                setIsNavHidden(true);
                document.body.classList.add('nav-hidden');
            },
            showSearchBar: () => {
                setIsSearchVisible(true);
            },
            hideSearchBar: () => {
                setIsSearchVisible(false);
            },
        }),
        [isNavOpen, isNavHidden, isSearchVisible]
    );
    return <NavContext.Provider value={value}>{children}</NavContext.Provider>;
}
