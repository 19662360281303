import { useOnClickOutside } from '@bpm-web-app/utils';
import { ReactNode, useMemo } from 'react';
import styles from './more-options-sheet.module.css';

type ContainerStyle = {
    top?: number;
    left?: number;
};

type Props = {
    children: ReactNode;
    leftPosition: number;
    topPosition: number;
    isVisible: boolean;
    setIsVisible: (visible: boolean) => void;
};

export function MoreOptionsSheet({ leftPosition, topPosition, children, isVisible: visible, setIsVisible: setVisible }: Props) {
    const containerStyle = useMemo(() => {
        const styleTemp: ContainerStyle = {};

        if (leftPosition !== null) {
            styleTemp.left = leftPosition;
        }
        if (topPosition !== null) {
            styleTemp.top = topPosition;
        }
        return styleTemp;
    }, [leftPosition, topPosition]);

    const { ref } = useOnClickOutside<HTMLDivElement>(visible, () => {
        setVisible(false);
    });

    if (!visible) return null;

    return (
        <div ref={ref} style={containerStyle} className={styles['more-options-menu__container']}>
            <div className={styles['more-options-menu__list']}>
                <ul>{children}</ul>
            </div>
        </div>
    );
}

export default MoreOptionsSheet;
