import { useCallback } from 'react';

import { useDownloadMedia } from '@bpm-web-app/swr-hooks';
import { fileDownload, useApiErrorHandler, useUserSettings } from '@bpm-web-app/utils';

import { useLibraryTabs } from 'libs/utils/src/lib/library-tabs.context';

import DownloadIcon from '../../../assets/icons/supreme/download.svg';
import styles from './mini-download-button.module.css';

export function MiniDownloadButton({ trackId }: { trackId: number }) {
    const { libraryProperty } = useLibraryTabs();
    const { downloadSingleMedia } = useDownloadMedia(libraryProperty, false);
    const { isAnonymous, setShowSignUpModal } = useUserSettings();
    const errorHandler = useApiErrorHandler();

    const handleDownloadVersion = useCallback(
        async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.stopPropagation();
            if (isAnonymous) {
                setShowSignUpModal({ type: 'track' });
            }
            try {
                const { data: downloadMediaUrlData } = await downloadSingleMedia(trackId);
                if (downloadMediaUrlData?.url) {
                    fileDownload(downloadMediaUrlData.url);
                }
            } catch (error) {
                errorHandler({ error });
            }
        },
        [downloadSingleMedia, errorHandler, isAnonymous, setShowSignUpModal, trackId]
    );
    return (
        <button onClick={handleDownloadVersion} className={styles['mini-download-button']} type="button" aria-label="Download" data-tip="Download" data-tip-show>
            <DownloadIcon />
        </button>
    );
}
