import { ChangeEvent } from 'react';
import styles from './switch.module.css';

/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
export interface SwitchProps {
    enabled: boolean;
    onChange: (enabled: boolean) => void;
    label: string;
}

export function Switch({ enabled, onChange, label }: SwitchProps) {
    const handleChange = (e: ChangeEvent) => {
        onChange((e.target as HTMLInputElement).checked);
    };

    return (
        /* eslint-disable-next-line @typescript-eslint/dot-notation */
        <label className={styles['switch']}>
            <div className={styles['switch__label']}>{label}</div>
            <input type="checkbox" checked={enabled} onChange={handleChange} className={styles['switch__input']} />
            <div className={styles['switch__slider']} />
        </label>
    );
}

export default Switch;
