import { AnimatePresence, motion, Variants } from 'framer-motion';
import classNames from 'classnames';

import { Album as DownloadAlbum, Media as DownloadMedia, MediaWithAlbum as DownloadMediaWithAlbum } from '@bpm-web-app/download-api-sdk';
import { MediaWithAlbum } from '@bpm-web-app/stream-api-sdk';
import { QueueItem, State, isDownloadLimitReached, useHubSwitch } from '@bpm-web-app/utils';
import AddToQueue from '../../../../assets/icons/add-to-queue.svg';
import AddToCrate from '../../../../assets/icons/add-to-crate.svg';
import RemoveFromCrate from '../../../../assets/icons/remove-from-crate.svg';
import DownloadIcon from '../../../../assets/icons/download.svg';
import RemoveFromQueue from '../../../../assets/icons/remove-track-from-queue.svg';
import ThreeDotsIcon from '../../../../assets/icons/supreme/three-dots.svg';
import { ActionType } from '../../three-dots-sheet/three-dots-sheet.context';
import VersionsColumn from '../columns/versions-column';
import { TitleColumnSupreme } from '../columns-supreme/title-column-supreme';
import styles from './download-accordion.module.css';
import { useCrates } from '../../three-dots-sheet/useCrates';
import { BpmColumn } from '../columns-supreme/bpm-column';
import { KeyColumn } from '../columns-supreme/key-column';
import { GenreColumn } from '../columns-supreme/genre-column';
import { TrackListPresetSupreme } from './track-list-supreme-helpers';

interface DownloadAccordionProps {
    media: MediaWithAlbum | DownloadMediaWithAlbum | DownloadAlbum | QueueItem;
    downloadAlbum: DownloadAlbum;
    hasPremiumOnlyAccess?: boolean;
    isPremiumOnly?: boolean;
    isOpen: boolean;
    isAnonymous: boolean;
    isMobile: boolean;
    openDownloadAccordions: number[];
    forMaxiPlayer?: boolean;
    currentTrack: QueueItem | null;
    playerState: State;
    onAccordionClick: () => void;
    playClick: (track: DownloadMedia, index: number) => () => void;
    setCurrentMediaInContext: (id: string, showSignUpModal?: boolean) => void;
    handleSingleMediaDownload: (downloadMedia: DownloadMedia) => void;
    addDownloadTrackToQueue: (downloadMedia: DownloadMedia, album: DownloadAlbum) => void;
    removeTrackFromQueue: (mediaId: string) => void;
    openThreeDotsModalSheet: (actionType: ActionType, mediaId: number, left: number, top: number, isMaxiPlayer: boolean) => void;
    isMediaInTheQueue: (mediaId: number) => string | undefined;
    setDraggingMediaId: (mediaId: number) => void;
    wideOffset?: boolean;
    preset: TrackListPresetSupreme
}

const accordionVariants: Variants = {
    collapsed: {
        height: 0
    },
    expanded: {
        height: 'auto'
    }
};

export function DownloadAccordion({
    downloadAlbum,
    media,
    hasPremiumOnlyAccess,
    isPremiumOnly,
    isOpen,
    isAnonymous,
    isMobile,
    openDownloadAccordions,
    forMaxiPlayer,
    currentTrack,
    playerState,
    onAccordionClick,
    setCurrentMediaInContext,
    handleSingleMediaDownload,
    addDownloadTrackToQueue,
    removeTrackFromQueue,
    openThreeDotsModalSheet,
    isMediaInTheQueue,
    playClick,
    setDraggingMediaId,
    wideOffset,
    preset
}: DownloadAccordionProps) {
    const { isDownload } = useHubSwitch();
    const { isMediaInCrate, addMediaToOfflineCrate, addMediaToOnlineCrate, removeFromOfflineCrate, removeFromOnlineCrate } = useCrates();

    const addToCrate = (track: DownloadMedia) => (isDownload ? addMediaToOnlineCrate(track.id) : addMediaToOfflineCrate(track.id));

    const removeFromCrate = (track: DownloadMedia) => (isDownload ? removeFromOnlineCrate(track.id) : removeFromOfflineCrate(track.id));

    const handleCrateClick = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>, track: DownloadMedia, isInCrate: boolean) => {
        evt.preventDefault();
        evt.stopPropagation();

        if (isAnonymous || (isPremiumOnly && !hasPremiumOnlyAccess)) {
            setCurrentMediaInContext(`${media.id}`, !hasPremiumOnlyAccess);
            return false;
        }

        if (isInCrate) {
            return removeFromCrate(track);
        }

        return addToCrate(track);
    };

    return (
        <AnimatePresence>
            {openDownloadAccordions.includes(+downloadAlbum.id) && (

                <motion.div
                    className={styles['download-accordion__container']}
                    variants={accordionVariants}
                    initial="collapsed"
                    exit="collapsed"
                    animate="expanded"
                    transition={{ duration: 0.3, type: 'tween', ease: [0.46, 0.03, 0.52, 0.96] }}
                >
                    {downloadAlbum.media?.map((track, index) => {
                        const mediaQueueItemId = isMediaInTheQueue(track.id);

                        return (
                            <div
                                draggable={!isMobile}
                                onDragStart={() => {
                                    setDraggingMediaId(track.id);
                                }}
                                key={track.id}
                                className={classNames(styles['download-accordion__item'], {
                                    [styles['download-accordion__item--maxi-player']]: forMaxiPlayer,
                                    [styles['download-accordion__item--wide-offset']]: wideOffset,
                                })}
                            >
                                <span />
                                {/* {preset === TrackListPreset.DownloadTrending && <span />} */}
                                <div className={classNames(styles['download-accordion__column'], styles['download-accordion__column--title'])}>
                                    <TitleColumnSupreme
                                        hasPremiumOnlyAccess={hasPremiumOnlyAccess}
                                        isPremiumOnly={isPremiumOnly}
                                        hasPlainPlayButton
                                        isPlaying={currentTrack?.id === track.id && playerState === State.Playing}
                                        playClick={playClick(track, index)}
                                        title={track.version.name}
                                        isExclusive={false}
                                        isVersionItem
                                    />
                                </div>
                                <div className={classNames(styles['download-accordion__column'], styles['download-accordion__column--bpm'])}>
                                    <BpmColumn bpm={downloadAlbum.bpm} />
                                </div>
                                <div className={classNames(styles['download-accordion__column'], styles['download-accordion__column--key'])}>
                                    <KeyColumn displayKey={downloadAlbum.display_key} />
                                </div>
                                <div className={classNames(styles['download-accordion__column'], styles['download-accordion__column--genre'])}>
                                    <GenreColumn genre={downloadAlbum.genre} />
                                </div>
                                <div className={classNames('hide-filters-open', styles['download-accordion__column'], styles['download-accordion__column--versions-content'])}>
                                    {isPremiumOnly === true && hasPremiumOnlyAccess === false ? (
                                        <VersionsColumn
                                            hasPremiumOnlyAccess={hasPremiumOnlyAccess}
                                            isPremiumOnly={isPremiumOnly}
                                            versionsList={downloadAlbum.media}
                                            onMoreVersionsClick={onAccordionClick}
                                            isAccordionOpen={isOpen}
                                            onVersionClick={(m) => handleSingleMediaDownload(m)}
                                            setCurrentMediaInContext={() => (setCurrentMediaInContext ? setCurrentMediaInContext(`${media.id}`, !hasPremiumOnlyAccess) : null)}
                                        />
                                    ) : (
                                        <>
                                            <button
                                                className={classNames(styles['download-accordion__download-btn'], { [styles['download-accordion__download-btn__downloaded']]: track.download_count })}
                                                onClick={() => handleSingleMediaDownload(track)}
                                                type="button"
                                                disabled={isDownloadLimitReached(track.download_count)}
                                            >
                                                <DownloadIcon />
                                            </button>
                                            <span className={classNames('hide-filters-open', styles['download-accordion__download-btn-text'])}>{track.download_count} of 3 Downloads</span>
                                        </>
                                    )}
                                </div>
                                <div className={classNames(styles['download-accordion__column'], styles['download-accordion__column--actions'])}>
                                    <button
                                        className={classNames(styles['download-accordion__action-btn'], styles['download-accordion__action-btn--desktop-only'], {
                                            [styles['download-accordion__action-btn-desktop-track-is-in-list']]: mediaQueueItemId,
                                        })}
                                        onClick={() => (!mediaQueueItemId ? addDownloadTrackToQueue(track, downloadAlbum) : removeTrackFromQueue(mediaQueueItemId))}
                                        data-tip={!mediaQueueItemId ? 'Add to Queue' : 'Remove from Queue'}
                                        type="button"
                                    >
                                        {!mediaQueueItemId ? <AddToQueue /> : <RemoveFromQueue />}
                                    </button>
                                    <button
                                        className={classNames(styles['download-accordion__action-btn'], styles['download-accordion__action-btn--desktop-only'], {
                                            [styles['download-accordion__action-btn-desktop-track-is-in-list']]: isMediaInCrate(track.id),
                                        })}
                                        onClick={(evt) => handleCrateClick(evt, track, isMediaInCrate(track.id))}
                                        data-tip={isMediaInCrate(track.id) ? 'Remove from Crate' : 'Add to Crate'}
                                        type="button"
                                    >
                                        {!isMediaInCrate(track.id) ? <AddToCrate /> : <RemoveFromCrate />}
                                    </button>
                                    <div>
                                        <button
                                            className={classNames(styles['download-accordion__action-btn'], styles['download-accordion__action-btn--three-dots'])}
                                            type="button"
                                            aria-label="More"
                                            data-tip="Show More"
                                            data-tip-show
                                            data-delay-show="400"
                                            onClick={(e) => {
                                                const boundingRect = e.currentTarget.getBoundingClientRect();

                                                if (isAnonymous || (isPremiumOnly && !hasPremiumOnlyAccess)) {
                                                    if (setCurrentMediaInContext) setCurrentMediaInContext(`${media.id}`, !hasPremiumOnlyAccess);
                                                    return;
                                                }
                                                openThreeDotsModalSheet('download-accordion', track.id, boundingRect.left, boundingRect.top + window.scrollY, false);
                                            }}
                                        >
                                            <ThreeDotsIcon />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </motion.div>
            )}
        </AnimatePresence>
    );
}
