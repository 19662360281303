import classNames from 'classnames';
import { ReactElement, MouseEvent } from 'react';
import styles from './squared-button.module.css';

type SquaredButtonType = 'outline' | 'filled' | 'dynamic' | 'colored-red' | 'outline-active';
type ButtonType = JSX.IntrinsicElements['button']['type'];

export interface SquaredButtonProps {
    type: SquaredButtonType;
    leftIcon?: ReactElement;
    rightIcon?: ReactElement;
    label?: string;
    onPress?: (e: MouseEvent<HTMLElement>) => void;
    removeHover?: boolean;
    fullWidth?: boolean;
    disabled?: boolean;
    svgColoring?: 'fill' | 'stroke' | 'fill-stroke';
    buttonType?: ButtonType;
    onHover?: () => void;
    onHoverEnded?: () => void;
}

export function SquaredButton({ type = 'outline', leftIcon, rightIcon, label, onPress, removeHover = false, fullWidth = false, disabled = false, svgColoring = 'fill', buttonType = 'button', onHover, onHoverEnded }: SquaredButtonProps) {
    const onClick = (e: MouseEvent<HTMLElement>) => !disabled && onPress ? onPress(e) : () => null;
    return (
        <button
            // eslint-disable-next-line react/button-has-type
            type={buttonType}
            className={classNames(styles['squared-button'], {
                [styles[`squared-button--${type}--no-hover`]]: removeHover,
                [styles[`squared-button--${type}`]]: type,
                [styles['squared-button--full-width']]: fullWidth,
                [styles['squared-button--disabled']]: disabled,
                [styles[`squared-button--${type}--${svgColoring}`]]: type
            })}
            aria-label={label}
            onMouseEnter={onHover}
            onMouseLeave={onHoverEnded}
            onClick={onClick}>
            {leftIcon || null}
            <span>{label}</span>
            {rightIcon || null}
        </button>
    );
}
