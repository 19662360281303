import { MouseEvent } from 'react';
import { useHubSwitch, useUserSettings } from '@bpm-web-app/utils';
import { LoadingIcon } from '../loading-icon/loading-icon';
import { SquaredButton } from '../squared-button/squared-button';
import { CustomIcon } from '../custom-icon/custom-icon';

interface DownloadButtonProps {
    type: 'dynamic' | 'outline';
    isLoading?: boolean;
    onPress: (e: MouseEvent<HTMLElement>) => void;
    fullWidth?: boolean
}

export function DownloadButton({ type, isLoading, onPress, fullWidth }: DownloadButtonProps) {
    const { isAnonymous, setShowSignUpModal } = useUserSettings();
    const { isCreate } = useHubSwitch();

    const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
        if (isAnonymous) {
            setShowSignUpModal({ type: isCreate ? 'pack' : 'curated-set' });
            return;
        }
        if (onPress) onPress(e);
    };

    return !isLoading ?
        (
            <SquaredButton leftIcon={<CustomIcon type="download-icon" color={type === 'dynamic' ? 'black' : 'gray'} />} type={type} label="Download" onPress={handleOnClick} fullWidth={fullWidth} />
        )
        :
        (
            <SquaredButton leftIcon={<LoadingIcon isDark={type === 'dynamic'} />} type={type} label="Downloading" onPress={handleOnClick} fullWidth={fullWidth} />
        );
}
