import { getPlatformLinkUsingRouter } from './getPlatformLink';

function getCurrentDomain() {
    return `${
        // eslint-disable-next-line @typescript-eslint/dot-notation
        process.env['NEXT_PUBLIC_RELEASE_STAGE'] === 'local'
            ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}`
            : `${window.location.protocol}//${window.location.hostname}`
    }`;
}

export function getSignupRedirectLink(toLocation?: string) {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    return `${process.env['NEXT_PUBLIC_POOL_URL']}/signup?close=back&redirect=${encodeURIComponent(
        toLocation ? `${getCurrentDomain()}${getPlatformLinkUsingRouter(toLocation)}` : window.location.href
    )}`;
}

export function getLoginRedirectLink() {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    return `${process.env['NEXT_PUBLIC_POOL_URL']}/login?close=back&redirect=${encodeURIComponent(window.location.href)}`;
}

export function getSignupRedirectLinkAccountPlans() {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    return `${process.env['NEXT_PUBLIC_POOL_URL']}/signup?close=back&redirect=${getCurrentDomain()}${getPlatformLinkUsingRouter('/account/plan')}`;
}
