import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
    relativeTime: {
        future: 'in %s',
        past: '%s',
        s: 'few seconds',
        m: '1 min',
        mm: '%d min',
        h: 'an hour',
        hh: '%d hours',
        d: 'a day',
        dd: '%d days',
        M: 'a month',
        MM: '%d months',
        y: 'a year',
        yy: '%d years',
    },
});

// const DEFAULT_DATE_FORMAT = 'MMMM D, YYYY';
export const ALTERNATE_DATE_FORMAT = 'MMMM D, YYYY';

/**
 * Returns the date formatted using the `DEFAULT_DATE_FORMAT`
 * @default DEFAULT_DATE_FORMAT
 */
export function formatDateToString(date?: string, format?: string): string {
    if (date == null) {
        return '';
    }
    if (format) {
        return dayjs(date).format(format);
    }
    return new Date(date).toLocaleDateString().replace(/\//g, '-');
}

export function timeAgo(date?: string, format?: string) {
    if (!date) {
        return '';
    }
    const day = dayjs(date);
    if (day.isAfter(dayjs().subtract(24, 'hour'))) {
        return 'Just Added';
    }
    if (day.isAfter(dayjs().subtract(48, 'hour'))) {
        return 'Yesterday';
    }
    if (day.isAfter(dayjs().subtract(168, 'hour'))) {
        return 'This Week';
    }
    if (format) {
        return day.format(format);
    }
    return day.toDate().toLocaleDateString().replace(/\//g, '-');
}

export const formatSeconds = (totalSeconds: number) => {
    if (totalSeconds >= 3600) {
        return dayjs.duration(totalSeconds * 1000).format('HH:mm:ss');
    }
    return dayjs.duration(totalSeconds * 1000).format('mm:ss');
};

export function timeFromNow(date: Date | string) {
    return dayjs(date).fromNow();
}
