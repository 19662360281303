import { useCallback, useContext, useEffect, useState } from 'react';
import { convertKeyboardToCamelot, KeyboardKeyType, KeyboardScale } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { Collapse } from 'react-collapse';
import { FilterInputType, FiltersContext } from '../filters/filters.context';
import CircleofFifths from '../circle-of-fifths-input/circle-of-fifths-input';
import { KeyKeyboardInput } from '../key-keyboard-input/key-keyboard-input';
import TextSwitch from '../shared/ui/text-switch/text-switch';
import styles from './key-filter.module.css';
import ChevronUp from '../../assets/icons/chevron-up.svg';

const DEFAULT_KEYBOARD_SCALE: KeyboardScale = 'major';
const DEFAULT_KEYBOARD_KEY_TYPE: KeyboardKeyType = 'flats';

export interface KeyFilterProps {
    value: string[];
    onChange: (value: string[]) => void;
    isSingleSelect?: boolean;
    justFilter?: boolean;
}

export function KeyFilter({ value, onChange, isSingleSelect = false, justFilter }: KeyFilterProps) {
    const { inputType, setInputType, scale, setScale, keyType, setKeyType } = useContext(FiltersContext);

    const [keyboardKeys, setKeyboardKeys] = useState<string[]>([]);
    const [camelotKeys, setCamelotKeys] = useState<string[]>(value);
    const [isSectionExpanded, setIsSectionExpanded] = useState(true);

    const onKeyboardKeyChange = useCallback(
        (newKeys: string[], newScale: KeyboardScale) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const convertedKeys = newKeys.map((key) => convertKeyboardToCamelot({ key, scale: newScale })!);

            onChange(convertedKeys);
            setKeyboardKeys(newKeys);
        },
        [onChange]
    );

    const onCamelotChange = (newKey: string[]) => {
        onChange(newKey);
    };

    useEffect(() => {
        if (inputType === 'wheel') setCamelotKeys(value);
        /**
         * NOTE: keyboard type is reset when values are reset
         * We don't use the values because there can be a mismatch
         * the camelot and the keyboard keys
         */
        if (!value.length) setKeyboardKeys([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const handleKeyboardScaleChange = useCallback(
        (newScale: KeyboardScale) => {
            onKeyboardKeyChange(keyboardKeys, newScale);
            setScale(newScale);
        },
        [keyboardKeys, onKeyboardKeyChange, setScale]
    );

    const handleReset = useCallback(() => {
        setScale(DEFAULT_KEYBOARD_SCALE);
        setKeyType(DEFAULT_KEYBOARD_KEY_TYPE);
        onChange([]);
    }, [onChange, setKeyType, setScale]);

    const filterActiveCount = value.length;

    const handleSectionToggle = useCallback(() => {
        setIsSectionExpanded(!isSectionExpanded);
    }, [isSectionExpanded]);

    const filterComponent = () => (
        <>
            <div className={styles['key-filter__input-type']}>
                <TextSwitch<FilterInputType>
                    value={inputType}
                    onChange={(newInputType) => {
                        setInputType(newInputType);
                        onChange([]);
                    }}
                    options={[
                        {
                            label: 'Wheel',
                            value: 'wheel',
                        },
                        {
                            label: 'Keyboard',
                            value: 'keyboard',
                        },
                    ]}
                />
            </div>
            <div className={styles['key-filter__input']}>
                {inputType === 'wheel' ? (
                    <CircleofFifths
                        setActiveKeys={onCamelotChange}
                        activeKeys={camelotKeys}
                        isSingleSelect={isSingleSelect}
                        keyType={keyType}
                        onKeyTypeChange={setKeyType}
                    />
                ) : (
                    <KeyKeyboardInput
                        onKeyChange={onKeyboardKeyChange}
                        activeKeys={keyboardKeys}
                        scale={scale}
                        onScaleChange={handleKeyboardScaleChange}
                        keyType={keyType}
                        onKeyTypeChange={setKeyType}
                        isSingleSelect={isSingleSelect}
                    />
                )}
            </div>
        </>
    );

    if (justFilter) {
        return filterComponent();
    }

    return (
        <div className={classNames('filter', styles['key-filter'])}>
            <div
                role="button"
                onClick={handleSectionToggle}
                tabIndex={0}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleSectionToggle();
                    }
                }}
                className="filter__header">
                <div className={classNames('filter__title', { 'filter__title-active': filterActiveCount > 0 })}>Key {filterActiveCount > 0 ? `(${filterActiveCount})` : ''}</div>
                {filterActiveCount > 0 && (
                    <button type="button" className="filter__reset" onClick={handleReset} aria-label="Reset key filter">
                        Remove
                    </button>
                )}
                <button aria-label="Expand/Collapse Key Filter" type="button" onClick={handleSectionToggle}>
                    <ChevronUp className={classNames('filter__chevron', { 'filter__chevron--expanded': isSectionExpanded })} />
                </button>
            </div>
            <Collapse isOpened={isSectionExpanded}>
                {filterComponent()}
            </Collapse>
        </div>
    );
}

export default KeyFilter;
