/** Start supreme imports */
import { Media as MediaHandler } from './handlers/media';
import { Playlist as PlaylistHandler } from './handlers/playlist';
import { UserPlaylist as UserPlaylistHandler } from './handlers/user-playlist';
import { Meta as MetaHandler } from './handlers/meta';
import { CuratedSets as CuratedHandler } from './handlers/curated-sets';
import { News as NewsHandler } from './handlers/news';
import { Artist as ArtistHandler } from './handlers/artist';
import { User as UserHandler } from './handlers/user';
import { Album as AlbumHandler } from './handlers/album';
import { Media as DownloadMediaHandler } from './handlers/download/media';
import { Album as DownloadAlbumHandler } from './handlers/download/album';
import { Giveaway as GiveawayHandler } from './handlers/giveaway';
import { Crate as CrateHandler } from './handlers/crate';
import { Device as DeviceHandler } from './handlers/device';
import { Notifications as NotificationsHandler } from './handlers/notifications';
import { Csv as CsvHandler } from './handlers/csv';
import { Subscription as SubscriptionHandler } from './handlers/subscriptions';
import { DJVerification as DJVerificationHandler } from './handlers/dj-verification';
import { Ads as AdsHandler } from './handlers/ads';
import { DownloadSection as DownloadSectionHandler } from './handlers/download/section';
/** End supreme imports */

/** Start Create imports */
import { Drive as DriveHandler } from './handlers/create/drive';
import { SoundPackage as SoundsPackagesHandler } from './handlers/create/sound-package';
import { Section as SectionHandler } from './handlers/create/section';
import { Sound as SoundsHandler } from './handlers/create/sound';
import { Label as LabelHandler } from './handlers/create/label';
import { CuratedSet as CuratedSetHandler } from './handlers/create/curated-set';
import { Contest as ContestHandler } from './handlers/create/contest';
import { Credits as CreditsHandler } from './handlers/create/credits';
import { Admin as AdminHandler } from './handlers/create/admin';

/** End Create imports */

/** Start Artist Portal imports */
import { ArtistPortal as ArtistPortalHandler } from './handlers/artist-portal';
/** End Artist Portal imports */

const unauthenticatedMethods: string[] = [];

const ApiProxyHandler = {
    get(target: any, prop: string) {
        if (unauthenticatedMethods.includes(prop)) return target[prop];
        return (...args: any) => target[prop](...args);
    },
};

export const Media = new Proxy<typeof MediaHandler>(MediaHandler, ApiProxyHandler);

export const Playlist = new Proxy<typeof PlaylistHandler>(PlaylistHandler, ApiProxyHandler);

export const UserPlaylist = new Proxy<typeof UserPlaylistHandler>(UserPlaylistHandler, ApiProxyHandler);

export const Meta = new Proxy<typeof MetaHandler>(MetaHandler, ApiProxyHandler);

export const CuratedSets = new Proxy<typeof CuratedHandler>(CuratedHandler, ApiProxyHandler);

export const News = new Proxy<typeof NewsHandler>(NewsHandler, ApiProxyHandler);

export const Artist = new Proxy<typeof ArtistHandler>(ArtistHandler, ApiProxyHandler);

export const User = new Proxy<typeof UserHandler>(UserHandler, ApiProxyHandler);

export const Album = new Proxy<typeof AlbumHandler>(AlbumHandler, ApiProxyHandler);

export const DownloadSection = new Proxy<typeof DownloadSectionHandler>(DownloadSectionHandler, ApiProxyHandler);

/* TODO: figure out a more standardised way of organising handlers from different hubs */
export const DownloadMedia = new Proxy<typeof DownloadMediaHandler>(DownloadMediaHandler, ApiProxyHandler);

export const DownloadAlbum = new Proxy<typeof DownloadAlbumHandler>(DownloadAlbumHandler, ApiProxyHandler);

export const Giveaway = new Proxy<typeof GiveawayHandler>(GiveawayHandler, ApiProxyHandler);

export const Crate = new Proxy<typeof CrateHandler>(CrateHandler, ApiProxyHandler);

export const Notifications = new Proxy<typeof NotificationsHandler>(NotificationsHandler, ApiProxyHandler);

export const Device = new Proxy<typeof DeviceHandler>(DeviceHandler, ApiProxyHandler);

export const Csv = new Proxy<typeof CsvHandler>(CsvHandler, ApiProxyHandler);

export const Subscription = new Proxy<typeof SubscriptionHandler>(SubscriptionHandler, ApiProxyHandler);

export const DJVerification = new Proxy<typeof DJVerificationHandler>(DJVerificationHandler, ApiProxyHandler);

export const Ads = new Proxy<typeof AdsHandler>(AdsHandler, ApiProxyHandler);

/**
 * Create Handlers
 */
export const Drives = new Proxy<typeof DriveHandler>(DriveHandler, ApiProxyHandler);

export const SoundPackage = new Proxy<typeof SoundsPackagesHandler>(SoundsPackagesHandler, ApiProxyHandler);

export const Section = new Proxy<typeof SectionHandler>(SectionHandler, ApiProxyHandler);

export const Label = new Proxy<typeof LabelHandler>(LabelHandler, ApiProxyHandler);

export const Sound = new Proxy<typeof SoundsHandler>(SoundsHandler, ApiProxyHandler);

export const CuratedSet = new Proxy<typeof CuratedSetHandler>(CuratedSetHandler, ApiProxyHandler);

export const Contest = new Proxy<typeof ContestHandler>(ContestHandler, ApiProxyHandler);

export const Credits = new Proxy<typeof CreditsHandler>(CreditsHandler, ApiProxyHandler);

export const Admin = new Proxy<typeof AdminHandler>(AdminHandler, ApiProxyHandler);

/**
 * Artist Handlers
 */
export const ArtistPortal = new Proxy<typeof ArtistPortalHandler>(ArtistPortalHandler, ApiProxyHandler);
