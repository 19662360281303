// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/dot-notation */
import { UserPlaylistCategory } from '@bpm-web-app/download-api-sdk';
import { Analytics, getCurrentPlatformLink } from '@bpm-web-app/utils';
import classNames from 'classnames';
import router from 'next/router';
import { useCallback } from 'react';
import { Collapse } from 'react-collapse';
import navStyles from '../nav/nav.module.css';
import styles from './nav-my-playlist-categories.module.css';

import { ReactComponent as ChevronRight } from '../../assets/icons/chevron-right.svg';
import { ReactComponent as ActiveFolderIcon } from '../../assets/icons/folder-active.svg';
import { ReactComponent as FolderIcon } from '../../assets/icons/folder.svg';

import { CustomIcon } from '../shared/custom-icon/custom-icon';
// eslint-disable-next-line import/no-cycle
import { useOnDragElement } from '../drag-and-drop/drag-and-drop.context';
import { ALL_TYPES_NO_ARTIST, useDroppable } from '../droppable/droppable';
import NavMyPlaylistList from '../nav-my-playlist-list/nav-my-playlist-list';
import { ContextMenuClickIgnoreClass } from '../shared';
import { LocalSearchSort } from './utils';

export interface NavMyPlaylistsCategoryProps {
    showPlaylist: boolean;
    category: UserPlaylistCategory;
    isActive: boolean;
    isFolderOpened: boolean;
    currentFolder: number | null | undefined;
    containsActivePlaylist: boolean;
    playlistSort: LocalSearchSort | undefined;
    handleOpenAndCloseFolder: (id: number, onlyOpen?: boolean) => void;
    onUpdatePlaylistsPerCategory: (categoryId: number, playlistsIds: string[]) => void;
    handleOpenThreeDots: (e: React.MouseEvent<HTMLElement>, item: UserPlaylistCategory) => void;
    handleShowSubPlaylistFolders: () => void;
}

export function NavMyPlaylistsCategory({
    showPlaylist,
    isActive,
    handleOpenThreeDots,
    handleOpenAndCloseFolder,
    category,
    containsActivePlaylist,
    isFolderOpened,
    currentFolder,
    onUpdatePlaylistsPerCategory,
    playlistSort,
    handleShowSubPlaylistFolders
}: NavMyPlaylistsCategoryProps) {
    const { isDragging } = useOnDragElement();

    const renderFolderIcon = useCallback(
        (item: UserPlaylistCategory) => {
            if (item.slug === 'shared') return <CustomIcon hasIconHover type="shared-folder" size="regular" color="boulder" />;
            return containsActivePlaylist ? <ActiveFolderIcon /> : <FolderIcon />;
        },
        [containsActivePlaylist]
    );

    const { isOver, dropRef } = useDroppable({
        shallow: false,
        accept: ALL_TYPES_NO_ARTIST,
        onlyHover: false,
        onDrop: () => {
            return { target: 'playlist-category', id: category.id, title: category.name };
        },
        disabled: category.slug === 'shared'
    });

    return (
        <div ref={dropRef}>
            <button
                type="button"
                className={classNames(styles['nav-my-playlists-category-item'], {
                    [styles['nav-my-playlists-category-item--is-dragging']]: isDragging,
                    [styles['nav-my-playlists-category-item--is-dragging--over']]: isOver,
                    [styles['nav-my-playlists-category-item--active']]: isActive,
                    [styles['nav-my-playlists-category-item--active-open']]: containsActivePlaylist,
                })}
                onClick={(e) => {
                    e.preventDefault();
                    if (category.playlist_count === 0) {
                        router.push(getCurrentPlatformLink(`/my-playlists/${category.id}`));
                    }

                    handleShowSubPlaylistFolders();
                    handleOpenAndCloseFolder(category.id);
                    Analytics.trackClick('nav_bar', category.id.toString(), { location: 'nav_my_playlists_category' });
                }}
            >
                <i className={`${styles['nav-my-playlists-category-item__button']}`}>{renderFolderIcon(category)}</i>
                <span>{category.name}</span>
                <div
                    className={classNames(styles['nav-my-playlists-category-item--three-dots'], {
                        [styles['nav-my-playlists-category-item--three-dots--open']]: isFolderOpened || currentFolder === category.id,
                    })}
                >
                    <CustomIcon
                        type="three-dots"
                        hasIconHover
                        className={ContextMenuClickIgnoreClass}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleOpenThreeDots(e, category);
                        }}
                    />
                </div>
                {category.playlist_count > 0 ? (
                    <button
                        type="button"
                        aria-label="Open or Close List"
                        onClick={() => {
                            handleOpenAndCloseFolder(category.id);
                        }}
                    >
                        <ChevronRight
                            className={classNames(styles['nav-my-playlists-category-item--chevron'], {
                                [styles['nav-my-playlists-category-item--chevron--open']]: isFolderOpened && currentFolder === category.id,
                            })}
                        />
                    </button>
                ) : null}
            </button>
            <div
                className={classNames(styles['drop-line'], styles['drop-line--thin'], styles['drop-line--indented'], {
                    [styles['drop-line--is-visible']]: isOver
                })}
            />
            <Collapse isOpened={(showPlaylist && currentFolder === category.id) || isOver}>
                <div
                    className={classNames(navStyles['nav__dropdown-container'], styles['nav-my-playlists-category-item__contents'], {
                        [navStyles['nav__dropdown-container--open']]: (showPlaylist && currentFolder === category.id) || isOver,
                    })}
                >
                    <NavMyPlaylistList categoryId={category.id} sort={playlistSort} isDragging={isDragging} onGetPlaylistsPerCategory={onUpdatePlaylistsPerCategory} />
                </div>
            </Collapse>
        </div>
    );
}

export default NavMyPlaylistsCategory;
