import { useRouter } from 'next/router';
import classNames from 'classnames';
import { appendQueryParams, useApiErrorHandler, useHubSwitch, showToast } from '@bpm-web-app/utils';
import { useForm } from 'react-hook-form';
import { ChangeEvent, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { useContests } from '@bpm-web-app/swr-hooks';
import { apiKey } from '@bpm-web-app/api-client';
import styles from './submit-remix.module.css';
import UploadIcon from '../../assets/icons/create/upload.svg';
import Title from '../title/title';

export function SubmitRemix() {
    const router = useRouter();
    const { slug } = router.query;

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [alreadySubmittedSuccessfully, setAlreadySubmittedSuccessfully] = useState(false);
    const [file, setFile] = useState(null);
    const errorHandler = useApiErrorHandler();
    const { hub } = useHubSwitch();

    const fileInputRef = useRef(null);

    const { contests, isLoading, error } = useContests();

    const detailedContest = useMemo(() => {
        const contest = contests?.current?.find((contestObj) => contestObj.slug === slug);
        if (contest) {
            return contest;
        }
        return contests?.past?.find((contestObj) => contestObj.slug === slug);
    }, [contests, slug]);

    const {
        register,
        handleSubmit,
        getValues,
        reset,
        formState: { errors },
        control,
    } = useForm({
        defaultValues: useMemo(
            () => ({
                full_name: '',
                title: '',
                description: '',
            }),
            []
        ),
    });

    const giveawayImage = useMemo(
        () => (
            <div className={styles['submit-remix__content']}>
                <div className={styles['submit-remix__img-wrapper']}>
                    <picture className={styles['submit-remix__img']}>
                        <source srcSet={detailedContest?.banner_image_url} media="(min-width: 1920px)" />
                        <source
                            srcSet={`${appendQueryParams(detailedContest?.banner_image_url, { key: 'dw', value: 3840 })} 2x, ${appendQueryParams(detailedContest?.banner_image_url, {
                                key: 'dw',
                                value: 1920,
                            })} 1x`}
                            media="(min-width: 1440px)"
                        />
                        <source
                            srcSet={`${appendQueryParams(detailedContest?.banner_image_url, { key: 'dw', value: 2880 })} 2x, ${appendQueryParams(detailedContest?.banner_image_url, {
                                key: 'dw',
                                value: 1440,
                            })} 1x`}
                            media="(min-width: 1024px)"
                        />
                        <source
                            srcSet={`${appendQueryParams(detailedContest?.banner_image_url, { key: 'dw', value: 2048 })} 2x, ${appendQueryParams(detailedContest?.banner_image_url, {
                                key: 'dw',
                                value: 1024,
                            })} 1x`}
                            media="(min-width: 768px)"
                        />
                        <img
                            src={appendQueryParams(detailedContest?.banner_image_url, { key: 'dw', value: 768 })}
                            srcSet={`${appendQueryParams(detailedContest?.banner_image_url, { key: 'dw', value: 1536 })} 2x`}
                            alt={detailedContest?.title}
                        />
                    </picture>
                </div>
            </div>
        ),
        [detailedContest?.banner_image_url, detailedContest?.title]
    );

    const onSubmit = async (formData) => {
        if (!file) {
            showToast({ type: 'error', message: 'File upload required.' });
            return;
        }

        setIsSubmitting(true);

        try {
            const finalFormData = new FormData();
            finalFormData.append('file', file, file.name);
            finalFormData.append('full_name', formData.full_name);
            finalFormData.append('title', formData.title);
            finalFormData.append('description', formData.description);
            const key = apiKey();
            const headers = {} as { [key: string]: string };
            if (key) {
                // eslint-disable-next-line @typescript-eslint/dot-notation
                headers['Authorization'] = key;
            }
            // eslint-disable-next-line @typescript-eslint/dot-notation
            const response = await fetch(`${process.env['NEXT_PUBLIC_CREATE_API_BASE_PATH']}/contest/${detailedContest.id}/submit`, {
                method: 'POST',
                credentials: 'include',
                body: finalFormData,
                headers
            });

            if (!response.ok) {
                // NOTE(paulomartinsbynd): this means user already submitted remix before
                // https://github.com/bpmsupreme/documentation/issues/109
                if (response.status === 403) {
                    showToast({ type: 'error', message: 'Remix submission limit reached.' });
                    setAlreadySubmittedSuccessfully(true);
                    return;
                }
                throw new Error(response.statusText);
            }

            setAlreadySubmittedSuccessfully(true);
            showToast({ type: 'success', message: 'File upload successful.' });
        } catch (e) {
            errorHandler({ error: e });
        }

        setIsSubmitting(false);
    };

    const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    /* TODO: implement (ghost?) loading */
    if (isLoading) return null;

    if (!isLoading && (error || !detailedContest)) return null;

    return (
        <>
            <Title platform={hub} title={detailedContest?.title} />
            <div className={styles['submit-remix__intro']}>
                <div className={styles['submit-remix__summary']}>
                    <h1 className={styles['submit-remix__title']}>Submit for a chance to win</h1>
                    <ul className={styles['submit-remix__details']}>
                        <li className={styles['submit-remix__detail']}>Only one entry per person per contest is permitted</li>
                        {alreadySubmittedSuccessfully && (
                            <li className={styles['submit-remix__detail']}>
                                Your submission was submitted successfully! Winner will be chosen on {dayjs(detailedContest.announcement).format('MMMM D, YYYY, hh:mma')}.
                            </li>
                        )}
                    </ul>
                    {!alreadySubmittedSuccessfully && (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className={styles['submit-remix__form-container']}>
                                <input {...register('full_name', { required: false })} type="text" placeholder="Full Name" className={styles['submit-remix__input']} />
                                <span>{errors?.full_name?.message}</span>
                                <input {...register('title', { required: true })} type="text" placeholder="Title of Your Track" className={styles['submit-remix__input']} />
                                <span>{errors?.title?.message}</span>
                                <input {...register('description', { required: true })} type="text" placeholder="Short Description of Your Track" className={styles['submit-remix__input']} />
                                <span>{errors?.description?.message}</span>
                                <input ref={fileInputRef} type="file" accept=".mp3,.wav" onChange={handleFileUpload} multiple={false} hidden />
                                <button type="button" onClick={() => fileInputRef.current.click()} className={styles['submit-remix__upload-row']}>
                                    <div className={styles['submit-remix__upload-row__title']}>{file === null ? <span>Upload Your Track as MP3 or WAV (30mb Limit)</span> : <span>{file.name}</span>}</div>
                                    <div className={styles['submit-remix__upload-row__action']}>
                                        <UploadIcon />
                                        <span>Upload Track</span>
                                    </div>
                                </button>
                            </div>
                            <div className={styles['submit-remix__summary-ctas']}>
                                <button disabled={isSubmitting} type="submit" className={classNames(styles['submit-remix__summary-cta'], styles['submit-remix__summary-cta--primary'])}>
                                    Submit
                                </button>
                            </div>
                        </form>
                    )}
                </div>
                {giveawayImage}
            </div>
        </>
    );
}
