export function unique<T>(array: Array<T>, property?: keyof T) {
    const s = new Set();
    return array.filter((value, index, self) => {
        if (property && s.has(value[property])) {
            return false;
        }
        if (property) {
            s.add(value[property]);
            return true;
        }
        if (s.has(value)) {
            return false;
        }
        s.add(value);
        return true;
    });
}
