import {
    convertToPluralIfNeeded,
    DEFAULT_SOUNDS_LIMIT,
    fileDownload,
    formatDateToString,
    useApiErrorHandler,
    getMutatedSounds,
    getMutatedPresets,
    useCreateFilterParams,
    useViewport,
} from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import { useDownloadMultiSounds, useGetCredits, useGetDrive, useInfiniteSearchSound, useInfiniteSearchPresets, useDownloadMultiSoundsPreview, useGetInvitesForDrive, useCurrentUser, useMutateDrives } from '@bpm-web-app/swr-hooks';
import { MutableRefObject, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { CreateCuratedSet, Drive, UserDriveCollaboration, UserDriveCollaborationAccessLevel, UserDriveCollaborationStatus } from '@bpm-web-app/create-api-sdk';
import { Drives as DriveAPI } from '@bpm-web-app/api-client';

import styles from './my-drive-detail.module.css';

import MediaDetailBanner from '../../shared/ui/media-detail-banner/media-detail-banner';
import TrackListCreate from '../../shared/track-list/create/track-list-create';
import Filters from '../../filters/filters';
import SecondaryPageTitle from '../../shared/secondary-page-title/secondary-page-title';
import { BreakpointView } from '../../shared/ui';
import { CreateThreeDotsSheetContext } from '../../shared/three-dots-sheet/create-three-dots-sheet.context';
import NoResultsBlock from '../../shared/ui/no-results-block/no-results-block';
import Title from '../../title/title';
import TrackListCreatePresets from '../../shared/track-list/create-presets/track-list-create-presets';
import { ActionModal } from '../../shared/action-modal/action-modal';
import { DownloadButton } from '../../shared/download-button/download-button';
import { PlaylistCoverImage } from '../../shared/playlist-cover-image/playlist-cover-image';
import { CollaborationModal } from '../../shared/collaboration/collaboration-modal';
import { CollaborationInvite } from '../../shared/collaboration/collaboration-invite';
import { CollaborationUsers } from '../../shared/collaboration/collaboration-users';
import { AddCollaboratorsButton } from '../../shared/add-collaborators-button/add-collaborators-button';
import { ThreeDotsButton } from '../../shared/three-dots-button/three-dots-button';
import { UploadButton } from '../../shared/upload-button/upload-button';
import { GhostComponent, QualityControlContext } from '../../shared';
import { QualityControlPublishForm } from '../../shared/quality-control/quality-control-publish-form';

export function MyDriveDetail() {
    const router = useRouter();
    const { isMobile } = useViewport();

    const { id } = router.query;

    const driveId = `${id}`;

    const [selectedItemsIds, setSelectedItemsIds] = useState<string[]>([]);
    const { data: driveData, isLoading, error, mutate: mutateDriveData } = useGetDrive(driveId);
    const { data: driveInvites, mutate: mutateInvites } = useGetInvitesForDrive(driveId);

    const { mutate: updateCredits } = useGetCredits(true);
    const mutateDrives = useMutateDrives();
    const errorHandler = useApiErrorHandler();
    const downloadMultiSounds = useDownloadMultiSounds();
    const downloadMultiSoundsPreview = useDownloadMultiSoundsPreview();
    const { openThreeDotsModalSheet } = useContext(CreateThreeDotsSheetContext);

    // Quality Control
    const { qualityControlIsAvailable } = useContext(QualityControlContext);
    const [showPublishDrive, setShowPublishDrive] = useState(false);
    const [qualityControlFormData, setQualityControlFormData] = useState<CreateCuratedSet>();
    const qualityControlFormRef: MutableRefObject<HTMLFormElement> = useRef();

    const [isRequestInProgress, setIsRequestInProgress] = useState(false);
    const [creditAmount, setCreditAmount] = useState(null);
    const [actionModalOpen, setActionModalOpen] = useState<boolean>(false);
    const [downloadSubtitle, setDownloadSubtitle] = useState<string>('this file');

    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const [showCopyModal, setShowCopyModal] = useState(false);

    useEffect(() => {
        if (driveData?.data?.access === Drive.AccessEnum.Downgraded) {
            setShowUpgradeModal(true);
        } else if (driveData?.data?.access === Drive.AccessEnum.Expired) {
            setShowCopyModal(true);
        }
    }, [driveData]);

    // Collaborators data
    const { data: user } = useCurrentUser();
    const [isAddingCollaborators, setIsAddingCollaborators] = useState(false);
    const [showInvitationMessage, setShowInvitationMessage] = useState(false);
    const [driveInvitation, setDriveInvitation] = useState<UserDriveCollaboration>();
    const [driveCollaborators, setDriveCollaborators] = useState<UserDriveCollaboration[]>();
    const [invitedCollaborators, setInvitedCollaborators] = useState<UserDriveCollaboration[]>([]);
    const hasEditAccess = useMemo(() => {
        if (!driveData) {
            return false;
        }
        return driveData.data.is_own === true || (driveData.data.access_level === UserDriveCollaborationAccessLevel.Manage || driveData.data.access_level === UserDriveCollaborationAccessLevel.Edit);
    }, [driveData]);

    useEffect(() => {
        setDriveCollaborators(driveData?.data.users || []);
    }, [driveData?.data.users]);

    const handleGetDriveInvites = useCallback(() => {
        if (driveInvites && driveInvites.data && driveInvites?.data.length > 0) {
            // Remove already members
            const nonMemberInvites = driveInvites.data.filter((invite) => invite.status === UserDriveCollaborationStatus.Invited);
            // Check if current user is pending invite
            const currentInvite = nonMemberInvites.find((invite) => invite.email.toLowerCase() === user?.data.user.email.toLowerCase());
            setDriveInvitation(currentInvite);
            setShowInvitationMessage(currentInvite !== undefined);
            setInvitedCollaborators(nonMemberInvites);
        } else {
            setInvitedCollaborators([]);
            setDriveInvitation(undefined);
        }
    }, [driveInvites, user?.data.user.email]);

    useEffect(() => {
        handleGetDriveInvites();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [driveInvites]);

    const query = useCreateFilterParams({ drive_id: driveId });

    const {
        data: soundsData,
        flatData: soundsList,
        isLoadingMore,
        isLoadingInitialData,
        setSize,
        isLastPage,
        mutate,
    } = useInfiniteSearchSound(
        query,
        true,
        // eslint-disable-next-line max-len
        /** We need to do this so we fetch the most recent data, this might bring some problems when we have infinite loading  */
        { revalidateFirstPage: true }
    );

    const mutateSound = useCallback(
        (soundId: string, progress: number) => {
            mutate(getMutatedSounds(soundsData, soundId, progress));
        },
        [mutate, soundsData]
    );

    const presetQuery = useMemo(() => {
        return {
            ...query,
            limit: driveData?.data?.sound_count === 0 ? DEFAULT_SOUNDS_LIMIT : 3
        };
    }, [query, driveData?.data?.sound_count]);

    // eslint-disable-next-line max-len
    const { data: presetData, flatData: presetList, isLoadingInitialData: isLoadingInitialDataPresets, isLoadingMore: isLoadingMorePresets, setSize: setSizePreset, isLastPage: isLastPagePreset, mutate: mutatePresetFn } = useInfiniteSearchPresets(presetQuery);

    const mutatePreset = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-shadow
        (id: string, progress: number) => {
            mutatePresetFn(getMutatedPresets(presetData, id, progress));
        },
        [mutatePresetFn, presetData]
    );

    const handleLoadMore = useCallback(() => {
        if (!isLoadingInitialData && !isLoadingMore && !isLastPage) {
            setSize((prevSize) => prevSize + 1);
        }
    }, [isLastPage, isLoadingInitialData, isLoadingMore, setSize]);

    const handleLoadMorePresets = useCallback(() => {
        if (!isLoadingMorePresets && !isLastPagePreset) {
            setSizePreset((prevSize) => prevSize + 1);
        }
    }, [isLastPagePreset, isLoadingMorePresets, setSizePreset]);

    const handleMultiDownload = useCallback(async () => {
        const totalList = soundsList?.concat(presetList);
        if (totalList?.length) {
            const soundsToDownload = selectedItemsIds.length ? totalList.filter((sound) => selectedItemsIds.includes(sound.id)) : totalList;

            try {
                if (soundsToDownload.length) {
                    setIsRequestInProgress(true);
                    const downloadMediaUrlData = await downloadMultiSounds(soundsToDownload.map((sound) => sound.id), router.asPath);

                    if (downloadMediaUrlData) {
                        const payload = await downloadMediaUrlData?.json();
                        if (payload?.data?.url) {
                            fileDownload(payload.data.url);
                            updateCredits();
                        }
                    }
                }
            } catch (err) {
                errorHandler({ error: err });
            } finally {
                setIsRequestInProgress(false);
            }
        }
    }, [downloadMultiSounds, errorHandler, selectedItemsIds, soundsList, presetList, updateCredits, router]);

    const handleMultiDownloadPreview = useCallback(async () => {
        const totalList = soundsList?.concat(presetList);
        if (totalList?.length) {
            const soundsToDownload = selectedItemsIds.length ? totalList.filter((sound) => selectedItemsIds.includes(sound.id)) : totalList;
            try {
                const response = await downloadMultiSoundsPreview(soundsToDownload.map((sound) => sound.id), router.asPath);
                const creditsRequired = response?.data?.required?.total;

                setCreditAmount(creditsRequired!);
                if (creditsRequired! > 0) {
                    if (soundsToDownload?.length === 1) setDownloadSubtitle('this file');
                    else if (soundsToDownload?.length > 1) setDownloadSubtitle('these files');
                    setActionModalOpen(true);
                } else {
                    handleMultiDownload();
                }
            } catch (err) {
                errorHandler({ error: err });
            }
        }
    }, [downloadMultiSoundsPreview, handleMultiDownload, errorHandler, soundsList, presetList, selectedItemsIds, router]);

    const openThreeDots = useCallback(
        async (element) => {
            if (driveData?.data?.id) {
                const { top, left } = (element.target as HTMLButtonElement).getBoundingClientRect();
                openThreeDotsModalSheet({
                    newCreateActionType: 'drive',
                    actionId: driveData.data.id,
                    left,
                    top: top + window.scrollY,
                });
            }
        },
        [openThreeDotsModalSheet, driveData]
    );

    const handleOpenCollaborationModal = () => {
        // Reload playlist data when opening the collaboration menu
        handleGetDriveInvites();
        mutate();
        setIsAddingCollaborators(true);
    };

    const handleSelectItem = useCallback((selectedSoundId: string) => {
        setSelectedItemsIds((prevState) => {
            if (prevState.includes(selectedSoundId)) {
                return prevState.filter((soundId) => selectedSoundId !== soundId);
            }
            return [...prevState, selectedSoundId];
        });
    }, []);

    const handleToggleAll = useCallback(
        (all: boolean) => {
            setSelectedItemsIds(() => (all ? (soundsList || []).map((sound) => sound.id) : []));
        },
        [soundsList]
    );

    const handleToggleAllPreset = useCallback(
        (all: boolean) => {
            setSelectedItemsIds(() => (all ? (presetList || []).map((sound) => sound.id) : []));
        },
        [presetList]
    );

    const ghostLoading = () => {
        return (
            <>
                <Title platform="create" title="Loading Drive..." />
                <GhostComponent type="my-drive-detail" />
                <div className="spacing--top" />

                <GhostComponent type="secondary-title" />
                <GhostComponent type="track-list" isCreate elementsCount={5} />
            </>
        );
    };

    if (isLoading || isLoadingInitialDataPresets || isLoadingInitialData) return ghostLoading();

    if (!isLoading && (error || !driveData)) return null;

    return (
        <>
            <Title platform="create" title={driveData?.data?.name} />
            <ActionModal
                headerTitle="Heads Up"
                title="You are about to use a few credits."
                subtitle={`Do you want to download ${downloadSubtitle} for ${creditAmount} Credits?`}
                confirmButtonText="Continue"
                onConfirm={() => {
                    handleMultiDownload();
                    setActionModalOpen(false);
                }}
                onClose={() => {
                    setActionModalOpen(false);
                }}
                variant="light"
                isOpen={actionModalOpen}
            />
            <div className="spacing__window">
                <MediaDetailBanner actionsClassName={styles['my-drive-detail__actions-container']} align={isMobile ? 'end' : 'start'}>
                    {{
                        image: (
                            <PlaylistCoverImage
                                drive={driveData?.data}
                                soundList={soundsList}
                                coverUrl={driveData?.data.image_url}
                                customCoverUrl={driveData?.data.custom_image_url}
                                collaborativePlaylist
                                onUploadComplete={() => mutateDriveData()}
                                isCreate
                            />
                        ),
                        text: (
                            <>
                                <div className={styles['my-drive-detail__title-container']}>
                                    <h2>{driveData?.data.name}</h2>
                                    <BreakpointView
                                        desktopChildren={null}
                                        mobileChildren={<Filters platform="create" showOnMobile hideCreateGenres />}
                                    />
                                </div>
                                <div className={styles['my-drive-detail__info']}>
                                    <CollaborationUsers
                                        owner={driveData?.data.owner}
                                        collaborators={driveCollaborators || []}
                                        onClick={handleOpenCollaborationModal} />
                                    <div className={styles['my-drive-detail__info--stats']}>
                                        <span>{convertToPluralIfNeeded(driveData?.data.sound_count || 0, 'Sound')}</span>
                                        {driveData && driveData.data && driveData.data.preset_count !== undefined && driveData?.data?.preset_count > 0 && <span>{`${convertToPluralIfNeeded(driveData?.data?.preset_count ?? 0, 'Preset')}`}</span>}
                                        <span>{`Updated ${formatDateToString(driveData?.data.updated_at)}`}</span>
                                    </div>
                                </div>
                            </>
                        ),
                        actions: (
                            <>
                                {qualityControlIsAvailable ? (
                                    <BreakpointView
                                        mobileChildren={null}
                                        desktopChildren={
                                            <UploadButton isLoading={false} type="dynamic" onPress={() => setShowPublishDrive(true)} />
                                        }
                                    />
                                ) : null}
                                <BreakpointView
                                    desktopChildren={<DownloadButton type="dynamic" isLoading={isRequestInProgress} onPress={handleMultiDownloadPreview} />
                                    }
                                    mobileChildren={null} />
                                <BreakpointView
                                    desktopChildren={
                                        null
                                    }
                                    mobileChildren={driveData?.data.is_own ? <ThreeDotsButton onClick={openThreeDots} /> : null}
                                />
                                {driveData?.data.is_own === true ? (<AddCollaboratorsButton onClick={handleOpenCollaborationModal} />) : null}

                                <BreakpointView
                                    desktopChildren={driveData?.data.is_own ? <ThreeDotsButton hasTooltip onClick={openThreeDots} /> : null}
                                    mobileChildren={null} />
                            </>
                        )
                    }}
                </MediaDetailBanner>
                {!isLoadingInitialDataPresets && (driveData?.data?.preset_count || 0) > 0 && (
                    <>
                        <SecondaryPageTitle title="Synth Presets" counter={presetData?.[0]?.pagination?.total} noPadding />
                        {(presetList && presetList?.length > 0) || isLoadingInitialDataPresets ? (
                            <TrackListCreatePresets
                                mutateSound={mutatePreset}
                                list={presetList}
                                onSelectItem={handleSelectItem}
                                selectedItemsIds={selectedItemsIds}
                                onSelectAll={handleToggleAllPreset}
                                onLoadMore={handleLoadMorePresets}
                                isLoadingMore={isLoadingMorePresets}
                                hasMore={!isLastPagePreset}
                                hidePackName
                                automatedLoadMore={driveData?.data?.sound_count === 0}
                                isDriveDetailPage={driveData?.data.is_own}
                                hasEditAccess={hasEditAccess}
                                collaborators={driveData?.data.users}
                                connections={driveData?.data.connections}
                                owner={driveData?.data.owner}
                            />
                        ) : (
                            <NoResultsBlock>No Presets Available</NoResultsBlock>
                        )}
                    </>
                )}
                <SecondaryPageTitle title="Sounds" noPadding />
                {(soundsList && soundsList.length > 0) || isLoadingInitialData ? (
                    <TrackListCreate
                        mutateSound={mutateSound}
                        list={soundsList}
                        onLoadMore={handleLoadMore}
                        isLoadingMore={isLoadingMore}
                        onSelectItem={handleSelectItem}
                        selectedItemsIds={selectedItemsIds}
                        onSelectAll={handleToggleAll}
                        isDriveDetailPage={driveData?.data.is_own}
                        hasEditAccess={hasEditAccess}
                        collaborators={driveData?.data.users}
                        connections={driveData?.data.connections}
                        owner={driveData?.data.owner}
                    />
                ) : (
                    <NoResultsBlock>No Sounds Available</NoResultsBlock>
                )}
            </div>

            <ActionModal
                hideCancel
                headerTitle="Collaboration Invite"
                onClose={() => setShowInvitationMessage(false)}
                variant="light"
                isOpen={showInvitationMessage === true && driveInvitation !== undefined && driveId !== undefined}
            >
                <CollaborationInvite
                    driveInvite={driveInvitation as UserDriveCollaboration}
                    playlistTitle={driveData?.data.name}
                    owner={driveData?.data.owner?.full_name as unknown as string}
                    onCreateComplete={(newCollaborator) => {
                        mutateDriveData();
                        mutateInvites();
                        if (newCollaborator) {
                            const allCollaborators = driveCollaborators || [];
                            allCollaborators.push(newCollaborator);
                            setDriveCollaborators(allCollaborators);
                        }
                        handleGetDriveInvites();
                        setShowInvitationMessage(false);
                    }}
                    isCreate />
            </ActionModal>

            <ActionModal
                hideCancel
                headerTitle="Publish drive as Curated Set"
                onClose={() => setShowPublishDrive(false)}
                variant="dark"
                isOpen={showPublishDrive && qualityControlIsAvailable}
            >
                <QualityControlPublishForm
                    setHasCompletedQualityControlPublishForm={() => setShowPublishDrive(false)}
                    curatedSetData={qualityControlFormData}
                    driveData={driveData?.data}
                    qualityControlPublishFormRef={qualityControlFormRef}
                />
            </ActionModal>

            <ActionModal
                hideCancel
                headerTitle={`Share Drive ${driveData?.data.name}`}
                onClose={() => setIsAddingCollaborators(false)}
                variant="dark"
                isOpen={isAddingCollaborators}
            >
                {driveData && driveData.data ? (
                    <CollaborationModal
                        driveId={driveData?.data?.id}
                        invitedDriveCollaborators={invitedCollaborators}
                        setDriveInvitedCollaborators={setInvitedCollaborators}
                        setDriveCollaborators={setDriveCollaborators}
                        onClose={() => setIsAddingCollaborators(false)}
                        driveCollaborators={driveCollaborators}
                        driveOwner={driveData?.data.owner}
                        isMyPlaylist={driveData?.data.is_own}
                        invitationLink={driveData?.data.invitation_link}
                        isCreate
                    />
                ) : null}
            </ActionModal>

            <ActionModal
                headerTitle="Heads Up!"
                title="Drive Collaboration Expired"
                subtitle={`The owner of '${driveData?.data?.name}' is no longer a Premium subscriber. To save the drive, please upgrade your subscription now.`}
                confirmButtonText="Upgrade Now"
                onConfirm={() => {
                    router.push('/account/plan');
                }}
                onClose={() => {
                    setShowUpgradeModal(false);
                    router.back();
                }}
                variant="light"
                isOpen={showUpgradeModal}
            />

            <ActionModal
                headerTitle="Attention!"
                title="Drive Collaboration Expired"
                subtitle={`Collaboration for this drive has expired because the owner of '${driveData?.data?.name}' is no longer a Premium subscriber. However, you can save the drive to your own library.`}
                confirmButtonText="Save Drive"
                onConfirm={() => {
                    DriveAPI.copyDrive(driveId).then((newDrive) => {
                        mutateDrives();
                        router.push(`/my-drive/${newDrive.data.id}`);
                        setShowCopyModal(false);
                        return null;
                    }).catch((err) => {
                        errorHandler({ error: err });
                        setShowCopyModal(false);
                    });
                }}
                onClose={() => {
                    setShowCopyModal(false);
                    router.back();
                }}
                hideCancel
                variant="dark"
                isOpen={showCopyModal}
            />
        </>
    );
}

export default MyDriveDetail;
