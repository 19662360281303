import { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { rebuildReactTooltip } from '@bpm-web-app/utils';
import { CustomIcon } from '../../shared/custom-icon/custom-icon';
import styles from './header-profile-button.module.css';
import ProfileIcon from '../../../assets/icons/profile-icon.svg';
import CloseIcon from '../../../assets/icons/close.svg';

interface HeaderProfileButtonProps {
    isHidden?: boolean;
    imageAlt?: string;
    imageUrl?: string;
    onClick?: () => void;
    isMobile?: boolean;
    isOpen?: boolean;
    hasNotifications?: boolean;
    onlyDisplayImage?: boolean;
}

export function HeaderProfileButton({ isHidden, imageAlt, imageUrl, onClick, isMobile, isOpen, hasNotifications, onlyDisplayImage }: HeaderProfileButtonProps) {
    const profileIcon = useMemo(() => <ProfileIcon />, []);
    const closeIcon = useMemo(() => <CloseIcon />, []);

    const handleOnClickPressed = (e?: React.MouseEvent<HTMLElement>) => {
        e?.stopPropagation();
        e?.preventDefault();

        if (onClick) onClick();
    };

    const renderProfileIcon = useMemo(() => {
        return (
            <CustomIcon isIconHoverActive={isOpen} type="profile-icon" hasIconHover hasBackgroundHover size="regular" containerSize="regular" tooltip="Account" hasActiveState tooltipBottomOffset={-4} />
        );
    }, [isOpen]);

    useEffect(() => {
        rebuildReactTooltip();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isMobile) {
        return (
            <button
                type="button"
                aria-label="Open Nav"
                className={classNames(styles['header-profile-button__mobile-profile-btn'], {
                    [styles['header-profile-button__mobile-profile-btn--notification-active']]: hasNotifications,
                })}
                onClick={onClick}
            >
                {isOpen ? closeIcon : profileIcon}
            </button>
        );
    }

    if (onlyDisplayImage) {
        return (
            imageUrl ? (
                <img
                    src={imageUrl}
                    alt={imageAlt || ''}
                    className={styles['header-profile-button__profile-img']}
                />
            ) : (
                profileIcon
            )
        );
    }

    return (
        isHidden !== true ? (
            <button type="button" aria-label="profile context menu" className={styles['header-profile-button__profile']} onClick={handleOnClickPressed} data-tip="Account" data-offset={'{\'bottom\': -4}'}>
                <span className={styles['header-profile-button__profile-img-wrapper']}>
                    {imageUrl ? (
                        <img
                            src={imageUrl}
                            alt={imageAlt || ''}
                            width={32}
                            height={32}
                        />
                    ) : (
                        renderProfileIcon
                    )}
                </span>
            </button>
        ) : null);
}
