import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchGenres } from '@bpm-web-app/swr-hooks';
import classNames from 'classnames';
import { Collapse } from 'react-collapse';
import { useSupremeFilterParams } from '@bpm-web-app/utils';
import { SearchPaginatedQuery } from '@bpm-web-app/api-client';
import Checkboxes from '../shared/checkboxes/checkboxes';
import styles from './genres-filter.module.css';
import { SearchInput } from '../shared/search-input/search-input';
import ChevronUp from '../../assets/icons/chevron-up.svg';

export interface GenresFilterProps {
    value: string[];
    onChange: (value: string[]) => void;
    justTags?: boolean;
    viewAll?: boolean;
    rectangularCheckboxes?: boolean;
    onDisable?: (disabled: boolean) => void
    defaultFilters?: Partial<SearchPaginatedQuery>
}

export function GenresFilter({ value, onChange, justTags = false, viewAll, rectangularCheckboxes, onDisable, defaultFilters }: GenresFilterProps) {
    const query = useSupremeFilterParams(true);
    const { genres: genresData } = useSearchGenres({ ...query, ...defaultFilters });

    const genres = useMemo(() =>
        genresData?.map((genre) => ({
            label: genre.name,
            value: genre.slug,
            count: genre.available_count
        })) || [],
        [genresData]);

    useEffect(() => {
        onDisable?.(genres.length === 0);
    }, [genres, onDisable]);

    const [filteredGenres, setFilteredGenres] = useState(genres);
    const [isExpanded, setIsExpanded] = useState<boolean>(viewAll || false);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [isSectionExpanded, setIsSectionExpanded] = useState(true);

    const isSearching = useMemo(() => searchQuery.trim() !== '', [searchQuery]);

    const currentGenres = useMemo(() => {
        let newGenres = genres;
        if (isSearching && searchQuery) {
            newGenres = newGenres.filter((genre) => genre.value.toLowerCase().includes(searchQuery.toLowerCase()));
        }
        return newGenres;
    }, [searchQuery, isSearching, genres]);

    useEffect(() => {
        let newGenres = currentGenres;
        const selectedOptions = newGenres.filter((option) => value.includes(option.value));
        const unselectedOptions = newGenres.filter((option) => !value.includes(option.value));
        const sortedSelectedGenres = [...selectedOptions, ...unselectedOptions];

        if (!isExpanded) {
            newGenres = sortedSelectedGenres.slice(0, 10);
        } else {
            newGenres = sortedSelectedGenres.slice();
        }
        setFilteredGenres(newGenres);
    }, [isExpanded, currentGenres, value]);

    const filterActiveCount = value.length;

    const handleSectionToggle = useCallback(() => {
        if (!isSectionExpanded) {
            setIsExpanded(false);
        }
        setIsSectionExpanded(!isSectionExpanded);
    }, [isSectionExpanded]);

    return (
        <div className={classNames('filter', styles['genres-filter'])}>
            {justTags
                ? (
                    <div className={styles['genres-filter__checkboxes']}>
                        <Checkboxes hasMaxHeight={32} hasRectangularCheckboxes={rectangularCheckboxes} options={filteredGenres} value={value} onChange={onChange} />
                    </div>
                )
                : (
                    <>
                        <div
                            role="button"
                            onClick={handleSectionToggle}
                            tabIndex={0}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleSectionToggle();
                                }
                            }}
                            className="filter__header">
                            <div className={classNames('filter__title', { 'filter__title-active': filterActiveCount > 0 })}>Genres {filterActiveCount > 0 ? `(${filterActiveCount})` : ''}</div>
                            {filterActiveCount > 0 && (
                                <button
                                    type="button"
                                    className="filter__reset"
                                    onClick={() => {
                                        setSearchQuery('');
                                        onChange([]);
                                    }}
                                >
                                    Remove
                                </button>
                            )}
                            <button
                                aria-label="Expand/Collapse Genres"
                                type="button"
                                onClick={handleSectionToggle}>
                                <ChevronUp className={classNames('filter__chevron', { 'filter__chevron--expanded': isSectionExpanded })} />
                            </button>
                        </div>
                        <Collapse isOpened={isSectionExpanded}>
                            <div className={styles['genres-filter__search']}>
                                <SearchInput
                                    placeholder="Search Genres"
                                    value={searchQuery}
                                    onChange={(e) => {
                                        setSearchQuery(e.target.value);
                                    }}
                                    onClear={() => setSearchQuery('')}
                                />
                            </div>
                            <div className={styles['genres-filter__checkboxes']}>
                                <Checkboxes hasMaxHeight={32} hasRectangularCheckboxes={rectangularCheckboxes} options={filteredGenres} value={value} onChange={onChange} />
                            </div>
                            {currentGenres.length > 10 && (
                                <button type="button" onClick={() => setIsExpanded(!isExpanded)} className="filter__view-more">
                                    {isExpanded ? 'View Less' : 'View More'}
                                </button>
                            )}
                        </Collapse>
                    </>
                )}
        </div>
    );
}

export default GenresFilter;
