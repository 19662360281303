import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { UrlObject } from 'url';
import { useWindowResizing } from '@bpm-web-app/utils';
import { NavContext } from '@bpm-web-app/components';
import ChevronLeft from '../../../assets/icons/chevron-left.svg';
import ChevronRight from '../../../assets/icons/chevron-right.svg';
import styles from './card-carousel.module.css';
import SecondaryPageTitle from '../secondary-page-title/secondary-page-title';
import { AppLink } from '../app-link/app-link';
import SeeMore from '../see-more-button/see-more-btn';

export interface CardCarouselProps {
    title?: string;
    titleCounter?: string | number;
    children?: ReactElement[] | null;
    seeMorePath?: string | UrlObject;
    isInContainer?: boolean;
    noPadding?: boolean;
}

export function CardCarousel({ title = '', children, seeMorePath, titleCounter, isInContainer = false, noPadding = false }: CardCarouselProps) {
    const cardListRef = useRef<HTMLDivElement>(null);

    const [isAtTheStart, setIsAtTheStart] = useState(true);
    const [isAtTheEnd, setIsAtTheEnd] = useState(false);
    const [isScrollable, setIsScrollable] = useState(false);
    const [resizeIndex, setResizeIndex] = useState(1);

    const { isNavOpen } = useContext(NavContext);

    useWindowResizing(() => {

    }, () => {
        setResizeIndex((i) => (i + 1));
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setResizeIndex((i) => (i + 1));
        }, 400);
    }, [isNavOpen]);

    const onScroll = () => {
        if (cardListRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = cardListRef.current;

            if (scrollLeft + clientWidth === scrollWidth) {
                setIsAtTheEnd(true);
                setIsAtTheStart(false);
            } else if (scrollLeft <= 1) {
                setIsAtTheEnd(false);
                setIsAtTheStart(true);
            } else {
                setIsAtTheEnd(false);
                setIsAtTheStart(false);
            }
        }
    };

    function handleScroll(left = false) {
        if (left) cardListRef.current?.scrollBy({ left: 700, behavior: 'smooth' });
        else cardListRef.current?.scrollBy({ left: -700, behavior: 'smooth' });
    }

    useEffect(() => {
        if (cardListRef.current) {
            const scrollableCheck = cardListRef.current?.scrollWidth > cardListRef.current?.clientWidth;
            setIsScrollable(scrollableCheck);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardListRef.current, children?.length, resizeIndex]);

    return (
        <div className={styles['card-carousel']}>
            <div
                className={classNames(styles['card-carousel__controls-container'], {
                    [styles['card-carousel__controls-container--no-right-margin']]: isInContainer,
                })}
            >
                {title !== '' ?
                    (seeMorePath ? (
                        <AppLink href={seeMorePath}>
                            <a className={classNames(styles['card-carousel__heading'], 'underline-link')}>
                                <SecondaryPageTitle title={title} counter={titleCounter} noPadding={noPadding} />
                            </a>
                        </AppLink>
                    ) : (
                        <SecondaryPageTitle title={title} counter={titleCounter} noPadding={noPadding} />
                    )) : <div />}
                <div className={styles['card-carousel__controls']}>
                    <button
                        type="button"
                        aria-label="Previous Page"
                        disabled={isAtTheStart || !isScrollable}
                        className={classNames(styles['card-carousel__arrow-btn'], styles['card-carousel__prev-btn'], {
                            [styles['card-carousel__arrow-btn__default']]: !isAtTheStart
                        })}
                        onClick={() => handleScroll()}>
                        <ChevronLeft />
                    </button>
                    <button
                        type="button"
                        aria-label="Next Page"
                        disabled={isAtTheEnd || !isScrollable}
                        className={classNames(styles['card-carousel__arrow-btn'], styles['card-carousel__next-btn'], {
                            [styles['card-carousel__arrow-btn__default']]: !isAtTheEnd
                        })}
                        onClick={() => handleScroll(true)}>
                        <ChevronRight />
                    </button>
                    {seeMorePath && isScrollable && (
                        <>
                            <AppLink href={seeMorePath}>
                                <a className={classNames(styles['card-carousel__see-more--desktop'])}><SeeMore expand={false} variant="text" /></a>
                            </AppLink>
                            <AppLink href={seeMorePath}>
                                <a className={classNames(styles['card-carousel__see-more'], styles['card-carousel__see-more--mobile'], styles['card-carousel__arrow-btn'])} aria-label="See more">
                                    <ChevronRight />
                                </a>
                            </AppLink>
                        </>
                    )}
                </div>
            </div>
            <div className={classNames(styles['card-carousel__card-list'], noPadding ? '' : 'spacing--left-first-child')} ref={cardListRef} onScroll={onScroll}>
                {React.Children.map(children, (child) => (
                    <div key={child?.props.id}>{child}</div>
                ))}
            </div>
        </div>
    );
}

export default CardCarousel;
