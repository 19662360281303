import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useCurrentUser, useCurrentUserAddress } from '@bpm-web-app/swr-hooks';
import { apiKey, User } from '@bpm-web-app/api-client';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import router from 'next/router';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { DEFAULT_ACCOUNT_DROPDOWN_CATEGORY, getCurrentPlatformLink, useApiErrorHandler, useHubSwitch, showToast, useUserSettings, getSignupRedirectLinkAccountPlans } from '@bpm-web-app/utils';
import styles from './account-overview.module.css';
import Devices from './devices/devices';
import EditIcon from '../../../assets/icons/edit.svg';
import ProfileImage from '../../../assets/icons/profile-icon.svg';
import { ActionModal } from '../../shared/action-modal/action-modal';
import Dropdown from '../../dropdown/dropdown';
import { AppLink, BreakpointView } from '../../shared';
import SubscriptionAccountSummary from '../account-plan/subscription-item/subscription-account-summary';

interface Inputs {
    first_name: string;
    last_name: string;
    email: string;
    username: string;
    dj_type: string;
    dob: string;
    company: string;
    phone: string;
    current_password: string;
    new_password: string;
    new_password_confirm: string;
}

const DJTypeOptions = [
    {
        name: 'Mobile DJ',
        value: 'Mobile DJ',
    },
    {
        name: 'Recreational DJ',
        value: 'Recreational DJ'
    },
    {
        name: 'Club DJ',
        value: 'Club DJ'
    },
    {
        name: 'Radio DJ',
        value: 'Radio DJ'
    },
    {
        name: 'Bedroom DJ',
        value: 'Bedroom DJ'
    }
];

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AccountOverviewProps { }
export function AccountOverview(props: AccountOverviewProps) {
    const fileRef = useRef<HTMLInputElement>();
    const { data, isLoading: isLoadingUser, mutate: mutateUser } = useCurrentUser();
    const { data: userAddressData, isLoading: isAddressLoading, mutate: mutateAddress } = useCurrentUserAddress();
    const user = data?.data ? data.data.user : undefined;
    const userAddress = userAddressData?.data;
    const [image, setImage] = useState(null);
    const [showPaypalModal, setShowPaypalModal] = useState(false);
    const { isCreate, isArtistPlatform } = useHubSwitch();
    const { status } = router.query;

    const { isAnonymous } = useUserSettings();

    useEffect(() => {
        if (isAnonymous) {
            router.replace(getSignupRedirectLinkAccountPlans());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAnonymous]);

    const schema = Joi.object({
        first_name: Joi.string().required().messages({ 'string.empty': 'First name is required. Please try again.' }),
        last_name: Joi.string().required().messages({ 'string.empty': 'Last name is required. Please try again.' }),
        email: Joi.string()
            .email({ tlds: { allow: false } })
            .required()
            .messages({
                'string.email': 'This email address is not valid. Please try again.',
                'string.empty': 'Email is required. Please try again.',
            }),
        dj_type: Joi.any().required().messages({ 'any.required': 'DJ type is required. Please try again.' }),
        dob: Joi.any().required().messages({ 'any.required': 'DOB is required. Please try again.' }),
        company: Joi.any().optional().allow(''),
        phone: Joi.string()
            .optional().allow('')
            .min(6)
            .max(20)
            .messages({
                'string.min': 'Phone is invalid. Please try again.',
                'string.max': 'Phone is invalid. Please try again.',
                'string.pattern.base': 'Phone is invalid. Please try again.',
            }),
        current_password: Joi.string().optional().allow(''),
        new_password: Joi.string().optional().allow(''),
        new_password_confirm: Joi.string().optional().allow(''),
        username: Joi.string().optional(),
    }).custom((obj, helper) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { new_password, new_password_confirm, current_password } = obj;
        if (!current_password && (new_password || new_password_confirm)) {
            return helper.error('string.current_password.invalid');
        }
        if (new_password !== new_password_confirm) {
            return helper.error('string.passwords.invalid');
        }
        return obj;
    }).messages({
        'string.current_password.invalid': "You've entered an invalid password. Please try again.",
        'string.passwords.invalid': 'Passwords do not match. Please try again.',
    });

    const errorHandler = useApiErrorHandler(isCreate);

    const [activeCategory, setActiveCategory] = useState<string>(DEFAULT_ACCOUNT_DROPDOWN_CATEGORY.value);

    useEffect(() => {
        if (!isLoadingUser && user?.dj_type) {
            setActiveCategory(user.dj_type);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingUser]);

    const {
        register,
        handleSubmit,
        reset,
        formState: { isDirty, dirtyFields, isSubmitting, errors, submitCount },
        setValue,
        control,
    } = useForm({
        defaultValues: useMemo(
            () => ({
                first_name: userAddress?.first_name,
                last_name: userAddress?.last_name,
                email: user?.email,
                username: user?.username,
                dj_type: user?.dj_type,
                dob: user?.dob,
                company: userAddress?.company,
                phone: userAddress?.phone,
                current_password: '',
                new_password: '',
                new_password_confirm: '',
            }),
            [user, userAddress]
        ),
        resolver: joiResolver(schema),
    });

    useEffect(() => {
        if (status === 'success') {
            setShowPaypalModal(true);
        }
    }, [status]);

    useEffect(() => {
        reset({
            email: user?.email,
            username: user?.username,
            dj_type: user?.dj_type,
            dob: user?.dob,
            first_name: userAddress?.first_name,
            last_name: userAddress?.last_name,
            company: userAddress?.company,
            phone: userAddress?.phone,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, userAddress]);

    useEffect(() => {
        if (Object.keys(errors).length !== 0) {
            toast.dismiss();
            Object.keys(errors).forEach((key) => {
                if (errors?.[key as keyof Inputs]?.message) {
                    showToast({ type: 'error', title: errors?.[key as keyof Inputs]?.message });
                }
            });
        }
    }, [errors, submitCount]);

    const uploadImage = async (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files.length) return;
        try {
            setImage(e.target.files?.[0]);
            const file = e.target.files?.[0];
            const formData = new FormData();
            formData.append('image', file, file.name);
            const key = apiKey();
            const headers = {} as { [key: string]: string };
            if (key) {
                // eslint-disable-next-line @typescript-eslint/dot-notation
                headers['Authorization'] = key;
            }
            // eslint-disable-next-line @typescript-eslint/dot-notation
            const response = await fetch(`${process.env['NEXT_PUBLIC_SUPREME_API_BASE_PATH']}/users/me/picture`, {
                method: 'POST',
                credentials: 'include',
                body: formData,
                headers
            });

            if (!response.ok) throw new Error(response.statusText);
            showToast({ type: 'success', message: 'Photo upload successful.' });
            mutateUser();
        } catch (error) {
            errorHandler({ error });
        }
    };

    const onSubmit = async (formData: Inputs) => {
        let detailsUpdated = false;
        if (formData.current_password && formData.new_password) {
            try {
                await User.setMyPassword({
                    old_password: formData.current_password,
                    new_password: formData.new_password,
                });
                showToast({ type: 'success', message: 'Password saved successfully.' });
                reset({ new_password: '', new_password_confirm: '', current_password: '' });
            } catch (error) {
                errorHandler({ error });
            }
        }

        try {
            if (dirtyFields.dj_type || dirtyFields.dob || dirtyFields.email || dirtyFields.username) {
                await User.updateMe({
                    dj_type: formData.dj_type,
                    dob: formData.dob,
                    email: formData.email,
                    username: formData.username
                });
                detailsUpdated = true;
            }

            if (dirtyFields.first_name || dirtyFields.last_name || dirtyFields.company || dirtyFields.phone) {
                await User.setMyCurrentAddress({
                    first_name: formData.first_name,
                    last_name: formData.last_name,
                    company: formData.company,
                    phone: formData.phone,
                });
                detailsUpdated = true;
            }

            if (detailsUpdated) {
                await Promise.all([mutateUser(), mutateAddress()]);
                showToast({ type: 'success', message: 'Details saved successfully.' });
            }
        } catch (error) {
            errorHandler({ error });
        }
    };

    const renderSubscriptionOverview = useMemo(() => {
        if (isArtistPlatform) return null;
        return (
            <>
                <h3>Your Subscription</h3>
                <SubscriptionAccountSummary isCreate={isCreate || false} />
            </>
        );
    }, [isArtistPlatform, isCreate]);

    if (!user || !userAddress || isLoadingUser || isAddressLoading) return null;

    return (
        <>
            <ActionModal
                headerTitle="PayPal"
                title="Your PayPal account was successfully connected"
                subtitle=""
                confirmButtonText={isCreate ? 'Start Browsing' : 'Browse Music'}
                onConfirm={() => router.replace(getCurrentPlatformLink('/'))}
                onClose={() => {
                    setShowPaypalModal(false);
                }}
                hideCancel
                variant="dark"
                isOpen={showPaypalModal}
            />
            <div className={styles['account-overview']}>
                <div className={styles['account-overview__user-infos']}>
                    <div className={styles['account-overview__profile-img-container-mobile']}>
                        <div className={styles['account-overview__profile-img-wrapper']}>
                            {image || user?.profile_image_url ? <img src={image ? URL.createObjectURL(image) : user?.profile_image_url} alt={user.full_name} /> : <ProfileImage />}
                        </div>
                        <input type="file" accept="image/*" ref={fileRef} onChange={(e) => uploadImage(e)} multiple={false} hidden />
                        <button onClick={() => fileRef.current.click()} className={styles['account-overview__profile-edit-container']} type="button">
                            <EditIcon />
                        </button>
                    </div>
                    <div className={styles['account-overview__user-info']}>
                        <div className={styles['account-overview__user-name']}>{`${user?.first_name} ${user?.last_name}`}</div>
                        <div className={styles['account-overview__user-id']}>{`Customer # ${user?.id}`}</div>
                    </div>
                </div>
                <div className={styles['account-overview__mobile-divider']} />
                <BreakpointView
                    desktopChildren={null}
                    mobileChildren={renderSubscriptionOverview}
                />
                <h2 className={styles['account-overview__desktop-section-title']}>Account</h2>
                {isArtistPlatform ? <h2 className={styles['account-overview__desktop-section-body']}>Manage your personal information for your BPM Music For Artists account and BPM ID, these settings do not affect your public artist profile on BPM Supreme. To update your public artist profile, please use the <AppLink href="/manage-profile" key="manage-profile-button" replace><a className="underline-link">Manage Profile</a></AppLink> page.</h2> : null}
                {isArtistPlatform ? <div className={styles['account-overview__desktop-divider']} /> : null}

                <div className={styles['account-overview__personal']}>
                    {isArtistPlatform ? <h2 className={styles['account-overview__mobile-section-title']}>Account</h2> : null}
                    {isArtistPlatform ? <h2 className={styles['account-overview__mobile-section-body']}>Manage your personal information for your BPM Music For Artists account and BPM ID, these settings do not affect your public artist profile on BPM Supreme. To update your public artist profile, please use the <AppLink href="/manage-profile" key="manage-profile-button" replace><a className="underline-link">Manage Profile</a></AppLink> page.</h2> : null}

                    <BreakpointView
                        desktopChildren={renderSubscriptionOverview}
                        mobileChildren={null}
                    />

                    <h3>Personal Information </h3>
                    <div className={styles['account-overview__profile-img-container']}>
                        <div className={styles['account-overview__profile-img-wrapper']}>
                            {image || user?.profile_image_url ? <img src={image ? URL.createObjectURL(image) : user?.profile_image_url} alt={user.full_name} /> : <ProfileImage />}
                        </div>
                        <input type="file" accept="image/*" ref={fileRef} onChange={(e) => uploadImage(e)} multiple={false} hidden />
                        <button onClick={() => fileRef.current.click()} className={styles['account-overview__profile-edit-container']} type="button">
                            <EditIcon />
                        </button>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={styles['account-overview__form-container']}>
                            <input {...register('first_name')} type="text" placeholder="First Name" className="generic__input" />
                            <input {...register('last_name')} type="text" placeholder="Last Name" className="generic__input" />
                            <input {...register('username')} type="text" placeholder="Username" className="generic__input" />

                            <input {...register('phone')} type="tel" placeholder="Phone Number" className="generic__input" />

                            <input {...register('email')} type="text" placeholder="Email" className="generic__input" />

                            <div className={styles['account-overview__datepicker-container']}>
                                <Controller
                                    name="dob"
                                    control={control}
                                    render={({ field }) => (
                                        <DatePicker
                                            placeholderText="DOB: MM/DD/YYYY"
                                            className="generic__input"
                                            maxDate={new Date()}
                                            selected={new Date(field.value ?? '1990/01/01')}
                                            openToDate={new Date(field.value ?? '1990/01/01')}
                                            onChange={(date) => field.onChange(date)}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                        />
                                    )}
                                />
                            </div>
                            {isArtistPlatform ? null : <input {...register('company')} type="text" placeholder="Company" className="generic__input" />}
                            {isArtistPlatform
                                ? null
                                :
                                <div className={styles['account-overview__dj-type-dropdown-container']}>
                                    <Dropdown<string>
                                        onClick={(option) => {
                                            setActiveCategory(option);
                                            setValue('dj_type', option, { shouldDirty: true });
                                        }}
                                        containerClassname={classNames(styles['account-overview__dj-type-dropdown'], {
                                            [styles['account-overview__dj-type-dropdown--placeholder']]: activeCategory === DEFAULT_ACCOUNT_DROPDOWN_CATEGORY.value
                                        })}
                                        value={activeCategory}
                                        options={[
                                            DEFAULT_ACCOUNT_DROPDOWN_CATEGORY,
                                            ...(DJTypeOptions?.map((category) => ({
                                                label: category.name,
                                                value: category.value,
                                            })) || []),
                                        ]}
                                        {...register('dj_type', { required: 'DJ type is required' })}
                                    />
                                </div>
                            }
                        </div>
                        <h3>Change Password</h3>
                        <div className={styles['account-overview__form-container']}>
                            <input {...register('current_password')} type="password" placeholder="Current Password" className="generic__input" />
                            <input {...register('new_password')} type="password" placeholder="New Password" className="generic__input" />
                            <input {...register('new_password_confirm')} type="password" placeholder="Confirm New Password" className="generic__input" />
                        </div>
                        <button disabled={isSubmitting || !isDirty} type="submit" className={classNames('button button--solid', styles['account-overview__cta'])}>
                            Save Changes
                        </button>
                    </form>
                </div>
            </div>
            <Devices platform={isCreate ? 'create' : 'supreme'} />
        </>
    );
}

export default AccountOverview;
