import { useHubSwitch } from '@bpm-web-app/utils';
import { ReactNode, createContext, useState, useMemo, useCallback } from 'react';
import { useLibraryTabs } from '../../../../../utils/src/lib/library-tabs.context';

export type ActionType =
    | 'maxi-player'
    | 'supreme-maxi-player'
    | 'mini-player'
    | 'maxi-player-versions'
    | 'mini-player-versions'
    | 'user-playlist'
    | 'for-you-playlist'
    | 'add-to-playlist'
    | 'exclusive-playlist'
    | 'stream:album'
    | 'curatedSet'
    | 'track'
    | 'artist'
    | 'download:album'
    | 'download:track'
    | 'online-crate'
    | 'offline-crate'
    | 'user-playlist-track'
    | 'queue-track'
    | 'for-you-playlist-detail'
    | 'download-accordion'
    | 'news';

export type ThreeDotsSheetOptionsKeys =
    | 'add-to-queue'
    | 'add-to-crate'
    | 'add-to-playlist'
    | 'add-to-favorites'
    | 'find-remixes'
    | 'find-similar'
    | 'follow-artist'
    | 'follow-playlist'
    | 'follow-curated-set'
    | 'remove-from-queue'
    | 'featured-in'
    | 'report-track'
    | 'edit-playlist'
    | 'delete-playlist'
    | 'download-version'
    | 'delete-track'
    | 'share';

const artistActiveOptions: ThreeDotsSheetOptionsKeys[] = ['follow-artist'];

const forYouPlaylistDetailActiveOptions: ThreeDotsSheetOptionsKeys[] = ['add-to-queue', 'add-to-crate', 'add-to-playlist'];

const trackActiveOptions: ThreeDotsSheetOptionsKeys[] = ['add-to-crate', 'add-to-favorites', 'add-to-queue', 'add-to-playlist', 'find-remixes', 'find-similar'];

const maxiPlayerOptions: ThreeDotsSheetOptionsKeys[] = ['add-to-crate', 'add-to-playlist', 'add-to-favorites', 'find-remixes', 'find-similar', 'featured-in'];

const supremeMaxiPlayerOptions: ThreeDotsSheetOptionsKeys[] = ['add-to-crate', 'add-to-playlist', 'add-to-favorites'];

const downloadAccordionOptions: ThreeDotsSheetOptionsKeys[] = ['add-to-queue', 'add-to-crate', 'find-similar'];

const playerVersionOptions: ThreeDotsSheetOptionsKeys[] = ['add-to-crate', 'add-to-queue', 'download-version', 'find-remixes', 'find-similar'];

const queueTrackOptions: ThreeDotsSheetOptionsKeys[] = ['add-to-playlist', 'add-to-crate', 'add-to-favorites', 'find-remixes', 'find-similar', 'remove-from-queue'];

const miniPlayerOptions: ThreeDotsSheetOptionsKeys[] = ['add-to-crate', 'add-to-playlist', 'add-to-favorites', 'find-remixes', 'find-similar'];

const downloadTrackActiveOptions: ThreeDotsSheetOptionsKeys[] = ['add-to-crate', 'add-to-queue'];

const albumActiveOptions: ThreeDotsSheetOptionsKeys[] = ['add-to-crate', 'add-to-favorites', 'add-to-queue', 'add-to-playlist'];

const downloadAlbumActiveOptions: ThreeDotsSheetOptionsKeys[] = ['add-to-crate', 'add-to-favorites', 'add-to-queue', 'add-to-playlist', 'find-remixes', 'find-similar'];

const exclusivePlaylistActiveOptions: ThreeDotsSheetOptionsKeys[] = ['add-to-crate', 'add-to-queue', 'add-to-playlist', 'follow-playlist'];

const forYouPlaylistActiveOptions: ThreeDotsSheetOptionsKeys[] = ['add-to-crate', 'add-to-queue', 'add-to-playlist'];

const userPlaylistActiveOptions: ThreeDotsSheetOptionsKeys[] = ['edit-playlist', 'add-to-queue', 'delete-playlist'];

const curatedSetsActiveOptions: ThreeDotsSheetOptionsKeys[] = ['add-to-crate', 'add-to-queue', 'add-to-playlist', 'follow-curated-set'];

const onlineCrateActiveOptions: ThreeDotsSheetOptionsKeys[] = ['delete-track', 'add-to-favorites', 'find-remixes', 'find-similar'];

const offlineCrateActiveOptions: ThreeDotsSheetOptionsKeys[] = ['delete-track', 'add-to-favorites', 'find-remixes', 'find-similar'];

const userPlaylistTrackActiveOptions: ThreeDotsSheetOptionsKeys[] = [...trackActiveOptions, 'delete-track'];

export type RenderLocationType = 'app' | 'notification' | 'action-modal' | 'sidebar';

export interface ThreeDotsSheetContextProperties {
    setActionTypeId: (id: number | string) => void;
    actionTypeId: number | string;
    /** Opens the 3 dots menu with the options based in the `ActionType` specified.
     * Content ID should be passed, along with left and top absolute position on the pressed button.
     * mediaList is optional and can be used if an action needs to be taken against multiple media,
     * e.g. adding multiple media to a user playlist */
    openThreeDotsModalSheet: (
        actionType: ActionType,
        actionTypeId: number | string,
        left: number,
        top: number,
        showOnlyPlaylist: boolean,
        secondaryActionTypeId?: number | string | null,
        mediaList?: number[],
        renderLocation?: RenderLocationType
    ) => void;
    closeThreeDotsModalSheet: () => void;
    /** Active options in the 3 dots menu */
    optionsToShow: ThreeDotsSheetOptionsKeys[];
    shareURL?: string | undefined;
    /** Current ActionType */
    actionType: ActionType;
    leftPosition: number;
    topPosition: number;
    showOnlyPlaylist: boolean;
    mediaIdsList?: number[];
    setSecondaryActionTypeId: (id: number | string | null) => void;
    move: (x: number, y: number) => void;
    secondaryActionTypeId: string | number | null | undefined;
    renderLocation?: RenderLocationType;
}

export const ThreeDotsSheetContext = createContext<ThreeDotsSheetContextProperties>({
    setActionTypeId: () => null,
    /** Initial Type ID is -1 so we can check if we should present 3 dots components or not */
    actionTypeId: -1,
    openThreeDotsModalSheet: () => null,
    closeThreeDotsModalSheet: () => null,
    optionsToShow: [],
    shareURL: undefined,
    leftPosition: 0,
    topPosition: 0,
    actionType: 'user-playlist',
    showOnlyPlaylist: false,
    mediaIdsList: undefined,
    setSecondaryActionTypeId: () => null,
    move: (x: number, y: number) => null,
    secondaryActionTypeId: null,
    renderLocation: 'app',
});

export interface ThreeDotsSheetProviderProps {
    children: ReactNode;
    initialState?: {
        actionTypeId: number | string;
        actionType?: ActionType;
        leftPosition?: number;
        topPosition?: number;
        optionsToShow?: ThreeDotsSheetOptionsKeys[];
        shareURL?: string | undefined;
        showOnlyPlaylist?: boolean;
        mediaIdsList?: number[];
    };
}

export function ThreeDotsSheetProvider({ children, initialState }: ThreeDotsSheetProviderProps) {
    const [actionType, setActionType] = useState<ActionType>(initialState?.actionType || 'user-playlist');
    const [actionTypeId, setActionTypeId] = useState<ThreeDotsSheetContextProperties['actionTypeId']>(initialState?.actionTypeId || -1);
    const [secondaryActionTypeId, setSecondaryActionTypeId] = useState<ThreeDotsSheetContextProperties['secondaryActionTypeId'] | undefined>();
    const [renderLocation, setRenderLocation] = useState<ThreeDotsSheetContextProperties['renderLocation']>('app');
    const [leftPosition, setLeftPosition] = useState<number>(initialState?.leftPosition || 0);
    const [topPosition, setTopPosition] = useState<number>(initialState?.topPosition || 0);
    const [showOnlyPlaylist, setShowOnlyPlaylist] = useState<boolean>(initialState?.showOnlyPlaylist || false);
    const [mediaIdsList, setMediaIdsList] = useState<number[] | undefined>(initialState?.mediaIdsList);
    const [optionsToShow, setOptionsToShow] = useState<ThreeDotsSheetOptionsKeys[]>(initialState?.optionsToShow || []);
    const [shareURL, setShareUrl] = useState<string | undefined>(initialState?.shareURL);
    const { isDownload } = useHubSwitch();
    const { library } = useLibraryTabs();
    const move = useCallback(
        (x: number, y: number) => {
            setLeftPosition(x);
            setTopPosition(y);
        },
        [setLeftPosition, setTopPosition]
    );

    const value = useMemo(
        () => ({
            actionType,
            actionTypeId,
            optionsToShow,
            shareURL,
            leftPosition,
            topPosition,
            showOnlyPlaylist,
            mediaIdsList,
            move,
            openThreeDotsModalSheet: (
                newActionType: ActionType,
                actionId: ThreeDotsSheetContextProperties['actionTypeId'],
                left: number,
                top: number,
                onlyPlaylist: boolean,
                secondaryActionId: ThreeDotsSheetContextProperties['secondaryActionTypeId'],
                mediaIdsArray?: number[],
                renderLocationPosition?: RenderLocationType
            ) => {
                /* if three dots menu already open and the next click was done on the same
                 * three dots icon as the first one, close the one that's currently open */
                if (actionId !== -1 && actionTypeId === actionId) {
                    setActionTypeId(-1);
                    return;
                }

                if (actionId) {
                    setActionTypeId(actionId);
                }

                if (secondaryActionId) {
                    setSecondaryActionTypeId(secondaryActionId);
                }

                if (renderLocationPosition) {
                    setRenderLocation(renderLocationPosition);
                } else {
                    setRenderLocation('app');
                }

                if (mediaIdsArray) {
                    setMediaIdsList(mediaIdsArray);
                }

                setShowOnlyPlaylist(onlyPlaylist);

                const activeOptions = (): ThreeDotsSheetOptionsKeys[] => {
                    switch (newActionType) {
                        case 'stream:album': {
                            return albumActiveOptions;
                        }
                        case 'artist': {
                            return artistActiveOptions;
                        }
                        case 'curatedSet': {
                            return curatedSetsActiveOptions;
                        }
                        case 'exclusive-playlist': {
                            return exclusivePlaylistActiveOptions;
                        }
                        case 'track': {
                            return trackActiveOptions;
                        }
                        case 'download:album': {
                            return downloadAlbumActiveOptions;
                        }
                        case 'download:track': {
                            return downloadTrackActiveOptions;
                        }
                        case 'queue-track': {
                            return queueTrackOptions;
                        }
                        case 'for-you-playlist':
                            return forYouPlaylistActiveOptions;
                        case 'user-playlist':
                            return userPlaylistActiveOptions;
                        case 'user-playlist-track': {
                            return userPlaylistTrackActiveOptions;
                        }
                        case 'maxi-player': {
                            return maxiPlayerOptions;
                        }
                        case 'download-accordion': {
                            return downloadAccordionOptions;
                        }
                        case 'maxi-player-versions':
                        case 'mini-player-versions': {
                            return playerVersionOptions;
                        }
                        case 'mini-player': {
                            return miniPlayerOptions;
                        }
                        case 'online-crate':
                            return onlineCrateActiveOptions;
                        case 'offline-crate':
                            return offlineCrateActiveOptions;
                        case 'for-you-playlist-detail':
                            return forYouPlaylistDetailActiveOptions;
                        case 'supreme-maxi-player':
                            return supremeMaxiPlayerOptions;
                        default: {
                            return [];
                        }
                    }
                };

                const shareUrl = (): string | undefined => {
                    switch (newActionType) {
                        case 'stream:album': {
                            return `/s/album/${actionId}?library=${library}`;
                        }
                        case 'artist': {
                            if (isDownload) {
                                return `/d/artist/${actionId}?library=${library}`;
                            }
                            return `/s/artist/${actionId}?library=${library}`;
                        }
                        case 'curatedSet': {
                            if (isDownload) {
                                return `/d/curated-set/${actionId}?library=${library}`;
                            }
                            return `/s/curated-set/${actionId}?library=${library}`;
                        }
                        case 'for-you-playlist':
                        case 'user-playlist': {
                            return '';
                        }
                        case 'exclusive-playlist': {
                            if (isDownload) {
                                return `/d/exclusive-playlist/${actionId}?library=${library}`;
                            }
                            return `/s/exclusive-playlist/${actionId}?library=${library}`;
                        }
                        case 'track': {
                            if (isDownload) {
                                return `/d/album/${actionId}?library=${library}`;
                            }
                            return `/s/album/${actionId}?library=${library}`;
                        }
                        case 'download:album': {
                            return `/d/album/${actionId}?library=${library}`;
                        }
                        case 'download-accordion': {
                            return '';
                        }
                        case 'download:track': {
                            if (isDownload) {
                                return `/d/album/${actionId}?library=${library}`;
                            }
                            return `/s/album/${actionId}?library=${library}`;
                        }
                        case 'maxi-player':
                        case 'supreme-maxi-player': {
                            if (isDownload) {
                                return `/d/album/${secondaryActionId}?library=${library}`;
                            }
                            return `/s/album/${secondaryActionId}?library=${library}`;
                        }
                        case 'maxi-player-versions':
                        case 'mini-player-versions': {
                            if (isDownload) {
                                return `/d/album/${secondaryActionId}?library=${library}`;
                            }
                            return `/s/album/${actionId}?library=${library}`;
                        }
                        case 'mini-player': {
                            if (isDownload) {
                                return `/d/album/${secondaryActionId}?library=${library}`;
                            }
                            return `/s/album/${actionId}?library=${library}`;
                        }
                        default: {
                            return '';
                        }
                    }
                };

                setShareUrl(shareUrl);
                setOptionsToShow(activeOptions);
                setActionType(newActionType);
                setTopPosition(top);
                setLeftPosition(left);
            },
            closeThreeDotsModalSheet: () => {
                setActionTypeId(-1);
            },
            setActionTypeId,
            secondaryActionTypeId,
            renderLocation,
            setSecondaryActionTypeId,
        }),
        [actionType, actionTypeId, optionsToShow, shareURL, leftPosition, topPosition, showOnlyPlaylist, mediaIdsList, move, secondaryActionTypeId, renderLocation, library, isDownload]
    );
    return <ThreeDotsSheetContext.Provider value={value}>{children}</ThreeDotsSheetContext.Provider>;
}
