import { Library } from '@bpm-web-app/stream-api-sdk';
import { Library as DownloadLibrary } from '@bpm-web-app/download-api-sdk';
import classNames from 'classnames';
import { ReactNode } from 'react';
import ReactModal from 'react-modal';
import CloseIcon from '../../../assets/icons/close.svg';
import styles from './action-modal.module.css';

export interface ActionModalProps {
    headerTitle?: string | ReactNode;
    onClose?: (canceled: boolean) => void;
    onConfirm?: () => void;
    confirmButtonText?: string;
    title?: string;
    /* eslint-disable react/require-default-props */
    subtitle?: string | ReactNode;
    cancelButtonText?: string;
    children?: ReactNode;
    childrenNoReplace?: ReactNode;
    variant?: 'light' | 'dark';
    hideCancel?: boolean;
    isOpen?: boolean;
    shouldCloseOnOverlayClick?: boolean;
    actionLoading?: boolean;
    contentClassname?: string
    renderInput?: (inputRenderKey?: string | number) => ReactNode
    inputRenderKey?: string | number
    /* eslint-enable react/require-default-props */
}
export const ActionModal = ({
    headerTitle,
    onClose,
    onConfirm,
    confirmButtonText,
    cancelButtonText = 'Cancel',
    children,
    childrenNoReplace,
    title,
    subtitle,
    variant = 'light',
    hideCancel = false,
    isOpen,
    shouldCloseOnOverlayClick = false,
    actionLoading = false,
    contentClassname,
    renderInput,
    inputRenderKey
}: ActionModalProps) => {
    const bodyContent = (
        <>
            {!headerTitle ? (
                <header className={styles['action-modal__header']}>
                    <p className="heading--h3">{title}</p>
                    {onClose && (
                        <button className={styles[`action-modal__header--close-${variant}`]} type="button" aria-label="Close" onClick={() => onClose(false)}>
                            <CloseIcon />
                        </button>
                    )}
                </header>
            ) : (
                <p className="heading--h3">{title}</p>
            )}
            {subtitle && <span>{subtitle}</span>}
        </>
    );

    const renderChildren = () => {
        if (childrenNoReplace) {
            return (
                <div className={styles['action-modal__body']}>
                    {bodyContent}
                    {children}
                </div>
            );
        }

        return (
            <div className={styles['action-modal__body']}>
                {children || bodyContent}
            </div>
        );
    };

    return (
        <ReactModal isOpen={!!isOpen} shouldCloseOnOverlayClick={shouldCloseOnOverlayClick} onRequestClose={() => onClose && onClose(false)} className={classNames('react-modal__content', contentClassname)} overlayClassName="react-modal__overlay">
            <div className={classNames(styles['action-modal'])}>
                <div className={classNames(styles['action-modal__container'], styles[`action-modal__container--${variant}`])}>
                    <header className={styles['action-modal__header']}>
                        {headerTitle ?? <h6 className={styles['action-modal__header-title']}>{headerTitle}</h6>}
                        {onClose && headerTitle && (
                            <button className={styles[`action-modal__header--close-${variant}`]} type="button" aria-label="Close" onClick={() => onClose && onClose(false)}>
                                <CloseIcon />
                            </button>
                        )}
                    </header>
                    {renderChildren()}
                    {renderInput && renderInput(inputRenderKey)}
                    <footer className={styles['action-modal__footer']}>
                        {onConfirm && (
                            <button
                                className={classNames(styles['action-modal__footer-button'], styles[`action-modal__footer-button--confirm-${variant}`])}
                                disabled={actionLoading}
                                type="button"
                                aria-label={`${confirmButtonText}`}
                                onClick={onConfirm}
                            >
                                <span className="heading--h4">{confirmButtonText}</span>
                            </button>
                        )}
                        {!hideCancel && (
                            <button
                                className={classNames(styles['action-modal__footer-button'], styles['action-modal__footer-button--cancel'])}
                                type="button"
                                aria-label={`${cancelButtonText}`}
                                onClick={() => onClose && onClose(true)}
                            >
                                <span className="heading--h4">{cancelButtonText}</span>
                            </button>
                        )}
                    </footer>
                </div>
            </div>
        </ReactModal>
    );
};

export const getLibraryName = (library: Library | DownloadLibrary): string => {
    switch (library) {
        case DownloadLibrary.Music:
        case Library.Music: {
            return 'Open Format';
        }
        case Library.Latin:
        case DownloadLibrary.Latin: {
            return 'Latino';
        }

        case DownloadLibrary.Artist: {
            return 'White Label';
        }

        default: {
            return '';
        }
    }
};

export const getPlanName = (membership: 1 | 2 | 3): string => {
    switch (membership) {
        case 1: {
            return 'Standard';
        }
        case 2: {
            return 'Premium';
        }

        case 3: {
            return 'Premium Plus';
        }

        default: {
            return '';
        }
    }
};

export const upgradeRequiredActionModalProps = ({ onClose, onConfirm, requiredSubscription }: (Pick<ActionModalProps, 'onClose' | 'onConfirm'> & { requiredSubscription: 2 | 3 })): ActionModalProps => ({
    headerTitle: 'Heads up!',
    title: 'Upgrade Required for Full Access',
    subtitle: `A ${getPlanName(requiredSubscription)} subscription is required to access this content.`,
    confirmButtonText: 'Upgrade Now',
    onConfirm,
    onClose,
});

export const membershipRequiredActionModalProps = ({ onClose, onConfirm, isCreate }: (Pick<ActionModalProps, 'onClose' | 'onConfirm'> & { isCreate?: boolean })): ActionModalProps => ({
    headerTitle: 'Heads up!',
    title: 'Subscription Required for Full Access',
    subtitle: isCreate ? 'Subscribe now for access to samples, sound packs and more.' : 'Request a subscription now for access to music and features.',
    confirmButtonText: 'Get Started',
    onConfirm,
    onClose,
});

export const libraryRequiredActionModalProps = ({ library, onClose, onConfirm }: Pick<ActionModalProps, 'onClose' | 'onConfirm'> & { library: Library | DownloadLibrary }): ActionModalProps => ({
    headerTitle: 'Heads up!',
    title: 'Additional Subscription Needed',
    subtitle: `You’ve selected content from the ${getLibraryName(library)} music library. Purchase the ${getLibraryName(library)} subscription plan to access this content.`,
    confirmButtonText: 'Get Started',
    onConfirm,
    onClose,
});

export const notEnoughCreditsActionModalProps = ({ creditsRequired, onClose, onConfirm }: Pick<ActionModalProps, 'onClose' | 'onConfirm'> & { creditsRequired: number }): ActionModalProps => ({
    headerTitle: 'Heads up!',
    title: 'Additional Credits Needed',
    subtitle: `Additional ${creditsRequired} Credits Needed! Get more credits here.`,
    confirmButtonText: 'Get More Credits',
    onConfirm,
    onClose,
});

export const dailyDownloadLimitReachedModalProps = ({ onClose, onConfirm }: Pick<ActionModalProps, 'onClose' | 'onConfirm'>): ActionModalProps => ({
    headerTitle: 'Heads up!',
    title: 'Unusual Activity Notice',
    subtitle: 'Your account has been downloading music at an abnormally high rate. Because of this, we have temporarily limited your ability to download music. We’ll get you back on track soon. Thanks for your patience! If you have questions, our support team is here to help.',
    confirmButtonText: 'Contact Support',
    cancelButtonText: 'Acknowledge',
    onConfirm,
    onClose,
});

export const weeklyDownloadLimitReachedModalProps = ({ onClose, onConfirm }: Pick<ActionModalProps, 'onClose' | 'onConfirm'>): ActionModalProps => ({
    headerTitle: 'Heads up!',
    title: 'Unusual Activity Notice',
    subtitle: 'Your account has been downloading music at an abnormally high rate. To maintain a great experience for all users, we may temporarily limit your ability to download music. If you have questions or need help, please reach out to support.',
    confirmButtonText: 'Contact Support',
    cancelButtonText: 'Acknowledge',
    onConfirm,
    onClose,
});
