import classNames from 'classnames';
import { useViewport } from '@bpm-web-app/utils';
import PlusIcon from '../../../assets/icons/artist-platform/plus.svg';

import styles from './see-more-btn.module.css';

export interface SeeMorePros {
    text?: string;
    onClick?: () => void;
    seeLess?: boolean;
    onSeeLess?: () => void;
    disabled?: boolean;
    expand?: boolean;
    variant?: 'container' | 'text';
}

export function SeeMore({ text = 'See More', onClick, seeLess = false, disabled, expand = true, variant = 'container', onSeeLess }: SeeMorePros) {
    const { isMobile } = useViewport();

    return (
        <>
            <button type="button" disabled={disabled} className={classNames({ [styles[`see-more-btn__${variant}`]]: variant, 'link-hover': variant === 'text' })} aria-label="See More" onClick={onClick}>
                {expand ? <PlusIcon /> : null}
                {text}
            </button>
            {
                !isMobile && onSeeLess && (!!seeLess) ? (
                    <button type="button" disabled={!seeLess} className={classNames({ 'link-hover': seeLess }, styles['see-more-btn__see-less'])} aria-label="See Less" onClick={onSeeLess}>
                        See Less
                    </button>
                ) : null
            }
        </>
    );
}

export default SeeMore;
