export function formatBigNumber(nr?: number, defaultStr = '', roundDown = false) {
    if (nr === undefined) {
        return defaultStr;
    }

    if (roundDown) {
        const formatter = Intl.NumberFormat('en', {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            notation: 'compact',
            minimumFractionDigits: 1,
            maximumFractionDigits: 4,
            minimumSignificantDigits: 1,
            maximumSignificantDigits: 4,
        });
        return formatter.format(nr);
    }

    const formatter = Intl.NumberFormat('en', {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        notation: 'compact',
    });
    return formatter.format(nr);
}

export const formatBillingPeriod = (period: number, alwaysIncludeNumber?: boolean) => {
    if (period === 1) {
        return alwaysIncludeNumber ? '1 month' : 'month';
    }

    if (period === 12) {
        return alwaysIncludeNumber ? '1 year' : 'year';
    }

    return `${period} months`;
};
