import classNames from 'classnames';
import { useMemo, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Grid, Swiper as SwiperConfig } from 'swiper';
import { createAppRoutes, DEFAULT_BROWSE_GENRES_LIMIT, useHubSwitch, useUniqueArray } from '@bpm-web-app/utils';
import { Genre as StreamGenre } from '@bpm-web-app/stream-api-sdk';
import { Genre as CreateGenre } from '@bpm-web-app/create-api-sdk';
import styles from './genres-carousel.module.css';
import ChevronLeft from '../../assets/icons/chevron-left.svg';
import ChevronRight from '../../assets/icons/chevron-right.svg';
import { AppLink } from '../shared/app-link/app-link';
import { GhostComponent } from '../shared';
import SeeMore from '../shared/see-more-button/see-more-btn';

/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
export interface GenresCarouselProps {
    genres: Pick<StreamGenre, 'id' | 'name' | 'slug'>[] | Pick<CreateGenre, 'id' | 'name' | 'slug'>[];
    isLoading: boolean;
}

export function GenresCarousel({ genres, isLoading }: GenresCarouselProps) {
    const [, setSwiper] = useState<SwiperConfig>();
    const ghostElementsKeys = useUniqueArray(20);

    const prevButtonRef = useRef<HTMLButtonElement>(null);
    const nextButtonRef = useRef<HTMLButtonElement>(null);

    const { isCreate } = useHubSwitch();

    const genresToDisplay = useMemo(() => (genres ? genres?.slice(0, DEFAULT_BROWSE_GENRES_LIMIT) : []), [genres]);

    return (
        /* eslint-disable-next-line @typescript-eslint/dot-notation */
        <div className={styles['genres']}>
            <div className={styles['genres__controls-container']}>
                <AppLink href="/genres">
                    <a aria-label="See more" className="underline-link">
                        <h6 className={classNames(styles['genres__heading'], 'spacing__window--left')}>Genres</h6>
                    </a>
                </AppLink>
                <div className={styles['genres__controls']}>
                    <button type="button" aria-label="Previous Page" ref={prevButtonRef} className={classNames(styles['genres__arrow-btn'], styles['genres__arrow-btn--prev'])}>
                        <ChevronLeft />
                    </button>
                    <button type="button" aria-label="Next Page" ref={nextButtonRef} className={classNames(styles['genres__arrow-btn'], styles['genres__arrow-btn--next'])}>
                        <ChevronRight />
                    </button>
                    <AppLink href="/genres">
                        <a className={classNames(styles['genres__see-more'], styles['genres__see-more--desktop'])}><SeeMore expand={false} variant="text" /></a>
                    </AppLink>
                    <AppLink href="/genres">
                        <a className={classNames(styles['genres__see-more'], styles['genres__see-more--mobile'], styles['genres__arrow-btn'])} aria-label="See more">
                            <ChevronRight />
                        </a>
                    </AppLink>
                </div>
            </div>
            <div className={styles['genres__container']}>
                <Swiper
                    modules={[Navigation, Grid]}
                    className={classNames(styles['genres__swiper'], 'spacing--left-first-child')}
                    threshold={8}
                    breakpoints={{
                        0: {
                            grid: {
                                rows: 3,
                                fill: 'row',
                            },
                        },
                        1024: {
                            grid: {
                                rows: 2,
                                fill: 'row',
                            },
                        },
                    }}
                    slidesPerView="auto"
                    slidesPerGroupAuto
                    navigation={{
                        prevEl: prevButtonRef.current,
                        nextEl: nextButtonRef.current,
                    }}
                    onSwiper={setSwiper}
                >
                    {!!genresToDisplay && !isLoading &&
                        genresToDisplay.map((genre) => (
                            <SwiperSlide key={genre.id} className={styles['genres__list-item-wrapper']}>
                                <AppLink href={isCreate ? createAppRoutes.genresSlug(genre.name) : `/genres?genre=${genre.slug}`} isFilterLink>
                                    <a className={styles['genres__list-item']} draggable={false}>
                                        {genre.name}
                                    </a>
                                </AppLink>
                            </SwiperSlide>
                        ))}
                    {isLoading ? <div className="spacing__window--horizontal">{ghostElementsKeys.map(() => <GhostComponent type="genre" itemClass="shimmer" />)}</div> : null}
                </Swiper>
            </div>
        </div>
    );
}

export default GenresCarousel;
