import { SubscriptionPackage } from '@bpm-web-app/supreme-api-sdk';
import { getColorFromLibrary, monthlyPlanText } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { useMemo } from 'react';
import CheckIcon from '../../../../../assets/icons/check.svg';
import styles from './checkout-item.module.css';

export type PlanItem = {
    name: string;
    amount: number;
    type: string;
    libraryText: string
    library?: SubscriptionPackage['library'];
    additionalText: string;
    promoCode?: string;
    promoCodeDiscount?: number;
    trialDiscount?: number;
    isNew?: boolean;
    payToday?: number;
    isCreate: boolean;
    isOneTime?: boolean;
};

// eslint-disable-next-line max-len
function CheckoutItem({ name, amount, type, libraryText, additionalText, promoCode = null, promoCodeDiscount = 0, isNew = true, payToday = 0, isCreate, isOneTime = false, trialDiscount = 0, library }: PlanItem) {
    const AdditionalTextComponent = useMemo(() => {
        if (isCreate && additionalText) {
            return (
                <ul className={styles['checkout-item__description-create']}>
                    {additionalText.split('\n').map((line) => (
                        <li key={line} className={styles['checkout-item__description-create--item']}>
                            <CheckIcon />
                            {line}
                        </li>
                    ))}
                </ul>
            );
        }

        return <p className={styles['checkout-item__description']}>{additionalText}</p>;
    }, [additionalText, isCreate]);

    const bgClass = useMemo(() => (library || isCreate) ? styles[`checkout-item__content__background--${isCreate ? 'generic' : getColorFromLibrary(library)}`] : '', [library, isCreate]);
    const borderClass = useMemo(() => (library || isCreate) ? styles[`checkout-item__border--${isCreate ? 'generic' : getColorFromLibrary(library)}`] : '', [library, isCreate]);

    return (
        <div className={classNames(styles['checkout-item'], borderClass)}>
            <div className={styles['checkout-item__header']}>
                <span className={styles['checkout-item__header--title']}>{name || type}</span>
                <span>{monthlyPlanText(amount, isOneTime)}</span>
            </div>
            {isCreate && !name ? null : <div className={classNames(styles['checkout-item__type'], styles['checkout-item__type--title'])}>{type}</div>}
            <p className={styles['checkout-item__library']}>{isCreate ? ' ' : `Primary Library: ${libraryText}`}</p>
            {Boolean(trialDiscount) && (
                <div className={classNames(styles['checkout-item__content'], styles['checkout-item__content-discount'])}>
                    <p>{isOneTime ? 'Discount' : 'Trial Discount'}</p>
                    <p>{monthlyPlanText(trialDiscount, true)}</p>
                </div>
            )}
            {promoCode && (
                <div className={classNames(styles['checkout-item__content'], styles['checkout-item__content-discount'])}>
                    <p>{`Promo Code*: ${promoCode}`}</p>
                    <p>{monthlyPlanText(promoCodeDiscount * -1, isNew || isOneTime)}</p>
                </div>
            )}
            <div className={classNames(styles['checkout-item__content'], { [styles['checkout-item__content__pay']]: isNew, [bgClass]: isNew })}>
                <p>{isNew ? 'Pay Today' : 'Package Total'}</p>
                <p>{isNew ? monthlyPlanText(payToday, true) : monthlyPlanText(amount, isOneTime)}</p>
            </div>
            {additionalText && AdditionalTextComponent}
        </div>
    );
}

export default CheckoutItem;
