import { useMemo } from 'react';
import { useViewport } from '@bpm-web-app/utils';
import ModalSlider from '../modal-slider/modal-slider';
import notificationsDesktop from '../../../assets/onboarding/desktop/notifications.gif';
import notificationsMobile from '../../../assets/onboarding/mobile/notifications.gif';

export function OnboardingModalNotifications() {
    const { isMobile } = useViewport();

    const data = useMemo(
        () => [
            {
                image: isMobile ? notificationsMobile : notificationsDesktop,
                title: 'Notifications',
                text: 'Check your notifications here, such as when a playlist you follow is updated, a new track by a favorite artist is added, and more.',
                href: 'https://www.youtube.com/watch?v=qHD_1j7w1VQ&list=PLrlMfzKBjbOiofesOOuZM-302p-EpYkZs',
            },
        ],
        [isMobile]
    );

    return <ModalSlider type="ModalNotifications" sliderItems={data} />;
}

export default OnboardingModalNotifications;
