import { Platform } from '@bpm-web-app/api-client';
import { DOWNLOAD_ONLY, getCurrentPlatformLink, localStorageAdd, useHubSwitch, useOnClickOutside } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

// Icons
import SearchIconActive from '../../../assets/icons/search-icon-nav-active.svg';
import SearchIcon from '../../../assets/icons/search-icon-nav.svg';

import Filters from '../../filters/filters';
import { FiltersContext } from '../../filters/filters.context';
import { NavContext } from '../../nav/nav.context';
import { PlaylistsFormProps } from '../../playlists-form/playlists-form';
import PredictiveSearch from '../../predictive-search/predictive-search';
import PredictiveSearchMobile from '../../predictive-search/predictive-search-mobile';
import { SearchInput } from '../../shared';
import styles from './search-form.module.css';

interface SearchFormProps {
    isMobile?: boolean;
    setOpenForm: React.Dispatch<React.SetStateAction<null | PlaylistsFormProps>>;
}

export function SearchForm({ isMobile = false, setOpenForm }: SearchFormProps) {
    const { hub } = useHubSwitch();
    const router = useRouter();
    const { ref: predictiveSearchRef, showComponent, setShowComponent } = useOnClickOutside(false);
    const isSearchPage = useMemo(() => router.pathname === '/search', [router.pathname]);
    const [searchTerm, setSearchTerm] = useState('');
    const { isFiltersOpen, openFilters, closeFilters } = useContext(FiltersContext);
    const { hideSearchBar, } = useContext(NavContext);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        setSearchTerm((router?.query['searchTerm'] as string) || '');
    }, [router?.query]);

    const handleSearch = useCallback(
        (e?: React.FormEvent<HTMLElement>, term?: string, selectSoundPage?: boolean) => {
            const termToUse = term || searchTerm;
            if (termToUse.trim()) {
                setShowComponent(false);
                localStorageAdd('search-history', termToUse);
                router.push({
                    pathname: getCurrentPlatformLink('/search'),
                    query: {
                        searchTerm: termToUse,
                        ...(selectSoundPage ? { type: 'albums' } : {}),
                    },
                });
                if (term) {
                    setSearchTerm(term);
                }
            }
            e?.preventDefault();
        },
        [router, searchTerm, setShowComponent]
    );

    useEffect(() => {
        setShowComponent(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.query]);

    useEffect(() => {
        if (searchTerm) {
            setShowComponent(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    if (isMobile) {
        return (
            <form className={classNames(styles['search-form--legacy-mobile__search'])}>
                {/* @TODO setup react hook form */}
                <div className={styles['search-form--legacy-mobile__search-container-input']}>
                    <input
                        id="supreme-search-input"
                        type="text"
                        placeholder="Search artists, tracks, playlists and more."
                        name="searchTerm"
                        aria-label="Search Field"
                        autoComplete="off"
                        value={searchTerm}
                        className={styles['search-form--legacy-mobile__search-input']}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                        }}
                    />
                    <button
                        type="submit"
                        aria-label="Search"
                        className={styles['search-form--legacy-mobile__search-submit']}
                        onClick={(e) => {
                            handleSearch(e);
                        }}
                    >
                        {searchTerm?.length ? <SearchIconActive /> : <SearchIcon />}
                    </button>
                    {isMobile && isSearchPage && (
                        <button
                            onClick={() => (isFiltersOpen ? closeFilters() : openFilters())}
                            type="button"
                            aria-label={isFiltersOpen ? 'Close Filters' : 'Open Filters'}
                            className={styles['search-form--legacy-mobile__search-filters']}
                        >
                            {isFiltersOpen ? <i className="icon icon--controls-close" /> : <i className="icon icon--nav-filter" />}
                        </button>
                    )}
                    {!!searchTerm && (
                        <>
                            {isMobile ? <Filters platform={hub as Platform} rightAlign={DOWNLOAD_ONLY} inlineMobileStyle /> : null}
                            <button
                                onClick={() => {
                                    hideSearchBar();
                                    setSearchTerm('');
                                }}
                                type="button"
                                aria-label="Clear Search"
                                className={styles['search-form--legacy-mobile__search-clear']}
                            >
                                <i className="icon icon--controls-clear" />
                            </button>
                        </>
                    )}
                </div>
                {searchTerm && (
                    <PredictiveSearchMobile
                        term={searchTerm}
                        setTerm={(term) => {
                            if (term) {
                                handleSearch(undefined, term, true);
                            }
                        }}
                    />
                )}
            </form>
        );
    }

    return (
        <div ref={predictiveSearchRef}>
            <form className={styles['search-form__search']} onSubmit={handleSearch}>
                <SearchInput
                    id="supreme-search-input"
                    placeholder="Search artists, tracks, playlists and more."
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                    }}
                    onClear={() => setSearchTerm('')}
                    variant="large"
                    onFocus={() => setShowComponent(true)}
                    isAlternateMobileLayout
                    isActive={showComponent}
                />

                <PredictiveSearch
                    term={searchTerm}
                    setTerm={(term) => {
                        if (term) {
                            handleSearch(undefined, term);
                        }
                    }}
                    setOpenForm={setOpenForm}
                    handleCancel={() => setShowComponent(false)}
                    isVisible={showComponent}
                />
            </form>
        </div>
    );
}
