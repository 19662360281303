import { FC, InputHTMLAttributes } from 'react';

import CheckmarkIcon from './checkbox-icon.svg';
import styles from './checkbox.module.css';

type CheckboxProps = InputHTMLAttributes<HTMLInputElement>;

export const Checkbox: FC<CheckboxProps> = ({ ...props }) => (
    // eslint-disable-next-line @typescript-eslint/dot-notation
    <span className={styles['checkbox']}>
        <input {...props} type="checkbox" className={styles['checkbox__input']} />
        <span className={styles['checkbox__ui']} aria-hidden>
            <CheckmarkIcon />
        </span>
    </span>
);
