import { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { getPlatformLinkFromHub, getPlatformLinkUsingRouter, getSignupRedirectLinkAccountPlans, rebuildReactTooltip, useHubSwitch, useUserSettings } from '@bpm-web-app/utils';
import { useGetMeSubscription } from '@bpm-web-app/swr-hooks';
import { SubscriptionDetailsv4 } from '@bpm-web-app/supreme-api-sdk';
import UpgradePlan from '../../assets/icons/upgrade-plan.svg';

import { useOnDragElement } from '../drag-and-drop/drag-and-drop.context';
import styles from './nav-footer.module.css';
import { PlainNavItem } from './plain-nav-item';

export function NavFooter() {
    const { isArtistPlatform, isCreate, isDownload, hub } = useHubSwitch();
    const { isDragging } = useOnDragElement();
    const { isAnonymous } = useUserSettings();
    const router = useRouter();

    useEffect(() => {
        rebuildReactTooltip();
    }, []);

    const { data } = useGetMeSubscription('supreme', !isDownload);
    const { data: createData } = useGetMeSubscription('create', !isCreate);

    const showUpgrade = useMemo(() => {
        if (isCreate) {
            return createData?.data.membership.subscription?.membership_type !== SubscriptionDetailsv4.MembershipTypeEnum.PremiumPlus;
        }
        if (!isCreate && !isArtistPlatform) {
            return data?.data.membership.subscription?.membership_type !== SubscriptionDetailsv4.MembershipTypeEnum.Premium;
        }
        return false;
    }, [createData, data, isCreate, isArtistPlatform]);

    return (
        <div className={styles['nav-footer']}>
            {showUpgrade && (
                <PlainNavItem
                    title="Upgrade Plan"
                    icon={<UpgradePlan />}
                    activeIcon={<UpgradePlan />}
                    action={() => {
                        router.push(getPlatformLinkFromHub(hub, `/account/plan?library=${router.query.library?.toString() || 'music'}`));
                    }}
                    isDragging={isDragging}
                    disableDragDrop
                />
            )}
        </div>
    );
}
