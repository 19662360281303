import { getPlatformLinkFromHub, useHubSwitch } from '@bpm-web-app/utils';
import Link, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import React, { useContext, useMemo } from 'react';
import * as qs from 'query-string';
import { FiltersContext } from '../../filters/filters.context';

type AppLinkProps = LinkProps & {
    /**
     * Allows to pass the href directly to the `Link` component, this transformation only occurs
     * in the supreme platform. This is needed when href is an object
     */
    overrideAppLinkTransform?: boolean;

    isFilterLink?: boolean
    forceCurrentWindow?: boolean
};

const testLink = (href: string) => {
    try {
        return new URL(href).hostname !== window.location.hostname;
    } catch (error) {
        return false;
    }
};

export const AppLink: React.FC<AppLinkProps> = ({ children, overrideAppLinkTransform, isFilterLink, forceCurrentWindow, ...rest }) => {
    const { isCreate, isArtistPlatform, hub } = useHubSwitch();
    const router = useRouter();
    const { updateFiltersFromQuery } = useContext(FiltersContext);
    if (!rest.href) {
        // eslint-disable-next-line no-param-reassign
        rest.href = '';
    }
    const isExternalLink = React.useMemo(() => testLink(rest.href.toString()), [rest.href]);
    let currentChildren = children || null;

    let linkWithPlatform = useMemo(() => {
        if (isCreate || isArtistPlatform || overrideAppLinkTransform || isExternalLink) return rest.href;

        if (typeof rest.href === 'object') {
            return {
                ...rest.href,
                pathname: getPlatformLinkFromHub(hub, (rest.href.pathname || '').toString())
            };
        }

        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return getPlatformLinkFromHub(hub, rest.href.toString());
    }, [isCreate, isArtistPlatform, overrideAppLinkTransform, isExternalLink, rest.href, hub]);

    const overrideSmoothScroll = (pathname: string, hash: string) => {
        const potentialLinkOrButton = React.Children.toArray(children)[0];
        if ((potentialLinkOrButton as React.ReactElement).type === 'a' || (potentialLinkOrButton as React.ReactElement).type === 'button') {
            currentChildren = React.cloneElement(potentialLinkOrButton as React.ReactElement, {
                onClick: (e: React.MouseEvent<HTMLElement>) => {
                    const element = document.getElementById(hash.slice(1));
                    if (router.pathname === pathname && element) {
                        e.preventDefault();
                        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }
                    try {
                        (potentialLinkOrButton as React.ReactElement).props.onClick?.();
                        // eslint-disable-next-line no-empty
                    } catch (error) {
                    }
                }
            });
        }
    };

    if (typeof linkWithPlatform === 'object') {
        if (linkWithPlatform.pathname?.includes('#')) {
            linkWithPlatform.hash = linkWithPlatform.pathname.substring(linkWithPlatform.pathname.indexOf('#'));
            linkWithPlatform.pathname = linkWithPlatform.pathname.substring(0, linkWithPlatform.pathname.indexOf('#'));
            overrideSmoothScroll(linkWithPlatform.pathname, linkWithPlatform.hash);
        }
    } else if (typeof linkWithPlatform === 'string') {
        if (linkWithPlatform.includes('#')) {
            const path = linkWithPlatform;
            linkWithPlatform = { pathname: path.substring(0, path.indexOf('#')), hash: path.substring(path.indexOf('#')) };
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            overrideSmoothScroll(linkWithPlatform.pathname!, linkWithPlatform.hash!);
        }
    }

    if (!forceCurrentWindow && isExternalLink && children && React.Children.count(children) > 0) {
        const potentialLink = React.Children.toArray(children)[0];
        if ((potentialLink as React.ReactElement).type === 'a') {
            currentChildren = React.cloneElement(potentialLink as React.ReactElement, {
                target: '_blank'
            });
        }
    }

    if (isFilterLink && children && React.Children.count(children) > 0) {
        const potentialLink = React.Children.toArray(children)[0];
        if ((potentialLink as React.ReactElement).type === 'a') {
            const link = React.cloneElement(potentialLink as React.ReactElement, {
                onClick: () => {
                    if (typeof linkWithPlatform === 'object' && linkWithPlatform.query) {
                        updateFiltersFromQuery(linkWithPlatform.query as any);
                    } else {
                        const { query } = qs.parseUrl(linkWithPlatform as string);
                        if (query) {
                            updateFiltersFromQuery(query as any);
                        }
                    }
                }
            });

            currentChildren = link;
        }
    }

    return (
        <Link
            {...rest}
            href={linkWithPlatform}>
            {currentChildren}
        </Link>
    );
};
