/* eslint-disable jsx-a11y/anchor-is-valid */
import { appendQueryParams, useUserSettings } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { ReactNode, useCallback, useRef } from 'react';
import { Artist } from '@bpm-web-app/download-api-sdk';
import styles from './title-column.module.css';
import ExclusiveTag from '../../../../assets/icons/exclusive-tag.svg';
import PlayIcon from '../../../../assets/icons/player-play.svg';
import PauseIcon from '../../../../assets/icons/player-pause.svg';
import NowPlayingAnimation from '../../now-playing-animation/now-playing-animation';
import { AppLink } from '../../app-link/app-link';
import { CustomIcon } from '../../custom-icon/custom-icon';

export interface TitleColumnProps {
    /* eslint-disable react/require-default-props */
    coverUrl?: string;
    title: string;
    linkedArtist?: Artist
    subtitle?: ReactNode;
    isExclusive: boolean;
    hasThreeLinesOnMobile: boolean;
    hasPlainPlayButton?: boolean;
    playClick?: () => void;
    isPlaying?: boolean;
    thirdLineMobileContent?: ReactNode;
    isPlayable?: boolean;
    isPremiumOnly?: boolean;
    hasPremiumOnlyAccess?: boolean;
    isVersionItem?: boolean;
    /* eslint-enable react/require-default-props */
}

function TitleColumn({
    coverUrl,
    title,
    subtitle,
    isExclusive,
    hasThreeLinesOnMobile,
    hasPlainPlayButton,
    playClick,
    thirdLineMobileContent,
    isPlayable = true,
    isPlaying,
    linkedArtist,
    isPremiumOnly,
    hasPremiumOnlyAccess,
    isVersionItem
}: TitleColumnProps) {
    const spanRef = useRef<HTMLSpanElement>(null);
    const { isAnonymous } = useUserSettings();

    const renderTitle = useCallback(() => {
        if (linkedArtist) {
            const startIndex = title.indexOf(linkedArtist.name);
            if (startIndex > -1) {
                const endIndex = startIndex + linkedArtist.name.length;

                // Extract parts of the title
                const beforeLink = title.substring(0, startIndex);
                const linkText = title.substring(startIndex, endIndex);
                const afterLink = title.substring(endIndex);
                const artistUrl = `/artist/${linkedArtist.slug}`;

                return (
                    <>
                        <span data-tip={title}>{beforeLink}</span>
                        <AppLink href={artistUrl}>
                            <a
                                className="underline-link"
                                data-for="artist-tooltip"
                                data-tip={`${linkedArtist.name}|${linkedArtist.slug}|${linkedArtist.id}|${linkedArtist.artwork_url}`}
                                onClick={(e) => e.stopPropagation()}
                                onKeyDown={() => { }}
                                role="link"
                                tabIndex={-5}>{linkText}
                            </a>
                        </AppLink>
                        <span data-tip={title}>{afterLink}</span>
                    </>
                );
            }
        }
        return title;
    }, [linkedArtist, title]);

    return (
        <div
            className={classNames(styles['title-column'], {
                [styles['title-column--three-lines-mobile']]: hasThreeLinesOnMobile,
                [styles['title-column--no-subtitle']]: subtitle === undefined
            })}
        >
            {hasPlainPlayButton ? (
                <button type="button" onClick={playClick} className={styles['title-column__play-btn']}>
                    {isPlaying ? <NowPlayingAnimation /> : <PlayIcon />}
                </button>
            ) : (
                coverUrl && (
                    <button type="button" className={styles['title-column__cover-wrapper']} onClick={playClick}>
                        {!isPlaying ? <img
                            src={appendQueryParams(coverUrl, { key: 'dw', value: 56 })}
                            srcSet={`${appendQueryParams(coverUrl, { key: 'dw', value: 112 })} 2x`}
                            alt={title}
                            className={styles['title-column__cover']}
                        /> : <NowPlayingAnimation />
                        }
                        {isPlayable && <div className={styles['title-column__cover-play-overlay']}>{!isPlaying ? <PlayIcon /> : <PauseIcon />}</div>}
                    </button>
                )
            )}
            <div className={classNames(styles['title-column__title'], { [styles['title-column__title--active']]: isPlaying })}>
                <span id="tracklist-title" ref={spanRef}>{renderTitle()}</span>
                {isPremiumOnly === true && !hasPremiumOnlyAccess && !isAnonymous ? (
                    <CustomIcon type="lock-premium-icon" size={15} tooltip="For Premium Subscribers Only" hasIconHover />
                ) : null}
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                {isExclusive && !(isPremiumOnly === true && !hasPremiumOnlyAccess && !isAnonymous) && !(isPremiumOnly && hasPremiumOnlyAccess) && (
                    <div className={styles['title-column__exclusive']}>
                        <ExclusiveTag />
                    </div>
                )}
                {isPremiumOnly && hasPremiumOnlyAccess && !isVersionItem && <span className={styles['title-column__premium-tag']}>PREM</span>}
            </div>
            {subtitle !== undefined && <div className={classNames(styles['title-column__subtitle'], { [styles['title-column__subtitle--active']]: isPlaying })}>{subtitle}</div>}
            {hasThreeLinesOnMobile && <div className={styles['title-column__third-line-mobile']}>{thirdLineMobileContent}</div>}
        </div>
    );
}
export default TitleColumn;
/* eslint-enable jsx-a11y/anchor-is-valid */
