import { DrawWaveOptions } from '@bpm-web-app/utils';
import { PlayerWaveVariant } from '../types';

export const PlayerWaveCanvasConfig: {
    [K in PlayerWaveVariant]: DrawWaveOptions;
} = {
    mini: {
        detailDivFactor: 2,
        barWidth: 1.25,
        height: 32,
    },
    'maxi-desktop': {
        detailDivFactor: 2,
        barWidth: 1.5,
        height: 48,
    },
    'maxi-tablet': {
        detailDivFactor: 2,
        barWidth: 1.5,
        height: 32,
    },
    'maxi-mobile': {
        detailDivFactor: 2,
        barWidth: 1.5,
        height: 32,
    },
};
