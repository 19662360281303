import { useCallback, useEffect, useRef } from 'react';

export const useWindowResizing = (onResize: () => void, onResizeEnd: () => void, deps: any[]) => {
    /* istanbul ignore next */
    const timer = useRef<null | ReturnType<typeof setTimeout>>(null);

    /* istanbul ignore next */
    const resizeListener = useCallback(() => {
        if (timer.current) {
            clearTimeout(timer.current);
            timer.current = null;
        }
        onResize();
        timer.current = setTimeout(() => {
            onResizeEnd();
            timer.current = null;
        }, 100);
    }, [onResize, onResizeEnd]);

    /* istanbul ignore next */
    useEffect(() => {
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    }, [onResize, onResizeEnd, ...deps]);
};
