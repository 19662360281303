import { useCallback, useState } from 'react';
import Cropper, { Area } from 'react-easy-crop';
import BPMIcons from '../bpm-icons/bpm-icons';
import styles from './crop-image.module.css';

function getRadianAngle(degreeValue: number) {
    return (degreeValue * Math.PI) / 180;
}

export default function getCroppedImg(imageSrc: string, pixelCrop: Area, rotation = 0) {
    const image = new Image();
    image.src = imageSrc;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const maxSize = Math.max(image.width || 0, image?.height || 0);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx?.translate(safeArea / 2, safeArea / 2);
    ctx?.rotate(getRadianAngle(rotation));
    ctx?.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx?.drawImage(
        image,
        safeArea / 2 - image.width * 0.5,
        safeArea / 2 - image.height * 0.5
    );

    const data = ctx?.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    if (data) {
        // paste generated rotate image with correct offsets for x,y crop values.
        ctx?.putImageData(
            data,
            0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
            0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
        );
    }

    // As Base64 string
    return canvas.toDataURL('image/jpeg');
    // return canvas;
}

export const dataURLtoFile = (dataurl: string, filename: string) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1] || '';
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    // eslint-disable-next-line no-plusplus
    while (n--) u8arr[n] = bstr.charCodeAt(n);

    return new File([u8arr], filename, { type: mime });
};

interface CropImageProps {
    imageUrl: string;
    onUpload?: (croppedImage: File) => void;
    onCancel?: () => void;
    title?: string;
    subtitle?: string;
    hasRoundMaskCrop?: boolean;
}

export function CropImage({ imageUrl, onUpload, onCancel, title, subtitle, hasRoundMaskCrop = true }: CropImageProps) {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const ZOOM_CONSTANT = 0.5;

    const [, setCroppedArea] = useState<Area | undefined>(undefined);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | undefined>(undefined);

    const onUploadComplete = () => {
        if (croppedAreaPixels) {
            const croppedFile = dataURLtoFile(getCroppedImg(imageUrl, croppedAreaPixels), 'profilePhoto');
            if (onUpload) onUpload(croppedFile);
        } else if (onCancel) onCancel();
    };

    const onCropComplete = useCallback((croppedAreaValue: Area, croppedAreaPixelsValue: Area) => {
        setCroppedArea(croppedAreaValue);
        setCroppedAreaPixels(croppedAreaPixelsValue);
    }, []);

    const useZoom = useCallback((zoomIn: boolean) => {
        if (zoomIn && zoom < 9) {
            const zoomAmount = zoom + (ZOOM_CONSTANT);

            if (zoomAmount > 10) setZoom(10);
            else setZoom(zoom + (ZOOM_CONSTANT));
        }
        if (!zoomIn && zoom > ZOOM_CONSTANT) {
            const zoomAmount = zoom - (ZOOM_CONSTANT);

            if (zoomAmount < 1) setZoom(1);
            else setZoom(zoom - (ZOOM_CONSTANT));
        }
    }, [zoom]);

    return (
        <div className={styles['crop-image']}>
            <h3>{title}</h3>
            <p className={styles['crop-image__subtitle']}>{subtitle}</p>
            <div className={styles['crop-image__crop-container']}>
                <Cropper
                    image={imageUrl}
                    crop={crop}
                    zoom={zoom}
                    aspect={1 / 1}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                    classes={{
                        containerClassName: styles['crop-image__containerClassName'],
                        cropAreaClassName: hasRoundMaskCrop ? styles['crop-image__cropAreaClassName'] : styles['crop-image__hiddenCropAreaClassName'],
                    }}
                />
            </div>

            <div className={styles['crop-image__buttons']}>
                <div className={styles['crop-image__left-buttons']}>
                    <button
                        className={styles['crop-image__button']}
                        type="button"
                        onClick={() => useZoom(false)}
                    >
                        <BPMIcons.MinusIcon />
                    </button>

                    <button
                        className={styles['crop-image__button']}
                        type="button"
                        onClick={() => useZoom(true)}
                    >
                        <BPMIcons.PlusIcon />
                    </button>
                </div>
                <div className={styles['crop-image__right-buttons']}>
                    <button
                        className="button--outline"
                        type="button"
                        onClick={onCancel}
                    >
                        Cancel
                    </button>

                    <button
                        className="button--solid"
                        type="button"
                        onClick={onUploadComplete}
                    >
                        Upload Photo
                    </button>
                </div>
            </div>
        </div>
    );
}
