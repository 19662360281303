import useSWR from 'swr';
import { CreateSearchPaginatedQuery, Sound } from '@bpm-web-app/api-client';
import useSWRInfinite, { SWRInfiniteConfiguration } from 'swr/infinite';
import { useMemo } from 'react';

export function useSearchSound(query: CreateSearchPaginatedQuery) {
    const opts = {
        persist: false,
        ...query,
    };

    const { data, error } = useSWR(['create-search-sound', opts], () => Sound.getSounds(query));

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

export function useInfiniteSearchSound(query: CreateSearchPaginatedQuery, enabled = true, swrOptions?: SWRInfiniteConfiguration<any>) {
    const pageSize = query?.limit || 50;

    const { data, error, size, setSize, isValidating, mutate } = useSWRInfinite<Awaited<ReturnType<typeof Sound['getSounds']>> | null>(
        (index) => [`create-search-sound-${JSON.stringify({ ...query, enabled })}-${index}`],
        (key) => {
            /* extract page index back from key name */
            const pageIndex = key.split('-').pop() || '0';
            return enabled
                ? Sound.getSounds({
                    ...query,
                    skip: +pageIndex * pageSize,
                })
                : null;
        },
        { ...swrOptions }
    );

    const isLoadingInitialData = !data && !error;
    const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === 'undefined');
    const isEmpty = data?.[0]?.data?.length === 0;
    const flatData = useMemo(() => data?.flatMap((m) => m?.data || []), [data]);
    const isLastPage = isEmpty || (data?.[0]?.pagination.total || 0) <= (flatData?.length || 0);

    return {
        data,
        flatData,
        isLoadingInitialData,
        isLoadingMore,
        isValidating,
        isLastPage,
        error,
        size,
        setSize,
        mutate,
    };
}

export function useInfiniteSearchPresets(query: CreateSearchPaginatedQuery, enabled = true, swrOptions?: SWRInfiniteConfiguration<any>) {
    const pageSize = query?.limit || 50;

    const { data, error, size, setSize, isValidating, mutate } = useSWRInfinite<Awaited<ReturnType<typeof Sound['getPresets']>> | null>(
        (index) => [`create-search-preset-${JSON.stringify({ ...query, enabled })}-${index}`],
        (key) => {
            /* extract page index back from key name */
            const pageIndex = key.split('-').pop() || '0';
            return enabled
                ? Sound.getPresets({
                    ...query,
                    skip: +pageIndex * pageSize,
                })
                : null;
        },
        { ...swrOptions }
    );

    const isLoadingInitialData = !data && !error;
    const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === 'undefined');
    const isEmpty = data?.[0]?.data?.length === 0;
    const flatData = useMemo(() => data?.flatMap((m) => m?.data || []), [data]);
    const isLastPage = isEmpty || (data?.[0]?.pagination.total || 0) <= (flatData?.length || 0);

    return {
        data,
        flatData,
        isLoadingInitialData,
        isLoadingMore,
        isValidating,
        isLastPage,
        error,
        size,
        setSize,
        mutate,
    };
}

export function getDownloadedSounds(query: CreateSearchPaginatedQuery, isEnable: boolean) {
    const { data, error } = useSWR(
        [
            'get-downloaded-sounds',
            {
                ...query,
                persist: true,
                isEnable,
            },
        ],
        () => (isEnable ? Sound.getDownloadedSounds(query) : null)
    );

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}
