import classNames from 'classnames';
import { Button } from '../../components/button/button';
import styles from './cancel-modal.module.css';
import { Modal, ModalLabel } from '../modal/modal';
import { LoadingEllipsis } from '../../../shared';
import modalStyles from '../modal/modal.module.css';
import InfoIcon from '../../../../assets/icons/supreme/info.svg';

interface ConfirmCancelModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void
    isCancelLoading: boolean;
    isCreate: boolean
}

export function ConfirmCancelModal({
    isOpen,
    onClose,
    onConfirm,
    isCancelLoading,
    isCreate
}: ConfirmCancelModalProps) {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <div className={styles['cancel-modal__content']}>
                <h2 className={styles['cancel-modal__heading']}>Cancel Subscription</h2>

                <div>
                    <ModalLabel>
                        Are you sure you want to cancel your subscription?
                        <br />
                    </ModalLabel>

                    {isCreate && (
                        <div className={styles['cancel-modal__info']}>
                            <div>
                                <InfoIcon />
                            </div>
                            <ModalLabel>
                                You will lose your credits when canceling a subscription.
                            </ModalLabel>
                        </div>
                    )}
                </div>

                <div className={styles['cancel-modal__buttons']}>
                    <Button
                        disabled={isCancelLoading}
                        className={classNames(modalStyles['modal__button'], modalStyles['modal__button--destructive'])}
                        type="button"
                        as="button"
                        variant="destructive"
                        onClick={onConfirm}>
                        {isCancelLoading ? <LoadingEllipsis /> : 'Confirm Cancellation'}
                    </Button>
                    <Button
                        className={modalStyles['modal__button']}
                        type="button"
                        variant="primary"
                        onClick={onClose}>
                        Cancel
                    </Button>

                </div>
            </div>
        </Modal>
    );
}
