import { PlanItem } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { useMemo } from 'react';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/check.svg';
import styles from './subscription-item.module.css';
import { SquaredButton } from '../../../shared/squared-button/squared-button';
import { SubscriptionValueProposition } from './subsciption-value-proposition';

function SubscriptionItem({ id, title, amount, platform, libraryText, description, ctaText, onCtaClick, color, saleText = '', isCurrent, promotionText, allowed, isFree = false, index, variant = 'card' }: PlanItem) {
    const isCreate = useMemo(() => platform === 'create', [platform]);
    const colorClass = useMemo(() => styles[`subscription-item__color--${isCreate ? 'generic' : color}`], [color, isCreate]);
    const freeColorClass = styles['subscription-item__color--free'];
    const bgClass = useMemo(() => styles[`subscription-item__background--${isCreate ? 'generic' : color}`], [color, isCreate]);
    const borderClass = useMemo(() => styles[`subscription-item__border--${isCreate ? 'generic' : color}`], [color, isCreate]);
    const freeBorderClass = styles['subscription-item__border--free'];

    const DescriptionComponent = useMemo(() => {
        if (isCreate) {
            const currentDescription = () => {
                switch (index) {
                    case 0:
                        return 'Great for Beginners';
                    case 1:
                        return 'Our Most Popular';
                    case 2:
                        return 'Premium Plus';
                    default:
                        return '';
                }
            };
            return <p className={styles['subscription-item__description-create']}>{currentDescription()}</p>;
        }

        return <p className={styles['subscription-item__description']}>{description}</p>;
    }, [description, index, isCreate]);

    const renderSupremeValuePropositionPrompts = useMemo(() => {
        const prompt1 = 'Download Access';
        const prompt2 = 'Exclusive Edits & Remixes';
        const prompt3 = 'Premium Exclusives';
        const prompt4 = 'Playlists & Curated Sets';
        const prompt5 = 'Collaborative Playlists';

        switch (index) {
            case 0:
                return (
                    <>
                        <SubscriptionValueProposition label="Preview Access" isAvailable />
                        <SubscriptionValueProposition label={prompt2} isAvailable={false} />
                        <SubscriptionValueProposition label={prompt3} isAvailable={false} />
                        <SubscriptionValueProposition label={prompt4} isAvailable={false} />
                        <SubscriptionValueProposition label={prompt5} isAvailable={false} />
                    </>
                );
            case 1:
                return (
                    <>
                        <SubscriptionValueProposition label={prompt1} isAvailable />
                        <SubscriptionValueProposition label={prompt2} isAvailable />
                        <SubscriptionValueProposition label={prompt3} isAvailable={false} />
                        <SubscriptionValueProposition label={prompt4} isAvailable={false} />
                        <SubscriptionValueProposition label={prompt5} isAvailable={false} />
                    </>
                );
            case 2:
                return (
                    <>
                        <SubscriptionValueProposition label={prompt1} isAvailable />
                        <SubscriptionValueProposition label={prompt2} isAvailable />
                        <SubscriptionValueProposition label={prompt3} isAvailable />
                        <SubscriptionValueProposition label={prompt4} isAvailable />
                        <SubscriptionValueProposition label={prompt5} isAvailable />
                    </>
                );
            default:
                return null;
        }
    }, [index]);

    const ValueProposition = useMemo(() => {
        if (isCreate) {
            return (

                <ul>
                    {description.split('\n').map((line) => (
                        <SubscriptionValueProposition label={line} isAvailable />
                    ))}
                    <SubscriptionValueProposition label="Collaborative Drives" isAvailable={index !== 0} />
                </ul>
            );
        }

        return renderSupremeValuePropositionPrompts;
    }, [description, index, isCreate, renderSupremeValuePropositionPrompts]);

    return (
        <div className={classNames(styles['subscription-item-container'])}>
            <div className={classNames(styles['subscription-item__promotion-container'])}>
                {promotionText && allowed ? <span className={classNames(styles['subscription-item__promotion-text'], bgClass)}>{promotionText}</span> : null}
            </div>
            <div className={classNames(styles['subscription-item__top-line'], borderClass, { [freeBorderClass]: isFree })} />
            <div className={classNames(styles['subscription-item'], styles[`subscription-item__variant--${variant}`], { [styles['subscription-item-disabled']]: !allowed }, { [styles['subscription-item__background--premium']]: index === 2 }, { [styles['subscription-item__max-card-height']]: libraryText === 'Open Format + Latino' })}>
                <div className={styles['subscription-item__header']}>
                    <span className={styles['subscription-item__header--title']}>{title}</span>
                    <p className={classNames({ [styles['subscription-item__header--has-sale']]: saleText }, { [styles['subscription-item__header--text']]: !saleText })}>{amount}</p>
                </div>
                <div className={styles['subscription-item__header']}>
                    <p className={classNames(styles['subscription-item__library-text'], colorClass, { [freeColorClass]: isFree })}>{libraryText}</p>
                    <span>{saleText}</span>
                </div>
                {description && DescriptionComponent}
                {description && <div className={styles['subscription-item__value-proposition-container']}>{ValueProposition}</div>}
                <div className={styles['subscription-item__cta-container']}>
                    <SquaredButton type="filled" label={ctaText} fullWidth leftIcon={ctaText === 'Current Plan' ? <CheckIcon /> : undefined} disabled={!allowed} removeHover={!allowed} svgColoring="stroke" onPress={onCtaClick} />
                </div>
            </div>
        </div>
    );
}

export default SubscriptionItem;
