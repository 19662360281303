import { Playlist } from '@bpm-web-app/download-api-sdk';
import { appendQueryParams } from '@bpm-web-app/utils';
import Card from '../card';
import CardGhostLoading from '../card-ghost-loading';

interface PlaylistCardProps {
    playlists?: Playlist[];
    playlist?: Playlist;
    isGhost?: boolean;
    className?: string
}

export function PlaylistCard({ playlist, playlists, isGhost, className }: PlaylistCardProps) {
    if (isGhost) {
        return (
            <CardGhostLoading contentType="exclusive-playlist" />
        );
    }

    return playlist ? (
        <Card
            cardSize="small"
            contentType="exclusive-playlist"
            id={playlist.id}
            title={playlist.title}
            imageUrl={appendQueryParams(playlist.image_url, { key: 'dw', value: 160 })}
            imageUrl2x={appendQueryParams(playlist.image_url, { key: 'dw', value: 320 })}
            isPlayable
            descriptionProps={{
                artist: playlist.artist,
                artists: playlist.artists,
            }}
            link={`exclusive-playlist/${playlist.id}`}
            badge={playlist?.badge}
            currentPlaylist={playlists}
            className={className}
        />
    ) : null;
}
