import { ChangeSubscriptionRequest, PackageIdCreditcardBody, PackageIdPaypalBody, PackageIdPreviewBody, PlatformPauseBody, SubscriptionApi, SubscriptionPackage } from '@bpm-web-app/supreme-api-sdk';
import { supremeConfig } from '../config';

export class Subscription {
    static client = new SubscriptionApi(supremeConfig);

    static getSubscriptionPackages = (platform: SubscriptionPackage.PlatformEnum) => Subscription.client.getSubscriptionPackages(platform as unknown as string);

    static getSubscriptionPackage = (packageId: number) => Subscription.client.getSubscriptionPackage(packageId);

    static getMeSubscription = (platform: 'stream' | 'supreme' | string) => Subscription.client.getMeSubscription(platform);

    static getSetupIntent = (platform: 'create' | 'supreme' | string) => Subscription.client.getSetupIntent(platform);

    static getPaymentMethodBySetupIntent = (platform: 'create' | 'supreme', setupIntentId: string) => Subscription.client.getPaymentMethodBySetupIntent(platform, { setup_intent_id: setupIntentId });

    static checkCouponCode = (packageId: number, name: string) => Subscription.client.checkCouponCode(packageId, name);

    static subscribeCc = (packageId: number, body: PackageIdCreditcardBody) => Subscription.client.subscribeCc(packageId, body);

    static subscribeStripe = (packageId: number, body: PackageIdCreditcardBody) => Subscription.client.subscribeStripe(packageId, body);

    static subscribePaypal = (packageId: number, body: PackageIdPaypalBody) => Subscription.client.subscribePaypal(packageId, body);

    static changeSubscription = (packageId: number, body?: ChangeSubscriptionRequest) => Subscription.client.changeSubscription(packageId, body);

    static cancelSubscription = (platform: SubscriptionPackage.PlatformEnum) => Subscription.client.cancelSubscription(platform as unknown as string);

    static subscribePreview = (packageId: number, body?: PackageIdPreviewBody) => Subscription.client.subscribePreview(packageId, body);

    static downloadInvoice = (paymentId: number) => Subscription.client.downloadInvoice(paymentId);

    static startSubscriptionProcess = (platform: SubscriptionPackage.PlatformEnum) => Subscription.client.startSubscriptionProcess(platform as unknown as string);

    static retentionIncentivesSubscription = (platform: SubscriptionPackage.PlatformEnum) => Subscription.client.retentionIncentivesSubscription(platform as unknown as string);

    static pauseSubscription = (platform: SubscriptionPackage.PlatformEnum, body: PlatformPauseBody) => Subscription.client.pauseSubscription(platform as unknown as string, body);

    static resumeSubscription = (platform: SubscriptionPackage.PlatformEnum) => Subscription.client.resumeSubscription(platform as unknown as string);

    static applyPromotionSubscription = (platform: SubscriptionPackage.PlatformEnum,) => Subscription.client.applyPromotionSubscription(platform as unknown as string);

    static previewResumeSubscription = (platform: string) => Subscription.client.previewResumeSubscription(platform);
}
