import { QueueItem } from '@bpm-web-app/utils';
import classNames from 'classnames';
import CloseIcon from '../../../../../assets/icons/close.svg';
import PencilIcon from '../../../../../assets/icons/pencil.svg';
import styles from './player-queue.module.css';

interface PlayerQueueActionsProps {
    isEditMode: boolean;
    selectedItemsIds: QueueItem['uuid'][];
    handleFavoriteMultipleMedia: () => void;
    handleRemoveFromQueue: () => void;
    setIsEditMode: (isEditMode: boolean) => void;
    handleCancelEditMode: () => void;
}
export function PlayerQueueActions({ isEditMode, selectedItemsIds, handleFavoriteMultipleMedia, handleRemoveFromQueue, setIsEditMode, handleCancelEditMode }: PlayerQueueActionsProps) {
    return (
        <div className={classNames(styles['player-queue__edit-queue'])}>

            <div className={classNames(styles['player-queue__edit-actions'])}>

                {isEditMode ? (
                    <>
                        <p className={classNames(styles['player-queue__selected-label'])}>
                            {selectedItemsIds.length} selected
                        </p>
                        <div className={classNames(styles['player-queue__action-buttons'])}>

                            <button
                                onClick={handleFavoriteMultipleMedia}
                                type="button"
                                className={classNames(styles['player-queue__button'])}
                                disabled={selectedItemsIds.length === 0}
                                aria-label="Add to favorite">
                                Add to Favorites
                            </button>
                            <button
                                onClick={handleRemoveFromQueue}
                                type="button"
                                className={classNames(styles['player-queue__button'])}
                                disabled={selectedItemsIds.length === 0}
                                aria-label="Remove from queue">
                                Remove from queue
                            </button>
                        </div>
                    </>
                    ) : (
                        <button
                            type="button"
                            onClick={() => setIsEditMode(true)}
                            className={classNames(styles['player-queue__toggle-edit-button'])}>
                            <PencilIcon />
                            Edit Queue
                        </button>
                    )}

            </div>
            {isEditMode ? (
                <button type="button" className={classNames(styles['player-queue__cancel-button'])} onClick={handleCancelEditMode} aria-label="Cancel edit">
                    <CloseIcon />
                </button>
                ) : null}
        </div>
    );
}
