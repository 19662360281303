import classNames from 'classnames';
import { noop } from 'lodash';
import { useCallback } from 'react';
import ThreeDotsIcon from '../../../../assets/icons/supreme/three-dots.svg';
import { usePlayer } from '../../../player-context';
import styles from './top-menu.module.css';

interface TopMenuProps {
    openThreeDots: (element: React.MouseEvent<HTMLButtonElement>) => void;
}

export function TopMenu({ openThreeDots }: TopMenuProps) {
    const { setIsMaxiPlayer } = usePlayer();

    const closeMaxiPlayer = useCallback(() => {
        setIsMaxiPlayer(false);
    }, [setIsMaxiPlayer]);

    return (
        <div role="button" tabIndex={0} aria-label="Minimize" className={classNames(styles['top-menu'])} onClick={closeMaxiPlayer} onKeyDown={noop}>
            <button
                className={classNames(styles['top-menu__three-dots-button'])}
                type="button"
                aria-label="More"
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    openThreeDots(e);
                }}
            >
                <ThreeDotsIcon />
            </button>
        </div>
    );
}
