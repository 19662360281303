import { Notification, Notification as NotificationType, NotificationApi } from '@bpm-web-app/supreme-api-sdk';
import { supremeConfig } from '../config';

export class Notifications {
    static client = new NotificationApi(supremeConfig);

    static getNotifications = (platform: Notification.PlatformEnum[]) => Notifications.client.getNotifications(platform as unknown as string[]);

    static readNotifications = (platform: Notification.PlatformEnum[]) => Notifications.client.readNotifications(platform as unknown as string[]);

    static deleteNotifications = (platform: Notification.PlatformEnum[]) => Notifications.client.deleteNotifications(platform as unknown as string[]);

    static deleteNotification = (id: string) => Notifications.client.deleteNotifications_1(id);

    static createNotification = (data: Partial<NotificationType>) => Notifications.client.createNotification(data);
}
