// designs: dimension [segments] (breakpoint)
// mini: 328x32 [164], 640x32 (1920) [321], 840x32 (2560) [400] => 1
// maxi-mobile: resize [171],
// maxi-tablet: 328 x 32 [164] (680px), 440 x 32px [220] (1024px)
// maxi-desktop: 328x48 [164] => 3, 640x48 (1920) [321] => 2, 840x48 (2560) [400] => 1

import { WaveDetailDivFactor } from '@bpm-web-app/utils';
import { clamp } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

const WAVEFORM_LENGTH = 400;

export const useResizableDetail = (barWidth: number,) => {
    const containerRef = useRef<HTMLDivElement>(null!);
    const [containerWidth, setContainerWidth] = useState<number>(0);
    const [detailDivFactor, setDivFactor] = useState<WaveDetailDivFactor>(2);

    const onResize = useCallback((width) => {
        // compute the number of bars based on the container width
        const ofSegments = Math.min(400, Math.floor(width / (2 * barWidth)));
        const detail = Math.ceil(WAVEFORM_LENGTH / ofSegments);
        setDivFactor(clamp(detail, 1, 6) as WaveDetailDivFactor);
        setContainerWidth(width);
    }, [barWidth, setDivFactor]);

    useEffect(() => {
        if (containerRef.current) {
            setContainerWidth(containerRef.current?.clientWidth);
        }
    }, [setContainerWidth, containerRef]);

    useEffect(() => {
        let observer: ResizeObserver;
        if (containerRef.current) {
            observer = new ResizeObserver((entries) => {
                entries.forEach((entry) => {
                    const { width } = entry.contentRect;
                    onResize(width);
                });
            });
            observer.observe(containerRef.current);
        }
        return () => {
            if (containerRef.current) observer?.unobserve(containerRef.current);
        };
    }, [onResize, containerRef]);

    return { detailDivFactor, width: containerWidth, containerRef };
};
