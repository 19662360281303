import classNames from 'classnames';
import { AppLink } from '../app-link/app-link';
import { CustomIcon } from '../custom-icon/custom-icon';
import styles from './empty-state.module.css';
import { EmptyStateProps } from './types';

export type CircleButtonProps = Pick<EmptyStateProps, 'onPress' | 'icon' | 'variant' | 'href'>;

function CirclesButtonImpl({ onPress, icon = 'plus-icon', variant }: CircleButtonProps) {
    return (
        <div
            role="button"
            tabIndex={0}
            onKeyDown={() => { }}
            onClick={onPress}
            className={classNames(styles['empty-state__circles-container'], { [styles['empty-state__circles-container--has-action']]: onPress !== undefined })}
        >
            <CustomIcon type={icon} hasIconHover color={variant} />
            <div className={classNames(styles['empty-state__circle'], styles['empty-state__circle-1'], styles[`empty-state__circle-color--${variant}`])} />
            <div className={classNames(styles['empty-state__circle'], styles['empty-state__circle-2'], styles[`empty-state__circle-color--${variant}`])} />
            <div className={classNames(styles['empty-state__circle'], styles['empty-state__circle-3'], styles[`empty-state__circle-color--${variant}`])} />
        </div>
    );
}
export function CirclesButton({ onPress, icon = 'plus-icon', variant, href }: CircleButtonProps) {
    return href ? (
        <AppLink key={`circles-${href}`} href={(href as string)}>
            <CirclesButtonImpl onPress={onPress} icon={icon} variant={variant} />
        </AppLink>

    ) : (
        <CirclesButtonImpl onPress={onPress} icon={icon} variant={variant} />
    );
}
