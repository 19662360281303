import classNames from 'classnames';
import { ReactComponent as ControlsCheck } from '../../../assets/icons/controls-check.svg';
import styles from './loading-icon.module.css';

interface LoadingIconProps {
    isDark?: boolean;
}

export function LoadingIcon({ isDark }: LoadingIconProps) {
    return <ControlsCheck className={classNames(styles['loading-icon'], { [styles['loading-icon--dark']]: isDark })} />;
}
