import { ChangeEvent, MouseEvent, useContext } from 'react';
import { useHubSwitch, useViewport } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { Filters } from '@bpm-web-app/components';
import { Platform } from '@bpm-web-app/api-client';
import styles from './search-input.module.css';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import { ReactComponent as SearchIconAlternate } from '../../../assets/icons/search-icon-nav.svg';
import { ReactComponent as SearchIconAlternateActive } from '../../../assets/icons/search-icon-nav-active.svg';

import { FiltersContext } from '../../filters/filters.context';

interface SearchInputProps {
    placeholder: string;
    value: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onClear?: (event: MouseEvent<HTMLButtonElement>) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    isAlternateMobileLayout?: boolean;
    variant?: 'large' | 'small' | undefined;
    isActive?: boolean;
    id?: string;
    containerClassName?: string;
    inputClassName?: string;
}

export const SearchInput = ({ placeholder, onChange, value, onClear, isAlternateMobileLayout = false, variant = undefined, onBlur, onFocus, isActive, id, containerClassName = '', inputClassName = '' }: SearchInputProps) => {
    const { isMobile } = useViewport();
    const { isFiltersOpen, openFilters, closeFilters } = useContext(FiltersContext);
    const { hub, isCreate } = useHubSwitch();

    return (
        <div
            className={classNames(
                styles['search-input'],
                { [styles[`search-input__${variant}`]]: variant },
                { [styles['search-input__alternate-mobile-layout']]: isAlternateMobileLayout },
                containerClassName
            )}
        >
            {variant === 'large' || (isMobile && isAlternateMobileLayout) ? (
                value.length > 0 || isActive ? (
                    <SearchIconAlternateActive className={classNames(styles['search-input__icon-alternate'], { [styles[`search-input__icon-alternate--${variant}`]]: variant })} />
                ) : (
                    <SearchIconAlternate className={classNames(styles['search-input__icon-alternate'], { [styles[`search-input__icon-alternate--${variant}`]]: variant })} />
                )
            ) : (
                <SearchIcon className={styles['search-input__icon']} />
            )}
            <input
                id={id}
                type="text"
                onChange={onChange}
                placeholder={placeholder}
                value={value}
                onFocus={() => (onFocus ? onFocus() : null)}
                onBlur={() => (onBlur ? onBlur() : null)}
                className={classNames(styles['search-input__input'], inputClassName, {
                    [styles['search-input__input--alternate-mobile-layout']]: isAlternateMobileLayout,
                    [styles[`search-input__input--${variant}`]]: variant,
                })}
            />
            {isMobile && variant === 'large' ? (
                <button
                    onClick={() => (isFiltersOpen ? closeFilters() : openFilters())}
                    type="button"
                    aria-label={isFiltersOpen ? 'Close Filters' : 'Open Filters'}
                    className={styles['search-input__search-filters']}
                >
                    {isFiltersOpen ? <i className="icon icon--controls-close" /> : <i className="icon icon--nav-filter" />}
                </button>
            ) : null}

            {isMobile && variant === 'large' ? <Filters platform={isCreate ? 'create' : (hub as Platform)} hideButton /> : null}

            <button type="button" onClick={onClear} className={classNames(styles['search-input__clear'], { [styles[`search-input__clear--${variant}`]]: variant })}>
                <i className="icon icon--controls-clear" />
            </button>
        </div>
    );
};
