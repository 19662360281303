import classNames from 'classnames';
import { HTMLProps, useCallback, useContext, useEffect, useState } from 'react';
import { Admin } from '@bpm-web-app/api-client';
import { showToast } from '@bpm-web-app/utils';
import { Genre, SubGenre, Tag, TagCategory, TagGroup } from '@bpm-web-app/create-api-sdk';
import { uniqBy } from 'lodash';
import styles from './quality-control.module.css';
import { CustomIcon } from '../custom-icon/custom-icon';
import { ActionModal } from '../action-modal/action-modal';
import { TagsView } from '../../tags-view/tags-view';
import { ApplicationSectionHeader, ApplicationSectionSmall, ApplicationSectionTitle } from '../../pages/artist-portal/artist-application/artist-application-text/artist-application-text';
import { SearchInput } from '../search-input/search-input';
import { QualityControlContext } from './quality-control.context';
import { LoadingSpinner } from '../loading-spinner/loading-spinner';

type QualityControlInputType = 'title' | 'artist' | 'genre' | 'subgenres' | 'description' | 'bpm' | 'key' | 'tag' | 'add-tag';

interface QualityControlInputProps {
    isCreate: boolean;
    inputType: QualityControlInputType;
    associatedId?: string;
    text?: string;
    hasCross?: boolean;
    tagsData?: Tag[];
    subgenresData?: SubGenre[];
    updateTagsData?: (tags: Tag[]) => void
}

export function QualityControlInput({ updateTagsData, isCreate, inputType, associatedId, text, hasCross, subgenresData, tagsData }: QualityControlInputProps) {
    const { selectedSounds, setShouldMutateData, shouldMutateData } = useContext(QualityControlContext);

    const [currentText, setCurrentText] = useState(text || undefined);

    const { updateSoundPackage, updateSound, getKeys, getTagCategories, addTagsToSound, removeTagFromSound, getGenres, getSubGenres } = Admin;

    const [showAddKeys, setShowAddKeys] = useState(false);
    const [showAddGenre, setShowAddGenre] = useState(false);
    const [showAddSubGenre, setShowAddSubGenre] = useState(false);
    const [showAddTags, setShowAddTags] = useState(false);

    const [keys, setKeys] = useState<string[]>([]);
    const [genres, setGenres] = useState<Genre[]>([]);
    const [subGenres, setSubGenres] = useState<SubGenre[]>([]);
    const [tags, setTags] = useState<TagCategory[]>([]);
    const [searchText, setSearchText] = useState('');

    const [selectedTags, setSelectedTags] = useState<Tag[]>(tagsData || []);
    const [selectedSubGenres, setSelectedSubGenres] = useState<SubGenre[]>(subgenresData || []);

    const filteredTags = (tagsToFilter: Tag[]) => {
        if (searchText !== '') {
            return tagsToFilter.filter((tag) => tag.name.toLowerCase().includes(searchText.toLowerCase()));
        }
        return tagsToFilter;
    };

    const filteredSubGenres = (currentSubgenres: SubGenre[]) => {
        if (currentSubgenres.length > 0) {
            if (searchText !== '') {
                return currentSubgenres.filter((genre) => genre.name.toLowerCase().includes(searchText.toLowerCase()));
            }
            return currentSubgenres;
        }
        return [];
    };

    const getAllTags = useCallback(() => {
        getTagCategories().then((res) => {
            setTags(res.data);
            return res.data;
        }).catch((e) => {
            showToast({ type: 'error', title: 'Something went wrong fetching tags.' });
            return [];
        });
    }, [getTagCategories]);

    const getAllGenres = useCallback(() => {
        getGenres().then((res) => {
            if (res.data.data) setGenres(res.data.data);
            return res.data.data;
        }).catch((e) => {
            showToast({ type: 'error', title: 'Something went wrong fetching genres.' });
            return [];
        });
    }, [getGenres]);

    const getAllSubGenres = useCallback(() => {
        getSubGenres().then((res) => {
            if (res.data.data) setSubGenres(res.data.data);
            return res.data.data;
        }).catch((e) => {
            showToast({ type: 'error', title: 'Something went wrong fetching genres.' });
            return [];
        });
    }, [getSubGenres]);

    const getAllKeys = useCallback(() => {
        getKeys().then((res) => {
            setKeys(res.data.sort((a, b) => a.localeCompare(b)));
            return res.data;
        }).catch((e) => {
            showToast({ type: 'error', title: 'Something went wrong fetching keys.' });
            return [];
        });
    }, [getKeys]);

    const updateSoundPackageGenre = (newGenre: Genre) => {
        if (!associatedId) return;
        setCurrentText(newGenre.name);
        updateSoundPackage(associatedId, { genre_id: newGenre.id });
    };

    const mutateAllData = () => {
        if (!shouldMutateData) {
            setShouldMutateData(true);
        }
    };

    const onChangeEnded = (newText: string) => {
        if (!associatedId) return;
        switch (inputType) {
            case 'key':
                setCurrentText(newText);
                updateSound(associatedId, { key: newText });

                if (selectedSounds) {
                    mutateAllData();
                    selectedSounds.forEach((soundId) => {
                        updateSound(soundId, { key: newText });
                    });
                }
                break;
            case 'title':
                updateSoundPackage(associatedId, { name: currentText });
                break;
            case 'description':
                updateSoundPackage(associatedId, { description: currentText });
                break;

            case 'bpm':
                if (Number(newText)) {
                    updateSound(associatedId, { bpm: Number(newText) });

                    if (selectedSounds) {
                        mutateAllData();
                        selectedSounds.forEach((soundId) => {
                            updateSound(soundId, { bpm: Number(newText) });
                        });
                    }
                } else {
                    showToast({ type: 'error', title: 'Invalid BPM Input. Please try again.' });
                }
                break;
            default:
                break;
        }
    };

    const handleOnClick = () => {
        switch (inputType) {
            case 'genre':
                // Open Genres
                getAllGenres();
                setShowAddGenre(true);
                break;
            case 'subgenres':
                // Open Sub Genres
                getAllSubGenres();
                setShowAddSubGenre(true);
                break;
            case 'key':
                // Open keys
                getAllKeys();
                setShowAddKeys(true);
                break;
            default:
                break;
        }
    };

    const inputPlaceholder = () => {
        switch (inputType) {
            case 'genre':
                return 'Genres';
            case 'subgenres':
                return 'Subgenres';
            case 'bpm':
                return 'BPM';
            case 'key':
                return 'Key';
            case 'add-tag':
                return 'Tag';
            case 'tag':
                return 'Tag';
            default:
                return `Enter new ${inputType}`;
        }
    };

    const inputProps: HTMLProps<any> = {
        value: currentText,
        onChange: (e) => setCurrentText((e.target as any).value),
        onClick: () => handleOnClick(),
        onBlur: (e) => onChangeEnded(e.target.value),
        placeholder: inputPlaceholder(),
        className: classNames(
            styles['quality-control-input'],
            styles[`quality-control-input__${inputType}`],
            { [styles['quality-control-input--changed']]: text !== currentText },
        )
    };

    const inputComponent = () => {
        switch (inputType) {
            case 'description':
                return <textarea {...inputProps} />;

            case 'tag':
                return (
                    <button
                        key={currentText}
                        className={classNames(styles['quality-control-input__tag'])}
                        type="button">
                        {currentText}
                    </button>
                );

            case 'add-tag':
                return (
                    <button
                        type="button"
                        className={classNames(styles['quality-control-input__icon-wrapper'])}
                        onClick={() => {
                            getAllTags();
                            setShowAddTags(true);
                        }}>
                        <CustomIcon type="plus-icon" />
                    </button>
                );
            default:
                return <input {...inputProps} />;
        }
    };

    useEffect(() => {
        setCurrentText(text);
    }, [text]);

    const loadingSpinnerBlock = () => {
        return (
            <div className={styles['quality-control-input__loading-container']}>
                <LoadingSpinner isLoading />
            </div>
        );
    };

    const renderTags = (tagsToRender: Tag[]) => {
        return (
            <TagsView
                orderBySelected={false}
                className={styles['quality-control-input--tags-container']}
                tags={filteredTags(tagsToRender).map((e) => e.name)}
                selected={selectedTags.map(({ name }) => name)}
                onToggleTag={(tag: string, toggleOn: boolean) => {
                    if (toggleOn) {
                        // Add Tag
                        const tagsToAdd: Tag[] = tagsToRender.filter((e) => e.name === tag);

                        if (tagsToAdd.length > 0) {
                            setSelectedTags((currentTags) => [...currentTags, tagsToAdd[0]]);
                        }
                    } else {
                        // Remove Tag
                        const tagsAfterRemoving = selectedTags.filter((e) => e.name !== tag);
                        setSelectedTags(tagsAfterRemoving);
                    }
                }} />
        );
    };

    const renderTagGroup = (tagGroup: TagGroup) => {
        if (filteredTags(tagGroup.items).length === 0) {
            return null;
        }
        return (
            <div key={tagGroup.id}>
                <div className="spacing--bottom" />
                <ApplicationSectionHeader subtitle={tagGroup.name} />
                <div className="spacing--bottom-half" />
                {renderTags(tagGroup.items)}
            </div>
        );
    };

    return (
        <div className={styles['quality-control-input__wrapper']}>
            {inputComponent()}
            {hasCross && currentText !== undefined ? (
                <button
                    type="button"
                    className={styles['quality-control-input__icon']}
                    onClick={() => {
                        if (tagsData && associatedId) {
                            const matchingTags = tagsData.filter((e) => e.name === text);

                            if (matchingTags.length > 0) {
                                removeTagFromSound(associatedId, matchingTags[0].id);
                                showToast({ type: 'success', title: `Successfully removed ${matchingTags[0].name}` });
                                updateTagsData?.(tagsData.filter((e) => e.name !== text));
                            }
                        }
                    }}>
                    <CustomIcon type="plus-icon" color="red" hasIconHover />
                </button>
            ) : null}

            {showAddTags ? (
                <ActionModal
                    headerTitle={`Add ${inputPlaceholder()}s`}
                    confirmButtonText="Apply to Selected Items"
                    onConfirm={() => {
                        const newTagData = selectedTags.filter((t) => !tagsData?.map((tag) => tag.id).includes(t.id));
                        if (associatedId && newTagData.length > 0) {
                            const newTagsIds = newTagData.map((e) => e.id);
                            addTagsToSound(associatedId, newTagsIds);
                            if (selectedSounds) {
                                mutateAllData();
                                selectedSounds.forEach((soundId) => {
                                    addTagsToSound(soundId, newTagsIds);
                                });
                            }
                            setShowAddTags(false);
                            showToast({ type: 'success', title: `Successfully added ${newTagData.map((e) => e.name).join(', ')}` });
                            updateTagsData?.(uniqBy((tagsData || []).concat(newTagData), (tag) => tag.id));
                            setSelectedTags([]);
                        }
                    }}
                    onClose={() => {
                        setShowAddTags(false);
                    }}
                    variant="dark"
                    cancelButtonText="Close"
                    isOpen={showAddTags}
                >
                    <div className={styles['quality-control-input--tags-groups-container']}>
                        <SearchInput placeholder="Search Tags" value={searchText} onChange={(e) => setSearchText(e.target.value)} onClear={() => setSearchText('')} />
                        {tags.length > 0 ? (
                            tags.map((tagCategory) => {
                                return (
                                    <div key={tagCategory.id}>
                                        <div className="spacing--bottom" />
                                        <ApplicationSectionTitle title={tagCategory.name} noMargin />
                                        <div className="spacing--bottom-half" />
                                        {tagCategory.id === 'null-c' ? renderTags(tagCategory.items) : null}
                                        {tagCategory.id !== 'null-c' ? tagCategory.items.map(renderTagGroup) : null}
                                    </div>
                                );
                            })
                        ) : loadingSpinnerBlock()}
                    </div>
                </ActionModal>
            ) : null}

            {showAddKeys ? (
                <ActionModal
                    headerTitle={`Add ${inputPlaceholder()}s`}
                    confirmButtonText="Continue"
                    hideCancel
                    onClose={() => {
                        setShowAddKeys(false);
                    }}
                    variant="dark"
                    isOpen={showAddKeys}
                >
                    {keys.length > 0 ? (
                        <div>
                            <TagsView
                                className={styles['quality-control-input--tags-container']}
                                tags={keys}
                                selected={[text || '']}
                                onToggleTag={(tag: string, toggleOn: boolean) => {
                                    onChangeEnded(tag);
                                    setShowAddKeys(false);
                                }}

                            />
                        </div>
                    ) : loadingSpinnerBlock()}
                </ActionModal>
            ) : null}

            {showAddGenre ? (
                <ActionModal
                    headerTitle={`Add ${inputPlaceholder()}s`}
                    confirmButtonText="Continue"
                    hideCancel
                    onClose={() => {
                        setShowAddGenre(false);
                    }}
                    variant="dark"
                    isOpen={showAddGenre}
                >
                    {genres.length > 0 ? (
                        <div>
                            <TagsView
                                className={styles['quality-control-input--tags-container']}
                                tags={genres.map((e) => e.name)}
                                selected={[text || '']}
                                onToggleTag={(tag: string, toggleOn: boolean) => {
                                    const newGenres = genres.filter((e) => e.name === tag);

                                    if (newGenres.length > 0) {
                                        updateSoundPackageGenre(newGenres[0]);
                                    }
                                    setShowAddGenre(false);
                                }}

                            />
                        </div>
                    ) : loadingSpinnerBlock()}
                </ActionModal>
            ) : null}
            {showAddSubGenre ? (
                <ActionModal
                    headerTitle={`Edit ${inputPlaceholder()}s`}
                    confirmButtonText="Apply Selected"
                    onConfirm={() => {
                        if (associatedId) {
                            updateSoundPackage(associatedId, { SubGenres: selectedSubGenres });
                            setShowAddSubGenre(false);
                            setShouldMutateData(true);
                            showToast({ type: 'success', title: `Successfully added ${selectedSubGenres.map((e) => e.name).join(', ')}` });
                            setCurrentText(`${selectedSubGenres.map((e) => e.name).join(', ')}`);
                        }
                    }}
                    onClose={() => {
                        setShowAddSubGenre(false);
                    }}
                    variant="dark"
                    cancelButtonText="Close"
                    isOpen={showAddSubGenre}
                >
                    {subGenres.length > 0 ? (
                        <div className={styles['quality-control-input--tags-groups-container']}>
                            <SearchInput placeholder="Search Subgenres" value={searchText} onChange={(e) => setSearchText(e.target.value)} onClear={() => setSearchText('')} />
                            <div className="spacing--top" />
                            <TagsView
                                orderBySelected
                                className={styles['quality-control-input--tags-container']}
                                tags={filteredSubGenres(subGenres).map(({ name }) => name)}
                                selected={selectedSubGenres.map(({ name }) => name)}
                                onToggleTag={(tag: string, toggleOn: boolean) => {
                                    if (toggleOn) {
                                        // Add Subgenre
                                        const subGenreToAdd: SubGenre[] = filteredSubGenres(subGenres).filter((e) => e.name === tag);
                                        if (subGenreToAdd.length > 0) {
                                            setSelectedSubGenres((currentTags) => [...currentTags, subGenreToAdd[0]]);
                                        }
                                    } else {
                                        // Remove Subgenre
                                        const subGenresAfterRemoving = selectedSubGenres.filter((e) => e.name !== tag);
                                        setSelectedSubGenres(subGenresAfterRemoving);
                                    }
                                }}

                            />
                        </div>
                    ) : loadingSpinnerBlock()}
                </ActionModal>
            ) : null}
        </div>
    );
}
