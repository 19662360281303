import { ReactNode } from 'react';

export enum TrackListPreset {
    Stream = 'stream',
    StreamTrending = 'stream-trending',
    DownloadTrending = 'download-trending',
    Download = 'download',
    Create = 'create',
    Album = 'album',
    Queue = 'queue',
    OnlineCrate = 'online-crate',
    OfflineCrate = 'offline-crate',
}

export enum ColumnType {
    Title = 'title',
    Bpm = 'bpm',
    Key = 'key',
    Time = 'time',
    Date = 'date',
    Genre = 'genre',
    Versions = 'versions',
    QueueDownloadVersion = 'queue-download-version',
    Count = 'count',
    TrendingCount = 'trending-count',
    StreamActions = 'stream-actions',
    DownloadActions = 'download-actions',
    Accordion = 'accordion',
    EditModeCheckbox = 'edit-mode-checkbox',
    DownloadablePill = 'downloadable-pill',
    Empty = 'empty',
    OnlineCrateActions = 'online-crate-actions',
    OfflineCrateActions = 'offline-crate-actions',
}

interface ColumnDefinition {
    heading: ReactNode;
    type: ColumnType;
    class?: string
}

export const columnDefinitions: { [key in TrackListPreset]?: ColumnDefinition[] } = {
    [TrackListPreset.Stream]: [
        { heading: '', type: ColumnType.Count },
        { heading: 'Title', type: ColumnType.Title },
        { heading: 'BPM', type: ColumnType.Bpm },
        { heading: 'Key', type: ColumnType.Key },
        { heading: 'Length', type: ColumnType.Time },
        { heading: 'Genre', type: ColumnType.Genre },
        {
            heading: 'placeholder',
            type: ColumnType.StreamActions,
        },
    ],
    [TrackListPreset.Download]: [
        { heading: '', type: ColumnType.Accordion },
        { heading: 'Title', type: ColumnType.Title },
        { heading: 'BPM', type: ColumnType.Bpm },
        { heading: 'Key', type: ColumnType.Key },
        { heading: 'Added', type: ColumnType.Date },
        { heading: 'Genre', type: ColumnType.Genre },
        { heading: 'Versions', type: ColumnType.Versions, class: 'hide-filters-open' },
        {
            heading: 'placeholder',
            type: ColumnType.DownloadActions,
        },
    ],
    [TrackListPreset.DownloadTrending]: [
        { heading: '', type: ColumnType.TrendingCount },
        { heading: '', type: ColumnType.Accordion },
        { heading: 'Title', type: ColumnType.Title },
        { heading: 'BPM', type: ColumnType.Bpm },
        { heading: 'Key', type: ColumnType.Key },
        { heading: 'Added', type: ColumnType.Date },
        { heading: 'Genre', type: ColumnType.Genre },
        { heading: 'Versions', type: ColumnType.Versions, class: 'hide-filters-open' },
        {
            heading: 'placeholder',
            type: ColumnType.DownloadActions,
        },
    ],
    [TrackListPreset.StreamTrending]: [
        { heading: '', type: ColumnType.TrendingCount },
        { heading: 'Title', type: ColumnType.Title },
        { heading: 'BPM', type: ColumnType.Bpm },
        { heading: 'Key', type: ColumnType.Key },
        { heading: 'Length', type: ColumnType.Time },
        { heading: 'Genre', type: ColumnType.Genre },
        { heading: '', type: ColumnType.StreamActions },
    ],
    [TrackListPreset.Album]: [
        { heading: '', type: ColumnType.Count },
        { heading: 'Title', type: ColumnType.Title },
        { heading: 'BPM', type: ColumnType.Bpm },
        { heading: 'Key', type: ColumnType.Key },
        { heading: 'Length', type: ColumnType.Time },
        {
            heading: 'placeholder',
            type: ColumnType.StreamActions,
        },
    ],
    [TrackListPreset.Queue]: [
        /* If the queue is in edit mode, the hash will be replaced by a "select all" checkbox.
         * See the logic in TrackList for more info. */
        { heading: '', type: ColumnType.EditModeCheckbox },
        { heading: 'Title', type: ColumnType.Title },
        { heading: 'BPM', type: ColumnType.Bpm },
        { heading: 'Key', type: ColumnType.Key },
        { heading: 'Length', type: ColumnType.Time },
        { heading: 'Genre', type: ColumnType.Genre },
        { heading: 'Version', type: ColumnType.QueueDownloadVersion },
        {
            heading: 'placeholder',
            type: ColumnType.DownloadActions,
        },
    ],
    [TrackListPreset.OnlineCrate]: [
        /* The placeholder will be replaced by the "Select All" checkbox.
         * See the logic in TrackList for more info. */
        { heading: 'placeholder', type: ColumnType.EditModeCheckbox },
        { heading: 'Title', type: ColumnType.Title },
        { heading: 'BPM', type: ColumnType.Bpm },
        { heading: 'Key', type: ColumnType.Key },
        { heading: 'Date Added', type: ColumnType.Date },
        { heading: 'Genre', type: ColumnType.Genre },
        { heading: 'Versions', type: ColumnType.DownloadablePill },
        {
            heading: 'placeholder',
            type: ColumnType.OnlineCrateActions,
        },
    ],
    [TrackListPreset.OfflineCrate]: [
        /* The placeholder will be replaced by the "Select All" checkbox.
         * See the logic in TrackList for more info. */
        { heading: 'placeholder', type: ColumnType.EditModeCheckbox },
        { heading: 'Title', type: ColumnType.Title },
        { heading: 'BPM', type: ColumnType.Bpm },
        { heading: 'Key', type: ColumnType.Key },
        { heading: 'Date Added', type: ColumnType.Date },
        { heading: 'Genre', type: ColumnType.Genre },
        {
            heading: 'placeholder',
            type: ColumnType.OfflineCrateActions,
        },
    ],
};
