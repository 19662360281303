import { DeviceApi, UpdateDevice } from '@bpm-web-app/supreme-api-sdk';
import { supremeConfig } from '../config';

export class Device {
    static client = new DeviceApi(supremeConfig);

    static getDevices = () => Device.client.getDevices();

    static requestAuthCode = (platform: 'supreme' | 'create') => Device.client.requestDeviceAuthCode(platform);

    static verifyAuthCode = (platform: 'supreme' | 'create', code: string, name?: string) => Device.client.verifyDeviceAuthCode(platform, { code, name });

    static deleteDeviceById = (id: string, platform: 'supreme' | 'create') => Device.client.deleteDeviceById(id, platform);

    static updateDevice = (deviceUuid: string, body: UpdateDevice) => Device.client.updateDevice(deviceUuid, body);

    static updateDeviceName = (deviceUuid: string, name: string) => Device.client.updateDeviceName(deviceUuid, { name });

    static getAuthorizedDevices = (platform: 'supreme' | 'create') => Device.client.getAuthorizedDevices(platform);
}
