import { FC } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';

import IconBack from '../icons/back-button.svg';
import mainStyles from '../../account.module.css';
import styles from './page-header.module.css';

type Props = {
    title: string
    description?: string
    hasBackButton?: boolean
};

export const PageHeader: FC<Props> = ({ title, description, children, hasBackButton = true }) => {
    const router = useRouter();
    const { backRoute } = router.query;

    return (
        <>
            {hasBackButton && (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                    {backRoute ? (
                        <Link href={backRoute.toString()} passHref>
                            <a className={styles['page-header__back-button']}>
                                <IconBack aria-label="Back" />
                            </a>
                        </Link>
                    ) : (
                        <button type="button" onClick={router.back} className={styles['page-header__back-button']}>
                            <IconBack aria-label="Back" />
                        </button>
                    )}
                </>
            )}

            {!!(title || children) && (
                <div className={mainStyles['account__header']}>
                    <h2>{title}</h2>
                    {!!children && <div>{children}</div>}
                </div>
            )}

            {!!description && <p className={styles['page-header__description']}>{description}</p>}
        </>
    );
};
