import React from 'react';
import { useHubSwitch } from './hubSwitch.context';

/**
 * Hook to control the hub switch visibility in some screens
 * @param {boolean} disableBehavior When we need to force disable this behavior
 */
export function useHideSwitch(disableBehavior?: boolean) {
    const { setSwitchVisibility } = useHubSwitch();

    React.useEffect(() => {
        if (disableBehavior) return () => undefined;

        setSwitchVisibility(false);

        return () => {
            setSwitchVisibility(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setSwitchVisibility]);
}
