import useSWR from 'swr';

import { UserPlaylist } from '@bpm-web-app/api-client';
import { useHubSwitch } from '@bpm-web-app/utils';
import { useUserPlaylistSets } from '@bpm-web-app/swr-hooks';

type ResponseType = Awaited<ReturnType<(typeof UserPlaylist)['getUserPlaylistsFull']>>;

export const useUserPlaylistsFull = (include_view_only?: boolean) => {
    const { isDownload } = useHubSwitch();
    const { mutate: mutatePlaylistSets } = useUserPlaylistSets(isDownload, include_view_only);
    const {
        data: playlists,
        error: playlistsError,
        mutate: mutatePlaylists,
    } = useSWR<ResponseType>(
        [
            `get-user-playlists-full-${include_view_only ? 'view-only' : 'all'}`,
            {
                persist: true,
            },
        ],
        () => UserPlaylist.getUserPlaylistsFull(isDownload, include_view_only),
        {
            onError(err, key, config) {},
        }
    );

    const addItemsToUserPlaylist = async (playlistId: string, mediaIds: string[]) => {
        if (isDownload) {
            await UserPlaylist.addMultipleAlbumToUserPlaylist(playlistId, { albums: mediaIds.map((id, i) => ({ album_id: Number(id), order: i })) });
        } else {
            await UserPlaylist.addMultipleMediaToUserPlaylist(playlistId, { media: mediaIds.map((id, i) => ({ media_id: Number(id), order: i })) });
        }
        mutatePlaylists(playlists);
        mutatePlaylistSets();
    };

    const removeItemsFromUserPlaylist = async (playlistId: string, mediaIds: string[]) => {
        if (isDownload) {
            await UserPlaylist.removeMultipleAlbumsFromUserPlaylist(playlistId, { album_ids: mediaIds.map((id) => Number(id)) });
        } else {
            await UserPlaylist.removeMultipleMediaFromUserPlaylist(playlistId, { media_ids: mediaIds.map((id) => Number(id)) });
        }
        mutatePlaylists(playlists);
        mutatePlaylistSets();
    };

    return {
        playlists,
        addItemsToUserPlaylist,
        removeItemsFromUserPlaylist,
        error: playlistsError,
        isLoading: !playlists && !playlistsError,
        mutatePlaylists,
    };
};
