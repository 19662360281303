import { useRouter } from 'next/router';
import { useMemo } from 'react';

export type DetailPageResource = 'artist' | 'album' | 'exclusive-playlist' | 'curated-set' | 'my-playlist';

function isOfTypePageResource(keyInput: string): keyInput is DetailPageResource {
    return ['artist', 'album', 'exclusive-playlist', 'curated-set', 'my-playlist'].includes(keyInput);
}

export type PageDetails = {
    /** Initially should be the id, but this can be a slug too */
    identifier: string;
    /** Resource is linked to the possible page details  */
    resource: DetailPageResource | null;
};

export function usePageDetails() {
    const router = useRouter();

    const identifier = useMemo<string | null>(() => {
        // NOTE: Most detail page routes have id associated with the query, eg. playlistId
        const idKey = Object.keys(router.query).find((key) => key.toLowerCase().includes('id') || key.toLowerCase().includes('slug'));

        if (idKey) {
            return router.query[idKey] as string;
        }

        return null;
    }, [router.query]);

    const resource = useMemo<DetailPageResource | null>(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        () => getResourceFromLink(router.asPath),
        [router]
    );

    return { identifier, resource };
}

export function getResourceFromLink(link: string) {
    const resourcePath = link.split('/').filter(Boolean)?.[0] || null;

    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (!resourcePath && process.env['NODE_ENV'] === 'development') {
        console.warn('Failed to get resource from path', link);
    }

    if (resourcePath && isOfTypePageResource(resourcePath)) {
        return resourcePath;
    }

    return null;
}
