import classNames from 'classnames';
import { FC, ReactNode, useContext } from 'react';

import { LibraryTabsContext, useLibraryTabsOptions } from '@bpm-web-app/components';
import Dropdown from '../../dropdown/dropdown';
import { AppLink } from '../../shared';
import { NavContext } from '../nav.context';
import styles from './nav-header.module.css';

type Props = {
    title: string;
    hasSwitcher?: boolean;
    AfterTitle?: ReactNode;
};

export const NavHeader: FC<Props> = ({ children, title, hasSwitcher = true, AfterTitle = null }) => {
    const { library, setLibrary, isSwitcherVisible } = useContext(LibraryTabsContext);
    const { isNavOpen } = useContext(NavContext);

    const options = useLibraryTabsOptions();

    return (
        <div className={styles['nav-header__container']}>
            <div className={styles['nav-header__logo-container']}>
                <AppLink href="/">
                    <a>
                        <i aria-label="Homepage" className={styles['nav-header__logo-img']}>
                            {children}
                        </i>
                    </a>
                </AppLink>

                <div className={classNames(styles['nav-header__text-container'], { [styles['nav-header__text-container--closed']]: !isNavOpen })}>
                    <h4 className={styles['nav-header__title']}>
                        <AppLink href="/">
                            <a>{title}</a>
                        </AppLink>
                    </h4>
                    {(!!AfterTitle || hasSwitcher) && (
                        <div className={styles['nav-header__text']}>
                            {AfterTitle}
                            {hasSwitcher && (
                                <Dropdown
                                    buttonClassname={styles['nav-header__dropdown-button']}
                                    containerClassname={styles['nav-header__dropdown-container']}
                                    optionContainerClassname={styles['nav-header__dropdown-options']}
                                    value={library}
                                    options={options}
                                    onClick={setLibrary}
                                    disabled={!isSwitcherVisible}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
