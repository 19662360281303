import { ARTIST_LIBRARY_DISABLED_PAGES, ARTIST_LIBRARY_EXCLUSIVE_PAGES, Library } from 'libs/utils/src/lib/library-tabs.context';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

export const LIBRARY_TABS = [
    {
        value: Library.Main,
        label: 'Open Format',
    },
    {
        value: Library.Latin,
        label: 'Latino',
    },

    {
        value: Library.Artist,
        label: 'White Label'
    },
];

export function useLibraryTabsOptions() {
    const { pathname } = useRouter();

    const options = useMemo(() => {
        return LIBRARY_TABS.map((tab) => {
            if (tab.value === Library.Artist && ARTIST_LIBRARY_DISABLED_PAGES.includes(pathname)) {
                return { ...tab, disabled: true };
            }
            if (tab.value !== Library.Artist && ARTIST_LIBRARY_EXCLUSIVE_PAGES.includes(pathname)) {
                return { ...tab, disabled: true };
            }
            return tab;
        });
    }, [pathname]);

    return options;
}
