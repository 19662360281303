/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unstable-nested-components */
import classNames from 'classnames';
import { Album as DownloadAlbum, Artist, Media as DownloadMedia, MediaWithAlbum as DownloadMediaWithAlbum, UserPlaylistCollaboration, UserPlaylistWithAlbumOwner } from '@bpm-web-app/download-api-sdk';
import { Album as StreamAlbum, Genre, MediaWithAlbum } from '@bpm-web-app/stream-api-sdk';
import { HTMLAttributes, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import {
    Analytics,
    downloadAlbumWithMediaToQueueItem,
    downloadMediaWithAlbumToQueueItem,
    formatDateToString,
    generateArtistLinks,
    isDownloadLimitReached,
    parseSeconds,
    QueueItem,
    State,
    streamMediaWithAlbumToQueueItem,
    streamMediaWithoutAlbumToQueueItem,
    useApiErrorHandler,
    useHubSwitch,
    usePageDetails,
    usePlayerState,
    timeAgo,
    showToast,
    useViewport,
    useUserSettings,
    rebuildReactTooltip
} from '@bpm-web-app/utils';
import { useDownloadMedia } from '@bpm-web-app/swr-hooks';
import { useCrates } from '@bpm-web-app/components/src/lib/shared/three-dots-sheet/useCrates';
import { useInView } from 'react-intersection-observer';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import styles from './track-list.module.css';
import TitleColumn, { TitleColumnProps } from './columns/title-column';
import { LibraryTabsContext } from '../../../../../utils/src/lib/library-tabs.context';
import VersionsColumn from './columns/versions-column';
import ChevronRight from '../../../assets/icons/chevron-right.svg';
import DownloadIcon from '../../../assets/icons/download.svg';
import { ThreeDotsButton } from '../three-dots-button/three-dots-button';
import { columnDefinitions, ColumnType, TrackListPreset } from './track-list-helpers';
import { usePlayer } from '../../player-context';
import AddToQueue from '../../../assets/icons/add-to-queue.svg';
import AddToCrate from '../../../assets/icons/add-to-crate.svg';
import RemoveFromQueue from '../../../assets/icons/remove-track-from-queue.svg';
import RemoveFromCrate from '../../../assets/icons/remove-from-crate.svg';
import { useBpmLink, useGenreLinks, useKeyLinks } from '../../generate-link/generate-link';
import { ActionType, ThreeDotsSheetContext } from '../three-dots-sheet/three-dots-sheet.context';
import { Checkbox } from '../ui/checkbox/checkbox';
import { AppLink } from '../app-link/app-link';
import { ShareURL } from '../share-url/share-url';
import { CollaborationUserImage } from '../collaboration/collaboration-user-image';
import { DragDropItem, DragResult } from '../../droppable/droppable';
import { useUserPlaylistsDetails } from '../three-dots-sheet/useUserPlaylistsDetails';
import useFavoriteAlbums from '../three-dots-sheet/useFavoriteAlbums';
import { getVersionPillLabel } from '../version-pill/version-pill';

interface TrackListItemGenericProps {
    preset: TrackListPreset;
    streamTracksList: MediaWithAlbum[];
    downloadTracksList: DownloadAlbum[];
    trackIndex: number;
    onMove?: (prevIndex: number, mediaId: number) => void
    listId?: number;
    // eslint-disable-next-line react/require-default-props
    isUserPlaylist?: boolean;
    hasEditAccess?: boolean;
    streamAlbum?: StreamAlbum;
    onDownloadRevalidate?: (downloadMedia?: DownloadMedia) => void;
    defaultExpanded?: number;
    onFilterApplied?: (filter: { bpm?: number, key?: string, genre?: string }) => void;
    trendingStatusToShow?: 'trending_status_weekly' | 'trending_status_daily' | 'trending_status_monthly'
    collaborator?: UserPlaylistCollaboration;
    owner?: UserPlaylistWithAlbumOwner;
    isSharedPlaylist?: boolean;
    addedAt?: string;
    isDraggable?: boolean;
    setCurrentMediaInContext?: (id: string, showSignUpModal?: boolean) => void;
    isPremiumOnly?: boolean;
    hasPremiumOnlyAccess?: boolean;
}

interface TrackListItemNonSelectableProps extends TrackListItemGenericProps {
    media: MediaWithAlbum | DownloadMediaWithAlbum | DownloadAlbum | QueueItem;
    onSelectItem?: never;
    isSelected?: never;
}

export interface TrackListItemSelectableProps extends TrackListItemGenericProps {
    media: MediaWithAlbum | DownloadAlbum | QueueItem;
    onSelectItem: () => void;
    isSelected: boolean;
}

export type TrackListItemProps = TrackListItemNonSelectableProps | TrackListItemSelectableProps;

export function TrackListItem({
    preset,
    media,
    streamTracksList,
    downloadTracksList,
    trackIndex,
    streamAlbum,
    onSelectItem,
    isSelected,
    isUserPlaylist = false,
    hasEditAccess,
    onDownloadRevalidate,
    defaultExpanded,
    onFilterApplied,
    trendingStatusToShow = 'trending_status_weekly',
    collaborator,
    owner,
    isSharedPlaylist,
    addedAt,
    isDraggable,
    onMove,
    setCurrentMediaInContext,
    isPremiumOnly,
    hasPremiumOnlyAccess
}: TrackListItemProps) {
    const { openThreeDotsModalSheet, secondaryActionTypeId } = useContext(ThreeDotsSheetContext);
    // Replace setQueue
    const { identifier, resource } = usePageDetails();

    const mediaWithAlbum = media as MediaWithAlbum;
    const downloadAlbum = media as DownloadAlbum;
    const onlineCrateMedia = media as DownloadMediaWithAlbum;
    const queueItem = media as QueueItem;
    const errorHandler = useApiErrorHandler();

    const { isDownload } = useHubSwitch();
    const { isAnonymous } = useUserSettings();

    const { libraryProperty } = useContext(LibraryTabsContext);
    const [openDownloadAccordions, setOpenDownloadAccordions] = useState<number[]>([]);

    const getActionType = (isPresetDownload: boolean) => {
        let actionType: ActionType = isPresetDownload ? 'download:album' : 'track';

        if (preset === TrackListPreset.Queue) {
            actionType = 'queue-track';
        } else if (isUserPlaylist || hasEditAccess) {
            actionType = 'user-playlist-track';
        }

        return actionType;
    };

    const getAuxiliaryId = () => {
        let auxiliaryId = secondaryActionTypeId || undefined;

        if (preset === TrackListPreset.Queue) {
            auxiliaryId = (media as Partial<QueueItem>)?.uuid;
        }

        return auxiliaryId;
    };

    const getMediaId = () => {
        let mediaId = media.id || undefined;

        if (preset === TrackListPreset.Queue) {
            mediaId = (media as Partial<QueueItem>)?.album_id;
        }

        return mediaId;
    };

    // TODO: (by Elliot Briant) We need to implement the fill in icon when adding to Crate or Queue method for Stream as well.
    const { isMediaInTheQueue, setIsMaxiPlayer } = usePlayer();

    useEffect(() => {
        if (defaultExpanded) {
            setOpenDownloadAccordions([...openDownloadAccordions, defaultExpanded]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultExpanded]);

    const mainItemRef = useRef<HTMLDivElement>(null);

    const { ref: viewRef, inView } = useInView({
        triggerOnce: true,
        threshold: 1
    });

    useEffect(() => {
        viewRef(mainItemRef.current);
    }, [viewRef, mainItemRef]);

    useEffect(() => {
        if (inView) {
            let albumId: number | undefined;
            if ([TrackListPreset.Stream, TrackListPreset.StreamTrending, TrackListPreset.Album, TrackListPreset.OfflineCrate].includes(preset)) {
                albumId = streamAlbum?.id;
            } else if ([TrackListPreset.Download, TrackListPreset.DownloadTrending, TrackListPreset.OnlineCrate].includes(preset)) {
                albumId = downloadAlbum.id;
            }
            if (preset === TrackListPreset.Queue) {
                albumId = queueItem.album_id;
            }
            Analytics.trackImpression('Album', `${albumId}`);
        }
    }, [downloadAlbum, inView, media, preset, queueItem, streamAlbum]);

    const { setQueue, skipToTrackInQueue, addToQueue, removeFromQueue, isMaxiPlayer, currentTrack, togglePlayPause } = usePlayer();

    const playerState = usePlayerState();

    const { addMediaToOnlineCrate, isMediaInCrate, removeFromOnlineCrate } = useCrates();

    const { downloadSingleMedia, handleOpenDownloadUrl, setIsRequestInProgress } = useDownloadMedia(libraryProperty, preset === TrackListPreset.OnlineCrate || preset === TrackListPreset.OfflineCrate);

    const handlePlayStreamList = useCallback(() => {
        if (streamAlbum) {
            const mediaToAdd = streamAlbum.media.map((albumMedia) =>
                streamMediaWithoutAlbumToQueueItem(albumMedia, {
                    cover_url: streamAlbum.cover_url,
                    genre: streamAlbum.genre,
                    isExclusive: streamAlbum.is_exclusive,
                })
            );
            setQueue(mediaToAdd, trackIndex, { identifier, resource });
        } else {
            setQueue(
                streamTracksList.map((track) => streamMediaWithAlbumToQueueItem(track)),
                trackIndex || 0,
                { identifier, resource }
            );
        }
    }, [identifier, resource, setQueue, streamAlbum, streamTracksList, trackIndex]);

    const onAccordionClick = useCallback(() => {
        const downloadAlbumId = +downloadAlbum.id;
        let newOpenDownloadAccordions = [...openDownloadAccordions, downloadAlbumId];

        if (openDownloadAccordions.includes(downloadAlbumId)) {
            newOpenDownloadAccordions = newOpenDownloadAccordions.filter((id) => id !== downloadAlbumId);
        }
        setOpenDownloadAccordions(newOpenDownloadAccordions);
    }, [downloadAlbum.id, openDownloadAccordions]);

    const handleSingleMediaDownload = useCallback(
        async (downloadMedia: DownloadMedia) => {
            if (isAnonymous || (isPremiumOnly && !hasPremiumOnlyAccess)) {
                if (setCurrentMediaInContext) setCurrentMediaInContext(`${media.id}`, !hasPremiumOnlyAccess);
                return;
            }
            try {
                setIsRequestInProgress(true);
                const url = (await downloadSingleMedia(downloadMedia.id)).data?.url || '';
                Analytics.trackClick('one_click_download', downloadMedia.id.toString(), { location: '1-click' });

                handleOpenDownloadUrl(url);
                if (onDownloadRevalidate) onDownloadRevalidate(downloadMedia);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                errorHandler({ error, message: 'Error downloading files. Please try again.' });
            } finally {
                setIsRequestInProgress(false);
            }
        },
        [downloadSingleMedia, errorHandler, handleOpenDownloadUrl, hasPremiumOnlyAccess, isAnonymous, isPremiumOnly, media.id, onDownloadRevalidate, setCurrentMediaInContext, setIsRequestInProgress]
    );

    const addDownloadTrackToQueue = useCallback(
        (downloadMedia, album) => {
            if (!downloadMedia || !album) return;

            const newQueueItem = downloadMediaWithAlbumToQueueItem(
                {
                    ...downloadMedia,
                    album,
                },
                album.media.length
            );

            addToQueue([newQueueItem]);
            showToast({ type: 'success', message: 'Added to queue.', buttonText: 'Open Queue', onButtonClick: () => setIsMaxiPlayer(true) });
            rebuildReactTooltip();
        },
        [addToQueue, setIsMaxiPlayer]
    );

    const removeTrackFromQueue = useCallback(
        async (mediaId) => {
            removeFromQueue(mediaId);
            showToast({ type: 'success', message: 'Removed from queue.' });
            rebuildReactTooltip();
        },
        [removeFromQueue]
    );

    const addDownloadTrackToCrate = useCallback(
        async (mediaId: number) => {
            if (isAnonymous || (isPremiumOnly && !hasPremiumOnlyAccess)) {
                if (setCurrentMediaInContext) setCurrentMediaInContext(`${media.id}`, !hasPremiumOnlyAccess);
                return;
            }
            await addMediaToOnlineCrate(mediaId);
            rebuildReactTooltip();
        },
        [addMediaToOnlineCrate, hasPremiumOnlyAccess, isAnonymous, isPremiumOnly, media.id, setCurrentMediaInContext]
    );

    const removeTrackFromCrate = useCallback(
        async (mediaId: number) => {
            removeFromOnlineCrate(mediaId);
            showToast({ type: 'success', message: 'Removed from crate.' });
            rebuildReactTooltip();
        },
        [removeFromOnlineCrate]
    );

    const downloadAlbumMediaIds = useMemo<number[]>(() => downloadAlbum?.media?.map(({ id }) => id) || [], [downloadAlbum?.media]);

    const isCurrentTrackPartOfDownloadAlbum = useMemo(() => isDownload && downloadAlbumMediaIds.includes(currentTrack?.id), [currentTrack?.id, downloadAlbumMediaIds, isDownload]);

    const isCurrentPlayingTrack = useMemo(() => currentTrack?.id === media.id, [currentTrack?.id, media.id]);

    const handleMainItemClick = useCallback(() => {
        /* TODO: improve and extend the click event handler */
        if (isCurrentTrackPartOfDownloadAlbum || isCurrentPlayingTrack) {
            togglePlayPause();
            return;
        }

        if ([TrackListPreset.Stream, TrackListPreset.StreamTrending, TrackListPreset.Album, TrackListPreset.OfflineCrate].includes(preset)) {
            handlePlayStreamList();
        }

        if (preset === TrackListPreset.Download || preset === TrackListPreset.DownloadTrending) {
            const currentAlbumIndex = downloadTracksList?.findIndex((album) => album.id === downloadAlbum.id);

            const newQueue = downloadTracksList.map((album) => downloadAlbumWithMediaToQueueItem(album));

            setQueue(newQueue, currentAlbumIndex, { identifier, resource });
        } else if (preset === TrackListPreset.OnlineCrate) {
            const currentAlbumIndex = downloadTracksList?.findIndex((album) => album.id === downloadAlbum.id);

            const newQueue = downloadTracksList.map((album) => downloadMediaWithAlbumToQueueItem(album as unknown as DownloadMediaWithAlbum, 1));

            setQueue(newQueue, currentAlbumIndex, { identifier, resource });
        }
        if (preset === TrackListPreset.Queue) skipToTrackInQueue(queueItem.uuid);
    }, [
        downloadAlbum,
        downloadTracksList,
        handlePlayStreamList,
        identifier,
        isCurrentPlayingTrack,
        isCurrentTrackPartOfDownloadAlbum,
        preset,
        queueItem.uuid,
        resource,
        setQueue,
        skipToTrackInQueue,
        togglePlayPause,
    ]);

    const isMainItemClickable = useMemo(
        () => [
            TrackListPreset.Stream,
            TrackListPreset.StreamTrending,
            TrackListPreset.Album,
            TrackListPreset.OfflineCrate,
            TrackListPreset.OnlineCrate,
            TrackListPreset.Download,
            TrackListPreset.DownloadTrending,
            TrackListPreset.Queue
        ].includes(preset),
        [preset]
    );

    const mainItemProps = useMemo<HTMLAttributes<HTMLDivElement>>(() => {
        if (isMainItemClickable) {
            return {
                role: 'button',
                ref: mainItemRef,
                tabIndex: 0,
                onClick: handleMainItemClick,
                onKeyDown: (e) => {
                    const hasKeyPressedOnMainItem = e.target === mainItemRef.current;
                    if (e.key === 'Enter' && hasKeyPressedOnMainItem) {
                        handleMainItemClick();
                    }
                },
                'aria-label': `Play ${mediaWithAlbum.title}`,
            };
        }
        return {};
    }, [handleMainItemClick, isMainItemClickable, mediaWithAlbum.title]);

    const generateBPMLinks = useBpmLink();
    const generateKeyLinks = useKeyLinks();
    const generateGenreLinks = useGenreLinks();

    /* TODO: consider moving thirdLineMobileContent logic inside TitleColumn component */
    const getThirdLineMobileContent = useCallback(
        (bpm?: number, actualKey?: string, displayKey?: string, genre?: Genre | string) => (
            <span className={styles['track-list__third-line-mobile-content']}>
                {bpm && <span>{generateBPMLinks(bpm)}</span>}
                {displayKey && <span>{generateKeyLinks(actualKey, displayKey)}</span>}
                {genre && <span>{typeof genre !== 'string' ? generateGenreLinks(genre) : genre}</span>}
            </span>
        ),
        [generateBPMLinks, generateGenreLinks, generateKeyLinks]
    );

    const threeLinesMobileProps = useMemo<Pick<TitleColumnProps, 'hasThreeLinesOnMobile' | 'thirdLineMobileContent'>>(() => {
        switch (preset) {
            case TrackListPreset.Queue:
                return {
                    hasThreeLinesOnMobile: true,
                    thirdLineMobileContent: getThirdLineMobileContent(queueItem.bpm, queueItem.actual_key, queueItem.display_key, queueItem.genre),
                };
            case TrackListPreset.Stream:
            case TrackListPreset.StreamTrending:
            case TrackListPreset.OfflineCrate:
            case TrackListPreset.Album:
                return {
                    hasThreeLinesOnMobile: true,
                    thirdLineMobileContent: getThirdLineMobileContent(mediaWithAlbum.bpm, mediaWithAlbum.key, mediaWithAlbum.display_key, mediaWithAlbum.album?.genre),
                };
            case TrackListPreset.DownloadTrending:
            case TrackListPreset.Download:
                return {
                    hasThreeLinesOnMobile: true,
                    thirdLineMobileContent: getThirdLineMobileContent(downloadAlbum.bpm, downloadAlbum.key, downloadAlbum.display_key, downloadAlbum?.genre),
                };
            case TrackListPreset.OnlineCrate:
                return {
                    hasThreeLinesOnMobile: true,
                    thirdLineMobileContent: getThirdLineMobileContent(onlineCrateMedia.album?.bpm, onlineCrateMedia.album?.key, onlineCrateMedia.album?.display_key, onlineCrateMedia.album?.genre),
                };
            default:
                return {
                    hasThreeLinesOnMobile: false,
                };
        }
    }, [mediaWithAlbum, downloadAlbum, onlineCrateMedia, preset, getThirdLineMobileContent, queueItem]);

    const isDownloadAccordionOpen = useMemo(() => openDownloadAccordions.includes(+downloadAlbum.id), [downloadAlbum.id, openDownloadAccordions]);

    const artistLink = useCallback((a: Artist) => {
        return (
            <AppLink href={`/artist/${a.slug}`} key={a.id}>
                <a
                    data-for="artist-tooltip"
                    data-tip={`${a.name}|${a.slug}|${a.id}|${a.artwork_url}`}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    className="underline-link"
                >
                    {a.name}
                </a>
            </AppLink>
        );
    }, []);

    const separator = useCallback((sep: string, i: number) => {
        return <span key={i}>{sep}</span>;
    }, []);

    const subtitle = useMemo(() => {
        if (preset === TrackListPreset.Download || preset === TrackListPreset.DownloadTrending || preset === TrackListPreset.Queue) {
            return generateArtistLinks(
                downloadAlbum.artist,
                downloadAlbum.artists,
                artistLink,
                separator
            );
        }
        if (preset === TrackListPreset.OnlineCrate) {
            return generateArtistLinks(
                onlineCrateMedia.album.artist,
                onlineCrateMedia.album.artists,
                artistLink,
                separator
            );
        }
        return generateArtistLinks(
            mediaWithAlbum.artist,
            mediaWithAlbum.artists,
            artistLink,
            separator
        );
    }, [artistLink, separator, downloadAlbum, mediaWithAlbum, onlineCrateMedia, preset]);

    const playClick = useMemo(() => {
        return (track: DownloadMedia, index: number) => {
            return () => {
                if (currentTrack?.id === track.id) {
                    togglePlayPause();
                    return;
                }

                let albumToPlayIndex = 0;
                const queueItems = (downloadTracksList as DownloadAlbum[]).map((incomingAlbum, albumIndex) => {
                    if (incomingAlbum.id === track.album_id) {
                        albumToPlayIndex = albumIndex;
                        return downloadAlbumWithMediaToQueueItem(incomingAlbum, index);
                    }
                    return downloadAlbumWithMediaToQueueItem(incomingAlbum);
                });
                setQueue(queueItems, albumToPlayIndex, { identifier, resource });
            };
        };
    }, [currentTrack?.id, downloadTracksList, identifier, resource, setQueue, togglePlayPause]);

    const { addMediaToPlaylist, addAlbumToPlaylist } = useUserPlaylistsDetails();

    const { addAlbumToFavorites } = useFavoriteAlbums(mediaWithAlbum.album_id || media.id);

    const { isMobile } = useViewport();

    const [draggingMediaId, setDraggingMediaId] = useState<number | undefined>();

    // eslint-disable-next-line no-empty-pattern
    const [{ }, dragRef, connectDragPreview] = useDrag<DragDropItem, any, { opacity: number }>({
        item: {
            id: isUserPlaylist ? media.id : mediaWithAlbum.album_id || media.id,
            title: downloadAlbum.title || downloadAlbum.name,
            prevIndex: trackIndex
        } as any,
        canDrag() {
            return !isMobile;
        },
        end: (draggedItem, monitor) => {
            if (monitor.didDrop()) {
                const { target, id } = monitor.getDropResult() as DragResult;
                if (target === 'favorites') {
                    addAlbumToFavorites();
                } else if (target === 'my-crate') {
                    switch (preset) {
                        case TrackListPreset.Queue:
                            if (isDownload) {
                                addDownloadTrackToCrate(queueItem.id);
                            } else {
                                addMediaToOnlineCrate(queueItem.id);
                            }
                            break;
                        case TrackListPreset.Stream:
                        case TrackListPreset.StreamTrending:
                        case TrackListPreset.OfflineCrate:
                        case TrackListPreset.Album:
                            if (isDownload) {
                                addDownloadTrackToCrate(mediaWithAlbum.id);
                            } else {
                                addMediaToOnlineCrate(mediaWithAlbum.id);
                            }
                            break;
                        case TrackListPreset.DownloadTrending:
                        case TrackListPreset.Download:
                            if (isDownload) {
                                if (draggingMediaId !== undefined) {
                                    addDownloadTrackToCrate(draggingMediaId);
                                } else {
                                    addDownloadTrackToCrate(downloadAlbum.media[0].id);
                                }
                            }
                            break;
                        default:
                            break;
                    }
                } else if (target === 'playlist') {
                    switch (preset) {
                        case TrackListPreset.Queue:
                            if (isDownload) {
                                addAlbumToPlaylist(id as string, { albums: [{ album_id: queueItem.album_id as number }] });
                            } else {
                                addMediaToPlaylist(id as string, { media: [{ media_id: queueItem.id as number }] });
                            }
                            break;
                        case TrackListPreset.Stream:
                        case TrackListPreset.StreamTrending:
                        case TrackListPreset.OfflineCrate:
                        case TrackListPreset.Album:
                            if (isDownload) {
                                addAlbumToPlaylist(id as string, { albums: [{ album_id: mediaWithAlbum.album_id as number }] });
                            } else {
                                addMediaToPlaylist(id as string, { media: [{ media_id: mediaWithAlbum.id as number }] });
                            }
                            break;
                        case TrackListPreset.DownloadTrending:
                        case TrackListPreset.Download:
                            if (isDownload) {
                                addAlbumToPlaylist(id as string, { albums: [{ album_id: downloadAlbum.id as number }] });
                            }
                            break;
                        case TrackListPreset.OnlineCrate:
                            addAlbumToPlaylist(id as string, { albums: [{ album_id: downloadAlbum.id as number }] });
                            break;
                        case TrackListPreset.Create:
                            addMediaToPlaylist(id as string, { media: [{ media_id: onlineCrateMedia.id as number }] });
                            break;
                        default:
                            break;
                    }
                }
                setDraggingMediaId(undefined);
            }
        },
        type: isDraggable ? (preset === TrackListPreset.Queue ? 'Queue' : 'UserPlaylist-Album') : 'Album'
    });

    const [{ isOver }, dropRef] = useDrop<DragDropItem, any, { isOver: boolean }>({
        accept: (preset === TrackListPreset.Queue ? 'Queue' : 'UserPlaylist-Album'),
        collect: (monitor) => ({
            isOver: monitor.isOver()
        }),
        canDrop: () => {
            return !!isDraggable;
        },
        drop: (data: any) => {
            onMove?.(data.prevIndex, data.id);
        }
    });

    connectDragPreview(getEmptyImage(), {
        // IE fallback: specify that we'd rather screenshot the node
        // when it already knows it's being dragged so we can hide it with CSS.
        captureDraggingState: true,
    });

    const columnTypeDefitions = useMemo(() => columnDefinitions[preset] || [], [preset]);

    const dateColumn = useMemo(() => {
        let column;
        let dateToDisplay = mediaWithAlbum.created_at;
        if (mediaWithAlbum.created_at < mediaWithAlbum.release_date) {
            dateToDisplay = mediaWithAlbum.release_date;
        }
        if (mediaWithAlbum.highlighted_date) {
            dateToDisplay = mediaWithAlbum.highlighted_date;
        }
        if ((preset === TrackListPreset.OnlineCrate || preset === TrackListPreset.OfflineCrate) && mediaWithAlbum.added_to_crate_at) {
            dateToDisplay = mediaWithAlbum.added_to_crate_at;
        }
        if (preset === TrackListPreset.Download && downloadAlbum.added_at) {
            dateToDisplay = downloadAlbum.added_at;
            column = timeAgo(dateToDisplay);
            if (Number.isNaN(+column.slice(0, 1))) {
                column = <span className={styles['track-list__date-timeago']}>{column}</span>;
            }
        } else {
            column = formatDateToString(dateToDisplay);
        }
        return column;
    }, [downloadAlbum?.added_at, mediaWithAlbum?.added_to_crate_at, mediaWithAlbum?.created_at, mediaWithAlbum?.release_date, mediaWithAlbum.highlighted_date, preset]);

    const mediaWithAlbumFormattedDate = useMemo(() => {
        return formatDateToString(mediaWithAlbum.created_at);
    }, [mediaWithAlbum.created_at]);

    return (
        <div ref={(ref) => {
            dragRef(ref);
            dropRef(ref);
        }}>
            <div
                className={classNames(styles['track-list__list-item'], styles[`track-list__list-item--${preset}`], {
                    [styles['track-list__list-item--trending-rising']]: preset === TrackListPreset.StreamTrending && mediaWithAlbum[trendingStatusToShow] > 0,
                    [styles['track-list__list-item--trending-dropping']]: preset === TrackListPreset.StreamTrending && mediaWithAlbum[trendingStatusToShow] <= 0,
                    [styles['track-list__list-item--trending-rising']]: preset === TrackListPreset.DownloadTrending && downloadAlbum[trendingStatusToShow] > 0,
                    [styles['track-list__list-item--trending-dropping']]: preset === TrackListPreset.DownloadTrending && downloadAlbum[trendingStatusToShow] <= 0,
                    [styles['track-list__list-item--clickable']]: isMainItemClickable,
                    [styles['track-list__list-item--download-accordion-open']]: isDownloadAccordionOpen,
                    [styles['track-list__list-item--is-album-restricted']]: downloadAlbum?.is_restricted || mediaWithAlbum?.album?.is_restricted,
                    [styles['track-list__list-item--dragging-over']]: isOver
                })}
                {...mainItemProps}
            >
                {columnTypeDefitions.map(({ type }, index) => {
                    let column;
                    const isDownloadPreset = preset === TrackListPreset.Download || preset === TrackListPreset.DownloadTrending;
                    switch (type) {
                        case ColumnType.Title:
                            if (isDownloadPreset || preset === TrackListPreset.Queue) {
                                column = (
                                    /* TODO: fix the implementation of three lines of text on mobile:
                                     * https://beyond.atlassian.net/browse/BPM-436  */
                                    <TitleColumn
                                        hasPremiumOnlyAccess={hasPremiumOnlyAccess}
                                        isPremiumOnly={isPremiumOnly}
                                        coverUrl={downloadAlbum.cover_url}
                                        title={downloadAlbum.title}
                                        linkedArtist={downloadAlbum.artist_remixer}
                                        subtitle={subtitle}
                                        isExclusive={downloadAlbum.is_exclusive}
                                        isPlaying={downloadAlbumMediaIds.includes(currentTrack?.id) && playerState === State.Playing}
                                        {...threeLinesMobileProps}
                                    />
                                );
                            } else if (preset === TrackListPreset.OnlineCrate) {
                                column = (
                                    <TitleColumn
                                        hasPremiumOnlyAccess={hasPremiumOnlyAccess}
                                        isPremiumOnly={isPremiumOnly}
                                        coverUrl={onlineCrateMedia.album?.cover_url}
                                        title={onlineCrateMedia.name}
                                        subtitle={subtitle}
                                        isExclusive={!!onlineCrateMedia.album?.is_exclusive}
                                        isPlaying={currentTrack?.id === onlineCrateMedia.id && playerState === State.Playing}
                                        {...threeLinesMobileProps}
                                    />
                                );
                            } else {
                                column = (
                                    <TitleColumn
                                        hasPremiumOnlyAccess={hasPremiumOnlyAccess}
                                        isPremiumOnly={isPremiumOnly}
                                        coverUrl={streamAlbum ? streamAlbum.cover_url : mediaWithAlbum.album?.cover_url}
                                        title={mediaWithAlbum.title}
                                        subtitle={subtitle}
                                        isPlaying={isCurrentPlayingTrack}
                                        isExclusive={!!(streamAlbum?.is_exclusive || mediaWithAlbum.album?.is_exclusive)}
                                        {...threeLinesMobileProps}
                                    />
                                );
                            }
                            break;
                        case ColumnType.Bpm:
                            if (onFilterApplied) {
                                column = (
                                    <button
                                        className="underline-link-track-list-item"
                                        type="button"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onFilterApplied({ bpm: onlineCrateMedia?.album?.bpm || mediaWithAlbum?.bpm });
                                        }}>
                                        {preset === TrackListPreset.OnlineCrate ? onlineCrateMedia.album?.bpm : mediaWithAlbum.bpm}
                                    </button>
                                );
                            } else {
                                column = preset === TrackListPreset.OnlineCrate ? onlineCrateMedia.album?.bpm : mediaWithAlbum.bpm;
                            }
                            break;
                        case ColumnType.Key:
                            if (onFilterApplied) {
                                column = (
                                    <button
                                        className="underline-link-track-list-item"
                                        type="button"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onFilterApplied({ key: onlineCrateMedia?.album?.key || mediaWithAlbum?.key });
                                        }}
                                    >
                                        {preset === TrackListPreset.OnlineCrate ? onlineCrateMedia.album?.display_key : mediaWithAlbum.display_key}
                                    </button>
                                );
                            } else {
                                column = preset === TrackListPreset.OnlineCrate ? onlineCrateMedia.album?.display_key : mediaWithAlbum.display_key;
                            }
                            break;
                        case ColumnType.Genre:
                            if (preset === TrackListPreset.Queue) {
                                column = (
                                    <span onClick={(e) => {
                                        e.stopPropagation();
                                    }}>{generateGenreLinks(queueItem.genre)}
                                    </span>
                                );
                            } else {
                                column = isDownloadPreset ? (
                                    <span onClick={(e) => {
                                        e.stopPropagation();
                                    }}>{generateGenreLinks(downloadAlbum.genre)}
                                    </span>
                                ) : (
                                    <span onClick={(e) => {
                                        e.stopPropagation();
                                    }}>{generateGenreLinks(mediaWithAlbum.album?.genre)}
                                    </span>
                                );
                            }
                            break;
                        case ColumnType.Time:
                            column = isDownloadPreset ? parseSeconds(downloadAlbum.media[0].estimated_duration || 0) : parseSeconds(mediaWithAlbum.estimated_duration || 0);
                            break;
                        case ColumnType.Date:
                            column = dateColumn;

                            break;
                        case ColumnType.Versions:
                            column = (
                                <VersionsColumn
                                    hasPremiumOnlyAccess={hasPremiumOnlyAccess}
                                    isPremiumOnly={isPremiumOnly}
                                    versionsList={downloadAlbum.media}
                                    onMoreVersionsClick={onAccordionClick}
                                    isAccordionOpen={isDownloadAccordionOpen}
                                    onVersionClick={(m) => handleSingleMediaDownload(m)}
                                    setCurrentMediaInContext={() => setCurrentMediaInContext ? setCurrentMediaInContext(`${media.id}`, !hasPremiumOnlyAccess) : null}
                                />
                            );
                            break;
                        case ColumnType.QueueDownloadVersion:
                            column = isDownload ? queueItem.version : '';
                            break;
                        case ColumnType.Count:
                            column = trackIndex + 1;
                            break;
                        case ColumnType.TrendingCount:
                            column = trackIndex + 1;
                            break;
                        case ColumnType.Accordion:
                            column = (
                                <button
                                    className={classNames(styles['track-list__download-accordion-trigger'], {
                                        [styles['track-list__download-accordion-trigger--open']]: isDownloadAccordionOpen,
                                    })}
                                    type="button"
                                    aria-label={`${downloadAlbum.name} - versions`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onAccordionClick();
                                    }}
                                >
                                    <ChevronRight />
                                </button>
                            );
                            break;
                        case ColumnType.StreamActions:
                        case ColumnType.DownloadActions:
                            column = (
                                <div className={styles['track-list__action-buttons-container']}>
                                    <div className={styles['track-list__share-button']}>
                                        {isSharedPlaylist ? <CollaborationUserImage imageUrl={collaborator !== undefined ? collaborator.profile_image_thumbnail_url : owner ? owner?.profile_image_thumbnail_url : undefined} size="small" collaborator={collaborator} owner={owner} addedAt={addedAt} /> : null}
                                        <ShareURL actionType={getActionType(isDownloadPreset)} actionTypeId={getMediaId()} secondaryActionTypeId={getAuxiliaryId()} variant="arrow-icon" />
                                    </div>
                                    <div className={styles['track-list__three-dots']}>
                                        <div className={styles['track-list__three-dots-container']}>
                                            <ThreeDotsButton
                                                avoidCheckingIfAnonymous
                                                onClick={(e) => {
                                                    if (isAnonymous || (isPremiumOnly && !hasPremiumOnlyAccess)) {
                                                        if (setCurrentMediaInContext) setCurrentMediaInContext(`${media.id}`, !hasPremiumOnlyAccess);
                                                        return;
                                                    }
                                                    e.preventDefault();
                                                    e.stopPropagation();

                                                    openThreeDotsModalSheet(
                                                        getActionType(isDownloadPreset),
                                                        media.id,
                                                        e.currentTarget.getBoundingClientRect().left,
                                                        e.currentTarget.getBoundingClientRect().top + window.scrollY,
                                                        false,
                                                        getAuxiliaryId()
                                                    );
                                                }} />
                                        </div>
                                    </div>
                                </div>
                            );
                            break;
                        case ColumnType.OnlineCrateActions:
                            column = (
                                <ThreeDotsButton
                                    avoidCheckingIfAnonymous
                                    onClick={(e) => {
                                        if (isAnonymous || (isPremiumOnly && !hasPremiumOnlyAccess)) {
                                            if (setCurrentMediaInContext) setCurrentMediaInContext(`${media.id}`, !hasPremiumOnlyAccess);
                                            return;
                                        }
                                        e.preventDefault();
                                        e.stopPropagation();
                                        openThreeDotsModalSheet(
                                            'online-crate',
                                            media.id,
                                            isMaxiPlayer ? e.currentTarget.getBoundingClientRect().left : e.currentTarget.offsetLeft,
                                            isMaxiPlayer ? e.currentTarget.getBoundingClientRect().top + window.scrollY : e.currentTarget.offsetTop,
                                            false
                                        );
                                    }} />
                            );
                            break;
                        case ColumnType.OfflineCrateActions:
                            column = (
                                <ThreeDotsButton
                                    avoidCheckingIfAnonymous
                                    onClick={(e) => {
                                        if (isAnonymous || (isPremiumOnly && !hasPremiumOnlyAccess)) {
                                            if (setCurrentMediaInContext) setCurrentMediaInContext(`${media.id}`, !hasPremiumOnlyAccess);
                                            return;
                                        }

                                        e.preventDefault();
                                        e.stopPropagation();
                                        openThreeDotsModalSheet(
                                            'offline-crate',
                                            media.id,
                                            isMaxiPlayer ? e.currentTarget.getBoundingClientRect().left : e.currentTarget.offsetLeft,
                                            isMaxiPlayer ? e.currentTarget.getBoundingClientRect().top + window.scrollY : e.currentTarget.offsetTop,
                                            false
                                        );
                                    }} />
                            );
                            break;

                        case ColumnType.EditModeCheckbox:
                            column = onSelectItem ? <Checkbox checked={isSelected} onChange={onSelectItem} /> : trackIndex + 1;
                            break;
                        case ColumnType.DownloadablePill:
                            column = (
                                <button
                                    type="button"
                                    className={styles['track-list__downloadable-pill']}
                                    aria-label={`Download ${onlineCrateMedia.name}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleSingleMediaDownload(onlineCrateMedia);
                                    }}
                                >
                                    <span>{getVersionPillLabel(onlineCrateMedia.version?.name)}</span>
                                    <DownloadIcon />
                                </button>
                            );
                            break;

                        case ColumnType.Empty:
                        default:
                            column = '';
                            break;
                    }
                    return (
                        <div
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            className={classNames(styles['track-list__column'], styles[`track-list__column--${type}`])}
                        >
                            {column}
                        </div>
                    );
                })}
            </div>
            {/* Download accordion content */}
            {[TrackListPreset.Download, TrackListPreset.DownloadTrending].includes(preset) && (
                <AnimateHeight duration={300} easing="ease-in-out" height={openDownloadAccordions.includes(+downloadAlbum.id) ? 'auto' : 0}>
                    {downloadAlbum.media.map((track, index) => {
                        const mediaQueueItemId = isMediaInTheQueue(track.id);
                        const mediaCrateItem = isMediaInCrate(track.id);
                        return (
                            <div
                                draggable={!isMobile}
                                onDragStart={() => {
                                    setDraggingMediaId(track.id);
                                }}
                                key={track.id}
                                className={classNames(styles['track-list__list-item--download'],
                                    styles['track-list__download-accordion'],
                                    styles['track-list__list-item--download-accordion'],
                                    { [styles['track-list__list-item--download-accordion--trending']]: preset === TrackListPreset.DownloadTrending })}
                            >
                                <span />
                                {preset === TrackListPreset.DownloadTrending && <span />}
                                <div className={classNames(styles['track-list__column'], styles['track-list__column--title'])}>
                                    <TitleColumn
                                        hasPremiumOnlyAccess={hasPremiumOnlyAccess}
                                        isPremiumOnly={isPremiumOnly}
                                        hasPlainPlayButton
                                        isPlaying={currentTrack?.id === track.id && playerState === State.Playing}
                                        playClick={playClick(track, index)}
                                        title={track.version.name}
                                        isExclusive={false}
                                        isVersionItem
                                        {...threeLinesMobileProps}
                                    />
                                </div>
                                <div className={classNames(styles['track-list__column'], styles['track-list__column--bpm'])}>{downloadAlbum.bpm}</div>
                                <div className={classNames(styles['track-list__column'], styles['track-list__column--key'])}>{downloadAlbum.display_key}</div>
                                <div className={classNames(styles['track-list__column'], styles['track-list__column--date'])}>{mediaWithAlbumFormattedDate}</div>
                                <div className={classNames(styles['track-list__column'], styles['track-list__column--genre'])}>{downloadAlbum.genre?.name}</div>
                                <div className={classNames('hide-filters-open', styles['track-list__column'], styles['track-list__column--versions-content'])}>
                                    {isPremiumOnly === true && hasPremiumOnlyAccess === false ? (
                                        <VersionsColumn
                                            hasPremiumOnlyAccess={hasPremiumOnlyAccess}
                                            isPremiumOnly={isPremiumOnly}
                                            versionsList={downloadAlbum.media}
                                            onMoreVersionsClick={onAccordionClick}
                                            isAccordionOpen={isDownloadAccordionOpen}
                                            onVersionClick={(m) => handleSingleMediaDownload(m)}
                                            setCurrentMediaInContext={() => setCurrentMediaInContext ? setCurrentMediaInContext(`${media.id}`, !hasPremiumOnlyAccess) : null}
                                        />
                                    ) : (
                                        <>
                                            <button
                                                className={classNames(styles['track-list__download-btn'], { [styles['track-list__download-btn__downloaded']]: track.download_count })}
                                                onClick={() => handleSingleMediaDownload(track)}
                                                type="button"
                                                disabled={isDownloadLimitReached(track.download_count)}
                                            >
                                                <DownloadIcon />
                                            </button>
                                            <span className={classNames('hide-filters-open', styles['track-list__download-btn-text'])}>
                                                {track.download_count} of 3 Downloads
                                            </span>
                                        </>
                                    )}
                                </div>
                                <div className={classNames(styles['track-list__column'], styles['track-list__column--download-actions'])}>
                                    <button
                                        className={classNames(styles['track-list__action-btn-desktop'],
                                            {
                                                [styles['track-list__action-btn-desktop-track-is-in-list']]: mediaQueueItemId
                                            })}
                                        onClick={() => !mediaQueueItemId ? addDownloadTrackToQueue(track, downloadAlbum) : removeTrackFromQueue(mediaQueueItemId)}
                                        data-tip={(!mediaQueueItemId) ? 'Add to Queue' : 'Remove from Queue'}
                                        type="button">
                                        {(!mediaQueueItemId) ? <AddToQueue /> : <RemoveFromQueue />}
                                    </button>
                                    <button
                                        className={classNames(styles['track-list__action-btn-desktop'],
                                            {
                                                [styles['track-list__action-btn-desktop-track-is-in-list']]: mediaCrateItem
                                            })}
                                        onClick={() => !mediaCrateItem ? addDownloadTrackToCrate(track.id) : removeTrackFromCrate(track.id)}
                                        data-tip={(!mediaCrateItem) ? 'Add to Crate' : 'Remove from Crate'}
                                        type="button">
                                        {(!mediaCrateItem) ? <AddToCrate /> : <RemoveFromCrate />}
                                    </button>
                                    <div>
                                        <div className={styles['track-list__three-dots-container']}>
                                            <ThreeDotsButton onClick={(e) => {
                                                if (isAnonymous || (isPremiumOnly && !hasPremiumOnlyAccess)) {
                                                    if (setCurrentMediaInContext) setCurrentMediaInContext(`${media.id}`, !hasPremiumOnlyAccess);
                                                    return;
                                                }
                                                openThreeDotsModalSheet('download:track', track.id, e.currentTarget.getBoundingClientRect().left, e.currentTarget.getBoundingClientRect().top + window.scrollY, false);
                                            }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </AnimateHeight>
            )}
        </div>
    );
}
/* eslint-enable jsx-a11y/anchor-is-valid */
/* eslint-enable jsx-a11y/click-events-have-key-events */
/* eslint-enable jsx-a11y/no-static-element-interactions */
/* eslint-enable react/no-unstable-nested-components */
