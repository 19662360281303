import CreateLogo from '../../../assets/icons/create/bpm-create-logo-icon.svg';
import SupremeLogo from '../../../assets/icons/bpm-supreme-logo-icon.svg';
import ArtistPlatformLogo from '../../../assets/icons/bpm-artist-platform-logo-icon.svg';
import { PlatformName } from './types';

interface PlatformLogoProps {
    platform: PlatformName;

}

export function PlatformLogo({ platform }: PlatformLogoProps) {
    switch (platform) {
        case 'BPM Create':
            return <CreateLogo />;
        case 'BPM Supreme':
            return <SupremeLogo />;
        default:
            return <ArtistPlatformLogo />;
    }
}
