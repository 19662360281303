import { useCallback } from 'react';
import { useGetFavoritedPresets } from '@bpm-web-app/swr-hooks';
import { getMutatedPresetsNotPaginated } from '@bpm-web-app/utils';
import SecondaryPageTitle from '../shared/secondary-page-title/secondary-page-title';
import styles from './favorite-presets.module.css';
import TrackListCreatePresets from '../shared/track-list/create-presets/track-list-create-presets';

export function FavoritePresets() {
    const { data: soundsData, mutate } = useGetFavoritedPresets();

    const mutateSound = useCallback(
        (id: string, progress: number) => {
            mutate(getMutatedPresetsNotPaginated(soundsData, id, progress));
        },
        [mutate, soundsData]
    );

    return (
        <div className="spacing__window">
            <SecondaryPageTitle title="Synth Presets" noPadding counter={soundsData?.pagination?.total} />
            <TrackListCreatePresets mutateSound={mutateSound} list={soundsData?.data} />
        </div>
    );
}
export default FavoritePresets;
