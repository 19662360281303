import { TrendingPeriod } from '@bpm-web-app/api-client';

export const DEFAULT_BROWSE_CARD_LIMIT = 20;
export const DEFAULT_BROWSE_TRENDING_LIMIT = 10;
export const DEFAULT_BROWSE_GENRES_LIMIT = 25;
export const DEFAULT_PACKS_LIMIT = 20;
export const DEFAULT_TRENDING_LIMIT = 50;
export const DEFAULT_SOUNDS_LIMIT = 20;
export const DEFAULT_LABELS_LIMIT = 50;
export const DEFAULT_PERIOD: TrendingPeriod = 'weekly';
export const FAVORITES_STALE_INTERVAL = 5 * 60 * 1000;
export const PLAYER_PREVIEW_SECONDS = 30;

export const DEFAULT_DROPDOWN_CATEGORY = {
    label: 'All',
    value: '',
};

export const DEFAULT_RELEASES_DROPDOWN_CATEGORY = {
    label: 'All Categories',
    value: '',
};

export const DEFAULT_GENRE_DROPDOWN_CATEGORY = {
    label: 'All Genres',
    value: '',
};

export const DEFAULT_ACCOUNT_DROPDOWN_CATEGORY = {
    label: 'DJ Type',
    value: '',
};

export const STREAM_PLATFORM_BASE_PATH = '/s';
export const DOWNLOAD_PLATFORM_BASE_PATH = '/d';

export const LOOP_TAG_VALUE = 'Loop';
export const ONE_SHOT_TAG_VALUE = 'One-Shot';
