import { ReactNode, createContext, useState, useMemo, useEffect } from 'react';

export interface QualityControlContextProperties {
    isQualityControlActive: boolean;
    setIsQualityControlActive: (isQualityControlActive: boolean) => void;
    qualityControlIsAvailable: boolean;
    setQualityControlIsAvailable: (isQualityControlActive: boolean) => void;
    selectedSounds: string[];
    setSelectedSounds: (sounds: string[]) => void;
    shouldMutateData: boolean;
    setShouldMutateData: (shouldMutateData: boolean) => void;
}

export const QualityControlContext = createContext<QualityControlContextProperties>({
    isQualityControlActive: false,
    qualityControlIsAvailable: false,
    setIsQualityControlActive: () => null,
    setQualityControlIsAvailable: () => null,
    selectedSounds: [],
    setSelectedSounds: () => null,
    shouldMutateData: false,
    setShouldMutateData: () => null,
});

export interface QualityControlProviderProps {
    children: ReactNode;
    initialState?: {
        isQualityControlActive?: boolean;
        qualityControlIsAvailable?: boolean;
        selectedSounds?: string[],
        shouldMutateData?: boolean,
    };
}

export function QualityControlProvider({ children, initialState }: QualityControlProviderProps) {
    const [isQualityControlActive, setIsQualityControlActive] = useState<QualityControlContextProperties['isQualityControlActive']>(initialState?.isQualityControlActive || false);
    const [qualityControlIsAvailable, setQualityControlIsAvailable] = useState<QualityControlContextProperties['qualityControlIsAvailable']>(initialState?.qualityControlIsAvailable || false);
    const [selectedSounds, setSelectedSounds] = useState<QualityControlContextProperties['selectedSounds']>(initialState?.selectedSounds || []);
    const [shouldMutateData, setShouldMutateData] = useState<QualityControlContextProperties['shouldMutateData']>(initialState?.shouldMutateData || false);

    useEffect(() => {
        if (qualityControlIsAvailable) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            if (localStorage['edit_mode'] === 'true') {
                setIsQualityControlActive(true);
            }
        }
    }, [qualityControlIsAvailable]);

    useEffect(() => {
        if (qualityControlIsAvailable) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            localStorage['edit_mode'] = isQualityControlActive;
        }
    }, [isQualityControlActive, qualityControlIsAvailable]);

    const value = useMemo(
        () => ({
            isQualityControlActive,
            setIsQualityControlActive,
            qualityControlIsAvailable,
            setQualityControlIsAvailable,
            selectedSounds,
            setSelectedSounds,
            shouldMutateData,
            setShouldMutateData,
        }),
        [isQualityControlActive, qualityControlIsAvailable, selectedSounds, shouldMutateData]
    );
    return <QualityControlContext.Provider value={value}>{children}</QualityControlContext.Provider>;
}
