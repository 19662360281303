import { useViewport } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { forwardRef } from 'react';
import { MobileDrawer } from '../../mobile-drawer/mobile-drawer';
import { AppLink, useSession } from '../../shared';
import { PlatformSwitchContent } from '../../shared/platform-switch/platform-switch-content';
import { PlatformName } from '../../shared/platform-switch/types';
import AvatarPlaceholder from './avatar-placeholder.svg';
import styles from './supreme-profile-menu.module.css';

interface SupremeProfileMenuProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    userData: any;
    platforms: { name: PlatformName }[];
}

export function ProfileMenuContent({ userData, platforms }: Pick<SupremeProfileMenuProps, 'userData' | 'platforms'>) {
    const { user } = useSession();
    const { logout } = useSession();

    return (
        <div className={styles['profile-menu__container']}>
            <div className={styles['profile-menu__header']}>
                <div className={styles['profile-menu__user-infos']}>
                    <div className={styles['profile-menu__user-avatar']}>
                        {userData?.data?.user?.profile_image_thumbnail_url ? (
                            <img
                                src={userData?.data?.user?.profile_image_thumbnail_url}
                                alt={userData?.data?.user?.full_name || ''}
                                width={48}
                                height={48}
                            />
                        ) : (
                            <AvatarPlaceholder />
                        )}
                    </div>
                    <div className={styles['profile-menu__user-info']}>
                        <div className={styles['profile-menu__user-name']}>{`${user?.first_name} ${user?.last_name}`}</div>
                        <div className={styles['profile-menu__user-email']}>{user?.email}</div>
                    </div>
                </div>
                <AppLink href="/account/overview">
                    <a className={styles['profile-menu__account-button']}>
                        Manage Account
                    </a>
                </AppLink>
            </div>
            <div className={styles['profile-menu__items']}>
                <PlatformSwitchContent platforms={platforms} />
                <button
                    type="button"
                    aria-label="logout"
                    className={classNames(styles['profile-menu__link'], styles['profile-menu__link--logout'])}
                    onClick={logout}
                >
                    Logout
                </button>
            </div>
        </div>

    );
}

export const SupremeProfileMenu = forwardRef<HTMLDivElement, SupremeProfileMenuProps>(({
    isOpen,
    userData,
    setIsOpen,
    platforms
}, ref) => {
    const { isMobile } = useViewport();

    if (isMobile) {
        return (
            <MobileDrawer isOpen={isOpen} onClose={() => setIsOpen(false)} className={styles['profile-menu__mobile-drawer']}>
                <ProfileMenuContent userData={userData} platforms={platforms} />
                <button
                    type="button"
                    aria-label="Close"
                    className={styles['profile-menu__close-button']}
                    onClick={() => setIsOpen(false)}>
                    Close
                </button>
            </MobileDrawer>
        );
    }

    return (
        <div
            ref={ref}
            className={classNames(styles['profile-menu__desktop-menu'], {
                [styles['profile-menu__desktop-menu--active']]: isOpen,
            })}
        >
            <ProfileMenuContent userData={userData} platforms={platforms} />
        </div>
    );
});
