import { UserPlaylist } from '@bpm-web-app/api-client';
import { IdBatchBody } from '@bpm-web-app/stream-api-sdk';
import { IdBatchBody as DonwloadIdBatchBody } from '@bpm-web-app/download-api-sdk';
import { userPlaylistDetailSWRKey } from '@bpm-web-app/swr-hooks';
import { useApiErrorHandler, showToast, Analytics } from '@bpm-web-app/utils';
import { mutate } from 'swr';

export const useUserPlaylistsDetails = () => {
    const errorHandler = useApiErrorHandler();

    const addMediaToPlaylist = async (playlistId: string, body: IdBatchBody) => {
        try {
            const response = await UserPlaylist.addMultipleMediaToUserPlaylist(playlistId, body);
            mutate(userPlaylistDetailSWRKey(false, playlistId), { revalidate: true });
            showToast({ type: 'success', message: 'Added to playlist.', buttonText: 'Go To Playlist', replaceRouteOnButtonClick: `/my-playlist/${response.data.id}` });
        } catch (error) {
            errorHandler({ error });
        }
    };

    const addAlbumToPlaylist = async (playlistId: string, body: DonwloadIdBatchBody) => {
        try {
            const response = await UserPlaylist.addMultipleAlbumToUserPlaylist(playlistId, body);
            mutate(userPlaylistDetailSWRKey(true, playlistId), { revalidate: true });
            Analytics.trackClick('add_album_to_playlist', playlistId);
            showToast({ type: 'success', message: 'Added to playlist.', buttonText: 'Go To Playlist', replaceRouteOnButtonClick: `/my-playlist/${response.data.id}` });
        } catch (error) {
            errorHandler({ error });
        }
    };

    return {
        addMediaToPlaylist,
        addAlbumToPlaylist
    };
};
