import { IdPlayBody, InlineResponse200 as StreamInlineResponse200, MediaApi as StreamMediaApi, SortByQueryOptions } from '@bpm-web-app/stream-api-sdk';
import { InlineResponse200 as DownloadInlineResponse200, MediaApi as DownloadMediaApi, IdPlayBody as DownloadIdBody, InlineResponse2001 as DownloadInlineResponse2001 } from '@bpm-web-app/download-api-sdk';
import { downloadConfig, streamConfig } from '../config';
import { SearchPaginatedQuery } from '../typings/search-query';
import { SearchQuery } from '../typings';

type SearchAlbumReturn<E extends boolean> = E extends true
    ? Promise<DownloadInlineResponse200>
    : Promise<StreamInlineResponse200>;
export class Media {
    static streamClient = new StreamMediaApi(streamConfig);

    static downloadClient = new DownloadMediaApi(downloadConfig);

    static getClient(isDownload: boolean) {
        return isDownload ? this.downloadClient : this.streamClient;
    }

    static reportMediaPlay = (isDownload: boolean, id: number, body: IdPlayBody | DownloadIdBody) => Media.getClient(isDownload).reportMediaPlay(id, body as IdPlayBody);

    static reportMedia = (isDownload: boolean, id: number) => Media.getClient(isDownload).reportMedia(id);

    static getVersions = (isDownload: boolean, library: string) => Media.getClient(isDownload).getVersions(library);

    static searchVersions = (isDownload: boolean, args: Omit<SearchQuery, 'sort_by'>) => {
        // eslint-disable-next-line no-constant-condition
        if (isDownload || 1) {
            return Media.downloadClient.searchVersions(
                args.category,
                args.library,
                args.genre as string,
                args.artist,
                args.hide_remix ? '1' : '0',
                args.hide_originals ? '1' : '0',
                args.hide_exclusives ? '1' : '0',
                args.hide_explicit ? '1' : '0',
                args.hide_prev_downloaded ? '1' : '0',
                args.version as string,
                args.key as string,
                args.bpm_min,
                args.bpm_max,
                args.term,
                args.date,
                args.remixer);
        }
    };

    static getGenres = (isDownload: boolean, library?: string) => Media.getClient(isDownload).getGenres(library);

    static searchGenres = (isDownload: boolean, args: Omit<SearchQuery, 'sort_by'>) => {
        if (isDownload) {
            return Media.downloadClient.searchGenres(
                args.category,
                args.library,
                args.genre as string,
                args.artist,
                args.hide_remix ? '1' : '0',
                args.hide_originals ? '1' : '0',
                args.hide_exclusives ? '1' : '0',
                args.hide_explicit ? '1' : '0',
                args.hide_prev_downloaded ? '1' : '0',
                args.version as string,
                args.key as string,
                args.bpm_min,
                args.bpm_max,
                args.term,
                args.date,
                args.remixer);
        }
        return Media.streamClient.searchGenres(
            args.category,
            args.library,
            args.genre as string,
            args.artist,
            args.version as string,
            args.key as string,
            args.bpm_min,
            args.bpm_max,
            args.term,
            args.date
        );
    };

    static searchMedia = (args: SearchPaginatedQuery) =>
        Media.streamClient.searchMedia(
            args.limit,
            args.skip,
            args.category,
            args.library,
            /** NOTE: this values can be an array when we want to combine them */
            args.genre as string,
            args.artist,
            args.version as string,
            /** NOTE: this values can be an array when we want to combine them */
            args.key as string,
            args.keyType,
            args.bpm_min,
            args.bpm_max,
            args.sort_by,
            args.term,
            args.date
        );

    static searchAlbum<T extends boolean>(isDownload: T, args: SearchPaginatedQuery): SearchAlbumReturn<T> {
        if (isDownload) {
            return Media.downloadClient.searchAlbum(
                args.limit,
                args.skip,
                args.category,
                args.library,
                args.genre as string,
                args.artist,
                args.hide_remix ? '1' : '0',
                args.hide_originals ? '1' : '0',
                args.hide_exclusives ? '1' : '0',
                args.hide_explicit ? '1' : '0',
                args.hide_prev_downloaded ? '1' : '0',
                args.version as string,
                args.key as string,
                args.keyType,
                args.bpm_min,
                args.bpm_max,
                args.sort_by,
                args.term,
                args.date,
                args.remixer
            ) as SearchAlbumReturn<T>;
        }
        return Media.streamClient.searchAlbum(
            args.limit,
            args.skip,
            args.category,
            args.library,
            args.genre as string,
            args.artist,
            args.version as string,
            args.key as string,
            args.keyType,
            args.bpm_min,
            args.bpm_max,
            args.sort_by,
            args.term,
            args.date
        ) as SearchAlbumReturn<T>;
    }

    static countAlbums = (isDownload: boolean, args: SearchQuery): Promise<DownloadInlineResponse2001> => {
        if (isDownload) {
            return Media.downloadClient.countAlbums(
                args.category,
                args.library,
                args.genre as string,
                args.artist,
                args.hide_remix ? '1' : '0',
                args.hide_originals ? '1' : '0',
                args.hide_exclusives ? '1' : '0',
                args.hide_explicit ? '1' : '0',
                args.hide_prev_downloaded ? '1' : '0',
                args.version as string,
                args.key as string,
                args.keyType,
                args.bpm_min,
                args.bpm_max,
                args.term,
                args.date,
                args.remixer
            );
        }
        return Media.streamClient.countAlbums(
            args.category,
            args.library,
            args.genre as string,
            args.artist,
            args.version as string,
            args.key as string,
            args.keyType,
            args.bpm_min,
            args.bpm_max,
            args.term,
            args.date
        );
    };

    static getMedia = (isDownload: boolean, id: number) => Media.getClient(isDownload).getMedia(id);

    static getFeatured = (
        isDownload: boolean,
        args: {
            limit?: number;
            skip?: number;
            library?: string;
            keyType?: string;
            sort_by?: SortByQueryOptions;
        }
    ) =>
        isDownload
            ? Media.downloadClient.getAlbumFeatured(args.limit, args.skip, args.library, args.keyType, args.sort_by)
            : Media.streamClient.getMediaFeatured(args.library, args.keyType, args.sort_by);

    static getTrendingMedia = (args: { period: string; limit?: number; skip?: number; category?: string; library?: string }) =>
        Media.streamClient.getTrendingMediaPeriod(args.period, args.limit, args.skip, args.category, args.library);

    static getFeaturedBpmOriginalMedia = (args: { limit?: number; skip?: number; keyType?: string; sort_by?: SortByQueryOptions; library?: string }) =>
        Media.streamClient.getFeaturedBpmoriginalMedia(args.limit, args.skip, args.keyType, args.sort_by, args.library);

    static getCategories = (isDownload: boolean, library: string, withGenres?: boolean) => Media.getClient(isDownload).getCategories(library, withGenres ? '1' : undefined);

    static getMediaForCategory = (args: SearchQuery) =>
        Media.streamClient.getMediaForCategory(
            args.category,
            args.library,
            args.genre as string,
            args.artist,
            args.version as string,
            args.key as string,
            args.keyType,
            args.bpm_min,
            args.bpm_max,
            args.sort_by,
            args.term,
            args.date
        );

    static searchSuggestMediaArtist = (args: { term: string; library?: string; keyType?: string }) =>
        Media.streamClient.suggestMediaArtist(args.term, args.library, args.keyType);

    static getAlbum = (isDownload: boolean, id: number) => isDownload ? Media.downloadClient.getAlbum(id) : Media.streamClient.getAlbum(id);

    static getSimilarMedia = (mediaId: number) => Media.streamClient.similarMedia(mediaId);

    static getFavoriteMedia = (library?: string) => Media.streamClient.getFavMedia(library);

    static addMediaToFavorites = (mediaId: number) => Media.streamClient.favoriteMedia(mediaId);

    static removeMediaFromFavorites = (mediaId: number) => Media.streamClient.unfavoriteMedia(mediaId);

    static addMultipleMediaToFavorites = (mediaIds: number[]) => Media.streamClient.favoriteMediaBatch({ media_ids: mediaIds });
}
