import { FC, FunctionComponent, SVGProps } from 'react';
import { AppLink } from '../../shared';
import styles from './header-account.module.css';

type Props = {
    IconComponent: FunctionComponent<SVGProps<SVGSVGElement>>;
    title: string;
};

export const HeaderAccount: FC<Props> = ({ IconComponent, title }) => (
    <div className={styles['header-account']}>
        <AppLink href="/">
            <a className={styles['header-account__link']}>
                <IconComponent className={styles['header-account__logo']} /> {title}
            </a>
        </AppLink>
        <span className={styles['header-account__page-title']}>
            <span>Account</span>
        </span>
    </div>
);
