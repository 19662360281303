import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';

import { useApiErrorHandler } from '@bpm-web-app/utils';
import Add from '../../assets/icons/add.svg';
import { useUserPlaylists } from '../shared/three-dots-sheet/useUserPlaylists';
import styles from './my-playlist-checkboxes.module.css';
import { CreatePlaylistForm } from '../playlists-form/playlists-form-create-playlist';
import { useUserPlaylistCategories } from '../shared/three-dots-sheet/useUserPlaylistCategories';

type Props = {
    onCreated: (id: number) => void
};

export const MyPlaylistCreateButton: FC<Props> = ({ onCreated }) => {
    const [selectedData, setSelectedData] = useState<{ name: string, category: string }>({ name: '', category: '' });
    const [openForm, setOpenForm] = useState<{ onSubmit:(data: { name: string, category: string }) => void, onClose: () => void } | null>(null);
    const { createPlaylist } = useUserPlaylists(selectedData.category);
    const { userPlaylistCategories } = useUserPlaylistCategories();
    const errorHandler = useApiErrorHandler();

    const addPlaylist = () => setOpenForm({
        onSubmit: setSelectedData,
        onClose: () => setOpenForm(null),
    });

    const handleDataChange = async () => {
        setOpenForm(null);

        try {
            const response = await createPlaylist(selectedData.name, !selectedData.category);

            if (response) {
                onCreated(Number(response.data.id));
            }
        } catch (error) {
            errorHandler({ error });
        }
    };

    useEffect(() => {
        if (selectedData.name) {
            handleDataChange();
        }
    }, [selectedData]);

    return (
        <>
            <li className={classNames(styles['my-playlists-item'], styles['my-playlists-item--footer'])}>
                <button type="button" className={styles['my-playlists-item__wrapper']} onClick={addPlaylist}>
                    <Add />
                    <span>Create New Playlist</span>
                </button>
            </li>

            {openForm && <CreatePlaylistForm categories={userPlaylistCategories?.data} {...openForm} />}
        </>
    );
};
