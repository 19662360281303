import { Artist } from '@bpm-web-app/stream-api-sdk';
import { appendQueryParams, getInitialsFromName, useHubSwitch, generateArtistLinks } from '@bpm-web-app/utils';
import { useCallback, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { AppLink, ThreeDotsSheetContext } from '../shared';
import BPMIcons from '../shared/bpm-icons/bpm-icons';
import NowPlayingAnimation from '../shared/now-playing-animation/now-playing-animation';
import styles from './row-component.module.css';
import PlayIcon from '../../assets/icons/player-play-alt.svg';
import PauseIcon from '../../assets/icons/player-pause-alt.svg';
import useFollowArtist from '../shared/three-dots-sheet/useFollowArtist';

type RowType = 'artist' | 'track' | 'album' | 'playlist';

interface RowComponentProps {
    type: RowType;
    id: number;
    isPlaying?: boolean;
    cover_url?: string;
    title?: string;
    albumName?: string;
    artist?: string;
    artists?: Artist[];
    bpm?: number;
    display_key?: string;
    genre?: string;
    mediaCount?: number;
    onClick?: () => void;
    onCoverClick?: () => void;
    hasHover?: boolean;
}

export function RowComponent({ type, id, isPlaying = false, cover_url, title, albumName, artist, artists, bpm, display_key, genre, mediaCount, onClick, onCoverClick, hasHover }: RowComponentProps) {
    const { openThreeDotsModalSheet } = useContext(ThreeDotsSheetContext);
    const { isDownload } = useHubSwitch();

    const { followArtist, unfollowArtist, isArtistFollowed } = useFollowArtist(type === 'artist' && id ? id : 0);

    const handleFollow = useCallback(() => (isArtistFollowed ? unfollowArtist() : followArtist()), [unfollowArtist, followArtist, isArtistFollowed]);

    const handleOpenThreeDots = (e: React.MouseEvent<HTMLElement>) => {
        const { top, left } = e.currentTarget.getBoundingClientRect();
        switch (type) {
            case 'artist':
                openThreeDotsModalSheet('artist', +id, left ?? 0, top ?? 0, false);
                break;
            case 'track':
                openThreeDotsModalSheet('track', +id, left ?? 0, top ?? 0, false);
                break;
            case 'playlist':
                openThreeDotsModalSheet('exclusive-playlist', id, left ?? 0, top ?? 0, false);
                break;
            case 'album':
                openThreeDotsModalSheet(isDownload ? 'download:album' : 'stream:album', id, left ?? 0, top ?? 0, false);
                break;
            default:
                break;
        }
    };

    const attributes = () => {
        switch (type) {
            case 'artist':
                return (
                    <div className={styles['row-component--item-subtitle']}>{`${mediaCount} Tracks`}</div>
                );
            case 'track':
                return (
                    <div className={styles['row-component--item-attributes']}>{`${bpm} ${bpm && display_key ? '/' : ''} ${display_key} ${genre ? `/ ${genre}` : ''}`}</div>
                );
            case 'album':
                return (
                    <div className={styles['row-component--item-attributes']}>{`${mediaCount} Tracks / ${genre}`}</div>
                );
            default:
                return null;
        }
    };

    const image = () => {
        switch (type) {
            case 'artist':
                return (
                    <div
                        className={styles['row-component--artist-image']}
                        role="button"
                        tabIndex={-1}
                        onClick={(e) => {
                            if (onCoverClick) {
                                e.stopPropagation();
                                e.preventDefault();
                                onCoverClick();
                            }
                        }}
                        onKeyPress={(e) => {
                            if (e.key !== 'Enter') { return; }
                            onCoverClick?.();
                        }}>
                        {cover_url ? (
                            <img
                                src={appendQueryParams(cover_url, { key: 'dw', value: 56 })}
                                draggable={false}
                                alt={`${title} ${type}`}
                                srcSet={`${appendQueryParams(cover_url, { key: 'dw', value: 112 })} 2x`} />
                        ) : (
                            <div className={styles['row-component--name-initials']}>{getInitialsFromName(title || '')}</div>
                        )}
                        <div className={classNames(styles['row-component--item-play-overlay'], styles['row-component--item-play-overlay-round'])}>{isPlaying ? <PauseIcon /> : <PlayIcon />}</div>
                    </div>
                );
            default:
                return (
                    <div
                        className={styles['row-component--item-image']}
                        role="button"
                        tabIndex={-1}
                        onClick={(e) => {
                            if (onCoverClick) {
                                e.stopPropagation();
                                e.preventDefault();
                                onCoverClick();
                            }
                        }}
                        onKeyPress={(e) => {
                            if (e.key !== 'Enter') { return; }
                            onCoverClick?.();
                        }}>
                        {isPlaying
                            ? <NowPlayingAnimation />
                            :
                            <img
                                src={appendQueryParams(cover_url, { key: 'dw', value: 56 })}
                                srcSet={`${appendQueryParams(cover_url, { key: 'dw', value: 112 })} 2x`}
                                alt={`${title} ${type}`}
                            />
                        }
                        <div className={styles['row-component--item-play-overlay']}>{isPlaying ? <PauseIcon /> : <PlayIcon />}</div>
                    </div>
                );
        }
    };

    const artistLink = useCallback((a: Artist) => {
        return (
            <AppLink href={`/artist/${a.slug}`} key={a.id}>
                <button
                    type="button"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    className="underline-link"
                >
                    {a.name}
                </button>
            </AppLink>
        );
    }, []);

    const separator = useCallback((sep: string, i: number) => {
        return <span key={i}>{sep}</span>;
    }, []);

    const subtitle = useMemo(() => {
        if (artist !== undefined && artists !== undefined) {
            return generateArtistLinks(
                artist,
                artists,
                artistLink,
                separator,
            );
        }

        return null;
    }, [artist, artists, artistLink, separator]);

    return (
        <div
            key={id}
            className={classNames(styles['row-component--item'], { [styles['row-component--has-hover']]: hasHover })}
            role="button"
            tabIndex={0}
            onClick={onClick}
            onKeyPress={(e) => {
                if (e.key !== 'Enter') { return; }
                onClick?.();
            }}
        >
            {image()}
            <div className={styles['row-component--item-infos']}>
                <div className={styles['row-component--item-title']}>{albumName || title}</div>
                {artists && artist
                    ?
                    (
                        <div className={styles['row-component--item-subtitle']}>
                            {subtitle}
                        </div>
                    )
                    :
                    null}
                {attributes()}
            </div>
            {type === 'artist' ?
                (
                    <button
                        type="button"
                        className={styles['row-component--button']}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleFollow();
                        }}
                    >
                        {isArtistFollowed ? <BPMIcons.FollowActiveIcon /> : <BPMIcons.FollowIcon />}
                    </button>
                )
                : (
                    <button
                        type="button"
                        className={classNames({ [styles['row-component--button']]: hasHover })}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleOpenThreeDots(e);
                        }}
                    >
                        <BPMIcons.ThreeDotsIcon />
                    </button>
                )}
        </div>
    );
}
