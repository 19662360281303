import { SectionApi } from '@bpm-web-app/download-api-sdk';
import { downloadConfig } from '../../config';

export class DownloadSection {
    static client = new SectionApi(downloadConfig);

    static getSections = (location: string, library: string) => DownloadSection.client.getSections(library, location);

    static getSectionByName = (location: string, library: string, name: string) => DownloadSection.client.getSectionByName(library, location, name);
}
