import classNames from 'classnames';
import { useMemo } from 'react';
import { CustomIcon } from '../custom-icon/custom-icon';
import styles from './empty-state.module.css';
import { AppLink } from '../app-link/app-link';
import { CirclesButton } from './circles-button';
import { EmptyStateProps } from './types';

export function EmptyState({ variant = 'dynamic', title, subtitle, actionLabel, onPress, icon = 'plus-icon', noPadding = false, hasBackground = true, actionIcon = 'plus-icon', href, verticalMargins }: EmptyStateProps) {
    const verticalMarginsStyle = useMemo(
        () =>
            verticalMargins ? {
                marginTop: `${verticalMargins}px`,
                marginBottom: `${verticalMargins}px`,
            } : {},
        [verticalMargins]
    );

    return (
        <div
            className={classNames(styles['empty-state'],
                { 'spacing__window--horizontal': !noPadding },
                { [styles['empty-state__has-background']]: hasBackground },
            )}
            style={verticalMarginsStyle}
        >
            <div className={classNames(styles['empty-state__content-container'])}>
                <CirclesButton icon={icon} variant={variant} onPress={onPress} href={href} />
                <div className={classNames(styles['empty-state__title'])}>{title}</div>
                <div className={classNames(styles['empty-state__subtitle'])}>{subtitle}</div>
                {actionLabel && href ? (
                    <AppLink key={href} href={(href as string)}>
                        <div
                            tabIndex={0}
                            role="button"
                            onKeyDown={() => { }}
                            onClick={onPress}
                            className={classNames(styles['empty-state__action-container'])}
                        >
                            <div className={classNames(styles['empty-state__action-label'])}>{actionLabel}</div>
                            <div className={classNames(styles[`empty-state__action-container--${actionIcon}`])}><CustomIcon type={actionIcon} hasIconHover={false} color={variant} /></div>
                        </div>
                    </AppLink>
                ) : actionLabel && onPress ? (
                    <button
                        tabIndex={0}
                        type="button"
                        onKeyDown={() => { }}
                        onClick={onPress}
                        className={classNames(styles['empty-state__action-container'])}
                    >
                        <div className={classNames(styles['empty-state__action-label'])}>{actionLabel}</div>
                        <div className={classNames(styles[`empty-state__action-container--${actionIcon}`])}><CustomIcon type={actionIcon} hasIconHover={false} color={variant} /></div>
                    </button>
                ) : null}
            </div>
        </div>
    );
}
