import { useCategories, useSearchAlbum, useSearchMedia } from '@bpm-web-app/swr-hooks';
import { useContext, useEffect, useMemo, useState } from 'react';
import { SortByQueryOptions } from '@bpm-web-app/stream-api-sdk';
import { DEFAULT_RELEASES_DROPDOWN_CATEGORY, getCurrentPlatformLink, useHubSwitch, useSupremeFilterParams } from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import styles from './new-releases-per-category.module.css';
import { LibraryTabsContext } from '../../../../../utils/src/lib/library-tabs.context';
import { allApiSortOptions } from '../../sort-options-sheet/sort-options-sheet';
import { usePagination } from '../../shared/paging/paging';
import { BreakpointView, PrimaryPageTitle } from '../../shared';
import Dropdown from '../../dropdown/dropdown';
import { TrackListFilters } from '../../shared/track-list/track-list-filters/track-list-filters';
import { TrackListSupreme } from '../../shared/track-list/supreme/track-list-supreme';
import { TrackListPresetSupreme } from '../../shared/track-list/supreme/track-list-supreme-helpers';

export interface NewReleasesPerCategoryProps {
    categorySlug: string;
}

export function NewReleasesPerCategory({ categorySlug }: NewReleasesPerCategoryProps) {
    const router = useRouter();
    const { sortBy: sortByFromQuery } = router.query;
    const [sortType, setSortType] = useState<SortByQueryOptions>((sortByFromQuery as unknown as SortByQueryOptions) || SortByQueryOptions.DateDesc);
    const { libraryProperty } = useContext(LibraryTabsContext);
    const { isDownload } = useHubSwitch();

    const defaultFilters = useMemo(() => ({ category: categorySlug }), [categorySlug]);

    const { limit, page, bottomComponent, setPagination } = usePagination();
    const query = useSupremeFilterParams(true, { ...defaultFilters, sort_by: sortType, limit, skip: (page - 1) * limit });

    const { data, mutate, isLoading } = isDownload ? useSearchAlbum(query) : useSearchMedia(query);
    const library = isDownload ? TrackListPresetSupreme.Download : TrackListPresetSupreme.Stream;
    const flatMediaList = useMemo(() => data?.data || [], [data]);

    useEffect(() => {
        setPagination(data?.pagination);
    }, [data, setPagination]);

    const { categories, isLoading: isAllCategoriesLoading } = useCategories(libraryProperty);

    const currentCategory = useMemo(() => categories && categories.find((category) => category.slug === categorySlug), [categories, categorySlug]);

    /* there could be a scenario where a user was on a page
     * of the category that exists for Download, but not for Stream.
     * If that's the case, let's just navigate back to the listing page */
    if (!isAllCategoriesLoading && !currentCategory) {
        router.replace(getCurrentPlatformLink('/releases'));
    }

    const renderDropdown = () => (
        <Dropdown<string>
            layoutType="border-input"
            isDefaultWidth
            onClick={(option) => {
                if (option) {
                    router.push({
                        pathname: router.pathname,
                        query: {
                            ...router.query,
                            category: option,
                        },
                    });
                } else {
                    router.push({
                        pathname: router.pathname,
                    });
                }
            }}
            value={categorySlug}
            options={[
                DEFAULT_RELEASES_DROPDOWN_CATEGORY,
                ...(categories || []).map(({ name, slug }) => ({
                    label: name,
                    value: slug,
                })),
            ]}
        />
    );

    return (
        <div className="spacing__window">
            <div className={styles['new-releases-per-category__intro']}>
                <PrimaryPageTitle title="New Releases" noPadding />
                <BreakpointView mobileChildren={null} desktopChildren={renderDropdown()} />
            </div>
            <BreakpointView mobileChildren={<div className="spacing__window--horizontal">{renderDropdown()}</div>} desktopChildren={null} />
            <div className={styles['new-releases-per-category__container']}>
                <div className={classNames('spacing--top')}>
                    <TrackListFilters dynamicActiveTabColor showActiveFilters defaultFilters={defaultFilters} />
                </div>
                <div className={styles['new-releases-per-category__list']}>
                    <TrackListSupreme
                        isSortable
                        hasColumnSorting
                        preset={library}
                        list={flatMediaList}
                        isLoading={isLoading || isAllCategoriesLoading}
                        onSort={(nextSort) => setSortType(nextSort as SortByQueryOptions)}
                        sortOptions={allApiSortOptions}
                        selectedSortType={sortType}
                        indexOffset={(page - 1) * limit}
                        onDownloadRevalidate={(downloadMedia) => {
                            if (downloadMedia) {
                                // eslint-disable-next-line no-param-reassign
                                downloadMedia.download_count += 1;
                                /* for some reason, mutate of useSWRInfinite handles opts differently
                                 * than a normal useSWR. See a reported bug for more info:
                                 * https://github.com/vercel/swr/issues/1899 */
                                mutate(data as any, false);
                            }
                        }}
                    />
                </div>
                {data && data.pagination && data.pagination?.total > 25 ? bottomComponent : null}
            </div>
        </div>
    );
}

export default NewReleasesPerCategory;
