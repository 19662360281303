import { Configuration } from '@bpm-web-app/supreme-api-sdk';
import { Configuration as StreamConfiguration } from '@bpm-web-app/stream-api-sdk';
import { Configuration as DownloadConfiguration } from '@bpm-web-app/download-api-sdk';
import { Configuration as CreateConfiguration } from '@bpm-web-app/create-api-sdk';

/* eslint-disable @typescript-eslint/dot-notation */
export const supremeBasePath = process.env['NEXT_PUBLIC_SUPREME_API_BASE_PATH'];
const streamBasePath = process.env['NEXT_PUBLIC_STREAM_API_BASE_PATH'];
const downloadBasePath = process.env['NEXT_PUBLIC_DOWNLOAD_API_BASE_PATH'];
const downloadDirectBasePath = process.env['NEXT_PUBLIC_DOWNLOAD_API_DIRECT_BASE_PATH'] || downloadBasePath;
const createBasePath = process.env['NEXT_PUBLIC_CREATE_API_BASE_PATH'];
const createadminBasePath = process.env['NEXT_PUBLIC_CREATE_ADMIN_API_BASE_PATH'];
const isDevelop = process.env['NEXT_PUBLIC_IS_DEVELOP'] === '1' || supremeBasePath?.includes('develop.');
const authCookieName = isDevelop ? 'bpm_session_develop' : 'bpm_session';
const adminCookieName = isDevelop ? 'bpm_admin_session_develop' : 'bpm_admin_session';
const partnerName = process.env['NEXT_PUBLIC_PARTNER_NAME'];

/* eslint-enable @typescript-eslint/dot-notation */

if (typeof supremeBasePath === undefined || typeof streamBasePath === undefined || typeof downloadBasePath === undefined || typeof createBasePath === undefined) {
    throw new Error('API basePath is missing from environment variables');
}

const parseCookie = () =>
    document.cookie
        .split(';')
        .map((v) => v.split('='))
        .reduce((acc, v) => {
            if (v.length > 1) {
                const key = decodeURIComponent(v[0].trim());
                acc[key] = decodeURIComponent(v[1].trim());
            }
            return acc;
        }, {} as { [key: string]: string });

export const apiKey = (key?: string) => {
    if (key === 'BPM-Integration') {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return partnerName!;
    }
    if (key === 'Admin-Authorization') {
        const cookies = parseCookie();
        // eslint-disable-next-line @typescript-eslint/dot-notation
        if (cookies[adminCookieName]) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            return `Bearer ${cookies[adminCookieName]}`;
        }
        return undefined;
    }
    const cookies = parseCookie();
    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (cookies[authCookieName]) {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        return `Bearer ${cookies[authCookieName]}`;
    }
    return undefined;
};

export const isAdminSession = () => {
    const cookies = parseCookie();
    return Boolean(cookies[adminCookieName]);
};

export const supremeConfig = new Configuration({
    basePath: supremeBasePath,
    includeCredentials: true,
    apiKey
});

export const streamConfig = new StreamConfiguration({
    basePath: streamBasePath,
    includeCredentials: true,
    apiKey
});

export const downloadConfig = new DownloadConfiguration({
    basePath: downloadBasePath,
    includeCredentials: true,
    apiKey
});

export const downloadDirectConfig = new DownloadConfiguration({
    basePath: downloadDirectBasePath,
    includeCredentials: true,
    apiKey
});

export const createConfig = new CreateConfiguration({
    basePath: createBasePath,
    includeCredentials: true,
    apiKey
});

export const createAdminConfig = new CreateConfiguration({
    basePath: createadminBasePath,
    includeCredentials: true,
    apiKey
});
