import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useCuratedSetDetail, useCuratedSetsByCurator } from '@bpm-web-app/swr-hooks';
import {
    appendQueryParams,
    convertToPluralIfNeeded,
    downloadAlbumWithMediaToQueueItem,
    formatDateToString,
    QueueItem,
    rebuildReactTooltip,
    State,
    streamMediaWithAlbumToQueueItem,
    useHideSwitch,
    useHubSwitch,
    usePageDetails,
    usePlayerState,
    useUserSettings,
    useViewport,
} from '@bpm-web-app/utils';
import { CuratedSetWithMedia } from '@bpm-web-app/stream-api-sdk';
import { CuratedSetWithAlbum as DownloadCuratedSetWithAlbum } from '@bpm-web-app/download-api-sdk';
import classNames from 'classnames';
import { usePlayer } from '../../player-context';
import styles from './curated-set-detail.module.css';
import NewWindow from '../../../assets/icons/new-window.svg';
import { MediaDetailBanner } from '../../shared/ui/media-detail-banner/media-detail-banner';
import CardCarousel from '../../shared/card-carousel/card-carousel';
import { LibraryTabsContext, useHideLibraryTabs } from '../../../../../utils/src/lib/library-tabs.context';
import { ThreeDotsSheetContext } from '../../shared/three-dots-sheet/three-dots-sheet.context';
import useFollowCuratedSets from '../../shared/three-dots-sheet/useFollowCuratedSets';
import { LocalSortingKeys, sortMediaLocally, useLocalSort } from '../../sort-options-sheet/sort-options-sheet';
import { useArtistLinks } from '../../artist-link/artist-link';
import { PageNotFound } from '../page-not-found/page-not-found';
import Title from '../../title/title';
import { BreakpointView, GhostComponent, SignUpTrackList } from '../../shared';
import { PlayButton } from '../../shared/play-button/play-button';
import { SquaredButton } from '../../shared/squared-button/squared-button';
import BPMIcons from '../../shared/bpm-icons/bpm-icons';
import { ShareURL } from '../../shared/share-url/share-url';
import { ThreeDotsButton } from '../../shared/three-dots-button/three-dots-button';
import { CuratedSetCard } from '../../shared/card/curated-set-card/curated-set-card';
import { AddToFavoriteButton } from '../../shared/add-to-favorite-button/add-to-favorite-button';
import { TrackListSupreme } from '../../shared/track-list/supreme/track-list-supreme';
import { TrackListPresetSupreme } from '../../shared/track-list/supreme/track-list-supreme-helpers';
import { TrackListLoadingSupreme } from '../../shared/track-list/ghost-loading/track-list-loading-supreme';

export function CuratedSetDetail() {
    const { libraryProperty } = useContext(LibraryTabsContext);
    const router = useRouter();
    const { openThreeDotsModalSheet } = useContext(ThreeDotsSheetContext);
    const { curatedSetId } = router.query;
    const { isDownload, hub } = useHubSwitch();
    const [sortKey, setSortKey] = useLocalSort();
    const { originalListDetails, setQueue, togglePlayPause } = usePlayer();
    const playerState = usePlayerState();
    const { resource } = usePageDetails();
    const { isMobile } = useViewport();
    const { isAnonymous, setShowSignUpModal } = useUserSettings();

    useHideSwitch();
    useHideLibraryTabs();
    const generateArtistLinks = useArtistLinks();

    useEffect(() => {
        rebuildReactTooltip();
    }, []);

    const isCurrentListInPlayer = useMemo(() => originalListDetails?.identifier === curatedSetId && originalListDetails?.resource === resource, [originalListDetails, curatedSetId, resource]);

    const { addCuratedSetsToFavorites, removeCuratedSetFromFavorites, isCuratedSetFollowed } = useFollowCuratedSets(curatedSetId as unknown as number, libraryProperty);

    const handleFavorite = useCallback(() => {
        if (isAnonymous) {
            setShowSignUpModal({ type: 'track' });
            return;
        }

        if (isCuratedSetFollowed) {
            removeCuratedSetFromFavorites();
        } else {
            addCuratedSetsToFavorites();
        }
        rebuildReactTooltip();
    }, [isAnonymous, isCuratedSetFollowed, setShowSignUpModal, removeCuratedSetFromFavorites, addCuratedSetsToFavorites]);

    const openMoreSheet = useCallback(
        async (element: EventTarget) => {
            const { top: topPosition, left: leftPosition } = (element as HTMLButtonElement).getBoundingClientRect();
            openThreeDotsModalSheet('curatedSet', curatedSetId as string, leftPosition, topPosition, false);
        },
        [openThreeDotsModalSheet, curatedSetId]
    );

    const { data, isLoading, error, mutate } = useCuratedSetDetail(curatedSetId as unknown as number);
    const curatedSet = data?.data as CuratedSetWithMedia | DownloadCuratedSetWithAlbum;

    const { data: curatorCuratedSetsData, isLoading: isCuratedSetsFromCuratorLoading } = useCuratedSetsByCurator(data?.data.curator, libraryProperty, curatedSet?.slug);
    const curatorCuratedSets = curatorCuratedSetsData?.data;

    const numberOfTracksText = useMemo(() => {
        if (!curatedSet) return '';

        const tracks = isDownload ? (curatedSet as DownloadCuratedSetWithAlbum)?.albums?.length : (curatedSet as CuratedSetWithMedia)?.media?.length;

        return convertToPluralIfNeeded(tracks, 'Track');
    }, [curatedSet, isDownload]);

    const sortedTrackList = useMemo(
        () => (isDownload ? sortMediaLocally((curatedSet as DownloadCuratedSetWithAlbum)?.albums, sortKey) : sortMediaLocally((curatedSet as CuratedSetWithMedia)?.media, sortKey)),
        [curatedSet, isDownload, sortKey]
    );

    const handleCuratedSetPlay = useCallback(() => {
        if (isCurrentListInPlayer) {
            togglePlayPause();
            return;
        }

        const queueItems: QueueItem[] = isDownload
            ? sortedTrackList?.map((album) => downloadAlbumWithMediaToQueueItem(album))
            : sortedTrackList?.map((media) => streamMediaWithAlbumToQueueItem(media));

        if (queueItems) {
            setQueue(queueItems, 0, {
                identifier: curatedSetId as string,
                resource,
            });
        }
    }, [curatedSetId, isCurrentListInPlayer, isDownload, resource, setQueue, sortedTrackList, togglePlayPause]);

    const TrackListComponent = useMemo(() => {
        if (!curatedSet) return null;

        return (
            <TrackListSupreme
                list={sortedTrackList}
                preset={isDownload ? TrackListPresetSupreme.Download : TrackListPresetSupreme.Stream}
                isSortable
                onSort={(nextSort) => setSortKey(nextSort as LocalSortingKeys)}
                selectedSortType={sortKey}
                onDownloadRevalidate={(downloadMedia) => {
                    if (downloadMedia) {
                        // eslint-disable-next-line no-param-reassign
                        downloadMedia.download_count += 1;
                    }
                    mutate({ data: curatedSet }, false);
                }}
            />
        );
    }, [curatedSet, isDownload, mutate, setSortKey, sortKey, sortedTrackList]);

    if (curatedSetId && Number.isNaN(+curatedSetId)) {
        return <PageNotFound />;
    }

    if (isLoading) {
        return (
            <>
                <Title platform={hub} title="Loading Artist..." />
                <GhostComponent type="curated-set-detail" noPadding />
                <TrackListLoadingSupreme amount={10} preset={TrackListPresetSupreme.Download} />
                <div className="spacing--top" />
                <GhostComponent type="secondary-title" />
                <GhostComponent type="cards" elementsCount={10} cardSize="small" />
            </>
        );
    }

    /* TODO: potentially replace this with a custom 404 page if/when built */
    if (!isLoading && error) return null;

    return (
        <>
            <Title platform={hub} title={curatedSet?.title} />
            {curatedSet && (
                <div className={classNames(styles['curated-set-detail'])}>
                    <div className="spacing__window">
                        <MediaDetailBanner align={isMobile ? 'end' : 'start'}>
                            {{
                                image: (
                                    <picture>
                                        <source
                                            srcSet={`${appendQueryParams(curatedSet.image_url, { key: 'dw', value: 296 })}, ${appendQueryParams(curatedSet.image_url, { key: 'dw', value: 592 })} 2x`}
                                            media="(min-width: 1024px)"
                                        />
                                        <img
                                            src={appendQueryParams(curatedSet.image_url, { key: 'dw', value: 296 })}
                                            srcSet={`${appendQueryParams(curatedSet.image_url, { key: 'dw', value: 592 })} 2x`}
                                            alt={curatedSet.title}
                                            draggable={false}
                                        />
                                    </picture>
                                ),
                                text: (
                                    <>
                                        <div className={styles['curated-set-detail__title-container']}>
                                            <h2>{curatedSet.title}</h2>

                                            <BreakpointView desktopChildren={<ThreeDotsButton hasTooltip onClick={(event) => openMoreSheet(event.target)} />} mobileChildren={undefined} />
                                        </div>
                                        {curatedSet.curator && <p className={styles['curated-set-detail__curated-by']}>Curated by {curatedSet.curator}</p>}
                                        <div className={styles['curated-set-detail__info']}>
                                            <span>{numberOfTracksText}</span>
                                            <span>{curatedSet.category.name}</span>
                                            <span>{`Updated ${formatDateToString(curatedSet.updated_at)}`}</span>
                                        </div>
                                        <p className={styles['curated-set-detail__description']}>{generateArtistLinks(curatedSet.artist, curatedSet.artists)}</p>
                                        {curatedSet.youtube_url && (
                                            <a href={curatedSet.youtube_url} className={styles['curated-set-detail__watch-mix-link']} target="_blank" rel="noreferrer">
                                                Watch the Mix
                                                <NewWindow />
                                            </a>
                                        )}
                                    </>
                                ),
                                actions: (
                                    <BreakpointView
                                        desktopChildren={
                                            <div className={styles['curated-set-detail__actions']}>
                                                <PlayButton type="play" isPlaying={isCurrentListInPlayer && playerState === State.Playing} onPress={handleCuratedSetPlay} />
                                                <AddToFavoriteButton squared isFavorite={isCuratedSetFollowed} onClick={handleFavorite} hasTooltip />
                                                <ShareURL currentUrl />
                                            </div>
                                        }
                                        mobileChildren={
                                            <div>
                                                <ShareURL currentUrl />
                                                <ThreeDotsButton hasTooltip onClick={(event) => openMoreSheet(event.target)} />
                                            </div>
                                        }
                                    />
                                ),
                            }}
                        </MediaDetailBanner>
                        <BreakpointView
                            desktopChildren={undefined}
                            mobileChildren={
                                <div className={classNames(styles['curated-set-detail__actions'], 'spacing--bottom')}>
                                    <PlayButton type="play" isPlaying={isCurrentListInPlayer && playerState === State.Playing} onPress={handleCuratedSetPlay} fullWidth />
                                    <SquaredButton
                                        type="outline"
                                        svgColoring={isCuratedSetFollowed ? 'fill-stroke' : 'stroke'}
                                        label={isCuratedSetFollowed ? 'Remove from Saved' : 'Save'}
                                        onPress={handleFavorite}
                                        leftIcon={isCuratedSetFollowed ? <BPMIcons.FavoriteHeartFilledIcon /> : <BPMIcons.FavoriteHeartIcon />}
                                        fullWidth
                                    />
                                </div>
                            }
                        />
                        {TrackListComponent}
                        {isAnonymous ? <SignUpTrackList /> : null}
                    </div>
                    {!isCuratedSetsFromCuratorLoading && curatorCuratedSets?.length && (
                        <CardCarousel title={`More Curated Sets from ${curatedSet.curator}`} seeMorePath={`/curated-sets/curator/${curatedSet.curator}`}>
                            {curatorCuratedSets.map((set) => (
                                <CuratedSetCard key={set.id} curatedSet={set} curatedSets={curatorCuratedSets} />
                            ))}
                        </CardCarousel>
                    )}
                </div>
            )}
        </>
    );
}

export default CuratedSetDetail;
