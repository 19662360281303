import { useMemo } from 'react';
import { cssTransition, ToastContainer, ToastOptions } from 'react-toastify';
import Bugsnag from '@bugsnag/js';
import styles from './app-toast.module.css';

export function AppToast(props: ToastOptions) {
    const CustomToastAnimation = cssTransition({
        enter: styles['app-toast--enter'],
        exit: styles['app-toast--exit'],
    });
    const toastConfiguration = useMemo<ToastOptions>(
        () => ({
            autoClose: 3000, // 3 sec
            position: 'bottom-center',
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            bodyClassName: styles['app-toast__body'],
            className: styles['app-toast'],
            toastClassName: styles['app-toast__container'],
            closeButton: false,
            theme: null,
            transition: CustomToastAnimation,
            onOpen() {
                Bugsnag.leaveBreadcrumb('Toast shown');
            },
            onClose() {
                Bugsnag.leaveBreadcrumb('Toast hidden');
            },
        }),
        [CustomToastAnimation]
    );
    return <ToastContainer {...toastConfiguration} {...props} />;
}
