import { UserPlaylistWithAlbum } from '@bpm-web-app/download-api-sdk';
import { UserPlaylistWithMedia } from '@bpm-web-app/stream-api-sdk';
import { appendQueryParams, showToast } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { useCallback, useMemo, useRef, useState } from 'react';
import { apiKey } from '@bpm-web-app/api-client';
import { Drive, Sound } from '@bpm-web-app/create-api-sdk';
import BpmLogo from '../../../assets/icons/bpm-logo.svg';

import styles from './playlist-cover-image.module.css';
import { CustomIcon } from '../custom-icon/custom-icon';
import { ActionModal } from '../action-modal/action-modal';
import { CropImage } from '../crop-image/crop-image';

interface PlaylistCoverImageProps {
    playlist?: UserPlaylistWithMedia | UserPlaylistWithAlbum;
    drive?: Drive;
    soundList?: Sound[];
    coverUrl?: string;
    customCoverUrl?: string;
    collaborativePlaylist?: boolean;
    onUploadComplete?: () => void;
    isCreate?: boolean;
    imageSizeFill?: boolean;
    gridImagesUrls?: string[];
}

export function PlaylistCoverImage({
    playlist,
    coverUrl,
    customCoverUrl,
    collaborativePlaylist,
    onUploadComplete,
    isCreate = false,
    drive,
    soundList,
    imageSizeFill,
    gridImagesUrls
}: PlaylistCoverImageProps) {
    const fileRef = useRef<HTMLInputElement>(null);
    const [showUploadProfilePhoto, setShowProfilePhoto] = useState(false);
    const [uploadedImage, setUploadedImage] = useState<string>('');

    const imageColor = useMemo(() => {
        if (isCreate && drive && (drive.image_color !== null || drive.custom_image_color)) {
            return drive.custom_image_color || drive.image_color;
        }
        if (isCreate) {
            return `${'#FFFFFF'}`;
        }
        return playlist?.image_color || '#000';
    }, [drive, isCreate, playlist?.image_color]);

    const title = useMemo(() => {
        if (isCreate && drive) {
            return drive.name;
        }
        return playlist?.title || '';
    }, [drive, isCreate, playlist?.title]);

    const handlePlaylistImageUploadSupreme = useCallback(async (uploadFiles: File) => {
        const formData = new FormData();
        formData.append('file', uploadFiles, uploadFiles.name);

        const key = apiKey();
        const headers = {} as { [key: string]: string };
        if (key) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            headers['Authorization'] = key;
        }

        // eslint-disable-next-line @typescript-eslint/dot-notation
        fetch(`${process.env['NEXT_PUBLIC_DOWNLOAD_API_BASE_PATH']}/user-playlists/${playlist?.id}/image`, {
            method: 'PUT',
            credentials: 'include',
            body: formData,
            headers
        }).then(async (res) => {
            if (res.ok === true) {
                showToast({ type: 'success', title: 'Updated playlist cover' });
            } else if (res.json) {
                const jsonError = await res.json();
                if (jsonError && jsonError.message) showToast({ type: 'error', title: jsonError.message });
            }
            if (onUploadComplete) onUploadComplete();
            setShowProfilePhoto(false);
            return [];
        }).catch(async (e) => {
            if (e.message) showToast({ type: 'error', title: e.message });

            if (e.json) {
                const jsonError = await e.json();
                if (jsonError && jsonError.message) showToast({ type: 'error', title: jsonError.message });
            }
            if (onUploadComplete) onUploadComplete();
            setShowProfilePhoto(false);
            return [];
        });
    }, [onUploadComplete, playlist?.id]);

    const handlePlaylistImageUploadCreate = useCallback(async (uploadFiles: File) => {
        const formData = new FormData();
        formData.append('file', uploadFiles, uploadFiles.name);

        const key = apiKey();
        const headers = {} as { [key: string]: string };
        if (key) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            headers['Authorization'] = key;
        }

        // eslint-disable-next-line @typescript-eslint/dot-notation
        fetch(`${process.env['NEXT_PUBLIC_CREATE_API_BASE_PATH']}/drive/${drive?.id}/image`, {
            method: 'PUT',
            credentials: 'include',
            body: formData,
            headers
        }).then(async (res) => {
            if (res.ok === true) {
                showToast({ type: 'success', title: 'Updated drive cover' });
            } else if (res.json) {
                const jsonError = await res.json();
                if (jsonError && jsonError.message) showToast({ type: 'error', title: jsonError.message });
            }
            if (onUploadComplete) onUploadComplete();
            setShowProfilePhoto(false);
            return [];
        }).catch(async (e) => {
            if (e.message) showToast({ type: 'error', title: e.message });

            if (e.json) {
                const jsonError = await e.json();
                if (jsonError && jsonError.message) showToast({ type: 'error', title: jsonError.message });
            }
            if (onUploadComplete) onUploadComplete();
            setShowProfilePhoto(false);
            return [];
        });
    }, [drive?.id, onUploadComplete]);

    const handlePlaylistImageUpload = useCallback((uploadFiles: File) => {
        if (isCreate) {
            const temp = handlePlaylistImageUploadCreate(uploadFiles);
            return temp;
        }
        const temp = handlePlaylistImageUploadSupreme(uploadFiles);
        return temp;
    }, [handlePlaylistImageUploadCreate, handlePlaylistImageUploadSupreme, isCreate]);

    const handleClick = useCallback(() => {
        if (fileRef.current) fileRef.current.click();
    }, [fileRef]);

    const renderDefaultImage = useCallback(() => {
        if (customCoverUrl) {
            return (
                <picture>
                    <source
                        srcSet={`${appendQueryParams(customCoverUrl, { key: 'dw', value: 296 })}, ${appendQueryParams(customCoverUrl, { key: 'dw', value: 592 })} 2x`}
                        media="(min-width: 1024px)" />
                    <img
                        src={appendQueryParams(customCoverUrl, { key: 'dw', value: 296 })}
                        srcSet={`${appendQueryParams(customCoverUrl, { key: 'dw', value: 592 })} 2x`}
                        alt={title}
                        draggable={false}
                        className={classNames(styles['playlist-cover-image__image'])} />
                </picture>
            );
        }
        return (
            <div
                className={classNames(styles['playlist-cover-image__img-container'], { [styles['playlist-cover-image__img-container--full']]: imageSizeFill })}>
                {coverUrl && (((playlist as UserPlaylistWithAlbum)?.albums?.length || 0) > 0 || ((playlist as UserPlaylistWithMedia)?.media?.length || 0) > 0 || (drive?.sound_count || 0) > 0) ? (
                    <picture>
                        <source
                            srcSet={`${appendQueryParams(coverUrl, { key: 'dw', value: 296 })}, ${appendQueryParams(coverUrl, { key: 'dw', value: 592 })} 2x`}
                            media="(min-width: 1024px)" />
                        <img
                            src={appendQueryParams(coverUrl, { key: 'dw', value: 296 })}
                            srcSet={`${appendQueryParams(coverUrl, { key: 'dw', value: 592 })} 2x`}
                            alt={title}
                            draggable={false}
                            className={styles['playlist-cover-image__image']} />
                    </picture>
                ) : (
                    <div className={styles['playlist-cover-image__missing-img-placeholder']}>
                        <BpmLogo />
                    </div>
                )}
            </div>
        );
    }, [customCoverUrl, imageSizeFill, coverUrl, playlist, drive?.sound_count, title]);

    const renderCollaborativeImage = useCallback((imageUrl: string, id: number | string) => {
        return (
            <picture key={id}>
                <source
                    srcSet={`${appendQueryParams(imageUrl, { key: 'dw', value: 148 })}, ${appendQueryParams(imageUrl, { key: 'dw', value: 296 })} 2x`}
                    media="(min-width: 1024px)" />
                <img
                    src={appendQueryParams(imageUrl, { key: 'dw', value: 68 })}
                    srcSet={`${appendQueryParams(imageUrl, { key: 'dw', value: 136 })} 2x`}
                    alt={isCreate ? drive?.name : playlist?.title}
                    draggable={false}
                    className={styles['playlist-cover-image__quarter-image']} />
            </picture>
        );
    }, [drive?.name, isCreate, playlist?.title]);

    const renderPlaylistTwoByTwo = useMemo(() => {
        if (isCreate && soundList && soundList?.length > 0) {
            return soundList.length >= 4 ? (
                soundList.slice(0, 4).map((sound) => renderCollaborativeImage(sound.artwork_url, sound.id))
            ) : renderDefaultImage();
        }
        if (playlist) {
            return ((playlist as UserPlaylistWithAlbum).albums?.length || 0) >= 4 ? (
                (playlist as UserPlaylistWithAlbum).albums?.slice(0, 4).map((album) => renderCollaborativeImage(album.cover_url, album.id))
            ) : ((playlist as UserPlaylistWithMedia).media?.length || 0) >= 4 ? (
                (playlist as UserPlaylistWithMedia).media?.slice(0, 4).map((track) => renderCollaborativeImage(track.album?.cover_url || '', track.id))
            ) : renderDefaultImage();
        }
        return renderDefaultImage();
    }, [isCreate, playlist, renderCollaborativeImage, renderDefaultImage, soundList]);

    const renderTwoByTwoImageGrid = useMemo(() => {
        return (
            <div className={styles['playlist-cover-image__image-grid']}>
                {gridImagesUrls?.map((url, index) => (
                    <div className={styles['playlist-cover-image__image-item']} key={`${title}-grid-image-${index + 1}`}>
                        <img src={url} alt={`${title} ${index + 1}`} />
                    </div>
                ))}
            </div>
        );
    }, [gridImagesUrls, title]);

    if (gridImagesUrls && gridImagesUrls.length > 3) {
        return renderTwoByTwoImageGrid;
    }

    if (collaborativePlaylist) {
        return (
            <>
                <button
                    type="button"
                    className={classNames(styles['playlist-cover-image__collaborative-img-container'], { [styles['playlist-cover-image__collaborative-img-container--full']]: imageSizeFill })}
                    style={{ backgroundColor: `${imageColor}E6` }}
                    onClick={handleClick}
                >
                    <input
                        type="file"
                        multiple={false}
                        ref={fileRef}
                        hidden
                        accept={'image/*'}
                        onChange={(e) => {
                            if (e.target.files) {
                                setShowProfilePhoto(true);
                                setUploadedImage(URL.createObjectURL(e.target.files[0]));
                            }
                        }}

                    />
                    {customCoverUrl ? renderDefaultImage() : renderPlaylistTwoByTwo}
                    <div className={classNames(styles['playlist-cover-image__collaborative-hover'])}>
                        <CustomIcon type="pencil-outline" size="large" color="white" tooltip="Upload image" hideToolTipOnFocus />
                    </div>
                </button>
                <ActionModal
                    hideCancel
                    variant="dark"
                    isOpen={showUploadProfilePhoto}
                >
                    <CropImage
                        title="Edit Playlist Artwork"
                        imageUrl={uploadedImage}
                        onCancel={() => setShowProfilePhoto(false)}
                        onUpload={handlePlaylistImageUpload}
                        hasRoundMaskCrop={false}
                    />
                </ActionModal>
            </>
        );
    }

    return (
        renderDefaultImage()
    );
}
