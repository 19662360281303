/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from 'classnames';
import { AppLink } from '../../app-link/app-link';
import styles from '../supreme/track-list-supreme.module.css';

interface BpmColumnProps {
    bpm?: number
}
export function BpmColumn({ bpm }: BpmColumnProps) {
    if (!bpm) {
        return null;
    }

    return (
        <div className={classNames(styles['track-list__label-link'])} onClick={(e) => e.stopPropagation()}>
            <AppLink href={`/releases?bpm=${bpm}&bpm=${bpm}`}>
                <a className="relative-link">{bpm}</a>
            </AppLink>
        </div>
    );
}
/* eslint-enable jsx-a11y/click-events-have-key-events */
/* eslint-enable jsx-a11y/no-static-element-interactions */
