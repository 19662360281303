import { Media } from '@bpm-web-app/download-api-sdk';
import classNames from 'classnames';
import ArrowDownTriangle from '../../../../assets/icons/arrow-down-triangle.svg';
import { VersionPill } from '../../version-pill/version-pill';
import styles from './versions-column.module.css';

interface VersionsColumnProps {
    versionsList?: Media[];
    onMoreVersionsClick?: () => void;
    onVersionClick?: (version: Media) => void;
    isAccordionOpen: boolean;
    isPremiumOnly?: boolean;
    hasPremiumOnlyAccess?: boolean;
    setCurrentMediaInContext?: () => void;
}

const lowerPillsLimit = 3;
const upperPillsLimit = 5;

function VersionsColumn({ versionsList, onMoreVersionsClick, onVersionClick, isAccordionOpen, isPremiumOnly, hasPremiumOnlyAccess, setCurrentMediaInContext }: VersionsColumnProps) {
    if (!versionsList) {
        return null;
    }

    if (isPremiumOnly && !hasPremiumOnlyAccess) {
        return (
            <div className={classNames('hide-filters-open', styles['versions-column'])}>
                <button
                    type="button"
                    className={classNames(styles['versions-column__upgrade-to-unlock'])}
                    onClick={(e) => {
                        e.stopPropagation();
                        if (setCurrentMediaInContext) setCurrentMediaInContext();
                    }}>
                    UPGRADE TO UNLOCK
                </button>
            </div>
        );
    }

    return (
        <div className={classNames('hide-filters-open', styles['versions-column'])}>
            <div className={classNames(styles['versions-column'], styles['versions-column__default'])}>
                {versionsList.slice(0, lowerPillsLimit).map((media) => <VersionPill media={media} key={media.id} onClick={() => onVersionClick?.(media)} />)}
            </div>

            <div className={classNames(styles['versions-column'], styles['versions-column__from-desktop'])}>
                {versionsList.slice(0, upperPillsLimit).map((media) => <VersionPill media={media} key={media.id} onClick={() => onVersionClick?.(media)} />)}
            </div>

            <div className={classNames(styles['versions-column'], styles['versions-column__from-large-desktop'])}>
                {versionsList.map((media) => <VersionPill media={media} key={media.id} onClick={() => onVersionClick?.(media)} />)}
            </div>
            {versionsList.length > lowerPillsLimit && versionsList.length < upperPillsLimit && (
                <button
                    type="button"
                    className={classNames(styles['versions-column__more-versions-btn'], { [styles['versions-column__more-versions-btn--open']]: isAccordionOpen })}
                    onClick={(e) => {
                        e.stopPropagation();
                        onMoreVersionsClick?.();
                    }}
                >
                    {`+${versionsList.length - lowerPillsLimit} More`}
                    <ArrowDownTriangle />
                </button>
            )}

            {versionsList.length > upperPillsLimit && (
                <button
                    type="button"
                    className={classNames(styles['versions-column__more-versions-btn'], { [styles['versions-column__more-versions-btn--open']]: isAccordionOpen })}
                    onClick={(e) => {
                        e.stopPropagation();
                        onMoreVersionsClick?.();
                    }}
                >
                    {`+${versionsList.length - upperPillsLimit} More`}
                    <ArrowDownTriangle />
                </button>
            )}
        </div>
    );
}
export default VersionsColumn;
