import { PlaylistWithAlbum } from '@bpm-web-app/download-api-sdk';
import { PlaylistWithMedia } from '@bpm-web-app/stream-api-sdk';
import { usePlaylistDetail, usePlaylistsByCategoryId } from '@bpm-web-app/swr-hooks';
import {
    appendQueryParams,
    convertToPluralIfNeeded,
    downloadAlbumWithMediaToQueueItem,
    formatDateToString,
    QueueItem,
    rebuildReactTooltip,
    State,
    streamMediaWithAlbumToQueueItem,
    useHideSwitch,
    useHubSwitch,
    usePageDetails,
    usePlayerState,
    useUserSettings,
    useViewport,
} from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { MediaDetailBanner } from '../../shared/ui/media-detail-banner/media-detail-banner';
import { usePlayer } from '../../player-context';
import { TrackList, TrackListPreset } from '../../shared/track-list/track-list';
import styles from './playlist-detail.module.css';
import { ThreeDotsSheetContext } from '../../shared/three-dots-sheet/three-dots-sheet.context';
import { useFollowPlaylists } from '../../shared/three-dots-sheet/useFollowPlaylist';
import { LibraryTabsContext, useHideLibraryTabs } from '../../../../../utils/src/lib/library-tabs.context';
import { LocalSortingKeys, sortMediaLocally, useLocalSort } from '../../sort-options-sheet/sort-options-sheet';
import { useArtistLinks } from '../../artist-link/artist-link';
import { PageNotFound } from '../page-not-found/page-not-found';
import Title from '../../title/title';
import { ShareURL } from '../../shared/share-url/share-url';
import { BreakpointView, GhostComponent, SignUpTrackList, CardCarousel } from '../../shared';
import { PlayButton } from '../../shared/play-button/play-button';
import { ThreeDotsButton } from '../../shared/three-dots-button/three-dots-button';
import { AddToPlaylistButton } from '../../shared/add-to-playlist-button/add-to-playlist-button';
import { FollowButton } from '../../shared/follow-button/follow-button';
import { PlaylistCard } from '../../shared/card/playlist-card/playlist-card';
import { TrackListSupreme } from '../../shared/track-list/supreme/track-list-supreme';
import { TrackListPresetSupreme } from '../../shared/track-list/supreme/track-list-supreme-helpers';
import { TrackListLoadingSupreme } from '../../shared/track-list/ghost-loading/track-list-loading-supreme';

export function PlaylistDetail() {
    const router = useRouter();
    const { isMobile } = useViewport();
    const { openThreeDotsModalSheet } = useContext(ThreeDotsSheetContext);
    const { playlistId } = router.query;
    const { isDownload, hub } = useHubSwitch();
    const { libraryProperty } = useContext(LibraryTabsContext);
    const [sortKey, setSortKey] = useLocalSort();
    const { originalListDetails, setQueue, togglePlayPause } = usePlayer();
    const playerState = usePlayerState();
    const { resource } = usePageDetails();
    const generateArtistLinks = useArtistLinks();
    const { isAnonymous } = useUserSettings();

    useEffect(() => {
        rebuildReactTooltip();
    }, []);

    const openAddToPlaylist = useCallback(
        async (element: EventTarget) => {
            const { top: topPosition, left: leftPosition } = (element as HTMLButtonElement).getBoundingClientRect();
            openThreeDotsModalSheet('exclusive-playlist', playlistId as string, leftPosition, topPosition, true);
        },
        [openThreeDotsModalSheet, playlistId]
    );

    const openMore = useCallback(
        async (element: EventTarget) => {
            const { top: topPosition, left: leftPosition } = (element as HTMLButtonElement).getBoundingClientRect();
            openThreeDotsModalSheet('exclusive-playlist', playlistId as string, leftPosition, topPosition, false);
        },
        [openThreeDotsModalSheet, playlistId]
    );

    useHideSwitch();
    useHideLibraryTabs();

    const { addPlaylistToFavorites, removePlaylistFromFavorites, isPlaylistFollowed } = useFollowPlaylists(playlistId as unknown as number, libraryProperty);

    const handleFavorite = useCallback(
        () => (isPlaylistFollowed ? removePlaylistFromFavorites() : addPlaylistToFavorites()),
        [removePlaylistFromFavorites, isPlaylistFollowed, addPlaylistToFavorites]
    );

    const { data, isLoading: isLoadingTracks, error, mutate } = usePlaylistDetail(playlistId as unknown as number);
    const playlist: PlaylistWithMedia | PlaylistWithAlbum = data?.data;
    const { data: similarPlaylistsData, isLoading: isLoadingSimilarPlaylists } = usePlaylistsByCategoryId(playlist?.category_id, libraryProperty);

    const sortedTrackList = useMemo(
        () => (isDownload ? sortMediaLocally((playlist as PlaylistWithAlbum)?.albums, sortKey) : sortMediaLocally((playlist as PlaylistWithMedia)?.media, sortKey)),
        [isDownload, playlist, sortKey]
    );

    const ListComponent = useMemo(() => {
        if (!playlist) return null;

        return (
            <TrackListSupreme
                list={sortedTrackList}
                preset={isDownload ? TrackListPresetSupreme.Download : TrackListPresetSupreme.Stream}
                isSortable
                onSort={(nextSort) => setSortKey(nextSort as LocalSortingKeys)}
                selectedSortType={sortKey}
                onDownloadRevalidate={(downloadMedia) => {
                    if (downloadMedia) {
                        // eslint-disable-next-line no-param-reassign
                        downloadMedia.download_count += 1;
                    }
                    mutate({ data: playlist as PlaylistWithAlbum }, false);
                }}
            />
        );
    }, [isDownload, mutate, playlist, setSortKey, sortKey, sortedTrackList]);

    const SimilarCardsView = useMemo(() => {
        if (isAnonymous) return null;
        if (!playlist || !playlist.category || !playlist.category.id) return null;
        if (!similarPlaylistsData || similarPlaylistsData.data.length === 0) return null;
        if (similarPlaylistsData?.data.length === 1 && `${similarPlaylistsData.data[0].id}` === playlistId) return null;

        return (
            <CardCarousel noPadding title={`More ${playlist.category.name} Playlists`}>
                {similarPlaylistsData?.data
                    .filter((pl) => `${pl.id}` !== playlistId)
                    .map((cardPlaylist) => (
                        <PlaylistCard key={cardPlaylist.id} playlist={cardPlaylist} playlists={similarPlaylistsData.data} />
                    ))}
            </CardCarousel>
        );
    }, [playlist, playlistId, similarPlaylistsData, isAnonymous]);

    const isCurrentListInPlayer = useMemo(() => originalListDetails?.identifier === playlistId && originalListDetails?.resource === resource, [originalListDetails, playlistId, resource]);

    const handlePlayPlaylist = useCallback(() => {
        if (isCurrentListInPlayer) {
            togglePlayPause();
            return;
        }

        const queueItems: QueueItem[] = isDownload
            ? sortedTrackList?.map((album) => downloadAlbumWithMediaToQueueItem(album))
            : sortedTrackList?.map((media) => streamMediaWithAlbumToQueueItem(media));

        if (queueItems) {
            setQueue(queueItems, 0, {
                identifier: playlistId as string,
                resource,
            });
        }
    }, [isCurrentListInPlayer, isDownload, playlistId, resource, setQueue, sortedTrackList, togglePlayPause]);

    const isLoading = useMemo(() => {
        return isLoadingTracks && isLoadingSimilarPlaylists;
    }, [isLoadingSimilarPlaylists, isLoadingTracks]);

    if (playlistId === undefined || Number.isNaN(+playlistId)) return <PageNotFound />;

    if (isLoading) {
        return (
            <>
                <Title platform={hub} title="Loading Artist..." />
                <GhostComponent type="playlist-detail" />
                <TrackListLoadingSupreme preset={TrackListPresetSupreme.Download} amount={20} />

            </>
        );
    }

    if (!isLoading && error) return null;

    if (!playlist) {
        return null;
    }

    return (
        <>
            <Title platform={hub} title={playlist?.title} />
            <div className={classNames(styles['playlist-detail'], 'spacing__window')}>
                <MediaDetailBanner align={isMobile ? 'end' : 'start'}>
                    {{
                        image: (
                            <picture className={styles['playlist-detail__image']}>
                                <source
                                    srcSet={`${appendQueryParams(playlist.image_url, { key: 'dw', value: 296 })}, ${appendQueryParams(playlist.image_url, { key: 'dw', value: 592 })} 2x`}
                                    media="(min-width: 1024px)"
                                />
                                <img
                                    src={appendQueryParams(playlist.image_url, { key: 'dw', value: 136 })}
                                    srcSet={`${appendQueryParams(playlist.image_url, { key: 'dw', value: 272 })} 2x`}
                                    alt={playlist.title}
                                    draggable={false}
                                />
                            </picture>
                        ),
                        text: (
                            <>
                                <div className={styles['playlist-detail__title-container']}>
                                    <h2>{playlist.title}</h2>
                                </div>
                                <div className={styles['playlist-detail__info']}>
                                    <span>
                                        {convertToPluralIfNeeded('media' in playlist ? (playlist as PlaylistWithMedia).media?.length : (playlist as PlaylistWithAlbum)?.albums?.length, 'Track')}
                                    </span>
                                    {playlist.genre && <span>{playlist.genre.name}</span>}
                                    <span>{`Updated ${formatDateToString(playlist.updated_at)}`}</span>
                                </div>
                                <p className={styles['playlist-detail__description']}>{generateArtistLinks(playlist.artist, playlist.artists)}</p>
                            </>
                        ),
                        actions: (
                            <BreakpointView
                                desktopChildren={
                                    <div className={styles['playlist-detail__actions']}>
                                        <PlayButton type="play" isPlaying={isCurrentListInPlayer && playerState === State.Playing} onPress={handlePlayPlaylist} />
                                        <FollowButton signUpModalType="track" isFollowed={isPlaylistFollowed || false} handleFollow={handleFavorite} type="playlist" fullWidth={isMobile} />
                                        <AddToPlaylistButton onClick={(event) => openAddToPlaylist(event.target)} hasTooltip />
                                        <ShareURL currentUrl />
                                        <ThreeDotsButton hasTooltip onClick={(event) => openMore(event?.target)} />
                                    </div>
                                }
                                mobileChildren={
                                    <div className={styles['playlist-detail__actions']}>
                                        <AddToPlaylistButton onClick={(event) => openAddToPlaylist(event.target)} hasTooltip />
                                        <ShareURL currentUrl />
                                        <ThreeDotsButton hasTooltip onClick={(event) => openMore(event?.target)} />
                                    </div>
                                }
                            />
                        ),
                    }}
                </MediaDetailBanner>
                <BreakpointView
                    desktopChildren={undefined}
                    mobileChildren={
                        <div className={classNames(styles['playlist-detail__actions'], 'spacing--bottom')}>
                            <PlayButton type="play" isPlaying={isCurrentListInPlayer && playerState === State.Playing} onPress={handlePlayPlaylist} fullWidth />
                            <FollowButton signUpModalType="track" isFollowed={isPlaylistFollowed || false} handleFollow={handleFavorite} type="playlist" fullWidth={isMobile} />
                        </div>
                    }
                />

                {ListComponent}
                {isAnonymous ? <SignUpTrackList /> : null}
                {SimilarCardsView}
            </div>
        </>
    );
}

export default PlaylistDetail;
