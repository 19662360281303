import { useMemo } from 'react';
import { DOWNLOAD_ONLY, useViewport } from '@bpm-web-app/utils';
import ModalSlider from '../modal-slider/modal-slider';
import myPlaylistsDesktop from '../../../assets/onboarding/desktop/features-my-playlists.gif';
import myCrateDesktop from '../../../assets/onboarding/desktop/features-my-crate.gif';
import trendingDesktop from '../../../assets/onboarding/desktop/features-new-trending.gif';
import exclusivePlaylistsDesktop from '../../../assets/onboarding/desktop/features-exclusive-playlist.gif';

import myPlaylistsMobile from '../../../assets/onboarding/mobile/features-my-playlists.gif';
import myCrateMobile from '../../../assets/onboarding/mobile/features-my-crate.gif';
import favoritesMobile from '../../../assets/onboarding/mobile/features-favorites.gif';
import exclusivePlaylistsMobile from '../../../assets/onboarding/mobile/features-exclusive-playlist.gif';

export function OnboardingModalFeatures() {
    const { isMobile } = useViewport();

    const data = useMemo(
        () => [
            {
                image: isMobile ? myPlaylistsMobile : myPlaylistsDesktop,
                title: 'My Playlists',
                text: DOWNLOAD_ONLY ? 'Create your own custom playlists and start building set lists for DJ gigs.' : 'Create your own custom playlists for streaming or offline use and start building set lists for DJ gigs.',
                href: 'https://www.youtube.com/watch?v=Rn-UCcctjEE&list=PLrlMfzKBjbOiofesOOuZM-302p-EpYkZs&index=5',
            },
            {
                image: isMobile ? myCrateMobile : myCrateDesktop,
                title: 'My Crate',
                text: DOWNLOAD_ONLY ? 'View all of the songs you’ve added to your crate here. You can download tracks individually or in a batch.'
                    : 'In your crate, view the songs you’ve added from the ‘Download’ and/or ‘Stream’ libraries. Download tracks individually or in a batch, or manage your songs to play offline later.',
                href: 'https://www.youtube.com/watch?v=yi6Vzj4m4bc&list=PLrlMfzKBjbOiofesOOuZM-302p-EpYkZs&index=6',
            },
            {
                image: isMobile ? favoritesMobile : trendingDesktop,
                title: 'New Releases, Trending & Favorites',
                text: 'Browse the newest releases, trending tracks, and songs by genre in these sections. You can also find your favorite songs, artists, and playlists here.',
                href: 'https://www.youtube.com/watch?v=yi6Vzj4m4bc&list=PLrlMfzKBjbOiofesOOuZM-302p-EpYkZs&index=6',
            },
            {
                image: isMobile ? exclusivePlaylistsMobile : exclusivePlaylistsDesktop,
                title: 'Exclusive Playlists & Curated Sets',
                text: 'Exclusive playlists are hand-picked tracks by our team of experts. Curated Sets are mix-ready tracklists to play at your next DJ gig.',
                href: 'https://www.youtube.com/watch?v=yi6Vzj4m4bc&list=PLrlMfzKBjbOiofesOOuZM-302p-EpYkZs&index=6',
            },
        ],
        [isMobile]
    );

    return <ModalSlider type="ModalFeature" sliderItems={data} />;
}

export default OnboardingModalFeatures;
