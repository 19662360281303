import { PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './option-card.module.css';
import CrossIcon from '../../../../assets/icons/close.svg';
import CheckmarkIcon from '../../../../assets/icons/supreme/check.svg';

interface OptionCardProps {
    heading: string;
    count: string | number;
    variant: 'light' | 'dark';
    content: ReactNode | ReactNode[]
    button: ReactNode
}

export const OptionCard = ({ heading, count, content, button, variant = 'dark' }: OptionCardProps) => (
    <div className={classNames(styles['option-card'], { [styles['option-card--light']]: variant === 'light' })}>
        <div className={styles['option-card__heading-wrapper']}>
            <div className={styles['option-card__counter']}>{count}</div>
            <h3 className={styles['option-card__heading']}>{heading}</h3>
        </div>
        <div className={styles['option-card__content']}>
            {content}
        </div>
        <div className={styles['option-card__button-holder']}>
            {button}
        </div>
    </div>
);

export const OptionCardLabel = ({ children }: PropsWithChildren<any>) => (
    <p className={styles['option-card__label']}>{children}</p>
);

interface ListItemProps { label: string; variant: 'positive' | 'negative' }

export const OptionCardListItem = ({ label, variant }: ListItemProps) => (
    <div className={styles['option-card__list-item']}>
        <div className={styles[`option-card__list-item-icon--${variant}`]}>
            {variant === 'positive' ? (
                <CheckmarkIcon />
        ) : (
            <CrossIcon />
        )}
        </div>
        <OptionCardLabel>
            {label}
        </OptionCardLabel>
    </div>
);
