import useSWR from 'swr';
import { CuratedSets } from '@bpm-web-app/api-client';
import { useHubSwitch } from '@bpm-web-app/utils';

export const useCuratedSets = (query: any) => {
    const { isDownload } = useHubSwitch();

    const { data, error } = useSWR<Awaited<ReturnType<typeof CuratedSets['getCuratedSets']>>>(
        [
            'get-curated-sets',
            {
                persist: true,
                isDownload,
                ...query,
            },
        ],
        () => CuratedSets.getCuratedSets(isDownload, query)
    );

    return {
        data,
        isLoading: !error && !data,
        error,
    };
};

export const useCuratedSetsByCurator = (curator: string, library?: string, exclude?: string) => {
    const { isDownload } = useHubSwitch();

    const { data, error } = useSWR<Awaited<ReturnType<typeof CuratedSets['getCuratedSetsByCurator']>>>(
        [
            'get-curated-sets-by-curator',
            {
                persist: true,
                isDownload,
                curator,
                library,
                exclude
            },
        ],
        () => CuratedSets.getCuratedSetsByCurator(isDownload, curator, library, exclude)
    );

    return {
        data,
        isLoading: !error && !data,
        error,
    };
};

export function useCuratedSetsByCategoryId(categoryId: number, library?: string) {
    const { isDownload } = useHubSwitch();

    const { data, error } = useSWR<Awaited<ReturnType<typeof CuratedSets['getCuratedSetsCategoryId']>>>(
        [
            'get-curated-sets-by-cat-id',
            {
                persist: true,
                categoryId,
                isDownload,
                library,
            },
        ],
        () => CuratedSets.getCuratedSetsCategoryId(isDownload, categoryId, library)
    );

    return {
        data: data?.data,
        isLoading: !error && !data,
        error,
    };
}

export function useCuratedSetsByCategories(library: string) {
    const { isDownload } = useHubSwitch();

    const { data, error } = useSWR<Awaited<ReturnType<typeof CuratedSets['getCuratedSetsCategories']>>>(
        [
            'stream-get-curated-sets-categories',
            {
                persist: true,
                library,
                isDownload,
            },
        ],
        () => CuratedSets.getCuratedSetsCategories(isDownload, library)
    );

    return {
        data: data?.data,
        isLoading: !error && !data,
        error,
    };
}

export function useCuratedSetDetail(curatedSetId: number, disabled?: boolean) {
    const { isDownload } = useHubSwitch();

    const { data, error, mutate } = useSWR<Awaited<ReturnType<typeof CuratedSets['getCuratedSet']>>>(['get-curated-set-by-id', curatedSetId, { persist: true, isDownload }], () => CuratedSets.getCuratedSet(isDownload, curatedSetId), {
        isPaused: () => !!disabled,
    });

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}

export function getFollowedCuratedSets(library?: string) {
    const { isDownload } = useHubSwitch();
    const { data, error, mutate } = useSWR<Awaited<ReturnType<typeof CuratedSets['getFollowedCuratedSets']>>>(['get-followed-curated-sets', { persist: true, isDownload, library }], () => CuratedSets.getFollowedCuratedSets(isDownload, library));

    return {
        data,
        mutate,
        isLoading: !error && !data,
        error,
    };
}
