import { User } from '@bpm-web-app/api-client';
import { useRouter } from 'next/router';
import { createContext, ReactNode, useMemo, useState, useContext, useEffect } from 'react';

export enum Library {
    Main = 'main',
    Latin = 'latin',
    Artist = 'artist',
}

export interface LibraryTabsContextProperties {
    library: Library;
    setLibrary: (library: Library) => void;
    libraryProperty: string;
    setIsSwitcherVisible: (visible: boolean) => void;
    isSwitcherVisible: boolean;
}

export const LibraryTabsContext = createContext<LibraryTabsContextProperties>({} as LibraryTabsContextProperties);

export interface LibraryTabsProviderProps {
    children: ReactNode;
}

export function LibraryTabsProvider({ children }: LibraryTabsProviderProps) {
    const getCurrentLibrary = (route: Library) => {
        switch (route as Library) {
            case 'main':
                return Library.Main;
            case 'artist':
                return Library.Artist;
            case 'latin':
                return Library.Latin;
            default:
                return Library.Main;
        }
    };

    const getCurrentLibraryString = (route: Library) => {
        switch (route as Library) {
            case 'main':
                return 'music';
            case 'artist':
                return 'artist';
            case 'latin':
                return 'latin';
            default:
                return 'music';
        }
    };
    const router = useRouter();
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const [library, setLibrary] = useState<Library>(getCurrentLibrary(router.query['library'] as Library));
    const [isSwitcherVisible, setIsSwitcherVisible] = useState(true);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        if ((router.query['library'] === 'latin' || router.query['library'] === 'main' || router.query['library'] === 'artist') && router.query['library'] !== library) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            setLibrary(router.query['library'] as Library);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.query]);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        if (router.query['library'] !== library) {
            router.replace({
                pathname: router.pathname,
                query: {
                    ...router.query,
                    library
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [library]);

    useEffect(() => {
        User.setLastSelectedLibrary(getCurrentLibraryString(library));
    }, [library]);

    const value = useMemo<LibraryTabsContextProperties>(
        () => ({
            library,
            setLibrary,
            libraryProperty: getCurrentLibraryString(library),
            setIsSwitcherVisible,
            isSwitcherVisible,
        }),
        [isSwitcherVisible, library]
    );

    return <LibraryTabsContext.Provider value={value}>{children}</LibraryTabsContext.Provider>;
}

export function useLibraryTabs() {
    const context = useContext(LibraryTabsContext);
    return context;
}

/**
 * Hook to control the library tabs visibility
 */
export function useHideLibraryTabs() {
    const { setIsSwitcherVisible } = useContext(LibraryTabsContext);

    useEffect(() => {
        setIsSwitcherVisible(false);

        return () => {
            setIsSwitcherVisible(true);
        };
    }, [setIsSwitcherVisible]);
}

export const ARTIST_LIBRARY_DISABLED_PAGES = ['/d/curated-sets', '/s/curated-sets', '/d/exclusives/all', '/d/all-editors'];
export const ARTIST_LIBRARY_EXCLUSIVE_PAGES = ['/d/featured-artists/all'];
