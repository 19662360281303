import classNames from 'classnames';
import { CustomIcon } from '../../shared/custom-icon/custom-icon';
import styles from './header-notification-button.module.css';

interface HeaderNotificationButtonProps {
    isMobile?: boolean;
    onClick?: () => void;
    hasNotifications?: boolean;
    isShowingNotifications?: boolean;
}

export function HeaderNotificationButton({ isMobile, onClick, hasNotifications, isShowingNotifications }: HeaderNotificationButtonProps) {
    return (
        <CustomIcon
            type={isShowingNotifications ? 'notification-bell-active-icon' : 'notification-bell-icon'}
            isIconHoverActive={isShowingNotifications}
            hasIconHover
            hasBackgroundHover
            size="regular"
            container="round"
            containerSize="regular"
            tooltip="Notifications"
            hasActiveState
            onClick={onClick}
            tooltipBottomOffset={-4}
            className={classNames({ [styles['header-notification-button__notifications']]: !isMobile })}
        >
            {hasNotifications ? <div className={styles['header-notification-button__notifications-active']}><div className={styles['header-notification-button__notifications-active--circle']} /></div> : undefined}
        </CustomIcon>
    );
}
