import classNames from 'classnames';
import { useState } from 'react';
import { LoadingIcon } from '@bpm-web-app/components/src/lib/shared/loading-icon/loading-icon';
import styles from './artist-application-button.module.css';
import { ReactComponent as ArrowIcon } from '../../../../../assets/icons/chevron-right.svg';

interface ArtistApplicationButtonProps {
    text?: string;
    disabled?: boolean
    onContinue?: () => void;
    onBack?: () => void;
    isLoading?: boolean;
}

// ToDo: Split this up, Button copmonent should render back on forward button. Make another component NavigationButtons.
export function ArtistApplicationButton({ text = 'Continue', onContinue, onBack = null, disabled, isLoading = false }: ArtistApplicationButtonProps) {
    return (
        <div className={styles['artist-application-button']}>
            {onBack !== null ?
                <button disabled={disabled} className={styles['artist-application-button--left-button']} type="submit" onClick={onBack}>
                    <ArrowIcon className={styles['artist-application-button--left-icon']} height={20} width={20} />
                    <div className={styles['artist-application-button--gray-text']}>
                        {' '}
                        Go Back{' '}
                    </div>
                </button>
                : null}

            <button disabled={disabled} className={styles['artist-application-button--right-button']} type="submit" onClick={onContinue}>
                <div className={styles['artist-application-button--text']}>
                    {isLoading
                        ? <LoadingIcon isDark />
                        : ` ${text} `
                    }
                </div>
                {!isLoading && <ArrowIcon className={styles['artist-application-button--icon']} height={20} width={20} />}
            </button>

        </div>
    );
}

interface ArtistApplicationBooleanButtonProps {
    value?: boolean | null;
    setValue: (v: boolean) => void;
}

export function ArtistApplicationBooleanButton({ value = undefined, setValue }: ArtistApplicationBooleanButtonProps) {
    const [hasSelectedValue, setHasSelectedValue] = useState<boolean>(!!(value === true || value === false));
    const [selectedValue, setSelectedValue] = useState<boolean | undefined | null>(value);

    return (
        <div className={styles['artist-application-button']}>
            <button
                className={classNames(styles['artist-application-button--boolean-button'], { [styles['artist-application-button--selected']]: selectedValue === true && hasSelectedValue })}
                type="submit"
                onClick={(e) => {
                    e.preventDefault();
                    setValue(true);
                    setSelectedValue(true);
                    setHasSelectedValue(true);
                }}>
                <div className={classNames({ [styles['artist-application-button--gray-text']]: selectedValue === false || !hasSelectedValue }, { [styles['artist-application-button--selected--text']]: selectedValue === true && hasSelectedValue })}>
                    {' '}
                    Yes{' '}
                </div>
            </button>

            <button
                className={classNames(styles['artist-application-button--boolean-button'], { [styles['artist-application-button--selected']]: selectedValue === false && hasSelectedValue })}
                type="submit"
                onClick={(e) => {
                    e.preventDefault();
                    setValue(false);
                    setSelectedValue(false);
                    setHasSelectedValue(true);
                }}>
                <div className={classNames({ [styles['artist-application-button--gray-text']]: selectedValue === true || !hasSelectedValue }, { [styles['artist-application-button--selected--text']]: selectedValue === false && hasSelectedValue })}>
                    {' '}
                    No{' '}
                </div>
            </button>

        </div>
    );
}
