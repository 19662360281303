import { IdPlayBody } from '@bpm-web-app/stream-api-sdk';
import { IdPlayBody as DownloadIdBody } from '@bpm-web-app/download-api-sdk';
import { useReportMediaPlay } from '@bpm-web-app/swr-hooks';
import { arrayMove, HLSPlayer, insertTrackOrTracks, PageDetails, PlayerState, PLAYER_PREVIEW_SECONDS, QueueItem, showToast, shufflePlaylist, State, useHubSwitch, usePlayerState } from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import { ReactNode, createContext, useState, useMemo, useEffect, useCallback, useContext, useRef } from 'react';
import { Library, useLibraryTabs } from '../../../../utils/src/lib/library-tabs.context';

export interface PlayerContextProperties {
    showQueue: boolean;
    showFeaturedIn: boolean;
    showSimilarTracks: boolean;
    isOpen: boolean;
    isShuffling: boolean;
    isRepeating: boolean;
    repeat: 'off' | 'one' | 'all';
    hasPrevTrack: boolean;
    hasNextTrack: boolean;
    /** Current playing track */
    currentTrack: QueueItem | null;
    /** Current playing track index */
    currentTrackIndex: number;
    currentDuration: number;
    onTrackEnd: () => void;
    prevTrack: () => void;
    nextTrack: () => void;
    clearQueue: () => void;
    silenceWorkaround: () => void;
    /** Sets the queue erasing the previous queue tracks */
    setQueue: (queueItems: QueueItem[], indexToPlayFrom?: number, listDetails?: Pick<PageDetails, 'resource' | 'identifier'>) => void;
    /** Checks if a given track/media is in the queue */
    isMediaInTheQueue: (mediaId: number) => string | undefined;
    /** Appends tracks starting from the current playing index */
    addToQueue: (songs: QueueItem[]) => void;
    /** Removes specified track from the queue */
    removeFromQueue: (uuid: QueueItem['uuid'] | QueueItem['id']) => void;
    /** Moves track to a new place in the queue (drag&drop) */
    moveTrackPositionInQueue: (prevIndex: number, newIndex: number) => void;
    /** Skips play to the specified uuid  */
    skipToTrackInQueue: (uuid: QueueItem['uuid']) => void;
    /** ATM should be used when playing a track version and we want to play another version */
    replaceCurrentPlayingTrack: (item: QueueItem) => void;
    /** Set Show Queue */
    setShowQueue: (show: boolean) => void;
    /** Set Show Featured In */
    setShowFeaturedIn: (show: boolean) => void;
    /** Set Show Similar Tracks */
    setShowSimilarTracks: (show: boolean) => void;
    /** Closes the player */
    closePlayer: () => void;
    /** Toggles shuffle on or off */
    toggleShuffle: () => void;
    /** Cycles repeat @see `repeat` */
    toggleRepeat: () => void;
    /** Current playing queue */
    currentQueue: QueueItem[];
    /** Initial added queue, used to remember the un-shuffled list */
    rawQueue: QueueItem[];
    volume: number;
    setVolume: (volume: number) => void;
    setElapsed: (elapsed: number) => void;
    setStartOffset: (startOffset: number) => void;
    isMaxiPlayer: boolean;
    setIsMaxiPlayer: (isMaxiPlayer: boolean) => void;
    useSdk: boolean;
    onSeek: (progress: number) => void;
    onPlay: () => void;
    onPause: () => void;
    isPreviewTrack: boolean;
    /** The details from the list added to the queue */
    originalListDetails?: Pick<PageDetails, 'resource' | 'identifier'>;
    togglePlayPause: () => void;
    /** Progress the track by progress in seconds */
    moveBy: (progress: number) => void;
}

export interface PlayerContextCurrentDurationProperties {
    elapsed: number;
    startOffset: number;
}

export const PlayerContext = createContext<PlayerContextProperties>({} as PlayerContextProperties);
export const PlayerContextCurrentDuration = createContext<PlayerContextCurrentDurationProperties>({} as PlayerContextCurrentDurationProperties);

export interface PlayerProviderProps {
    children: ReactNode;
    initialState?: {
        isOpen?: boolean;
        isShuffling?: boolean;
        repeat?: PlayerContextProperties['repeat'];
        mediaId?: number;
    };
}

export function PlayerProvider({ children, initialState }: PlayerProviderProps) {
    const [isOpen, setIsOpen] = useState(initialState?.isOpen || false);
    const [showQueue, setShowQueue] = useState(true);
    const [showSimilarTracks, setShowSimilarTracks] = useState(false);
    const [showFeaturedIn, setShowFeaturedIn] = useState(false);
    const [isShuffling, setIsShuffling] = useState(initialState?.isShuffling || false);
    const [repeat, setRepeat] = useState<PlayerContextProperties['repeat']>(initialState?.repeat || 'off');
    const [rawQueue, setRawQueue] = useState<PlayerContextProperties['rawQueue']>([]);
    const [currentQueue, setCurrentQueue] = useState<PlayerContextProperties['currentQueue']>([]);
    const [volume, setVolume] = useState(100);
    const [elapsed, setElapsed] = useState(0);
    const [startOffset, setStartOffset] = useState(0);
    const elapsedRef = useRef(elapsed);
    const [isMaxiPlayer, setIsMaxiPlayer] = useState(false);
    const [currentTrackIndex, setCurrentTrackIndex] = useState<number>(-1);
    const [currentTrack, setCurrentTrack] = useState<PlayerContextProperties['currentTrack']>(null);
    const [currentDuration, setCurrentDuration] = useState(0);
    const [originalListDetails, setOriginalListDetails] = useState<PlayerContextProperties['originalListDetails']>();
    const router = useRouter();
    const { library } = useLibraryTabs();
    const { isDownload } = useHubSwitch();
    const playerState = usePlayerState();

    useEffect(() => {
        HLSPlayer.setErrorHandler(() => {
            showToast({ type: 'error', message: 'Unavailable. Please try again later.' });
        });
    }, []);

    const reportMediaPlay = useReportMediaPlay();

    const setPlayerDuration = useCallback((time: number) => {
        setCurrentDuration(time);
    }, []);

    useEffect(() => {
        PlayerState.onDuration(setPlayerDuration);
        return () => PlayerState.offDuration(setPlayerDuration);
    }, [setPlayerDuration]);

    useEffect(() => {
        elapsedRef.current = elapsed;
    }, [elapsed]);

    /**
     * Will report the track as played to the API, only when the durations is bigger than 0
     * Report conditions:
     * Skip back or forward, track ended playing, when the current queue or track is replaced
     */
    const handleReportMediaPlay = useCallback(() => {
        if (elapsedRef.current <= 0 || !currentTrack?.id) {
            return;
        }

        const body: IdPlayBody | DownloadIdBody = {
            duration: elapsedRef.current,
            from_location: router.asPath,
        };

        if (!isDownload) {
            body.from_library = library === Library.Main ? IdPlayBody.FromLibraryEnum.Music : IdPlayBody.FromLibraryEnum.Latin;
        } else {
            body.from_library =
                library === Library.Artist ? DownloadIdBody.FromLibraryEnum.Artist : library === Library.Latin ? DownloadIdBody.FromLibraryEnum.Latin : DownloadIdBody.FromLibraryEnum.Music;
        }
        reportMediaPlay(currentTrack.id, body).catch(() => {
            /** Failed  to report media play */
        });
    }, [currentTrack?.id, elapsedRef, isDownload, library, reportMediaPlay, router.asPath]);

    const handleTogglePlayPause = useCallback(() => {
        if (playerState === State.Playing) {
            HLSPlayer.pause();
        } else if (playerState === State.Paused) {
            HLSPlayer.play();
        }
    }, [playerState]);

    useEffect(() => {
        if (isShuffling) {
            setCurrentQueue(shufflePlaylist(rawQueue, currentTrack));
        } else {
            setCurrentQueue([...rawQueue]);
            setCurrentTrackIndex(rawQueue.findIndex((track) => track.uuid === currentTrack.uuid));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShuffling]);
    /** Repeating is single or all */
    const isRepeating = useMemo(() => repeat !== 'off', [repeat]);

    /** The current queue size, currentQueue?.length-1 */
    const queueSize = useMemo(() => {
        if (currentQueue?.length) {
            return currentQueue.length - 1;
        }

        return 0;
    }, [currentQueue?.length]);

    /** Note: When repeating there's always a next track */
    const hasNextTrack = useMemo(() => isRepeating || currentTrackIndex < queueSize, [currentTrackIndex, isRepeating, queueSize]);

    const hasPrevTrack = useMemo(() => isRepeating || currentTrackIndex > 0, [currentTrackIndex, isRepeating]);

    const useSdk = useMemo(() => currentTrack?.stream_info?.audioInfo?.type === 'bpmdrm', [currentTrack]);

    const isPreviewTrack = useMemo<boolean>(() => currentTrack?.stream_info?.audioInfo?.is_preview, [currentTrack?.stream_info?.audioInfo?.is_preview]);

    const handleSeek = useCallback(
        async (progress: number) => {
            if (isPreviewTrack && progress >= (currentDuration || PLAYER_PREVIEW_SECONDS)) return;

            try {
                HLSPlayer.goTo(progress);
                await HLSPlayer.play();
                setElapsed(progress);
            } catch {
                // Placeholder
            }
        },
        [isPreviewTrack, currentDuration]
    );

    const moveBy = useCallback(
        (progress: number) => {
            if (progress > 0) {
                handleSeek(elapsedRef.current + progress);
            } else if (elapsedRef.current - progress <= startOffset) {
                handleSeek(startOffset);
            } else {
                handleSeek(elapsedRef.current + progress);
            }
        },
        [startOffset, handleSeek]
    );

    const handlePlay = useCallback(() => {
        if (isPreviewTrack && elapsedRef.current >= (currentDuration || PLAYER_PREVIEW_SECONDS)) {
            /** NOTE: Case the player was paused when it reached the preview
             * limit when clicking play should play from the beginning
             */
            handleSeek(0);
        } else {
            HLSPlayer.play();
        }
    }, [elapsedRef, handleSeek, isPreviewTrack, currentDuration]);

    const handlePlayPreviousTrack = useCallback(() => {
        handleReportMediaPlay();

        if (!hasPrevTrack) return;

        let nextIndex = currentTrackIndex - 1;

        if (elapsedRef.current >= 4 || (repeat !== 'all' && currentTrackIndex === 0)) {
            handleSeek(0);
            return;
        }

        if (repeat === 'one') {
            setRepeat('off');
        }

        if (repeat === 'all' && currentTrackIndex === 0) {
            nextIndex = queueSize;
        }

        handleSeek(0);
        setCurrentTrack(currentQueue[nextIndex]);
        setCurrentTrackIndex(nextIndex);
    }, [currentQueue, currentTrackIndex, elapsedRef, handleReportMediaPlay, handleSeek, hasPrevTrack, queueSize, repeat]);

    const handleTrackEnded = useCallback(() => {
        handleReportMediaPlay();
        if (!hasNextTrack) return;

        if (repeat === 'one') {
            handleSeek(0);
            return;
        }

        let nextIndex = currentTrackIndex + 1;

        if (repeat === 'all' && nextIndex > queueSize) {
            nextIndex = 0;
        } else if (repeat !== 'all' && nextIndex >= queueSize) {
            return;
        }

        handleSeek(0);
        setCurrentTrackIndex(nextIndex);
        setCurrentTrack(currentQueue[nextIndex]);
    }, [currentQueue, currentTrackIndex, handleReportMediaPlay, handleSeek, hasNextTrack, queueSize, repeat]);

    const handlePlayNextTrack = useCallback(() => {
        handleReportMediaPlay();
        if (!hasNextTrack) return;
        const nextIndex = currentTrackIndex + 1;

        // NOTE: When the repeat is set to one we want to disable it if user presses next or previous
        if (repeat === 'one') {
            setRepeat('off');
        }

        if (repeat === 'all' && nextIndex > queueSize) {
            setCurrentTrack(currentQueue[0]);
            setCurrentTrackIndex(0);
            return;
        }

        handleSeek(0);
        setCurrentTrack(currentQueue[nextIndex]);
        setCurrentTrackIndex(nextIndex);
    }, [handleReportMediaPlay, hasNextTrack, handleSeek, currentTrackIndex, repeat, queueSize, currentQueue]);

    const handleSetQueue = useCallback(
        (list: QueueItem[], indexToPlayFrom: number, listDetails?: PlayerContextProperties['originalListDetails']) => {
            handleReportMediaPlay();
            const index = indexToPlayFrom || 0;
            const song = list[index];
            setCurrentTrack(song);
            setCurrentTrackIndex(index);
            setRawQueue(list);
            setCurrentQueue(list);
            setIsOpen(true);
            setIsShuffling(false);
            setRepeat('off');

            if (listDetails) {
                setOriginalListDetails(listDetails);
            } else {
                setOriginalListDetails(null);
            }
        },
        [handleReportMediaPlay]
    );

    const handleClearQueue = useCallback(() => {
        handleReportMediaPlay();
        setCurrentTrack(null);
        setCurrentTrackIndex(-1);
        setRawQueue([]);
        setCurrentQueue([]);
        setIsOpen(true);
        setIsShuffling(false);
        setRepeat('off');
        HLSPlayer.stop();
    }, [handleReportMediaPlay]);

    const handleAddToQueue = useCallback(
        (songs: QueueItem[]) => {
            if (!songs?.length) return;

            if (currentTrackIndex < 0) {
                setCurrentTrack(songs[0]);
                setCurrentTrackIndex(0);
                setRawQueue(songs);
                setCurrentQueue(songs);
                setIsOpen(true);
                return;
            }

            const newPlaylist = insertTrackOrTracks(currentQueue, currentTrackIndex + 1, ...songs);

            setRawQueue(newPlaylist);
            setCurrentQueue(newPlaylist);
        },
        [currentTrackIndex, currentQueue]
    );

    const handleSkipToTrackInQueue = useCallback(
        (uuid: QueueItem['uuid']) => {
            handleReportMediaPlay();
            const trackIndex = currentQueue.findIndex((item) => item.uuid === uuid);
            if (trackIndex === -1) return;

            setCurrentTrack(currentQueue[trackIndex]);
            setCurrentTrackIndex(trackIndex);
        },
        [currentQueue, handleReportMediaPlay]
    );

    const handleReplaceCurrentPlayingTrack = useCallback(
        (item: QueueItem) => {
            handleReportMediaPlay();
            const nextQueue = currentQueue ? [...currentQueue] : [];
            nextQueue.splice(currentTrackIndex, 1, item);
            setRawQueue(nextQueue);
            setCurrentQueue(nextQueue);
            setCurrentTrack(nextQueue[currentTrackIndex]);
        },
        [currentQueue, currentTrackIndex, handleReportMediaPlay]
    );

    const handleToggleRepeat = useCallback(() => {
        setRepeat((prevState) => {
            switch (prevState) {
                case 'off': {
                    return 'all';
                }
                case 'all': {
                    return 'one';
                }
                default: {
                    return 'off';
                }
            }
        });
    }, []);

    const handleShowFeaturedIn = useCallback((show: boolean) => {
        if (show) {
            setShowQueue(false);
            setShowSimilarTracks(false);
        }
        setShowFeaturedIn(show);
    }, []);

    const handleShowSimilarTracks = useCallback((show: boolean) => {
        if (show) {
            setShowQueue(false);
            setShowFeaturedIn(false);
        }
        setShowSimilarTracks(show);
    }, []);

    const handleShowQueue = useCallback((show: boolean) => {
        if (show) {
            setShowSimilarTracks(false);
            setShowFeaturedIn(false);
        }
        setShowQueue(show);
    }, []);

    const handleMoveTrackPositionInQueue = useCallback(
        (prevIndex, newIndex) => {
            setCurrentQueue((prevState) => [...arrayMove(prevState, prevIndex + currentTrackIndex + 1, newIndex + currentTrackIndex + 1)]);
        },
        [currentTrackIndex]
    );

    const handleRemoveFromQueue = useCallback((songToRemoveId: QueueItem['uuid'] | QueueItem['id']) => {
        const filterById = (item: QueueItem) => {
            if (typeof songToRemoveId === 'string') {
                return item.uuid !== songToRemoveId;
            }

            return item.id !== songToRemoveId;
        };

        setCurrentQueue((prevState) => prevState.filter(filterById));
        setRawQueue((prevState) => prevState.filter(filterById));
    }, [currentQueue, rawQueue]);

    useEffect(() => {
        if (router && isMaxiPlayer) {
            setIsMaxiPlayer(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router]);

    const silenceWorkaround = () => {
        HLSPlayer.load('https://av.bpmsupreme.com/live/500ms-silence.mp3');
        HLSPlayer.play();
    };

    const checkIfMediaInTheQueue = useCallback(
        (mediaId: number): string | undefined => {
            const item = currentQueue.find((i) => i.id === mediaId);
            return item?.uuid;
        },
        [currentQueue]
    );

    const value = useMemo(
        (): PlayerContextProperties => ({
            setShowSimilarTracks: handleShowSimilarTracks,
            showSimilarTracks,
            showQueue,
            showFeaturedIn,
            setShowQueue: handleShowQueue,
            setShowFeaturedIn: handleShowFeaturedIn,
            isOpen,
            isShuffling,
            isRepeating,
            repeat,
            currentQueue,
            rawQueue,
            hasPrevTrack,
            hasNextTrack,
            onTrackEnd: handleTrackEnded,
            prevTrack: handlePlayPreviousTrack,
            nextTrack: handlePlayNextTrack,
            volume,
            isMaxiPlayer,
            currentTrack,
            currentTrackIndex,
            currentDuration,
            clearQueue: handleClearQueue,
            setQueue: handleSetQueue,
            isMediaInTheQueue: checkIfMediaInTheQueue,
            addToQueue: handleAddToQueue,
            removeFromQueue: handleRemoveFromQueue,
            moveTrackPositionInQueue: handleMoveTrackPositionInQueue,
            skipToTrackInQueue: handleSkipToTrackInQueue,
            closePlayer: () => setIsOpen(false),
            toggleShuffle: () => setIsShuffling((prevState) => !prevState),
            toggleRepeat: handleToggleRepeat,
            setVolume: (newVolume: number) => setVolume(newVolume),
            setElapsed: (time: number) => setElapsed(time),
            setStartOffset,
            setIsMaxiPlayer: (newIsMaxiPlayer: boolean) => setIsMaxiPlayer(newIsMaxiPlayer),
            replaceCurrentPlayingTrack: handleReplaceCurrentPlayingTrack,
            onSeek: handleSeek,
            useSdk,
            onPlay: handlePlay,
            onPause: HLSPlayer.pause,
            isPreviewTrack,
            originalListDetails,
            togglePlayPause: handleTogglePlayPause,
            silenceWorkaround,
            moveBy,
        }),
        [
            handleShowFeaturedIn,
            handleShowQueue,
            handleClearQueue,
            handleShowSimilarTracks,
            handleTrackEnded,
            showFeaturedIn,
            showQueue,
            showSimilarTracks,
            currentQueue,
            currentTrack,
            currentTrackIndex,
            currentDuration,
            checkIfMediaInTheQueue,
            handleAddToQueue,
            handleMoveTrackPositionInQueue,
            handlePlay,
            handlePlayNextTrack,
            handlePlayPreviousTrack,
            handleRemoveFromQueue,
            handleReplaceCurrentPlayingTrack,
            handleSeek,
            handleSetQueue,
            handleSkipToTrackInQueue,
            handleTogglePlayPause,
            handleToggleRepeat,
            hasNextTrack,
            hasPrevTrack,
            isMaxiPlayer,
            isOpen,
            isPreviewTrack,
            isRepeating,
            repeat,
            isShuffling,
            rawQueue,
            volume,
            useSdk,
            originalListDetails,
            moveBy,
        ]
    );

    const durationValue = useMemo(
        (): PlayerContextCurrentDurationProperties => ({
            elapsed,
            startOffset,
        }),
        [elapsed, startOffset]
    );

    useEffect(() => {
        document.body.classList[isOpen ? 'add' : 'remove']('player-is-open');
        return () => {
            document.body.classList.remove('player-is-open');
        };
    }, [isOpen]);

    useEffect(() => {
        document.body.classList[isMaxiPlayer ? 'add' : 'remove']('maxi-player-is-open');
        return () => {
            document.body.classList.remove('maxi-player-is-open');
        };
    }, [isMaxiPlayer]);

    return (
        <PlayerContext.Provider value={value}>
            <PlayerContextCurrentDuration.Provider value={durationValue}>{children}</PlayerContextCurrentDuration.Provider>
        </PlayerContext.Provider>
    );
}

export const usePlayer = () => {
    const context: PlayerContextProperties = useContext(PlayerContext);
    return context;
};

export const usePlayerDuration = () => {
    const context: PlayerContextCurrentDurationProperties = useContext(PlayerContextCurrentDuration);
    return context;
};
