import classNames from 'classnames';
import styles from './artist-application-text.module.css';

export function ApplicationSectionTitle({ title, noMargin = false, halfMargin }: { title: string, noMargin?: boolean, halfMargin?: boolean }) {
    return (
        <div className={classNames(styles['section-title'], { [styles['section-title--no-margin']]: noMargin || halfMargin, [styles['section-title--half-margin']]: halfMargin })}>
            {title}
        </div>
    );
}

export function ApplicationSectionHeader({ subtitle }: { subtitle: string }) {
    return (
        <div className={styles['section-header']}>
            {subtitle}
        </div>
    );
}

export function ApplicationSectionSmall({ text }: { text: string }) {
    return (
        <div className={styles['section-small']}>
            {text}
        </div>
    );
}

export function ApplicationErrorText({ text }: { text: string }) {
    return (
        <div className={styles['error-text']}>
            {text}
        </div>
    );
}
