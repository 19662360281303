import { ArtistApi as StreamArtistApi } from '@bpm-web-app/stream-api-sdk';
import { ArtistApi as DownloadArtistApi } from '@bpm-web-app/download-api-sdk';
import { downloadConfig, streamConfig } from '../config';

export class Artist {
    static streamClient = new StreamArtistApi(streamConfig);

    static downloadClient = new DownloadArtistApi(downloadConfig);

    static getClient(isDownload: boolean) {
        return isDownload ? this.downloadClient : this.streamClient;
    }

    static searchArtist = (
        isDownload: boolean,
        args: {
            limit: number;
            skip: number;
            category: string;
            library: string;
            genre: string;
            artist: string;
            version: string;
            key: string;
            keyType: string;
            bpm_min: number;
            bpm_max: number;
            term: string;
            date: string;
            hide_remix: boolean
            hide_originals: boolean
            hide_exclusives: boolean
            hide_explicit: boolean
            hide_prev_downloaded: boolean
        }
    ) => {
        if (isDownload) {
            return Artist.downloadClient.searchArtist(
                args.limit,
                args.skip,
                args.category,
                args.library,
                args.genre,
                args.artist,
                args.hide_remix ? '1' : '0',
                args.hide_originals ? '1' : '0',
                args.hide_exclusives ? '1' : '0',
                args.hide_explicit ? '1' : '0',
                args.hide_prev_downloaded ? '1' : '0',
                args.version as string,
                args.key,
                args.keyType,
                args.bpm_min,
                args.bpm_max,
                args.term,
                args.date
            );
        }
        return Artist.streamClient.searchArtist(
            args.limit,
            args.skip,
            args.category,
            args.library,
            args.genre,
            args.artist,
            args.version as string,
            args.key,
            args.keyType,
            args.bpm_min,
            args.bpm_max,
            args.term,
            args.date
        );
    };

    static getArtistBySlug = (slug: string) => Artist.downloadClient.getArtistSlug(slug);

    static searchArtistFeaturedIn = (isDownload: boolean, id: number, library?: string) => Artist.getClient(isDownload).getArtistFeaturedIn(id, library);

    static getFollowedArtists = (isDownload: boolean, library?: string) => Artist.getClient(isDownload).getFollowedArtists(library);

    static followArtist = (id: number) => Artist.downloadClient.followArtist(id);

    static unfollowArtist = (id: number) => Artist.downloadClient.unfollowArtist(id);

    static getFeaturedArtists = (library: string) => Artist.downloadClient.getFeaturedArtists(library);

    static getFeaturedEditors = (library: string) => Artist.downloadClient.getFeaturedEditors(library);
}
