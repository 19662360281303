import classNames from 'classnames';
import styles from './ghost-element.module.css';

export interface GhostElementProps {
    linesWidthArray?: number[];
    itemClass?: string;
    squareClass?: string;
    lineHeight?: number;
    isRow?: boolean;
    squareWidth?: number;
    hasShimmer?: boolean;
    isRoundImage?: boolean;
    forMaxiPlayer?: boolean;
}

export function GhostElement({ linesWidthArray = [], itemClass, lineHeight = 16, isRow = false, squareWidth = 0, squareClass, hasShimmer = true, isRoundImage, forMaxiPlayer }: GhostElementProps) {
    return (
        <div
            className={classNames(itemClass, styles['ghost-element'], !isRow ? styles['ghost-element--padding'] : styles['ghost-element--row'], {
                [styles['ghost-element--maxi-player']]: forMaxiPlayer,
            })}
            style={squareWidth > 0 ? { height: `${squareWidth}px` } : {}}
        >
            {isRow ? (
                <div
                    style={squareWidth > 0 ? { width: squareWidth, height: squareWidth } : {}}
                    className={classNames(styles['ghost-element__square-container'], { [styles['ghost-element__square-container--round']]: isRoundImage })}
                >
                    <div
                        style={squareWidth > 0 ? { width: squareWidth, height: squareWidth } : {}}
                        className={classNames(squareWidth && styles['ghost-element__square'], styles['ghost-element__row-container'], squareClass, hasShimmer ? 'shimmer' : '', {
                            [styles['ghost-element__round']]: isRoundImage,
                        })}
                    />
                </div>
            ) : (
                <div className={classNames(styles['ghost-element__square-container'], { [styles['ghost-element__square-container--round']]: isRoundImage })}>
                    <div
                        style={squareWidth > 0 ? { width: squareWidth, height: squareWidth } : {}}
                        className={classNames(styles['ghost-element__container'], squareClass, { [styles['ghost-element__round']]: isRoundImage })}
                    />
                </div>
            )}
            {linesWidthArray?.length > 0 && (
                <div className={styles['ghost-element__lines-container']}>
                    {linesWidthArray.map((lineWidth, index) => (
                        <div
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            style={{ width: `${lineWidth}%`, height: lineHeight }}
                            className={classNames(styles['ghost-element__line-container'])}
                        >
                            <div
                                // eslint-disable-next-line react/no-array-index-key
                                key={`line-${index}`}
                                className={classNames(styles['ghost-element__lines-container__item'], hasShimmer ? 'shimmer' : '')}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default GhostElement;
