import { useDragLayer, XYCoord, useDragDropManager } from 'react-dnd';
import { useMemo } from 'react';
import styles from './draggable-component.module.css';
import { DragDropItem } from '../../droppable/droppable';

const RedCrossIcon = (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 0C3.14 0 0 3.14 0 7C0 10.86 3.14 14 7 14C10.86 14 14 10.86 14 7C14 3.14 10.86 0 7 0ZM2 7C2 4.24 4.24 2 7 2C8.02 2 8.96 2.31 9.75 2.83L2.83 9.75C2.31 8.96 2 8.01 2 7ZM7 12C5.98 12 5.04 11.69 4.25 11.17L11.17 4.25C11.69 5.04 12 5.99 12 7C12 9.76 9.76 12 7 12Z" fill="#EB5757" />
    </svg>
);

const GrayCrossIcon = (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 0C3.14 0 0 3.14 0 7C0 10.86 3.14 14 7 14C10.86 14 14 10.86 14 7C14 3.14 10.86 0 7 0ZM2 7C2 4.24 4.24 2 7 2C8.02 2 8.96 2.31 9.75 2.83L2.83 9.75C2.31 8.96 2 8.01 2 7ZM7 12C5.98 12 5.04 11.69 4.25 11.17L11.17 4.25C11.69 5.04 12 5.99 12 7C12 9.76 9.76 12 7 12Z" fill="#747474" />
    </svg>
);

const GreenPlusIcon = (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 4H6V0H4V4H0V6H4V10H6V6H10V4Z" fill="#49A147" />
    </svg>
);

export interface CustomDragLayerProps {
    item?: DragDropItem | DragDropItem[]
    itemType?: string | symbol | null
    initialOffset?: XYCoord | null
    clientOffset?: XYCoord | null
    currentOffset?: XYCoord | null
    isDragging?: boolean
}

function getItemStyles(props: CustomDragLayerProps) {
    const { clientOffset, currentOffset, item } = props;
    if (!clientOffset || !currentOffset || !item) {
        return {
            display: 'none',
        };
    }

    const { x, y } = clientOffset;
    // const sounds = Array.isArray(item.sound) ? item.sound : [item.sound];
    const offset = 1;// (Math.min(sounds.length, MAX_ELEMS) - 1) * 10;

    const transform = `translate(${x + offset}px, ${y - offset}px)`;
    return {
        transform,
        WebkitTransform: transform,
    };
}

const layerStyles: React.CSSProperties = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 10000,
    left: -32,
    top: -24
};

export function DraggableComponent() {
    const collectedProps: CustomDragLayerProps = useDragLayer((monitor) => {
        return {
            item: monitor.getItem(),
            itemType: monitor.getItemType(),
            initialOffset: monitor.getInitialSourceClientOffset(),
            currentOffset: monitor.getSourceClientOffset(),
            isDragging: monitor.isDragging(),
            clientOffset: monitor.getClientOffset()
        };
    });

    const manager = useDragDropManager();
    const monitor = manager.getMonitor();

    const title = useMemo(() => {
        if (collectedProps.item) {
            if (Array.isArray(collectedProps.item)) {
                if (collectedProps.item.length > 1) {
                    return `${collectedProps.item.length} items`;
                }
                return collectedProps.item[0].title;
            }
            return collectedProps.item.title;
        }
        return '';
    }, [collectedProps.item]);

    if (!collectedProps.isDragging) {
        return null;
    }

    const targetIds = monitor.getTargetIds();
    const canDrop = targetIds.find((id) => monitor.canDropOnTarget(id));

    return (
        <div style={layerStyles}>
            <div style={getItemStyles(collectedProps)} className={styles['draggable-component']}>
                <div className={styles['draggable-component__icon']}>
                    {canDrop ? GreenPlusIcon : collectedProps.isDragging ? GrayCrossIcon : RedCrossIcon}
                </div>
                <div className={styles['draggable-component__text-container']}>
                    <div className={styles['draggable-component__text']}>{title}</div>
                </div>

            </div>
        </div>
    );
}
