import useSWR, { useSWRConfig } from 'swr';
import { UserPlaylist } from '@bpm-web-app/api-client';

export const getUserPlaylistCategoriesKey = (isDownload: boolean, include_view_only?: boolean) => ['get-user-playlist-categories', { isDownload, include_view_only, persist: true }];

export function useGetUserPlaylistCategories(isDownload: boolean, include_view_only?: boolean, disabled?: boolean) {
    const { mutate } = useSWRConfig();
    const { data, error } = useSWR(
        getUserPlaylistCategoriesKey(isDownload, include_view_only),
        () => UserPlaylist.getUserPlaylistCategories(isDownload, include_view_only),
        { isPaused: () => !!disabled }
    );

    return {
        data,
        mutate: async () => {
            await Promise.all([
                mutate(getUserPlaylistCategoriesKey(isDownload, true), (d: any) => d, { revalidate: true }),
                mutate(getUserPlaylistCategoriesKey(isDownload, false), (d: any) => d, { revalidate: true }),
                mutate(getUserPlaylistCategoriesKey(isDownload, undefined), (d: any) => d, { revalidate: true })
            ]);
        },
        isLoading: !error && !data,
        error,
    };
}

type UserPlaylistCategoryResponse = Awaited<ReturnType<typeof UserPlaylist['getUserPlaylistCategory']>>;

export function useGetUserPlaylistCategory(id: number | string, isDownload: boolean) {
    const { data, error, mutate } = useSWR<UserPlaylistCategoryResponse>(
        [
            'get-user-playlist-category',
            {
                persist: true,
                isDownload,
                id
            },
        ],
        () => UserPlaylist.getUserPlaylistCategory(id, isDownload)
    );

    return {
        data,
        mutate,
        isLoading: !error && !data,
        error,
    };
}

type UserPlaylistSetsResponse = Awaited<ReturnType<typeof UserPlaylist['getUserPlaylistSets']>> | null;

export const getUserPlaylistSetsKey = (isDownload: boolean, include_view_only?: boolean) => ['get-user-playlist-sets', { isDownload, include_view_only, persist: true }];

export function useUserPlaylistSets(isDownload: boolean, include_view_only?: boolean, disabled?: boolean) {
    const { mutate } = useSWRConfig();
    const { data, error } = useSWR<UserPlaylistSetsResponse>(getUserPlaylistSetsKey(isDownload, include_view_only),
        () => UserPlaylist.getUserPlaylistSets(isDownload, include_view_only),
        { isPaused: () => !!disabled }
    );

    return {
        data,
        mutate: async () => {
            await Promise.all([
                mutate(getUserPlaylistSetsKey(isDownload, true), (d: any) => d, { revalidate: true }),
                mutate(getUserPlaylistSetsKey(isDownload, false), (d: any) => d, { revalidate: true }),
                mutate(getUserPlaylistSetsKey(isDownload, undefined), (d: any) => d, { revalidate: true })
            ]);
        },
        isLoading: !error && !data,
        error,
    };
}
