import { useUniqueArray } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { noop } from 'lodash';
import { useMemo } from 'react';
import GhostElement from '../../ghost-element/ghost-element';
import { TrackListHeader } from '../supreme/track-list-supreme-header';
import { TrackListPresetSupreme } from '../supreme/track-list-supreme-helpers';
import styles from './track-list-loading-supreme.module.css';

interface TrackListLoadingSupremeProps {
    amount: number;
    preset: TrackListPresetSupreme;
    showHeader?: boolean
}

export function TitleColumnPlaceholder({ forMaxiPlayer }: { forMaxiPlayer: boolean }) {
    return (
        <div className={classNames(styles['list-loading__column'], styles['list-loading__column--title'])}>
            <GhostElement
                forMaxiPlayer={forMaxiPlayer}
                hasShimmer
                isRow
                itemClass={styles['list-loading__title-ghost-element']}
                linesWidthArray={[80, 100]}
                squareWidth={40}
                lineHeight={18} />
        </div>
    );
}

export function ColumnPlaceholder({ type }: {
    type: 'bpm' | 'key' | 'genre' | 'download' | 'added' | 'actions' | 'accordion'
}) {
    return (
        <div className={classNames(styles['list-loading__column'], styles[`list-loading__column--${type}`], 'shimmer')} />
    );
}

interface ItemPlaceholderProps {
    preset: 'download' | 'maxi-player';
}

export function ItemPlaceholder({ preset }: ItemPlaceholderProps) {
    const hasDateAdded = preset === 'download';

    return (
        <div className={classNames(styles['list-loading__item'], styles[`list-loading__item--${preset}`])}>
            <ColumnPlaceholder type="accordion" />
            <TitleColumnPlaceholder forMaxiPlayer={preset === 'maxi-player'} />
            <ColumnPlaceholder type="bpm" />
            <ColumnPlaceholder type="key" />
            <ColumnPlaceholder type="genre" />
            <ColumnPlaceholder type="download" />
            {hasDateAdded && <ColumnPlaceholder type="added" />}
            <ColumnPlaceholder type="actions" />
        </div>

    );
}

export function TrackListLoadingSupreme({ amount, preset, showHeader = true }: TrackListLoadingSupremeProps) {
    const items = useUniqueArray(amount);
    const itemPreset = useMemo(() =>
        [
            TrackListPresetSupreme.DownloadMaxi,
            TrackListPresetSupreme.StreamMaxi,
            TrackListPresetSupreme.Queue
        ].includes(preset) ? 'maxi-player' : 'download', [preset]);

    return (

        <div>
            {showHeader ? (
                <TrackListHeader preset={preset} list={[]} setSortPosition={noop} setSortVisible={noop} isOver={false} />
            ) : null}
            {items.map((uuid) => (
                <ItemPlaceholder preset={itemPreset} key={uuid} />
            ))}
        </div>

    );
}
