import { Membership, SubscriptionDetailsv4, SubscriptionPackage } from '@bpm-web-app/supreme-api-sdk';
import Router from 'next/router';
import { getCurrentPlatformLink } from './getPlatformLink';
import { DOWNLOAD_ONLY } from './hubSwitch.context';
import monthlyPlanText from './monthlyPlanText';
import { formatDateToString } from './date';
import { formatBillingPeriod } from './formatter';

export type PlanItem = {
    id: number;
    title: string;
    amount: string | number;
    libraryText: string;
    plan: SubscriptionPackage;
    description: string;
    ctaText: string;
    onCtaClick: () => void;
    color: 'primary' | 'latin' | 'all';
    platform?: string;
    promotionText?: string;
    // eslint-disable-next-line react/require-default-props
    saleText?: string;
    isCurrent?: boolean;
    allowed: boolean;
    isFree?: boolean;
    index?: number;
    variant?: 'card' | 'card-small' | 'credits';
};

export const getColorFromLibrary = (library: SubscriptionPackage['library']): PlanItem['color'] => {
    switch (library) {
        case SubscriptionPackage.LibraryEnum.Music: {
            return 'primary';
        }
        case SubscriptionPackage.LibraryEnum.Latin: {
            return 'latin';
        }
        case SubscriptionPackage.LibraryEnum.All:
        default: {
            return 'all';
        }
    }
};

export const getLibraryName = (library: SubscriptionPackage['library']): PlanItem['title'] => {
    switch (library) {
        case SubscriptionPackage.LibraryEnum.Music: {
            return 'Open Format';
        }
        case SubscriptionPackage.LibraryEnum.Latin: {
            return 'Latino';
        }
        case SubscriptionPackage.LibraryEnum.All: {
            return 'Open Format + Latino';
        }

        default: {
            return '';
        }
    }
};

export const getPlatformName = (platform: SubscriptionPackage['platform']) => {
    switch (platform) {
        case SubscriptionPackage.PlatformEnum.Stream: {
            if (DOWNLOAD_ONLY) {
                return '';
            }
            return 'Stream';
        }

        case SubscriptionPackage.PlatformEnum.Supreme: {
            if (DOWNLOAD_ONLY) {
                return '';
            }
            return 'Download';
        }

        case SubscriptionPackage.PlatformEnum.Create: {
            return 'Create';
        }

        default:
            return '';
    }
};

export const getPackageHeadingText = (library: SubscriptionPackage['library']) => {
    switch (library) {
        case SubscriptionPackage.LibraryEnum.Music: {
            return `Download top open format tracks, DJ-ready exclusives, edits, and more.
                Plus, get full access to playlists and curated sets with a Premium subscription.`;
        }
        case SubscriptionPackage.LibraryEnum.Latin: {
            return `Download top latin tracks, DJ-ready exclusives, edits, and more.
                Plus, get full access to playlists and curated sets with a Premium subscription.`;
        }
        case SubscriptionPackage.LibraryEnum.All: {
            return `Download top tracks, DJ-ready exclusives, edits, and more.
                Plus, get full access to playlists and curated sets with a Premium subscription.`;
        }

        default: {
            return '';
        }
    }
};

export const isCreditsPlan = (plan: SubscriptionPackage) => plan.name?.toString()?.includes('Credits');

export const getSalesText = (plan: SubscriptionPackage): string => {
    const isCredits = isCreditsPlan(plan);
    if (isCredits && plan.trial_amount && plan.trial_amount > '0.00') {
        return `$${plan.trial_amount}`;
    }
    if (plan.trial_period === 0 || plan.trial_period === null) {
        return '';
    }
    const trialAmount = plan?.trial_amount ? +plan.trial_amount : 0;

    if (trialAmount > 0 && plan.trial_period > 0) {
        return `${formatBillingPeriod(plan.trial_period, true)} for $${trialAmount}`;
    }

    return plan.trial_period === 1 ? 'First month free!' : `${plan.trial_period} months free!`;
};

export const convertCreatePackagesToPlanItem = (plan: SubscriptionPackage, currentPlanPrice: number, currentPlatformMembership?: Membership, setModalOpen?: (isOpen: boolean) => void): PlanItem => {
    const isCredits = isCreditsPlan(plan);
    let ctaText = isCredits ? 'Add credits' : 'Add Plan';

    if (!isCredits && currentPlatformMembership?.has_membership) {
        if (Number(plan.amount) < currentPlanPrice) {
            ctaText = 'Downgrade Plan';
        } else {
            ctaText = 'Upgrade Plan';
        }

        if (plan.is_current) {
            ctaText = 'Current Plan';
            if (currentPlatformMembership.subscription?.status === SubscriptionDetailsv4.StatusEnum.Cancelled) {
                ctaText = `Ends on ${formatDateToString(currentPlatformMembership.subscription.expire_date, 'MM/DD/YYYY')}`;
            }
        }
    }

    let saleText = plan.info?.trial_allowed ? getSalesText(plan) : '';

    if (plan.info?.promoted && plan.info?.promoted_amount) {
        saleText = `Pay $${plan.info.promoted_amount} today`;
        if (plan.info?.promoted_amount === '0.00') {
            saleText = '';
        }
    }

    if (plan.is_current) {
        saleText = '';
    }

    const handleCtaClick = () => {
        if (isCredits && !currentPlatformMembership?.has_membership && setModalOpen) {
            setModalOpen(true);
        } else {
            Router.push({
                pathname: getCurrentPlatformLink(`/account/plan/change/${plan.id}`),
            });
        }
    };

    return {
        id: plan.id,
        amount: monthlyPlanText(plan.amount, plan.method === SubscriptionPackage.MethodEnum.OneTime, plan.trial_period),
        description: plan.description ?? '',
        title: `${plan.name}`,
        color: getColorFromLibrary(plan.library),
        ctaText,
        plan,
        onCtaClick: handleCtaClick,
        libraryText: getLibraryName(plan.library),
        saleText,
        isCurrent: plan.is_current,
        platform: plan.platform as unknown as string,
        promotionText: plan.is_promotion ? plan.promotion_text : undefined,
        allowed: !!plan.info?.allowed && !(currentPlatformMembership?.subscription?.status === SubscriptionDetailsv4.StatusEnum.Cancelled),
    };
};

export const convertSupremePackageToPlanItem = (plan: SubscriptionPackage, currentPlatformMembership?: Membership, verificationStatus?: boolean): PlanItem => {
    let ctaText = 'Add Plan';
    let disabled = false;
    if (currentPlatformMembership?.has_membership) {
        const { subscription } = currentPlatformMembership;
        const isPremiumPlan = plan.name === SubscriptionPackage.NameEnum.Premium;
        const planLibraryIsAll = plan.library === SubscriptionPackage.LibraryEnum.All;
        const subscribedToAllLibrary = subscription?.library === SubscriptionDetailsv4.LibraryEnum.All;
        // eslint-disable-next-line max-len
        const subscribedToPremium = subscription?.membership_type === SubscriptionDetailsv4.MembershipTypeEnum.Premium;

        if (isPremiumPlan && !subscribedToPremium) {
            // User is subscribed to Standard and the plan is Premium
            ctaText = 'Upgrade Plan';
        } else if ((isPremiumPlan && subscribedToPremium && planLibraryIsAll && !subscribedToAllLibrary) || (!isPremiumPlan && !subscribedToPremium && planLibraryIsAll && !subscribedToAllLibrary)) {
            // User is subscribed to a single plan and the plan library is all
            ctaText = 'Upgrade Plan';
        } else if ((!isPremiumPlan && !subscribedToPremium && !planLibraryIsAll && !subscribedToAllLibrary) || (isPremiumPlan && subscribedToPremium && !planLibraryIsAll && !subscribedToAllLibrary)) {
            // User is subscribed to single plan and the plan library is single
            ctaText = 'Switch Plan';
        } else if (!isPremiumPlan && subscribedToPremium) {
            // User has a Premium plan and the plans is Standard
            ctaText = 'Downgrade Plan';
        } else if (isPremiumPlan && subscribedToPremium && subscribedToAllLibrary) {
            // User has a Premium plan (lib all))
            ctaText = 'Downgrade Plan';
        } else if (!isPremiumPlan && !subscribedToPremium && subscribedToAllLibrary) {
            ctaText = 'Downgrade Plan';
        }
    }

    if (plan.is_current) {
        disabled = true;
        ctaText = 'Current Plan';
        if (currentPlatformMembership?.subscription?.status === SubscriptionDetailsv4.StatusEnum.Cancelled) {
            ctaText = `Ends on ${formatDateToString(currentPlatformMembership.subscription.expire_date, 'MM/DD/YYYY')}`;
        }
    }

    if (!verificationStatus && plan.platform === SubscriptionPackage.PlatformEnum.Supreme) {
        ctaText = 'Apply';
    }

    let saleText = plan.info?.trial_allowed ? getSalesText(plan) : '';

    if (plan.info?.promoted && plan.info?.promoted_amount) {
        saleText = `Pay $${plan.info.promoted_amount} today`;
        if (plan.info?.promoted_amount === '0.00') {
            saleText = '';
        }
    }

    if (plan.is_current) {
        saleText = '';
    }

    const handleCtaClick = () => {
        Router.push({
            pathname: getCurrentPlatformLink(`/account/plan/change/${plan.id}`),
        });
    };

    const handleApplyClick = () => {
        Router.push({
            pathname: getCurrentPlatformLink(`/account/dj-verification/${plan.id}`),
        });
    };

    return {
        id: plan.id,
        amount: monthlyPlanText(plan.amount, plan.method === SubscriptionPackage.MethodEnum.OneTime, plan.trial_period),
        description: plan.description ?? '',
        title: `${getPlatformName(plan.platform)} ${plan.name}`,
        color: getColorFromLibrary(plan.library),
        ctaText,
        onCtaClick: verificationStatus === true || plan.platform !== SubscriptionPackage.PlatformEnum.Supreme ? handleCtaClick : handleApplyClick,
        libraryText: getLibraryName(plan.library),
        plan,
        saleText,
        isCurrent: plan.is_current,
        platform: plan.platform as unknown as string,
        promotionText: plan.is_promotion ? plan.promotion_text : undefined,
        allowed: !!plan.info?.allowed && !(currentPlatformMembership?.subscription?.status === SubscriptionDetailsv4.StatusEnum.Cancelled) && !disabled,
    };
};

export function sortSupremePlanItems(p1: PlanItem, p2: PlanItem) {
    const indexes = [SubscriptionPackage.LibraryEnum.Music, SubscriptionPackage.LibraryEnum.Latin, SubscriptionPackage.LibraryEnum.All];
    if (p1.plan.trial_period === p2.plan.trial_period && p1.plan.library && p2.plan.library) {
        return indexes.indexOf(p1.plan.library) - indexes.indexOf(p2.plan.library);
    }
    return 0;
}
