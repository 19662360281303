/**
 * Utility to handle file download always in the same way.
 * In Create App assigning the link to the current page `_self`, causes lists/data to vanish
 * As workaround we are assigning the link to a new tab
 *
 * NOTE: if we can make the data to not vanish we can change _blank to _self
 * NOTE: Safari blocks popups by default, so we can't to add the target="_blank" to the anchor tag
 */
export const fileDownload = async (url: string) => {
    window.location.assign(url);
};
