import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Notification } from '@bpm-web-app/supreme-api-sdk';

import { useCurrentUser, useNotifications } from '@bpm-web-app/swr-hooks';
import { useOnClickOutside, useViewport } from '@bpm-web-app/utils';
// Icons
import CloseIcon from '../../assets/icons/close.svg';
import NavIcon from '../../assets/icons/nav-icon.svg';
import BPMLogo from '../../assets/icons/bpm-logo.svg';
import ArtistPortalLogo from '../../assets/icons/artist-platform/yellow-icon.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/add.svg';

import { NavContext } from '../nav/nav.context';
import { UserNotifications } from '../user-notifications/user-notifications';
import mainHeaderStyles from './supreme-header.module.css';
import { PlatformSwitch } from '../shared/platform-switch/platform-switch';
import { ArtistPortalHeaderContext } from './artist-portal-header.context';
import { SquaredButton } from '../shared/squared-button/squared-button';
import { AppLink } from '../shared';
import { HeaderProfileButton } from './header-profile-button/header-profile-button';
import { HeaderNotificationButton } from './header-notification-button/header-notification-button';
import { HeaderPlatformsButton } from './header-platforms-button/header-platforms-button';
import { HeaderAccount } from './header-account/header-account';
import { SupremeProfileMenu } from './supreme-profile-menu/supreme-profile-menu';

export function ArtistPortalHeader() {
    const router = useRouter();
    const isAccount = useMemo(() => router.pathname.includes('/account/'), [router.pathname]);

    const { data: userData } = useCurrentUser();
    const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
    const [showPlatformSwitch, setShowPlatformSwitch] = useState(false);
    const { isNavOpen, openNav, closeNav } = useContext(NavContext);
    const { isSubmitTrackButtonHidden } = useContext(ArtistPortalHeaderContext);

    const { data: notifications } = useNotifications([Notification.PlatformEnum.Artist]);
    const [showNotifications, setShowNotifications] = useState(false);
    const { isMobile: isMobileViewPort } = useViewport();
    const { ref: notificationElRef } = useOnClickOutside<HTMLDivElement>(false, () => (isMobileViewPort ? null : setShowNotifications(false)));

    const desktopMenuOpenerRef = useRef<HTMLDivElement>(null);
    const { ref: profileMenuRef } = useOnClickOutside<HTMLDivElement>(false, (e: MouseEvent) => {
        if (!desktopMenuOpenerRef.current?.contains(e.target as Node)) {
            setIsProfileMenuOpen(false);
        }
    });

    const desktopSwitchPlatformOpenerRef = useRef<HTMLDivElement>(null);
    const { ref: switchPlatformMenuRef } = useOnClickOutside<HTMLDivElement>(false, (e: MouseEvent) => {
        if (!desktopSwitchPlatformOpenerRef.current?.contains(e.target as Node)) {
            setShowPlatformSwitch(false);
        }
    });

    const hasNotifications = useMemo(() => notifications?.data?.some((notification) => !notification.read), [notifications?.data]);

    const closeIcon = useMemo(() => <CloseIcon />, []);
    const navIcon = useMemo(() => <NavIcon />, []);

    useEffect(() => {
        setIsProfileMenuOpen(false);
    }, [router]);

    const handleMenuToggle = () => {
        if (isMobileViewPort) closeNav();
        setIsProfileMenuOpen(!isProfileMenuOpen);
    };

    return (
        <div className={mainHeaderStyles['supreme-header']}>
            <header className={classNames(mainHeaderStyles['supreme-header__container'], { [mainHeaderStyles['supreme-header__container--nav-opened']]: isNavOpen })}>
                {!isSubmitTrackButtonHidden ? (
                    <button
                        type="button"
                        aria-label="Open Nav"
                        onClick={() => {
                            if (isNavOpen) {
                                setShowNotifications(false);
                                closeNav();
                            } else {
                                openNav();
                            }
                        }}
                        className={mainHeaderStyles['supreme-header__mobile-nav-btn']}
                    >
                        {isNavOpen ? closeIcon : navIcon}
                    </button>
                ) : (
                    <div />
                )}
                {isMobileViewPort ? (
                    <div className={mainHeaderStyles['supreme-header__logo']}>
                        <AppLink href="/">
                            <a aria-label="Homepage" className={mainHeaderStyles['supreme-header__logo-img']}>
                                <BPMLogo />
                            </a>
                        </AppLink>
                    </div>
                ) : null}

                {isAccount && <HeaderAccount IconComponent={ArtistPortalLogo} title="BPM For Artist" />}

                <div className={mainHeaderStyles['supreme-header__right']}>
                    {!isAccount && (
                        <>
                            {!isSubmitTrackButtonHidden ? (
                                <div className={mainHeaderStyles['supreme-header__submit-track']}>
                                    <SquaredButton type="filled" leftIcon={<PlusIcon height={26} width={26} />} label="Submit Track" onPress={() => router.push('/submit-track')} />
                                </div>
                            ) : null}

                            {!isSubmitTrackButtonHidden ? (
                                <div>
                                    <div ref={desktopSwitchPlatformOpenerRef}>
                                        <HeaderPlatformsButton onClick={() => setShowPlatformSwitch(!showPlatformSwitch)} isOpen={showPlatformSwitch} />
                                    </div>
                                    <div ref={switchPlatformMenuRef}>
                                        {showPlatformSwitch ? (
                                            <PlatformSwitch
                                                platforms={[{ name: 'BPM Create' }, { name: 'BPM Supreme' }]}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            ) : null}
                            {!isSubmitTrackButtonHidden ? (
                                <div ref={notificationElRef}>
                                    <HeaderNotificationButton
                                        onClick={() => setShowNotifications((prevState) => !prevState)}
                                        hasNotifications={hasNotifications}
                                        isShowingNotifications={showNotifications}
                                    />
                                    <UserNotifications
                                        platform={Notification.PlatformEnum.Artist}
                                        onClose={() => {
                                            setShowNotifications(false);
                                        }}
                                        isVisible={showNotifications && !isMobileViewPort}
                                    />
                                </div>
                            ) : null}
                        </>
                    )}

                    <div ref={desktopMenuOpenerRef}>
                        <HeaderProfileButton
                            isOpen={isProfileMenuOpen}
                            isHidden={isSubmitTrackButtonHidden}
                            imageAlt={userData?.data?.user?.full_name}
                            imageUrl={userData?.data?.user?.profile_image_thumbnail_url}
                            onClick={handleMenuToggle}
                        />
                    </div>
                </div>
                <HeaderProfileButton
                    imageAlt={userData?.data?.user?.full_name}
                    imageUrl={userData?.data?.user?.profile_image_thumbnail_url}
                    onClick={handleMenuToggle}
                    isMobile
                    isOpen={isProfileMenuOpen}
                    hasNotifications={hasNotifications}
                />
            </header>
            <SupremeProfileMenu
                isOpen={isProfileMenuOpen}
                ref={profileMenuRef}
                platforms={[{ name: 'BPM Supreme' }, { name: 'BPM Create' }]}
                setIsOpen={setIsProfileMenuOpen}
                userData={userData}
            />
        </div>
    );
}

export default ArtistPortalHeader;
