import Head from 'next/head';

export interface TitleProps {
    title?: string
    platform: 'create' | 'download' | 'stream' | 'artist'
}

export function Title({ title, platform }: TitleProps) {
    // eslint-disable-next-line no-nested-ternary
    const platformString = platform === 'create' ? 'BPM Create' : platform === 'artist' ? 'BPM Artist Platform' : (platform === 'download' ? 'BPM Supreme' : 'BPM Stream');
    if (!title) {
        return (
            <Head>
                <title>{platformString}</title>
            </Head>
        );
    }
    return (
        <Head>
            <title>{title} | {platformString}</title>
        </Head>
    );
}

export default Title;
