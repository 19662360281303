import { useCallback, useContext, useEffect, useState } from 'react';

import { useDownloadAlbumById } from '@bpm-web-app/swr-hooks';
import {
    CreateCardCarouselItem,
    State as PlayerStateEnum,
    appendQueryParams,
    convertToPluralIfNeeded,
    downloadAlbumWithMediaToQueueItem,
    parseSeconds,
    useHubSwitch,
    useUserSettings,
    usePlayerState,
} from '@bpm-web-app/utils';

import classNames from 'classnames';
import PauseIcon from '../../assets/icons/supreme/pause-large.svg';
import PlayIcon from '../../assets/icons/supreme/play-large.svg';
import NextIcon from '../../assets/icons/supreme/player-next.svg';
import PrevIcon from '../../assets/icons/supreme/player-prev.svg';
import RepeatOneIcon from '../../assets/icons/supreme/player-repeat-one.svg';
import RepeatIcon from '../../assets/icons/supreme/player-repeat.svg';
import ShuffleIcon from '../../assets/icons/supreme/player-shuffle.svg';
import { useArtistLinks } from '../artist-link/artist-link';
import { useBpmLink, useGenreLinks, useKeyLinks } from '../generate-link/generate-link';
import useFavoriteAlbums from '../shared/three-dots-sheet/useFavoriteAlbums';
import { PlayerPrimaryControls } from './parts/player-primary-controls/player-primary-controls';
import { PlayerSecondaryControls } from './parts/player-secondary-controls/player-secondary-controls';
import { QuickActions } from './parts/quick-actions/quick-actions';
import { TopMenu } from './parts/top-menu/top-menu';
import { TrackTitle } from './parts/track-title/track-title';
import { VersionsDownload } from './parts/versions-download/versions-download';
import { Wave } from './parts/wave/wave';
import VersionsDropdownSupreme, { VersionsButtonSupreme } from '../versions-dropdown/versions-dropdown-supreme';
import { usePlayer } from '../player-context';
import { ThreeDotsSheetContext } from '../shared';
import styles from './supreme-maxi-player.module.css';

type Props = {
    onVersionsMenuOpen: () => void
};

export function PlayingTrack({ onVersionsMenuOpen }: Props) {
    const { repeat, currentTrack, isShuffling, isRepeating, hasNextTrack, hasPrevTrack, nextTrack, prevTrack, toggleShuffle, toggleRepeat, replaceCurrentPlayingTrack, onPlay, onPause } = usePlayer();
    const playerState = usePlayerState();
    const { isDownload } = useHubSwitch();

    const generateArtistLinks = useArtistLinks();

    const { openThreeDotsModalSheet } = useContext(ThreeDotsSheetContext);

    const { data: downloadAlbumData, mutate } = useDownloadAlbumById(currentTrack?.album_id, isDownload);

    const { isAnonymous, setSelectedMedia, setShowSignUpModal } = useUserSettings();

    const setCurrentMediaInContext = useCallback(
        (showSignUpModal?: boolean) => {
            if (downloadAlbumData?.data) {
                const currentCenterMedia = downloadAlbumData?.data;
                if (currentCenterMedia) setSelectedMedia(currentCenterMedia as unknown as CreateCardCarouselItem, [downloadAlbumData?.data] as unknown as CreateCardCarouselItem[]);
                if (showSignUpModal) {
                    setShowSignUpModal({ type: 'track' });
                }
            }
        },
        [downloadAlbumData?.data, setSelectedMedia, setShowSignUpModal]
    );

    useEffect(() => {
        setCurrentMediaInContext();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAnonymous]);

    const openThreeDots = useCallback(
        async (element) => {
            if (isAnonymous) {
                setCurrentMediaInContext(true);
                return;
            }
            const { top: topPos, left: leftPos } = (element.target as HTMLButtonElement).getBoundingClientRect();
            openThreeDotsModalSheet('supreme-maxi-player', currentTrack?.id, leftPos, topPos + window.scrollY, false, currentTrack.album_id);
        },
        [openThreeDotsModalSheet, currentTrack, setCurrentMediaInContext, isAnonymous]
    );

    const openPlaylistsModal = useCallback(
        async (element) => {
            if (isAnonymous) {
                setCurrentMediaInContext(true);
                return;
            }

            const { top: topPos, left: leftPos } = (element.target as HTMLButtonElement).getBoundingClientRect();

            openThreeDotsModalSheet('maxi-player', currentTrack?.id, leftPos, topPos + window.scrollY, true, currentTrack.album_id);
        },
        [openThreeDotsModalSheet, currentTrack, isAnonymous, setCurrentMediaInContext]
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { addAlbumToFavorites, removeAlbumFromFavorites, isAlbumFavorite } = useFavoriteAlbums(currentTrack!.album_id!);

    const likeOrUnlike = useCallback(() => {
        if (isAnonymous) {
            setCurrentMediaInContext(true);
            return;
        }
        if (isAlbumFavorite) {
            removeAlbumFromFavorites();
        } else {
            addAlbumToFavorites();
        }
    }, [addAlbumToFavorites, isAlbumFavorite, isAnonymous, removeAlbumFromFavorites, setCurrentMediaInContext]);

    const generateBPMLinks = useBpmLink();
    const generateKeyLinks = useKeyLinks();
    const generateGenreLinks = useGenreLinks();

    const onDownloadRevalidate = useCallback(
        (downloadMedia) => {
            if (downloadMedia && downloadAlbumData?.data) {
                // eslint-disable-next-line no-param-reassign
                downloadMedia.download_count += 1;
                mutate({ data: downloadAlbumData?.data }, false);
            }
        },
        [mutate, downloadAlbumData?.data]
    );

    if (!currentTrack) return null;

    return (
        <div>
            <TopMenu openThreeDots={openThreeDots} />
            <div className={styles['maxi-player__desktop']}>
                <div className={styles['maxi-player__desktop-wrapper']}>
                    <div className={styles['maxi-player__desktop-cover-wrapper']}>
                        <img
                            src={appendQueryParams(currentTrack.cover_url, { key: 'dw', value: 317 })}
                            srcSet={`${appendQueryParams(currentTrack.cover_url, { key: 'dw', value: 634 })} 2x`}
                            alt={currentTrack.title}
                            className={styles['maxi-player__desktop-cover']}
                        />
                    </div>
                    <div className={styles['maxi-player__desktop-right-content']}>
                        <div className={classNames(styles['maxi-player__desktop-track-info'])}>
                            <div className={styles['maxi-player__desktop-player-title-container']}>
                                <TrackTitle currentTrack={currentTrack} />

                                <div className={classNames(styles['maxi-player__desktop-player-title-versions-button'])}>
                                    {isDownload && currentTrack.versions && currentTrack.versions - 1 > 0 && (
                                        <VersionsDropdownSupreme<number>
                                            onClick={(version) => {
                                                replaceCurrentPlayingTrack(downloadAlbumWithMediaToQueueItem(downloadAlbumData.data, version));
                                            }}
                                            openThreeDotsMenu={(versionId, left, top) => {
                                                setCurrentMediaInContext();
                                                openThreeDotsModalSheet('maxi-player-versions', versionId, left - 220, top - 100, false, currentTrack?.album_id);
                                            }}
                                            placeholder={convertToPluralIfNeeded(currentTrack.versions, 'Version')}
                                            options={downloadAlbumData?.data.media.map((media, index) => ({
                                                versionName: media.version.name,
                                                versionId: media.id,
                                                versionIndex: index,
                                                versionDuration: parseSeconds(media.estimated_duration || 0),
                                            }))}
                                            flipOptionsContainer
                                            currentVersion={currentTrack.version}
                                            layoutType="mini"
                                        />
                                    )}
                                </div>
                            </div>
                            <h2 className={styles['maxi-player__subtitle']}>{generateArtistLinks(currentTrack.artist, currentTrack.artists)}</h2>
                            <div className={styles['maxi-player__meta-items']}>
                                <span className={styles['maxi-player__meta-item']}>{generateBPMLinks(currentTrack.bpm)}</span>
                                <span className={styles['maxi-player__meta-item']}>{generateKeyLinks(currentTrack.actual_key, currentTrack.display_key)}</span>
                                <span className={styles['maxi-player__meta-item']}>{generateGenreLinks(currentTrack.genre)}</span>
                            </div>
                        </div>

                        <div className={classNames(styles['maxi-player__desktop-controls'])}>
                            <PlayerPrimaryControls
                                hasNextTrack={hasNextTrack}
                                hasPrevTrack={hasPrevTrack}
                                playerState={playerState}
                                onPlay={onPlay}
                                onPause={onPause}
                                nextTrack={nextTrack}
                                prevTrack={prevTrack}
                            />
                            <Wave variant="maxi-desktop" />

                            <PlayerSecondaryControls isShuffling={isShuffling} isRepeating={isRepeating} toggleShuffle={toggleShuffle} toggleRepeat={toggleRepeat} repeat={repeat} />
                        </div>
                        <div className={classNames(styles['maxi-player__desktop-actions'])}>
                            <div className={classNames(styles['maxi-player__desktop-actions-download'])}>
                                {downloadAlbumData?.data ? (
                                    <VersionsDownload
                                        media={downloadAlbumData?.data}
                                        onDownloadRevalidate={onDownloadRevalidate}
                                        hasPremiumOnlyAccess={downloadAlbumData?.data.has_premium_only_access}
                                        isPremiumOnly={downloadAlbumData?.data.is_premium_only}
                                        setCurrentMediaInContext={() => setCurrentMediaInContext(!downloadAlbumData?.data.has_premium_only_access)}
                                    />
                                ) : null}
                            </div>
                            <QuickActions isFavorite={isAlbumFavorite} openPlaylistsModal={openPlaylistsModal} openThreeDots={openThreeDots} likeOrUnlike={likeOrUnlike} />
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles['maxi-player__tablet']}>
                <div className={styles['maxi-player__tablet-wrapper']}>
                    <div className={styles['maxi-player__desktop-cover-wrapper']}>
                        <img
                            src={appendQueryParams(currentTrack.cover_url, { key: 'dw', value: 317 })}
                            srcSet={`${appendQueryParams(currentTrack.cover_url, { key: 'dw', value: 634 })} 2x`}
                            alt={currentTrack.title}
                            className={styles['maxi-player__desktop-cover']}
                        />
                    </div>
                    <div className={classNames(styles['maxi-player__desktop-track-info'])}>
                        <div className={styles['maxi-player__desktop-player-title-container']}>
                            <TrackTitle currentTrack={currentTrack} />
                        </div>
                        <h2 className={styles['maxi-player__subtitle']}>{generateArtistLinks(currentTrack.artist, currentTrack.artists)}</h2>
                        <div className={styles['maxi-player__meta-items']}>
                            <span className={styles['maxi-player__meta-item']}>{generateBPMLinks(currentTrack.bpm)}</span>
                            <span className={styles['maxi-player__meta-item']}>{generateKeyLinks(currentTrack.actual_key, currentTrack.display_key)}</span>
                            <span className={styles['maxi-player__meta-item']}>{generateGenreLinks(currentTrack.genre)}</span>
                        </div>
                    </div>
                    <div className={styles['maxi-player__tablet-player-menu']}>
                        {/* @TODO - This can call the API with the currentTrack.album_id
              and get all versions from there */}
                        {isDownload && currentTrack.versions && currentTrack.versions - 1 > 0 && (
                            <VersionsDropdownSupreme<number>
                                onClick={(version) => {
                                    replaceCurrentPlayingTrack(downloadAlbumWithMediaToQueueItem(downloadAlbumData.data, version));
                                }}
                                openThreeDotsMenu={(versionId, left, top) => {
                                    setCurrentMediaInContext();
                                    openThreeDotsModalSheet('maxi-player-versions', versionId, left - 220, top - 100, false, currentTrack?.album_id);
                                }}
                                placeholder={convertToPluralIfNeeded(currentTrack.versions, 'Version')}
                                options={downloadAlbumData?.data.media.map((media, index) => ({
                                    versionName: media.version.name,
                                    versionId: media.id,
                                    versionIndex: index,
                                    versionDuration: parseSeconds(media.estimated_duration || 0),
                                }))}
                                flipOptionsContainer
                                currentVersion={currentTrack.version}
                                layoutType="mini"
                            />
                        )}

                        <PlayerSecondaryControls isShuffling={isShuffling} isRepeating={isRepeating} toggleShuffle={toggleShuffle} toggleRepeat={toggleRepeat} repeat={repeat} />
                    </div>

                    <div className={classNames(styles['maxi-player__desktop-controls'])}>
                        <PlayerPrimaryControls
                            hasNextTrack={hasNextTrack}
                            hasPrevTrack={hasPrevTrack}
                            playerState={playerState}
                            onPlay={onPlay}
                            onPause={onPause}
                            nextTrack={nextTrack}
                            prevTrack={prevTrack}
                        />

                        <Wave variant="maxi-tablet" />
                    </div>

                    <div className={classNames(styles['maxi-player__desktop-actions'])}>
                        <div className={classNames(styles['maxi-player__desktop-actions-download'])}>
                            {downloadAlbumData?.data ? (
                                <VersionsDownload
                                    media={downloadAlbumData?.data}
                                    onDownloadRevalidate={onDownloadRevalidate}
                                    hasPremiumOnlyAccess={downloadAlbumData?.data.has_premium_only_access}
                                    isPremiumOnly={downloadAlbumData?.data.is_premium_only}
                                    setCurrentMediaInContext={() => setCurrentMediaInContext(!downloadAlbumData?.data.has_premium_only_access)}
                                />
                            ) : null}
                        </div>
                        <QuickActions isFavorite={isAlbumFavorite} openPlaylistsModal={openPlaylistsModal} openThreeDots={openThreeDots} likeOrUnlike={likeOrUnlike} />
                    </div>
                </div>
            </div>

            <div className={styles['maxi-player__mobile']}>
                <div className={styles['maxi-player__mobile-cover-wrapper']}>
                    <img
                        src={appendQueryParams(currentTrack.cover_url, { key: 'dw', value: 480 })}
                        srcSet={`${appendQueryParams(currentTrack.cover_url, { key: 'dw', value: 960 })} 2x`}
                        alt={currentTrack.title}
                        className={styles['maxi-player__mobile-cover']}
                    />
                </div>

                <div className={styles['maxi-player__mobile-title-container']}>
                    <h1 className={styles['maxi-player__mobile-title']}>{currentTrack.title}</h1>
                    <h2 className={styles['maxi-player__subtitle']}>{generateArtistLinks(currentTrack.artist, currentTrack.artists)}</h2>
                    <p className={styles['maxi-player__meta-items']}>
                        <span className={styles['maxi-player__meta-item']}>{generateBPMLinks(currentTrack.bpm)}</span>
                        <span className={styles['maxi-player__meta-item']}>{generateKeyLinks(currentTrack.actual_key, currentTrack.display_key)}</span>
                        <span className={styles['maxi-player__meta-item']}>{generateGenreLinks(currentTrack.genre)}</span>
                    </p>
                </div>

                <div className={classNames(styles['maxi-player__mobile-versions-button'])}>
                    {isDownload && currentTrack.versions && currentTrack.versions - 1 > 0 && (
                        <VersionsButtonSupreme onClick={onVersionsMenuOpen} layoutType="mini" currentVersion={currentTrack.version} />
                    )}
                </div>

                <div className={styles['maxi-player__mobile-player-ctrls']}>
                    <button
                        className={classNames(styles['maxi-player__mobile-player-ctrl'], styles['maxi-player__player-ctrl--shuffle'], {
                            [styles['maxi-player__player-ctrl--active']]: isShuffling,
                        })}
                        type="button"
                        aria-label="Shuffle"
                        onClick={toggleShuffle}
                    >
                        <ShuffleIcon />
                    </button>
                    <div className={classNames(styles['maxi-player__player-ctrls-main'])}>
                        <button
                            className={classNames(styles['maxi-player__player-ctrl'], styles['maxi-player__player-ctrl--prev'])}
                            type="button"
                            onClick={prevTrack}
                            disabled={!hasPrevTrack}
                            aria-label="Previous Track"
                        >
                            <PrevIcon />
                        </button>
                        <button
                            onClick={() => {
                                if (playerState === PlayerStateEnum.Playing) {
                                    onPause();
                                } else {
                                    onPlay();
                                }
                            }}
                            className={classNames(styles['maxi-player__player-ctrl'], styles['maxi-player__player-ctrl--active'], {
                                [styles['maxi-player__player-ctrl--play']]: playerState !== PlayerStateEnum.Playing,
                                [styles['maxi-player__player-ctrl--pause']]: playerState === PlayerStateEnum.Playing,
                            })}
                            type="button"
                            aria-label={playerState === PlayerStateEnum.Playing ? 'Pause' : 'Play'}
                        >
                            {playerState === PlayerStateEnum.Playing ? <PauseIcon /> : <PlayIcon />}
                        </button>
                        <button
                            className={classNames(styles['maxi-player__player-ctrl'], styles['maxi-player__player-ctrl--next'])}
                            type="button"
                            onClick={nextTrack}
                            disabled={!hasNextTrack}
                            aria-label="Next Track"
                        >
                            <NextIcon />
                        </button>
                    </div>
                    <button
                        className={classNames(styles['maxi-player__player-ctrl'], styles['maxi-player__player-ctrl--repeat'], {
                            [styles['maxi-player__player-ctrl--active']]: isRepeating,
                        })}
                        type="button"
                        aria-label="Repeat"
                        onClick={toggleRepeat}
                    >
                        {(repeat === 'off' || repeat === 'all') && <RepeatIcon />}
                        {repeat === 'one' && <RepeatOneIcon />}
                    </button>
                </div>

                <Wave variant="maxi-mobile" />
            </div>
        </div>
    );
}
