import classNames from 'classnames';
import { ReactElement, useRef } from 'react';
import { useDrag } from '@use-gesture/react';
import { useViewport } from '@bpm-web-app/utils';
import styles from './mobile-drawer.module.css';

/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
export interface MobileDrawerProps {
    isOpen: boolean;
    onClose: () => void;
    children: ReactElement | ReactElement[];
    dynamicHeight?: boolean
    className?: string
}

export function MobileDrawer({ isOpen, children, onClose, dynamicHeight, className }: MobileDrawerProps) {
    const { isMobile } = useViewport();
    const wrapperEl = useRef<HTMLDivElement>(null);
    const dragEvents = useDrag(({ movement: [, mY], distance: [, dY], velocity: [, vY], down }) => {
        const trigger = vY > 0.5;
        if (mY < 0 || !down) {
            wrapperEl.current.style.transform = null;
            wrapperEl.current.style.transition = null;
            if (mY > 0 && trigger) onClose();
            return;
        }
        wrapperEl.current.style.transition = 'none';
        wrapperEl.current.style.transform = `translateY(${mY}px)`;
    });

    if (!isMobile) return null;

    return (
        <>
            <div
                className={classNames(styles['mobile-drawer'], {
                    [styles['mobile-drawer--open']]: isOpen,
                    [styles['mobile-drawer--dynamic']]: dynamicHeight,
                }, className)}
                ref={wrapperEl}
            >
                <button {...(dragEvents as any)()} type="button" className={styles['mobile-drawer__drag-handle']} aria-label="Close Drawer" />
                <div className={styles['mobile-drawer__wrapper']}>{children}</div>
            </div>
            <button
                type="button"
                aria-label="Close Drawer"
                onClick={onClose}
                className={classNames(styles['mobile-drawer-shadow'], {
                    [styles['mobile-drawer-shadow--visible']]: isOpen,
                })}
            />
        </>
    );
}
