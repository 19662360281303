import { MetaApi as StreamMetaApi } from '@bpm-web-app/stream-api-sdk';
import { MetaApi as DownloadMetaApi } from '@bpm-web-app/download-api-sdk';
import { BannerApi as BannerCreateMetaApi, MetaApi as CreateMetaApi } from '@bpm-web-app/create-api-sdk';
import { downloadConfig, streamConfig, createConfig } from '../config';
import { CreateSearchPaginatedQuery, Platform } from '../typings';

export class Meta {
    static streamClient = new StreamMetaApi(streamConfig);

    static createClient = new CreateMetaApi(createConfig);

    static downloadClient = new DownloadMetaApi(downloadConfig);

    static bannerCreateClient = new BannerCreateMetaApi(createConfig);

    static getClient(platform: Platform) {
        switch (platform) {
            case 'download':
                return this.downloadClient;
            case 'create':
                return this.bannerCreateClient;
            case 'stream':
            default:
                return this.streamClient;
        }
    }

    static getBanners = (platform: Platform, bannersLocation: string, library?: string) => platform === 'create' ? this.bannerCreateClient.getBanner(bannersLocation) : Meta.getClient(platform).getBanner(bannersLocation, library);

    static getGenres = () => Meta.createClient.getGenres();

    static getTags = (args: CreateSearchPaginatedQuery) =>
        Meta.createClient.getTags(
            args.limit,
            args.skip,
            args.sort_by,
            args.search,
            args.bpm_max,
            args.bpm_min,
            args.key,
            args.keyType,
            args.type,
            args.tags,
            args.tag_groups,
            args.artists,
            args.subgenre,
            args.label,
            args.sound_package_id,
            args.curated_id,
            args.drive_id,
            args.genre,
            args.showHalfTime,
            args.suggested,
            args.hide_prev_played,
            args.hide_prev_downloaded,
            args.only_free,
            args.tag_categories,
            args.trending,
            args?.synth
        );
}
