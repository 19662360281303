import { useAlbumCount, useGetArtistBySlug, useSearchAlbum } from '@bpm-web-app/swr-hooks';
import { useRouter } from 'next/router';
import { useHideSwitch, useHubSwitch, useSupremeFilterParams } from '@bpm-web-app/utils';
import { useContext, useEffect, useMemo, useState } from 'react';
import { LibraryTabsContext } from '@bpm-web-app/components';
import classNames from 'classnames';
import styles from './artist-albums.module.css';
import SecondaryPageTitle from '../../shared/secondary-page-title/secondary-page-title';
import Title from '../../title/title';
import { usePagination } from '../../shared/paging/paging';
import { GhostComponent, TrackList, TrackListPreset } from '../../shared';
import { APISortingKeys, apiSortOptionsWithRelevance, useApiSort } from '../../sort-options-sheet/sort-options-sheet';
import { TrackListFilters } from '../../shared/track-list/track-list-filters/track-list-filters';
import { TrackListSupreme } from '../../shared/track-list/supreme/track-list-supreme';
import { TrackListPresetSupreme } from '../../shared/track-list/supreme/track-list-supreme-helpers';

enum ArtistDetailTrackView {
    All = 'all',
    Originals = 'originals',
    Remixes = 'remixes',
    RemixedBy = 'remixed_by'
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ArtistAlbumsProps { }

export function ArtistAlbums(props: ArtistAlbumsProps) {
    const router = useRouter();
    const { artistSlug, sortBy, category } = router.query;
    const { isDownload } = useHubSwitch();
    const apiSortFunction = useApiSort();
    const [activeTrackTab, setActiveTrackTab] = useState(ArtistDetailTrackView.All);
    const { libraryProperty } = useContext(LibraryTabsContext);

    useHideSwitch();

    useEffect(() => {
        if (category) {
            setActiveTrackTab(category as ArtistDetailTrackView);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (activeTrackTab !== router.query.category) {
            router.replace({
                pathname: router.pathname,
                query: {
                    ...router.query,
                    category: activeTrackTab,
                },
            });
        }
    }, [activeTrackTab, router]);

    const { limit, page, bottomComponent, setPagination } = usePagination(true);
    const { data: artistData } = useGetArtistBySlug(artistSlug as string);

    const defaultQuery = {
        hide_originals: activeTrackTab === ArtistDetailTrackView.Remixes,
        hide_remix: activeTrackTab === ArtistDetailTrackView.Originals,
        limit,
        skip: (page - 1) * limit,
        artist: activeTrackTab === ArtistDetailTrackView.RemixedBy ? undefined : artistSlug?.toString(),
        remixer: activeTrackTab !== ArtistDetailTrackView.RemixedBy ? undefined : artistSlug?.toString(),
    };
    const query = useSupremeFilterParams(true, defaultQuery);

    const { data: allCount } = useAlbumCount({ library: libraryProperty, artist: artistSlug?.toString() });
    const { data: remixCount } = useAlbumCount({ hide_originals: true, library: libraryProperty, artist: artistSlug?.toString() });
    const { data: originalCount } = useAlbumCount({ hide_remix: true, library: libraryProperty, artist: artistSlug?.toString() });
    const { data: remixedBySongsListTotalCount } = useAlbumCount({ library: libraryProperty, remixer: artistSlug?.toString() });

    const { data, isLoading: isArtistAlbumsLoading, mutate } = useSearchAlbum({
        ...query
    });
    const { hub } = useHubSwitch();

    useEffect(() => {
        setPagination(data?.pagination);
    }, [data, setPagination]);

    const artistAlbums = data?.data;

    const trackTabs = useMemo(() => [
        {
            id: ArtistDetailTrackView.All,
            label: 'All',
            disabled: allCount?.data.total === 0,
            counter: allCount?.data.total || 0
        },
        {
            id: ArtistDetailTrackView.Originals,
            label: 'Originals',
            disabled: originalCount?.data.total === 0,
            counter: originalCount?.data.total || 0,
        },
        {
            id: ArtistDetailTrackView.Remixes,
            label: 'Remixes',
            disabled: remixCount?.data.total === 0,
            counter: remixCount?.data.total || 0,
        },
        {
            id: ArtistDetailTrackView.RemixedBy,
            label: 'Remixed By',
            disabled: remixedBySongsListTotalCount?.data.total === 0,
            counter: remixedBySongsListTotalCount?.data.total || 0,
        }
    ], [allCount?.data.total, originalCount?.data.total, remixCount?.data.total, remixedBySongsListTotalCount?.data.total]);

    return (
        <>
            {artistData && <Title platform={hub} title={artistData?.name} />}
            <article className="spacing__window">
                <div className={styles['artist-albums__title']}>
                    {artistData && <SecondaryPageTitle title={`${artistData?.name} Songs`} noPadding />}
                </div>
                <div className="spacing--bottom"><TrackListFilters dynamicActiveTabColor showActiveFilters defaultFilters={defaultQuery} hideHideRemix /></div>
                {
                    trackTabs.map((tab) => (
                        <button
                            key={tab.id}
                            className={classNames(styles['artist-albums--track-tabs__tab'], {
                                [styles['artist-albums--track-tabs__tab--active']]: activeTrackTab === tab.id,
                                [styles['artist-albums--track-tabs__tab--disabled']]: tab.disabled,
                            })}
                            type="button"
                            role="tab"
                            onClick={() => !tab.disabled && setActiveTrackTab(tab.id)}
                        >
                            {tab.label}
                            <span>{` (${tab.counter})`}</span>
                        </button>
                    ))
                }
                <div>
                    {isArtistAlbumsLoading ? <div className={styles['artist-albums__ghost']}><GhostComponent noPadding type="track-list" elementsCount={limit} preset={isDownload ? TrackListPreset.Download : TrackListPreset.Stream} /></div> : null}
                    {!isArtistAlbumsLoading && (
                        <TrackListSupreme
                            indexOffset={(page - 1) * limit}
                            hasColumnSorting
                            preset={isDownload ? TrackListPresetSupreme.Download : TrackListPresetSupreme.Stream}
                            list={artistAlbums}
                            isSortable
                            onSort={apiSortFunction as any}
                            sortOptions={apiSortOptionsWithRelevance}
                            selectedSortType={sortBy as APISortingKeys}
                            onDownloadRevalidate={(downloadMedia) => {
                                if (downloadMedia) {
                                    // eslint-disable-next-line no-param-reassign
                                    downloadMedia.download_count += 1;
                                    mutate(data, false);
                                }
                            }}
                        />)}

                </div>
                {artistAlbums && data && data.pagination && data.pagination?.total > 25 ? bottomComponent : null}
            </article>
        </>
    );
}

export default ArtistAlbums;
