import { useSearchVersions } from '@bpm-web-app/swr-hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Collapse } from 'react-collapse';
import { useSupremeFilterParams } from '@bpm-web-app/utils';
import { SearchPaginatedQuery } from '@bpm-web-app/api-client';
import Checkboxes from '../shared/checkboxes/checkboxes';
import styles from './versions-filter.module.css';
import ChevronUp from '../../assets/icons/chevron-up.svg';

export interface VersionsFilterProps {
    value: string[];
    onChange: (value: string[]) => void;
    justTags?: boolean;
    viewAll?: boolean;
    rectangularCheckboxes?: boolean;
    onDisable?: (disabled: boolean) => void
    defaultFilters?: Partial<SearchPaginatedQuery>
}

export function VersionsFilter({ value, onChange, justTags, viewAll, rectangularCheckboxes, onDisable, defaultFilters }: VersionsFilterProps) {
    const query = useSupremeFilterParams(true);

    const { versions: versionsData } = useSearchVersions({ ...query, ...defaultFilters });
    const [isExpanded, setIsExpanded] = useState<boolean>(viewAll || false);
    const [isSectionExpanded, setIsSectionExpanded] = useState(true);

    const versionOptions = useMemo(
        () =>
            (versionsData?.map((version) => ({
                label: version.name,
                value: version.name,
                count: version.available_count
            })) || []).slice(0, isExpanded ? undefined : 10),
        [versionsData, isExpanded]
    );

    useEffect(() => {
        onDisable?.(versionOptions.length === 0);
    }, [versionOptions, onDisable]);

    const filterActiveCount = (value.length);

    const handleSectionToggle = useCallback(() => {
        if (!isSectionExpanded) {
            setIsExpanded(false);
        }
        setIsSectionExpanded(!isSectionExpanded);
    }, [isSectionExpanded]);

    const filterComponent = () => (
        <div className={styles['versions-filter__checkboxes']}>
            <Checkboxes hasMaxHeight={32} hasRectangularCheckboxes={rectangularCheckboxes} options={versionOptions} value={value} onChange={onChange} />
        </div>
    );

    if (justTags) {
        return filterComponent();
    }

    return (
        /* eslint-disable-next-line @typescript-eslint/dot-notation */
        <div className={classNames('filter', styles['versions-filter'])}>
            <div
                role="button"
                onClick={handleSectionToggle}
                tabIndex={0}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleSectionToggle();
                    }
                }}
                className="filter__header">
                <div className={classNames('filter__title', { 'filter__title-active': filterActiveCount > 0 })}>Versions {filterActiveCount > 0 ? `(${filterActiveCount})` : ''}</div>
                {filterActiveCount > 0 && (
                    <button type="button" className="filter__reset" onClick={() => onChange([])}>
                        Remove
                    </button>
                )}
                <button
                    aria-label="Expand/Collapse Versions"
                    type="button"
                    onClick={handleSectionToggle}>
                    <ChevronUp className={classNames('filter__chevron', { 'filter__chevron--expanded': isSectionExpanded })} />
                </button>
            </div>
            <Collapse isOpened={isSectionExpanded}>
                {filterComponent()}
                {versionsData && versionsData.length > 10 && (
                    <button type="button" onClick={() => setIsExpanded(!isExpanded)} className="filter__view-more">
                        {isExpanded ? 'View Less' : 'View More'}
                    </button>
                )}
            </Collapse>
        </div>
    );
}

export default VersionsFilter;
