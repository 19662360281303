import { getFollowedCuratedSets } from '@bpm-web-app/swr-hooks';
import { useApiErrorHandler, useHubSwitch, showToast, Analytics } from '@bpm-web-app/utils';
import { CuratedSets } from 'libs/api-client/src/lib/handlers/curated-sets';
import { useEffect, useState } from 'react';

export const useFollowCuratedSets = (setId: number, library?: string) => {
    const { isDownload } = useHubSwitch();

    const { data: followedCuratedSets, isLoading, mutate: mutateFollowedCuratedSets } = getFollowedCuratedSets(library);
    const errorHandler = useApiErrorHandler();
    const [isCuratedSetFollowed, setIsCuratedSetFollowed] = useState<boolean>();
    useEffect(() => {
        const isFav = followedCuratedSets?.data.some(({ id }) => `${id}` === `${setId}`);
        setIsCuratedSetFollowed(isFav);
    }, [followedCuratedSets, setId]);

    const addToFavorites = async () => {
        setIsCuratedSetFollowed(true);
        try {
            mutateFollowedCuratedSets(await CuratedSets.addCuratedSetToFollowed(isDownload, +setId));
            Analytics.trackClick('add_to_favorites_curated_set', setId.toString());
            showToast({ type: 'success', message: 'Added to favorites.' });
        } catch (error) {
            errorHandler({ error });
            setIsCuratedSetFollowed(false);
        }
    };

    const removeFromFavorites = async () => {
        // TODO: Need to see about error handling
        // TODO: Need to implement Toast notification
        setIsCuratedSetFollowed(false);
        try {
            mutateFollowedCuratedSets(await CuratedSets.removeCuratedSetFromFollowed(isDownload, +setId));
            showToast({ type: 'success', message: 'Removed from favorites.' });
        } catch (error) {
            errorHandler({ error });
            setIsCuratedSetFollowed(true);
        }
    };

    return {
        followedCuratedSets,
        isLoading,
        addCuratedSetsToFavorites: addToFavorites,
        removeCuratedSetFromFavorites: removeFromFavorites,
        isCuratedSetFollowed,
    };
};

export default useFollowCuratedSets;
