import Hls, { ErrorData } from 'hls.js';
import qs from 'query-string';
import { isServerSide } from './isServerSide';
import { PlayerState, State } from './playerState';

export class HLSPlayer {
    private static hls: Hls;

    private static mediaAttached: Promise<null>;

    private static eventsBound = false;

    private static lastLoadedUrl = '';

    private static errorHandler: (err: ErrorData) => void = () => { };

    public static videoEl?: HTMLVideoElement = (() => {
        if (isServerSide()) return undefined;
        const videoEl = document.createElement('video');
        videoEl.disablePictureInPicture = true;
        videoEl.oncontextmenu = (e) => {
            e.preventDefault();
            return false;
        };
        videoEl.controls = false;
        videoEl.playsInline = true;
        videoEl.muted = false;
        return videoEl;
    })();

    private static lastPlaybackType: 'hls' | 'other' | 'none' = 'none';

    public static setErrorHandler(handler: (err: ErrorData) => void) {
        HLSPlayer.errorHandler = handler;
    }

    private static init() {
        if (!HLSPlayer.videoEl) return;
        let mediaAttachedResolver: (value: null) => void;
        HLSPlayer.mediaAttached = new Promise((resolve) => {
            mediaAttachedResolver = resolve;
        });
        if (HLSPlayer.hls) {
            HLSPlayer.hls.destroy();
        }
        HLSPlayer.hls = new Hls({
            autoStartLoad: true,
            initialLiveManifestSize: 1,
            xhrSetup: (xhr, url) => {
                // eslint-disable-next-line no-param-reassign
                xhr.withCredentials = true;
            },
        });
        HLSPlayer.hls.on(Hls.Events.MEDIA_ATTACHED, () => {
            mediaAttachedResolver(null);
        });

        HLSPlayer.hls.on(Hls.Events.ERROR, (_, data) => {
            if (data.fatal) {
                switch (data.type) {
                    case Hls.ErrorTypes.NETWORK_ERROR:
                        if (data.details === Hls.ErrorDetails.FRAG_LOAD_ERROR && data.frag?.url.includes('silence')) {
                            this.goTo(data.frag.start + data.frag.duration);
                            return;
                        }
                        PlayerState.STATE = State.Error;
                        HLSPlayer.hls.startLoad();
                        if (data.networkDetails && data.networkDetails.status !== 200) {
                            HLSPlayer.errorHandler(data);
                        }
                        break;
                    case Hls.ErrorTypes.MEDIA_ERROR:
                        HLSPlayer.hls.recoverMediaError();
                        break;
                    default:
                        HLSPlayer.hls.destroy();
                        break;
                }
            }
        });
        HLSPlayer.hls.attachMedia(HLSPlayer.videoEl);

        HLSPlayer.hls.on(Hls.Events.MANIFEST_PARSED, (_, e) => {
            e.levels.forEach((level) => {
                PlayerState.START_TIME_OFFSET = level.details?.startTimeOffset || 0;
                if (level.details?.url.includes('Key-Pair-Id')) {
                    const elems = qs.parseUrl(level.details.url).query;
                    // eslint-disable-next-line @typescript-eslint/dot-notation
                    if (elems['Key-Pair-Id'] && elems['Policy'] && elems['Signature'] && elems['Expires']) {
                        level.details.fragments.forEach((fragment: any) => {
                            // eslint-disable-next-line no-underscore-dangle, no-param-reassign
                            fragment.relurl = `${fragment.relurl}?${qs.stringify(elems)}`;
                        });
                    }
                }
            });
        });

        HLSPlayer.bindEvents();
    }

    private static bindEvents() {
        if (!HLSPlayer.videoEl) return;
        if (HLSPlayer.eventsBound) return;
        HLSPlayer.eventsBound = true;
        HLSPlayer.videoEl.addEventListener('timeupdate', HLSPlayer.handleAudioEvent);
        HLSPlayer.videoEl.addEventListener('pause', HLSPlayer.handleAudioEvent);
        HLSPlayer.videoEl.addEventListener('ended', HLSPlayer.handleAudioEvent);
        HLSPlayer.videoEl.addEventListener('playing', HLSPlayer.handleAudioEvent);
        HLSPlayer.videoEl.addEventListener('error', HLSPlayer.handleAudioEvent);
        HLSPlayer.videoEl.addEventListener('loadedmetadata', HLSPlayer.handleAudioEvent);
        HLSPlayer.videoEl.addEventListener('loadeddata', HLSPlayer.handleAudioEvent);
        HLSPlayer.videoEl.addEventListener('loadstart', HLSPlayer.handleAudioEvent);
        HLSPlayer.videoEl.addEventListener('canplay', HLSPlayer.handleAudioEvent);
        HLSPlayer.videoEl.addEventListener('play', HLSPlayer.handleAudioEvent);
    }

    static handleAudioEvent(event: Event) {
        if (!HLSPlayer.videoEl) return;

        switch (event.type) {
            case 'canplay':
                PlayerState.LOADED_SOUND = true;
                break;
            case 'loadstart':
                PlayerState.LOADED_SOUND = false;
                break;
            case 'play':
                PlayerState.STATE = State.Buffering;
                break;
            case 'playing':
                PlayerState.STATE = State.Playing;
                break;
            case 'pause':
                PlayerState.STATE = State.Paused;
                break;
            case 'timeupdate':
                PlayerState.PROGRESS = HLSPlayer.videoEl.currentTime;
                break;
            case 'loadeddata': {
                const { buffered } = HLSPlayer.videoEl;
                if (buffered.length) {
                    PlayerState.START_TIME_OFFSET = buffered.start(0) || 0;
                }
            }
                break;
            case 'loadedmetadata':
                PlayerState.STATE = State.Playing;
                PlayerState.DURATION = HLSPlayer.videoEl.duration;
                break;
            case 'ended':
                PlayerState.STATE = State.Ended;
                break;
            case 'error':
                PlayerState.STATE = State.Error;
                break;
            default:
                break;
        }
    }

    private static isBlob(url: string) {
        return url.startsWith('blob:');
    }

    static async load(hlsUrl: string, originalUrl: string = hlsUrl) {
        if (!HLSPlayer.videoEl) return;
        HLSPlayer.lastLoadedUrl = originalUrl;
        PlayerState.PROGRESS = 0;

        if (Hls.isSupported() && !HLSPlayer.isBlob(hlsUrl) && !hlsUrl.startsWith('.mp3')) {
            HLSPlayer.lastPlaybackType = 'hls';
            HLSPlayer.init();
            await HLSPlayer.mediaAttached;
            HLSPlayer.hls.loadSource(hlsUrl);
            HLSPlayer.videoEl.play();
        } else {
            if (HLSPlayer.lastPlaybackType === 'hls') {
                HLSPlayer.init();
            }
            HLSPlayer.lastPlaybackType = 'other';
            HLSPlayer.bindEvents();
            HLSPlayer.videoEl.src = hlsUrl;
            HLSPlayer.videoEl.play();
        }
    }

    static getLastLoadedSound() {
        if (!HLSPlayer.lastLoadedUrl) return '';
        return HLSPlayer.lastLoadedUrl;
    }

    static async play() {
        if (!HLSPlayer.videoEl) return;
        HLSPlayer.videoEl.play();
    }

    static pause() {
        if (!HLSPlayer.videoEl) return;
        HLSPlayer.videoEl.pause();
    }

    static stop() {
        if (!HLSPlayer.videoEl) return;
        HLSPlayer.videoEl.pause();
        HLSPlayer.videoEl.currentTime = 0;
    }

    static goTo(time: number) {
        if (!HLSPlayer.videoEl) return;
        HLSPlayer.videoEl.currentTime = time;
    }

    static setVolume(volume: number) {
        if (!HLSPlayer.videoEl) return;
        HLSPlayer.videoEl.volume = volume;
    }

    static getDuration() {
        if (!HLSPlayer.videoEl) return 0;
        return HLSPlayer.videoEl.duration || 0;
    }

    static getCurrentTime() {
        if (!HLSPlayer.videoEl) return 0;
        return HLSPlayer.videoEl.currentTime;
    }

    static destroy() {
        if (!HLSPlayer.hls) return;
        HLSPlayer.lastLoadedUrl = '';
        HLSPlayer.hls.destroy();
    }
}
