import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { Swiper, SwiperSlide } from 'swiper/react';
import { User } from '@bpm-web-app/api-client';
import { useGetMyTutorialStatus } from '@bpm-web-app/swr-hooks';
import { Navigation, Pagination, Swiper as SwiperConfig } from 'swiper';
import Image from 'next/image';
import styles from './modal-slider.module.css';
import ChevronLeft from '../../../assets/icons/chevron-left.svg';
import ChevronRight from '../../../assets/icons/chevron-right.svg';
import CloseIcon from '../../../assets/icons/close.svg';

export type StaticImageData = {
    src: string;
    height: number;
    width: number;
    blurDataURL?: string;
};
interface ModalSliderData {
    image: string | StaticImageData;
    title: string;
    text: string;
    isHtml?: boolean;
    href?: string;
}
export interface ModalSliderProps {
    testing?: boolean
    type: string;
    title?: string
    sliderItems: ModalSliderData[];
}

export const modalOpenBodyClassName = 'react-modal--modal-slider-open';

export function ModalSlider({ type, testing, sliderItems, title = 'Welcome' }: ModalSliderProps) {
    const { data: tutorialState, isLoading } = useGetMyTutorialStatus(type);
    const [swiper, setSwiper] = useState<SwiperConfig>();
    const [activeIndex, setActiveIndex] = useState(0);
    const prevButtonRef = useRef<HTMLButtonElement>(null);
    const nextButtonRef = useRef<HTMLButtonElement>(null);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (!isLoading) {
            /* tip: if modals get annoying during testing, uncomment the line below and comment out
             * the next one - this will cause the modals to not appear even if BE says to show them */
            // setIsOpen(!tutorialState?.data?.show || false);
            setIsOpen(testing || tutorialState?.data?.show || false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, tutorialState?.data, type]);

    const closeModal = async () => {
        setIsOpen(false);
        await User.setMyTutorialStatus(type);
    };

    const pagination = {
        clickable: true,
        el: `.${styles['modal-slider__pagination']}`,
        renderBullet(index: number, className: string) {
            return `<button type="button" class="${classNames(styles['modal-slider__pagination-item'], className)}" aria-label="Go to modal slide ${index + 1}"></button>`;
        },
    };

    return (
        <ReactModal
            isOpen={isOpen}
            className={classNames('react-modal__content', styles['modal-slider__content'])}
            overlayClassName="react-modal__overlay"
            shouldCloseOnOverlayClick={false}
            bodyOpenClassName={modalOpenBodyClassName}
            onRequestClose={closeModal}
        >
            <form className={styles['modal-slider__form']}>
                <div className={styles['modal-slider__header']}>
                    <h2 className={styles['modal-slider__title']}>{title}</h2>
                    <button type="button" className={styles['modal-slider__close-icon']} aria-label="Close modal" onClick={closeModal}>
                        <CloseIcon />
                    </button>
                </div>

                <Swiper
                    modules={[Pagination, Navigation]}
                    slidesPerView={1}
                    slidesPerGroupAuto
                    navigation={{
                        prevEl: prevButtonRef.current,
                        nextEl: nextButtonRef.current,
                    }}
                    onSwiper={setSwiper}
                    pagination={pagination}
                    onSlideChange={(e) => {
                        setActiveIndex(e.activeIndex);
                    }}
                >
                    {sliderItems.map((item) => (
                        <SwiperSlide key={item.title}>
                            <Image quality={100} src={item.image} />
                        </SwiperSlide>
                    ))}
                </Swiper>
                {sliderItems.length > 1 && (
                    <div className={styles['modal-slider__controls']}>
                        <button type="button" aria-label="Previous Page" ref={prevButtonRef} className={styles['modal-slider__arrow-btn']}>
                            <ChevronLeft />
                        </button>
                        <div className={styles['modal-slider__pagination']} />
                        <button type="button" aria-label="Next Page" ref={nextButtonRef} className={styles['modal-slider__arrow-btn']}>
                            <ChevronRight />
                        </button>
                    </div>
                )}
                <h2 className={styles['modal-slider__body-title']}>{sliderItems?.[activeIndex]?.title}</h2>
                {sliderItems?.[activeIndex]?.isHtml ? <div className={styles['modal-slider__body-text']} dangerouslySetInnerHTML={{ __html: sliderItems?.[activeIndex]?.text }} /> : <div className={styles['modal-slider__body-text']}>{sliderItems?.[activeIndex]?.text}</div>}
                <div className={styles['modal-slider__buttons']}>
                    <div>
                        <div
                            onClick={closeModal}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    closeModal();
                                }
                            }}
                            role="button"
                            tabIndex={0}
                            className={classNames('button button--outline', styles['modal-slider__dimiss'])}>
                            Dismiss
                        </div>
                        {sliderItems?.[activeIndex]?.href !== undefined && (
                            <a href={sliderItems?.[activeIndex]?.href} target="_blank" type="button" className={classNames('button button--solid', styles['modal-slider__learn-more'])} rel="noreferrer">
                                Learn More
                            </a>
                        )}
                        {sliderItems?.[activeIndex]?.href === undefined && activeIndex < ((sliderItems?.length || 1) - 1) && (
                            <div
                                onClick={() => {
                                    swiper?.slideNext();
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        swiper?.slideNext();
                                    }
                                }}
                                role="button"
                                tabIndex={0}
                                className={classNames('button button--solid', styles['modal-slider__learn-more'])}
                            >
                                Next
                            </div>
                        )}
                    </div>
                </div>
            </form>
        </ReactModal>
    );
}
export default ModalSlider;
