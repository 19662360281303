import classNames from 'classnames';

import { QueueItem } from '@bpm-web-app/utils';
import { MouseEventHandler } from 'react';
import styles from './quick-actions-column.module.css';

import AddToPlaylistIcon from '../../../../assets/icons/supreme/add-to-playlist.svg';
import FavoriteHeart from '../../../../assets/icons/supreme/favorites-filled.svg';
import UnfavoriteHeart from '../../../../assets/icons/supreme/favorites-outline.svg';
import ThreeDotsIcon from '../../../../assets/icons/supreme/three-dots.svg';

interface QuickActionsColumnProps {
    media: QueueItem;
    openPlaylists: MouseEventHandler<HTMLButtonElement>;
    openThreeDots: MouseEventHandler<HTMLButtonElement>;
    likeOrUnlike: MouseEventHandler<HTMLButtonElement>;
    isVisible: boolean;
    isAlbumFavorite: boolean | undefined;
}

export function QuickActionsColumn({ media, openThreeDots, openPlaylists, isVisible, likeOrUnlike, isAlbumFavorite }: QuickActionsColumnProps) {
    return (
        <div
            className={classNames(styles['quick-actions-column'], {
                [styles['quick-actions-column--visible']]: isVisible,
            })}
        >
            <button
                className={classNames(styles['quick-actions-column__button'], styles['quick-actions-column__button--tablet-up-only'], 'three-dots-button')}
                type="button"
                onClick={openPlaylists}
                aria-label="Add to Playlist"
                data-tip="Add to Playlist"
                data-tip-show
                data-delay-show="400"
            >
                <AddToPlaylistIcon />
            </button>
            <button
                className={classNames(styles['quick-actions-column__button'], styles['quick-actions-column__button--tablet-up-only'])}
                type="button"
                onClick={likeOrUnlike}
                aria-label={isAlbumFavorite ? 'Remove from Favorites' : 'Add to Favorites'}
                data-tip={isAlbumFavorite ? 'Remove from Favorites' : 'Add to Favorites'}
                data-tip-show
                data-delay-show="400"
            >
                {isAlbumFavorite ? <FavoriteHeart /> : <UnfavoriteHeart />}
            </button>

            <button
                className={classNames(styles['quick-actions-column__button'], styles['quick-actions-column__button--three-dots'], 'three-dots-button')}
                type="button"
                aria-label="More"
                data-tip="Show More"
                data-tip-show
                data-delay-show="400"
                onClick={openThreeDots}
            >
                <ThreeDotsIcon />
            </button>
        </div>
    );
}
