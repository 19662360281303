import { Period, useOtherArtists } from '@bpm-web-app/swr-hooks';
import { appendQueryParams, capitalizeFirstLetter, useUniqueArray, useViewport } from '@bpm-web-app/utils';
import { Fragment, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Swiper as SwiperConfig } from 'swiper'; import classNames from 'classnames';
import Dropdown from '../../../dropdown/dropdown';
import ArtistCardImage from '../../../shared/artist-card-image/artist-card-image';
import NoResultsBlock from '../../../shared/ui/no-results-block/no-results-block';
import { TitleView } from '../title-view/title-view';
import styles from './other-artists.module.css';
import ChevronLeft from '../../../../assets/icons/chevron-left.svg';
import ChevronRight from '../../../../assets/icons/chevron-right.svg';
import CardGhostLoading from '../../../shared/card/card-ghost-loading';
import { ListGridGhostLoading } from '../../../shared/ui/list-grid/list-grid-ghost-loading';
import ListGrid from '../../../shared/ui/list-grid/list-grid';
import { BreakpointView, ListGridItem } from '../../../shared';
import SeeMore from '../../../shared/see-more-button/see-more-btn';

// eslint-disable-next-line @typescript-eslint/dot-notation
const SUPREME_URL = process.env['NEXT_PUBLIC_SUPREME_PLATFORM_URL'];

export function ArtistOtherArtists() {
    const [, setSwiper] = useState<SwiperConfig>();
    const prevButtonRef = useRef<HTMLButtonElement>(null);
    const nextButtonRef = useRef<HTMLButtonElement>(null);
    const [period, setPeriod] = useState<Period>('week');
    const { data, isLoading } = useOtherArtists(period);
    const allPeriods: Period[] = ['day', 'week', 'month'];
    const [seeAll, setSeeAll] = useState(false);

    const titleView = <TitleView
        title="Similar Artists"
        subtitle="The artists your profile is most similar to; based on stats, genre, and download habits."
        rightComponent={
            <div className={styles['other-artists__right-container']}>
                <Dropdown<Period>
                    onClick={(option) => {
                        setPeriod(option);
                    }}
                    value={period}
                    useNewMobileStyle
                    options={[
                        ...allPeriods.map((p) => ({
                            label: capitalizeFirstLetter(p),
                            value: p
                        }))
                    ]}
                />
                <button type="button" aria-label="Previous Page" ref={prevButtonRef} className={classNames(styles['other-artists__arrow-btn'], styles['other-artists__prev-btn'])}>
                    <ChevronLeft />
                </button>
                <button type="button" aria-label="Next Page" ref={nextButtonRef} className={classNames(styles['other-artists__arrow-btn'], styles['other-artists__next-btn'])}>
                    <ChevronRight />
                </button>
            </div>} />;

    const ghostLoadingItems = useUniqueArray(12);
    const { isMobile } = useViewport();

    return (
        <div className={styles['other-artists']}>
            {titleView}
            {isMobile ? (
                <>
                    {isLoading && <ListGridGhostLoading elementsCount={8} isRow itemClass={styles['search-artists__mobile-loading-item']} lineHeight={10} squareClass={styles['search-artists__mobile-loading-item-round']} />}
                    {!isLoading && (
                        <BreakpointView
                            mobileChildren={
                                <ListGrid>
                                    {data?.data.slice(0, seeAll ? undefined : 4).map((artist) => (
                                        <Fragment key={artist.id}>
                                            <ListGridItem
                                                id={artist.id}
                                                title={artist.name}
                                                subtitle=""
                                                rounded
                                                isPlayable={false}
                                                imageUrl={appendQueryParams(artist.artwork_url, { key: 'dw', value: 160 })}
                                                imageUrl2x={appendQueryParams(artist.artwork_url, { key: 'dw', value: 320 })}
                                                link={`${SUPREME_URL}d/artist/${artist.slug}`}
                                            />
                                        </Fragment>
                                    ))}
                                    {seeAll && <SeeMore text="View less" onClick={() => setSeeAll(false)} expand={false} />}
                                    {!seeAll && <SeeMore text="View all" onClick={() => setSeeAll(true)} />}
                                </ListGrid>
                            }
                            desktopChildren={
                                <ListGrid>
                                    {data?.data.map((artist) => (
                                        <Fragment key={artist.id}>
                                            <ListGridItem
                                                id={artist.id}
                                                title={artist.name}
                                                subtitle=""
                                                rounded
                                                isPlayable={false}
                                                imageUrl={appendQueryParams(artist.artwork_url, { key: 'dw', value: 160 })}
                                                imageUrl2x={appendQueryParams(artist.artwork_url, { key: 'dw', value: 320 })}
                                                link={`${SUPREME_URL}d/artist/${artist.slug}`}
                                            />
                                        </Fragment>
                                    ))}
                                </ListGrid>
                            } />

                    )}
                </>
            ) : (
                <>
                    {isLoading && ghostLoadingItems.map((uuid) => (
                        <div key={`card-ghost-${uuid}`} className={styles['other-artists__loading-item']}>
                            <CardGhostLoading
                                cardSize="small"
                                lineHeight={20}
                                linesWidthArray={[80, 40]}
                                imageClass={styles['other-artists__loading-image']}
                                itemClass={styles['other-artists__loading-item-round']}
                            />
                        </div>
                    ))}
                    {!isLoading &&
                        (data?.data && data?.data.length > 0 ? (
                            <Swiper
                                modules={[Navigation]}
                                spaceBetween={16}
                                slidesPerView="auto"
                                slidesPerGroupAuto
                                threshold={8}
                                navigation={{
                                    prevEl: prevButtonRef.current,
                                    nextEl: nextButtonRef.current,
                                }}
                                onSwiper={setSwiper}
                            >
                                {data?.data.map((artist) => (
                                    <SwiperSlide key={artist.id}>
                                        <ArtistCardImage
                                            artist={artist}
                                            link={`${SUPREME_URL}d/artist/${artist.slug}`}
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>

                        ) : (
                            <div className={styles['search-artists-carousel__no-results-section']}>
                                <NoResultsBlock>No Artists Available</NoResultsBlock>
                            </div>
                        ))}
                </>
            )}
        </div>
    );
}
