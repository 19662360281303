import { useFavoriteMedia } from '@bpm-web-app/swr-hooks';
import { Album, DownloadAlbum, Media } from '@bpm-web-app/api-client';
import { useApiErrorHandler, useHubSwitch } from '@bpm-web-app/utils';
import useSWR from 'swr';

export const useFavoriteMultipleMedia = (library?: string) => {
    const { isDownload } = useHubSwitch();
    const errorHandler = useApiErrorHandler();
    const { mutate: mutateFavoriteMedia } = useFavoriteMedia(library);
    const { mutate: mutateFavoriteAlbums } = useSWR<any>(
        [
            'get-favorite-albums',
            {
                isDownload,
                persist: true,
                library,
            },
        ],
        () => Album.getFavoriteAlbums(isDownload, library)
    );

    const addMultipleToFavorites = async (mediaIds: number[]) => {
        try {
            if (isDownload) {
                await mutateFavoriteAlbums(DownloadAlbum.favoriteMultipleAlbums(mediaIds));
            } else {
                await mutateFavoriteMedia(Media.addMultipleMediaToFavorites(mediaIds));
            }
        } catch (error) {
            errorHandler({ error });
        }
    };

    return {
        addMultipleToFavorites,
    };
};

export default useFavoriteMultipleMedia;
