import { Artist } from '@bpm-web-app/stream-api-sdk';
import { generateArtistLinks } from '@bpm-web-app/utils';
import { useCallback } from 'react';
import { AppLink } from '../shared';

export function useArtistLinks() {
    const link = useCallback((artist: Artist) => {
        return (
            <AppLink href={`/artist/${artist.slug}`} key={artist.id}>
                <a
                    data-for="artist-tooltip"
                    data-tip={`${artist.name}|${artist.slug}|${artist.id}|${artist.artwork_url}`}
                    className="underline-link">{artist.name}
                </a>
            </AppLink>
        );
    }, []);

    const separator = useCallback((sep: string, i: number) => <span className="link" key={i}>{sep}</span>, []);

    const generate = (artist: string, artists: Artist[], options?: { sort: boolean }) => {
        return generateArtistLinks(artist, artists, link, separator, options);
    };
    return generate;
}
