import { useRouter } from 'next/router';
import { useEffect, useRef, ReactNode, createContext, useState, useMemo, useCallback } from 'react';

export interface HistoryContextProperties {
    canGoBack: boolean;
    canGoForwards: boolean;
    history: string[];
    historyKey: number;
    goBack: () => void;
    goForwards: () => void;
}

export const HistoryContext = createContext<HistoryContextProperties>({
    canGoBack: false,
    canGoForwards: false,
    history: [],
    historyKey: -1,
    goBack: /* istanbul ignore next */ () => {},
    goForwards: /* istanbul ignore next */ () => {},
});

export const useHistory = () => {
    const trimTrailingSlash = useCallback((str: string) => str.replace(/\/$/, ''), []);

    const router = useRouter();

    const isNavigatingBack = useRef(false);
    const isNavigatingForward = useRef(false);

    const [history, setHistory] = useState([`${router.basePath}${trimTrailingSlash(router.pathname)}`]);
    const [historyKey, setHistoryKey] = useState(0);

    useEffect(() => {
        router.beforePopState((event: any) => {
            if (event.idx < historyKey) {
                isNavigatingBack.current = true;
                isNavigatingForward.current = false;
            } else {
                isNavigatingBack.current = false;
                isNavigatingForward.current = true;
            }
            return true;
        });

        const handleRouteChange = (url: string) => {
            if (isNavigatingForward.current) {
                setHistoryKey(historyKey + 1);
            } else if (isNavigatingBack.current) {
                setHistoryKey(historyKey - 1);
            } else if (url !== history[historyKey]) {
                const historyCp = [...history];
                setHistoryKey(historyKey + 1);
                historyCp.length = historyKey + 1;
                historyCp.push(trimTrailingSlash(url));
                setHistory(historyCp);
            }
            isNavigatingForward.current = false;
            isNavigatingBack.current = false;
        };

        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, historyKey]);

    const historyState = useMemo(
        () => ({
            canGoBack: !!historyKey,
            canGoForwards: historyKey < history.length - 1,
            history,
            historyKey,
            goBack: () => {
                window.history.go(-1);
            },
            goForwards: () => {
                window.history.forward();
            },
        }),
        [history, historyKey]
    );

    return { historyState };
};

export interface HistoryProviderProps {
    children: ReactNode;
}

export function HistoryProvider({ children }: HistoryProviderProps) {
    const { historyState } = useHistory();
    return <HistoryContext.Provider value={historyState}>{children}</HistoryContext.Provider>;
}
