import { Sound as SoundAPI } from '@bpm-web-app/api-client';
import { Sound } from '@bpm-web-app/create-api-sdk';
import { MediaWithAlbum, MediaWithDownloadedDate } from '@bpm-web-app/download-api-sdk';
import { Pagination } from '@bpm-web-app/stream-api-sdk';
import { useDownloadMedia, useGetCredits } from '@bpm-web-app/swr-hooks';
import { fileDownload, useApiErrorHandler } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useContext } from 'react';
import { LibraryTabsContext } from '../../../../../../utils/src/lib/library-tabs.context';
import ChevronLeft from '../../../../assets/icons/chevron-left.svg';
import ChevronRight from '../../../../assets/icons/chevron-right.svg';
import { DateHeader } from './date-header';
import { ListItemCreate, ListItemSupreme } from './list-item';
import styles from './list.module.css';
import { useGroupByDownloadDate } from './useGroupByDownloadDate';

interface DownloadsListProps {
    isCreate: boolean;
    mutateSupreme?: (item: { data: MediaWithDownloadedDate[]; pagination: Pagination }) => void;
    activePage: number;
    onPageChange: (nextPage: number) => void;
    pageSize: number;
    data: { data: MediaWithDownloadedDate[] | Sound[]; pagination: Pagination };
}

export function DownloadsList({
    isCreate,
    mutateSupreme,
    onPageChange,
    pageSize,
    activePage,
    data
}: DownloadsListProps) {
    const { libraryProperty } = useContext(LibraryTabsContext);
    const errorHandler = useApiErrorHandler();
    const { mutate: updateCredits } = useGetCredits(!isCreate);

    const router = useRouter();

    const { downloadSingleMedia: downloadSingleMediaHook, isRequestInProgress, setIsRequestInProgress } = useDownloadMedia(libraryProperty, false);

    const downloadSingleMedia = async (media: MediaWithAlbum | Sound) => {
        try {
            setIsRequestInProgress(true);

            if (isCreate) {
                const { data: downloadMediaUrlData } = await SoundAPI.downloadSound(media.id as string, router.asPath);
                if (downloadMediaUrlData?.url) {
                    fileDownload(downloadMediaUrlData.url);
                    updateCredits();
                }
            } else {
                const { data: downloadMediaUrlData } = await downloadSingleMediaHook(+media.id);

                if (downloadMediaUrlData?.url) {
                    fileDownload(downloadMediaUrlData.url);
                    const mutatedData = data.data.map((downloadMedia) =>
                        downloadMedia.id === media.id
                            ? { ...downloadMedia, download_count: (downloadMedia as MediaWithDownloadedDate).download_count + 1, }
                            : downloadMedia
                    );
                    mutateSupreme?.({ ...data, data: mutatedData });
                }
            }
        } catch (error) {
            errorHandler({ error });
        } finally {
            setIsRequestInProgress(false);
        }
    };

    const groupedMedia = useGroupByDownloadDate(data.data);

    return (
        <div>
            <div>
                <div
                    className={classNames(styles['table__list-header'], {
                        [styles['table__list-header--create']]: isCreate,
                    })}
                >
                    <div className={classNames(styles['table__list-header-column'], styles['table__list-header-column--title'])}>Title</div>
                    <div className={classNames(styles['table__list-header-column'], styles['table__list-header-column--version'])}>Version</div>
                    <div className={classNames(styles['table__list-header-column'], styles['table__list-header-column--download-date'])}>Download Date</div>
                    <div className={classNames(styles['table__list-header-column'], styles['table__list-header-column--sort'])} />
                </div>
                <div className={styles['table__list']}>
                    {Object.entries(groupedMedia || {}).map(([date, media]) => (
                        <div key={date}>
                            <DateHeader date={date} />
                            {media.map((mediaItem) => (
                                isCreate
                                    ? (
                                        <ListItemCreate
                                            key={mediaItem.last_downloaded}
                                            media={mediaItem as Sound}
                                            downloadMedia={() => downloadSingleMedia(mediaItem)}
                                            isDownloadInProgress={isRequestInProgress} />
                                    )
                                    : (
                                        <ListItemSupreme
                                            key={mediaItem.last_downloaded}
                                            media={mediaItem as MediaWithDownloadedDate}
                                            downloadMedia={() => downloadSingleMedia(mediaItem)}
                                            isDownloadInProgress={isRequestInProgress} />
                                    )
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles['table__controls']}>
                {data.pagination && (
                    <div className={styles['table__pagination']}>
                        <span>{`${activePage + 1} of ${Math.ceil(data.pagination.total / data.pagination.limited)}`}</span>
                        <button
                            type="button"
                            aria-label="Previous Page"
                            className={classNames(styles['table__arrow-btn'], styles['table__prev-btn'])}
                            disabled={activePage === 0}
                            onClick={() => onPageChange(activePage - 1)}
                        >
                            <ChevronLeft />
                        </button>
                        <button
                            type="button"
                            aria-label="Next Page"
                            className={classNames(styles['table__arrow-btn'], styles['table__next-btn'])}
                            disabled={data.pagination.total <= (activePage + 1) * pageSize}
                            onClick={() => onPageChange(activePage + 1)}
                        >
                            <ChevronRight />
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}
