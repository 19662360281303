import { FormEvent, useCallback, useState } from 'react';
import { useSession } from '../shared/session-provider/session-provider';
import useGiveaway from '../shared/three-dots-sheet/useGiveaway';
import styles from './comments-form.module.css';
import ProfileIcon from '../../assets/icons/profile-icon.svg';
import useContest from '../shared/three-dots-sheet/useContest';

export interface CommentsFormProps {
    giveawayId?: string;
    contestId?: string;
}

export function CommentsForm({ giveawayId, contestId }: CommentsFormProps) {
    const { postGiveawayComent } = useGiveaway(giveawayId, contestId === undefined);
    const { postContestComent } = useContest(contestId, contestId === undefined);
    const { user: loggedUser } = useSession();
    const [comment, setComment] = useState('');

    const onSubmit = useCallback(
        (e: FormEvent) => {
            e.preventDefault();
            if (giveawayId === undefined) {
                postContestComent(comment, (value: string) => setComment(value));
                return;
            }

            postGiveawayComent(comment, (value: string) => setComment(value));
        },
        [giveawayId, postGiveawayComent, comment, postContestComent]
    );

    return (
        <form onSubmit={onSubmit} className={styles['comments-form']}>
            <div className={styles['comments-form__avatar-wrapper']}>
                {loggedUser?.profile_image_thumbnail_url ? <img src={loggedUser.profile_image_thumbnail_url} alt="" className={styles['comments-form__avatar']} /> : <ProfileIcon />}
            </div>
            <textarea className={styles['comments-form__input']} onChange={(e) => setComment(e.target.value)} value={comment} placeholder="Begin typing..." />
            <div className={styles['comments-form__submit-wrapper']}>
                <button className={styles['comments-form__submit']} type="submit">
                    Post Comment
                </button>
            </div>
        </form>
    );
}

export default CommentsForm;
