import { useDownloadAlbumById } from '@bpm-web-app/swr-hooks';
import {
    downloadAlbumWithMediaToQueueItem,
    getCurrentPlatformLink,
    showToast,
    useHubSwitch,
} from '@bpm-web-app/utils';
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import AddToCrate from '../../assets/icons/add-to-crate.svg';
import AddToQueue from '../../assets/icons/add-to-queue.svg';
import FindSimilar from '../../assets/icons/find-similar.svg';
import { MobileDrawer } from '../mobile-drawer/mobile-drawer';
import { usePlayer } from '../player-context';
import { useCrates } from '../shared/three-dots-sheet/useCrates';
import styles from './versions-mobile-drawer-supreme.module.css';

export function VersionsMobileDrawerSupreme({
    isOpen,
    onClose,
}: {
    isOpen: boolean;
    onClose: () => void;
}) {
    const {
        currentTrack,
        replaceCurrentPlayingTrack,
        addToQueue,
        setIsMaxiPlayer
    } = usePlayer();
    const router = useRouter();
    const { isDownload } = useHubSwitch();

    const { data: downloadAlbumData } = useDownloadAlbumById(currentTrack?.album_id, isDownload);
    const { addMediaToOnlineCrate, addMediaToOfflineCrate } = useCrates();

    const addVersionToCrate = useCallback(
        (versionId) => {
            if (isDownload) {
                addMediaToOnlineCrate(versionId);
            } else {
                addMediaToOfflineCrate(versionId);
            }
        },
        [addMediaToOfflineCrate, addMediaToOnlineCrate, isDownload]
    );

    const addVersionToQueue = useCallback(
        (queueItem) => {
            addToQueue([queueItem]);
            showToast({ type: 'success', message: 'Added to queue.', buttonText: 'Open Queue', onButtonClick: () => setIsMaxiPlayer(true) });
        },
        [addToQueue, setIsMaxiPlayer]
    );

    const onItemClicked = useCallback((index: number) => {
        replaceCurrentPlayingTrack(downloadAlbumWithMediaToQueueItem(downloadAlbumData.data, index));
        onClose();
    }, [downloadAlbumData, onClose, replaceCurrentPlayingTrack]);

    const handleSimilar = useCallback((versionId: number) => {
        router.push(getCurrentPlatformLink(`/similar-media/${versionId}`));
        onClose();
    }, [router, onClose]);

    return (
        <MobileDrawer isOpen={isOpen} onClose={onClose} dynamicHeight>
            <div className={styles['versions-menu']}>
                {isDownload &&
                    downloadAlbumData?.data.media?.map((version, index) => (
                        <div key={version.id} className={styles['versions-menu__item']}>
                            <button
                                type="button"
                                // This should replace current playing track;
                                onClick={() => onItemClicked(index)}
                                aria-label={`Play ${version.name}`}
                                className={styles['versions-menu__item-play']}
                            >
                                <i className="icon icon--play" />
                                <span className={styles['versions-menu__item-title']}>{version.name}</span>
                            </button>
                            <button
                                type="button"
                                className={styles['versions-menu__item-button']}
                                onClick={() => addVersionToQueue(downloadAlbumWithMediaToQueueItem(downloadAlbumData.data, index))}
                            >
                                <AddToQueue />
                            </button>
                            <button type="button" className={styles['versions-menu__item-button']} onClick={() => addVersionToCrate(version.id)}>
                                <AddToCrate />
                            </button>
                            <button type="button" className={styles['versions-menu__item-button']} onClick={() => handleSimilar(version.album_id)}>
                                <FindSimilar />
                            </button>
                        </div>
                    ))}
            </div>
        </MobileDrawer>
    );
}
