import { FC, useMemo } from 'react';

import styles from './my-playlist-checkboxes.module.css';

type Props = {
    text: string;
    query?: string;
};

export const MyPlaylistHightLight: FC<Props> = ({ text, query = '' }) => {
    const Output = useMemo(() => {
        const startIndex = text.toLowerCase().indexOf(query);

        return startIndex >= 0 ? (
            <>
                {text.substring(0, startIndex)}
                <span className={styles['my-playlists-item__highlight']}>{text.substring(startIndex, startIndex + query.length)}</span>
                {text.substring(startIndex + query.length)}
            </>
        ) : (
            <span>{text}</span>
        );
    }, [text, query]);

    return Output;
};
