import { Analytics, showToast, useApiErrorHandler } from '@bpm-web-app/utils';
import { useCallback, useMemo } from 'react';
import { MutatorCallback, MutatorOptions } from 'swr';
import { useFollowCreateCuratedSet, useGetFavoritedCreateCuratedSets, useUnFollowCreateCuratedSet } from './create/curated-set';
import { useFollowLabel, useGetFavoritedLabels, useUnFollowLabel } from './create/label';
import { useFollowSound, useGetFavoritedPresets, useGetFavoritedSounds, useUnFollowSound } from './create/sound';
import { useFollowSoundPackage, useGetFavoritedSoundPackages, useUnFollowSoundPackage } from './create/sound-package';

export type CreateLikeType = 'sound' | 'preset' | 'soundPackage' | 'label' | 'curated';

/**
 * Hook used to facilitate liking and disliking different entities
 */
export function useCreateLike(type: CreateLikeType | undefined) {
    const { data: favoriteCuratedSetsData, mutate: mutateCuratedSets } = useGetFavoritedCreateCuratedSets(undefined, type === 'curated');
    const likeCuratedSets = useFollowCreateCuratedSet();
    const dislikeCuratedSets = useUnFollowCreateCuratedSet();
    const errorHandler = useApiErrorHandler();

    const { data: favoriteLabelData, mutate: mutateLabels } = useGetFavoritedLabels(type === 'label');
    const likeLabel = useFollowLabel();
    const dislikeLabel = useUnFollowLabel();

    const { data: favoriteSoundPackagesData, mutate: mutateSoundPackages } = useGetFavoritedSoundPackages(undefined, type === 'soundPackage');
    const likeSoundPackages = useFollowSoundPackage();
    const dislikeSoundPackages = useUnFollowSoundPackage();

    const { data: favoriteSoundData, mutate: mutateSounds } = useGetFavoritedSounds(undefined, type === 'sound');
    const { data: favoritePresetData, mutate: mutatePresets } = useGetFavoritedPresets(undefined, type === 'preset');
    const likeSound = useFollowSound();
    const dislikeSound = useUnFollowSound();

    const mutationFn = useCallback(
        (data?: any | Promise<any> | MutatorCallback<any>, opts?: boolean | MutatorOptions<any>) => {
            switch (type) {
                case 'curated':
                    return mutateCuratedSets(data, opts);
                case 'label':
                    return mutateLabels(data, opts);
                case 'sound':
                    return mutateSounds();
                case 'preset':
                    return mutatePresets();
                case 'soundPackage':
                    return mutateSoundPackages(data, opts);
                default:
                    return undefined;
            }
        },
        [mutateCuratedSets, mutateLabels, mutateSounds, mutateSoundPackages, mutatePresets, type]
    );

    const likeFn = useCallback(
        (id: string) => {
            switch (type) {
                case 'curated':
                    return likeCuratedSets(id);
                case 'label':
                    return likeLabel(id);
                case 'preset':
                case 'sound':
                    return likeSound(id);
                case 'soundPackage':
                    return likeSoundPackages(id);
                default:
                    return undefined;
            }
        },
        [likeCuratedSets, likeLabel, likeSound, likeSoundPackages, type]
    );

    const dislikeFn = useCallback(
        (id: string) => {
            switch (type) {
                case 'curated':
                    return dislikeCuratedSets(id);
                case 'label':
                    return dislikeLabel(id);
                case 'preset':
                case 'sound':
                    return dislikeSound(id);
                case 'soundPackage':
                    return dislikeSoundPackages(id);
                default:
                    return undefined;
            }
        },
        [dislikeCuratedSets, dislikeLabel, dislikeSound, dislikeSoundPackages, type]
    );

    const likedData = useMemo(() => {
        switch (type) {
            case 'curated':
                return favoriteCuratedSetsData;
            case 'label':
                return favoriteLabelData;
            case 'sound':
                return favoriteSoundData;
            case 'soundPackage':
                return favoriteSoundPackagesData;
            case 'preset':
                return favoritePresetData;
            default:
                return undefined;
        }
    }, [favoriteCuratedSetsData, favoriteLabelData, favoriteSoundData, favoriteSoundPackagesData, favoritePresetData, type]);

    const likedIds = useMemo(() => likedData?.data?.map((favorites) => favorites.id), [likedData?.data]);

    const isLiked = useCallback((id: string): boolean => likedIds?.includes(id) ?? false, [likedIds]);

    const handleLikeDislike = useCallback(
        async (id: string, onlyLike?: boolean, doShowToast = true) => {
            if (!id) return;

            try {
                if (isLiked(id) && !onlyLike) {
                    await dislikeFn(id);
                    if (doShowToast) {
                        showToast({ type: 'success', message: 'Removed from favorites.' });
                    }
                } else {
                    await likeFn(id);
                    Analytics.trackClick('add_to_favorites_album', id);
                    if (doShowToast) {
                        showToast({ type: 'success', message: 'Added to favorites.', buttonText: 'Go To Favorites', replaceRouteOnButtonClick: '/favorites' });
                    }
                }

                mutationFn();
            } catch (error) {
                errorHandler({ error });
            }
        },
        [dislikeFn, errorHandler, isLiked, likeFn, mutationFn]
    );

    return {
        likeDislike: handleLikeDislike,
        /** WIP: this might be needed or not, delete if not needed  */
        likedIds,
        /** The liked data for the type passed */
        likedData,
        /** Function to get if the id is part of the liked   */
        isLiked,
        mutate: mutationFn,
    };
}
