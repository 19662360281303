import { useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { appendQueryParams, convertToPluralIfNeeded, CreateCardCarouselItem, downloadAlbumWithMediaToQueueItem, HLSPlayer, parseSeconds, State as PlayerStateEnum, useHubSwitch, useUserSettings, useViewport } from '@bpm-web-app/utils';
import { useDownloadAlbumById } from '@bpm-web-app/swr-hooks';
import classNames from 'classnames';
import styles from './maxi-player.module.css';
import { usePlayer, usePlayerDuration } from '../player-context';
import { PlayerWave } from '../shared/player-wave/player-wave';
import PlayIcon from '../../assets/icons/player-play.svg';
import PauseIcon from '../../assets/icons/player-pause.svg';
import PrevIcon from '../../assets/icons/player-prev.svg';
import NextIcon from '../../assets/icons/player-next.svg';
import PlayIconAlt from '../../assets/icons/player-play-alt.svg';
import PauseIconAlt from '../../assets/icons/player-pause-alt.svg';
import ShuffleIcon from '../../assets/icons/player-shuffle.svg';
import RepeatIcon from '../../assets/icons/player-repeat.svg';
import RepeatOneIcon from '../../assets/icons/player-repeat-one.svg';
import FavoriteHeart from '../../assets/icons/favorite-heart.svg';
import UnfavoriteHeart from '../../assets/icons/favorite-heart-outline.svg';
import AddToCrateIcon from '../../assets/icons/add-to-crate.svg';
import AddToPlaylistIcon from '../../assets/icons/add-to-playlist.svg';
import PlayerQueue from '../player-queue/player-queue';
import { FeaturedIn } from '../featured-in/featured-in';
import SimilarMediaDetail from '../pages/similar-media-detail/similar-media-detail';
import { ThreeDotsSheetContext } from '../shared/three-dots-sheet/three-dots-sheet.context';
import useFavoriteMedia from '../shared/three-dots-sheet/useFavoriteMedia';
import { LibraryTabsContext } from '../../../../utils/src/lib/library-tabs.context';
import MaxiPlayerPlaylist from '../maxi-player-playlist/maxi-player-playlist';
import { useUserPlaylistsDetails } from '../shared/three-dots-sheet/useUserPlaylistsDetails';
import { useCrates } from '../shared/three-dots-sheet/useCrates';
import ChevronLeft from '../../assets/icons/chevron-left.svg';
import VersionsDropdown from '../versions-dropdown/versions-dropdown';
import { NavContext } from '../nav/nav.context';
import { useBpmLink, useGenreLinks, useKeyLinks } from '../generate-link/generate-link';
import { useArtistLinks } from '../artist-link/artist-link';
import { ThreeDotsButton } from '../shared/three-dots-button/three-dots-button';

export interface MaxiPlayerProps {
    playerState: PlayerStateEnum;
    setIsVersionMenuOpen: (isOpen: boolean) => void;
}

export function MaxiPlayer({ playerState, setIsVersionMenuOpen }: MaxiPlayerProps) {
    const {
        repeat,
        currentTrack,
        isShuffling,
        isRepeating,
        hasNextTrack,
        hasPrevTrack,
        nextTrack,
        prevTrack,
        isMaxiPlayer,
        setIsMaxiPlayer,
        toggleShuffle,
        toggleRepeat,
        showQueue,
        showFeaturedIn,
        showSimilarTracks,
        setShowQueue,
        setShowFeaturedIn,
        setShowSimilarTracks,
        replaceCurrentPlayingTrack,
        onPlay,
        onPause,
        isPreviewTrack,
    } = usePlayer();

    const { elapsed } = usePlayerDuration();

    const { addMediaToOnlineCrate, addMediaToOfflineCrate } = useCrates();
    const generateArtistLinks = useArtistLinks();

    const { isDownload } = useHubSwitch();
    const { isMobile } = useViewport();
    const { openThreeDotsModalSheet } = useContext(ThreeDotsSheetContext);
    const { isNavOpen, closeNav } = useContext(NavContext);

    const [showPlaylists, setShowPlaylists] = useState(false);
    const { data: downloadAlbumData } = useDownloadAlbumById(currentTrack?.album_id, isDownload);

    const { isAnonymous, setSelectedMedia, setShowSignUpModal } = useUserSettings();

    const setCurrentMediaInContext = useCallback((showSignUpModal?: boolean) => {
        if (downloadAlbumData?.data) {
            const currentCenterMedia = downloadAlbumData?.data;
            if (currentCenterMedia) setSelectedMedia(currentCenterMedia as unknown as CreateCardCarouselItem, [downloadAlbumData?.data] as unknown as CreateCardCarouselItem[]);
            if (showSignUpModal) {
                setShowSignUpModal({ type: 'track' });
            }
        }
    }, [downloadAlbumData?.data, setSelectedMedia, setShowSignUpModal]);

    useEffect(() => {
        setCurrentMediaInContext();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAnonymous]);

    const openThreeDots = useCallback(
        async (element) => {
            const { top: topPos, left: leftPos } = (element.target as HTMLButtonElement).getBoundingClientRect();

            openThreeDotsModalSheet('maxi-player', currentTrack?.id, leftPos, topPos + window.scrollY, false, currentTrack.album_id);
        },
        [openThreeDotsModalSheet, currentTrack]
    );

    useEffect(() => {
        setShowQueue(!isMobile);
        setShowFeaturedIn(false);
        setShowSimilarTracks(false);
    }, [isMobile, setShowFeaturedIn, setShowQueue, setShowSimilarTracks]);

    /* if nav is open when opening a maxi player, close it */
    useEffect(() => {
        if (isNavOpen && isMaxiPlayer) closeNav();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMaxiPlayer]);

    const { libraryProperty } = useContext(LibraryTabsContext);

    const { addToFavorites, removeFromFavorites, isMediaFollowed } = useFavoriteMedia(currentTrack?.id, libraryProperty);

    const addToCrate = useCallback(() => {
        if (isAnonymous) {
            setCurrentMediaInContext(true);
            return;
        }
        if (isDownload) {
            addMediaToOnlineCrate(currentTrack?.id);
        } else {
            addMediaToOfflineCrate(currentTrack?.id);
        }
    }, [addMediaToOfflineCrate, addMediaToOnlineCrate, currentTrack?.id, isAnonymous, isDownload, setCurrentMediaInContext]);

    const handleShowPlaylists = useCallback(() => {
        if (isAnonymous) {
            setCurrentMediaInContext(true);
            return;
        }

        setShowPlaylists(true);
    }, [isAnonymous, setCurrentMediaInContext]);

    const likeOrUnlike = useCallback(() => {
        if (isAnonymous) {
            setCurrentMediaInContext(true);
            return;
        }
        if (isMediaFollowed) {
            removeFromFavorites();
        } else {
            addToFavorites();
        }
    }, [addToFavorites, isAnonymous, isMediaFollowed, removeFromFavorites, setCurrentMediaInContext]);

    const { addMediaToPlaylist, addAlbumToPlaylist } = useUserPlaylistsDetails();
    const generateBPMLinks = useBpmLink();
    const generateKeyLinks = useKeyLinks();
    const generateGenreLinks = useGenreLinks();

    const addToPlaylist = useCallback(
        (playlistId: string) => {
            if (isAnonymous) {
                setCurrentMediaInContext(true);
                return;
            }

            if (isDownload) {
                addAlbumToPlaylist(playlistId, {
                    albums: [
                        {
                            album_id: Number(currentTrack?.album_id),
                            order: 0,
                        },
                    ],
                });
            } else {
                addMediaToPlaylist(playlistId, {
                    media: [
                        {
                            media_id: Number(currentTrack?.id),
                            order: 0,
                        },
                    ],
                });
            }
            setShowPlaylists(false);
        },
        [isAnonymous, isDownload, setCurrentMediaInContext, addAlbumToPlaylist, currentTrack?.album_id, currentTrack?.id, addMediaToPlaylist]
    );

    const options = useMemo(
        () => [
            {
                label: 'Play Queue',
                icon: 'queue',
                isActive: showQueue,
                onClick: () => setShowQueue(true),
            },
            {
                label: 'Similar Tracks',
                icon: 'find-similar',
                isActive: showSimilarTracks,
                onClick: () => setShowSimilarTracks(true),
            },
            {
                label: 'Featured In',
                icon: 'featured-in',
                isActive: showFeaturedIn,
                onClick: () => setShowFeaturedIn(true),
            },
            {
                label: 'Minimize',
                icon: 'chevron-down',
                isActive: false,
                onClick: () => {
                    setIsMaxiPlayer(false);
                },
            },
        ],
        [setIsMaxiPlayer, setShowFeaturedIn, setShowQueue, setShowSimilarTracks, showFeaturedIn, showQueue, showSimilarTracks]
    );

    return (
        <div className={classNames(styles['maxi-player'], 'spacing__window')}>
            {!isMobile ? (
                <div className={styles['maxi-player__desktop']}>
                    <div className={styles['maxi-player__desktop-wrapper']}>
                        <h1 className={styles['maxi-player__desktop-breadcrumb']}>Now Playing</h1>
                        <div className={styles['maxi-player__desktop-menu']}>
                            {options.map((item) => (
                                <button
                                    onClick={item.onClick}
                                    key={item.label}
                                    className={classNames(styles['maxi-player__desktop-menu-item'], item.isActive && styles['maxi-player__desktop-menu-item--active'])}
                                    type="button"
                                >
                                    {item.icon === 'chevron-down' ? item.label : null}
                                    <i className={classNames(`icon icon--${item.icon}`, item.isActive && 'icon--light')} />
                                    {item.icon !== 'chevron-down' ? item.label : null}
                                </button>
                            ))}
                        </div>
                        <div className={styles['maxi-player__desktop-cover-wrapper']}>
                            <img
                                src={appendQueryParams(currentTrack.cover_url, { key: 'dw', value: 317 })}
                                srcSet={`${appendQueryParams(currentTrack.cover_url, { key: 'dw', value: 634 })} 2x`}
                                alt={currentTrack.title}
                                className={styles['maxi-player__desktop-cover']}
                            />
                        </div>
                        <div className={styles['maxi-player__desktop-player']}>
                            <div className={styles['maxi-player__desktop-player-menu']}>
                                {/* @TODO - This can call the API with the currentTrack.album_id
              and get all versions from there */}
                                {isDownload && currentTrack.versions && currentTrack.versions - 1 > 0 && (
                                    <VersionsDropdown<number>
                                        onClick={(version) => {
                                            replaceCurrentPlayingTrack(downloadAlbumWithMediaToQueueItem(downloadAlbumData.data, version));
                                        }}
                                        openThreeDotsMenu={(versionId, left, top) => {
                                            setCurrentMediaInContext();
                                            openThreeDotsModalSheet('maxi-player-versions', versionId, left - 220, top - 100, false, currentTrack?.album_id);
                                        }}
                                        placeholder={convertToPluralIfNeeded(currentTrack.versions, 'Version')}
                                        options={downloadAlbumData?.data.media.map((media, index) => ({
                                            versionName: media.version.name,
                                            versionId: media.id,
                                            versionIndex: index,
                                            versionDuration: parseSeconds(media.estimated_duration || 0),
                                        }))}
                                        flipOptionsContainer
                                        currentVersion={currentTrack.version}
                                    />
                                )}
                                <div className="spacing--left"><ThreeDotsButton hasTooltip onClick={openThreeDots} /></div>
                            </div>
                            <h1 className={styles['maxi-player__desktop-player-title']}>{currentTrack.title}</h1>
                            <h1 className={styles['maxi-player__desktop-player-subtitle']}>
                                {generateArtistLinks(currentTrack.artist, currentTrack.artists)}
                            </h1>
                            <div className={styles['maxi-player__desktop-meta-items']}>
                                <span className={styles['maxi-player__mobile-meta-item']}>
                                    {generateBPMLinks(currentTrack.bpm)}
                                </span>
                                <span className={styles['maxi-player__mobile-meta-item']}>
                                    {generateKeyLinks(currentTrack.actual_key, currentTrack.display_key)}
                                </span>
                                <span className={styles['maxi-player__mobile-meta-item']}>
                                    {generateGenreLinks(currentTrack.genre)}
                                </span>
                            </div>
                            <div className={styles['maxi-player__desktop-wave']}>
                                <PlayerWave variant="maxi-desktop" />
                            </div>
                            <div className={styles['maxi-player__desktop-player-ctrls-wrapper']}>
                                <div className={styles['maxi-player__desktop-elapsed']}>{parseSeconds(elapsed)}</div>
                                <div className={styles['maxi-player__desktop-player-ctrls']}>
                                    <button
                                        className={classNames(styles['maxi-player__desktop-player-ctrl'], styles['maxi-player__desktop-player-ctrl--shuffle'], {
                                            [styles['maxi-player__desktop-player-ctrl--active']]: isShuffling,
                                        })}
                                        type="button"
                                        aria-label="Shuffle"
                                        onClick={toggleShuffle}
                                    >
                                        <ShuffleIcon />
                                    </button>
                                    <button
                                        className={classNames(styles['maxi-player__desktop-player-ctrl'], styles['maxi-player__desktop-player-ctrl--prev'])}
                                        type="button"
                                        onClick={prevTrack}
                                        disabled={!hasPrevTrack}
                                        aria-label="Previous Track"
                                    >
                                        <PrevIcon />
                                    </button>
                                    <button
                                        onClick={() => {
                                            if (playerState === PlayerStateEnum.Playing) {
                                                onPause();
                                            } else {
                                                onPlay();
                                            }
                                        }}
                                        className={classNames(styles['maxi-player__desktop-player-ctrl-button'], {
                                            [styles['maxi-player__desktop-player-ctrl--play']]: playerState !== PlayerStateEnum.Playing,
                                            [styles['maxi-player__desktop-player-ctrl--pause']]: playerState === PlayerStateEnum.Playing,
                                        })}
                                        type="button"
                                        aria-label={playerState === PlayerStateEnum.Playing ? 'Pause' : 'Play'}
                                    >
                                        {playerState === PlayerStateEnum.Playing ? <PauseIcon /> : <PlayIcon />}
                                    </button>
                                    <button
                                        className={classNames(styles['maxi-player__desktop-player-ctrl'], styles['maxi-player__desktop-player-ctrl--next'])}
                                        type="button"
                                        onClick={nextTrack}
                                        disabled={!hasNextTrack}
                                        aria-label="Next Track"
                                    >
                                        <NextIcon />
                                    </button>
                                    <button
                                        className={classNames(styles['maxi-player__desktop-player-ctrl'], styles['maxi-player__desktop-player-ctrl--repeat'], {
                                            [styles['maxi-player__desktop-player-ctrl--active']]: isRepeating,
                                        })}
                                        type="button"
                                        aria-label="Repeat"
                                        onClick={toggleRepeat}
                                    >
                                        {(repeat === 'off' || repeat === 'all') && <RepeatIcon />}
                                        {repeat === 'one' && <RepeatOneIcon />}
                                    </button>
                                </div>
                                <div className={styles['maxi-player__desktop-duration']}>{parseSeconds(isPreviewTrack ? currentTrack.estimated_duration : HLSPlayer.getDuration())}</div>
                            </div>
                        </div>
                        <div className={styles['maxi-player__desktop-up-next']}>
                            {showQueue ? <PlayerQueue /> : null}

                            {showSimilarTracks ? <SimilarMediaDetail isMaxiPlayer mediaId={isDownload ? currentTrack?.album_id : currentTrack?.id} /> : null}

                            {showFeaturedIn ? <FeaturedIn albumId={currentTrack?.album_id} /> : null}
                        </div>
                    </div>
                </div>
            ) : (
                <div className={styles['maxi-player__mobile']}>
                    {showPlaylists ? (
                        <div className={styles['maxi-player__mobile-overlay']}>
                            <MaxiPlayerPlaylist closePlaylistOverlay={() => setShowPlaylists(false)} addToPlaylist={addToPlaylist} />
                        </div>
                    ) : null}

                    {showQueue ? (
                        <div className={styles['maxi-player__mobile-overlay']}>
                            <button className={styles['maxi-player__mobile-menu-item']} type="button" aria-label="Back" onClick={() => setShowQueue(false)}>
                                <ChevronLeft />
                                <span>Back</span>
                            </button>
                            <PlayerQueue />
                        </div>
                    ) : null}

                    {showSimilarTracks ? (
                        <div className={styles['maxi-player__mobile-overlay']}>
                            <button className={styles['maxi-player__mobile-menu-item']} type="button" aria-label="Back" onClick={() => setShowSimilarTracks(false)}>
                                <ChevronLeft />
                                <span>Back</span>
                            </button>
                            <SimilarMediaDetail isMaxiPlayer mediaId={isDownload ? currentTrack?.album_id : currentTrack?.id} />
                        </div>
                    ) : null}

                    {showFeaturedIn && (
                        <div className={styles['maxi-player__mobile-overlay']}>
                            <button className={styles['maxi-player__mobile-menu-item']} type="button" aria-label="Back" onClick={() => setShowFeaturedIn(false)}>
                                <ChevronLeft />
                                <span>Back</span>
                            </button>
                            <FeaturedIn albumId={currentTrack?.album_id} />
                        </div>
                    )}

                    <div className={styles['maxi-player__mobile-menu']}>
                        <div className={styles['maxi-player__mobile-menu__column']}>
                            <button onClick={() => setIsMaxiPlayer(false)} className={styles['maxi-player__minimize']} type="button" aria-label="Switch to mini player">
                                <i className="icon icon--chevron-down icon--light" />
                            </button>
                            <button className={styles['maxi-player__mobile-menu-item']} type="button" aria-label="Play Queue" onClick={() => setShowQueue(true)}>
                                <span>Play Queue</span>
                                <i className="icon icon--queue icon--light" />
                            </button>
                        </div>

                        <div className={styles['maxi-player__mobile-menu-items']}>
                            <button className={styles['maxi-player__mobile-menu-item']} type="button" aria-label="Featured In" onClick={() => setShowFeaturedIn(true)}>
                                <span>Featured In</span>
                                <i className="icon icon--featured-in icon--light" />
                            </button>
                        </div>
                    </div>
                    <div className={styles['maxi-player__mobile-cover-wrapper']}>
                        <img
                            src={appendQueryParams(currentTrack.cover_url, { key: 'dw', value: 480 })}
                            srcSet={`${appendQueryParams(currentTrack.cover_url, { key: 'dw', value: 960 })} 2x`}
                            alt={currentTrack.title}
                            className={styles['maxi-player__mobile-cover']}
                        />
                    </div>

                    <div className={styles['maxi-player__mobile-title-container']}>
                        <h1 className={styles['maxi-player__mobile-title']}>{currentTrack.title}</h1>
                        <ThreeDotsButton hasTooltip onClick={openThreeDots} />
                    </div>

                    <h2 className={styles['maxi-player__mobile-subtitle']}>
                        {generateArtistLinks(currentTrack.artist, currentTrack.artists)}
                    </h2>
                    <div className={styles['maxi-player__mobile-meta']}>
                        <div className={styles['maxi-player__mobile-meta-items']}>
                            <span className={styles['maxi-player__mobile-meta-item']}>
                                {generateBPMLinks(currentTrack.bpm)}
                            </span>
                            <span className={styles['maxi-player__mobile-meta-item']}>
                                {generateKeyLinks(currentTrack.actual_key, currentTrack.display_key)}
                            </span>
                            <span className={styles['maxi-player__mobile-meta-item']}>
                                {generateGenreLinks(currentTrack.genre)}
                            </span>
                        </div>
                        {isDownload && currentTrack.versions && currentTrack.versions - 1 > 0 && (
                            <button onClick={() => setIsVersionMenuOpen(true)} type="button" className={styles['maxi-player__mobile-other-versions']}>
                                {convertToPluralIfNeeded(currentTrack.versions, 'Version')}
                            </button>
                        )}
                    </div>

                    <div className={styles['maxi-player__mobile-wave']}>
                        <div className={styles['maxi-player__mobile-wave-wrapper']}>
                            <PlayerWave variant="maxi-mobile" />
                        </div>
                        <div className={styles['maxi-player__elapsed']}>{parseSeconds(elapsed)}</div>
                        <div className={styles['maxi-player__duration']}>{parseSeconds(isPreviewTrack ? currentTrack.estimated_duration : HLSPlayer.getDuration())}</div>
                    </div>

                    <div className={styles['maxi-player__mobile-player-ctrls']}>
                        <button
                            className={classNames(styles['maxi-player__mobile-player-ctrl'], styles['maxi-player__mobile-player-ctrl--shuffle'], {
                                [styles['maxi-player__mobile-player-ctrl--active']]: isShuffling,
                            })}
                            type="button"
                            aria-label="Shuffle"
                            onClick={toggleShuffle}
                        >
                            <ShuffleIcon />
                        </button>
                        <button
                            className={classNames(styles['maxi-player__mobile-player-ctrl'], styles['maxi-player__mobile-player-ctrl--prev'], styles['maxi-player__mobile-player-ctrl--active'])}
                            type="button"
                            onClick={prevTrack}
                            disabled={!hasPrevTrack}
                            aria-label="Previous Track"
                        >
                            <PrevIcon />
                        </button>
                        <button
                            onClick={() => {
                                if (playerState === PlayerStateEnum.Playing) {
                                    onPause();
                                } else {
                                    onPlay();
                                }
                            }}
                            className={classNames(styles['maxi-player__mobile-player-ctrl'], styles['maxi-player__mobile-player-ctrl--active'], {
                                [styles['maxi-player__mobile-player-ctrl--play']]: playerState !== PlayerStateEnum.Playing,
                                [styles['maxi-player__mobile-player-ctrl--pause']]: playerState === PlayerStateEnum.Playing,
                            })}
                            type="button"
                            aria-label={playerState === PlayerStateEnum.Playing ? 'Pause' : 'Play'}
                        >
                            {playerState === PlayerStateEnum.Playing ? <PauseIconAlt /> : <PlayIconAlt />}
                        </button>
                        <button
                            className={classNames(styles['maxi-player__mobile-player-ctrl'], styles['maxi-player__mobile-player-ctrl--next'], styles['maxi-player__mobile-player-ctrl--active'])}
                            type="button"
                            onClick={nextTrack}
                            disabled={!hasNextTrack}
                            aria-label="Next Track"
                        >
                            <NextIcon />
                        </button>
                        <button
                            className={classNames(styles['maxi-player__mobile-player-ctrl'], styles['maxi-player__mobile-player-ctrl--repeat'], {
                                [styles['maxi-player__mobile-player-ctrl--active']]: isRepeating,
                            })}
                            type="button"
                            aria-label="Repeat"
                            onClick={toggleRepeat}
                        >
                            {(repeat === 'off' || repeat === 'all') && <RepeatIcon />}
                            {repeat === 'one' && <RepeatOneIcon />}
                        </button>
                    </div>

                    <div className={classNames(styles['maxi-player__mobile-player-ctrls'], styles['maxi-player__mobile-player-ctrls--bar'])}>
                        <button className={classNames(styles['maxi-player__mobile-player-ctrl'])} type="button" aria-label="Add to Crate" onClick={addToCrate}>
                            <AddToCrateIcon />
                        </button>
                        <button className={classNames(styles['maxi-player__mobile-player-ctrl'])} type="button" onClick={likeOrUnlike} aria-label="Like">
                            {isMediaFollowed ? <FavoriteHeart /> : <UnfavoriteHeart />}
                        </button>
                        {!isDownload ? (
                            <button className={classNames(styles['maxi-player__mobile-player-ctrl'])} type="button" onClick={handleShowPlaylists} aria-label="Add to Playlist">
                                <AddToPlaylistIcon />
                            </button>
                        ) : null}
                    </div>
                </div>
            )}
        </div>
    );
}
