import { useManagingArtist } from '@bpm-web-app/swr-hooks';
import classNames from 'classnames';
import { useMemo } from 'react';

import { ReactComponent as BPMIcon } from '../../assets/icons/bpm-artist-platform-logo-yellow.svg';
import { Divider } from '../divider/divider';
import styles from './artist-portal-header.module.css';

export function ArtistPortalHeader() {
    const { data } = useManagingArtist();
    const artist = useMemo(() => data?.data, [data]);

    return (
        <div>
            <div className={classNames(styles['artist-portal-header__text-container'])}>
                <div className={classNames(styles['artist-portal-header__header-logo'])}>
                    <BPMIcon className={styles['artist-portal-header__logo']} />
                </div>
                <h2 className={classNames(styles['artist-portal-header__header-main-text'])}>
                    {artist && artist.name ? `Welcome, ${artist.name}` : 'Welcome'}
                </h2>
                <div>
                    <h3 className={classNames(styles['artist-portal-header__header-text'])}>
                        Stats and analytics based on tracks available on BPM Supreme.
                    </h3>
                </div>
            </div>
            <Divider largePadding />
        </div>
    );
}

export default ArtistPortalHeader;
