import { FC, InputHTMLAttributes } from 'react';

import SearchIcon from './search-icon.svg';
import ClearIcon from './clear-icon.svg';
import styles from './search.module.css';

type Props = InputHTMLAttributes<HTMLInputElement> & {
    onClear: () => void;
};

export const Search: FC<Props> = ({ onClear, value, ...props }) => (
    <div className={styles['search__container']}>
        <input type="text" {...props} value={value} className={styles['search__input']} />
        <SearchIcon className={styles['search__icon']} />
        {!!value && (
            <button type="button" onClick={onClear} className={styles['search__clear']}>
                <ClearIcon className={styles['search__clear-icon']} />
            </button>
        )}
    </div>
);
