import { useLibraryTabs } from '@bpm-web-app/components';
import { showToast, useHubSwitch, useViewport } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { useCallback } from 'react';
import BPMIcons from '../bpm-icons/bpm-icons';
import { SquaredButton } from '../squared-button/squared-button';
import { ActionType, ThreeDotsSheetContextProperties } from '../three-dots-sheet/three-dots-sheet.context';
import styles from './share-url.module.css';
import { CustomIcon } from '../custom-icon/custom-icon';

interface ShareURLProps {
    variant?: 'default' | 'squared' | 'arrow-icon';
    url?: string;
    label?: string;
    iconSide?: 'left' | 'right';
    slug?: string;
    currentUrl?: boolean;
    actionType?: ActionType;
    actionTypeId?: ThreeDotsSheetContextProperties['actionTypeId'];
    secondaryActionTypeId?: number | string | null;
    customTooltip?: string;
}

export const openSharingOptions = (url: string, title?: string, text?: string) => {
    if (navigator.share) {
        navigator
            .share({
                url,
                title,
                text,
            })
            .catch(() => null);
    } else {
        showToast({ type: 'error', message: 'Unavailable. Please try again later.' });
    }
};

export function ShareURL({ variant = 'default', url, slug, label, iconSide = 'left', currentUrl = false, actionType, actionTypeId, secondaryActionTypeId, customTooltip }: ShareURLProps) {
    const { isMobile } = useViewport();
    const { library } = useLibraryTabs();
    const { isDownload } = useHubSwitch();
    const text = label || 'Copy Link';

    const desktopCopyUrl = useCallback((shareURL: string) => {
        navigator.clipboard?.writeText(shareURL);
        showToast({ type: 'success', message: 'Copied to clipboard.' });
    }, []);

    const mobileCopyUrl = useCallback((shareURL: string) => {
        // MARK: Check if browser supports sharing.
        if (navigator.share !== undefined) {
            openSharingOptions(shareURL);
        } else {
            desktopCopyUrl(shareURL);
        }
    }, [desktopCopyUrl]);

    const getShareURL = useCallback((): string | undefined => {
        switch (actionType) {
            case 'stream:album': {
                return `/s/album/${actionTypeId}?library=${library}`;
            }
            case 'artist': {
                if (isDownload) {
                    return `/d/artist/${actionTypeId}?library=${library}`;
                }
                return `/s/artist/${actionTypeId}?library=${library}`;
            }
            case 'curatedSet': {
                if (isDownload) {
                    return `/d/curated-set/${actionTypeId}?library=${library}`;
                }
                return `/s/curated-set/${actionTypeId}?library=${library}`;
            }
            case 'for-you-playlist':
            case 'user-playlist': {
                if (isDownload) {
                    return `/d/my-playlist/${actionTypeId}?library=${library}`;
                }
                return `/s/my-playlist/${actionTypeId}?library=${library}`;
            }
            case 'user-playlist-track': {
                if (isDownload) {
                    return `/d/my-playlist/${secondaryActionTypeId}?library=${library}`;
                }
                return `/s/my-playlist/${secondaryActionTypeId}?library=${library}`;
            }
            case 'exclusive-playlist': {
                if (isDownload) {
                    return `/d/exclusive-playlist/${actionTypeId}?library=${library}`;
                }
                return `/s/exclusive-playlist/${actionTypeId}?library=${library}`;
            }
            case 'queue-track':
            case 'track': {
                if (isDownload) {
                    return `/d/album/${actionTypeId}?library=${library}`;
                }
                return `/s/album/${actionTypeId}?library=${library}`;
            }
            case 'download:album': {
                return `/d/album/${actionTypeId}?library=${library}`;
            }
            case 'download:track': {
                if (isDownload) {
                    return `/d/album/${actionTypeId}?library=${library}`;
                }
                return `/s/album/${actionTypeId}?library=${library}`;
            }
            case 'maxi-player': {
                if (isDownload) {
                    return `/d/album/${secondaryActionTypeId}?library=${library}`;
                }
                return `/s/album/${secondaryActionTypeId}?library=${library}`;
            }
            case 'maxi-player-versions':
            case 'mini-player-versions': {
                if (isDownload) {
                    return `/d/album/${secondaryActionTypeId}?library=${library}`;
                }
                return `/s/album/${actionTypeId}?library=${library}`;
            }
            case 'mini-player': {
                if (isDownload) {
                    return `/d/album/${secondaryActionTypeId}?library=${library}`;
                }
                return `/s/album/${actionTypeId}?library=${library}`;
            }
            default: {
                return '';
            }
        }
    }, [actionType, actionTypeId, isDownload, library, secondaryActionTypeId]);

    const copyUrl = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        let shareURL = url;

        if (slug) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            shareURL = process.env['NEXT_PUBLIC_RELEASE_STAGE'] === 'local' ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}${slug}` : `${window.location.protocol}//${window.location.hostname}${slug}`;
        }

        if (currentUrl) {
            shareURL = window.location.href;
        }

        if (actionType && (actionTypeId || secondaryActionTypeId)) {
            const newSlug = getShareURL();
            // eslint-disable-next-line @typescript-eslint/dot-notation
            shareURL = process.env['NEXT_PUBLIC_RELEASE_STAGE'] === 'local' ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}${newSlug}` : `${window.location.protocol}//${window.location.hostname}${newSlug}`;
        }

        if (shareURL) {
            if (isMobile) mobileCopyUrl(shareURL);
            else desktopCopyUrl(shareURL);
        }
    }, [url, slug, currentUrl, actionType, actionTypeId, secondaryActionTypeId, getShareURL, isMobile, mobileCopyUrl, desktopCopyUrl]);

    switch (variant) {
        case 'default':
            return (
                <button type="button" className={classNames({ [styles[`share-url__${variant}`]]: variant })} onClick={copyUrl}>
                    <CustomIcon type="share-url-icon" hasIconHover hasBackgroundHover container="round" containerSize="regular" tooltip={customTooltip || 'Copy Link'} />
                </button>
            );
        case 'squared':
            return (
                <SquaredButton
                    type="outline"
                    svgColoring="fill"
                    leftIcon={iconSide === 'left' ? <BPMIcons.ShareIcon /> : undefined}
                    rightIcon={iconSide === 'right' ? <BPMIcons.ShareIcon /> : undefined}
                    label={text}
                    onPress={copyUrl} />
            );
        case 'arrow-icon':
            return (
                <button type="button" className={classNames({ [styles[`share-url__${variant}`]]: variant })} onClick={copyUrl}>
                    <CustomIcon type="share-arrow-icon" hasIconHover />
                </button>
            );
        default:
            return (
                <button type="button" className={classNames({ [styles[`share-url__${variant}`]]: variant })} onClick={copyUrl}>
                    <CustomIcon type="share-url-icon" hasIconHover hasBackgroundHover container="round" containerSize="regular" tooltip={customTooltip || 'Copy Link'} />
                </button>
            );
    }
}
