import classNames from 'classnames';
import { useEffect, useState } from 'react';
import styles from './loading-spinner.module.css';

interface LoadingSpinnerProps {
    isLoading?: boolean;
}

export function LoadingSpinner({ isLoading }: LoadingSpinnerProps) {
    return (
        <div className={classNames(styles['loading-spinner__container'], isLoading ? styles['loading-spinner__container--visible'] : '')}>
            <div className={styles['loading-spinner']}>
                <div className={styles['bar-1']} />
                <div className={styles['bar-2']} />
                <div className={styles['bar-3']} />
                <div className={styles['bar-4']} />
                <div className={styles['bar-5']} />
                <div className={styles['bar-6']} />
                <div className={styles['bar-7']} />
                <div className={styles['bar-8']} />
                <div className={styles['bar-9']} />
                <div className={styles['bar-10']} />
                <div className={styles['bar-11']} />
                <div className={styles['bar-12']} />
            </div>
        </div>
    );
}
