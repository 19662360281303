import { MouseEvent } from 'react';
import BPMIcons from '../bpm-icons/bpm-icons';
import { SquaredButton } from '../squared-button/squared-button';
import styles from './play-button.module.css';
import { CustomIcon } from '../custom-icon/custom-icon';

interface PlayButtonProps {
    type?: 'play' | 'play-red' | 'download' | 'demo';
    isPlaying?: boolean;
    removeHover?: boolean;
    onPress?: (e: MouseEvent<HTMLElement>) => void;
    fullWidth?: boolean;
    variant?: 'squared' | 'round';
}

export function PlayButton({ type = 'play', isPlaying, removeHover, onPress, fullWidth, variant = 'squared' }: PlayButtonProps) {
    const squaredButton = () => {
        switch (type) {
            case 'demo':
            case 'play':
                return <SquaredButton type="dynamic" leftIcon={isPlaying ? <BPMIcons.PauseIcon /> : <BPMIcons.PlayIcon />} label={isPlaying ? type === 'demo' ? 'Pause Demo' : 'Pause' : type === 'demo' ? 'Play Demo' : 'Play'} onPress={onPress} removeHover={removeHover} fullWidth={fullWidth} />;
            case 'play-red':
                return <SquaredButton type="colored-red" leftIcon={isPlaying ? <BPMIcons.PauseIcon /> : <BPMIcons.PlayIcon />} label={isPlaying ? 'Pause' : 'Play'} onPress={onPress} removeHover={removeHover} fullWidth={fullWidth} />;
            default:
                return null;
        }
    };

    const roundButton = () => {
        switch (type) {
            case 'play':
                return (
                    <button type="button" className={styles['play-button--play-btn']} aria-label="Play/Pause" onClick={onPress}>
                        {!isPlaying ? <CustomIcon type="play-button-round-icon" size="large" hasIconHover color="dynamic" /> : <CustomIcon type="pause-button-round-icon" size="large" hasIconHover color="dynamic" />}
                    </button>
                );
            case 'play-red':
                return (
                    <button type="button" className={styles['play-button--play-btn']} aria-label="Play/Pause" onClick={onPress}>
                        {!isPlaying ? <CustomIcon type="play-button-round-icon" size="large" color="red" /> : <CustomIcon type="pause-button-round-icon" size="large" color="red" />}
                    </button>
                );
            default:
                return null;
        }
    };

    return variant === 'squared' ? squaredButton() : roundButton();
}
