import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';

const NoOpComponent: React.FC = ({ children }) => {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children || null}</>;
};

export function startBugsnag(apiKey: string) {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (process.env['NEXT_PHASE'] !== 'phase-production-build') {
        // eslint-disable-next-line @typescript-eslint/semi, @typescript-eslint/dot-notation
        const stage = process.env['NEXT_PUBLIC_RELEASE_STAGE']
        if (stage !== 'local' && apiKey && !Bugsnag.isStarted()) {
            Bugsnag.start({
                apiKey,
                // eslint-disable-next-line global-require
                appVersion: require('../../../../package.json').version,
                releaseStage: stage,
                plugins: [new BugsnagPluginReact()]
            });
            return Bugsnag.getPlugin('react')?.createErrorBoundary(React) || NoOpComponent;
        }
    }
    return NoOpComponent;
}
