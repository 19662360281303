import { UserPlaylistResponse, useUserPlaylistDetail } from '@bpm-web-app/swr-hooks';
import {
    appendQueryParams,
    convertToPluralIfNeeded,
    downloadAlbumWithMediaToQueueItem,
    formatDateToString,
    State,
    streamMediaWithAlbumToQueueItem,
    useHideSwitch,
    useHubSwitch,
    usePageDetails,
    usePlayerState,
} from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import { useCallback, useContext, useMemo } from 'react';
import { UserPlaylistWithAlbum } from '@bpm-web-app/download-api-sdk';
import { UserPlaylistWithMedia } from '@bpm-web-app/stream-api-sdk';
import classNames from 'classnames';
import PauseIcon from '../../../assets/icons/player-pause-lg.svg';
import PlayArrow from '../../../assets/icons/player-play-alt.svg';
import { useHideLibraryTabs } from '../../../../../utils/src/lib/library-tabs.context';
import MediaDetailBanner from '../../shared/ui/media-detail-banner/media-detail-banner';
import { usePlayer } from '../../player-context';
import { LocalSortingKeys, sortMediaLocally, useLocalSort } from '../../sort-options-sheet/sort-options-sheet';
import { ThreeDotsSheetContext } from '../../shared/three-dots-sheet/three-dots-sheet.context';

import styles from './for-you-playlist-detail.module.css';
import Title from '../../title/title';
import { ThreeDotsButton } from '../../shared/three-dots-button/three-dots-button';
import { AddToPlaylistButton } from '../../shared/add-to-playlist-button/add-to-playlist-button';
import { GhostComponent } from '../../shared';
import { TrackListSupreme } from '../../shared/track-list/supreme/track-list-supreme';
import { TrackListPresetSupreme } from '../../shared/track-list/supreme/track-list-supreme-helpers';
import { TrackListLoadingSupreme } from '../../shared/track-list/ghost-loading/track-list-loading-supreme';

export function ForYouPlaylistDetail() {
    const router = useRouter();
    const { openThreeDotsModalSheet } = useContext(ThreeDotsSheetContext);
    const { id } = router.query;
    const { isDownload, hub } = useHubSwitch();
    const [sortKey, setSortKey] = useLocalSort();
    const { originalListDetails, setQueue, togglePlayPause } = usePlayer();
    const playerState = usePlayerState();
    const { resource } = usePageDetails();

    useHideLibraryTabs();
    useHideSwitch();

    const { data, isLoading, error, mutate } = useUserPlaylistDetail(isDownload, `${id}`);
    const playlist = useMemo(() => data?.data, [data?.data]);

    const sortedTrackList = useMemo(
        () => (isDownload ? sortMediaLocally((playlist as UserPlaylistWithAlbum)?.albums, sortKey) : sortMediaLocally((playlist as UserPlaylistWithMedia)?.media, sortKey)),
        [playlist, isDownload, sortKey]
    );

    const isCurrentListInPlayer = useMemo(() => originalListDetails?.identifier === id && originalListDetails?.resource === resource, [originalListDetails, id, resource]);

    const handlePlayPlaylist = useCallback(() => {
        if (isCurrentListInPlayer) {
            togglePlayPause();
            return;
        }

        const queueItems = isDownload ? sortedTrackList?.map((album) => downloadAlbumWithMediaToQueueItem(album)) : sortedTrackList?.map((media) => streamMediaWithAlbumToQueueItem(media));

        if (queueItems) {
            setQueue(queueItems, 0, {
                identifier: id as string,
                resource,
            });
        }
    }, [isCurrentListInPlayer, isDownload, id, resource, setQueue, sortedTrackList, togglePlayPause]);

    const handleMoreOptions = useCallback(
        (event: React.MouseEvent<HTMLElement>) =>
            openThreeDotsModalSheet('for-you-playlist-detail', id as string, event.currentTarget.getBoundingClientRect().left, event.currentTarget.getBoundingClientRect().top, false),
        [id, openThreeDotsModalSheet]
    );

    const handleAddToPlaylist = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            openThreeDotsModalSheet(
                'for-you-playlist-detail',
                id as string,
                event.currentTarget.getBoundingClientRect().left,
                event.currentTarget.getBoundingClientRect().top,
                true,
            );
        },
        [id, openThreeDotsModalSheet],
    );

    if (isLoading) {
        return (
            <>
                <Title platform={hub} title="Loading Playlist..." />
                <GhostComponent type="for-you-detail" />
                <TrackListLoadingSupreme preset={TrackListPresetSupreme.Download} amount={20} />
            </>
        );
    }
    if (error || !playlist) return null;

    return (
        <>
            <Title platform={hub} title={playlist?.title} />
            <div className={classNames(styles['for-you-playlist-detail'], 'spacing__window')}>
                <MediaDetailBanner minHeight={185} minHeightMobile={85}>
                    {{
                        image: (
                            <picture>
                                <source
                                    srcSet={`${appendQueryParams(playlist.image_url, { key: 'dw', value: 296 })}, ${appendQueryParams(playlist.image_url, { key: 'dw', value: 592 })} 2x`}
                                    media="(min-width: 1024px)"
                                />
                                <img
                                    src={appendQueryParams(playlist.image_url, { key: 'dw', value: 136 })}
                                    srcSet={`${appendQueryParams(playlist.image_url, { key: 'dw', value: 272 })} 2x`}
                                    alt={playlist.title}
                                    draggable={false}
                                />
                            </picture>
                        ),
                        text: (
                            <>
                                <div className={styles['for-you-playlist-detail__title-container']}>
                                    <h2>{playlist.title}</h2>
                                    <ThreeDotsButton hasTooltip onClick={handleMoreOptions} />
                                </div>
                                <div className={styles['for-you-playlist-detail__info']}>
                                    <span>{isDownload
                                        ? convertToPluralIfNeeded((playlist as UserPlaylistWithAlbum).album_count, 'Track', 'Tracks')
                                        : convertToPluralIfNeeded((playlist as UserPlaylistWithMedia).media_count, 'Track', 'Tracks')}
                                    </span>
                                    <span>{`Updated ${formatDateToString(playlist.updated_at)}`}</span>
                                </div>
                                <p className={styles['for-you-playlist-detail__description']}>{playlist.artist}</p>
                            </>
                        ),
                        actions: (
                            <>
                                <button type="button" className={styles['for-you-playlist-detail__play-btn']} aria-label="Play/Pause" onClick={handlePlayPlaylist}>
                                    {isCurrentListInPlayer && playerState === State.Playing ? <PauseIcon /> : <PlayArrow />}
                                </button>
                                <AddToPlaylistButton onClick={handleAddToPlaylist} hasTooltip />
                            </>
                        ),
                    }}
                </MediaDetailBanner>
                <TrackListSupreme
                    list={sortedTrackList}
                    preset={isDownload ? TrackListPresetSupreme.Download : TrackListPresetSupreme.Stream}
                    isSortable
                    onSort={(nextSort) => setSortKey(nextSort as LocalSortingKeys)}
                    selectedSortType={sortKey}
                    onDownloadRevalidate={(downloadMedia) => {
                        // eslint-disable-next-line no-param-reassign
                        downloadMedia.download_count += 1;
                        mutate({ data: playlist } as UserPlaylistResponse, false);
                    }}
                />
            </div>
        </>
    );
}

export default ForYouPlaylistDetail;
