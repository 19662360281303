import classNames from 'classnames';
import { useUniqueArray } from '@bpm-web-app/utils';
import styles from './tags-view.module.css';

interface TagsViewGhostProps {
    large?: boolean;
    key?: string;
}

export function TagsViewGhost({ large, key }: TagsViewGhostProps) {
    const ghostLoadingItems = useUniqueArray(6);

    return (
        <div className={classNames(styles['tags-view--tags-ghost'], { [styles['tags-view--tags-large']]: large })}>
            {ghostLoadingItems.map((uuid) => {
                return (
                    <div
                        key={`${key}--${uuid}`}
                        className={classNames(styles['tags-view__tag'], styles['tags-view__ghost'], { [styles['tags-view__tag-large']]: large, [styles['tags-view__ghost--large']]: large }, 'shimmer')}
                    />
                );
            })}
        </div>
    );
}
