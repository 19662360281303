import { FC, useCallback, useState } from 'react';
import ReactModal from 'react-modal';
import classNames from 'classnames';
import styles from './playlists-form.module.css';
import CloseIcon from '../../assets/icons/close.svg';

export interface PlaylistsFormProps {
    id?: string;
    type: 'AddPlaylist' | 'CreateNewPlaylist' | 'CreateNewFolder' | 'CreateNewDrive' | 'EditMyPlaylist' | 'EditMyFolder' | 'EditDriveName';
    text: string;
    defaultvalue?: string;
    formAction?: (name: string) => void | null | any;
    formActionWithId?: (id: string | undefined, name: string) => void | null;
    close: (status: 'submitted' | 'closed') => void;
}

export enum FormTitle {
    AddPlaylist = 'Add Playlist',
    CreateNewFolder = 'Create New Folder',
    CreateNewDrive = 'Create New Drive',
    CreateNewPlaylist = 'Create New Playlist',
    EditMyPlaylist = 'Edit Playlist Name',
    EditMyFolder = 'Edit Folder Name',
    EditDriveName = 'Edit Drive Name',
}

export enum FormInputPlaceholder {
    AddPlaylist = 'Playlist Name',
    CreateNewFolder = 'Folder Name',
    CreateNewDrive = 'Drive Name',
    CreateNewPlaylist = 'Playlist Name',
    EditMyPlaylist = 'New Playlist Name',
    EditMyFolder = 'New Folder Name',
    EditDriveName = 'New Drive Name',
}

const PlaylistsForm: FC<PlaylistsFormProps> = ({ id, text, type, formAction, formActionWithId, close, defaultvalue }) => {
    const [inputText, setInputText] = useState(defaultvalue || '');
    const handleSubmit = useCallback(() => {
        if (id) {
            formActionWithId?.(id, inputText);
        } else {
            formAction?.(inputText);
        }
        close('submitted');
    }, [close, formAction, formActionWithId, id, inputText]);

    return (
        <ReactModal isOpen className={classNames('react-modal__content', styles['playlists-form__modal-content'])} overlayClassName="react-modal__overlay" onRequestClose={() => close('closed')}>
            <div className={styles['playlists-form__modal-form']}>
                <div className={styles['playlists-form__modal-header']}>
                    <h2 className={styles['playlists-form__modal-title']}>{FormTitle[type]}</h2>
                    <button type="button" className={styles['playlists-form__modal-close-btn']} onClick={() => close('closed')}>
                        <i className={styles['playlists-form__modal-close-icon']}>
                            <CloseIcon />
                        </i>
                    </button>
                </div>
                {/* eslint-disable-next-line max-len */}
                <div className={styles['playlists-form__modal-text']}>{text}</div>
                <input
                    type="text"
                    name="name"
                    aria-label={FormInputPlaceholder[type]}
                    placeholder={FormInputPlaceholder[type]}
                    className={styles['playlists-form__modal-input-text']}
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            handleSubmit();
                        }
                    }}
                />
                <button type="submit" className={styles['playlists-form__modal-btn']} onClick={handleSubmit}>
                    {FormTitle[type]}
                </button>
                <button type="button" className={styles['playlists-form__modal-btn']} onClick={() => close('closed')}>
                    Cancel
                </button>
            </div>
        </ReactModal>
    );
};

export default PlaylistsForm;
