import { AlbumApi as StreamAlbumApi } from '@bpm-web-app/stream-api-sdk';
import { AlbumApi as DownloadAlbumApi } from '@bpm-web-app/download-api-sdk';
import { downloadConfig, streamConfig } from '../config';

export class Album {
    static streamClient = new StreamAlbumApi(streamConfig);

    static downloadClient = new DownloadAlbumApi(downloadConfig);

    static getClient(isDownload: boolean) {
        return isDownload ? this.downloadClient : this.streamClient;
    }

    static addAlbumToFavorites = (isDownload: boolean, id: number) => Album.getClient(isDownload).favoriteAlbum(id);

    static removeAlbumFromFavorites = (isDownload: boolean, id: number) => Album.getClient(isDownload).unfavoriteAlbum(id);

    static getFavoriteAlbums = (isDownload: boolean, library?: string) => Album.getClient(isDownload).getFavAlbums(library);

    static getAlbumFeaturedIn = (id: number, library?: string) => Album.downloadClient.getAlbumFeaturedIn(id, library);
}
