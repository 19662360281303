import { useState } from 'react';
import classNames from 'classnames';
import { Collapse } from 'react-collapse';
import Switch from '../shared/ui/switch/switch';
import styles from './showing-filter.module.css';
import ChevronUp from '../../assets/icons/chevron-up.svg';

export interface ShowingFilterProps {
    hideExplicit: boolean;
    onHideExplicit: (value: boolean) => void;
    hideRemixes: boolean;
    onHideRemixes: (value: boolean) => void;
    hideHideRemix?: boolean
    hideExclusives: boolean;
    onHideExclusives: (value: boolean) => void;
    hidePrevDownloaded: boolean;
    onHidePrevDownloaded: (value: boolean) => void;
    justFilter?: boolean;
    hasTwoColumns?: boolean;
}

export function ShowingFilter({ hideRemixes, onHideRemixes, hideExplicit, hasTwoColumns, hideHideRemix,
    onHideExplicit, hideExclusives, onHideExclusives, hidePrevDownloaded, onHidePrevDownloaded, justFilter }: ShowingFilterProps) {
    const [isSectionExpanded, setIsSectionExpanded] = useState(true);
    const filterActiveCount = [hideExplicit, hideExclusives, hideRemixes, hidePrevDownloaded].filter((i) => i).length;

    const filterComponent = () => (
        <div className={hasTwoColumns ? styles['showing-filter__switches-column-container'] : styles['showing-filter__switches-row-container']}>
            <div className={styles['showing-filter__switch-column']}>
                <div className={styles['showing-filter__switch']}>
                    <Switch onChange={onHideExplicit} enabled={hideExplicit} label="Hide explicit tracks" />
                </div>
                {!hideHideRemix && (
                    <div className={styles['showing-filter__switch']}>
                        <Switch onChange={onHideRemixes} enabled={hideRemixes} label="Hide remixes" />
                    </div>
                )}
            </div>
            <div className={styles['showing-filter__switch-column']}>
                <div className={styles['showing-filter__switch']}>
                    <Switch onChange={onHideExclusives} enabled={hideExclusives} label="Hide exclusives" />
                </div>
                {/* <div className={styles['showing-filter__switch']}>
                    <Switch onChange={onHidePrevDownloaded} enabled={hidePrevDownloaded} label="Hide previously downloaded" />
                    </div> */}
            </div>
        </div>
    );

    if (justFilter) {
        return filterComponent();
    }

    return (
        /* eslint-disable-next-line @typescript-eslint/dot-notation */
        <div className={classNames('filter', styles['showing-filter'])}>
            <div
                role="button"
                onClick={() => setIsSectionExpanded(!isSectionExpanded)}
                tabIndex={0}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        setIsSectionExpanded(!isSectionExpanded);
                    }
                }}
                className="filter__header">
                <div className={classNames('filter__title', { 'filter__title-active': filterActiveCount > 0 })}>Showing {filterActiveCount > 0 ? `(${filterActiveCount})` : ''}</div>
                {filterActiveCount > 0 && (
                    <button
                        type="button"
                        className="filter__reset"
                        onClick={() => {
                            onHideExclusives(false);
                            onHideRemixes(false);
                            onHideExplicit(false);
                            onHidePrevDownloaded(false);
                        }}>
                        Remove
                    </button>
                )}
                <button aria-label="Expand/Collapse Versions" type="button" onClick={() => setIsSectionExpanded(!isSectionExpanded)}>
                    <ChevronUp className={classNames('filter__chevron', { 'filter__chevron--expanded': isSectionExpanded })} />
                </button>
            </div>
            <Collapse isOpened={isSectionExpanded}>
                {filterComponent()}
            </Collapse>
        </div>
    );
}

export default ShowingFilter;
