import { useCallback, useEffect, useRef, useState } from 'react';

export function useOnClickOutside<T extends HTMLDivElement>(initialIsVisible: boolean, handler?: (event: MouseEvent) => void) {
    const [showComponent, setShowComponent] = useState(initialIsVisible);
    const ref = useRef<T>(null);

    const handleClickOutside = useCallback(
        (event: MouseEvent) => {
            if (!ref.current && !event.target) return;

            if (handler && !ref.current?.contains(event.target as Node)) {
                handler(event);
                return;
            }

            setShowComponent(ref.current?.contains(event.target as Node) || false);
        },
        [handler]
    );

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [handleClickOutside]);

    return { ref, showComponent, setShowComponent };
}
