import React, { useCallback } from 'react';
import classNames from 'classnames';
import { convertKeyToSelectedKeyType, KeyboardKeyType, keyTypes } from '@bpm-web-app/utils';
import styles from './circle-of-fifths-input.module.css';

export interface CircleofFifthsInputProps {
    activeKeys?: string[];
    setActiveKeys: (value: string[]) => void;
    keyType: KeyboardKeyType;
    onKeyTypeChange: (value: KeyboardKeyType) => void;
    isSingleSelect: boolean;
}

const CAMELOT_OPTIONS = ['1A', '2A', '3A', '4A', '5A', '6A', '7A', '8A', '9A', '10A', '11A', '12A', '1B', '2B', '3B', '4B', '5B', '6B', '7B', '8B', '9B', '10B', '11B', '12B'];

export function CircleofFifths({ setActiveKeys, activeKeys, isSingleSelect, keyType, onKeyTypeChange, }: CircleofFifthsInputProps) {
    const toggleCamelotKey = useCallback(
        (key: string) => {
            if (isSingleSelect) {
                setActiveKeys([key]);
                return;
            }

            if (activeKeys?.includes(key)) {
                setActiveKeys(activeKeys.filter((item) => item !== key));
            } else {
                setActiveKeys([...activeKeys, key]);
            }
        },
        [activeKeys, setActiveKeys, isSingleSelect]
    );

    const renderCamelotPie = useCallback(
        (keyName: string) => {
            const textProps = {
                key: `${keyName}-camelot-text`,
                className: classNames(styles['key-camelot-input__camelot-text'], {
                    [styles['key-camelot-input__camelot-text--active']]: activeKeys?.includes(keyName),
                }),
                onClick: () => toggleCamelotKey(keyName),
            };

            const pieProps = {
                key: `${keyName}-camelot`,
                className: classNames(styles['key-camelot-input__camelot'], {
                    [styles['key-camelot-input__camelot--active']]: activeKeys?.includes(keyName),
                    [styles['key-camelot-input__camelot--has-b']]: keyName.includes('B'),
                }),
                onClick: () => toggleCamelotKey(keyName),
                tabIndex: 0,
                'aria-label': keyName,
            };

            switch (keyName) {
                case '9A':
                    return (
                        <React.Fragment key={pieProps.key}>
                            <path
                                d="M161.793 111.533L201.716 71.5507C190.47 60.3225 176.333 51.9934 160.525 47.7629L145.889 102.345C151.991 103.981 157.449 107.194 161.793 111.533Z"
                                {...pieProps}
                                stroke="#121212"
                            />
                            <text x="168" y="85" textAnchor="middle" {...textProps}>
                                {convertKeyToSelectedKeyType(keyName, keyType, true)}
                            </text>
                        </React.Fragment>
                    );
                case '10A':
                    return (
                        <React.Fragment key={pieProps.key}>
                            <path
                                d="M201.716 71.5505L161.793 111.533C166.128 115.863 169.35 121.307 170.995 127.401L225.545 112.646C221.287 96.8696 212.945 82.7651 201.716 71.5505Z"
                                {...pieProps}
                                stroke="#121212"
                            />
                            <text x="189" y="110" textAnchor="middle" {...textProps}>
                                {convertKeyToSelectedKeyType(keyName, keyType, true)}
                            </text>
                        </React.Fragment>
                    );

                case '11A':
                    return (
                        <React.Fragment key={pieProps.key}>
                            <path
                                d="M172.218 136.679C172.218 139.869 171.796 142.959 171.01 145.899L225.587 160.553C227.628 152.938 228.723 144.936 228.723 136.679C228.723 128.364 227.614 120.307 225.547 112.646L170.996 127.4C171.791 130.359 172.218 133.467 172.218 136.679Z"
                                {...pieProps}
                                stroke="#121212"
                            />
                            <text x="198" y="140" textAnchor="middle" {...textProps}>
                                {convertKeyToSelectedKeyType(keyName, keyType, true)}
                            </text>
                        </React.Fragment>
                    );

                case '12A':
                    return (
                        <React.Fragment key={pieProps.key}>
                            <path
                                d="M161.74 161.881L201.582 201.945C212.919 190.671 221.33 176.462 225.592 160.558L171.014 145.904C169.365 152.043 166.116 157.528 161.74 161.881Z"
                                {...pieProps}
                                stroke="#121212"
                            />
                            <text x="190" y="175" textAnchor="middle" {...textProps}>
                                {convertKeyToSelectedKeyType(keyName, keyType, true)}
                            </text>
                        </React.Fragment>
                    );

                case '1A':
                    return (
                        <React.Fragment key={pieProps.key}>
                            <path
                                d="M145.944 170.995L160.676 225.555C176.362 221.329 190.394 213.068 201.576 201.944L161.735 161.88C157.418 166.174 152.001 169.364 145.944 170.995Z"
                                {...pieProps}
                                stroke="#121212"
                            />
                            <text x="170" y="200" textAnchor="middle" {...textProps}>
                                {convertKeyToSelectedKeyType(keyName, keyType, true)}
                            </text>
                        </React.Fragment>
                    );

                case '2A':
                    return (
                        <React.Fragment key={pieProps.key}>
                            <path
                                d="M136.678 172.218C133.493 172.218 130.408 171.795 127.472 171.009L112.836 225.591C120.443 227.627 128.435 228.718 136.683 228.718C144.985 228.718 153.032 227.613 160.684 225.55L145.952 170.991C142.99 171.791 139.886 172.218 136.678 172.218Z"
                                {...pieProps}
                                stroke="#121212"
                            />
                            <text x="137" y="210" textAnchor="middle" {...textProps}>
                                {convertKeyToSelectedKeyType(keyName, keyType, true)}
                            </text>
                        </React.Fragment>
                    );

                case '3A':
                    return (
                        <React.Fragment key={pieProps.key}>
                            <path
                                d="M111.564 161.826L71.6406 201.808C82.887 213.036 97.0233 221.365 112.832 225.596L127.468 171.014C121.365 169.378 115.908 166.165 111.564 161.826Z"
                                {...pieProps}
                                stroke="#121212"
                            />
                            <text x="103" y="200" textAnchor="middle" {...textProps}>
                                {convertKeyToSelectedKeyType(keyName, keyType, true)}
                            </text>
                        </React.Fragment>
                    );

                case '4A':
                    return (
                        <React.Fragment key={pieProps.key}>
                            <path
                                d="M102.368 145.958L47.8174 160.712C52.0751 176.494 60.4133 190.594 71.646 201.808L111.569 161.826C107.23 157.495 104.008 152.051 102.368 145.958Z"
                                {...pieProps}
                                stroke="#121212"
                            />
                            <text x="79" y="175" textAnchor="middle" {...textProps}>
                                {convertKeyToSelectedKeyType(keyName, keyType, true)}
                            </text>
                        </React.Fragment>
                    );

                case '5A':
                    return (
                        <React.Fragment key={pieProps.key}>
                            <path
                                d="M101.141 136.679C101.141 133.49 101.564 130.4 102.35 127.46L47.7721 112.805C45.7318 120.421 44.6367 128.423 44.6367 136.679C44.6367 144.995 45.7454 153.051 47.813 160.713L102.363 145.958C101.568 143 101.141 139.892 101.141 136.679Z"
                                {...pieProps}
                                stroke="#121212"
                            />
                            <text x="69" y="140" textAnchor="middle" {...textProps}>
                                {convertKeyToSelectedKeyType(keyName, keyType, true)}
                            </text>
                        </React.Fragment>
                    );

                case '6A':
                    return (
                        <React.Fragment key={pieProps.key}>
                            <path
                                d="M111.622 111.478L71.7799 71.4141C60.4427 82.6877 52.0318 96.8967 47.7695 112.801L102.347 127.455C103.997 121.316 107.246 115.831 111.622 111.478Z"
                                {...pieProps}
                                stroke="#121212"
                            />
                            <text x="78" y="110" textAnchor="middle" {...textProps}>
                                {convertKeyToSelectedKeyType(keyName, keyType, true)}
                            </text>
                        </React.Fragment>
                    );

                case '7A':
                    return (
                        <React.Fragment key={pieProps.key}>
                            <path
                                d="M127.415 102.363L112.684 47.8037C96.9977 52.0296 82.9659 60.2906 71.7832 71.4142L111.625 111.479C115.942 107.184 121.358 103.995 127.415 102.363Z"
                                stroke="#121212"
                                {...pieProps}
                            />
                            <text x="104" y="85" textAnchor="middle" {...textProps}>
                                {convertKeyToSelectedKeyType(keyName, keyType, true)}
                            </text>
                        </React.Fragment>
                    );

                case '8A':
                    return (
                        <React.Fragment key={pieProps.key}>
                            <path
                                d="M136.681 101.141C139.866 101.141 142.951 101.564 145.887 102.35L160.523 47.7675C152.916 45.7318 144.923 44.6412 136.676 44.6412C128.374 44.6412 120.327 45.7454 112.675 47.8084L127.406 102.368C130.369 101.568 133.473 101.141 136.681 101.141Z"
                                {...pieProps}
                                stroke="#121212"
                            />
                            <text x="137" y="75" textAnchor="middle" {...textProps}>
                                {convertKeyToSelectedKeyType(keyName, keyType, true)}
                            </text>
                        </React.Fragment>
                    );

                case '9B':
                    return (
                        <React.Fragment key={pieProps.key}>
                            <path
                                d="M201.719 71.5506L233.004 40.2199C216.351 23.5889 195.417 11.2475 171.997 4.98132L160.523 47.7629C176.336 51.9933 190.473 60.3224 201.719 71.5506Z"
                                {...pieProps}
                                stroke="#121212"
                            />
                            <text x="193" y="40" textAnchor="middle" {...textProps}>
                                {convertKeyToSelectedKeyType(keyName, keyType, true)}
                            </text>
                        </React.Fragment>
                    );
                case '10B':
                    return (
                        <React.Fragment key={pieProps.key}>
                            <path
                                d="M225.547 112.646L268.306 101.082C262.004 77.712 249.64 56.8278 233.004 40.2196L201.719 71.5503C212.947 82.7649 221.29 96.8694 225.547 112.646Z"
                                {...pieProps}
                                stroke="#121212"
                            />
                            <text x="235" y="85" textAnchor="middle" {...textProps}>
                                {convertKeyToSelectedKeyType(keyName, keyType, true)}
                            </text>
                        </React.Fragment>
                    );

                case '11B':
                    return (
                        <React.Fragment key={pieProps.key}>
                            <path
                                d="M268.308 101.086L225.549 112.651C227.616 120.312 228.725 128.368 228.725 136.684C228.725 144.94 227.63 152.942 225.59 160.558L268.367 172.045C271.389 160.762 273.002 148.912 273.002 136.679C273.002 124.365 271.37 112.433 268.308 101.086Z"
                                {...pieProps}
                                stroke="#121212"
                            />
                            <text x="250" y="140" textAnchor="middle" {...textProps}>
                                {convertKeyToSelectedKeyType(keyName, keyType, true)}
                            </text>
                        </React.Fragment>
                    );

                case '12B':
                    return (
                        <React.Fragment key={pieProps.key}>
                            <path
                                d="M201.582 201.945L232.804 233.343C249.594 216.649 262.058 195.606 268.369 172.045L225.592 160.558C221.326 176.462 212.915 190.671 201.582 201.945Z"
                                {...pieProps}
                                stroke="#121212"
                            />
                            <text x="235" y="200" textAnchor="middle" {...textProps}>
                                {convertKeyToSelectedKeyType(keyName, keyType, true)}
                            </text>
                        </React.Fragment>
                    );

                case '1B':
                    return (
                        <React.Fragment key={pieProps.key}>
                            <path
                                d="M160.681 225.555L172.227 268.319C195.46 262.062 216.235 249.816 232.803 233.339L201.581 201.94C190.394 213.064 176.366 221.329 160.681 225.555Z"
                                {...pieProps}
                                stroke="#121212"
                            />
                            <text x="195" y="240" textAnchor="middle" {...textProps}>
                                {convertKeyToSelectedKeyType(keyName, keyType, true)}
                            </text>
                        </React.Fragment>
                    );

                case '2B':
                    return (
                        <React.Fragment key={pieProps.key}>
                            <path
                                d="M136.68 228.722C128.432 228.722 120.44 227.632 112.833 225.596L101.359 268.378C112.628 271.39 124.466 272.999 136.68 272.999C148.976 272.999 160.895 271.367 172.227 268.318L160.681 225.555C153.029 227.614 144.982 228.722 136.68 228.722Z"
                                {...pieProps}
                                stroke="#121212"
                            />
                            <text x="137" y="255" textAnchor="middle" {...textProps}>
                                {convertKeyToSelectedKeyType(keyName, keyType, true)}
                            </text>
                        </React.Fragment>
                    );

                case '3B':
                    return (
                        <React.Fragment key={pieProps.key}>
                            <path
                                d="M71.6398 201.809L40.3545 233.139C57.0082 249.77 77.9423 262.112 101.362 268.378L112.836 225.596C97.0225 221.366 82.8861 213.037 71.6398 201.809Z"
                                {...pieProps}
                                stroke="#121212"
                            />
                            <text x="79" y="240" textAnchor="middle" {...textProps}>
                                {convertKeyToSelectedKeyType(keyName, keyType, true)}
                            </text>
                        </React.Fragment>
                    );

                case '4B':
                    return (
                        <React.Fragment key={pieProps.key}>
                            <path
                                d="M47.8135 160.713L5.05469 172.277C11.3572 195.647 23.7213 216.531 40.3569 233.139L71.6421 201.808C60.414 190.594 52.0712 176.489 47.8135 160.713Z"
                                {...pieProps}
                                stroke="#121212"
                            />
                            <text x="37" y="200" textAnchor="middle" {...textProps}>
                                {convertKeyToSelectedKeyType(keyName, keyType, true)}
                            </text>
                        </React.Fragment>
                    );

                case '5B':
                    return (
                        <React.Fragment key={pieProps.key}>
                            <path
                                d="M44.6359 136.68C44.6359 128.423 45.731 120.421 47.7712 112.806L4.99423 101.318C1.97249 112.597 0.359375 124.447 0.359375 136.68C0.359375 148.994 1.99066 160.926 5.0533 172.273L47.8121 160.708C45.7446 153.052 44.6359 144.995 44.6359 136.68Z"
                                {...pieProps}
                                stroke="#121212"
                            />
                            <text x="22" y="140" textAnchor="middle" {...textProps}>
                                {convertKeyToSelectedKeyType(keyName, keyType, true)}
                            </text>
                        </React.Fragment>
                    );

                case '6B':
                    return (
                        <React.Fragment key={pieProps.key}>
                            <path
                                d="M71.7786 71.4144L40.5569 40.0155C23.7669 56.7101 11.3028 77.7532 4.99121 101.314L47.7682 112.801C52.035 96.897 60.4459 82.688 71.7786 71.4144Z"
                                {...pieProps}
                                stroke="#121212"
                            />
                            <text x="38" y="85" textAnchor="middle" {...textProps}>
                                {convertKeyToSelectedKeyType(keyName, keyType, true)}
                            </text>
                        </React.Fragment>
                    );

                case '7B':
                    return (
                        <React.Fragment key={pieProps.key}>
                            <path
                                d="M112.679 47.8036L101.132 5.04028C77.899 11.2973 57.124 23.5433 40.5566 40.0198L71.7783 71.4187C82.9656 60.295 96.9928 52.0295 112.679 47.8036Z"
                                {...pieProps}
                                stroke="#121212"
                            />
                            <text x="78" y="40" textAnchor="middle" {...textProps}>
                                {convertKeyToSelectedKeyType(keyName, keyType, true)}
                            </text>
                        </React.Fragment>
                    );

                case '8B':
                    return (
                        <React.Fragment key={pieProps.key}>
                            <path
                                d="M136.68 44.6365C144.928 44.6365 152.921 45.727 160.527 47.7628L172.001 4.98121C160.732 1.96855 148.895 0.359985 136.68 0.359985C124.384 0.359985 112.465 1.99127 101.133 5.04028L112.679 47.8036C120.331 45.7452 128.378 44.6365 136.68 44.6365Z"
                                {...pieProps}
                                stroke="#121212"
                            />
                            <text x="137" y="27.5" textAnchor="middle" {...textProps}>
                                {convertKeyToSelectedKeyType(keyName, keyType, true)}
                            </text>
                        </React.Fragment>
                    );

                default:
                    return null;
            }
        },
        [activeKeys, keyType, toggleCamelotKey]
    );

    return (
        <div className={styles['key-camelot-input']}>
            <div className={styles['key-camelot-input__types']}>
                {keyTypes.map((type) => (
                    <button
                        className={classNames(styles['key-camelot-input__type'], {
                            [styles['key-camelot-input__type--active']]: keyType === type.value,
                        })}
                        key={type.value}
                        type="button"
                        onClick={() => onKeyTypeChange(type.value)}
                        aria-label={`Change key type to ${type.label}`}
                    >
                        {type.label}
                    </button>
                ))}
            </div>
            <svg viewBox="0 0 273 273" className={styles['key-camelot-input__svg']}>
                {CAMELOT_OPTIONS.map((keyName) => renderCamelotPie(keyName))}
            </svg>
        </div>
    );
}

export default CircleofFifths;
