import {
    LoginBody,
    MeChangePasswordBody,
    UserAddress,
    UserApi,
    UsersMeBody,
    Preferences,
    ParentalcontrolDisableexplicitcontentBody,
    ParentalcontrolEnableexplicitcontentBody,
    SupremeLibraryBody,
} from '@bpm-web-app/supreme-api-sdk';
import { supremeConfig } from '../config';

export class User {
    static client = new UserApi(supremeConfig);

    static getCurrentUser = () => User.client.getMe();

    static setMyTutorialStatus = (type: string) => User.client.setMyTutorialStatus(type);

    static getMyTutorialStatus = (type: string) => User.client.getMyTutorialStatus(type);

    static getMyPreferences = () => User.client.getMyPreferences();

    static setMyPreferences = (body: Preferences) => User.client.updateMyPreferences(body);

    static getMyCurrentAddress = () => User.client.getMyAddress();

    static setMyCurrentAddress = (data: UserAddress) => User.client.updateMyAddress(data);

    static setMyPassword = (data: MeChangePasswordBody) => User.client.changePassword(data);

    static setMyPicture = (data: any) => User.client.updateMePic(data);

    static updateMe = (data: UsersMeBody) => User.client.updateMe(data);

    static disableExplicitContent = (data: ParentalcontrolDisableexplicitcontentBody) => User.client.disapleExplicitContent(data);

    static enableExplicitContent = (data: ParentalcontrolEnableexplicitcontentBody) => User.client.enableExplicitContent(data);

    static getPaymentInstrument = (platform: string) => User.client.getPaymentInstrument(platform);

    static getPaymentHistory = (platform: string) => User.client.getPaymentHistory(platform);

    static login = (data: LoginBody) => User.client.login(data);

    static setLastSelectedLibrary = (library: 'latin' | 'music' | 'artist') => User.client.updateMeLibrary({ library: library as unknown as SupremeLibraryBody.LibraryEnum });

    static logout = () => User.client.logout();
}
