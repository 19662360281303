import { Drive } from '@bpm-web-app/create-api-sdk';
import ThreeDotsMenuItem from '../shared/three-dots-sheet/three-dots-menu-item';

export interface MyDrivesListProps {
    drives: Drive[];
    highlightIndex: number;
    selectCategory: (id: string) => void;
}

export function MyDrivesList({ drives, highlightIndex, selectCategory }: MyDrivesListProps) {
    if (drives) {
        return (
            <>
                {drives.sort((x, y) => (x.is_own === y.is_own) ? 0 : x.is_own === false ? -1 : 1).map((item, index) => (
                    <ThreeDotsMenuItem
                        action={(e) => {
                            e.preventDefault();
                            selectCategory(item.id);
                        }}
                        legend={item.name}
                        icon={item.is_own ? 'folder' : 'sharedFolder'}
                        disabled={!item.is_own}
                        disabledDataTip="Unavailable"
                    />
                ))}
            </>
        );
    }

    return null;
}

export default MyDrivesList;
