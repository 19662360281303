/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Genre } from '@bpm-web-app/stream-api-sdk';
import classNames from 'classnames';
import { AppLink } from '../../app-link/app-link';
import styles from '../supreme/track-list-supreme.module.css';

export function GenreColumn({ genre }: { genre?: Genre }) {
    if (!genre) {
        return null;
    }

    return (
        <div className={classNames(styles['track-list__label-link'])} onClick={(e) => e.stopPropagation()}>
            <AppLink href={`/genres?genre=${genre.slug}`}>
                <a className="relative-link">{genre.name}</a>
            </AppLink>
        </div>
    );
}
/* eslint-enable jsx-a11y/click-events-have-key-events */
/* eslint-enable jsx-a11y/no-static-element-interactions */
