import { CuratedSet, GenericPaginatedQuery, SoundPackage } from '@bpm-web-app/api-client';
import { FAVORITES_STALE_INTERVAL } from '@bpm-web-app/utils';
import { Curated } from 'libs/api-client/src/lib/handlers/create/curated-set';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';

export function useGetCuratedSet(idOrSlug: string) {
    const { data, error } = useSWR(
        [
            'create-curated-set',
            {
                idOrSlug,
                persist: true,
            },
        ],
        () => CuratedSet.getCuratedSet(idOrSlug)
    );

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

export function useGetNewestCuratedSets(args?: Parameters<typeof CuratedSet.getNewestCuratedSets>[0]) {
    const { data, error } = useSWR(
        [
            'curated-set-newest',
            {
                ...args,
                persist: true,
            },
        ],
        () => CuratedSet.getNewestCuratedSets(args)
    );

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

export function useGetCuratedSetsByCategory(args?: Parameters<typeof CuratedSet.getCuratedSetsByCategory>[0]) {
    const { data, error } = useSWR(
        [
            'curated-set-by-category',
            {
                ...args,
                persist: true,
            },
        ],
        () => CuratedSet.getCuratedSetsByCategory(args)
    );

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

export function useGetFreeCuratedSets(args?: Parameters<typeof CuratedSet.getFreeCuratedSets>[0]) {
    const { data, error } = useSWR(
        [
            'create-free-curated-sets',
            {
                ...args,
                persist: true,
            },
        ],
        () => CuratedSet.getFreeCuratedSets(args)
    );

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

export function useInfiniteFreeCuratedSets(query: GenericPaginatedQuery) {
    const pageSize = query?.limit || 50;

    const { data, error, size, setSize, isValidating } = useSWRInfinite(
        (index) => [`create-free-curated-sets-${JSON.stringify(query)}-${index}`, { persist: true }],
        (key) => {
            /* extract page index back from key name */
            const pageIndex = key.split('-').pop() || '0';
            return CuratedSet.getFreeCuratedSets({
                ...query,
                skip: +pageIndex * pageSize,
            });
        },
        { revalidateFirstPage: false }
    );

    const isLoadingInitialData = !data && !error;
    const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === 'undefined');
    const isEmpty = data?.[0]?.data.length === 0;
    const lastPageSize = data ? data[data.length - 1]?.data.length || 0 : 0;
    const isLastPage = isEmpty || lastPageSize < pageSize;

    return {
        data,
        isLoadingInitialData,
        isLoadingMore,
        isValidating,
        isLastPage,
        error,
        size,
        setSize,
    };
}

export function useGetFavoritedCreateCuratedSets(args?: Parameters<typeof CuratedSet.getFavoritedCuratedSets>[0], enabled = true) {
    const { data, error, mutate } = useSWR(
        [
            'curated-set-favorites',
            {
                ...args,
                enabled,
                persist: true,
            },
        ],
        () => (enabled ? CuratedSet.getFavoritedCuratedSets(args) : null),
        {
            dedupingInterval: FAVORITES_STALE_INTERVAL,
            isPaused: () => !enabled,
        }
    );

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}

export function useFollowCreateCuratedSet() {
    return (id: Parameters<typeof CuratedSet.followCuratedSet>[0]) => CuratedSet.followCuratedSet(id);
}

export function useUnFollowCreateCuratedSet() {
    return (id: Parameters<typeof CuratedSet.unfollowCuratedSet>[0]) => CuratedSet.unfollowCuratedSet(id);
}

export function useDownloadCuratedSet() {
    return (id: Parameters<typeof CuratedSet.downloadCuratedSet>[0], location: string) => CuratedSet.downloadCuratedSet(id, location);
}

export function useDownloadCuratedSetPreview() {
    return (id: Parameters<typeof Curated.downloadCuratedSetPreview>[0]) => Curated.downloadCuratedSetPreview(id);
}
