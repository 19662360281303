import ReactModal from 'react-modal';
import classNames from 'classnames';
import Image from 'next/image';
import styles from './promotional-modal.module.css';
import CloseIcon from '../../assets/icons/close.svg';
import { StaticImageData } from '../onboarding-modals/modal-slider/modal-slider';

export interface PromotionalModalProps {
    isOpen: boolean;
    headerTitle: string;
    onClose: () => void;
    onConfirm: () => void;
    confirmButtonText: string;
    title: string;
    subtitle: string;
    image: string | StaticImageData | (() => React.ReactElement);
    footerRichTextContent?: string;
}

export function PromotionalModal({ isOpen, headerTitle, onClose, onConfirm, confirmButtonText, title, subtitle, image, footerRichTextContent }: PromotionalModalProps) {
    return (
        /* TODO: implement modal closing */
        <ReactModal
            isOpen={isOpen}
            className={classNames('react-modal__content', styles['promotional-modal__modal-content'])}
            overlayClassName="react-modal__overlay"
            shouldCloseOnOverlayClick
            onRequestClose={onClose}
        >
            <div className={styles['promotional-modal']}>
                <div className={styles['promotional-modal__container']}>
                    <div className={styles['promotional-modal__heading-section']}>
                        <h5>{headerTitle}</h5>
                        <button className={styles['promotional-modal__button-icon']} type="button" onClick={onClose} aria-label="Close">
                            <CloseIcon />
                        </button>
                    </div>
                    <div className={styles['promotional-modal__content']}>
                        <div className={styles['promotional-modal__text-container']}>
                            <h2>{title}</h2>
                            <p dangerouslySetInnerHTML={{ __html: subtitle }} />
                        </div>
                        <div className={styles['promotional-modal__img-container']}>{typeof image === 'string' ? <img alt="" src={image} /> : typeof image === 'function' ? image() : <Image alt="" src={image as StaticImageData} />}</div>
                    </div>
                    <div className={styles['promotional-modal__controls']}>
                        <div>
                            <button type="button" onClick={onConfirm} className={classNames('button button--solid', styles['promotional-modal__cta'])}>
                                {confirmButtonText}
                            </button>
                        </div>
                        <div className={styles['promotional-modal__rich-text-content']} dangerouslySetInnerHTML={{ __html: footerRichTextContent }} />
                    </div>
                </div>
            </div>
        </ReactModal>
    );
}

export default PromotionalModal;
