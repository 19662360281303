import useSWR from 'swr';
import { Meta } from '@bpm-web-app/api-client';

export function useTags(query?: Parameters<typeof Meta.getTags>[0]) {
    const { data, error } = useSWR(
        [
            'create-tags',
            {
                persist: true,
                ...query,
            },
        ],
        () => Meta.getTags({ ...query })
    );

    return {
        tags: data?.data,
        isLoading: !error && !data,
        error,
    };
}

export function useTagsFromCategory(category: string) {
    const { data, error } = useSWR(
        [
            'create-tags',
            category,
            {
                persist: true,
            },
        ],
        () => Meta.getTags({ tag_categories: [category] })
    );

    return {
        tags: data?.data,
        isLoading: !error && !data,
        error,
    };
}
