import { createAppRoutes, DEFAULT_DROPDOWN_CATEGORY, State, usePlayerState } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import { UrlObject } from 'url';
import ChevronRight from '../../assets/icons/chevron-right.svg';
import { AppLink } from '../shared/app-link/app-link';
import Dropdown from '../dropdown/dropdown';
import { SecondaryPageTitle } from '../shared/secondary-page-title/secondary-page-title';
import styles from './trending.module.css';
import { usePlayer } from '../player-context';
import { ITrendingItem, TrendingItem } from './trending-item';
import { GhostComponent } from '../shared';

export type { ITrendingItem } from './trending-item';

interface TrendingBaseProps {
    trending: ITrendingItem[];
    isLoading: boolean;
    noTitle?: boolean;
    onPlayTrack?: (index: number) => void;
    platform: 'create' | 'supreme';
    dropdownData?: { label: string; value: string }[];
    activeDropdownValue?: string;
    onDropdownChange?: (category: string) => void;
    noPadding?: boolean
    className?: string
    seeMorePath?: string | UrlObject
}

export type TrendingProps = TrendingBaseProps;

export function Trending({ trending, isLoading, noTitle, onPlayTrack, dropdownData, onDropdownChange, activeDropdownValue, platform, noPadding, className, seeMorePath }: TrendingProps) {
    const { trendingStatusMin, trendingStatusDiff } = useMemo(() => {
        if (!trending) {
            return {
                trendingStatusMin: 0,
                trendingStatusDiff: 0,
            };
        }
        const trendingStatus = trending.map((item) => Math.abs(item.trending_status));
        const [trendStatusMin, trendStatusMax] = [Math.min(...trendingStatus), Math.max(...trendingStatus)];
        const trendStatusDiff = trendStatusMax - trendStatusMin;

        return {
            trendingStatusMin: trendStatusMin,
            trendingStatusDiff: trendStatusDiff,
        };
    }, [trending]);

    const calculateBarWidthPercentage = useMemo(
        () => (trendingStatus: number) => 50 + Math.floor(((Math.abs(trendingStatus) - trendingStatusMin) / trendingStatusDiff) * 50),
        [trendingStatusDiff, trendingStatusMin]
    );

    const playerState = usePlayerState();
    const { currentTrack, onPause } = usePlayer();
    const isMediaPlaying = useCallback((mediaId: number,) => {
        return playerState === State.Playing && mediaId === currentTrack?.id;
    }, [currentTrack, playerState]);

    const renderTrackItem = useCallback((media: ITrendingItem, index: number) => {
        return (platform === 'supreme' ? (
            <div
                key={media.id}
                tabIndex={0}
                onKeyUp={({ key }) => {
                    if (key === 'Enter') {
                        if (isMediaPlaying(media.media_id!)) {
                            onPause();
                        } else {
                            onPlayTrack?.(index);
                        }
                    }
                }}
                onClick={() => {
                    if (isMediaPlaying(media.media_id!)) {
                        onPause();
                    } else {
                        onPlayTrack?.(index);
                    }
                }}
                role="button"
                aria-label={`Play ${media.title}`}
                className={classNames(styles['trending__list-item'], styles[`trending__list-item--${media.trending_status > 0 ? 'rising' : 'dropping'}`])}
            >
                <TrendingItem
                    media={media}
                    index={index}
                    calculateBarWidthPercentage={calculateBarWidthPercentage}
                    platform={platform}
                    isPlaying={isMediaPlaying(media.media_id!)} />
            </div>
        ) : (
            <AppLink key={media.id} href={createAppRoutes.packsSlug(media?.slug ?? '')}>
                <a className={classNames(styles['trending__list-item'], styles[`trending__list-item--${media.trending_status > 0 ? 'rising' : 'dropping'}`])}>
                    <TrendingItem media={media} index={index} calculateBarWidthPercentage={calculateBarWidthPercentage} platform={platform} />
                </a>
            </AppLink>
        ));
    }, [calculateBarWidthPercentage, isMediaPlaying, onPause, onPlayTrack, platform]);

    if (isLoading) {
        return <GhostComponent type="trending" />;
    }

    if (!trending || (!isLoading && trending.length === 0)) return null;

    return (
        /* eslint-disable-next-line @typescript-eslint/dot-notation */
        <div className={classNames(className, { 'spacing__window--horizontal': !noPadding })}>
            {!noTitle
                ? (
                    <div className={styles['trending__header']}>
                        <AppLink href={seeMorePath ?? '/trending'}>
                            <a aria-label="See more" className={classNames(styles['trending__title'], 'heading--h6', 'underline-link')}>
                                <SecondaryPageTitle title="Trending" noPadding />
                                <ChevronRight />
                            </a>
                        </AppLink>
                        {dropdownData && dropdownData?.length && (
                            <Dropdown<string>
                                onClick={(option) => {
                                    onDropdownChange?.(option);
                                }}
                                value={activeDropdownValue || ''}
                                options={[DEFAULT_DROPDOWN_CATEGORY, ...dropdownData]}
                            />
                        )}
                    </div>
                ) : null}

            <div className={styles['trending__list']}>
                {trending?.map((media, index) => {
                    return renderTrackItem(media, index);
                })}
            </div>

        </div>
    );
}

export default Trending;
