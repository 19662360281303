import { createContext, useContext, useMemo, useState } from 'react';
import { ActionModal, ActionModalProps } from './action-modal';

interface ActionModalContextProps {
    openModal: (modalProps: ActionModalProps) => void;
    closeModal: () => void;
}

const ActionModalContext = createContext<ActionModalContextProps>({} as ActionModalContextProps);

// eslint-disable-next-line react/prop-types
export const ActionModalProvider: React.FC = ({ children }) => {
    const [modalProps, setModalProps] = useState<ActionModalProps>(null);

    const value = useMemo<ActionModalContextProps>(
        () => ({
            closeModal: () => {
                setModalProps(null);
            },
            openModal: (props: ActionModalProps) => {
                setModalProps(props);
            },
        }),
        []
    );

    const { children: modalChildren, ...rest } = modalProps || ({} as ActionModalProps);

    return (
        <ActionModalContext.Provider value={value}>
            {modalProps && (
                <ActionModal {...rest} isOpen>
                    {modalChildren || null}
                </ActionModal>
            )}
            {children}
        </ActionModalContext.Provider>
    );
};

export default ActionModalProvider;

export const useActionModal = () => {
    const context: ActionModalContextProps = useContext(ActionModalContext);

    return context;
};
