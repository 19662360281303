import { GenericPaginatedQuery, Sound } from '@bpm-web-app/api-client';
import { FAVORITES_STALE_INTERVAL } from '@bpm-web-app/utils';
import { Preset, Sound as SoundModel } from '@bpm-web-app/create-api-sdk';
import useSWR from 'swr';

export function useGetFavoritedSounds(args?: Parameters<typeof Sound.getFavoritesSounds>[0], enabled = true) {
    const { data, error, mutate } = useSWR(
        [
            'sounds-favorite',
            {
                ...args,
                enabled,
                persist: true,
            },
        ],
        () => (enabled ? Sound.getFavoritesSounds(args) : null),
        {
            dedupingInterval: FAVORITES_STALE_INTERVAL,
            isPaused: () => !enabled,
        }
    );

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}

export function useGetFavoritedPresets(args?: Parameters<typeof Sound.getFavoritesPresets>[0], enabled = true) {
    const { data, error, mutate } = useSWR(
        [
            'presets-favorite',
            {
                ...args,
                enabled,
                persist: true,
            },
        ],
        () => (enabled ? Sound.getFavoritesPresets(args) : null),
        {
            dedupingInterval: FAVORITES_STALE_INTERVAL,
            isPaused: () => !enabled,
        }
    );

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}

export function useFollowSound() {
    return (id: Parameters<typeof Sound.favoriteSound>[0]) => Sound.favoriteSound(id);
}

export function useUnFollowSound() {
    return (id: Parameters<typeof Sound.unfavoriteSound>[0]) => Sound.unfavoriteSound(id);
}

export function useDownloadSound() {
    return (id: Parameters<typeof Sound.downloadSound>[0], location: string) => Sound.downloadSound(id, location);
}

export function useDownloadMultiSounds() {
    return (ids: Parameters<typeof Sound.downloadMultiSounds>[0], location: string) => Sound.downloadMultiSounds(ids, location);
}

export function useDownloadMultiSoundsPreview() {
    return (ids: Parameters<typeof Sound.downloadMultiSoundsPreview>[0], location: string) => Sound.downloadMultiSoundsPreview(ids, location);
}

export function useReportSound() {
    return (id: Parameters<typeof Sound.reportSound>[0]) => Sound.reportSound(id);
}

export function useStreamSound() {
    return (id: string, location: string) => Sound.streamSound(id, location);
}

export function useStreamSoundSetDuration() {
    return (id: string, duration: number) => Sound.streamSoundSetDuration(id, duration);
}

export function useGetSound(id: string, disabled?: boolean, currentSound?: SoundModel) {
    const { data, error, mutate } = useSWR(
        [
            'sound',
            disabled,
            {
                id,
                persist: true,
            },
        ],
        () => (disabled ? null : Sound.getSound(id)),
        { fallbackData: currentSound ? { data: currentSound } : undefined }
    );

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}

export function useGetPreset(id: string, disabled?: boolean, currentSound?: Preset) {
    const { data, error, mutate } = useSWR(
        [
            'preset',
            disabled,
            {
                id,
                persist: true,
            },
        ],
        () => (disabled ? null : Sound.getPreset(id)),
        { fallbackData: currentSound ? { data: currentSound } : undefined }
    );

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}

export function useGetSimilarSounds(id: string, args?: GenericPaginatedQuery) {
    const { data, error, mutate } = useSWR(
        [
            'similar-sounds',
            {
                id,
                persist: true,
            },
        ],
        () => Sound.getSimilarSounds(id, args)
    );

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}
