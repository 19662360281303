/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * BPM Create
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.18
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from 'url';
import * as isomorphicFetch from 'isomorphic-fetch';
import { Configuration } from './configuration';

const BASE_PATH = 'https://api.develop.create.bpmsupreme.com/v1.2'.replace(/\/+$/, '');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ',',
    ssv: ' ',
    tsv: '\t',
    pipes: '|',
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = isomorphicFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: 'RequiredError'
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum AudioFileType {
    Midi = <any> 'midi',
    Wav = <any> 'wav',
    Mid = <any> 'mid',
    Preset = <any> 'preset'
}
/**
 * 
 * @export
 * @interface Banner
 */
export interface Banner {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof Banner
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof Banner
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof Banner
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    location: string;
    /**
     * 
     * @type {Array<BannerImage>}
     * @memberof Banner
     */
    BannerImages: Array<BannerImage>;
}
/**
 * 
 * @export
 * @interface BannerImage
 */
export interface BannerImage {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof BannerImage
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof BannerImage
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof BannerImage
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof BannerImage
     */
    image_url_large?: string;
    /**
     * 
     * @type {string}
     * @memberof BannerImage
     */
    image_url_small?: string;
    /**
     * 
     * @type {string}
     * @memberof BannerImage
     */
    video_url_large?: string;
    /**
     * 
     * @type {string}
     * @memberof BannerImage
     */
    video_url_small?: string;
    /**
     * 
     * @type {string}
     * @memberof BannerImage
     */
    link?: string;
    /**
     * 
     * @type {number}
     * @memberof BannerImage
     */
    position: number;
    /**
     * 
     * @type {string}
     * @memberof BannerImage
     */
    banner_id: string;
}
/**
 * 
 * @export
 * @interface CommentsIdBody
 */
export interface CommentsIdBody {
    /**
     * The comment
     * @type {string}
     * @memberof CommentsIdBody
     */
    comment: string;
}
/**
 * 
 * @export
 * @interface Contest
 */
export interface Contest {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof Contest
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof Contest
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof Contest
     */
    updated_at: string;
    /**
     * Main colors of the contests artwork, seperated by comma. For example: #db6d4d, #261b27, #792e3a, #93948d, #8d8c8c
     * @type {string}
     * @memberof Contest
     */
    artwork_gradient_colors?: string;
    /**
     * The start date of the contest
     * @type {string}
     * @memberof Contest
     */
    start_date: string;
    /**
     * The submission deadline date of the contest. Deactivate submission button after this date.
     * @type {string}
     * @memberof Contest
     */
    submission_deadline: string;
    /**
     * The announcement date of the contest
     * @type {string}
     * @memberof Contest
     */
    announcement: string;
    /**
     * The title
     * @type {string}
     * @memberof Contest
     */
    title: string;
    /**
     * The optional subtitle
     * @type {string}
     * @memberof Contest
     */
    subtitle?: string;
    /**
     * The description as HTML string
     * @type {string}
     * @memberof Contest
     */
    description: string;
    /**
     * The rules as HTML string
     * @type {string}
     * @memberof Contest
     */
    rules?: string;
    /**
     * The slug
     * @type {string}
     * @memberof Contest
     */
    slug: string;
    /**
     * The song title
     * @type {string}
     * @memberof Contest
     */
    song_title: string;
    /**
     * The song artist
     * @type {string}
     * @memberof Contest
     */
    song_artist: string;
    /**
     * The zip URL to download
     * @type {string}
     * @memberof Contest
     */
    zip_url: string;
    /**
     * The artowrk URL
     * @type {string}
     * @memberof Contest
     */
    artwork_url: string;
    /**
     * The banner image URL
     * @type {string}
     * @memberof Contest
     */
    banner_image_url: string;
    /**
     * The terms PDF URL
     * @type {string}
     * @memberof Contest
     */
    terms_pdf_url?: string;
    /**
     * The share URL
     * @type {string}
     * @memberof Contest
     */
    share_url: string;
    /**
     * 
     * @type {ContestSongUrl}
     * @memberof Contest
     */
    song_url: ContestSongUrl;
    /**
     * 
     * @type {ContestSongWave}
     * @memberof Contest
     */
    song_wave: ContestSongWave;
    /**
     * If current user has submitted a entry
     * @type {boolean}
     * @memberof Contest
     */
    has_submitted_entry: boolean;
    /**
     * The contests prizes
     * @type {Array<ContestPrize>}
     * @memberof Contest
     */
    ContestPrizes: Array<ContestPrize>;
}
/**
 * 
 * @export
 * @interface ContestComment
 */
export interface ContestComment {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof ContestComment
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof ContestComment
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof ContestComment
     */
    updated_at: string;
    /**
     * Amount of likes on this comment
     * @type {number}
     * @memberof ContestComment
     */
    like_count: number;
    /**
     * The comment
     * @type {string}
     * @memberof ContestComment
     */
    comment: string;
    /**
     * If current user liked this comment
     * @type {boolean}
     * @memberof ContestComment
     */
    liked: boolean;
    /**
     * 
     * @type {ContestCommentUser}
     * @memberof ContestComment
     */
    user: ContestCommentUser;
}
/**
 * The user who commented
 * @export
 * @interface ContestCommentUser
 */
export interface ContestCommentUser {
    /**
     * The user id
     * @type {number}
     * @memberof ContestCommentUser
     */
    id: number;
    /**
     * The user name
     * @type {string}
     * @memberof ContestCommentUser
     */
    username: string;
    /**
     * The user full name
     * @type {string}
     * @memberof ContestCommentUser
     */
    full_name: string;
    /**
     * The user profile image thumbnail url
     * @type {string}
     * @memberof ContestCommentUser
     */
    profile_image_thumbnail_url?: string;
    /**
     * If the user is verified (show the verified badge)
     * @type {boolean}
     * @memberof ContestCommentUser
     */
    chat_verified?: boolean;
}
/**
 * 
 * @export
 * @interface ContestPrize
 */
export interface ContestPrize {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof ContestPrize
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof ContestPrize
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof ContestPrize
     */
    updated_at: string;
    /**
     * The prize title
     * @type {string}
     * @memberof ContestPrize
     */
    title: string;
    /**
     * The prize description
     * @type {string}
     * @memberof ContestPrize
     */
    description: string;
    /**
     * The prize external link
     * @type {string}
     * @memberof ContestPrize
     */
    link: string;
    /**
     * The prize order
     * @type {number}
     * @memberof ContestPrize
     */
    order: number;
    /**
     * The prize image URL
     * @type {string}
     * @memberof ContestPrize
     */
    image_url: string;
}
/**
 * The song streaming information
 * @export
 * @interface ContestSongUrl
 */
export interface ContestSongUrl {
    /**
     * 
     * @type {string}
     * @memberof ContestSongUrl
     */
    mp3: string;
    /**
     * 
     * @type {string}
     * @memberof ContestSongUrl
     */
    hls: string;
}
/**
 * The song wave url
 * @export
 * @interface ContestSongWave
 */
export interface ContestSongWave {
    /**
     * 
     * @type {string}
     * @memberof ContestSongWave
     */
    red: string;
    /**
     * 
     * @type {string}
     * @memberof ContestSongWave
     */
    gray: string;
    /**
     * 
     * @type {string}
     * @memberof ContestSongWave
     */
    light: string;
    /**
     * 
     * @type {string}
     * @memberof ContestSongWave
     */
    green: string;
}
/**
 * 
 * @export
 * @interface ContestSubmission
 */
export interface ContestSubmission {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof ContestSubmission
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof ContestSubmission
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof ContestSubmission
     */
    updated_at: string;
    /**
     * The submissions title
     * @type {string}
     * @memberof ContestSubmission
     */
    title: string;
    /**
     * The submissions description
     * @type {string}
     * @memberof ContestSubmission
     */
    description: string;
    /**
     * 
     * @type {ContestSubmissionSongUrl}
     * @memberof ContestSubmission
     */
    song_url: ContestSubmissionSongUrl;
    /**
     * 
     * @type {ContestSubmissionSongWave}
     * @memberof ContestSubmission
     */
    song_wave: ContestSubmissionSongWave;
    /**
     * 
     * @type {ContestSubmissionUser}
     * @memberof ContestSubmission
     */
    user: ContestSubmissionUser;
}
/**
 * The submissions song streaming information
 * @export
 * @interface ContestSubmissionSongUrl
 */
export interface ContestSubmissionSongUrl {
    /**
     * 
     * @type {string}
     * @memberof ContestSubmissionSongUrl
     */
    mp3: string;
    /**
     * 
     * @type {string}
     * @memberof ContestSubmissionSongUrl
     */
    hls: string;
}
/**
 * The submissions song wave url
 * @export
 * @interface ContestSubmissionSongWave
 */
export interface ContestSubmissionSongWave {
    /**
     * 
     * @type {string}
     * @memberof ContestSubmissionSongWave
     */
    red: string;
    /**
     * 
     * @type {string}
     * @memberof ContestSubmissionSongWave
     */
    gray: string;
    /**
     * 
     * @type {string}
     * @memberof ContestSubmissionSongWave
     */
    light: string;
    /**
     * 
     * @type {string}
     * @memberof ContestSubmissionSongWave
     */
    green: string;
}
/**
 * The user who submitted
 * @export
 * @interface ContestSubmissionUser
 */
export interface ContestSubmissionUser {
    /**
     * The user id
     * @type {number}
     * @memberof ContestSubmissionUser
     */
    id: number;
    /**
     * The user name
     * @type {string}
     * @memberof ContestSubmissionUser
     */
    username: string;
    /**
     * The user full name
     * @type {string}
     * @memberof ContestSubmissionUser
     */
    full_name: string;
    /**
     * The user profile image thumbnail url
     * @type {string}
     * @memberof ContestSubmissionUser
     */
    profile_image_thumbnail_url?: string;
    /**
     * If the user is verified (show the verified badge)
     * @type {boolean}
     * @memberof ContestSubmissionUser
     */
    chat_verified?: boolean;
}
/**
 * 
 * @export
 * @interface CreateCuratedSet
 */
export interface CreateCuratedSet {
    /**
     * Slug of the curated set
     * @type {string}
     * @memberof CreateCuratedSet
     */
    slug: string;
    /**
     * Name of the curated set
     * @type {string}
     * @memberof CreateCuratedSet
     */
    name: string;
    /**
     * Description of the curated set
     * @type {string}
     * @memberof CreateCuratedSet
     */
    description: string;
    /**
     * Artwork of the curated set. For convience set here too
     * @type {string}
     * @memberof CreateCuratedSet
     */
    artwork_url: string;
    /**
     * 
     * @type {SoundPackageDemoFileUrl}
     * @memberof CreateCuratedSet
     */
    demo_file_url?: SoundPackageDemoFileUrl;
    /**
     * Youtube tutorial url, if available
     * @type {string}
     * @memberof CreateCuratedSet
     */
    video_url?: string;
    /**
     * The drive id to import from
     * @type {string}
     * @memberof CreateCuratedSet
     */
    drive_id: string;
    /**
     * The curated category id
     * @type {string}
     * @memberof CreateCuratedSet
     */
    category_id: string;
}
/**
 * 
 * @export
 * @interface CuratedCategory
 */
export interface CuratedCategory {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof CuratedCategory
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof CuratedCategory
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof CuratedCategory
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof CuratedCategory
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof CuratedCategory
     */
    position: number;
}
/**
 * 
 * @export
 * @interface CuratedSet
 */
export interface CuratedSet {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof CuratedSet
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof CuratedSet
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof CuratedSet
     */
    updated_at: string;
    /**
     * Slug of the curated set
     * @type {string}
     * @memberof CuratedSet
     */
    slug: string;
    /**
     * Name of the curated set
     * @type {string}
     * @memberof CuratedSet
     */
    name: string;
    /**
     * Description of the curated set
     * @type {string}
     * @memberof CuratedSet
     */
    description: string;
    /**
     * True if curated set has been approved by QC
     * @type {boolean}
     * @memberof CuratedSet
     */
    approved?: boolean;
    /**
     * 
     * @type {Genre}
     * @memberof CuratedSet
     */
    Genre?: Genre;
    /**
     * If this sound is favorited by the user
     * @type {boolean}
     * @memberof CuratedSet
     */
    is_favorited: boolean;
    /**
     * Artwork of the curated set. For convience set here too
     * @type {string}
     * @memberof CuratedSet
     */
    artwork_url: string;
    /**
     * 
     * @type {SoundPackageDemoFileUrl}
     * @memberof CuratedSet
     */
    demo_file_url?: SoundPackageDemoFileUrl;
    /**
     * 
     * @type {SoundPackageDemoWave}
     * @memberof CuratedSet
     */
    demo_wave?: SoundPackageDemoWave;
    /**
     * Youtube tutorial url, if available
     * @type {string}
     * @memberof CuratedSet
     */
    video_url?: string;
    /**
     * Main colors of the curated set artwork, seperated by comma. For example: #db6d4d, #261b27, #792e3a, #93948d, #8d8c8c
     * @type {string}
     * @memberof CuratedSet
     */
    logo_gradient_colors?: string;
    /**
     * The curated category id
     * @type {string}
     * @memberof CuratedSet
     */
    curated_category_id?: string;
    /**
     * Amount of sounds in this curated pack
     * @type {number}
     * @memberof CuratedSet
     */
    sound_count?: number;
    /**
     * Amount of presets in this curated pack
     * @type {number}
     * @memberof CuratedSet
     */
    preset_count?: number;
    /**
     * Amount of midis in this curated pack
     * @type {number}
     * @memberof CuratedSet
     */
    midi_count?: number;
}
/**
 * 
 * @export
 * @interface Drive
 */
export interface Drive {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof Drive
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof Drive
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof Drive
     */
    updated_at: string;
    /**
     * Drive name
     * @type {string}
     * @memberof Drive
     */
    name: string;
    /**
     * Cover (Artwork) URL of the drive
     * @type {string}
     * @memberof Drive
     */
    image_url?: string;
    /**
     * Custom Cover (Artwork) URL of the drive
     * @type {string}
     * @memberof Drive
     */
    custom_image_url?: string;
    /**
     * Custom Cover (Artwork) Color of the drive
     * @type {string}
     * @memberof Drive
     */
    custom_image_color?: string;
    /**
     * Cover (Artwork) main color
     * @type {string}
     * @memberof Drive
     */
    image_color?: string;
    /**
     * If drive is owned by current user
     * @type {boolean}
     * @memberof Drive
     */
    is_own: boolean;
    /**
     * The link to invite users to thsi drive. Only set for owner
     * @type {string}
     * @memberof Drive
     */
    invitation_link?: string;
    /**
     * 
     * @type {UserDriveCollaborationAccessLevel}
     * @memberof Drive
     */
    access_level?: UserDriveCollaborationAccessLevel;
    /**
     * The drives collab status
     * @type {string}
     * @memberof Drive
     */
    collaboration_status?: Drive.CollaborationStatusEnum;
    /**
     * The drives collab access (if downgraded)
     * @type {string}
     * @memberof Drive
     */
    access?: Drive.AccessEnum;
    /**
     * 
     * @type {Array<UserDriveCollaboration>}
     * @memberof Drive
     */
    users?: Array<UserDriveCollaboration>;
    /**
     * 
     * @type {Array<DriveConnections>}
     * @memberof Drive
     */
    connections?: Array<DriveConnections>;
    /**
     * 
     * @type {DriveOwner}
     * @memberof Drive
     */
    owner?: DriveOwner;
    /**
     * Amount of sounds in this pack
     * @type {number}
     * @memberof Drive
     */
    sound_count?: number;
    /**
     * Amount of presets in this pack
     * @type {number}
     * @memberof Drive
     */
    preset_count?: number;
    /**
     * Amount of midis in this pack
     * @type {number}
     * @memberof Drive
     */
    midi_count?: number;
}

/**
 * @export
 * @namespace Drive
 */
export namespace Drive {
    /**
     * @export
     * @enum {string}
     */
    export enum CollaborationStatusEnum {
        Downgraded = <any> 'downgraded'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum AccessEnum {
        Downgraded = <any> 'downgraded',
        Expired = <any> 'expired'
    }
}
/**
 * 
 * @export
 * @interface DriveConnections
 */
export interface DriveConnections {
    /**
     * The id of the sound
     * @type {string}
     * @memberof DriveConnections
     */
    sound_id?: string;
    /**
     * The invite id
     * @type {string}
     * @memberof DriveConnections
     */
    added_by_key?: string;
    /**
     * If its the current users connection
     * @type {boolean}
     * @memberof DriveConnections
     */
    is_own?: boolean;
    /**
     * When was the sound added
     * @type {string}
     * @memberof DriveConnections
     */
    added_at?: string;
}
/**
 * 
 * @export
 * @interface DriveIdBody
 */
export interface DriveIdBody {
    /**
     * Name of the drive
     * @type {string}
     * @memberof DriveIdBody
     */
    name: string;
}
/**
 * 
 * @export
 * @interface DriveOwner
 */
export interface DriveOwner {
    /**
     * Email of the owner
     * @type {string}
     * @memberof DriveOwner
     */
    email: string;
    /**
     * The owners full name
     * @type {string}
     * @memberof DriveOwner
     */
    full_name: string;
    /**
     * The owners profile image
     * @type {string}
     * @memberof DriveOwner
     */
    profile_image_thumbnail_url: string;
}
/**
 * 
 * @export
 * @interface DriveinvitesIdBody
 */
export interface DriveinvitesIdBody {
    /**
     * 
     * @type {UserDriveCollaborationAccessLevel}
     * @memberof DriveinvitesIdBody
     */
    access_level: UserDriveCollaborationAccessLevel;
}
/**
 * 
 * @export
 * @interface DrivesBody
 */
export interface DrivesBody {
    /**
     * Name of the drive
     * @type {string}
     * @memberof DrivesBody
     */
    name: string;
}
/**
 * 
 * @export
 * @interface Genre
 */
export interface Genre {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof Genre
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof Genre
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof Genre
     */
    updated_at: string;
    /**
     * Name of Genre
     * @type {string}
     * @memberof Genre
     */
    name: string;
    /**
     * Slug of Genre
     * @type {string}
     * @memberof Genre
     */
    slug: string;
    /**
     * The category icon, as svg
     * @type {string}
     * @memberof Genre
     */
    icon_svg?: string;
    /**
     * Subgenres in genre
     * @type {Array<SubGenre>}
     * @memberof Genre
     */
    SubGenres: Array<SubGenre>;
}
/**
 * 
 * @export
 * @interface IdCommentBody
 */
export interface IdCommentBody {
    /**
     * The comment
     * @type {string}
     * @memberof IdCommentBody
     */
    comment: string;
}
/**
 * 
 * @export
 * @interface IdImageBody
 */
export interface IdImageBody {
    /**
     * The Image file
     * @type {Blob}
     * @memberof IdImageBody
     */
    file?: Blob;
}
/**
 * 
 * @export
 * @interface IdInviteBody
 */
export interface IdInviteBody {
    /**
     * The users email
     * @type {string}
     * @memberof IdInviteBody
     */
    email: string;
    /**
     * 
     * @type {UserDriveCollaborationAccessLevel}
     * @memberof IdInviteBody
     */
    access_level: UserDriveCollaborationAccessLevel;
}
/**
 * 
 * @export
 * @interface IdSubmitBody
 */
export interface IdSubmitBody {
    /**
     * The title of the song
     * @type {string}
     * @memberof IdSubmitBody
     */
    title: string;
    /**
     * The description of the song
     * @type {string}
     * @memberof IdSubmitBody
     */
    description?: string;
    /**
     * The MP3 file
     * @type {Blob}
     * @memberof IdSubmitBody
     */
    file: Blob;
}
/**
 * 
 * @export
 * @interface IdTagsBody
 */
export interface IdTagsBody {
    /**
     * 
     * @type {Array<string>}
     * @memberof IdTagsBody
     */
    id?: Array<string>;
}
/**
 * 
 * @export
 * @interface ImageTempBody
 */
export interface ImageTempBody {
    /**
     * The Image file
     * @type {Blob}
     * @memberof ImageTempBody
     */
    file?: Blob;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {InlineResponse200Data}
     * @memberof InlineResponse200
     */
    data: InlineResponse200Data;
}
/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {Array<Preset>}
     * @memberof InlineResponse2001
     */
    data: Array<Preset>;
    /**
     * 
     * @type {Pagination}
     * @memberof InlineResponse2001
     */
    pagination: Pagination;
}
/**
 * 
 * @export
 * @interface InlineResponse20010
 */
export interface InlineResponse20010 {
    /**
     * 
     * @type {InlineResponse20010Data}
     * @memberof InlineResponse20010
     */
    data: InlineResponse20010Data;
    /**
     * 
     * @type {Pagination}
     * @memberof InlineResponse20010
     */
    pagination: Pagination;
}
/**
 * 
 * @export
 * @interface InlineResponse20010Data
 */
export interface InlineResponse20010Data {
    /**
     * 
     * @type {Array<SoundPackage>}
     * @memberof InlineResponse20010Data
     */
    packs?: Array<SoundPackage>;
    /**
     * 
     * @type {Genre}
     * @memberof InlineResponse20010Data
     */
    genre?: Genre;
}
/**
 * 
 * @export
 * @interface InlineResponse20011
 */
export interface InlineResponse20011 {
    /**
     * 
     * @type {InlineResponse20011Data}
     * @memberof InlineResponse20011
     */
    data: InlineResponse20011Data;
    /**
     * 
     * @type {Pagination}
     * @memberof InlineResponse20011
     */
    pagination: Pagination;
}
/**
 * 
 * @export
 * @interface InlineResponse20011Data
 */
export interface InlineResponse20011Data {
    /**
     * 
     * @type {Array<SoundPackage>}
     * @memberof InlineResponse20011Data
     */
    packs: Array<SoundPackage>;
    /**
     * 
     * @type {SoundPackage}
     * @memberof InlineResponse20011Data
     */
    current: SoundPackage;
}
/**
 * 
 * @export
 * @interface InlineResponse20012
 */
export interface InlineResponse20012 {
    /**
     * 
     * @type {InlineResponse20012Data}
     * @memberof InlineResponse20012
     */
    data?: InlineResponse20012Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20012Data
 */
export interface InlineResponse20012Data {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20012Data
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse20013
 */
export interface InlineResponse20013 {
    /**
     * 
     * @type {InlineResponse20013Data}
     * @memberof InlineResponse20013
     */
    data: InlineResponse20013Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20013Data
 */
export interface InlineResponse20013Data {
    /**
     * 
     * @type {SoundPackage}
     * @memberof InlineResponse20013Data
     */
    pack: SoundPackage;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof InlineResponse20013Data
     */
    topTags: Array<Tag>;
}
/**
 * 
 * @export
 * @interface InlineResponse20014
 */
export interface InlineResponse20014 {
    /**
     * 
     * @type {SoundPackage}
     * @memberof InlineResponse20014
     */
    data: SoundPackage;
}
/**
 * 
 * @export
 * @interface InlineResponse20015
 */
export interface InlineResponse20015 {
    /**
     * 
     * @type {InlineResponse20015Data}
     * @memberof InlineResponse20015
     */
    data: InlineResponse20015Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20015Data
 */
export interface InlineResponse20015Data {
    /**
     * 
     * @type {Array<Contest>}
     * @memberof InlineResponse20015Data
     */
    current: Array<Contest>;
    /**
     * 
     * @type {Array<Contest>}
     * @memberof InlineResponse20015Data
     */
    past: Array<Contest>;
}
/**
 * 
 * @export
 * @interface InlineResponse20016
 */
export interface InlineResponse20016 {
    /**
     * 
     * @type {Array<ContestComment>}
     * @memberof InlineResponse20016
     */
    data: Array<ContestComment>;
}
/**
 * 
 * @export
 * @interface InlineResponse20017
 */
export interface InlineResponse20017 {
    /**
     * 
     * @type {ContestComment}
     * @memberof InlineResponse20017
     */
    data: ContestComment;
}
/**
 * 
 * @export
 * @interface InlineResponse20018
 */
export interface InlineResponse20018 {
    /**
     * 
     * @type {Array<ContestSubmission>}
     * @memberof InlineResponse20018
     */
    data: Array<ContestSubmission>;
}
/**
 * 
 * @export
 * @interface InlineResponse20019
 */
export interface InlineResponse20019 {
    /**
     * 
     * @type {ContestSubmission}
     * @memberof InlineResponse20019
     */
    data: ContestSubmission;
}
/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     * 
     * @type {Array<Sound>}
     * @memberof InlineResponse2002
     */
    data: Array<Sound>;
    /**
     * 
     * @type {Pagination}
     * @memberof InlineResponse2002
     */
    pagination: Pagination;
}
/**
 * 
 * @export
 * @interface InlineResponse20020
 */
export interface InlineResponse20020 {
    /**
     * 
     * @type {Banner}
     * @memberof InlineResponse20020
     */
    data: Banner;
}
/**
 * 
 * @export
 * @interface InlineResponse20021
 */
export interface InlineResponse20021 {
    /**
     * 
     * @type {Array<CuratedSet>}
     * @memberof InlineResponse20021
     */
    data: Array<CuratedSet>;
    /**
     * 
     * @type {Pagination}
     * @memberof InlineResponse20021
     */
    pagination: Pagination;
}
/**
 * 
 * @export
 * @interface InlineResponse20022
 */
export interface InlineResponse20022 {
    /**
     * 
     * @type {Array<InlineResponse20022Data>}
     * @memberof InlineResponse20022
     */
    data: Array<InlineResponse20022Data>;
}
/**
 * 
 * @export
 * @interface InlineResponse20022Data
 */
export interface InlineResponse20022Data {
    /**
     * 
     * @type {Array<CuratedSet>}
     * @memberof InlineResponse20022Data
     */
    sets?: Array<CuratedSet>;
    /**
     * 
     * @type {CuratedCategory}
     * @memberof InlineResponse20022Data
     */
    category?: CuratedCategory;
}
/**
 * 
 * @export
 * @interface InlineResponse20023
 */
export interface InlineResponse20023 {
    /**
     * 
     * @type {CuratedSet}
     * @memberof InlineResponse20023
     */
    data: CuratedSet;
}
/**
 * 
 * @export
 * @interface InlineResponse20024
 */
export interface InlineResponse20024 {
    /**
     * 
     * @type {InlineResponse20024Data}
     * @memberof InlineResponse20024
     */
    data: InlineResponse20024Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20024Data
 */
export interface InlineResponse20024Data {
    /**
     * 
     * @type {CuratedSet}
     * @memberof InlineResponse20024Data
     */
    pack: CuratedSet;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof InlineResponse20024Data
     */
    topTags: Array<Tag>;
}
/**
 * 
 * @export
 * @interface InlineResponse20025
 */
export interface InlineResponse20025 {
    /**
     * 
     * @type {Array<Drive>}
     * @memberof InlineResponse20025
     */
    data: Array<Drive>;
}
/**
 * 
 * @export
 * @interface InlineResponse20026
 */
export interface InlineResponse20026 {
    /**
     * 
     * @type {Drive}
     * @memberof InlineResponse20026
     */
    data: Drive;
}
/**
 * 
 * @export
 * @interface InlineResponse20027
 */
export interface InlineResponse20027 {
    /**
     * 
     * @type {Array<Label>}
     * @memberof InlineResponse20027
     */
    data: Array<Label>;
}
/**
 * 
 * @export
 * @interface InlineResponse20028
 */
export interface InlineResponse20028 {
    /**
     * 
     * @type {Label}
     * @memberof InlineResponse20028
     */
    data: Label;
}
/**
 * 
 * @export
 * @interface InlineResponse20029
 */
export interface InlineResponse20029 {
    /**
     * 
     * @type {any}
     * @memberof InlineResponse20029
     */
    data?: any;
}
/**
 * 
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
    /**
     * 
     * @type {Sound}
     * @memberof InlineResponse2003
     */
    data: Sound;
}
/**
 * 
 * @export
 * @interface InlineResponse20030
 */
export interface InlineResponse20030 {
    /**
     * 
     * @type {Array<Tag>}
     * @memberof InlineResponse20030
     */
    data: Array<Tag>;
}
/**
 * 
 * @export
 * @interface InlineResponse20031
 */
export interface InlineResponse20031 {
    /**
     * 
     * @type {Array<Genre>}
     * @memberof InlineResponse20031
     */
    data: Array<Genre>;
}
/**
 * 
 * @export
 * @interface InlineResponse20032
 */
export interface InlineResponse20032 {
    /**
     * 
     * @type {Array<Section>}
     * @memberof InlineResponse20032
     */
    data: Array<Section>;
}
/**
 * 
 * @export
 * @interface InlineResponse20033
 */
export interface InlineResponse20033 {
    /**
     * 
     * @type {Section}
     * @memberof InlineResponse20033
     */
    data: Section;
}
/**
 * 
 * @export
 * @interface InlineResponse20034
 */
export interface InlineResponse20034 {
    /**
     * 
     * @type {Array<UserDriveCollaboration>}
     * @memberof InlineResponse20034
     */
    data: Array<UserDriveCollaboration>;
}
/**
 * 
 * @export
 * @interface InlineResponse20035
 */
export interface InlineResponse20035 {
    /**
     * 
     * @type {UserDriveCollaboration}
     * @memberof InlineResponse20035
     */
    data: UserDriveCollaboration;
}
/**
 * 
 * @export
 * @interface InlineResponse20036
 */
export interface InlineResponse20036 {
    /**
     * 
     * @type {InlineResponse20036Data}
     * @memberof InlineResponse20036
     */
    data: InlineResponse20036Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20036Data
 */
export interface InlineResponse20036Data {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20036Data
     */
    url: string;
}
/**
 * 
 * @export
 * @interface InlineResponse20037
 */
export interface InlineResponse20037 {
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineResponse20037
     */
    data: Array<string>;
}
/**
 * 
 * @export
 * @interface InlineResponse20038
 */
export interface InlineResponse20038 {
    /**
     * 
     * @type {Array<TagCategory>}
     * @memberof InlineResponse20038
     */
    data: Array<TagCategory>;
}
/**
 * 
 * @export
 * @interface InlineResponse20039
 */
export interface InlineResponse20039 {
    /**
     * 
     * @type {InlineResponse20039Data}
     * @memberof InlineResponse20039
     */
    data: InlineResponse20039Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20039Data
 */
export interface InlineResponse20039Data {
    /**
     * 
     * @type {Array<CuratedCategory>}
     * @memberof InlineResponse20039Data
     */
    data?: Array<CuratedCategory>;
}
/**
 * 
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
    /**
     * 
     * @type {Preset}
     * @memberof InlineResponse2004
     */
    data: Preset;
}
/**
 * 
 * @export
 * @interface InlineResponse20040
 */
export interface InlineResponse20040 {
    /**
     * 
     * @type {InlineResponse20040Data}
     * @memberof InlineResponse20040
     */
    data: InlineResponse20040Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20040Data
 */
export interface InlineResponse20040Data {
    /**
     * 
     * @type {Array<Genre>}
     * @memberof InlineResponse20040Data
     */
    data?: Array<Genre>;
}
/**
 * 
 * @export
 * @interface InlineResponse20041
 */
export interface InlineResponse20041 {
    /**
     * 
     * @type {InlineResponse20041Data}
     * @memberof InlineResponse20041
     */
    data: InlineResponse20041Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20041Data
 */
export interface InlineResponse20041Data {
    /**
     * 
     * @type {Array<SubGenre>}
     * @memberof InlineResponse20041Data
     */
    data?: Array<SubGenre>;
}
/**
 * 
 * @export
 * @interface InlineResponse2005
 */
export interface InlineResponse2005 {
    /**
     * 
     * @type {any}
     * @memberof InlineResponse2005
     */
    data: any;
}
/**
 * 
 * @export
 * @interface InlineResponse2006
 */
export interface InlineResponse2006 {
    /**
     * 
     * @type {InlineResponse2006Data}
     * @memberof InlineResponse2006
     */
    data: InlineResponse2006Data;
}
/**
 * 
 * @export
 * @interface InlineResponse2006Data
 */
export interface InlineResponse2006Data {
    /**
     * Download URL
     * @type {string}
     * @memberof InlineResponse2006Data
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2007
 */
export interface InlineResponse2007 {
    /**
     * 
     * @type {Preview}
     * @memberof InlineResponse2007
     */
    data?: Preview;
}
/**
 * 
 * @export
 * @interface InlineResponse2008
 */
export interface InlineResponse2008 {
    /**
     * 
     * @type {Array<SoundPackage>}
     * @memberof InlineResponse2008
     */
    data: Array<SoundPackage>;
    /**
     * 
     * @type {Pagination}
     * @memberof InlineResponse2008
     */
    pagination: Pagination;
}
/**
 * 
 * @export
 * @interface InlineResponse2009
 */
export interface InlineResponse2009 {
    /**
     * 
     * @type {Array<SoundPackage>}
     * @memberof InlineResponse2009
     */
    data: Array<SoundPackage>;
}
/**
 * 
 * @export
 * @interface InlineResponse200Data
 */
export interface InlineResponse200Data {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse200Data
     */
    promo: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse200Data
     */
    regular: number;
    /**
     * If true, endless credits are available
     * @type {boolean}
     * @memberof InlineResponse200Data
     */
    hasAdminAccess: boolean;
}
/**
 * 
 * @export
 * @interface InternalServerError
 */
export interface InternalServerError {
}
/**
 * 
 * @export
 * @interface InvalidParametersError
 */
export interface InvalidParametersError extends Error {
    /**
     * 
     * @type {Array<string>}
     * @memberof InvalidParametersError
     */
    missing_keys: Array<string>;
    /**
     * object format: [invalid_key]: 'message'
     * @type {any}
     * @memberof InvalidParametersError
     */
    invalid_keys?: any;
}
/**
 * 
 * @export
 * @interface InvalidSubscriptionError
 */
export interface InvalidSubscriptionError extends Error {
    /**
     * 0 = None, 1 = Standard, 2 = Premium
     * @type {number}
     * @memberof InvalidSubscriptionError
     */
    current_supscription?: InvalidSubscriptionError.CurrentSupscriptionEnum;
    /**
     * 0 = None, 1 = Standard, 2 = Premium
     * @type {number}
     * @memberof InvalidSubscriptionError
     */
    required_subscription?: InvalidSubscriptionError.RequiredSubscriptionEnum;
}

/**
 * @export
 * @namespace InvalidSubscriptionError
 */
export namespace InvalidSubscriptionError {
    /**
     * @export
     * @enum {string}
     */
    export enum CurrentSupscriptionEnum {
        NUMBER_0 = <any> 0,
        NUMBER_1 = <any> 1,
        NUMBER_2 = <any> 2
    }
    /**
     * @export
     * @enum {string}
     */
    export enum RequiredSubscriptionEnum {
        NUMBER_0 = <any> 0,
        NUMBER_1 = <any> 1,
        NUMBER_2 = <any> 2
    }
}
/**
 * 
 * @export
 * @interface KeyExpiredError
 */
export interface KeyExpiredError {
}
/**
 * 
 * @export
 * @interface Label
 */
export interface Label {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof Label
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof Label
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof Label
     */
    updated_at: string;
    /**
     * Label description
     * @type {string}
     * @memberof Label
     */
    description: string;
    /**
     * Label logo
     * @type {string}
     * @memberof Label
     */
    logo_url: string;
    /**
     * Main colors of the label logo, seperated by comma. For example: #db6d4d, #261b27
     * @type {string}
     * @memberof Label
     */
    gradient_color: string;
    /**
     * Name of Label
     * @type {string}
     * @memberof Label
     */
    name: string;
    /**
     * Amount of sound packages this label has
     * @type {number}
     * @memberof Label
     */
    soundpackage_count?: number;
    /**
     * Top tags for this label
     * @type {Array<LabelTopTags>}
     * @memberof Label
     */
    top_tags?: Array<LabelTopTags>;
    /**
     * Top Genres for this albel
     * @type {Array<Genre>}
     * @memberof Label
     */
    top_genres?: Array<Genre>;
}
/**
 * 
 * @export
 * @interface LabelTopTags
 */
export interface LabelTopTags {
    /**
     * 
     * @type {string}
     * @memberof LabelTopTags
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LabelTopTags
     */
    name: string;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * Error Type
     * @type {string}
     * @memberof ModelError
     */
    type: string;
    /**
     * The HTTP Code
     * @type {number}
     * @memberof ModelError
     */
    code: number;
    /**
     * Message. Can be displayed to the user (not localized)
     * @type {string}
     * @memberof ModelError
     */
    message: string;
}
/**
 * 
 * @export
 * @interface Mp3TempBody
 */
export interface Mp3TempBody {
    /**
     * The Image file
     * @type {Blob}
     * @memberof Mp3TempBody
     */
    file?: Blob;
}
/**
 * 
 * @export
 * @interface NotAccessibleError
 */
export interface NotAccessibleError {
}
/**
 * 
 * @export
 * @interface NotEnoughCreditsError
 */
export interface NotEnoughCreditsError extends Error {
    /**
     * 
     * @type {number}
     * @memberof NotEnoughCreditsError
     */
    credits_required: number;
    /**
     * 
     * @type {number}
     * @memberof NotEnoughCreditsError
     */
    credits_available: number;
    /**
     * 
     * @type {boolean}
     * @memberof NotEnoughCreditsError
     */
    trial_applicable: boolean;
}
/**
 * 
 * @export
 * @interface Pagination
 */
export interface Pagination {
    /**
     * Total number of entities
     * @type {number}
     * @memberof Pagination
     */
    total: number;
    /**
     * Number of skipped entities
     * @type {number}
     * @memberof Pagination
     */
    skipped: number;
    /**
     * Max amount of entities to return
     * @type {number}
     * @memberof Pagination
     */
    limited: number;
    /**
     * Current page
     * @type {number}
     * @memberof Pagination
     */
    page: number;
}
/**
 * 
 * @export
 * @interface Preset
 */
export interface Preset extends Sound {
    /**
     * The synth type of the preset
     * @type {string}
     * @memberof Preset
     */
    synth: string;
    /**
     * 
     * @type {Sound}
     * @memberof Preset
     */
    preset_preview?: Sound;
    /**
     * 
     * @type {PresetSoundPackage}
     * @memberof Preset
     */
    SoundPackage?: PresetSoundPackage;
}
/**
 * 
 * @export
 * @interface PresetSoundPackage
 */
export interface PresetSoundPackage {
    /**
     * 
     * @type {Genre}
     * @memberof PresetSoundPackage
     */
    Genre?: Genre;
}
/**
 * 
 * @export
 * @interface Preview
 */
export interface Preview {
    /**
     * 
     * @type {PreviewRequired}
     * @memberof Preview
     */
    required: PreviewRequired;
    /**
     * 
     * @type {PreviewReduction}
     * @memberof Preview
     */
    reduction: PreviewReduction;
    /**
     * 
     * @type {PreviewReduction}
     * @memberof Preview
     */
    available: PreviewReduction;
    /**
     * 
     * @type {boolean}
     * @memberof Preview
     */
    allowed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Preview
     */
    trial_applicable: boolean;
}
/**
 * 
 * @export
 * @interface PreviewReduction
 */
export interface PreviewReduction {
    /**
     * 
     * @type {number}
     * @memberof PreviewReduction
     */
    regular: number;
    /**
     * 
     * @type {number}
     * @memberof PreviewReduction
     */
    promo: number;
}
/**
 * 
 * @export
 * @interface PreviewRequired
 */
export interface PreviewRequired {
    /**
     * 
     * @type {number}
     * @memberof PreviewRequired
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof PreviewRequired
     */
    promo: number;
    /**
     * 
     * @type {number}
     * @memberof PreviewRequired
     */
    regular: number;
}
/**
 * 
 * @export
 * @interface ResourceAlreadyExists
 */
export interface ResourceAlreadyExists extends Error {
    /**
     * 
     * @type {Array<string>}
     * @memberof ResourceAlreadyExists
     */
    existing_keys?: Array<string>;
}
/**
 * 
 * @export
 * @interface ResourceNotFoundError
 */
export interface ResourceNotFoundError extends Error {
    /**
     * The resource type
     * @type {string}
     * @memberof ResourceNotFoundError
     */
    resource_type?: string;
}
/**
 * 
 * @export
 * @interface Section
 */
export interface Section {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof Section
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof Section
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof Section
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof Section
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Section
     */
    position: number;
    /**
     * 
     * @type {string}
     * @memberof Section
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof Section
     */
    type: Section.TypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Section
     */
    active: boolean;
    /**
     * 
     * @type {Array<SoundPackage> | Array<CuratedSet>}
     * @memberof Section
     */
    items: Array<SoundPackage> | Array<CuratedSet>;
}

/**
 * @export
 * @namespace Section
 */
export namespace Section {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Virtual = <any> 'virtual',
        Section = <any> 'section'
    }
}
/**
 * 
 * @export
 * @interface SessionNotFoundError
 */
export interface SessionNotFoundError {
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SortByQueryOptions {
    DateAsc = <any> 'date_asc',
    DateDesc = <any> 'date_desc',
    TitleAsc = <any> 'title_asc',
    TitleDesc = <any> 'title_desc',
    BpmAsc = <any> 'bpm_asc',
    BpmDesc = <any> 'bpm_desc',
    Relevance = <any> 'relevance'
}
/**
 * 
 * @export
 * @interface Sound
 */
export interface Sound {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof Sound
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof Sound
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof Sound
     */
    updated_at: string;
    /**
     * Sound name
     * @type {string}
     * @memberof Sound
     */
    name: string;
    /**
     * BPM of Sound, if available
     * @type {number}
     * @memberof Sound
     */
    bpm?: number;
    /**
     * Key of Sound, if available
     * @type {string}
     * @memberof Sound
     */
    key?: string;
    /**
     * 
     * @type {AudioFileType}
     * @memberof Sound
     */
    type: AudioFileType;
    /**
     * Duration of Sound
     * @type {string}
     * @memberof Sound
     */
    duration: string;
    /**
     * Size of Sound
     * @type {number}
     * @memberof Sound
     */
    size: number;
    /**
     * ID of parent Sound Package
     * @type {string}
     * @memberof Sound
     */
    sound_package_id?: string;
    /**
     * Trending status. Negative number if status went down, positive number if status went up
     * @type {number}
     * @memberof Sound
     */
    trending_status?: number;
    /**
     * Silence info, if available. In format of: s=startSecond;e=endSecond|..., for example: s=28.9158;e=31.2063 For skip start silence, only execute if a s=0.0 is given
     * @type {string}
     * @memberof Sound
     */
    silence_info?: string;
    /**
     * 
     * @type {SoundFileUrl}
     * @memberof Sound
     */
    file_url?: SoundFileUrl;
    /**
     * 
     * @type {SoundPricing}
     * @memberof Sound
     */
    pricing: SoundPricing;
    /**
     * If current sound has stream info. MiDi files for example do not have streams to play
     * @type {boolean}
     * @memberof Sound
     */
    has_stream_info: boolean;
    /**
     * 
     * @type {SoundWave}
     * @memberof Sound
     */
    wave: SoundWave;
    /**
     * Artists of the sound package. For convience set here too
     * @type {string}
     * @memberof Sound
     */
    artist: string;
    /**
     * Artwork of the sound package. For convience set here too
     * @type {string}
     * @memberof Sound
     */
    artwork_url: string;
    /**
     * Name of the sound package. For convience set here too
     * @type {string}
     * @memberof Sound
     */
    sound_package_name: string;
    /**
     * True if this sound is a BPM Create created sounds
     * @type {boolean}
     * @memberof Sound
     */
    is_create_sound: boolean;
    /**
     * 
     * @type {Sound}
     * @memberof Sound
     */
    midi?: Sound;
    /**
     * Tags assocaited to this sound
     * @type {Array<Tag>}
     * @memberof Sound
     */
    Tags?: Array<Tag>;
    /**
     * If this sound is favorited by the user
     * @type {boolean}
     * @memberof Sound
     */
    is_favorited: boolean;
    /**
     * If this sound was downloaded by the user
     * @type {boolean}
     * @memberof Sound
     */
    downloaded: boolean;
    /**
     * Last time thsi sound was downlaoded. Only set on download history endpoints
     * @type {string}
     * @memberof Sound
     */
    last_downloaded?: string;
    /**
     * If this sound was streamed by the user
     * @type {number}
     * @memberof Sound
     */
    streamed: number;
}
/**
 * Streaming info
 * @export
 * @interface SoundFileUrl
 */
export interface SoundFileUrl {
    /**
     * 
     * @type {string}
     * @memberof SoundFileUrl
     */
    mp3: string;
    /**
     * 
     * @type {string}
     * @memberof SoundFileUrl
     */
    hls: string;
}
/**
 * 
 * @export
 * @interface SoundPackage
 */
export interface SoundPackage {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof SoundPackage
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof SoundPackage
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof SoundPackage
     */
    updated_at: string;
    /**
     * Slug of the sound package
     * @type {string}
     * @memberof SoundPackage
     */
    slug: string;
    /**
     * Name of the sound package
     * @type {string}
     * @memberof SoundPackage
     */
    name: string;
    /**
     * Description of the sound package
     * @type {string}
     * @memberof SoundPackage
     */
    description: string;
    /**
     * Artist of the sound package
     * @type {string}
     * @memberof SoundPackage
     */
    artist: string;
    /**
     * True if sound package has been approved by QC
     * @type {boolean}
     * @memberof SoundPackage
     */
    approved?: boolean;
    /**
     * If this sound is favorited by the user
     * @type {boolean}
     * @memberof SoundPackage
     */
    is_favorited: boolean;
    /**
     * Artwork of the sound package. For convience set here too
     * @type {string}
     * @memberof SoundPackage
     */
    artwork_url: string;
    /**
     * 
     * @type {SoundPackageDemoFileUrl}
     * @memberof SoundPackage
     */
    demo_file_url?: SoundPackageDemoFileUrl;
    /**
     * 
     * @type {SoundPackageDemoWave}
     * @memberof SoundPackage
     */
    demo_wave?: SoundPackageDemoWave;
    /**
     * 
     * @type {SoundPackageDemoFileUrl2}
     * @memberof SoundPackage
     */
    demo_file_url_2?: SoundPackageDemoFileUrl2;
    /**
     * 
     * @type {SoundPackageDemoWave}
     * @memberof SoundPackage
     */
    demo_wave_2?: SoundPackageDemoWave;
    /**
     * Youtube tutorial url, if available
     * @type {string}
     * @memberof SoundPackage
     */
    video_url?: string;
    /**
     * 
     * @type {Label}
     * @memberof SoundPackage
     */
    Label: Label;
    /**
     * 
     * @type {Genre}
     * @memberof SoundPackage
     */
    Genre: Genre;
    /**
     * The genre id
     * @type {string}
     * @memberof SoundPackage
     */
    genre_id?: string;
    /**
     * SubGenres of the sound package
     * @type {Array<SubGenre>}
     * @memberof SoundPackage
     */
    SubGenres: Array<SubGenre>;
    /**
     * Main colors of the sound package artwork, seperated by comma. For example: #db6d4d, #261b27, #792e3a, #93948d, #8d8c8c
     * @type {string}
     * @memberof SoundPackage
     */
    logo_gradient_colors?: string;
    /**
     * The badge for this playlist
     * @type {string}
     * @memberof SoundPackage
     */
    badge?: SoundPackage.BadgeEnum;
    /**
     * Amount of sounds in this pack
     * @type {number}
     * @memberof SoundPackage
     */
    sound_count: number;
    /**
     * Amount of presets in this pack
     * @type {number}
     * @memberof SoundPackage
     */
    preset_count: number;
    /**
     * Amount of midis in this pack
     * @type {number}
     * @memberof SoundPackage
     */
    midi_count: number;
}

/**
 * @export
 * @namespace SoundPackage
 */
export namespace SoundPackage {
    /**
     * @export
     * @enum {string}
     */
    export enum BadgeEnum {
        Exclusive = <any> 'exclusive',
        Top = <any> 'top',
        StaffPick = <any> 'staff-pick',
        Trending = <any> 'trending'
    }
}
/**
 * Demo stream information, if available
 * @export
 * @interface SoundPackageDemoFileUrl
 */
export interface SoundPackageDemoFileUrl {
    /**
     * 
     * @type {string}
     * @memberof SoundPackageDemoFileUrl
     */
    mp3: string;
    /**
     * 
     * @type {string}
     * @memberof SoundPackageDemoFileUrl
     */
    hls: string;
}
/**
 * Demo 2 stream information, if available
 * @export
 * @interface SoundPackageDemoFileUrl2
 */
export interface SoundPackageDemoFileUrl2 {
    /**
     * 
     * @type {string}
     * @memberof SoundPackageDemoFileUrl2
     */
    mp3: string;
    /**
     * 
     * @type {string}
     * @memberof SoundPackageDemoFileUrl2
     */
    hls: string;
}
/**
 * Demo wave information, if available
 * @export
 * @interface SoundPackageDemoWave
 */
export interface SoundPackageDemoWave {
    /**
     * 
     * @type {string}
     * @memberof SoundPackageDemoWave
     */
    red: string;
    /**
     * 
     * @type {string}
     * @memberof SoundPackageDemoWave
     */
    gray: string;
    /**
     * 
     * @type {string}
     * @memberof SoundPackageDemoWave
     */
    light: string;
    /**
     * 
     * @type {string}
     * @memberof SoundPackageDemoWave
     */
    green: string;
}
/**
 * Pricing info
 * @export
 * @interface SoundPricing
 */
export interface SoundPricing {
    /**
     * Credit value
     * @type {number}
     * @memberof SoundPricing
     */
    value: number;
    /**
     * If discounted, set to end of discount
     * @type {string}
     * @memberof SoundPricing
     */
    valid_unitl?: string;
}
/**
 * Different colored waves
 * @export
 * @interface SoundWave
 */
export interface SoundWave {
    /**
     * 
     * @type {string}
     * @memberof SoundWave
     */
    red: string;
    /**
     * 
     * @type {string}
     * @memberof SoundWave
     */
    gray: string;
    /**
     * 
     * @type {string}
     * @memberof SoundWave
     */
    light: string;
    /**
     * 
     * @type {string}
     * @memberof SoundWave
     */
    green: string;
}
/**
 * 
 * @export
 * @interface StreamDurationBody
 */
export interface StreamDurationBody {
    /**
     * 
     * @type {number}
     * @memberof StreamDurationBody
     */
    duration?: number;
}
/**
 * 
 * @export
 */
export type StringOrStringArray = string | Array<string>
/**
 * 
 * @export
 * @interface SubGenre
 */
export interface SubGenre {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof SubGenre
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof SubGenre
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof SubGenre
     */
    updated_at: string;
    /**
     * Name of SubGenre
     * @type {string}
     * @memberof SubGenre
     */
    name: string;
    /**
     * Slug of SubGenre
     * @type {string}
     * @memberof SubGenre
     */
    slug: string;
}
/**
 * 
 * @export
 * @interface SubmissionIdBody
 */
export interface SubmissionIdBody {
    /**
     * The title of the song
     * @type {string}
     * @memberof SubmissionIdBody
     */
    title?: string;
    /**
     * The description of the song
     * @type {string}
     * @memberof SubmissionIdBody
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof Tag
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof Tag
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof Tag
     */
    updated_at: string;
    /**
     * Name of the tag
     * @type {string}
     * @memberof Tag
     */
    name: string;
    /**
     * 
     * @type {TagGroup}
     * @memberof Tag
     */
    TagGroup?: TagGroup;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    tag_group_id?: string;
}
/**
 * 
 * @export
 * @interface TagCategory
 */
export interface TagCategory {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof TagCategory
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof TagCategory
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof TagCategory
     */
    updated_at: string;
    /**
     * Name of the tag category
     * @type {string}
     * @memberof TagCategory
     */
    name: string;
    /**
     * 
     * @type {Array<TagGroup>}
     * @memberof TagCategory
     */
    items: Array<TagGroup>;
}
/**
 * 
 * @export
 * @interface TagGroup
 */
export interface TagGroup {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof TagGroup
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof TagGroup
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof TagGroup
     */
    updated_at: string;
    /**
     * Name of the tag group
     * @type {string}
     * @memberof TagGroup
     */
    name: string;
    /**
     * 
     * @type {TagCategory}
     * @memberof TagGroup
     */
    TagCategory: TagCategory;
    /**
     * 
     * @type {string}
     * @memberof TagGroup
     */
    tag_category_id: string;
    /**
     * Tags in this group.
     * @type {Array<Tag>}
     * @memberof TagGroup
     */
    items: Array<Tag>;
}
/**
 * 
 * @export
 * @interface UploadPackgeIdBody
 */
export interface UploadPackgeIdBody {
    /**
     * The Image file
     * @type {Blob}
     * @memberof UploadPackgeIdBody
     */
    file?: Blob;
}
/**
 * 
 * @export
 * @interface UserDriveCollaboration
 */
export interface UserDriveCollaboration {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof UserDriveCollaboration
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof UserDriveCollaboration
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof UserDriveCollaboration
     */
    updated_at: string;
    /**
     * Email of the invited user
     * @type {string}
     * @memberof UserDriveCollaboration
     */
    email: string;
    /**
     * The user drive id
     * @type {string}
     * @memberof UserDriveCollaboration
     */
    drive_id: string;
    /**
     * The users full name. Only populated when user accepted
     * @type {string}
     * @memberof UserDriveCollaboration
     */
    full_name?: string;
    /**
     * The users profile image. Only populated when user accepted
     * @type {string}
     * @memberof UserDriveCollaboration
     */
    profile_image_thumbnail_url?: string;
    /**
     * 
     * @type {UserDriveCollaborationStatus}
     * @memberof UserDriveCollaboration
     */
    status: UserDriveCollaborationStatus;
    /**
     * 
     * @type {UserDriveCollaborationAccessLevel}
     * @memberof UserDriveCollaboration
     */
    access_level: UserDriveCollaborationAccessLevel;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum UserDriveCollaborationAccessLevel {
    View = <any> 'view',
    Edit = <any> 'edit',
    Manage = <any> 'manage'
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum UserDriveCollaborationStatus {
    Invited = <any> 'invited',
    Declined = <any> 'declined',
    Accepted = <any> 'accepted'
}
/**
 * AdminApi - fetch parameter creator
 * @export
 */
export const AdminApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Tags to Sound
         * @param {string} id The sound Id
         * @param {IdTagsBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAddTagSound(id: string, body?: IdTagsBody, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminAddTagSound.');
            }
            const localVarPath = `/admin/sound/{id}/tags`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'IdTagsBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Curated Set
         * @param {CreateCuratedSet} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateCuratedSet(body?: CreateCuratedSet, options: any = {}): FetchArgs {
            const localVarPath = `/admin/curated`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'CreateCuratedSet' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Curated Categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCuratedCategories(options: any = {}): FetchArgs {
            const localVarPath = `/admin/curatedcategory`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Genres
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetGenres(options: any = {}): FetchArgs {
            const localVarPath = `/admin/genre`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetKeys(options: any = {}): FetchArgs {
            const localVarPath = `/admin/keys/names`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Sub Genres
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSubGenres(options: any = {}): FetchArgs {
            const localVarPath = `/admin/subgenre`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Tag Categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTagCategories(options: any = {}): FetchArgs {
            const localVarPath = `/admin/tagcategories-full`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add Tags to Sound
         * @param {string} id The sound Id
         * @param {string} tagId The tag Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRemoveTagSound(id: string, tagId: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminRemoveTagSound.');
            }
            // verify required parameter 'tagId' is not null or undefined
            if (tagId === null || tagId === undefined) {
                throw new RequiredError('tagId','Required parameter tagId was null or undefined when calling adminRemoveTagSound.');
            }
            const localVarPath = `/admin/sound/{id}/tags/{tagId}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'tagId'}}`, encodeURIComponent(String(tagId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Sound
         * @param {string} id The sound Id
         * @param {Sound} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateSound(id: string, body?: Sound, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminUpdateSound.');
            }
            const localVarPath = `/admin/sound/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'Sound' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Sound Package
         * @param {string} id The sound package Id
         * @param {SoundPackage} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateSoundPackage(id: string, body?: SoundPackage, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminUpdateSoundPackage.');
            }
            const localVarPath = `/admin/soundpackage/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'SoundPackage' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload Curated Temp MP3 Demo
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUploadCuratedDemo(file?: Blob, options: any = {}): FetchArgs {
            const localVarPath = `/admin/curated/mp3/temp`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (file !== undefined) {
                localVarFormParams.set('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload Curated Temp Image
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUploadCuratedImage(file?: Blob, options: any = {}): FetchArgs {
            const localVarPath = `/admin/curated/image/temp`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (file !== undefined) {
                localVarFormParams.set('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload Sound File
         * @param {string} id The sound package Id
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUploadSoundFile(id: string, file?: Blob, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminUploadSoundFile.');
            }
            const localVarPath = `/admin/sound/upload/{packgeId}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (file !== undefined) {
                localVarFormParams.set('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Tags to Sound
         * @param {string} id The sound Id
         * @param {IdTagsBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAddTagSound(id: string, body?: IdTagsBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = AdminApiFetchParamCreator(configuration).adminAddTagSound(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create Curated Set
         * @param {CreateCuratedSet} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateCuratedSet(body?: CreateCuratedSet, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20023> {
            const localVarFetchArgs = AdminApiFetchParamCreator(configuration).adminCreateCuratedSet(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Curated Categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCuratedCategories(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20039> {
            const localVarFetchArgs = AdminApiFetchParamCreator(configuration).adminGetCuratedCategories(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Genres
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetGenres(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20040> {
            const localVarFetchArgs = AdminApiFetchParamCreator(configuration).adminGetGenres(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetKeys(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20037> {
            const localVarFetchArgs = AdminApiFetchParamCreator(configuration).adminGetKeys(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Sub Genres
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSubGenres(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20041> {
            const localVarFetchArgs = AdminApiFetchParamCreator(configuration).adminGetSubGenres(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Tag Categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTagCategories(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20038> {
            const localVarFetchArgs = AdminApiFetchParamCreator(configuration).adminGetTagCategories(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add Tags to Sound
         * @param {string} id The sound Id
         * @param {string} tagId The tag Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRemoveTagSound(id: string, tagId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = AdminApiFetchParamCreator(configuration).adminRemoveTagSound(id, tagId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update Sound
         * @param {string} id The sound Id
         * @param {Sound} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateSound(id: string, body?: Sound, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = AdminApiFetchParamCreator(configuration).adminUpdateSound(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update Sound Package
         * @param {string} id The sound package Id
         * @param {SoundPackage} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateSoundPackage(id: string, body?: SoundPackage, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20014> {
            const localVarFetchArgs = AdminApiFetchParamCreator(configuration).adminUpdateSoundPackage(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Upload Curated Temp MP3 Demo
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUploadCuratedDemo(file?: Blob, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20036> {
            const localVarFetchArgs = AdminApiFetchParamCreator(configuration).adminUploadCuratedDemo(file, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Upload Curated Temp Image
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUploadCuratedImage(file?: Blob, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20036> {
            const localVarFetchArgs = AdminApiFetchParamCreator(configuration).adminUploadCuratedImage(file, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Upload Sound File
         * @param {string} id The sound package Id
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUploadSoundFile(id: string, file?: Blob, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20036> {
            const localVarFetchArgs = AdminApiFetchParamCreator(configuration).adminUploadSoundFile(id, file, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Add Tags to Sound
         * @param {string} id The sound Id
         * @param {IdTagsBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAddTagSound(id: string, body?: IdTagsBody, options?: any) {
            return AdminApiFp(configuration).adminAddTagSound(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create Curated Set
         * @param {CreateCuratedSet} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateCuratedSet(body?: CreateCuratedSet, options?: any) {
            return AdminApiFp(configuration).adminCreateCuratedSet(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Curated Categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCuratedCategories(options?: any) {
            return AdminApiFp(configuration).adminGetCuratedCategories(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Genres
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetGenres(options?: any) {
            return AdminApiFp(configuration).adminGetGenres(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetKeys(options?: any) {
            return AdminApiFp(configuration).adminGetKeys(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Sub Genres
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSubGenres(options?: any) {
            return AdminApiFp(configuration).adminGetSubGenres(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Tag Categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTagCategories(options?: any) {
            return AdminApiFp(configuration).adminGetTagCategories(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add Tags to Sound
         * @param {string} id The sound Id
         * @param {string} tagId The tag Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRemoveTagSound(id: string, tagId: string, options?: any) {
            return AdminApiFp(configuration).adminRemoveTagSound(id, tagId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update Sound
         * @param {string} id The sound Id
         * @param {Sound} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateSound(id: string, body?: Sound, options?: any) {
            return AdminApiFp(configuration).adminUpdateSound(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update Sound Package
         * @param {string} id The sound package Id
         * @param {SoundPackage} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateSoundPackage(id: string, body?: SoundPackage, options?: any) {
            return AdminApiFp(configuration).adminUpdateSoundPackage(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Upload Curated Temp MP3 Demo
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUploadCuratedDemo(file?: Blob, options?: any) {
            return AdminApiFp(configuration).adminUploadCuratedDemo(file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Upload Curated Temp Image
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUploadCuratedImage(file?: Blob, options?: any) {
            return AdminApiFp(configuration).adminUploadCuratedImage(file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Upload Sound File
         * @param {string} id The sound package Id
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUploadSoundFile(id: string, file?: Blob, options?: any) {
            return AdminApiFp(configuration).adminUploadSoundFile(id, file, options)(fetch, basePath);
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @summary Add Tags to Sound
     * @param {string} id The sound Id
     * @param {IdTagsBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminAddTagSound(id: string, body?: IdTagsBody, options?: any) {
        return AdminApiFp(this.configuration).adminAddTagSound(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create Curated Set
     * @param {CreateCuratedSet} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCreateCuratedSet(body?: CreateCuratedSet, options?: any) {
        return AdminApiFp(this.configuration).adminCreateCuratedSet(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Curated Categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCuratedCategories(options?: any) {
        return AdminApiFp(this.configuration).adminGetCuratedCategories(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Genres
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetGenres(options?: any) {
        return AdminApiFp(this.configuration).adminGetGenres(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Keys
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetKeys(options?: any) {
        return AdminApiFp(this.configuration).adminGetKeys(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Sub Genres
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetSubGenres(options?: any) {
        return AdminApiFp(this.configuration).adminGetSubGenres(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Tag Categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetTagCategories(options?: any) {
        return AdminApiFp(this.configuration).adminGetTagCategories(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add Tags to Sound
     * @param {string} id The sound Id
     * @param {string} tagId The tag Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminRemoveTagSound(id: string, tagId: string, options?: any) {
        return AdminApiFp(this.configuration).adminRemoveTagSound(id, tagId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update Sound
     * @param {string} id The sound Id
     * @param {Sound} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpdateSound(id: string, body?: Sound, options?: any) {
        return AdminApiFp(this.configuration).adminUpdateSound(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update Sound Package
     * @param {string} id The sound package Id
     * @param {SoundPackage} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpdateSoundPackage(id: string, body?: SoundPackage, options?: any) {
        return AdminApiFp(this.configuration).adminUpdateSoundPackage(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Upload Curated Temp MP3 Demo
     * @param {Blob} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUploadCuratedDemo(file?: Blob, options?: any) {
        return AdminApiFp(this.configuration).adminUploadCuratedDemo(file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Upload Curated Temp Image
     * @param {Blob} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUploadCuratedImage(file?: Blob, options?: any) {
        return AdminApiFp(this.configuration).adminUploadCuratedImage(file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Upload Sound File
     * @param {string} id The sound package Id
     * @param {Blob} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUploadSoundFile(id: string, file?: Blob, options?: any) {
        return AdminApiFp(this.configuration).adminUploadSoundFile(id, file, options)(this.fetch, this.basePath);
    }

}
/**
 * BannerApi - fetch parameter creator
 * @export
 */
export const BannerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Banners
         * @param {string} location The location to load the banners for, like &#x27;browse&#x27; or &#x27;trending&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBanner(location: string, options: any = {}): FetchArgs {
            // verify required parameter 'location' is not null or undefined
            if (location === null || location === undefined) {
                throw new RequiredError('location','Required parameter location was null or undefined when calling getBanner.');
            }
            const localVarPath = `/banner`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BannerApi - functional programming interface
 * @export
 */
export const BannerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Banners
         * @param {string} location The location to load the banners for, like &#x27;browse&#x27; or &#x27;trending&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBanner(location: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20020> {
            const localVarFetchArgs = BannerApiFetchParamCreator(configuration).getBanner(location, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * BannerApi - factory interface
 * @export
 */
export const BannerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get Banners
         * @param {string} location The location to load the banners for, like &#x27;browse&#x27; or &#x27;trending&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBanner(location: string, options?: any) {
            return BannerApiFp(configuration).getBanner(location, options)(fetch, basePath);
        },
    };
};

/**
 * BannerApi - object-oriented interface
 * @export
 * @class BannerApi
 * @extends {BaseAPI}
 */
export class BannerApi extends BaseAPI {
    /**
     * 
     * @summary Get Banners
     * @param {string} location The location to load the banners for, like &#x27;browse&#x27; or &#x27;trending&#x27;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannerApi
     */
    public getBanner(location: string, options?: any) {
        return BannerApiFp(this.configuration).getBanner(location, options)(this.fetch, this.basePath);
    }

}
/**
 * ContestApi - fetch parameter creator
 * @export
 */
export const ContestApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Report download stems
         * @summary Download Stems
         * @param {string} id The contest id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contestReportDownloadStems(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling contestReportDownloadStems.');
            }
            const localVarPath = `/contest/{id}/download-stems`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Report stream submission
         * @summary Stream contest submission
         * @param {string} id The submission id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contestReportStreamSubmission(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling contestReportStreamSubmission.');
            }
            const localVarPath = `/contest/submissions/{id}/stream`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete contest comment
         * @param {string} id The comment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContestComent(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteContestComent.');
            }
            const localVarPath = `/contest/comments/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit contest comment
         * @param {string} id The comment id
         * @param {CommentsIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editContestComent(id: string, body?: CommentsIdBody, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling editContestComent.');
            }
            const localVarPath = `/contest/comments/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'CommentsIdBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit contest submission
         * @param {string} id The submission id
         * @param {SubmissionIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editContestSubmission(id: string, body?: SubmissionIdBody, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling editContestSubmission.');
            }
            const localVarPath = `/contest/submission/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'SubmissionIdBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the comments of the contest
         * @param {string} id The contest id
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContestComents(id: string, limit?: number, skip?: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getContestComents.');
            }
            const localVarPath = `/contest/{id}/comments`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the submissions of the contest
         * @param {string} contestId The contest id
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [id] The submission id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContestSubmissions(contestId: string, limit?: number, skip?: number, id?: string, options: any = {}): FetchArgs {
            // verify required parameter 'contestId' is not null or undefined
            if (contestId === null || contestId === undefined) {
                throw new RequiredError('contestId','Required parameter contestId was null or undefined when calling getContestSubmissions.');
            }
            const localVarPath = `/contest/{contestId}/submissions`
                .replace(`{${'contestId'}}`, encodeURIComponent(String(contestId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all Contests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContests(options: any = {}): FetchArgs {
            const localVarPath = `/contests/all`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Like contest comment
         * @param {string} id The comment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likeContestComent(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling likeContestComent.');
            }
            const localVarPath = `/contest/comments/{id}/like`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Like contest submission
         * @param {string} id The submission id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likeContestSubmission(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling likeContestSubmission.');
            }
            const localVarPath = `/contest/submissions/{id}/like`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Comment the contest
         * @param {string} id The contest id
         * @param {IdCommentBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postContestComent(id: string, body?: IdCommentBody, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling postContestComent.');
            }
            const localVarPath = `/contest/{id}/comment`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'IdCommentBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Submit song to contest
         * @summary Submit song to contest
         * @param {string} id The contest id
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitContest(id: string, title?: string, description?: string, file?: Blob, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling submitContest.');
            }
            const localVarPath = `/contest/{id}/submit`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (title !== undefined) {
                localVarFormParams.set('title', title as any);
            }

            if (description !== undefined) {
                localVarFormParams.set('description', description as any);
            }

            if (file !== undefined) {
                localVarFormParams.set('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unlike contest comment
         * @param {string} id The comment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlikeContestComent(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling unlikeContestComent.');
            }
            const localVarPath = `/contest/comments/{id}/like`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unlike contest submission
         * @param {string} id The submission id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlikeContestSubmission(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling unlikeContestSubmission.');
            }
            const localVarPath = `/contest/submissions/{id}/like`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContestApi - functional programming interface
 * @export
 */
export const ContestApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Report download stems
         * @summary Download Stems
         * @param {string} id The contest id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contestReportDownloadStems(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = ContestApiFetchParamCreator(configuration).contestReportDownloadStems(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Report stream submission
         * @summary Stream contest submission
         * @param {string} id The submission id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contestReportStreamSubmission(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20019> {
            const localVarFetchArgs = ContestApiFetchParamCreator(configuration).contestReportStreamSubmission(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete contest comment
         * @param {string} id The comment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContestComent(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = ContestApiFetchParamCreator(configuration).deleteContestComent(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Edit contest comment
         * @param {string} id The comment id
         * @param {CommentsIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editContestComent(id: string, body?: CommentsIdBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20017> {
            const localVarFetchArgs = ContestApiFetchParamCreator(configuration).editContestComent(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Edit contest submission
         * @param {string} id The submission id
         * @param {SubmissionIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editContestSubmission(id: string, body?: SubmissionIdBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20019> {
            const localVarFetchArgs = ContestApiFetchParamCreator(configuration).editContestSubmission(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the comments of the contest
         * @param {string} id The contest id
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContestComents(id: string, limit?: number, skip?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20016> {
            const localVarFetchArgs = ContestApiFetchParamCreator(configuration).getContestComents(id, limit, skip, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the submissions of the contest
         * @param {string} contestId The contest id
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [id] The submission id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContestSubmissions(contestId: string, limit?: number, skip?: number, id?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20018> {
            const localVarFetchArgs = ContestApiFetchParamCreator(configuration).getContestSubmissions(contestId, limit, skip, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all Contests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContests(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20015> {
            const localVarFetchArgs = ContestApiFetchParamCreator(configuration).getContests(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Like contest comment
         * @param {string} id The comment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likeContestComent(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20017> {
            const localVarFetchArgs = ContestApiFetchParamCreator(configuration).likeContestComent(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Like contest submission
         * @param {string} id The submission id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likeContestSubmission(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20019> {
            const localVarFetchArgs = ContestApiFetchParamCreator(configuration).likeContestSubmission(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Comment the contest
         * @param {string} id The contest id
         * @param {IdCommentBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postContestComent(id: string, body?: IdCommentBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20017> {
            const localVarFetchArgs = ContestApiFetchParamCreator(configuration).postContestComent(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Submit song to contest
         * @summary Submit song to contest
         * @param {string} id The contest id
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitContest(id: string, title?: string, description?: string, file?: Blob, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20019> {
            const localVarFetchArgs = ContestApiFetchParamCreator(configuration).submitContest(id, title, description, file, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Unlike contest comment
         * @param {string} id The comment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlikeContestComent(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20017> {
            const localVarFetchArgs = ContestApiFetchParamCreator(configuration).unlikeContestComent(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Unlike contest submission
         * @param {string} id The submission id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlikeContestSubmission(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20019> {
            const localVarFetchArgs = ContestApiFetchParamCreator(configuration).unlikeContestSubmission(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ContestApi - factory interface
 * @export
 */
export const ContestApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Report download stems
         * @summary Download Stems
         * @param {string} id The contest id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contestReportDownloadStems(id: string, options?: any) {
            return ContestApiFp(configuration).contestReportDownloadStems(id, options)(fetch, basePath);
        },
        /**
         * Report stream submission
         * @summary Stream contest submission
         * @param {string} id The submission id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contestReportStreamSubmission(id: string, options?: any) {
            return ContestApiFp(configuration).contestReportStreamSubmission(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete contest comment
         * @param {string} id The comment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContestComent(id: string, options?: any) {
            return ContestApiFp(configuration).deleteContestComent(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Edit contest comment
         * @param {string} id The comment id
         * @param {CommentsIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editContestComent(id: string, body?: CommentsIdBody, options?: any) {
            return ContestApiFp(configuration).editContestComent(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Edit contest submission
         * @param {string} id The submission id
         * @param {SubmissionIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editContestSubmission(id: string, body?: SubmissionIdBody, options?: any) {
            return ContestApiFp(configuration).editContestSubmission(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the comments of the contest
         * @param {string} id The contest id
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContestComents(id: string, limit?: number, skip?: number, options?: any) {
            return ContestApiFp(configuration).getContestComents(id, limit, skip, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the submissions of the contest
         * @param {string} contestId The contest id
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [id] The submission id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContestSubmissions(contestId: string, limit?: number, skip?: number, id?: string, options?: any) {
            return ContestApiFp(configuration).getContestSubmissions(contestId, limit, skip, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all Contests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContests(options?: any) {
            return ContestApiFp(configuration).getContests(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Like contest comment
         * @param {string} id The comment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likeContestComent(id: string, options?: any) {
            return ContestApiFp(configuration).likeContestComent(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Like contest submission
         * @param {string} id The submission id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likeContestSubmission(id: string, options?: any) {
            return ContestApiFp(configuration).likeContestSubmission(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Comment the contest
         * @param {string} id The contest id
         * @param {IdCommentBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postContestComent(id: string, body?: IdCommentBody, options?: any) {
            return ContestApiFp(configuration).postContestComent(id, body, options)(fetch, basePath);
        },
        /**
         * Submit song to contest
         * @summary Submit song to contest
         * @param {string} id The contest id
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitContest(id: string, title?: string, description?: string, file?: Blob, options?: any) {
            return ContestApiFp(configuration).submitContest(id, title, description, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Unlike contest comment
         * @param {string} id The comment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlikeContestComent(id: string, options?: any) {
            return ContestApiFp(configuration).unlikeContestComent(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Unlike contest submission
         * @param {string} id The submission id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlikeContestSubmission(id: string, options?: any) {
            return ContestApiFp(configuration).unlikeContestSubmission(id, options)(fetch, basePath);
        },
    };
};

/**
 * ContestApi - object-oriented interface
 * @export
 * @class ContestApi
 * @extends {BaseAPI}
 */
export class ContestApi extends BaseAPI {
    /**
     * Report download stems
     * @summary Download Stems
     * @param {string} id The contest id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContestApi
     */
    public contestReportDownloadStems(id: string, options?: any) {
        return ContestApiFp(this.configuration).contestReportDownloadStems(id, options)(this.fetch, this.basePath);
    }

    /**
     * Report stream submission
     * @summary Stream contest submission
     * @param {string} id The submission id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContestApi
     */
    public contestReportStreamSubmission(id: string, options?: any) {
        return ContestApiFp(this.configuration).contestReportStreamSubmission(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete contest comment
     * @param {string} id The comment id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContestApi
     */
    public deleteContestComent(id: string, options?: any) {
        return ContestApiFp(this.configuration).deleteContestComent(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Edit contest comment
     * @param {string} id The comment id
     * @param {CommentsIdBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContestApi
     */
    public editContestComent(id: string, body?: CommentsIdBody, options?: any) {
        return ContestApiFp(this.configuration).editContestComent(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Edit contest submission
     * @param {string} id The submission id
     * @param {SubmissionIdBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContestApi
     */
    public editContestSubmission(id: string, body?: SubmissionIdBody, options?: any) {
        return ContestApiFp(this.configuration).editContestSubmission(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the comments of the contest
     * @param {string} id The contest id
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContestApi
     */
    public getContestComents(id: string, limit?: number, skip?: number, options?: any) {
        return ContestApiFp(this.configuration).getContestComents(id, limit, skip, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the submissions of the contest
     * @param {string} contestId The contest id
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {string} [id] The submission id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContestApi
     */
    public getContestSubmissions(contestId: string, limit?: number, skip?: number, id?: string, options?: any) {
        return ContestApiFp(this.configuration).getContestSubmissions(contestId, limit, skip, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all Contests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContestApi
     */
    public getContests(options?: any) {
        return ContestApiFp(this.configuration).getContests(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Like contest comment
     * @param {string} id The comment id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContestApi
     */
    public likeContestComent(id: string, options?: any) {
        return ContestApiFp(this.configuration).likeContestComent(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Like contest submission
     * @param {string} id The submission id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContestApi
     */
    public likeContestSubmission(id: string, options?: any) {
        return ContestApiFp(this.configuration).likeContestSubmission(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Comment the contest
     * @param {string} id The contest id
     * @param {IdCommentBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContestApi
     */
    public postContestComent(id: string, body?: IdCommentBody, options?: any) {
        return ContestApiFp(this.configuration).postContestComent(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * Submit song to contest
     * @summary Submit song to contest
     * @param {string} id The contest id
     * @param {string} [title] 
     * @param {string} [description] 
     * @param {Blob} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContestApi
     */
    public submitContest(id: string, title?: string, description?: string, file?: Blob, options?: any) {
        return ContestApiFp(this.configuration).submitContest(id, title, description, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Unlike contest comment
     * @param {string} id The comment id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContestApi
     */
    public unlikeContestComent(id: string, options?: any) {
        return ContestApiFp(this.configuration).unlikeContestComent(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Unlike contest submission
     * @param {string} id The submission id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContestApi
     */
    public unlikeContestSubmission(id: string, options?: any) {
        return ContestApiFp(this.configuration).unlikeContestSubmission(id, options)(this.fetch, this.basePath);
    }

}
/**
 * CreditApi - fetch parameter creator
 * @export
 */
export const CreditApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get current users credits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCredits(options: any = {}): FetchArgs {
            const localVarPath = `/credits`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CreditApi - functional programming interface
 * @export
 */
export const CreditApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get current users credits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCredits(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse200> {
            const localVarFetchArgs = CreditApiFetchParamCreator(configuration).getCredits(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CreditApi - factory interface
 * @export
 */
export const CreditApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get current users credits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCredits(options?: any) {
            return CreditApiFp(configuration).getCredits(options)(fetch, basePath);
        },
    };
};

/**
 * CreditApi - object-oriented interface
 * @export
 * @class CreditApi
 * @extends {BaseAPI}
 */
export class CreditApi extends BaseAPI {
    /**
     * 
     * @summary Get current users credits
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditApi
     */
    public getCredits(options?: any) {
        return CreditApiFp(this.configuration).getCredits(options)(this.fetch, this.basePath);
    }

}
/**
 * CuratedApi - fetch parameter creator
 * @export
 */
export const CuratedApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download Curated Set Preview
         * @param {string} id The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCuratedSetPreview(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling downloadCuratedSetPreview.');
            }
            const localVarPath = `/curated/{id}/download-preview`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CuratedApi - functional programming interface
 * @export
 */
export const CuratedApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download Curated Set Preview
         * @param {string} id The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCuratedSetPreview(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2007> {
            const localVarFetchArgs = CuratedApiFetchParamCreator(configuration).downloadCuratedSetPreview(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CuratedApi - factory interface
 * @export
 */
export const CuratedApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Download Curated Set Preview
         * @param {string} id The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCuratedSetPreview(id: string, options?: any) {
            return CuratedApiFp(configuration).downloadCuratedSetPreview(id, options)(fetch, basePath);
        },
    };
};

/**
 * CuratedApi - object-oriented interface
 * @export
 * @class CuratedApi
 * @extends {BaseAPI}
 */
export class CuratedApi extends BaseAPI {
    /**
     * 
     * @summary Download Curated Set Preview
     * @param {string} id The curated set Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedApi
     */
    public downloadCuratedSetPreview(id: string, options?: any) {
        return CuratedApiFp(this.configuration).downloadCuratedSetPreview(id, options)(this.fetch, this.basePath);
    }

}
/**
 * CuratedSetApi - fetch parameter creator
 * @export
 */
export const CuratedSetApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download Curated Set
         * @param {string} id The curated set Id
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCuratedSet(id: string, location: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling downloadCuratedSet.');
            }
            // verify required parameter 'location' is not null or undefined
            if (location === null || location === undefined) {
                throw new RequiredError('location','Required parameter location was null or undefined when calling downloadCuratedSet.');
            }
            const localVarPath = `/curated/{id}/download`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Favorite Curated Set
         * @param {string} id The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followCuratedSet(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling followCuratedSet.');
            }
            const localVarPath = `/curated/{id}/favorite`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Curated Set
         * @param {string} id The curated set Id or slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSet(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getCuratedSet.');
            }
            const localVarPath = `/curated/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Curated Sets By Category
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetsByCategory(limit?: number, skip?: number, options: any = {}): FetchArgs {
            const localVarPath = `/curated/byCategory`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Favorited Curated Sets
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoritedCuratedSets(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options: any = {}): FetchArgs {
            const localVarPath = `/curated/favorited`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (tag_groups) {
                localVarQueryParameter['tag_groups'] = tag_groups;
            }

            if (artists) {
                localVarQueryParameter['artists'] = artists;
            }

            if (subgenre) {
                localVarQueryParameter['subgenre'] = subgenre;
            }

            if (label !== undefined) {
                localVarQueryParameter['label'] = label;
            }

            if (sound_package_id !== undefined) {
                localVarQueryParameter['sound_package_id'] = sound_package_id;
            }

            if (drive_id !== undefined) {
                localVarQueryParameter['drive_id'] = drive_id;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (showHalfTime !== undefined) {
                localVarQueryParameter['showHalfTime'] = showHalfTime;
            }

            if (suggested !== undefined) {
                localVarQueryParameter['suggested'] = suggested;
            }

            if (hide_prev_played !== undefined) {
                localVarQueryParameter['hide_prev_played'] = hide_prev_played;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (only_free !== undefined) {
                localVarQueryParameter['only_free'] = only_free;
            }

            if (tag_categories) {
                localVarQueryParameter['tag_categories'] = tag_categories;
            }

            if (trending !== undefined) {
                localVarQueryParameter['trending'] = trending;
            }

            if (synth !== undefined) {
                localVarQueryParameter['synth'] = synth;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Free Curated Sets
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFreeCuratedSets(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, options: any = {}): FetchArgs {
            const localVarPath = `/curated/free`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (tag_groups) {
                localVarQueryParameter['tag_groups'] = tag_groups;
            }

            if (artists) {
                localVarQueryParameter['artists'] = artists;
            }

            if (subgenre) {
                localVarQueryParameter['subgenre'] = subgenre;
            }

            if (label !== undefined) {
                localVarQueryParameter['label'] = label;
            }

            if (sound_package_id !== undefined) {
                localVarQueryParameter['sound_package_id'] = sound_package_id;
            }

            if (drive_id !== undefined) {
                localVarQueryParameter['drive_id'] = drive_id;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (showHalfTime !== undefined) {
                localVarQueryParameter['showHalfTime'] = showHalfTime;
            }

            if (suggested !== undefined) {
                localVarQueryParameter['suggested'] = suggested;
            }

            if (hide_prev_played !== undefined) {
                localVarQueryParameter['hide_prev_played'] = hide_prev_played;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Newest Curated Sets
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewestCuratedSets(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options: any = {}): FetchArgs {
            const localVarPath = `/curated/newest`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (tag_groups) {
                localVarQueryParameter['tag_groups'] = tag_groups;
            }

            if (artists) {
                localVarQueryParameter['artists'] = artists;
            }

            if (subgenre) {
                localVarQueryParameter['subgenre'] = subgenre;
            }

            if (label !== undefined) {
                localVarQueryParameter['label'] = label;
            }

            if (sound_package_id !== undefined) {
                localVarQueryParameter['sound_package_id'] = sound_package_id;
            }

            if (drive_id !== undefined) {
                localVarQueryParameter['drive_id'] = drive_id;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (showHalfTime !== undefined) {
                localVarQueryParameter['showHalfTime'] = showHalfTime;
            }

            if (suggested !== undefined) {
                localVarQueryParameter['suggested'] = suggested;
            }

            if (hide_prev_played !== undefined) {
                localVarQueryParameter['hide_prev_played'] = hide_prev_played;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (only_free !== undefined) {
                localVarQueryParameter['only_free'] = only_free;
            }

            if (tag_categories) {
                localVarQueryParameter['tag_categories'] = tag_categories;
            }

            if (trending !== undefined) {
                localVarQueryParameter['trending'] = trending;
            }

            if (synth !== undefined) {
                localVarQueryParameter['synth'] = synth;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search Curated Sets
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [search] Searches only through curated set name
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCuratedSets(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options: any = {}): FetchArgs {
            const localVarPath = `/curated/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (tag_groups) {
                localVarQueryParameter['tag_groups'] = tag_groups;
            }

            if (artists) {
                localVarQueryParameter['artists'] = artists;
            }

            if (subgenre) {
                localVarQueryParameter['subgenre'] = subgenre;
            }

            if (label !== undefined) {
                localVarQueryParameter['label'] = label;
            }

            if (sound_package_id !== undefined) {
                localVarQueryParameter['sound_package_id'] = sound_package_id;
            }

            if (drive_id !== undefined) {
                localVarQueryParameter['drive_id'] = drive_id;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (showHalfTime !== undefined) {
                localVarQueryParameter['showHalfTime'] = showHalfTime;
            }

            if (suggested !== undefined) {
                localVarQueryParameter['suggested'] = suggested;
            }

            if (hide_prev_played !== undefined) {
                localVarQueryParameter['hide_prev_played'] = hide_prev_played;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (only_free !== undefined) {
                localVarQueryParameter['only_free'] = only_free;
            }

            if (tag_categories) {
                localVarQueryParameter['tag_categories'] = tag_categories;
            }

            if (trending !== undefined) {
                localVarQueryParameter['trending'] = trending;
            }

            if (synth !== undefined) {
                localVarQueryParameter['synth'] = synth;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unfavorite Curated Set
         * @param {string} id The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfollowCuratedSet(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling unfollowCuratedSet.');
            }
            const localVarPath = `/curated/{id}/favorite`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CuratedSetApi - functional programming interface
 * @export
 */
export const CuratedSetApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download Curated Set
         * @param {string} id The curated set Id
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCuratedSet(id: string, location: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20012> {
            const localVarFetchArgs = CuratedSetApiFetchParamCreator(configuration).downloadCuratedSet(id, location, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Favorite Curated Set
         * @param {string} id The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followCuratedSet(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20023> {
            const localVarFetchArgs = CuratedSetApiFetchParamCreator(configuration).followCuratedSet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Curated Set
         * @param {string} id The curated set Id or slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSet(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20024> {
            const localVarFetchArgs = CuratedSetApiFetchParamCreator(configuration).getCuratedSet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Curated Sets By Category
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetsByCategory(limit?: number, skip?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20022> {
            const localVarFetchArgs = CuratedSetApiFetchParamCreator(configuration).getCuratedSetsByCategory(limit, skip, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Favorited Curated Sets
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoritedCuratedSets(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20021> {
            const localVarFetchArgs = CuratedSetApiFetchParamCreator(configuration).getFavoritedCuratedSets(limit, skip, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Free Curated Sets
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFreeCuratedSets(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20021> {
            const localVarFetchArgs = CuratedSetApiFetchParamCreator(configuration).getFreeCuratedSets(limit, skip, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Newest Curated Sets
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewestCuratedSets(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20021> {
            const localVarFetchArgs = CuratedSetApiFetchParamCreator(configuration).getNewestCuratedSets(limit, skip, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Search Curated Sets
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [search] Searches only through curated set name
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCuratedSets(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20021> {
            const localVarFetchArgs = CuratedSetApiFetchParamCreator(configuration).searchCuratedSets(limit, skip, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Unfavorite Curated Set
         * @param {string} id The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfollowCuratedSet(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20023> {
            const localVarFetchArgs = CuratedSetApiFetchParamCreator(configuration).unfollowCuratedSet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CuratedSetApi - factory interface
 * @export
 */
export const CuratedSetApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Download Curated Set
         * @param {string} id The curated set Id
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCuratedSet(id: string, location: string, options?: any) {
            return CuratedSetApiFp(configuration).downloadCuratedSet(id, location, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Favorite Curated Set
         * @param {string} id The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followCuratedSet(id: string, options?: any) {
            return CuratedSetApiFp(configuration).followCuratedSet(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Curated Set
         * @param {string} id The curated set Id or slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSet(id: string, options?: any) {
            return CuratedSetApiFp(configuration).getCuratedSet(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Curated Sets By Category
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetsByCategory(limit?: number, skip?: number, options?: any) {
            return CuratedSetApiFp(configuration).getCuratedSetsByCategory(limit, skip, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Favorited Curated Sets
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoritedCuratedSets(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
            return CuratedSetApiFp(configuration).getFavoritedCuratedSets(limit, skip, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Free Curated Sets
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFreeCuratedSets(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, options?: any) {
            return CuratedSetApiFp(configuration).getFreeCuratedSets(limit, skip, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Newest Curated Sets
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewestCuratedSets(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
            return CuratedSetApiFp(configuration).getNewestCuratedSets(limit, skip, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Search Curated Sets
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [search] Searches only through curated set name
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCuratedSets(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
            return CuratedSetApiFp(configuration).searchCuratedSets(limit, skip, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Unfavorite Curated Set
         * @param {string} id The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfollowCuratedSet(id: string, options?: any) {
            return CuratedSetApiFp(configuration).unfollowCuratedSet(id, options)(fetch, basePath);
        },
    };
};

/**
 * CuratedSetApi - object-oriented interface
 * @export
 * @class CuratedSetApi
 * @extends {BaseAPI}
 */
export class CuratedSetApi extends BaseAPI {
    /**
     * 
     * @summary Download Curated Set
     * @param {string} id The curated set Id
     * @param {string} location The location on site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedSetApi
     */
    public downloadCuratedSet(id: string, location: string, options?: any) {
        return CuratedSetApiFp(this.configuration).downloadCuratedSet(id, location, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Favorite Curated Set
     * @param {string} id The curated set Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedSetApi
     */
    public followCuratedSet(id: string, options?: any) {
        return CuratedSetApiFp(this.configuration).followCuratedSet(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Curated Set
     * @param {string} id The curated set Id or slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedSetApi
     */
    public getCuratedSet(id: string, options?: any) {
        return CuratedSetApiFp(this.configuration).getCuratedSet(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Curated Sets By Category
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedSetApi
     */
    public getCuratedSetsByCategory(limit?: number, skip?: number, options?: any) {
        return CuratedSetApiFp(this.configuration).getCuratedSetsByCategory(limit, skip, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Favorited Curated Sets
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {string} [search] Search term
     * @param {number} [bpm_max] Maxmium is 250
     * @param {number} [bpm_min] 
     * @param {StringOrStringArray} [key] 
     * @param {string} [keyType] Display attribute: Determines how API returns keys
     * @param {string} [type] 
     * @param {Array<string>} [tags] 
     * @param {Array<string>} [tag_groups] 
     * @param {Array<string>} [artists] 
     * @param {Array<string>} [subgenre] 
     * @param {StringOrStringArray} [label] 
     * @param {string} [sound_package_id] 
     * @param {string} [drive_id] 
     * @param {StringOrStringArray} [genre] 
     * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
     * @param {boolean} [suggested] Only return suggested sounds
     * @param {boolean} [hide_prev_played] Only return sounds not played yet
     * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
     * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
     * @param {Array<string>} [tag_categories] 
     * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
     * @param {string} [synth] Filter by preset synth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedSetApi
     */
    public getFavoritedCuratedSets(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
        return CuratedSetApiFp(this.configuration).getFavoritedCuratedSets(limit, skip, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Free Curated Sets
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {string} [search] Search term
     * @param {number} [bpm_max] Maxmium is 250
     * @param {number} [bpm_min] 
     * @param {StringOrStringArray} [key] 
     * @param {string} [keyType] Display attribute: Determines how API returns keys
     * @param {string} [type] 
     * @param {Array<string>} [tags] 
     * @param {Array<string>} [tag_groups] 
     * @param {Array<string>} [artists] 
     * @param {Array<string>} [subgenre] 
     * @param {StringOrStringArray} [label] 
     * @param {string} [sound_package_id] 
     * @param {string} [drive_id] 
     * @param {StringOrStringArray} [genre] 
     * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
     * @param {boolean} [suggested] Only return suggested sounds
     * @param {boolean} [hide_prev_played] Only return sounds not played yet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedSetApi
     */
    public getFreeCuratedSets(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, options?: any) {
        return CuratedSetApiFp(this.configuration).getFreeCuratedSets(limit, skip, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Newest Curated Sets
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {string} [search] Search term
     * @param {number} [bpm_max] Maxmium is 250
     * @param {number} [bpm_min] 
     * @param {StringOrStringArray} [key] 
     * @param {string} [keyType] Display attribute: Determines how API returns keys
     * @param {string} [type] 
     * @param {Array<string>} [tags] 
     * @param {Array<string>} [tag_groups] 
     * @param {Array<string>} [artists] 
     * @param {Array<string>} [subgenre] 
     * @param {StringOrStringArray} [label] 
     * @param {string} [sound_package_id] 
     * @param {string} [drive_id] 
     * @param {StringOrStringArray} [genre] 
     * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
     * @param {boolean} [suggested] Only return suggested sounds
     * @param {boolean} [hide_prev_played] Only return sounds not played yet
     * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
     * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
     * @param {Array<string>} [tag_categories] 
     * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
     * @param {string} [synth] Filter by preset synth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedSetApi
     */
    public getNewestCuratedSets(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
        return CuratedSetApiFp(this.configuration).getNewestCuratedSets(limit, skip, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Search Curated Sets
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {string} [search] Searches only through curated set name
     * @param {number} [bpm_max] Maxmium is 250
     * @param {number} [bpm_min] 
     * @param {StringOrStringArray} [key] 
     * @param {string} [keyType] Display attribute: Determines how API returns keys
     * @param {string} [type] 
     * @param {Array<string>} [tags] 
     * @param {Array<string>} [tag_groups] 
     * @param {Array<string>} [artists] 
     * @param {Array<string>} [subgenre] 
     * @param {StringOrStringArray} [label] 
     * @param {string} [sound_package_id] 
     * @param {string} [drive_id] 
     * @param {StringOrStringArray} [genre] 
     * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
     * @param {boolean} [suggested] Only return suggested sounds
     * @param {boolean} [hide_prev_played] Only return sounds not played yet
     * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
     * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
     * @param {Array<string>} [tag_categories] 
     * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
     * @param {string} [synth] Filter by preset synth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedSetApi
     */
    public searchCuratedSets(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
        return CuratedSetApiFp(this.configuration).searchCuratedSets(limit, skip, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Unfavorite Curated Set
     * @param {string} id The curated set Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedSetApi
     */
    public unfollowCuratedSet(id: string, options?: any) {
        return CuratedSetApiFp(this.configuration).unfollowCuratedSet(id, options)(this.fetch, this.basePath);
    }

}
/**
 * DownloadApi - fetch parameter creator
 * @export
 */
export const DownloadApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download Curated Set
         * @param {string} id The curated set Id
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCuratedSet(id: string, location: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling downloadCuratedSet.');
            }
            // verify required parameter 'location' is not null or undefined
            if (location === null || location === undefined) {
                throw new RequiredError('location','Required parameter location was null or undefined when calling downloadCuratedSet.');
            }
            const localVarPath = `/curated/{id}/download`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download Curated Set Preview
         * @param {string} id The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCuratedSetPreview(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling downloadCuratedSetPreview.');
            }
            const localVarPath = `/curated/{id}/download-preview`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download Drive
         * @param {string} id The drive Id
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDrive(id: string, location: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling downloadDrive.');
            }
            // verify required parameter 'location' is not null or undefined
            if (location === null || location === undefined) {
                throw new RequiredError('location','Required parameter location was null or undefined when calling downloadDrive.');
            }
            const localVarPath = `/drive/{id}/download`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download Drive Preview
         * @param {string} id The drive Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDrivePreview(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling downloadDrivePreview.');
            }
            const localVarPath = `/drive/{id}/download-preview`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download Multi Sound
         * @param {Array<string>} sound_ids The sound Ids
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMultiSound(sound_ids: Array<string>, location: string, options: any = {}): FetchArgs {
            // verify required parameter 'sound_ids' is not null or undefined
            if (sound_ids === null || sound_ids === undefined) {
                throw new RequiredError('sound_ids','Required parameter sound_ids was null or undefined when calling downloadMultiSound.');
            }
            // verify required parameter 'location' is not null or undefined
            if (location === null || location === undefined) {
                throw new RequiredError('location','Required parameter location was null or undefined when calling downloadMultiSound.');
            }
            const localVarPath = `/sounds/downloadMulti`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (sound_ids) {
                localVarQueryParameter['sound_ids'] = sound_ids;
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download Multi Sound Preview
         * @param {Array<string>} sound_ids The sound Ids
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMultiSoundPreview(sound_ids: Array<string>, location: string, options: any = {}): FetchArgs {
            // verify required parameter 'sound_ids' is not null or undefined
            if (sound_ids === null || sound_ids === undefined) {
                throw new RequiredError('sound_ids','Required parameter sound_ids was null or undefined when calling downloadMultiSoundPreview.');
            }
            // verify required parameter 'location' is not null or undefined
            if (location === null || location === undefined) {
                throw new RequiredError('location','Required parameter location was null or undefined when calling downloadMultiSoundPreview.');
            }
            const localVarPath = `/sounds/downloadMulti-preview`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (sound_ids) {
                localVarQueryParameter['sound_ids'] = sound_ids;
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download Sound
         * @param {string} id The sound Id
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSound(id: string, location: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling downloadSound.');
            }
            // verify required parameter 'location' is not null or undefined
            if (location === null || location === undefined) {
                throw new RequiredError('location','Required parameter location was null or undefined when calling downloadSound.');
            }
            const localVarPath = `/sounds/{id}/download`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download Sound Package
         * @param {string} id The sound package Id
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSoundPackage(id: string, location: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling downloadSoundPackage.');
            }
            // verify required parameter 'location' is not null or undefined
            if (location === null || location === undefined) {
                throw new RequiredError('location','Required parameter location was null or undefined when calling downloadSoundPackage.');
            }
            const localVarPath = `/sound-package/{id}/download`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download Sound Package Preview
         * @param {string} id The sound package Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSoundPackagePreview(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling downloadSoundPackagePreview.');
            }
            const localVarPath = `/sound-package/{id}/download-preview`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DownloadApi - functional programming interface
 * @export
 */
export const DownloadApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download Curated Set
         * @param {string} id The curated set Id
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCuratedSet(id: string, location: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20012> {
            const localVarFetchArgs = DownloadApiFetchParamCreator(configuration).downloadCuratedSet(id, location, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Download Curated Set Preview
         * @param {string} id The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCuratedSetPreview(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2007> {
            const localVarFetchArgs = DownloadApiFetchParamCreator(configuration).downloadCuratedSetPreview(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Download Drive
         * @param {string} id The drive Id
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDrive(id: string, location: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20012> {
            const localVarFetchArgs = DownloadApiFetchParamCreator(configuration).downloadDrive(id, location, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Download Drive Preview
         * @param {string} id The drive Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDrivePreview(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2007> {
            const localVarFetchArgs = DownloadApiFetchParamCreator(configuration).downloadDrivePreview(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Download Multi Sound
         * @param {Array<string>} sound_ids The sound Ids
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMultiSound(sound_ids: Array<string>, location: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DownloadApiFetchParamCreator(configuration).downloadMultiSound(sound_ids, location, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Download Multi Sound Preview
         * @param {Array<string>} sound_ids The sound Ids
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMultiSoundPreview(sound_ids: Array<string>, location: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2007> {
            const localVarFetchArgs = DownloadApiFetchParamCreator(configuration).downloadMultiSoundPreview(sound_ids, location, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Download Sound
         * @param {string} id The sound Id
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSound(id: string, location: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2006> {
            const localVarFetchArgs = DownloadApiFetchParamCreator(configuration).downloadSound(id, location, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Download Sound Package
         * @param {string} id The sound package Id
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSoundPackage(id: string, location: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20012> {
            const localVarFetchArgs = DownloadApiFetchParamCreator(configuration).downloadSoundPackage(id, location, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Download Sound Package Preview
         * @param {string} id The sound package Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSoundPackagePreview(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2007> {
            const localVarFetchArgs = DownloadApiFetchParamCreator(configuration).downloadSoundPackagePreview(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DownloadApi - factory interface
 * @export
 */
export const DownloadApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Download Curated Set
         * @param {string} id The curated set Id
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCuratedSet(id: string, location: string, options?: any) {
            return DownloadApiFp(configuration).downloadCuratedSet(id, location, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Download Curated Set Preview
         * @param {string} id The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCuratedSetPreview(id: string, options?: any) {
            return DownloadApiFp(configuration).downloadCuratedSetPreview(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Download Drive
         * @param {string} id The drive Id
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDrive(id: string, location: string, options?: any) {
            return DownloadApiFp(configuration).downloadDrive(id, location, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Download Drive Preview
         * @param {string} id The drive Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDrivePreview(id: string, options?: any) {
            return DownloadApiFp(configuration).downloadDrivePreview(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Download Multi Sound
         * @param {Array<string>} sound_ids The sound Ids
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMultiSound(sound_ids: Array<string>, location: string, options?: any) {
            return DownloadApiFp(configuration).downloadMultiSound(sound_ids, location, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Download Multi Sound Preview
         * @param {Array<string>} sound_ids The sound Ids
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMultiSoundPreview(sound_ids: Array<string>, location: string, options?: any) {
            return DownloadApiFp(configuration).downloadMultiSoundPreview(sound_ids, location, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Download Sound
         * @param {string} id The sound Id
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSound(id: string, location: string, options?: any) {
            return DownloadApiFp(configuration).downloadSound(id, location, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Download Sound Package
         * @param {string} id The sound package Id
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSoundPackage(id: string, location: string, options?: any) {
            return DownloadApiFp(configuration).downloadSoundPackage(id, location, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Download Sound Package Preview
         * @param {string} id The sound package Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSoundPackagePreview(id: string, options?: any) {
            return DownloadApiFp(configuration).downloadSoundPackagePreview(id, options)(fetch, basePath);
        },
    };
};

/**
 * DownloadApi - object-oriented interface
 * @export
 * @class DownloadApi
 * @extends {BaseAPI}
 */
export class DownloadApi extends BaseAPI {
    /**
     * 
     * @summary Download Curated Set
     * @param {string} id The curated set Id
     * @param {string} location The location on site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadApi
     */
    public downloadCuratedSet(id: string, location: string, options?: any) {
        return DownloadApiFp(this.configuration).downloadCuratedSet(id, location, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Download Curated Set Preview
     * @param {string} id The curated set Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadApi
     */
    public downloadCuratedSetPreview(id: string, options?: any) {
        return DownloadApiFp(this.configuration).downloadCuratedSetPreview(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Download Drive
     * @param {string} id The drive Id
     * @param {string} location The location on site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadApi
     */
    public downloadDrive(id: string, location: string, options?: any) {
        return DownloadApiFp(this.configuration).downloadDrive(id, location, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Download Drive Preview
     * @param {string} id The drive Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadApi
     */
    public downloadDrivePreview(id: string, options?: any) {
        return DownloadApiFp(this.configuration).downloadDrivePreview(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Download Multi Sound
     * @param {Array<string>} sound_ids The sound Ids
     * @param {string} location The location on site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadApi
     */
    public downloadMultiSound(sound_ids: Array<string>, location: string, options?: any) {
        return DownloadApiFp(this.configuration).downloadMultiSound(sound_ids, location, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Download Multi Sound Preview
     * @param {Array<string>} sound_ids The sound Ids
     * @param {string} location The location on site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadApi
     */
    public downloadMultiSoundPreview(sound_ids: Array<string>, location: string, options?: any) {
        return DownloadApiFp(this.configuration).downloadMultiSoundPreview(sound_ids, location, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Download Sound
     * @param {string} id The sound Id
     * @param {string} location The location on site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadApi
     */
    public downloadSound(id: string, location: string, options?: any) {
        return DownloadApiFp(this.configuration).downloadSound(id, location, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Download Sound Package
     * @param {string} id The sound package Id
     * @param {string} location The location on site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadApi
     */
    public downloadSoundPackage(id: string, location: string, options?: any) {
        return DownloadApiFp(this.configuration).downloadSoundPackage(id, location, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Download Sound Package Preview
     * @param {string} id The sound package Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadApi
     */
    public downloadSoundPackagePreview(id: string, options?: any) {
        return DownloadApiFp(this.configuration).downloadSoundPackagePreview(id, options)(this.fetch, this.basePath);
    }

}
/**
 * DriveApi - fetch parameter creator
 * @export
 */
export const DriveApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Accept a invitation to a drive
         * @param {string} id The invite Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptDriveInvite(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling acceptDriveInvite.');
            }
            const localVarPath = `/drive-invites/{id}/accept`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add Sound to Drive
         * @param {string} id The drive id
         * @param {string} soundId The sound id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSoundToDrive(id: string, soundId: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling addSoundToDrive.');
            }
            // verify required parameter 'soundId' is not null or undefined
            if (soundId === null || soundId === undefined) {
                throw new RequiredError('soundId','Required parameter soundId was null or undefined when calling addSoundToDrive.');
            }
            const localVarPath = `/drive/{id}/{soundId}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'soundId'}}`, encodeURIComponent(String(soundId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Copy Drive
         * @param {string} id The drive Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyDriveToOwn(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling copyDriveToOwn.');
            }
            const localVarPath = `/drive/{id}/copy`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Drive
         * @param {DrivesBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDrives(body?: DrivesBody, options: any = {}): FetchArgs {
            const localVarPath = `/drives`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'DrivesBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Decline a invitation to a drive
         * @param {string} id The invite Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declineDriveInvite(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling declineDriveInvite.');
            }
            const localVarPath = `/drive-invites/{id}/decline`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Drive
         * @param {string} id The drive id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDrive(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteDrive.');
            }
            const localVarPath = `/drive/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download Drive
         * @param {string} id The drive Id
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDrive(id: string, location: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling downloadDrive.');
            }
            // verify required parameter 'location' is not null or undefined
            if (location === null || location === undefined) {
                throw new RequiredError('location','Required parameter location was null or undefined when calling downloadDrive.');
            }
            const localVarPath = `/drive/{id}/download`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download Drive Preview
         * @param {string} id The drive Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDrivePreview(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling downloadDrivePreview.');
            }
            const localVarPath = `/drive/{id}/download-preview`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Drive
         * @param {string} id The drive id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrive(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDrive.');
            }
            const localVarPath = `/drive/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Drives
         * @param {boolean} [include_view_only] Also return shared view-only drives when set to true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrives(include_view_only?: boolean, options: any = {}): FetchArgs {
            const localVarPath = `/drives`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (include_view_only !== undefined) {
                localVarQueryParameter['include_view_only'] = include_view_only;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns only accepted for collab users, for owner all invites
         * @summary Get all user invites for Drive
         * @param {string} id The drive Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitesForDrive(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getInvitesForDrive.');
            }
            const localVarPath = `/drives/{id}/invites`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Shared Drives
         * @param {boolean} [include_view_only] Also return shared view-only drives when set to true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharedDrives(include_view_only?: boolean, options: any = {}): FetchArgs {
            const localVarPath = `/drives/shared`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (include_view_only !== undefined) {
                localVarQueryParameter['include_view_only'] = include_view_only;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Invite user to Drive
         * @param {string} id The drive Id
         * @param {IdInviteBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUserToDrive(id: string, body?: IdInviteBody, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling inviteUserToDrive.');
            }
            const localVarPath = `/drives/{id}/invite`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'IdInviteBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove Sound from Drive
         * @param {string} id The drive id
         * @param {string} soundId The sound id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSoundFromDrive(id: string, soundId: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling removeSoundFromDrive.');
            }
            // verify required parameter 'soundId' is not null or undefined
            if (soundId === null || soundId === undefined) {
                throw new RequiredError('soundId','Required parameter soundId was null or undefined when calling removeSoundFromDrive.');
            }
            const localVarPath = `/drive/{id}/{soundId}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'soundId'}}`, encodeURIComponent(String(soundId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a user from a Drive
         * @param {string} id The invite Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromDrive(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling removeUserFromDrive.');
            }
            const localVarPath = `/drive-invites/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Drive
         * @param {string} id The drive id
         * @param {DriveIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDrive(id: string, body?: DriveIdBody, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateDrive.');
            }
            const localVarPath = `/drive/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'DriveIdBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Drive Image
         * @param {string} id The drive Id
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDriveImage(id: string, file?: Blob, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateDriveImage.');
            }
            const localVarPath = `/drive/{id}/image`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (file !== undefined) {
                localVarFormParams.set('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a drive invite
         * @param {string} id The invite Id
         * @param {DriveinvitesIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserDriveInvite(id: string, body?: DriveinvitesIdBody, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateUserDriveInvite.');
            }
            const localVarPath = `/drive-invites/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'DriveinvitesIdBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DriveApi - functional programming interface
 * @export
 */
export const DriveApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Accept a invitation to a drive
         * @param {string} id The invite Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptDriveInvite(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20035> {
            const localVarFetchArgs = DriveApiFetchParamCreator(configuration).acceptDriveInvite(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add Sound to Drive
         * @param {string} id The drive id
         * @param {string} soundId The sound id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSoundToDrive(id: string, soundId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20026> {
            const localVarFetchArgs = DriveApiFetchParamCreator(configuration).addSoundToDrive(id, soundId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Copy Drive
         * @param {string} id The drive Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyDriveToOwn(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20026> {
            const localVarFetchArgs = DriveApiFetchParamCreator(configuration).copyDriveToOwn(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create Drive
         * @param {DrivesBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDrives(body?: DrivesBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20026> {
            const localVarFetchArgs = DriveApiFetchParamCreator(configuration).createDrives(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Decline a invitation to a drive
         * @param {string} id The invite Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declineDriveInvite(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20035> {
            const localVarFetchArgs = DriveApiFetchParamCreator(configuration).declineDriveInvite(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete Drive
         * @param {string} id The drive id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDrive(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = DriveApiFetchParamCreator(configuration).deleteDrive(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Download Drive
         * @param {string} id The drive Id
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDrive(id: string, location: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20012> {
            const localVarFetchArgs = DriveApiFetchParamCreator(configuration).downloadDrive(id, location, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Download Drive Preview
         * @param {string} id The drive Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDrivePreview(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2007> {
            const localVarFetchArgs = DriveApiFetchParamCreator(configuration).downloadDrivePreview(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Drive
         * @param {string} id The drive id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrive(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20026> {
            const localVarFetchArgs = DriveApiFetchParamCreator(configuration).getDrive(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Drives
         * @param {boolean} [include_view_only] Also return shared view-only drives when set to true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrives(include_view_only?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20025> {
            const localVarFetchArgs = DriveApiFetchParamCreator(configuration).getDrives(include_view_only, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Returns only accepted for collab users, for owner all invites
         * @summary Get all user invites for Drive
         * @param {string} id The drive Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitesForDrive(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20034> {
            const localVarFetchArgs = DriveApiFetchParamCreator(configuration).getInvitesForDrive(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Shared Drives
         * @param {boolean} [include_view_only] Also return shared view-only drives when set to true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharedDrives(include_view_only?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20025> {
            const localVarFetchArgs = DriveApiFetchParamCreator(configuration).getSharedDrives(include_view_only, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Invite user to Drive
         * @param {string} id The drive Id
         * @param {IdInviteBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUserToDrive(id: string, body?: IdInviteBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20034> {
            const localVarFetchArgs = DriveApiFetchParamCreator(configuration).inviteUserToDrive(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Remove Sound from Drive
         * @param {string} id The drive id
         * @param {string} soundId The sound id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSoundFromDrive(id: string, soundId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20026> {
            const localVarFetchArgs = DriveApiFetchParamCreator(configuration).removeSoundFromDrive(id, soundId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Remove a user from a Drive
         * @param {string} id The invite Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromDrive(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20034> {
            const localVarFetchArgs = DriveApiFetchParamCreator(configuration).removeUserFromDrive(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update Drive
         * @param {string} id The drive id
         * @param {DriveIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDrive(id: string, body?: DriveIdBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = DriveApiFetchParamCreator(configuration).updateDrive(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update Drive Image
         * @param {string} id The drive Id
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDriveImage(id: string, file?: Blob, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20026> {
            const localVarFetchArgs = DriveApiFetchParamCreator(configuration).updateDriveImage(id, file, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update a drive invite
         * @param {string} id The invite Id
         * @param {DriveinvitesIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserDriveInvite(id: string, body?: DriveinvitesIdBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20035> {
            const localVarFetchArgs = DriveApiFetchParamCreator(configuration).updateUserDriveInvite(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DriveApi - factory interface
 * @export
 */
export const DriveApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Accept a invitation to a drive
         * @param {string} id The invite Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptDriveInvite(id: string, options?: any) {
            return DriveApiFp(configuration).acceptDriveInvite(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add Sound to Drive
         * @param {string} id The drive id
         * @param {string} soundId The sound id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSoundToDrive(id: string, soundId: string, options?: any) {
            return DriveApiFp(configuration).addSoundToDrive(id, soundId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Copy Drive
         * @param {string} id The drive Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyDriveToOwn(id: string, options?: any) {
            return DriveApiFp(configuration).copyDriveToOwn(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create Drive
         * @param {DrivesBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDrives(body?: DrivesBody, options?: any) {
            return DriveApiFp(configuration).createDrives(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Decline a invitation to a drive
         * @param {string} id The invite Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declineDriveInvite(id: string, options?: any) {
            return DriveApiFp(configuration).declineDriveInvite(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete Drive
         * @param {string} id The drive id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDrive(id: string, options?: any) {
            return DriveApiFp(configuration).deleteDrive(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Download Drive
         * @param {string} id The drive Id
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDrive(id: string, location: string, options?: any) {
            return DriveApiFp(configuration).downloadDrive(id, location, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Download Drive Preview
         * @param {string} id The drive Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDrivePreview(id: string, options?: any) {
            return DriveApiFp(configuration).downloadDrivePreview(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Drive
         * @param {string} id The drive id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrive(id: string, options?: any) {
            return DriveApiFp(configuration).getDrive(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Drives
         * @param {boolean} [include_view_only] Also return shared view-only drives when set to true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrives(include_view_only?: boolean, options?: any) {
            return DriveApiFp(configuration).getDrives(include_view_only, options)(fetch, basePath);
        },
        /**
         * Returns only accepted for collab users, for owner all invites
         * @summary Get all user invites for Drive
         * @param {string} id The drive Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitesForDrive(id: string, options?: any) {
            return DriveApiFp(configuration).getInvitesForDrive(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Shared Drives
         * @param {boolean} [include_view_only] Also return shared view-only drives when set to true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharedDrives(include_view_only?: boolean, options?: any) {
            return DriveApiFp(configuration).getSharedDrives(include_view_only, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Invite user to Drive
         * @param {string} id The drive Id
         * @param {IdInviteBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUserToDrive(id: string, body?: IdInviteBody, options?: any) {
            return DriveApiFp(configuration).inviteUserToDrive(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Remove Sound from Drive
         * @param {string} id The drive id
         * @param {string} soundId The sound id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSoundFromDrive(id: string, soundId: string, options?: any) {
            return DriveApiFp(configuration).removeSoundFromDrive(id, soundId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Remove a user from a Drive
         * @param {string} id The invite Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromDrive(id: string, options?: any) {
            return DriveApiFp(configuration).removeUserFromDrive(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update Drive
         * @param {string} id The drive id
         * @param {DriveIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDrive(id: string, body?: DriveIdBody, options?: any) {
            return DriveApiFp(configuration).updateDrive(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update Drive Image
         * @param {string} id The drive Id
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDriveImage(id: string, file?: Blob, options?: any) {
            return DriveApiFp(configuration).updateDriveImage(id, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update a drive invite
         * @param {string} id The invite Id
         * @param {DriveinvitesIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserDriveInvite(id: string, body?: DriveinvitesIdBody, options?: any) {
            return DriveApiFp(configuration).updateUserDriveInvite(id, body, options)(fetch, basePath);
        },
    };
};

/**
 * DriveApi - object-oriented interface
 * @export
 * @class DriveApi
 * @extends {BaseAPI}
 */
export class DriveApi extends BaseAPI {
    /**
     * 
     * @summary Accept a invitation to a drive
     * @param {string} id The invite Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriveApi
     */
    public acceptDriveInvite(id: string, options?: any) {
        return DriveApiFp(this.configuration).acceptDriveInvite(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add Sound to Drive
     * @param {string} id The drive id
     * @param {string} soundId The sound id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriveApi
     */
    public addSoundToDrive(id: string, soundId: string, options?: any) {
        return DriveApiFp(this.configuration).addSoundToDrive(id, soundId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Copy Drive
     * @param {string} id The drive Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriveApi
     */
    public copyDriveToOwn(id: string, options?: any) {
        return DriveApiFp(this.configuration).copyDriveToOwn(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create Drive
     * @param {DrivesBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriveApi
     */
    public createDrives(body?: DrivesBody, options?: any) {
        return DriveApiFp(this.configuration).createDrives(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Decline a invitation to a drive
     * @param {string} id The invite Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriveApi
     */
    public declineDriveInvite(id: string, options?: any) {
        return DriveApiFp(this.configuration).declineDriveInvite(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete Drive
     * @param {string} id The drive id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriveApi
     */
    public deleteDrive(id: string, options?: any) {
        return DriveApiFp(this.configuration).deleteDrive(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Download Drive
     * @param {string} id The drive Id
     * @param {string} location The location on site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriveApi
     */
    public downloadDrive(id: string, location: string, options?: any) {
        return DriveApiFp(this.configuration).downloadDrive(id, location, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Download Drive Preview
     * @param {string} id The drive Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriveApi
     */
    public downloadDrivePreview(id: string, options?: any) {
        return DriveApiFp(this.configuration).downloadDrivePreview(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Drive
     * @param {string} id The drive id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriveApi
     */
    public getDrive(id: string, options?: any) {
        return DriveApiFp(this.configuration).getDrive(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Drives
     * @param {boolean} [include_view_only] Also return shared view-only drives when set to true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriveApi
     */
    public getDrives(include_view_only?: boolean, options?: any) {
        return DriveApiFp(this.configuration).getDrives(include_view_only, options)(this.fetch, this.basePath);
    }

    /**
     * Returns only accepted for collab users, for owner all invites
     * @summary Get all user invites for Drive
     * @param {string} id The drive Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriveApi
     */
    public getInvitesForDrive(id: string, options?: any) {
        return DriveApiFp(this.configuration).getInvitesForDrive(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Shared Drives
     * @param {boolean} [include_view_only] Also return shared view-only drives when set to true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriveApi
     */
    public getSharedDrives(include_view_only?: boolean, options?: any) {
        return DriveApiFp(this.configuration).getSharedDrives(include_view_only, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Invite user to Drive
     * @param {string} id The drive Id
     * @param {IdInviteBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriveApi
     */
    public inviteUserToDrive(id: string, body?: IdInviteBody, options?: any) {
        return DriveApiFp(this.configuration).inviteUserToDrive(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Remove Sound from Drive
     * @param {string} id The drive id
     * @param {string} soundId The sound id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriveApi
     */
    public removeSoundFromDrive(id: string, soundId: string, options?: any) {
        return DriveApiFp(this.configuration).removeSoundFromDrive(id, soundId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Remove a user from a Drive
     * @param {string} id The invite Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriveApi
     */
    public removeUserFromDrive(id: string, options?: any) {
        return DriveApiFp(this.configuration).removeUserFromDrive(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update Drive
     * @param {string} id The drive id
     * @param {DriveIdBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriveApi
     */
    public updateDrive(id: string, body?: DriveIdBody, options?: any) {
        return DriveApiFp(this.configuration).updateDrive(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update Drive Image
     * @param {string} id The drive Id
     * @param {Blob} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriveApi
     */
    public updateDriveImage(id: string, file?: Blob, options?: any) {
        return DriveApiFp(this.configuration).updateDriveImage(id, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update a drive invite
     * @param {string} id The invite Id
     * @param {DriveinvitesIdBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriveApi
     */
    public updateUserDriveInvite(id: string, body?: DriveinvitesIdBody, options?: any) {
        return DriveApiFp(this.configuration).updateUserDriveInvite(id, body, options)(this.fetch, this.basePath);
    }

}
/**
 * LabelApi - fetch parameter creator
 * @export
 */
export const LabelApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Favorite Label
         * @param {string} id The label Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteLabel(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling favoriteLabel.');
            }
            const localVarPath = `/labels/{id}/favorite`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Favorited Labels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoritedLabels(options: any = {}): FetchArgs {
            const localVarPath = `/labels/liked`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Label by Name
         * @param {string} name The label name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelByName(name: string, options: any = {}): FetchArgs {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling getLabelByName.');
            }
            const localVarPath = `/labels/byName/{name}`
                .replace(`{${'name'}}`, encodeURIComponent(String(name)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Label Newest Packs
         * @param {string} name The label name
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelNewestPacks(name: string, limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options: any = {}): FetchArgs {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling getLabelNewestPacks.');
            }
            const localVarPath = `/labels/byName/{name}/newest`
                .replace(`{${'name'}}`, encodeURIComponent(String(name)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (tag_groups) {
                localVarQueryParameter['tag_groups'] = tag_groups;
            }

            if (artists) {
                localVarQueryParameter['artists'] = artists;
            }

            if (subgenre) {
                localVarQueryParameter['subgenre'] = subgenre;
            }

            if (label !== undefined) {
                localVarQueryParameter['label'] = label;
            }

            if (sound_package_id !== undefined) {
                localVarQueryParameter['sound_package_id'] = sound_package_id;
            }

            if (curated_id !== undefined) {
                localVarQueryParameter['curated_id'] = curated_id;
            }

            if (drive_id !== undefined) {
                localVarQueryParameter['drive_id'] = drive_id;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (showHalfTime !== undefined) {
                localVarQueryParameter['showHalfTime'] = showHalfTime;
            }

            if (suggested !== undefined) {
                localVarQueryParameter['suggested'] = suggested;
            }

            if (hide_prev_played !== undefined) {
                localVarQueryParameter['hide_prev_played'] = hide_prev_played;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (only_free !== undefined) {
                localVarQueryParameter['only_free'] = only_free;
            }

            if (tag_categories) {
                localVarQueryParameter['tag_categories'] = tag_categories;
            }

            if (trending !== undefined) {
                localVarQueryParameter['trending'] = trending;
            }

            if (synth !== undefined) {
                localVarQueryParameter['synth'] = synth;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Label Top Packs
         * @param {string} name The label name
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelTopPacks(name: string, limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options: any = {}): FetchArgs {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling getLabelTopPacks.');
            }
            const localVarPath = `/labels/byName/{name}/topPacks`
                .replace(`{${'name'}}`, encodeURIComponent(String(name)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (tag_groups) {
                localVarQueryParameter['tag_groups'] = tag_groups;
            }

            if (artists) {
                localVarQueryParameter['artists'] = artists;
            }

            if (subgenre) {
                localVarQueryParameter['subgenre'] = subgenre;
            }

            if (label !== undefined) {
                localVarQueryParameter['label'] = label;
            }

            if (sound_package_id !== undefined) {
                localVarQueryParameter['sound_package_id'] = sound_package_id;
            }

            if (curated_id !== undefined) {
                localVarQueryParameter['curated_id'] = curated_id;
            }

            if (drive_id !== undefined) {
                localVarQueryParameter['drive_id'] = drive_id;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (showHalfTime !== undefined) {
                localVarQueryParameter['showHalfTime'] = showHalfTime;
            }

            if (suggested !== undefined) {
                localVarQueryParameter['suggested'] = suggested;
            }

            if (hide_prev_played !== undefined) {
                localVarQueryParameter['hide_prev_played'] = hide_prev_played;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (only_free !== undefined) {
                localVarQueryParameter['only_free'] = only_free;
            }

            if (tag_categories) {
                localVarQueryParameter['tag_categories'] = tag_categories;
            }

            if (trending !== undefined) {
                localVarQueryParameter['trending'] = trending;
            }

            if (synth !== undefined) {
                localVarQueryParameter['synth'] = synth;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Labels
         * @param {string} [type] 
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabels(type?: string, synth?: string, options: any = {}): FetchArgs {
            const localVarPath = `/labels`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (synth !== undefined) {
                localVarQueryParameter['synth'] = synth;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Trending Labels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingLabels(options: any = {}): FetchArgs {
            const localVarPath = `/labels/trending`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search Labels
         * @param {string} [search] Search term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchLabels(search?: string, options: any = {}): FetchArgs {
            const localVarPath = `/labels/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unfavorite Label
         * @param {string} id The label Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfavoriteLabel(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling unfavoriteLabel.');
            }
            const localVarPath = `/labels/{id}/favorite`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LabelApi - functional programming interface
 * @export
 */
export const LabelApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Favorite Label
         * @param {string} id The label Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteLabel(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20028> {
            const localVarFetchArgs = LabelApiFetchParamCreator(configuration).favoriteLabel(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Favorited Labels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoritedLabels(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20027> {
            const localVarFetchArgs = LabelApiFetchParamCreator(configuration).getFavoritedLabels(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Label by Name
         * @param {string} name The label name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelByName(name: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20028> {
            const localVarFetchArgs = LabelApiFetchParamCreator(configuration).getLabelByName(name, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Label Newest Packs
         * @param {string} name The label name
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelNewestPacks(name: string, limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2008> {
            const localVarFetchArgs = LabelApiFetchParamCreator(configuration).getLabelNewestPacks(name, limit, skip, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Label Top Packs
         * @param {string} name The label name
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelTopPacks(name: string, limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2008> {
            const localVarFetchArgs = LabelApiFetchParamCreator(configuration).getLabelTopPacks(name, limit, skip, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Labels
         * @param {string} [type] 
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabels(type?: string, synth?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20027> {
            const localVarFetchArgs = LabelApiFetchParamCreator(configuration).getLabels(type, synth, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Trending Labels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingLabels(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20027> {
            const localVarFetchArgs = LabelApiFetchParamCreator(configuration).getTrendingLabels(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Search Labels
         * @param {string} [search] Search term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchLabels(search?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20027> {
            const localVarFetchArgs = LabelApiFetchParamCreator(configuration).searchLabels(search, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Unfavorite Label
         * @param {string} id The label Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfavoriteLabel(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20029> {
            const localVarFetchArgs = LabelApiFetchParamCreator(configuration).unfavoriteLabel(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * LabelApi - factory interface
 * @export
 */
export const LabelApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Favorite Label
         * @param {string} id The label Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteLabel(id: string, options?: any) {
            return LabelApiFp(configuration).favoriteLabel(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Favorited Labels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoritedLabels(options?: any) {
            return LabelApiFp(configuration).getFavoritedLabels(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Label by Name
         * @param {string} name The label name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelByName(name: string, options?: any) {
            return LabelApiFp(configuration).getLabelByName(name, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Label Newest Packs
         * @param {string} name The label name
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelNewestPacks(name: string, limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
            return LabelApiFp(configuration).getLabelNewestPacks(name, limit, skip, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Label Top Packs
         * @param {string} name The label name
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelTopPacks(name: string, limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
            return LabelApiFp(configuration).getLabelTopPacks(name, limit, skip, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Labels
         * @param {string} [type] 
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabels(type?: string, synth?: string, options?: any) {
            return LabelApiFp(configuration).getLabels(type, synth, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Trending Labels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingLabels(options?: any) {
            return LabelApiFp(configuration).getTrendingLabels(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Search Labels
         * @param {string} [search] Search term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchLabels(search?: string, options?: any) {
            return LabelApiFp(configuration).searchLabels(search, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Unfavorite Label
         * @param {string} id The label Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfavoriteLabel(id: string, options?: any) {
            return LabelApiFp(configuration).unfavoriteLabel(id, options)(fetch, basePath);
        },
    };
};

/**
 * LabelApi - object-oriented interface
 * @export
 * @class LabelApi
 * @extends {BaseAPI}
 */
export class LabelApi extends BaseAPI {
    /**
     * 
     * @summary Favorite Label
     * @param {string} id The label Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelApi
     */
    public favoriteLabel(id: string, options?: any) {
        return LabelApiFp(this.configuration).favoriteLabel(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Favorited Labels
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelApi
     */
    public getFavoritedLabels(options?: any) {
        return LabelApiFp(this.configuration).getFavoritedLabels(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Label by Name
     * @param {string} name The label name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelApi
     */
    public getLabelByName(name: string, options?: any) {
        return LabelApiFp(this.configuration).getLabelByName(name, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Label Newest Packs
     * @param {string} name The label name
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {string} [search] Search term
     * @param {number} [bpm_max] Maxmium is 250
     * @param {number} [bpm_min] 
     * @param {StringOrStringArray} [key] 
     * @param {string} [keyType] Display attribute: Determines how API returns keys
     * @param {string} [type] 
     * @param {Array<string>} [tags] 
     * @param {Array<string>} [tag_groups] 
     * @param {Array<string>} [artists] 
     * @param {Array<string>} [subgenre] 
     * @param {StringOrStringArray} [label] 
     * @param {string} [sound_package_id] 
     * @param {string} [curated_id] 
     * @param {string} [drive_id] 
     * @param {StringOrStringArray} [genre] 
     * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
     * @param {boolean} [suggested] Only return suggested sounds
     * @param {boolean} [hide_prev_played] Only return sounds not played yet
     * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
     * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
     * @param {Array<string>} [tag_categories] 
     * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
     * @param {string} [synth] Filter by preset synth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelApi
     */
    public getLabelNewestPacks(name: string, limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
        return LabelApiFp(this.configuration).getLabelNewestPacks(name, limit, skip, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Label Top Packs
     * @param {string} name The label name
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {string} [search] Search term
     * @param {number} [bpm_max] Maxmium is 250
     * @param {number} [bpm_min] 
     * @param {StringOrStringArray} [key] 
     * @param {string} [keyType] Display attribute: Determines how API returns keys
     * @param {string} [type] 
     * @param {Array<string>} [tags] 
     * @param {Array<string>} [tag_groups] 
     * @param {Array<string>} [artists] 
     * @param {Array<string>} [subgenre] 
     * @param {StringOrStringArray} [label] 
     * @param {string} [sound_package_id] 
     * @param {string} [curated_id] 
     * @param {string} [drive_id] 
     * @param {StringOrStringArray} [genre] 
     * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
     * @param {boolean} [suggested] Only return suggested sounds
     * @param {boolean} [hide_prev_played] Only return sounds not played yet
     * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
     * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
     * @param {Array<string>} [tag_categories] 
     * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
     * @param {string} [synth] Filter by preset synth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelApi
     */
    public getLabelTopPacks(name: string, limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
        return LabelApiFp(this.configuration).getLabelTopPacks(name, limit, skip, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Labels
     * @param {string} [type] 
     * @param {string} [synth] Filter by preset synth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelApi
     */
    public getLabels(type?: string, synth?: string, options?: any) {
        return LabelApiFp(this.configuration).getLabels(type, synth, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Trending Labels
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelApi
     */
    public getTrendingLabels(options?: any) {
        return LabelApiFp(this.configuration).getTrendingLabels(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Search Labels
     * @param {string} [search] Search term
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelApi
     */
    public searchLabels(search?: string, options?: any) {
        return LabelApiFp(this.configuration).searchLabels(search, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Unfavorite Label
     * @param {string} id The label Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelApi
     */
    public unfavoriteLabel(id: string, options?: any) {
        return LabelApiFp(this.configuration).unfavoriteLabel(id, options)(this.fetch, this.basePath);
    }

}
/**
 * MetaApi - fetch parameter creator
 * @export
 */
export const MetaApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Genres
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenres(options: any = {}): FetchArgs {
            const localVarPath = `/genres`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Tags
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTags(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options: any = {}): FetchArgs {
            const localVarPath = `/tags`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (tag_groups) {
                localVarQueryParameter['tag_groups'] = tag_groups;
            }

            if (artists) {
                localVarQueryParameter['artists'] = artists;
            }

            if (subgenre) {
                localVarQueryParameter['subgenre'] = subgenre;
            }

            if (label !== undefined) {
                localVarQueryParameter['label'] = label;
            }

            if (sound_package_id !== undefined) {
                localVarQueryParameter['sound_package_id'] = sound_package_id;
            }

            if (curated_id !== undefined) {
                localVarQueryParameter['curated_id'] = curated_id;
            }

            if (drive_id !== undefined) {
                localVarQueryParameter['drive_id'] = drive_id;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (showHalfTime !== undefined) {
                localVarQueryParameter['showHalfTime'] = showHalfTime;
            }

            if (suggested !== undefined) {
                localVarQueryParameter['suggested'] = suggested;
            }

            if (hide_prev_played !== undefined) {
                localVarQueryParameter['hide_prev_played'] = hide_prev_played;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (only_free !== undefined) {
                localVarQueryParameter['only_free'] = only_free;
            }

            if (tag_categories) {
                localVarQueryParameter['tag_categories'] = tag_categories;
            }

            if (trending !== undefined) {
                localVarQueryParameter['trending'] = trending;
            }

            if (synth !== undefined) {
                localVarQueryParameter['synth'] = synth;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetaApi - functional programming interface
 * @export
 */
export const MetaApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Genres
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenres(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20031> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).getGenres(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Tags
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTags(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20030> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).getTags(limit, skip, sort_by, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * MetaApi - factory interface
 * @export
 */
export const MetaApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get Genres
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenres(options?: any) {
            return MetaApiFp(configuration).getGenres(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Tags
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTags(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
            return MetaApiFp(configuration).getTags(limit, skip, sort_by, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(fetch, basePath);
        },
    };
};

/**
 * MetaApi - object-oriented interface
 * @export
 * @class MetaApi
 * @extends {BaseAPI}
 */
export class MetaApi extends BaseAPI {
    /**
     * 
     * @summary Get Genres
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public getGenres(options?: any) {
        return MetaApiFp(this.configuration).getGenres(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Tags
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
     * @param {string} [search] Search term
     * @param {number} [bpm_max] Maxmium is 250
     * @param {number} [bpm_min] 
     * @param {StringOrStringArray} [key] 
     * @param {string} [keyType] Display attribute: Determines how API returns keys
     * @param {string} [type] 
     * @param {Array<string>} [tags] 
     * @param {Array<string>} [tag_groups] 
     * @param {Array<string>} [artists] 
     * @param {Array<string>} [subgenre] 
     * @param {StringOrStringArray} [label] 
     * @param {string} [sound_package_id] 
     * @param {string} [curated_id] 
     * @param {string} [drive_id] 
     * @param {StringOrStringArray} [genre] 
     * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
     * @param {boolean} [suggested] Only return suggested sounds
     * @param {boolean} [hide_prev_played] Only return sounds not played yet
     * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
     * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
     * @param {Array<string>} [tag_categories] 
     * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
     * @param {string} [synth] Filter by preset synth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public getTags(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
        return MetaApiFp(this.configuration).getTags(limit, skip, sort_by, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(this.fetch, this.basePath);
    }

}
/**
 * SearchApi - fetch parameter creator
 * @export
 */
export const SearchApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Search Presets
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresets(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options: any = {}): FetchArgs {
            const localVarPath = `/presets`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (tag_groups) {
                localVarQueryParameter['tag_groups'] = tag_groups;
            }

            if (artists) {
                localVarQueryParameter['artists'] = artists;
            }

            if (subgenre) {
                localVarQueryParameter['subgenre'] = subgenre;
            }

            if (label !== undefined) {
                localVarQueryParameter['label'] = label;
            }

            if (sound_package_id !== undefined) {
                localVarQueryParameter['sound_package_id'] = sound_package_id;
            }

            if (curated_id !== undefined) {
                localVarQueryParameter['curated_id'] = curated_id;
            }

            if (drive_id !== undefined) {
                localVarQueryParameter['drive_id'] = drive_id;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (only_free !== undefined) {
                localVarQueryParameter['only_free'] = only_free;
            }

            if (tag_categories) {
                localVarQueryParameter['tag_categories'] = tag_categories;
            }

            if (trending !== undefined) {
                localVarQueryParameter['trending'] = trending;
            }

            if (synth !== undefined) {
                localVarQueryParameter['synth'] = synth;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search Sound Packages
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSoundPackages(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options: any = {}): FetchArgs {
            const localVarPath = `/sound-packages`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (tag_groups) {
                localVarQueryParameter['tag_groups'] = tag_groups;
            }

            if (artists) {
                localVarQueryParameter['artists'] = artists;
            }

            if (subgenre) {
                localVarQueryParameter['subgenre'] = subgenre;
            }

            if (label !== undefined) {
                localVarQueryParameter['label'] = label;
            }

            if (sound_package_id !== undefined) {
                localVarQueryParameter['sound_package_id'] = sound_package_id;
            }

            if (curated_id !== undefined) {
                localVarQueryParameter['curated_id'] = curated_id;
            }

            if (drive_id !== undefined) {
                localVarQueryParameter['drive_id'] = drive_id;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (showHalfTime !== undefined) {
                localVarQueryParameter['showHalfTime'] = showHalfTime;
            }

            if (suggested !== undefined) {
                localVarQueryParameter['suggested'] = suggested;
            }

            if (hide_prev_played !== undefined) {
                localVarQueryParameter['hide_prev_played'] = hide_prev_played;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (only_free !== undefined) {
                localVarQueryParameter['only_free'] = only_free;
            }

            if (tag_categories) {
                localVarQueryParameter['tag_categories'] = tag_categories;
            }

            if (trending !== undefined) {
                localVarQueryParameter['trending'] = trending;
            }

            if (synth !== undefined) {
                localVarQueryParameter['synth'] = synth;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search Sounds
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSounds(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options: any = {}): FetchArgs {
            const localVarPath = `/sounds`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (tag_groups) {
                localVarQueryParameter['tag_groups'] = tag_groups;
            }

            if (artists) {
                localVarQueryParameter['artists'] = artists;
            }

            if (subgenre) {
                localVarQueryParameter['subgenre'] = subgenre;
            }

            if (label !== undefined) {
                localVarQueryParameter['label'] = label;
            }

            if (sound_package_id !== undefined) {
                localVarQueryParameter['sound_package_id'] = sound_package_id;
            }

            if (curated_id !== undefined) {
                localVarQueryParameter['curated_id'] = curated_id;
            }

            if (drive_id !== undefined) {
                localVarQueryParameter['drive_id'] = drive_id;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (showHalfTime !== undefined) {
                localVarQueryParameter['showHalfTime'] = showHalfTime;
            }

            if (suggested !== undefined) {
                localVarQueryParameter['suggested'] = suggested;
            }

            if (hide_prev_played !== undefined) {
                localVarQueryParameter['hide_prev_played'] = hide_prev_played;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (only_free !== undefined) {
                localVarQueryParameter['only_free'] = only_free;
            }

            if (tag_categories) {
                localVarQueryParameter['tag_categories'] = tag_categories;
            }

            if (trending !== undefined) {
                localVarQueryParameter['trending'] = trending;
            }

            if (synth !== undefined) {
                localVarQueryParameter['synth'] = synth;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Search Presets
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresets(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2001> {
            const localVarFetchArgs = SearchApiFetchParamCreator(configuration).getPresets(limit, skip, sort_by, search, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, only_free, tag_categories, trending, synth, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Search Sound Packages
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSoundPackages(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2008> {
            const localVarFetchArgs = SearchApiFetchParamCreator(configuration).getSoundPackages(limit, skip, sort_by, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Search Sounds
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSounds(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2002> {
            const localVarFetchArgs = SearchApiFetchParamCreator(configuration).getSounds(limit, skip, sort_by, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Search Presets
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresets(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
            return SearchApiFp(configuration).getPresets(limit, skip, sort_by, search, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, only_free, tag_categories, trending, synth, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Search Sound Packages
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSoundPackages(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
            return SearchApiFp(configuration).getSoundPackages(limit, skip, sort_by, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Search Sounds
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSounds(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
            return SearchApiFp(configuration).getSounds(limit, skip, sort_by, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(fetch, basePath);
        },
    };
};

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
    /**
     * 
     * @summary Search Presets
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
     * @param {string} [search] Search term
     * @param {Array<string>} [tags] 
     * @param {Array<string>} [tag_groups] 
     * @param {Array<string>} [artists] 
     * @param {Array<string>} [subgenre] 
     * @param {StringOrStringArray} [label] 
     * @param {string} [sound_package_id] 
     * @param {string} [curated_id] 
     * @param {string} [drive_id] 
     * @param {StringOrStringArray} [genre] 
     * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
     * @param {Array<string>} [tag_categories] 
     * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
     * @param {string} [synth] Filter by preset synth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public getPresets(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
        return SearchApiFp(this.configuration).getPresets(limit, skip, sort_by, search, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, only_free, tag_categories, trending, synth, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Search Sound Packages
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
     * @param {string} [search] Search term
     * @param {number} [bpm_max] Maxmium is 250
     * @param {number} [bpm_min] 
     * @param {StringOrStringArray} [key] 
     * @param {string} [keyType] Display attribute: Determines how API returns keys
     * @param {string} [type] 
     * @param {Array<string>} [tags] 
     * @param {Array<string>} [tag_groups] 
     * @param {Array<string>} [artists] 
     * @param {Array<string>} [subgenre] 
     * @param {StringOrStringArray} [label] 
     * @param {string} [sound_package_id] 
     * @param {string} [curated_id] 
     * @param {string} [drive_id] 
     * @param {StringOrStringArray} [genre] 
     * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
     * @param {boolean} [suggested] Only return suggested sounds
     * @param {boolean} [hide_prev_played] Only return sounds not played yet
     * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
     * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
     * @param {Array<string>} [tag_categories] 
     * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
     * @param {string} [synth] Filter by preset synth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public getSoundPackages(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
        return SearchApiFp(this.configuration).getSoundPackages(limit, skip, sort_by, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Search Sounds
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
     * @param {string} [search] Search term
     * @param {number} [bpm_max] Maxmium is 250
     * @param {number} [bpm_min] 
     * @param {StringOrStringArray} [key] 
     * @param {string} [keyType] Display attribute: Determines how API returns keys
     * @param {string} [type] 
     * @param {Array<string>} [tags] 
     * @param {Array<string>} [tag_groups] 
     * @param {Array<string>} [artists] 
     * @param {Array<string>} [subgenre] 
     * @param {StringOrStringArray} [label] 
     * @param {string} [sound_package_id] 
     * @param {string} [curated_id] 
     * @param {string} [drive_id] 
     * @param {StringOrStringArray} [genre] 
     * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
     * @param {boolean} [suggested] Only return suggested sounds
     * @param {boolean} [hide_prev_played] Only return sounds not played yet
     * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
     * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
     * @param {Array<string>} [tag_categories] 
     * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
     * @param {string} [synth] Filter by preset synth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public getSounds(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
        return SearchApiFp(this.configuration).getSounds(limit, skip, sort_by, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(this.fetch, this.basePath);
    }

}
/**
 * SectionApi - fetch parameter creator
 * @export
 */
export const SectionApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Section by Name
         * @param {string} location The location to load the section for, like &#x27;browse&#x27; or &#x27;trending&#x27;
         * @param {string} name The unique name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSectionByName(location: string, name: string, options: any = {}): FetchArgs {
            // verify required parameter 'location' is not null or undefined
            if (location === null || location === undefined) {
                throw new RequiredError('location','Required parameter location was null or undefined when calling getSectionByName.');
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling getSectionByName.');
            }
            const localVarPath = `/sections/{name}`
                .replace(`{${'name'}}`, encodeURIComponent(String(name)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Sections
         * @param {string} location The location to load the section for, like &#x27;browse&#x27; or &#x27;trending&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSections(location: string, options: any = {}): FetchArgs {
            // verify required parameter 'location' is not null or undefined
            if (location === null || location === undefined) {
                throw new RequiredError('location','Required parameter location was null or undefined when calling getSections.');
            }
            const localVarPath = `/sections`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SectionApi - functional programming interface
 * @export
 */
export const SectionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Section by Name
         * @param {string} location The location to load the section for, like &#x27;browse&#x27; or &#x27;trending&#x27;
         * @param {string} name The unique name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSectionByName(location: string, name: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20033> {
            const localVarFetchArgs = SectionApiFetchParamCreator(configuration).getSectionByName(location, name, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Sections
         * @param {string} location The location to load the section for, like &#x27;browse&#x27; or &#x27;trending&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSections(location: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20032> {
            const localVarFetchArgs = SectionApiFetchParamCreator(configuration).getSections(location, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SectionApi - factory interface
 * @export
 */
export const SectionApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get Section by Name
         * @param {string} location The location to load the section for, like &#x27;browse&#x27; or &#x27;trending&#x27;
         * @param {string} name The unique name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSectionByName(location: string, name: string, options?: any) {
            return SectionApiFp(configuration).getSectionByName(location, name, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Sections
         * @param {string} location The location to load the section for, like &#x27;browse&#x27; or &#x27;trending&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSections(location: string, options?: any) {
            return SectionApiFp(configuration).getSections(location, options)(fetch, basePath);
        },
    };
};

/**
 * SectionApi - object-oriented interface
 * @export
 * @class SectionApi
 * @extends {BaseAPI}
 */
export class SectionApi extends BaseAPI {
    /**
     * 
     * @summary Get Section by Name
     * @param {string} location The location to load the section for, like &#x27;browse&#x27; or &#x27;trending&#x27;
     * @param {string} name The unique name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionApi
     */
    public getSectionByName(location: string, name: string, options?: any) {
        return SectionApiFp(this.configuration).getSectionByName(location, name, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Sections
     * @param {string} location The location to load the section for, like &#x27;browse&#x27; or &#x27;trending&#x27;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionApi
     */
    public getSections(location: string, options?: any) {
        return SectionApiFp(this.configuration).getSections(location, options)(this.fetch, this.basePath);
    }

}
/**
 * SoundPackageApi - fetch parameter creator
 * @export
 */
export const SoundPackageApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download Sound Package
         * @param {string} id The sound package Id
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSoundPackage(id: string, location: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling downloadSoundPackage.');
            }
            // verify required parameter 'location' is not null or undefined
            if (location === null || location === undefined) {
                throw new RequiredError('location','Required parameter location was null or undefined when calling downloadSoundPackage.');
            }
            const localVarPath = `/sound-package/{id}/download`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download Sound Package Preview
         * @param {string} id The sound package Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSoundPackagePreview(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling downloadSoundPackagePreview.');
            }
            const localVarPath = `/sound-package/{id}/download-preview`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Favorite Sound Package
         * @param {string} id The sound package Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteSoundPackage(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling favoriteSoundPackage.');
            }
            const localVarPath = `/sound-packages/{id}/favorite`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Downloaded Sound Packages
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadedSoundPackages(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options: any = {}): FetchArgs {
            const localVarPath = `/sound-packages/downloaded`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (tag_groups) {
                localVarQueryParameter['tag_groups'] = tag_groups;
            }

            if (artists) {
                localVarQueryParameter['artists'] = artists;
            }

            if (subgenre) {
                localVarQueryParameter['subgenre'] = subgenre;
            }

            if (label !== undefined) {
                localVarQueryParameter['label'] = label;
            }

            if (sound_package_id !== undefined) {
                localVarQueryParameter['sound_package_id'] = sound_package_id;
            }

            if (curated_id !== undefined) {
                localVarQueryParameter['curated_id'] = curated_id;
            }

            if (drive_id !== undefined) {
                localVarQueryParameter['drive_id'] = drive_id;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (showHalfTime !== undefined) {
                localVarQueryParameter['showHalfTime'] = showHalfTime;
            }

            if (suggested !== undefined) {
                localVarQueryParameter['suggested'] = suggested;
            }

            if (hide_prev_played !== undefined) {
                localVarQueryParameter['hide_prev_played'] = hide_prev_played;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (only_free !== undefined) {
                localVarQueryParameter['only_free'] = only_free;
            }

            if (tag_categories) {
                localVarQueryParameter['tag_categories'] = tag_categories;
            }

            if (trending !== undefined) {
                localVarQueryParameter['trending'] = trending;
            }

            if (synth !== undefined) {
                localVarQueryParameter['synth'] = synth;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Featured Sound Packages
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedSoundPackages(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options: any = {}): FetchArgs {
            const localVarPath = `/sound-packages/featured`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (tag_groups) {
                localVarQueryParameter['tag_groups'] = tag_groups;
            }

            if (artists) {
                localVarQueryParameter['artists'] = artists;
            }

            if (subgenre) {
                localVarQueryParameter['subgenre'] = subgenre;
            }

            if (label !== undefined) {
                localVarQueryParameter['label'] = label;
            }

            if (sound_package_id !== undefined) {
                localVarQueryParameter['sound_package_id'] = sound_package_id;
            }

            if (curated_id !== undefined) {
                localVarQueryParameter['curated_id'] = curated_id;
            }

            if (drive_id !== undefined) {
                localVarQueryParameter['drive_id'] = drive_id;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (showHalfTime !== undefined) {
                localVarQueryParameter['showHalfTime'] = showHalfTime;
            }

            if (suggested !== undefined) {
                localVarQueryParameter['suggested'] = suggested;
            }

            if (hide_prev_played !== undefined) {
                localVarQueryParameter['hide_prev_played'] = hide_prev_played;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (only_free !== undefined) {
                localVarQueryParameter['only_free'] = only_free;
            }

            if (tag_categories) {
                localVarQueryParameter['tag_categories'] = tag_categories;
            }

            if (trending !== undefined) {
                localVarQueryParameter['trending'] = trending;
            }

            if (synth !== undefined) {
                localVarQueryParameter['synth'] = synth;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns sound packages which only contain free sounds
         * @summary Get Free Sound Packages
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFreeSoundPackages(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, options: any = {}): FetchArgs {
            const localVarPath = `/sound-packages/free`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (tag_groups) {
                localVarQueryParameter['tag_groups'] = tag_groups;
            }

            if (artists) {
                localVarQueryParameter['artists'] = artists;
            }

            if (subgenre) {
                localVarQueryParameter['subgenre'] = subgenre;
            }

            if (label !== undefined) {
                localVarQueryParameter['label'] = label;
            }

            if (sound_package_id !== undefined) {
                localVarQueryParameter['sound_package_id'] = sound_package_id;
            }

            if (curated_id !== undefined) {
                localVarQueryParameter['curated_id'] = curated_id;
            }

            if (drive_id !== undefined) {
                localVarQueryParameter['drive_id'] = drive_id;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (showHalfTime !== undefined) {
                localVarQueryParameter['showHalfTime'] = showHalfTime;
            }

            if (suggested !== undefined) {
                localVarQueryParameter['suggested'] = suggested;
            }

            if (hide_prev_played !== undefined) {
                localVarQueryParameter['hide_prev_played'] = hide_prev_played;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Liked Sound Packages
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLikedSoundPackages(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options: any = {}): FetchArgs {
            const localVarPath = `/sound-packages/liked`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (tag_groups) {
                localVarQueryParameter['tag_groups'] = tag_groups;
            }

            if (artists) {
                localVarQueryParameter['artists'] = artists;
            }

            if (subgenre) {
                localVarQueryParameter['subgenre'] = subgenre;
            }

            if (label !== undefined) {
                localVarQueryParameter['label'] = label;
            }

            if (sound_package_id !== undefined) {
                localVarQueryParameter['sound_package_id'] = sound_package_id;
            }

            if (curated_id !== undefined) {
                localVarQueryParameter['curated_id'] = curated_id;
            }

            if (drive_id !== undefined) {
                localVarQueryParameter['drive_id'] = drive_id;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (showHalfTime !== undefined) {
                localVarQueryParameter['showHalfTime'] = showHalfTime;
            }

            if (suggested !== undefined) {
                localVarQueryParameter['suggested'] = suggested;
            }

            if (hide_prev_played !== undefined) {
                localVarQueryParameter['hide_prev_played'] = hide_prev_played;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (only_free !== undefined) {
                localVarQueryParameter['only_free'] = only_free;
            }

            if (tag_categories) {
                localVarQueryParameter['tag_categories'] = tag_categories;
            }

            if (trending !== undefined) {
                localVarQueryParameter['trending'] = trending;
            }

            if (synth !== undefined) {
                localVarQueryParameter['synth'] = synth;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Newest Sound Packages
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewestSoundPackages(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options: any = {}): FetchArgs {
            const localVarPath = `/sound-packages/newest`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (tag_groups) {
                localVarQueryParameter['tag_groups'] = tag_groups;
            }

            if (artists) {
                localVarQueryParameter['artists'] = artists;
            }

            if (subgenre) {
                localVarQueryParameter['subgenre'] = subgenre;
            }

            if (label !== undefined) {
                localVarQueryParameter['label'] = label;
            }

            if (sound_package_id !== undefined) {
                localVarQueryParameter['sound_package_id'] = sound_package_id;
            }

            if (curated_id !== undefined) {
                localVarQueryParameter['curated_id'] = curated_id;
            }

            if (drive_id !== undefined) {
                localVarQueryParameter['drive_id'] = drive_id;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (showHalfTime !== undefined) {
                localVarQueryParameter['showHalfTime'] = showHalfTime;
            }

            if (suggested !== undefined) {
                localVarQueryParameter['suggested'] = suggested;
            }

            if (hide_prev_played !== undefined) {
                localVarQueryParameter['hide_prev_played'] = hide_prev_played;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (only_free !== undefined) {
                localVarQueryParameter['only_free'] = only_free;
            }

            if (tag_categories) {
                localVarQueryParameter['tag_categories'] = tag_categories;
            }

            if (trending !== undefined) {
                localVarQueryParameter['trending'] = trending;
            }

            if (synth !== undefined) {
                localVarQueryParameter['synth'] = synth;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns 10 Sound Packages per Genre
         * @summary Get Newest Sound Packages by Genres
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewestSoundPackagesByGenres(options: any = {}): FetchArgs {
            const localVarPath = `/sound-packages/newest/by-genre`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Similar Sound Packages
         * @param {string} id The sound package Id
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimilarSoundPackages(id: string, limit?: number, skip?: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getSimilarSoundPackages.');
            }
            const localVarPath = `/sound-packages/{id}/similar`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Sound Package
         * @param {string} id The sound package id or slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSoundPackage(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getSoundPackage.');
            }
            const localVarPath = `/sound-package/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search Sound Packages
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSoundPackages(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options: any = {}): FetchArgs {
            const localVarPath = `/sound-packages`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (tag_groups) {
                localVarQueryParameter['tag_groups'] = tag_groups;
            }

            if (artists) {
                localVarQueryParameter['artists'] = artists;
            }

            if (subgenre) {
                localVarQueryParameter['subgenre'] = subgenre;
            }

            if (label !== undefined) {
                localVarQueryParameter['label'] = label;
            }

            if (sound_package_id !== undefined) {
                localVarQueryParameter['sound_package_id'] = sound_package_id;
            }

            if (curated_id !== undefined) {
                localVarQueryParameter['curated_id'] = curated_id;
            }

            if (drive_id !== undefined) {
                localVarQueryParameter['drive_id'] = drive_id;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (showHalfTime !== undefined) {
                localVarQueryParameter['showHalfTime'] = showHalfTime;
            }

            if (suggested !== undefined) {
                localVarQueryParameter['suggested'] = suggested;
            }

            if (hide_prev_played !== undefined) {
                localVarQueryParameter['hide_prev_played'] = hide_prev_played;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (only_free !== undefined) {
                localVarQueryParameter['only_free'] = only_free;
            }

            if (tag_categories) {
                localVarQueryParameter['tag_categories'] = tag_categories;
            }

            if (trending !== undefined) {
                localVarQueryParameter['trending'] = trending;
            }

            if (synth !== undefined) {
                localVarQueryParameter['synth'] = synth;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Sound Packages by Genre
         * @param {string} genre Genre name or slug
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSoundPackagesByGenre(genre: string, limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options: any = {}): FetchArgs {
            // verify required parameter 'genre' is not null or undefined
            if (genre === null || genre === undefined) {
                throw new RequiredError('genre','Required parameter genre was null or undefined when calling getSoundPackagesByGenre.');
            }
            const localVarPath = `/sound-packages/byGenre/{genre}`
                .replace(`{${'genre'}}`, encodeURIComponent(String(genre)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (tag_groups) {
                localVarQueryParameter['tag_groups'] = tag_groups;
            }

            if (artists) {
                localVarQueryParameter['artists'] = artists;
            }

            if (subgenre) {
                localVarQueryParameter['subgenre'] = subgenre;
            }

            if (label !== undefined) {
                localVarQueryParameter['label'] = label;
            }

            if (sound_package_id !== undefined) {
                localVarQueryParameter['sound_package_id'] = sound_package_id;
            }

            if (curated_id !== undefined) {
                localVarQueryParameter['curated_id'] = curated_id;
            }

            if (drive_id !== undefined) {
                localVarQueryParameter['drive_id'] = drive_id;
            }

            if (showHalfTime !== undefined) {
                localVarQueryParameter['showHalfTime'] = showHalfTime;
            }

            if (suggested !== undefined) {
                localVarQueryParameter['suggested'] = suggested;
            }

            if (hide_prev_played !== undefined) {
                localVarQueryParameter['hide_prev_played'] = hide_prev_played;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (only_free !== undefined) {
                localVarQueryParameter['only_free'] = only_free;
            }

            if (tag_categories) {
                localVarQueryParameter['tag_categories'] = tag_categories;
            }

            if (trending !== undefined) {
                localVarQueryParameter['trending'] = trending;
            }

            if (synth !== undefined) {
                localVarQueryParameter['synth'] = synth;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Suggested Sound Packages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuggestedSoundPackages(options: any = {}): FetchArgs {
            const localVarPath = `/sound-packages/suggested`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Trending Sound Packages
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingSoundPackages(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options: any = {}): FetchArgs {
            const localVarPath = `/sound-packages/trending`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (tag_groups) {
                localVarQueryParameter['tag_groups'] = tag_groups;
            }

            if (artists) {
                localVarQueryParameter['artists'] = artists;
            }

            if (subgenre) {
                localVarQueryParameter['subgenre'] = subgenre;
            }

            if (label !== undefined) {
                localVarQueryParameter['label'] = label;
            }

            if (sound_package_id !== undefined) {
                localVarQueryParameter['sound_package_id'] = sound_package_id;
            }

            if (curated_id !== undefined) {
                localVarQueryParameter['curated_id'] = curated_id;
            }

            if (drive_id !== undefined) {
                localVarQueryParameter['drive_id'] = drive_id;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (showHalfTime !== undefined) {
                localVarQueryParameter['showHalfTime'] = showHalfTime;
            }

            if (suggested !== undefined) {
                localVarQueryParameter['suggested'] = suggested;
            }

            if (hide_prev_played !== undefined) {
                localVarQueryParameter['hide_prev_played'] = hide_prev_played;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (only_free !== undefined) {
                localVarQueryParameter['only_free'] = only_free;
            }

            if (tag_categories) {
                localVarQueryParameter['tag_categories'] = tag_categories;
            }

            if (trending !== undefined) {
                localVarQueryParameter['trending'] = trending;
            }

            if (synth !== undefined) {
                localVarQueryParameter['synth'] = synth;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unfavorite Sound Package
         * @param {string} id The sound package Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfavoriteSoundPackage(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling unfavoriteSoundPackage.');
            }
            const localVarPath = `/sound-packages/{id}/favorite`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SoundPackageApi - functional programming interface
 * @export
 */
export const SoundPackageApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download Sound Package
         * @param {string} id The sound package Id
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSoundPackage(id: string, location: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20012> {
            const localVarFetchArgs = SoundPackageApiFetchParamCreator(configuration).downloadSoundPackage(id, location, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Download Sound Package Preview
         * @param {string} id The sound package Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSoundPackagePreview(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2007> {
            const localVarFetchArgs = SoundPackageApiFetchParamCreator(configuration).downloadSoundPackagePreview(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Favorite Sound Package
         * @param {string} id The sound package Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteSoundPackage(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20014> {
            const localVarFetchArgs = SoundPackageApiFetchParamCreator(configuration).favoriteSoundPackage(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Downloaded Sound Packages
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadedSoundPackages(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2008> {
            const localVarFetchArgs = SoundPackageApiFetchParamCreator(configuration).getDownloadedSoundPackages(limit, skip, sort_by, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Featured Sound Packages
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedSoundPackages(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2008> {
            const localVarFetchArgs = SoundPackageApiFetchParamCreator(configuration).getFeaturedSoundPackages(limit, skip, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Returns sound packages which only contain free sounds
         * @summary Get Free Sound Packages
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFreeSoundPackages(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2008> {
            const localVarFetchArgs = SoundPackageApiFetchParamCreator(configuration).getFreeSoundPackages(limit, skip, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Liked Sound Packages
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLikedSoundPackages(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2008> {
            const localVarFetchArgs = SoundPackageApiFetchParamCreator(configuration).getLikedSoundPackages(limit, skip, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Newest Sound Packages
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewestSoundPackages(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2008> {
            const localVarFetchArgs = SoundPackageApiFetchParamCreator(configuration).getNewestSoundPackages(limit, skip, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Returns 10 Sound Packages per Genre
         * @summary Get Newest Sound Packages by Genres
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewestSoundPackagesByGenres(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2009> {
            const localVarFetchArgs = SoundPackageApiFetchParamCreator(configuration).getNewestSoundPackagesByGenres(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Similar Sound Packages
         * @param {string} id The sound package Id
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimilarSoundPackages(id: string, limit?: number, skip?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20011> {
            const localVarFetchArgs = SoundPackageApiFetchParamCreator(configuration).getSimilarSoundPackages(id, limit, skip, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Sound Package
         * @param {string} id The sound package id or slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSoundPackage(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20013> {
            const localVarFetchArgs = SoundPackageApiFetchParamCreator(configuration).getSoundPackage(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Search Sound Packages
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSoundPackages(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2008> {
            const localVarFetchArgs = SoundPackageApiFetchParamCreator(configuration).getSoundPackages(limit, skip, sort_by, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Sound Packages by Genre
         * @param {string} genre Genre name or slug
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSoundPackagesByGenre(genre: string, limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20010> {
            const localVarFetchArgs = SoundPackageApiFetchParamCreator(configuration).getSoundPackagesByGenre(genre, limit, skip, sort_by, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Suggested Sound Packages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuggestedSoundPackages(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2009> {
            const localVarFetchArgs = SoundPackageApiFetchParamCreator(configuration).getSuggestedSoundPackages(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Trending Sound Packages
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingSoundPackages(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2008> {
            const localVarFetchArgs = SoundPackageApiFetchParamCreator(configuration).getTrendingSoundPackages(limit, skip, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Unfavorite Sound Package
         * @param {string} id The sound package Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfavoriteSoundPackage(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20014> {
            const localVarFetchArgs = SoundPackageApiFetchParamCreator(configuration).unfavoriteSoundPackage(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SoundPackageApi - factory interface
 * @export
 */
export const SoundPackageApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Download Sound Package
         * @param {string} id The sound package Id
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSoundPackage(id: string, location: string, options?: any) {
            return SoundPackageApiFp(configuration).downloadSoundPackage(id, location, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Download Sound Package Preview
         * @param {string} id The sound package Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSoundPackagePreview(id: string, options?: any) {
            return SoundPackageApiFp(configuration).downloadSoundPackagePreview(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Favorite Sound Package
         * @param {string} id The sound package Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteSoundPackage(id: string, options?: any) {
            return SoundPackageApiFp(configuration).favoriteSoundPackage(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Downloaded Sound Packages
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadedSoundPackages(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
            return SoundPackageApiFp(configuration).getDownloadedSoundPackages(limit, skip, sort_by, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Featured Sound Packages
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedSoundPackages(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
            return SoundPackageApiFp(configuration).getFeaturedSoundPackages(limit, skip, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(fetch, basePath);
        },
        /**
         * Returns sound packages which only contain free sounds
         * @summary Get Free Sound Packages
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFreeSoundPackages(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, options?: any) {
            return SoundPackageApiFp(configuration).getFreeSoundPackages(limit, skip, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Liked Sound Packages
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLikedSoundPackages(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
            return SoundPackageApiFp(configuration).getLikedSoundPackages(limit, skip, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Newest Sound Packages
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewestSoundPackages(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
            return SoundPackageApiFp(configuration).getNewestSoundPackages(limit, skip, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(fetch, basePath);
        },
        /**
         * Returns 10 Sound Packages per Genre
         * @summary Get Newest Sound Packages by Genres
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewestSoundPackagesByGenres(options?: any) {
            return SoundPackageApiFp(configuration).getNewestSoundPackagesByGenres(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Similar Sound Packages
         * @param {string} id The sound package Id
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimilarSoundPackages(id: string, limit?: number, skip?: number, options?: any) {
            return SoundPackageApiFp(configuration).getSimilarSoundPackages(id, limit, skip, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Sound Package
         * @param {string} id The sound package id or slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSoundPackage(id: string, options?: any) {
            return SoundPackageApiFp(configuration).getSoundPackage(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Search Sound Packages
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSoundPackages(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
            return SoundPackageApiFp(configuration).getSoundPackages(limit, skip, sort_by, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Sound Packages by Genre
         * @param {string} genre Genre name or slug
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSoundPackagesByGenre(genre: string, limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
            return SoundPackageApiFp(configuration).getSoundPackagesByGenre(genre, limit, skip, sort_by, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Suggested Sound Packages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuggestedSoundPackages(options?: any) {
            return SoundPackageApiFp(configuration).getSuggestedSoundPackages(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Trending Sound Packages
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingSoundPackages(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
            return SoundPackageApiFp(configuration).getTrendingSoundPackages(limit, skip, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Unfavorite Sound Package
         * @param {string} id The sound package Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfavoriteSoundPackage(id: string, options?: any) {
            return SoundPackageApiFp(configuration).unfavoriteSoundPackage(id, options)(fetch, basePath);
        },
    };
};

/**
 * SoundPackageApi - object-oriented interface
 * @export
 * @class SoundPackageApi
 * @extends {BaseAPI}
 */
export class SoundPackageApi extends BaseAPI {
    /**
     * 
     * @summary Download Sound Package
     * @param {string} id The sound package Id
     * @param {string} location The location on site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundPackageApi
     */
    public downloadSoundPackage(id: string, location: string, options?: any) {
        return SoundPackageApiFp(this.configuration).downloadSoundPackage(id, location, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Download Sound Package Preview
     * @param {string} id The sound package Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundPackageApi
     */
    public downloadSoundPackagePreview(id: string, options?: any) {
        return SoundPackageApiFp(this.configuration).downloadSoundPackagePreview(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Favorite Sound Package
     * @param {string} id The sound package Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundPackageApi
     */
    public favoriteSoundPackage(id: string, options?: any) {
        return SoundPackageApiFp(this.configuration).favoriteSoundPackage(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Downloaded Sound Packages
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
     * @param {string} [search] Search term
     * @param {number} [bpm_max] Maxmium is 250
     * @param {number} [bpm_min] 
     * @param {StringOrStringArray} [key] 
     * @param {string} [keyType] Display attribute: Determines how API returns keys
     * @param {string} [type] 
     * @param {Array<string>} [tags] 
     * @param {Array<string>} [tag_groups] 
     * @param {Array<string>} [artists] 
     * @param {Array<string>} [subgenre] 
     * @param {StringOrStringArray} [label] 
     * @param {string} [sound_package_id] 
     * @param {string} [curated_id] 
     * @param {string} [drive_id] 
     * @param {StringOrStringArray} [genre] 
     * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
     * @param {boolean} [suggested] Only return suggested sounds
     * @param {boolean} [hide_prev_played] Only return sounds not played yet
     * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
     * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
     * @param {Array<string>} [tag_categories] 
     * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
     * @param {string} [synth] Filter by preset synth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundPackageApi
     */
    public getDownloadedSoundPackages(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
        return SoundPackageApiFp(this.configuration).getDownloadedSoundPackages(limit, skip, sort_by, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Featured Sound Packages
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {string} [search] Search term
     * @param {number} [bpm_max] Maxmium is 250
     * @param {number} [bpm_min] 
     * @param {StringOrStringArray} [key] 
     * @param {string} [keyType] Display attribute: Determines how API returns keys
     * @param {string} [type] 
     * @param {Array<string>} [tags] 
     * @param {Array<string>} [tag_groups] 
     * @param {Array<string>} [artists] 
     * @param {Array<string>} [subgenre] 
     * @param {StringOrStringArray} [label] 
     * @param {string} [sound_package_id] 
     * @param {string} [curated_id] 
     * @param {string} [drive_id] 
     * @param {StringOrStringArray} [genre] 
     * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
     * @param {boolean} [suggested] Only return suggested sounds
     * @param {boolean} [hide_prev_played] Only return sounds not played yet
     * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
     * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
     * @param {Array<string>} [tag_categories] 
     * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
     * @param {string} [synth] Filter by preset synth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundPackageApi
     */
    public getFeaturedSoundPackages(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
        return SoundPackageApiFp(this.configuration).getFeaturedSoundPackages(limit, skip, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(this.fetch, this.basePath);
    }

    /**
     * Returns sound packages which only contain free sounds
     * @summary Get Free Sound Packages
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {string} [search] Search term
     * @param {number} [bpm_max] Maxmium is 250
     * @param {number} [bpm_min] 
     * @param {StringOrStringArray} [key] 
     * @param {string} [keyType] Display attribute: Determines how API returns keys
     * @param {string} [type] 
     * @param {Array<string>} [tags] 
     * @param {Array<string>} [tag_groups] 
     * @param {Array<string>} [artists] 
     * @param {Array<string>} [subgenre] 
     * @param {StringOrStringArray} [label] 
     * @param {string} [sound_package_id] 
     * @param {string} [curated_id] 
     * @param {string} [drive_id] 
     * @param {StringOrStringArray} [genre] 
     * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
     * @param {boolean} [suggested] Only return suggested sounds
     * @param {boolean} [hide_prev_played] Only return sounds not played yet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundPackageApi
     */
    public getFreeSoundPackages(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, options?: any) {
        return SoundPackageApiFp(this.configuration).getFreeSoundPackages(limit, skip, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Liked Sound Packages
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {string} [search] Search term
     * @param {number} [bpm_max] Maxmium is 250
     * @param {number} [bpm_min] 
     * @param {StringOrStringArray} [key] 
     * @param {string} [keyType] Display attribute: Determines how API returns keys
     * @param {string} [type] 
     * @param {Array<string>} [tags] 
     * @param {Array<string>} [tag_groups] 
     * @param {Array<string>} [artists] 
     * @param {Array<string>} [subgenre] 
     * @param {StringOrStringArray} [label] 
     * @param {string} [sound_package_id] 
     * @param {string} [curated_id] 
     * @param {string} [drive_id] 
     * @param {StringOrStringArray} [genre] 
     * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
     * @param {boolean} [suggested] Only return suggested sounds
     * @param {boolean} [hide_prev_played] Only return sounds not played yet
     * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
     * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
     * @param {Array<string>} [tag_categories] 
     * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
     * @param {string} [synth] Filter by preset synth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundPackageApi
     */
    public getLikedSoundPackages(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
        return SoundPackageApiFp(this.configuration).getLikedSoundPackages(limit, skip, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Newest Sound Packages
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {string} [search] Search term
     * @param {number} [bpm_max] Maxmium is 250
     * @param {number} [bpm_min] 
     * @param {StringOrStringArray} [key] 
     * @param {string} [keyType] Display attribute: Determines how API returns keys
     * @param {string} [type] 
     * @param {Array<string>} [tags] 
     * @param {Array<string>} [tag_groups] 
     * @param {Array<string>} [artists] 
     * @param {Array<string>} [subgenre] 
     * @param {StringOrStringArray} [label] 
     * @param {string} [sound_package_id] 
     * @param {string} [curated_id] 
     * @param {string} [drive_id] 
     * @param {StringOrStringArray} [genre] 
     * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
     * @param {boolean} [suggested] Only return suggested sounds
     * @param {boolean} [hide_prev_played] Only return sounds not played yet
     * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
     * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
     * @param {Array<string>} [tag_categories] 
     * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
     * @param {string} [synth] Filter by preset synth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundPackageApi
     */
    public getNewestSoundPackages(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
        return SoundPackageApiFp(this.configuration).getNewestSoundPackages(limit, skip, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(this.fetch, this.basePath);
    }

    /**
     * Returns 10 Sound Packages per Genre
     * @summary Get Newest Sound Packages by Genres
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundPackageApi
     */
    public getNewestSoundPackagesByGenres(options?: any) {
        return SoundPackageApiFp(this.configuration).getNewestSoundPackagesByGenres(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Similar Sound Packages
     * @param {string} id The sound package Id
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundPackageApi
     */
    public getSimilarSoundPackages(id: string, limit?: number, skip?: number, options?: any) {
        return SoundPackageApiFp(this.configuration).getSimilarSoundPackages(id, limit, skip, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Sound Package
     * @param {string} id The sound package id or slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundPackageApi
     */
    public getSoundPackage(id: string, options?: any) {
        return SoundPackageApiFp(this.configuration).getSoundPackage(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Search Sound Packages
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
     * @param {string} [search] Search term
     * @param {number} [bpm_max] Maxmium is 250
     * @param {number} [bpm_min] 
     * @param {StringOrStringArray} [key] 
     * @param {string} [keyType] Display attribute: Determines how API returns keys
     * @param {string} [type] 
     * @param {Array<string>} [tags] 
     * @param {Array<string>} [tag_groups] 
     * @param {Array<string>} [artists] 
     * @param {Array<string>} [subgenre] 
     * @param {StringOrStringArray} [label] 
     * @param {string} [sound_package_id] 
     * @param {string} [curated_id] 
     * @param {string} [drive_id] 
     * @param {StringOrStringArray} [genre] 
     * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
     * @param {boolean} [suggested] Only return suggested sounds
     * @param {boolean} [hide_prev_played] Only return sounds not played yet
     * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
     * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
     * @param {Array<string>} [tag_categories] 
     * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
     * @param {string} [synth] Filter by preset synth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundPackageApi
     */
    public getSoundPackages(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
        return SoundPackageApiFp(this.configuration).getSoundPackages(limit, skip, sort_by, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Sound Packages by Genre
     * @param {string} genre Genre name or slug
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
     * @param {string} [search] Search term
     * @param {number} [bpm_max] Maxmium is 250
     * @param {number} [bpm_min] 
     * @param {StringOrStringArray} [key] 
     * @param {string} [keyType] Display attribute: Determines how API returns keys
     * @param {string} [type] 
     * @param {Array<string>} [tags] 
     * @param {Array<string>} [tag_groups] 
     * @param {Array<string>} [artists] 
     * @param {Array<string>} [subgenre] 
     * @param {StringOrStringArray} [label] 
     * @param {string} [sound_package_id] 
     * @param {string} [curated_id] 
     * @param {string} [drive_id] 
     * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
     * @param {boolean} [suggested] Only return suggested sounds
     * @param {boolean} [hide_prev_played] Only return sounds not played yet
     * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
     * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
     * @param {Array<string>} [tag_categories] 
     * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
     * @param {string} [synth] Filter by preset synth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundPackageApi
     */
    public getSoundPackagesByGenre(genre: string, limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
        return SoundPackageApiFp(this.configuration).getSoundPackagesByGenre(genre, limit, skip, sort_by, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Suggested Sound Packages
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundPackageApi
     */
    public getSuggestedSoundPackages(options?: any) {
        return SoundPackageApiFp(this.configuration).getSuggestedSoundPackages(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Trending Sound Packages
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {string} [search] Search term
     * @param {number} [bpm_max] Maxmium is 250
     * @param {number} [bpm_min] 
     * @param {StringOrStringArray} [key] 
     * @param {string} [keyType] Display attribute: Determines how API returns keys
     * @param {string} [type] 
     * @param {Array<string>} [tags] 
     * @param {Array<string>} [tag_groups] 
     * @param {Array<string>} [artists] 
     * @param {Array<string>} [subgenre] 
     * @param {StringOrStringArray} [label] 
     * @param {string} [sound_package_id] 
     * @param {string} [curated_id] 
     * @param {string} [drive_id] 
     * @param {StringOrStringArray} [genre] 
     * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
     * @param {boolean} [suggested] Only return suggested sounds
     * @param {boolean} [hide_prev_played] Only return sounds not played yet
     * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
     * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
     * @param {Array<string>} [tag_categories] 
     * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
     * @param {string} [synth] Filter by preset synth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundPackageApi
     */
    public getTrendingSoundPackages(limit?: number, skip?: number, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
        return SoundPackageApiFp(this.configuration).getTrendingSoundPackages(limit, skip, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Unfavorite Sound Package
     * @param {string} id The sound package Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundPackageApi
     */
    public unfavoriteSoundPackage(id: string, options?: any) {
        return SoundPackageApiFp(this.configuration).unfavoriteSoundPackage(id, options)(this.fetch, this.basePath);
    }

}
/**
 * SoundsApi - fetch parameter creator
 * @export
 */
export const SoundsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download Multi Sound
         * @param {Array<string>} sound_ids The sound Ids
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMultiSound(sound_ids: Array<string>, location: string, options: any = {}): FetchArgs {
            // verify required parameter 'sound_ids' is not null or undefined
            if (sound_ids === null || sound_ids === undefined) {
                throw new RequiredError('sound_ids','Required parameter sound_ids was null or undefined when calling downloadMultiSound.');
            }
            // verify required parameter 'location' is not null or undefined
            if (location === null || location === undefined) {
                throw new RequiredError('location','Required parameter location was null or undefined when calling downloadMultiSound.');
            }
            const localVarPath = `/sounds/downloadMulti`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (sound_ids) {
                localVarQueryParameter['sound_ids'] = sound_ids;
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download Multi Sound Preview
         * @param {Array<string>} sound_ids The sound Ids
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMultiSoundPreview(sound_ids: Array<string>, location: string, options: any = {}): FetchArgs {
            // verify required parameter 'sound_ids' is not null or undefined
            if (sound_ids === null || sound_ids === undefined) {
                throw new RequiredError('sound_ids','Required parameter sound_ids was null or undefined when calling downloadMultiSoundPreview.');
            }
            // verify required parameter 'location' is not null or undefined
            if (location === null || location === undefined) {
                throw new RequiredError('location','Required parameter location was null or undefined when calling downloadMultiSoundPreview.');
            }
            const localVarPath = `/sounds/downloadMulti-preview`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (sound_ids) {
                localVarQueryParameter['sound_ids'] = sound_ids;
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download Sound
         * @param {string} id The sound Id
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSound(id: string, location: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling downloadSound.');
            }
            // verify required parameter 'location' is not null or undefined
            if (location === null || location === undefined) {
                throw new RequiredError('location','Required parameter location was null or undefined when calling downloadSound.');
            }
            const localVarPath = `/sounds/{id}/download`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Favorite Sound
         * @param {string} id The sound Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteSound(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling favoriteSound.');
            }
            const localVarPath = `/sounds/{id}/favorite`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Favorited Presets
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoritedPresets(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options: any = {}): FetchArgs {
            const localVarPath = `/presets/liked`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (tag_groups) {
                localVarQueryParameter['tag_groups'] = tag_groups;
            }

            if (artists) {
                localVarQueryParameter['artists'] = artists;
            }

            if (subgenre) {
                localVarQueryParameter['subgenre'] = subgenre;
            }

            if (label !== undefined) {
                localVarQueryParameter['label'] = label;
            }

            if (sound_package_id !== undefined) {
                localVarQueryParameter['sound_package_id'] = sound_package_id;
            }

            if (curated_id !== undefined) {
                localVarQueryParameter['curated_id'] = curated_id;
            }

            if (drive_id !== undefined) {
                localVarQueryParameter['drive_id'] = drive_id;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (suggested !== undefined) {
                localVarQueryParameter['suggested'] = suggested;
            }

            if (hide_prev_played !== undefined) {
                localVarQueryParameter['hide_prev_played'] = hide_prev_played;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (only_free !== undefined) {
                localVarQueryParameter['only_free'] = only_free;
            }

            if (tag_categories) {
                localVarQueryParameter['tag_categories'] = tag_categories;
            }

            if (trending !== undefined) {
                localVarQueryParameter['trending'] = trending;
            }

            if (synth !== undefined) {
                localVarQueryParameter['synth'] = synth;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Favorited Sounds
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoritedSounds(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options: any = {}): FetchArgs {
            const localVarPath = `/sounds/liked`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (tag_groups) {
                localVarQueryParameter['tag_groups'] = tag_groups;
            }

            if (artists) {
                localVarQueryParameter['artists'] = artists;
            }

            if (subgenre) {
                localVarQueryParameter['subgenre'] = subgenre;
            }

            if (label !== undefined) {
                localVarQueryParameter['label'] = label;
            }

            if (sound_package_id !== undefined) {
                localVarQueryParameter['sound_package_id'] = sound_package_id;
            }

            if (curated_id !== undefined) {
                localVarQueryParameter['curated_id'] = curated_id;
            }

            if (drive_id !== undefined) {
                localVarQueryParameter['drive_id'] = drive_id;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (showHalfTime !== undefined) {
                localVarQueryParameter['showHalfTime'] = showHalfTime;
            }

            if (suggested !== undefined) {
                localVarQueryParameter['suggested'] = suggested;
            }

            if (hide_prev_played !== undefined) {
                localVarQueryParameter['hide_prev_played'] = hide_prev_played;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (only_free !== undefined) {
                localVarQueryParameter['only_free'] = only_free;
            }

            if (tag_categories) {
                localVarQueryParameter['tag_categories'] = tag_categories;
            }

            if (trending !== undefined) {
                localVarQueryParameter['trending'] = trending;
            }

            if (synth !== undefined) {
                localVarQueryParameter['synth'] = synth;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Featured Sounds
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedSounds(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options: any = {}): FetchArgs {
            const localVarPath = `/sounds/featured`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (tag_groups) {
                localVarQueryParameter['tag_groups'] = tag_groups;
            }

            if (artists) {
                localVarQueryParameter['artists'] = artists;
            }

            if (subgenre) {
                localVarQueryParameter['subgenre'] = subgenre;
            }

            if (label !== undefined) {
                localVarQueryParameter['label'] = label;
            }

            if (sound_package_id !== undefined) {
                localVarQueryParameter['sound_package_id'] = sound_package_id;
            }

            if (curated_id !== undefined) {
                localVarQueryParameter['curated_id'] = curated_id;
            }

            if (drive_id !== undefined) {
                localVarQueryParameter['drive_id'] = drive_id;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (showHalfTime !== undefined) {
                localVarQueryParameter['showHalfTime'] = showHalfTime;
            }

            if (suggested !== undefined) {
                localVarQueryParameter['suggested'] = suggested;
            }

            if (hide_prev_played !== undefined) {
                localVarQueryParameter['hide_prev_played'] = hide_prev_played;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (only_free !== undefined) {
                localVarQueryParameter['only_free'] = only_free;
            }

            if (tag_categories) {
                localVarQueryParameter['tag_categories'] = tag_categories;
            }

            if (trending !== undefined) {
                localVarQueryParameter['trending'] = trending;
            }

            if (synth !== undefined) {
                localVarQueryParameter['synth'] = synth;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Trending Sounds
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedSounds_1(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options: any = {}): FetchArgs {
            const localVarPath = `/sounds/trending`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (tag_groups) {
                localVarQueryParameter['tag_groups'] = tag_groups;
            }

            if (artists) {
                localVarQueryParameter['artists'] = artists;
            }

            if (subgenre) {
                localVarQueryParameter['subgenre'] = subgenre;
            }

            if (label !== undefined) {
                localVarQueryParameter['label'] = label;
            }

            if (sound_package_id !== undefined) {
                localVarQueryParameter['sound_package_id'] = sound_package_id;
            }

            if (curated_id !== undefined) {
                localVarQueryParameter['curated_id'] = curated_id;
            }

            if (drive_id !== undefined) {
                localVarQueryParameter['drive_id'] = drive_id;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (showHalfTime !== undefined) {
                localVarQueryParameter['showHalfTime'] = showHalfTime;
            }

            if (suggested !== undefined) {
                localVarQueryParameter['suggested'] = suggested;
            }

            if (hide_prev_played !== undefined) {
                localVarQueryParameter['hide_prev_played'] = hide_prev_played;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (only_free !== undefined) {
                localVarQueryParameter['only_free'] = only_free;
            }

            if (tag_categories) {
                localVarQueryParameter['tag_categories'] = tag_categories;
            }

            if (trending !== undefined) {
                localVarQueryParameter['trending'] = trending;
            }

            if (synth !== undefined) {
                localVarQueryParameter['synth'] = synth;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Trending Sounds
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedSounds_2(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options: any = {}): FetchArgs {
            const localVarPath = `/sounds/downloaded`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (tag_groups) {
                localVarQueryParameter['tag_groups'] = tag_groups;
            }

            if (artists) {
                localVarQueryParameter['artists'] = artists;
            }

            if (subgenre) {
                localVarQueryParameter['subgenre'] = subgenre;
            }

            if (label !== undefined) {
                localVarQueryParameter['label'] = label;
            }

            if (sound_package_id !== undefined) {
                localVarQueryParameter['sound_package_id'] = sound_package_id;
            }

            if (curated_id !== undefined) {
                localVarQueryParameter['curated_id'] = curated_id;
            }

            if (drive_id !== undefined) {
                localVarQueryParameter['drive_id'] = drive_id;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (showHalfTime !== undefined) {
                localVarQueryParameter['showHalfTime'] = showHalfTime;
            }

            if (suggested !== undefined) {
                localVarQueryParameter['suggested'] = suggested;
            }

            if (hide_prev_played !== undefined) {
                localVarQueryParameter['hide_prev_played'] = hide_prev_played;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (only_free !== undefined) {
                localVarQueryParameter['only_free'] = only_free;
            }

            if (tag_categories) {
                localVarQueryParameter['tag_categories'] = tag_categories;
            }

            if (trending !== undefined) {
                localVarQueryParameter['trending'] = trending;
            }

            if (synth !== undefined) {
                localVarQueryParameter['synth'] = synth;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Preset
         * @param {string} id The preset Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreset(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPreset.');
            }
            const localVarPath = `/preset/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search Presets
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresets(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options: any = {}): FetchArgs {
            const localVarPath = `/presets`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (tag_groups) {
                localVarQueryParameter['tag_groups'] = tag_groups;
            }

            if (artists) {
                localVarQueryParameter['artists'] = artists;
            }

            if (subgenre) {
                localVarQueryParameter['subgenre'] = subgenre;
            }

            if (label !== undefined) {
                localVarQueryParameter['label'] = label;
            }

            if (sound_package_id !== undefined) {
                localVarQueryParameter['sound_package_id'] = sound_package_id;
            }

            if (curated_id !== undefined) {
                localVarQueryParameter['curated_id'] = curated_id;
            }

            if (drive_id !== undefined) {
                localVarQueryParameter['drive_id'] = drive_id;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (only_free !== undefined) {
                localVarQueryParameter['only_free'] = only_free;
            }

            if (tag_categories) {
                localVarQueryParameter['tag_categories'] = tag_categories;
            }

            if (trending !== undefined) {
                localVarQueryParameter['trending'] = trending;
            }

            if (synth !== undefined) {
                localVarQueryParameter['synth'] = synth;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Similar Sounds
         * @param {string} id The sound Id
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimilarSounds(id: string, limit?: number, skip?: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getSimilarSounds.');
            }
            const localVarPath = `/sound/{id}/similar`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Sound
         * @param {string} id The sound Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSound(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getSound.');
            }
            const localVarPath = `/sound/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search Sounds
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSounds(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options: any = {}): FetchArgs {
            const localVarPath = `/sounds`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (tag_groups) {
                localVarQueryParameter['tag_groups'] = tag_groups;
            }

            if (artists) {
                localVarQueryParameter['artists'] = artists;
            }

            if (subgenre) {
                localVarQueryParameter['subgenre'] = subgenre;
            }

            if (label !== undefined) {
                localVarQueryParameter['label'] = label;
            }

            if (sound_package_id !== undefined) {
                localVarQueryParameter['sound_package_id'] = sound_package_id;
            }

            if (curated_id !== undefined) {
                localVarQueryParameter['curated_id'] = curated_id;
            }

            if (drive_id !== undefined) {
                localVarQueryParameter['drive_id'] = drive_id;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (showHalfTime !== undefined) {
                localVarQueryParameter['showHalfTime'] = showHalfTime;
            }

            if (suggested !== undefined) {
                localVarQueryParameter['suggested'] = suggested;
            }

            if (hide_prev_played !== undefined) {
                localVarQueryParameter['hide_prev_played'] = hide_prev_played;
            }

            if (hide_prev_downloaded !== undefined) {
                localVarQueryParameter['hide_prev_downloaded'] = hide_prev_downloaded;
            }

            if (only_free !== undefined) {
                localVarQueryParameter['only_free'] = only_free;
            }

            if (tag_categories) {
                localVarQueryParameter['tag_categories'] = tag_categories;
            }

            if (trending !== undefined) {
                localVarQueryParameter['trending'] = trending;
            }

            if (synth !== undefined) {
                localVarQueryParameter['synth'] = synth;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Report Sound
         * @param {string} id The sound Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportSound(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling reportSound.');
            }
            const localVarPath = `/sound/{id}/report`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stream Sound
         * @param {string} id The sound Id
         * @param {string} location The page location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamSound(id: string, location: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling streamSound.');
            }
            // verify required parameter 'location' is not null or undefined
            if (location === null || location === undefined) {
                throw new RequiredError('location','Required parameter location was null or undefined when calling streamSound.');
            }
            const localVarPath = `/sounds/{id}/stream`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Sound stream Duration
         * @param {string} id The sound Id
         * @param {StreamDurationBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamSoundSetDuration(id: string, body?: StreamDurationBody, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling streamSoundSetDuration.');
            }
            const localVarPath = `/sounds/{id}/stream/duration`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'StreamDurationBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unfavorite Sound
         * @param {string} id The sound Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfavoriteSound(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling unfavoriteSound.');
            }
            const localVarPath = `/sounds/{id}/favorite`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SoundsApi - functional programming interface
 * @export
 */
export const SoundsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download Multi Sound
         * @param {Array<string>} sound_ids The sound Ids
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMultiSound(sound_ids: Array<string>, location: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SoundsApiFetchParamCreator(configuration).downloadMultiSound(sound_ids, location, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Download Multi Sound Preview
         * @param {Array<string>} sound_ids The sound Ids
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMultiSoundPreview(sound_ids: Array<string>, location: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2007> {
            const localVarFetchArgs = SoundsApiFetchParamCreator(configuration).downloadMultiSoundPreview(sound_ids, location, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Download Sound
         * @param {string} id The sound Id
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSound(id: string, location: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2006> {
            const localVarFetchArgs = SoundsApiFetchParamCreator(configuration).downloadSound(id, location, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Favorite Sound
         * @param {string} id The sound Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteSound(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = SoundsApiFetchParamCreator(configuration).favoriteSound(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Favorited Presets
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoritedPresets(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2001> {
            const localVarFetchArgs = SoundsApiFetchParamCreator(configuration).getFavoritedPresets(limit, skip, sort_by, search, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Favorited Sounds
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoritedSounds(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2002> {
            const localVarFetchArgs = SoundsApiFetchParamCreator(configuration).getFavoritedSounds(limit, skip, sort_by, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Featured Sounds
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedSounds(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2002> {
            const localVarFetchArgs = SoundsApiFetchParamCreator(configuration).getFeaturedSounds(limit, skip, sort_by, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Trending Sounds
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedSounds_1(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2002> {
            const localVarFetchArgs = SoundsApiFetchParamCreator(configuration).getFeaturedSounds_1(limit, skip, sort_by, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Trending Sounds
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedSounds_2(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2002> {
            const localVarFetchArgs = SoundsApiFetchParamCreator(configuration).getFeaturedSounds_2(limit, skip, sort_by, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Preset
         * @param {string} id The preset Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreset(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2004> {
            const localVarFetchArgs = SoundsApiFetchParamCreator(configuration).getPreset(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Search Presets
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresets(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2001> {
            const localVarFetchArgs = SoundsApiFetchParamCreator(configuration).getPresets(limit, skip, sort_by, search, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, only_free, tag_categories, trending, synth, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Similar Sounds
         * @param {string} id The sound Id
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimilarSounds(id: string, limit?: number, skip?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2002> {
            const localVarFetchArgs = SoundsApiFetchParamCreator(configuration).getSimilarSounds(id, limit, skip, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Sound
         * @param {string} id The sound Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSound(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = SoundsApiFetchParamCreator(configuration).getSound(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Search Sounds
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSounds(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2002> {
            const localVarFetchArgs = SoundsApiFetchParamCreator(configuration).getSounds(limit, skip, sort_by, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Report Sound
         * @param {string} id The sound Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportSound(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = SoundsApiFetchParamCreator(configuration).reportSound(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Stream Sound
         * @param {string} id The sound Id
         * @param {string} location The page location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamSound(id: string, location: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = SoundsApiFetchParamCreator(configuration).streamSound(id, location, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update Sound stream Duration
         * @param {string} id The sound Id
         * @param {StreamDurationBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamSoundSetDuration(id: string, body?: StreamDurationBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = SoundsApiFetchParamCreator(configuration).streamSoundSetDuration(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Unfavorite Sound
         * @param {string} id The sound Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfavoriteSound(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = SoundsApiFetchParamCreator(configuration).unfavoriteSound(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SoundsApi - factory interface
 * @export
 */
export const SoundsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Download Multi Sound
         * @param {Array<string>} sound_ids The sound Ids
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMultiSound(sound_ids: Array<string>, location: string, options?: any) {
            return SoundsApiFp(configuration).downloadMultiSound(sound_ids, location, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Download Multi Sound Preview
         * @param {Array<string>} sound_ids The sound Ids
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMultiSoundPreview(sound_ids: Array<string>, location: string, options?: any) {
            return SoundsApiFp(configuration).downloadMultiSoundPreview(sound_ids, location, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Download Sound
         * @param {string} id The sound Id
         * @param {string} location The location on site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSound(id: string, location: string, options?: any) {
            return SoundsApiFp(configuration).downloadSound(id, location, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Favorite Sound
         * @param {string} id The sound Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteSound(id: string, options?: any) {
            return SoundsApiFp(configuration).favoriteSound(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Favorited Presets
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoritedPresets(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
            return SoundsApiFp(configuration).getFavoritedPresets(limit, skip, sort_by, search, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Favorited Sounds
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoritedSounds(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
            return SoundsApiFp(configuration).getFavoritedSounds(limit, skip, sort_by, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Featured Sounds
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedSounds(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
            return SoundsApiFp(configuration).getFeaturedSounds(limit, skip, sort_by, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Trending Sounds
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedSounds_1(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
            return SoundsApiFp(configuration).getFeaturedSounds_1(limit, skip, sort_by, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Trending Sounds
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedSounds_2(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
            return SoundsApiFp(configuration).getFeaturedSounds_2(limit, skip, sort_by, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Preset
         * @param {string} id The preset Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreset(id: string, options?: any) {
            return SoundsApiFp(configuration).getPreset(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Search Presets
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresets(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
            return SoundsApiFp(configuration).getPresets(limit, skip, sort_by, search, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, only_free, tag_categories, trending, synth, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Similar Sounds
         * @param {string} id The sound Id
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimilarSounds(id: string, limit?: number, skip?: number, options?: any) {
            return SoundsApiFp(configuration).getSimilarSounds(id, limit, skip, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Sound
         * @param {string} id The sound Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSound(id: string, options?: any) {
            return SoundsApiFp(configuration).getSound(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Search Sounds
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
         * @param {string} [search] Search term
         * @param {number} [bpm_max] Maxmium is 250
         * @param {number} [bpm_min] 
         * @param {StringOrStringArray} [key] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys
         * @param {string} [type] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [tag_groups] 
         * @param {Array<string>} [artists] 
         * @param {Array<string>} [subgenre] 
         * @param {StringOrStringArray} [label] 
         * @param {string} [sound_package_id] 
         * @param {string} [curated_id] 
         * @param {string} [drive_id] 
         * @param {StringOrStringArray} [genre] 
         * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
         * @param {boolean} [suggested] Only return suggested sounds
         * @param {boolean} [hide_prev_played] Only return sounds not played yet
         * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
         * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
         * @param {Array<string>} [tag_categories] 
         * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
         * @param {string} [synth] Filter by preset synth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSounds(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
            return SoundsApiFp(configuration).getSounds(limit, skip, sort_by, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Report Sound
         * @param {string} id The sound Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportSound(id: string, options?: any) {
            return SoundsApiFp(configuration).reportSound(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Stream Sound
         * @param {string} id The sound Id
         * @param {string} location The page location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamSound(id: string, location: string, options?: any) {
            return SoundsApiFp(configuration).streamSound(id, location, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update Sound stream Duration
         * @param {string} id The sound Id
         * @param {StreamDurationBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamSoundSetDuration(id: string, body?: StreamDurationBody, options?: any) {
            return SoundsApiFp(configuration).streamSoundSetDuration(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Unfavorite Sound
         * @param {string} id The sound Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfavoriteSound(id: string, options?: any) {
            return SoundsApiFp(configuration).unfavoriteSound(id, options)(fetch, basePath);
        },
    };
};

/**
 * SoundsApi - object-oriented interface
 * @export
 * @class SoundsApi
 * @extends {BaseAPI}
 */
export class SoundsApi extends BaseAPI {
    /**
     * 
     * @summary Download Multi Sound
     * @param {Array<string>} sound_ids The sound Ids
     * @param {string} location The location on site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundsApi
     */
    public downloadMultiSound(sound_ids: Array<string>, location: string, options?: any) {
        return SoundsApiFp(this.configuration).downloadMultiSound(sound_ids, location, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Download Multi Sound Preview
     * @param {Array<string>} sound_ids The sound Ids
     * @param {string} location The location on site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundsApi
     */
    public downloadMultiSoundPreview(sound_ids: Array<string>, location: string, options?: any) {
        return SoundsApiFp(this.configuration).downloadMultiSoundPreview(sound_ids, location, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Download Sound
     * @param {string} id The sound Id
     * @param {string} location The location on site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundsApi
     */
    public downloadSound(id: string, location: string, options?: any) {
        return SoundsApiFp(this.configuration).downloadSound(id, location, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Favorite Sound
     * @param {string} id The sound Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundsApi
     */
    public favoriteSound(id: string, options?: any) {
        return SoundsApiFp(this.configuration).favoriteSound(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Favorited Presets
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
     * @param {string} [search] Search term
     * @param {Array<string>} [tags] 
     * @param {Array<string>} [tag_groups] 
     * @param {Array<string>} [artists] 
     * @param {Array<string>} [subgenre] 
     * @param {StringOrStringArray} [label] 
     * @param {string} [sound_package_id] 
     * @param {string} [curated_id] 
     * @param {string} [drive_id] 
     * @param {StringOrStringArray} [genre] 
     * @param {boolean} [suggested] Only return suggested sounds
     * @param {boolean} [hide_prev_played] Only return sounds not played yet
     * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
     * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
     * @param {Array<string>} [tag_categories] 
     * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
     * @param {string} [synth] Filter by preset synth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundsApi
     */
    public getFavoritedPresets(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
        return SoundsApiFp(this.configuration).getFavoritedPresets(limit, skip, sort_by, search, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Favorited Sounds
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
     * @param {string} [search] Search term
     * @param {number} [bpm_max] Maxmium is 250
     * @param {number} [bpm_min] 
     * @param {StringOrStringArray} [key] 
     * @param {string} [keyType] Display attribute: Determines how API returns keys
     * @param {string} [type] 
     * @param {Array<string>} [tags] 
     * @param {Array<string>} [tag_groups] 
     * @param {Array<string>} [artists] 
     * @param {Array<string>} [subgenre] 
     * @param {StringOrStringArray} [label] 
     * @param {string} [sound_package_id] 
     * @param {string} [curated_id] 
     * @param {string} [drive_id] 
     * @param {StringOrStringArray} [genre] 
     * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
     * @param {boolean} [suggested] Only return suggested sounds
     * @param {boolean} [hide_prev_played] Only return sounds not played yet
     * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
     * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
     * @param {Array<string>} [tag_categories] 
     * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
     * @param {string} [synth] Filter by preset synth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundsApi
     */
    public getFavoritedSounds(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
        return SoundsApiFp(this.configuration).getFavoritedSounds(limit, skip, sort_by, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Featured Sounds
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
     * @param {string} [search] Search term
     * @param {number} [bpm_max] Maxmium is 250
     * @param {number} [bpm_min] 
     * @param {StringOrStringArray} [key] 
     * @param {string} [keyType] Display attribute: Determines how API returns keys
     * @param {string} [type] 
     * @param {Array<string>} [tags] 
     * @param {Array<string>} [tag_groups] 
     * @param {Array<string>} [artists] 
     * @param {Array<string>} [subgenre] 
     * @param {StringOrStringArray} [label] 
     * @param {string} [sound_package_id] 
     * @param {string} [curated_id] 
     * @param {string} [drive_id] 
     * @param {StringOrStringArray} [genre] 
     * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
     * @param {boolean} [suggested] Only return suggested sounds
     * @param {boolean} [hide_prev_played] Only return sounds not played yet
     * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
     * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
     * @param {Array<string>} [tag_categories] 
     * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
     * @param {string} [synth] Filter by preset synth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundsApi
     */
    public getFeaturedSounds(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
        return SoundsApiFp(this.configuration).getFeaturedSounds(limit, skip, sort_by, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Trending Sounds
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
     * @param {string} [search] Search term
     * @param {number} [bpm_max] Maxmium is 250
     * @param {number} [bpm_min] 
     * @param {StringOrStringArray} [key] 
     * @param {string} [keyType] Display attribute: Determines how API returns keys
     * @param {string} [type] 
     * @param {Array<string>} [tags] 
     * @param {Array<string>} [tag_groups] 
     * @param {Array<string>} [artists] 
     * @param {Array<string>} [subgenre] 
     * @param {StringOrStringArray} [label] 
     * @param {string} [sound_package_id] 
     * @param {string} [curated_id] 
     * @param {string} [drive_id] 
     * @param {StringOrStringArray} [genre] 
     * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
     * @param {boolean} [suggested] Only return suggested sounds
     * @param {boolean} [hide_prev_played] Only return sounds not played yet
     * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
     * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
     * @param {Array<string>} [tag_categories] 
     * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
     * @param {string} [synth] Filter by preset synth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundsApi
     */
    public getFeaturedSounds_1(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
        return SoundsApiFp(this.configuration).getFeaturedSounds_1(limit, skip, sort_by, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Trending Sounds
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
     * @param {string} [search] Search term
     * @param {number} [bpm_max] Maxmium is 250
     * @param {number} [bpm_min] 
     * @param {StringOrStringArray} [key] 
     * @param {string} [keyType] Display attribute: Determines how API returns keys
     * @param {string} [type] 
     * @param {Array<string>} [tags] 
     * @param {Array<string>} [tag_groups] 
     * @param {Array<string>} [artists] 
     * @param {Array<string>} [subgenre] 
     * @param {StringOrStringArray} [label] 
     * @param {string} [sound_package_id] 
     * @param {string} [curated_id] 
     * @param {string} [drive_id] 
     * @param {StringOrStringArray} [genre] 
     * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
     * @param {boolean} [suggested] Only return suggested sounds
     * @param {boolean} [hide_prev_played] Only return sounds not played yet
     * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
     * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
     * @param {Array<string>} [tag_categories] 
     * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
     * @param {string} [synth] Filter by preset synth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundsApi
     */
    public getFeaturedSounds_2(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
        return SoundsApiFp(this.configuration).getFeaturedSounds_2(limit, skip, sort_by, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Preset
     * @param {string} id The preset Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundsApi
     */
    public getPreset(id: string, options?: any) {
        return SoundsApiFp(this.configuration).getPreset(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Search Presets
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
     * @param {string} [search] Search term
     * @param {Array<string>} [tags] 
     * @param {Array<string>} [tag_groups] 
     * @param {Array<string>} [artists] 
     * @param {Array<string>} [subgenre] 
     * @param {StringOrStringArray} [label] 
     * @param {string} [sound_package_id] 
     * @param {string} [curated_id] 
     * @param {string} [drive_id] 
     * @param {StringOrStringArray} [genre] 
     * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
     * @param {Array<string>} [tag_categories] 
     * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
     * @param {string} [synth] Filter by preset synth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundsApi
     */
    public getPresets(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
        return SoundsApiFp(this.configuration).getPresets(limit, skip, sort_by, search, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, only_free, tag_categories, trending, synth, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Similar Sounds
     * @param {string} id The sound Id
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundsApi
     */
    public getSimilarSounds(id: string, limit?: number, skip?: number, options?: any) {
        return SoundsApiFp(this.configuration).getSimilarSounds(id, limit, skip, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Sound
     * @param {string} id The sound Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundsApi
     */
    public getSound(id: string, options?: any) {
        return SoundsApiFp(this.configuration).getSound(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Search Sounds
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {SortByQueryOptions} [sort_by] default is depends on the endpoint used.
     * @param {string} [search] Search term
     * @param {number} [bpm_max] Maxmium is 250
     * @param {number} [bpm_min] 
     * @param {StringOrStringArray} [key] 
     * @param {string} [keyType] Display attribute: Determines how API returns keys
     * @param {string} [type] 
     * @param {Array<string>} [tags] 
     * @param {Array<string>} [tag_groups] 
     * @param {Array<string>} [artists] 
     * @param {Array<string>} [subgenre] 
     * @param {StringOrStringArray} [label] 
     * @param {string} [sound_package_id] 
     * @param {string} [curated_id] 
     * @param {string} [drive_id] 
     * @param {StringOrStringArray} [genre] 
     * @param {boolean} [showHalfTime] If bpm filter is set, will also filter half time bpm
     * @param {boolean} [suggested] Only return suggested sounds
     * @param {boolean} [hide_prev_played] Only return sounds not played yet
     * @param {boolean} [hide_prev_downloaded] Only return sounds not downloaded yet
     * @param {boolean} [only_free] Only return free sounds. For packs this will return packs containing at least one free sound
     * @param {Array<string>} [tag_categories] 
     * @param {boolean} [trending] Only return trending sounds. For packs this will return packs containing at least one trending sound
     * @param {string} [synth] Filter by preset synth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundsApi
     */
    public getSounds(limit?: number, skip?: number, sort_by?: SortByQueryOptions, search?: string, bpm_max?: number, bpm_min?: number, key?: StringOrStringArray, keyType?: string, type?: string, tags?: Array<string>, tag_groups?: Array<string>, artists?: Array<string>, subgenre?: Array<string>, label?: StringOrStringArray, sound_package_id?: string, curated_id?: string, drive_id?: string, genre?: StringOrStringArray, showHalfTime?: boolean, suggested?: boolean, hide_prev_played?: boolean, hide_prev_downloaded?: boolean, only_free?: boolean, tag_categories?: Array<string>, trending?: boolean, synth?: string, options?: any) {
        return SoundsApiFp(this.configuration).getSounds(limit, skip, sort_by, search, bpm_max, bpm_min, key, keyType, type, tags, tag_groups, artists, subgenre, label, sound_package_id, curated_id, drive_id, genre, showHalfTime, suggested, hide_prev_played, hide_prev_downloaded, only_free, tag_categories, trending, synth, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Report Sound
     * @param {string} id The sound Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundsApi
     */
    public reportSound(id: string, options?: any) {
        return SoundsApiFp(this.configuration).reportSound(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Stream Sound
     * @param {string} id The sound Id
     * @param {string} location The page location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundsApi
     */
    public streamSound(id: string, location: string, options?: any) {
        return SoundsApiFp(this.configuration).streamSound(id, location, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update Sound stream Duration
     * @param {string} id The sound Id
     * @param {StreamDurationBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundsApi
     */
    public streamSoundSetDuration(id: string, body?: StreamDurationBody, options?: any) {
        return SoundsApiFp(this.configuration).streamSoundSetDuration(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Unfavorite Sound
     * @param {string} id The sound Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundsApi
     */
    public unfavoriteSound(id: string, options?: any) {
        return SoundsApiFp(this.configuration).unfavoriteSound(id, options)(this.fetch, this.basePath);
    }

}
