import classNames from 'classnames';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import objectToGetParams from 'libs/utils/src/lib/queryParams';
import { showToast } from '@bpm-web-app/utils';
import styles from './social-share.module.css';
import FacebookIcon from '../../../assets/icons/facebook.svg';
import TwitterIcon from '../../../assets/icons/twitter.svg';
import LinkedinIcon from '../../../assets/icons/linkedin.svg';
import LinkIcon from '../../../assets/icons/link.svg';
import EmailIcon from '../../../assets/icons/email.svg';

export interface SocialShareProps {
    platforms: Array<'facebook' | 'twitter' | 'linkedin' | 'link' | 'email'>;
    url: string;
    icon: ReactNode;
    title?: string;
}

export function SocialShare({ platforms, url, icon, title }: SocialShareProps) {
    const [isOpen, setIsOpen] = useState(false);
    const wrapper = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const handleDocClick = (event: MouseEvent) => {
            if (!wrapper?.current) return;
            if (wrapper.current !== event.target && !wrapper.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('click', handleDocClick);
        return () => {
            document.removeEventListener('click', handleDocClick);
        };
    }, []);

    const openShareWindow = useCallback((windowUrl) => {
        const left = (window.screen.width - 570) / 2;
        const top = (window.screen.height - 570) / 2;
        const params = `menubar=no,toolbar=no,status=no,width=570,height=570,top=${top},left=${left}`;
        window.open(windowUrl, 'Share', params);
    }, []);

    const copyUrl = useCallback(() => {
        navigator.clipboard?.writeText(url);
        showToast({ type: 'success', message: 'Copied to clipboard.' });
    }, [url]);

    const platformsData = useMemo(
        () =>
            [
                platforms.includes('facebook') && {
                    id: 'facebook',
                    label: 'Facebook',
                    icon: <FacebookIcon />,
                    onClick: () => {
                        openShareWindow(`https://www.facebook.com/sharer.php?u=${url}`);
                        setIsOpen(false);
                    },
                },
                platforms.includes('twitter') && {
                    id: 'twitter',
                    label: 'Twitter',
                    icon: <TwitterIcon />,
                    onClick: () => {
                        openShareWindow(`https://twitter.com/intent/tweet?url=${url}`);
                        setIsOpen(false);
                    },
                },
                platforms.includes('linkedin') && {
                    id: 'linkedin',
                    label: 'LinkedIn',
                    icon: <LinkedinIcon />,
                    onClick: () => {
                        openShareWindow(`https://www.linkedin.com/sharing/share-offsite/?url=${url}`);
                        setIsOpen(false);
                    },
                },
                platforms.includes('link') && {
                    id: 'link',
                    label: 'Copy Link',
                    icon: <LinkIcon />,
                    onClick: () => {
                        copyUrl();
                        setIsOpen(false);
                    },
                },
                platforms.includes('email') && {
                    id: 'email',
                    label: 'Email',
                    icon: <EmailIcon />,
                    onClick: () => {
                        const windowUrl = `mailto:your-recipient@bpmsupreme.com${objectToGetParams({ subject: 'Giveaway', body: url })}`;
                        window.open(windowUrl, 'Share');
                        setIsOpen(false);
                    },
                },
            ].filter(Boolean),
        [copyUrl, openShareWindow, platforms, url]
    );

    return (
        <div
            className={classNames(styles['social-share'], {
                [styles['social-share--open']]: isOpen,
            })}
            ref={wrapper}
        >
            <button type="button" onClick={() => setIsOpen(!isOpen)} className={styles['social-share__toggle']}>
                {icon}
                {title}
            </button>
            <div className={styles['social-share__menu']}>
                {platformsData.map((platform) => (
                    <button onClick={platform.onClick} key={platform.id} type="button" className={styles['social-share__menu-item']}>
                        {platform.icon}
                        {platform.label}
                    </button>
                ))}
            </div>
        </div>
    );
}

export default SocialShare;
