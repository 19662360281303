import { User as UserAPIClient } from '@bpm-web-app/api-client';
import { Membership, User } from '@bpm-web-app/supreme-api-sdk';
import { useCurrentUser, useGetMeSubscription } from '@bpm-web-app/swr-hooks';
import { Analytics, DeviceManager, useApiErrorHandler } from '@bpm-web-app/utils';
import Router from 'next/router';
import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSWRConfig } from 'swr';
import Loader from '../ui/loader/loader';

interface AuthState {
    isInitialized: boolean;
    isAuthenticated: boolean;
    user: User | null;
}

interface SessionContextValue extends AuthState {
    logout: () => void;
    userMemberships: {
        stream?: Membership;
        download?: Membership;
    };
    platformUser: {
        stream?: User;
        download?: User;
    };
}

export const SessionContext = createContext({} as SessionContextValue);

const initialState: AuthState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
};

export const SessionProvider: FC = ({ children }) => {
    const user = useCurrentUser();
    const userData = user.data?.data.user || null;
    const [authState, setAuthState] = useState<AuthState>({ ...initialState, user: userData, isAuthenticated: user !== undefined });
    const [userMemberships, setUserMemberships] = useState<SessionContextValue['userMemberships']>({});
    const { cache } = useSWRConfig();
    const [platformUser, setPlatformUser] = useState<SessionContextValue['platformUser']>({});

    const { data: streamUserData } = useGetMeSubscription(authState.isAuthenticated ? 'stream' : '');
    const { data: downloadUserData } = useGetMeSubscription(authState.isAuthenticated ? 'supreme' : '');
    const errorHandler = useApiErrorHandler();

    useEffect(() => {
        if (downloadUserData?.data) {
            setUserMemberships((prevState) => ({ ...prevState, download: downloadUserData.data.membership }));
            setPlatformUser((prevState) => ({ ...prevState, download: downloadUserData.data.user }));
        }

        if (streamUserData?.data) {
            setUserMemberships((prevState) => ({ ...prevState, stream: streamUserData.data.membership }));
            setPlatformUser((prevState) => ({ ...prevState, stream: streamUserData.data.user }));
        }
    }, [downloadUserData, streamUserData]);

    const handlePostAuthActions = useCallback(
        (authenticatedUser: User) => {
            setAuthState({ isAuthenticated: true, isInitialized: true, user: authenticatedUser });
        },
        []
    );

    const handleLogout = useCallback(async () => {
        try {
            Analytics.trackUserId('');
            await UserAPIClient.logout();
        } catch (error) {
            // Placeholder
        }
        (cache as any)?.clear();
        setAuthState({ ...initialState, isInitialized: true });

        // eslint-disable-next-line @typescript-eslint/dot-notation
        Router.replace(`${process.env['NEXT_PUBLIC_POOL_URL']}/login`);
    }, [cache]);

    useEffect(() => {
        const initialize = async () => {
            try {
                await DeviceManager.register();
                if (userData?.id) Analytics.trackUserId(`${userData.id}`);
                if (userData) handlePostAuthActions(userData);
            } catch (error) {
                errorHandler({ error });
            }
        };

        initialize();

        return () => {
            initialize();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData]);

    const value = useMemo<SessionContextValue>(
        () => ({
            logout: handleLogout,
            userMemberships,
            platformUser,
            ...authState,
        }),
        [authState, handleLogout, platformUser, userMemberships]
    );

    return <SessionContext.Provider value={value}>{authState.isAuthenticated ? children : <Loader />}</SessionContext.Provider>;
};

export function useSession() {
    return useContext(SessionContext);
}
