import { useSWRConfig } from 'swr';

import { UserPlaylist } from '@bpm-web-app/api-client';
import { getUserPlaylistsByCategoryIdKey, userPlaylistDetailSWRKey, useUserPlaylistSets, useUserPlaylistSetsByCatId } from '@bpm-web-app/swr-hooks';
import { useApiErrorHandler, useHubSwitch, showToast } from '@bpm-web-app/utils';
import { IdInviteBody, UserplaylistinvitesIdBody } from '@bpm-web-app/download-api-sdk';

export const useUserPlaylists = (categoryId: number | string | undefined, include_view_only?: boolean) => {
    const { mutate } = useSWRConfig();
    const errorHandler = useApiErrorHandler();
    const { isDownload, } = useHubSwitch();
    const { data: userPlaylists, isLoading, mutate: mutateUserPlaylist } = useUserPlaylistSetsByCatId(isDownload, categoryId, include_view_only);
    const { mutate: mutatePlaylistSets } = useUserPlaylistSets(isDownload, include_view_only);

    const sendUpdateEvent = () => {
        if (!categoryId) {
            const updatedPlaylist = new CustomEvent('playlist-update', {
                bubbles: true,
            });
            document.dispatchEvent(updatedPlaylist);
        }
    };

    const mainCreatePlaylist = async (folderId: number | null, name: string) => {
        try {
            const response = folderId ? await UserPlaylist.createUserPlaylist(isDownload, folderId, name, 0) : await UserPlaylist.createUserPlaylistWithoutCategory(name, 0);

            mutateUserPlaylist();
            mutatePlaylistSets();

            if (response) {
                showToast({ type: 'success', message: 'Playlist created.', buttonText: 'Go To Playlist', replaceRouteOnButtonClick: `/my-playlist/${response.data.id}` });
            }

            sendUpdateEvent();
            return response;
        } catch (error) {
            errorHandler({ error, message: 'Failed to create playlist. Please try again.' });
            return null;
        }
    };

    const createPlaylist = async (name: string, withoutCategory?: boolean) => {
        const response = await mainCreatePlaylist(withoutCategory ? null : (categoryId as number), name);
        return response;
    };

    const createPlaylistOnFolder = async (folderId: number, name: string) => {
        const response = await mainCreatePlaylist(folderId, name);
        return response;
    };

    const deletePlaylist = async (playlistId: string) => {
        try {
            const response = await UserPlaylist.deleteUserPlaylist(isDownload, playlistId);
            mutateUserPlaylist();
            mutatePlaylistSets();
            showToast({ type: 'success', message: 'Playlist deleted.' });
            sendUpdateEvent();
            return response;
        } catch (error) {
            errorHandler({ error, message: 'Failed to delete playlist. Please try again.' });
            return null;
        }
    };

    const editPlaylist = async (playlistId: string, name: string) => {
        try {
            const playlist = await UserPlaylist.updateUserPlaylist(isDownload, playlistId, { title: name, order: 0 });
            mutate(userPlaylistDetailSWRKey(isDownload, playlistId), playlist);
            mutatePlaylistSets();
            sendUpdateEvent();
            showToast({ type: 'success', message: 'Playlist edited.' });
        } catch (error) {
            errorHandler({ error, message: 'Failed to create playlist. Please try again.' });
        }
    };

    const editPlaylistCategory = async (playlistId: string, newCategoryId: string) => {
        try {
            const playlist = await UserPlaylist.updateUserPlaylist(isDownload, playlistId, { category_id: newCategoryId });
            mutate(userPlaylistDetailSWRKey(isDownload, playlistId), playlist);
            mutatePlaylistSets();
            mutateUserPlaylist();
            mutate(getUserPlaylistsByCategoryIdKey(isDownload, newCategoryId, include_view_only), { revalidate: true });
            sendUpdateEvent();
            showToast({ type: 'success', message: 'Playlist edited.' });
        } catch (error) {
            errorHandler({ error, message: 'Failed to update playlist. Please try again.' });
        }
    };

    const removePlaylistFromCategory = async (playlistId: string) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - have to set category_id to null
        await UserPlaylist.updateUserPlaylist(isDownload, playlistId, { category_id: null });
        mutate(`get-user-playlist-categories-${isDownload}`);
        mutateUserPlaylist();
        await mutatePlaylistSets();
        showToast({ type: 'success', message: 'Playlist edited.' });
    };

    const editFolder = async (name: string) => {
        try {
            await UserPlaylist.updateUserPlaylistCategory(isDownload, categoryId as number, name, 0);
            mutate(`get-user-playlist-categories-${isDownload}`);
            showToast({ type: 'success', message: 'Folder edited.' });
        } catch (error) {
            errorHandler({ error });
        }
    };

    const removeTrackFromPlaylist = async (playlistId: string, trackId: number) => {
        try {
            const playlist = await UserPlaylist.removeTrackFromPlaylist(isDownload, playlistId, trackId);
            showToast({ type: 'success', message: 'Removed from playlist.' });
            mutate(userPlaylistDetailSWRKey(isDownload, playlistId), playlist);
        } catch (error) {
            errorHandler({ error, message: 'Failed to remove track from playlist. Please try again.' });
        }
    };

    const updateMyPlaylistPicture = async (playlistId: string, file: Blob) => {
        try {
            const res = await UserPlaylist.updateMyPlaylistPicture(playlistId, file);
            return res.data;
        } catch (e) {
            if (e.json) {
                const jsonError = await e.json();
                if (jsonError && jsonError.message) showToast({ type: 'error', title: jsonError.message });
            }
            return null;
        }
    };

    const getUserToUserPlaylistInvites = async (playlistId: string) => {
        try {
            const invites = await UserPlaylist.getUserPlaylistInvites(playlistId);
            return invites.data;
        } catch (e) {
            if (e.json) {
                const jsonError = await e.json();
                if (jsonError && jsonError.message) showToast({ type: 'error', title: jsonError.message });
            }
            return null;
        }
    };

    const inviteUserToUserPlaylist = async (playlistId: string, invitee: IdInviteBody) => {
        try {
            const invites = await UserPlaylist.inviteUserToPlaylist(playlistId, invitee);
            return invites.data;
        } catch (e) {
            if (e.json) {
                const jsonError = await e.json();
                if (jsonError && jsonError.message) showToast({ type: 'error', title: jsonError.message });
            }
            return null;
        }
    };

    const editUserInviteToPlaylist = async (inviteId: string, invitee: UserplaylistinvitesIdBody) => {
        try {
            const invites = await UserPlaylist.editUserInviteToPlaylist(inviteId, invitee);
            return invites.data;
        } catch (e) {
            if (e.json) {
                const jsonError = await e.json();
                if (jsonError && jsonError.message) showToast({ type: 'error', title: jsonError.message });
            }
            return null;
        }
    };

    const removeUserFromPlaylist = async (inviteId: string) => {
        try {
            const invites = await UserPlaylist.removeUserFromPlaylist(inviteId);
            return invites.data;
        } catch (e) {
            if (e.json) {
                const jsonError = await e.json();
                if (jsonError && jsonError.message) showToast({ type: 'error', title: jsonError.message });
            }
            return null;
        }
    };

    const acceptInviteToPlaylist = async (inviteId: string) => {
        try {
            const invites = await UserPlaylist.acceptPlaylistInvite(inviteId);
            return invites;
        } catch (e) {
            if (e.json) {
                const jsonError = await e.json();
                if (jsonError && jsonError.message) showToast({ type: 'error', title: jsonError.message });
            }
            return null;
        }
    };

    const declineInviteToPlaylist = async (inviteId: string) => {
        try {
            const invites = await UserPlaylist.declinePlaylistInvite(inviteId);

            return invites;
        } catch (e) {
            if (e.json) {
                const jsonError = await e.json();
                if (jsonError && jsonError.message) showToast({ type: 'error', title: jsonError.message });
            }
            return null;
        }
    };

    return {
        mainCreatePlaylist,
        userPlaylists,
        isLoading,
        createPlaylist,
        editPlaylist,
        editFolder,
        deletePlaylist,
        reload: mutateUserPlaylist,
        removeTrackFromPlaylist,
        createPlaylistOnFolder,
        getUserToUserPlaylistInvites,
        inviteUserToUserPlaylist,
        editUserInviteToPlaylist,
        removeUserFromPlaylist,
        acceptInviteToPlaylist,
        declineInviteToPlaylist,
        updateMyPlaylistPicture,
        editPlaylistCategory,
        removePlaylistFromCategory
    };
};
