import React, { ReactNode, useCallback, useContext, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { DOWNLOAD_ONLY, getSignupRedirectLink, useUserSettings } from '@bpm-web-app/utils';
import styles from './account-page-layout.module.css';
import { NavContext } from '../../nav/nav.context';
import ChevronLeft from '../../../assets/icons/chevron-left.svg';
import { AppLink } from '../../shared/app-link/app-link';

export interface AccountPageLayoutProps {
    isCreate?: boolean;
    isArtistPlatform?: boolean;
    children: ReactNode;
}
export function AccountPageLayout({ children, isCreate = false, isArtistPlatform = false }: AccountPageLayoutProps) {
    const { showNav, hideNav } = useContext(NavContext);
    const { isAnonymous } = useUserSettings();

    const isWebview = !!window.isRNWebView;

    useEffect(() => {
        hideNav();
        return function cleanup() {
            showNav();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const router = useRouter();

    const links = useMemo(() => {
        const items = [
            { label: 'Account', url: isAnonymous ? getSignupRedirectLink('/account/overview') : '/account/overview' },
            { label: 'Plans', url: isAnonymous ? getSignupRedirectLink('/account/plan') : '/account/plan' },
            { label: 'Payment', url: isAnonymous ? getSignupRedirectLink('/account/payment') : '/account/payment' },
            { label: 'Downloads', url: isAnonymous ? getSignupRedirectLink('/account/downloads') : '/account/downloads' },
            { label: 'Settings', url: isAnonymous ? getSignupRedirectLink('/account/settings') : '/account/settings' },
            { label: 'Support', url: '/account/support' },
        ];
        if (!isCreate && !DOWNLOAD_ONLY) items.splice(3, 0, { label: 'Add Music', url: '/account/add-music' });
        if (isArtistPlatform) {
            items.splice(1, 3);
        }
        return items;
    }, [isCreate, isArtistPlatform, isAnonymous]);

    const isDjVerificationPageActive = useCallback((currentLabel: string) => router.pathname.includes('/dj-verification/') && currentLabel.toLowerCase() === 'plans', [router.pathname]);

    const activeSection = links.find(({ url }) => router.pathname.includes(url));

    return (
        <div className={styles['account-page-layout']}>
            <div className={styles['account-page-layout__container']}>
                <nav className={styles['account-page-layout__nav-desktop']}>
                    <AppLink href="/">
                        <a className={classNames(styles['account-page-layout__link'], styles['account-page-layout__link--chevron'])}>
                            <ChevronLeft />
                            Back to Home
                        </a>
                    </AppLink>
                    {links.map(({ label, url }) => (
                        <AppLink key={url} href={url} forceCurrentWindow>
                            <a
                                className={classNames(styles['account-page-layout__link'], {
                                    [styles['account-page-layout__link--active']]: router.pathname.endsWith(url) || isDjVerificationPageActive(label),
                                })}
                            >
                                {label}
                            </a>
                        </AppLink>
                    ))}
                </nav>
                <nav className={classNames(styles['account-page-layout__nav-mobile'], { [styles['account-page-layout__nav-RNmobile']]: isWebview })}>
                    <AppLink href="/">
                        <a aria-label="Back to Home" className={styles['account-page-layout__back-to-home-link']}>
                            <ChevronLeft />
                        </a>
                    </AppLink>
                    {activeSection && <h3>{activeSection.label}</h3>}
                    <div className={styles['account-page-layout__right-button-container']} />
                </nav>
                <div className={styles['account-page-layout__content-container']}>{children}</div>
            </div>
        </div>
    );
}

export default AccountPageLayout;
