import { Credits } from '@bpm-web-app/api-client';
import useSWR from 'swr';

export function useGetCredits(disabled = false) {
    const { data, error, mutate } = useSWR(
        [
            'credits',
            {
                disabled,
                persist: true,
            },
        ],
        () => Credits.getCredits(),
        {
            isPaused: () => !!disabled,
        }
    );

    return {
        data: data?.data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}
