import { useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import PinInput from 'react-pin-input';
import styles from './pin-modal.module.css';
import CloseIcon from '../../../../assets/icons/close.svg';
import EyeIcon from '../../../../assets/icons/eye.svg';

export enum PinState {
    CREATE,
    REMOVE,
}
interface PinModalProps {
    isDisable: boolean;
    onModalClose: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setValue: UseFormSetValue<any>;
    setTypePin: (show: PinState) => void;
}

export default function PinModal({ onModalClose, setValue, isDisable, setTypePin }: PinModalProps) {
    let refPinInput: PinInput = null;
    const [isPinVisible, setVisibilityPin] = useState(false);
    const [insertedPin, setInsertedPin] = useState(null);

    const inputStyle = {
        marginRight: '16px',
        background: 'var(--color-tundora)',
        color: 'var(--color-gin)',
        border: 'none',
        fontSize: '20px',
        width: '40px',
        height: '40px',
    };

    const handlerPinVisibility = () => {
        setVisibilityPin(!isPinVisible);
    };
    const onChange = (value: string) => {
        setInsertedPin(value);
    };
    const onClose = () => {
        if (isDisable) {
            setValue('pinCode', insertedPin);
            setValue('parentalControl', true);
        } else {
            setValue('parentalControl', false);
        }
        onModalClose();
    };
    const onDisable = () => {
        setTypePin(PinState.REMOVE);
        setValue('pinCode', insertedPin);
        onModalClose();
    };
    const onCreate = () => {
        setTypePin(PinState.CREATE);
        setValue('pinCode', insertedPin);
        onModalClose();
    };
    return (
        <div className={styles['pin-modal']}>
            <div className={styles['pin-modal__modal-main']}>
                <div className={styles['pin-modal__container']}>
                    <div className={styles['pin-modal__title']}>
                        <h3>Enter PIN</h3>
                        <button className={styles['pin-modal__button-icon']} onClick={onClose} type="button">
                            <CloseIcon />
                        </button>
                    </div>
                    <div className={styles['pin-modal__content']}>
                        {!isDisable && <span>Create a personal identification number (PIN) to turn on parental controls and remove explicit content.</span>}
                        <div className={styles['pin-modal__input-section']}>
                            <PinInput
                                length={4}
                                initialValue=""
                                secret={!isPinVisible}
                                type="numeric"
                                ref={(e: PinInput) => {
                                    refPinInput = e;
                                }}
                                inputStyle={inputStyle}
                                onChange={(value: string) => onChange(value)}
                                autoSelect={false}
                            />
                            <button className={isPinVisible ? styles['pin-modal__button-icon--active'] : styles['pin-modal__button-icon']} onClick={handlerPinVisibility} type="button">
                                {/* TODO add icon for the other state */}
                                <EyeIcon />
                            </button>
                        </div>
                        {isDisable && (
                            <a className={styles['pin-modal__button-forgot']} href="https://support.bpmsupreme.com/hc/en-us/" target="_blank" rel="noreferrer">
                                Forgot PIN?
                            </a>
                        )}
                        <button disabled={insertedPin?.length !== 4} className={styles['pin-modal__button']} type="button" onClick={isDisable ? onDisable : onCreate}>
                            {isDisable ? 'Disable PIN' : 'Create PIN'}
                        </button>
                        <button className={styles['pin-modal__button-cancel']} type="button" onClick={onClose}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
