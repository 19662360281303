import { createAppRoutes, DEFAULT_BROWSE_CARD_LIMIT } from '@bpm-web-app/utils';
import { useNews, useNewsTags, useGetAllGiveaways, useContests } from '@bpm-web-app/swr-hooks';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import styles from './news.module.css';
import Dropdown from '../../dropdown/dropdown';
import { CreateCardCarousel } from '../../create-card-carousel/create-card-carousel';
import { NewsCard } from '../../shared/card/news-card.tsx/news-card';
import { GiveawayCard } from '../../shared/card/giveaway-card/giveaway-card';

dayjs.extend(utc);
dayjs.extend(tz);
export interface NewsProps {
    newsProviderId: string;
    isCreate: boolean;
}

export function News({ newsProviderId, isCreate }: NewsProps) {
    const getSubtitleMarkup = useCallback((card: any) => {
        const lastTag = card.tags[card.tags.length - 1];
        return <div className="card__artist-description--single-line">{`${lastTag.title} - ${dayjs(lastTag.created_at).format('MMMM D, YYYY')}`.toUpperCase()}</div>;
    }, []);

    const parseNewsText = (text: string) => {
        const linkRegex = /\$LINK_DATA((\s|.)+?)LINK_DATA\$/g;
        const linkSeparatorRegex = /\$LINK_DATA((\s|.)+?)\$LINK_SEPARATOR((\s|.)+?)LINK_DATA\$/g;
        const imageRegex = /\$IMAGE_DATA((\s|.)+?)IMAGE_DATA\$/g;
        const songsRegex = /\$SONG_DATA((\s|.)+?)SONG_DATA\$/g;
        const songsNewlineRegex = /SONG_DATA\$(\s|\\n+|R)\$SONG_DATA/g;
        const youtubeRegex = /\$YOUTUBE_VIDEO((\s|.)+?)YOUTUBE_VIDEO\$/g;
        return text.replace(linkSeparatorRegex, '$1').replace(linkRegex, '').replace(imageRegex, '').replace(songsRegex, '').replace(songsNewlineRegex, '').replace(youtubeRegex, '').split('\n')[0];
    };

    if (!newsProviderId) return <div />;

    const defaultTagDropdownOption = {
        label: 'All',
        value: '',
    };
    const [activeTag, setActiveTag] = useState<string>(defaultTagDropdownOption.value);

    const { data, isLoading } = useNews({
        providerId: newsProviderId,
        tag: activeTag,
    });
    const { data: newsTags } = useNewsTags(newsProviderId);

    const { contests: contestsData } = useContests(!isCreate);
    const contestsCarouselData = useMemo(
        () =>
            contestsData?.current
                ?.map((contest) => ({
                    id: contest.id,
                    title: contest.title,
                    coverUrl: contest.artwork_url,
                    slug: contest.slug,
                    submission_deadline: contest.submission_deadline

                }))
                .concat(
                    contestsData?.past?.map((contest) => ({
                        id: contest.id,
                        title: contest.title,
                        coverUrl: contest.artwork_url,
                        slug: contest.slug,
                        label: 'expired',
                        submission_deadline: contest.submission_deadline
                    }))
                )
                .slice(0, DEFAULT_BROWSE_CARD_LIMIT),
        [contestsData]
    );

    const { data: giveaways } = useGetAllGiveaways(isCreate);

    const news = data?.data.slice(0, 20);

    if (isLoading || !news || !newsTags) return <div />;
    const firstNews = news.shift();

    return (
        /* eslint-disable-next-line @typescript-eslint/dot-notation */
        <div className="spacing__window">
            <div className={styles['news__heading']}>
                <h2>BPM News</h2>
                <Dropdown<string>
                    value={activeTag}
                    options={[
                        defaultTagDropdownOption,
                        ...newsTags.data.map(({ title, id }) => ({
                            label: title,
                            value: id,
                        })),
                    ]}
                    onClick={(option) => {
                        setActiveTag(option);
                    }}
                />
            </div>
            <div className={styles['news__big-card']}>
                <div className={styles['news__big-card-img-container']}>
                    <img src={firstNews.image_url} draggable={false} alt={firstNews.title} />
                </div>
                <div className={styles['news__big-card-text-container']}>
                    <div className={styles['news__big-card-title']}>{firstNews.title}</div>
                    <div className={styles['news__big-card-subtitle']}>{getSubtitleMarkup(firstNews)}</div>
                    <div className={styles['news__big-card-text-content']}>{parseNewsText(firstNews.rendered)}</div>
                    <a className={classNames('button button--solid', styles['news__big-card-cta'])} href={firstNews.link} target="_blank" rel="noreferrer">
                        Read More
                    </a>
                </div>
            </div>
            {isCreate ? (
                <CreateCardCarousel
                    contentType="contest"
                    items={contestsCarouselData}
                    cardSize="large"
                    carouselMorePath={createAppRoutes.contests}
                    isLoading={false}
                    carouselTitle="Contests"
                    noPadding
                    itemBasePath={createAppRoutes.contest}
                    isInContainer
                />

            ) : (
                <>
                    <h3 className={styles['news__title']}>Contests & Giveaways</h3>
                    <div className={styles['news__grid']}>
                        {giveaways?.data.current?.map((item, index) => (
                            <GiveawayCard key={item.id} giveaway={item} />
                        ))}
                        {giveaways?.data.past?.map((item, index) => (
                            <GiveawayCard key={item.id} giveaway={item} isPast />
                        ))}
                    </div>
                </>
            )}
            <div className="spacing--top" />
            <h3 className={styles['news__title']}>DJ Articles</h3>
            <div className={styles['news__grid']}>
                {news &&
                    news.map((card) => (
                        <NewsCard key={card.id} news={card} newsList={news} isCreate={isCreate} />

                    ))
                }
            </div>
        </div>
    );
}

export default News;
