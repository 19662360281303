import { AlbumApi } from '@bpm-web-app/download-api-sdk';
import { SearchQuery } from '../../typings/search-query';
import { downloadConfig } from '../../config';
import { TrendingByPeriodQuery } from '../../typings';
import { FeaturedQuery } from '../../typings/featured-query';

export class Album {
    static client = new AlbumApi(downloadConfig);

    static getAlbumsForCategory = (args: SearchQuery) =>
        Album.client.getAlbumsForCategory(
            args.category,
            args.library,
            args.genre as string,
            args.artist,
            args.hide_remix ? '1' : '0',
            args.hide_originals ? '1' : '0',
            args.hide_exclusives ? '1' : '0',
            args.hide_explicit ? '1' : '0',
            args.hide_prev_downloaded ? '1' : '0',
            args.version as string,
            args.key as string,
            args.keyType,
            args.bpm_min,
            args.bpm_max,
            args.sort_by,
            args.term,
            args.date
        );

    static getTrendingAlbumPeriod = (args: TrendingByPeriodQuery) => Album.client.getTrendingAlbumPeriod(args.period, args.limit, args.skip, args.category, args.genre, args.library);

    static getFeaturedBpmOriginalAlbums = (args: FeaturedQuery) =>
        Album.client.getFeaturedBpmoriginalAlbums(args.limit, args.skip, args.keyType, args.sort_by, args.library);

    static getSimilarAlbum = (albumId: string | number, library: string) => Album.client.similarAlbum(Number(albumId), library);

    static getRemixesAlbum = (albumId: string | number) => Album.client.remixesAlbum(Number(albumId));

    static getFavAlbums = (library?: string) => Album.client.getFavAlbums(library);

    static favoriteAlbum = (albumId: string | number) => Album.client.favoriteAlbum(Number(albumId));

    static unfavoriteAlbum = (albumId: string | number) => Album.client.unfavoriteAlbum(Number(albumId));

    static favoriteMultipleAlbums = (albumIds: number[]) => Album.client.favoriteAlbumsBatch({ album_ids: albumIds });

    static getSuggestedAlbumsForTerm = (args: { term: string; library?: string; keyType?: string }) =>
        Album.client.suggestAlbumArtist(args.term, args.library, args.keyType);
}
