import { ReactElement, useState } from 'react';
import classNames from 'classnames';
import { ApplicationSectionHeader } from '../artist-application-text/artist-application-text';
import styles from './artist-application-dropdown.module.css';
import { ReactComponent as ChevronRightIcon } from '../../../../../assets/icons/chevron-right.svg';
import { ReactComponent as ChevronDownIcon } from '../../../../../assets/icons/chevron-up.svg';

interface ArtistsApplicationDropdown {
    title: string;
    bottomComponent: ReactElement;
}

export function ArtistApplicationDropdown({ title, bottomComponent }: ArtistsApplicationDropdown) {
    const [closed, setClosed] = useState(true);
    return (
        <div className={styles['application-dropdown']}>
            <button
                type="button"
                onClick={(e) => {
                e.preventDefault();
                setClosed(!closed);
                }}
                className={styles['application-dropdown--button']}
            >
                <div className={styles['application-dropdown--container']}>
                    <ApplicationSectionHeader subtitle={title} />

                    {!closed ? <ChevronDownIcon className={styles['application-dropdown--down']} /> : <ChevronRightIcon /> }

                </div>
            </button>
            <div className={classNames(styles['application-dropdown--bottom'], { [styles['application-dropdown--bottom-closed']]: closed })}>
                {bottomComponent}
            </div>
        </div>
    );
}
