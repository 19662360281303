import { Drives } from '@bpm-web-app/api-client';
import { DrivesBody } from '@bpm-web-app/create-api-sdk';
import { showToast, useApiErrorHandler } from '@bpm-web-app/utils';
import useSWR, { useSWRConfig } from 'swr';

const getDrivesSWRKey = 'create-get-drive-folders';

export function useGetDrives(enabled = true, include_view_only?: boolean) {
    const { data, error, mutate } = useSWR(
        [
            getDrivesSWRKey,
            {
                persist: true,
                include_view_only: !!include_view_only
            },
        ],
        () => Drives.getDrives(include_view_only),
        {
            isPaused: () => !enabled,
        }
    );

    return {
        data: data?.data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}

export function useGetDrive(driveId: string, enabled = true) {
    const errorHandler = useApiErrorHandler(false);
    const { data, error, mutate } = useSWR(
        [
            getDrivesSWRKey,
            {
                driveId,
                persist: true,
            },
        ],
        () => Drives.getDrive(driveId),
        {
            isPaused: () => !enabled,
            onError(err) {
                if (err.status === 404 || err.status === 403) {
                    showToast({ type: 'error', message: 'Drive not found or no access.' });
                } else { errorHandler({ error: err }); }
            },
        }
    );

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}

export function useMutateDrives() {
    const { mutate } = useSWRConfig();
    return () => Promise.all([
        mutate([
            getDrivesSWRKey,
            {
                persist: true,
                include_view_only: true
            },
        ], null, { revalidate: true }),
        mutate([
            getDrivesSWRKey,
            {
                persist: true,
                include_view_only: false
            },
        ], null, { revalidate: true })
    ]);
}

export function useCreateDrive() {
    const mutate = useMutateDrives();
    return (data: DrivesBody) => {
        return Drives.createDrive(data).then((d) => {
            mutate();
            return d;
        });
    };
}

export function useDeleteDrive() {
    const { mutate } = useSWRConfig();
    return (driveId: string) => {
        return Drives.deleteDrive(driveId).then((d) => {
            mutate([
                getDrivesSWRKey,
                {
                    persist: true,
                    include_view_only: true
                },
            ], null, { revalidate: true });
            mutate([
                getDrivesSWRKey,
                {
                    persist: true,
                    include_view_only: false
                },
            ], null, { revalidate: true });
            return d;
        });
    };
}

export function useAddSoundToDrive() {
    return Drives.addSoundToDrive;
}

const getDriveInvitesSWRKey = 'create-get-drive-invites';

export function useGetInvitesForDrive(driveId: string) {
    const { data, error, mutate } = useSWR(
        [
            getDriveInvitesSWRKey,
            {
                driveId,
                persist: true
            },
        ],
        () => Drives.getInvitesToDrive(driveId),
        {
            onError(err, key, config) {

            },
        }
    );

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}

const getSharedDrivesSWRKey = 'create-get-shared-drives';

export function useGetSharedDrives(include_view_only: boolean) {
    const { data, error, mutate } = useSWR(
        [
            getSharedDrivesSWRKey,
            {
                include_view_only,
                persist: true,
            },
        ],
        () => Drives.getSharedDrives(include_view_only),
    );

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}

export function useInviteUserToDrive() {
    return Drives.inviteToDrive;
}

export function useAcceptInviteToDrive() {
    const { mutate } = useSWRConfig();
    return (inviteId: string) => {
        return Drives.acceptInviteToDrive(inviteId).then((d) => {
            mutate([
                getDrivesSWRKey,
                {
                    persist: true,
                    include_view_only: true
                },
            ], null, { revalidate: true });
            mutate([
                getDrivesSWRKey,
                {
                    persist: true,
                    include_view_only: false
                },
            ], null, { revalidate: true });
            mutate([
                getSharedDrivesSWRKey,
                {
                    include_view_only: true,
                    persist: true,
                },
            ]);
            mutate([
                getSharedDrivesSWRKey,
                {
                    include_view_only: false,
                    persist: true,
                },
            ]);
            return d;
        });
    };
}

export function useDeclineInviteToDrive() {
    return Drives.declineInviteToDrive;
}

export function useUpdateInviteToDrive() {
    return Drives.editInviteToDrive;
}

export function useRemoveInviteToDrive() {
    return Drives.removeUserFromDrive;
}

export function useRemoveSoundFromDrive() {
    return Drives.removeSoundFromDrive;
}

export function useUpdateDriveName() {
    return Drives.updateDriveName;
}

export function useDownloadDrive() {
    return Drives.downloadDrive;
}

export function useDownloadDrivePreview() {
    return Drives.downloadDrivePreview;
}
