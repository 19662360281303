import { DOWNLOAD_ONLY, formatDateToString, getColorFromLibrary, getLibraryName } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useGetMeSubscription, useGetPaymentInstrument } from '@bpm-web-app/swr-hooks';
import { PaymentInstrumentAuthorize, PaymentInstrumentPaypal, SubscriptionDetailsv4 } from '@bpm-web-app/supreme-api-sdk';
import styles from './subscription-item.module.css';
import { AccountPlanLandingProps } from '../account-plan';
import { SquaredButton } from '../../../shared/squared-button/squared-button';
import { AppLink, BreakpointView } from '../../../shared';
import BPMIcons from '../../../shared/bpm-icons/bpm-icons';

function isPaymentInstrumentCard(paymentData?: PaymentInstrumentAuthorize | PaymentInstrumentPaypal): paymentData is PaymentInstrumentAuthorize {
    return !!(paymentData && 'card' in paymentData);
}

function isPaymentInstrumentPaypal(paymentData?: PaymentInstrumentAuthorize | PaymentInstrumentPaypal): paymentData is PaymentInstrumentPaypal {
    return paymentData?.method === PaymentInstrumentPaypal.MethodEnum.Paypal;
}

function SubscriptionAccountSummary({ isCreate = false }: AccountPlanLandingProps) {
    const { data: createSubscriptionData } = useGetMeSubscription('create', !isCreate);
    const { data: streamSubscriptionData } = useGetMeSubscription('stream', isCreate);
    const { data: downloadSubscriptionData } = useGetMeSubscription('supreme', isCreate);

    // Payment Data
    const { data: paymentDownload, } = useGetPaymentInstrument('supreme', !isCreate);
    const { data: paymentStream, } = useGetPaymentInstrument('stream', !isCreate);
    const { data: paymentCreate, } = useGetPaymentInstrument('create', isCreate);

    const currentSupremeLibrary = useMemo(() => DOWNLOAD_ONLY ? downloadSubscriptionData?.data : streamSubscriptionData?.data, [downloadSubscriptionData?.data, streamSubscriptionData?.data]);
    const currentSupremeLibraryName = useMemo(() => currentSupremeLibrary?.membership.subscription?.library, [currentSupremeLibrary?.membership.subscription?.library]);
    const color = useMemo(() => getColorFromLibrary(currentSupremeLibraryName), [currentSupremeLibraryName]);

    const paymentAmountText = useMemo(() => {
        if (isCreate) return `$${createSubscriptionData?.data.membership.subscription?.package?.amount}/mo`;
        return `$${currentSupremeLibrary?.membership.subscription?.package?.amount}/mo`;
    }, [createSubscriptionData?.data.membership.subscription?.package?.amount, currentSupremeLibrary?.membership.subscription?.package?.amount, isCreate]);

    const colorClass = useMemo(() => styles[`subscription-item__color--${isCreate ? 'generic' : color}`], [color, isCreate]);
    const freeColorClass = styles['subscription-item__color--free'];
    const borderClass = useMemo(() => styles[`subscription-item__border--${isCreate ? 'generic' : color}`], [color, isCreate]);
    const freeBorderClass = styles['subscription-item__border--free'];

    const [paymentMethodText, setPaymentMethodText] = useState('Payment Method');

    const paymentData: any = useMemo(() => {
        if (isCreate) return paymentCreate?.data;
        return DOWNLOAD_ONLY ? paymentDownload?.data : paymentStream?.data;
    }, [isCreate, paymentCreate?.data, paymentDownload?.data, paymentStream?.data]);

    useEffect(() => {
        if (isPaymentInstrumentCard(paymentData) && paymentData?.card?.cardNumber) {
            setPaymentMethodText(`Card ending ${paymentData?.card?.cardNumber}`);
        }
        if (isPaymentInstrumentPaypal(paymentData)) {
            setPaymentMethodText('Paypal');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentData]);

    const isSubscribedToAPlan = useMemo(() => {
        if (isCreate && createSubscriptionData?.data?.membership?.has_membership) {
            return true;
        }
        if (DOWNLOAD_ONLY) {
            return !!downloadSubscriptionData?.data?.membership?.has_membership;
        }
        if (!isCreate && (streamSubscriptionData?.data?.membership?.has_membership || downloadSubscriptionData?.data?.membership?.has_membership)) {
            return true;
        }
        return false;
    }, [downloadSubscriptionData?.data?.membership?.has_membership, streamSubscriptionData?.data?.membership?.has_membership, createSubscriptionData?.data?.membership?.has_membership, isCreate]);

    const isFree = useMemo(() => {
        return !isSubscribedToAPlan;
    }, [isSubscribedToAPlan]);

    const libraryText = useMemo(() => {
        if (isFree) return 'Preview Access';
        if (isCreate) {
            return '';
        }
        // Supreme
        return getLibraryName(currentSupremeLibraryName);
    }, [currentSupremeLibraryName, isCreate, isFree]);

    const titleText = useMemo(() => {
        if (isFree) return 'Free';
        if (isCreate) {
            switch (createSubscriptionData?.data.membership.subscription?.membership_type) {
                case (SubscriptionDetailsv4.MembershipTypeEnum.PremiumPlus):
                    return 'Premium Plus';
                default:
                    return createSubscriptionData?.data.membership.subscription?.membership_type;
            }
        } else {
            // Supreme
            return currentSupremeLibrary?.membership.subscription?.membership_type;
        }
    }, [createSubscriptionData?.data.membership.subscription?.membership_type, currentSupremeLibrary?.membership.subscription?.membership_type, isCreate, isFree]);

    const renewDate = useMemo(() => {
        if (isCreate) {
            if (createSubscriptionData?.data.membership.subscription?.status === SubscriptionDetailsv4.StatusEnum.Cancelled) {
                return `Ends on: ${formatDateToString(createSubscriptionData?.data?.membership.subscription?.next_billing, 'MM/DD/YYYY')}`;
            }
            return `Renews: ${formatDateToString(createSubscriptionData?.data.membership.subscription?.next_billing, 'MM/DD/YYYY')}`;
        }
        if (currentSupremeLibrary?.membership.subscription?.status === SubscriptionDetailsv4.StatusEnum.Cancelled) {
            return `Ends on: ${formatDateToString(currentSupremeLibrary?.membership.subscription?.next_billing, 'MM/DD/YYYY')}`;
        }
        // Supreme
        return `Renews: ${formatDateToString(currentSupremeLibrary?.membership.subscription?.next_billing, 'MM/DD/YYYY')}`;
    }, [createSubscriptionData?.data.membership.subscription, currentSupremeLibrary?.membership.subscription, isCreate]);

    const renderPaymentDetails = useMemo(() => {
        return (
            <div className={classNames(styles['subscription-item__buttons-container'], 'spacing--top', 'spacing--bottom')}>
                <BPMIcons.CardIcon />
                <div className={styles['subscription-item__header--gray-text']}>{isFree ? 'No Payment on File' : paymentMethodText}</div>
            </div>
        );
    }, [isFree, paymentMethodText]);

    const paymentAmount = useMemo(() => {
        if (isFree) return '$0/mo';
        return paymentAmountText;
    }, [isFree, paymentAmountText]);

    return (
        <div className={classNames(styles['subscription-item-container'], 'spacing--top')}>
            <div className={classNames(styles['subscription-item__top-line'], borderClass, { [freeBorderClass]: isFree })} />
            <div className={classNames(styles['subscription-item'], styles['subscription-item__variant--account-summary'])}>
                <div className={styles['subscription-item__header']}>
                    <span className={styles['subscription-item__header--title']}>{titleText}</span>
                    <p className={classNames(styles['subscription-item__header--text'])}>{paymentAmount}</p>

                </div>
                <div className={styles['subscription-item__header']}>
                    <p className={classNames(styles['subscription-item__library-text'], colorClass, { [freeColorClass]: isFree })}>{libraryText}</p>

                    {!isFree ? <div className={styles['subscription-item__header--gray-text']}>{renewDate}</div> : null}
                </div>

                <BreakpointView
                    desktopChildren={null}
                    mobileChildren={renderPaymentDetails}
                />
                <div className={styles['subscription-item__bottom-actions-container']}>
                    <div className={styles['subscription-item__buttons-container']}>
                        <AppLink key="/account/plan" href="/account/plan">
                            <a>
                                <SquaredButton label={isFree ? 'Start Subscription' : 'Change Plan'} type="filled" />
                            </a>
                        </AppLink>
                        <AppLink key="/account/payment" href="/account/payment">
                            <a>
                                <SquaredButton label="Payment Details" type="outline" />
                            </a>
                        </AppLink>
                    </div>
                    <BreakpointView
                        desktopChildren={renderPaymentDetails}
                        mobileChildren={null}
                    />
                </div>
            </div>
        </div>
    );
}

export default SubscriptionAccountSummary;
