import useSWR, { useSWRConfig } from 'swr';
import { User } from '@bpm-web-app/api-client';
import { SubscriptionPackage } from '@bpm-web-app/supreme-api-sdk';
import { useEffect, useRef } from 'react';
import { LOCAL_CACHE_KEY } from '../provider';

export function useCurrentUser() {
    const { cache } = useSWRConfig();
    const previousUserId = useRef<any>(null);
    const { data, error, mutate } = useSWR(
        [
            'supreme-get-user',
            {
                persist: true,
            },
        ],
        () => User.getCurrentUser()
    );

    useEffect(() => {
        if (previousUserId.current === null) {
            previousUserId.current = data?.data.user.id;
        } else if (previousUserId.current && previousUserId.current !== data?.data.user.id) {
            if ('clear' in cache) {
                (cache as Map<any, any>).clear();
                localStorage.removeItem(LOCAL_CACHE_KEY);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}

export function useCurrentUserAddress() {
    const { data, error, mutate } = useSWR(
        [
            'supreme-get-user-address',
            {
                persist: true,
            },
        ],
        () => User.getMyCurrentAddress()
    );

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}

export function useGetMyTutorialStatus(type: string) {
    const { data, error, mutate } = useSWR(
        [
            `supreme-get-user-status-${type}`,
            {
                persist: false,
                type,
            },
        ],
        () => User.getMyTutorialStatus(type)
    );

    return {
        data,
        mutate,
        isLoading: !error && !data,
        error,
    };
}

export function useGetMyPreferences() {
    const { data, error, mutate } = useSWR(
        [
            'supreme-get-user-preferences',
            {
                persist: false,
            },
        ],
        () => User.getMyPreferences()
    );

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}

export function useGetPaymentInstrument(platform?: SubscriptionPackage.PlatformEnum | 'stream' | 'supreme' | 'create', enabled = true) {
    const { data, error, mutate } = useSWR(
        [
            `${platform}-get-payment-instrument`,
            {
                persist: false,
                platform,
            },
        ],
        () => platform ? User.getPaymentInstrument(platform.toString()) : null,
        { isPaused: () => !enabled || !platform, onError: () => { /** Ignore */ } }
    );

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}

export function useGetPaymentHistory(platform: 'stream' | 'supreme' | 'create') {
    const { data, error } = useSWR(
        [
            `${platform}-get-payment-history`,
            {
                persist: false,
                platform,
            },
        ],
        () => User.getPaymentHistory(platform)
    );

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}
