import { useInfiniteFreeCuratedSets } from '@bpm-web-app/swr-hooks';
import { createAppRoutes, useCreateFilterParams } from '@bpm-web-app/utils';
import { useCallback, useMemo } from 'react';
import PrimaryPageTitle from '../../shared/primary-page-title/primary-page-title';
import styles from './free-curated-sound-packs-all.module.css';
import { LoadMore } from '../../shared/load-more/load-more';
import SeeMoreLayout from '../../see-more-layout/see-more-layout';
import Filters from '../../filters/filters';

export const FreeCuratedSoundPacksAll = () => {
    const query = useCreateFilterParams();

    const {
        data: freeCuratedSetsData,
        isLoadingMore,
        setSize,
        isLastPage,
    } = useInfiniteFreeCuratedSets({
        ...query,
    });

    const handleLoadMore = useCallback(() => {
        if (!isLoadingMore && !isLastPage) {
            setSize((prevSize) => prevSize + 1);
        }
    }, [isLastPage, isLoadingMore, setSize]);

    const flatFreeCuratedSets = useMemo(() => freeCuratedSetsData?.flatMap((data) => data.data), [freeCuratedSetsData]);

    return (
        <div className={styles['free-curated-sound-packs']}>
            <div className={styles['free-curated-sound-packs__header']}>
                <PrimaryPageTitle title="Free Curated Packs" />
                <Filters platform="create" showOnMobile />
            </div>
            <SeeMoreLayout contentType="curated-set" itemBasePath={createAppRoutes.curated} data={flatFreeCuratedSets} />
            <LoadMore onLoadMore={handleLoadMore} />
        </div>
    );
};
