import classNames from 'classnames';
import {
    HLSPlayer,
    getStartSilence,
    useApiErrorHandler,
    useViewport,
    fileDownload,
    isMobileNavigator,
    createAppRoutes,
    PlayerState,
    convertToPluralIfNeeded,
    showToast,
    isInViewport,
    useUserSettings,
    PlaySoundListContext,
    Analytics
} from '@bpm-web-app/utils';
import { DriveOwner, Preset, UserDriveCollaboration } from '@bpm-web-app/create-api-sdk';
import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useAddSoundToDrive, useCreateLike, useDownloadSound, useGetCredits, useStreamSoundSetDuration } from '@bpm-web-app/swr-hooks';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDrag } from 'react-dnd';
import DragSelect from 'dragselect';
import styles from '../create/track-list-create.module.css';
import { Checkbox } from '../../ui/checkbox/checkbox';
import PlayIcon from '../../../../assets/icons/player-play.svg';
import PauseIcon from '../../../../assets/icons/player-pause.svg';
import FavoriteHeartIcon from '../../../../assets/icons/favorite-heart.svg';
import DownloadIcon from '../../../../assets/icons/download.svg';
import { useCreatePlayer } from '../../../../../../utils/src/lib/create-player.context';
import { FiltersContext } from '../../../filters/filters.context';
import { CreateThreeDotsSheetContext } from '../../three-dots-sheet/create-three-dots-sheet.context';
import { BreakpointView } from '../../ui';
import { CreatePresetWave } from '../../../create-preset-wave/create-preset-wave';
import TrackListCreateArtwork from '../create/track-list-create-artwork';
import { TagsView } from '../../../tags-view/tags-view';
import { CollaborationUserImage } from '../../collaboration/collaboration-user-image';
import { DragDropItem, DragResult } from '../../../droppable/droppable';
import { ThreeDotsButton } from '../../three-dots-button/three-dots-button';
import { QualityControlContext } from '../../quality-control/quality-control.context';

interface TrackListCreateItemBaseProps {
    dragSelect?: DragSelect
    mutateSound?: (id: string, duration: number) => void;
    updateSoundLocal: (sound: Omit<Preset, 'midi' | 'file_url' | 'duration'>) => void;
    sound: Omit<Preset, 'midi' | 'file_url' | 'duration'>;
    soundIndex: number;
    playContext: PlaySoundListContext;
    hideTagColumn?: boolean;
    hidePackName?: boolean;
    isDriveDetailPage?: boolean;
    hasEditAccess?: boolean;
    collaborator?: UserDriveCollaboration;
    owner?: DriveOwner;
    isSharedDrive?: boolean;
    addedAt?: string;
    isLastItem?: boolean;
    setCurrentMediaInContext?: (id: string, showSignUpModal?: boolean) => void;
}

export interface TrackListCreateItemNonSelectableProps extends TrackListCreateItemBaseProps {
    onSelectItem?: never;
    selectedItemsIds?: never;
}

export interface TrackListCreateItemSelectableProps extends TrackListCreateItemBaseProps {
    onSelectItem: (soundId: string, index: number) => void;
    selectedItemsIds?: string[]
    shareUrl?: string;
}

export type TrackListCreateItemProps = TrackListCreateItemNonSelectableProps | TrackListCreateItemSelectableProps;

export const TrackListCreatePresetItem = memo(
    ({
        mutateSound,
        updateSoundLocal,
        sound, onSelectItem,
        selectedItemsIds,
        soundIndex,
        hideTagColumn = false,
        hidePackName = false,
        isDriveDetailPage = false,
        hasEditAccess,
        collaborator,
        owner,
        isSharedDrive,
        addedAt,
        isLastItem = false,
        dragSelect,
        setCurrentMediaInContext,
        playContext
    }: TrackListCreateItemProps) => {
        const isSelected = selectedItemsIds?.includes(sound.id) || false;
        const { closePlayer, setCurrentSoundId, currentSoundId, currentSelectedSoundId, currentTrack } = useCreatePlayer();

        const router = useRouter();
        const [elapsed, setElapsed] = useState(0);
        const [isAlreadyDownloaded, setIsAlreadyDownloaded] = useState(false);
        const { openThreeDotsModalSheet } = useContext(CreateThreeDotsSheetContext);
        const { mutate: updateCredits } = useGetCredits(true);

        const onlyDrives = useRef(false);
        const { isMobile } = useViewport();

        const { tags, setTags } = useContext(FiltersContext);

        const hasPreview = !!sound?.preset_preview;

        const threeDotsRef = useRef<HTMLDivElement>(null);
        const downloadButtonRef = useRef<HTMLButtonElement>(null);

        const downloadSound = useDownloadSound();
        const errorHandler = useApiErrorHandler();

        const streamSoundSetDuration = useStreamSoundSetDuration();
        const { isAnonymous } = useUserSettings();

        const trackListItemRef = useRef<HTMLDivElement | null>(null);
        const { isQualityControlActive } = useContext(QualityControlContext);

        useEffect(() => {
            // NOTE(paulomartinsbynd): it seems streamed sometimes is a string
            // sometimes a boolean but schema states its a number
            if (Number.isNaN(+sound.streamed) || !sound.streamed) {
                return;
            }

            setElapsed(+sound.streamed);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        useEffect(() => {
            if (trackListItemRef.current) { dragSelect?.addSelectables([trackListItemRef.current]); }
            return () => {
                if (trackListItemRef.current) { dragSelect?.removeSelectables([trackListItemRef.current]); }
            };
        }, [trackListItemRef, dragSelect]);

        useEffect(() => {
            if (currentSelectedSoundId && sound.id === currentSelectedSoundId && trackListItemRef.current && !isInViewport(trackListItemRef.current) && downloadButtonRef.current) {
                downloadButtonRef.current.scrollIntoView({ block: 'nearest' });
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [currentSelectedSoundId, sound.id]);

        const onDownloadCompleted = useCallback(() => {
            setIsAlreadyDownloaded(true);
        }, []);

        const handleMouseEnterDownload = useCallback(
            async (element) => {
                if (isAnonymous) {
                    if (setCurrentMediaInContext) setCurrentMediaInContext(`${sound.id}`, false);
                    return;
                }

                const { top, left } = (element.target as HTMLButtonElement).getBoundingClientRect();
                openThreeDotsModalSheet({
                    newCreateActionType: 'download-preset',
                    actionId: sound.id,
                    left,
                    top: top + window.scrollY - 8,
                    object: { type: 'Sound', obj: sound as Preset },
                    onActionCompleted: onDownloadCompleted
                });
            },
            [isAnonymous, sound, setCurrentMediaInContext, openThreeDotsModalSheet, onDownloadCompleted]
        );

        const handleDownload = useCallback(
            async (element) => {
                if (isAnonymous) {
                    if (setCurrentMediaInContext) setCurrentMediaInContext(`${sound.id}`, true);
                    return;
                }
                try {
                    const response = await showToast({
                        promise: downloadSound(sound.id, router.asPath),
                        noProgress: true,
                        successText: 'Download successful.',
                        preventErrorToast: true
                    });
                    fileDownload(response.data.url!);
                    setIsAlreadyDownloaded(true);
                    updateCredits();
                    Analytics.trackClick('one_click_download', sound.id, { location: 'create_track_list_preset' });
                } catch (error) {
                    errorHandler({ error, message: 'Failed to download sound' });
                }
            },
            [isAnonymous, setCurrentMediaInContext, sound.id, downloadSound, updateCredits, errorHandler, router]
        );

        const openThreeDots = useCallback(
            async (element) => {
                if (isAnonymous) {
                    if (setCurrentMediaInContext) setCurrentMediaInContext(`${sound.id}`, true);
                    return;
                }
                const { top, left } = (element.target as HTMLButtonElement).getBoundingClientRect();

                openThreeDotsModalSheet({
                    newCreateActionType: isDriveDetailPage || hasEditAccess ? 'drive-preset' : 'preset',
                    actionId: sound.id,
                    secondaryActionId: isDriveDetailPage || hasEditAccess ? (router.query as { id?: string })?.id : '',
                    left,
                    top: top + window.scrollY,
                    onlyDrives: onlyDrives.current,
                    shareUrl: isDriveDetailPage || hasEditAccess ? undefined : createAppRoutes.packsSlug(sound.sound_package_id),
                });
            },
            [hasEditAccess, isAnonymous, isDriveDetailPage, openThreeDotsModalSheet, router.query, setCurrentMediaInContext, sound.id, sound.sound_package_id]
        );

        const toggleTagOnFilters = useCallback(
            (tag: string) => {
                const nextTags = new Set(tags);
                if (tags.includes(tag)) {
                    nextTags.delete(tag);
                } else {
                    nextTags.add(tag);
                }

                setTags([...nextTags]);

                router.replace({
                    pathname: router.pathname,
                    query: { ...router.query, tags: [...nextTags] },
                });
            },
            [router, setTags, tags]
        );

        const setElapsedTime = useCallback(
            (time: number) => {
                if (currentSoundId === sound.id && time > 0) {
                    setElapsed(time);
                }
            },
            [currentSoundId, sound.id]
        );

        useEffect(() => {
            PlayerState.onProgress(setElapsedTime);

            return () => {
                PlayerState.offProgress(setElapsedTime);
            };
        }, [setElapsedTime]);

        const { isLiked, likeDislike } = useCreateLike('preset');

        const handleLikeDislike = useCallback(() => {
            if (isAnonymous) {
                if (setCurrentMediaInContext) setCurrentMediaInContext(`${sound.id}`, true);
                return;
            }
            likeDislike(sound.id);
        }, [isAnonymous, likeDislike, setCurrentMediaInContext, sound.id]);

        const isSoundLiked = useMemo(() => isLiked(sound.id), [isLiked, sound.id]);

        useEffect(
            () => () => {
                if (sound.id === currentSoundId) HLSPlayer.stop();
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            []
        );

        const playSound = useCallback(
            async (progress?: number) => {
                if (hasPreview) {
                    const finalProgress = progress || elapsed;
                    const skipSilence = getStartSilence(sound.preset_preview.silence_info);

                    if (finalProgress === 0 && skipSilence > 0) {
                        await HLSPlayer.goTo(skipSilence);
                    } else if (finalProgress >= +sound.preset_preview.duration) {
                        await HLSPlayer.goTo(0);
                    } else if (finalProgress > 0) {
                        await HLSPlayer.goTo(finalProgress);
                    }

                    await HLSPlayer.play();
                }
            },
            [hasPreview, elapsed, sound]
        );

        const handlePlay = useCallback(async () => {
            if (!hasPreview) {
                return;
            }
            playContext.play(soundIndex);
        }, [hasPreview, playContext, soundIndex]);

        const handleStop = useCallback(async () => {
            closePlayer();

            try {
                await streamSoundSetDuration(sound.id, elapsed);
            } catch (e) {
                console.error(e);
            }

            mutateSound?.(sound.id, elapsed);

            HLSPlayer.pause();
            setCurrentSoundId(undefined);
        }, [closePlayer, streamSoundSetDuration, sound.id, elapsed, mutateSound, setCurrentSoundId]);

        const handleMobileImageToggle = useCallback(() => {
            if (!isMobile) return;

            if (currentSoundId === sound.id) {
                handleStop();
            } else {
                handlePlay();
            }
        }, [currentSoundId, handlePlay, handleStop, isMobile, sound.id]);

        useEffect(() => {
            if (hasPreview && elapsed > 0 && elapsed >= +sound.preset_preview.duration && currentSoundId === sound.id) {
                handleStop();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [elapsed, sound.preset_preview]);

        const handleSeek = useCallback(
            async (progress: number) => {
                if (!hasPreview) {
                    return;
                }
                try {
                    if (!sound.preset_preview.file_url) {
                        showToast({ type: 'error', message: 'Credits required. Purchase credits now.', buttonText: 'Add Credits', replaceRouteOnButtonClick: '/account/plan' });
                        return;
                    }

                    let finalProgress = progress;
                    if (progress < 0) finalProgress = 0;
                    if (progress > +sound.preset_preview.duration) finalProgress = +sound.preset_preview.duration;

                    closePlayer();

                    if (currentSoundId !== sound.id) {
                        handlePlay();
                    } else {
                        playSound(finalProgress);
                    }
                } catch {
                    // Placeholder
                }
            },
            [sound, hasPreview, closePlayer, currentSoundId, handlePlay, playSound]
        );

        const handleUserKeyPress = useCallback(
            (event) => {
                if (currentSelectedSoundId !== sound.id || document.activeElement?.className.includes('input')) {
                    return;
                }

                // NOTE(paulomartinsbynd): this means mini player is opened and
                // we want keyboard events to be triggered there
                if (currentTrack && [' ', 'ArrowRight', 'ArrowLeft'].includes(event.key)) {
                    return;
                }

                switch (event.key) {
                    case ' ':
                        event.preventDefault();
                        if (currentSoundId === currentSelectedSoundId) {
                            handleStop();
                        } else {
                            handlePlay();
                        }
                        break;
                    case 'ArrowRight':
                        handleSeek(elapsed + 1);
                        break;
                    case 'ArrowLeft':
                        handleSeek(elapsed - 1);
                        break;
                    case 'm':
                        if (!threeDotsRef?.current) return;
                        threeDotsRef.current.click();
                        break;
                    case 'f':
                        handleLikeDislike();
                        break;
                    case 'd':
                        if (!threeDotsRef?.current) return;
                        onlyDrives.current = true;
                        threeDotsRef.current.click();
                        onlyDrives.current = false;
                        break;
                    case 'p':
                        if (!downloadButtonRef?.current) return;
                        downloadButtonRef.current.click();
                        break;
                    default:
                }
                // eslint-disable-next-line react-hooks/exhaustive-deps
            },
            [currentSelectedSoundId, sound, currentTrack, currentSoundId, handleSeek, elapsed, handleLikeDislike, handleStop, handlePlay]
        );

        useEffect(() => {
            window.addEventListener('keydown', handleUserKeyPress);

            return () => {
                window.removeEventListener('keydown', handleUserKeyPress);
            };
        }, [handleUserKeyPress]);

        const addSound = useAddSoundToDrive();

        // eslint-disable-next-line no-empty-pattern
        const [{ }, dragRef, connectDragPreview] = useDrag<DragDropItem | DragDropItem[], any, { opacity: number }>({
            item: selectedItemsIds?.length ? selectedItemsIds.map((id) => ({ id })) : { id: sound.id, title: sound.name },
            canDrag() {
                return !isMobile;
            },
            end: async (draggedItem, monitor) => {
                if (monitor.didDrop()) {
                    const { target, id } = monitor.getDropResult() as DragResult;
                    if (target === 'favorites') {
                        if (selectedItemsIds?.length) {
                            await Promise.all(selectedItemsIds.map((soundId, index) => likeDislike(soundId, true, index === (selectedItemsIds.length - 1))));
                        } else {
                            await likeDislike(sound.id, true);
                        }
                    } else if (target === 'my-drive') {
                        if (selectedItemsIds?.length) {
                            await Promise.all(selectedItemsIds.map((soundId) => addSound(id as string, soundId)));
                        } else {
                            await addSound(id as string, sound.id);
                        }
                        showToast({ type: 'success', title: `${convertToPluralIfNeeded(selectedItemsIds?.length || 1, 'Preset')} added to drive.`, buttonText: 'Go To Drive', replaceRouteOnButtonClick: `/my-drive/${id}` });
                    }
                }
            },
            type: 'Sound'
        });

        connectDragPreview(getEmptyImage(), {
            // IE fallback: specify that we'd rather screenshot the node
            // when it already knows it's being dragged so we can hide it with CSS.
            captureDraggingState: true,
        });

        return (
            <div
                ref={(ref) => {
                    trackListItemRef.current = ref;
                    dragRef(ref);
                }}
                data-sound-id={sound.id}
                className={classNames(
                    styles['track-list-create__list-item'],
                    styles['track-list-create__list-item__preset'],
                    {
                        [styles['track-list-create__list-item__preset--collboration']]: isSharedDrive,
                        [styles['track-list-create__list-item--active']]: currentSelectedSoundId === sound.id && !currentTrack
                    }
                )}
            >
                <div className={classNames(styles['track-list-create__column'], styles['track-list-create__column--count'])}>
                    {onSelectItem ? (
                        <Checkbox
                            checked={isSelected}
                            onChange={() => {
                                if (onSelectItem) onSelectItem(sound.id, soundIndex);
                            }}
                        />
                    ) : (
                        soundIndex + 1
                    )}
                </div>

                <BreakpointView
                    desktopChildren={
                        <div className={classNames(styles['track-list-create__column'], styles['track-list-create__column--image'])}>
                            <TrackListCreateArtwork artworkURL={sound?.artwork_url} hintText={sound?.sound_package_name} packId={sound?.sound_package_id} isLastItem={isLastItem} />
                        </div>
                    }
                    mobileChildren={
                        <div
                            role="button"
                            aria-label="play/pause sound"
                            className={classNames(styles['track-list-create__column'], styles['track-list-create__column--image'])}
                            tabIndex={0}
                            onClick={handleMobileImageToggle}
                            onKeyUp={(event) => {
                                if (event.key === ' ' || event.key === 'Enter') handleMobileImageToggle();
                            }}
                        >
                            {currentSoundId === sound.id && (
                                <div className={styles['track-list-create__image-overlay']}>
                                    <PauseIcon />
                                </div>
                            )}
                            <TrackListCreateArtwork artworkURL={sound?.artwork_url} hintText={sound?.sound_package_name} />
                        </div>
                    }
                />

                <div className={classNames(styles['track-list-create__column'], styles['track-list-create__column--play'])}>
                    {currentSoundId === sound.id ? (
                        <button className={styles['track-list-create__play-btn']} disabled={!hasPreview} type="button" aria-label={`Stop ${sound.name}`} onClick={handleStop}>
                            <PauseIcon />
                        </button>
                    ) : (
                        <button className={styles['track-list-create__play-btn']} disabled={!hasPreview} type="button" aria-label={`Play ${sound.name}`} onClick={handlePlay}>
                            <PlayIcon />
                        </button>
                    )}
                </div>
                <button
                    type="button"
                    disabled={!hasPreview}
                    className={classNames(styles['track-list-create__column'], styles['track-list-create__column--wave'], {
                        [styles['track-list-create__column--hidden-desktop']]: elapsed === 0 || (hasPreview && elapsed >= +sound.preset_preview.duration) || currentSoundId !== sound.id,
                    })}
                >
                    <div className={styles['track-list-create__column--wave--preset']}>
                        <CreatePresetWave hasPreview={hasPreview} handleSeek={handleSeek} elapsed={elapsed} duration={sound.preset_preview?.duration} isPaused={currentSoundId !== sound.id} />
                    </div>
                    <div className={classNames(styles['track-list-create__sound-third-line-mobile-content'])}>
                        {sound.bpm ? <span>{sound.bpm}</span> : null}
                        {sound?.key ? <span>{sound?.key}</span> : null}
                        {sound.Tags?.[0] ? <span>{sound.Tags?.[0].name}</span> : null}
                    </div>
                </button>

                {
                    isMobile ? (
                        <div
                            role="button"
                            aria-label="play sound"
                            className={classNames(styles['track-list-create__column'], styles['track-list-create__column--title'], {
                                [styles['track-list-create__column--hidden-desktop']]: currentSoundId === sound.id,
                            })}
                            tabIndex={0}
                            onClick={handlePlay}
                            onKeyUp={(event) => {
                                if (event.key === ' ' || event.key === 'Enter') handlePlay();
                            }}
                        >
                            <div className={styles['track-list-create__sound-title-container']}>
                                <span className={styles['track-list-create__sound-title']}>{sound.name}</span>
                                <span className={styles['track-list-create__preset']}>PRESET</span>
                            </div>
                            <div className={styles['track-list-create__sound-artist']}>{sound.artist}</div>
                            <div className={styles['track-list-create__sound-third-line-mobile-content']}>
                                {sound.bpm ? <span>{sound.bpm}</span> : null}
                                {sound?.key ? <span>{sound?.key}</span> : null}
                                {sound.Tags?.[0] ? <span>{sound.Tags?.[0].name}</span> : null}
                            </div>
                        </div>
                    ) : (
                        <div className={styles['track-list-create__row']}>
                            <div className={classNames(styles['track-list-create__column'], styles['track-list-create__column--title'])}>
                                <div className={styles['track-list-create__sound-title']}>{sound.name}</div>
                                {!hidePackName && (
                                    <Link href={createAppRoutes.packsSlug(sound.sound_package_id)}>
                                        <span className={styles['track-list-create__sound-pack']}>{sound.sound_package_name}</span>
                                    </Link>
                                )}
                            </div>
                            <span className={styles['track-list-create__preset']}>PRESET</span>
                        </div>
                    )
                }

                <div className={classNames(styles['track-list-create__column'], styles['track-list-create__column--synth'])}>{sound.synth}</div>
                <div className={classNames(styles['track-list-create__column'], styles['track-list-create__column--genre'])}>{sound.SoundPackage?.Genre?.name}</div>
                {
                    hideTagColumn ? (
                        <div className={classNames(styles['track-list-create__column'])} />
                    ) : (
                        <div className={classNames(styles['track-list-create__column'], styles['track-list-create__column--tags'])}>
                            <TagsView
                                updateTagsData={(newTags) => {
                                    // eslint-disable-next-line no-param-reassign
                                    sound.Tags = newTags;
                                    updateSoundLocal({ ...sound });
                                }}
                                tags={sound.Tags?.map(({ name }) => name) || []}
                                selected={tags}
                                onToggleTag={toggleTagOnFilters}
                                isQualityControlActive={isQualityControlActive}
                                tagsData={sound.Tags}
                                soundId={sound.id}
                            />
                        </div>
                    )
                }
                <div className={classNames(styles['track-list-create__column'], styles['track-list-create__column--actions'])}>
                    {isSharedDrive ? (
                        <div className={classNames(styles['track-list-create__column--collboration'])}>
                            <CollaborationUserImage imageUrl={collaborator !== undefined ? collaborator.profile_image_thumbnail_url : owner ? owner?.profile_image_thumbnail_url : undefined} size="small" driveCollaborator={collaborator} owner={owner} addedAt={addedAt} />
                        </div>
                    ) : null}
                    <button
                        className={classNames(styles['track-list-create__action-btn-desktop'], styles['track-list-create__action-btn-desktop--favorite'], {
                            [styles['track-list-create__action-btn-desktop--is-favorite']]: isSoundLiked,
                        })}
                        onClick={handleLikeDislike}
                        type="button"
                    >
                        <FavoriteHeartIcon />
                    </button>
                    {isMobileNavigator ? null : (
                        <button
                            ref={downloadButtonRef}
                            className={classNames(styles['track-list-create__action-btn-desktop'], styles['track-list-create__action-btn-desktop--download'], {
                                [styles['track-list-create__action-btn-desktop--is-downloaded']]: sound.downloaded || isAlreadyDownloaded,
                            })}
                            onClick={handleDownload}
                            type="button"
                            onMouseEnter={handleMouseEnterDownload}
                        >
                            <DownloadIcon />
                        </button>
                    )}
                    <div ref={threeDotsRef}>
                        <ThreeDotsButton avoidCheckingIfAnonymous onClick={openThreeDots} />
                    </div>
                </div>
            </div>
        );
    }
);
