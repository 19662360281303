import { useRouter } from 'next/router';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Analytics } from './analytics';
import { DOWNLOAD_PLATFORM_BASE_PATH, STREAM_PLATFORM_BASE_PATH } from './constants';
import { getPlatformFromRouterPath } from './getPlatformLink';

// eslint-disable-next-line @typescript-eslint/dot-notation
export const DOWNLOAD_ONLY = process.env['NEXT_PUBLIC_DOWNLOAD_ONLY'] === 'true';

export enum Hub {
    Download = 'download',
    Stream = 'stream',
}

type HubCount = { stream: number; download: number; showCount: boolean };

export interface HubSwitchContextProperties {
    hub: Hub;
    hubCount: HubCount;
    /**
     * sets the result count for download and stream
     * to show the results must pass `showCount:true`
     */
    setCount: (count: HubCount) => void;
    /** helper function to set showCount to false  */
    hideCount: () => void;
    setSwitchVisibility: (visible: boolean) => void;
    toggle: () => void;
    setHub: (hub: Hub) => void;
    isDownload: boolean;
    isSwitchVisible: boolean;
    isCreate?: boolean;
    isArtistPlatform?: boolean
}

export const HubSwitchContext = createContext<HubSwitchContextProperties>({} as HubSwitchContextProperties);

export interface HubSwitchProviderProps {
    children: ReactNode;
    /** This provider has no usage in create, but for the `AppLink` to work we need this */
    isCreate?: boolean;
    isArtistPlatform?: boolean
}

export function HubSwitchProvider({ children, isCreate, isArtistPlatform }: HubSwitchProviderProps) {
    const router = useRouter();
    const [hub, setHub] = useState<Hub>(DOWNLOAD_ONLY ? Hub.Download : (getPlatformFromRouterPath() || Hub.Download));

    const [switchVisibility, setSwitchVisibility] = useState(true);
    const [hubCount, setHubCount] = useState<HubCount>({ stream: 0, download: 0, showCount: false });

    const handleSwitchChange = useCallback(
        (nextValue: Hub) => {
            if (DOWNLOAD_ONLY) {
                setHub(Hub.Download);
            } else {
                setHub(nextValue);
            }

            if (getPlatformFromRouterPath() === Hub.Stream && (nextValue === Hub.Download || DOWNLOAD_ONLY)) {
                const downloadPath = router.asPath.replace(STREAM_PLATFORM_BASE_PATH, DOWNLOAD_PLATFORM_BASE_PATH);
                setHub(Hub.Download);
                router.push(downloadPath);
            } else if (!DOWNLOAD_ONLY && (getPlatformFromRouterPath() === Hub.Download && nextValue === Hub.Stream)) {
                const streamPath = router.asPath.replace(DOWNLOAD_PLATFORM_BASE_PATH, STREAM_PLATFORM_BASE_PATH);
                router.push(streamPath);
            }
        },
        [router]
    );

    const value = useMemo<HubSwitchContextProperties>(
        () => ({
            hub,
            toggle: () => {
                const nextHub = hub === Hub.Stream ? Hub.Download : Hub.Stream;
                handleSwitchChange(nextHub);
            },
            setHub: handleSwitchChange,
            setCount: setHubCount,
            hideCount: () => setHubCount((prevState) => ({ ...prevState, showCount: false })),
            setSwitchVisibility,
            isSwitchVisible: !!switchVisibility,
            hubCount,
            isDownload: hub === Hub.Download,
            isCreate,
            isArtistPlatform
        }),
        [hub, handleSwitchChange, switchVisibility, hubCount, isCreate, isArtistPlatform]
    );

    useEffect(() => {
        if (getPlatformFromRouterPath() !== hub) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            handleSwitchChange(getPlatformFromRouterPath()!);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router]);

    useEffect(() => {
        if (isArtistPlatform) {
            return;
        }
        const platform = isCreate ? 'create' : hub === Hub.Download ? 'supreme' : 'stream';
        if (Analytics.platform !== platform) {
            Analytics.trackSession('end');
            Analytics.platform = platform;
            Analytics.trackSession('start');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hub]);

    useEffect(() => {
        if (isArtistPlatform) {
            return () => { };
        }
        const handleRouteChange = () => Analytics.trackView();
        router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.events, hub]);

    return <HubSwitchContext.Provider value={value}>{children}</HubSwitchContext.Provider>;
}

export const useHubSwitch = () => {
    const context = useContext(HubSwitchContext);
    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (!Object.keys(context).length && process.env['NODE_ENV'] === 'development') throw new Error('useHubSwitch must be used inside the `HubSwitchProvider');
    return context;
};
