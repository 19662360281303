import { SearchPaginatedQuery } from '@bpm-web-app/api-client';
import { SortByQueryOptions } from '@bpm-web-app/download-api-sdk';
import { useRouter } from 'next/router';
import { useContext, useMemo, useState } from 'react';
import { LibraryTabsContext } from './library-tabs.context';
import { useDeepCompareEffect } from './useDeepCompareEffect';

export function useSupremeFilterParams(enableFilter: boolean, overrides?: Partial<SearchPaginatedQuery>) {
    const router = useRouter();
    const { libraryProperty } = useContext(LibraryTabsContext);
    const { sortBy, versions, bpm, key, keyType, genre, searchTerm, hideRemixes, hideOriginals, hideExclusives, hideExplicit, hidePrevDownloaded } = router.query;

    const [actualOverrides, setActualOverrids] = useState<Partial<SearchPaginatedQuery> | undefined>();

    useDeepCompareEffect(() => {
        setActualOverrids(overrides);
    }, [overrides]);

    const query = useMemo<SearchPaginatedQuery>(() => {
        const queryParams: SearchPaginatedQuery = {
            sort_by: sortBy?.toString() as unknown as SortByQueryOptions,
            library: libraryProperty
        };

        if (searchTerm?.toString().trim()) {
            queryParams.term = searchTerm.toString().trim();
        }

        if (!enableFilter) {
            return { ...queryParams, ...actualOverrides };
        }

        if (versions) queryParams.version = versions;
        if (bpm) {
            queryParams.bpm_min = Number(bpm[0]);
            queryParams.bpm_max = Number(bpm[1]);
        }

        if (key) queryParams.key = key;
        if (genre) queryParams.genre = genre;
        if (keyType) queryParams.keyType = keyType.toString();
        if (hideRemixes === '1') queryParams.hide_remix = true;
        if (hideOriginals === '1') queryParams.hide_originals = true;
        if (hideExclusives === '1') queryParams.hide_exclusives = true;
        if (hideExplicit === '1') queryParams.hide_explicit = true;
        if (hidePrevDownloaded === '1') queryParams.hide_prev_downloaded = true;

        return { ...queryParams, ...actualOverrides };
    }, [enableFilter, bpm, actualOverrides, genre, hideRemixes, hideOriginals, hideExclusives, hidePrevDownloaded, hideExplicit, key, keyType, libraryProperty, sortBy, versions, searchTerm]);
    return query;
}
