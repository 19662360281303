import { HistoryContext } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { useContext } from 'react';
import { AppLink } from '../../shared/app-link/app-link';
import styles from './page-not-found.module.css';

export const PageNotFound = () => {
    const { goBack, canGoBack } = useContext(HistoryContext);

    const handleGoBack = () => {
        if (canGoBack) {
            return (
                <button
                    className={classNames('button button--solid', styles['page-not-found__cta'])}
                    type="button"
                    onClick={goBack}
                >
                    Go Back
                </button>
            );
        }
        return (
            <AppLink href="/">
                <a aria-label="Homepage">
                    <button
                        className={classNames('button button--solid', styles['page-not-found__cta'])}
                        type="button"
                    >
                        Go To Home
                    </button>
                </a>
            </AppLink>
        );
    };
    return (
        <div className={classNames(styles['page-not-found__body'])}>
            <h2 className={classNames(styles['page-not-found__title'])}>Something Went Wrong</h2>
            <div className={classNames(styles['page-not-found__description'])}>There was a problem displaying this page. This page either does not exist or an error occured.</div>
            {handleGoBack()}
        </div>
    );
};
