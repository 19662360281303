export function getAbsolutePosition(elem: HTMLElement) {
    let left = 0;
    let top = 0;

    do {
        left += elem.offsetLeft - elem.scrollLeft;
        top += elem.offsetTop - elem.scrollTop;
        // eslint-disable-next-line no-cond-assign, no-param-reassign
    } while (elem = (elem.offsetParent as HTMLElement || elem.parentElement as HTMLElement));

    return { x: left, y: top };
}
