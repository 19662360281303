import { Artist } from '@bpm-web-app/stream-api-sdk';
import { Hub } from '@bpm-web-app/utils';
import { Album } from 'libs/api-client/src/lib/handlers/download/album';
import { BreakpointView, CardGrid, ListGrid } from '../shared';
import { CrateCard, CrateListItem } from '../shared/card/crate-card/crate-card';

export const CRATE_ALL_ITEM = {
    id: 'all',
    name: 'All',
    slug: 'all',
    created_at: new Date().toDateString(),
    order: 0,
    updated_at: new Date().toDateString(),
};

export interface CrateCategoryListProps {
    items: {
        id: string;
        name: string;
        slug: string;
        coverUrl: string;
        coverColor: string;
        artists: Artist[];
        created_at: string;
        albums: Album[]
    }[];
    hub: Hub;
}

export function CrateCategoryList({ items }: CrateCategoryListProps) {
    const renderCrateCard = (item: CrateListItem) => <CrateCard key={item.id} crate={item} crates={items as CrateListItem[]} />;
    const renderCards = items?.map((item) => renderCrateCard(item as CrateListItem));

    return (
        <BreakpointView
            mobileChildren={
                <ListGrid>
                    {renderCards}
                </ListGrid>
            }
            desktopChildren={
                <div className="spacing--top">
                    <CardGrid cardType="card-small">
                        {renderCards}
                    </CardGrid>
                </div>
            }
        />

    );
}

export default CrateCategoryList;
