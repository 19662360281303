import { useCallback, useMemo, useState } from 'react';
import Dayjs from 'dayjs';
import { Button } from '../../components/button/button';
import { TabsInput } from '../tabs-input/tabs-input';
import styles from './pause-modal.module.css';
import modalStyles from '../modal/modal.module.css';
import InfoIcon from '../../../../assets/icons/supreme/info.svg';
import { Modal, ModalLabel } from '../modal/modal';
import { LoadingEllipsis } from '../../../shared';

interface PauseModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: (duration: string) => void;
    isLoading: boolean;
    billingCycleEnd?: string;
}

export function PauseModal({ isOpen, onClose, onConfirm, isLoading, billingCycleEnd }: PauseModalProps) {
    const [duration, setDuration] = useState('1');

    const handleConfirm = useCallback(() => {
        onConfirm(duration);
    }, [onConfirm, duration]);

    const pauseDate = useMemo(() => {
        return Dayjs(billingCycleEnd).add(Number(duration), 'month').toDate().toLocaleDateString();
    }, [duration, billingCycleEnd]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <div className={styles['pause-modal__header']}>
                <h1 className={styles['pause-modal__heading']}>Pause Subscription</h1>
            </div>
            <div className={styles['pause-modal__content']}>
                <TabsInput
                    initialValue="1"
                    name="pause-duration"
                    label="Select Duration"
                    options={[{ value: '1', label: '1 Month' }, { value: '2', label: '2 Months' }]}
                    onChange={(e) => {
                        setDuration(String(e.target.value));
                    }} />

                <ModalLabel>
                    Your subscription will be paused on <span className={styles['pause-modal__date']}>{Dayjs(billingCycleEnd).toDate().toLocaleDateString()}</span>, the end of your current billing cycle.{' '}
                    It will resume on <span className={styles['pause-modal__date']}>{pauseDate}</span>. However, you can resume your subscription at any time.
                </ModalLabel>
                <div className={styles['pause-modal__info']}>
                    <div>
                        <InfoIcon />
                    </div>
                    <ModalLabel>
                        While your subscription is in pause, we will retain all your saved content, download history,
                        and preferences.
                    </ModalLabel>
                </div>
            </div>

            <Button disabled={isLoading} className={modalStyles['modal__button']} type="button" variant="primary" onClick={handleConfirm}>
                {isLoading ? <LoadingEllipsis /> : 'Confirm Pause'}
            </Button>
        </Modal>
    );
}
