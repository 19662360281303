import { Crate } from '@bpm-web-app/api-client';
import { Analytics, DOWNLOAD_ONLY, Hub } from '@bpm-web-app/utils';
import useSWR from 'swr';

export const onlineCrateSWRKey = (library?: string) => ['online-crate', { library, persist: true }];
export const offlineCrateSWRKey = (library?: string) => ['offline-crate', { library, persist: true }];

export function useOnlineCrate(library?: string, disabled?: boolean) {
    const { data, error, mutate } = useSWR(onlineCrateSWRKey(library), () => (disabled ? null : Crate.getOnlineCrate(library)));

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}

export function useOnlineCrateCategories(library: string) {
    const { data, error } = useSWR(['online-crate-categories', { persist: true, library }], () => Crate.getOnlineCrateCategories(library));

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

export function useOfflineCrate(library?: string, disabled?: boolean) {
    if (DOWNLOAD_ONLY) {
        return {
            data: { data: [] },
            isLoading: false,
            error: null,
        };
    }
    const { data, error, mutate } = useSWR(offlineCrateSWRKey(library), () => (disabled ? null : Crate.getOfflineCrate(library)));

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}

export function useOfflineCrateCategories(library: string) {
    if (DOWNLOAD_ONLY) {
        return {
            data: { data: [] },
            isLoading: false,
            error: null,
        };
    }
    const { data, error } = useSWR(['offline-crate-categories', { library, persist: true }], () => Crate.getOfflineCrateCategories(library));

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

type RemoveMultipleParams = Parameters<typeof Crate['removeMultipleMediaFromOfflineCrate']>[0] | Parameters<typeof Crate['removeMultipleMediaFromOnlineCrate']>[0];

export function useRemoveMultipleFromCrate(hub: Hub) {
    return (ids: RemoveMultipleParams) => {
        if (hub === Hub.Download) {
            return Crate.removeMultipleMediaFromOnlineCrate(ids).then((response) => {
                ids.forEach((id) => Analytics.trackClick('remove_from_crate_media', `${id}`));
                return response;
            });
        }

        return Crate.removeMultipleMediaFromOfflineCrate(ids).then((response) => {
            ids.forEach((id) => Analytics.trackClick('remove_from_crate_media', `${id}`));
            return response;
        });
    };
}

type RemoveParams = Parameters<typeof Crate['removeFromOnlineCrate']>[0] | Parameters<typeof Crate['removeFromOfflineCrate']>[0];

export function useRemoveFromCrate(hub: Hub) {
    return (params: RemoveParams) => {
        if (hub === Hub.Download) {
            return Crate.removeFromOnlineCrate(params).then((response) => {
                Analytics.trackClick('remove_from_crate_media', `${params}`);
                return response;
            });
        }

        return Crate.removeFromOfflineCrate(params).then((response) => {
            Analytics.trackClick('remove_from_crate_media', `${params}`);
            return response;
        });
    };
}
