import { useEffect, useState } from 'react';
import { useDragDropManager } from 'react-dnd';

export const useOnDragElement = () => {
    const manager = useDragDropManager();
    const [isDragging, setIsDragging] = useState(false);
    const monitor = manager.getMonitor();

    useEffect(() => {
        const listen = () => {
            setIsDragging(monitor.isDragging());
        };
        return monitor.subscribeToStateChange(listen);
    }, [monitor]);

    return { isDragging, type: monitor.getItemType() };
};
