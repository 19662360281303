import { SubscriptionDetailsv4, SubscriptionPackage, User } from '@bpm-web-app/supreme-api-sdk';
import { useCancelSubscription, useGetCredits, useGetMeSubscription, useGetSubscriptionPackage, useGetSubscriptionPackages } from '@bpm-web-app/swr-hooks';
import {
    convertSupremePackageToPlanItem,
    getLibraryName,
    getCurrentPlatformLink,
    getPlatformName,
    PlanItem,
    useApiErrorHandler,
    convertCreatePackagesToPlanItem,
    DOWNLOAD_ONLY,
    convertToPluralIfNeeded,
    sortSupremePlanItems,
    changeLibraryColors,
    formatDateToString,
    showExitSurvey,
    useUserSettings,
    getSignupRedirectLinkAccountPlans,
} from '@bpm-web-app/utils';
import Router, { useRouter } from 'next/router';
import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Library, LibraryTabsContext } from 'libs/utils/src/lib/library-tabs.context';
import classNames from 'classnames';
import TiktokPixel from 'tiktok-pixel';
import { ActionModal } from '../../shared/action-modal/action-modal';
import PromotionalModal from '../../promotional-modal/promotional-modal';
import styles from './account-plan.module.css';
import SubscriptionItem from './subscription-item/subscription-item';
import WelcomeImage from '../../../assets/images/welcome.svg';
import WelcomeImageCreate from '../../../assets/images/welcomeCreate.svg';
import PageSwitcher from '../../page-switcher/page-switcher';
import { SubscriptionValueProposition } from './subscription-item/subsciption-value-proposition';

export interface AccountPlanLandingProps {
    isCreate: boolean;
}

export function AccountPlanLanding({ isCreate }: AccountPlanLandingProps) {
    const [cancelState, setCancelState] = useState<SubscriptionPackage.PlatformEnum | null>(null);
    const router = useRouter();
    const { data: streamData } = useGetSubscriptionPackages('stream', isCreate);
    const { data: downloadData } = useGetSubscriptionPackages('supreme', isCreate);
    const { data: createData } = useGetSubscriptionPackages('create', !isCreate);

    const { data: userCredits } = useGetCredits(!isCreate);
    const { data: createSubscriptionData, isLoading: isCreateSubscriptionLoading } = useGetMeSubscription('create', !isCreate);
    const { data: streamSubscriptionData, isLoading: isStreamSubscriptionLoading } = useGetMeSubscription('stream', isCreate);
    const { data: downloadSubscriptionData, isLoading: isDownloadSubscriptionLoading } = useGetMeSubscription('supreme', isCreate);
    const cancelSubscription = useCancelSubscription();
    const [showCanceledModal, setShowCanceledModal] = useState(false);
    const [showLibraryChangedModal, setShowLibraryChangedModal] = useState(false);
    const [showWelcomeModal, setShowWelcomeModal] = useState(false);
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const [showAlreadyCancelled, setShowAlreadyCancelled] = useState<SubscriptionDetailsv4 | undefined>();
    const [showDowngradeModal, setShowDowngradeModal] = useState(false);
    const [showCreditsModal, setShowCreditsModal] = useState(false);
    const [showCreditsModalPurchase, setShowCreditsModalPurchase] = useState(false);
    const { isAnonymous } = useUserSettings();

    const [newPlanID, setNewPlanID] = useState<number | null>(null);
    const errorHandler = useApiErrorHandler(isCreate);
    const { cancel: cancelParam, switch: switchParam, new: newParam, upgrade: upgradeParam, downgrade: downgradeParam, id: planId, credit: isCreditPurchase, credits: creditsAmount } = router.query;

    const { library, setLibrary } = useContext(LibraryTabsContext);

    const [selectedTabSupreme, setSelectedTabSupreme] = useState<number>(library === Library.Latin ? 1 : 0);
    const [selectedTabCreate, setSelectedTabCreate] = useState<number>(0);

    const selectedPlanTypeSupreme = [SubscriptionPackage.LibraryEnum.Music, SubscriptionPackage.LibraryEnum.Latin, SubscriptionPackage.LibraryEnum.All][selectedTabSupreme];

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        if (router.query['allaccess'] !== undefined) {
            setSelectedTabSupreme(2);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isAnonymous) {
            router.replace(getSignupRedirectLinkAccountPlans());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAnonymous]);

    const tabDataSupreme = useMemo(() => [
        {
            text: 'Open Format Plans',
            enabled: true,
            color: 'var(--color-supreme-red)',
            onSelected: (index: number) => {
                setLibrary(Library.Main);
                setSelectedTabSupreme(index);
            }
        },
        {
            text: 'Latino Plans',
            enabled: true,
            color: 'var(--color-supreme-red)',
            onSelected: (index: number) => {
                setLibrary(Library.Latin);
                setSelectedTabSupreme(index);
            }
        },
        {
            text: 'All Access Plans',
            enabled: true,
            color: 'var(--color-supreme-red)',
            onSelected: (index: number) => {
                setLibrary(Library.Main);
                setSelectedTabSupreme(index);
            }
        },
    ], [setLibrary]);

    const tabDataCreate = useMemo(() => [
        {
            text: 'Subscriptions',
            enabled: true,
            color: 'var(--color-main-dynamic)',
            onSelected: (index: number) => {
                setSelectedTabCreate(index);
            }
        },
        {
            text: 'Credit Packs',
            enabled: true,
            color: 'var(--color-main-dynamic)',
            onSelected: (index: number) => {
                setSelectedTabCreate(index);
            }
        },
    ], []);

    useEffect(() => {
        changeLibraryColors({ library });
    }, [library]);

    useEffect(() => {
        if (cancelParam) {
            setShowCanceledModal(true);
        }
        if (switchParam) {
            setShowLibraryChangedModal(true);
        }
        if (newParam) {
            setShowWelcomeModal(true);
        }
        if (upgradeParam) {
            setShowUpgradeModal(true);
        }
        if (downgradeParam) {
            setShowDowngradeModal(true);
        }
        if (isCreditPurchase) {
            setShowCreditsModalPurchase(true);
            // eslint-disable-next-line @typescript-eslint/dot-notation
            if ((window as any).gtag) {
                try {
                    (window as any).gtag('event', 'conversion', { send_to: 'AW-863988361/fpaRCPmU5OYDEInV_ZsD', type: 'credit-pack', platform: 'create', currency: 'USD' });
                    // eslint-disable-next-line no-empty
                } catch (error) {

                }
            }
            try {
                TiktokPixel.track('CompletePayment', { platform: 'create' });
                // eslint-disable-next-line no-empty
            } catch (error) {
            }
        }
    }, [cancelParam, switchParam, downgradeParam, newParam, upgradeParam, planId, isCreditPurchase]);

    useEffect(() => {
        if (planId) setNewPlanID(Array.isArray(planId) ? +planId[0] : +planId);
    }, [planId]);

    const { data: newPlan } = useGetSubscriptionPackage(newPlanID, !!newPlanID);

    useEffect(() => {
        if ((upgradeParam || newParam) && newPlan) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            if ((window as any).gtag) {
                try {
                    (window as any).gtag('event', 'conversion', { send_to: 'AW-863988361/fpaRCPmU5OYDEInV_ZsD', type: 'subscription', platform: newPlan?.data?.platform, library: newPlan?.data?.library, value: (newPlan?.data.trial_amount || newPlan?.data.amount), currency: 'USD' });
                    // eslint-disable-next-line no-empty
                } catch (error) {

                }
            }
            try {
                TiktokPixel.track('CompletePayment', { type: 'subscription', platform: newPlan?.data?.platform, library: newPlan?.data?.library, value: (newPlan?.data.trial_amount || newPlan?.data.amount), currency: 'USD' });
                // eslint-disable-next-line no-empty
            } catch (error) {
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPlan]);

    const verificationStatus = useMemo(() => downloadSubscriptionData?.data?.user?.verification_status, [downloadSubscriptionData?.data?.user?.verification_status]);

    const isSubscriptionStatusVerified = useMemo(() => verificationStatus === User.VerificationStatusEnum.Verified, [verificationStatus]);

    const createPlans = useMemo<{ plans: PlanItem[]; credits: PlanItem[] }>(() => {
        const currentPlan = createData?.data?.find(({ id }) => id === createSubscriptionData?.data?.membership?.subscription?.package_id);

        const plans =
            createData?.data
                ?.filter((plan) => plan.is_default && plan.method === SubscriptionPackage.MethodEnum.Recurring)
                ?.map<PlanItem>((plan) => convertCreatePackagesToPlanItem(plan, Number(currentPlan?.amount) || 0, createSubscriptionData?.data?.membership, setShowCreditsModal)) || [];

        const credits =
            createData?.data
                ?.filter((plan) => plan.is_default && plan.method === SubscriptionPackage.MethodEnum.OneTime)
                ?.map<PlanItem>((plan) => convertCreatePackagesToPlanItem(plan, Number(currentPlan?.amount) || 0, createSubscriptionData?.data?.membership, setShowCreditsModal)) || [];

        return { plans, credits };
    }, [createData?.data, createSubscriptionData?.data?.membership]);

    const createPromotedPlans = useMemo<{ plans: PlanItem[]; credits: PlanItem[] }>(() => {
        const currentPlan = createData?.data?.find(({ id }) => id === createSubscriptionData?.data?.membership?.subscription?.package_id);

        const plans =
            createData?.data
                ?.filter((plan) => plan.is_promotion && plan.method === SubscriptionPackage.MethodEnum.Recurring)
                ?.map<PlanItem>((plan) => convertCreatePackagesToPlanItem(plan, Number(currentPlan?.amount) || 0, createSubscriptionData?.data?.membership, setShowCreditsModal)) || [];

        const credits =
            createData?.data
                ?.filter((plan) => plan.is_promotion && plan.method === SubscriptionPackage.MethodEnum.OneTime)
                ?.map<PlanItem>((plan) => convertCreatePackagesToPlanItem(plan, Number(currentPlan?.amount) || 0, createSubscriptionData?.data?.membership, setShowCreditsModal)) || [];

        return { plans, credits };
    }, [createData?.data, createSubscriptionData?.data?.membership]);

    const createPromotedPlansAvailable = useMemo(() => createPromotedPlans.plans.length > 0 || createPromotedPlans.credits.length > 0, [createPromotedPlans]);

    const streamPlans = useMemo<{ standard: PlanItem[]; premium: PlanItem[] }>(() => {
        const standard =
            streamData?.data
                ?.filter((plan) => plan.is_default && plan.name === SubscriptionPackage.NameEnum.Standard)
                ?.map<PlanItem>((plan) => convertSupremePackageToPlanItem(plan, streamSubscriptionData?.data?.membership))
                ?.sort(sortSupremePlanItems) || [];

        const premium =
            streamData?.data
                ?.filter((plan) => plan.is_default && plan.name === SubscriptionPackage.NameEnum.Premium)
                ?.map<PlanItem>((plan) => convertSupremePackageToPlanItem(plan, streamSubscriptionData?.data?.membership))
                ?.sort(sortSupremePlanItems) || [];

        return { standard, premium };
    }, [streamData?.data, streamSubscriptionData?.data?.membership]);

    const streamPromotedPlans = useMemo<{ standard: PlanItem[]; premium: PlanItem[] }>(() => {
        const standard =
            streamData?.data
                ?.filter((plan) => plan.is_promotion && plan.name === SubscriptionPackage.NameEnum.Standard)
                ?.map<PlanItem>((plan) => convertSupremePackageToPlanItem(plan, streamSubscriptionData?.data?.membership))
                ?.sort(sortSupremePlanItems) || [];

        const premium =
            streamData?.data
                ?.filter((plan) => plan.is_promotion && plan.name === SubscriptionPackage.NameEnum.Premium)
                ?.map<PlanItem>((plan) => convertSupremePackageToPlanItem(plan, streamSubscriptionData?.data?.membership))
                ?.sort(sortSupremePlanItems) || [];

        return { standard, premium };
    }, [streamData?.data, streamSubscriptionData?.data?.membership]);

    const streamPromotedPlansAvailable = useMemo(() => streamPromotedPlans.premium.length > 0 || streamPromotedPlans.standard.length > 0, [streamPromotedPlans]);

    const downloadPlans = useMemo<{ standard: PlanItem[]; premium: PlanItem[] }>(() => {
        const standard =
            downloadData?.data
                ?.filter((plan) => plan.is_default && plan.name === SubscriptionPackage.NameEnum.Standard)
                ?.map<PlanItem>((plan) => convertSupremePackageToPlanItem(plan, downloadSubscriptionData?.data?.membership, isSubscriptionStatusVerified))
                ?.sort(sortSupremePlanItems) || [];

        const premium =
            downloadData?.data
                ?.filter((plan) => plan.is_default && plan.name === SubscriptionPackage.NameEnum.Premium)
                ?.map<PlanItem>((plan) => convertSupremePackageToPlanItem(plan, downloadSubscriptionData?.data?.membership, isSubscriptionStatusVerified))
                ?.sort(sortSupremePlanItems) || [];

        return { standard, premium };
    }, [downloadData?.data, downloadSubscriptionData?.data?.membership, isSubscriptionStatusVerified]);

    const downloadPromotedPlans = useMemo<{ standard: PlanItem[]; premium: PlanItem[] }>(() => {
        const standard =
            downloadData?.data
                ?.filter((plan) => plan.is_promotion && plan.name === SubscriptionPackage.NameEnum.Standard)
                ?.map<PlanItem>((plan) => convertSupremePackageToPlanItem(plan, downloadSubscriptionData?.data?.membership, isSubscriptionStatusVerified))
                ?.sort(sortSupremePlanItems) || [];

        const premium =
            downloadData?.data
                ?.filter((plan) => plan.is_promotion && plan.name === SubscriptionPackage.NameEnum.Premium)
                ?.map<PlanItem>((plan) => convertSupremePackageToPlanItem(plan, downloadSubscriptionData?.data?.membership, isSubscriptionStatusVerified))
                ?.sort(sortSupremePlanItems) || [];

        return { standard, premium };
    }, [downloadData?.data, downloadSubscriptionData?.data?.membership, isSubscriptionStatusVerified]);

    const downloadPromotedPlansAvailable = useMemo(() => downloadPromotedPlans.premium.length > 0 || downloadPromotedPlans.standard.length > 0, [downloadPromotedPlans]);

    const renderPlanItem = useCallback(
        (plan: PlanItem) => plan ? (
            <Fragment key={plan.id}>
                <SubscriptionItem {...plan} />
            </Fragment>
        ) : null,
        []
    );

    const isSubscribedToAPlan = useMemo(() => {
        if (isCreate && createSubscriptionData?.data?.membership?.has_membership) {
            return true;
        }
        if (DOWNLOAD_ONLY) {
            return !!downloadSubscriptionData?.data?.membership?.has_membership;
        }
        if (!isCreate && (streamSubscriptionData?.data?.membership?.has_membership || downloadSubscriptionData?.data?.membership?.has_membership)) {
            return true;
        }
        return false;
    }, [downloadSubscriptionData?.data?.membership?.has_membership, streamSubscriptionData?.data?.membership?.has_membership, createSubscriptionData?.data?.membership?.has_membership, isCreate]);

    const isPlanCancelled = useMemo(() => {
        if (isCreate && createSubscriptionData?.data?.membership?.subscription?.status === SubscriptionDetailsv4.StatusEnum.Cancelled) {
            return true;
        }
        if (DOWNLOAD_ONLY) {
            return downloadSubscriptionData?.data.membership?.subscription?.status === SubscriptionDetailsv4.StatusEnum.Cancelled;
        }
        if (!isCreate && (streamSubscriptionData?.data?.membership?.subscription?.status === SubscriptionDetailsv4.StatusEnum.Cancelled
            || downloadSubscriptionData?.data?.membership?.subscription?.status === SubscriptionDetailsv4.StatusEnum.Cancelled)) {
            return true;
        }
        return false;
    }, [isCreate, createSubscriptionData?.data?.membership?.subscription, streamSubscriptionData?.data?.membership?.subscription, downloadSubscriptionData?.data.membership?.subscription]);

    // const isSubscriptionPlansLoading = useMemo(() => {
    //     if (isCreate) {
    //         return isCreateSubscriptionLoading;
    //     }
    //     if (DOWNLOAD_ONLY) {
    //         return isDownloadSubscriptionLoading;
    //     }
    //     if (!isCreate) {
    //         return isDownloadSubscriptionLoading || isStreamSubscriptionLoading;
    //     }
    //     return false;
    // }, [isCreateSubscriptionLoading, isDownloadSubscriptionLoading, isStreamSubscriptionLoading, isCreate]);

    const handleCancelPlan = useCallback(
        async (subscription: SubscriptionDetailsv4) => {
            if (subscription.status === SubscriptionDetailsv4.StatusEnum.Cancelled) {
                setShowAlreadyCancelled(subscription);
                return;
            }
            /**
             * Check if it's a premium plan, if so redirect to cancel screen.
             * If its a standard plan with library all, redirect to cancel screen
             */

            if (subscription.membership_type === SubscriptionDetailsv4.MembershipTypeEnum.Premium || subscription.library === SubscriptionPackage.LibraryEnum.All || isCreate) {
                Router.push(`${Router.pathname}/cancel/${subscription.package_id}?platform=${subscription.platform}`);
                return;
            }
            // If it's a individual plan cancel subscription;
            setCancelState(subscription.platform);
        },
        [isCreate]
    );

    const handleCancelSubscription = useCallback(async () => {
        if (!cancelState) return;

        try {
            await cancelSubscription(cancelState);
            setCancelState(null);
            showExitSurvey(cancelState === SubscriptionPackage.PlatformEnum.Supreme ? 'supreme' : 'create');
        } catch (error) {
            errorHandler({ error, message: 'Failed to cancel subscription. Please try again.' });
        }
    }, [cancelState, cancelSubscription, errorHandler]);

    const getDescription = useCallback(() => {
        if (isCreate) {
            return (
                <ul>
                    {newPlan?.data?.description?.split('\n').map((line) => (
                        <SubscriptionValueProposition label={line} isAvailable />
                    ))}
                </ul>
            );
        }

        return newPlan?.data?.description;
    }, [newPlan?.data?.description, isCreate]);

    const handleConfirm = useCallback(() => router.replace(getCurrentPlatformLink('/')), [router]);

    /** Removes query params after the corresponding modal is closed */
    const handlePostClosePlanChangesModals = useCallback(() => router.replace(router.pathname), [router]);

    const onCancelSubscription = useCallback(
        (data: typeof streamSubscriptionData['data'] | typeof downloadSubscriptionData['data'] | typeof createSubscriptionData['data']) => {
            if (data?.membership?.has_membership && data.membership.subscription) {
                const { subscription } = data.membership;
                handleCancelPlan(subscription);
            }
        }, [handleCancelPlan]);

    const freePlan: SubscriptionPackage = useMemo(() => {
        return {
            id: 0,
            created_at: '',
            updated_at: '',
            name: SubscriptionPackage.NameEnum.Standard,
            amount: '',
            period: SubscriptionPackage.PeriodEnum.NUMBER_0,
            trial_period: SubscriptionPackage.TrialPeriodEnum.NUMBER_0,
            method: SubscriptionPackage.MethodEnum.Recurring,
            platform: SubscriptionPackage.PlatformEnum.Supreme,
            is_current: false,
            is_default: false,
            is_promotion: false
        };
    }, []);

    const freePlanItem = useCallback(
        (data?: typeof streamSubscriptionData['data'] | typeof downloadSubscriptionData['data'] | typeof createSubscriptionData['data']) => {
            return {
                id: 10,
                title: 'Free',
                amount: '$0/mo',
                libraryText: 'Preview Access',
                plan: freePlan,
                description: 'Try out platform features and preview our full library of DJ-ready tracks.',
                ctaText: isSubscribedToAPlan ? 'Downgrade Plan' : 'Current Plan',
                onCtaClick: isSubscribedToAPlan && data ? () => onCancelSubscription(data) : () => null,
                color: 'primary',
                platform: 'supreme',
                isCurrent: isSubscribedToAPlan,
                allowed: !isPlanCancelled,
                isFree: true,
                index: 0,
            } as unknown as PlanItem;
        }, [freePlan, isSubscribedToAPlan, isPlanCancelled, onCancelSubscription]);

    const renderRegularDownloadSupremePlans = useMemo(() => {
        return (
            <div className={styles['account-plan__plan-container--layout']}>
                {renderPlanItem(
                    freePlanItem(downloadSubscriptionData?.data)
                )}
                {downloadPlans.standard.filter((p) => p.plan.library === selectedPlanTypeSupreme).map((plan) => renderPlanItem({ ...plan, index: 1 }))}
                {downloadPlans.premium.filter((p) => p.plan.library === selectedPlanTypeSupreme).map((plan) => renderPlanItem({ ...plan, index: 2 }))}
            </div>
        );
    }, [renderPlanItem, freePlanItem, downloadSubscriptionData, downloadPlans, selectedPlanTypeSupreme]);

    function sortStandardPremiumArray<T>(standardArray: T[], premiumArray: T[]): T[] {
        const result: T[] = [];

        let standardIndex = 0;
        let premiumIndex = 0;

        while (standardIndex < standardArray.length && premiumIndex < premiumArray.length) {
            result.push(standardArray[standardIndex]);
            result.push(premiumArray[premiumIndex]);
            standardIndex += 1;
            premiumIndex += 1;
        }

        // Add any remaining elements from the longer array
        result.push(...standardArray.slice(standardIndex));
        result.push(...premiumArray.slice(premiumIndex));

        return result;
    }

    const renderPromotionalPlans = useMemo(() => {
        const standardPromoPlans = downloadPromotedPlans.standard.filter((p) => p.plan.library === selectedPlanTypeSupreme).map((plan) => renderPlanItem({ ...plan, index: 1 })).reverse();
        const premiumPromoPlans = downloadPromotedPlans.premium.filter((p) => p.plan.library === selectedPlanTypeSupreme).map((plan) => renderPlanItem({ ...plan, index: 2 })).reverse();
        const sortedArray = sortStandardPremiumArray(standardPromoPlans, premiumPromoPlans);

        return (
            <>
                <div className={styles['account-plan__promotion-text']}>Active Promotional Plans</div>
                <div className={styles['account-plan__plan-container--layout']}>
                    {sortedArray || null}
                </div>
            </>
        );
    }, [renderPlanItem, downloadPromotedPlans.standard, downloadPromotedPlans.premium, selectedPlanTypeSupreme]);

    const renderStreamingPlans = useMemo(() => {
        return (
            <>
                {streamPromotedPlansAvailable ? (
                    <>
                        <div className={styles['account-plan__promotion-text']}>Promotional Stream Plans</div>
                        <div className={styles['account-plan__plan-container--layout']}>
                            {streamPromotedPlans.standard.filter((p) => p.plan.library === selectedPlanTypeSupreme).map((plan) => renderPlanItem({ ...plan, index: 1 }))}
                            {streamPromotedPlans.premium.filter((p) => p.plan.library === selectedPlanTypeSupreme).map((plan) => renderPlanItem({ ...plan, index: 2 }))}
                        </div>
                    </>
                ) : null}
                <div className={styles['account-plan__promotion-text']}>Stream Plans</div>
                <div className={styles['account-plan__plan-container--layout']}>
                    {streamPlans.standard.filter((p) => p.plan.library === selectedPlanTypeSupreme).map((plan) => renderPlanItem({ ...plan, index: 1 }))}
                    {streamPlans.premium.filter((p) => p.plan.library === selectedPlanTypeSupreme).map((plan) => renderPlanItem({ ...plan, index: 2 }))}
                </div>
            </>
        );
    }, [streamPromotedPlansAvailable, renderPlanItem, streamPromotedPlans.standard, streamPromotedPlans.premium, selectedPlanTypeSupreme, streamPlans.standard, streamPlans.premium]);

    const renderCancelButtonSupreme = useMemo(() => {
        if (isPlanCancelled) {
            return (
                <div className={classNames(styles['account-plan__support'], 'spacing--top')}>
                    <div className={styles['account-plan__cancelled_info']}>Subscription ends on {formatDateToString(downloadSubscriptionData?.data.membership.subscription?.expire_date, 'MM/DD/YYYY')}</div>
                    <button
                        type="button"
                        onClick={() => {
                            window.open('https://support.bpmsupreme.com/hc/en-us', '__blank');
                        }}
                        className={styles['account-plan__support-text']}
                    >
                        Contact Support
                    </button>
                </div>
            );
        }
        return (
            <div className="spacing--top">
                <button
                    type="button"
                    onClick={() => {
                        onCancelSubscription(downloadSubscriptionData?.data);
                    }}
                    className={styles['account-plan__cancel-text']}
                >
                    Cancel Subscription
                </button>
            </div>
        );
    }, [downloadSubscriptionData?.data, isPlanCancelled, onCancelSubscription]);

    const renderSupremePlan = useCallback((subtitle: string) => {
        return (
            <div className={styles['account-plan__plan-container']}>
                <p className="heading--h2">{tabDataSupreme[selectedTabSupreme].text}</p>
                <div className={styles['account-plan__subtitle']}>{subtitle}</div>
                {isSubscribedToAPlan ? (renderCancelButtonSupreme) : null}
                {downloadPromotedPlansAvailable ? renderPromotionalPlans : null}
                {!DOWNLOAD_ONLY ? renderStreamingPlans : null}
                {streamPromotedPlansAvailable || !DOWNLOAD_ONLY || downloadPromotedPlansAvailable ? <div className={styles['account-plan__promotion-text']}>Other Plans</div> : null}
                {renderRegularDownloadSupremePlans}
            </div>
        );
    },
        [
            tabDataSupreme,
            selectedTabSupreme,
            isSubscribedToAPlan,
            renderCancelButtonSupreme,
            downloadPromotedPlansAvailable,
            renderPromotionalPlans,
            renderStreamingPlans,
            streamPromotedPlansAvailable,
            renderRegularDownloadSupremePlans
        ]);

    const renderOpenFormatPlans = useMemo(() => {
        return renderSupremePlan('An Open Format subscription provides download access to top genres like hip hop, R&B, pop, dance, and more, plus DJ-ready exclusives, edits, and remixes.');
    }, [renderSupremePlan]);

    const renderLatinPlans = useMemo(() => {
        return renderSupremePlan('A Latino subscription provides download access to top Latin genres like reggaeton, moombahton, cumbia, and regional, plus DJ-ready exclusives, edits, and remixes.');
    }, [renderSupremePlan]);

    const renderAllPlans = useMemo(() => {
        return renderSupremePlan('An All Access subscription provides download access to all libraries and every genre on BPM Supreme, including Open Format and Latino, plus DJ-ready exclusives, edits, and remixes.');
    }, [renderSupremePlan]);

    const currentPageSupreme = useMemo(() => {
        switch (selectedTabSupreme) {
            case 0:
                return renderOpenFormatPlans;
            case 1:
                return renderLatinPlans;
            default:
                return renderAllPlans;
        }
    }, [renderAllPlans, renderLatinPlans, renderOpenFormatPlans, selectedTabSupreme]);

    const renderCancelButtonCreate = useMemo(() => {
        if (isPlanCancelled) {
            return (
                <div className={classNames(styles['account-plan__support'], 'spacing--top')}>
                    <div className={styles['account-plan__cancelled_info']}>Subscription ends on {formatDateToString(createSubscriptionData?.data.membership.subscription?.expire_date, 'MM/DD/YYYY')}</div>
                    <button
                        type="button"
                        onClick={() => {
                            window.open('https://support.bpmsupreme.com/hc/en-us', '__blank');
                        }}
                        className={styles['account-plan__support-text']}
                    >
                        Contact Support
                    </button>
                </div>
            );
        }
        return (
            <div className="spacing--top">
                <button
                    type="button"
                    onClick={() => {
                        onCancelSubscription(createSubscriptionData?.data);
                    }}
                    className={styles['account-plan__cancel-text']}
                >
                    Cancel Subscription
                </button>
            </div>
        );
    }, [createSubscriptionData?.data, isPlanCancelled, onCancelSubscription]);

    const renderPromotionalPlansCreate = useMemo(() => {
        return (
            <>
                <p className="heading--h2 spacing--top-double">Create Promotional Plans</p>
                <div className={styles['account-plan__plan-container--layout']}>
                    {createPromotedPlans?.plans?.map((item, index) => renderPlanItem({ ...item, index }))}
                </div>
                <p className="heading--h2 spacing--top-double">Other Plans</p>
            </>
        );
    }, [createPromotedPlans?.plans, renderPlanItem]);

    const renderSubscriptionsCreate = useMemo(() => {
        return (
            <div className={styles['account-plan__plan-container']}>
                <p className="heading--h2">How Subscriptions Work</p>
                <div className={styles['account-plan__subtitle']}>You will receive credits each month based on your plan. Any extra credits from the previous month will roll over but will be removed should you cancel your plan. Each sample (.wav file) requires 1 credit. MIDI files and synth presets require 3 credits.</div>
                {isSubscribedToAPlan ? (renderCancelButtonCreate) : null}
                {createPromotedPlansAvailable ? renderPromotionalPlansCreate : null}
                <div className={styles['account-plan__plan-container--layout']}>
                    {createPlans?.plans?.map((item, index) => renderPlanItem({ ...item, index }))}
                </div>

            </div>
        );
    }, [createPlans?.plans, createPromotedPlansAvailable, isSubscribedToAPlan, renderCancelButtonCreate, renderPlanItem, renderPromotionalPlansCreate]);

    const renderCreditPacksCreate = useMemo(() => {
        return (
            <div className={styles['account-plan__plan-container']}>
                <p className="heading--h2">How Credit Packs Work</p>
                <div className={styles['account-plan__subtitle']}>When needed, you can purchase credit packs to get more credits than your monthly subscription provides.</div>
                {isSubscribedToAPlan ? (renderCancelButtonCreate) : null}

                <div className={styles['account-plan__plan-container--layout']}>
                    {createPlans?.credits?.map((item, index) => {
                        return renderPlanItem({ ...item, index, variant: 'credits' });
                    })}
                </div>
            </div>
        );
    }, [createPlans?.credits, isSubscribedToAPlan, renderCancelButtonCreate, renderPlanItem]);

    const currentPageCreate = useMemo(() => {
        switch (selectedTabCreate) {
            case 0:
                return renderSubscriptionsCreate;
            case 1:
                return renderCreditPacksCreate;
            default:
                return renderSubscriptionsCreate;
        }
    }, [renderCreditPacksCreate, renderSubscriptionsCreate, selectedTabCreate]);

    const renderPageContent = useMemo(() => {
        if (isCreate) {
            return (
                <>
                    <PageSwitcher pages={tabDataCreate} selected={selectedTabCreate} />
                    {currentPageCreate}
                </>
            );
        }
        return (
            <>
                <PageSwitcher pages={tabDataSupreme} selected={selectedTabSupreme} />
                {currentPageSupreme}
            </>
        );
    }, [currentPageCreate, currentPageSupreme, isCreate, selectedTabCreate, selectedTabSupreme, tabDataCreate, tabDataSupreme]);

    return (
        <div className={styles['account-plan']}>
            {renderPageContent}
            <ActionModal
                headerTitle="Account changes"
                title="Cancellation Confirmed"
                subtitle="We're sorry to see you go!"
                confirmButtonText="Back to Home"
                onConfirm={handleConfirm}
                onClose={() => {
                    handlePostClosePlanChangesModals();
                    setShowCanceledModal(false);
                }}
                hideCancel
                variant="light"
                isOpen={showCanceledModal}
            />
            <ActionModal
                title="Are you sure you want to cancel?"
                headerTitle="Cancel Subscription"
                subtitle="Please note that you will continue to have access to download music and use paid features until your next billing date. After that, your account will switch to our Free plan."
                confirmButtonText="Confirm Cancellation"
                cancelButtonText="Go Back"
                onConfirm={handleCancelSubscription}
                onClose={() => setCancelState(null)}
                isOpen={!!cancelState}
            />
            <ActionModal
                headerTitle="Plan Updated"
                title={`${getLibraryName(newPlan?.data?.library)} Library Added`}
                subtitle={newPlan?.data?.description}
                confirmButtonText={isCreate ? 'Start Browsing' : 'Browse Music'}
                onConfirm={handleConfirm}
                onClose={() => {
                    handlePostClosePlanChangesModals();
                    setShowLibraryChangedModal(false);
                }}
                hideCancel
                variant="dark"
                isOpen={showLibraryChangedModal}
            />
            <ActionModal
                headerTitle="Your plan was upgraded"
                title={`Upgrade to ${getPlatformName(newPlan?.data?.platform)} ${newPlan?.data.name}${isCreate ? '' : (` ${getLibraryName(newPlan?.data?.library)}`)} complete`}
                subtitle={getDescription()}
                confirmButtonText={isCreate ? 'Start Browsing' : 'Browse Music'}
                onConfirm={handleConfirm}
                onClose={() => {
                    handlePostClosePlanChangesModals();
                    setShowUpgradeModal(false);
                }}
                hideCancel
                variant="dark"
                isOpen={showUpgradeModal}
            />
            <ActionModal
                headerTitle="Subscription Cancelled"
                title="No More Modifications Allowed"
                subtitle={`Your subscription has already been cancelled, and no further modifications can be made to your account until ${formatDateToString(showAlreadyCancelled?.expire_date)}.`}
                confirmButtonText="Dismiss"
                onConfirm={() => {
                    setShowAlreadyCancelled(undefined);
                }}
                onClose={() => {
                    setShowAlreadyCancelled(undefined);
                }}
                hideCancel
                variant="dark"
                isOpen={showAlreadyCancelled !== undefined}
            />
            <ActionModal
                headerTitle="Your plan was downgraded"
                title={`Downgrade to ${getPlatformName(newPlan?.data?.platform)} ${newPlan?.data.name}${isCreate ? '' : (` ${getLibraryName(newPlan?.data?.library)}`)} complete`}
                subtitle={getDescription()}
                confirmButtonText={isCreate ? 'Start Browsing' : 'Browse Music'}
                onConfirm={handleConfirm}
                onClose={() => {
                    handlePostClosePlanChangesModals();
                    setShowDowngradeModal(false);
                }}
                hideCancel
                variant="dark"
                isOpen={showDowngradeModal}
            />
            <ActionModal
                headerTitle="Subscribe Now"
                title="You need a plan to add credits."
                subtitle="Please choose a monthly subscription plan and complete your payment. You can add more credits at any time with an active subscription plan."
                confirmButtonText="Back to Account"
                onConfirm={() => setShowCreditsModal(false)}
                onClose={() => setShowCreditsModal(false)}
                variant="dark"
                hideCancel
                isOpen={showCreditsModal}
            />
            <ActionModal
                headerTitle="Credits Added"
                title={`${creditsAmount} credits have been added to your account`}
                subtitle={`You now have ${convertToPluralIfNeeded((userCredits?.regular ?? 0), 'credit', 'credits')} available. You can add more credits at any time with a one-time purchase.`}
                confirmButtonText="Start Browsing"
                onConfirm={handleConfirm}
                onClose={() => {
                    handlePostClosePlanChangesModals();
                    setShowCreditsModalPurchase(false);
                }}
                variant="dark"
                hideCancel
                isOpen={showCreditsModalPurchase}
            />
            <PromotionalModal
                isOpen={showWelcomeModal}
                onClose={() => {
                    handlePostClosePlanChangesModals();
                    setShowWelcomeModal(false);
                }}
                onConfirm={handleConfirm}
                headerTitle="You’re Subscribed"
                confirmButtonText={isCreate ? 'Start Browsing' : 'Browse Music'}
                title="Welcome"
                subtitle={isCreate ? 'Thank you for subscribing to BPM Create. Start browsing samples and sound packs for music production.' : 'Thanks for subscribing to BPM Supreme. Start browsing DJ-ready music and get ready for your next gig! '}
                // eslint-disable-next-line react/no-unstable-nested-components
                image={isCreate ? () => <WelcomeImageCreate /> : () => <WelcomeImage />}
            />
        </div>
    );
}

export default AccountPlanLanding;
