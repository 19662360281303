import { Media } from '@bpm-web-app/download-api-sdk';
import { ReactNode, createContext, useState, useMemo, useCallback } from 'react';

export interface ThreeDotsSheetVersionsContextProperties {
    leftPosition: number,
    topPosition: number,
    media: Media[],
    show: boolean,
    onHide: () => void,
    move: (x: number, y: number) => void,
    id: number,
    openThreeDotsModalSheet: (args: Omit<ThreeDotsSheetVersionsContextProperties, 'openThreeDotsModalSheet' | 'onHide' | 'move'>) => void;
}

export const ThreeDotsSheetVersionsContext = createContext<ThreeDotsSheetVersionsContextProperties>({
    leftPosition: 0,
    topPosition: 0,
    media: [],
    show: false,
    id: -1,
    onHide: () => { },
    move: (x: number, y: number) => { },
    openThreeDotsModalSheet: () => null
});

export interface ThreeDotsSheetVersionsProviderProps {
    children: ReactNode;
}

export function ThreeDotsSheetVersionsProvider({ children }: ThreeDotsSheetVersionsProviderProps) {
    const [leftPosition, setLeftPosition] = useState(0);
    const [topPosition, setTopPosition] = useState(0);
    const [media, setMedia] = useState<Media[]>([]);
    const [show, setShow] = useState(false);
    const [id, setId] = useState(-1);

    const move = useCallback((x: number, y: number) => {
        setLeftPosition(x);
        setTopPosition(y);
    }, [setLeftPosition, setTopPosition]);

    const value = useMemo(
        () => ({
            media,
            id,
            leftPosition,
            topPosition,
            onHide: () => {
                setShow(false);
            },
            show,
            openThreeDotsModalSheet: ({
                media: newMedia,
                id: newId,
                leftPosition: left,
                topPosition: top,
                show: newShow
            }: Omit<ThreeDotsSheetVersionsContextProperties, 'openThreeDotsModalSheet' | 'onHide'>) => {
                setShow(newShow);
                setId(newId);
                setMedia(newMedia);
                setLeftPosition(left);
                setTopPosition(top);
            },
            move,
            setLeftPosition,
            setTopPosition,
            setId,
            setShow,
            setMedia
        }),
        [id, leftPosition, media, show, topPosition, move]
    );
    return <ThreeDotsSheetVersionsContext.Provider value={value}>{children}</ThreeDotsSheetVersionsContext.Provider>;
}
