import useSWR from 'swr';
import { Media, SearchQuery } from '@bpm-web-app/api-client';
import { useHubSwitch } from '@bpm-web-app/utils';
import { useStickyResult } from './sticky-result';

export function useGetVersions(library: string) {
    const { isDownload } = useHubSwitch();

    const { data, error } = useSWR(
        [
            'versions',
            {
                persist: true,
                isDownload,
                library,
            },
        ],
        () => Media.getVersions(isDownload, library)
    );

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

export function useSearchVersions(args: Omit<SearchQuery, 'sort_by'>, disabled?: boolean) {
    const { isDownload } = useHubSwitch();

    const { data, error } = useSWR(
        [
            'search-versions',
            {
                persist: true,
                isDownload,
                args,
            },
        ],
        () => (disabled ? null : Media.searchVersions(isDownload, args))
    );

    const actualData = useStickyResult(data?.data);

    return {
        versions: actualData,
        isLoading: !error && !data,
        error,
    };
}
