import classNames from 'classnames';
import { ReactNode, useMemo, useContext, Fragment } from 'react';
import { getInitialsFromName } from '@bpm-web-app/utils';
import { MediaWithAlbum } from '@bpm-web-app/stream-api-sdk';
import { Album as DownloadAlbum } from '@bpm-web-app/download-api-sdk';
import styles from './list-grid-item.module.css';
import { ActionType, ThreeDotsSheetContext } from '../three-dots-sheet/three-dots-sheet.context';
import ThreeDotsIcon from '../../../assets/icons/more-dots-vert.svg';
import { AppLink } from '../app-link/app-link';
import { ThreeDotsButton } from '../three-dots-button/three-dots-button';

export interface ListGridItemProps {
    id: number | string;
    link?: string;
    imageUrl: string;
    imageUrl2x: string;
    rounded?: boolean;
    title: string;
    subtitle: ReactNode;
    song?: MediaWithAlbum | DownloadAlbum;
    songsList?: MediaWithAlbum[];
    isPlayable?: boolean;
    onPlayItem?: () => void;
    onMoreOptions?: (id: string | number) => void;
    contentType?: ActionType;
}

export function ListGridItem({ id, imageUrl, imageUrl2x, rounded, title, subtitle, link, song, contentType, isPlayable = false, onPlayItem, onMoreOptions }: ListGridItemProps) {
    const { openThreeDotsModalSheet } = useContext(ThreeDotsSheetContext);

    const ItemBody = useMemo(
        () => (
            <a className={classNames(styles['list-grid-item'])}>
                <div className={classNames(styles['list-grid-item__img-wrapper'], { [styles['list-grid-item__img-wrapper_rounded']]: rounded })}>
                    {imageUrl && <img className={styles['list-grid-item__img']} src={imageUrl} draggable={false} alt={title} srcSet={imageUrl2x ? `${imageUrl2x} 2x` : undefined} />}
                    {!imageUrl && <div className={styles['list-grid-item__name-initials']}>{getInitialsFromName(title)}</div>}
                </div>
                <div className={styles['list-grid-item__details']}>
                    <div className={styles['list-grid-item__title']}>{title}</div>
                    <div className={styles['list-grid-item__subtitle']}>{subtitle}</div>
                </div>
                {(contentType || onMoreOptions) && (
                    <div className={styles['list-grid-item__menu']}>
                        <ThreeDotsButton
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                if (onMoreOptions) return onMoreOptions(id);

                                return openThreeDotsModalSheet(contentType, id, event.currentTarget.offsetLeft, event.currentTarget.offsetTop, false);
                            }} />
                    </div>
                )}
            </a>
        ),
        [contentType, id, imageUrl, imageUrl2x, openThreeDotsModalSheet, onMoreOptions, subtitle, title, rounded]
    );

    if (isPlayable) {
        return (
            <div
                key={id}
                aria-label={song ? `Play ${song.name}` : ''}
                role="button"
                tabIndex={0}
                onClick={onPlayItem}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') onPlayItem();
                }}
            >
                {ItemBody}
            </div>
        );
    }

    return link ? (
        <AppLink key={id} href={link}>
            {ItemBody}
        </AppLink>
    ) : (
        <Fragment key={id}>{ItemBody}</Fragment>
    );
}

export default ListGridItem;
