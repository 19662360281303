import { AdsApi } from '@bpm-web-app/supreme-api-sdk';
import { supremeConfig } from '../config';

export class Ads {
    static client = new AdsApi(supremeConfig);

    static getCurrentAd(library: string, platform: string) {
        return Ads.client.getAd(platform, library);
    }

    static reportAdActivated(id: string) {
        return Ads.client.reportAdActivated(id);
    }
}
