import { Album as DownloadAlbum, MediaWithAlbum as DownloadMediaWithAlbum } from '@bpm-web-app/download-api-sdk';
import { MediaWithAlbum } from '@bpm-web-app/stream-api-sdk';
import { QueueItem, useUserSettings } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { forwardRef, useCallback, useMemo } from 'react';
import { APISortingKeys, LocalSortingKeys, SortOption } from '../../../sort-options-sheet/sort-options-sheet';
import { Checkbox } from '../../ui/checkbox/checkbox';
import styles from './track-list-supreme.module.css';

import { SortButton } from '../../sort-button/sort-button';
import { ColumnTypeSupreme, TrackListPresetSupreme, columnDefinitionsSupreme } from './track-list-supreme-helpers';

interface TrackListHeaderProps {
    isSortable?: boolean;
    isOver: boolean;
    preset: TrackListPresetSupreme;
    selectedItemsIds?: string[] | number[] ;
    list: Array<MediaWithAlbum> | Array<DownloadAlbum> | Array<DownloadMediaWithAlbum> | Array<QueueItem>;
    onSelectAll?: (checked: boolean) => void;
    onSelectItem?: (mediaId: number | string, index: number) => void;
    setSortPosition: (position: { left: number, top: number }) => void;
    setSortVisible: (visible: boolean) => void;
    selectedSortType?: LocalSortingKeys | APISortingKeys;
    sortOptions?: SortOption<LocalSortingKeys | APISortingKeys | any>[];
}

export const TrackListHeader = forwardRef<HTMLDivElement, TrackListHeaderProps>(({
    isSortable,
    isOver,
    preset,
    selectedItemsIds,
    list,
    onSelectAll,
    onSelectItem,
    setSortPosition,
    setSortVisible,
    selectedSortType,
    sortOptions,
}: TrackListHeaderProps, ref) => {
    const { isAnonymous, } = useUserSettings();

    const selectAllCheckbox = useMemo(
        () => (
            <Checkbox
                checked={!!selectedItemsIds?.length && selectedItemsIds?.length === list?.length}
                onChange={(e) => {
                    if (onSelectAll) onSelectAll(e.target.checked);
                }}
            />
        ),
        [list?.length, onSelectAll, selectedItemsIds?.length]
    );

    const getColumnHeading = useCallback(
        (type, heading) => {
            switch (type) {
                case ColumnTypeSupreme.EditModeCheckbox:
                    return onSelectItem ? selectAllCheckbox : heading;
                case ColumnTypeSupreme.StreamActions:
                case ColumnTypeSupreme.OfflineCrateActions:
                case ColumnTypeSupreme.OnlineCrateActions:
                case ColumnTypeSupreme.QuickActions: {
                    if (!isSortable) {
                        return null;
                    }
                    if (isAnonymous) {
                        return null;
                    }
                    return (
                        <SortButton
                            componentClass={styles['track-list__sort-btn']}
                            setSortPosition={setSortPosition}
                            setSortVisible={setSortVisible}
                            selectedSortType={selectedSortType}
                            sortOptions={sortOptions}
                        />
                    );
                }
                case ColumnTypeSupreme.Title:
                case ColumnTypeSupreme.Bpm:
                case ColumnTypeSupreme.Date:
                    return heading;
                default:
                    return heading;
            }
        },
        [isAnonymous, isSortable, onSelectItem, selectAllCheckbox, selectedSortType, setSortPosition, setSortVisible, sortOptions]
    );

    return (
        <div
            ref={ref}
            className={classNames(styles['track-list__list-heading'], styles[`track-list__list-heading--${preset}`], {
                [styles['track-list__list-heading--sortable']]: isSortable,
                [styles['track-list__list-heading--dragging-over']]: isOver,
                [styles['track-list__list-heading--crate']]: TrackListPresetSupreme.OnlineCrate === preset || TrackListPresetSupreme.OfflineCrate === preset,
            })}
        >
            {columnDefinitionsSupreme[preset]?.map(({ heading, type, class: className }, index) => (
                <div
                // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    className={classNames(className || '', styles['track-list__heading-column'], styles[`track-list__heading-column--${type}`])}
                >
                    {getColumnHeading(type, heading)}
                </div>
            ))}
        </div>
    );
});
