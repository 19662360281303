import { useForYou } from '@bpm-web-app/swr-hooks/src/hooks/playlists';
import { generateUuid, useHubSwitch } from '@bpm-web-app/utils';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { CardCarousel } from '../shared/card-carousel/card-carousel';
import styles from './for-you.module.css';
import { LibraryTabsContext } from '../../../../utils/src/lib/library-tabs.context';
import { useActionModal } from '../shared/action-modal/action-modal.context';
import { GhostComponent } from '../shared';
import { ForYouCommonData } from './for-you-common-data';
import { ForYouCard } from '../shared/card/for-you-card/for-you-card';

interface ForYouProps {
    isDataLoaded?: boolean;
    setIsDataLoaded?: (loaded: boolean) => void;
}

export function ForYou({ isDataLoaded, setIsDataLoaded }: ForYouProps) {
    const { isDownload } = useHubSwitch();
    const { libraryProperty } = useContext(LibraryTabsContext);
    const { openModal, closeModal } = useActionModal();

    const { data, isLoading } = useForYou(libraryProperty);

    const playlists = useMemo<ForYouCommonData[]>(() => {
        let mergedData: ForYouCommonData[] = [];

        if (data?.data?.not_available?.length) {
            mergedData = data.data.not_available.map((item) => ({
                id: generateUuid(),
                title: item.name,
                image_url: item.image_url,
                available: false,
            }));
        }

        if (data?.data?.available?.length) {
            mergedData.push(
                ...data.data.available.map((item) => ({
                    ...item,
                    available: true,
                    id: String(item.id),
                }))
            );
        }
        return mergedData;
    }, [data]);

    const showBrowseModal = useCallback(() => {
        openModal({
            headerTitle: isDownload ? 'Download more' : 'Keep browsing',
            title: isDownload ? 'Start downloading music to begin generating this personalized playlist' : 'Play more music to begin generating this personalized playlist',
            confirmButtonText: 'Ok',
            hideCancel: true,
            onConfirm: closeModal,
            onClose: closeModal,
        });
    }, [closeModal, isDownload, openModal]);

    useEffect(() => {
        if (setIsDataLoaded) {
            if (isLoading === false) {
                setIsDataLoaded(true);
            } else {
                setIsDataLoaded(false);
            }
        }
    }, [isLoading, setIsDataLoaded]);

    const shouldDisplayGhostLoading = useMemo(() => isDataLoaded === false || isLoading, [isDataLoaded, isLoading]);

    return (
        <div className={styles['for-you']}>
            {shouldDisplayGhostLoading ? (
                <GhostComponent type="cards" title="Recommended For You" elementsCount={8} contentType="user-playlist" />
            ) : (
                <CardCarousel title="Recommended For You">
                    {playlists?.map((playlist) => (
                        <ForYouCard
                            key={playlist.id}
                            playlist={playlist}
                            playlists={playlists}
                            onClick={showBrowseModal} />
                    ))}
                </CardCarousel>
            )}
        </div>
    );
}

export default ForYou;
