import { getMessaging, getToken, isSupported } from 'firebase/messaging';
import { getApps, initializeApp } from 'firebase/app';

export const firebaseConfig = {
    apiKey: 'AIzaSyB0d5ZRk9hNhw0ojA5OyQ3iUumBxdNmAUA',
    authDomain: 'bpm-supreme.firebaseapp.com',
    databaseURL: 'https://bpm-supreme.firebaseio.com',
    projectId: 'bpm-supreme',
    storageBucket: 'bpm-supreme.appspot.com',
    messagingSenderId: '205861889453',
    appId: '1:205861889453:web:c976ca51513eb6f9282d24',
    measurementId: 'G-YYT29RLLWX',
};

/** Web push certificates key pair */
const vapidKey = 'BH4tuXC1202CBHYXpXMMNRLozRDoRWmVTOD-SPeOl19DIKsZko096HEKF-2-GDZqzY-sDVg5fc0uxdG3xYcJsVM';

const firebaseCloudMessaging = {
    init: async (): Promise<string | null> => {
        if (!getApps().length) {
            const supported = await isSupported().catch(() => false);
            if (!supported) {
                return null;
            }
            // Initialize the Firebase app with the credentials
            initializeApp(firebaseConfig);

            try {
                const messaging = getMessaging();

                const status = await Notification.requestPermission();
                if (status && status === 'granted') {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const fcmToken = await getToken(messaging, { vapidKey });

                    if (fcmToken) {
                        return fcmToken;
                    }
                }
                return null;
            } catch (error) {
                return null;
            }
        }
        return null;
    },
};
export { firebaseCloudMessaging };
