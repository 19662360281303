import { CuratedSetApi as StreamCuratedSetApi } from '@bpm-web-app/stream-api-sdk';
import { CuratedSetApi as DownloadCuratedSetApi } from '@bpm-web-app/download-api-sdk';
import { downloadConfig, streamConfig } from '../config';
import { SearchCuratedSetsQuery } from '../typings';

export class CuratedSets {
    static streamClient = new StreamCuratedSetApi(streamConfig);

    static downloadClient = new DownloadCuratedSetApi(downloadConfig);

    static getClient(isDownload: boolean) {
        return isDownload ? this.downloadClient : this.streamClient;
    }

    static getCuratedSets = (
        isDownload: boolean,
        args: {
            limit?: number;
            skip?: number;
            library?: string;
        }
    ) => CuratedSets.getClient(isDownload).getCuratedSets(args.limit, args.skip, args.library);

    static getCuratedSetsCategoryId = (isDownload: boolean, categoryId: number, library?: string) =>
        CuratedSets.getClient(isDownload).getCuratedSetsByCatId(categoryId, library);

    static getCuratedSetsByCurator = (isDownload: boolean, curator: string, library?: string, exclude?: string) =>
        CuratedSets.getClient(isDownload).getCuratedSetsByCurator(curator, exclude, library);

    static getCuratedSetsCategories = (isDownload: boolean, library: string) => CuratedSets.getClient(isDownload).getCuratedSetCategories(library);

    static getCuratedSet = (isDownload: boolean, curatedSetId: number) => CuratedSets.getClient(isDownload).getCuratedSet(curatedSetId);

    static searchCuratedSets = (isDownload: boolean, query: SearchCuratedSetsQuery) => {
        if (isDownload) {
            return CuratedSets.downloadClient.searchCuratedSets(
                query.limit,
                query.skip,
                query.category,
                query.library,
                query.genre,
                query.artist,
                query.hide_remix ? '1' : '0',
                query.hide_originals ? '1' : '0',
                query.hide_exclusives ? '1' : '0',
                query.hide_explicit ? '1' : '0',
                query.hide_prev_downloaded ? '1' : '0',
                query.version,
                query.key,
                query.keyType,
                query.bpm_min,
                query.bpm_max,
                query.term,
                query.date
            );
        }
        return CuratedSets.streamClient.searchCuratedSets(
            query.limit,
            query.skip,
            query.category,
            query.library,
            query.genre,
            query.artist,
            query.version,
            query.key,
            query.keyType,
            query.bpm_min,
            query.bpm_max,
            query.term,
            query.date
        );
    };

    static getFollowedCuratedSets = (isDownload: boolean, library?: string) => CuratedSets.getClient(isDownload).getFollowedCuratedSets(library);

    static addCuratedSetToFollowed = (isDownload: boolean, id: number) => CuratedSets.getClient(isDownload).followCuratedSet(id);

    static removeCuratedSetFromFollowed = (isDownload: boolean, id: number) => CuratedSets.getClient(isDownload).unfollowCuratedSet(id);
}
