import classNames from 'classnames';
import styles from './loading-ellipsis.module.css';

export function LoadingEllipsis() {
    return (
        <div className={styles['loading-ellipsis']}>
            <div className={classNames(styles['loading-ellipsis__circle'], styles['loading-ellipsis__circle-1'])} />
            <div className={classNames(styles['loading-ellipsis__circle'], styles['loading-ellipsis__circle-2'])} />
            <div className={classNames(styles['loading-ellipsis__circle'], styles['loading-ellipsis__circle-3'])} />
        </div>
    );
}
