import * as React from 'react';
import deepEqual from 'lodash/isEqual';

type UseEffectParams = Parameters<typeof React.useEffect>;
type EffectCallback = UseEffectParams[0];
type DependencyList = UseEffectParams[1];
// yes, I know it's void, but I like what this communicates about
// the intent of these functions: It's just like useEffect
type UseEffectReturn = ReturnType<typeof React.useEffect>;

/**
 * @param value the value to be memoized (usually a dependency list)
 * @returns a memoized version of the value as long as it remains deeply equal
 */
export function useDeepCompareMemoize<T>(value: T) {
    const ref = React.useRef<T>(value);
    const signalRef = React.useRef<number>(0);

    if (!deepEqual(value, ref.current)) {
        ref.current = value;
        signalRef.current += 1;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useMemo(() => ref.current, [signalRef.current]);
}

export function useDeepCompareEffect(
    callback: EffectCallback,
    dependencies: DependencyList,
): UseEffectReturn {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useEffect(callback, useDeepCompareMemoize(dependencies));
}

export function useDeepCompareEffectNoCheck(
    callback: EffectCallback,
    dependencies: DependencyList,
): UseEffectReturn {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useEffect(callback, useDeepCompareMemoize(dependencies));
}
