import { flatMap, range } from 'lodash';

/**
 * Higher number = less detail
 */
export type WaveDetailDivFactor = 1 | 2 | 3 | 4 | 5 | 6;

export type DrawWaveOptions = {
    /**
     * The detail division factor of the wave.
     */
    detailDivFactor: WaveDetailDivFactor;
    /**
     * Override bar color
     */
    barColor?: string;
    /**
     * Override progress bar color
     */
    progressColor?: string;
    /**
     * Override locked bar color
     */
    lockedColor?: string;

    /**
     * The height of the wave
     */
    height: number;

    /**
     * The bar width
     */
    barWidth: number
};

type DrawWaveFormProps = {
    /**
     * The canvas element. Size needs to be static so it can be obtained via canvas.width etc.
     */
    canvas: HTMLCanvasElement;
    /**
     * The waveform data
     */
    waveform: Float32Array;
    /**
     * The ranges of the waveform to lock
     */
    lockedRanges: [startPercentage: number, endPercentage: number][];
    /**
     * The progress in percent (0 - 100)
     */
    progressPercentage: number;
    /**
     * Specicify either the bar width or the gap and the detail. Based on those (and the canvas size) the function will calculate the other paramters and draw the waveform.
     */
    options: DrawWaveOptions;
};

/**
 *
 * Draws the wave form on the canvas.
 */
export function drawWaveForm({ canvas, waveform, lockedRanges, progressPercentage, options }: DrawWaveFormProps) {
    const ctx = canvas.getContext('2d');

    if (!ctx) return;

    const segments = Math.round(waveform.length / options.detailDivFactor);
    const { width, height } = canvas;
    const barWidth = options.barWidth * window.devicePixelRatio;
    const gap = (width - (segments * barWidth)) / (segments - 1);

    const progressInSegments = (progressPercentage / 100) * segments;
    const progressIndex = Math.floor(progressInSegments);
    const progressCurrentIndexPixelAmount = (progressInSegments - progressIndex) * barWidth;
    const lockedRangesIndices = flatMap(lockedRanges.map(([start, end]) => range(Math.round((start / 100) * segments), Math.round((end / 100) * segments))));

    ctx.clearRect(0, 0, width, height);

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < segments; i++) {
        ctx.beginPath();
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const bar = getBarRect(
            i,
            waveform[i * options.detailDivFactor],
            height,
            barWidth,
            gap
        );
        ctx.rect(...bar);
        if (lockedRangesIndices.includes(i)) {
            ctx.fillStyle = options.lockedColor || 'rgba(74, 74, 74, 0.32)';
        } else if (i < progressIndex) {
            ctx.fillStyle = options.progressColor || '#f73e00';
        } else {
            ctx.fillStyle = options.barColor || '#4a4a4a';
        }
        ctx.fill();
        ctx.closePath();

        if (!lockedRangesIndices.includes(i) && i === progressIndex && progressCurrentIndexPixelAmount > 0) {
            // Color last bar partly
            ctx.beginPath();
            ctx.moveTo(bar[0] + 1, bar[1]);
            ctx.lineTo(bar[0] + 1, bar[1] + bar[3]);
            ctx.strokeStyle = options.progressColor || '#f73e00';
            ctx.lineWidth = progressCurrentIndexPixelAmount;
            ctx.stroke();
            ctx.closePath();
        }
    }
}

export function downloadWaveData(url: string) {
    return fetch(url).then((res) => {
        if (res.status === 200) {
            // eslint-disable-next-line promise/no-nesting
            return res.arrayBuffer().then((data) => {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                return arrayBufferToWaveData(data);
            });
        }
        throw new Error('No data found');
    });
}

/**
 * Internal
 */
function arrayBufferToWaveData(data: ArrayBuffer) {
    const view = new DataView(data);
    const floatArray = new Float32Array(data.byteLength / 4);
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < (data.byteLength / 4); index++) {
        const element = view.getFloat32(index * 4, true);
        floatArray[index] = element;
    }
    return floatArray;
}

/**
 * Internal
 */
function getBarRect(index: number, amplitude: number, canvasHeight: number, barWidth: number, gap: number): [x: number, y: number, w: number, h: number] {
    const startPercentage = 100 - amplitude;
    const heightPercentage = amplitude - startPercentage;
    const barHeight = canvasHeight * (heightPercentage / 100);
    const x = index * (barWidth + gap);
    const y = (canvasHeight - barHeight) / 2;
    return [x, y, barWidth, barHeight];
}

export function getDrawData(waveformData: Float32Array) {
    return waveformData;
}
