import classNames from 'classnames';
import styles from './trending-count-column.module.css';

interface TrendingCountColumnProps {
    trackCount: number;
    direction: 'rising' | 'dropping';
}

export function TrendingCountColumn({ trackCount, direction }: TrendingCountColumnProps) {
    return (
        <div className={classNames(styles['trending-count-column'], styles[`trending-count-column--${direction}`])}>
            {trackCount}
        </div>
    );
}
