import { SoundsApi } from '@bpm-web-app/create-api-sdk';
import { createConfig } from '../../config';
import { CreatePresetSearchPaginatedQuery, CreateSearchPaginatedQuery, GenericPaginatedQuery } from '../../typings';

export class Sound {
    static client = new SoundsApi(createConfig);

    static getSounds = (args: CreateSearchPaginatedQuery) =>
        Sound.client.getSounds(
            args.limit,
            args.skip,
            args.sort_by,
            args.search,
            args.bpm_max,
            args.bpm_min,
            args.key as string,
            args.keyType,
            args.type,
            args.tags,
            args.tag_groups,
            args.artists,
            args.subgenre,
            args.label,
            args.sound_package_id,
            args.curated_id,
            args.drive_id,
            args.genre as string,
            args.showHalfTime,
            args.suggested,
            args.hide_prev_played,
            args.hide_prev_downloaded,
            args.only_free,
            args.tag_categories,
            args.trending,
            args?.synth
        );

    static getPresets = (args: CreatePresetSearchPaginatedQuery) =>
        Sound.client.getPresets(
            args.limit,
            args.skip,
            args.sort_by,
            args.search,
            args.tags,
            args.tag_groups,
            args.artists,
            args.subgenre,
            args.label,
            args.sound_package_id,
            args.curated_id,
            args.drive_id,
            args.genre as string,
            args.only_free,
            args.tag_categories,
            args.trending,
            args.synth
        );

    static getSound = (id: string) => Sound.client.getSound(id);

    static getPreset = (id: string) => Sound.client.getPreset(id);

    static getSimilarSounds = (id: string, args?: GenericPaginatedQuery) => Sound.client.getSimilarSounds(id, args?.limit, args?.skip);

    static getDownloadedSounds = (args: CreateSearchPaginatedQuery) =>
        Sound.client.getFeaturedSounds_2(
            args.limit,
            args.skip,
            args.sort_by,
            args.search,
            args.bpm_max,
            args.bpm_min,
            args.key as string,
            args.keyType,
            args.type,
            args.tags,
            args.tag_groups,
            args.artists,
            args.subgenre,
            args.label,
            args.sound_package_id,
            args.curated_id,
            args.drive_id,
            args.genre as string,
            args.showHalfTime,
            args.suggested,
            args.hide_prev_played,
            args.hide_prev_downloaded,
            args.only_free,
            args.tag_categories,
            args.trending,
            args?.synth
        );

    static favoriteSound = (id: string) => Sound.client.favoriteSound(id);

    static unfavoriteSound = (id: string) => Sound.client.unfavoriteSound(id);

    static getFavoritesSounds = (args?: CreateSearchPaginatedQuery) =>
        Sound.client.getFavoritedSounds(
            args?.limit,
            args?.skip,
            args?.sort_by,
            args?.search,
            args?.bpm_max,
            args?.bpm_min,
            args?.key as string,
            args?.keyType,
            args?.type,
            args?.tags,
            args?.tag_groups,
            args?.artists,
            args?.subgenre,
            args?.label,
            args?.sound_package_id,
            args?.curated_id,
            args?.drive_id,
            args?.genre as string,
            args?.showHalfTime,
            args?.suggested,
            args?.hide_prev_played,
            args?.hide_prev_downloaded,
            args?.only_free,
            args?.tag_categories,
            args?.trending,
            args?.synth
        );

    static getFavoritesPresets = (args?: CreateSearchPaginatedQuery) =>
        Sound.client.getFavoritedPresets(
            args?.limit,
            args?.skip,
            args?.sort_by,
            args?.search,
            args?.tags,
            args?.tag_groups,
            args?.artists,
            args?.subgenre,
            args?.label,
            args?.sound_package_id,
            args?.curated_id,
            args?.drive_id,
            args?.genre as string,
            args?.suggested,
            args?.hide_prev_played,
            args?.hide_prev_downloaded,
            args?.only_free,
            args?.tag_categories,
            args?.trending,
            args?.synth
        );

    static downloadSound = (id: string, location: string) => Sound.client.downloadSound(id, location);

    static reportSound = (id: string) => Sound.client.reportSound(id);

    static streamSoundSetDuration = (id: string, duration: number) => Sound.client.streamSoundSetDuration(id, { duration });

    static streamSound = (id: string, location: string) => Sound.client.streamSound(id, location);

    static downloadMultiSounds = (ids: string[], location: string) => Sound.client.downloadMultiSound(ids, location);

    static downloadMultiSoundsPreview = (ids: string[], location: string) => Sound.client.downloadMultiSoundPreview(ids, location);
}
