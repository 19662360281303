import { Preset, Sound } from '@bpm-web-app/create-api-sdk';
import { useCallback, useEffect, useMemo } from 'react';
import { useStreamSoundSetDuration } from '@bpm-web-app/swr-hooks';
import { PlayerCache } from './playerCache';
import { generateUuid, getStartSilence } from './playerUtils';
import { HLSPlayer } from './hlsPlayer';
import { useCreatePlayer } from './create-player.context';
import { showToast } from './toast';

export interface PlaySoundListContext {
    play: (index: number) => Promise<void>
}

export function usePlaySoundList(type: 'sound', list: Sound[]): PlaySoundListContext;
export function usePlaySoundList(type: 'preset', list: Preset[]): PlaySoundListContext;
export function usePlaySoundList(type: 'sound' | 'preset', list: (Sound | Preset)[]): PlaySoundListContext {
    const uniqueId = useMemo(() => generateUuid(), []);
    const { setCurrentSelectedSoundId, closePlayer, currentSelectedSoundId, setCurrentSoundId } = useCreatePlayer();
    const streamSoundSetDuration = useStreamSoundSetDuration();

    const getSoundFile = useCallback((s: Sound | Preset) => {
        return type === 'preset' ? (s as Preset).preset_preview?.file_url : s.file_url;
    }, [type]);

    useEffect(() => {
        if (type === 'preset') {
            PlayerCache.loadUrls(uniqueId, (list || []).map((sound) => getSoundFile(sound)?.mp3 ?? '').filter((url) => !!url));
        } else {
            PlayerCache.loadUrls(uniqueId, (list || []).map((sound) => getSoundFile(sound)?.mp3 ?? '').filter((url) => !!url));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uniqueId, list?.length]);

    const play = useCallback(async (index: number) => {
        const sound = list[index];
        const soundFile = getSoundFile(sound);

        if (!soundFile) {
            showToast({ type: 'error', message: 'Credits required. Purchase credits now.', buttonText: 'Add Credits', replaceRouteOnButtonClick: '/account/plan' });
            return;
        }

        closePlayer();

        // if (elapsed > 0 && elapsed >= +sound.duration) {
        //    setElapsed(0);
        // }

        const toReportId = currentSelectedSoundId;
        const duration = HLSPlayer.getCurrentTime();

        const previousId = sound.id;
        if (soundFile) {
            HLSPlayer.load(await PlayerCache.getObjectUrlFor(soundFile.mp3, soundFile.hls) as string, soundFile.mp3);
        }
        const skipSilence = getStartSilence(sound.silence_info);
        if (skipSilence > 0) {
            HLSPlayer.goTo(skipSilence);
        }
        setCurrentSelectedSoundId(previousId, type);
        setCurrentSoundId(previousId);

        if (toReportId) {
            try {
                streamSoundSetDuration(toReportId, duration);
                // eslint-disable-next-line no-empty
            } catch (e) {
            }
        }
    }, [closePlayer, currentSelectedSoundId, getSoundFile, list, setCurrentSelectedSoundId, setCurrentSoundId, streamSoundSetDuration, type]);

    useEffect(() => {
        return () => {
            PlayerCache.resetPreloadUrls(uniqueId);
        };
    }, [uniqueId]);

    useEffect(() => {
        return () => {
            HLSPlayer.stop();
        };
    }, []);

    return {
        play
    };
}
