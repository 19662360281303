import classNames from 'classnames';
import RepeatOneIcon from '../../../../assets/icons/supreme/player-repeat-one.svg';
import RepeatIcon from '../../../../assets/icons/supreme/player-repeat.svg';
import ShuffleIcon from '../../../../assets/icons/supreme/player-shuffle.svg';
import styles from '../../supreme-maxi-player.module.css';
import { VolumeControl } from '../../../volume-control/volume-control';

interface PlayerSecondaryControlsProps {
    isShuffling: boolean;
    isRepeating: boolean;
    toggleShuffle: () => void;
    toggleRepeat: () => void;
    repeat: string;
}

export function PlayerSecondaryControls({ isShuffling, isRepeating, toggleShuffle, toggleRepeat, repeat }: PlayerSecondaryControlsProps) {
    return (
        <div className={styles['maxi-player__desktop-right-controls']}>
            <VolumeControl />
            <button
                className={classNames(styles['maxi-player__player-ctrl'], styles['maxi-player__player-ctrl--shuffle'], {
                    [styles['maxi-player__player-ctrl--active']]: isShuffling,
                })}
                type="button"
                aria-label="Shuffle"
                onClick={toggleShuffle}
                data-tip="Shuffle"
                data-tip-show
                data-delay-show="400"
            >
                <ShuffleIcon />
            </button>

            <button
                className={classNames(styles['maxi-player__player-ctrl'], styles['maxi-player__player-ctrl--repeat'], {
                    [styles['maxi-player__player-ctrl--active']]: isRepeating,
                })}
                type="button"
                aria-label="Repeat"
                onClick={toggleRepeat}
                data-tip="Repeat"
                data-tip-show
                data-delay-show="400"
            >
                {(repeat === 'off' || repeat === 'all') && <RepeatIcon />}
                {repeat === 'one' && (
                    <span className={classNames(styles['maxi-player__player-ctrl--repeat-one-icon'])}>
                        <RepeatOneIcon />
                    </span>
                )}
            </button>
        </div>
    );
}
