import Router from 'next/router';
import { DOWNLOAD_PLATFORM_BASE_PATH, STREAM_PLATFORM_BASE_PATH } from './constants';

/** NOTE: this is a copy from the type declared in the hubSwitch.context.ts to
 * avoid dependency cycles
 * */
enum Hub {
    Download = 'download',
    Stream = 'stream',
}

export function getPlatformFromRouterPath() {
    // NOTE: We assume is one Hub or another
    if (Router.pathname.split('/').some((path) => path === DOWNLOAD_PLATFORM_BASE_PATH.replace('/', ''))) {
        return Hub.Download;
    }

    if (Router.pathname.split('/').some((path) => path === STREAM_PLATFORM_BASE_PATH.replace('/', ''))) {
        return Hub.Stream;
    }

    return null;
}

export function getCurrentPlatformLink(href: string) {
    const routerPlatformPath = getPlatformFromRouterPath();

    if (routerPlatformPath === null) return href;

    const initialPath = routerPlatformPath === Hub.Stream ? STREAM_PLATFORM_BASE_PATH : DOWNLOAD_PLATFORM_BASE_PATH;

    return `${initialPath}/${href}`.replace('//', '/');
}

export function getPlatformLinkFromHub(hub: Hub | null, href: string) {
    if (hub === null) return href;
    if (href.startsWith(`${STREAM_PLATFORM_BASE_PATH}/`)) return href;
    if (href.startsWith(`${DOWNLOAD_PLATFORM_BASE_PATH}/`)) return href;
    const initialPath = hub === Hub.Stream ? STREAM_PLATFORM_BASE_PATH : DOWNLOAD_PLATFORM_BASE_PATH;

    return `${initialPath}/${href}`.replace('//', '/');
}

export function getPlatformLinkUsingRouter(href: string) {
    return getPlatformLinkFromHub(getPlatformFromRouterPath(), href);
}
