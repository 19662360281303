import { NewsApi } from '@bpm-web-app/supreme-api-sdk';
import { supremeConfig } from '../config';

export class News {
    static client = new NewsApi(supremeConfig);

    static getNewsForProvider = (args: { providerId: string; tag?: string; searchTerm?: string }) =>
        News.client.getNewsForProvider(args.providerId, args.searchTerm || '', args.tag);

    static getNewsTagsForProvider = (providerId: string) => News.client.getNewsTagsForProvider(providerId);
}
