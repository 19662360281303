import classNames from 'classnames';
import {
    formatBigNumber,
    formatDateToString
} from '@bpm-web-app/utils';
import { ArtistAlbumsSortByQueryOptions, ArtistSortByQueryOptions } from '@bpm-web-app/download-api-sdk';
import styles from './artist-portal-list.module.css';

import { ArtistPortalListPreset, columnDefinitions, ColumnType } from './artist-portal-list-helpers';

interface ArtistPortalListItemGenericProps {
    trackIndex: number;
    preset: ArtistPortalListPreset;
    row: any[]
    sortType?: ArtistSortByQueryOptions | ArtistAlbumsSortByQueryOptions
}

export type ArtistPortalListItemProps = ArtistPortalListItemGenericProps;

export function ArtistPortalListItem({
    preset,
    trackIndex,
    row,
    sortType
}: ArtistPortalListItemProps) {
    return (
        <div>
            <div
                className={classNames(styles['artist-portal-list__list-item'], styles[`artist-portal-list__list-item--${preset}`], {
                })}
            >
                {columnDefinitions[preset]?.map(({ type }, index) => {
                    let isCurrentSortType = false;
                    let column;
                    switch (type) {
                        case ColumnType.Count:
                            column = trackIndex + 1;
                            break;
                        case ColumnType.Downloads:
                            column = formatBigNumber(row[index]);
                            if (sortType === ArtistSortByQueryOptions.Downloads) isCurrentSortType = true;
                            break;
                        case ColumnType.Previews:
                            column = formatBigNumber(row[index]);
                            if (sortType === ArtistSortByQueryOptions.Previews) isCurrentSortType = true;
                            break;
                        case ColumnType.EstReach:
                            column = formatBigNumber(row[index]);
                            break;
                        case ColumnType.Impressions:
                            column = formatBigNumber(row[index]);
                            if (sortType === ArtistSortByQueryOptions.Impressions) isCurrentSortType = true;
                            break;
                        case ColumnType.Percentage:
                            column = `${row[index]}%`;
                            break;
                        case ColumnType.Source:
                            column = `${row[index]}`;
                            break;
                        case ColumnType.Flag:
                            column = (
                                <div className={styles[`artist-portal-list__column__${type}-container`]}>
                                    <span className={styles[`artist-portal-list__column--${type}-emoji`]}>{row[index]}</span>
                                </div>
                            );
                            break;
                        case ColumnType.Icon:
                            column = (
                                <div className={styles['artist-portal-list__source-icon']}>
                                    {row[index]}
                                </div>
                            );
                            break;
                        case ColumnType.Date:
                            column = formatDateToString(row[index]);
                            break;
                        case ColumnType.Empty:
                        default:
                            column = '';
                            break;
                    }
                    return (
                        <div
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            className={classNames(styles['artist-portal-list__column'], styles[`artist-portal-list__column--${type}`], { [styles[`artist-portal-list__column--${type}--visible-type`]]: isCurrentSortType })}
                        >
                            {column}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
