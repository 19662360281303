import useSWR from 'swr';
import { useEffect, useCallback, useRef, useState } from 'react';

import { Subscription } from '@bpm-web-app/api-client';
import { PackageIdPreviewBody, PlatformPauseBody, SubscriptionPackage } from '@bpm-web-app/supreme-api-sdk';
import { laggy } from './laggy';

export function useGetSubscriptionPackages(platform: SubscriptionPackage.PlatformEnum | 'stream' | 'supreme' | 'create', disabled?: boolean) {
    const { data, error } = useSWR(
        [
            `supreme-get-subscription-packages-${platform}`,
            {
                persist: false,
                platform,
            },
        ],
        () => Subscription.getSubscriptionPackages(platform as any),
        {
            isPaused: () => !!disabled,
        }
    );

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

export function useGetMeSubscription(platform?: Parameters<typeof Subscription.getMeSubscription>['0'], disabled?: boolean) {
    const { data, error, mutate } = useSWR(
        [
            `${platform}-get-user-subscriptions`,
            {
                persist: false,
                platform,
            },
        ],
        () => (platform ? Subscription.getMeSubscription(platform) : null),
        {
            isPaused: () => !!disabled || !platform,
        }
    );

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}

export function useGetMeSubscriptionUnCached(platform: Parameters<typeof Subscription.getMeSubscription>['0'], disabled?: boolean) {
    const random = useRef(Date.now());
    const { data, error, mutate } = useSWR([`${platform}-get-user-subscriptions-no-cache-${random}`, { persist: false }], () => (platform ? Subscription.getMeSubscription(platform) : null), {
        isPaused: () => !!disabled,
    });

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}

export function useGetSubscriptionPackage(packageId?: SubscriptionPackage['id'], enabled = true) {
    const { data, error } = useSWR(
        [
            `get-subscription-${packageId}`,
            {
                persist: false,
                packageId,
            },
        ],
        () => (packageId ? Subscription.getSubscriptionPackage(packageId) : null),
        { isPaused: () => !enabled || !packageId }
    );
    return {
        data,
        isLoading: !error && !data && Boolean(packageId),
        error,
    };
}

export function useGetSubscribePreview(packageId: SubscriptionPackage['id'], body?: PackageIdPreviewBody) {
    const [errorMessage, setErrorMessage] = useState('');
    const { data, error } = useSWR(
        [
            `supreme-get-subscribe-preview-${packageId}`,
            {
                persist: false,
                packageId,
                coupon: body?.coupon,
            },
        ],
        () => Subscription.subscribePreview(packageId, body),
        {
            shouldRetryOnError: false,
            use: [laggy],
            onError: async (err) => {
                try {
                    const json = (await err.json()) as { error: { type: string; status: number; message: string } };
                    setErrorMessage(json.error.message);
                } catch (e) {
                    setErrorMessage('An unknown error has occured');
                }
            },
        }
    );

    return {
        data,
        isLoading: !error && !data,
        error,
        errorMessage,
    };
}

export function useCancelSubscription() {
    return (platform: SubscriptionPackage.PlatformEnum) => Subscription.cancelSubscription(platform);
}

export function useStartSubscription() {
    return (platform: SubscriptionPackage.PlatformEnum) => Subscription.startSubscriptionProcess(platform);
}

export function useGetRetentionIncentivesSubscription(platform: SubscriptionPackage.PlatformEnum) {
    const { data, error } = useSWR(
        [
            `supreme-get-retention-incentives-subscription-${platform}`,
            {
                persist: false,
                platform,
            },
        ],
        () => Subscription.retentionIncentivesSubscription(platform),
        { isPaused: () => !platform }
    );

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

export function useApplyPromotionSubscription({ onError, onSuccess }: { onError: (error: any) => void; onSuccess: () => void }) {
    const [isLoading, setIsLoading] = useState(false);

    const applyPromotionSubscription = useCallback(
        async (platform: SubscriptionPackage.PlatformEnum) => {
            try {
                setIsLoading(true);
                await Subscription.applyPromotionSubscription(platform);
                setIsLoading(false);
                onSuccess();
            } catch (error: any) {
                setIsLoading(false);
                onError(error);
            }
        },
        [onError, onSuccess]
    );

    return { isLoading, applyPromotionSubscription };
}

export function usePauseSubscription({ onError, onSuccess }: { onError: (error: any) => void; onSuccess: () => void }) {
    const [isLoading, setIsLoading] = useState(false);

    const pauseSubscription = useCallback(
        async (platform: SubscriptionPackage.PlatformEnum, body: PlatformPauseBody) => {
            try {
                setIsLoading(true);
                await Subscription.pauseSubscription(platform, body);
                setIsLoading(false);
                onSuccess();
            } catch (error: any) {
                setIsLoading(false);
                onError(error);
            }
        },
        [onError, onSuccess]
    );

    return { isLoading, pauseSubscription };
}

export function useResumeSubscription(platform: string, { onError, onSuccess }: { onError: (error: any) => void; onSuccess: () => void }) {
    const { mutate } = useGetMeSubscription(platform);
    const [isLoading, setIsLoading] = useState(false);

    const resumeSubscription = async () => {
        try {
            setIsLoading(true);
            await Subscription.resumeSubscription(platform as unknown as SubscriptionPackage.PlatformEnum);
            mutate();
            setIsLoading(false);
            onSuccess();
        } catch (error: any) {
            setIsLoading(false);
            onError(error);
        }
    };

    return { isLoading, resumeSubscription };
}

export function usePreviewResumeSubscription(platform: string) {
    const [data, setData] = useState<Awaited<ReturnType<typeof Subscription.previewResumeSubscription>>['data'] | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const pauseSubscription = async () => {
        try {
            setIsLoading(true);
            setError('');
            const resp = await Subscription.previewResumeSubscription(platform);
            setData(resp.data);
            setIsLoading(false);
        } catch (err: any) {
            const errResponse = await err.json();
            setError(errResponse?.error?.message);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        pauseSubscription();
    }, [platform]);

    return { isLoading, data, error };
}
