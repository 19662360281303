import { CreateSearchContestQuery } from '@bpm-web-app/api-client';
import { useInfiniteContestSubmissions } from '@bpm-web-app/swr-hooks';
import { useRouter } from 'next/router';
import React, { useCallback, useMemo } from 'react';
import Submission from '../submission/submission';
import styles from './submissions.module.css';

export interface SubmissionsProps {
    contestId: string;
}

export function Submissions({ contestId }: SubmissionsProps) {
    const router = useRouter();
    const { submissionId } = router.query;

    const query: CreateSearchContestQuery = useMemo<CreateSearchContestQuery>(() => {
        const queryParams: CreateSearchContestQuery = {
            id: contestId,
            limit: 3,
        };

        if (submissionId) queryParams.submission_id = `${submissionId}`;
        return queryParams;
    }, [contestId, submissionId]);

    const { data, isLoadingMore, isLastPage, size, setSize, mutate } = useInfiniteContestSubmissions(query, false);

    const submissions = data?.map(({ data: paginatedResponse }) => paginatedResponse);
    const flattenSubmissions = useMemo(() => (submissions ? [].concat(...submissions) : []), [submissions]);

    const mutateSubmissions = useCallback(() => {
        mutate();
    }, [mutate]);

    const loadMoreSubmissions = useCallback(() => {
        if (isLoadingMore) return;
        setSize(size + 1);
    }, [isLoadingMore, setSize, size]);

    if (flattenSubmissions.length === 0) {
        return null;
    }

    return (
        // eslint-disable-next-line @typescript-eslint/dot-notation
        <div className={styles['comments']}>
            <h3 className={styles['submissions__title']}>Submissions ({flattenSubmissions.length})</h3>
            <div className={styles['submissions__comments']}>
                {flattenSubmissions.map((submission) => (
                    <React.Fragment key={submission.id}>
                        <Submission
                            contestId={contestId}
                            id={submission.id}
                            user={submission.user}
                            created_at={submission.created_at}
                            description={submission.description}
                            streamCount={submission.stream_count}
                            likeCount={submission.like_count}
                            title={submission.title}
                            liked={submission.liked}
                            songUrl={submission.song_url}
                            songWave={submission.song_wave}
                            mutate={mutateSubmissions}
                        />
                    </React.Fragment>
                ))}
            </div>
            {!isLastPage ? (
                <button type="button" className={styles['submissions__see-more']} onClick={loadMoreSubmissions}>
                    See More
                </button>
            ) : null}
        </div>
    );
}

export default Submissions;
