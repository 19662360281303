import { useContext, useMemo, useCallback, useEffect, useState, Fragment, useRef } from 'react';
import classNames from 'classnames';
import { useAlbumById, useCuratedSetDetail, useDownloadAlbumById, useDownloadMedia, useMedia, usePlaylistDetail, useUserPlaylistDetail } from '@bpm-web-app/swr-hooks';
import {
    showToast,
    downloadMediaWithAlbumToQueueItem,
    fileDownload,
    getAbsolutePosition,
    getCurrentPlatformLink,
    QueueItem,
    streamMediaWithAlbumToQueueItem,
    useApiErrorHandler,
    useHubSwitch,
    useOnClickOutside,
    Analytics,
    ViewportContext,
} from '@bpm-web-app/utils';
import { Media } from '@bpm-web-app/api-client';
import { CuratedSetWithAlbum, PlaylistWithAlbum, MediaWithAlbum as DownloadMediaWithAlbum, UserPlaylistWithAlbum, Album as DownloadAlbum } from '@bpm-web-app/download-api-sdk';
import { PlaylistWithMedia, CuratedSetWithMedia, MediaWithAlbum, UserPlaylistWithMedia } from '@bpm-web-app/stream-api-sdk';
import { useRouter } from 'next/router';
import { useActionModal } from '../action-modal/action-modal.context';
import styles from './three-dots-sheet.module.css';
import { ActionType, ThreeDotsSheetContext, ThreeDotsSheetOptionsKeys } from './three-dots-sheet.context';
import { usePlayer } from '../../player-context';
import useFavoriteAlbums from './useFavoriteAlbums';
import ThreeDotsMenuItem, { IconType } from './three-dots-menu-item';
import { useFollowPlaylists } from './useFollowPlaylist';
import useFavoriteMedia from './useFavoriteMedia';
import useFollowCuratedSets from './useFollowCuratedSets';
import useFollowArtist from './useFollowArtist';
import MyPlaylistList from '../../my-playlist-list/my-playlist-list';
import PlaylistsForm, { PlaylistsFormProps } from '../../playlists-form/playlists-form';
import { LibraryTabsContext } from '../../../../../utils/src/lib/library-tabs.context';
import { useUserPlaylists } from './useUserPlaylists';
import { useUserPlaylistsDetails } from './useUserPlaylistsDetails';
import { useCrates } from './useCrates';
import { ActionModal } from '../action-modal/action-modal';
import BackArrow from '../../../assets/icons/chevron-left.svg';
import { MyPlaylistsCheckboxes } from '../../my-playlist-checkboxes/my-playlist-checkboxes';

type Option = {
    key: ThreeDotsSheetOptionsKeys;
    label: string;
    // eslint-disable-next-line max-len
    icon?: IconType;
    action: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    extraLabel?: string;
    disabled?: boolean;
    disabledDataTip?: string;
};

type ContainerStyle = {
    top?: number;
    left?: number;
};

const threeDotsIconClassName = 'js-three-dots-icon';

const actionsToShowSelectVersion: ActionType[] = ['download:album', 'user-playlist-track'];

export function ThreeDotsSheet() {
    const { optionsToShow, shareURL, actionTypeId, setActionTypeId, actionType, leftPosition, topPosition, move, showOnlyPlaylist, secondaryActionTypeId, renderLocation } =
        useContext(ThreeDotsSheetContext);
    const [showReportTrack, setShowReportTrack] = useState(false);

    const { isDownload } = useHubSwitch();

    const { width: viewPortWidth } = useContext(ViewportContext);
    const isMobile = viewPortWidth <= 767;

    const { libraryProperty } = useContext(LibraryTabsContext);
    const router = useRouter();
    const selectedVersionId = useRef<number | null>(null);
    const selectVersionPostHandler = useRef<() => void>(null);

    const { addToQueue, removeFromQueue, currentTrack, setShowFeaturedIn, setShowSimilarTracks, setIsMaxiPlayer, isMediaInTheQueue } = usePlayer();

    const [showPlaylistFolders, setShowPlaylistFolders] = useState(false);
    const [showSelectVersionOptions, setShowSelectVersionOptions] = useState(false);

    const [additionalMenuTopOffset, setAdditionalMenuTopOffset] = useState(0);

    const [openForm, setOpenForm] = useState<null | PlaylistsFormProps>(null);
    const [categoryId, setCategoryId] = useState<number | string | null>(null);

    const handleCloseSheet = useCallback(() => {
        setActionTypeId(-1);
    }, [setActionTypeId]);

    useEffect(() => {
        setShowPlaylistFolders(false);
    }, [actionTypeId]);

    useEffect(() => {
        if (showOnlyPlaylist) {
            setShowPlaylistFolders(true);
        }
    }, [showOnlyPlaylist]);

    useEffect(() => {
        if (isMobile) {
            document.documentElement.classList.add('stop-scroll', 'lift-mobile-toast');
        } else {
            document.documentElement.classList.remove('stop-scroll', 'lift-mobile-toast');
        }
        return () => {
            document.documentElement.classList.remove('stop-scroll', 'lift-mobile-toast');
        };
    }, [isMobile]);

    const { media: track } = useMedia(
        +actionTypeId,
        !(
            [
                'track',
                'maxi-player',
                'online-crate',
                'mini-player',
                'mini-player-versions',
                'maxi-player-versions',
                'queue-track',
                'user-playlist-track',
                'download:track',
                'download-accordion',
                'supreme-maxi-player',
            ] as (typeof actionType)[]
        ).includes(actionType) ||
        (actionType === 'user-playlist-track' && isDownload)
    );

    const { data: exclusivePlaylist } = usePlaylistDetail(+actionTypeId, actionType !== 'exclusive-playlist');
    const { data: userPlaylist } = useUserPlaylistDetail(isDownload, `${actionTypeId}`, !(actionType === 'for-you-playlist-detail' || actionType === 'for-you-playlist' || actionType === 'user-playlist'));
    const { data: streamAlbum } = useAlbumById(+actionTypeId, actionType === 'stream:album');
    const { data: downloadAlbumData } = useDownloadAlbumById(+actionTypeId, actionType === 'download:album' || (actionType === 'user-playlist-track' && isDownload));
    const { data: curatedSet } = useCuratedSetDetail(+actionTypeId, actionType !== 'curatedSet');
    const { downloadSingleMedia } = useDownloadMedia(libraryProperty);

    /* no need to fetch any user playlist detail if mediaList is defined - that's what we want to add
     * to the playlist */
    const errorHandler = useApiErrorHandler();

    const album = useMemo(() => (isDownload ? downloadAlbumData : streamAlbum) || { data: track?.data.album }, [downloadAlbumData, isDownload, streamAlbum, track]);
    const remixesAvailable = (album?.data as DownloadAlbum)?.remixes_available || 0;
    const remixOfId = (album?.data as DownloadAlbum)?.remix_of_id || null;

    const { addCuratedSetsToFavorites, removeCuratedSetFromFavorites, isCuratedSetFollowed } = useFollowCuratedSets(+actionTypeId, libraryProperty);

    const { addAlbumToFavorites, removeAlbumFromFavorites, isAlbumFavorite } = useFavoriteAlbums(
        (['maxi-player', 'online-crate', 'supreme-maxi-player'] as (typeof actionType)[]).includes(actionType) ||
            (actionType === 'mini-player' && isDownload) ||
            (actionType === 'queue-track' && isDownload)
            ? track?.data?.album_id
            : +actionTypeId
    );

    const { addToFavorites, removeFromFavorites, isMediaFollowed } = useFavoriteMedia(+actionTypeId, libraryProperty);

    const { followArtist, unfollowArtist, isArtistFollowed } = useFollowArtist(+actionTypeId);

    const [showComponent] = useState(false);

    const { ref } = useOnClickOutside(false, (e) => {
        if (openForm === null && !showReportTrack) {
            const eventTarget = e.target;

            if (eventTarget instanceof Element) {
                const parentClass = `.${styles['three-dots-menu__container']}`;
                /* if clicked target with 3 dots */
                if (eventTarget.closest(parentClass)) {
                    return;
                }

                // If clicked inside a modal
                if (eventTarget.closest('.ReactModal__Overlay')) {
                    return;
                }

                if (eventTarget.closest('.three-dots-button')) {
                    return;
                }

                /* if clicked on a direct parent of the svg icon */
                const eventTargetChildren = Array.from(eventTarget.children);

                if (eventTargetChildren.some((childElement) => childElement.classList.contains(threeDotsIconClassName))) {
                    return;
                }
            }
            handleCloseSheet();
        }
    });

    useEffect(() => {
        const onScroll = () => {
            handleCloseSheet();
        };
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, [handleCloseSheet]);

    const { addPlaylistToFavorites, removePlaylistFromFavorites, isPlaylistFollowed } = useFollowPlaylists(+actionTypeId, libraryProperty);

    const { deletePlaylist, removeTrackFromPlaylist, editPlaylist } = useUserPlaylists(categoryId as string | number);

    const { addMediaToPlaylist, addAlbumToPlaylist } = useUserPlaylistsDetails();

    const {
        addMediaToOnlineCrate,
        addMultipleMediaToOnlineCrate,
        addMediaToOfflineCrate,
        addMultipleMediaToOfflineCrate,
        removeFromOfflineCrate,
        removeFromOnlineCrate,
        isMediaInCrate,
        batchRemoveFromOnlineCrate,
        batchRemoveFromOfflineCrate,
    } = useCrates();

    const { openModal: openActionModal, closeModal: closeActionModal } = useActionModal();

    const updatePlaylist = useCallback(() => {
        setOpenForm({
            id: actionTypeId as string,
            type: 'EditMyPlaylist',
            text: `Enter a new name for ${userPlaylist?.data.title} below.`,
            defaultvalue: 'New Playlist Name',
            formActionWithId: editPlaylist,
            close: () => setOpenForm(null),
        });
    }, [actionTypeId, editPlaylist, userPlaylist]);

    const mediaToAdd = useMemo(() => {
        let mediaItems: any[] = [];
        switch (actionType) {
            case 'stream:album': {
                if (album?.data.media) {
                    mediaItems = album.data.media.map((media) => ({ ...media, album: { ...album.data } }));
                }
                break;
            }
            case 'download:album': {
                if (album?.data?.media) {
                    mediaItems = album.data.media?.map((version) => ({ ...version, album: { ...album.data } }));
                }
                break;
            }
            case 'curatedSet': {
                if (!curatedSet) break;
                if ('media' in curatedSet.data && (curatedSet.data as CuratedSetWithMedia)) {
                    mediaItems = (curatedSet.data as CuratedSetWithMedia).media;
                } else if ('albums' in curatedSet.data && (curatedSet.data as CuratedSetWithAlbum).albums) {
                    // NOTE: returns the first version of each album, with the album property
                    mediaItems = (curatedSet.data as CuratedSetWithAlbum).albums.map((albumItem) => ({
                        ...albumItem.media[0],
                        album: albumItem,
                    }));
                }
                break;
            }
            case 'for-you-playlist':
            case 'user-playlist':
            case 'for-you-playlist-detail': {
                if (userPlaylist && 'media' in (userPlaylist.data as UserPlaylistWithMedia)) {
                    mediaItems = (userPlaylist.data as UserPlaylistWithMedia).media;
                    break;
                } else if (userPlaylist && 'albums' in userPlaylist.data && (userPlaylist.data as UserPlaylistWithAlbum).albums) {
                    // NOTE: returns the first version of each album, with the album property
                    mediaItems = (userPlaylist.data as UserPlaylistWithAlbum).albums?.map((albumItem) => ({
                        ...albumItem.media[0],
                        album: albumItem,
                    }));
                }
                break;
            }
            case 'exclusive-playlist': {
                if (exclusivePlaylist && 'media' in (exclusivePlaylist.data as PlaylistWithMedia)) {
                    mediaItems = (exclusivePlaylist.data as PlaylistWithMedia).media;
                    break;
                } else if (exclusivePlaylist && 'albums' in exclusivePlaylist.data && (exclusivePlaylist.data as PlaylistWithAlbum).albums) {
                    // NOTE: returns the first version of each album, with the album property
                    mediaItems = (exclusivePlaylist.data as PlaylistWithAlbum).albums?.map((albumItem) => ({
                        ...albumItem.media[0],
                        album: albumItem,
                    }));
                }
                break;
            }
            case 'maxi-player':
            case 'download-accordion':
            case 'supreme-maxi-player':
            case 'mini-player':
            case 'maxi-player-versions':
            case 'mini-player-versions':
            case 'download:track':
            case 'queue-track':
            case 'user-playlist-track':
            case 'track': {
                if (isDownload && downloadAlbumData) {
                    mediaItems = downloadAlbumData.data?.media.map((albumItem) => ({
                        ...albumItem,
                        album: downloadAlbumData.data,
                    }));
                }
                if (track) {
                    mediaItems = [track.data];
                }
                break;
            }

            default: {
                break;
            }
        }

        return mediaItems;
    }, [actionType, album?.data, curatedSet, userPlaylist, exclusivePlaylist, track, isDownload, downloadAlbumData]);
    const mediaIdsToAdd: string[] = useMemo(() => mediaToAdd.map((media) => media.album_id || media.id).reduce((acc, id) => (acc.includes(id) ? acc : [...acc, id]), []), [mediaToAdd]);

    useEffect(
        () => () => {
            if (!showComponent) {
                handleCloseSheet();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [showComponent]
    );

    const containerStyle = useMemo(() => {
        const styleTemp: ContainerStyle = {};
        if (isMobile) {
            return styleTemp;
        }
        if (leftPosition !== null) {
            styleTemp.left = leftPosition;
        }
        if (topPosition !== null) {
            styleTemp.top = topPosition;
        }
        return styleTemp;
    }, [leftPosition, topPosition, isMobile]);

    const leftSideStyle = useMemo(() => {
        const styleTemp: ContainerStyle = {};
        if (isMobile) {
            return styleTemp;
        }
        if (topPosition !== null) {
            styleTemp.top = additionalMenuTopOffset;
        } else {
            styleTemp.top = additionalMenuTopOffset;
        }
        return styleTemp;
    }, [additionalMenuTopOffset, topPosition, isMobile]);

    const handleRemoveFromQueue = useCallback(() => {
        let queueMedia: QueueItem[];

        if (isDownload) {
            const versionToRemove = mediaToAdd.length === 1 ? mediaToAdd[0] : mediaToAdd.find(({ id }: DownloadMediaWithAlbum) => id === selectedVersionId.current);

            queueMedia = versionToRemove ? [downloadMediaWithAlbumToQueueItem(versionToRemove, mediaToAdd.length)] : [];

            // remove the default version fallback
            if (queueMedia.length === 0) {
                const uuid = isMediaInTheQueue(mediaToAdd[0].id);
                queueMedia = uuid ? [{ id: uuid } as unknown as QueueItem] : [];
            }
        } else {
            queueMedia = mediaToAdd.map((media) => streamMediaWithAlbumToQueueItem(media));
        }

        queueMedia.forEach((qm) => {
            removeFromQueue(qm.id);
        });

        showToast({ type: 'success', message: 'Removed from queue.', buttonText: 'Open Queue', onButtonClick: () => setIsMaxiPlayer(true) });

        handleCloseSheet();
    }, [isDownload, handleCloseSheet, mediaToAdd, removeFromQueue, setIsMaxiPlayer, isMediaInTheQueue]);

    const handleShowReportMedia = useCallback(async () => {
        setShowReportTrack(true);
    }, []);

    const handleReportMedia = useCallback(async () => {
        try {
            await Media.reportMedia(isDownload, currentTrack?.id);
            if (currentTrack?.id !== undefined) {
                Analytics.trackClick('album_reported', currentTrack?.id.toString(), { location: 'artist-portal' });
            }
            showToast({ type: 'success', message: 'Reported successfully.' });
        } catch (error) {
            errorHandler({ error });
        }
        setShowReportTrack(false);
        handleCloseSheet();
    }, [currentTrack?.id, errorHandler, handleCloseSheet, isDownload]);

    const handleFeatured = useCallback(() => {
        setShowFeaturedIn(true);
        handleCloseSheet();
    }, [handleCloseSheet, setShowFeaturedIn]);

    const handleDeletePlaylist = useCallback(async () => {
        openActionModal({
            headerTitle: 'Delete Playlist',
            title: 'Are you sure you want to delete this playlist?',
            confirmButtonText: "I'm sure",
            cancelButtonText: 'Cancel',
            shouldCloseOnOverlayClick: true,
            onConfirm: async () => {
                await deletePlaylist(actionTypeId as string);
                handleCloseSheet();
                closeActionModal();

                /* if the delete action has taken place from a user playlist detail page,
                 * redirect back to the listing page */
                const { playlistId } = router.query;
                if (playlistId && `${actionTypeId}` === `${playlistId}`) {
                    router.replace(getCurrentPlatformLink('/my-playlists'));
                }
            },
            onClose: closeActionModal,
        });
    }, [actionTypeId, closeActionModal, deletePlaylist, handleCloseSheet, openActionModal, router]);

    const handleFollowPlaylist = useCallback(
        () => (isPlaylistFollowed ? removePlaylistFromFavorites() : addPlaylistToFavorites()),
        [addPlaylistToFavorites, removePlaylistFromFavorites, isPlaylistFollowed]
    );

    const handleFollowArtist = useCallback(() => (isArtistFollowed ? unfollowArtist() : followArtist()), [followArtist, unfollowArtist, isArtistFollowed]);

    const handleFollowCuratedSet = useCallback(
        () => (isCuratedSetFollowed ? removeCuratedSetFromFavorites() : addCuratedSetsToFavorites()),
        [isCuratedSetFollowed, removeCuratedSetFromFavorites, addCuratedSetsToFavorites]
    );

    const handleSimilar = useCallback(() => {
        handleCloseSheet();
        if (actionType === 'maxi-player' || actionType === 'maxi-player-versions') {
            setShowSimilarTracks(true);
        } else if (actionType === 'online-crate' || (isDownload && ['mini-player', 'mini-player-versions', 'queue-track', 'download-accordion', 'supreme-maxi-player'].includes(actionType))) {
            router.push(getCurrentPlatformLink(`/similar-media/${track?.data?.album_id}`));
        } else {
            router.push(getCurrentPlatformLink(`/similar-media/${actionTypeId}`));
        }
    }, [actionType, actionTypeId, handleCloseSheet, isDownload, router, setShowSimilarTracks, track?.data?.album_id]);

    const handleAddToPlaylist = useCallback(
        (e?: React.MouseEvent<HTMLElement>) => {
            setShowPlaylistFolders(true);
            setShowSelectVersionOptions(false);
            if (e) {
                setAdditionalMenuTopOffset(0);
            }
        },
        [setShowPlaylistFolders, setShowSelectVersionOptions]
    );

    const addToPlaylist = useCallback(
        (playlistId: string) => {
            if (mediaToAdd?.length) {
                if (isDownload) {
                    const ids = (mediaToAdd as MediaWithAlbum[]).map((a) => a.album_id).filter((v, i, a) => a.indexOf(v) === i);
                    addAlbumToPlaylist(playlistId, {
                        albums: ids.map((a, index: number) => {
                            return { album_id: a, order: index };
                        }),
                    });
                } else {
                    addMediaToPlaylist(playlistId, {
                        media: (mediaToAdd as MediaWithAlbum[]).map((media, index: number) => ({
                            media_id: Number(media.id),
                            order: index,
                        })),
                    });
                }
            }
            handleCloseSheet();
        },
        [addMediaToPlaylist, addAlbumToPlaylist, isDownload, handleCloseSheet, mediaToAdd]
    );

    const resetSelectVersion = useCallback(() => {
        selectVersionPostHandler.current = null;
        selectedVersionId.current = null;
        setShowSelectVersionOptions(false);
    }, []);

    const handleAddToQueue = useCallback(
        (e?: React.MouseEvent<HTMLElement>) => {
            setShowPlaylistFolders(false);
            if (isDownload && mediaToAdd.length > 1 && !selectedVersionId.current && actionsToShowSelectVersion.includes(actionType)) {
                setShowSelectVersionOptions(true);
                if (e) {
                    setAdditionalMenuTopOffset(e.currentTarget.offsetTop);
                }
                selectVersionPostHandler.current = handleAddToQueue;
                return;
            }

            if (mediaToAdd.length) {
                let queueMedia: QueueItem[];

                if (isDownload) {
                    const versionToAdd = mediaToAdd.length === 1 ? mediaToAdd[0] : mediaToAdd.find(({ id }: DownloadMediaWithAlbum) => id === selectedVersionId.current);

                    queueMedia = versionToAdd
                        ? [downloadMediaWithAlbumToQueueItem(versionToAdd, mediaToAdd.length)]
                        : ['curatedSet', 'user-playlist', 'exclusive-playlist', 'for-you-playlist'].includes(actionType)
                            ? mediaToAdd.map((m) => downloadMediaWithAlbumToQueueItem(m, 1))
                            : [];
                } else {
                    queueMedia = mediaToAdd.map((media) => streamMediaWithAlbumToQueueItem(media));
                }

                addToQueue(queueMedia);
                resetSelectVersion();
                handleCloseSheet();
                showToast({ type: 'success', message: 'Added to queue.', buttonText: 'Open Queue', onButtonClick: () => setIsMaxiPlayer(true) });
            }
        },
        [actionType, addToQueue, handleCloseSheet, isDownload, mediaToAdd, resetSelectVersion, setIsMaxiPlayer]
    );

    const handleRemoveFromCrate = (e?: React.MouseEvent<HTMLElement>) => {
        if (isDownload && mediaToAdd.length > 1 && !selectedVersionId.current && actionsToShowSelectVersion.includes(actionType)) {
            setShowPlaylistFolders(false);
            setShowSelectVersionOptions(true);
            if (e) {
                setAdditionalMenuTopOffset(e.currentTarget.offsetTop);
            }
            selectVersionPostHandler.current = handleRemoveFromCrate;
            return;
        }

        if (mediaToAdd.length === 1 || actionsToShowSelectVersion.includes(actionType)) {
            if (isDownload) {
                const versionToAdd = mediaToAdd.length === 1 ? mediaToAdd[0].id : selectedVersionId.current;
                removeFromOnlineCrate(versionToAdd);
                resetSelectVersion();
            } else {
                removeFromOfflineCrate(mediaToAdd[0].id);
            }
        } else if (mediaToAdd.length > 1) {
            if (isDownload) {
                batchRemoveFromOnlineCrate(mediaToAdd.map((media) => Number(media.id)));
            } else {
                batchRemoveFromOfflineCrate(mediaToAdd.map((media) => Number(media.id)));
            }
        }

        handleCloseSheet();
    };

    const handleAddToCrate = useCallback(
        (e?: React.MouseEvent<HTMLElement>) => {
            if (isDownload && mediaToAdd.length > 1 && !selectedVersionId.current && actionsToShowSelectVersion.includes(actionType)) {
                setShowPlaylistFolders(false);
                setShowSelectVersionOptions(true);
                if (e) {
                    setAdditionalMenuTopOffset(e.currentTarget.offsetTop);
                }
                selectVersionPostHandler.current = handleAddToCrate;
                return;
            }

            if (mediaToAdd.length === 1 || actionsToShowSelectVersion.includes(actionType)) {
                if (isDownload) {
                    const versionToAdd = mediaToAdd.length === 1 ? mediaToAdd[0].id : selectedVersionId.current;
                    addMediaToOnlineCrate(versionToAdd);
                    resetSelectVersion();
                } else {
                    addMediaToOfflineCrate(mediaToAdd[0].id);
                }
            } else if (mediaToAdd.length > 1) {
                if (isDownload) {
                    addMultipleMediaToOnlineCrate({
                        media_ids: mediaToAdd.map((media) => Number(media.id)),
                    });
                } else {
                    addMultipleMediaToOfflineCrate({
                        media_ids: mediaToAdd.map((media) => Number(media.id)),
                    });
                }
            }
            handleCloseSheet();
        },
        [actionType, addMediaToOfflineCrate, addMediaToOnlineCrate, addMultipleMediaToOfflineCrate, addMultipleMediaToOnlineCrate, handleCloseSheet, resetSelectVersion, isDownload, mediaToAdd]
    );

    // eslint-disable-next-line consistent-return
    const handleFavorite = useCallback(() => {
        handleCloseSheet();

        if (
            actionType === 'stream:album' ||
            actionType === 'download:album' ||
            (isDownload && actionType === 'supreme-maxi-player') ||
            (isDownload && actionType === 'maxi-player') ||
            (isDownload && actionType === 'mini-player') ||
            (isDownload && actionType === 'download-accordion') ||
            (isDownload && actionType === 'online-crate') ||
            (isDownload && actionType === 'user-playlist-track') ||
            (isDownload && actionType === 'queue-track')
        ) {
            return isAlbumFavorite ? removeAlbumFromFavorites() : addAlbumToFavorites();
        }
        if (
            actionType === 'track' ||
            (!isDownload && actionType === 'supreme-maxi-player') ||
            (!isDownload && actionType === 'maxi-player') ||
            (!isDownload && actionType === 'mini-player') ||
            (!isDownload && actionType === 'download-accordion') ||
            (!isDownload && actionType === 'user-playlist-track') ||
            (!isDownload && actionType === 'queue-track')
        ) {
            return isMediaFollowed ? removeFromFavorites() : addToFavorites();
        }

        return null;
    }, [actionType, isDownload, handleCloseSheet, isAlbumFavorite, removeAlbumFromFavorites, addAlbumToFavorites, isMediaFollowed, removeFromFavorites, addToFavorites]);

    const isFavorite = useMemo(
        () =>
            actionType === 'track' ||
                (actionType === 'mini-player' && !isDownload) ||
                (actionType === 'queue-track' && !isDownload) ||
                (actionType === 'maxi-player' && !isDownload) ||
                (actionType === 'download-accordion' && !isDownload) ||
                (actionType === 'user-playlist-track' && !isDownload)
                ? isMediaFollowed
                : isAlbumFavorite,
        [actionType, isAlbumFavorite, isDownload, isMediaFollowed]
    );

    const handleDeleteTrack = useCallback(() => {
        if (actionType === 'online-crate') {
            removeFromOnlineCrate(+actionTypeId);
        } else if (actionType === 'offline-crate') {
            removeFromOfflineCrate(+actionTypeId);
        } else if (actionType === 'user-playlist-track' && secondaryActionTypeId) {
            removeTrackFromPlaylist(secondaryActionTypeId as string, +actionTypeId);
        }
        handleCloseSheet();
    }, [actionType, actionTypeId, handleCloseSheet, removeFromOfflineCrate, removeFromOnlineCrate, removeTrackFromPlaylist, secondaryActionTypeId]);

    const handleDownloadVersion = useCallback(async () => {
        handleCloseSheet();
        try {
            const { data: downloadMediaUrlData } = await downloadSingleMedia(actionTypeId);

            if (downloadMediaUrlData?.url) {
                fileDownload(downloadMediaUrlData.url);
            }
        } catch (error) {
            errorHandler({ error });
        }
    }, [actionTypeId, downloadSingleMedia, errorHandler, handleCloseSheet]);

    const copyUrl = useCallback(() => {
        Analytics.trackClick(`share:${actionType}`, actionTypeId.toString(), { location: '3-dot' });
        // eslint-disable-next-line @typescript-eslint/dot-notation
        navigator.clipboard?.writeText(
            process.env.NEXT_PUBLIC_RELEASE_STAGE === 'local'
                ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}${shareURL}`
                : `${window.location.protocol}//${window.location.hostname}${shareURL}`
        );
        showToast({ type: 'success', message: 'Copied to clipboard.' });
        handleCloseSheet();
    }, [actionType, actionTypeId, handleCloseSheet, shareURL]);

    const handleFindRemixes = useCallback(() => {
        handleCloseSheet();
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (album && (album.data as DownloadAlbum)?.remixes_available && (album.data as DownloadAlbum)!.remixes_available! > 0) {
            router.push(getCurrentPlatformLink(`/find-remixes/${album.data?.id}`));
        } else if (album && (album.data as DownloadAlbum)?.remix_of_id !== null) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            router.push(getCurrentPlatformLink(`/find-remixes/${(album.data as DownloadAlbum)!.remix_of_id}`));
        }
    }, [album, handleCloseSheet, router]);

    const allOption: Option[] = useMemo(
        (): Option[] => [
            {
                key: 'delete-track',
                icon: 'deleteTrack',
                label: 'Delete Track',
                action: handleDeleteTrack,
            },
            {
                key: 'add-to-playlist',
                icon: 'addToPlaylist',
                label: 'Add to Playlist',
                action: handleAddToPlaylist,
            },
            {
                key: 'add-to-queue',
                icon: isMediaInTheQueue(mediaToAdd[0]?.id) ? 'removeFromQueue' : 'addToQueue',
                label: isMediaInTheQueue(mediaToAdd[0]?.id) ? 'Remove from Queue' : 'Add to Queue',
                action: isMediaInTheQueue(mediaToAdd[0]?.id) ? handleRemoveFromQueue : handleAddToQueue,
            },
            {
                key: 'add-to-crate',
                icon: isMediaInCrate(Number(mediaToAdd[0]?.id)) ? 'removeFromCrate' : 'addToCrate',
                label: isMediaInCrate(Number(mediaToAdd[0]?.id)) ? 'Remove from Crate' : 'Add to Crate',
                action: isMediaInCrate(Number(mediaToAdd[0]?.id)) ? handleRemoveFromCrate : handleAddToCrate,
            },
            {
                key: 'add-to-favorites',
                icon: isFavorite ? 'favorite' : 'unFavorite',
                label: isFavorite ? 'Remove from favorites' : 'Add to Favorites',
                action: handleFavorite,
            },
            {
                key: 'follow-playlist',
                icon: isPlaylistFollowed ? 'unFollowPlaylist' : 'followPlaylist',
                label: isPlaylistFollowed ? 'Unfollow Playlist' : 'Follow Playlist',
                action: handleFollowPlaylist,
            },
            {
                key: 'follow-curated-set',
                icon: isCuratedSetFollowed ? 'unFollowPlaylist' : 'followPlaylist',
                label: isCuratedSetFollowed ? 'Remove from favorites' : 'Add to favorites',
                action: handleFollowCuratedSet,
            },
            {
                key: 'follow-artist',
                icon: isArtistFollowed ? 'unFollow' : 'follow',
                label: isArtistFollowed ? 'Unfollow Artist' : 'Follow Artist',
                action: handleFollowArtist,
            },
            {
                key: 'featured-in',
                icon: 'featuredIn',
                label: 'Featured In',
                action: handleFeatured,
            },
            {
                key: 'find-remixes',
                icon: 'findRemixes',
                label: 'Find Remixes & Edits',
                action: handleFindRemixes,
                disabled: !(remixesAvailable > 0 || remixOfId !== null),
                disabledDataTip: 'None Available',
            },
            {
                key: 'find-similar',
                icon: 'findSimilar',
                label: 'Find Similar',
                action: handleSimilar,
            },
            {
                key: 'edit-playlist',
                icon: 'editPlaylist',
                label: 'Edit Playlist Name',
                action: updatePlaylist,
            },
            {
                key: 'delete-playlist',
                icon: 'deletePlaylist',
                label: 'Delete Playlist',
                action: handleDeletePlaylist,
            },
            {
                key: 'share',
                icon: 'share',
                label: 'Copy Link',
                action: copyUrl,
            },
            {
                key: 'report-track',
                icon: 'reportTrack',
                label: 'Report Track',
                action: handleShowReportMedia,
            },
            {
                key: 'download-version',
                icon: 'download',
                label: 'Download Version',
                action: handleDownloadVersion,
            },
        ],
        [
            handleDeleteTrack,
            handleAddToPlaylist,
            handleAddToQueue,
            handleRemoveFromQueue,
            actionType,
            isMediaInCrate,
            mediaToAdd,
            handleAddToCrate,
            isFavorite,
            handleFavorite,
            isPlaylistFollowed,
            handleFollowPlaylist,
            isCuratedSetFollowed,
            handleFollowCuratedSet,
            isArtistFollowed,
            handleFollowArtist,
            handleFeatured,
            handleFindRemixes,
            remixesAvailable,
            remixOfId,
            handleSimilar,
            updatePlaylist,
            handleDeletePlaylist,
            copyUrl,
            handleShowReportMedia,
            handleDownloadVersion,
        ]
    );

    useEffect(() => {
        if (shareURL && !optionsToShow.includes('share')) {
            optionsToShow.push('share');
        }
    }, [shareURL, optionsToShow]);

    const options = useMemo(() => {
        return allOption.filter((option) => optionsToShow.includes(option.key));
    }, [allOption, optionsToShow]);

    useEffect(() => {
        if (isMobile || renderLocation !== 'app') {
            return;
        }
        if (ref.current && ref.current.children.length > 0) {
            const { width, height } = ref.current.children[0].getBoundingClientRect();
            const { x, y } = getAbsolutePosition(ref.current);
            let deltaX = 0;
            let deltaY = 0;
            if (x - width < 0) {
                deltaX = width - leftPosition;
            }
            if (y + height > window.innerHeight) {
                deltaY = window.innerHeight - (y + height + 60);
            }
            if (Math.abs(deltaX) > 10 || Math.abs(deltaY) > 10) {
                move(leftPosition + deltaX, topPosition + deltaY);
            }
        }
    }, [ref, leftPosition, topPosition, options, move, isMobile, renderLocation]);

    return (
        <div
            ref={ref}
            style={containerStyle}
            className={classNames(styles['three-dots-menu__container'], {
                [styles['three-dots-menu__container--bottom-bar-visible']]: renderLocation === 'notification',
            })}
        >
            <ActionModal
                headerTitle="Report track"
                onClose={() => {
                    setShowReportTrack(false);
                    handleCloseSheet();
                }}
                onConfirm={handleReportMedia}
                confirmButtonText="Report"
                title={`Are you sure you want to report an issue with ${currentTrack?.title}?`}
                isOpen={showReportTrack}
            />
            {openForm ? <PlaylistsForm {...openForm} /> : null}
            <div
                className={classNames(styles['three-dots-menu__list'], styles['three-dots-menu__list--bottom-bar-visible'], {
                    [styles['three-dots-menu__list--open']]: actionTypeId !== -1,
                    [styles['three-dots-menu__list--auto-width']]: showOnlyPlaylist,
                })}
            >
                {showSelectVersionOptions && (
                    <ul className={classNames(styles['three-dots-menu__list--display-option'])} style={leftSideStyle}>
                        {isMobile && (
                            <li className={classNames(styles['three-dots-menu__list-item'], styles['three-dots-menu__list-item__title'])}>
                                <button
                                    type="button"
                                    className={styles['three-dots-menu__list-item__button']}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowSelectVersionOptions(false);
                                    }}
                                >
                                    <BackArrow />
                                    <span>Go Back</span>
                                </button>
                            </li>
                        )}
                        {mediaToAdd?.map((version) => (
                            <li key={version.id} className={styles['three-dots-menu__list-item']}>
                                <button
                                    type="button"
                                    className={styles['three-dots-menu__list-item__button']}
                                    onClick={() => {
                                        selectedVersionId.current = version.id;
                                        selectVersionPostHandler.current();
                                    }}
                                >
                                    <span>{version.version.name}</span>
                                </button>
                            </li>
                        ))}
                    </ul>
                )}
                {isMobile ? (
                    <ul>
                        {!showPlaylistFolders && !showSelectVersionOptions
                            ? options.map((option) => (
                                <Fragment key={option.key}>
                                    <ThreeDotsMenuItem
                                        disabled={option.disabled}
                                        disabledDataTip={option.disabledDataTip}
                                        key={option.key}
                                        legend={option.label}
                                        action={option.action}
                                        icon={option.icon as IconType}
                                    />
                                </Fragment>
                            ))
                            : null}
                    </ul>
                ) : (
                    !showOnlyPlaylist && (
                        <ul>
                            {options.map((option) => (
                                <Fragment key={option.key}>
                                    <ThreeDotsMenuItem
                                        disabled={option.disabled}
                                        disabledDataTip={option.disabledDataTip}
                                        key={option.key}
                                        legend={option.label}
                                        action={option.action}
                                        icon={option.icon as IconType}
                                    />
                                </Fragment>
                            ))}
                        </ul>
                    )
                )}

                {showOnlyPlaylist && <MyPlaylistsCheckboxes mediaIds={mediaIdsToAdd} />}
                {showPlaylistFolders &&
                    (categoryId ? (
                        <MyPlaylistList categoryId={categoryId} addToPlaylist={addToPlaylist} showIcon />
                    ) : (
                        <ul className={classNames(styles['three-dots-menu__list--display-option'])} style={leftSideStyle}>
                            {!showOnlyPlaylist && isMobile ? (
                                <li className={classNames(styles['three-dots-menu__list-item'], styles['three-dots-menu__list-item__title'])}>
                                    <button
                                        type="button"
                                        className={styles['three-dots-menu__list-item__button']}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowPlaylistFolders(false);
                                        }}
                                    >
                                        <BackArrow />
                                        <span>Go Back</span>
                                    </button>
                                </li>
                            ) : null}
                            {!showOnlyPlaylist ? <MyPlaylistsCheckboxes isSubmenu mediaIds={mediaIdsToAdd} /> : null}
                        </ul>
                    ))}
                {isMobile &&
                    (showPlaylistFolders ? (
                        <button
                            type="button"
                            className={styles['three-dots-menu__list-item__button--done-btn']}
                            onClick={() => {
                                handleCloseSheet();
                            }}
                        >
                            Done
                        </button>
                    ) : (
                        <button
                            type="button"
                            className={classNames(styles['three-dots-menu__list-item__button--cancel-btn'])}
                            onClick={() => {
                                handleCloseSheet();
                            }}
                        >
                            Cancel
                        </button>
                    ))}
            </div>
        </div>
    );
}

export default ThreeDotsSheet;
