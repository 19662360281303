import useSWR from 'swr';
import { Playlist, UserPlaylist } from '@bpm-web-app/api-client';
import { useHubSwitch } from '@bpm-web-app/utils';

export function usePlaylists(query: any) {
    const { isDownload } = useHubSwitch();

    const { data, error } = useSWR(
        [
            'stream-get-playlists',
            {
                persist: true,
                isDownload,
                ...query,
            },
        ],
        () => Playlist.getPlaylists(isDownload, query)
    );

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

export function useFeaturedPlaylists(query: any) {
    const { isDownload } = useHubSwitch();

    const { data, error } = useSWR(
        [
            'stream-get-featured-playlists',
            {
                persist: true,
                isDownload,
                ...query,
            },
        ],
        () => Playlist.getFeaturedPlaylists(isDownload, query)
    );

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

type ForYouResponse = Awaited<ReturnType<typeof UserPlaylist['getUserForYouPlaylist']>>;

export function useForYou(library: string) {
    const { isDownload } = useHubSwitch();
    const { data, error } = useSWR<ForYouResponse>(
        ['for-you', { isDownload, library, persist: true }],

        () => UserPlaylist.getUserForYouPlaylist(isDownload, library)
    );

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

export function usePlaylistsByCategoryId(categoryId?: number, library?: string) {
    const { isDownload } = useHubSwitch();

    const { data, error } = useSWR(['get-playlists-by-cat-id', { categoryId, isDownload, library, persist: true }], () => Playlist.getPlaylistByCategoryId(isDownload, categoryId!, library), {
        isPaused: () => categoryId === undefined
    });

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

type PlaylistResponse = Awaited<ReturnType<typeof Playlist['getPlaylistDetail']>>;

export function usePlaylistDetail(playlistId: number, disabled?: boolean) {
    const { isDownload } = useHubSwitch();

    const { data, error, mutate } = useSWR<PlaylistResponse>(['playlist-detail', { persist: true, isDownload, playlistId }], () => Playlist.getPlaylistDetail(isDownload, playlistId), {
        isPaused: () => !!disabled,
    });

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}

export function usePlaylistCategories(library: string) {
    const { isDownload } = useHubSwitch();

    const { data, error } = useSWR(
        [
            'playlist-categories',
            {
                persist: true,
                isDownload,
                library,
            },
        ],
        () => Playlist.getPlaylistCategories(isDownload, library)
    );

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

export function getFollowedPlaylists(library?: string) {
    const { isDownload } = useHubSwitch();

    const { data, error, mutate } = useSWR(
        [
            'get-followed-playlists',
            {
                persist: true,
                isDownload,
                library,
            },
        ],
        () => Playlist.getFollowedPlaylists(isDownload, library)
    );

    return {
        data,
        mutate,
        isLoading: !error && !data,
        error,
    };
}
