import { useHubSwitch, useUserSettings } from '@bpm-web-app/utils';
import { CustomIcon } from '../custom-icon/custom-icon';

interface ThreeDotsButtonProps {
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    hasTooltip?: boolean;
    avoidCheckingIfAnonymous?: boolean;
}

export function ThreeDotsButton({ onClick, hasTooltip, avoidCheckingIfAnonymous }: ThreeDotsButtonProps) {
    const { isAnonymous, setShowSignUpModal } = useUserSettings();
    const { isCreate } = useHubSwitch();

    const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        if (isAnonymous && avoidCheckingIfAnonymous !== true) {
            setShowSignUpModal({ type: isCreate ? 'pack' : 'track' });
        } else if (onClick) onClick(e);
    };
    if (hasTooltip) {
        return (
            <CustomIcon type="three-dots" className="three-dots-button" hasIconHover onClick={handleOnClick} tooltip="More" hasBackgroundHover container="round" containerSize="regular" />
        );
    }

    return (
        <CustomIcon type="three-dots" className="three-dots-button" hasIconHover onClick={handleOnClick} />
    );
}
