import { Device } from '@bpm-web-app/api-client';

import useSWR from 'swr';

export function useGetDevices() {
    const { data, error, mutate } = useSWR(
        [
            'supreme-get-devices',
            {
                persist: false,
            },
        ],
        () => Device.getDevices()
    );

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}

export function useGetAuthorizedDevices(platform: 'supreme' | 'create') {
    const { data, error, mutate } = useSWR(
        [
            'supreme-get-authorized-devices',
            {
                persist: true,
            },
        ],
        () => Device.getAuthorizedDevices(platform)
    );

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}
