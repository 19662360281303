import useSWR from 'swr';
import { DownloadAlbum, Media, Album, SearchQuery } from '@bpm-web-app/api-client';
import { useHubSwitch } from '@bpm-web-app/utils';

export function useAlbumById(albumId: number, enabled = true) {
    const { isDownload } = useHubSwitch();
    const { data, error, mutate } = useSWR<Awaited<ReturnType<typeof Media['getAlbum']>>>([`stream-get-album-by-id-${albumId}`, { persist: true }], () => Media.getAlbum(isDownload, albumId), {
        isPaused: () => !enabled || !albumId,
    });

    return {
        data,
        mutate,
        isLoading: !error && !data && Boolean(albumId),
        error,
    };
}

type StreamFavoriteAlbums = Awaited<ReturnType<typeof Album['getFavoriteAlbums']>>;
type DownloadFavoriteAlbums = Awaited<ReturnType<typeof DownloadAlbum['getFavAlbums']>>;

export function getFavoriteAlbums(library?: string, enabled = true) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { isDownload } = useHubSwitch();

    const { data, error, mutate } = useSWR<StreamFavoriteAlbums | DownloadFavoriteAlbums>(
        [
            'get-favorite-albums',
            {
                isDownload,
                persist: true,
                library,
            },
        ],
        () => Album.getFavoriteAlbums(isDownload, library),
        { isPaused: () => !enabled }
    );

    return {
        data,
        mutate,
        isLoading: !error && !data,
        error,
    };
}

export function useAlbumCount(query: SearchQuery) {
    const { isDownload } = useHubSwitch();
    const { data, error, mutate } = useSWR<Awaited<ReturnType<typeof Media['countAlbums']>>>(['get-album-count', {
        isDownload,
        persist: true,
        query
    }], () => Media.countAlbums(isDownload, query));

    return {
        data,
        mutate,
        isLoading: !error && !data,
        error,
    };
}

export function useGetAlbumFeaturedIn(id?: number, library?: string) {
    // Using SWR's conditional fetching feature
    if (id) {
        const { data, error } = useSWR<Awaited<ReturnType<typeof Album['getAlbumFeaturedIn']>>>(
            [`get-album-featured-in-${id}`, {
                persist: true,
                id,
                library
            }],
            () => Album.getAlbumFeaturedIn(id, library)
        );
        return {
            data: data?.data,
            isLoading: !error && !data,
            error,
        };
    }
    const { data, error } = useSWR(null, null);
    return {
        data: data?.data,
        isLoading: !error && !data,
        error,
    };
}
