import classNames from 'classnames';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { DriveConnections, DriveOwner, Sound, UserDriveCollaboration } from '@bpm-web-app/create-api-sdk';
import { usePlaySoundList, useUserSettings } from '@bpm-web-app/utils';
import DragSelect from 'dragselect';
import styles from './track-list-create.module.css';
import { Checkbox } from '../../ui/checkbox/checkbox';
import SortArrowDown from '../../../../assets/icons/create/sort-arrow-down.svg';
import SortArrowUp from '../../../../assets/icons/create/sort-arrow-up.svg';

import { TrackListCreateItem } from './track-list-create-item';
import { useCreatePlayer } from '../../../../../../utils/src/lib/create-player.context';
import { LoadMore } from '../../load-more/load-more';

import { CreateThreeDotsSheetContext } from '../../three-dots-sheet/create-three-dots-sheet.context';
import SecondaryPageTitle from '../../secondary-page-title/secondary-page-title';
import { TagsView } from '../../../tags-view/tags-view';
import SeeMore from '../../see-more-button/see-more-btn';
import { TagsViewGhost } from '../../../tags-view/tags-view-ghost';
import { TrackListGhostLoading } from '../ghost-loading/track-list-ghost-loading';
import { NoResultsBlock } from '../../ui';

type CreateSortByMain = 'date' | 'title' | 'bpm' | 'relevance';

interface TrackListBaseProps {
    mutateSound?: (id: string, duration: number) => void;
    list?: Array<Sound>;
    hideHeading?: boolean;
    isLoading?: boolean;
    isLoadingMore?: boolean;
    onLoadMore?: () => void;
    hideTagColumn?: boolean;
    hidePackName?: boolean;
    isDriveDetailPage?: boolean;
    hasEditAccess?: boolean;
    title?: string
    showTagFilter?: boolean
    onSeeMorePressed?: () => void;
    collaborators?: UserDriveCollaboration[];
    connections?: DriveConnections[]
    owner?: DriveOwner;
    hideNotAvailableBlock?: boolean;
}

interface TrackListTagFilterProps {
    showTagFilter: true;
    tags: string[];
    selectedTags: string[];
    onToggleTag: (tag: string, toggleOn: boolean) => void;
}

interface TrackListNoTagFilterProps {
    showTagFilter?: false;
    tags?: never
    selectedTags?: never
    onToggleTag?: never
}

interface TrackListNonSelectableProps extends TrackListBaseProps {
    onSelectItem?: never;
    selectedItemsIds?: never;
    onSelectAll?: never;
}

interface TrackListSelectableProps extends TrackListBaseProps {
    /** Sets the TrackList with the selectable variant */
    onSelectItem: (soundId: string, index: number) => void;
    selectedItemsIds: string[];
    onSelectAll: (checked: boolean) => void;
}

export type TrackListCreateProps = (TrackListNonSelectableProps | TrackListSelectableProps) & (TrackListTagFilterProps | TrackListNoTagFilterProps);

export function TrackListCreate({
    title,
    mutateSound,
    list: propsList,
    onSelectItem,
    selectedItemsIds,
    onSelectAll,
    isLoading = false,
    isLoadingMore = false,
    hideHeading = false,
    onLoadMore,
    hideTagColumn = false,
    hidePackName = false,
    isDriveDetailPage = false,
    hasEditAccess,
    showTagFilter = false,
    tags,
    selectedTags,
    onToggleTag,
    onSeeMorePressed,
    collaborators,
    connections,
    owner,
    hideNotAvailableBlock
}: TrackListCreateProps) {
    const { setCurrentSelectedSoundId, currentSelectedSoundType, currentSelectedSoundId, setCurrentSoundId } = useCreatePlayer();

    const [list, setList] = useState(propsList);
    const listRef = useRef(list);
    const [fadeOut, setFaceOut] = useState(false);
    const currentFadeoutTimer = useRef<NodeJS.Timeout>();
    const { isAnonymous, setSelectedMedia, setShowSignUpModal } = useUserSettings();

    const setCurrentMediaInContext = useCallback((id: string, showSignUpModal?: boolean) => {
        if (isAnonymous) {
            const currentCenterMedia = list?.find((item) => `${item.id}` === id);
            if (currentCenterMedia as Sound) setSelectedMedia(currentCenterMedia as Sound, list as Sound[]);

            if (showSignUpModal) {
                setShowSignUpModal({ type: 'sound' });
            }
        }
    }, [isAnonymous, list, setSelectedMedia, setShowSignUpModal]);

    useEffect(() => {
        if (currentFadeoutTimer) { clearTimeout(currentFadeoutTimer.current as unknown as number); }
        if (!propsList || propsList.length === 0) {
            setFaceOut(true);
            currentFadeoutTimer.current = setTimeout(() => {
                setFaceOut(false);
                setList(propsList);
            }, 50);
        } else {
            setFaceOut(false);
            setList(propsList);
        }
    }, [propsList]);

    useEffect(() => {
        listRef.current = list;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list?.length]);

    useEffect(() => {
        setCurrentSoundId(undefined);
        setCurrentSelectedSoundId(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (propsList as Sound[] && (propsList as Sound[])[0] && (propsList as Sound[])[0].id) setCurrentMediaInContext(`${(propsList as Sound[])[0].id}`, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAnonymous]);

    const dragSelect = useRef<DragSelect>();

    const playSoundListContext = usePlaySoundList('sound', list || []);
    const { play } = playSoundListContext;

    /** useEffect(() => {
        if (isQualityControlActive && onSelectItem) {
         dragSelect.current = new DragSelect({
            });
            dragSelect.current.subscribe('callback', ({ items, ...key }: CallbackObject) => {
                (items || []).forEach((e) => {
                    if (e.dataset.soundId) {
                        const soundIndex = (listRef.current || []).findIndex((s) => s.id === e.dataset.soundId);
                        if (soundIndex > -1) {
                            onSelectItem(e.dataset.soundId, soundIndex);
                        }
                    }
                });
                if (items?.length) {
                    if (dragSelect.current) {
                        dragSelect.current.clearSelection(false);
                    }
                }
            });
        }
        return () => {
            dragSelect.current?.unsubscribe('callback');
            dragSelect.current?.stop(true);
        };
    }, [isQualityControlActive, onSelectItem]); * */

    const { actionTypeId } = useContext(CreateThreeDotsSheetContext);

    const handleUserKeyPress = useCallback(
        async (event) => {
            const { key } = event;

            if (actionTypeId === -1 && list) {
                if (currentSelectedSoundId !== undefined && currentSelectedSoundType === 'preset') {
                    return;
                }
                const selectedTrackIndex = list.findIndex((sound) => sound.id === currentSelectedSoundId);

                if (key === 'ArrowDown' && list.length > selectedTrackIndex + 1) {
                    event.preventDefault();
                    play(selectedTrackIndex + 1);
                } else if (key === 'ArrowUp' && selectedTrackIndex > 0) {
                    event.preventDefault();
                    play(selectedTrackIndex - 1);
                }
            }
        },
        [actionTypeId, list, currentSelectedSoundId, currentSelectedSoundType, play]
    );

    useEffect(() => {
        window.addEventListener('keydown', handleUserKeyPress);

        return () => {
            window.removeEventListener('keydown', handleUserKeyPress);
        };
    }, [handleUserKeyPress]);

    const selectAllCheckbox = useMemo(
        () => (
            <Checkbox
                checked={!!selectedItemsIds?.length && selectedItemsIds?.length === list?.length}
                onChange={(e) => {
                    if (onSelectAll) onSelectAll(e.target.checked);
                }}
            />
        ),
        [list?.length, onSelectAll, selectedItemsIds?.length]
    );

    const { trackListSortBy, setTrackListSortBy } = useCreatePlayer();

    const toggleSortBy = useCallback(
        (sortBy: CreateSortByMain) => {
            switch (sortBy) {
                case 'relevance': {
                    setTrackListSortBy('relevance');
                    break;
                }

                default: {
                    if (trackListSortBy === `${sortBy}_asc`) {
                        setTrackListSortBy(`${sortBy}_desc`);
                        break;
                    }
                    setTrackListSortBy(`${sortBy}_asc`);
                    break;
                }
            }
        },
        [setTrackListSortBy, trackListSortBy]
    );
    const renderTrackItem = useCallback((index: number) => {
        const connection = connections?.find((c) => c.sound_id === (list![index] as Sound).id);
        const collaborator = connection?.added_by_key ? collaborators?.find((current) => current.id === connection?.added_by_key) : undefined;
        const isLastItem = index === list!.length - 1;
        return (
            onSelectItem ? (
                <TrackListCreateItem
                    setCurrentMediaInContext={setCurrentMediaInContext}
                    sound={list![index]}
                    updateSoundLocal={(sound) => {
                        list![index] = sound;
                        setList(list?.slice());
                    }}
                    playContext={playSoundListContext}
                    key={list![index].id}
                    soundIndex={index}
                    onSelectItem={onSelectItem}
                    selectedItemsIds={selectedItemsIds}
                    hideTagColumn={hideTagColumn}
                    hidePackName={hidePackName}
                    isDriveDetailPage={isDriveDetailPage}
                    hasEditAccess={isDriveDetailPage || (hasEditAccess && connection?.is_own)}
                    mutateSound={mutateSound}
                    collaborator={collaborator}
                    isSharedDrive={collaborators && collaborators?.length > 0}
                    owner={owner}
                    addedAt={connection?.added_at}
                    isLastItem={isLastItem}
                    dragSelect={dragSelect.current}
                />
            ) : (
                <TrackListCreateItem
                    setCurrentMediaInContext={setCurrentMediaInContext}
                    sound={list![index]}
                    updateSoundLocal={(sound) => {
                        list![index] = sound;
                        setList(list?.slice());
                    }}
                    playContext={playSoundListContext}
                    key={list![index].id}
                    soundIndex={index}
                    hideTagColumn={hideTagColumn}
                    hidePackName={hidePackName}
                    isDriveDetailPage={isDriveDetailPage}
                    hasEditAccess={isDriveDetailPage || (hasEditAccess && connection?.is_own)}
                    mutateSound={mutateSound}
                    collaborator={collaborator}
                    isSharedDrive={collaborators && collaborators?.length > 0}
                    owner={owner}
                    addedAt={connection?.added_at}
                    isLastItem={isLastItem}
                    dragSelect={dragSelect.current}
                />
            )
        );
    }, [collaborators, connections, hasEditAccess, hidePackName, hideTagColumn,
        isDriveDetailPage, list, mutateSound, onSelectItem, owner, selectedItemsIds, setCurrentMediaInContext, playSoundListContext]);

    if (isLoading) {
        return (
            <>
                {title ? <SecondaryPageTitle noPadding title={title} /> : null}
                {showTagFilter && (
                    <>
                        <div className="spacing--top" />
                        <TagsViewGhost large={false} key="tags-view-small-ghost" />
                        <div className="spacing--top" />
                    </>
                )}
                {hideHeading ? null : (
                    <div
                        className={classNames(styles['track-list-create__list-heading'], {
                            [styles['track-list-create__list-heading--sortable']]: false,
                        })}
                    >
                        <div className={classNames(styles['track-list-create__heading-column'], styles['track-list-create__heading-column--count'])}>{onSelectItem ? selectAllCheckbox : null}</div>
                        <button
                            type="button"
                            aria-label="sort by title"
                            className={classNames(styles['track-list-create__heading-column'], styles['track-list-create__heading-column--title'])}
                            onClick={() => toggleSortBy('title')}
                        >
                            Title
                            {trackListSortBy === 'title_asc' ? <SortArrowUp /> : trackListSortBy === 'title_desc' && <SortArrowDown />}
                        </button>
                        <button
                            type="button"
                            aria-label="sort by bpm"
                            className={classNames(styles['track-list-create__heading-column'], styles['track-list-create__heading-column--bpm'])}
                            onClick={() => toggleSortBy('bpm')}
                        >
                            BPM
                            {trackListSortBy === 'bpm_asc' ? <SortArrowUp /> : trackListSortBy === 'bpm_desc' && <SortArrowDown />}
                        </button>
                        <div className={classNames(styles['track-list-create__heading-column'], styles['track-list-create__heading-column--key'])}>
                            Key
                        </div>
                        <div className={classNames(styles['track-list-create__heading-column'], styles['track-list-create__heading-column--time'])}>Time</div>
                        {!hideTagColumn && <div className={classNames(styles['track-list-create__heading-column'], styles['track-list-create__heading-column--tags'])}>Tags</div>}
                    </div>
                )}
                <div className="spacing--top" />
                <TrackListGhostLoading amount={10} isCreate />
            </>
        );
    }

    return (
        <div className={classNames(styles['track-list-create'])}>
            {title && <SecondaryPageTitle title={title} noPadding />}
            {showTagFilter && (
                <div className={classNames(styles['track-list-create__tag-view'])}>
                    <TagsView tags={tags!} selected={selectedTags!} onToggleTag={onToggleTag!} />
                </div>
            )}
            {hideHeading ? null : (
                <div
                    className={classNames(styles['track-list-create__list-heading'], {
                        [styles['track-list-create__list-heading--sortable']]: false,
                    })}
                >
                    <div className={classNames(styles['track-list-create__heading-column'], styles['track-list-create__heading-column--count'])}>{onSelectItem ? selectAllCheckbox : null}</div>
                    <button
                        type="button"
                        aria-label="sort by title"
                        className={classNames(styles['track-list-create__heading-column'], styles['track-list-create__heading-column--title'])}
                        onClick={() => toggleSortBy('title')}
                    >
                        Title
                        {trackListSortBy === 'title_asc' ? <SortArrowUp /> : trackListSortBy === 'title_desc' && <SortArrowDown />}
                    </button>
                    <button
                        type="button"
                        aria-label="sort by bpm"
                        className={classNames(styles['track-list-create__heading-column'], styles['track-list-create__heading-column--bpm'])}
                        onClick={() => toggleSortBy('bpm')}
                    >
                        BPM
                        {trackListSortBy === 'bpm_asc' ? <SortArrowUp /> : trackListSortBy === 'bpm_desc' && <SortArrowDown />}
                    </button>
                    <div className={classNames(styles['track-list-create__heading-column'], styles['track-list-create__heading-column--key'])}>
                        {/* TODO: potentially add sorting by column */}
                        Key
                    </div>
                    <div className={classNames(styles['track-list-create__heading-column'], styles['track-list-create__heading-column--time'])}>Time</div>
                    {!hideTagColumn && <div className={classNames(styles['track-list-create__heading-column'], styles['track-list-create__heading-column--tags'])}>Tags</div>}
                </div>
            )}
            {isLoading === false && list && list?.length > 0 ? (
                <>
                    <div className={classNames(styles['track-list-create__list'], { [styles['track-list-create__list--fadeOut']]: fadeOut })}>
                        {list?.map((sound, soundIndex) => {
                            return renderTrackItem(soundIndex);
                        })}
                        {onLoadMore && <LoadMore onLoadMore={onLoadMore} isLoadingMore={isLoadingMore} />}
                    </div>

                    {onSeeMorePressed ? <SeeMore onClick={onSeeMorePressed} expand={false} variant="text" /> : null}

                </>
            )
                : hideNotAvailableBlock ? null : (
                    <NoResultsBlock>{title ? `No ${title} Available` : 'Not Available'}</NoResultsBlock>
                )}
        </div>
    );
}

export default TrackListCreate;
