import { SubscriptionPackage } from '@bpm-web-app/supreme-api-sdk';
import { useRouter } from 'next/router';
import ArrowBackIcon from '../../../assets/icons/supreme/arrow-left.svg';
import { AppLink } from '../../shared';
import { Account } from '../account';
import { PageHeader } from '../components/page-header/page-header';
import styles from './cancel-plan.module.css';
import { CancelPlanOptions } from './options';

interface CancelPlanProps {
    title: string;
    platform: 'supreme' | 'stream' | 'create';
}

// TODO: all platforms
export function CancelPlan({ title, platform }: CancelPlanProps) {
    const router = useRouter();
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const packageId = router.query['packageId'] as string;

    return (
        <Account title={title}>
            <PageHeader title="Is this goodbye or see you later?" />
            <CancelPlanOptions platform={platform as unknown as SubscriptionPackage.PlatformEnum} packageId={packageId} />
            <AppLink href="/account/overview">
                <a className={styles['cancel-plan__back-link']}>
                    <ArrowBackIcon />
                    Nevermind, I want to keep my subscription
                </a>
            </AppLink>

        </Account>
    );
}
