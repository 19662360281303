import classNames from 'classnames';
import { Fragment } from 'react';
import { useUniqueArray } from '@bpm-web-app/utils';
import styles from './list-grid.module.css';
import GhostElement, { GhostElementProps } from '../../ghost-element/ghost-element';

export interface ListGridGhostLoadingProps extends GhostElementProps {
    elementsCount: number;
}

export function ListGridGhostLoading({ elementsCount, ...ghostElementProps }: ListGridGhostLoadingProps) {
    const ghostElementsKeys = useUniqueArray(elementsCount);

    return (
        <div className={classNames(styles['list-grid'], styles['list-grid--ghost-loading'])}>
            {ghostElementsKeys.map((uniqueKey) => (
                <Fragment key={uniqueKey}>
                    <GhostElement {...ghostElementProps} isRoundImage squareWidth={ghostElementProps.squareWidth || 56} linesWidthArray={ghostElementProps.linesWidthArray || [90, 70]} />
                </Fragment>
            ))}
        </div>
    );
}
