/* based on our switch component modified for react form hook */
import { ChangeEventHandler } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import styles from './settings-switch.module.css';

export interface SettingsSwitchProps {
    register?: UseFormRegisterReturn;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    isChecked?: boolean;
}

export function SettingsSwitch({ register, onChange, isChecked }: SettingsSwitchProps) {
    return (
        <label className={styles['settings-switch']}>
            <input type="checkbox" onChange={onChange} {...register} checked={isChecked} className={styles['settings-switch__input']} />
            <div className={styles['settings-switch__slider']} />
        </label>
    );
}

export default SettingsSwitch;
