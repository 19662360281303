import classNames from 'classnames';
import { Tag } from '@bpm-web-app/create-api-sdk';
import { useState } from 'react';
import styles from './tags-view.module.css';
import { QualityControlInput } from '../shared';
import { ActionModal } from '../shared/action-modal/action-modal';
import { TagsViewGhost } from './tags-view-ghost';

interface TagsViewProps {
    tags: string[]
    selected: string[]
    onToggleTag: (tag: string, toggleOn: boolean) => void
    updateTagsData?: (tags: Tag[]) => void
    large?: boolean
    className?: string
    orderBySelected?: boolean
    isQualityControlActive?: boolean;
    tagsData?: Tag[];
    soundId?: string;
    isLoading?: boolean;
}

export function TagsView({ tags,
    selected,
    onToggleTag: toggleTag,
    large,
    className,
    orderBySelected = true,
    isQualityControlActive = false,
    tagsData,
    soundId,
    updateTagsData,
    isLoading
}: TagsViewProps) {
    const [showAllTags, setShowAllTags] = useState(false);
    const sortedArray: string[] = isQualityControlActive ? tags.slice(0, 2) : tags.slice();
    if (orderBySelected) {
        sortedArray.sort((tag) => {
            if (selected.includes(tag)) {
                return -1;
            }
            return 1;
        });
    }

    if (isLoading) {
        <TagsViewGhost large={large} key="tags-view-ghost" />;
    }

    return (
        <div className={classNames(styles['tags-view--tags'], className, { [styles['tags-view--tags-large']]: large })}>
            {sortedArray.map((tag) => (
                !isQualityControlActive ? (
                    <button
                        key={tag}
                        className={classNames(styles['tags-view__tag'], { [styles['tags-view__tag-large']]: large, [styles['tags-view__tag__selected']]: selected.includes(tag) })}
                        onClick={() => toggleTag(tag, !selected.includes(tag))}
                        type="button">
                        {tag}
                    </button>
                ) : (
                    <QualityControlInput key={tag} isCreate inputType="tag" hasCross text={tag || undefined} tagsData={tagsData} associatedId={soundId} updateTagsData={updateTagsData} />
                )
            ))}
            {isQualityControlActive ? (
                <QualityControlInput inputType="add-tag" isCreate tagsData={tagsData} associatedId={soundId} updateTagsData={updateTagsData} />
            ) : null}
            {isQualityControlActive && tags.length > 2 ? (
                <div role="button" onKeyDown={(e) => e.stopPropagation()} tabIndex={0} onClick={() => setShowAllTags(true)} className={classNames(styles['tags-view__view-all-text'])}>{`+${tags.length - 2} View all`}</div>
            ) : null}

            <ActionModal
                headerTitle="All Tags"
                confirmButtonText="Continue"
                hideCancel
                onClose={() => {
                    setShowAllTags(false);
                }}
                variant="dark"
                isOpen={showAllTags}
            >
                <div className={styles['tags-view__view-all-tags']}>
                    {tags.map((tag) => (
                        <QualityControlInput key={tag} isCreate inputType="tag" hasCross text={tag || undefined} tagsData={tagsData} associatedId={soundId} updateTagsData={updateTagsData} />
                    ))}
                </div>
            </ActionModal>
        </div>
    );
}
