import { useCallback, useEffect, useState, useRef } from 'react';
import debounce from 'lodash/debounce';
import { DebouncedFunc, DebounceSettings } from 'lodash';

export function useDebouncedCallback<T extends (...args: any[]) => any>(
    callback: T,
    delay = 0,
    options?: DebounceSettings
): DebouncedFunc<T> {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback(debounce(callback, delay, options), [
        callback,
        delay,
        options,
    ]);
}

export function useDebounce<T>(
    value: T,
    delay = 0,
    options?: DebounceSettings
): T {
    const previousValue = useRef(value);
    const [current, setCurrent] = useState(value);
    const debouncedCallback = useDebouncedCallback((newValue: T) => setCurrent(newValue),
        delay,
        options
    );
    useEffect(() => {
        // doesn't trigger the debounce timer initially
        if (value !== previousValue.current) {
            debouncedCallback(value);
            previousValue.current = value;
            // cancel the debounced callback on clean up
            return debouncedCallback.cancel;
        }
        return () => { };
    }, [value]);

    return current;
}
