import { useMemo } from 'react';
import classNames from 'classnames';
import { ApplicationSectionHeader, ApplicationSectionTitle } from '../../artist-portal/artist-application/artist-application-text/artist-application-text';
import styles from './artist-detail-info.module.css';

import LinkIcon from '../../../../assets/icons/link-icon.svg';

interface ArtistDetailInfoProps {
    bio: string;
    links: string[];
}

export function ArtistDetailInfo({ bio = '', links = [] }: ArtistDetailInfoProps) {
    const renderLinks = useMemo(() => {
        return links?.map((link, index) => {
            return (
                link === ''
                    ? null
                    :
                    <div className={styles['artist-detail-info--link-row']}>
                        <a className={styles['artist-detail-info--link-img']}><LinkIcon /></a>
                        <a className={styles['artist-detail-info--link-data']}>{link}</a>
                    </div>
            );
        });
    }, [links]);

    return (
        <div className={classNames(styles['artist-detail-info'], 'spacing--left')}>
            <div className={styles['artist-detail-info--bio-container']}>
                <ApplicationSectionTitle title="Artist Bio" />
                {bio !== '' && bio !== undefined && bio !== null ?
                    <ApplicationSectionHeader subtitle={bio} />
                    : <ApplicationSectionHeader subtitle="No bio description." />}
            </div>

            <div className={styles['artist-detail-info--links-container']}>
                <ApplicationSectionTitle title="Social Links" />
                {links !== null && links.length > 0 && links !== undefined ?

                    renderLinks
                    : <ApplicationSectionHeader subtitle="No social media links." />}
            </div>

        </div>
    );
}
