/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * BPM Stream
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.4.6
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from 'url';
import * as isomorphicFetch from 'isomorphic-fetch';
import { Configuration } from './configuration';

const BASE_PATH = 'https://api.develop.stream.bpmsupreme.com/v1'.replace(/\/+$/, '');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ',',
    ssv: ' ',
    tsv: '\t',
    pipes: '|',
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = isomorphicFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: 'RequiredError'
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface Album
 */
export interface Album {
    /**
     * Unique Entity Id
     * @type {number}
     * @memberof Album
     */
    id: number;
    /**
     * Entity creation date
     * @type {string}
     * @memberof Album
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof Album
     */
    updated_at: string;
    /**
     * Combined artist string of the album. Please see artists for detailed information
     * @type {string}
     * @memberof Album
     */
    artist: string;
    /**
     * Title of the album
     * @type {string}
     * @memberof Album
     */
    title: string;
    /**
     * Subtitle of the album
     * @type {string}
     * @memberof Album
     */
    sub_title?: string;
    /**
     * Cover (Artwork) URL of the album
     * @type {string}
     * @memberof Album
     */
    cover_url: string;
    /**
     * Cover Main Color
     * @type {string}
     * @memberof Album
     */
    cover_color?: string;
    /**
     * Full name of album
     * @type {string}
     * @memberof Album
     */
    name: string;
    /**
     * Tracks in this album
     * @type {Array<Media>}
     * @memberof Album
     */
    media: Array<Media>;
    /**
     * 
     * @type {Category}
     * @memberof Album
     */
    category: Category;
    /**
     * 
     * @type {Genre}
     * @memberof Album
     */
    genre: Genre;
    /**
     * Artists of this album
     * @type {Array<Artist>}
     * @memberof Album
     */
    artists: Array<Artist>;
    /**
     * Libraries this album is available in
     * @type {Array<Library>}
     * @memberof Album
     */
    libraries: Array<Library>;
    /**
     * Daily Trending status. Negative number if status went down, positive number if status went up
     * @type {number}
     * @memberof Album
     */
    trending_status_daily: number;
    /**
     * Weekly Trending status. Negative number if status went down, positive number if status went up
     * @type {number}
     * @memberof Album
     */
    trending_status_weekly: number;
    /**
     * Monthly Trending status. Negative number if status went down, positive number if status went up
     * @type {number}
     * @memberof Album
     */
    trending_status_monthly: number;
    /**
     * Set if the album is a exclusive album
     * @type {boolean}
     * @memberof Album
     */
    is_exclusive: boolean;
    /**
     * If current album is restricted based on current location
     * @type {boolean}
     * @memberof Album
     */
    is_restricted: boolean;
}
/**
 * 
 * @export
 * @interface Artist
 */
export interface Artist {
    /**
     * Unique Entity Id
     * @type {number}
     * @memberof Artist
     */
    id: number;
    /**
     * Entity creation date
     * @type {string}
     * @memberof Artist
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof Artist
     */
    updated_at: string;
    /**
     * Artist name
     * @type {string}
     * @memberof Artist
     */
    name: string;
    /**
     * Artist bio
     * @type {string}
     * @memberof Artist
     */
    description?: string;
    /**
     * Artist slug
     * @type {string}
     * @memberof Artist
     */
    slug: string;
    /**
     * Artist social media links
     * @type {Array<string>}
     * @memberof Artist
     */
    social_media?: Array<string>;
    /**
     * Artist sequence number in display artist name. Only returned when assocaiated to media or album.
     * @type {number}
     * @memberof Artist
     */
    sequence_number?: number;
    /**
     * Artist banner URL
     * @type {string}
     * @memberof Artist
     */
    banner_url?: string;
    /**
     * Artist artwork URL
     * @type {string}
     * @memberof Artist
     */
    artwork_url?: string;
    /**
     * Artist artwork credit
     * @type {string}
     * @memberof Artist
     */
    artwork_credit?: string;
}
/**
 * 
 * @export
 * @interface ArtistWithMediaCount
 */
export interface ArtistWithMediaCount {
    /**
     * Unique Entity Id
     * @type {number}
     * @memberof ArtistWithMediaCount
     */
    id: number;
    /**
     * Entity creation date
     * @type {string}
     * @memberof ArtistWithMediaCount
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof ArtistWithMediaCount
     */
    updated_at: string;
    /**
     * Artist name
     * @type {string}
     * @memberof ArtistWithMediaCount
     */
    name: string;
    /**
     * Artist bio
     * @type {string}
     * @memberof ArtistWithMediaCount
     */
    description?: string;
    /**
     * Artist slug
     * @type {string}
     * @memberof ArtistWithMediaCount
     */
    slug: string;
    /**
     * Artist social media links
     * @type {Array<string>}
     * @memberof ArtistWithMediaCount
     */
    social_media?: Array<string>;
    /**
     * Artist sequence number in display artist name
     * @type {number}
     * @memberof ArtistWithMediaCount
     */
    sequence_number: number;
    /**
     * Artist banner URL
     * @type {string}
     * @memberof ArtistWithMediaCount
     */
    banner_url?: string;
    /**
     * Artist artwork URL
     * @type {string}
     * @memberof ArtistWithMediaCount
     */
    artwork_url?: string;
    /**
     * Amount of media this artist has
     * @type {number}
     * @memberof ArtistWithMediaCount
     */
    media_count: number;
}
/**
 * 
 * @export
 * @interface Banner
 */
export interface Banner {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof Banner
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof Banner
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof Banner
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    location: string;
    /**
     * 
     * @type {Array<BannerImage>}
     * @memberof Banner
     */
    images: Array<BannerImage>;
}
/**
 * 
 * @export
 * @interface BannerImage
 */
export interface BannerImage {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof BannerImage
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof BannerImage
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof BannerImage
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof BannerImage
     */
    image_url_large?: string;
    /**
     * 
     * @type {string}
     * @memberof BannerImage
     */
    image_url_small?: string;
    /**
     * 
     * @type {string}
     * @memberof BannerImage
     */
    video_url_large?: string;
    /**
     * 
     * @type {string}
     * @memberof BannerImage
     */
    video_url_small?: string;
    /**
     * 
     * @type {string}
     * @memberof BannerImage
     */
    link?: string;
    /**
     * 
     * @type {number}
     * @memberof BannerImage
     */
    position: number;
    /**
     * 
     * @type {string}
     * @memberof BannerImage
     */
    banner_id: string;
}
/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * Unique Entity Id
     * @type {number}
     * @memberof Category
     */
    id: number;
    /**
     * Entity creation date
     * @type {string}
     * @memberof Category
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof Category
     */
    updated_at: string;
    /**
     * Category name
     * @type {string}
     * @memberof Category
     */
    name: string;
    /**
     * Category slug
     * @type {string}
     * @memberof Category
     */
    slug: string;
    /**
     * The category icon, as svg
     * @type {string}
     * @memberof Category
     */
    icon_svg?: string;
    /**
     * Order of the Category. Ascending.
     * @type {number}
     * @memberof Category
     */
    order: number;
}
/**
 * 
 * @export
 * @interface CsvimportBody
 */
export interface CsvimportBody {
    /**
     * 
     * @type {Blob}
     * @memberof CsvimportBody
     */
    file: Blob;
}
/**
 * 
 * @export
 * @interface CuratedSet
 */
export interface CuratedSet {
    /**
     * Unique Entity Id
     * @type {number}
     * @memberof CuratedSet
     */
    id: number;
    /**
     * Entity creation date
     * @type {string}
     * @memberof CuratedSet
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof CuratedSet
     */
    updated_at: string;
    /**
     * Title of the curated set
     * @type {string}
     * @memberof CuratedSet
     */
    title: string;
    /**
     * Slug of the curated set
     * @type {string}
     * @memberof CuratedSet
     */
    slug: string;
    /**
     * Curator of the curated set
     * @type {string}
     * @memberof CuratedSet
     */
    curator: string;
    /**
     * Order of the curated set within the Category. Ascending.
     * @type {number}
     * @memberof CuratedSet
     */
    order: number;
    /**
     * Cover (Artwork) URL of the curated set
     * @type {string}
     * @memberof CuratedSet
     */
    image_url?: string;
    /**
     * The category id
     * @type {number}
     * @memberof CuratedSet
     */
    category_id: number;
    /**
     * 
     * @type {CuratedSetCategory}
     * @memberof CuratedSet
     */
    category: CuratedSetCategory;
    /**
     * 
     * @type {Library}
     * @memberof CuratedSet
     */
    library: Library;
    /**
     * Artists of the curated set (first 6)
     * @type {Array<Artist>}
     * @memberof CuratedSet
     */
    artists?: Array<Artist>;
    /**
     * Artist string of the curated set (first 6). Please see artists for further information
     * @type {string}
     * @memberof CuratedSet
     */
    artist: string;
    /**
     * Optional youtube URL
     * @type {string}
     * @memberof CuratedSet
     */
    youtube_url?: string;
    /**
     * Optional description
     * @type {string}
     * @memberof CuratedSet
     */
    description?: string;
    /**
     * Weekly Trending status. Negative number if status went down, positive number if status went up
     * @type {number}
     * @memberof CuratedSet
     */
    trending_status_weekly: number;
}
/**
 * 
 * @export
 * @interface CuratedSetCategory
 */
export interface CuratedSetCategory {
    /**
     * Unique Entity Id
     * @type {number}
     * @memberof CuratedSetCategory
     */
    id: number;
    /**
     * Entity creation date
     * @type {string}
     * @memberof CuratedSetCategory
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof CuratedSetCategory
     */
    updated_at: string;
    /**
     * Curated Set Category name
     * @type {string}
     * @memberof CuratedSetCategory
     */
    name: string;
    /**
     * Curated Set Category slug
     * @type {string}
     * @memberof CuratedSetCategory
     */
    slug: string;
    /**
     * Order of the Curated Set Category. Ascending.
     * @type {number}
     * @memberof CuratedSetCategory
     */
    order: number;
    /**
     * The amount of curated sets
     * @type {number}
     * @memberof CuratedSetCategory
     */
    set_count: number;
}
/**
 * 
 * @export
 * @interface CuratedSetWithMedia
 */
export interface CuratedSetWithMedia extends CuratedSet {
    /**
     * Tracks in this curated set
     * @type {Array<MediaWithAlbum>}
     * @memberof CuratedSetWithMedia
     */
    media?: Array<MediaWithAlbum>;
}
/**
 * 
 * @export
 * @interface FavoriteBatchBody
 */
export interface FavoriteBatchBody {
    /**
     * 
     * @type {Array<number>}
     * @memberof FavoriteBatchBody
     */
    album_ids: Array<number>;
}
/**
 * 
 * @export
 * @interface FavoriteBatchBody1
 */
export interface FavoriteBatchBody1 {
    /**
     * 
     * @type {Array<number>}
     * @memberof FavoriteBatchBody1
     */
    media_ids: Array<number>;
}
/**
 * 
 * @export
 * @interface Genre
 */
export interface Genre {
    /**
     * Unique Entity Id
     * @type {number}
     * @memberof Genre
     */
    id: number;
    /**
     * Entity creation date
     * @type {string}
     * @memberof Genre
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof Genre
     */
    updated_at: string;
    /**
     * Genre name
     * @type {string}
     * @memberof Genre
     */
    name: string;
    /**
     * Genre slug
     * @type {string}
     * @memberof Genre
     */
    slug: string;
    /**
     * Genre color
     * @type {string}
     * @memberof Genre
     */
    color?: string;
}
/**
 * 
 * @export
 * @interface IdBatchBody
 */
export interface IdBatchBody {
    /**
     * 
     * @type {Array<UserplaylistsidbatchMedia>}
     * @memberof IdBatchBody
     */
    media: Array<UserplaylistsidbatchMedia>;
}
/**
 * 
 * @export
 * @interface IdBatchBody1
 */
export interface IdBatchBody1 {
    /**
     * 
     * @type {Array<number>}
     * @memberof IdBatchBody1
     */
    media_ids: Array<number>;
}
/**
 * 
 * @export
 * @interface IdPlayBody
 */
export interface IdPlayBody {
    /**
     * The duration in seconds, for example 20.45
     * @type {number}
     * @memberof IdPlayBody
     */
    duration: number;
    /**
     * The current location on site, like '/new-releases'
     * @type {string}
     * @memberof IdPlayBody
     */
    from_location: string;
    /**
     * The library this track was streamed from. Not a filter!
     * @type {string}
     * @memberof IdPlayBody
     */
    from_library?: IdPlayBody.FromLibraryEnum;
}

/**
 * @export
 * @namespace IdPlayBody
 */
export namespace IdPlayBody {
    /**
     * @export
     * @enum {string}
     */
    export enum FromLibraryEnum {
        Music = <any> 'stream:music',
        Latin = <any> 'stream:latin'
    }
}
/**
 * 
 * @export
 * @interface IdUserplaylistsBody
 */
export interface IdUserplaylistsBody {
    /**
     * 
     * @type {string}
     * @memberof IdUserplaylistsBody
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof IdUserplaylistsBody
     */
    slug?: string;
    /**
     * 
     * @type {number}
     * @memberof IdUserplaylistsBody
     */
    order?: number;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {Array<Album>}
     * @memberof InlineResponse200
     */
    data: Array<Album>;
    /**
     * 
     * @type {Pagination}
     * @memberof InlineResponse200
     */
    pagination: Pagination;
}
/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {InlineResponse2001Data}
     * @memberof InlineResponse2001
     */
    data: InlineResponse2001Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20010
 */
export interface InlineResponse20010 {
    /**
     * 
     * @type {PlaylistWithMedia}
     * @memberof InlineResponse20010
     */
    data: PlaylistWithMedia;
}
/**
 * 
 * @export
 * @interface InlineResponse20011
 */
export interface InlineResponse20011 {
    /**
     * 
     * @type {Array<Playlist>}
     * @memberof InlineResponse20011
     */
    data: Array<Playlist>;
    /**
     * 
     * @type {Pagination}
     * @memberof InlineResponse20011
     */
    pagination: Pagination;
}
/**
 * 
 * @export
 * @interface InlineResponse20012
 */
export interface InlineResponse20012 {
    /**
     * 
     * @type {Array<Playlist>}
     * @memberof InlineResponse20012
     */
    data: Array<Playlist>;
}
/**
 * 
 * @export
 * @interface InlineResponse20013
 */
export interface InlineResponse20013 {
    /**
     * 
     * @type {UserPlaylistWithMedia}
     * @memberof InlineResponse20013
     */
    data: UserPlaylistWithMedia;
}
/**
 * 
 * @export
 * @interface InlineResponse20014
 */
export interface InlineResponse20014 {
    /**
     * 
     * @type {any}
     * @memberof InlineResponse20014
     */
    data: any;
}
/**
 * 
 * @export
 * @interface InlineResponse20015
 */
export interface InlineResponse20015 {
    /**
     * 
     * @type {Array<UserPlaylist>}
     * @memberof InlineResponse20015
     */
    data: Array<UserPlaylist>;
    /**
     * 
     * @type {Pagination}
     * @memberof InlineResponse20015
     */
    pagination: Pagination;
}
/**
 * 
 * @export
 * @interface InlineResponse20016
 */
export interface InlineResponse20016 {
    /**
     * 
     * @type {InlineResponse20016Data}
     * @memberof InlineResponse20016
     */
    data: InlineResponse20016Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20016Data
 */
export interface InlineResponse20016Data {
    /**
     * The for you playlists available for the current user
     * @type {Array<UserPlaylist>}
     * @memberof InlineResponse20016Data
     */
    available: Array<UserPlaylist>;
    /**
     * The for you playlists not available yet to the current user
     * @type {Array<InlineResponse20016DataNotAvailable>}
     * @memberof InlineResponse20016Data
     */
    not_available: Array<InlineResponse20016DataNotAvailable>;
}
/**
 * 
 * @export
 * @interface InlineResponse20016DataNotAvailable
 */
export interface InlineResponse20016DataNotAvailable {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20016DataNotAvailable
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20016DataNotAvailable
     */
    image_url: string;
}
/**
 * 
 * @export
 * @interface InlineResponse20017
 */
export interface InlineResponse20017 {
    /**
     * 
     * @type {Array<UserPlaylist>}
     * @memberof InlineResponse20017
     */
    data: Array<UserPlaylist>;
}
/**
 * 
 * @export
 * @interface InlineResponse20018
 */
export interface InlineResponse20018 {
    /**
     * 
     * @type {Array<PlaylistCategory>}
     * @memberof InlineResponse20018
     */
    data: Array<PlaylistCategory>;
}
/**
 * 
 * @export
 * @interface InlineResponse20019
 */
export interface InlineResponse20019 {
    /**
     * 
     * @type {Array<CuratedSetCategory>}
     * @memberof InlineResponse20019
     */
    data: Array<CuratedSetCategory>;
}
/**
 * 
 * @export
 * @interface InlineResponse2001Data
 */
export interface InlineResponse2001Data {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2001Data
     */
    total: number;
}
/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     * 
     * @type {Album}
     * @memberof InlineResponse2002
     */
    data: Album;
}
/**
 * 
 * @export
 * @interface InlineResponse20020
 */
export interface InlineResponse20020 {
    /**
     * 
     * @type {Array<UserPlaylistCategory>}
     * @memberof InlineResponse20020
     */
    data: Array<UserPlaylistCategory>;
}
/**
 * 
 * @export
 * @interface InlineResponse20021
 */
export interface InlineResponse20021 {
    /**
     * 
     * @type {Array<Category>}
     * @memberof InlineResponse20021
     */
    data: Array<Category>;
}
/**
 * 
 * @export
 * @interface InlineResponse20022
 */
export interface InlineResponse20022 {
    /**
     * 
     * @type {Array<Genre>}
     * @memberof InlineResponse20022
     */
    data: Array<Genre>;
}
/**
 * 
 * @export
 * @interface InlineResponse20023
 */
export interface InlineResponse20023 {
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineResponse20023
     */
    data: Array<string>;
}
/**
 * 
 * @export
 * @interface InlineResponse20024
 */
export interface InlineResponse20024 {
    /**
     * 
     * @type {Banner}
     * @memberof InlineResponse20024
     */
    data: Banner;
}
/**
 * 
 * @export
 * @interface InlineResponse20025
 */
export interface InlineResponse20025 {
    /**
     * 
     * @type {Artist}
     * @memberof InlineResponse20025
     */
    data: Artist;
}
/**
 * 
 * @export
 * @interface InlineResponse20026
 */
export interface InlineResponse20026 {
    /**
     * 
     * @type {InlineResponse20026Data}
     * @memberof InlineResponse20026
     */
    data: InlineResponse20026Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20026Data
 */
export interface InlineResponse20026Data {
    /**
     * 
     * @type {Array<CuratedSet>}
     * @memberof InlineResponse20026Data
     */
    curated: Array<CuratedSet>;
    /**
     * 
     * @type {Array<Playlist>}
     * @memberof InlineResponse20026Data
     */
    playlists: Array<Playlist>;
}
/**
 * 
 * @export
 * @interface InlineResponse20027
 */
export interface InlineResponse20027 {
    /**
     * 
     * @type {Array<ArtistWithMediaCount>}
     * @memberof InlineResponse20027
     */
    data: Array<ArtistWithMediaCount>;
    /**
     * 
     * @type {Pagination}
     * @memberof InlineResponse20027
     */
    pagination: Pagination;
}
/**
 * 
 * @export
 * @interface InlineResponse20028
 */
export interface InlineResponse20028 {
    /**
     * 
     * @type {Array<ArtistWithMediaCount>}
     * @memberof InlineResponse20028
     */
    data: Array<ArtistWithMediaCount>;
}
/**
 * 
 * @export
 * @interface InlineResponse20029
 */
export interface InlineResponse20029 {
    /**
     * 
     * @type {InlineResponse20029Data}
     * @memberof InlineResponse20029
     */
    data: InlineResponse20029Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20029Data
 */
export interface InlineResponse20029Data {
    /**
     * 
     * @type {InlineResponse20029DataTotal}
     * @memberof InlineResponse20029Data
     */
    total: InlineResponse20029DataTotal;
    /**
     * 
     * @type {InlineResponse20029DataTotal}
     * @memberof InlineResponse20029Data
     */
    artist: InlineResponse20029DataTotal;
    /**
     * 
     * @type {InlineResponse20029DataTotal}
     * @memberof InlineResponse20029Data
     */
    playlist: InlineResponse20029DataTotal;
    /**
     * 
     * @type {InlineResponse20029DataTotal}
     * @memberof InlineResponse20029Data
     */
    curated_set: InlineResponse20029DataTotal;
    /**
     * 
     * @type {InlineResponse20029DataTotal}
     * @memberof InlineResponse20029Data
     */
    album: InlineResponse20029DataTotal;
    /**
     * 
     * @type {InlineResponse20029DataTotal}
     * @memberof InlineResponse20029Data
     */
    media: InlineResponse20029DataTotal;
}
/**
 * 
 * @export
 * @interface InlineResponse20029DataTotal
 */
export interface InlineResponse20029DataTotal {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20029DataTotal
     */
    streamlatin: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20029DataTotal
     */
    streammusic: number;
}
/**
 * 
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
    /**
     * 
     * @type {Array<Album>}
     * @memberof InlineResponse2003
     */
    data: Array<Album>;
}
/**
 * 
 * @export
 * @interface InlineResponse20030
 */
export interface InlineResponse20030 {
    /**
     * 
     * @type {InlineResponse20030Data}
     * @memberof InlineResponse20030
     */
    data: InlineResponse20030Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20030Data
 */
export interface InlineResponse20030Data {
    /**
     * 
     * @type {Array<MediaWithAlbum>}
     * @memberof InlineResponse20030Data
     */
    media: Array<MediaWithAlbum>;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20030Data
     */
    media_total: number;
    /**
     * 
     * @type {Array<ArtistWithMediaCount>}
     * @memberof InlineResponse20030Data
     */
    artists: Array<ArtistWithMediaCount>;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20030Data
     */
    artist_total?: number;
    /**
     * 
     * @type {Array<Playlist>}
     * @memberof InlineResponse20030Data
     */
    playlists: Array<Playlist>;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20030Data
     */
    playlist_total: number;
    /**
     * 
     * @type {Array<CuratedSet>}
     * @memberof InlineResponse20030Data
     */
    curated: Array<CuratedSet>;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20030Data
     */
    curated_total: number;
}
/**
 * 
 * @export
 * @interface InlineResponse20031
 */
export interface InlineResponse20031 {
    /**
     * 
     * @type {InlineResponse20031Data}
     * @memberof InlineResponse20031
     */
    data: InlineResponse20031Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20031Data
 */
export interface InlineResponse20031Data {
    /**
     * The amount of songs and artists imported
     * @type {number}
     * @memberof InlineResponse20031Data
     */
    imported: number;
    /**
     * The amount of songs imported
     * @type {number}
     * @memberof InlineResponse20031Data
     */
    media: number;
    /**
     * The amount of artists imported (artists followed)
     * @type {number}
     * @memberof InlineResponse20031Data
     */
    artists: number;
}
/**
 * 
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
    /**
     * 
     * @type {Array<MediaWithAlbum>}
     * @memberof InlineResponse2004
     */
    data: Array<MediaWithAlbum>;
    /**
     * 
     * @type {Pagination}
     * @memberof InlineResponse2004
     */
    pagination: Pagination;
}
/**
 * 
 * @export
 * @interface InlineResponse2005
 */
export interface InlineResponse2005 {
    /**
     * 
     * @type {Array<MediaWithAlbum>}
     * @memberof InlineResponse2005
     */
    data: Array<MediaWithAlbum>;
}
/**
 * 
 * @export
 * @interface InlineResponse2006
 */
export interface InlineResponse2006 {
    /**
     * 
     * @type {MediaWithAlbum}
     * @memberof InlineResponse2006
     */
    data: MediaWithAlbum;
}
/**
 * 
 * @export
 * @interface InlineResponse2007
 */
export interface InlineResponse2007 {
    /**
     * 
     * @type {CuratedSetWithMedia}
     * @memberof InlineResponse2007
     */
    data: CuratedSetWithMedia;
}
/**
 * 
 * @export
 * @interface InlineResponse2008
 */
export interface InlineResponse2008 {
    /**
     * 
     * @type {Array<CuratedSet>}
     * @memberof InlineResponse2008
     */
    data: Array<CuratedSet>;
    /**
     * 
     * @type {Pagination}
     * @memberof InlineResponse2008
     */
    pagination: Pagination;
}
/**
 * 
 * @export
 * @interface InlineResponse2009
 */
export interface InlineResponse2009 {
    /**
     * 
     * @type {Array<CuratedSet>}
     * @memberof InlineResponse2009
     */
    data: Array<CuratedSet>;
}
/**
 * 
 * @export
 * @interface InlineResponse201
 */
export interface InlineResponse201 {
    /**
     * 
     * @type {UserPlaylistCategory}
     * @memberof InlineResponse201
     */
    data: UserPlaylistCategory;
}
/**
 * 
 * @export
 * @interface InlineResponse400
 */
export interface InlineResponse400 {
    /**
     * 
     * @type {InvalidParametersError}
     * @memberof InlineResponse400
     */
    error: InvalidParametersError;
}
/**
 * 
 * @export
 * @interface InternalServerError
 */
export interface InternalServerError {
}
/**
 * 
 * @export
 * @interface InvalidParametersError
 */
export interface InvalidParametersError extends Error {
    /**
     * 
     * @type {Array<string>}
     * @memberof InvalidParametersError
     */
    missing_keys: Array<string>;
    /**
     * object format: [invalid_key]: 'message'
     * @type {any}
     * @memberof InvalidParametersError
     */
    invalid_keys?: any;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum Library {
    Music = <any> 'stream:music',
    Latin = <any> 'stream:latin'
}
/**
 * 
 * @export
 * @interface Media
 */
export interface Media {
    /**
     * Unique Entity Id
     * @type {number}
     * @memberof Media
     */
    id: number;
    /**
     * Entity creation date
     * @type {string}
     * @memberof Media
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof Media
     */
    updated_at: string;
    /**
     * Title of the Track
     * @type {string}
     * @memberof Media
     */
    title: string;
    /**
     * Subtitle of the Track
     * @type {string}
     * @memberof Media
     */
    sub_title?: string;
    /**
     * Combined artist string of the track. Please see artists for further information
     * @type {string}
     * @memberof Media
     */
    artist: string;
    /**
     * BPM of the track, if available
     * @type {number}
     * @memberof Media
     */
    bpm?: number;
    /**
     * Key of the track, if available
     * @type {string}
     * @memberof Media
     */
    key?: string;
    /**
     * Display Key of the track, set depending on request param 'keyType', if available
     * @type {string}
     * @memberof Media
     */
    display_key?: string;
    /**
     * If track has explicit content
     * @type {boolean}
     * @memberof Media
     */
    explicit: boolean;
    /**
     * Track version (clean, intro, ...). In display format
     * @type {string}
     * @memberof Media
     */
    version: string;
    /**
     * First level sequeunce number (usually no sorting required as API will take care of it)
     * @type {number}
     * @memberof Media
     */
    parent_sequence_number: number;
    /**
     * Second level sequeunce number (usually no sorting required as API will take care of it)
     * @type {number}
     * @memberof Media
     */
    sequence_number: number;
    /**
     * The Album ID
     * @type {number}
     * @memberof Media
     */
    album_id: number;
    /**
     * Internal version number of track
     * @type {number}
     * @memberof Media
     */
    bpm_api_version: number;
    /**
     * The release date in ISO Format (2011-08-16T00:00:00.000Z) of the track
     * @type {string}
     * @memberof Media
     */
    release_date: string;
    /**
     * Highlight date
     * @type {string}
     * @memberof Media
     */
    highlighted_date?: string;
    /**
     * Size of the HQ 320kbs m4a file, in KB, if available
     * @type {number | string}
     * @memberof Media
     */
    size?: number | string;
    /**
     * Estimated duration of the track, in seconds, if available
     * @type {string}
     * @memberof Media
     */
    estimated_duration?: string;
    /**
     * Full name of track
     * @type {string}
     * @memberof Media
     */
    name: string;
    /**
     * 
     * @type {StreamInfo}
     * @memberof Media
     */
    stream_info: StreamInfo;
    /**
     * Artists of this album
     * @type {Array<Artist>}
     * @memberof Media
     */
    artists: Array<Artist>;
    /**
     * 
     * @type {WaveInfo}
     * @memberof Media
     */
    waves: WaveInfo;
    /**
     * Daily Trending status. Negative number if status went down, positive number if status went up
     * @type {number}
     * @memberof Media
     */
    trending_status_daily: number;
    /**
     * Weekly Trending status. Negative number if status went down, positive number if status went up
     * @type {number}
     * @memberof Media
     */
    trending_status_weekly: number;
    /**
     * Monthly Trending status. Negative number if status went down, positive number if status went up
     * @type {number}
     * @memberof Media
     */
    trending_status_monthly: number;
    /**
     * If media is in current users offline crate
     * @type {boolean}
     * @memberof Media
     */
    in_crate: boolean;
    /**
     * Only set on offline crate endpoints!
     * @type {string}
     * @memberof Media
     */
    added_to_crate_at?: string;
    /**
     * Only set on offline crate endpoints!
     * @type {string}
     * @memberof Media
     */
    synced_at?: string;
}
/**
 * 
 * @export
 * @interface MediaMediaIdBody
 */
export interface MediaMediaIdBody {
    /**
     * The 1 based order of the track
     * @type {number}
     * @memberof MediaMediaIdBody
     */
    order?: number;
}
/**
 * 
 * @export
 * @interface MediaWithAlbum
 */
export interface MediaWithAlbum extends Media {
    /**
     * 
     * @type {Album}
     * @memberof MediaWithAlbum
     */
    album?: Album;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * Error Type
     * @type {string}
     * @memberof ModelError
     */
    type: string;
    /**
     * The HTTP Code
     * @type {number}
     * @memberof ModelError
     */
    code: number;
    /**
     * Message. Can be displayed to the user (not localized)
     * @type {string}
     * @memberof ModelError
     */
    message: string;
}
/**
 * 
 * @export
 * @interface NotAccessibleError
 */
export interface NotAccessibleError {
}
/**
 * 
 * @export
 * @interface OfflinecrateBatchBody
 */
export interface OfflinecrateBatchBody {
    /**
     * 
     * @type {Array<number>}
     * @memberof OfflinecrateBatchBody
     */
    media_ids: Array<number>;
}
/**
 * 
 * @export
 * @interface OfflinecrateBatchBody1
 */
export interface OfflinecrateBatchBody1 {
    /**
     * 
     * @type {Array<number>}
     * @memberof OfflinecrateBatchBody1
     */
    media_ids: Array<number>;
}
/**
 * 
 * @export
 * @interface Pagination
 */
export interface Pagination {
    /**
     * Total number of entities
     * @type {number}
     * @memberof Pagination
     */
    total: number;
    /**
     * Number of skipped entities
     * @type {number}
     * @memberof Pagination
     */
    skipped: number;
    /**
     * Max amount of entities to return
     * @type {number}
     * @memberof Pagination
     */
    limited: number;
    /**
     * Current page
     * @type {number}
     * @memberof Pagination
     */
    page: number;
}
/**
 * 
 * @export
 * @interface Playlist
 */
export interface Playlist {
    /**
     * Unique Entity Id
     * @type {number}
     * @memberof Playlist
     */
    id: number;
    /**
     * Entity creation date
     * @type {string}
     * @memberof Playlist
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof Playlist
     */
    updated_at: string;
    /**
     * Title of the playlist
     * @type {string}
     * @memberof Playlist
     */
    title: string;
    /**
     * Slug of the playlist
     * @type {string}
     * @memberof Playlist
     */
    slug: string;
    /**
     * Optional description
     * @type {string}
     * @memberof Playlist
     */
    description?: string;
    /**
     * Order of the playlist within the Category. Ascending.
     * @type {number}
     * @memberof Playlist
     */
    order: number;
    /**
     * Cover (Artwork) URL of the playlist
     * @type {string}
     * @memberof Playlist
     */
    image_url?: string;
    /**
     * The category id
     * @type {number}
     * @memberof Playlist
     */
    category_id: number;
    /**
     * 
     * @type {PlaylistCategory}
     * @memberof Playlist
     */
    category: PlaylistCategory;
    /**
     * 
     * @type {PlaylistGenre}
     * @memberof Playlist
     */
    genre?: PlaylistGenre;
    /**
     * 
     * @type {Library}
     * @memberof Playlist
     */
    library: Library;
    /**
     * Artists of the playlist (first 6)
     * @type {Array<Artist>}
     * @memberof Playlist
     */
    artists?: Array<Artist>;
    /**
     * Artist string of the playlist (first 6). Please see artists for further information
     * @type {string}
     * @memberof Playlist
     */
    artist: string;
    /**
     * Weekly Trending status. Negative number if status went down, positive number if status went up
     * @type {number}
     * @memberof Playlist
     */
    trending_status_weekly: number;
    /**
     * The badge for this playlist
     * @type {string}
     * @memberof Playlist
     */
    badge?: Playlist.BadgeEnum;
}

/**
 * @export
 * @namespace Playlist
 */
export namespace Playlist {
    /**
     * @export
     * @enum {string}
     */
    export enum BadgeEnum {
        New = <any> 'new',
        Trending = <any> 'trending'
    }
}
/**
 * 
 * @export
 * @interface PlaylistCategory
 */
export interface PlaylistCategory {
    /**
     * Unique Entity Id
     * @type {number}
     * @memberof PlaylistCategory
     */
    id: number;
    /**
     * Entity creation date
     * @type {string}
     * @memberof PlaylistCategory
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof PlaylistCategory
     */
    updated_at: string;
    /**
     * Playlist Category name
     * @type {string}
     * @memberof PlaylistCategory
     */
    name: string;
    /**
     * Playlist Category slug
     * @type {string}
     * @memberof PlaylistCategory
     */
    slug: string;
    /**
     * Order of the Category. Ascending.
     * @type {number}
     * @memberof PlaylistCategory
     */
    order: number;
    /**
     * The amount of playlists
     * @type {number}
     * @memberof PlaylistCategory
     */
    playlist_count: number;
}
/**
 * 
 * @export
 * @interface PlaylistGenre
 */
export interface PlaylistGenre {
    /**
     * Unique Entity Id
     * @type {number}
     * @memberof PlaylistGenre
     */
    id: number;
    /**
     * Entity creation date
     * @type {string}
     * @memberof PlaylistGenre
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof PlaylistGenre
     */
    updated_at: string;
    /**
     * Playlist Genre name
     * @type {string}
     * @memberof PlaylistGenre
     */
    name: string;
    /**
     * Playlist Genre slug
     * @type {string}
     * @memberof PlaylistGenre
     */
    slug: string;
    /**
     * Order of the Genre. Ascending.
     * @type {number}
     * @memberof PlaylistGenre
     */
    order: number;
}
/**
 * 
 * @export
 * @interface PlaylistWithMedia
 */
export interface PlaylistWithMedia extends Playlist {
    /**
     * Tracks in this playlist
     * @type {Array<MediaWithAlbum>}
     * @memberof PlaylistWithMedia
     */
    media?: Array<MediaWithAlbum>;
}

/**
 * @export
 * @namespace PlaylistWithMedia
 */
export namespace PlaylistWithMedia {
}
/**
 * 
 * @export
 * @interface ResourceNotFoundError
 */
export interface ResourceNotFoundError extends Error {
    /**
     * The resource type
     * @type {string}
     * @memberof ResourceNotFoundError
     */
    resource_type?: string;
}
/**
 * 
 * @export
 * @interface SessionNotFoundError
 */
export interface SessionNotFoundError {
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SortByQueryOptions {
    DateAsc = <any> 'date_asc',
    DateDesc = <any> 'date_desc',
    ArtistAsc = <any> 'artist_asc',
    ArtistDesc = <any> 'artist_desc',
    TitleAsc = <any> 'title_asc',
    TitleDesc = <any> 'title_desc',
    BpmAsc = <any> 'bpm_asc',
    BpmDesc = <any> 'bpm_desc',
    Relevance = <any> 'relevance',
    Trending = <any> 'trending'
}
/**
 * 
 * @export
 * @interface StreamInfo
 */
export interface StreamInfo {
    /**
     * Contains information on how to stream this media file
     * @type {any}
     * @memberof StreamInfo
     */
    audioInfo: any;
}
/**
 * 
 * @export
 */
export type StringOrStringArray = string | Array<string>
/**
 * 
 * @export
 * @interface UserPlaylist
 */
export interface UserPlaylist {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof UserPlaylist
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof UserPlaylist
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof UserPlaylist
     */
    updated_at: string;
    /**
     * Title of the user playlist
     * @type {string}
     * @memberof UserPlaylist
     */
    title: string;
    /**
     * Artist string of the user playlist (first 6). Please see artists for further information
     * @type {string}
     * @memberof UserPlaylist
     */
    artist: string;
    /**
     * Artists of the user playlist (first 6)
     * @type {Array<Artist>}
     * @memberof UserPlaylist
     */
    artists?: Array<Artist>;
    /**
     * Slug of the user playlist
     * @type {string}
     * @memberof UserPlaylist
     */
    slug: string;
    /**
     * Order of the user playlist within the Category. Ascending.
     * @type {number}
     * @memberof UserPlaylist
     */
    order: number;
    /**
     * Cover (Artwork) URL of the user playlist
     * @type {string}
     * @memberof UserPlaylist
     */
    image_url?: string;
    /**
     * Cover (Artwork) main color
     * @type {string}
     * @memberof UserPlaylist
     */
    image_color?: string;
    /**
     * 
     * @type {UserPlaylistCategory}
     * @memberof UserPlaylist
     */
    category: UserPlaylistCategory;
    /**
     * The associated category id
     * @type {number}
     * @memberof UserPlaylist
     */
    category_id?: number;
    /**
     * The amount of media in this playlist
     * @type {number}
     * @memberof UserPlaylist
     */
    media_count: number;
    /**
     * The associated user id
     * @type {number}
     * @memberof UserPlaylist
     */
    user_id: number;
}
/**
 * 
 * @export
 * @interface UserPlaylistCategory
 */
export interface UserPlaylistCategory {
    /**
     * Unique Entity Id
     * @type {number}
     * @memberof UserPlaylistCategory
     */
    id: number;
    /**
     * Entity creation date
     * @type {string}
     * @memberof UserPlaylistCategory
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof UserPlaylistCategory
     */
    updated_at: string;
    /**
     * User Playlist Category name
     * @type {string}
     * @memberof UserPlaylistCategory
     */
    name: string;
    /**
     * User Playlist Category slug
     * @type {string}
     * @memberof UserPlaylistCategory
     */
    slug: string;
    /**
     * Order of the User Playlist Category. Ascending.
     * @type {number}
     * @memberof UserPlaylistCategory
     */
    order: number;
    /**
     * The amount of playlists
     * @type {number}
     * @memberof UserPlaylistCategory
     */
    playlist_count: number;
    /**
     * 
     * @type {Array<UserPlaylistCategoryPlaylists>}
     * @memberof UserPlaylistCategory
     */
    playlists?: Array<UserPlaylistCategoryPlaylists>;
}
/**
 * 
 * @export
 * @interface UserPlaylistCategoryPlaylists
 */
export interface UserPlaylistCategoryPlaylists {
    /**
     * 
     * @type {string}
     * @memberof UserPlaylistCategoryPlaylists
     */
    title: string;
}
/**
 * 
 * @export
 * @interface UserPlaylistWithMedia
 */
export interface UserPlaylistWithMedia extends UserPlaylist {
    /**
     * Tracks in this user playlist
     * @type {Array<MediaWithAlbum>}
     * @memberof UserPlaylistWithMedia
     */
    media?: Array<MediaWithAlbum>;
}
/**
 * 
 * @export
 * @interface UserplaylistcategoriesBody
 */
export interface UserplaylistcategoriesBody {
    /**
     * 
     * @type {string}
     * @memberof UserplaylistcategoriesBody
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UserplaylistcategoriesBody
     */
    slug?: string;
    /**
     * 
     * @type {number}
     * @memberof UserplaylistcategoriesBody
     */
    order?: number;
}
/**
 * 
 * @export
 * @interface UserplaylistcategoriesIdBody
 */
export interface UserplaylistcategoriesIdBody {
    /**
     * 
     * @type {string}
     * @memberof UserplaylistcategoriesIdBody
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserplaylistcategoriesIdBody
     */
    slug?: string;
    /**
     * 
     * @type {number}
     * @memberof UserplaylistcategoriesIdBody
     */
    order?: number;
}
/**
 * 
 * @export
 * @interface UserplaylistsIdBody
 */
export interface UserplaylistsIdBody {
    /**
     * 
     * @type {string}
     * @memberof UserplaylistsIdBody
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof UserplaylistsIdBody
     */
    slug?: string;
    /**
     * 
     * @type {number}
     * @memberof UserplaylistsIdBody
     */
    order?: number;
    /**
     * Change the User Playlist Category
     * @type {string}
     * @memberof UserplaylistsIdBody
     */
    category_id?: string;
}
/**
 * 
 * @export
 * @interface UserplaylistsidbatchMedia
 */
export interface UserplaylistsidbatchMedia {
    /**
     * The media Id
     * @type {number}
     * @memberof UserplaylistsidbatchMedia
     */
    media_id: number;
    /**
     * 
     * @type {number}
     * @memberof UserplaylistsidbatchMedia
     */
    order?: number;
}
/**
 * 
 * @export
 * @interface WaveInfo
 */
export interface WaveInfo {
    /**
     * 
     * @type {string}
     * @memberof WaveInfo
     */
    gray: string;
    /**
     * 
     * @type {string}
     * @memberof WaveInfo
     */
    colored: string;
    /**
     * 
     * @type {string}
     * @memberof WaveInfo
     */
    dat?: string;
    /**
     * 
     * @type {string}
     * @memberof WaveInfo
     */
    latino?: string;
}
/**
 * AlbumApi - fetch parameter creator
 * @export
 */
export const AlbumApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Favorite Album
         * @param {number} id The album Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteAlbum(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling favoriteAlbum.');
            }
            const localVarPath = `/albums/{id}/favorite`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Favorite multiple Albums
         * @param {FavoriteBatchBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteAlbumsBatch(body?: FavoriteBatchBody, options: any = {}): FetchArgs {
            const localVarPath = `/albums/favorite/batch`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'FavoriteBatchBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get favorited Albums
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavAlbums(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/albums/favorited`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get trending Albums by period
         * @param {string} period The period
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingAlbumPeriod(period: string, limit?: number, skip?: number, category?: StringOrStringArray, library?: string, options: any = {}): FetchArgs {
            // verify required parameter 'period' is not null or undefined
            if (period === null || period === undefined) {
                throw new RequiredError('period','Required parameter period was null or undefined when calling getTrendingAlbumPeriod.');
            }
            const localVarPath = `/albums/{period}/trending`
                .replace(`{${'period'}}`, encodeURIComponent(String(period)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unfavorite Album
         * @param {number} id The album Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfavoriteAlbum(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling unfavoriteAlbum.');
            }
            const localVarPath = `/albums/{id}/favorite`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AlbumApi - functional programming interface
 * @export
 */
export const AlbumApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Favorite Album
         * @param {number} id The album Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteAlbum(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = AlbumApiFetchParamCreator(configuration).favoriteAlbum(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Favorite multiple Albums
         * @param {FavoriteBatchBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteAlbumsBatch(body?: FavoriteBatchBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = AlbumApiFetchParamCreator(configuration).favoriteAlbumsBatch(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get favorited Albums
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavAlbums(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = AlbumApiFetchParamCreator(configuration).getFavAlbums(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get trending Albums by period
         * @param {string} period The period
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingAlbumPeriod(period: string, limit?: number, skip?: number, category?: StringOrStringArray, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse200> {
            const localVarFetchArgs = AlbumApiFetchParamCreator(configuration).getTrendingAlbumPeriod(period, limit, skip, category, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Unfavorite Album
         * @param {number} id The album Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfavoriteAlbum(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = AlbumApiFetchParamCreator(configuration).unfavoriteAlbum(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AlbumApi - factory interface
 * @export
 */
export const AlbumApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Favorite Album
         * @param {number} id The album Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteAlbum(id: number, options?: any) {
            return AlbumApiFp(configuration).favoriteAlbum(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Favorite multiple Albums
         * @param {FavoriteBatchBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteAlbumsBatch(body?: FavoriteBatchBody, options?: any) {
            return AlbumApiFp(configuration).favoriteAlbumsBatch(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get favorited Albums
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavAlbums(library?: string, options?: any) {
            return AlbumApiFp(configuration).getFavAlbums(library, options)(fetch, basePath);
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get trending Albums by period
         * @param {string} period The period
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingAlbumPeriod(period: string, limit?: number, skip?: number, category?: StringOrStringArray, library?: string, options?: any) {
            return AlbumApiFp(configuration).getTrendingAlbumPeriod(period, limit, skip, category, library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Unfavorite Album
         * @param {number} id The album Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfavoriteAlbum(id: number, options?: any) {
            return AlbumApiFp(configuration).unfavoriteAlbum(id, options)(fetch, basePath);
        },
    };
};

/**
 * AlbumApi - object-oriented interface
 * @export
 * @class AlbumApi
 * @extends {BaseAPI}
 */
export class AlbumApi extends BaseAPI {
    /**
     * 
     * @summary Favorite Album
     * @param {number} id The album Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlbumApi
     */
    public favoriteAlbum(id: number, options?: any) {
        return AlbumApiFp(this.configuration).favoriteAlbum(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Favorite multiple Albums
     * @param {FavoriteBatchBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlbumApi
     */
    public favoriteAlbumsBatch(body?: FavoriteBatchBody, options?: any) {
        return AlbumApiFp(this.configuration).favoriteAlbumsBatch(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get favorited Albums
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlbumApi
     */
    public getFavAlbums(library?: string, options?: any) {
        return AlbumApiFp(this.configuration).getFavAlbums(library, options)(this.fetch, this.basePath);
    }

    /**
     * Default filters based on susbcription and region are applied automatic.
     * @summary Get trending Albums by period
     * @param {string} period The period
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlbumApi
     */
    public getTrendingAlbumPeriod(period: string, limit?: number, skip?: number, category?: StringOrStringArray, library?: string, options?: any) {
        return AlbumApiFp(this.configuration).getTrendingAlbumPeriod(period, limit, skip, category, library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Unfavorite Album
     * @param {number} id The album Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlbumApi
     */
    public unfavoriteAlbum(id: number, options?: any) {
        return AlbumApiFp(this.configuration).unfavoriteAlbum(id, options)(this.fetch, this.basePath);
    }

}
/**
 * ArtistApi - fetch parameter creator
 * @export
 */
export const ArtistApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Follow Artist
         * @param {number} id The artist Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followArtist(id: number, library?: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling followArtist.');
            }
            const localVarPath = `/artists/{id}/follow`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Artist Featured In
         * @param {number} id The artist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistFeaturedIn(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getArtistFeaturedIn.');
            }
            const localVarPath = `/artists/{id}/featured-in`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Artist By Id
         * @param {number} id The artist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistId(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getArtistId.');
            }
            const localVarPath = `/artists/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Artist By Slug
         * @param {string} slug The artist slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistSlug(slug: string, options: any = {}): FetchArgs {
            // verify required parameter 'slug' is not null or undefined
            if (slug === null || slug === undefined) {
                throw new RequiredError('slug','Required parameter slug was null or undefined when calling getArtistSlug.');
            }
            const localVarPath = `/artists/{slug}`
                .replace(`{${'slug'}}`, encodeURIComponent(String(slug)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get followed Artists
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowedArtists(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/artists/following`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search for Artists
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchArtist(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options: any = {}): FetchArgs {
            const localVarPath = `/artists`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Suggest Media and Artist based on term
         * @param {string} term Search term
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestMediaArtist(term: string, library?: string, keyType?: string, options: any = {}): FetchArgs {
            // verify required parameter 'term' is not null or undefined
            if (term === null || term === undefined) {
                throw new RequiredError('term','Required parameter term was null or undefined when calling suggestMediaArtist.');
            }
            const localVarPath = `/suggest`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unfollow Artist
         * @param {number} id The artist Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfollowArtist(id: number, library?: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling unfollowArtist.');
            }
            const localVarPath = `/artists/{id}/follow`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArtistApi - functional programming interface
 * @export
 */
export const ArtistApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Follow Artist
         * @param {number} id The artist Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followArtist(id: number, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20028> {
            const localVarFetchArgs = ArtistApiFetchParamCreator(configuration).followArtist(id, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Artist Featured In
         * @param {number} id The artist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistFeaturedIn(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20026> {
            const localVarFetchArgs = ArtistApiFetchParamCreator(configuration).getArtistFeaturedIn(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Artist By Id
         * @param {number} id The artist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistId(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20025> {
            const localVarFetchArgs = ArtistApiFetchParamCreator(configuration).getArtistId(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Artist By Slug
         * @param {string} slug The artist slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistSlug(slug: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20025> {
            const localVarFetchArgs = ArtistApiFetchParamCreator(configuration).getArtistSlug(slug, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get followed Artists
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowedArtists(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20028> {
            const localVarFetchArgs = ArtistApiFetchParamCreator(configuration).getFollowedArtists(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search for Artists
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchArtist(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20027> {
            const localVarFetchArgs = ArtistApiFetchParamCreator(configuration).searchArtist(limit, skip, category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, term, date, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Suggest Media and Artist based on term
         * @param {string} term Search term
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestMediaArtist(term: string, library?: string, keyType?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20030> {
            const localVarFetchArgs = ArtistApiFetchParamCreator(configuration).suggestMediaArtist(term, library, keyType, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Unfollow Artist
         * @param {number} id The artist Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfollowArtist(id: number, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20028> {
            const localVarFetchArgs = ArtistApiFetchParamCreator(configuration).unfollowArtist(id, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ArtistApi - factory interface
 * @export
 */
export const ArtistApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Follow Artist
         * @param {number} id The artist Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followArtist(id: number, library?: string, options?: any) {
            return ArtistApiFp(configuration).followArtist(id, library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Artist Featured In
         * @param {number} id The artist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistFeaturedIn(id: number, options?: any) {
            return ArtistApiFp(configuration).getArtistFeaturedIn(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Artist By Id
         * @param {number} id The artist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistId(id: number, options?: any) {
            return ArtistApiFp(configuration).getArtistId(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Artist By Slug
         * @param {string} slug The artist slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistSlug(slug: string, options?: any) {
            return ArtistApiFp(configuration).getArtistSlug(slug, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get followed Artists
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowedArtists(library?: string, options?: any) {
            return ArtistApiFp(configuration).getFollowedArtists(library, options)(fetch, basePath);
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search for Artists
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchArtist(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options?: any) {
            return ArtistApiFp(configuration).searchArtist(limit, skip, category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, term, date, options)(fetch, basePath);
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Suggest Media and Artist based on term
         * @param {string} term Search term
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestMediaArtist(term: string, library?: string, keyType?: string, options?: any) {
            return ArtistApiFp(configuration).suggestMediaArtist(term, library, keyType, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Unfollow Artist
         * @param {number} id The artist Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfollowArtist(id: number, library?: string, options?: any) {
            return ArtistApiFp(configuration).unfollowArtist(id, library, options)(fetch, basePath);
        },
    };
};

/**
 * ArtistApi - object-oriented interface
 * @export
 * @class ArtistApi
 * @extends {BaseAPI}
 */
export class ArtistApi extends BaseAPI {
    /**
     * 
     * @summary Follow Artist
     * @param {number} id The artist Id
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistApi
     */
    public followArtist(id: number, library?: string, options?: any) {
        return ArtistApiFp(this.configuration).followArtist(id, library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Artist Featured In
     * @param {number} id The artist Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistApi
     */
    public getArtistFeaturedIn(id: number, options?: any) {
        return ArtistApiFp(this.configuration).getArtistFeaturedIn(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Artist By Id
     * @param {number} id The artist Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistApi
     */
    public getArtistId(id: number, options?: any) {
        return ArtistApiFp(this.configuration).getArtistId(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Artist By Slug
     * @param {string} slug The artist slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistApi
     */
    public getArtistSlug(slug: string, options?: any) {
        return ArtistApiFp(this.configuration).getArtistSlug(slug, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get followed Artists
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistApi
     */
    public getFollowedArtists(library?: string, options?: any) {
        return ArtistApiFp(this.configuration).getFollowedArtists(library, options)(this.fetch, this.basePath);
    }

    /**
     * Note: sort_by is NOT supported on this endpoint
     * @summary Search for Artists
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
     * @param {StringOrStringArray} [version] version names(s) (can be multiple)
     * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {number} [bpm_min] 
     * @param {number} [bpm_max] Maxmium is 250
     * @param {string} [term] Search term
     * @param {string} [date] Date string (Example: 2021-09-10)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistApi
     */
    public searchArtist(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options?: any) {
        return ArtistApiFp(this.configuration).searchArtist(limit, skip, category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, term, date, options)(this.fetch, this.basePath);
    }

    /**
     * Default filters based on susbcription and region are applied automatic.
     * @summary Suggest Media and Artist based on term
     * @param {string} term Search term
     * @param {string} [library] Default is all to the user available libraries
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistApi
     */
    public suggestMediaArtist(term: string, library?: string, keyType?: string, options?: any) {
        return ArtistApiFp(this.configuration).suggestMediaArtist(term, library, keyType, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Unfollow Artist
     * @param {number} id The artist Id
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistApi
     */
    public unfollowArtist(id: number, library?: string, options?: any) {
        return ArtistApiFp(this.configuration).unfollowArtist(id, library, options)(this.fetch, this.basePath);
    }

}
/**
 * BannerApi - fetch parameter creator
 * @export
 */
export const BannerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Banners
         * @param {string} location The location to load the banners for, like &#x27;browse&#x27; or &#x27;trending&#x27;
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBanner(location: string, library?: string, options: any = {}): FetchArgs {
            // verify required parameter 'location' is not null or undefined
            if (location === null || location === undefined) {
                throw new RequiredError('location','Required parameter location was null or undefined when calling getBanner.');
            }
            const localVarPath = `/banner`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BannerApi - functional programming interface
 * @export
 */
export const BannerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Banners
         * @param {string} location The location to load the banners for, like &#x27;browse&#x27; or &#x27;trending&#x27;
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBanner(location: string, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20024> {
            const localVarFetchArgs = BannerApiFetchParamCreator(configuration).getBanner(location, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * BannerApi - factory interface
 * @export
 */
export const BannerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get Banners
         * @param {string} location The location to load the banners for, like &#x27;browse&#x27; or &#x27;trending&#x27;
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBanner(location: string, library?: string, options?: any) {
            return BannerApiFp(configuration).getBanner(location, library, options)(fetch, basePath);
        },
    };
};

/**
 * BannerApi - object-oriented interface
 * @export
 * @class BannerApi
 * @extends {BaseAPI}
 */
export class BannerApi extends BaseAPI {
    /**
     * 
     * @summary Get Banners
     * @param {string} location The location to load the banners for, like &#x27;browse&#x27; or &#x27;trending&#x27;
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannerApi
     */
    public getBanner(location: string, library?: string, options?: any) {
        return BannerApiFp(this.configuration).getBanner(location, library, options)(this.fetch, this.basePath);
    }

}
/**
 * CSVApi - fetch parameter creator
 * @export
 */
export const CSVApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary CSV Import
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importCsv(file?: Blob, options: any = {}): FetchArgs {
            const localVarPath = `/csv-import`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (file !== undefined) {
                localVarFormParams.set('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CSVApi - functional programming interface
 * @export
 */
export const CSVApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary CSV Import
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importCsv(file?: Blob, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20031> {
            const localVarFetchArgs = CSVApiFetchParamCreator(configuration).importCsv(file, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CSVApi - factory interface
 * @export
 */
export const CSVApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary CSV Import
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importCsv(file?: Blob, options?: any) {
            return CSVApiFp(configuration).importCsv(file, options)(fetch, basePath);
        },
    };
};

/**
 * CSVApi - object-oriented interface
 * @export
 * @class CSVApi
 * @extends {BaseAPI}
 */
export class CSVApi extends BaseAPI {
    /**
     * 
     * @summary CSV Import
     * @param {Blob} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CSVApi
     */
    public importCsv(file?: Blob, options?: any) {
        return CSVApiFp(this.configuration).importCsv(file, options)(this.fetch, this.basePath);
    }

}
/**
 * CuratedSetApi - fetch parameter creator
 * @export
 */
export const CuratedSetApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Follow Curated Set
         * @param {number} id The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followCuratedSet(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling followCuratedSet.');
            }
            const localVarPath = `/curated-sets/{id}/follow`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Curated Set By Id
         * @param {number} id The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSet(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getCuratedSet.');
            }
            const localVarPath = `/curated-sets/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Curated Set Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetCategories(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/curated-set-categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Note: This endpoint does not have a default limit!
         * @summary Get All Curated Sets
         * @param {number} [limit] This endpoint does not have a default limit!
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSets(limit?: number, skip?: number, library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/curated-sets`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Curated Sets By Category Id
         * @param {number} id The curated set category Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetsByCatId(id: number, library?: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getCuratedSetsByCatId.');
            }
            const localVarPath = `/curated-set-categories/{id}/curated-sets`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Curated Sets By Category Slug
         * @param {string} slug The curated set category slug
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetsByCatSlug(slug: string, library?: string, options: any = {}): FetchArgs {
            // verify required parameter 'slug' is not null or undefined
            if (slug === null || slug === undefined) {
                throw new RequiredError('slug','Required parameter slug was null or undefined when calling getCuratedSetsByCatSlug.');
            }
            const localVarPath = `/curated-set-categories/{slug}/curated-sets`
                .replace(`{${'slug'}}`, encodeURIComponent(String(slug)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Curated Sets By Curator
         * @param {string} curator The curated set curator
         * @param {string} [exclude] The curated set slug to exclude
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetsByCurator(curator: string, exclude?: string, library?: string, options: any = {}): FetchArgs {
            // verify required parameter 'curator' is not null or undefined
            if (curator === null || curator === undefined) {
                throw new RequiredError('curator','Required parameter curator was null or undefined when calling getCuratedSetsByCurator.');
            }
            const localVarPath = `/curated-sets/curator/{curator}`
                .replace(`{${'curator'}}`, encodeURIComponent(String(curator)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (exclude !== undefined) {
                localVarQueryParameter['exclude'] = exclude;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Curated Set By Slug
         * @param {string} slug The curated set slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetsBySlug(slug: string, options: any = {}): FetchArgs {
            // verify required parameter 'slug' is not null or undefined
            if (slug === null || slug === undefined) {
                throw new RequiredError('slug','Required parameter slug was null or undefined when calling getCuratedSetsBySlug.');
            }
            const localVarPath = `/curated-sets/slug/{slug}`
                .replace(`{${'slug'}}`, encodeURIComponent(String(slug)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get followed Curated Sets
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowedCuratedSets(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/curated-sets/following`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all trending Curated Sets
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingCuratedSets(limit?: number, skip?: number, library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/curated-sets/trending`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search Curated Sets
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCuratedSets(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options: any = {}): FetchArgs {
            const localVarPath = `/curated-sets/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unfollow Curated Set
         * @param {number} id The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfollowCuratedSet(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling unfollowCuratedSet.');
            }
            const localVarPath = `/curated-sets/{id}/follow`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CuratedSetApi - functional programming interface
 * @export
 */
export const CuratedSetApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Follow Curated Set
         * @param {number} id The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followCuratedSet(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2009> {
            const localVarFetchArgs = CuratedSetApiFetchParamCreator(configuration).followCuratedSet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Curated Set By Id
         * @param {number} id The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSet(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2007> {
            const localVarFetchArgs = CuratedSetApiFetchParamCreator(configuration).getCuratedSet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get All Curated Set Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetCategories(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20019> {
            const localVarFetchArgs = CuratedSetApiFetchParamCreator(configuration).getCuratedSetCategories(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Note: This endpoint does not have a default limit!
         * @summary Get All Curated Sets
         * @param {number} [limit] This endpoint does not have a default limit!
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSets(limit?: number, skip?: number, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2008> {
            const localVarFetchArgs = CuratedSetApiFetchParamCreator(configuration).getCuratedSets(limit, skip, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Curated Sets By Category Id
         * @param {number} id The curated set category Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetsByCatId(id: number, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2009> {
            const localVarFetchArgs = CuratedSetApiFetchParamCreator(configuration).getCuratedSetsByCatId(id, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Curated Sets By Category Slug
         * @param {string} slug The curated set category slug
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetsByCatSlug(slug: string, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2009> {
            const localVarFetchArgs = CuratedSetApiFetchParamCreator(configuration).getCuratedSetsByCatSlug(slug, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Curated Sets By Curator
         * @param {string} curator The curated set curator
         * @param {string} [exclude] The curated set slug to exclude
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetsByCurator(curator: string, exclude?: string, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2009> {
            const localVarFetchArgs = CuratedSetApiFetchParamCreator(configuration).getCuratedSetsByCurator(curator, exclude, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Curated Set By Slug
         * @param {string} slug The curated set slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetsBySlug(slug: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2007> {
            const localVarFetchArgs = CuratedSetApiFetchParamCreator(configuration).getCuratedSetsBySlug(slug, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get followed Curated Sets
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowedCuratedSets(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2009> {
            const localVarFetchArgs = CuratedSetApiFetchParamCreator(configuration).getFollowedCuratedSets(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all trending Curated Sets
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingCuratedSets(limit?: number, skip?: number, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2008> {
            const localVarFetchArgs = CuratedSetApiFetchParamCreator(configuration).getTrendingCuratedSets(limit, skip, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search Curated Sets
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCuratedSets(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2008> {
            const localVarFetchArgs = CuratedSetApiFetchParamCreator(configuration).searchCuratedSets(limit, skip, category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, term, date, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Unfollow Curated Set
         * @param {number} id The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfollowCuratedSet(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2009> {
            const localVarFetchArgs = CuratedSetApiFetchParamCreator(configuration).unfollowCuratedSet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CuratedSetApi - factory interface
 * @export
 */
export const CuratedSetApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Follow Curated Set
         * @param {number} id The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followCuratedSet(id: number, options?: any) {
            return CuratedSetApiFp(configuration).followCuratedSet(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Curated Set By Id
         * @param {number} id The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSet(id: number, options?: any) {
            return CuratedSetApiFp(configuration).getCuratedSet(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get All Curated Set Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetCategories(library?: string, options?: any) {
            return CuratedSetApiFp(configuration).getCuratedSetCategories(library, options)(fetch, basePath);
        },
        /**
         * Note: This endpoint does not have a default limit!
         * @summary Get All Curated Sets
         * @param {number} [limit] This endpoint does not have a default limit!
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSets(limit?: number, skip?: number, library?: string, options?: any) {
            return CuratedSetApiFp(configuration).getCuratedSets(limit, skip, library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Curated Sets By Category Id
         * @param {number} id The curated set category Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetsByCatId(id: number, library?: string, options?: any) {
            return CuratedSetApiFp(configuration).getCuratedSetsByCatId(id, library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Curated Sets By Category Slug
         * @param {string} slug The curated set category slug
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetsByCatSlug(slug: string, library?: string, options?: any) {
            return CuratedSetApiFp(configuration).getCuratedSetsByCatSlug(slug, library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Curated Sets By Curator
         * @param {string} curator The curated set curator
         * @param {string} [exclude] The curated set slug to exclude
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetsByCurator(curator: string, exclude?: string, library?: string, options?: any) {
            return CuratedSetApiFp(configuration).getCuratedSetsByCurator(curator, exclude, library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Curated Set By Slug
         * @param {string} slug The curated set slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetsBySlug(slug: string, options?: any) {
            return CuratedSetApiFp(configuration).getCuratedSetsBySlug(slug, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get followed Curated Sets
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowedCuratedSets(library?: string, options?: any) {
            return CuratedSetApiFp(configuration).getFollowedCuratedSets(library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all trending Curated Sets
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingCuratedSets(limit?: number, skip?: number, library?: string, options?: any) {
            return CuratedSetApiFp(configuration).getTrendingCuratedSets(limit, skip, library, options)(fetch, basePath);
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search Curated Sets
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCuratedSets(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options?: any) {
            return CuratedSetApiFp(configuration).searchCuratedSets(limit, skip, category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, term, date, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Unfollow Curated Set
         * @param {number} id The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfollowCuratedSet(id: number, options?: any) {
            return CuratedSetApiFp(configuration).unfollowCuratedSet(id, options)(fetch, basePath);
        },
    };
};

/**
 * CuratedSetApi - object-oriented interface
 * @export
 * @class CuratedSetApi
 * @extends {BaseAPI}
 */
export class CuratedSetApi extends BaseAPI {
    /**
     * 
     * @summary Follow Curated Set
     * @param {number} id The curated set Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedSetApi
     */
    public followCuratedSet(id: number, options?: any) {
        return CuratedSetApiFp(this.configuration).followCuratedSet(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Curated Set By Id
     * @param {number} id The curated set Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedSetApi
     */
    public getCuratedSet(id: number, options?: any) {
        return CuratedSetApiFp(this.configuration).getCuratedSet(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get All Curated Set Categories
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedSetApi
     */
    public getCuratedSetCategories(library?: string, options?: any) {
        return CuratedSetApiFp(this.configuration).getCuratedSetCategories(library, options)(this.fetch, this.basePath);
    }

    /**
     * Note: This endpoint does not have a default limit!
     * @summary Get All Curated Sets
     * @param {number} [limit] This endpoint does not have a default limit!
     * @param {number} [skip] 
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedSetApi
     */
    public getCuratedSets(limit?: number, skip?: number, library?: string, options?: any) {
        return CuratedSetApiFp(this.configuration).getCuratedSets(limit, skip, library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Curated Sets By Category Id
     * @param {number} id The curated set category Id
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedSetApi
     */
    public getCuratedSetsByCatId(id: number, library?: string, options?: any) {
        return CuratedSetApiFp(this.configuration).getCuratedSetsByCatId(id, library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Curated Sets By Category Slug
     * @param {string} slug The curated set category slug
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedSetApi
     */
    public getCuratedSetsByCatSlug(slug: string, library?: string, options?: any) {
        return CuratedSetApiFp(this.configuration).getCuratedSetsByCatSlug(slug, library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Curated Sets By Curator
     * @param {string} curator The curated set curator
     * @param {string} [exclude] The curated set slug to exclude
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedSetApi
     */
    public getCuratedSetsByCurator(curator: string, exclude?: string, library?: string, options?: any) {
        return CuratedSetApiFp(this.configuration).getCuratedSetsByCurator(curator, exclude, library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Curated Set By Slug
     * @param {string} slug The curated set slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedSetApi
     */
    public getCuratedSetsBySlug(slug: string, options?: any) {
        return CuratedSetApiFp(this.configuration).getCuratedSetsBySlug(slug, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get followed Curated Sets
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedSetApi
     */
    public getFollowedCuratedSets(library?: string, options?: any) {
        return CuratedSetApiFp(this.configuration).getFollowedCuratedSets(library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all trending Curated Sets
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedSetApi
     */
    public getTrendingCuratedSets(limit?: number, skip?: number, library?: string, options?: any) {
        return CuratedSetApiFp(this.configuration).getTrendingCuratedSets(limit, skip, library, options)(this.fetch, this.basePath);
    }

    /**
     * Note: sort_by is NOT supported on this endpoint
     * @summary Search Curated Sets
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
     * @param {StringOrStringArray} [version] version names(s) (can be multiple)
     * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {number} [bpm_min] 
     * @param {number} [bpm_max] Maxmium is 250
     * @param {string} [term] Search term
     * @param {string} [date] Date string (Example: 2021-09-10)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedSetApi
     */
    public searchCuratedSets(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options?: any) {
        return CuratedSetApiFp(this.configuration).searchCuratedSets(limit, skip, category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, term, date, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Unfollow Curated Set
     * @param {number} id The curated set Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CuratedSetApi
     */
    public unfollowCuratedSet(id: number, options?: any) {
        return CuratedSetApiFp(this.configuration).unfollowCuratedSet(id, options)(this.fetch, this.basePath);
    }

}
/**
 * FeaturedApi - fetch parameter creator
 * @export
 */
export const FeaturedApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Artist Featured In
         * @param {number} id The artist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistFeaturedIn(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getArtistFeaturedIn.');
            }
            const localVarPath = `/artists/{id}/featured-in`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get all featured BPM Original Media
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedBpmoriginalMedia(limit?: number, skip?: number, keyType?: string, sort_by?: SortByQueryOptions, library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/media/featured-bpm-originals`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all featured Media
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaFeatured(library?: string, keyType?: string, sort_by?: SortByQueryOptions, options: any = {}): FetchArgs {
            const localVarPath = `/media/featured`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeaturedApi - functional programming interface
 * @export
 */
export const FeaturedApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Artist Featured In
         * @param {number} id The artist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistFeaturedIn(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20026> {
            const localVarFetchArgs = FeaturedApiFetchParamCreator(configuration).getArtistFeaturedIn(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get all featured BPM Original Media
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedBpmoriginalMedia(limit?: number, skip?: number, keyType?: string, sort_by?: SortByQueryOptions, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2004> {
            const localVarFetchArgs = FeaturedApiFetchParamCreator(configuration).getFeaturedBpmoriginalMedia(limit, skip, keyType, sort_by, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all featured Media
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaFeatured(library?: string, keyType?: string, sort_by?: SortByQueryOptions, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = FeaturedApiFetchParamCreator(configuration).getMediaFeatured(library, keyType, sort_by, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * FeaturedApi - factory interface
 * @export
 */
export const FeaturedApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get Artist Featured In
         * @param {number} id The artist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistFeaturedIn(id: number, options?: any) {
            return FeaturedApiFp(configuration).getArtistFeaturedIn(id, options)(fetch, basePath);
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get all featured BPM Original Media
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedBpmoriginalMedia(limit?: number, skip?: number, keyType?: string, sort_by?: SortByQueryOptions, library?: string, options?: any) {
            return FeaturedApiFp(configuration).getFeaturedBpmoriginalMedia(limit, skip, keyType, sort_by, library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all featured Media
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaFeatured(library?: string, keyType?: string, sort_by?: SortByQueryOptions, options?: any) {
            return FeaturedApiFp(configuration).getMediaFeatured(library, keyType, sort_by, options)(fetch, basePath);
        },
    };
};

/**
 * FeaturedApi - object-oriented interface
 * @export
 * @class FeaturedApi
 * @extends {BaseAPI}
 */
export class FeaturedApi extends BaseAPI {
    /**
     * 
     * @summary Get Artist Featured In
     * @param {number} id The artist Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturedApi
     */
    public getArtistFeaturedIn(id: number, options?: any) {
        return FeaturedApiFp(this.configuration).getArtistFeaturedIn(id, options)(this.fetch, this.basePath);
    }

    /**
     * Default filters based on susbcription and region are applied automatic.
     * @summary Get all featured BPM Original Media
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturedApi
     */
    public getFeaturedBpmoriginalMedia(limit?: number, skip?: number, keyType?: string, sort_by?: SortByQueryOptions, library?: string, options?: any) {
        return FeaturedApiFp(this.configuration).getFeaturedBpmoriginalMedia(limit, skip, keyType, sort_by, library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all featured Media
     * @param {string} [library] Default is all to the user available libraries
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturedApi
     */
    public getMediaFeatured(library?: string, keyType?: string, sort_by?: SortByQueryOptions, options?: any) {
        return FeaturedApiFp(this.configuration).getMediaFeatured(library, keyType, sort_by, options)(this.fetch, this.basePath);
    }

}
/**
 * MediaApi - fetch parameter creator
 * @export
 */
export const MediaApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Count Albums
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countAlbums(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options: any = {}): FetchArgs {
            const localVarPath = `/albums/count`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Favorite Media
         * @param {number} id The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteMedia(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling favoriteMedia.');
            }
            const localVarPath = `/media/{id}/favorite`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Favorite multiple Media
         * @param {FavoriteBatchBody1} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteMediaBatch(body?: FavoriteBatchBody1, options: any = {}): FetchArgs {
            const localVarPath = `/media/favorite/batch`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'FavoriteBatchBody1' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Album By Id
         * @param {number} id The album Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlbum(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAlbum.');
            }
            const localVarPath = `/album/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Get all trending Albums
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlbumTrending(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/albums/trending`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get favorited Media
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavMedia(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/media/favorited`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get all featured BPM Original Media
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedBpmoriginalMedia(limit?: number, skip?: number, keyType?: string, sort_by?: SortByQueryOptions, library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/media/featured-bpm-originals`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Genres
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenres(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/genres`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKeys(options: any = {}): FetchArgs {
            const localVarPath = `/keys`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Media By Id
         * @param {number} id The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedia(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getMedia.');
            }
            const localVarPath = `/media/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all featured Media
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaFeatured(library?: string, keyType?: string, sort_by?: SortByQueryOptions, options: any = {}): FetchArgs {
            const localVarPath = `/media/featured`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get 10 media for every category
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaForCategory(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, sort_by?: SortByQueryOptions, term?: string, date?: string, options: any = {}): FetchArgs {
            const localVarPath = `/media/by-category`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get trending Media
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingMedia(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/media/trending`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get trending Media by period
         * @param {string} period The period
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingMediaPeriod(period: string, limit?: number, skip?: number, category?: StringOrStringArray, library?: string, options: any = {}): FetchArgs {
            // verify required parameter 'period' is not null or undefined
            if (period === null || period === undefined) {
                throw new RequiredError('period','Required parameter period was null or undefined when calling getTrendingMediaPeriod.');
            }
            const localVarPath = `/media/{period}/trending`
                .replace(`{${'period'}}`, encodeURIComponent(String(period)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Versions
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersions(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/versions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Report media
         * @param {number} id The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportMedia(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling reportMedia.');
            }
            const localVarPath = `/media/{id}/report`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Calling this endpoint is required after a media has been played!
         * @summary Report media play
         * @param {number} id The media Id
         * @param {IdPlayBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportMediaPlay(id: number, body?: IdPlayBody, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling reportMediaPlay.');
            }
            const localVarPath = `/media/{id}/play`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'IdPlayBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Search for Albums
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAlbum(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, sort_by?: SortByQueryOptions, term?: string, date?: string, options: any = {}): FetchArgs {
            const localVarPath = `/albums`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search Genres
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGenres(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options: any = {}): FetchArgs {
            const localVarPath = `/genres/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Search for Media
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMedia(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, sort_by?: SortByQueryOptions, term?: string, date?: string, options: any = {}): FetchArgs {
            const localVarPath = `/media`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Similar Media for Media
         * @param {number} id The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        similarMedia(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling similarMedia.');
            }
            const localVarPath = `/media/{id}/similar`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Suggest Media and Artist based on term
         * @param {string} term Search term
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestMediaArtist(term: string, library?: string, keyType?: string, options: any = {}): FetchArgs {
            // verify required parameter 'term' is not null or undefined
            if (term === null || term === undefined) {
                throw new RequiredError('term','Required parameter term was null or undefined when calling suggestMediaArtist.');
            }
            const localVarPath = `/suggest`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unfavorite Media
         * @param {number} id The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfavoriteMedia(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling unfavoriteMedia.');
            }
            const localVarPath = `/media/{id}/favorite`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MediaApi - functional programming interface
 * @export
 */
export const MediaApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Count Albums
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countAlbums(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2001> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).countAlbums(category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, term, date, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Favorite Media
         * @param {number} id The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteMedia(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).favoriteMedia(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Favorite multiple Media
         * @param {FavoriteBatchBody1} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteMediaBatch(body?: FavoriteBatchBody1, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).favoriteMediaBatch(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Album By Id
         * @param {number} id The album Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlbum(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2002> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).getAlbum(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Get all trending Albums
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlbumTrending(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse200> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).getAlbumTrending(limit, skip, category, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get All Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20021> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).getCategories(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get favorited Media
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavMedia(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).getFavMedia(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get all featured BPM Original Media
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedBpmoriginalMedia(limit?: number, skip?: number, keyType?: string, sort_by?: SortByQueryOptions, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2004> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).getFeaturedBpmoriginalMedia(limit, skip, keyType, sort_by, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get All Genres
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenres(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20022> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).getGenres(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get All Keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKeys(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20023> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).getKeys(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Media By Id
         * @param {number} id The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedia(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2006> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).getMedia(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all featured Media
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaFeatured(library?: string, keyType?: string, sort_by?: SortByQueryOptions, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).getMediaFeatured(library, keyType, sort_by, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get 10 media for every category
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaForCategory(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, sort_by?: SortByQueryOptions, term?: string, date?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).getMediaForCategory(category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, sort_by, term, date, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get trending Media
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingMedia(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2004> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).getTrendingMedia(limit, skip, category, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get trending Media by period
         * @param {string} period The period
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingMediaPeriod(period: string, limit?: number, skip?: number, category?: StringOrStringArray, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2004> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).getTrendingMediaPeriod(period, limit, skip, category, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get All Versions
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersions(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20023> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).getVersions(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Report media
         * @param {number} id The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportMedia(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).reportMedia(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Calling this endpoint is required after a media has been played!
         * @summary Report media play
         * @param {number} id The media Id
         * @param {IdPlayBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportMediaPlay(id: number, body?: IdPlayBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).reportMediaPlay(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Search for Albums
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAlbum(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, sort_by?: SortByQueryOptions, term?: string, date?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse200> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).searchAlbum(limit, skip, category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, sort_by, term, date, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Search Genres
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGenres(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20022> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).searchGenres(category, library, genre, artist, version, key, bpm_min, bpm_max, term, date, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Search for Media
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMedia(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, sort_by?: SortByQueryOptions, term?: string, date?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2004> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).searchMedia(limit, skip, category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, sort_by, term, date, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Similar Media for Media
         * @param {number} id The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        similarMedia(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).similarMedia(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Suggest Media and Artist based on term
         * @param {string} term Search term
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestMediaArtist(term: string, library?: string, keyType?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20030> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).suggestMediaArtist(term, library, keyType, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Unfavorite Media
         * @param {number} id The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfavoriteMedia(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = MediaApiFetchParamCreator(configuration).unfavoriteMedia(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * MediaApi - factory interface
 * @export
 */
export const MediaApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Count Albums
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countAlbums(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options?: any) {
            return MediaApiFp(configuration).countAlbums(category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, term, date, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Favorite Media
         * @param {number} id The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteMedia(id: number, options?: any) {
            return MediaApiFp(configuration).favoriteMedia(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Favorite multiple Media
         * @param {FavoriteBatchBody1} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteMediaBatch(body?: FavoriteBatchBody1, options?: any) {
            return MediaApiFp(configuration).favoriteMediaBatch(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Album By Id
         * @param {number} id The album Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlbum(id: number, options?: any) {
            return MediaApiFp(configuration).getAlbum(id, options)(fetch, basePath);
        },
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Get all trending Albums
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlbumTrending(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, options?: any) {
            return MediaApiFp(configuration).getAlbumTrending(limit, skip, category, library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get All Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories(library?: string, options?: any) {
            return MediaApiFp(configuration).getCategories(library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get favorited Media
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavMedia(library?: string, options?: any) {
            return MediaApiFp(configuration).getFavMedia(library, options)(fetch, basePath);
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get all featured BPM Original Media
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedBpmoriginalMedia(limit?: number, skip?: number, keyType?: string, sort_by?: SortByQueryOptions, library?: string, options?: any) {
            return MediaApiFp(configuration).getFeaturedBpmoriginalMedia(limit, skip, keyType, sort_by, library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get All Genres
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenres(library?: string, options?: any) {
            return MediaApiFp(configuration).getGenres(library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get All Keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKeys(options?: any) {
            return MediaApiFp(configuration).getKeys(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Media By Id
         * @param {number} id The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedia(id: number, options?: any) {
            return MediaApiFp(configuration).getMedia(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all featured Media
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaFeatured(library?: string, keyType?: string, sort_by?: SortByQueryOptions, options?: any) {
            return MediaApiFp(configuration).getMediaFeatured(library, keyType, sort_by, options)(fetch, basePath);
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get 10 media for every category
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaForCategory(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, sort_by?: SortByQueryOptions, term?: string, date?: string, options?: any) {
            return MediaApiFp(configuration).getMediaForCategory(category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, sort_by, term, date, options)(fetch, basePath);
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get trending Media
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingMedia(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, options?: any) {
            return MediaApiFp(configuration).getTrendingMedia(limit, skip, category, library, options)(fetch, basePath);
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get trending Media by period
         * @param {string} period The period
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingMediaPeriod(period: string, limit?: number, skip?: number, category?: StringOrStringArray, library?: string, options?: any) {
            return MediaApiFp(configuration).getTrendingMediaPeriod(period, limit, skip, category, library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get All Versions
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersions(library?: string, options?: any) {
            return MediaApiFp(configuration).getVersions(library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Report media
         * @param {number} id The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportMedia(id: number, options?: any) {
            return MediaApiFp(configuration).reportMedia(id, options)(fetch, basePath);
        },
        /**
         * Calling this endpoint is required after a media has been played!
         * @summary Report media play
         * @param {number} id The media Id
         * @param {IdPlayBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportMediaPlay(id: number, body?: IdPlayBody, options?: any) {
            return MediaApiFp(configuration).reportMediaPlay(id, body, options)(fetch, basePath);
        },
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Search for Albums
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAlbum(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, sort_by?: SortByQueryOptions, term?: string, date?: string, options?: any) {
            return MediaApiFp(configuration).searchAlbum(limit, skip, category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, sort_by, term, date, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Search Genres
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGenres(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options?: any) {
            return MediaApiFp(configuration).searchGenres(category, library, genre, artist, version, key, bpm_min, bpm_max, term, date, options)(fetch, basePath);
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Search for Media
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMedia(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, sort_by?: SortByQueryOptions, term?: string, date?: string, options?: any) {
            return MediaApiFp(configuration).searchMedia(limit, skip, category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, sort_by, term, date, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Similar Media for Media
         * @param {number} id The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        similarMedia(id: number, options?: any) {
            return MediaApiFp(configuration).similarMedia(id, options)(fetch, basePath);
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Suggest Media and Artist based on term
         * @param {string} term Search term
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestMediaArtist(term: string, library?: string, keyType?: string, options?: any) {
            return MediaApiFp(configuration).suggestMediaArtist(term, library, keyType, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Unfavorite Media
         * @param {number} id The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfavoriteMedia(id: number, options?: any) {
            return MediaApiFp(configuration).unfavoriteMedia(id, options)(fetch, basePath);
        },
    };
};

/**
 * MediaApi - object-oriented interface
 * @export
 * @class MediaApi
 * @extends {BaseAPI}
 */
export class MediaApi extends BaseAPI {
    /**
     * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
     * @summary Count Albums
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
     * @param {StringOrStringArray} [version] version names(s) (can be multiple)
     * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {number} [bpm_min] 
     * @param {number} [bpm_max] Maxmium is 250
     * @param {string} [term] Search term
     * @param {string} [date] Date string (Example: 2021-09-10)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public countAlbums(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options?: any) {
        return MediaApiFp(this.configuration).countAlbums(category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, term, date, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Favorite Media
     * @param {number} id The media Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public favoriteMedia(id: number, options?: any) {
        return MediaApiFp(this.configuration).favoriteMedia(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Favorite multiple Media
     * @param {FavoriteBatchBody1} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public favoriteMediaBatch(body?: FavoriteBatchBody1, options?: any) {
        return MediaApiFp(this.configuration).favoriteMediaBatch(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Album By Id
     * @param {number} id The album Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getAlbum(id: number, options?: any) {
        return MediaApiFp(this.configuration).getAlbum(id, options)(this.fetch, this.basePath);
    }

    /**
     * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
     * @summary Get all trending Albums
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getAlbumTrending(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, options?: any) {
        return MediaApiFp(this.configuration).getAlbumTrending(limit, skip, category, library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get All Categories
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getCategories(library?: string, options?: any) {
        return MediaApiFp(this.configuration).getCategories(library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get favorited Media
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getFavMedia(library?: string, options?: any) {
        return MediaApiFp(this.configuration).getFavMedia(library, options)(this.fetch, this.basePath);
    }

    /**
     * Default filters based on susbcription and region are applied automatic.
     * @summary Get all featured BPM Original Media
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getFeaturedBpmoriginalMedia(limit?: number, skip?: number, keyType?: string, sort_by?: SortByQueryOptions, library?: string, options?: any) {
        return MediaApiFp(this.configuration).getFeaturedBpmoriginalMedia(limit, skip, keyType, sort_by, library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get All Genres
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getGenres(library?: string, options?: any) {
        return MediaApiFp(this.configuration).getGenres(library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get All Keys
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getKeys(options?: any) {
        return MediaApiFp(this.configuration).getKeys(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Media By Id
     * @param {number} id The media Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getMedia(id: number, options?: any) {
        return MediaApiFp(this.configuration).getMedia(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all featured Media
     * @param {string} [library] Default is all to the user available libraries
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getMediaFeatured(library?: string, keyType?: string, sort_by?: SortByQueryOptions, options?: any) {
        return MediaApiFp(this.configuration).getMediaFeatured(library, keyType, sort_by, options)(this.fetch, this.basePath);
    }

    /**
     * Default filters based on susbcription and region are applied automatic.
     * @summary Get 10 media for every category
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
     * @param {StringOrStringArray} [version] version names(s) (can be multiple)
     * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {number} [bpm_min] 
     * @param {number} [bpm_max] Maxmium is 250
     * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
     * @param {string} [term] Search term
     * @param {string} [date] Date string (Example: 2021-09-10)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getMediaForCategory(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, sort_by?: SortByQueryOptions, term?: string, date?: string, options?: any) {
        return MediaApiFp(this.configuration).getMediaForCategory(category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, sort_by, term, date, options)(this.fetch, this.basePath);
    }

    /**
     * Default filters based on susbcription and region are applied automatic.
     * @summary Get trending Media
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getTrendingMedia(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, options?: any) {
        return MediaApiFp(this.configuration).getTrendingMedia(limit, skip, category, library, options)(this.fetch, this.basePath);
    }

    /**
     * Default filters based on susbcription and region are applied automatic.
     * @summary Get trending Media by period
     * @param {string} period The period
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getTrendingMediaPeriod(period: string, limit?: number, skip?: number, category?: StringOrStringArray, library?: string, options?: any) {
        return MediaApiFp(this.configuration).getTrendingMediaPeriod(period, limit, skip, category, library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get All Versions
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getVersions(library?: string, options?: any) {
        return MediaApiFp(this.configuration).getVersions(library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Report media
     * @param {number} id The media Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public reportMedia(id: number, options?: any) {
        return MediaApiFp(this.configuration).reportMedia(id, options)(this.fetch, this.basePath);
    }

    /**
     * Calling this endpoint is required after a media has been played!
     * @summary Report media play
     * @param {number} id The media Id
     * @param {IdPlayBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public reportMediaPlay(id: number, body?: IdPlayBody, options?: any) {
        return MediaApiFp(this.configuration).reportMediaPlay(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
     * @summary Search for Albums
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
     * @param {StringOrStringArray} [version] version names(s) (can be multiple)
     * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {number} [bpm_min] 
     * @param {number} [bpm_max] Maxmium is 250
     * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
     * @param {string} [term] Search term
     * @param {string} [date] Date string (Example: 2021-09-10)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public searchAlbum(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, sort_by?: SortByQueryOptions, term?: string, date?: string, options?: any) {
        return MediaApiFp(this.configuration).searchAlbum(limit, skip, category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, sort_by, term, date, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Search Genres
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
     * @param {StringOrStringArray} [version] version names(s) (can be multiple)
     * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
     * @param {number} [bpm_min] 
     * @param {number} [bpm_max] Maxmium is 250
     * @param {string} [term] Search term
     * @param {string} [date] Date string (Example: 2021-09-10)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public searchGenres(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options?: any) {
        return MediaApiFp(this.configuration).searchGenres(category, library, genre, artist, version, key, bpm_min, bpm_max, term, date, options)(this.fetch, this.basePath);
    }

    /**
     * Default filters based on susbcription and region are applied automatic.
     * @summary Search for Media
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
     * @param {StringOrStringArray} [version] version names(s) (can be multiple)
     * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {number} [bpm_min] 
     * @param {number} [bpm_max] Maxmium is 250
     * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
     * @param {string} [term] Search term
     * @param {string} [date] Date string (Example: 2021-09-10)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public searchMedia(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, sort_by?: SortByQueryOptions, term?: string, date?: string, options?: any) {
        return MediaApiFp(this.configuration).searchMedia(limit, skip, category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, sort_by, term, date, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Similar Media for Media
     * @param {number} id The media Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public similarMedia(id: number, options?: any) {
        return MediaApiFp(this.configuration).similarMedia(id, options)(this.fetch, this.basePath);
    }

    /**
     * Default filters based on susbcription and region are applied automatic.
     * @summary Suggest Media and Artist based on term
     * @param {string} term Search term
     * @param {string} [library] Default is all to the user available libraries
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public suggestMediaArtist(term: string, library?: string, keyType?: string, options?: any) {
        return MediaApiFp(this.configuration).suggestMediaArtist(term, library, keyType, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Unfavorite Media
     * @param {number} id The media Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public unfavoriteMedia(id: number, options?: any) {
        return MediaApiFp(this.configuration).unfavoriteMedia(id, options)(this.fetch, this.basePath);
    }

}
/**
 * MetaApi - fetch parameter creator
 * @export
 */
export const MetaApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create User Playlist Category
         * @param {UserplaylistcategoriesBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserPlaylistCategory(body?: UserplaylistcategoriesBody, options: any = {}): FetchArgs {
            const localVarPath = `/user-playlist-categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'UserplaylistcategoriesBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete User Playlist Category
         * @param {string} id The user playlist category id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPlaylistCategory(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteUserPlaylistCategory.');
            }
            const localVarPath = `/user-playlist-categories/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Banners
         * @param {string} location The location to load the banners for, like &#x27;browse&#x27; or &#x27;trending&#x27;
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBanner(location: string, library?: string, options: any = {}): FetchArgs {
            // verify required parameter 'location' is not null or undefined
            if (location === null || location === undefined) {
                throw new RequiredError('location','Required parameter location was null or undefined when calling getBanner.');
            }
            const localVarPath = `/banner`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Curated Set Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetCategories(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/curated-set-categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Genres
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenres(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/genres`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKeys(options: any = {}): FetchArgs {
            const localVarPath = `/keys`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Playlist Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistCategories(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/playlist-categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All User Playlist Categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistCategories(options: any = {}): FetchArgs {
            const localVarPath = `/user-playlist-categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User Playlist Category
         * @param {string} id The user playlist category id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistCategory(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getUserPlaylistCategory.');
            }
            const localVarPath = `/user-playlist-categories/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Versions
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersions(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/versions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search Genres
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGenres(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options: any = {}): FetchArgs {
            const localVarPath = `/genres/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update User Playlist Category
         * @param {string} id The user playlist category id
         * @param {UserplaylistcategoriesIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPlaylistCategory(id: string, body?: UserplaylistcategoriesIdBody, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateUserPlaylistCategory.');
            }
            const localVarPath = `/user-playlist-categories/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'UserplaylistcategoriesIdBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetaApi - functional programming interface
 * @export
 */
export const MetaApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create User Playlist Category
         * @param {UserplaylistcategoriesBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserPlaylistCategory(body?: UserplaylistcategoriesBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse201> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).createUserPlaylistCategory(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete User Playlist Category
         * @param {string} id The user playlist category id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPlaylistCategory(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20014> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).deleteUserPlaylistCategory(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Banners
         * @param {string} location The location to load the banners for, like &#x27;browse&#x27; or &#x27;trending&#x27;
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBanner(location: string, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20024> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).getBanner(location, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get All Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20021> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).getCategories(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get All Curated Set Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetCategories(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20019> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).getCuratedSetCategories(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get All Genres
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenres(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20022> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).getGenres(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get All Keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKeys(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20023> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).getKeys(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get All Playlist Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistCategories(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20018> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).getPlaylistCategories(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get All User Playlist Categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistCategories(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20020> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).getUserPlaylistCategories(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get User Playlist Category
         * @param {string} id The user playlist category id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistCategory(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse201> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).getUserPlaylistCategory(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get All Versions
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersions(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20023> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).getVersions(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Search Genres
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGenres(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20022> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).searchGenres(category, library, genre, artist, version, key, bpm_min, bpm_max, term, date, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update User Playlist Category
         * @param {string} id The user playlist category id
         * @param {UserplaylistcategoriesIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPlaylistCategory(id: string, body?: UserplaylistcategoriesIdBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse201> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).updateUserPlaylistCategory(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * MetaApi - factory interface
 * @export
 */
export const MetaApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Create User Playlist Category
         * @param {UserplaylistcategoriesBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserPlaylistCategory(body?: UserplaylistcategoriesBody, options?: any) {
            return MetaApiFp(configuration).createUserPlaylistCategory(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete User Playlist Category
         * @param {string} id The user playlist category id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPlaylistCategory(id: string, options?: any) {
            return MetaApiFp(configuration).deleteUserPlaylistCategory(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Banners
         * @param {string} location The location to load the banners for, like &#x27;browse&#x27; or &#x27;trending&#x27;
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBanner(location: string, library?: string, options?: any) {
            return MetaApiFp(configuration).getBanner(location, library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get All Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories(library?: string, options?: any) {
            return MetaApiFp(configuration).getCategories(library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get All Curated Set Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedSetCategories(library?: string, options?: any) {
            return MetaApiFp(configuration).getCuratedSetCategories(library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get All Genres
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenres(library?: string, options?: any) {
            return MetaApiFp(configuration).getGenres(library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get All Keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKeys(options?: any) {
            return MetaApiFp(configuration).getKeys(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get All Playlist Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistCategories(library?: string, options?: any) {
            return MetaApiFp(configuration).getPlaylistCategories(library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get All User Playlist Categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistCategories(options?: any) {
            return MetaApiFp(configuration).getUserPlaylistCategories(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get User Playlist Category
         * @param {string} id The user playlist category id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistCategory(id: string, options?: any) {
            return MetaApiFp(configuration).getUserPlaylistCategory(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get All Versions
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersions(library?: string, options?: any) {
            return MetaApiFp(configuration).getVersions(library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Search Genres
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGenres(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options?: any) {
            return MetaApiFp(configuration).searchGenres(category, library, genre, artist, version, key, bpm_min, bpm_max, term, date, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update User Playlist Category
         * @param {string} id The user playlist category id
         * @param {UserplaylistcategoriesIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPlaylistCategory(id: string, body?: UserplaylistcategoriesIdBody, options?: any) {
            return MetaApiFp(configuration).updateUserPlaylistCategory(id, body, options)(fetch, basePath);
        },
    };
};

/**
 * MetaApi - object-oriented interface
 * @export
 * @class MetaApi
 * @extends {BaseAPI}
 */
export class MetaApi extends BaseAPI {
    /**
     * 
     * @summary Create User Playlist Category
     * @param {UserplaylistcategoriesBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public createUserPlaylistCategory(body?: UserplaylistcategoriesBody, options?: any) {
        return MetaApiFp(this.configuration).createUserPlaylistCategory(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete User Playlist Category
     * @param {string} id The user playlist category id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public deleteUserPlaylistCategory(id: string, options?: any) {
        return MetaApiFp(this.configuration).deleteUserPlaylistCategory(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Banners
     * @param {string} location The location to load the banners for, like &#x27;browse&#x27; or &#x27;trending&#x27;
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public getBanner(location: string, library?: string, options?: any) {
        return MetaApiFp(this.configuration).getBanner(location, library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get All Categories
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public getCategories(library?: string, options?: any) {
        return MetaApiFp(this.configuration).getCategories(library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get All Curated Set Categories
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public getCuratedSetCategories(library?: string, options?: any) {
        return MetaApiFp(this.configuration).getCuratedSetCategories(library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get All Genres
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public getGenres(library?: string, options?: any) {
        return MetaApiFp(this.configuration).getGenres(library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get All Keys
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public getKeys(options?: any) {
        return MetaApiFp(this.configuration).getKeys(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get All Playlist Categories
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public getPlaylistCategories(library?: string, options?: any) {
        return MetaApiFp(this.configuration).getPlaylistCategories(library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get All User Playlist Categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public getUserPlaylistCategories(options?: any) {
        return MetaApiFp(this.configuration).getUserPlaylistCategories(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get User Playlist Category
     * @param {string} id The user playlist category id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public getUserPlaylistCategory(id: string, options?: any) {
        return MetaApiFp(this.configuration).getUserPlaylistCategory(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get All Versions
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public getVersions(library?: string, options?: any) {
        return MetaApiFp(this.configuration).getVersions(library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Search Genres
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
     * @param {StringOrStringArray} [version] version names(s) (can be multiple)
     * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
     * @param {number} [bpm_min] 
     * @param {number} [bpm_max] Maxmium is 250
     * @param {string} [term] Search term
     * @param {string} [date] Date string (Example: 2021-09-10)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public searchGenres(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options?: any) {
        return MetaApiFp(this.configuration).searchGenres(category, library, genre, artist, version, key, bpm_min, bpm_max, term, date, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update User Playlist Category
     * @param {string} id The user playlist category id
     * @param {UserplaylistcategoriesIdBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public updateUserPlaylistCategory(id: string, body?: UserplaylistcategoriesIdBody, options?: any) {
        return MetaApiFp(this.configuration).updateUserPlaylistCategory(id, body, options)(this.fetch, this.basePath);
    }

}
/**
 * OfflineCrateApi - fetch parameter creator
 * @export
 */
export const OfflineCrateApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Media to Offline Crate
         * @param {number} mediaId The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMediaToOfflineCrate(mediaId: number, options: any = {}): FetchArgs {
            // verify required parameter 'mediaId' is not null or undefined
            if (mediaId === null || mediaId === undefined) {
                throw new RequiredError('mediaId','Required parameter mediaId was null or undefined when calling addMediaToOfflineCrate.');
            }
            const localVarPath = `/offline-crate/{mediaId}`
                .replace(`{${'mediaId'}}`, encodeURIComponent(String(mediaId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add multiple Media to Offline Crate
         * @param {OfflinecrateBatchBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMultipleMediaToOfflineCrate(body?: OfflinecrateBatchBody, options: any = {}): FetchArgs {
            const localVarPath = `/offline-crate/batch`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'OfflinecrateBatchBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Offline Crate
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfflineCrate(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/offline-crate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Users offline crate categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfflineCrateCategories(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/offline-crate/categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove Media from Offline Crate
         * @param {number} mediaId The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFromOfflineCrate(mediaId: number, options: any = {}): FetchArgs {
            // verify required parameter 'mediaId' is not null or undefined
            if (mediaId === null || mediaId === undefined) {
                throw new RequiredError('mediaId','Required parameter mediaId was null or undefined when calling removeFromOfflineCrate.');
            }
            const localVarPath = `/offline-crate/{mediaId}`
                .replace(`{${'mediaId'}}`, encodeURIComponent(String(mediaId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove multiple Media from Offline Crate
         * @param {OfflinecrateBatchBody1} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMultipleMediaFromOfflineCrate(body?: OfflinecrateBatchBody1, options: any = {}): FetchArgs {
            const localVarPath = `/offline-crate/batch`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'OfflinecrateBatchBody1' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfflineCrateApi - functional programming interface
 * @export
 */
export const OfflineCrateApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Media to Offline Crate
         * @param {number} mediaId The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMediaToOfflineCrate(mediaId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = OfflineCrateApiFetchParamCreator(configuration).addMediaToOfflineCrate(mediaId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add multiple Media to Offline Crate
         * @param {OfflinecrateBatchBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMultipleMediaToOfflineCrate(body?: OfflinecrateBatchBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = OfflineCrateApiFetchParamCreator(configuration).addMultipleMediaToOfflineCrate(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Offline Crate
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfflineCrate(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = OfflineCrateApiFetchParamCreator(configuration).getOfflineCrate(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Users offline crate categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfflineCrateCategories(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20021> {
            const localVarFetchArgs = OfflineCrateApiFetchParamCreator(configuration).getOfflineCrateCategories(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Remove Media from Offline Crate
         * @param {number} mediaId The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFromOfflineCrate(mediaId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = OfflineCrateApiFetchParamCreator(configuration).removeFromOfflineCrate(mediaId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Remove multiple Media from Offline Crate
         * @param {OfflinecrateBatchBody1} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMultipleMediaFromOfflineCrate(body?: OfflinecrateBatchBody1, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = OfflineCrateApiFetchParamCreator(configuration).removeMultipleMediaFromOfflineCrate(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * OfflineCrateApi - factory interface
 * @export
 */
export const OfflineCrateApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Add Media to Offline Crate
         * @param {number} mediaId The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMediaToOfflineCrate(mediaId: number, options?: any) {
            return OfflineCrateApiFp(configuration).addMediaToOfflineCrate(mediaId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add multiple Media to Offline Crate
         * @param {OfflinecrateBatchBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMultipleMediaToOfflineCrate(body?: OfflinecrateBatchBody, options?: any) {
            return OfflineCrateApiFp(configuration).addMultipleMediaToOfflineCrate(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Offline Crate
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfflineCrate(library?: string, options?: any) {
            return OfflineCrateApiFp(configuration).getOfflineCrate(library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Users offline crate categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfflineCrateCategories(library?: string, options?: any) {
            return OfflineCrateApiFp(configuration).getOfflineCrateCategories(library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Remove Media from Offline Crate
         * @param {number} mediaId The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFromOfflineCrate(mediaId: number, options?: any) {
            return OfflineCrateApiFp(configuration).removeFromOfflineCrate(mediaId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Remove multiple Media from Offline Crate
         * @param {OfflinecrateBatchBody1} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMultipleMediaFromOfflineCrate(body?: OfflinecrateBatchBody1, options?: any) {
            return OfflineCrateApiFp(configuration).removeMultipleMediaFromOfflineCrate(body, options)(fetch, basePath);
        },
    };
};

/**
 * OfflineCrateApi - object-oriented interface
 * @export
 * @class OfflineCrateApi
 * @extends {BaseAPI}
 */
export class OfflineCrateApi extends BaseAPI {
    /**
     * 
     * @summary Add Media to Offline Crate
     * @param {number} mediaId The media Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfflineCrateApi
     */
    public addMediaToOfflineCrate(mediaId: number, options?: any) {
        return OfflineCrateApiFp(this.configuration).addMediaToOfflineCrate(mediaId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add multiple Media to Offline Crate
     * @param {OfflinecrateBatchBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfflineCrateApi
     */
    public addMultipleMediaToOfflineCrate(body?: OfflinecrateBatchBody, options?: any) {
        return OfflineCrateApiFp(this.configuration).addMultipleMediaToOfflineCrate(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Offline Crate
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfflineCrateApi
     */
    public getOfflineCrate(library?: string, options?: any) {
        return OfflineCrateApiFp(this.configuration).getOfflineCrate(library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Users offline crate categories
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfflineCrateApi
     */
    public getOfflineCrateCategories(library?: string, options?: any) {
        return OfflineCrateApiFp(this.configuration).getOfflineCrateCategories(library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Remove Media from Offline Crate
     * @param {number} mediaId The media Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfflineCrateApi
     */
    public removeFromOfflineCrate(mediaId: number, options?: any) {
        return OfflineCrateApiFp(this.configuration).removeFromOfflineCrate(mediaId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Remove multiple Media from Offline Crate
     * @param {OfflinecrateBatchBody1} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfflineCrateApi
     */
    public removeMultipleMediaFromOfflineCrate(body?: OfflinecrateBatchBody1, options?: any) {
        return OfflineCrateApiFp(this.configuration).removeMultipleMediaFromOfflineCrate(body, options)(this.fetch, this.basePath);
    }

}
/**
 * PlaylistApi - fetch parameter creator
 * @export
 */
export const PlaylistApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Follow Playlist
         * @param {number} id The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followPlaylist(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling followPlaylist.');
            }
            const localVarPath = `/playlists/{id}/follow`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Featured Playlists
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedPlaylist(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/playlists/featured`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get followed Playlists
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowedPlaylists(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/playlists/following`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Playlist Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistCategories(library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/playlist-categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Playlist By Id
         * @param {number} id The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSet(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPlaylistSet.');
            }
            const localVarPath = `/playlists/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Playlist By Slug
         * @param {string} slug The playlist slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSetBySlug(slug: string, options: any = {}): FetchArgs {
            // verify required parameter 'slug' is not null or undefined
            if (slug === null || slug === undefined) {
                throw new RequiredError('slug','Required parameter slug was null or undefined when calling getPlaylistSetBySlug.');
            }
            const localVarPath = `/playlists/slug/{slug}`
                .replace(`{${'slug'}}`, encodeURIComponent(String(slug)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Note: This endpoint does not have a default limit!
         * @summary Get All Playlists
         * @param {number} [limit] This endpoint does not have a default limit!
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSets(limit?: number, skip?: number, library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/playlists`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Playlists By Category Id
         * @param {number} id The playlist category Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSetsByCatId(id: number, library?: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPlaylistSetsByCatId.');
            }
            const localVarPath = `/playlist-categories/{id}/playlists`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Playlists By Category Slug
         * @param {string} slug The playlist category slug
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSetsByCatSlug(slug: string, library?: string, options: any = {}): FetchArgs {
            // verify required parameter 'slug' is not null or undefined
            if (slug === null || slug === undefined) {
                throw new RequiredError('slug','Required parameter slug was null or undefined when calling getPlaylistSetsByCatSlug.');
            }
            const localVarPath = `/playlist-categories/{slug}/playlists`
                .replace(`{${'slug'}}`, encodeURIComponent(String(slug)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all trending Playlists
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingPlaylists(limit?: number, skip?: number, library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/playlists/trending`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search Playlists
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPlaylistSets(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options: any = {}): FetchArgs {
            const localVarPath = `/playlists/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unfollow Playlist
         * @param {number} id The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfollowPlaylist(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling unfollowPlaylist.');
            }
            const localVarPath = `/playlists/{id}/follow`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlaylistApi - functional programming interface
 * @export
 */
export const PlaylistApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Follow Playlist
         * @param {number} id The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followPlaylist(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20012> {
            const localVarFetchArgs = PlaylistApiFetchParamCreator(configuration).followPlaylist(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Featured Playlists
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedPlaylist(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20012> {
            const localVarFetchArgs = PlaylistApiFetchParamCreator(configuration).getFeaturedPlaylist(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get followed Playlists
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowedPlaylists(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20012> {
            const localVarFetchArgs = PlaylistApiFetchParamCreator(configuration).getFollowedPlaylists(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get All Playlist Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistCategories(library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20018> {
            const localVarFetchArgs = PlaylistApiFetchParamCreator(configuration).getPlaylistCategories(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Playlist By Id
         * @param {number} id The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSet(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20010> {
            const localVarFetchArgs = PlaylistApiFetchParamCreator(configuration).getPlaylistSet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Playlist By Slug
         * @param {string} slug The playlist slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSetBySlug(slug: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20010> {
            const localVarFetchArgs = PlaylistApiFetchParamCreator(configuration).getPlaylistSetBySlug(slug, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Note: This endpoint does not have a default limit!
         * @summary Get All Playlists
         * @param {number} [limit] This endpoint does not have a default limit!
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSets(limit?: number, skip?: number, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20011> {
            const localVarFetchArgs = PlaylistApiFetchParamCreator(configuration).getPlaylistSets(limit, skip, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Playlists By Category Id
         * @param {number} id The playlist category Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSetsByCatId(id: number, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20012> {
            const localVarFetchArgs = PlaylistApiFetchParamCreator(configuration).getPlaylistSetsByCatId(id, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Playlists By Category Slug
         * @param {string} slug The playlist category slug
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSetsByCatSlug(slug: string, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20012> {
            const localVarFetchArgs = PlaylistApiFetchParamCreator(configuration).getPlaylistSetsByCatSlug(slug, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all trending Playlists
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingPlaylists(limit?: number, skip?: number, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20011> {
            const localVarFetchArgs = PlaylistApiFetchParamCreator(configuration).getTrendingPlaylists(limit, skip, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search Playlists
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPlaylistSets(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20011> {
            const localVarFetchArgs = PlaylistApiFetchParamCreator(configuration).searchPlaylistSets(limit, skip, category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, term, date, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Unfollow Playlist
         * @param {number} id The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfollowPlaylist(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20012> {
            const localVarFetchArgs = PlaylistApiFetchParamCreator(configuration).unfollowPlaylist(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PlaylistApi - factory interface
 * @export
 */
export const PlaylistApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Follow Playlist
         * @param {number} id The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followPlaylist(id: number, options?: any) {
            return PlaylistApiFp(configuration).followPlaylist(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Featured Playlists
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedPlaylist(library?: string, options?: any) {
            return PlaylistApiFp(configuration).getFeaturedPlaylist(library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get followed Playlists
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowedPlaylists(library?: string, options?: any) {
            return PlaylistApiFp(configuration).getFollowedPlaylists(library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get All Playlist Categories
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistCategories(library?: string, options?: any) {
            return PlaylistApiFp(configuration).getPlaylistCategories(library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Playlist By Id
         * @param {number} id The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSet(id: number, options?: any) {
            return PlaylistApiFp(configuration).getPlaylistSet(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Playlist By Slug
         * @param {string} slug The playlist slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSetBySlug(slug: string, options?: any) {
            return PlaylistApiFp(configuration).getPlaylistSetBySlug(slug, options)(fetch, basePath);
        },
        /**
         * Note: This endpoint does not have a default limit!
         * @summary Get All Playlists
         * @param {number} [limit] This endpoint does not have a default limit!
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSets(limit?: number, skip?: number, library?: string, options?: any) {
            return PlaylistApiFp(configuration).getPlaylistSets(limit, skip, library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Playlists By Category Id
         * @param {number} id The playlist category Id
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSetsByCatId(id: number, library?: string, options?: any) {
            return PlaylistApiFp(configuration).getPlaylistSetsByCatId(id, library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Playlists By Category Slug
         * @param {string} slug The playlist category slug
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSetsByCatSlug(slug: string, library?: string, options?: any) {
            return PlaylistApiFp(configuration).getPlaylistSetsByCatSlug(slug, library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all trending Playlists
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingPlaylists(limit?: number, skip?: number, library?: string, options?: any) {
            return PlaylistApiFp(configuration).getTrendingPlaylists(limit, skip, library, options)(fetch, basePath);
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search Playlists
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPlaylistSets(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options?: any) {
            return PlaylistApiFp(configuration).searchPlaylistSets(limit, skip, category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, term, date, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Unfollow Playlist
         * @param {number} id The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfollowPlaylist(id: number, options?: any) {
            return PlaylistApiFp(configuration).unfollowPlaylist(id, options)(fetch, basePath);
        },
    };
};

/**
 * PlaylistApi - object-oriented interface
 * @export
 * @class PlaylistApi
 * @extends {BaseAPI}
 */
export class PlaylistApi extends BaseAPI {
    /**
     * 
     * @summary Follow Playlist
     * @param {number} id The playlist Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaylistApi
     */
    public followPlaylist(id: number, options?: any) {
        return PlaylistApiFp(this.configuration).followPlaylist(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Featured Playlists
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaylistApi
     */
    public getFeaturedPlaylist(library?: string, options?: any) {
        return PlaylistApiFp(this.configuration).getFeaturedPlaylist(library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get followed Playlists
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaylistApi
     */
    public getFollowedPlaylists(library?: string, options?: any) {
        return PlaylistApiFp(this.configuration).getFollowedPlaylists(library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get All Playlist Categories
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaylistApi
     */
    public getPlaylistCategories(library?: string, options?: any) {
        return PlaylistApiFp(this.configuration).getPlaylistCategories(library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Playlist By Id
     * @param {number} id The playlist Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaylistApi
     */
    public getPlaylistSet(id: number, options?: any) {
        return PlaylistApiFp(this.configuration).getPlaylistSet(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Playlist By Slug
     * @param {string} slug The playlist slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaylistApi
     */
    public getPlaylistSetBySlug(slug: string, options?: any) {
        return PlaylistApiFp(this.configuration).getPlaylistSetBySlug(slug, options)(this.fetch, this.basePath);
    }

    /**
     * Note: This endpoint does not have a default limit!
     * @summary Get All Playlists
     * @param {number} [limit] This endpoint does not have a default limit!
     * @param {number} [skip] 
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaylistApi
     */
    public getPlaylistSets(limit?: number, skip?: number, library?: string, options?: any) {
        return PlaylistApiFp(this.configuration).getPlaylistSets(limit, skip, library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Playlists By Category Id
     * @param {number} id The playlist category Id
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaylistApi
     */
    public getPlaylistSetsByCatId(id: number, library?: string, options?: any) {
        return PlaylistApiFp(this.configuration).getPlaylistSetsByCatId(id, library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Playlists By Category Slug
     * @param {string} slug The playlist category slug
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaylistApi
     */
    public getPlaylistSetsByCatSlug(slug: string, library?: string, options?: any) {
        return PlaylistApiFp(this.configuration).getPlaylistSetsByCatSlug(slug, library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all trending Playlists
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaylistApi
     */
    public getTrendingPlaylists(limit?: number, skip?: number, library?: string, options?: any) {
        return PlaylistApiFp(this.configuration).getTrendingPlaylists(limit, skip, library, options)(this.fetch, this.basePath);
    }

    /**
     * Note: sort_by is NOT supported on this endpoint
     * @summary Search Playlists
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
     * @param {StringOrStringArray} [version] version names(s) (can be multiple)
     * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {number} [bpm_min] 
     * @param {number} [bpm_max] Maxmium is 250
     * @param {string} [term] Search term
     * @param {string} [date] Date string (Example: 2021-09-10)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaylistApi
     */
    public searchPlaylistSets(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options?: any) {
        return PlaylistApiFp(this.configuration).searchPlaylistSets(limit, skip, category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, term, date, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Unfollow Playlist
     * @param {number} id The playlist Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaylistApi
     */
    public unfollowPlaylist(id: number, options?: any) {
        return PlaylistApiFp(this.configuration).unfollowPlaylist(id, options)(this.fetch, this.basePath);
    }

}
/**
 * ReportApi - fetch parameter creator
 * @export
 */
export const ReportApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Report media
         * @param {number} id The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportMedia(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling reportMedia.');
            }
            const localVarPath = `/media/{id}/report`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Calling this endpoint is required after a media has been played!
         * @summary Report media play
         * @param {number} id The media Id
         * @param {IdPlayBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportMediaPlay(id: number, body?: IdPlayBody, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling reportMediaPlay.');
            }
            const localVarPath = `/media/{id}/play`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'IdPlayBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Report media
         * @param {number} id The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportMedia(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = ReportApiFetchParamCreator(configuration).reportMedia(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Calling this endpoint is required after a media has been played!
         * @summary Report media play
         * @param {number} id The media Id
         * @param {IdPlayBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportMediaPlay(id: number, body?: IdPlayBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = ReportApiFetchParamCreator(configuration).reportMediaPlay(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Report media
         * @param {number} id The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportMedia(id: number, options?: any) {
            return ReportApiFp(configuration).reportMedia(id, options)(fetch, basePath);
        },
        /**
         * Calling this endpoint is required after a media has been played!
         * @summary Report media play
         * @param {number} id The media Id
         * @param {IdPlayBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportMediaPlay(id: number, body?: IdPlayBody, options?: any) {
            return ReportApiFp(configuration).reportMediaPlay(id, body, options)(fetch, basePath);
        },
    };
};

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
    /**
     * 
     * @summary Report media
     * @param {number} id The media Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportMedia(id: number, options?: any) {
        return ReportApiFp(this.configuration).reportMedia(id, options)(this.fetch, this.basePath);
    }

    /**
     * Calling this endpoint is required after a media has been played!
     * @summary Report media play
     * @param {number} id The media Id
     * @param {IdPlayBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportMediaPlay(id: number, body?: IdPlayBody, options?: any) {
        return ReportApiFp(this.configuration).reportMediaPlay(id, body, options)(this.fetch, this.basePath);
    }

}
/**
 * SearchApi - fetch parameter creator
 * @export
 */
export const SearchApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Count Albums
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countAlbums(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options: any = {}): FetchArgs {
            const localVarPath = `/albums/count`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get total counts per library
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counts(category?: StringOrStringArray, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options: any = {}): FetchArgs {
            const localVarPath = `/counts`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Search for Albums
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAlbum(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, sort_by?: SortByQueryOptions, term?: string, date?: string, options: any = {}): FetchArgs {
            const localVarPath = `/albums`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search for Artists
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchArtist(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options: any = {}): FetchArgs {
            const localVarPath = `/artists`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search Curated Sets
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCuratedSets(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options: any = {}): FetchArgs {
            const localVarPath = `/curated-sets/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Search for Media
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMedia(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, sort_by?: SortByQueryOptions, term?: string, date?: string, options: any = {}): FetchArgs {
            const localVarPath = `/media`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search Playlists
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPlaylistSets(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options: any = {}): FetchArgs {
            const localVarPath = `/playlists/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (genre !== undefined) {
                localVarQueryParameter['genre'] = genre;
            }

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (bpm_min !== undefined) {
                localVarQueryParameter['bpm_min'] = bpm_min;
            }

            if (bpm_max !== undefined) {
                localVarQueryParameter['bpm_max'] = bpm_max;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Suggest Media and Artist based on term
         * @param {string} term Search term
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestMediaArtist(term: string, library?: string, keyType?: string, options: any = {}): FetchArgs {
            // verify required parameter 'term' is not null or undefined
            if (term === null || term === undefined) {
                throw new RequiredError('term','Required parameter term was null or undefined when calling suggestMediaArtist.');
            }
            const localVarPath = `/suggest`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (keyType !== undefined) {
                localVarQueryParameter['keyType'] = keyType;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Count Albums
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countAlbums(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2001> {
            const localVarFetchArgs = SearchApiFetchParamCreator(configuration).countAlbums(category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, term, date, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get total counts per library
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counts(category?: StringOrStringArray, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20029> {
            const localVarFetchArgs = SearchApiFetchParamCreator(configuration).counts(category, genre, artist, version, key, keyType, bpm_min, bpm_max, term, date, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Search for Albums
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAlbum(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, sort_by?: SortByQueryOptions, term?: string, date?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse200> {
            const localVarFetchArgs = SearchApiFetchParamCreator(configuration).searchAlbum(limit, skip, category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, sort_by, term, date, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search for Artists
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchArtist(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20027> {
            const localVarFetchArgs = SearchApiFetchParamCreator(configuration).searchArtist(limit, skip, category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, term, date, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search Curated Sets
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCuratedSets(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2008> {
            const localVarFetchArgs = SearchApiFetchParamCreator(configuration).searchCuratedSets(limit, skip, category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, term, date, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Search for Media
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMedia(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, sort_by?: SortByQueryOptions, term?: string, date?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2004> {
            const localVarFetchArgs = SearchApiFetchParamCreator(configuration).searchMedia(limit, skip, category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, sort_by, term, date, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search Playlists
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPlaylistSets(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20011> {
            const localVarFetchArgs = SearchApiFetchParamCreator(configuration).searchPlaylistSets(limit, skip, category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, term, date, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Suggest Media and Artist based on term
         * @param {string} term Search term
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestMediaArtist(term: string, library?: string, keyType?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20030> {
            const localVarFetchArgs = SearchApiFetchParamCreator(configuration).suggestMediaArtist(term, library, keyType, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Count Albums
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countAlbums(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options?: any) {
            return SearchApiFp(configuration).countAlbums(category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, term, date, options)(fetch, basePath);
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get total counts per library
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counts(category?: StringOrStringArray, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options?: any) {
            return SearchApiFp(configuration).counts(category, genre, artist, version, key, keyType, bpm_min, bpm_max, term, date, options)(fetch, basePath);
        },
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Search for Albums
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAlbum(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, sort_by?: SortByQueryOptions, term?: string, date?: string, options?: any) {
            return SearchApiFp(configuration).searchAlbum(limit, skip, category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, sort_by, term, date, options)(fetch, basePath);
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search for Artists
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchArtist(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options?: any) {
            return SearchApiFp(configuration).searchArtist(limit, skip, category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, term, date, options)(fetch, basePath);
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search Curated Sets
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCuratedSets(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options?: any) {
            return SearchApiFp(configuration).searchCuratedSets(limit, skip, category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, term, date, options)(fetch, basePath);
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Search for Media
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMedia(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, sort_by?: SortByQueryOptions, term?: string, date?: string, options?: any) {
            return SearchApiFp(configuration).searchMedia(limit, skip, category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, sort_by, term, date, options)(fetch, basePath);
        },
        /**
         * Note: sort_by is NOT supported on this endpoint
         * @summary Search Playlists
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
         * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
         * @param {StringOrStringArray} [version] version names(s) (can be multiple)
         * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {number} [bpm_min] 
         * @param {number} [bpm_max] Maxmium is 250
         * @param {string} [term] Search term
         * @param {string} [date] Date string (Example: 2021-09-10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPlaylistSets(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options?: any) {
            return SearchApiFp(configuration).searchPlaylistSets(limit, skip, category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, term, date, options)(fetch, basePath);
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Suggest Media and Artist based on term
         * @param {string} term Search term
         * @param {string} [library] Default is all to the user available libraries
         * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestMediaArtist(term: string, library?: string, keyType?: string, options?: any) {
            return SearchApiFp(configuration).suggestMediaArtist(term, library, keyType, options)(fetch, basePath);
        },
    };
};

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
    /**
     * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
     * @summary Count Albums
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
     * @param {StringOrStringArray} [version] version names(s) (can be multiple)
     * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {number} [bpm_min] 
     * @param {number} [bpm_max] Maxmium is 250
     * @param {string} [term] Search term
     * @param {string} [date] Date string (Example: 2021-09-10)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public countAlbums(category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options?: any) {
        return SearchApiFp(this.configuration).countAlbums(category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, term, date, options)(this.fetch, this.basePath);
    }

    /**
     * Default filters based on susbcription and region are applied automatic.
     * @summary Get total counts per library
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
     * @param {StringOrStringArray} [version] version names(s) (can be multiple)
     * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {number} [bpm_min] 
     * @param {number} [bpm_max] Maxmium is 250
     * @param {string} [term] Search term
     * @param {string} [date] Date string (Example: 2021-09-10)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public counts(category?: StringOrStringArray, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options?: any) {
        return SearchApiFp(this.configuration).counts(category, genre, artist, version, key, keyType, bpm_min, bpm_max, term, date, options)(this.fetch, this.basePath);
    }

    /**
     * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
     * @summary Search for Albums
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
     * @param {StringOrStringArray} [version] version names(s) (can be multiple)
     * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {number} [bpm_min] 
     * @param {number} [bpm_max] Maxmium is 250
     * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
     * @param {string} [term] Search term
     * @param {string} [date] Date string (Example: 2021-09-10)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchAlbum(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, sort_by?: SortByQueryOptions, term?: string, date?: string, options?: any) {
        return SearchApiFp(this.configuration).searchAlbum(limit, skip, category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, sort_by, term, date, options)(this.fetch, this.basePath);
    }

    /**
     * Note: sort_by is NOT supported on this endpoint
     * @summary Search for Artists
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
     * @param {StringOrStringArray} [version] version names(s) (can be multiple)
     * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {number} [bpm_min] 
     * @param {number} [bpm_max] Maxmium is 250
     * @param {string} [term] Search term
     * @param {string} [date] Date string (Example: 2021-09-10)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchArtist(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options?: any) {
        return SearchApiFp(this.configuration).searchArtist(limit, skip, category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, term, date, options)(this.fetch, this.basePath);
    }

    /**
     * Note: sort_by is NOT supported on this endpoint
     * @summary Search Curated Sets
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
     * @param {StringOrStringArray} [version] version names(s) (can be multiple)
     * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {number} [bpm_min] 
     * @param {number} [bpm_max] Maxmium is 250
     * @param {string} [term] Search term
     * @param {string} [date] Date string (Example: 2021-09-10)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchCuratedSets(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options?: any) {
        return SearchApiFp(this.configuration).searchCuratedSets(limit, skip, category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, term, date, options)(this.fetch, this.basePath);
    }

    /**
     * Default filters based on susbcription and region are applied automatic.
     * @summary Search for Media
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
     * @param {StringOrStringArray} [version] version names(s) (can be multiple)
     * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {number} [bpm_min] 
     * @param {number} [bpm_max] Maxmium is 250
     * @param {SortByQueryOptions} [sort_by] default is Trending DESC. Relevance is only supported on search endpoints!
     * @param {string} [term] Search term
     * @param {string} [date] Date string (Example: 2021-09-10)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchMedia(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, sort_by?: SortByQueryOptions, term?: string, date?: string, options?: any) {
        return SearchApiFp(this.configuration).searchMedia(limit, skip, category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, sort_by, term, date, options)(this.fetch, this.basePath);
    }

    /**
     * Note: sort_by is NOT supported on this endpoint
     * @summary Search Playlists
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {StringOrStringArray} [genre] The album genre slug(s) (can be multiple)
     * @param {StringOrStringArray} [artist] The artist slug(s) (can be multiple)
     * @param {StringOrStringArray} [version] version names(s) (can be multiple)
     * @param {StringOrStringArray} [key] the key(s) in Camelot format (can be multiple)
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {number} [bpm_min] 
     * @param {number} [bpm_max] Maxmium is 250
     * @param {string} [term] Search term
     * @param {string} [date] Date string (Example: 2021-09-10)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchPlaylistSets(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, genre?: StringOrStringArray, artist?: StringOrStringArray, version?: StringOrStringArray, key?: StringOrStringArray, keyType?: string, bpm_min?: number, bpm_max?: number, term?: string, date?: string, options?: any) {
        return SearchApiFp(this.configuration).searchPlaylistSets(limit, skip, category, library, genre, artist, version, key, keyType, bpm_min, bpm_max, term, date, options)(this.fetch, this.basePath);
    }

    /**
     * Default filters based on susbcription and region are applied automatic.
     * @summary Suggest Media and Artist based on term
     * @param {string} term Search term
     * @param {string} [library] Default is all to the user available libraries
     * @param {string} [keyType] Display attribute: Determines how API returns keys (display_key)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public suggestMediaArtist(term: string, library?: string, keyType?: string, options?: any) {
        return SearchApiFp(this.configuration).suggestMediaArtist(term, library, keyType, options)(this.fetch, this.basePath);
    }

}
/**
 * TrendingApi - fetch parameter creator
 * @export
 */
export const TrendingApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Get all trending Albums
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlbumTrending(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/albums/trending`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get trending Albums by period
         * @param {string} period The period
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingAlbumPeriod(period: string, limit?: number, skip?: number, category?: StringOrStringArray, library?: string, options: any = {}): FetchArgs {
            // verify required parameter 'period' is not null or undefined
            if (period === null || period === undefined) {
                throw new RequiredError('period','Required parameter period was null or undefined when calling getTrendingAlbumPeriod.');
            }
            const localVarPath = `/albums/{period}/trending`
                .replace(`{${'period'}}`, encodeURIComponent(String(period)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get trending Media
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingMedia(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/media/trending`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get trending Media by period
         * @param {string} period The period
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingMediaPeriod(period: string, limit?: number, skip?: number, category?: StringOrStringArray, library?: string, options: any = {}): FetchArgs {
            // verify required parameter 'period' is not null or undefined
            if (period === null || period === undefined) {
                throw new RequiredError('period','Required parameter period was null or undefined when calling getTrendingMediaPeriod.');
            }
            const localVarPath = `/media/{period}/trending`
                .replace(`{${'period'}}`, encodeURIComponent(String(period)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all trending Playlists
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingPlaylists(limit?: number, skip?: number, library?: string, options: any = {}): FetchArgs {
            const localVarPath = `/playlists/trending`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrendingApi - functional programming interface
 * @export
 */
export const TrendingApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Get all trending Albums
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlbumTrending(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse200> {
            const localVarFetchArgs = TrendingApiFetchParamCreator(configuration).getAlbumTrending(limit, skip, category, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get trending Albums by period
         * @param {string} period The period
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingAlbumPeriod(period: string, limit?: number, skip?: number, category?: StringOrStringArray, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse200> {
            const localVarFetchArgs = TrendingApiFetchParamCreator(configuration).getTrendingAlbumPeriod(period, limit, skip, category, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get trending Media
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingMedia(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2004> {
            const localVarFetchArgs = TrendingApiFetchParamCreator(configuration).getTrendingMedia(limit, skip, category, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get trending Media by period
         * @param {string} period The period
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingMediaPeriod(period: string, limit?: number, skip?: number, category?: StringOrStringArray, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2004> {
            const localVarFetchArgs = TrendingApiFetchParamCreator(configuration).getTrendingMediaPeriod(period, limit, skip, category, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all trending Playlists
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingPlaylists(limit?: number, skip?: number, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20011> {
            const localVarFetchArgs = TrendingApiFetchParamCreator(configuration).getTrendingPlaylists(limit, skip, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TrendingApi - factory interface
 * @export
 */
export const TrendingApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
         * @summary Get all trending Albums
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlbumTrending(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, options?: any) {
            return TrendingApiFp(configuration).getAlbumTrending(limit, skip, category, library, options)(fetch, basePath);
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get trending Albums by period
         * @param {string} period The period
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingAlbumPeriod(period: string, limit?: number, skip?: number, category?: StringOrStringArray, library?: string, options?: any) {
            return TrendingApiFp(configuration).getTrendingAlbumPeriod(period, limit, skip, category, library, options)(fetch, basePath);
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get trending Media
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingMedia(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, options?: any) {
            return TrendingApiFp(configuration).getTrendingMedia(limit, skip, category, library, options)(fetch, basePath);
        },
        /**
         * Default filters based on susbcription and region are applied automatic.
         * @summary Get trending Media by period
         * @param {string} period The period
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingMediaPeriod(period: string, limit?: number, skip?: number, category?: StringOrStringArray, library?: string, options?: any) {
            return TrendingApiFp(configuration).getTrendingMediaPeriod(period, limit, skip, category, library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all trending Playlists
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {string} [library] Default is all to the user available libraries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendingPlaylists(limit?: number, skip?: number, library?: string, options?: any) {
            return TrendingApiFp(configuration).getTrendingPlaylists(limit, skip, library, options)(fetch, basePath);
        },
    };
};

/**
 * TrendingApi - object-oriented interface
 * @export
 * @class TrendingApi
 * @extends {BaseAPI}
 */
export class TrendingApi extends BaseAPI {
    /**
     * Even if just one track matches, the whole album is returned. Default filters based on susbcription and region are applied automatic.
     * @summary Get all trending Albums
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrendingApi
     */
    public getAlbumTrending(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, options?: any) {
        return TrendingApiFp(this.configuration).getAlbumTrending(limit, skip, category, library, options)(this.fetch, this.basePath);
    }

    /**
     * Default filters based on susbcription and region are applied automatic.
     * @summary Get trending Albums by period
     * @param {string} period The period
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrendingApi
     */
    public getTrendingAlbumPeriod(period: string, limit?: number, skip?: number, category?: StringOrStringArray, library?: string, options?: any) {
        return TrendingApiFp(this.configuration).getTrendingAlbumPeriod(period, limit, skip, category, library, options)(this.fetch, this.basePath);
    }

    /**
     * Default filters based on susbcription and region are applied automatic.
     * @summary Get trending Media
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrendingApi
     */
    public getTrendingMedia(limit?: number, skip?: number, category?: StringOrStringArray, library?: string, options?: any) {
        return TrendingApiFp(this.configuration).getTrendingMedia(limit, skip, category, library, options)(this.fetch, this.basePath);
    }

    /**
     * Default filters based on susbcription and region are applied automatic.
     * @summary Get trending Media by period
     * @param {string} period The period
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {StringOrStringArray} [category] The album category slug(s) (can be multiple)
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrendingApi
     */
    public getTrendingMediaPeriod(period: string, limit?: number, skip?: number, category?: StringOrStringArray, library?: string, options?: any) {
        return TrendingApiFp(this.configuration).getTrendingMediaPeriod(period, limit, skip, category, library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all trending Playlists
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {string} [library] Default is all to the user available libraries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrendingApi
     */
    public getTrendingPlaylists(limit?: number, skip?: number, library?: string, options?: any) {
        return TrendingApiFp(this.configuration).getTrendingPlaylists(limit, skip, library, options)(this.fetch, this.basePath);
    }

}
/**
 * UserPlaylistApi - fetch parameter creator
 * @export
 */
export const UserPlaylistApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Curated Set to User Playlist
         * @param {string} id The user playlist Id
         * @param {number} curatedSetId The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCuratedSetToUserPlaylist(id: string, curatedSetId: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling addCuratedSetToUserPlaylist.');
            }
            // verify required parameter 'curatedSetId' is not null or undefined
            if (curatedSetId === null || curatedSetId === undefined) {
                throw new RequiredError('curatedSetId','Required parameter curatedSetId was null or undefined when calling addCuratedSetToUserPlaylist.');
            }
            const localVarPath = `/user-playlists/{id}/curated-set/{curatedSetId}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'curatedSetId'}}`, encodeURIComponent(String(curatedSetId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add Media to User Playlist
         * @param {string} id The playlist Id
         * @param {number} mediaId The media Id
         * @param {MediaMediaIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMediaToUserPlaylist(id: string, mediaId: number, body?: MediaMediaIdBody, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling addMediaToUserPlaylist.');
            }
            // verify required parameter 'mediaId' is not null or undefined
            if (mediaId === null || mediaId === undefined) {
                throw new RequiredError('mediaId','Required parameter mediaId was null or undefined when calling addMediaToUserPlaylist.');
            }
            const localVarPath = `/user-playlists/{id}/media/{mediaId}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'mediaId'}}`, encodeURIComponent(String(mediaId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'MediaMediaIdBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add multiple Media to User Playlist
         * @param {string} id The playlist Id
         * @param {IdBatchBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMultipleMediaToUserPlaylist(id: string, body?: IdBatchBody, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling addMultipleMediaToUserPlaylist.');
            }
            const localVarPath = `/user-playlists/{id}/batch`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'IdBatchBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add Playlist to User Playlist
         * @param {string} id The user playlist Id
         * @param {number} playlistId The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPlaylistToUserPlaylist(id: string, playlistId: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling addPlaylistToUserPlaylist.');
            }
            // verify required parameter 'playlistId' is not null or undefined
            if (playlistId === null || playlistId === undefined) {
                throw new RequiredError('playlistId','Required parameter playlistId was null or undefined when calling addPlaylistToUserPlaylist.');
            }
            const localVarPath = `/user-playlists/{id}/playlist/{playlistId}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'playlistId'}}`, encodeURIComponent(String(playlistId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create User Playlist
         * @param {number} id The playlist Id
         * @param {IdUserplaylistsBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserPlaylist(id: number, body?: IdUserplaylistsBody, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createUserPlaylist.');
            }
            const localVarPath = `/user-playlist-categories/{id}/user-playlists`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'IdUserplaylistsBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create User Playlist Category
         * @param {UserplaylistcategoriesBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserPlaylistCategory(body?: UserplaylistcategoriesBody, options: any = {}): FetchArgs {
            const localVarPath = `/user-playlist-categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'UserplaylistcategoriesBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Media from User Playlist
         * @param {string} id The playlist Id
         * @param {number} mediaId The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMediaFromUserPlaylist(id: string, mediaId: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteMediaFromUserPlaylist.');
            }
            // verify required parameter 'mediaId' is not null or undefined
            if (mediaId === null || mediaId === undefined) {
                throw new RequiredError('mediaId','Required parameter mediaId was null or undefined when calling deleteMediaFromUserPlaylist.');
            }
            const localVarPath = `/user-playlists/{id}/media/{mediaId}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'mediaId'}}`, encodeURIComponent(String(mediaId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete User Playlist
         * @param {string} id The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPlaylist(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteUserPlaylist.');
            }
            const localVarPath = `/user-playlists/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete User Playlist Category
         * @param {string} id The user playlist category id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPlaylistCategory(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteUserPlaylistCategory.');
            }
            const localVarPath = `/user-playlist-categories/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get For You User Playlists
         * @param {string} library 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForYouPlaylistSets(library: string, options: any = {}): FetchArgs {
            // verify required parameter 'library' is not null or undefined
            if (library === null || library === undefined) {
                throw new RequiredError('library','Required parameter library was null or undefined when calling getForYouPlaylistSets.');
            }
            const localVarPath = `/user-playlists/for-you`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User Playlists By Category Id
         * @param {number} id The playlist category Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSetsByCatId(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPlaylistSetsByCatId.');
            }
            const localVarPath = `/user-playlist-categories/{id}/user-playlists`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All User Playlist Categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistCategories(options: any = {}): FetchArgs {
            const localVarPath = `/user-playlist-categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User Playlist Category
         * @param {string} id The user playlist category id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistCategory(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getUserPlaylistCategory.');
            }
            const localVarPath = `/user-playlist-categories/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User Playlist By Id
         * @param {string} id The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistSet(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getUserPlaylistSet.');
            }
            const localVarPath = `/user-playlists/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User Playlist By Slug
         * @param {string} slug The playlist slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistSetBySlug(slug: string, options: any = {}): FetchArgs {
            // verify required parameter 'slug' is not null or undefined
            if (slug === null || slug === undefined) {
                throw new RequiredError('slug','Required parameter slug was null or undefined when calling getUserPlaylistSetBySlug.');
            }
            const localVarPath = `/user-playlists/slug/{slug}`
                .replace(`{${'slug'}}`, encodeURIComponent(String(slug)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All User Playlists
         * @param {number} [limit] This endpoint does not have a default limit!
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistSets(limit?: number, skip?: number, options: any = {}): FetchArgs {
            const localVarPath = `/user-playlists`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove multiple Media from User Playlist
         * @param {number} id The playlist Id
         * @param {IdBatchBody1} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMultipleMediaFromUserPlaylist(id: number, body?: IdBatchBody1, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling removeMultipleMediaFromUserPlaylist.');
            }
            const localVarPath = `/user-playlists/{id}/batch`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'IdBatchBody1' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update User Playlist
         * @param {string} id The playlist Id
         * @param {UserplaylistsIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPlaylist(id: string, body?: UserplaylistsIdBody, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateUserPlaylist.');
            }
            const localVarPath = `/user-playlists/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'UserplaylistsIdBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update User Playlist Category
         * @param {string} id The user playlist category id
         * @param {UserplaylistcategoriesIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPlaylistCategory(id: string, body?: UserplaylistcategoriesIdBody, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateUserPlaylistCategory.');
            }
            const localVarPath = `/user-playlist-categories/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'UserplaylistcategoriesIdBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserPlaylistApi - functional programming interface
 * @export
 */
export const UserPlaylistApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Curated Set to User Playlist
         * @param {string} id The user playlist Id
         * @param {number} curatedSetId The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCuratedSetToUserPlaylist(id: string, curatedSetId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20013> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).addCuratedSetToUserPlaylist(id, curatedSetId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add Media to User Playlist
         * @param {string} id The playlist Id
         * @param {number} mediaId The media Id
         * @param {MediaMediaIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMediaToUserPlaylist(id: string, mediaId: number, body?: MediaMediaIdBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20013> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).addMediaToUserPlaylist(id, mediaId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add multiple Media to User Playlist
         * @param {string} id The playlist Id
         * @param {IdBatchBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMultipleMediaToUserPlaylist(id: string, body?: IdBatchBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20013> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).addMultipleMediaToUserPlaylist(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add Playlist to User Playlist
         * @param {string} id The user playlist Id
         * @param {number} playlistId The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPlaylistToUserPlaylist(id: string, playlistId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20013> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).addPlaylistToUserPlaylist(id, playlistId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create User Playlist
         * @param {number} id The playlist Id
         * @param {IdUserplaylistsBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserPlaylist(id: number, body?: IdUserplaylistsBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20013> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).createUserPlaylist(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create User Playlist Category
         * @param {UserplaylistcategoriesBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserPlaylistCategory(body?: UserplaylistcategoriesBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse201> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).createUserPlaylistCategory(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete Media from User Playlist
         * @param {string} id The playlist Id
         * @param {number} mediaId The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMediaFromUserPlaylist(id: string, mediaId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20013> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).deleteMediaFromUserPlaylist(id, mediaId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete User Playlist
         * @param {string} id The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPlaylist(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20014> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).deleteUserPlaylist(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete User Playlist Category
         * @param {string} id The user playlist category id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPlaylistCategory(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20014> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).deleteUserPlaylistCategory(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get For You User Playlists
         * @param {string} library 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForYouPlaylistSets(library: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20016> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).getForYouPlaylistSets(library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get User Playlists By Category Id
         * @param {number} id The playlist category Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSetsByCatId(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20017> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).getPlaylistSetsByCatId(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get All User Playlist Categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistCategories(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20020> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).getUserPlaylistCategories(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get User Playlist Category
         * @param {string} id The user playlist category id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistCategory(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse201> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).getUserPlaylistCategory(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get User Playlist By Id
         * @param {string} id The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistSet(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20013> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).getUserPlaylistSet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get User Playlist By Slug
         * @param {string} slug The playlist slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistSetBySlug(slug: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20013> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).getUserPlaylistSetBySlug(slug, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get All User Playlists
         * @param {number} [limit] This endpoint does not have a default limit!
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistSets(limit?: number, skip?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20015> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).getUserPlaylistSets(limit, skip, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Remove multiple Media from User Playlist
         * @param {number} id The playlist Id
         * @param {IdBatchBody1} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMultipleMediaFromUserPlaylist(id: number, body?: IdBatchBody1, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20013> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).removeMultipleMediaFromUserPlaylist(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update User Playlist
         * @param {string} id The playlist Id
         * @param {UserplaylistsIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPlaylist(id: string, body?: UserplaylistsIdBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20013> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).updateUserPlaylist(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update User Playlist Category
         * @param {string} id The user playlist category id
         * @param {UserplaylistcategoriesIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPlaylistCategory(id: string, body?: UserplaylistcategoriesIdBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse201> {
            const localVarFetchArgs = UserPlaylistApiFetchParamCreator(configuration).updateUserPlaylistCategory(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UserPlaylistApi - factory interface
 * @export
 */
export const UserPlaylistApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Add Curated Set to User Playlist
         * @param {string} id The user playlist Id
         * @param {number} curatedSetId The curated set Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCuratedSetToUserPlaylist(id: string, curatedSetId: number, options?: any) {
            return UserPlaylistApiFp(configuration).addCuratedSetToUserPlaylist(id, curatedSetId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add Media to User Playlist
         * @param {string} id The playlist Id
         * @param {number} mediaId The media Id
         * @param {MediaMediaIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMediaToUserPlaylist(id: string, mediaId: number, body?: MediaMediaIdBody, options?: any) {
            return UserPlaylistApiFp(configuration).addMediaToUserPlaylist(id, mediaId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add multiple Media to User Playlist
         * @param {string} id The playlist Id
         * @param {IdBatchBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMultipleMediaToUserPlaylist(id: string, body?: IdBatchBody, options?: any) {
            return UserPlaylistApiFp(configuration).addMultipleMediaToUserPlaylist(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add Playlist to User Playlist
         * @param {string} id The user playlist Id
         * @param {number} playlistId The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPlaylistToUserPlaylist(id: string, playlistId: number, options?: any) {
            return UserPlaylistApiFp(configuration).addPlaylistToUserPlaylist(id, playlistId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create User Playlist
         * @param {number} id The playlist Id
         * @param {IdUserplaylistsBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserPlaylist(id: number, body?: IdUserplaylistsBody, options?: any) {
            return UserPlaylistApiFp(configuration).createUserPlaylist(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create User Playlist Category
         * @param {UserplaylistcategoriesBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserPlaylistCategory(body?: UserplaylistcategoriesBody, options?: any) {
            return UserPlaylistApiFp(configuration).createUserPlaylistCategory(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete Media from User Playlist
         * @param {string} id The playlist Id
         * @param {number} mediaId The media Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMediaFromUserPlaylist(id: string, mediaId: number, options?: any) {
            return UserPlaylistApiFp(configuration).deleteMediaFromUserPlaylist(id, mediaId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete User Playlist
         * @param {string} id The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPlaylist(id: string, options?: any) {
            return UserPlaylistApiFp(configuration).deleteUserPlaylist(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete User Playlist Category
         * @param {string} id The user playlist category id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPlaylistCategory(id: string, options?: any) {
            return UserPlaylistApiFp(configuration).deleteUserPlaylistCategory(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get For You User Playlists
         * @param {string} library 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForYouPlaylistSets(library: string, options?: any) {
            return UserPlaylistApiFp(configuration).getForYouPlaylistSets(library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get User Playlists By Category Id
         * @param {number} id The playlist category Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylistSetsByCatId(id: number, options?: any) {
            return UserPlaylistApiFp(configuration).getPlaylistSetsByCatId(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get All User Playlist Categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistCategories(options?: any) {
            return UserPlaylistApiFp(configuration).getUserPlaylistCategories(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get User Playlist Category
         * @param {string} id The user playlist category id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistCategory(id: string, options?: any) {
            return UserPlaylistApiFp(configuration).getUserPlaylistCategory(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get User Playlist By Id
         * @param {string} id The playlist Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistSet(id: string, options?: any) {
            return UserPlaylistApiFp(configuration).getUserPlaylistSet(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get User Playlist By Slug
         * @param {string} slug The playlist slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistSetBySlug(slug: string, options?: any) {
            return UserPlaylistApiFp(configuration).getUserPlaylistSetBySlug(slug, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get All User Playlists
         * @param {number} [limit] This endpoint does not have a default limit!
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaylistSets(limit?: number, skip?: number, options?: any) {
            return UserPlaylistApiFp(configuration).getUserPlaylistSets(limit, skip, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Remove multiple Media from User Playlist
         * @param {number} id The playlist Id
         * @param {IdBatchBody1} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMultipleMediaFromUserPlaylist(id: number, body?: IdBatchBody1, options?: any) {
            return UserPlaylistApiFp(configuration).removeMultipleMediaFromUserPlaylist(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update User Playlist
         * @param {string} id The playlist Id
         * @param {UserplaylistsIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPlaylist(id: string, body?: UserplaylistsIdBody, options?: any) {
            return UserPlaylistApiFp(configuration).updateUserPlaylist(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update User Playlist Category
         * @param {string} id The user playlist category id
         * @param {UserplaylistcategoriesIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPlaylistCategory(id: string, body?: UserplaylistcategoriesIdBody, options?: any) {
            return UserPlaylistApiFp(configuration).updateUserPlaylistCategory(id, body, options)(fetch, basePath);
        },
    };
};

/**
 * UserPlaylistApi - object-oriented interface
 * @export
 * @class UserPlaylistApi
 * @extends {BaseAPI}
 */
export class UserPlaylistApi extends BaseAPI {
    /**
     * 
     * @summary Add Curated Set to User Playlist
     * @param {string} id The user playlist Id
     * @param {number} curatedSetId The curated set Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public addCuratedSetToUserPlaylist(id: string, curatedSetId: number, options?: any) {
        return UserPlaylistApiFp(this.configuration).addCuratedSetToUserPlaylist(id, curatedSetId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add Media to User Playlist
     * @param {string} id The playlist Id
     * @param {number} mediaId The media Id
     * @param {MediaMediaIdBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public addMediaToUserPlaylist(id: string, mediaId: number, body?: MediaMediaIdBody, options?: any) {
        return UserPlaylistApiFp(this.configuration).addMediaToUserPlaylist(id, mediaId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add multiple Media to User Playlist
     * @param {string} id The playlist Id
     * @param {IdBatchBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public addMultipleMediaToUserPlaylist(id: string, body?: IdBatchBody, options?: any) {
        return UserPlaylistApiFp(this.configuration).addMultipleMediaToUserPlaylist(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add Playlist to User Playlist
     * @param {string} id The user playlist Id
     * @param {number} playlistId The playlist Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public addPlaylistToUserPlaylist(id: string, playlistId: number, options?: any) {
        return UserPlaylistApiFp(this.configuration).addPlaylistToUserPlaylist(id, playlistId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create User Playlist
     * @param {number} id The playlist Id
     * @param {IdUserplaylistsBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public createUserPlaylist(id: number, body?: IdUserplaylistsBody, options?: any) {
        return UserPlaylistApiFp(this.configuration).createUserPlaylist(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create User Playlist Category
     * @param {UserplaylistcategoriesBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public createUserPlaylistCategory(body?: UserplaylistcategoriesBody, options?: any) {
        return UserPlaylistApiFp(this.configuration).createUserPlaylistCategory(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete Media from User Playlist
     * @param {string} id The playlist Id
     * @param {number} mediaId The media Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public deleteMediaFromUserPlaylist(id: string, mediaId: number, options?: any) {
        return UserPlaylistApiFp(this.configuration).deleteMediaFromUserPlaylist(id, mediaId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete User Playlist
     * @param {string} id The playlist Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public deleteUserPlaylist(id: string, options?: any) {
        return UserPlaylistApiFp(this.configuration).deleteUserPlaylist(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete User Playlist Category
     * @param {string} id The user playlist category id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public deleteUserPlaylistCategory(id: string, options?: any) {
        return UserPlaylistApiFp(this.configuration).deleteUserPlaylistCategory(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get For You User Playlists
     * @param {string} library 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public getForYouPlaylistSets(library: string, options?: any) {
        return UserPlaylistApiFp(this.configuration).getForYouPlaylistSets(library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get User Playlists By Category Id
     * @param {number} id The playlist category Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public getPlaylistSetsByCatId(id: number, options?: any) {
        return UserPlaylistApiFp(this.configuration).getPlaylistSetsByCatId(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get All User Playlist Categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public getUserPlaylistCategories(options?: any) {
        return UserPlaylistApiFp(this.configuration).getUserPlaylistCategories(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get User Playlist Category
     * @param {string} id The user playlist category id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public getUserPlaylistCategory(id: string, options?: any) {
        return UserPlaylistApiFp(this.configuration).getUserPlaylistCategory(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get User Playlist By Id
     * @param {string} id The playlist Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public getUserPlaylistSet(id: string, options?: any) {
        return UserPlaylistApiFp(this.configuration).getUserPlaylistSet(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get User Playlist By Slug
     * @param {string} slug The playlist slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public getUserPlaylistSetBySlug(slug: string, options?: any) {
        return UserPlaylistApiFp(this.configuration).getUserPlaylistSetBySlug(slug, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get All User Playlists
     * @param {number} [limit] This endpoint does not have a default limit!
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public getUserPlaylistSets(limit?: number, skip?: number, options?: any) {
        return UserPlaylistApiFp(this.configuration).getUserPlaylistSets(limit, skip, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Remove multiple Media from User Playlist
     * @param {number} id The playlist Id
     * @param {IdBatchBody1} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public removeMultipleMediaFromUserPlaylist(id: number, body?: IdBatchBody1, options?: any) {
        return UserPlaylistApiFp(this.configuration).removeMultipleMediaFromUserPlaylist(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update User Playlist
     * @param {string} id The playlist Id
     * @param {UserplaylistsIdBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public updateUserPlaylist(id: string, body?: UserplaylistsIdBody, options?: any) {
        return UserPlaylistApiFp(this.configuration).updateUserPlaylist(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update User Playlist Category
     * @param {string} id The user playlist category id
     * @param {UserplaylistcategoriesIdBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPlaylistApi
     */
    public updateUserPlaylistCategory(id: string, body?: UserplaylistcategoriesIdBody, options?: any) {
        return UserPlaylistApiFp(this.configuration).updateUserPlaylistCategory(id, body, options)(this.fetch, this.basePath);
    }

}
