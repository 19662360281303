import { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { PaymentInstrumentAuthorize, PaymentInstrumentPaypal, PaymentInstrumentStripe, PaymentInstrumentSystem, UserAddress } from '@bpm-web-app/supreme-api-sdk';
import { Elements, AddressElement } from '@stripe/react-stripe-js';
import { StripeCreatePromise, StripeOptionsCreate, StripeOptionsSupreme, StripeSupremePromise } from '@bpm-web-app/utils';
import { StripeElementsOptions, ContactOption } from '@stripe/stripe-js';
import { Subscription } from '@bpm-web-app/api-client';
import styles from './account-add-payment.module.css';
import CloseIcon from '../../../assets/icons/close.svg';
import PencilIcon from '../../../assets/icons/pencil.svg';
import Card, { CardRef } from './card';

export interface PaymentProps {
    isCreate?: boolean;
    billingAddress?: UserAddress;
    updateBillingAddress: (address: UserAddress) => Promise<void>
    paymentData?: PaymentInstrumentAuthorize | PaymentInstrumentPaypal | PaymentInstrumentStripe | PaymentInstrumentSystem;
    billingText: string;
    isOpen: boolean;
    setPaymentData: (item: PaymentInstrumentStripe) => Promise<void> | void;
}

export function AccountAddPayment({ billingText, setPaymentData, isOpen, paymentData, billingAddress, isCreate, updateBillingAddress }: PaymentProps) {
    const [addingCard, setAddingCard] = useState<boolean>(isOpen);
    const [paymentElementComplete, setPaymentElementComplete] = useState<boolean>(false);
    const [addressInitialized, setAddressInitialized] = useState<boolean>(isOpen);
    const [currentBillingAddress, setCurrentBillingAddress] = useState<ContactOption>();
    const [loading, setLoading] = useState<boolean>(false);
    const [clientSecret, setClientSecret] = useState<string>();
    const cardRef = useRef<CardRef>(null);

    useEffect(() => {
        if (billingAddress) {
            setCurrentBillingAddress({
                name: `${billingAddress?.first_name || ''} ${billingAddress?.last_name || ''}`.trim(),
                address: {
                    city: billingAddress?.city || '',
                    country: billingAddress?.country || '',
                    line1: billingAddress?.line1 || '',
                    line2: billingAddress?.line2 || '',
                    postal_code: billingAddress?.zip || '',
                    state: billingAddress?.state || ''
                }
            });
            setAddressInitialized(true);
        }
    }, [billingAddress]);

    useEffect(() => {
        if (addingCard) {
            Subscription.getSetupIntent(isCreate ? 'create' : 'supreme').then((data) => {
                setClientSecret(data.data.intent);
                return null;
            }).catch(() => {

            });
        }
    }, [addingCard, isCreate]);

    const [paymentMethodText, setPaymentMethodText] = useState<string | null>(null);

    useEffect(() => {
        if (paymentData?.method === PaymentInstrumentStripe.MethodEnum.Stripe) {
            setPaymentMethodText(`${(paymentData as PaymentInstrumentStripe)?.description}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentData]);

    const handleFormSubmit = useCallback(async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);
        try {
            await updateBillingAddress({
                first_name: currentBillingAddress?.name.split(' ')[0] || '',
                last_name: currentBillingAddress?.name.split(' ').splice(1).join(' ') || '',
                city: currentBillingAddress?.address.city,
                state: currentBillingAddress?.address.state,
                country: currentBillingAddress?.address.country,
                zip: currentBillingAddress?.address.postal_code,
                line1: currentBillingAddress?.address.line1,
                line2: currentBillingAddress?.address.line2
            });
            const result = await cardRef.current?.submit();
            if (result && result.payment_method) {
                const { data: paymentMethod } = await Subscription.getPaymentMethodBySetupIntent(isCreate ? 'create' : 'supreme', result.id);
                const res = setPaymentData(paymentMethod);
                try {
                    await res;
                    // eslint-disable-next-line no-empty
                } catch (error) {
                }
                setAddingCard(false);
            }
            // eslint-disable-next-line no-empty
        } catch (error) {

        }

        setLoading(false);
    }, [currentBillingAddress, setPaymentData, setLoading, updateBillingAddress, isCreate]);

    return (
        <>
            <div className={classNames(styles['account-add-payment__information'])}>
                <h3 className={styles['account-add-payment__information-title']}>Payment Details</h3>
                <div className={styles['account-add-payment__information-container']}>
                    <span className={styles['account-add-payment__information-text']}>{billingText}&nbsp;</span>
                    <span className={styles['account-add-payment__information-card']}>
                        {paymentMethodText}
                        <button aria-label="open payment details" className={styles['account-add-payment__information-icon']} onClick={() => setAddingCard(!addingCard)} type="button">
                            {addingCard ? <CloseIcon /> : <PencilIcon />}
                        </button>
                    </span>
                </div>
            </div>
            {addingCard && clientSecret ? (
                <Elements
                    stripe={isCreate ? StripeCreatePromise : StripeSupremePromise}
                    options={
                        { ...(isCreate ? StripeOptionsCreate : StripeOptionsSupreme), clientSecret } as StripeElementsOptions
                    }
                >
                    <form onSubmit={handleFormSubmit} className={classNames(styles['account-add-payment__form'], styles['account-add-payment__form--stripe'])}>
                        <h4>Card Information</h4>
                        <div className={styles['account-add-payment--spacer']} />
                        <Card
                            ref={cardRef}
                            onChange={(e) => {
                                setPaymentElementComplete(e.complete);
                            }} />
                        <div className={styles['account-add-payment--spacer']} />
                        <h4>Billing Address</h4>
                        <div className={styles['account-add-payment--spacer']} />
                        {addressInitialized && (
                            <AddressElement
                                options={{
                                    contacts: [],
                                    mode: 'billing',
                                    defaultValues: currentBillingAddress,
                                    // autocomplete: { mode: 'google_maps_api', apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ?? '' }
                                }}
                                onChange={(event) => {
                                    setCurrentBillingAddress(event.complete ? event.value as ContactOption : undefined);
                                }} />
                        )}
                        <div className={styles['account-add-payment--spacer']} />
                        <button disabled={currentBillingAddress === undefined || !paymentElementComplete || loading} className={classNames('account__button', styles['account-add-payment__form-button'])} type="submit">
                            {' '}
                            Save{' '}
                        </button>
                    </form>
                </Elements>
            ) : null}
        </>
    );
}

export default AccountAddPayment;

export interface PaymentData {
    billing?: {
        streetAddress: string;
        number: string;
        city: string;
        state: string;
        country: string;
        zipCode: string;
    };
    payment: {
        stripe?: {
            payment_method_id: string;
        };
    };
}
