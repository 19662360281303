import { usePlayer } from '@bpm-web-app/components/src/lib/player-context';
import { TrackListSelectableProps } from '@bpm-web-app/components/src/lib/shared';
import useFavoriteMultipleMedia from '@bpm-web-app/components/src/lib/shared/three-dots-sheet/useFavoriteMultipleMedia';
import { TrackListSupreme } from '@bpm-web-app/components/src/lib/shared/track-list/supreme/track-list-supreme';
import { QueueItem, showToast, useHubSwitch } from '@bpm-web-app/utils';
import { LibraryTabsContext } from 'libs/utils/src/lib/library-tabs.context';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { TrackListPresetSupreme } from '@bpm-web-app/components/src/lib/shared/track-list/supreme/track-list-supreme-helpers';
import QueueIcon from '../../../../../assets/icons/queue.svg';
import { EmptyState } from '../empty-state/empty-state';
import { ListWrapper } from '../list-wrapper/list-wrapper';
import { PlayerQueueActions } from './player-queue-actions';

export function PlayerQueue() {
    const { currentQueue, removeFromQueue, currentTrackIndex, repeat, currentTrack, moveTrackPositionInQueue } = usePlayer();
    const [selectedItemsIds, setSelectedItemsIds] = useState<QueueItem['uuid'][]>([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const { isDownload } = useHubSwitch();
    const { libraryProperty } = useContext(LibraryTabsContext);
    const { addMultipleToFavorites } = useFavoriteMultipleMedia(libraryProperty);

    const handleSelectItem = useCallback((uuid: QueueItem['uuid']) => {
        setSelectedItemsIds((prevState) => {
            if (prevState.includes(uuid)) {
                return prevState.filter((selectedUuid) => selectedUuid !== uuid);
            }
            return [...prevState, uuid];
        });
    }, []);

    const upNextQueue = useMemo(() => {
        if (currentTrackIndex === currentQueue.length - 1 && repeat === 'all') {
            return currentQueue.filter((media) => media.uuid !== currentTrack.uuid);
        }

        return currentQueue.filter((media) => media.uuid !== currentTrack.uuid).splice(currentTrackIndex);
    }, [currentTrackIndex, currentQueue, repeat, currentTrack]);

    const handleToggleAll = useCallback(
        (all: boolean) => {
            if (upNextQueue.length) {
                setSelectedItemsIds(() => (all ? [...upNextQueue.map((track) => track.uuid)] : []));
            }
        },
        [upNextQueue]
    );

    const selectableProps = useMemo(() => {
        if (isEditMode) {
            return {
                onSelectItem: handleSelectItem,
                selectedItemsIds,
                onSelectAll: handleToggleAll,
            } as Pick<TrackListSelectableProps, 'onSelectItem' | 'selectedItemsIds' | 'onSelectAll'>;
        }
        return {};
    }, [handleSelectItem, handleToggleAll, isEditMode, selectedItemsIds]);

    const handleCancelEditMode = useCallback(() => {
        setSelectedItemsIds([]);
        setIsEditMode(false);
    }, []);

    const handleRemoveFromQueue = useCallback(() => {
        selectedItemsIds.forEach((uuid) => removeFromQueue(uuid));
        showToast({ type: 'success', message: 'Removed from queue.' });
        setSelectedItemsIds([]);
    }, [removeFromQueue, selectedItemsIds]);

    const handleFavoriteMultipleMedia = useCallback(async () => {
        const selectedMediaIds = selectedItemsIds
            .map((uuid) => upNextQueue.find((item) => item.uuid === uuid))
            .filter((item) => item !== undefined)
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .map((item) => (isDownload ? item!.album_id : item!.id))
            .filter((id) => id !== undefined);
        await addMultipleToFavorites(selectedMediaIds as number[]);
        showToast({ type: 'success', message: 'Added to favorites.', buttonText: 'Go To Favorites', replaceRouteOnButtonClick: '/favorites' });
        setSelectedItemsIds([]);
    }, [addMultipleToFavorites, upNextQueue, isDownload, selectedItemsIds]);

    useEffect(() => {
        const visibleTrackUuids = upNextQueue.map((item) => item.uuid);
        setSelectedItemsIds((prevState) => prevState.filter((uuid) => visibleTrackUuids.includes(uuid)));
    }, [upNextQueue]);

    if (!upNextQueue.length) {
        return (
            <EmptyState icon={<QueueIcon />} title="The play queue is empty." />
        );
    }

    return (
        <>
            <PlayerQueueActions
                isEditMode={isEditMode}
                setIsEditMode={setIsEditMode}
                selectedItemsIds={selectedItemsIds}
                handleCancelEditMode={handleCancelEditMode}
                handleFavoriteMultipleMedia={handleFavoriteMultipleMedia}
                handleRemoveFromQueue={handleRemoveFromQueue}
            />
            <ListWrapper>
                <TrackListSupreme
                    forMaxiPlayer
                    preset={TrackListPresetSupreme.Queue}
                    list={upNextQueue}
                    isDraggable
                    {...selectableProps}
                    onDownloadRevalidate={(downloadMedia) => {
                        if (downloadMedia) {
                            // eslint-disable-next-line no-param-reassign
                            downloadMedia.download_count += 1;
                        }
                    }}
                    onMove={(id, prevIndex, index) => {
                        moveTrackPositionInQueue(prevIndex, index);
                    }} />
            </ListWrapper>
        </>
    );
}

export default PlayerQueue;
