import { getUserPlaylistsByCategoryIdKey, useCurrentUser, useUserPlaylistDetail, useUserPlaylistInvites } from '@bpm-web-app/swr-hooks';
import { useRouter } from 'next/router';
import {
    convertToPluralIfNeeded,
    downloadAlbumWithMediaToQueueItem,
    formatDateToString,
    generateArtistLinks,
    getPlatformLinkUsingRouter,
    QueueItem,
    rebuildReactTooltip,
    showToast,
    State,
    streamMediaWithAlbumToQueueItem,
    useApiErrorHandler,
    useHideSwitch,
    useHubSwitch,
    usePageDetails,
    usePlayerState,
    useViewport
} from '@bpm-web-app/utils';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { Artist, UserPlaylistWithMedia } from '@bpm-web-app/stream-api-sdk';
import { UserPlaylist, UserPlaylistCollaboration, UserPlaylistCollaborationAccessLevel, UserPlaylistCollaborationStatus, UserPlaylistWithAlbum, UserPlaylistWithAlbumOwner } from '@bpm-web-app/download-api-sdk';
import { UserPlaylist as UserPlaylistApi } from '@bpm-web-app/api-client';
import { useSWRConfig } from 'swr';
import { usePlayer } from '../../player-context';
import styles from './my-playlist-detail.module.css';
import { MediaDetailBanner } from '../../shared/ui/media-detail-banner/media-detail-banner';
import { ThreeDotsSheetContext } from '../../shared/three-dots-sheet/three-dots-sheet.context';
import { useHideLibraryTabs } from '../../../../../utils/src/lib/library-tabs.context';
import { LocalSortingKeys, sortMediaLocally, useLocalSort } from '../../sort-options-sheet/sort-options-sheet';
import { AppLink, BreakpointView, EmptyState, GhostComponent } from '../../shared';
import { PageNotFound } from '../page-not-found/page-not-found';
import Title from '../../title/title';
import { PlayButton } from '../../shared/play-button/play-button';
import BPMIcons from '../../shared/bpm-icons/bpm-icons';
import { SquaredButton } from '../../shared/squared-button/squared-button';
import { AddCollaboratorsButton } from '../../shared/add-collaborators-button/add-collaborators-button';
import { PlaylistCoverImage } from '../../shared/playlist-cover-image/playlist-cover-image';
import { CollaborationUsers } from '../../shared/collaboration/collaboration-users';
import { ActionModal } from '../../shared/action-modal/action-modal';
import { CollaborationModal } from '../../shared/collaboration/collaboration-modal';
import { CollaborationInvite } from '../../shared/collaboration/collaboration-invite';
import Dropdown from '../../dropdown/dropdown';
import { useUserPlaylistCategories } from '../../shared/three-dots-sheet/useUserPlaylistCategories';
import PlaylistsForm, { PlaylistsFormProps } from '../../playlists-form/playlists-form';
import { ThreeDotsButton } from '../../shared/three-dots-button/three-dots-button';
import { TrackListSupreme } from '../../shared/track-list/supreme/track-list-supreme';
import { TrackListPresetSupreme } from '../../shared/track-list/supreme/track-list-supreme-helpers';
import { TrackListLoadingSupreme } from '../../shared/track-list/ghost-loading/track-list-loading-supreme';

export function MyPlaylistDetail() {
    const router = useRouter();
    const errorHandler = useApiErrorHandler();
    const { mutate: globalMutate } = useSWRConfig();
    const { isMobile } = useViewport();
    const { openThreeDotsModalSheet, setSecondaryActionTypeId } = useContext(ThreeDotsSheetContext);
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const [showCopyModal, setShowCopyModal] = useState(false);
    const [copyToCategoryId, setCopyToCategorryId] = useState<string>();

    const { playlistId } = router.query;

    const [sortType, setSortType] = useLocalSort();
    const { data: user } = useCurrentUser();
    const { originalListDetails, setQueue, togglePlayPause, addToQueue, setIsMaxiPlayer } = usePlayer();
    const { data: invitesData, mutate: mutateInvites } = useUserPlaylistInvites(playlistId as string);
    const { userPlaylistCategories, createFolder } = useUserPlaylistCategories(false);
    const playlistInvites = invitesData?.data;

    const actualCategories = useMemo(() => userPlaylistCategories?.data?.filter((d) => d.slug !== 'shared') || [], [userPlaylistCategories]);

    const [invitedCollaborators, setInvitedCollaborators] = useState<UserPlaylistCollaboration[]>([]);

    const playerState = usePlayerState();
    const { resource } = usePageDetails();
    const { isDownload, hub } = useHubSwitch();

    const isCurrentListInPlayer = useMemo(() => originalListDetails?.identifier === playlistId && originalListDetails?.resource === resource, [originalListDetails, playlistId, resource]);

    const { data, isLoading, error, mutate } = useUserPlaylistDetail(isDownload, playlistId as string);
    const playlist = data?.data;
    const isMyPlaylist = playlist?.user_id === user?.data?.user?.id;

    const sortedTrackList = useMemo(
        () => (isDownload ? sortMediaLocally((playlist as UserPlaylistWithAlbum)?.albums || [], sortType) : sortMediaLocally((playlist as UserPlaylistWithMedia)?.media || [], sortType)),
        [isDownload, playlist, sortType]
    );

    const [isAddingCollaborators, setIsAddingCollaborators] = useState(false);
    const [showInvitationMessage, setShowInvitationMessage] = useState(false);
    const [playlistInvitation, setPlaylistInvitation] = useState<UserPlaylistCollaboration>();

    const playlistCollaboratorsData = useMemo(() => data?.data as UserPlaylistWithAlbum && (data?.data as UserPlaylistWithAlbum).users ? (data?.data as UserPlaylistWithAlbum).users : [], [data]);
    const [playlistCollaborators, setPlaylistCollaborators] = useState<UserPlaylistCollaboration[]>(playlistCollaboratorsData || []);

    const hasEditAccess = isMyPlaylist ||
        ((playlist as UserPlaylistWithAlbum)?.access_level === UserPlaylistCollaborationAccessLevel.Manage
            || (playlist as UserPlaylistWithAlbum)?.access_level === UserPlaylistCollaborationAccessLevel.Edit);

    useHideSwitch();
    useHideLibraryTabs();

    useEffect(() => {
        if ((playlist as UserPlaylistWithAlbum)?.access === UserPlaylist.AccessEnum.Downgraded) {
            setShowUpgradeModal(true);
        } else if ((playlist as UserPlaylistWithAlbum)?.access === UserPlaylist.AccessEnum.Expired) {
            setShowCopyModal(true);
        }
    }, [playlist]);

    useEffect(() => {
        rebuildReactTooltip();
    }, []);

    useEffect(() => {
        setPlaylistCollaborators(playlistCollaboratorsData || []);
    }, [playlistCollaboratorsData]);

    const handleGetPlaylistUserInvites = useCallback(() => {
        if (playlistInvites && playlistInvites?.length > 0) {
            // Remove already members
            const nonMemberInvites = playlistInvites.filter((invite) => invite.status === UserPlaylistCollaborationStatus.Invited);
            // Check if current user is pending invite
            const currentInvite = nonMemberInvites.find((invite) => invite.email.toLowerCase() === user?.data.user.email.toLowerCase());
            setPlaylistInvitation(currentInvite);
            setShowInvitationMessage(currentInvite !== undefined);
            setInvitedCollaborators(nonMemberInvites);
        } else {
            setInvitedCollaborators([]);
        }
    }, [playlistInvites, user?.data.user.email]);

    useEffect(() => {
        handleGetPlaylistUserInvites();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playlistInvites]);

    const handleOpenCollaborationModal = () => {
        // Reload playlist data when opening the collaboration menu
        handleGetPlaylistUserInvites();
        mutate();
        setIsAddingCollaborators(true);
    };

    const handleAddToQueue = useCallback(() => {
        if (sortedTrackList) {
            let queueMedia: QueueItem[] = [];

            if (isDownload) {
                queueMedia = sortedTrackList.map((album) => downloadAlbumWithMediaToQueueItem(album));
            } else {
                queueMedia = sortedTrackList.map((media) => streamMediaWithAlbumToQueueItem(media));
            }
            rebuildReactTooltip();
            addToQueue(queueMedia);
            showToast({
                type: 'success',
                message: 'Added to queue.',
                buttonText: 'Open Queue',
                onButtonClick: () => setIsMaxiPlayer(true)
            });
        }
    }, [sortedTrackList, isDownload, addToQueue, setIsMaxiPlayer]);

    const openMoreSheet = useCallback(
        async (element: EventTarget) => {
            const { top: topPosition, left: leftPosition } = (element as HTMLButtonElement).getBoundingClientRect();
            openThreeDotsModalSheet(isMyPlaylist ? 'user-playlist' : 'for-you-playlist-detail', playlistId as string, leftPosition, topPosition, false);
        },
        [openThreeDotsModalSheet, isMyPlaylist, playlistId]
    );

    const handlePlay = useCallback(() => {
        if (isCurrentListInPlayer) {
            togglePlayPause();
            return;
        }

        if (sortedTrackList) {
            let queueMedia: QueueItem[] = [];

            if (isDownload) {
                queueMedia = sortedTrackList.map((album) => downloadAlbumWithMediaToQueueItem(album));
            } else {
                queueMedia = sortedTrackList.map((media) => streamMediaWithAlbumToQueueItem(media));
            }

            setQueue(queueMedia, 0, { identifier: playlistId as string, resource });
        }
    }, [isCurrentListInPlayer, sortedTrackList, togglePlayPause, isDownload, setQueue, playlistId, resource]);

    type MarkArgs = { artist: string, artists: Artist[] };
    const getDescriptionMarkup = useCallback(
        ({ artist, artists }: MarkArgs) => (
            <div className={styles['my-playlist-detail__description']}>
                {
                    generateArtistLinks(artist, artists, (a) => {
                        return (
                            <AppLink href={`/artist/${a.slug}`} key={a.id}>
                                <a className="underline-link">
                                    <span>{a.name}</span>
                                </a>
                            </AppLink>
                        );
                    }, (sep, i) => (
                        <span key={i}>{sep}</span>
                    ))
                }
            </div>
        ),
        []
    );

    useEffect(() => {
        if (playlistId) {
            setSecondaryActionTypeId(playlistId as string);
        }

        return () => {
            setSecondaryActionTypeId(null);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playlistId]);

    const coverUrl = isDownload ? (playlist as UserPlaylistWithAlbum)?.image_url : (playlist as UserPlaylistWithAlbum)?.image_url;
    const customCoverUrl = isDownload ? (playlist as UserPlaylistWithAlbum)?.custom_image_url : (playlist as UserPlaylistWithAlbum)?.custom_image_url;

    const [openForm, setOpenForm] = useState<null | PlaylistsFormProps>(null);

    const addFolder = useCallback(() => {
        setOpenForm({
            type: 'CreateNewFolder',
            text: 'Create folders to organize your playlists by genre, event, or more.',
            formAction: (name: string) => {
                return createFolder(name).then((v) => {
                    setShowCopyModal(true);
                    return v;
                });
            },
            close: (status) => {
                setOpenForm(null);
                if (status !== 'submitted') { setShowCopyModal(true); }
            },
        });
    }, [createFolder]);

    if (playlistId === undefined) return <PageNotFound />;

    if (isLoading) {
        return (
            <>
                <Title platform={hub} title="Loading Artist..." />
                <GhostComponent type="playlist-detail" />
                <TrackListLoadingSupreme preset={TrackListPresetSupreme.Download} amount={20} />
            </>
        );
    }

    if (!isLoading && error) return null;

    return playlist ? (
        <>
            {openForm ? <PlaylistsForm {...openForm} /> : null}
            <Title platform={hub} title={playlist?.title} />
            <div className={classNames(styles['my-playlist-detail'], 'spacing__window')}>
                <MediaDetailBanner align={isMobile ? 'end' : 'start'}>
                    {{
                        image: (
                            <PlaylistCoverImage playlist={playlist} coverUrl={coverUrl} customCoverUrl={customCoverUrl} collaborativePlaylist onUploadComplete={() => mutate()} />
                        ),
                        text: (
                            <>
                                <div className={styles['my-playlist-detail__title-container']}>
                                    <h2>{playlist.title}</h2>
                                    <BreakpointView
                                        desktopChildren={<ThreeDotsButton hasTooltip onClick={(event) => openMoreSheet(event?.target)} />}
                                        mobileChildren={undefined} />
                                </div>
                                <div className={styles['my-playlist-detail__info']}>
                                    <CollaborationUsers
                                        owner={(playlist as UserPlaylistWithAlbum).owner}
                                        collaborators={playlistCollaborators}
                                        onClick={handleOpenCollaborationModal} />
                                    <span>{isDownload
                                        ? convertToPluralIfNeeded((playlist as UserPlaylistWithAlbum).album_count, 'Track', 'Tracks')
                                        : convertToPluralIfNeeded((playlist as UserPlaylistWithMedia).media_count, 'Track', 'Tracks')}
                                    </span>
                                    <span>{`Updated ${formatDateToString(playlist.updated_at)}`}</span>
                                </div>
                                <p className={styles['my-playlist-detail__description']}>{getDescriptionMarkup({
                                    artist: playlist.artist,
                                    artists: playlist.artists || []
                                })}
                                </p>
                            </>
                        ),
                        actions: (
                            <BreakpointView
                                desktopChildren={
                                    (
                                        <div className={styles['my-playlist-detail__actions']}>
                                            <PlayButton type="play" isPlaying={isCurrentListInPlayer && playerState === State.Playing} onPress={handlePlay} />
                                            <SquaredButton type="outline" label="Add to Queue" onPress={handleAddToQueue} leftIcon={<BPMIcons.AddToQueue />} />
                                            {isMyPlaylist ? <AddCollaboratorsButton onClick={handleOpenCollaborationModal} /> : null}
                                        </div>
                                    )
                                }
                                mobileChildren={<ThreeDotsButton onClick={(event) => openMoreSheet(event?.target)} />} />
                        ),
                    }}
                </MediaDetailBanner>

                <BreakpointView
                    desktopChildren={undefined}
                    mobileChildren={
                        (
                            <div className={classNames(styles['my-playlist-detail__actions'], 'spacing--bottom')}>
                                <PlayButton type="play" isPlaying={isCurrentListInPlayer && playerState === State.Playing} onPress={handlePlay} fullWidth />
                                <SquaredButton type="outline" label="Add to Queue" onPress={handleAddToQueue} leftIcon={<BPMIcons.AddToQueue />} fullWidth />
                            </div>
                        )
                    } />

                {sortedTrackList && (
                    <TrackListSupreme
                        list={sortedTrackList}
                        preset={isDownload ? TrackListPresetSupreme.Download : TrackListPresetSupreme.Stream}
                        isSortable
                        onSort={(nextSort) => setSortType(nextSort as LocalSortingKeys)}
                        sortOptions={[
                            { actionType: 'default', label: 'Custom order' },
                            { actionType: 'date', label: 'Most recent' },
                            { actionType: 'title', label: 'Title' },
                        ]}
                        selectedSortType={sortType}
                        isUserPlaylist={isMyPlaylist}
                        hasEditAccess={hasEditAccess}
                        isDraggable={isMyPlaylist || hasEditAccess}
                        collaborators={playlistCollaborators}
                        owner={(playlist as UserPlaylistWithAlbum).owner}
                        onMove={(id, existingIndex, index) => {
                            if (isMyPlaylist || hasEditAccess) {
                                if (sortType !== 'default') {
                                    showToast({ type: 'alert', message: 'Please use custom order when changing order of tracks' });
                                } else {
                                    const order = index;
                                    const newIndex = Math.max(order > (sortedTrackList.length - 1) ? (sortedTrackList.length - 1) : order, 0);
                                    sortedTrackList.splice(newIndex, 0, sortedTrackList.splice(existingIndex, 1)[0]);
                                    const currentData = { data: { ...data.data } };
                                    if (isDownload && currentData) {
                                        (currentData.data as UserPlaylistWithAlbum).albums = sortedTrackList.slice();
                                    } else if (currentData) {
                                        (currentData.data as UserPlaylistWithMedia).media = sortedTrackList.slice();
                                    }
                                    mutate(UserPlaylistApi.addAlbumToUserPlaylist(playlist.id, id, index + 1), {
                                        rollbackOnError: true,
                                        populateCache: true,
                                        revalidate: false,
                                        optimisticData: currentData as any
                                    });
                                }
                            }
                        }}
                    />
                )}

                {sortedTrackList.length === 0 ? (
                    <EmptyState variant="dynamic" title="Looks like you haven't added any tracks yet. " subtitle="Start filling out your playlist by adding tracks with the ‘Add to Playlist’ button in the options menu. Your tracks will appear here once you've created them." actionLabel="Go To New Releases" icon="plus-icon" actionIcon="chevron-right-icon" href="/releases" hasBackground={false} />
                ) : null}

                <ActionModal
                    hideCancel
                    headerTitle={`Share Playlist ‘${playlist.title}’`}
                    onClose={() => setIsAddingCollaborators(false)}
                    variant="dark"
                    isOpen={isAddingCollaborators}
                >
                    <CollaborationModal
                        playlistId={playlistId as string}
                        invitedCollaborators={invitedCollaborators}
                        setInvitedCollaborators={setInvitedCollaborators}
                        setCollaborators={setPlaylistCollaborators}
                        onClose={() => setIsAddingCollaborators(false)}
                        collaborators={playlistCollaborators}
                        playlistOwner={(playlist as UserPlaylistWithAlbum).owner as UserPlaylistWithAlbumOwner || undefined}
                        isMyPlaylist={isMyPlaylist}
                        invitationLink={(playlist as UserPlaylistWithAlbum).invitation_link}
                    />

                </ActionModal>

                <ActionModal
                    hideCancel
                    headerTitle="Collaboration Invite"
                    onClose={() => setShowInvitationMessage(false)}
                    variant="light"
                    contentClassname={styles['my-playlist-detail__collab-modal']}
                    isOpen={showInvitationMessage === true && playlistInvitation !== undefined && playlistId !== undefined}
                >
                    <CollaborationInvite
                        invite={playlistInvitation as UserPlaylistCollaboration}
                        playlistId={playlistId as string}
                        playlistTitle={playlist.title}
                        owner={(playlist as UserPlaylistWithAlbum).owner?.full_name as string}
                        onComplete={(newCollaborator) => {
                            mutateInvites();
                            if (newCollaborator) {
                                const allCollaborators = playlistCollaborators;
                                allCollaborators.push(newCollaborator);
                                setPlaylistCollaborators(allCollaborators);
                            }
                            handleGetPlaylistUserInvites();
                            setShowInvitationMessage(false);
                        }} />
                </ActionModal>

                <ActionModal
                    headerTitle="Heads Up!"
                    title="Playlist Collaboration Expired"
                    subtitle={`The owner of '${playlist.title}' is no longer a Premium subscriber. To save the playlist, please upgrade your subscription now.`}
                    confirmButtonText="Upgrade Now"
                    onConfirm={() => {
                        router.push(getPlatformLinkUsingRouter('/account/plan'));
                    }}
                    onClose={() => {
                        setShowUpgradeModal(false);
                        router.back();
                    }}
                    variant="light"
                    isOpen={showUpgradeModal}
                />

                <ActionModal
                    inputRenderKey={actualCategories.length}
                    headerTitle="Attention!"
                    title="Playlist Collaboration Expired"
                    subtitle={`Collaboration for this playlist has expired because the owner of '${playlist.title}' is no longer a Premium subscriber. However, you can save the playlist to your own library by selecting a folder below.`}
                    confirmButtonText="Save Playlist"
                    onConfirm={() => {
                        if (copyToCategoryId) {
                            UserPlaylistApi.copyUserPlaylist(playlistId as string, copyToCategoryId as any).then((pl) => {
                                globalMutate(getUserPlaylistsByCategoryIdKey(isDownload, copyToCategoryId, true), null, { revalidate: true });
                                globalMutate(getUserPlaylistsByCategoryIdKey(isDownload, copyToCategoryId, false), null, { revalidate: true });
                                globalMutate(getUserPlaylistsByCategoryIdKey(isDownload, copyToCategoryId, undefined), null, { revalidate: true });
                                globalMutate(getUserPlaylistsByCategoryIdKey(isDownload, 'shared', true), null, { revalidate: true });
                                globalMutate(getUserPlaylistsByCategoryIdKey(isDownload, 'shared', false), null, { revalidate: true });
                                globalMutate(getUserPlaylistsByCategoryIdKey(isDownload, 'shared', undefined), null, { revalidate: true });
                                router.push(getPlatformLinkUsingRouter(`/my-playlist/${pl.data.id}`));
                                setShowCopyModal(false);
                                return null;
                            }).catch((err) => {
                                errorHandler({ error: err });
                                setShowCopyModal(false);
                            });
                        }
                    }}
                    onClose={() => {
                        setShowCopyModal(false);
                        router.back();
                    }}
                    hideCancel
                    renderInput={() => <Dropdown
                        containerClassname={styles['my-playlist-detail__copy-dropdown']}
                        optionContainerClassname={styles['my-playlist-detail__copy-dropdown--options']}
                        placeHolder="Select Folder"
                        layoutType="input"
                        value={copyToCategoryId}
                        onClick={(v) => {
                            if (v === '-1') {
                                setShowCopyModal(false);
                                addFolder();
                            } else {
                                setCopyToCategorryId(v);
                            }
                        }}
                        options={[{ label: 'Create New Folder', value: '-1' }].concat(actualCategories.map((c) => {
                            return {
                                label: c.name,
                                value: `${c.id}`
                            };
                        }))} />}
                    variant="dark"
                    contentClassname={styles['my-playlist-detail__copy-modal']}
                    isOpen={showCopyModal}
                />
            </div>
        </>
    ) : null;
}

export default MyPlaylistDetail;
