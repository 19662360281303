import classNames from 'classnames';
import CheckIcon from '../../../../assets/icons/check.svg';
import CloseIcon from '../../../../assets/icons/close.svg';

import styles from './subscription-item.module.css';

interface SubscriptionValuePropositionProps {
    label: string;
    isAvailable: boolean;
    textClassname?: string;
}

export function SubscriptionValueProposition({ label, isAvailable, textClassname }: SubscriptionValuePropositionProps) {
    return (
        <li key={label} className={classNames(styles['subscription-item__value-proposition'], textClassname)}>
            {isAvailable ? <div className={styles['subscription-item__green-icon']}><CheckIcon /></div> : <CloseIcon />}
            {label}
        </li>
    );
}
