/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useGetDrives, useGetSharedDrives } from '@bpm-web-app/swr-hooks';
import { Analytics, sortArray } from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import { Collapse } from 'react-collapse';
import { Drive, UserDriveCollaborationAccessLevel } from '@bpm-web-app/create-api-sdk';
import styles from './nav-my-drive-folders.module.css';

import navStyles from '../nav/nav.module.css';
import { ReactComponent as ChevronRight } from '../../assets/icons/chevron-right.svg';
import Folder from '../../assets/icons/folder.svg';
import { AppLink } from '../shared/app-link/app-link';
import { CustomIcon } from '../shared/custom-icon/custom-icon';
import { ContextMenuClickIgnoreClass, ContextMenuContext, ContextMenuItemProps } from '../shared';
import { LOCAL_SORT_OPTIONS } from '../nav/nav';
import { useOnDragElement } from '../drag-and-drop/drag-and-drop.context';
import Droppable from '../droppable/droppable';
import { LocalSearchSort } from '../nav-my-playlist-categories/utils';

export interface NavMyDriveFoldersProps {
    sort: LocalSearchSort | undefined;

}

export function NavMyDriveFolders({ sort }: NavMyDriveFoldersProps) {
    const { data: sharedDrivesData } = useGetSharedDrives(true);
    const { data: foldersData } = useGetDrives();
    const [userDrives, setUserDrives] = useState<Drive[]>([]);

    const { openContextMenu: handleOpenContextMenu, closeOptions, isOpen } = useContext(ContextMenuContext);
    const { isDragging } = useOnDragElement();

    const [showSharedDrives, setShowSharedDrives] = useState(false);
    const [sharedDrivesSort, setSharedDrivesSort] = useState('most_recent');
    const router = useRouter();

    const sharedFolderIcon = useMemo(() => <CustomIcon type="shared-folder" size="regular" color="boulder" />, []);

    useEffect(() => {
        if (foldersData) {
            setUserDrives(foldersData);
        }
    }, [foldersData]);

    const folderList = useMemo(() => {
        if (!userDrives) return [];
        if (sort === 'most_recent') {
            return sortArray(userDrives, 'updated_at', 'DESC');
        }

        if (sort === 'title_asc' || sort === 'title_desc') {
            return sortArray(
                userDrives.map((item) => ({ ...item, lowerCaseName: item.name.toLowerCase() })),
                'lowerCaseName',
                sort === 'title_asc' ? 'ASC' : 'DESC'
            );
        }

        return userDrives;
    }, [sort, userDrives]);

    const sharedDrivesList = useMemo(() => {
        if (!sharedDrivesData?.data) return [];
        if (sharedDrivesSort === 'most_recent') {
            return sortArray(sharedDrivesData?.data, 'updated_at', 'DESC');
        }

        if (sharedDrivesSort === 'title_asc' || sharedDrivesSort === 'title_desc') {
            return sortArray(
                sharedDrivesData?.data.map((item) => ({ ...item, lowerCaseName: item.name.toLowerCase() })),
                'lowerCaseName',
                sharedDrivesSort === 'title_asc' ? 'ASC' : 'DESC'
            );
        }

        return sharedDrivesData?.data;
    }, [sharedDrivesSort, sharedDrivesData?.data]);

    const contextMenuOptions = useCallback(() => {
        const menuOptions: ContextMenuItemProps[] = [
            {
                leftIcon: <CustomIcon type="new-window-icon" hasIconHover />,
                label: 'Go to Shared With Me',
                onClick: () => {
                    closeOptions();
                    router.push('/my-drive/shared');
                },
            },
            {
                label: 'Sort By…',
                leftIcon: <CustomIcon type="sort-icon" hasIconHover />,
                items: LOCAL_SORT_OPTIONS.map((option) => {
                    return {
                        label: option.label,
                        onClick: () => {
                            closeOptions();
                            setSharedDrivesSort(option.value);
                        }
                    };
                })
            }
        ];
        return menuOptions;
    }, [closeOptions, router, setSharedDrivesSort]);

    const handleOpenThreeDots = (e: React.MouseEvent<HTMLElement>) => {
        const { left, top, width } = e.currentTarget.getBoundingClientRect();
        if (isOpen('drive-shared')) {
            closeOptions();
        } else {
            handleOpenContextMenu({ id: 'drive-shared', options: contextMenuOptions(), left, top, renderLocationPosition: 'sidebar', align: 'right', buttonWidth: width });
        }
    };
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const isActive = useCallback((id: string) => router.pathname.includes('my-drive') && router.query['id'] && router.query['id'] === id, [router]);

    return (
        <>
            {sharedDrivesData && sharedDrivesData.data && sharedDrivesData.data.length > 0 ? (
                <Droppable onlyHover accept="Sound">
                    {(state) => (
                        <>
                            <button
                                type="button"
                                aria-label="View shared Drives"
                                className={classNames(styles['nav-my-drive-folders-item'], {
                                    [styles['nav-my-drive-folders-item--is-dragging']]: isDragging,
                                    [styles['nav-my-drive-folders-item--is-dragging--over']]: state.isOver,
                                    [styles['nav-my-drive-folders-item--active']]: router.pathname === '/my-drive/shared'
                                })}
                                onClick={() => {
                                    setShowSharedDrives(!showSharedDrives);
                                }}>
                                <i className={styles['nav-my-drive-folders-item__button']}>
                                    {sharedFolderIcon}
                                </i>
                                <span>Shared With Me</span>
                                <div className={classNames(styles['nav-my-drive-folders-item--three-dots'], { [styles['nav-my-drive-folders-item--three-dots--open']]: showSharedDrives })}>
                                    <CustomIcon
                                        type="three-dots"
                                        hasIconHover
                                        className={ContextMenuClickIgnoreClass}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleOpenThreeDots(e);
                                        }} />
                                </div>
                                <div
                                    tabIndex={0}
                                    role="button"
                                    onKeyDown={() => { }}
                                    className={`${classNames(
                                        styles['nav__submenu-btn'],
                                        styles['nav__submenu-btn--context-toggle']
                                    )}`}
                                    aria-label="Open or Close List"
                                    onClick={() => {
                                        setShowSharedDrives(!showSharedDrives);
                                    }}
                                >
                                    <ChevronRight
                                        className={classNames(styles['nav-my-drive-folders-item--chevron'], { [styles['nav-my-drive-folders-item--chevron--open']]: showSharedDrives })}
                                    />
                                </div>
                            </button>

                            <Collapse isOpened={showSharedDrives || state.isOver}>
                                <div className={classNames(navStyles['nav__dropdown-container'], { [navStyles['nav__dropdown-container--open']]: showSharedDrives || state.isOver })}>
                                    {sharedDrivesList.map((item, index) => (
                                        <Droppable
                                            accept="Sound"
                                            key={item.id}
                                            disabled={item.access_level === UserDriveCollaborationAccessLevel.View}
                                            onDrop={() => {
                                                return { target: 'my-drive', id: item.id };
                                            }}>
                                            {(p) => (
                                                <AppLink
                                                    href={`/my-drive/${item.id}`}>
                                                    <button
                                                        aria-label="View Drive"
                                                        disabled={item.access_level === UserDriveCollaborationAccessLevel.View && isDragging}
                                                        onClick={() => {
                                                            Analytics.trackClick('nav_bar', item.id, { location: 'my_drive_folder' });
                                                        }}
                                                        role="link"
                                                        type="button"
                                                        tabIndex={index}
                                                        onKeyDown={() => { }}
                                                        className={classNames(styles['nav-my-drive-folders-item'], {
                                                            [styles['nav-my-drive-folders-item--is-dragging']]: isDragging,
                                                            [styles['nav-my-drive-folders-item--is-dragging--over']]: p.isOver,
                                                            [styles['nav-my-drive-folders-item--active']]: isActive(item.id),
                                                        })}
                                                    >
                                                        <i className={styles['nav-my-drive-folders-item__button']} />
                                                        <span>{item.name}</span>
                                                        {item.users && item.users.length > 0 ? (
                                                            <CustomIcon type="shared-with-me" isIconHoverActive={isActive((item.id)) || false} tooltip="Collaborators Added" color="boulder" />
                                                        ) : null}

                                                    </button>
                                                </AppLink>

                                            )}
                                        </Droppable>
                                    ))}
                                </div>
                            </Collapse>
                        </>
                    )}
                </Droppable>
            ) : null}
            {folderList.filter((item) => item.is_own).map((item, index) => (
                <Droppable
                    accept="Sound"
                    key={item.id}
                    disabled={item.access_level === UserDriveCollaborationAccessLevel.View}
                    onDrop={() => {
                        return { target: 'my-drive', id: item.id };
                    }}>
                    {(p) => (
                        <AppLink
                            key={item.id}
                            href={`/my-drive/${item.id}`}
                        >
                            <a
                                onClick={() => {
                                    Analytics.trackClick('nav_bar', item.id, { location: 'my_drive_folder' });
                                }}
                                role="link"
                                tabIndex={index}
                                onKeyDown={() => { }}
                                aria-label="View Drive"
                                className={classNames(styles['nav-my-drive-folders-item'], {
                                    [styles['nav-my-drive-folders-item--is-dragging']]: isDragging,
                                    [styles['nav-my-drive-folders-item--is-dragging--over']]: p.isOver,
                                    [styles['nav-my-drive-folders-item--active']]: isActive(item.id),
                                })}
                            >
                                <i className={classNames(styles['nav-my-drive-folders-item__button'],
                                    { [styles['nav-my-drive-folders-item__button--active']]: isActive(item.id) },
                                )}>
                                    <Folder />
                                </i>
                                <span>{item.name}</span>
                                {item.users && item.users.length > 0 ? (
                                    <CustomIcon type="shared-with-me" isIconHoverActive={isActive((item.id)) || false} tooltip="Collaborators Added" color="boulder" />
                                ) : null}
                            </a>
                        </AppLink>
                    )}
                </Droppable>
            ))}
        </>
    );
}

export default NavMyDriveFolders;
/* eslint-enable jsx-a11y/anchor-is-valid */
