import { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import styles from './filters.module.css';
import { FiltersContext } from './filters.context';
import VersionsFilter from '../versions-filter/versions-filter';
import BpmFilter from '../shared/bpm-filter/bpm-filter';
import KeyFilter from '../key-filter/key-filter';
import GenresFilter from '../genres-filter/genres-filter';
import TagsFilter from '../tags-filter/tags-filter';
import CreateGenresFilter from '../genres-filter/create-genres-filter';
import { FileTypeFilter } from '../file-type-filter/file-type-filter';
import ShowingFilter from '../showing-filter/showing-filter';
import ShowingFilterCreate from '../showing-filter/showing-filter-create';
import ArrowRight from '../../assets/icons/chevron-right-double.svg';

export interface SupremeFiltersProps {
    platform: 'stream' | 'download';
    showOnMobile?: boolean;
    hideButton?: boolean;
    inlineMobileStyle?: boolean;
    hideCreateGenres?: never;
    hideCreateTags?: never;
    hideFileType?: never;
    hideBPM?: boolean;
    hideKey?: boolean;
    rightAlign?: boolean;
}

export interface CreateFiltersProps {
    platform: 'create';
    showOnMobile?: boolean;
    hideButton?: boolean;
    inlineMobileStyle?: boolean;
    hideCreateGenres?: boolean;
    hideCreateTags?: boolean;
    hideBPM?: boolean;
    hideKey?: boolean;
    hideFileType?: boolean;
    rightAlign?: boolean;
}

type FiltersProps = SupremeFiltersProps | CreateFiltersProps;

export function Filters(props: FiltersProps) {
    const { platform,
        showOnMobile = false,
        hideButton = false,
        inlineMobileStyle = false,
        hideCreateGenres = false,
        hideCreateTags = false,
        hideFileType = false,
        rightAlign = false,
        hideBPM = false,
        hideKey = false } = props;

    const {
        resetFilters,
        isFiltersOpen,
        openFilters,
        closeFilters,
        versions,
        hideRemixes,
        hideExclusives,
        hideExplicit,
        hidePrevDownloaded,
        hidePrevPlayed,
        bpm,
        setBpm,
        setHideRemixes,
        setHideExclusives,
        setHideExplicit,
        setHidePrevDownloaded,
        setHidePrevPlayed,
        setVersions,
        key,
        setKey,
        genres,
        setGenres
    } = useContext(FiltersContext);

    const [filterCount, setFilterCount] = useState<number>(0);

    const router = useRouter();
    const isSupreme = platform === 'download';

    const countFiltersApplied = (query: ParsedUrlQuery) => {
        let count = 0;
        Object.keys(query).forEach((objKey) => {
            const filterItems = query[objKey];
            const filterToCount = ['versions', 'bpm', 'key', 'genre', 'subgenre', 'tags', 'tagGroups', 'fileType'];
            const booleanFilterToCount = ['hideRemixes', 'hideExclusives', 'hideExplicit', 'hidePrevDownloaded', 'hidePrevPlayed'];

            const countFilter = filterToCount.includes(objKey) || (booleanFilterToCount.includes(objKey) && filterItems === '1');

            if (!countFilter) return;
            if (filterItems instanceof Array) {
                if (objKey === 'bpm') {
                    count += 1;
                } else {
                    count += filterItems.length;
                }
            } else count += 1;
        });
        return count;
    };

    useEffect(() => {
        setFilterCount(countFiltersApplied(router.query));
    }, [router.query]);

    useEffect(
        () => () => {
            // NOTE: Clean up function
            closeFilters();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        /* eslint-disable-next-line @typescript-eslint/dot-notation */
        <div className={classNames(styles['filters'], {
            [styles['filters--open']]: isFiltersOpen,
            [styles['filters__right-aligned']]: rightAlign
        })}>
            {!hideButton && (
                <button
                    className={classNames({
                        [styles['filters__filter-button']]: !inlineMobileStyle,
                        [styles['filters__filter-button--search']]: inlineMobileStyle
                    })}
                    onClick={openFilters}
                    type="button"
                    aria-label="Open Filters"
                >

                    {(filterCount > 0) &&
                        <div className={classNames({
                            [styles['filters__open-counter']]: !inlineMobileStyle,
                            [styles['filters__open-counter--hidden']]: inlineMobileStyle
                        })}>
                            <span className={classNames(styles['filters__parenth'])}>(</span>
                            {filterCount}
                            <span className={classNames(styles['filters__parenth'])}>)</span>
                        </div>}

                    <div
                        className={classNames([styles['filters__open-btn'], { [styles['filters__open-btn--show-on-mobile']]: showOnMobile }])}
                    >
                        <i className={`icon icon--nav-filter${filterCount ? '--active' : ''}`} />
                    </div>
                </button>
            )}
            <form className={styles['filters__container']}>
                <div className={styles['filters__header']}>
                    <div
                        className={styles['filters__title-container']}
                        role="button"
                        tabIndex={0}
                        onClick={closeFilters}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                closeFilters();
                            }
                        }}>
                        <h5 className={styles['filters__title']}>Filters</h5>
                    </div>
                    {(filterCount > 0) && (
                        <button
                            type="button"
                            className={styles['filters__reset']}
                            onClick={(e) => {
                                e.stopPropagation();
                                resetFilters();
                            }}>
                            Clear All
                        </button>
                    )}
                    <button className={styles['filters__close']} type="button" aria-label="Close Filters" onClick={closeFilters}>
                        <ArrowRight />
                    </button>
                </div>
                <div className={styles['filters__wrapper']}>
                    {platform === 'create' && (
                        <>
                            {hideFileType ? null : <FileTypeFilter />}
                            {hideCreateGenres ? null : <CreateGenresFilter />}
                            {hideKey ? null : <KeyFilter value={key} onChange={setKey} />}

                            {hideBPM ? null : <BpmFilter value={bpm} onChange={setBpm} />}
                            {hideCreateTags ? null : <TagsFilter />}
                            <ShowingFilterCreate
                                hidePrevPlayed={hidePrevPlayed}
                                onHidePrevPlayed={setHidePrevPlayed}
                                hidePrevDownloaded={hidePrevDownloaded}
                                onHidePrevDownloaded={setHidePrevDownloaded} />
                        </>
                    )}
                    {(platform === 'download' || platform === 'stream') && (
                        <>
                            <GenresFilter value={genres} onChange={setGenres} />
                            {hideBPM ? null : <BpmFilter value={bpm} onChange={setBpm} />}
                            {hideKey ? null : <KeyFilter value={key} onChange={setKey} />}
                            {isSupreme && <ShowingFilter
                                hideRemixes={hideRemixes}
                                onHideRemixes={setHideRemixes}
                                hideExclusives={hideExclusives}
                                onHideExclusives={setHideExclusives}
                                hideExplicit={hideExplicit}
                                onHideExplicit={setHideExplicit}
                                hidePrevDownloaded={hidePrevDownloaded}
                                onHidePrevDownloaded={setHidePrevDownloaded} />}
                            {isSupreme && <VersionsFilter value={versions} onChange={setVersions} />}
                        </>
                    )}
                </div>
            </form>
        </div>
    );
}

export default Filters;
