import { useRouter } from 'next/router';
import { AppLink } from '../shared/app-link/app-link';
import BPMIcons from '../shared/bpm-icons/bpm-icons';
import styles from './toast-close-button.module.css';

interface ToastCloseButtonProps {
    title?: string;
    onClick?: () => void;
    closeToast?: () => void;
    routeOnClick?: string;
    replaceRouteOnClick?: string;
}

export function ToastCloseButton({ title, onClick, routeOnClick, replaceRouteOnClick, closeToast }: ToastCloseButtonProps) {
    const router = useRouter();

    return (
        <div className={styles['toast-close-button']}>
            {title
                ? replaceRouteOnClick
                    ?
                    (
                        <AppLink href={replaceRouteOnClick}>
                            <a
                                className={styles['toast-close-button--title']}
                            >
                                {title}
                            </a>
                        </AppLink>
                    )
                    :
                    (
                        <button
                            className={styles['toast-close-button--title']}
                            type="button"
                            onClick={() => {
                                if (onClick) onClick();
                                if (routeOnClick) router.push(routeOnClick);
                            }}
                        >
                            {title}
                        </button>
                    )
                : null
            }
            <div className={styles['toast-close-button--close-button']}>
                <BPMIcons.CloseIcon onClick={closeToast} />
            </div>
        </div>
    );
}
