/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import React, { ReactElement, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Album as StreamAlbum, CuratedSetWithMedia, Media as StreamMedia, MediaWithAlbum, PlaylistWithMedia, UserPlaylistWithMedia } from '@bpm-web-app/stream-api-sdk';
import { CuratedSets, Playlist, UserPlaylist } from '@bpm-web-app/api-client';
import { Album as DownloadAlbum, CuratedSetWithAlbum, Media, PlaylistWithAlbum, UserPlaylistWithAlbum, Playlist as downloadPlaylist, Album, CuratedSet, Artist } from '@bpm-web-app/download-api-sdk';
import {
    Analytics,
    DetailPageResource,
    downloadAlbumWithMediaToQueueItem,
    getResourceFromLink,
    QueueItem,
    State,
    streamMediaWithAlbumToQueueItem,
    streamMediaWithoutAlbumToQueueItem,
    useApiErrorHandler,
    useHubSwitch,
    usePlayerState,
    useUserSettings,
    useViewport,
} from '@bpm-web-app/utils';
import { useInView } from 'react-intersection-observer';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { SoundPackage } from '@bpm-web-app/create-api-sdk';
import { usePlayer } from '../../player-context';
import styles from './card.module.css';

import { ActionType, ThreeDotsSheetContext } from '../three-dots-sheet/three-dots-sheet.context';
import ThreeDotsIconSmall from '../../../assets/icons/more-dots-vert-small.svg';
import { ReactComponent as Top } from '../../../assets/icons/top-badge.svg';
import { ReactComponent as Trending } from '../../../assets/icons/trending-badge.svg';
import { ReactComponent as Hot } from '../../../assets/icons/hot-badge.svg';
import { ReactComponent as NewSupreme } from '../../../assets/icons/new-badge.svg';
import { ReactComponent as ExclusiveSupreme } from '../../../assets/icons/exclusive-badge.svg';
import { ReactComponent as PremiumBadge } from '../../../assets/icons/premium-card-badge.svg';
import { ReactComponent as PremiumAvailableBadge } from '../../../assets/icons/premium-available-card-badge.svg';
import { ReactComponent as ExclusiveSoundPacks } from '../../../assets/icons/exclusive-soundpacks-badge.svg';
import { ReactComponent as Classic } from '../../../assets/icons/classic-badge.svg';
import { ReactComponent as StaffPick } from '../../../assets/icons/staffpick-badge.svg';

import useFollowCuratedSets from '../three-dots-sheet/useFollowCuratedSets';
import { LibraryTabsContext } from '../../../../../utils/src/lib/library-tabs.context';
import useFavoriteAlbums from '../three-dots-sheet/useFavoriteAlbums';
import useFavoriteMedia from '../three-dots-sheet/useFavoriteMedia';
import { useFollowPlaylists } from '../three-dots-sheet/useFollowPlaylist';
import { AppLink } from '../app-link/app-link';
import { ThreeDotsSheetVersionsContext } from '../three-dots-sheet/three-dots-sheet-versions.context';
import { Accepting, DragDropItem, DragResult } from '../../droppable/droppable';
import { useCrates } from '../three-dots-sheet/useCrates';
import { useUserPlaylistsDetails } from '../three-dots-sheet/useUserPlaylistsDetails';
import { useActionModal } from '../action-modal/action-modal.context';
import { CardImageOverlay } from './card-image-overlay/card-image-overlay';
import { CardDescription, SupremeCardDescriptionProps } from './card-description/card-description';
import { CardImage } from './card-image/card-image';
import { useCreatePlaylistForm } from '../../playlists-form/create-playlist-form-context';

export type CardCustomPreset = 'full-width-mobile';

export interface CardProps {
    /* TODO: revisit these if the card becomes something more generic */
    title?: string;
    linkedArtist?: Artist;
    contentType?: ActionType;
    imageUrl: string;
    imageUrl2x?: string;
    imageComponent?: ReactElement;
    id: number | string;
    label?: string | ReactNode;
    album?: DownloadAlbum | StreamAlbum;
    playlist?: StreamMedia[] | MediaWithAlbum[] | DownloadAlbum['media'];
    isPlayable: boolean;
    link?: string;
    media?: StreamMedia | DownloadAlbum['media'][0];
    cardSize: 'small' | 'medium' | 'large' | 'large-square' | 'user-playlist';
    description?: ReactNode;
    isDescriptionSingleLine?: boolean;
    fullHeight?: boolean;
    openInNewTab?: boolean;
    userPlaylistConfig?: {
        initials: string;
        imageBgColor: string;
    };
    onCardPlay?: () => void;
    preset?: CardCustomPreset;
    badge?: DownloadAlbum.BadgeEnum | SoundPackage.BadgeEnum | downloadPlaylist.BadgeEnum
    descriptionProps?: SupremeCardDescriptionProps;
    currentPlaylist?: DownloadAlbum[] | MediaWithAlbum[] | downloadPlaylist[] | CuratedSet[];
    gridImagesUrls?: string[];
    onOpenContextMenu?: (e: React.MouseEvent) => void;
    isPremiumOnly?: boolean;
    hasPremiumOnlyAccess?: boolean;
    className?: string;
}

export function Card({
    album,
    cardSize,
    id,
    title,
    imageUrl,
    imageUrl2x,
    imageComponent,
    playlist,
    isPlayable,
    link,
    media,
    contentType,
    description,
    openInNewTab,
    onCardPlay,
    userPlaylistConfig,
    fullHeight,
    isDescriptionSingleLine = false,
    preset,
    badge,
    descriptionProps,
    currentPlaylist,
    gridImagesUrls,
    linkedArtist,
    onOpenContextMenu,
    hasPremiumOnlyAccess,
    isPremiumOnly,
    className
}: CardProps) {
    const { setQueue, silenceWorkaround, currentTrack, togglePlayPause, originalListDetails } = usePlayer();
    const { isDownload } = useHubSwitch();
    const [isCardImageLoaded, setIsCardImageLoaded] = useState(false);
    const [isHoveringCard, setIsHoveringCard] = useState(false);
    const { isAnonymous, setSelectedMedia, setShowSignUpModal } = useUserSettings();

    const identifier = useMemo(() => `${id}`, [id]);

    const resource = useMemo<DetailPageResource | null>(() => (link ? getResourceFromLink(link) : null), [link]);

    const { openThreeDotsModalSheet } = useContext(ThreeDotsSheetContext);
    const { openThreeDotsModalSheet: openThreeDotsVersionsModalSheet } = useContext(ThreeDotsSheetVersionsContext);

    const [cardPlaylist] = useState(playlist || []);

    const isCurrentCardPlaying = useMemo(() => {
        if (id === currentTrack?.id || id === currentTrack?.album_id || originalListDetails?.identifier === `${id}`) return true;

        return false;
    }, [currentTrack, id, originalListDetails?.identifier]);

    const shouldSetMediaInContext = useMemo(() => {
        if (isAnonymous || (isPremiumOnly === true && hasPremiumOnlyAccess === false)) return true;
        return false;
    }, [hasPremiumOnlyAccess, isAnonymous, isPremiumOnly]);

    const setCurrentMediaInContext = useCallback((showSignUpModal?: boolean) => {
        if (shouldSetMediaInContext) {
            switch (contentType) {
                case 'curatedSet': {
                    const currentCenterMedia = (currentPlaylist as CuratedSet[])?.find((currentMediaPlaylist) => id === currentMediaPlaylist.id);
                    if (currentCenterMedia) setSelectedMedia(currentCenterMedia, currentPlaylist);
                    break;
                }
                case 'exclusive-playlist': {
                    const currentCenterMedia = (currentPlaylist as downloadPlaylist[])?.find((currentMediaPlaylist) => id === currentMediaPlaylist.id);
                    if (currentCenterMedia) setSelectedMedia(currentCenterMedia, currentPlaylist);
                    break;
                }

                default:
                    if (album as Album) setSelectedMedia(album as Album, currentPlaylist);
                    break;
            }
            if (showSignUpModal) {
                switch (contentType) {
                    case 'curatedSet':
                    case 'exclusive-playlist':
                        setShowSignUpModal({ type: 'playlist' });
                        break;
                    default:
                        setShowSignUpModal({ type: 'track' });
                        break;
                }
            }
        }
    }, [album, contentType, currentPlaylist, id, setSelectedMedia, setShowSignUpModal, shouldSetMediaInContext]);

    const handleShowPremiumPressed = useCallback(
        async () => {
            if (shouldSetMediaInContext) {
                setCurrentMediaInContext(true);
            }
        },
        [shouldSetMediaInContext, setCurrentMediaInContext]
    );

    const openThreeDots = useCallback(
        async (element: React.MouseEvent) => {
            element.preventDefault();
            if (shouldSetMediaInContext) {
                setCurrentMediaInContext(true);
                return;
            }

            if (onOpenContextMenu) {
                onOpenContextMenu(element);
                return;
            }
            const { top, left } = (element.target as HTMLButtonElement).getBoundingClientRect();
            openThreeDotsModalSheet(contentType as ActionType, id, left, top + window.scrollY, false);
        },
        [shouldSetMediaInContext, onOpenContextMenu, openThreeDotsModalSheet, contentType, id, setCurrentMediaInContext]
    );

    const openDownloadMenu = useCallback(
        async (element) => {
            if (shouldSetMediaInContext) {
                setCurrentMediaInContext(true);
                return;
            }
            const { top, left } = (element.target as HTMLButtonElement).getBoundingClientRect();
            openThreeDotsVersionsModalSheet({
                leftPosition: left,
                topPosition: top + window.scrollY,
                media: album?.media as Media[],
                id: album?.id as number,
                show: true,
            });
        },
        [album?.id, album?.media, openThreeDotsVersionsModalSheet, setCurrentMediaInContext, shouldSetMediaInContext]
    );

    const { libraryProperty } = useContext(LibraryTabsContext);
    const playerState = usePlayerState();

    const { addCuratedSetsToFavorites, removeCuratedSetFromFavorites, isCuratedSetFollowed } = useFollowCuratedSets(id as number, libraryProperty);

    const { addAlbumToFavorites, removeAlbumFromFavorites, isAlbumFavorite } = useFavoriteAlbums(id as number);

    const { addToFavorites, removeFromFavorites, isMediaFollowed } = useFavoriteMedia(id as number, libraryProperty);

    const { addPlaylistToFavorites, removePlaylistFromFavorites, isPlaylistFollowed } = useFollowPlaylists(id as number, libraryProperty);

    const { openModal: openActionModal, closeModal: closeActionModal } = useActionModal();

    const { openCreatePlaylistForm } = useCreatePlaylistForm();

    const isFavorite = useMemo(() => {
        switch (contentType) {
            case 'stream:album':
            case 'download:album':
                return isAlbumFavorite;
            case 'curatedSet':
                return isCuratedSetFollowed;
            case 'track':
                return isMediaFollowed;
            case 'exclusive-playlist':
                return isPlaylistFollowed;
            case 'for-you-playlist':
            case 'user-playlist':
                return false;
            default:
                return isMediaFollowed;
        }
    }, [contentType, isAlbumFavorite, isCuratedSetFollowed, isMediaFollowed, isPlaylistFollowed]);

    const errorHandler = useApiErrorHandler();

    const handleFavorite = useCallback(async (onlyLike?: boolean) => {
        if (shouldSetMediaInContext) {
            setCurrentMediaInContext(true);
            return;
        }
        switch (contentType) {
            case 'stream:album':
            case 'download:album':
                if (isAlbumFavorite && onlyLike !== true) {
                    removeAlbumFromFavorites();
                } else {
                    addAlbumToFavorites();
                }
                break;
            case 'curatedSet':
                if (isCuratedSetFollowed && onlyLike !== true) {
                    removeCuratedSetFromFavorites();
                } else {
                    addCuratedSetsToFavorites();
                }
                break;
            case 'track':
                if (isMediaFollowed && onlyLike !== true) {
                    removeFromFavorites();
                } else {
                    addToFavorites();
                }
                break;
            case 'exclusive-playlist':
                if (isPlaylistFollowed && onlyLike !== true) {
                    removePlaylistFromFavorites();
                } else {
                    addPlaylistToFavorites();
                }
                break;
            case 'for-you-playlist':
            case 'user-playlist':
                break;
            default:
                if (isMediaFollowed && onlyLike !== true) {
                    removeFromFavorites();
                } else {
                    addToFavorites();
                }
        }
    }, [
        shouldSetMediaInContext,
        contentType,
        setCurrentMediaInContext,
        isAlbumFavorite,
        isCuratedSetFollowed,
        isMediaFollowed,
        isPlaylistFollowed,
        removeAlbumFromFavorites,
        addAlbumToFavorites,
        removeCuratedSetFromFavorites,
        addCuratedSetsToFavorites,
        removeFromFavorites,
        addToFavorites,
        removePlaylistFromFavorites,
        addPlaylistToFavorites
    ]);

    const handleFetchMedia = useCallback(
        async (play: boolean) => {
            if (!contentType || contentType === 'track' || !id || (cardPlaylist && media)) return [];

            switch (contentType) {
                case 'curatedSet': {
                    try {
                        silenceWorkaround();
                        const { data } = await CuratedSets.getCuratedSet(isDownload, +id);
                        if (!data) break;

                        if (play) {
                            const queueMedia = isDownload
                                ? (data as CuratedSetWithAlbum).albums?.map((curatedSetAlbum) => downloadAlbumWithMediaToQueueItem(curatedSetAlbum))
                                : (data as CuratedSetWithMedia).media?.map((curatedSetMedia) => streamMediaWithAlbumToQueueItem(curatedSetMedia));

                            setQueue(queueMedia as QueueItem[], 0, { identifier, resource });
                        }
                        return data;
                        break;
                    } catch (error) {
                        errorHandler({ error });
                        break;
                    }
                }

                case 'exclusive-playlist': {
                    try {
                        // Can't use SWR because it's a hook
                        // eslint-disable-next-line max-len
                        // TODO: Maybe try to check if SWR cache can help to avoid a bit more fetch without the help of SWR?
                        silenceWorkaround();
                        const { data } = await Playlist.getPlaylistDetail(isDownload, Number(id));
                        if (!data) break;

                        if (play) {
                            const queueMedia = isDownload
                                ? (data as PlaylistWithAlbum).albums?.map((curatedSetAlbum) => downloadAlbumWithMediaToQueueItem(curatedSetAlbum))
                                : (data as PlaylistWithMedia).media?.map((curatedSetMedia) => streamMediaWithAlbumToQueueItem(curatedSetMedia));
                            setQueue(queueMedia as QueueItem[], 0, { identifier, resource });
                        }
                        return data;

                        break;
                    } catch (error: unknown) {
                        errorHandler({ error });
                        break;
                    }
                }
                case 'for-you-playlist':
                case 'user-playlist':
                    try {
                        // Can't use SWR because it's a hook
                        // eslint-disable-next-line max-len
                        // TODO: Maybe try to check if SWR cache can help to avoid a bit more fetch without the help of SWR?
                        silenceWorkaround();
                        const { data } = await UserPlaylist.getUserPlaylistDetail(isDownload, `${id}`);
                        if (!data) break;

                        if (play) {
                            const queueMedia = isDownload
                                ? (data as UserPlaylistWithAlbum).albums?.map((curatedSetAlbum) => downloadAlbumWithMediaToQueueItem(curatedSetAlbum))
                                : (data as UserPlaylistWithMedia).media?.map((curatedSetMedia) => streamMediaWithAlbumToQueueItem(curatedSetMedia));

                            if (queueMedia) setQueue(queueMedia, 0, { identifier, resource });
                        }
                        return data;

                        break;
                    } catch (error) {
                        errorHandler({ error });
                        break;
                    }

                default:
                    break;
            }
            return [];
        },
        [contentType, id, cardPlaylist, media, silenceWorkaround, isDownload, setQueue, identifier, resource, errorHandler]
    );

    const handlePlayCard = useCallback(() => {
        if (shouldSetMediaInContext) {
            setCurrentMediaInContext();
        }
        if (isCurrentCardPlaying) {
            togglePlayPause();
            return;
        }
        if (onCardPlay) {
            onCardPlay();
            return;
        }

        if (media) {
            if (isDownload && album) {
                // NOTE: We want only to add the 1st version of the album
                setQueue([downloadAlbumWithMediaToQueueItem(album as DownloadAlbum)], 0, { identifier, resource });
                const queueItems = (album as StreamAlbum).media.map((albumMedia) =>
                    streamMediaWithoutAlbumToQueueItem(albumMedia, {
                        cover_url: album.cover_url,
                        genre: album.genre,
                        isExclusive: album.is_exclusive,
                    })
                );
                setQueue(queueItems, 0, { identifier, resource });
            } else if (!isDownload) {
                const index = playlist?.findIndex((playlistMedia) => playlistMedia.id === id);

                const queueItems = playlist?.map((playlistMedia) => streamMediaWithAlbumToQueueItem(playlistMedia as MediaWithAlbum));

                setQueue(queueItems as QueueItem[], index, { identifier, resource });
            }
        } else {
            handleFetchMedia(true);
        }
    }, [
        shouldSetMediaInContext,
        isCurrentCardPlaying,
        onCardPlay,
        media,
        setCurrentMediaInContext,
        togglePlayPause,
        isDownload,
        album,
        setQueue,
        identifier,
        resource,
        playlist,
        id,
        handleFetchMedia
    ]);

    const { ref: viewRef, inView } = useInView({
        triggerOnce: true,
        threshold: 1
    });

    useEffect(() => {
        if (inView) {
            if (isDownload && album) {
                Analytics.trackImpression('Album', `${album.id}`);
            } else if (!isDownload && album) {
                Analytics.trackImpression('Album', `${album.id}`);
            } else if (!isDownload && media) {
                Analytics.trackImpression('Album', `${media.album_id}`);
            }
        }
    }, [inView, album, media, isDownload]);

    const renderBadge = useCallback(() => {
        if (isPremiumOnly && !hasPremiumOnlyAccess) {
            return <PremiumBadge height={24} width={75} />;
        }

        if (isPremiumOnly && hasPremiumOnlyAccess) {
            return <PremiumAvailableBadge />;
        }

        if (cardSize === 'large-square') {
            switch (badge) {
                case 'top' as unknown as DownloadAlbum.BadgeEnum | SoundPackage.BadgeEnum:
                    return <Top height={30} width={61.25} />;
                case 'trending' as unknown as DownloadAlbum.BadgeEnum | SoundPackage.BadgeEnum | downloadPlaylist.BadgeEnum:
                    return <Trending height={30} width={93.75} />;
                case 'new' as unknown as downloadPlaylist.BadgeEnum:
                    return <NewSupreme height={30} width={67.5} />;
                case 'hot' as unknown as DownloadAlbum.BadgeEnum:
                    return <Hot height={30} width={61.25} />;
                case 'exclusive' as unknown as DownloadAlbum.BadgeEnum:
                    return <ExclusiveSupreme height={30} width={97.5} />;
                case 'exclusive-sound-packs' as unknown as SoundPackage.BadgeEnum:
                    return <ExclusiveSoundPacks height={30} width={97.5} />;
                case 'staff-pick' as unknown as SoundPackage.BadgeEnum:
                    return <StaffPick height={30} width={100} />;
                case 'classic' as unknown as DownloadAlbum.BadgeEnum:
                    return <Classic height={30} width={85} />;
                default:
                    return null;
            }
        }
        switch (badge) {
            case 'top' as unknown as DownloadAlbum.BadgeEnum | SoundPackage.BadgeEnum:
                return <Top />;
            case 'trending' as unknown as DownloadAlbum.BadgeEnum | SoundPackage.BadgeEnum | downloadPlaylist.BadgeEnum:
                return <Trending />;
            case 'new' as unknown as downloadPlaylist.BadgeEnum:
                return <NewSupreme />;
            case 'hot' as unknown as DownloadAlbum.BadgeEnum:
                return <Hot />;
            case 'exclusive' as unknown as DownloadAlbum.BadgeEnum:
                return <ExclusiveSupreme />;
            case 'exclusive-sound-packs' as unknown as SoundPackage.BadgeEnum:
                return <ExclusiveSoundPacks />;
            case 'staff-pick' as unknown as SoundPackage.BadgeEnum:
                return <StaffPick />;
            case 'classic' as unknown as DownloadAlbum.BadgeEnum:
                return <Classic />;
            default:
                return null;
        }
    }, [badge, cardSize, isPremiumOnly, hasPremiumOnlyAccess]);

    const dragAndDropType: Accepting | undefined = useMemo(() => {
        switch (contentType) {
            case 'stream:album':
            case 'download:album':
                return 'Album';
            case 'curatedSet':
                return 'CuratedSet';
            case 'track':
                return 'Media';
            case 'exclusive-playlist':
                return 'ExclusivePlaylist';
            case 'for-you-playlist':
            case 'user-playlist':
                return 'UserPlaylist-Album';
            default:
                return 'None';
        }
    }, [contentType]);

    const dragAndDropItem: DragDropItem = useMemo(() => {
        switch (contentType) {
            case 'stream:album':
            case 'download:album':
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                return album!;
            case 'curatedSet':
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                return { id, title: title! };
            case 'track':
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                return { id, title: title! };
            case 'exclusive-playlist':
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                return { id, title: title! };
            case 'for-you-playlist':
            case 'user-playlist':
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                return { id, title: title! };
            default:
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                return album!;
        }
    }, [album, contentType, id, title]);

    const { addMultipleMediaToOnlineCrate } = useCrates();
    const { addAlbumToPlaylist } = useUserPlaylistsDetails();

    const { isMobile } = useViewport();

    // eslint-disable-next-line no-empty-pattern
    const [{ }, dragRef, connectDragPreview] = useDrag<DragDropItem, any, { opacity: number }>({
        item: dragAndDropItem,
        canDrag() {
            return !isMobile;
        },
        end: (draggedItem, monitor) => {
            if (monitor.didDrop()) {
                // eslint-disable-next-line @typescript-eslint/no-shadow
                const { target, id } = monitor.getDropResult() as DragResult;
                if (target === 'favorites') {
                    handleFavorite(true);
                } else if (target === 'my-crate') {
                    if (isDownload && album) {
                        if (album.media.length > 1) {
                            openActionModal({
                                title: 'Add to Crate?',
                                subtitle: `You are about to add ${album.media.length} versions to your crate, do you want to proceed?`,
                                confirmButtonText: 'Add Versions to Crate',
                                cancelButtonText: 'Cancel',
                                shouldCloseOnOverlayClick: true,
                                variant: 'dark',
                                onConfirm: async () => {
                                    closeActionModal();
                                    addMultipleMediaToOnlineCrate({ media_ids: album.media.map((a) => a.id) });
                                },
                                onClose: closeActionModal,
                            });
                        } else {
                            addMultipleMediaToOnlineCrate({ media_ids: album.media.map((a) => a.id) });
                        }
                    } else if (isDownload) {
                        handleFetchMedia(false).then((data) => {
                            const list: CuratedSetWithAlbum | PlaylistWithAlbum | UserPlaylistWithAlbum = data as any;
                            if (list && list.albums) {
                                openActionModal({
                                    title: 'Add to Crate?',
                                    subtitle: `You are about to add ${list.albums.length} tracks from the playlist ‘${list.title}’ to your crate, do you want to proceed?`,
                                    confirmButtonText: 'Add Tracks to Crate',
                                    cancelButtonText: 'Cancel',
                                    shouldCloseOnOverlayClick: true,
                                    variant: 'dark',
                                    onConfirm: async () => {
                                        closeActionModal();
                                        if (list.albums) {
                                            addMultipleMediaToOnlineCrate({ media_ids: list.albums.map((a) => a.media[0].id) });
                                        }
                                    },
                                    onClose: closeActionModal,
                                });
                            }
                            return null;
                        }).catch(() => {

                        });
                    }
                } else if (target === 'playlist') {
                    if (isDownload && album) {
                        addAlbumToPlaylist(id as string, { albums: [{ album_id: album.id as number }] });
                    } else if (isDownload) {
                        handleFetchMedia(false).then((data) => {
                            const list: CuratedSetWithAlbum | PlaylistWithAlbum | UserPlaylistWithAlbum = data as any;
                            openActionModal({
                                title: 'Add to Playlist?',
                                subtitle: `You are about to add ${list.albums?.length} tracks from the playlist ‘${list.title}’ to your playlist, do you want to proceed?`,
                                confirmButtonText: 'Add Tracks to Playlist',
                                cancelButtonText: 'Cancel',
                                shouldCloseOnOverlayClick: true,
                                variant: 'dark',
                                onConfirm: async () => {
                                    closeActionModal();
                                    if (list && list.albums) {
                                        addAlbumToPlaylist(id as string, { albums: list.albums.map((a) => ({ album_id: a.id })) });
                                    }
                                },
                                onClose: closeActionModal,
                            });
                            return null;
                        }).catch(() => {

                        });
                    }
                } else if (target === 'playlist-category' || target === 'my-playlists') {
                    if (isDownload && album) {
                        const albums = [{ album_id: album.id as number }];
                        openCreatePlaylistForm({ category: id ? String(id) : undefined, albums });
                    } else {
                        handleFetchMedia(false).then((data) => {
                            const list: CuratedSetWithAlbum | PlaylistWithAlbum | UserPlaylistWithAlbum = data as any;
                            if (list && list.albums) {
                                const albums = list.albums.map((a) => ({ album_id: a.id }));
                                openCreatePlaylistForm({ category: id ? String(id) : undefined, albums });
                            }
                            return null;
                        }).catch(() => {
                        });
                    }
                }
            }
        },
        type: dragAndDropType
    });

    const renderTitle = useCallback(() => {
        if (contentType === 'download:album') {
            if (linkedArtist && title) {
                const startIndex = title.indexOf(linkedArtist.name);
                if (startIndex > -1) {
                    const endIndex = startIndex + linkedArtist.name.length;

                    // Extract parts of the title
                    const beforeLink = title.substring(0, startIndex);
                    const linkText = title.substring(startIndex, endIndex);
                    const afterLink = title.substring(endIndex);
                    const artistUrl = `/artist/${linkedArtist.slug}`;

                    return (
                        <>
                            <span data-tip={title}>{beforeLink}</span>
                            <AppLink href={artistUrl}>
                                <a className={classNames('underline-link', styles['card__title-top'])} onClick={(e) => e.stopPropagation()} onKeyDown={() => { }} role="link" tabIndex={-5}>{linkText}</a>
                            </AppLink>
                            <span data-tip={title}>{afterLink}</span>
                        </>
                    );
                }
            }
        }
        return (
            <div className={styles['card__title']}>{title}</div>
        );
    }, [contentType, title, linkedArtist]);

    connectDragPreview(getEmptyImage(), {
        // IE fallback: specify that we'd rather screenshot the node
        // when it already knows it's being dragged so we can hide it with CSS.
        captureDraggingState: true,
    });

    const renderCard = () => {
        return (
            <div
                ref={(ref) => {
                    if (ref) {
                        dragRef(ref);
                        viewRef(ref);
                    }
                }}
                className={classNames(
                    // eslint-disable-next-line @typescript-eslint/dot-notation
                    styles['card'],
                    styles[`card--${cardSize}`],
                    {
                        [styles['card--plain-link']]: !isPlayable,
                        [styles['card--playable']]: isPlayable,
                        [styles[`card--custom-preset-${preset}`]]: !!preset,
                        [styles['card--is-full-height']]: fullHeight,
                    },
                    className
                )}
                onMouseEnter={() => setIsHoveringCard(true)}
                onMouseLeave={() => setIsHoveringCard(false)}
            >
                <div
                    className={classNames(styles['card__img-container'], {
                        [styles['card__img-container--no-img']]: !imageUrl,
                    })}>
                    <CardImage
                        imageComponent={imageComponent}
                        imageUrl={imageUrl}
                        imageUrl2x={imageUrl2x}
                        setIsCardImageLoaded={() => setIsCardImageLoaded(true)}
                        title={title}
                        isSmallImg={!!userPlaylistConfig}
                        gridImagesUrls={gridImagesUrls}
                    />
                    {(badge || isPremiumOnly) && isCardImageLoaded ? (
                        <div className={styles['card__badge-container']}>
                            <div className={classNames(styles['card__badge'], { [styles['card__badge--hidden']]: isHoveringCard })}>
                                {renderBadge()}
                            </div>
                        </div>
                    ) : null}

                    <CardImageOverlay
                        isVisible={isHoveringCard}
                        isPlaying={isCurrentCardPlaying && playerState === State.Playing}
                        onPlayPressed={handlePlayCard}
                        onPremiumOnlyAccessPressed={handleShowPremiumPressed}
                        isPlayable={isPlayable}
                        onDownloadPressed={openDownloadMenu}
                        onSavePressed={handleFavorite}
                        onFollowPressed={handleFavorite}
                        contentType={contentType}
                        isFavorite={isFavorite}
                        hasPremiumOnlyAccess={hasPremiumOnlyAccess}
                        isPremiumOnly={isPremiumOnly}
                    />
                </div>
                <div className={styles['card__text-container']}>
                    <div className={styles['card__title-container']}>
                        <div className={styles['card__title']}>
                            {renderTitle()}
                        </div>
                        {contentType !== undefined && (
                            <button
                                aria-label="More Options"
                                type="button"
                                onClick={openThreeDots}
                                className={classNames(styles['card__download-more-dots-container'], styles['card__more-dots-toggler'], styles['card__more-dots-toggler-small'], 'relative-link', { [styles['card__download-more-dots-container--visible']]: isHoveringCard })}
                                data-tip="More"
                                data-offset={'{\'bottom\': 5}'}
                            >
                                <ThreeDotsIconSmall />
                            </button>
                        )}
                    </div>
                    <CardDescription
                        isDescriptionSingleLine={isDescriptionSingleLine}
                        description={description}
                        supremeCardDescriptionProps={descriptionProps}
                    />
                </div>
            </div>
        );
    };

    return (link ? (
        <AppLink href={link}>
            <a target={openInNewTab ? '_blank' : undefined}>
                {renderCard()}
            </a>
        </AppLink>
    ) : (
        <button type="button" onClick={handlePlayCard}>
            {renderCard()}
        </button>
    ));
}

export default Card;
/* eslint-enable jsx-a11y/anchor-is-valid */
