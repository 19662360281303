import { SubscriptionPackage, User } from '@bpm-web-app/supreme-api-sdk';
import { PageNotFound } from '@bpm-web-app/components/pages';
import { useGetMeSubscription, useGetSubscriptionPackages } from '@bpm-web-app/swr-hooks';
import { getColorFromLibrary, getLibraryName, getPackageHeadingText, getPlatformName, getCurrentPlatformLink } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useCallback, useMemo, useState } from 'react';
import CheckboxMark from '../../../../assets/icons/checkbox_mark.svg';

import styles from './compare-plans.module.css';

type PackageFeatures = {
    monthlyCost: string;
    trackPreview: boolean;
    waveform: boolean;
    hqAudioDownload: boolean;
    versionSelection: boolean;
    crateDownloads: boolean;
    customPlaylists: boolean;
    playlistAndCuratedSets: boolean;
    downloadHistory: boolean;
};

const standardPlan: PackageFeatures = {
    monthlyCost: '',
    trackPreview: true,
    waveform: true,
    hqAudioDownload: true,
    versionSelection: true,
    crateDownloads: true,
    customPlaylists: true,
    downloadHistory: true,
    playlistAndCuratedSets: false,
};

const premiumPlan: PackageFeatures = {
    monthlyCost: '',
    trackPreview: true,
    waveform: true,
    hqAudioDownload: true,
    versionSelection: true,
    crateDownloads: true,
    customPlaylists: true,
    downloadHistory: true,
    playlistAndCuratedSets: true,
};
const featuresName: { [key in keyof PackageFeatures]: string } = {
    monthlyCost: 'Monthly Cost',
    trackPreview: 'Track Preview',
    waveform: 'Waveform Player',
    hqAudioDownload: 'HQ MP3 Audio Downloads',
    versionSelection: 'Version Selection',
    crateDownloads: 'Crate Downloads',
    customPlaylists: 'Custom Playlists',
    downloadHistory: 'Download History',
    playlistAndCuratedSets: 'Exclusive Playlists & Curated Sets',
};

export interface AccountComparePlansProps {
    isCreate?: boolean;
}

export function ComparePlans({ isCreate }: AccountComparePlansProps) {
    const router = useRouter();
    const [selectedPlan, setSelectedPlan] = useState<'premium' | 'standard' | null>(null);

    const { packageId } = router.query;
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const platform = router.query['platform'] as unknown as SubscriptionPackage.PlatformEnum;

    const { data: platformPackagesData, isLoading } = useGetSubscriptionPackages(platform);
    const { data: downloadSubscriptionData, isLoading: isDownloadSubscriptionLoading } = useGetMeSubscription('supreme', isCreate);
    const verificationStatus = useMemo(() => downloadSubscriptionData?.data?.user?.verification_status, [downloadSubscriptionData?.data?.user?.verification_status]);
    const isSubscriptionStatusVerified = useMemo(() => verificationStatus === User.VerificationStatusEnum.Verified, [verificationStatus]);

    const selectedPackage = useMemo<SubscriptionPackage | null>(() => platformPackagesData?.data?.find((pack) => pack.id === +packageId!) || null, [packageId, platformPackagesData?.data]);

    const plansToCompare = useMemo<{ standard: SubscriptionPackage; premium: SubscriptionPackage }>(() => {
        const plans = platformPackagesData?.data?.filter((plan) => plan.library === selectedPackage?.library) || [];

        const standard = plans.find((plan) => plan.name === SubscriptionPackage.NameEnum.Standard)!;
        const premium = plans.find((plan) => plan.name === SubscriptionPackage.NameEnum.Premium)!;
        return { standard, premium };
    }, [platformPackagesData?.data, selectedPackage?.library]);

    const color = useMemo(() => getColorFromLibrary(selectedPackage?.library), [selectedPackage?.library]);

    const handleContinueWithSelectedPlan = useCallback(() => {
        if (plansToCompare.premium && plansToCompare.standard) {
            const plan = selectedPlan === 'premium' ? plansToCompare.premium : plansToCompare.standard;
            if (!isSubscriptionStatusVerified && plansToCompare?.standard.platform === SubscriptionPackage.PlatformEnum.Supreme) {
                router.push({
                    pathname: getCurrentPlatformLink(`/account/dj-verification/${plan.id}`),
                });
            } else { router.push(getCurrentPlatformLink(`/account/plan/change/${plan.id}`)); }
        }
    }, [plansToCompare.premium, plansToCompare.standard, router, selectedPlan, isSubscriptionStatusVerified]);

    const renderActionButton = useCallback(
        (value: typeof selectedPlan) => (
            <button
                type="button"
                disabled={plansToCompare[value]?.is_current}
                className={classNames(styles['compare-plans__cta'], styles[`compare-plans__cta--${color}`], selectedPlan === value && styles['compare-plans__cta--selected'])}
                onClick={() => setSelectedPlan(value)}
            >
                {selectedPlan === value && <CheckboxMark />}
                {selectedPlan === value ? 'Selected' : plansToCompare[value]?.is_current ? 'Current Plan' : `Get ${value}`}
            </button>
        ),
        [color, selectedPlan, plansToCompare]
    );

    const renderCheckbox = useCallback(
        (value: typeof selectedPlan, key: keyof PackageFeatures) => {
            const plan = value === 'premium' ? premiumPlan : standardPlan;

            return (
                <CheckboxMark
                    className={classNames(
                        styles['compare-plans__checkbox'],
                        { [styles[`compare-plans__checkbox--checked-${color}`]]: plan[key] },
                        { [styles['compare-plans__checkbox--unchecked']]: !plan[key] }
                    )}
                />
            );
        },
        [color]
    );

    if ((isLoading && !platformPackagesData?.data) || isDownloadSubscriptionLoading) {
        return null;
    }

    if (Number.isNaN(+packageId!) || !platform) {
        return <PageNotFound />;
    }

    let ctaText = 'Continue';

    if (!isSubscriptionStatusVerified && plansToCompare?.standard.platform === SubscriptionPackage.PlatformEnum.Supreme) {
        ctaText = 'Apply';
    }

    return (
        <div className={styles['compare-plans']}>
            <h2>{`Compare ${getPlatformName(platform as any)} Plans`}</h2>
            <h3 className={classNames(styles['compare-plans__library-name'], styles[`compare-plans__library-name--color-${color}`])}>{getLibraryName(selectedPackage?.library)}</h3>

            <div className={classNames(styles['compare-plans__container'], styles[`compare-plans__container--color-${color}`])}>
                <div className={styles['compare-plans__header']}>
                    <span className={styles['compare-plans__header--description']}>{getPackageHeadingText(selectedPackage?.library)}</span>
                    <div className={styles['compare-plans__header--plan-1']}>
                        <span>{plansToCompare?.standard.name}</span>
                        <h4>{`$${plansToCompare?.standard.amount}`}</h4>
                    </div>
                    <div className={styles['compare-plans__header--plan-2']}>
                        <span>{plansToCompare?.premium?.name || ''}</span>
                        <h4>{`$${plansToCompare?.premium?.amount || 0}`}</h4>
                    </div>
                </div>
                <hr className={styles['compare-plans__divider']} />
                <div className={styles['compare-plans__body']}>
                    {Object.keys(featuresName).map((k) => {
                        const key = k as keyof PackageFeatures;

                        return (
                            <div key={key} className={styles['compare-plans__body-item']}>
                                <div className={styles['compare-plans__body-item--description']}>
                                    <p>{featuresName[key]}</p>
                                </div>
                                <div className={styles['compare-plans__body-item--plan-1']}>
                                    {key === 'monthlyCost' ? <p>{`$${plansToCompare.standard.amount}`}</p> : renderCheckbox('standard', key)}
                                </div>
                                <div className={styles['compare-plans__body-item--plan-2']}>
                                    {key === 'monthlyCost' ? <p>{`$${plansToCompare.premium.amount}`}</p> : renderCheckbox('premium', key)}
                                </div>
                            </div>
                        );
                    })}
                    <div className={styles['compare-plans__body-item']}>
                        <div className={styles['compare-plans__body-item--description']} />
                        <div className={styles['compare-plans__body-item--plan-1']}>{renderActionButton('standard')}</div>
                        <div className={styles['compare-plans__body-item--plan-2']}>{renderActionButton('premium')}</div>
                    </div>
                </div>
            </div>
            <div className={styles['compare-plans__buttons-container']}>
                <button className="account__button" type="button" disabled={!selectedPlan} onClick={handleContinueWithSelectedPlan}>
                    {ctaText}
                </button>
                <button className="account__button-secondary" type="button" onClick={router.back}>
                    Back
                </button>
            </div>
        </div>
    );
}

export default ComparePlans;
