import { Notification, NotificationTypeIdentifier } from '@bpm-web-app/supreme-api-sdk';
import { useViewport } from '@bpm-web-app/utils';
import classNames from 'classnames';
import Router from 'next/router';
import { memo, useCallback, useContext, useMemo } from 'react';
import { CreateThreeDotsSheetContext, ThreeDotsSheetContext } from '../shared';
import BPMIcons from '../shared/bpm-icons/bpm-icons';
import styles from './user-notifications.module.css';

interface NotificationItemProps {
    notification: Notification;
    onDeleteNotification: (id: string) => void;
    platform: Notification.PlatformEnum;
    isPlaying: boolean;
    handlePlay: () => void;
    togglePlayPause: () => void;
    onClose: () => void
}

const NotificationItem = (props: NotificationItemProps) => {
    const { notification, onDeleteNotification, platform, isPlaying, handlePlay, togglePlayPause, onClose } = props;
    const { isMobile } = useViewport();

    const { openThreeDotsModalSheet: openCreateThreeDotsModalSheet } = useContext(CreateThreeDotsSheetContext);
    const { openThreeDotsModalSheet, actionTypeId, setActionTypeId } = useContext(ThreeDotsSheetContext);

    const handleTogglePlayPause = useCallback(() => {
        togglePlayPause();
    }, [togglePlayPause]);

    const handlePlayMedia = useCallback(() => {
        handlePlay();
    }, [handlePlay]);

    const isDownloadExpired = useCallback(() => {
        const creationDate = notification.created_at;
        if (!creationDate) return true;
        const createdDate = new Date(creationDate);
        const todayDate = new Date();

        // Calculate expiration date based on creation date.
        const sixDaysFromCreationDate = new Date(createdDate);
        sixDaysFromCreationDate.setDate(sixDaysFromCreationDate.getDate() + 6);

        // Compare if today's date is past expiration.
        return todayDate > sixDaysFromCreationDate;
    }, [notification.created_at]);

    const handleDeleteNotification = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        onDeleteNotification(notification.id);
    }, [notification.id, onDeleteNotification]);

    const handleOpenThreeDots = useCallback((e: React.MouseEvent<HTMLElement>) => {
        // eslint-disable-next-line prefer-const
        let { top } = e.currentTarget.getBoundingClientRect();
        top -= 80; // item height, super bad
        const left = e.currentTarget.offsetLeft;
        if (actionTypeId === notification.associated_id) {
            setActionTypeId(-1);
            return;
        }

        if (platform === Notification.PlatformEnum.Create) {
            switch (notification.type_identifier) {
                case NotificationTypeIdentifier.NewArtistRelease:
                case NotificationTypeIdentifier.NewLabelRelease:
                    if (notification.associated_type === 'Label') {
                        openCreateThreeDotsModalSheet({
                            newCreateActionType: 'label',
                            actionId: notification.associated_id ?? '',
                            secondaryActionId: notification.id,
                            left,
                            top,
                            renderLocationPosition: 'notification'
                        });
                    } else {
                        openCreateThreeDotsModalSheet({
                            newCreateActionType: 'pack',
                            actionId: notification.associated_id ?? '',
                            secondaryActionId: notification.id,
                            left,
                            top,
                            renderLocationPosition: 'notification'
                        });
                    }
                    break;
                default:
                    break;
            }
        } else {
            switch (notification.type_identifier) {
                case NotificationTypeIdentifier.NewRecommendation:
                    openThreeDotsModalSheet('for-you-playlist-detail', notification.associated_id ?? '', left ?? 0, top ?? 0, false, notification.id, undefined, 'notification');
                    break;
                case NotificationTypeIdentifier.NewPlaylistUpdate:
                    openThreeDotsModalSheet('exclusive-playlist', notification.associated_id ?? '', left ?? 0, top ?? 0, false, notification.id, undefined, 'notification');
                    break;
                case NotificationTypeIdentifier.NewArtistRelease:
                    if (notification.associated_type === 'Artist') {
                        openThreeDotsModalSheet('artist', notification.associated_id ?? '', left ?? 0, top ?? 0, false, notification.id, undefined, 'notification');
                    } else {
                        openThreeDotsModalSheet('download:album', notification.associated_id ?? '', left ?? 0, top ?? 0, false, notification.id, undefined, 'notification');
                    }
                    break;
                default:
                    break;
            }
        }
    }, [actionTypeId, notification, openCreateThreeDotsModalSheet, openThreeDotsModalSheet, platform, setActionTypeId]);

    const renderNotificationLeftIcon = useMemo(() => {
        return (
            <div className={styles['user-notifications-item__left-container']}>

                {notification.read ? null : <div className={styles['user-notifications-item__read']} />}
                <button type="button" aria-label="Delete notification" onClick={handleDeleteNotification} className={styles['user-notifications-item__close']}>
                    <BPMIcons.CloseIcon />
                </button>
            </div>
        );
    }, [handleDeleteNotification, notification.read]);

    const renderNotificationImage = useMemo(() => {
        const imageComponent = () => {
            switch (notification.type_identifier) {
                case NotificationTypeIdentifier.ApplicationApproved:
                    return (
                        <div className={styles['user-notifications-item__image']}><BPMIcons.BPMSupremeLogo /></div>
                    );
                case NotificationTypeIdentifier.CrateDownloadReady:
                    return (
                        <div className={styles['user-notifications-item__image']}><BPMIcons.CrateIcon /></div>
                    );
                case NotificationTypeIdentifier.CollaborationEnded:
                    if (notification.image) {
                        return (
                            <img src={notification.image} alt={notification.title} className={styles['user-notifications-item__image']} />
                        );
                    }
                    return (
                        <div className={styles['user-notifications-item__image']}><BPMIcons.BPMExclusiveIcon /></div>
                    );
                case NotificationTypeIdentifier.InvitedToDrive:
                case NotificationTypeIdentifier.AcceptedCollaborationInvite:
                case NotificationTypeIdentifier.InvitedToUserPlaylist:
                    return (
                        <div className={styles['user-notifications-item__image']}><BPMIcons.FollowIcon /></div>
                    );
                case NotificationTypeIdentifier.NewRecommendation:
                case NotificationTypeIdentifier.NewPlaylistUpdate:
                case NotificationTypeIdentifier.NewArtistRelease:
                    return (
                        <>
                            <img src={notification.image} alt={notification.title} className={styles['user-notifications-item__image']} />
                            <div className={styles['user-notifications-item__play-hover']}>{isPlaying ? <BPMIcons.PauseIcon /> : <BPMIcons.PlayIcon />}</div>
                        </>
                    );
                default:
                    if (notification.image) {
                        return (
                            <img src={notification.image} alt={notification.title} className={styles['user-notifications-item__image']} />
                        );
                    }
                    return <div />;
            }
        };
        return (
            <button
                type="button"
                className={styles['user-notifications-item__image-container']}
                onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    if (isPlaying) {
                        handleTogglePlayPause();
                    } else {
                        handlePlayMedia();
                    }
                }}>
                {imageComponent()}
            </button>
        );
    }, [handlePlayMedia, handleTogglePlayPause, isPlaying, notification.image, notification.title, notification.type_identifier]);

    const renderNotificationBody = useMemo(() => {
        const notificationText = () => {
            switch (notification.type_identifier) {
                case NotificationTypeIdentifier.InvitedToDrive:
                case NotificationTypeIdentifier.AcceptedCollaborationInvite:
                case NotificationTypeIdentifier.InvitedToUserPlaylist:
                case NotificationTypeIdentifier.NewRecommendation:
                    return (
                        <div className={styles['user-notifications-item__multiline-text']}>{notification.title}</div>
                    );

                default:
                    return (
                        <div className={styles['user-notifications-item__multiline-text']}>{notification.title}</div>
                    );
            }
        };
        return (
            <div className={styles['user-notifications-item__middle-container']}>
                {notificationText()}
                {notification.body ? <div className={styles['user-notifications-item__secondary-text']}>{notification.body}</div> : null}
            </div>
        );
    }, [notification.body, notification.title, notification.type_identifier]);

    const renderNotificationAction = useMemo(() => {
        const rightContainer = () => {
            switch (notification.type_identifier) {
                case NotificationTypeIdentifier.CrateDownloadReady:
                    return isDownloadExpired() ? null : (
                        <div className={classNames(styles['user-notifications-item__right-container'], styles['user-notifications-item__right-container--action'])}>
                            <button type="button" aria-label="Download Crate" className={classNames(styles['user-notifications-item__right-button'], styles['user-notifications-item__download-button'])} onClick={() => null}>
                                Download
                            </button>
                        </div>
                    );
                case NotificationTypeIdentifier.CollaborationEnded:
                    if (!notification.link) {
                        return null;
                    }
                    return (
                        <div className={classNames(styles['user-notifications-item__right-container'], styles['user-notifications-item__right-container--action'])}>
                            <button
                                type="button"
                                aria-label={`Open ${notification.associated_type}`}
                                className={styles['user-notifications-item__right-button']}
                                onClick={() => {
                                    Router.push((notification.link as string));
                                    onClose();
                                }}>
                                Save {notification.associated_type === 'Drive' ? 'Drive' : 'Playlist'}
                            </button>
                        </div>
                    );
                case NotificationTypeIdentifier.AcceptedCollaborationInvite:
                case NotificationTypeIdentifier.CollaborationUpdate:
                    return (
                        <div className={classNames(styles['user-notifications-item__right-container'], styles['user-notifications-item__right-container--action'])}>
                            <button
                                type="button"
                                aria-label={`Open ${notification.associated_type}`}
                                className={styles['user-notifications-item__right-button']}
                                onClick={() => {
                                    Router.push((notification.link as string));
                                    onClose();
                                }}>
                                Open {notification.associated_type === 'Drive' ? 'Drive' : 'Playlist'}
                            </button>
                        </div>
                    );
                case NotificationTypeIdentifier.InvitedToDrive:
                case NotificationTypeIdentifier.InvitedToUserPlaylist:
                    return (
                        <div className={classNames(styles['user-notifications-item__right-container'], styles['user-notifications-item__right-container--action'])}>
                            <button
                                type="button"
                                aria-label="Open Playlist"
                                className={styles['user-notifications-item__right-button']}
                                onClick={() => {
                                    Router.push((notification.link as string));
                                    onClose();
                                }}>
                                View Invite
                            </button>
                        </div>
                    );
                case NotificationTypeIdentifier.NewRecommendation:
                case NotificationTypeIdentifier.NewPlaylistUpdate:
                case NotificationTypeIdentifier.NewArtistRelease:
                case NotificationTypeIdentifier.NewLabelRelease:
                    return platform === Notification.PlatformEnum.Create ?
                        (
                            <CreateThreeDotsSheetContext.Consumer>
                                {(value) => {
                                    return (
                                        <div className={classNames(styles['user-notifications-item__right-container'])}>
                                            <button
                                                type="button"
                                                className={classNames(styles['user-notifications-item__ellipsis'], 'three-dots-button', {
                                                    [styles['user-notifications-item__ellipsis--visible']]: value.secondaryCreateActionTypeId === notification.id && value.renderLocation === 'notification' && value.actionTypeId !== -1
                                                })}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleOpenThreeDots(e);
                                                }}
                                            >
                                                <BPMIcons.ThreeDotsIcon />
                                            </button>
                                        </div>
                                    );
                                }}
                            </CreateThreeDotsSheetContext.Consumer>
                        )
                        :
                        (
                            <ThreeDotsSheetContext.Consumer>
                                {(value) => {
                                    return (
                                        <div className={classNames(styles['user-notifications-item__right-container'])}>
                                            <button
                                                type="button"
                                                className={classNames(styles['user-notifications-item__ellipsis'], 'three-dots-button', {
                                                    [styles['user-notifications-item__ellipsis--visible']]: value.secondaryActionTypeId === notification.id && value.renderLocation === 'notification' && value.actionTypeId !== -1
                                                })}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleOpenThreeDots(e);
                                                }}
                                            >
                                                <BPMIcons.ThreeDotsIcon />
                                            </button>
                                        </div>
                                    );
                                }}
                            </ThreeDotsSheetContext.Consumer>
                        );

                default:
                    return null;
            }
        };
        return rightContainer();
    }, [handleOpenThreeDots, isDownloadExpired, notification.associated_type, notification.id, notification.link, notification.type_identifier, onClose, platform]);

    const renderNotification = useMemo(() => {
        return (
            <div
                role="button"
                tabIndex={0}
                className={styles['user-notifications-item__notification']}
                onKeyDown={(e) => {
                    if (isMobile) e.preventDefault();
                }}
                onClick={(e) => {
                    if (isMobile) e.preventDefault();
                    if (notification.type_identifier !== NotificationTypeIdentifier.CrateDownloadReady) {
                        Router.push((notification.link as string));
                        onClose();
                    } else if (isDownloadExpired() === false) {
                        Router.push((notification.link as string));
                    }
                }}
            >
                {renderNotificationLeftIcon}
                {renderNotificationImage}
                {renderNotificationBody}
                {renderNotificationAction}
            </div>
        );
    }, [isDownloadExpired, isMobile, notification.link, notification.type_identifier, onClose, renderNotificationAction, renderNotificationBody, renderNotificationImage, renderNotificationLeftIcon]);

    return (
        <li className={styles['user-notifications-item']}>
            {renderNotification}
        </li>
    );
};

export default memo(NotificationItem);
