import classNames from 'classnames';
import { ChangeEvent, useCallback, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { useApiErrorHandler, useHubSwitch, showToast } from '@bpm-web-app/utils';
import { apiKey } from '@bpm-web-app/api-client';
import styles from './account-add-music.module.css';
import CloseIcon from '../../../assets/icons/close.svg';
import SpinnerIcon from '../../../assets/icons/spinner.svg';
import CheckIcon from '../../../assets/icons/check.svg';

export function AccountAddMusic() {
    const fileRef = useRef<HTMLInputElement>();
    const [isOpen, setIsOpen] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [result, setResult] = useState<{ filename: string; imported: number } | null>(null);

    const { isDownload, toggle: togglePlatform } = useHubSwitch();
    const errorHandler = useApiErrorHandler();

    const handleFile = useCallback(
        async (e: ChangeEvent<HTMLInputElement>) => {
            if (!e.target.files.length) return;

            setIsOpen(true);
            setIsUploading(true);

            try {
                const file = e.target.files?.[0];
                const formData = new FormData();
                formData.append('file', file, file.name);
                const key = apiKey();
                const headers = {} as { [key: string]: string };
                if (key) {
                    // eslint-disable-next-line @typescript-eslint/dot-notation
                    headers['Authorization'] = key;
                }
                // eslint-disable-next-line @typescript-eslint/dot-notation
                const response = await fetch(`${process.env['NEXT_PUBLIC_STREAM_API_BASE_PATH']}/csv-import`, {
                    method: 'POST',
                    credentials: 'include',
                    body: formData,
                    headers
                });
                if (!response.ok) {
                    throw new Error(response.statusText);
                }

                const { data } = await response.json();
                setResult({ filename: file.name, imported: data.imported });
                showToast({ type: 'success', message: 'File upload successful.' });

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                errorHandler({ error });
            } finally {
                setIsUploading(false);
            }
        },
        [errorHandler]
    );

    const closeModal = () => {
        setIsOpen(false);
    };

    const handleUploadButtonClick = useCallback(() => {
        if (isDownload) {
            togglePlatform();
            showToast({ type: 'success', message: 'Switched to stream platform.' });
        }

        fileRef.current.click();
    }, [isDownload, togglePlatform]);

    return (
        <>
            <div className={styles['account-add-music']}>
                <h2>Add Music</h2>
                <div className={styles['account-add-music__container']}>
                    <div className={styles['account-add-music__container-header']}>
                        <h3 className={styles['account-add-music__container-title']}>Transfer Music</h3>
                    </div>
                    <a target="_blank" href="https://www.tunemymusic.com/" rel="noopener noreferrer">
                        <span className={classNames(styles['account-add-music__container-button'], 'button button--solid')} aria-label="transfer">
                            {' '}
                            Transfer{' '}
                        </span>
                    </a>
                </div>

                <div className={styles['account-add-music__container']}>
                    <div className={styles['account-add-music__container-header']}>
                        <h3 className={styles['account-add-music__container-title']}>Upload CSV</h3>
                        {isUploading && <SpinnerIcon />}
                    </div>
                    <div className={styles['account-add-music__container-text']}>
                        Get started by uploading playlists from your computer or other music services. A new playlist will be created in your &apos;My Playlist&apos; section that includes any matched
                        songs available. To learn more about uploading a CSV,{' '}
                        <a href="https://support.bpmsupreme.com" target="_blank" rel="noreferrer">
                            click here
                        </a>
                        .
                    </div>

                    <input className={styles['account-add-music__container-file']} type="file" accept="text/csv" ref={fileRef} onChange={(e) => handleFile(e)} multiple={false} hidden />
                    <button type="button" className={classNames(styles['account-add-music__container-button'], 'button button--solid')} aria-label="upload" onClick={handleUploadButtonClick}>
                        Upload
                    </button>
                    {result && (
                        <div className={styles['account-add-music__container-resume']}>
                            <CheckIcon />
                            {`${result.imported} songs from `}
                            <strong>{result.filename}</strong> have been detected
                        </div>
                    )}
                </div>
            </div>
            <ReactModal
                isOpen={isOpen}
                className={classNames('react-modal__content', styles['account-add-music__modal-content'])}
                overlayClassName="react-modal__overlay"
                shouldCloseOnOverlayClick
                onRequestClose={closeModal}
            >
                <form className={styles['account-add-music__modal-form']}>
                    <div className={styles['account-add-music__modal-header']}>
                        <h2 className={styles['account-add-music__modal-title']}>Upload in Progress</h2>
                        <button type="button" className={styles['account-add-music__modal-close-icon']} onClick={closeModal}>
                            <CloseIcon />
                        </button>
                    </div>
                    <h2 className={styles['account-add-music__modal-body-title']}>This will only take a few moments...</h2>
                    <h5>Songs will be added to &quot;My Playlists&quot; if they are available. You will receive a notification when the upload is complete.</h5>
                </form>
            </ReactModal>
        </>
    );
}
